import { InvariantException } from '@kontent-ai/errors';
import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { ICompiledContentItemElementData } from '../../../../models/contentItemElements/ICompiledContentItemElement.ts';
import {
  getTargetElementsAffectedByTriggerChange,
  isElementHiddenByTrigger,
  isElementShownByTrigger,
} from '../../../../utils/itemElementConditionUtils.ts';
import {
  addDefaultValuesToVisibleElements,
  removeHiddenElementsValues,
} from '../contentItemEditingActions.ts';

export const createProcessDependentElementsAction =
  () =>
  (
    triggerElementNewValue: ICompiledContentItemElementData,
    triggerElementPreviousValue: ICompiledContentItemElementData,
  ): ThunkFunction =>
  (dispatch, getState) => {
    const {
      contentApp: { editedContentItem, editedContentItemVariantElements, loadedContentItemTypes },
    } = getState();

    if (!editedContentItem) {
      throw InvariantException(
        `refreshHiddenElements.ts: "editedContentItem" is ${editedContentItem}.`,
      );
    }

    if (!editedContentItemVariantElements) {
      throw InvariantException(
        `refreshHiddenElements.ts: "editedContentItemVariantElements" is ${editedContentItemVariantElements}.`,
      );
    }

    const itemType = loadedContentItemTypes.get(editedContentItem.editedContentItemTypeId);

    if (!itemType) {
      throw InvariantException(`${__filename}: "itemType" is ${itemType}.`);
    }

    const becomingVisibleElements = getTargetElementsAffectedByTriggerChange(
      triggerElementPreviousValue,
      triggerElementNewValue,
      editedContentItemVariantElements,
      itemType.contentElements,
      isElementShownByTrigger,
    );

    if (becomingVisibleElements.length) {
      dispatch(
        addDefaultValuesToVisibleElements(becomingVisibleElements, itemType.contentElements),
      );
    }

    const becomingHiddenElements = getTargetElementsAffectedByTriggerChange(
      triggerElementPreviousValue,
      triggerElementNewValue,
      editedContentItemVariantElements,
      itemType.contentElements,
      isElementHiddenByTrigger,
    );

    if (becomingHiddenElements.length) {
      dispatch(removeHiddenElementsValues(becomingHiddenElements.map((el) => el.elementId)));
    }
  };
