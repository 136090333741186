import { LocationDescriptor } from 'history';
import PropTypes from 'prop-types';
import React from 'react';
import { BackButton } from '../../../../component-library/components/StatusBar/BackButton/BackButton.tsx';
import {
  DataUiStatusBarActions,
  getDataUiActionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

export interface IBackNavigationStateProps {
  readonly isDisabled?: boolean;
  readonly to: LocationDescriptor;
}

const propTypes: PropTypeMap<IBackNavigationStateProps> = {
  isDisabled: PropTypes.bool,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export const BackNavigation: React.FC<IBackNavigationStateProps> = ({ isDisabled, to }) => (
  <div className="status-bar__back-button">
    <BackButton
      to={to}
      disabled={isDisabled}
      {...getDataUiActionAttribute(DataUiStatusBarActions.NavigateBack)}
    />
  </div>
);

BackNavigation.displayName = 'BackNavigation';
BackNavigation.propTypes = propTypes;
