import React from 'react';
import { Route, Switch } from 'react-router';
import { RedirectIf } from '../../../_shared/components/routing/RedirectIf.tsx';
import {
  ContentItemAccessDeniedRoute,
  ContentItemAccessDeniedRouteParams,
  ContentItemPreviewRoute,
  ContentItemRoute,
  ContentItemRouteParams,
  RootRoute,
} from '../../../_shared/constants/routePaths.ts';
import { IRouteContext, RouteContext } from '../../../_shared/containers/routing/RouteContext.tsx';
import { AccessDeniedToContentItemAction } from '../../errorHandling/constants/AccessDeniedToContentItemAction.ts';
import { AccessDeniedToContentItem } from '../../errorHandling/containers/AccessDeniedToContentItem.tsx';
import { ContentItemEditing } from '../../itemEditor/components/ContentItemEditing.tsx';
import { WebSpotlightTree } from '../containers/WebSpotlightTree.tsx';
import { WebSpotlightContextProvider } from '../context/WebSpotlightContext.tsx';
import { WebSpotlightEditorView } from './WebSpotlightEditorView.tsx';
import { WebSpotlightGrid } from './WebSpotlightGrid.tsx';
import { WebSpotlightLoaderView } from './WebSpotlightLoaderView.tsx';
import { WebSpotlightPreviewView } from './WebSpotlightPreviewView.tsx';
import { WebSpotlightRevisionView } from './WebSpotlightRevisionView.tsx';
import { WebSpotlightWorkspace } from './WebSpotlightWorkspace.tsx';

type InitializedWebSpotlightProps = {
  readonly rootItemId: Uuid;
};

export const InitializedWebSpotlight: React.FC<InitializedWebSpotlightProps> = ({ rootItemId }) => {
  return (
    <WebSpotlightContextProvider>
      <WebSpotlightGrid>
        <WebSpotlightTree rootItemId={rootItemId} />
        <Switch>
          <Route path={ContentItemAccessDeniedRoute}>
            <RouteContext>
              {({ match }: IRouteContext<ContentItemAccessDeniedRouteParams<string>>) => (
                <RedirectIf
                  redirectTo={RootRoute}
                  doRedirect={
                    !Object.values(AccessDeniedToContentItemAction).includes(
                      match.params.requestedAction,
                    )
                  }
                >
                  <WebSpotlightWorkspace>
                    <AccessDeniedToContentItem requestedAction={match.params.requestedAction} />
                  </WebSpotlightWorkspace>
                </RedirectIf>
              )}
            </RouteContext>
          </Route>
          <Route path={[ContentItemPreviewRoute, ContentItemRoute]}>
            <RouteContext>
              {({ match }: IRouteContext<ContentItemRouteParams<string>>) => (
                <ContentItemEditing
                  deleteUntouchedTemporaryItem
                  renderEditor={() => <WebSpotlightEditorView />}
                  renderRevision={(timelineItemId) => (
                    <WebSpotlightRevisionView timelineItemId={timelineItemId} />
                  )}
                  renderPreview={() => <WebSpotlightPreviewView />}
                  // In case the route is preview route, we always render preview as it has its own loader logic independent from the edited item state
                  renderNotInitialized={() =>
                    match.path === ContentItemPreviewRoute ? (
                      <WebSpotlightPreviewView />
                    ) : (
                      <WebSpotlightLoaderView />
                    )
                  }
                />
              )}
            </RouteContext>
          </Route>
        </Switch>
      </WebSpotlightGrid>
    </WebSpotlightContextProvider>
  );
};

InitializedWebSpotlight.displayName = 'InitializedWebSpotlight';
