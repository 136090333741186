import { forwardRef } from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { NewContentItemDialog } from '../components/NewContentItemDialog.tsx';
import { NewContentItemOrigin } from '../constants/newContentItemOrigin.ts';
import { disabledCreateButtonTooltip } from '../constants/uiConstants.ts';
import { getNewContentItemFormSelectedValues } from '../selectors/getNewContentItemFormSelectedValues.ts';
import { hasNewContentItemFormAvailableContentTypes } from '../selectors/hasNewContentItemFormAvailableContentTypes.ts';
import { getNewContentItemDialogProperties } from '../selectors/newContentItemDialog.ts';
import { getNewContentItemVisibleSelectors } from '../utils/getNewContentItemVisibleSelectors.ts';

interface IProps {
  readonly isOpen: boolean;
  readonly onSubmit: () => void;
  readonly onReturnFocus?: () => void;
  readonly onClose: () => void;
}

const NewContentItemDialogContainer = forwardRef<HTMLDivElement, IProps>(
  ({ onClose, isOpen, onReturnFocus, onSubmit }, ref) => {
    const isSubmitButtonShown = useSelector(hasNewContentItemFormAvailableContentTypes);
    const disabledButtonTooltip = useSelector(getDisabledButtonTooltip);
    const headline = useSelector(getHeadline);

    return (
      <NewContentItemDialog
        disabledSubmitTooltip={disabledButtonTooltip}
        headline={headline}
        isOpen={isOpen}
        isSubmitButtonShown={isSubmitButtonShown}
        onClose={onClose}
        onReturnFocus={onReturnFocus}
        onSubmit={onSubmit}
        ref={ref}
      />
    );
  },
);

NewContentItemDialogContainer.displayName = 'NewContentItemDialogContainer';

export { NewContentItemDialogContainer as NewContentItemDialog };

const getHeadline = (state: IStore): string =>
  getNewContentItemDialogProperties(state).origin === NewContentItemOrigin.ContentComponent
    ? 'Create a component'
    : 'Create a content item';

const getDisabledButtonTooltip = (state: IStore): string | undefined => {
  const selectedValues = getNewContentItemFormSelectedValues(state);
  const visibleSelectors = getNewContentItemVisibleSelectors(state);
  const doAllVisibleSelectorsHaveSelectedValue = Array.from(visibleSelectors.values()).every(
    (selector) => selectedValues[selector],
  );

  return doAllVisibleSelectorsHaveSelectedValue ? undefined : disabledCreateButtonTooltip;
};
