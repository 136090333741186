export const DateIsInPastError = 'Select a date that’s in the future.';
export const PublishDateAfterUnpublishDateError =
  'You need to set a publishing date that comes before the unpublishing date.';

export const IncompleteElementsError = 'There are some elements not filled in correctly.';

export const PublishErrorMessage = 'There was an error while publishing. Please try again.';
export const UnpublishErrorMessage = 'There was an error while unpublishing. Please try again.';
export const SchedulePublishErrorMessage =
  'There was an error while scheduling publishing. Please try again.';
export const ScheduleUnpublishErrorMessage =
  'There was an error while saving changes. Please try again.';
export const UpdateWorkflowStepErrorMessage =
  'There was an error while updating the workflow step. Please try again.';

export const ContinueToCascadeActionTooltip = 'Select what should be published in the next step.';

export const BulkMoveToCollectionError =
  'There was an error while moving items between collections. Please refresh the page and try again.';

export const CannotPublishNonLocalizableElementsAndViewDefaultVariantError =
  'This item contains required non-localizable elements. Ask a colleague with enough permissions to publish the variant in default language first.';
