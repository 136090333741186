import PropTypes from 'prop-types';
import React from 'react';
import {
  HighlightedIndexes,
  findMatchingIndexes as findMatchingIndexesDefault,
} from '../../utils/filter/nameFilterUtils.ts';
import { SearchPhraseHighlighterElement } from './SearchPhraseHighlighterElement.tsx';

type Props = {
  readonly className?: string;
  readonly findMatchingIndexes?: (words: string[], text: string) => HighlightedIndexes[];
  readonly searchPhrase: string;
  readonly text: string;
};

const propTypes: PropTypesShape<Props> = {
  className: PropTypes.string,
  findMatchingIndexes: PropTypes.func,
  searchPhrase: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export const SearchPhraseHighlighter: React.FC<Props> = ({
  className,
  findMatchingIndexes = findMatchingIndexesDefault,
  searchPhrase,
  text,
}) => {
  return (
    <span className={className} title={text}>
      <SearchPhraseHighlighterElement
        searchPhrase={searchPhrase}
        text={text}
        findMatchingIndexes={findMatchingIndexes}
      />
    </span>
  );
};

SearchPhraseHighlighter.propTypes = propTypes;
SearchPhraseHighlighter.displayName = 'SearchPhraseHighlighter';
