import { identity } from '@kontent-ai/utils';
import React, { memo } from 'react';
import { Loader } from '../../../../../_shared/components/Loader.tsx';
import { ModalDialog } from '../../../../../_shared/components/ModalDialog/ModalDialog.tsx';
import { Warning } from '../../../../../_shared/components/infos/Warning.tsx';
import {
  ItemColumnCode,
  translateColumnCodeToTitle,
} from '../../../../../_shared/constants/itemColumnCode.ts';
import { useGradualSequence } from '../../../../../_shared/hooks/useGradualSequence.ts';
import { MemoizedContentItemId } from '../../../../../_shared/models/ContentItemId.type.ts';
import { stringifyContentItemId } from '../../../../../_shared/models/utils/contentItemIdUtils.ts';
import { ListingMessage } from '../../../../../_shared/uiComponents/ListingMessage/ListingMessage.tsx';
import { ScrollTableHeadColumn } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableHeadColumn.tsx';
import { ScrollTableHeadRow } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableHeadRow.tsx';
import { SimpleScrollTable } from '../../../../../_shared/uiComponents/ScrollTable/SimpleScrollTable.tsx';
import {
  DataUiCollection,
  DataUiElement,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IStatusInfoMessage } from '../../../../contentInventory/assets/models/IStatusInfoMessage.type.ts';
import { LazyChildRow } from '../containers/ChildRow.tsx';
import { ParentItemDefaultVariantRow } from '../containers/ParentItemDefaultVariantRow.tsx';
import { ParentItemRow } from '../containers/ParentItemRow.tsx';
import { ResponsiveCascadeCellsGroup } from './ResponsiveCascadeCellsGroup.tsx';

type ModalBodyProps = {
  readonly firstLevelChildIds: ReadonlyArray<MemoizedContentItemId>;
  readonly statusInfoMessage: IStatusInfoMessage;
};

const ModalBody: React.FC<ModalBodyProps> = (props) => (
  <div className="modal-dialog__scroll-table-wrapper">
    <SimpleScrollTable
      collectionName={DataUiCollection.ContentItems}
      fillAvailableSpace
      noShadow
      renderTitle={() => <ListingMessage statusInfoMessage={props.statusInfoMessage} />}
      renderHead={(showScrollBar: boolean) => (
        <ScrollTableHeadRow collectionName={DataUiCollection.SortOptions}>
          <ResponsiveCascadeCellsGroup>
            <ScrollTableHeadColumn name="" size={1} />
            <ScrollTableHeadColumn name="" size={1} />
            <ScrollTableHeadColumn
              name={translateColumnCodeToTitle(ItemColumnCode.Name)}
              size={10}
            />
            <ScrollTableHeadColumn name="" size={1} />
          </ResponsiveCascadeCellsGroup>
          <ScrollTableHeadColumn
            name={translateColumnCodeToTitle(ItemColumnCode.WorkflowStep)}
            size={4}
            isGrowing
          />
          <ScrollTableHeadColumn
            name={translateColumnCodeToTitle(ItemColumnCode.ContentType)}
            size={4}
            isGrowing
          />
          {showScrollBar && <div className="scroll-table__head-scroll" />}
        </ScrollTableHeadRow>
      )}
    >
      <ParentItemRow />
      <ParentItemDefaultVariantRow />
      <ChildItems childContentItemIds={props.firstLevelChildIds} />
    </SimpleScrollTable>
  </div>
);

ModalBody.displayName = 'ModalBody';

type ChildItemsProps = {
  readonly childContentItemIds: ReadonlyArray<MemoizedContentItemId>;
};

const ChildItems: React.FC<ChildItemsProps> = memo(({ childContentItemIds }) => {
  // When we are dealing with more than 1000 items, mount them in chunks to avoid browser getting stuck on too many new components
  const { renderItems } = useGradualSequence(childContentItemIds, identity, { chunkSize: 1000 });
  const hideOutsideViewport = childContentItemIds.length > 10;

  return (
    <>
      {renderItems.map((contentItemId) => (
        <LazyChildRow
          key={stringifyContentItemId(contentItemId)}
          contentItemId={contentItemId}
          depth={1}
          hideOutsideViewport={hideOutsideViewport}
        />
      ))}
    </>
  );
});

ChildItems.displayName = 'ChildItems';

interface ICascadeModalProps extends ModalBodyProps {
  readonly isModalContentLoaded: boolean;
  readonly onClose: () => void;
}

export type CascadeModalOwnProps = {
  modalTitle: string;
  renderFooter: (renderFooterCallbacks: {
    readonly isPublishDisabled: boolean;
    readonly onClose: () => void;
  }) => React.ReactNode;
  statusInfoMessage: IStatusInfoMessage;
};

type Props = ICascadeModalProps & CascadeModalOwnProps;

export const CascadeModal: React.ComponentType<Props> = (props) => {
  const { isModalContentLoaded, onClose, renderFooter } = props;

  const footer = renderFooter({
    isPublishDisabled: !isModalContentLoaded,
    onClose,
  });

  const modalBody = isModalContentLoaded ? (
    <ModalBody
      firstLevelChildIds={props.firstLevelChildIds}
      statusInfoMessage={props.statusInfoMessage}
    />
  ) : (
    <Loader />
  );

  return (
    <ModalDialog
      customClassName="cascade-modal"
      dataUiElement={DataUiElement.CascadePublishDialog}
      headerContent={props.modalTitle}
      isFullHeight
      onClose={props.onClose}
      withDividers
      bodyContent={modalBody}
      footerContentRight={footer}
      footerContentLeft={
        <div>
          <Warning hideTitle>
            Leaving linked items or pages unpublished might lead to broken links or break your app.
          </Warning>
        </div>
      }
    />
  );
};

CascadeModal.displayName = 'CascadeModal';
