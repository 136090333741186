import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import {
  getSelectedSubscriptionIdOrThrow,
  getSubscriptionPlan,
} from '../../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';

interface IDeps {
  readonly loadEnvironmentRoles: (projectId: Uuid) => ThunkPromise;
  readonly loadEnvironmentCollections: (projectId: Uuid) => ThunkPromise;
  readonly loadEnvironmentLanguages: (projectId: Uuid) => ThunkPromise;
}

export const createLoadSubscriptionUserListingFilterDepsAction =
  ({ loadEnvironmentCollections, loadEnvironmentLanguages, loadEnvironmentRoles }: IDeps) =>
  (environmentId: Uuid): ThunkPromise =>
  async (dispatch, getState) => {
    const subscriptionId = getSelectedSubscriptionIdOrThrow(getState());
    const subscriptionPlan = getSubscriptionPlan(getState(), subscriptionId);

    const promises = [];

    if (environmentId) {
      promises.push(dispatch(loadEnvironmentRoles(environmentId)));

      if (
        subscriptionPlan.features.areContentCollectionsEnabled &&
        subscriptionPlan.features.areContentCollectionsPermissionsEnabled
      ) {
        promises.push(dispatch(loadEnvironmentCollections(environmentId)));
      }

      if (subscriptionPlan.features.areLanguageRolesEnabled) {
        promises.push(dispatch(loadEnvironmentLanguages(environmentId)));
      }
    }

    await Promise.all(promises);
  };
