import { ThunkFunction } from '../../../../@types/Dispatcher.type.ts';
import { getProjectIdToRedirect } from '../../../../_shared/utils/getPathToRedirectToFromRoot.ts';
import { logError } from '../../../../_shared/utils/logError.ts';
import { buildAssetsRoutePath } from '../../../contentInventory/assets/utils/assetsPathUtils.ts';
import { handleSuccessfulRedirect } from '../smartLinkActions.ts';

export const redirectToAssetsLibraryCreator = () => (): ThunkFunction => (dispatch, getState) => {
  const {
    data: { user },
  } = getState();

  const projectId = getProjectIdToRedirect(user);
  if (!projectId) {
    logError(
      `Trying to redirectToAssetsLibrary a user that has no projectId to redirect to. UserId: ${user.info.userId}`,
    );
    return;
  }
  const path = buildAssetsRoutePath({ projectId });
  dispatch(handleSuccessfulRedirect(path));
};
