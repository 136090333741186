import { Box } from '@kontent-ai/component-library/Box';
import { Button } from '@kontent-ai/component-library/Button';
import { Callout } from '@kontent-ai/component-library/Callout';
import { Card } from '@kontent-ai/component-library/Card';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import PropTypes from 'prop-types';
import React from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ScopeLimitations } from './ScopeLimitations.tsx';

const ChangesWontAffectExistingItems =
  'Your changes here won’t affect existing items. You can change their workflow manually.';
const DefaultWorkflowCanOnlyHaveOneRule = 'The default workflow can only have one generic rule.';
const ItemsWillUseMoreSpecificWorkflow =
  'Items matching a workflow with more specific rules will use that workflow instead of this one.';

const DefaultWorkflowQuickTip: React.FC = () => (
  <Callout calloutType="quickTip">
    <ul>
      <li>
        The default workflow can’t have any specific rules because every content item needs to have
        at least one workflow available.
      </li>
      <li>{ItemsWillUseMoreSpecificWorkflow}</li>
    </ul>
  </Callout>
);

DefaultWorkflowQuickTip.displayName = 'DefaultWorkflowQuickTip';

const LimitedWorkflowQuickTip: React.FC = () => (
  <Callout calloutType="quickTip">
    <ul>
      <li>New items matching the selected combinations will use this workflow.</li>
      <li>{ChangesWontAffectExistingItems}</li>
    </ul>
  </Callout>
);

LimitedWorkflowQuickTip.displayName = 'LimitedWorkflowQuickTip';

const UnlimitedWorkflowQuickTip: React.FC = () => (
  <Callout calloutType="quickTip">
    <ul>
      <li>This workflow isn’t limited to any collection and content type combination. </li>
      <li>{ItemsWillUseMoreSpecificWorkflow}</li>
      <li>{ChangesWontAffectExistingItems}</li>
    </ul>
  </Callout>
);

UnlimitedWorkflowQuickTip.displayName = 'UnlimitedWorkflowQuickTip';

const getWorkflowLimitationsQuickTip = (
  isDefaultWorkflow: boolean,
  hasUnlimitedScopes: boolean,
) => {
  if (isDefaultWorkflow) {
    return <DefaultWorkflowQuickTip />;
  }
  return hasUnlimitedScopes ? <UnlimitedWorkflowQuickTip /> : <LimitedWorkflowQuickTip />;
};

interface IWorkflowLimitationsProps {
  readonly hasUnlimitedScopes: boolean;
  readonly isDefaultWorkflow: boolean;
  readonly onWorkflowScopeCreated: () => void;
  readonly onWorkflowScopeDeleted: (scopeId: Uuid) => void;
  readonly workflowScopesIds: UuidArray;
}

const propTypes: PropTypeMap<IWorkflowLimitationsProps> = {
  hasUnlimitedScopes: PropTypes.bool.isRequired,
  isDefaultWorkflow: PropTypes.bool.isRequired,
  onWorkflowScopeCreated: PropTypes.func.isRequired,
  onWorkflowScopeDeleted: PropTypes.func.isRequired,
  workflowScopesIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export const WorkflowLimitations: React.FC<IWorkflowLimitationsProps> = ({
  hasUnlimitedScopes,
  isDefaultWorkflow,
  onWorkflowScopeCreated,
  onWorkflowScopeDeleted,
  workflowScopesIds,
}) => {
  const onDelete = workflowScopesIds.length > 1 ? onWorkflowScopeDeleted : undefined;

  const [firstScopeId, ...restOfScopeIds] = workflowScopesIds;

  return (
    <Card component="section" cardLabel="Use this workflow for">
      <Card.Headline>Use this workflow for</Card.Headline>
      <Card.Body>
        <Stack spacing={Spacing.XL}>
          {isDefaultWorkflow ? (
            <ScopeLimitations renderLabels scopeId={undefined} />
          ) : (
            <>
              <ScopeLimitations
                onWorkflowScopeDeleted={onDelete}
                renderLabels
                scopeId={firstScopeId}
              />
              {restOfScopeIds.map((scopeId) => (
                <ScopeLimitations
                  key={scopeId}
                  onWorkflowScopeDeleted={onDelete}
                  scopeId={scopeId}
                />
              ))}
            </>
          )}

          <Box>
            <Button
              buttonStyle="primary"
              disabled={isDefaultWorkflow}
              onClick={onWorkflowScopeCreated}
              tooltipText={isDefaultWorkflow ? DefaultWorkflowCanOnlyHaveOneRule : undefined}
              {...getDataUiActionAttribute(DataUiAction.AddScope)}
            >
              Add rule
            </Button>
          </Box>

          {getWorkflowLimitationsQuickTip(isDefaultWorkflow, hasUnlimitedScopes)}
        </Stack>
      </Card.Body>
    </Card>
  );
};

WorkflowLimitations.displayName = 'WorkflowLimitations';
WorkflowLimitations.propTypes = propTypes;
