import classNames from 'classnames';
import React from 'react';
import {
  HotkeysHandler,
  HotkeysMode,
} from '../../../../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ModalDialogTitleBar } from './ModalDialogTitleBar.tsx';

export enum ModalDialogSize {
  Narrow = 'dialog--narrow',
}

interface IModalDialogProps {
  readonly buttons?: JSX.Element;
  readonly noDivider?: boolean;
  readonly notificationBar?: JSX.Element;
  readonly onCancel: () => void;
  readonly onInit?: () => void;
  readonly onSubmit?: () => void;
  readonly size?: ModalDialogSize;
  readonly titleBarText?: string;
  readonly children: React.ReactElement;
}

export class ModalDialog extends React.PureComponent<IModalDialogProps> {
  static displayName: 'ModalDialog';

  componentDidMount() {
    this.props.onInit?.();
  }

  private readonly _onEnter = (event: KeyboardEvent): void => {
    event.preventDefault();
    if (this.props.onSubmit) {
      this.props.onSubmit();
    }
  };

  render() {
    const { noDivider, buttons, titleBarText, children, notificationBar, onCancel, size } =
      this.props;

    return (
      <HotkeysHandler
        mode={HotkeysMode.WindowOnly}
        className={classNames('dialog', size)}
        {...getDataUiElementAttribute(DataUiElement.ModalDialog)}
        handlers={{
          onEscape: onCancel,
        }}
      >
        <div className="dialog__inner">
          {titleBarText && (
            <ModalDialogTitleBar noDivider={noDivider} onClose={onCancel} title={titleBarText} />
          )}
          <HotkeysHandler
            className="dialog__body"
            handlers={{
              onEscape: onCancel,
              onEnter: this._onEnter,
            }}
          >
            {children}
          </HotkeysHandler>
          <div
            className={classNames('dialog__bar', {
              'dialog__bar--with-divider': !noDivider,
            })}
            {...getDataUiElementAttribute(DataUiElement.ModalDialogFooter)}
          >
            {notificationBar && <div className="dialog__notification-bar">{notificationBar}</div>}
            {buttons && <div className="dialog__actions-pane">{buttons}</div>}
          </div>
        </div>
      </HotkeysHandler>
    );
  }
}
