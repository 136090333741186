import { Collection } from '@kontent-ai/utils';
import { useMemo } from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { HookFormProps } from '../../../../../_shared/types/hookFormProps.ts';
import { IEntityWebhookFormShape } from '../../../models/IEntityWebhookFormShape.type.ts';
import { EntityWebhookExtendedWorkflowStepFilterSelector as EntityWebhookExtendedWorkflowStepFilterSelectorComponent } from './EntityWebhookExtendedWorkflowStepFilterSelector.tsx';
import { EntityWebhookWorkflowStepFilterSelector as EntityWebhookWorkflowStepFilterSelectorComponent } from './EntityWebhookWorkflowStepFilterSelector.tsx';

type Props = {
  readonly formProps: HookFormProps<IEntityWebhookFormShape>;
};

export const EntityWebhookWorkflowStepChangedFilter = ({ formProps }: Props) => {
  const workflowsById = useSelector((state) => state.data.workflows.byId);
  const workflows = useMemo(() => Collection.getValues(workflowsById), [workflowsById]);

  if (workflows.length === 1) {
    return <EntityWebhookWorkflowStepFilterSelectorComponent formProps={formProps} />;
  }

  if (workflows.length > 1) {
    return <EntityWebhookExtendedWorkflowStepFilterSelectorComponent formProps={formProps} />;
  }

  return null;
};

EntityWebhookWorkflowStepChangedFilter.displayName = 'EntityWebhookWorkflowStepChangedFilter';
