import { AiMessageErrorCode } from './AiMessageErrorCode.ts';
import { CumulatedOperationResult } from './AiServerModels.result.ts';
import { CategorizeItemResponseMessage } from './actions/AiServerModels.categorizeItem.ts';
import { ChangeToneResponseMessage } from './actions/AiServerModels.changeTone.ts';
import { CreateTitleResponseMessage } from './actions/AiServerModels.createTitle.ts';
import { ImproveContentByBriefResponseMessage } from './actions/AiServerModels.improveContentByBrief.ts';
import { InlinePlainTextInstructionResponseMessage } from './actions/AiServerModels.inlinePlainTextInstruction.ts';
import { InlineRichTextInstructionResponseMessage } from './actions/AiServerModels.inlineRichTextInstruction.ts';
import { MakeShorterResponseMessage } from './actions/AiServerModels.makeShorter.ts';
import { SummarizeResponseMessage } from './actions/AiServerModels.summarize.ts';
import { TranslateContentResponseMessage } from './actions/AiServerModels.translateContent.ts';

export type CreateAiActionResponse = {
  readonly operationId: Uuid;
};

export type OperationResponseMessage =
  | CumulatedOperationResponseMessage
  | ErrorOperationResponseMessage;

type CumulatedOperationResponseMessage =
  | CategorizeItemResponseMessage
  | ChangeToneResponseMessage
  | CreateTitleResponseMessage
  | ImproveContentByBriefResponseMessage
  | MakeShorterResponseMessage
  | SummarizeResponseMessage
  | InlinePlainTextInstructionResponseMessage
  | InlineRichTextInstructionResponseMessage
  | TranslateContentResponseMessage;

const cumulatedOperationResponseMessage = 'cumulated-result-v1';
export const isCumulatedOperationResponseMessage = (
  result: OperationResponseMessage,
): result is CumulatedOperationResponseMessage => result.type === cumulatedOperationResponseMessage;

type ResultResponseMessageTemplate<TType, TResult> = {
  readonly type: TType;
  readonly operationId: Uuid;
  readonly result: TResult;
};
export type CumulatedOperationResponseMessageTemplate<TType extends CumulatedOperationResult> =
  ResultResponseMessageTemplate<typeof cumulatedOperationResponseMessage, TType>;

export type PlainOperationError =
  | {
      readonly code: AiMessageErrorCode.UnableWithGeneratedReason;
      readonly message: string;
    }
  | {
      readonly code: Exclude<AiMessageErrorCode, AiMessageErrorCode.UnableWithGeneratedReason>;
      readonly message?: never;
    };

const errorOperationResponseMessageType = 'error-result-v1';
export type ErrorOperationResponseMessage = {
  readonly type: typeof errorOperationResponseMessageType;
  readonly operationId: Uuid;
  readonly error: PlainOperationError;
};
export const isErrorOperationMessage = (
  result: OperationResponseMessage,
): result is ErrorOperationResponseMessage => result.type === errorOperationResponseMessageType;
