import React from 'react';
import { useHistory, useParams } from 'react-router';
import { EnsureValidState } from '../../../../../_shared/components/EnsureValidState.tsx';
import {
  ContentItemRevisionViewerRouteParams,
  ContentItemTimelineItemRoute,
  ContentItemTimelineRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import {
  buildPath,
  parseContentItemIds,
} from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { initCompareRevision } from '../../Revisions/actions/thunkRevisionsActions.ts';

type EnsureComparedRevisionProps = {
  readonly comparedTimelineId: Uuid;
  readonly contentItemId: Uuid;
  readonly variantId: Uuid;
};

export const EnsureComparedRevision: React.FC<
  React.PropsWithChildren<EnsureComparedRevisionProps>
> = ({ children, comparedTimelineId, contentItemId, variantId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams<ContentItemRevisionViewerRouteParams<string>>();

  const isStateEnsured = useSelector((s) => {
    const selectedComparisonRevision = s.contentApp.selectedComparisonRevision;
    return (
      !!selectedComparisonRevision &&
      selectedComparisonRevision.contentItemId.itemId === contentItemId &&
      selectedComparisonRevision.contentItemId.variantId === variantId &&
      selectedComparisonRevision.itemId === comparedTimelineId
    );
  });

  const onRouteEntered = () =>
    dispatch(
      initCompareRevision({
        contentItemId,
        compareItemId: comparedTimelineId,
        history,
      }),
    ).catch(() => {
      const fallbackPath = buildPath<ContentItemTimelineRouteParams<UuidArray>>(
        ContentItemTimelineItemRoute,
        {
          ...params,
          contentItemIds: parseContentItemIds(params.contentItemIds),
        },
      );
      history.push(fallbackPath);
    });

  return (
    <EnsureValidState isStateEnsured={isStateEnsured} onRouteEntered={onRouteEntered}>
      {children}
    </EnsureValidState>
  );
};

EnsureComparedRevision.displayName = 'EnsureComparedRevision';
