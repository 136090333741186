import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import PropTypes from 'prop-types';
import React from 'react';
import { QuickTip } from '../../../../_shared/components/infos/QuickTip.tsx';
import { IconName } from '../../../../_shared/constants/iconEnumGenerated.ts';
import { Button, ButtonSize } from '../../../../_shared/uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../../../_shared/uiComponents/Button/buttonStyle.ts';
import { Icon } from '../../../../_shared/uiComponents/Icon/Icon.tsx';
import { DataUiAction } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { FastSpringWidget } from '../containers/FastSpringWidget.tsx';

export interface ICreditCardInputStateProps {
  readonly baseUrl: string;
  readonly isConnected: boolean;
}

interface ICreditCardInputProps extends ICreditCardInputStateProps {}

const propTypes: PropTypesShape<ICreditCardInputProps> = {
  baseUrl: PropTypes.string.isRequired,
  isConnected: PropTypes.bool.isRequired,
};

const CreditCardInput: React.FC<ICreditCardInputProps> = ({ isConnected, baseUrl }) => {
  const quickTipAdditionalMessage = isConnected
    ? 'The button to manage your payment method will take you to FastSpring where you can edit your payment information.'
    : 'The button will take you to FastSpring where you can enter your payment information.';

  return (
    <div className="credit-card">
      <section className="card__pane">
        <div className="card__content-section">
          <div className="card__title-section">
            <h1 className="card__title">Payment details</h1>
          </div>
          {isConnected ? (
            <div className="card__content">
              <p className="credit-card__message">
                Your subscription is currently linked with the selected payment method.
              </p>
              <div className="credit-card__quick-tip">
                <QuickTip>
                  We use FastSpring for processing payments. {quickTipAdditionalMessage}
                </QuickTip>
              </div>
              <OutwardLink className="u-no-text-decoration" href={`https://${baseUrl}/account`}>
                <Button
                  style={ButtonStyle.Primary}
                  size={ButtonSize.Block}
                  dataUiAction={DataUiAction.ManageCreditCard}
                >
                  Manage your FastSpring account&nbsp;
                  <Icon iconName={IconName.ArrowRightTopSquare} />
                </Button>
              </OutwardLink>
              <FastSpringWidget
                buttonText="Update your payment method"
                customButtonClassName="payment-method__update-button"
                dataUiAction={DataUiAction.SelectCreditCard}
              />
            </div>
          ) : (
            <div className="card__content">
              <p className="credit-card__message">
                Your subscription is not linked with any payment method.
              </p>
              <FastSpringWidget
                buttonText="Select a payment method"
                buttonIsPrimary
                dataUiAction={DataUiAction.SelectCreditCard}
              />
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

CreditCardInput.displayName = 'CreditCardInput';
CreditCardInput.propTypes = propTypes;

export { CreditCardInput };
