import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

interface IRichTextBlockLimitStatusMessageProps {
  readonly blockName: string;
  readonly isAllowed: boolean;
  readonly isValid: boolean;
}

const propTypes: PropTypesShape<IRichTextBlockLimitStatusMessageProps> = {
  blockName: PropTypes.string.isRequired,
  isAllowed: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
};

export const RichTextBlockLimitStatusMessage: React.FC<
  React.PropsWithChildren<IRichTextBlockLimitStatusMessageProps>
> = ({ blockName, children, isAllowed, isValid }): JSX.Element | null => (
  <>
    {isAllowed ? (
      <>{children}</>
    ) : (
      <span className="content-item-element__status-segment">
        <span
          className={classNames('content-item-element__status-item', {
            'content-item-element__status-item--is-invalid': !isValid,
          })}
        >
          {blockName} not allowed
        </span>
      </span>
    )}
  </>
);

RichTextBlockLimitStatusMessage.displayName = 'RichTextBlockLimitStatusMessage';
RichTextBlockLimitStatusMessage.propTypes = propTypes;
