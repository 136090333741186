import { Action } from '../../../../../../../@types/Action.type.ts';
import { INoteFormState } from '../../../../../../contentInventory/content/stores/IContentAppStoreState.ts';
import {
  ContentItemEditing_NoteForm_Changed,
  ContentItemEditing_NoteForm_Reset,
  ContentItemEditing_Sidebar_Section_Cleaned_Up,
} from '../../../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

const initialState: INoteFormState = {
  unsavedValues: null,
};

export const note = (state: INoteFormState = initialState, action: Action): INoteFormState => {
  switch (action.type) {
    case ContentItemEditing_NoteForm_Changed:
      return {
        unsavedValues: action.payload.formValues,
      };

    case ContentItemEditing_NoteForm_Reset:
    case ContentItemEditing_Sidebar_Section_Cleaned_Up:
      return initialState;

    default:
      return state;
  }
};
