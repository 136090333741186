import React from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { newContentItemNameChanged } from '../actions/newContentItemActions.ts';
import { NameInput as NameInputComponent } from '../components/NameInput.tsx';
import { getNewContentItemForm } from '../selectors/newContentItemDialog.ts';

type Props = {
  readonly autoFocus: boolean;
  readonly index: number;
};

export const NameInput: React.FC<Props> = ({ autoFocus, index }) => {
  const name = useSelector((s) => getNewContentItemForm(s).name);

  const dispatch = useDispatch();
  const changeName = (newName: string) => dispatch(newContentItemNameChanged(newName));

  return (
    <NameInputComponent autoFocus={autoFocus} index={index} name={name} onNameChange={changeName} />
  );
};

NameInput.displayName = 'NameInput';
