import PropTypes from 'prop-types';
import React, { TextareaHTMLAttributes } from 'react';
import {
  BaseInputComponent,
  IBaseInputProps,
  baseInputPropTypes,
} from './components/BaseInputComponent.tsx';
import { TextareaControl } from './components/InputControl.tsx';

type TextareaElementAttributes = Pick<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  'autoComplete' | 'maxLength' | 'minLength' | 'name' | 'required' | 'tabIndex'
>;

const textareaElementAttributesPropTypes: PropTypeMap<TextareaElementAttributes> = {
  autoComplete: PropTypes.string,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  name: PropTypes.string,
  required: PropTypes.bool,
  tabIndex: PropTypes.number,
};

export interface ITextareaProps extends IBaseInputProps, TextareaElementAttributes {
  readonly maxRows?: number;
  readonly minRows?: number;
  readonly rows?: number;
}

const textareaPropTypes: PropTypeMap<ITextareaProps> = {
  ...baseInputPropTypes,
  ...textareaElementAttributesPropTypes,
  maxRows: PropTypes.number,
  minRows: PropTypes.number,
  rows: PropTypes.number,
};

export const Textarea = React.forwardRef<HTMLTextAreaElement, ITextareaProps>(
  (
    { maxRows, minRows, rows, tooltipPlacement = 'top', tooltipText, ...textareaProps },
    forwardedRef,
  ) => (
    <BaseInputComponent
      renderControlComponent={(ref, { inputState, ...injectedProps }) => (
        <TextareaControl
          $inputState={inputState}
          ref={ref as React.Ref<HTMLTextAreaElement>}
          minRows={rows ?? minRows}
          maxRows={rows ?? maxRows}
          {...injectedProps}
        />
      )}
      ref={forwardedRef}
      {...{
        tooltipPlacement,
        tooltipText,
        ...textareaProps,
      }}
    />
  ),
);

Textarea.displayName = 'Textarea';
Textarea.propTypes = textareaPropTypes;
