import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DeferAutoScrollCssClass } from '../../../../../../_shared/utils/autoScrollUtils.ts';

export const CommentThreadPositionerClassName = 'comment-thread__positioner';

interface ICommentThreadPositionerProps {
  readonly isNewComment: boolean;
  readonly absoluteOffset: number | null;
  readonly allowAnimation: boolean;
}

const propTypes: PropTypesShape<ICommentThreadPositionerProps> = {
  isNewComment: PropTypes.bool.isRequired,
  absoluteOffset: PropTypes.number,
  allowAnimation: PropTypes.bool,
};

export const CommentThreadPositioner: React.FC<
  React.PropsWithChildren<ICommentThreadPositionerProps>
> = (props) => {
  const { absoluteOffset, allowAnimation, isNewComment, children } = props;
  const hasKnownPosition = absoluteOffset !== null;

  const [positionRendered, setPositionRendered] = useState(false);

  useEffect(() => {
    if (positionRendered) {
      if (!hasKnownPosition) {
        setPositionRendered(false);
      }
    } else if (hasKnownPosition) {
      setPositionRendered(true);
    }
  }, [positionRendered, hasKnownPosition]);

  const style = hasKnownPosition ? { transform: `translateY(${absoluteOffset}px)` } : undefined;

  return (
    <div
      className={classNames(CommentThreadPositionerClassName, {
        // Do not allow scrolling to comment until we are sure it is positioned properly
        [DeferAutoScrollCssClass]: !hasKnownPosition,
        'comment-thread__positioner--has-known-position': hasKnownPosition,
        'comment-thread__positioner--is-animated': allowAnimation && positionRendered,
        'comment-thread__positioner--is-new-comment': isNewComment,
      })}
      style={style}
    >
      {children}
    </div>
  );
};

CommentThreadPositioner.displayName = 'CommentThreadPositioner';
CommentThreadPositioner.propTypes = propTypes;
