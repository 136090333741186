import { InvariantException } from '@kontent-ai/errors';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../../@types/Dispatcher.type.ts';
import { CreateNewVersion } from '../../../../../../_shared/constants/itemActions.ts';
import { ActiveCapabilityType } from '../../../../../../_shared/models/activeCapability.type.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import {
  isArchivedWorkflowStepSelected,
  isDisabled,
  isPublishingStepSelected,
} from '../../../../../../_shared/utils/contentItemUtils.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { getCurrentUserRoleForCollectionForLanguageOrThrow } from '../../../../../../_shared/utils/permissions/getContributorRole.ts';
import { ICommentThreadItemContentModel } from '../../../../models/comments/CommentThreadItem.ts';
import { isSuggestion } from '../../../../models/comments/Suggestion.ts';
import { getElementFromContentTypes } from '../../../../stores/utils/contentItemElementsUtils.ts';
import { isThreadInline, isThreadResolved } from '../../../../utils/commentUtils.ts';
import { commentThreadItemEditingCanceled } from '../../actions/contentItemEditingActions.ts';
import {
  approveSuggestion,
  resolveCommentThread,
  updateCommentThreadItem,
} from '../../actions/thunkContentItemEditingActions.ts';
import {
  CommentThreadItem as CommentThreadItemComponent,
  ICommentThreadItemDispatchProps,
  ICommentThreadItemOwnProps,
  ICommentThreadItemStateProps,
} from '../../components/comments/threadItem/CommentThreadItem.tsx';
import { getCommentThreadTopLevelElementId } from '../../utils/getCommentThreadTopLevelElementId.ts';

interface ICommentThreadItemContainerProps extends ICommentThreadItemOwnProps {
  readonly isThreadRoot: boolean;
  readonly showReferenceForInlineThreads: boolean;
  readonly isLastApprovedSuggestion?: boolean;
}

const getApproveInfo = (
  canApprove: boolean,
  roleName: string,
  isInArchivingStep: boolean,
  isInPublishingStep: boolean,
  isCommentThreadResolved: boolean,
  isInlineThreadWithRemovedContent: boolean | undefined,
): string => {
  if (!canApprove) {
    return `As a ${roleName} you cannot approve suggestions`;
  }

  if (isInlineThreadWithRemovedContent) {
    return 'The content related to this suggestion has been removed.';
  }

  if (isCommentThreadResolved) {
    return 'Reopen comment thread to approve the suggestion';
  }

  if (isInPublishingStep) {
    return `${CreateNewVersion} of this item to approve the suggestion`;
  }

  if (isInArchivingStep) {
    return 'Restore the item to approve the suggestion';
  }

  return 'Approve the suggestion to replace the highlighted text';
};

function mapStateToProps(
  state: IStore,
  ownProps: ICommentThreadItemContainerProps,
): ICommentThreadItemStateProps {
  const {
    commentThread,
    commentThreadItem,
    isInlineThreadWithRemovedContent,
    isThreadRoot,
    showReferenceForInlineThreads,
  } = ownProps;
  const {
    data: { user, users },
    contentApp: {
      editedContentItem,
      editedContentItemVariant,
      editedContentItemVariantElements,
      editorUi: { lockedElements, outdatedElementIds },
      loadedContentItemTypes,
    },
  } = state;

  if (!editedContentItem) {
    throw InvariantException('CommentThreadItem.tsx: "editedContentItem" is not loaded');
  }

  const selectedLanguageId = getSelectedLanguageIdOrThrow(state);
  if (!editedContentItemVariant) {
    throw InvariantException('This is available only in edited content item');
  }

  const currentUserId = user.info.userId;
  const isCommentThreadResolved = isThreadResolved(commentThread);
  const showLocation = showReferenceForInlineThreads && isThreadInline(commentThread);

  const element =
    showLocation && getElementFromContentTypes(commentThread.elementId, loadedContentItemTypes);

  const createdBy = users.usersById.get(commentThreadItem.createdBy) ?? null;

  const suggestionApprovedByUserId =
    isSuggestion(commentThreadItem) && commentThreadItem.suggestionApprovedBy;
  const suggestionApprovedBy = users.usersById.get(suggestionApprovedByUserId || '') ?? null;

  const disabled = isDisabled(false, editedContentItemVariant);
  const canUpdateContent = hasActiveVariantCapabilityForEditedItem(
    ActiveCapabilityType.UpdateContent,
    state,
  );

  const topLevelElementId = getCommentThreadTopLevelElementId(
    editedContentItem,
    editedContentItemVariantElements,
    commentThread,
  );
  const isTopElementLocked = !!lockedElements.find(
    (lockedElement) =>
      !!lockedElement &&
      lockedElement.elementId === topLevelElementId &&
      lockedElement.userId !== currentUserId,
  );
  const isTopElementOutdated =
    !!topLevelElementId && outdatedElementIds.contains(topLevelElementId);

  const canApproveSuggestion =
    !disabled &&
    !isTopElementLocked &&
    !isTopElementOutdated &&
    !isCommentThreadResolved &&
    isSuggestion(commentThreadItem) &&
    canUpdateContent &&
    !isInlineThreadWithRemovedContent;

  const isInArchivingStep = isArchivedWorkflowStepSelected(editedContentItemVariant.assignment);
  const isInPublishingStep = isPublishingStepSelected(editedContentItemVariant.assignment);
  const roleName = getCurrentUserRoleForCollectionForLanguageOrThrow(
    state,
    editedContentItem.collectionId,
    selectedLanguageId,
  ).name;
  const approveInfo = getApproveInfo(
    canUpdateContent,
    roleName,
    isInArchivingStep,
    isInPublishingStep,
    isCommentThreadResolved,
    isInlineThreadWithRemovedContent,
  );

  return {
    canApproveSuggestion,
    createdBy,
    suggestionApprovedBy,
    elementName: element ? element.name : null,
    commentThreadType: commentThread.threadType,
    elementSegment: commentThread.elementSegment,
    allowEdit: currentUserId === commentThreadItem.createdBy && !isCommentThreadResolved,
    allowResolve: isThreadRoot && !isCommentThreadResolved,
    allowUnresolve: isThreadRoot && isCommentThreadResolved,
    shouldDisplaySegmentInfo:
      isThreadRoot && (isThreadResolved(commentThread) || !!isInlineThreadWithRemovedContent),
    approveInfo,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  ownProps: ICommentThreadItemContainerProps,
): ICommentThreadItemDispatchProps {
  const { commentThread, commentThreadItem } = ownProps;

  return {
    onSubmitEdit: (content: ICommentThreadItemContentModel) =>
      dispatch(updateCommentThreadItem(commentThread.id, commentThreadItem.id, content)),
    onCancelEdit: () =>
      dispatch(commentThreadItemEditingCanceled(commentThread.id, commentThreadItem.id)),
    onApproveSuggestion: () => dispatch(approveSuggestion(commentThread, commentThreadItem)),
    onResolveThreadAfterSuggestionWasApproved: () =>
      dispatch(resolveCommentThread(commentThread.id, false, false)),
    onResolve: () => dispatch(resolveCommentThread(commentThread.id, true)),
  };
}

export const CommentThreadItem: React.ComponentType<
  React.PropsWithChildren<ICommentThreadItemContainerProps>
> = connect(mapStateToProps, mapDispatchToProps)(CommentThreadItemComponent);
