import { Button } from '@kontent-ai/component-library/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { pluralizeWithCount } from '../../../../../../_shared/utils/stringUtils.ts';

type IncompleteItemElementsButtonNotificationProps = {
  readonly highlightIncompleteElements: () => void;
  readonly incompleteElementsCount: number;
};

const propTypes: PropTypesShape<IncompleteItemElementsButtonNotificationProps> = {
  highlightIncompleteElements: PropTypes.func.isRequired,
  incompleteElementsCount: PropTypes.number.isRequired,
};

export const IncompleteItemElementsButtonNotification: React.FC<
  IncompleteItemElementsButtonNotificationProps
> = ({ highlightIncompleteElements, incompleteElementsCount }) => (
  <Button onClick={highlightIncompleteElements} size="small" buttonStyle="primary-inverse">
    {pluralizeWithCount('element', incompleteElementsCount)} left to complete
  </Button>
);

IncompleteItemElementsButtonNotification.displayName = 'IncompleteItemElementsButtonNotification';
IncompleteItemElementsButtonNotification.propTypes = propTypes;
