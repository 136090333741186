import { Collection } from '@kontent-ai/utils';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams, useRouteMatch } from 'react-router';
import { modalOpened } from '../../../../../_shared/actions/sharedActions.ts';
import { Loader } from '../../../../../_shared/components/Loader.tsx';
import { AuthorizedSection } from '../../../../../_shared/components/routing/AuthorizedSection.tsx';
import { ModalDialogType } from '../../../../../_shared/constants/modalDialogType.ts';
import {
  ApiKeyDetailAccessDeniedRouteSegment,
  ApiKeyDetailRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ApiKeyType } from '../../../../../_shared/models/ApiKeyType.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { getCurrentProjectContainer } from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { isProjectManagerInAnyEnvironment } from '../../../root/selectors/isProjectManagerInAnyEnvironment.ts';
import { apiKeyDetailsAlertDismissed } from '../../actions/apiKeysActions.ts';
import { initApiKey, updateApiKey } from '../../actions/thunkApiKeysActions.ts';
import { ApiKey, emptyApiKey } from '../../models/ApiKey.ts';
import { ApiKeyActionStatus } from '../../reducers/IApiKeysAppStoreState.type.ts';
import { getHiddenEnvironmentOption } from '../../selectors/getHiddenEnvironmentOption.ts';
import {
  AllEnvironmentsTagId,
  getAllEnvironmentOptions,
} from '../../selectors/getSelectedEnvironmentOptions.ts';
import { getMapiKeyCapabilitiesFromPermissions } from '../../utils/getMapiKeyCapabilitiesFromPermissions.ts';
import { MapiKeyFormShape } from '../../validation/mapiKeyValidationConfig.ts';
import { MapiKeyDetailForm } from './MapiKeyDetailForm.tsx';

export const MapiKeyDetail: React.FC = () => {
  const { tokenSeedId } = useParams<ApiKeyDetailRouteParams>();
  const { url } = useRouteMatch();

  const loadingStatus = useSelector((state) => state.apiKeysApp.detailLoadingStatus);
  const actionStatus = useSelector((state) => state.apiKeysApp.detailActionStatus);
  const apiKey = useSelector((state) => state.data.apiKeys.keyDetail);
  const isProjectManager = useSelector(isProjectManagerInAnyEnvironment);
  const currentProjectContainer = useSelector(getCurrentProjectContainer);
  const allEnvironmentOptions = useSelector((state) =>
    getAllEnvironmentOptions(state, ApiKeyType.MAPI),
  );
  const hiddenEnvironmentOption = useSelector(getHiddenEnvironmentOption);
  const projectContainerActiveUsersById = useSelector(
    (state) => state.data.projectContainerActiveUsers.byId,
  );

  const projectContainerActiveUsers = useMemo(
    () => Collection.getValues(projectContainerActiveUsersById),
    [projectContainerActiveUsersById],
  );

  const dispatch = useDispatch();
  const onRegenerate = () => dispatch(modalOpened(ModalDialogType.ApiKeyRegenerationDialog));

  const onSave = useCallback(
    (mapiKeyFormShape: MapiKeyFormShape) => {
      const hasAccessToAllEnvironments =
        mapiKeyFormShape.environments.includes(AllEnvironmentsTagId);

      const updatedApiKey: ApiKey = {
        ...emptyApiKey,
        apiKey: apiKey.apiKey,
        environments: hasAccessToAllEnvironments ? [] : mapiKeyFormShape.environments,
        expiresAt: apiKey.expiresAt,
        hasAccessToAllEnvironments,
        mapiKeyCapabilities: getMapiKeyCapabilitiesFromPermissions(mapiKeyFormShape.permissions),
        name: mapiKeyFormShape.name,
        sharedWithUsers: mapiKeyFormShape.sharedWithUsers,
        tokenSeedId: apiKey.tokenSeedId,
        type: apiKey.type,
      };

      return dispatch(updateApiKey(updatedApiKey));
    },
    [apiKey.apiKey, apiKey.expiresAt, apiKey.tokenSeedId, apiKey.type],
  );

  useEffect(() => {
    dispatch(initApiKey(tokenSeedId, url));
  }, [tokenSeedId, url]);

  const acceptableApiKeyTypes = [ApiKeyType.MAPI, emptyApiKey.type];
  if (loadingStatus !== LoadingStatus.Loaded || !acceptableApiKeyTypes.includes(apiKey.type)) {
    return <Loader />;
  }

  const isLoadingFailed =
    loadingStatus === LoadingStatus.Loaded && actionStatus === ApiKeyActionStatus.LoadingFailed;

  const allOptions = hiddenEnvironmentOption
    ? [...allEnvironmentOptions, hiddenEnvironmentOption]
    : allEnvironmentOptions;

  return (
    <AuthorizedSection
      appName={ApiKeyDetailAccessDeniedRouteSegment}
      isAuthorized={!isLoadingFailed}
      projectId={currentProjectContainer.masterEnvironmentId}
      projectContainerId={currentProjectContainer.projectContainerId}
    >
      <MapiKeyDetailForm
        allEnvironmentOptions={allOptions}
        apiKey={apiKey}
        apiKeyActionStatus={actionStatus}
        isProjectManager={isProjectManager}
        projectContainerActiveUsers={projectContainerActiveUsers}
        onAlertDismiss={() => dispatch(apiKeyDetailsAlertDismissed())}
        onRegenerate={isProjectManager ? onRegenerate : undefined}
        onSave={onSave}
      />
    </AuthorizedSection>
  );
};

MapiKeyDetail.displayName = 'MapiKeyDetailWrapper';
