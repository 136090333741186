import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { Action } from '../../@types/Action.type.ts';
import { ThunkPromise } from '../../@types/Dispatcher.type.ts';
import { IEnsureSelectedVariantParams } from '../actions/thunks/ensureSelectedVariantInRoute.ts';
import { useDispatch } from '../hooks/useDispatch.ts';
import { useSelector } from '../hooks/useSelector.ts';
import { IStore } from '../stores/IStore.type.ts';
import { EnsureValidState } from './EnsureValidState.tsx';

interface IEnsureSelectedVariantIdProps {
  readonly children: React.ReactNode;
  readonly currentRouteVariantId: Uuid | undefined;
  readonly getSelectedLanguageIdFromStore: (state: IStore) => Uuid | undefined | null;
  readonly ensureSelectedVariant: (params: IEnsureSelectedVariantParams) => ThunkPromise;
  readonly localizedRouteLeft: () => Action;
}

export const EnsureSelectedVariantIdInRoute: React.FC<IEnsureSelectedVariantIdProps> = ({
  children,
  currentRouteVariantId,
  getSelectedLanguageIdFromStore,
  ensureSelectedVariant,
  localizedRouteLeft,
}) => {
  const selectedLanguageId = useSelector(getSelectedLanguageIdFromStore);
  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();

  const onRouteEntered = () =>
    dispatch(
      ensureSelectedVariant({
        history,
        location,
        currentRouteVariantId,
      }),
    );

  const onRouteLeft = () => dispatch(localizedRouteLeft());

  return (
    <EnsureValidState
      isStateEnsured={!!selectedLanguageId && selectedLanguageId === currentRouteVariantId}
      onRouteEntered={onRouteEntered}
      onRouteLeft={onRouteLeft}
    >
      {children}
    </EnsureValidState>
  );
};

EnsureSelectedVariantIdInRoute.displayName = 'EnsureSelectedVariantIdInRoute';
