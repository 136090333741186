import { Clipboard } from '@kontent-ai/component-library/Clipboard';
import { InputState } from '@kontent-ai/component-library/Input';
import PropTypes from 'prop-types';
import React, { FocusEventHandler } from 'react';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly inputErrorMessage?: string;
  readonly inputIsDisabled?: boolean;
  readonly inputMaxLength?: number;
  readonly inputName: string;
  readonly inputText: string | null;
  readonly labelText?: string;
  readonly onCopied?: () => void;
  readonly onCopyCompleted?: () => void;
  readonly onInputBlur?: FocusEventHandler<HTMLInputElement>;
  readonly onInputChange?: (value: string) => void;
};

const propTypes: PropTypeMap<Props> = {
  inputErrorMessage: PropTypes.string,
  inputIsDisabled: PropTypes.bool,
  inputMaxLength: PropTypes.number,
  inputName: PropTypes.string.isRequired,
  inputText: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  onCopied: PropTypes.func,
  onCopyCompleted: PropTypes.func,
  onInputBlur: PropTypes.func,
  onInputChange: PropTypes.func,
};

const getInputState = (
  isEditable: boolean,
  inputErrorMessage?: string,
  isDisabled?: boolean,
): InputState => {
  if (isDisabled) {
    return InputState.Disabled;
  }

  if (inputErrorMessage) {
    return InputState.Alert;
  }

  if (isEditable) {
    return InputState.Default;
  }

  return InputState.ReadOnly;
};

export const ClipboardFormField = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      inputErrorMessage,
      inputIsDisabled,
      inputMaxLength,
      inputName,
      inputText,
      labelText,
      onCopied,
      onCopyCompleted,
      onInputBlur,
      onInputChange,
    },
    ref,
  ) => {
    return (
      <div className="form__group form__group--clipboard form__group--medium-width">
        <Clipboard
          ref={ref}
          caption={inputErrorMessage}
          inputState={getInputState(!!onInputChange, inputErrorMessage, inputIsDisabled)}
          label={labelText}
          maxLength={inputMaxLength}
          name={inputName}
          value={inputText || ''}
          onCopyCompleted={onCopyCompleted}
          onSuccess={onCopied}
          onInputBlur={onInputBlur}
          onInputChange={onInputChange ? (e) => onInputChange(e.target.value) : undefined}
          onInputFocus={(e) => e.target?.select()}
          {...getDataUiInputAttribute(DataUiInput.Text)}
        />
      </div>
    );
  },
);

ClipboardFormField.displayName = 'ClipboardFormField';
ClipboardFormField.propTypes = propTypes;
