import { isAbortError, isXMLHttpRequest } from '@kontent-ai/errors';
import { History } from 'history';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import {
  AccessDeniedToAssetRoute,
  AssetRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import { getBreadcrumbsOrigin } from '../../../../../_shared/selectors/breadcrumbs/getBreadcrumbsOrigin.ts';
import { getPreviouslyOpenAssetFolderPath } from '../../../../../_shared/utils/breadcrumbs/getPreviouslyOpenAssetFolderPath.ts';
import { redirectToDefaultRoute } from '../../../../../_shared/utils/routing/redirectToDefaultRoute.ts';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IAsset } from '../../../../../data/models/assets/Asset.ts';
import { IAssetService } from '../../../content/features/Asset/services/assetService.ts';
import { RootFolderId } from '../../constants/assetFolderConstants.ts';
import {
  AssetLibrary_AssetEditor_InitFinished,
  AssetLibrary_AssetEditor_InitStarted,
} from '../../constants/assetLibraryActionTypes.ts';

interface IInitializeAssetEditingDependencies {
  readonly assetService: IAssetService;
  readonly loadAssetUsage: (assetId: Uuid, abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadAssetType: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadContentTypes: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadLanguages: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadTaxonomyGroups: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadUsers: (abortSignal?: AbortSignal) => ThunkPromise;
}

interface IActionParams {
  readonly abortSignal?: AbortSignal;
  readonly assetId: Uuid;
  readonly history: History;
}

const initializeAssetEditingInternal = {
  type: AssetLibrary_AssetEditor_InitStarted,
} as const;

const finishAssetEditingInitialization = (asset: IAsset) =>
  ({
    type: AssetLibrary_AssetEditor_InitFinished,
    payload: {
      asset,
    },
  }) as const;

export type InitializeAssetEditingActionsType =
  | typeof initializeAssetEditingInternal
  | ReturnType<typeof finishAssetEditingInitialization>;

export const createInitializeAssetEditingAction =
  (deps: IInitializeAssetEditingDependencies) =>
  ({ abortSignal, assetId, history }: IActionParams): ThunkPromise =>
  async (dispatch, getState) => {
    const state = getState();
    const {
      sharedApp: { currentProjectId },
    } = state;

    dispatch(initializeAssetEditingInternal);

    try {
      const asset = await deps.assetService.getAsset(assetId, abortSignal);

      if (asset.archived) {
        const breadcrumbsOrigin = getBreadcrumbsOrigin(state);
        const assetsPath = getPreviouslyOpenAssetFolderPath(
          breadcrumbsOrigin,
          currentProjectId,
          RootFolderId,
        );

        history.push(assetsPath);
        return;
      }

      await Promise.all([
        dispatch(deps.loadLanguages(abortSignal)),
        dispatch(deps.loadAssetUsage(assetId, abortSignal)),
        dispatch(deps.loadAssetType(abortSignal)),
        dispatch(deps.loadContentTypes(abortSignal)),
        dispatch(deps.loadTaxonomyGroups(abortSignal)),
        dispatch(deps.loadUsers(abortSignal)),
      ]);

      dispatch(finishAssetEditingInitialization(asset));
    } catch (error) {
      if (isXMLHttpRequest(error) && error.status === 403) {
        history.push(
          buildPath<AssetRouteParams>(AccessDeniedToAssetRoute, {
            assetId,
            projectId: currentProjectId,
          }),
        );
        return;
      }

      if (!isAbortError(error)) {
        redirectToDefaultRoute({
          currentProjectId,
          history,
          error,
        });
      }

      throw error;
    }
  };
