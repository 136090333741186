import { identity } from '@kontent-ai/utils';
import classNames from 'classnames';
import React from 'react';
import { ElementType } from '../../../../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  ILinkedItemsTypeElement,
  LinkedItemsTypeElementPropTypes,
} from '../../../../../../contentInventory/content/models/contentTypeElements/LinkedItemsTypeElement.ts';
import {
  ILinkedItemsElement,
  LinkedItemsElementPropTypes,
} from '../../../../../models/contentItemElements/modularItems/ILinkedItemsElement.ts';
import { ReadonlyEmptyElementPlaceholder } from '../../../../ContentItemEditing/models/ReadonlyEmptyElementPlaceholder.ts';
import { useRenderPlaceholderText } from '../../../hooks/useRenderPlaceholderText.ts';
import { IRevisionDiffElementProps } from '../../../types/IRevisionDiffElementProps.type.ts';
import { IMergedListItem, getListDiff } from '../../../utils/getListDiff.ts';
import { SimpleLinkedItems } from './SimpleLinkedItems.tsx';

const getLinkedItemClassName = (linkedItemsWithDiffMetadata: IMergedListItem<Uuid>): string =>
  classNames({
    'bar-item__bar--is-added': linkedItemsWithDiffMetadata.added,
    'bar-item__bar--is-removed': linkedItemsWithDiffMetadata.removed,
  });

interface ILinkedItemsDiffProps extends IRevisionDiffElementProps<ILinkedItemsElement> {
  readonly typeElement: ILinkedItemsTypeElement;
}

const propTypes: PropTypeMap<ILinkedItemsDiffProps> = {
  elementData: LinkedItemsElementPropTypes.isRequired,
  originalElementData: LinkedItemsElementPropTypes,
  typeElement: LinkedItemsTypeElementPropTypes.isRequired,
};

export const LinkedItemsDiff: React.FC<ILinkedItemsDiffProps> = ({
  elementData,
  originalElementData,
  typeElement,
}) => {
  const placeholderText = useRenderPlaceholderText(
    typeElement.type === ElementType.Subpages
      ? ReadonlyEmptyElementPlaceholder.SubpageElement
      : ReadonlyEmptyElementPlaceholder.LinkedItem,
    originalElementData,
    typeElement,
  );
  const currentItems = elementData.value.toArray();
  const originalItems = originalElementData?.value.toArray();

  if (!originalItems) {
    const contentItemIds = currentItems.map((id) => ({ value: id }));

    if (contentItemIds.length === 0) {
      return <div className="content-item-element__placeholder">{placeholderText}</div>;
    }
    return <SimpleLinkedItems linkedItemIds={contentItemIds} typeElement={typeElement} />;
  }

  const diff = getListDiff(originalItems, currentItems, identity);

  return (
    <SimpleLinkedItems
      linkedItemIds={diff}
      getLinkedItemClassName={getLinkedItemClassName}
      typeElement={typeElement}
    />
  );
};

LinkedItemsDiff.displayName = 'LinkedItemsDiff';
LinkedItemsDiff.propTypes = propTypes;
