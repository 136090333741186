import classNames from 'classnames';
import React from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../utils/dataAttributes/DataUiAttributes.ts';

export enum LoaderType {
  Narrow = 'loader__wrapper--narrow',
  MinimumHeight = 'loader__wrapper--minimum',
  Sidebar = 'loader__wrapper--sidebar',
}

interface ILoaderProps {
  readonly className?: string;
  readonly type?: LoaderType;
  readonly renderContent?: () => React.ReactNode;
  readonly text?: string;
}

export const Loader: React.FC<ILoaderProps> = ({ className, type, renderContent, text }) => (
  <div
    className={classNames('loader__wrapper', type, className)}
    {...getDataUiElementAttribute(DataUiElement.Loader)}
  >
    {text && <div className="loader__text">{text}</div>}
    {renderContent !== undefined ? renderContent() : null}
    <div className="loader">
      <div className="loader__bubble" />
    </div>
  </div>
);

Loader.displayName = 'Loader';
