import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { getCurrentUserId } from '../../../../../../../_shared/selectors/getCurrentUser.ts';
import { getSelectedLanguageId } from '../../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { isUuid } from '../../../../../../../_shared/utils/validation/typeValidators.ts';
import {
  getCurrentProject,
  getCurrentProjectId,
} from '../../../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { variantTranslationStarted } from '../../../actions/contentItemEditingActions.ts';
import { LeafActionFormProps } from '../../../components/editingActions/EditingLeafActionForm.tsx';
import { TranslateFromLanguageOptions as TranslateFromLanguageOptionsComponent } from '../../../components/editingActions/leafActionForms/TranslateFromLanguageOptions.tsx';
import { getLanguageOptionsMemoized } from '../../selectors/copyContentFromVariantMenuOptions.ts';

export const TranslateFromLanguageOptions: React.FC<LeafActionFormProps> = () => {
  const userId = useSelector(getCurrentUserId);
  const dispatch = useDispatch();
  const menuOptions = useSelector((s) => {
    const selectedLanguageId = getSelectedLanguageId(s);
    const collectionId = s.contentApp.editedContentItem?.collectionId ?? null;

    return selectedLanguageId
      ? getLanguageOptionsMemoized(
          s.data.languages,
          selectedLanguageId,
          s.contentApp.contentItemVariants,
          s.data.user,
          getCurrentProject(s).projectId,
          collectionId,
        )
      : [];
  });

  const contentItemId = useSelector((s) => s.contentApp.editedContentItemVariant?.id);
  const projectEnvironmentId = useSelector(getCurrentProjectId);

  if (!contentItemId || !isUuid(projectEnvironmentId)) {
    return null;
  }

  return (
    <TranslateFromLanguageOptionsComponent
      contentItemId={contentItemId}
      menuOptions={menuOptions}
      projectEnvironmentId={projectEnvironmentId}
      userId={userId}
      onTranslationStarted={() =>
        dispatch(
          variantTranslationStarted(
            projectEnvironmentId,
            contentItemId.itemId,
            contentItemId.variantId,
          ),
        )
      }
    />
  );
};

TranslateFromLanguageOptions.displayName = 'TranslateFromLanguageOptions';
