import { useMemo } from 'react';
import { useEditorApi } from '../../editorCore/hooks/useEditorApi.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { EditorPlugin } from '../../editorCore/types/Editor.plugins.type.ts';
import { withDisplayName } from '../../editorCore/utils/withDisplayName.ts';
import { EditorEntityApi } from './api/EditorEntityApi.type.ts';
import { editorEntityApi } from './api/editorEntityApi.ts';

export type EntityApiPlugin = EditorPlugin<None, None, EditorEntityApi>;

export const useEntityApi: PluginCreator<EntityApiPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('EntityApiPlugin', {
        ComposedEditor: (props) => {
          const { getApiMethods } = useEditorApi<EntityApiPlugin>(editorEntityApi);

          return useEditorWithPlugin<EntityApiPlugin>(baseEditor, props, { getApiMethods });
        },
      }),
    [baseEditor],
  );
