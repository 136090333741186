import { memoize } from '@kontent-ai/memoization';
import { forwardRef } from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ICompiledContentType } from '../../../content/models/CompiledContentType.ts';
import { ITaxonomyTypeElement } from '../../../content/models/contentTypeElements/TaxonomyTypeElement.ts';
import { isTaxonomyTypeElement } from '../../../content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { AssignTermsAssetsDialog as AssignTermsAssetsDialogComponent } from '../../components/AssetListing/AssignTermsAssetsDialog.tsx';
import { useAssignTaxonomyTermsToAssets } from '../../hooks/useAssignTaxonomyTermsToAssets.ts';

const getTaxonomyElements = memoize.maxOne(
  (assetType: ICompiledContentType | null): ReadonlyArray<ITaxonomyTypeElement> =>
    assetType?.contentElements.filter(isTaxonomyTypeElement) ?? [],
);

type Props = {
  readonly closeDialog: () => void;
  readonly deselectAssets: (assetIds: ReadonlySet<Uuid>) => void;
  readonly isOpen: boolean;
  readonly selectedAssets: ReadonlySet<Uuid>;
};

// TODO: remove forwardRef?
export const AssignTermsAssetsDialog = forwardRef<HTMLDivElement, Props>(
  ({ closeDialog, deselectAssets, isOpen, selectedAssets }, ref) => {
    const taxonomyElements = useSelector((s) =>
      getTaxonomyElements(s.data.assetTypes.defaultAssetType),
    );

    const assignTaxonomyTermsToAssets = useAssignTaxonomyTermsToAssets({
      deselectAssets,
      selectedAssets,
    });

    return (
      <AssignTermsAssetsDialogComponent
        isOpen={isOpen}
        numberOfSelectedAssets={selectedAssets.size}
        onAssignTaxonomies={assignTaxonomyTermsToAssets}
        onClose={closeDialog}
        ref={ref}
        taxonomyElements={taxonomyElements}
      />
    );
  },
);
