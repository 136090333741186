import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { StatusBarActions } from '../../../../../_shared/components/StatusBar/StatusBarActions.tsx';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { createContentType } from '../../actions/thunkContentTypesActions.ts';

export const ContentTypeCreatorToolbarActions: React.FC = () => {
  const dispatch = useDispatch();
  const codename = useSelector((s) => s.contentModelsApp.typeEditor.editedType.codename);
  const isBeingSaved = useSelector((s) => s.contentModelsApp.typeEditor.typeIsBeingSaved);
  const isLoading = useSelector(
    (s) => s.contentModelsApp.typeEditor.loadingStatus !== LoadingStatus.Loaded,
  );

  const history = useHistory();
  const onSave = useCallback(() => dispatch(createContentType(history)), [history]);

  return (
    <StatusBarActions
      codename={codename}
      isBeingSaved={isBeingSaved}
      isLoading={isLoading}
      onSave={onSave}
    />
  );
};

ContentTypeCreatorToolbarActions.displayName = 'ContentTypeCreatorToolbarActions';
