import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { StatusBarActions } from '../../../../../_shared/components/StatusBar/StatusBarActions.tsx';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { createContentTypeSnippet } from '../../actions/thunkSnippetsActions.ts';

export const ContentTypeSnippetCreatorToolbarActions: React.FC = () => {
  const dispatch = useDispatch();
  const codename = useSelector(
    (s) => s.contentModelsApp.snippets.editor.editedContentTypeSnippet.codename,
  );
  const isBeingSaved = useSelector(
    (s) => s.contentModelsApp.snippets.editor.contentTypeSnippetIsBeingSaved,
  );
  const isLoading = useSelector(
    (s) =>
      s.contentModelsApp.snippets.editor.contentTypeSnippetEditingLoadingStatus !==
      LoadingStatus.Loaded,
  );

  const history = useHistory();
  const onSave = useCallback(() => dispatch(createContentTypeSnippet(history)), [history]);

  return (
    <StatusBarActions
      codename={codename}
      isBeingSaved={isBeingSaved}
      isLoading={isLoading}
      onSave={onSave}
    />
  );
};

ContentTypeSnippetCreatorToolbarActions.displayName = 'ContentTypeSnippetCreatorToolbarActions';
