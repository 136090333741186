import { Button } from '@kontent-ai/component-library/Button';
import React, { useEffect } from 'react';
import { NotificationBarSuccess } from '../../../../component-library/components/NotificationBar/NotificationBar.tsx';

export interface IOnboardingNotificationBarStateProps {
  readonly message: React.ReactNode;
  readonly buttonMessage: string;
}

export interface IOnboardingNotificationBarDispatchProps {
  readonly onButtonClick: () => void;
  readonly onClose: () => void;
  readonly onInit?: () => void;
}

export interface IOnboardingNotificationBarProps
  extends IOnboardingNotificationBarStateProps,
    IOnboardingNotificationBarDispatchProps {}

type OnboardingNotificationBarProps = {
  readonly buttonMessage: string;
  readonly message: React.ReactNode;
  readonly onButtonClick: () => void;
  readonly onClose: () => void;
  readonly onInit?: () => void;
};

export const OnboardingNotificationBar: React.FC<OnboardingNotificationBarProps> = ({
  buttonMessage,
  message,
  onButtonClick,
  onClose,
  onInit,
}) => {
  useEffect(() => {
    onInit?.();
  }, [onInit]);

  return (
    <div className="onboarding-notification-bar__pane">
      <NotificationBarSuccess
        actions={{
          alignment: 'left',
          renderActions: () => (
            <Button size="small" buttonStyle="primary-inverse" onClick={onButtonClick}>
              {buttonMessage}
            </Button>
          ),
        }}
        onDismiss={onClose}
        {...{ className: 'onboarding-notification-bar' }}
      >
        <span>{message}</span>
      </NotificationBarSuccess>
    </div>
  );
};

OnboardingNotificationBar.displayName = 'OnboardingNotificationBar';
