import PropTypes from 'prop-types';
import { PropsWithChildren, RefObject } from 'react';
import { useOnClickOutside } from '../hooks/useOnClickOutside.ts';
import { forwardedRefProps } from '../utils/forwardedRefProps.tsx';

type Props<TRef> = {
  readonly observedRefs: OneOrMore<RefObject<TRef>>;
  readonly onClickOutside: (event: MouseEvent) => void;
};

export const HandleClickOutside = <TRef extends HTMLElement>({
  children,
  observedRefs,
  onClickOutside,
}: PropsWithChildren<Props<TRef>>) => {
  useOnClickOutside<TRef>(observedRefs, onClickOutside);

  return <>{children}</>;
};

HandleClickOutside.propTypes = {
  observedRefs: PropTypes.oneOfType([
    forwardedRefProps.forwardedRef,
    PropTypes.arrayOf(forwardedRefProps.forwardedRef),
  ]).isRequired,
  onClickOutside: PropTypes.func.isRequired,
};
HandleClickOutside.displayName = 'HandleClickOutside';
