import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IApiKeysRepository } from '../../../../../repositories/interfaces/IApiKeysRepository.type.ts';
import {
  ApiKey,
  createApiKeyFromServerModel,
  emptyApiKey,
} from '../../../../projectSettings/apiKeys/models/ApiKey.ts';
import {
  SubscriptionApiKey_Loading_Finished,
  SubscriptionApiKey_Loading_Started,
} from '../../../shared/constants/subscriptionManagementActionTypes.ts';

const started = () =>
  ({
    type: SubscriptionApiKey_Loading_Started,
  }) as const;

const succeed = (apiKey: ApiKey) =>
  ({
    type: SubscriptionApiKey_Loading_Finished,
    payload: { apiKey },
  }) as const;

export type LoadSubscriptionApiKeyActionsType = ReturnType<typeof started | typeof succeed>;

interface ILoadSubscriptionApiKeyDependencies {
  readonly apiKeysRepository: Pick<IApiKeysRepository, 'getSubscriptionApiKeys'>;
}

export const initSubscriptionApiKeyActionCreator =
  ({ apiKeysRepository }: ILoadSubscriptionApiKeyDependencies) =>
  (
    subscriptionId: Uuid,
    isApiAvailable: boolean,
    isSubscriptionAdmin: boolean,
    abortSignal?: AbortSignal,
  ): ThunkPromise =>
  async (dispatch) => {
    if (!isApiAvailable || !isSubscriptionAdmin) {
      dispatch(succeed(emptyApiKey));
    }

    dispatch(started());

    const serverModel = await apiKeysRepository.getSubscriptionApiKeys(subscriptionId, abortSignal);

    if (!serverModel.length || !serverModel[0]) {
      dispatch(succeed(emptyApiKey));
    } else {
      const apiKey = createApiKeyFromServerModel(serverModel[0]);

      dispatch(succeed(apiKey));
    }
  };
