import PropTypes from 'prop-types';
import { Validator } from 'react';
import { Control, FieldValues, UseFormReturn } from 'react-hook-form';

/**
 * Using `watch` and `formState` down in the hierarchy makes the root component to re-render on their changes as well.
 * There are `useWatch` and `useFormState` hooks for when you need to access these in the child components.
 *
 * `register` should be reserved only for connecting fields within the root component.
 * All custom components that act as form fields should use `useController` as that encapsulates everything needed and simplifies working with errors.
 *
 * If you still have a need to modify what is exposed here, consult RadoslavK or other front end specialist.
 */
export type HookFormProps<TFormShape extends FieldValues> = Pick<
  UseFormReturn<TFormShape>,
  'control' | 'setFocus' | 'setValue'
>;

const propTypes: PropTypeMap<HookFormProps<any>> = {
  control: PropTypes.object.isRequired as Validator<Control<any>>,
  setFocus: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};

export const hookFormProps = PropTypes.shape(propTypes);
