import { usePrevious } from '@kontent-ai/hooks';
import React, { useEffect } from 'react';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { showCommentThreadsOnRemovedContentOnboarding } from '../../actions/thunkContentItemEditingActions.ts';
import { getCommentsOnRemovedContent } from '../../selectors/inlineCommentSelectors.ts';

export const CommentThreadsOnRemovedContentOnboarding: React.FC = () => {
  const dispatch = useDispatch();

  const commentThreadsOnRemovedContentOnboardingShown = useSelector(
    (state) => !!state.sharedApp.userProperties.commentThreadsOnRemovedContentOnboardingShownAt,
  );
  const commentThreadsOnRemovedContent = useSelector((state) =>
    commentThreadsOnRemovedContentOnboardingShown ? 0 : getCommentsOnRemovedContent(state).length,
  );
  const previousCommentThreadsOnRemovedContent = usePrevious(commentThreadsOnRemovedContent);

  useEffect(() => {
    if (commentThreadsOnRemovedContent > previousCommentThreadsOnRemovedContent) {
      dispatch(showCommentThreadsOnRemovedContentOnboarding());
    }
  }, [commentThreadsOnRemovedContent, previousCommentThreadsOnRemovedContent]);

  return null;
};

CommentThreadsOnRemovedContentOnboarding.displayName = 'CommentThreadsOnRemovedContentOnboarding';
