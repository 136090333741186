import PropTypes, { ValidationMap } from 'prop-types';
import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  emptyEditorState,
  emptyJsonValue,
} from '../../../richText/utils/general/editorEmptyValues.ts';
import {
  BaseTextItemElementValidationMap,
  IBaseTextItemElement,
  IBaseTextItemElementValue,
} from './IBaseTextItemElement.ts';

export const EmptyTextItemElementValue: IBaseTextItemElementValue = {
  _editorState: emptyEditorState,
  jsonValue: emptyJsonValue,
};

export const EmptyTextItemElement: ITextItemElement = {
  elementId: '',
  lastModifiedAt: null,
  lastModifiedBy: null,
  type: ElementType.Text,
  ...EmptyTextItemElementValue,
};

export interface ITextItemElement extends IBaseTextItemElement {}

export const TextItemElementPropTypes = PropTypes.shape<ValidationMap<ITextItemElement>>({
  ...BaseTextItemElementValidationMap,
});

export const newTextItemElement = (params: Partial<ITextItemElement>): ITextItemElement => ({
  ...EmptyTextItemElement,
  ...params,
});
