import PropTypes from 'prop-types';
import React from 'react';
import { Guidelines as GuidelinesElement } from '../../../../../../_shared/containers/Guidelines.tsx';
import {
  GuidelinesTypeElementPropTypesShape,
  IGuidelinesTypeElement,
} from '../../../../../contentInventory/content/models/contentTypeElements/GuidelinesTypeElement.ts';
import { ItemElement } from '../../containers/elements/ItemElement.tsx';

interface IGuidelinesProps {
  readonly disabled: boolean;
  readonly typeElement: IGuidelinesTypeElement;
}

const propTypes: PropTypesShape<IGuidelinesProps> = {
  disabled: PropTypes.bool.isRequired,
  typeElement: GuidelinesTypeElementPropTypesShape.isRequired,
};

const Guidelines: React.FC<IGuidelinesProps> = ({ disabled, typeElement }) => (
  <ItemElement typeElement={typeElement} disabled={disabled}>
    <GuidelinesElement typeElement={typeElement} />
  </ItemElement>
);

Guidelines.displayName = 'Guidelines';
Guidelines.propTypes = propTypes;

export { Guidelines };
