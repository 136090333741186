import { InvariantException } from '@kontent-ai/errors';
import { Dispatch, GetState, ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { isCreatingRenditionsEnabled } from '../../../../../../_shared/selectors/enhancedAssetManagement.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { emptyValidationResult } from '../../../../../../_shared/utils/validation/ValidationResult.ts';
import { getDefaultLanguage } from '../../../../../../data/reducers/languages/selectors/getLanguages.ts';
import { getCurrentProjectPlan } from '../../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { isEditableElement } from '../../../../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { ICompiledContentItemElementData } from '../../../../models/contentItemElements/ICompiledContentItemElement.ts';
import { isMultipleChoiceElement } from '../../../../models/contentItemElements/compiledItemElementTypeGuards.ts';
import { getItemElementValidationResult } from '../../../../utils/getItemElementValidationResult.ts';
import { getNonLocalizableElementValidationResult } from '../../../../utils/getItemValidationResult.ts';
import { contentItemElementRefreshFinished } from '../contentItemEditingActions.ts';

type Deps = {
  revalidateEditedContentItemVariantElements: () => ThunkFunction;
};

export const createFinishContentItemElementRefreshAction =
  ({ revalidateEditedContentItemVariantElements }: Deps) =>
  (elementData: ICompiledContentItemElementData): ThunkFunction =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const {
      data: { assets, assetRenditions, languages, listingContentItems, taxonomyGroups },
      contentApp: { editedContentItem, loadedContentItemTypes },
    } = state;

    const elementId = elementData.elementId;

    const selectedLanguageId = getSelectedLanguageIdOrThrow(state);
    if (!editedContentItem) {
      throw InvariantException('finishContentItemElementRefresh: "editedContentItem" is null');
    }

    const editedContentItemType = loadedContentItemTypes.get(
      editedContentItem.editedContentItemTypeId,
    );
    if (!editedContentItemType) {
      throw InvariantException('finishContentItemElementRefresh: "editedContentItemType" is null');
    }

    const areAssetRenditionsEnabled = isCreatingRenditionsEnabled(getCurrentProjectPlan(state));
    const typeElement = editedContentItemType.contentElements.find(
      (element) => element.elementId === elementId,
    );

    let validationResult = emptyValidationResult;
    if (isEditableElement(typeElement)) {
      const originalValidationResult = getItemElementValidationResult(
        typeElement,
        elementData,
        loadedContentItemTypes,
        assets.byId,
        assetRenditions.byId,
        taxonomyGroups.byId,
        listingContentItems.byId,
        selectedLanguageId,
        languages.byId,
        areAssetRenditionsEnabled,
      );

      validationResult = getNonLocalizableElementValidationResult(
        typeElement,
        originalValidationResult,
        selectedLanguageId,
        getDefaultLanguage(state).name,
      );
    }

    dispatch(contentItemElementRefreshFinished(elementData, validationResult));

    if (isMultipleChoiceElement(elementData)) {
      // revalidate conditionally-dependent elements
      dispatch(revalidateEditedContentItemVariantElements());
    }
  };
