import { EditorBlock } from 'draft-js';
import PropTypes from 'prop-types';
import React from 'react';
import { DropTarget } from '../../../../../_shared/components/DragDrop/DropTarget.tsx';
import {
  getAcceptedDropTypes,
  getCustomBlockSleevePositionForRendering,
} from '../../../editorCore/utils/editorComponentUtils.ts';
import { CustomBlockSleevePosition } from '../../../utils/blocks/blockTypeUtils.ts';
import { IEditorBlockProps } from '../../../utils/blocks/editorBlockUtils.ts';
import {
  DroppableBlockPropTypes,
  DroppableBlockWrapperBase,
  IDroppableBlockProps,
} from './DroppableBlockWrapperBase.tsx';

export interface IDroppableNativeBlockWrapperProps extends IDroppableBlockProps {
  readonly children?: JSX.Element;
}

export class DroppableNativeBlockWrapper extends DroppableBlockWrapperBase<IDroppableNativeBlockWrapperProps> {
  static displayName = 'DroppableNativeBlockWrapper';

  static propTypes: PropTypesShape<IDroppableNativeBlockWrapperProps> = {
    children: PropTypes.node,
    ...DroppableBlockPropTypes,
  };

  private editorBlockForUpdateCheck: EditorBlock | null = null;

  constructor(props: IDroppableNativeBlockWrapperProps) {
    super(props);

    this._updateEditorBlock();
  }

  componentDidUpdate(): void {
    this._updateEditorBlock();
  }

  shouldComponentUpdate(nextProps: IDroppableNativeBlockWrapperProps) {
    if (super.shouldComponentUpdate(nextProps)) {
      return true;
    }

    const nextChild = this._getChild(nextProps);

    if (!this.editorBlockForUpdateCheck || !nextChild) {
      return !!this.editorBlockForUpdateCheck || !!nextChild;
    }

    const childrenArray = Array.isArray(this.props.children);
    const nextChildProps =
      nextChild && (childrenArray ? nextChild.props.children.props : nextProps);

    return this.shouldBlockUpdate(this.editorBlockForUpdateCheck, nextChildProps);
  }

  private readonly _updateEditorBlock = () => {
    // We maintain extra instance of EditorBlock for delegation of shouldComponentUpdate to prevent copying of the logic from DraftJS code
    // The underlying instance cannot be referenced directly due to too many nesting levels of underlying components
    const child = this._getChild(this.props);
    const childrenArray = Array.isArray(this.props.children);
    this.editorBlockForUpdateCheck =
      child && new EditorBlock(childrenArray ? child.props.children.props : this.props);
  };

  private readonly _getChild = (props: IDroppableNativeBlockWrapperProps): JSX.Element | null => {
    if (Array.isArray(props.children)) {
      const children = props.children as Array<JSX.Element>;
      if (!children || children.length !== 1) {
        return null;
      }

      const child = children[0] ?? null;
      return child;
    }

    return props.children as JSX.Element;
  };

  render() {
    const { canUpdate, hoveringCollisionStrategy, onMove, parentId } = this.props;

    const child = this._getChild(this.props);
    if (!child) {
      return null;
    }

    const childBlockProps = child.props.children.props as IEditorBlockProps;
    const block = childBlockProps.block;
    if (!block) {
      return null;
    }

    // Do not wrap extra empty paragraphs (= empty blocks before and after custom blocks) with drag and drop.
    // It is because we do not want to allow user to manipulate these blocks with DnD as they are automatically generated in consistency utils.
    if (getCustomBlockSleevePositionForRendering(block) !== CustomBlockSleevePosition.None) {
      return child;
    }

    return (
      <DropTarget<HTMLElement>
        accept={getAcceptedDropTypes(block)}
        canDrop={canUpdate}
        hoveringCollisionStrategy={hoveringCollisionStrategy}
        onMove={onMove}
        parentId={parentId}
        renderDroppable={(ref) => React.cloneElement(child, { ref })}
        targetId={block.getKey()}
      />
    );
  }
}
