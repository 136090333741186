import { DraftStyleMap } from 'draft-js';
import React, { useCallback, useMemo, useRef } from 'react';
import { RTECommandSource } from '../../../../_shared/models/events/RTECommandEventData.type.ts';
import { useEditorApi } from '../../editorCore/hooks/useEditorApi.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { OnUpdate } from '../../editorCore/types/Editor.base.type.ts';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { DecoratedEditor } from '../../editorCore/types/Editor.decorated.type.ts';
import {
  Apply,
  EditorPlugin,
  PluginState,
  Render,
} from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import { withDisplayName } from '../../editorCore/utils/withDisplayName.ts';
import { isTextBlock } from '../../utils/blocks/blockTypeUtils.ts';
import {
  doesSelectionContainText,
  getFullBlockTypesAtSelection,
  getMetadataAtSelection,
} from '../../utils/editorSelectionUtils.ts';
import {
  CanHandleNewCharsNatively,
  CustomInputHandlingPlugin,
  PostProcessInsertedChars,
} from '../customInputHandling/CustomInputHandlingPlugin.tsx';
import {
  ExecuteCommand,
  KeyboardShortcutsPlugin,
} from '../keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { RichTextInputCommand } from '../keyboardShortcuts/api/EditorCommand.ts';
import { InlineToolbarPlugin } from '../toolbars/InlineToolbarPlugin.tsx';
import { UndoRedoPlugin } from '../undoRedo/UndoRedoPlugin.tsx';
import { EditorInlineStyleApi } from './api/EditorInlineStyleApi.type.ts';
import { evaluateMarkdownInlineConversion } from './api/editorInlineMarkdownUtils.ts';
import { editorInlineStyleApi } from './api/editorInlineStyleApi.ts';
import { CustomStyles } from './api/inlineStyles.ts';
import {
  IInlineStylesToolbarButtonsProps,
  InlineStylesToolbarButtons,
} from './components/InlineStylesToolbarButtons.tsx';

export type InlineStylesPlugin = EditorPlugin<
  None,
  None,
  EditorInlineStyleApi,
  [
    InlineToolbarPlugin,
    KeyboardShortcutsPlugin<RichTextInputCommand>,
    CustomInputHandlingPlugin,
    UndoRedoPlugin,
  ]
>;

const EditorWithInlineStyles: DecoratedEditor<InlineStylesPlugin> = ({ baseRender, state }) => {
  const {
    editorProps: { customStyleMap: parentCustomStyleMap },
  } = state;

  const customStyleMap = useMemo(
    () =>
      ({
        ...parentCustomStyleMap,
        ...CustomStyles,
      }) as DraftStyleMap,
    [parentCustomStyleMap],
  );

  const stateWithInlineStyles: PluginState<InlineStylesPlugin> = {
    ...state,
    editorProps: {
      ...state.editorProps,
      customStyleMap,
    },
  };

  return baseRender(stateWithInlineStyles);
};

EditorWithInlineStyles.displayName = 'EditorWithInlineStyles';

type InlineStylesButtonsProps = Pick<
  PluginState<InlineStylesPlugin>,
  'editorState' | 'handleCommand'
> &
  Pick<
    IInlineStylesToolbarButtonsProps,
    'currentVisualStyle' | 'limitations' | 'hidesDisallowedFeatures'
  >;

const InlineStylesButtons: React.FC<InlineStylesButtonsProps> = ({
  currentVisualStyle,
  editorState,
  handleCommand,
  hidesDisallowedFeatures,
  limitations,
}) => {
  const content = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const fullBlockTypesAtSelection = getFullBlockTypesAtSelection(content, selection);
  const metadataAtSelection = getMetadataAtSelection(content, selection);

  const onCommand = useCallback(
    (command: RichTextInputCommand): void => {
      handleCommand(command, RTECommandSource.InlineToolbar);
    },
    [handleCommand],
  );

  return (
    <InlineStylesToolbarButtons
      currentVisualStyle={currentVisualStyle}
      fullBlockTypesAtSelection={fullBlockTypesAtSelection}
      hidesDisallowedFeatures={hidesDisallowedFeatures}
      limitations={limitations}
      onCommand={onCommand}
      metadataAtSelection={metadataAtSelection}
    />
  );
};

InlineStylesButtons.displayName = 'InlineStylesButtons';

const canHandleNewCharsNatively: Decorator<CanHandleNewCharsNatively> =
  (baseCanHandleNewCharsNatively) => (params) => {
    if (!baseCanHandleNewCharsNatively(params)) {
      return false;
    }

    const { editorState, chars } = params;
    const content = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const block = content.getBlockForKey(selection.getStartKey());

    if (isTextBlock(block)) {
      const text = block.getText();
      const offset = selection.getStartOffset();
      const futureText = text.substring(0, offset) + chars;

      if (evaluateMarkdownInlineConversion(futureText) !== null) {
        return false;
      }
    }
    return true;
  };

const render: Decorator<Render<InlineStylesPlugin>> = (baseRender) => (state) => (
  <EditorWithInlineStyles baseRender={baseRender} state={state} />
);

export const useInlineStyles: PluginCreator<InlineStylesPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('InlineStylesPlugin', {
        ComposedEditor: (props) => {
          const { hidesDisallowedFeatures } = props;

          const setUndoOnBackspaceAfterChanges = useRef(false);
          const undoOnBackspace = useRef(false);

          const onUpdate: Decorator<OnUpdate> = useCallback(
            (baseOnUpdate) => (params) => {
              baseOnUpdate(params);
              undoOnBackspace.current = setUndoOnBackspaceAfterChanges.current;
              setUndoOnBackspaceAfterChanges.current = false;
            },
            [],
          );

          const renderInlineToolbarButtons: Decorator<Render<InlineStylesPlugin>> = useCallback(
            (baseRender) => (state) => {
              const { editorState } = state;
              const content = editorState.getCurrentContent();
              const selection = editorState.getSelection();

              const canUpdateContent = state.canUpdateContent();
              const metadataAtSelection = getMetadataAtSelection(content, selection);
              const selectionContainsText = doesSelectionContainText(
                selection,
                metadataAtSelection,
              );
              const textCanBeFormatted = selectionContainsText && canUpdateContent;

              if (!textCanBeFormatted) {
                return baseRender(state);
              }

              const currentVisualStyle = state.getApi().getCurrentVisualStyle(state.editorState);

              return (
                <>
                  {baseRender(state)}
                  <InlineStylesButtons
                    currentVisualStyle={currentVisualStyle}
                    editorState={state.editorState}
                    handleCommand={state.handleCommand}
                    hidesDisallowedFeatures={!!hidesDisallowedFeatures}
                    limitations={state.getApi().getLimitations()}
                  />
                </>
              );
            },
            [hidesDisallowedFeatures],
          );

          const apply: Apply<InlineStylesPlugin> = useCallback(
            (state) => {
              state.render.decorate(render);
              state.onUpdate.decorate(onUpdate);
              state.renderInlineToolbarButtons.decorate(renderInlineToolbarButtons);

              const executeCommand: Decorator<ExecuteCommand<RichTextInputCommand>> =
                (baseExecuteCommand) => (command, isShiftPressed) => {
                  switch (command) {
                    case RichTextInputCommand.Bold:
                    case RichTextInputCommand.Code:
                    case RichTextInputCommand.Italic:
                    case RichTextInputCommand.Subscript:
                    case RichTextInputCommand.Superscript: {
                      state.executeChange((editorState) =>
                        state.getApi().executeTextStyleCommand(editorState, command),
                      );
                      return true;
                    }

                    case RichTextInputCommand.Backspace: {
                      if (
                        undoOnBackspace.current &&
                        state.getEditorState().getSelection().isCollapsed()
                      ) {
                        state.undo();
                        return true;
                      }
                      break;
                    }

                    default:
                      break;
                  }

                  return baseExecuteCommand(command, isShiftPressed);
                };

              state.executeCommand.decorate(executeCommand);

              const postProcessInsertedChars: Decorator<PostProcessInsertedChars> =
                (basePostProcessInsertedChars) => (params) => {
                  const newEditorState = basePostProcessInsertedChars(params);
                  const withInlineMarkdownConverted = state
                    .getApi()
                    .applyAutomaticMarkdownInlineConversion(newEditorState, () => {
                      setUndoOnBackspaceAfterChanges.current = true;
                    });

                  return withInlineMarkdownConverted;
                };

              state.canHandleNewCharsNatively.decorate(canHandleNewCharsNatively);
              state.postProcessInsertedChars.decorate(postProcessInsertedChars);

              return {};
            },
            [renderInlineToolbarButtons, onUpdate],
          );

          const { getApiMethods } = useEditorApi<InlineStylesPlugin>(editorInlineStyleApi);

          return useEditorWithPlugin(baseEditor, props, { apply, getApiMethods });
        },
      }),
    [baseEditor],
  );

type DisplayInlineStylesPlugin = EditorPlugin;

export const useDisplayInlineStyles: PluginCreator<DisplayInlineStylesPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('DisplayInlineStylesPlugin', {
        ComposedEditor: (props) => {
          const apply: Apply<DisplayInlineStylesPlugin> = useCallback((state) => {
            state.render.decorate(render);

            return {};
          }, []);

          return useEditorWithPlugin(baseEditor, props, { apply });
        },
      }),
    [baseEditor],
  );
