import { createGuid } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { Dispatch, GetState, ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../_shared/models/TrackUserEvent.type.ts';
import {
  IWebhookSetting,
  createWebhookSettingDomainModel,
  createWebhookSettingServerModel,
} from '../../../../data/models/webhooks/WebhookSetting.ts';
import { IWebhookRepository } from '../../../../repositories/interfaces/IWebhookRepository.type.ts';
import {
  ContentTriggerOperation,
  WebhookTriggerType,
} from '../../../../repositories/serverModels/WebhookServerModel.ts';
import {
  Webhooks_Webhook_InsertCompleted,
  Webhooks_Webhook_SaveFailed,
  Webhooks_Webhook_SaveStarted,
  Webhooks_Webhook_UpdateCompleted,
} from '../../constants/webhooksActionTypes.ts';
import { IWebhookFormShape } from '../../models/IWebhookFormShape.type.ts';

interface ISaveWebhookDependencies {
  readonly webhookRepository: IWebhookRepository;
  readonly trackUserEvent: TrackUserEventWithDataAction;
}

const started = (webhook: IWebhookSetting) =>
  ({
    type: Webhooks_Webhook_SaveStarted,
    payload: {
      webhook,
    },
  }) as const;

const insertCompleted = (webhook: IWebhookSetting) =>
  ({
    type: Webhooks_Webhook_InsertCompleted,
    payload: {
      webhook,
    },
  }) as const;

const updateCompleted = (webhook: IWebhookSetting) =>
  ({
    type: Webhooks_Webhook_UpdateCompleted,
    payload: {
      webhook,
    },
  }) as const;

const failed = (webhook: IWebhookSetting) =>
  ({
    type: Webhooks_Webhook_SaveFailed,
    payload: {
      webhook,
    },
  }) as const;

export type SaveWebhookActionsType = ReturnType<
  typeof started | typeof insertCompleted | typeof updateCompleted | typeof failed
>;

const getHighestWebhookOrder = (webhooks: Immutable.Map<Uuid, IWebhookSetting>): number =>
  webhooks
    .toList()
    .sortBy((webhookSetting: IWebhookSetting) => webhookSetting.order)
    .last()?.order ?? 0;

const processWebhook = (
  webhook: IWebhookSetting,
  webhooks: Immutable.Map<Uuid, IWebhookSetting>,
): IWebhookSetting =>
  webhook.order !== 0
    ? webhook
    : {
        ...webhook,
        order: getHighestWebhookOrder(webhooks),
      };

const createWebhookSettings = (
  formValues: IWebhookFormShape,
  webhookSetting: IWebhookSetting,
): IWebhookSetting => {
  const webhook = {
    id: webhookSetting.id,
    url: formValues.url || '',
    name: formValues.name || '',
    enabled: webhookSetting.enabled,
    secret: formValues.secret || '',
    order: webhookSetting.order,
    healthStatus: webhookSetting.healthStatus,
    deliveryApiContentChangeTriggers: Immutable.Map<
      WebhookTriggerType,
      ReadonlyArray<ContentTriggerOperation>
    >([
      [
        WebhookTriggerType.ContentItemVariant,
        Array.from(formValues.triggers.deliveryApiItemContentTriggerIds),
      ],
      [
        WebhookTriggerType.Taxonomy,
        Array.from(formValues.triggers.deliveryApiTaxonomyContentTriggerIds),
      ],
    ]),
    previewDeliveryApiContentChangeTriggers: Immutable.Map<
      WebhookTriggerType,
      ReadonlyArray<ContentTriggerOperation>
    >([
      [
        WebhookTriggerType.ContentItemVariant,
        Array.from(formValues.triggers.previewDeliveryApiItemContentTriggerIds),
      ],
      [
        WebhookTriggerType.Taxonomy,
        Array.from(formValues.triggers.previewDeliveryApiTaxonomyContentTriggerIds),
      ],
    ]),
    workflowStepChangeTriggers: Immutable.Map<WebhookTriggerType, ReadonlyArray<Uuid>>([
      [WebhookTriggerType.ContentItemVariant, Array.from(formValues.triggers.itemWorkflowStepIds)],
    ]),
    managementApiContentChangeTriggers: Immutable.Map<
      WebhookTriggerType,
      ReadonlyArray<ContentTriggerOperation>
    >([
      [
        WebhookTriggerType.ContentItemVariant,
        Array.from(formValues.triggers.managementApiItemContentTriggerIds),
      ],
    ]),
  };

  return webhook;
};

export const createInsertWebhookAction =
  (deps: ISaveWebhookDependencies) =>
  (formValues: IWebhookFormShape, webhookSetting: IWebhookSetting): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {
      data: { webhooks },
    } = getState();
    const webhook = createWebhookSettings(formValues, webhookSetting);
    const processedWebhook = processWebhook(webhook, webhooks.byId);
    const modifiedWebhook: IWebhookSetting = {
      ...processedWebhook,
      id: createGuid(),
    };

    try {
      dispatch(started(modifiedWebhook));

      const response = await deps.webhookRepository.insert(
        createWebhookSettingServerModel(modifiedWebhook),
      );
      const webhookModel = createWebhookSettingDomainModel(response);

      dispatch(insertCompleted(webhookModel));
      dispatch(deps.trackUserEvent(TrackedEvent.WebhookSaved, { url: webhookModel.url }));
    } catch {
      dispatch(failed(processedWebhook));
    }
  };

export const createUpdateSaveWebhookAction =
  (deps: ISaveWebhookDependencies) =>
  (formValues: IWebhookFormShape, webhookSetting: IWebhookSetting): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {
      data: { webhooks },
    } = getState();
    const webhook = createWebhookSettings(formValues, webhookSetting);
    const processedWebhook = processWebhook(webhook, webhooks.byId);

    try {
      dispatch(started(processedWebhook));

      const response = await deps.webhookRepository.update(
        createWebhookSettingServerModel(processedWebhook),
      );
      const webhookModel = createWebhookSettingDomainModel(response);

      dispatch(updateCompleted(webhookModel));
      dispatch(deps.trackUserEvent(TrackedEvent.WebhookSaved, { url: webhookModel.url }));
    } catch {
      dispatch(failed(processedWebhook));
    }
  };
