import React from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { canUserCreateContentTypes } from '../../../../contentInventory/content/features/ContentItemInventory/utils/canUserCreateContentTypes.ts';
import { ContentTypesLoadingIndicator } from '../components/ContentTypesLoadingIndicator.tsx';
import { EmptyContentTypePlaceholder } from '../components/EmptyContentTypePlaceholder.tsx';
import { NewContentItemForm } from '../components/NewContentItemForm.tsx';
import { getNewContentItemFormSelectedValues } from '../selectors/getNewContentItemFormSelectedValues.ts';
import { hasNewContentItemFormAvailableContentTypes } from '../selectors/hasNewContentItemFormAvailableContentTypes.ts';
import { getNewContentItemVisibleSelectors } from '../utils/getNewContentItemVisibleSelectors.ts';

type Props = {
  readonly onClose: () => void;
};

const NewContentItemFormContainer: React.FC<Props> = ({ onClose }) => {
  const selectedValues = useSelector(getNewContentItemFormSelectedValues);
  const visibleSelectors = useSelector(getNewContentItemVisibleSelectors);
  const projectId = useSelector((s) => s.sharedApp.currentProjectId);
  const canCreateContentTypes = useSelector(canUserCreateContentTypes);
  const hasAvailableContentTypes = useSelector(hasNewContentItemFormAvailableContentTypes);

  const isFormReady = useSelector((s) => {
    const areTypesLoaded = s.data.contentTypes.loadingStatus === LoadingStatus.Loaded;
    const areCollectionsLoaded = s.data.collections.loadingStatus === LoadingStatus.Loaded;
    const isConfigLoaded = s.contentApp.newContentItem.formConfig.isLoaded;

    return areTypesLoaded && areCollectionsLoaded && isConfigLoaded;
  });

  if (!isFormReady) {
    return <ContentTypesLoadingIndicator />;
  }

  if (!hasAvailableContentTypes) {
    return (
      <EmptyContentTypePlaceholder
        canCreateContentTypes={canCreateContentTypes}
        onCreateNewTypeClick={onClose}
        projectId={projectId}
      />
    );
  }

  return <NewContentItemForm selectedValues={selectedValues} visibleSelectors={visibleSelectors} />;
};

NewContentItemFormContainer.displayName = 'NewContentItemFormContainer';

export { NewContentItemFormContainer as NewContentItemForm };
