import React, { memo } from 'react';
import { createEditorWithPlugins } from '../../../../editorCore/Editor.composition.tsx';
import { EditorProps } from '../../../../editorCore/types/Editor.composition.type.ts';
import { useComposedViewer } from '../../../../editors/richText/RichTextViewer.tsx';
import { useBorderless } from '../../../visuals/BorderlessPlugin.tsx';

export const useComposedAiViewer = () => {
  return createEditorWithPlugins(useComposedViewer(), useBorderless);
};

export type RichAiSuggestionViewerProps = EditorProps<typeof useComposedAiViewer>;

export const RichAiSuggestionViewer: React.FC<RichAiSuggestionViewerProps> = memo((props) => {
  const { ComposedEditor } = useComposedAiViewer();

  return <ComposedEditor {...props} />;
});

RichAiSuggestionViewer.displayName = 'RichAiSuggestionViewer';
