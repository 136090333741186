import { assert } from '@kontent-ai/utils';
import classNames from 'classnames';
import ImmutablePropTypes from 'immutable-prop-types';
import PropTypes from 'prop-types';
import React, { RefObject, memo, useCallback, useMemo, useState } from 'react';
import { ModalViewer } from '../../../../_shared/components/Modal/ModalViewer.tsx';
import { ModalViewerPosition } from '../../../../_shared/components/Modal/ModalViewerPosition.ts';
import { IconName } from '../../../../_shared/constants/iconEnumGenerated.ts';
import { SquareButton } from '../../../../_shared/uiComponents/Button/SquareButton.tsx';
import { ButtonStyle } from '../../../../_shared/uiComponents/Button/buttonStyle.ts';
import { SquareButtonSize } from '../../../../_shared/uiComponents/Button/squareButtonSize.ts';
import { DropDownFrame } from '../../../../_shared/uiComponents/DropDown/DropDownFrame.tsx';
import { DropDownOptionsGroup } from '../../../../_shared/uiComponents/DropDown/DropDownOptionsGroup.tsx';
import { IDropdownTippyOptions } from '../../../../_shared/uiComponents/DropDown/dropDownTippyOptions.ts';
import {
  DataUiAction,
  DataUiCollection,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IContentType } from '../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import {
  ILinkedItemsTypeElementData,
  LinkedItemsTypeElementDataPropTypesShape,
} from '../../../contentModels/shared/models/elements/LinkedItemsTypeElementData.ts';
import { PageIsAlreadyLinked } from '../../../features/ModalContentItemSelector/constants/uiConstants.ts';
import { ModalMultipleContentItemsSelector } from '../../../features/ModalContentItemSelector/containers/ModalMultipleContentItemsSelector.tsx';
import {
  WebSpotlightTreeActions,
  WebSpotlightTreeActionsDisabledTooltipConnectionLost,
  WebSpotlightTreeActionsDisabledTooltipContentPublished,
  WebSpotlightTreeActionsDisabledTooltipContentScheduledToPublish,
  WebSpotlightTreeActionsDisabledTooltipMissingPermission,
  WebSpotlightTreeActionsDisabledTooltipNotTranslated,
} from '../../constants/uiConstants.ts';
import { IMoreActionsCheckResult } from '../../reducers/moreActionsCheckResult.ts';
import { WebSpotlightTreeOperationsCheckResult } from '../../types/IWebSpotlightTreeActionsChekTypes.type.ts';
import { TreeItemMoreActionsOption } from './TreeItemMoreActionsOption.tsx';

enum MoreActionsOpenModalOptions {
  LinkExisting = 'LinkExisting',
  None = 'None',
}

const moreActionsDisabledTooltipMap = Immutable.Map<
  WebSpotlightTreeOperationsCheckResult | undefined,
  string
>([
  [
    WebSpotlightTreeOperationsCheckResult.ItemIsPublished,
    WebSpotlightTreeActionsDisabledTooltipContentPublished,
  ],
  [
    WebSpotlightTreeOperationsCheckResult.ItemIsScheduledToPublish,
    WebSpotlightTreeActionsDisabledTooltipContentScheduledToPublish,
  ],
  [
    WebSpotlightTreeOperationsCheckResult.ItemNotTranslated,
    WebSpotlightTreeActionsDisabledTooltipNotTranslated,
  ],
  [
    WebSpotlightTreeOperationsCheckResult.PermissionMissing,
    WebSpotlightTreeActionsDisabledTooltipMissingPermission,
  ],
  [
    WebSpotlightTreeOperationsCheckResult.Unknown,
    WebSpotlightTreeActionsDisabledTooltipConnectionLost,
  ],
]);

const tippyOptions: IDropdownTippyOptions = {
  placement: 'bottom-end',
  popperOptions: {
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-end'],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          mainAxis: false,
          altAxis: true,
          altBoundary: true,
        },
      },
    ],
  },
  offset: [0, 5],
};

export interface ITreeItemMoreActionsOwnProps {
  readonly isPublished: boolean;
  readonly isScheduledToPublish: boolean;
  readonly isTranslated: boolean;
  readonly itemId: Uuid;
  readonly onNodeSelected: () => void;
  readonly typeId: Uuid;
  readonly visible: boolean;
}

export interface ITreeItemMoreActionsStateProps {
  readonly canCreateItemWithoutSelection: boolean;
  readonly contentType: IContentType;
  readonly moreActionsCheckResult: IMoreActionsCheckResult | null;
  readonly subpagesById: Immutable.Map<Uuid, UuidArray>;
  readonly subpagesElementData: ILinkedItemsTypeElementData | null;
}

export interface ITreeItemMoreActionsDispatchProps {
  readonly linkItems: (subpagesElementId: Uuid, contentItemIds: UuidArray) => Promise<void>;
  readonly loadActionPermissions: (contentGroupId: string | null) => void;
  readonly onCreateNewItem: (subpagesElementId: Uuid) => Promise<void>;
  readonly onNodeBlurred: () => void;
  readonly onNodeFocused: () => void;
  readonly onOpenCreateNewItemDialog: () => void;
}

export type TreeItemMoreActionsProps = ITreeItemMoreActionsOwnProps &
  ITreeItemMoreActionsStateProps &
  ITreeItemMoreActionsDispatchProps;

const propTypes: PropTypesShape<TreeItemMoreActionsProps> = {
  canCreateItemWithoutSelection: PropTypes.bool,
  contentType: PropTypes.object.isRequired,
  isPublished: PropTypes.bool.isRequired,
  isScheduledToPublish: PropTypes.bool.isRequired,
  isTranslated: PropTypes.bool.isRequired,
  itemId: PropTypes.string.isRequired,
  linkItems: PropTypes.func.isRequired,
  loadActionPermissions: PropTypes.func.isRequired,
  moreActionsCheckResult: PropTypes.object,
  onCreateNewItem: PropTypes.func.isRequired,
  onNodeBlurred: PropTypes.func.isRequired,
  onNodeFocused: PropTypes.func.isRequired,
  onNodeSelected: PropTypes.func.isRequired,
  onOpenCreateNewItemDialog: PropTypes.func.isRequired,
  subpagesById: ImmutablePropTypes.map.isRequired,
  subpagesElementData: LinkedItemsTypeElementDataPropTypesShape,
  typeId: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
};

const TreeItemMoreActions: React.FC<TreeItemMoreActionsProps> = ({
  canCreateItemWithoutSelection,
  contentType,
  isPublished,
  isScheduledToPublish,
  isTranslated,
  itemId,
  linkItems,
  loadActionPermissions,
  moreActionsCheckResult,
  onCreateNewItem,
  onNodeBlurred,
  onNodeFocused,
  onNodeSelected,
  onOpenCreateNewItemDialog,
  subpagesById,
  subpagesElementData,
  visible,
}) => {
  const [isDropdownShown, setIsDropdownShown] = useState(false);
  const [openModalOption, setOpenModalOption] = useState(MoreActionsOpenModalOptions.None);

  const preventEventPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const hideMoreActionsDropdown = () => {
    setIsDropdownShown(false);
  };

  const hideMoreActionsDropdownAndBlur = () => {
    if (isDropdownShown) {
      onNodeBlurred();
      hideMoreActionsDropdown();
    }
  };

  const toggleMoreActionsDropdown = (e: React.MouseEvent) => {
    preventEventPropagation(e);

    if (!subpagesElementData) {
      return;
    }

    if (isDropdownShown) {
      onNodeBlurred();
    } else {
      loadActionPermissions(subpagesElementData.contentGroupId);
      onNodeFocused();
    }
    setIsDropdownShown(!isDropdownShown);
  };

  const closeModal = useCallback(() => {
    setOpenModalOption(MoreActionsOpenModalOptions.None);
  }, []);

  const onModalClosed = useCallback(() => {
    closeModal();
    onNodeBlurred();
  }, [closeModal, onNodeBlurred]);

  const createNewItem = async (): Promise<void> => {
    assert(
      subpagesElementData,
      () => `${__filename} component: Subpages element for type ${contentType.id} was not found.`,
    );
    await onCreateNewItem(subpagesElementData.elementId);
    onNodeBlurred();
  };

  const onCreateNewPageItemClick = (e: React.MouseEvent) => {
    preventEventPropagation(e);
    hideMoreActionsDropdown();

    if (canCreateItemWithoutSelection) {
      createNewItem();
    } else {
      onOpenCreateNewItemDialog();
    }
  };
  const onLinkExistingPageItemsClick = (e: React.MouseEvent) => {
    preventEventPropagation(e);

    hideMoreActionsDropdown();
    setOpenModalOption(MoreActionsOpenModalOptions.LinkExisting);
  };

  const onItemsSelected = async (ids: ReadonlyArray<Uuid>) => {
    closeModal();
    assert(
      subpagesElementData,
      () =>
        `TreeItemMoreActions.tsx component: Subpages element for type ${contentType.id} was not found.`,
    );
    await linkItems(subpagesElementData.elementId, ids);
    onNodeBlurred();
    onNodeSelected();
  };

  const renderMenuContent = () => {
    const areActionsDisabled = isPublished || isScheduledToPublish || !isTranslated;
    const canCreateContent =
      moreActionsCheckResult?.createNewItem === WebSpotlightTreeOperationsCheckResult.Ok;
    const canLinkContent =
      moreActionsCheckResult?.linkExistingItem === WebSpotlightTreeOperationsCheckResult.Ok;

    const menuContent = (
      <>
        <TreeItemMoreActionsOption
          dataAttributes={getDataUiActionAttribute(DataUiAction.CreateNew)}
          disabled={areActionsDisabled || !canCreateContent}
          disabledTooltip={moreActionsDisabledTooltipMap.get(moreActionsCheckResult?.createNewItem)}
          iconName={IconName.PuzzlePlus}
          loading={
            moreActionsCheckResult?.createNewItem === WebSpotlightTreeOperationsCheckResult.Loading
          }
          onClick={onCreateNewPageItemClick}
          text={WebSpotlightTreeActions.CreateNewItem}
        />
        <TreeItemMoreActionsOption
          dataAttributes={getDataUiActionAttribute(DataUiAction.Add)}
          disabled={areActionsDisabled || !canLinkContent}
          disabledTooltip={moreActionsDisabledTooltipMap.get(
            moreActionsCheckResult?.linkExistingItem,
          )}
          iconName={IconName.Puzzle}
          loading={
            moreActionsCheckResult?.linkExistingItem ===
            WebSpotlightTreeOperationsCheckResult.Loading
          }
          onClick={onLinkExistingPageItemsClick}
          text={WebSpotlightTreeActions.LinkExistingItems}
        />
      </>
    );

    return <DropDownOptionsGroup>{menuContent}</DropDownOptionsGroup>;
  };

  const preselectedFilterIds = useMemo(
    () => ({
      contentTypeIds: subpagesElementData?.allowedTypes,
    }),
    [subpagesElementData],
  );

  const alreadyLinkedItemIds = useMemo(
    () => Immutable.Set<Uuid>(subpagesById.get(itemId) ?? []),
    [subpagesById, itemId],
  );

  return (
    <>
      <DropDownFrame
        hasTrailingElements
        isOptionListVisible={isDropdownShown}
        onClickOutside={hideMoreActionsDropdownAndBlur}
        optionListDataUiAttributes={getDataUiCollectionAttribute(
          DataUiCollection.MoreActionsDropdown,
        )}
        renderContent={renderMenuContent}
        renderSelector={(ref: RefObject<HTMLDivElement>) => (
          <SquareButton
            className={classNames('web-spotlight-tree__more-actions-button', {
              'web-spotlight-tree__more-actions-button--active': visible,
            })}
            isActive={isDropdownShown}
            size={SquareButtonSize.Quinary}
            style={ButtonStyle.Quinary}
            screenReaderText="More actions"
            iconName={IconName.Ellipsis}
            iconClass="u-rotate-90"
            onClick={toggleMoreActionsDropdown}
            tooltipPlacement="top"
            tooltipText={
              subpagesElementData
                ? 'More actions'
                : `This page doesn’t support subpages yet. To change that, add a subpages element to the ${contentType.name} content type.`
            }
            ref={ref}
            disabled={!subpagesElementData}
            {...getDataUiActionAttribute(DataUiAction.MoreActions)}
          />
        )}
        tippyOptions={tippyOptions}
      />
      {subpagesElementData && (
        <ModalViewer
          dialogClassName="dialog"
          isDialogVisible={openModalOption === MoreActionsOpenModalOptions.LinkExisting}
          onClose={onModalClosed}
          position={ModalViewerPosition.Center}
        >
          <ModalMultipleContentItemsSelector
            preselectedFilterIds={preselectedFilterIds}
            alreadyLinkedItemIds={alreadyLinkedItemIds}
            itemIsAlreadyLinkedMsg={PageIsAlreadyLinked}
            onClose={onModalClosed}
            onSelect={onItemsSelected}
            submitButtonText="Link"
            titleBarText={`Insert existing pages to ${subpagesElementData.name} (Subpages element)`}
          />
        </ModalViewer>
      )}
    </>
  );
};

TreeItemMoreActions.displayName = 'TreeItemMoreActions';
TreeItemMoreActions.propTypes = propTypes;

const TreeItemMoreActionsMemo = memo(TreeItemMoreActions);

export { TreeItemMoreActionsMemo as TreeItemMoreActions };
