import { History } from 'history';
import { Dispatch, GetState, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { SubscriptionsRoute } from '../../../../../_shared/constants/routePaths.ts';
import { ISubscription } from '../../../../../data/models/subscriptions/Subscription.ts';
import { getAdministratedSubscriptions } from '../../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { Subscription_SelectSubscription } from '../../constants/subscriptionManagementActionTypes.ts';

interface IDeps {
  readonly loadAdministratedSubscriptions: () => ThunkPromise;
  readonly loadSubscriptionUsage: (subscriptionId: Uuid) => ThunkPromise;
}

const selectSubscription = (subscription: ISubscription) =>
  ({
    type: Subscription_SelectSubscription,
    payload: {
      subscription,
    },
  }) as const;

export type EnsureSelectedSubscriptionActionsType = ReturnType<typeof selectSubscription>;

export const createEnsureSelectedSubscriptionAction =
  ({ loadAdministratedSubscriptions, loadSubscriptionUsage }: IDeps) =>
  (desiredSubscriptionId: Uuid | undefined, history: History): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    await dispatch(loadAdministratedSubscriptions());
    const {
      data: { subscriptions },
    } = getState();
    const desiredSubscription = getAdministratedSubscriptions(
      subscriptions.administratedIds,
      subscriptions.byId,
    ).find((sub: ISubscription) => sub.subscriptionId === desiredSubscriptionId);

    if (desiredSubscription) {
      dispatch(selectSubscription(desiredSubscription));
      dispatch(loadSubscriptionUsage(desiredSubscription.subscriptionId));
    } else {
      history.push(SubscriptionsRoute);
    }
  };
