import { InvariantException } from '@kontent-ai/errors';
import { Collection, delay } from '@kontent-ai/utils';
import { Dispatch, GetState, ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../_shared/models/TrackUserEvent.type.ts';
import { getLanguageCodename } from '../../../../_shared/utils/languageUtils.ts';
import { logError } from '../../../../_shared/utils/logError.ts';
import { IWebSpotlightRepository } from '../../../../repositories/interfaces/IWebSpotlightRepository.type.ts';
import {
  WebSpotlight_PreviewApiPolling_Aborted,
  WebSpotlight_PreviewApiPolling_ChangeReady,
  WebSpotlight_PreviewApiPolling_Failed,
  WebSpotlight_PreviewApiPolling_Finished,
  WebSpotlight_PreviewApiPolling_Started,
} from '../../constants/webSpotlightActionTypes.ts';
import { isPreviewAutoRefreshFeatureAvailable } from '../../selectors/webSpotlightSelectors.ts';

interface IDeps {
  readonly webSpotlightRepository: Pick<
    IWebSpotlightRepository,
    'getItemLastModificationDateFromPreviewApi'
  >;
  readonly trackUserEventWithData: TrackUserEventWithDataAction;
}

interface IParams {
  readonly signal: AbortSignal;
  readonly onChangeReady: (itemCodename: string) => void;
}

const PreviewApiChangesPollingIntervalMs = 2000;

const started = () =>
  ({
    type: WebSpotlight_PreviewApiPolling_Started,
  }) as const;

const aborted = () =>
  ({
    type: WebSpotlight_PreviewApiPolling_Aborted,
  }) as const;

const changeReady = (itemCodename: string) =>
  ({
    type: WebSpotlight_PreviewApiPolling_ChangeReady,
    payload: {
      itemCodename,
    },
  }) as const;

const finished = () =>
  ({
    type: WebSpotlight_PreviewApiPolling_Finished,
  }) as const;

const failed = () =>
  ({
    type: WebSpotlight_PreviewApiPolling_Failed,
  }) as const;

export type StartPreviewApiChangesPollingActionsType = ReturnType<
  typeof started | typeof changeReady | typeof failed | typeof aborted | typeof finished
>;

export const createStartPreviewApiChangesPollingAction =
  (deps: IDeps) =>
  ({ signal, onChangeReady }: IParams): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      const state = getState();

      if (!isPreviewAutoRefreshFeatureAvailable(state)) {
        throw InvariantException(
          'Web Spotlight preview auto refresh feature is not available for current project',
        );
      }

      const {
        data: { languages },
        sharedApp: { selectedLanguage },
        webSpotlightApp: { lastModifiedPreviewItems },
      } = state;

      const languageCodename = getLanguageCodename(
        selectedLanguage.id ?? '',
        languages.defaultLanguage,
        languages.byId,
      );

      dispatch(started());

      const lastModificationDateByItemCodename = new Map<string, Date>(lastModifiedPreviewItems);

      while (!signal.aborted && lastModificationDateByItemCodename.size > 0) {
        const promises = Collection.getEntries(lastModificationDateByItemCodename).map((entry) => {
          const [itemCodename, lastModifiedDate] = entry;
          return deps.webSpotlightRepository
            .getItemLastModificationDateFromPreviewApi(languageCodename, itemCodename)
            .then((response) => {
              const updatedLastModifiedDateMs = new Date(response.lastModified).getTime();
              if (!signal.aborted && updatedLastModifiedDateMs >= lastModifiedDate.getTime()) {
                dispatch(
                  deps.trackUserEventWithData(
                    TrackedEvent.WebSpotlightUserChangesReadyOnPreviewApi,
                    { msSinceChangeSaved: Date.now() - updatedLastModifiedDateMs },
                  ),
                );

                onChangeReady(itemCodename);
                dispatch(changeReady(itemCodename));
                lastModificationDateByItemCodename.delete(itemCodename);
              }
            });
        });

        await Promise.all(promises);

        if (lastModificationDateByItemCodename.size === 0) {
          dispatch(finished());
          return;
        }

        await delay(PreviewApiChangesPollingIntervalMs);
      }

      dispatch(aborted());
    } catch (e) {
      dispatch(failed());
      logError(e);
    }
  };
