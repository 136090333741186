import { Box } from '@kontent-ai/component-library/Box';
import { Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type ContentItemEditorToolbarActionsProps = {
  readonly isSidebarOpen: boolean;
  readonly isSidebarToggleActive: boolean;
  readonly onSidebarToggleClick: (isCollapsed: boolean) => void;
};

export const ContentItemEditorToolbarActions: React.FC<ContentItemEditorToolbarActionsProps> = ({
  isSidebarOpen,
  isSidebarToggleActive,
  onSidebarToggleClick,
}) => (
  <Box paddingLeft={Spacing.L}>
    <Button
      activated={isSidebarToggleActive}
      buttonStyle="secondary"
      onClick={() => onSidebarToggleClick(isSidebarOpen)}
      {...getDataUiActionAttribute(DataUiAction.OpenContentItemSidebar)}
    >
      <Button.Icon icon={Icons.ICircle} />
      Content details
    </Button>
  </Box>
);

ContentItemEditorToolbarActions.displayName = 'ContentItemEditorToolbarActions';
