import React from 'react';
import { SidebarSection } from '../../../../_shared/components/Sidebar/SidebarSection.tsx';
import { SidebarSubmitButtonWithErrorMessage } from '../../../../_shared/components/Sidebar/SidebarSubmitButtonWithErrorMessage.tsx';
import { Sidebar } from '../../../../_shared/containers/Sidebar/SidebarContainer.tsx';
import { HookFormProps } from '../../../../_shared/types/hookFormProps.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ISubscription } from '../../../../data/models/subscriptions/Subscription.ts';
import { SelectedSubscriptionProjectLimitReachedTooltipMessage } from '../../constants/UIConstants.ts';
import { CloneProjectErrors } from '../../containers/projects/copyProjectErrors/CloneProjectErrors.tsx';
import { ICloneProjectFormShape } from '../../models/ICloneProjectFormShape.type.ts';
import { CloneProjectForm } from './CloneProjectForm.tsx';

type Props = {
  readonly activeSubscriptions: ReadonlyArray<ISubscription>;
  readonly canSubmitForm: boolean;
  readonly cloningProject: boolean;
  readonly closeDialog: () => void;
  readonly destinationSubscriptionId: Uuid;
  readonly errorMessage: string;
  readonly formProps: HookFormProps<ICloneProjectFormShape>;
  readonly onSubmit: () => void;
  readonly showSidebar: boolean;
  readonly subscriptionProjectsLimit: number | null;
  readonly subscriptionProjectsLimitExceeded: boolean;
};

export const CloneProjectModal: React.FC<Props> = ({
  activeSubscriptions,
  canSubmitForm,
  cloningProject,
  closeDialog,
  destinationSubscriptionId,
  errorMessage,
  formProps,
  onSubmit,
  showSidebar,
  subscriptionProjectsLimit,
  subscriptionProjectsLimitExceeded,
}) => {
  const shouldRenderWarnings = !canSubmitForm && !cloningProject;

  const getSubmitButtonTooltipText = () => {
    if (cloningProject) {
      return undefined;
    }
    if (subscriptionProjectsLimitExceeded) {
      return SelectedSubscriptionProjectLimitReachedTooltipMessage(subscriptionProjectsLimit);
    }
    if (!canSubmitForm) {
      return 'Limitations exceeded';
    }
    return undefined;
  };

  const onFormSubmit = cloningProject || !canSubmitForm ? undefined : onSubmit;

  const submitButton = (
    <SidebarSubmitButtonWithErrorMessage
      onSubmit={onFormSubmit}
      buttonLabel={cloningProject ? 'Cloning project' : 'Clone project'}
      dataUiAttribute={getDataUiActionAttribute(DataUiAction.Clone)}
      disabled={cloningProject || !canSubmitForm}
      errorMessage={errorMessage}
      isBeingSubmitted={cloningProject}
      tooltipText={getSubmitButtonTooltipText()}
    />
  );

  return (
    <Sidebar
      isVisible={showSidebar}
      onClose={closeDialog}
      titleElement="Clone project"
      submitElement={submitButton}
      onSubmit={onFormSubmit}
    >
      <SidebarSection>
        <CloneProjectForm
          activeSubscriptions={activeSubscriptions}
          destinationSubscriptionId={destinationSubscriptionId}
          formProps={formProps}
          onSubmit={onFormSubmit}
        />
        {shouldRenderWarnings && (
          <div className="create-project-sidebar__increase-warning">
            <CloneProjectErrors subscriptionId={destinationSubscriptionId} />
          </div>
        )}
      </SidebarSection>
    </Sidebar>
  );
};

CloneProjectModal.displayName = 'CloneProjectModal';
