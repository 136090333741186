import { Button } from '@kontent-ai/component-library/Button';
import { Spacing } from '@kontent-ai/component-library/tokens';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';
import { PageTitle } from '../../../../_shared/components/PageTitle.tsx';
import { EnvironmentRouteParams, WebhooksRoute } from '../../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getCurrentProject } from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { EnvironmentSettingsAppNames } from '../../../environmentSettings/root/constants/EnvironmentSettingsAppNames.ts';

interface IWebhookEmptyState {
  readonly onCreateButtonNewClick: () => void;
}

export const WebhookEmptyState: React.FC<IWebhookEmptyState> = ({ onCreateButtonNewClick }) => {
  const { projectId } = useSelector(getCurrentProject);
  const newWebhooksPath = buildPath<EnvironmentRouteParams>(WebhooksRoute, { projectId });
  return (
    <>
      <PageTitle css={`margin-bottom: ${Spacing.XL}px`}>
        {EnvironmentSettingsAppNames.LegacyWebhooks}
      </PageTitle>
      <EmptyState>
        <EmptyState.Title>There’s a better way</EmptyState.Title>
        <EmptyState.Content>
          <EmptyState.ContentGroup>
            <p>
              We recommend using the improved <Link to={newWebhooksPath}>webhooks</Link> for
              advanced content filters and better performance over the legacy webhooks.
            </p>
          </EmptyState.ContentGroup>
        </EmptyState.Content>
        <EmptyState.Footer>
          <Button buttonStyle="primary" onClick={onCreateButtonNewClick}>
            Create new legacy Webhook
          </Button>
        </EmptyState.Footer>
      </EmptyState>
    </>
  );
};

WebhookEmptyState.displayName = 'WebhookEmptyState';
const propTypes: PropTypesShape<IWebhookEmptyState> = {
  onCreateButtonNewClick: PropTypes.func.isRequired,
};
WebhookEmptyState.propTypes = propTypes;
