import { areMembersEqual } from '@kontent-ai/utils';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { createFormValidationResolver } from '../../../../../_shared/utils/validation/createFormValidationResolver.ts';
import { IWorkflowStep } from '../../../../../data/models/workflow/WorkflowStep.ts';
import {
  cancelWorkflowStepEditor,
  editedWorkflowStepUnsavedChangesUpdated,
  savePublishedWorkflowStepEditor,
} from '../../actions/workflowActions.ts';
import { PublishedStepEditor } from '../../components/stepEditors/PublishedStepEditor.tsx';
import { IPublishedWorkflowStepFormShape } from '../../model/IPublishedWorkflowStepFormShape.type.ts';
import { getOrderedAllSteps } from '../../selectors/getOrderedWorkflowSteps.ts';
import { getSortedRoleOptionsListWithAllRolesFirst } from '../../selectors/getSortedRoleOptionsListWithAllRolesFirst.ts';
import {
  getDefaultRolesForFormInput,
  handleAllRolesRoleOnSubmit,
} from '../../utils/allRolesRoleBehaviorUtils.ts';
import { getTransitionFromSteps } from '../../utils/stepEditorInitialValuesUtils.ts';
import { publishedWorkflowStepValidationConfig } from '../../validation/publishedWorkflowStepValidation.ts';

type Props = {
  readonly id: Uuid;
};

const PublishedStepEditorContainer: React.FC<Props> = ({ id }) => {
  const workflowStep = useSelector((s) => s.workflowsApp.editorUi.publishedWorkflowStep);
  const allRenderedSteps = useSelector((s) => getOrderedAllSteps(s.workflowsApp.editorUi));
  const allRoles = useSelector((s) =>
    getSortedRoleOptionsListWithAllRolesFirst(s.data.roles.rolesById),
  );

  const dispatch = useDispatch();
  const onStatusEditingCanceled = () => dispatch(cancelWorkflowStepEditor());

  const defaultValues = useMemo(
    (): IPublishedWorkflowStepFormShape => ({
      transitionFrom: getTransitionFromSteps(allRenderedSteps, workflowStep.id, false).map(
        (step) => step.id,
      ),
      rolesForNewVersion: getDefaultRolesForFormInput(
        workflowStep.roleIdsForCreateNew,
        allRoles,
        false,
      ).map((role) => role.id),
      rolesForUnpublish: getDefaultRolesForFormInput(
        workflowStep.roleIdsForUnpublish,
        allRoles,
        false,
      ).map((role) => role.id),
    }),
    [allRenderedSteps, allRoles, workflowStep],
  );

  const formProps = useForm<IPublishedWorkflowStepFormShape>({
    defaultValues,
    resolver: createFormValidationResolver(publishedWorkflowStepValidationConfig, {}),
  });

  const submitForm = formProps.handleSubmit((values) =>
    dispatch(
      savePublishedWorkflowStepEditor({
        stepId: id,
        transitionsFrom: new Set(
          values.transitionFrom.map(
            (stepId) => allRenderedSteps.find((step) => step.id === stepId) as IWorkflowStep,
          ),
        ),
        roleIdsForCreateNew: handleAllRolesRoleOnSubmit(values.rolesForNewVersion),
        roleIdsForUnpublish: handleAllRolesRoleOnSubmit(values.rolesForUnpublish),
      }),
    ),
  );

  const { watch } = formProps;

  useEffect(() => {
    const susbscription = watch((updatedStep: IPublishedWorkflowStepFormShape) => {
      const hasUnsavedChanges = hasEditorUnsavedChanges(updatedStep, defaultValues);

      dispatch(editedWorkflowStepUnsavedChangesUpdated(hasUnsavedChanges));
    });

    return () => {
      dispatch(editedWorkflowStepUnsavedChangesUpdated(false));
      susbscription.unsubscribe();
    };
  }, [watch, defaultValues]);

  return (
    <PublishedStepEditor
      allRenderedSteps={allRenderedSteps}
      allRoles={allRoles}
      formProps={formProps}
      onStatusEditingCanceled={onStatusEditingCanceled}
      onSubmit={submitForm}
      workflowStep={workflowStep}
    />
  );
};

PublishedStepEditorContainer.displayName = 'PublishedStepEditorContainer';
export { PublishedStepEditorContainer as PublishedStepEditor };

const hasEditorUnsavedChanges = (
  values: IPublishedWorkflowStepFormShape,
  otherValues: IPublishedWorkflowStepFormShape,
): boolean =>
  !areMembersEqual(values.transitionFrom, otherValues.transitionFrom) ||
  !areMembersEqual(values.rolesForNewVersion, otherValues.rolesForNewVersion) ||
  !areMembersEqual(values.rolesForUnpublish, otherValues.rolesForUnpublish);
