import { useMemo } from 'react';
import { BaseEditor } from '../../editorCore/types/Editor.base.type.ts';
import {
  ComposedEditorProps,
  EditorComposition,
  PluginHooks,
} from '../types/Editor.composition.type.ts';
import { Contract } from '../types/Editor.contract.type.ts';

export const useEditorWithPlugin = <
  TPlugin extends Contract,
  TEditor extends BaseEditor = BaseEditor,
>(
  composition: EditorComposition<TEditor>,
  props: ComposedEditorProps<TEditor>,
  plugin: PluginHooks<TPlugin>,
) => {
  const { plugins, ...baseProps } = props;

  const { apply, finalize, getApiMethods, init } = plugin;
  const memoizedPlugin = useMemo(
    (): PluginHooks => ({
      apply,
      finalize,
      getApiMethods,
      init,
    }),
    [apply, finalize, getApiMethods, init],
  );

  const allPlugins = useMemo(() => [memoizedPlugin, ...(plugins ?? [])], [plugins, memoizedPlugin]);

  return <composition.ComposedEditor {...baseProps} plugins={allPlugins} />;
};
