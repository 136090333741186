import React, { forwardRef } from 'react';
import { useLocation } from 'react-router';
import { trackUserEventWithData } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { IContentEntryLinkedItemExpandedData } from '../../../../../_shared/models/TrackUserEventData.ts';
import { ActiveCapabilityType } from '../../../../../_shared/models/activeCapability.type.ts';
import { getSelectedLanguageNameOrPlaceholder } from '../../../../../_shared/selectors/getSelectedLanguageNameOrPlaceholder.ts';
import { isDisabled } from '../../../../../_shared/utils/contentItemUtils.ts';
import {
  hasActiveVariantCapability,
  hasActiveVariantCapabilityForEditedItem,
} from '../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { getCannotViewItemMessage } from '../../../../../_shared/utils/permissions/getCannotViewItemMessage.ts';
import { getLinkedContentItemPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { isRevisionCurrent } from '../../Revisions/utils/revisionUtils.ts';
import { SimpleLinkedItem as SimpleLinkedItemComponent } from '../components/SimpleLinkedItem.tsx';

type Props = {
  readonly className?: string;
  readonly contentItemId: Uuid;
  readonly onExpandedChanged: (isExpanded: boolean) => void;
  readonly renderExpanded: (contentItemId: string) => React.ReactNode;
};

export const SimpleLinkedItem = forwardRef<HTMLDivElement, Props>(
  ({ className, contentItemId, onExpandedChanged, renderExpanded }, ref) => {
    const dispatch = useDispatch();
    const location = useLocation();

    // Be careful when using variables of contentItem, it can be undefined even if you think it can't (eg. cloned project has no archived items from original)
    const contentItem = useSelector((s) => s.data.listingContentItems.byId.get(contentItemId));
    const contentItemPath = contentItem
      ? getLinkedContentItemPath(location.pathname, contentItem.item.id)
      : '';
    const contentType = useSelector(
      (s) => contentItem && s.data.contentTypes.byId.get(contentItem.item.typeId),
    );
    const currentLanguageName = useSelector(getSelectedLanguageNameOrPlaceholder);

    const disabled = useSelector((s) => {
      const selectedRevision = s.contentApp.selectedRevision;
      const isDisplayedInRevisionOfItem =
        !!selectedRevision && !isRevisionCurrent(s.contentApp.entryTimeline, selectedRevision);
      return (
        isDisabled(isDisplayedInRevisionOfItem, s.contentApp.editedContentItemVariant) ||
        !hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.UpdateContent, s)
      );
    });

    return (
      <SimpleLinkedItemComponent
        canCreate={hasActiveVariantCapability(ActiveCapabilityType.CreateContent, contentItem)}
        cannotViewDisabledMessage={contentItem ? getCannotViewItemMessage(contentItem) : undefined}
        className={className}
        contentItem={contentItem}
        contentItemId={contentItemId}
        contentItemPath={contentItemPath}
        contentType={contentType}
        currentLanguageName={currentLanguageName}
        disabled={disabled}
        onExpandedChanged={onExpandedChanged}
        onTrackLinkedItemExpanded={(eventData: IContentEntryLinkedItemExpandedData) =>
          dispatch(trackUserEventWithData(TrackedEvent.ContentEntryLinkedItemExpanded, eventData))
        }
        ref={ref}
        renderExpanded={renderExpanded}
      />
    );
  },
);

SimpleLinkedItem.displayName = 'SimpleLinkedItem';
