import { Pathname } from 'history';
import Immutable from 'immutable';
import React, { useEffect } from 'react';
import { DataTable } from '../../../../_shared/components/DataTable/DataTable.tsx';
import {
  Column,
  DataTableHeadRow,
} from '../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import { DataTableRow } from '../../../../_shared/components/DataTable/DataTableRow.tsx';
import { LinkDataTableCell } from '../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { HtmlSettingsPageTitle } from '../../../../_shared/components/HtmlSettingsPageTitle.tsx';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { OrderByDirection } from '../../../../_shared/models/OrderBy.ts';
import { ListingMessage } from '../../../../_shared/uiComponents/ListingMessage/ListingMessage.tsx';
import { Tag } from '../../../../_shared/uiComponents/Tag/Tag.tsx';
import {
  DataUiAppName,
  DataUiCollection,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { TagColor } from '../../../../data/constants/tagColor.ts';
import { EnvironmentSettingsAppNames } from '../../root/constants/EnvironmentSettingsAppNames.ts';
import { MaxNumberOfRolesExceededMessege } from '../constants/UIConstants.ts';
import {
  IRoleListingOperationStatus,
  RoleListingOperationType,
} from '../models/RoleListingOperation.ts';

export type RoleItem = {
  readonly id: Uuid;
  readonly name: string;
  readonly userCount: number;
  readonly roleEditorPath: Pathname;
  readonly isDefaultRole: boolean;
};

export interface IRoleListingDataProps {
  readonly createNewRolePath: Pathname;
  readonly loadingStatus: LoadingStatus;
  readonly roleItems: ReadonlyArray<RoleItem>;
  readonly operationStatus: IRoleListingOperationStatus;
  readonly canCreateNewRole: boolean;
}

export interface IRoleListingCallbackProps {
  readonly onInit: () => void;
  readonly onLeave: () => void;
  readonly onRenewDeletedRole?: () => void;
}

type IRoleListingProps = IRoleListingDataProps & IRoleListingCallbackProps;

const dataTableHeadColumns: Immutable.List<Column> = Immutable.List([
  {
    columnName: 'Name',
    orderBy: OrderByDirection.None,
  },
  {
    columnName: 'Users in this role',
    orderBy: OrderByDirection.None,
  },
]);

export const RoleListing: React.FC<IRoleListingProps> = ({
  loadingStatus,
  roleItems,
  createNewRolePath,
  operationStatus,
  onRenewDeletedRole,
  canCreateNewRole,
  onInit,
  onLeave,
}) => {
  useEffect(() => {
    onInit();
    return () => onLeave();
  }, [onInit, onLeave]);

  if (loadingStatus !== LoadingStatus.Loaded) {
    return <Loader />;
  }

  return (
    <div
      className="canvas__inner-section canvas__inner-section--restricted-width"
      {...getDataUiAppNameAttribute(DataUiAppName.Roles)}
    >
      <HtmlSettingsPageTitle settingsAppName={EnvironmentSettingsAppNames.Roles} />
      <DataTable
        createNewItemLinkPath={createNewRolePath}
        isCreateNewDisabled={!canCreateNewRole}
        createNewItemTooltip={canCreateNewRole ? undefined : MaxNumberOfRolesExceededMessege}
        dataUiCollectionName={DataUiCollection.RoleList}
        title={
          <ListingMessage
            statusInfoMessage={{
              text: operationStatus.message,
              style: operationStatus.messageStyle,
            }}
            onUndo={
              operationStatus.operationType === RoleListingOperationType.Deleted && canCreateNewRole
                ? onRenewDeletedRole
                : null
            }
          />
        }
        header={<DataTableHeadRow columns={dataTableHeadColumns} />}
      >
        {roleItems.map((roleItem: RoleItem) => (
          <DataTableRow id={roleItem.id} key={roleItem.id} dataUiObjectName={roleItem.name}>
            <LinkDataTableCell linkPath={roleItem.roleEditorPath}>
              {roleItem.name}
              {roleItem.isDefaultRole && (
                <Tag color={TagColor.Gray} title="Predefined role" customClass="data-table__tag">
                  Predefined
                </Tag>
              )}
            </LinkDataTableCell>
            <LinkDataTableCell linkPath={roleItem.roleEditorPath}>
              {roleItem.userCount}
            </LinkDataTableCell>
          </DataTableRow>
        ))}
      </DataTable>
    </div>
  );
};

RoleListing.displayName = 'RoleListing';
