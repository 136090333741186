import { BorderRadius, gridUnit, px } from '@kontent-ai/component-library/tokens';

export const assetTileHeightMultiplier = 29.5;
export const assetTileWidthMultiplier = 29;
export const assetTilePreviewHeightMultiplier = 18;
export const assetTileReadonlyBorderWidthMultiplier = 0.125;
export const assetTileAlertBorderWidthMultiplier = 0.25;

export const assetTileHeight = assetTileHeightMultiplier * gridUnit;
export const assetTileWidth = assetTileWidthMultiplier * gridUnit;
export const assetTilePreviewHeight = assetTilePreviewHeightMultiplier * gridUnit;
export const assetTileBorderRadius = px(BorderRadius.M);
export const assetTileReadonlyBorderWidth = assetTileReadonlyBorderWidthMultiplier * gridUnit;
export const assetTileAlertBorderWidth = assetTileAlertBorderWidthMultiplier * gridUnit;
