import { History } from 'history';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { emptyNote } from '../../../../models/contentItem/Assignment.ts';
import { AssignmentSections } from '../../constants/AssignmentSections.ts';
import { ContentItemSidebarEditableSection } from '../../constants/ContentItemSidebarEditableSection.ts';
import { noteFormReset } from '../contentItemEditingActions.ts';
import { createUpdateContentItemEditingSidebarSectionActions } from './createUpdateContentItemEditingSidebarSection.ts';
import { IUpdateAssignmentSectionParams } from './updateAssignment.ts';

interface IDeps {
  readonly updateAssignmentSection: (args: IUpdateAssignmentSectionParams) => ThunkPromise;
}

type Parameters = {
  readonly actionOrigin: ContentItemEditingEventOrigins;
  readonly deps: IDeps;
  readonly history: History;
  readonly note: string;
};

const updateNote =
  ({ actionOrigin, note, deps, history }: Parameters): ThunkPromise =>
  async (dispatch, getState) => {
    const { editedContentItemVariant } = getState().contentApp;
    await dispatch(
      deps.updateAssignmentSection({
        actionOrigin,
        history,
        getUpdatedAssignment: () => ({
          note: {
            ...(editedContentItemVariant?.assignment.note || emptyNote),
            noteText: note,
          },
        }),
        submittingSection: AssignmentSections.Note,
      }),
    );
    dispatch(noteFormReset());
  };

const { startSidebarSectionUpdate, finishSidebarSectionUpdate } =
  createUpdateContentItemEditingSidebarSectionActions(ContentItemSidebarEditableSection.Note);

export const createUpdateNoteInSidebar =
  (deps: IDeps) =>
  (history: History, note: string): ThunkPromise =>
  async (dispatch) => {
    dispatch(startSidebarSectionUpdate);

    await dispatch(
      updateNote({ actionOrigin: ContentItemEditingEventOrigins.ItemDetails, note, deps, history }),
    );

    dispatch(finishSidebarSectionUpdate);
  };

export const createUpdateNote =
  (deps: IDeps) =>
  (history: History, note: string, actionOrigin: ContentItemEditingEventOrigins): ThunkPromise =>
  async (dispatch) =>
    await dispatch(updateNote({ actionOrigin, note, deps, history }));
