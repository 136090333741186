import PropTypes from 'prop-types';
import React from 'react';
import { SearchPhraseHighlighterElement } from '../Highlighting/SearchPhraseHighlighterElement.tsx';

interface IHighlightedOptionProps {
  readonly optionName: string;
  readonly pattern: string;
}

const propTypes: PropTypesShape<IHighlightedOptionProps> = {
  optionName: PropTypes.string.isRequired,
  pattern: PropTypes.string.isRequired,
};

export const HighlightedOption: React.FC<IHighlightedOptionProps> = ({ optionName, pattern }) => {
  return <SearchPhraseHighlighterElement searchPhrase={pattern} text={optionName} />;
};

HighlightedOption.displayName = 'HighlightedOption';
HighlightedOption.propTypes = propTypes;
