import { Collection } from '@kontent-ai/utils';
import PropTypes from 'prop-types';
import React from 'react';

const highlightedUserName = (activeUser: string, addComma: boolean) => (
  <>
    <span className="callout__user">{activeUser}</span>
    {addComma ? ', ' : ''}
  </>
);

const multipleUsersMsg = (activeUsers: ReadonlyArray<string>) => {
  const lastUserName = Collection.getLast(activeUsers);

  return (
    <>
      {activeUsers.slice(0, activeUsers.length - 1).map((activeUser: string, index: number) => {
        return highlightedUserName(activeUser, index < activeUsers.length - 2);
      })}
      {activeUsers.length === 2 ? ' and ' : ', and '}
      {lastUserName && highlightedUserName(lastUserName, false)}
      {' are '}
    </>
  );
};

const singleUserMsg = (activeUsers: ReadonlyArray<string>) => {
  const firstUserName = Collection.getFirst(activeUsers);

  return (
    <>
      {firstUserName && highlightedUserName(firstUserName, false)}
      {' is '}
    </>
  );
};

interface IFriendlyWarningMessageForMultipleUsersEditingOwnProps {
  readonly activeUsersNames: ReadonlyArray<string>;
  readonly infoMessage: string;
}

const propTypes: PropTypesShape<IFriendlyWarningMessageForMultipleUsersEditingOwnProps> = {
  activeUsersNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  infoMessage: PropTypes.string.isRequired,
};

export const FriendlyWarningMessageForMultipleUsersEditing: React.FC<
  IFriendlyWarningMessageForMultipleUsersEditingOwnProps
> = ({ activeUsersNames, infoMessage }) => {
  return (
    <div className="callout__text-block">
      <p>
        {activeUsersNames.length > 1
          ? multipleUsersMsg(activeUsersNames)
          : singleUserMsg(activeUsersNames)}
        <strong>currently editing</strong> this item. {infoMessage}
      </p>
    </div>
  );
};

FriendlyWarningMessageForMultipleUsersEditing.displayName =
  'FriendlyWarningMessageForMultipleUsersEditing';
FriendlyWarningMessageForMultipleUsersEditing.propTypes = propTypes;
