import React from 'react';
import {
  FriendlyWarningReason,
  FriendlyWarningReasonDetail,
  IFriendlyWarning,
} from '../../../../../../applications/itemEditor/utils/itemElementFriendlyWarningCheckers/types/FriendlyWarnings.ts';
import { Warning } from '../../../../../components/infos/Warning.tsx';
import { FriendlyWarningMessageForEditing } from '../../../containers/Elements/PublishingFriendlyWarningSection/FriendlyWarningMessageForEditing.tsx';
import { ModalSection } from '../../ModalSection.tsx';
import { FriendlyWarningMessageForListing } from './FriendlyWarningMessageForListing.tsx';

const isWarningForListing = (w: IFriendlyWarning) =>
  w.reason === FriendlyWarningReason.BrokenModularsInListing ||
  w.reason === FriendlyWarningReason.BrokenLinksInListing;

const getWarningsForListing = (
  friendlyWarningReasons: ReadonlyArray<IFriendlyWarning>,
): ReadonlyArray<IFriendlyWarning> => friendlyWarningReasons.filter(isWarningForListing);

const anyWarningsForListing = (friendlyWarningReasons: ReadonlyArray<IFriendlyWarning>): boolean =>
  !!getWarningsForListing(friendlyWarningReasons).length;

const isWarningForUnpublishedModularsInEditing = (w?: IFriendlyWarning) =>
  !!w &&
  !isWarningForListing(w) &&
  !!w.reason &&
  !!w.reasonDetails &&
  w.reason === FriendlyWarningReason.BrokenModulars &&
  w.reasonDetails.includes(FriendlyWarningReasonDetail.UnpublishedItem) &&
  !w.reasonDetails.some((d) => d !== FriendlyWarningReasonDetail.UnpublishedItem);

const getFriendlyWarningTitle = (
  friendlyWarningReasons: ReadonlyArray<IFriendlyWarning>,
): string =>
  anyWarningsForListing(friendlyWarningReasons)
    ? 'Publish content items?'
    : 'Publish content item?';

export interface IPublishingFriendlyWarningPopoverDataProps {
  readonly activeUsersNames: ReadonlyArray<string>;
  readonly friendlyWarningReasons: ReadonlyArray<IFriendlyWarning> | undefined;
}

interface IPublishingFriendlyWarningPopoverProps
  extends IPublishingFriendlyWarningPopoverDataProps {}

export const PublishingFriendlyWarningSection: React.FC<IPublishingFriendlyWarningPopoverProps> = ({
  activeUsersNames,
  friendlyWarningReasons,
}) => {
  const filteredReasons =
    !!friendlyWarningReasons &&
    friendlyWarningReasons.filter((w) => !isWarningForUnpublishedModularsInEditing(w));

  if (!filteredReasons || !filteredReasons.length) {
    return null;
  }

  return (
    <ModalSection>
      <Warning subtitle={getFriendlyWarningTitle(filteredReasons)}>
        {anyWarningsForListing(filteredReasons) ? (
          <FriendlyWarningMessageForListing friendlyWarnings={filteredReasons} />
        ) : (
          <FriendlyWarningMessageForEditing
            activeUsersNames={activeUsersNames}
            friendlyWarnings={filteredReasons}
          />
        )}
      </Warning>
    </ModalSection>
  );
};

PublishingFriendlyWarningSection.displayName = 'PublishingFriendlyWarningSection';
