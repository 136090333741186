import { AvatarSize } from '@kontent-ai/component-library/Avatar';
import {
  CircleSkeleton,
  TextSkeleton,
  WrappingSkeleton,
} from '@kontent-ai/component-library/Skeleton';
import React from 'react';
import { DataUiCollection } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { StyledDataTable, StyledDataTableCell, StyledDataTableRow } from './StyledDataTable.tsx';
import { Widget } from './Widget.tsx';
import { WidgetListingContentShowMoreButton } from './WidgetListingContentShowMoreButton.tsx';

export const WidgetLoadingState: React.FC = () => (
  <>
    <Widget.Body>
      <StyledDataTable dataUiCollectionName={DataUiCollection.ContentTasks}>
        {[...Array(5).keys()].map((index) => (
          <StyledDataTableRow
            dataUiObjectName={DataUiCollection.ContentTasks}
            id={`${index}`}
            key={index}
          >
            <StyledDataTableCell linkPath={null}>
              <TextSkeleton width={400} />
            </StyledDataTableCell>
            <StyledDataTableCell linkPath={null}>
              <CircleSkeleton diameter={AvatarSize.S} />
            </StyledDataTableCell>
            <StyledDataTableCell linkPath={null}>
              <TextSkeleton width={200} />
            </StyledDataTableCell>
          </StyledDataTableRow>
        ))}
      </StyledDataTable>
    </Widget.Body>
    <Widget.Footer>
      <Widget.CenterContent>
        <WrappingSkeleton isLoading type="text">
          <WidgetListingContentShowMoreButton onClick={() => undefined} />
        </WrappingSkeleton>
      </Widget.CenterContent>
    </Widget.Footer>
  </>
);

WidgetLoadingState.displayName = 'WidgetLoadingState';
