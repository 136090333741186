import { createGuid } from '@kontent-ai/utils';
import { DefaultVariantId } from '../../../../_shared/constants/variantIdValues.ts';
import { IUserIdentifier } from '../../../../_shared/models/UserIdentifier.ts';
import { emptyListingContentItem } from '../../../../data/models/listingContentItems/IListingContentItem.ts';
import { emptyItemData } from '../../../../data/models/listingContentItems/IListingItemData.ts';
import { emptyListingVariantData } from '../../../../data/models/listingContentItems/IListingVariantData.ts';
import { Workflow } from '../../../../data/models/workflow/Workflow.ts';
import { emptyAssignment } from '../../../itemEditor/models/contentItem/Assignment.ts';
import { TimeInterval } from '../../constants/timeIntervals.ts';
import { TimeSpentInWorkflowStepRecord } from '../../repositories/serverModels/missionControlRepositoryServerModels.type.ts';
import { ContentPaceDetailContentItem } from '../getDetailContentItemsFromServerModel.ts';
import {
  TimeSpentInWorkflowStepByWorkflow,
  getTimeSpentInWorkflowStepByWorkflowFromServerModel,
} from '../getTimeSpentInWorkflowStepByWorkflowFromServerModel.ts';

const demoDefaultContentPaceDurationInSeconds = 60 * 60 * 12;
const demoScheduledStepPaceDurationInSeconds = 60 * 60 * 5;

export const getDemoContentPaceDetailContentItems = (
  userId: UserId,
  contentTypeId: string,
  averageTimeInSeconds: number,
): ReadonlyArray<ContentPaceDetailContentItem> => {
  const itemIds = [createGuid(), createGuid(), createGuid(), createGuid(), createGuid()];
  const names = [
    'Why is Kontent.ai the best headless CMS',
    'Content creator’s role in content modeling',
    'Master the art of modular content',
    'Get modeling inspiration from others',
    'Typical content modeling red flags',
  ];
  const durations = [
    averageTimeInSeconds * 1.5,
    averageTimeInSeconds / 2,
    averageTimeInSeconds,
    averageTimeInSeconds * 1.5,
    averageTimeInSeconds / 2,
  ];

  return itemIds.map((id, index) => ({
    itemWithVariant: {
      ...emptyListingContentItem,
      item: {
        ...emptyItemData,
        id,
        name: names[index] ?? '',
        typeId: contentTypeId,
      },
      variant: {
        ...emptyListingVariantData,
        id: {
          itemId: id,
          variantId: DefaultVariantId,
        },
        assignment: {
          ...emptyAssignment,
          assignees: new Set<IUserIdentifier>([{ userId }]),
        },
        isComplete: true,
      },
    },
    durationInSeconds: durations[index] ?? 0,
  }));
};

const getDemoTimeIntervalModifier = (
  timeInterval: TimeInterval,
  contentTypeId: Uuid | null,
): number => {
  const modifier = contentTypeId ? 0.5 : 1;

  switch (timeInterval) {
    case TimeInterval.CurrentMonth:
      return modifier;
    case TimeInterval.PastThirtyDays:
      return modifier * 1.3;
    case TimeInterval.CurrentWeek:
      return modifier * 0.85;
    default:
      return modifier;
  }
};

const getDemoTimeSpentServerModel = (
  workflows: ReadonlyArray<Workflow>,
  timeInterval: TimeInterval,
  contentTypeId: Uuid | null,
) => {
  const array: Array<TimeSpentInWorkflowStepRecord> = [];
  const timeIntervalModifier = getDemoTimeIntervalModifier(timeInterval, contentTypeId);

  workflows.forEach((workflow, index) => {
    const workflowPaceDuration =
      demoDefaultContentPaceDurationInSeconds * (index + 1) * timeIntervalModifier;

    array.push({
      workflowId: workflow.id,
      workflowStatusId: workflow.scheduledStep.id,
      durationInSeconds: demoScheduledStepPaceDurationInSeconds,
    });

    workflow.steps.forEach((step, nestedIndex) => {
      array.push({
        workflowId: workflow.id,
        workflowStatusId: step.id,
        durationInSeconds: workflowPaceDuration / (nestedIndex + 1),
      });
    });
  });

  return array;
};

export const getDemoContentPaceTimeSpentByWorkflowData = (
  workflows: ReadonlyArray<Workflow>,
  timeInterval: TimeInterval,
  contentTypeId: Uuid | null,
): TimeSpentInWorkflowStepByWorkflow | null =>
  getTimeSpentInWorkflowStepByWorkflowFromServerModel(
    getDemoTimeSpentServerModel(workflows, timeInterval, contentTypeId),
  );
