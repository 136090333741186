import { delay } from '@kontent-ai/utils';
import { GetState } from '../../../../../@types/Dispatcher.type.ts';
import { logError } from '../../../../../_shared/utils/logError.ts';
import { areAnyOperationsPending } from '../selectors/areAnyOperationsPending.ts';

const PendingCheckInterval = 100;

export const waitForPendingOperations = async (getState: GetState, waitErrorMessage?: string) => {
  let shouldLogError = !!waitErrorMessage;
  while (areAnyOperationsPending(getState())) {
    if (shouldLogError && waitErrorMessage) {
      logError(waitErrorMessage);
      shouldLogError = false;
    }
    await delay(PendingCheckInterval);
  }
};
