import { memoize } from '@kontent-ai/memoization';
import { notNull } from '@kontent-ai/utils';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../@types/Dispatcher.type.ts';
import { trackUserEventWithData } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { DateRange } from '../../../../../_shared/models/DateRange.type.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { CalendarEventTypes } from '../../../../../_shared/models/TrackUserEventData.ts';
import { ContentItemFilterOrigin } from '../../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { isContentItemFilterInitialized } from '../../../../contentInventory/content/features/ListingFilter/containers/ContentItemFilter.tsx';
import { clearListingFilter } from '../../../../itemEditor/actions/contentActions.ts';
import {
  calendarDrillDownDateSelected,
  calendarItemDetailsSelected,
} from '../../actions/calendarActions.ts';
import {
  filterCalendar,
  leaveCalendar,
  loadItemsMatchingFilterWithVariantActiveInPeriod,
} from '../../actions/thunkCalendarActions.ts';
import {
  Calendar as CalendarComponent,
  ICalendarDispatchProps,
  ICalendarStateProps,
} from '../../components/Calendar/Calendar.tsx';
import { convertContentItemsToCalendarEvents } from '../../selectors/calendarSelectors.tsx';

const memoizedConvertContentItemsToCalendarEvents = memoize.maxOne(
  convertContentItemsToCalendarEvents,
);

const mapStateToProps = (state: IStore): ICalendarStateProps => {
  const {
    data: { listingContentItems },
    contentApp: {
      listingUi: { filterStatus },
    },
    calendarApp: {
      calendarView,
      itemsLoadingStatus,
      selectedPeriod,
      orderedItemIds,
      wasDataCountLimitExceeded,
      selectedArrangement,
    },
  } = state;

  const items = orderedItemIds
    .toArray()
    .map((id) => listingContentItems.byId.get(id) ?? null)
    .filter(notNull);

  const events = memoizedConvertContentItemsToCalendarEvents(...items);

  const isFilterInitialized =
    isContentItemFilterInitialized(ContentItemFilterOrigin.Calendar, filterStatus) &&
    itemsLoadingStatus !== LoadingStatus.InitialEmpty;

  return {
    calendarView,
    events,
    isCurrentMonthLoaded: itemsLoadingStatus === LoadingStatus.Loaded,
    isFilterInitialized,
    range: selectedPeriod,
    selectedArrangement,
    wasItemsLimitExceeded: wasDataCountLimitExceeded,
    listingItemsLoadingStatus: itemsLoadingStatus,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ICalendarDispatchProps => {
  return {
    onLeave: () => dispatch(leaveCalendar()),
    onPeriodChanged: (period: DateRange) =>
      dispatch(loadItemsMatchingFilterWithVariantActiveInPeriod(period)),
    onDrillDownDateSelected: (date: DateTimeStamp) => dispatch(calendarDrillDownDateSelected(date)),
    onItemDetailSelected: (itemId: Uuid, date: DateTimeStamp) => {
      dispatch(calendarItemDetailsSelected(itemId, date));
      dispatch(
        trackUserEventWithData(TrackedEvent.Calendar, {
          action: CalendarEventTypes.ItemDetailsSelected,
        }),
      );
    },
    onFilterChange: () => dispatch(filterCalendar()),
    onClearFilter: () => dispatch(clearListingFilter()),
  };
};

export const Calendar: React.ComponentType = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CalendarComponent);
