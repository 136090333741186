import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { getUsersInfo } from '../../../../../../../_shared/utils/usersUtils.ts';
import { abandonAssignmentSectionChange } from '../../../actions/contentItemEditingActions.ts';
import { updateContributors } from '../../../actions/thunkContentItemEditingActions.ts';
import { LeafActionFormProps } from '../../../components/editingActions/EditingLeafActionForm.tsx';
import { ContributorsForm as ContributorsFormComponent } from '../../../components/editingActions/leafActionForms/ContributorsForm.tsx';
import { AssignmentSections } from '../../../constants/AssignmentSections.ts';

export const ContributorsForm: React.FC<LeafActionFormProps> = ({
  actionOrigin,
  useCustomHandleClickOutside,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const usersById = useSelector((s) => s.data.users.usersById);

  const initialContributorsIdentifiers = useSelector((s) => {
    const editedContentItemVariant = s.contentApp.editedContentItemVariant;

    return editedContentItemVariant ? editedContentItemVariant.assignment.assignees : null;
  });

  if (!initialContributorsIdentifiers) {
    return null;
  }

  const initialContributors = getUsersInfo(initialContributorsIdentifiers, usersById);

  return (
    <ContributorsFormComponent
      actionOrigin={actionOrigin}
      initialContributors={initialContributors}
      onCancel={() => dispatch(abandonAssignmentSectionChange(AssignmentSections.Contributors))}
      onConfirm={(values) =>
        dispatch(updateContributors(history, values.contributors, actionOrigin))
      }
      useCustomHandleClickOutside={useCustomHandleClickOutside}
    />
  );
};

ContributorsForm.displayName = 'ContributorsForm';
