import { InvariantException } from '@kontent-ai/errors';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { modalDismissed } from '../../../../../_shared/actions/sharedActions.ts';
import { ModalDialogType } from '../../../../../_shared/constants/modalDialogType.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ContentItemEditingEventOrigins } from '../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { getEditedContentItem } from '../../../../../_shared/selectors/getEditedContentItem.ts';
import { compose } from '../../../../../_shared/utils/func/compose.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { useAvailableCollectionsForSelectedLanguage } from '../../../../contentInventory/content/features/ContentItemInventory/selectors/useAvailableCollections.ts';
import { getCollection } from '../../../../contentInventory/content/selectors/getCollection.ts';
import { RouteProps } from '../../ContentItemEditing/utils/redirectToItem.ts';
import {
  duplicateItemWithContent,
  duplicateItemWithoutContent,
} from '../actions/thunkDuplicateItemToCollectionActions.ts';
import { DuplicateToCollectionDialog } from '../components/DuplicateToCollectionDialog.tsx';

type DuplicateItemAction = (destinationCollectionId: Uuid) => ThunkPromise;

const createDuplicateItemAction = (
  modalDialogType: ModalDialogType,
  routeProps: RouteProps,
): DuplicateItemAction => {
  switch (modalDialogType) {
    case ModalDialogType.DuplicateItemWithoutContent:
      return (destinationCollectionId: Uuid) =>
        duplicateItemWithoutContent(
          routeProps,
          ContentItemEditingEventOrigins.MoreActions,
          destinationCollectionId,
        );

    case ModalDialogType.DuplicateItemWithContent:
      return (destinationCollectionId: Uuid) =>
        duplicateItemWithContent(routeProps, destinationCollectionId);

    default:
      throw InvariantException(
        `This component should be rendered only for item duplicate actions. Modal dialog of ${modalDialogType} type is not supported.`,
      );
  }
};

const DuplicateToCollectionDialogContainer: React.FC = () => {
  const dispatch = useDispatch();
  const routeProps: RouteProps = { history: useHistory(), location: useLocation() };

  const dialogType = useSelector((state) => state.sharedApp.modalDialog.type);
  const duplicateItem = createDuplicateItemAction(dialogType, routeProps);

  const onDuplicate = compose(dispatch, duplicateItem);
  const onClose = compose(dispatch, modalDismissed);

  const editedItemName = useSelector((s) => getEditedContentItem(s).name);
  const collectionName = useSelector((s) => {
    const item = getEditedContentItem(s);
    return getCollection(s, item.collectionId)?.name || '';
  });
  const editedContentItemTypeId = useSelector(
    (s) => getEditedContentItem(s).editedContentItemTypeId,
  );
  const collectionOptions = useAvailableCollectionsForSelectedLanguage(
    Capability.CreateContent,
    editedContentItemTypeId ?? '',
  );

  if (!editedContentItemTypeId) {
    return null;
  }

  return (
    <DuplicateToCollectionDialog
      collectionName={collectionName}
      collectionOptions={collectionOptions}
      itemName={editedItemName}
      onClose={onClose}
      onDuplicate={onDuplicate}
    />
  );
};

DuplicateToCollectionDialogContainer.displayName = 'DuplicateToCollectionDialogContainer';

export { DuplicateToCollectionDialogContainer as DuplicateToCollectionDialog };
