import { Collection, chronologically, notNull } from '@kontent-ai/utils';
import React, { useEffect } from 'react';
import { modalOpened } from '../../actions/sharedActions.ts';
import { ModalDialogType } from '../../constants/modalDialogType.ts';
import { useUserFeedback } from '../../hooks/UserFeedback/useUserFeedback.ts';
import { useDispatch } from '../../hooks/useDispatch.ts';
import { useSelector } from '../../hooks/useSelector.ts';
import { AiFeedbackTimestamps } from '../../models/UserPropertiesServerKeys.ts';
import { UserFeedbackSource } from '../../models/events/UserFeedbackEventData.type.ts';
import { getCurrentUser } from '../../selectors/getCurrentUser.ts';
import { IStore } from '../../stores/IStore.type.ts';
import { hasWeeksPeriodPassed } from '../../utils/dateTime/timeUtils.ts';
import { isEmployee } from '../../utils/subscriptionUsageUtils.ts';

const isEnabled = false;
const showAiFeedbackModalAfterWeeks = 2;

const shouldShowAiFeedbackModalAutomatically = (state: IStore): boolean => {
  const {
    sharedApp: {
      userProperties: {
        aiFeedbackTimestamps: { answeredAt, dismissedAt, displayedAt },
      },
    },
  } = state;

  if (!isEnabled) {
    return false;
  }

  if (isEmployee(getCurrentUser(state).email)) {
    return false;
  }

  if (!answeredAt && !dismissedAt && !displayedAt) {
    return false;
  }

  const newestDate = Collection.getLast(
    [answeredAt, dismissedAt, displayedAt].filter(notNull).sort(chronologically),
  );

  return (
    !!newestDate && hasWeeksPeriodPassed(showAiFeedbackModalAfterWeeks, new Date(), newestDate)
  );
};

export const ShowAiFeedbackModalAutomatically: React.FC = () => {
  const shouldShowDialog = useSelector(shouldShowAiFeedbackModalAutomatically);
  const { showUserFeedback } = useUserFeedback(
    UserFeedbackSource.Ai,
    AiFeedbackTimestamps,
    (userProperties) => userProperties.aiFeedbackTimestamps,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (shouldShowDialog) {
      showUserFeedback();
      dispatch(modalOpened(ModalDialogType.AiFeedbackDialog));
    }
  }, [shouldShowDialog, showUserFeedback]);

  return null;
};

ShowAiFeedbackModalAutomatically.displayName = 'ShowAiFeedbackModalAutomatically';
