import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { TextFilter } from '../../../../_shared/components/TextFilter.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { CollectionsFilterStorage } from '../../../../localStorages/collectionsFilterStorage.ts';
import { filterSearchPhraseChanged } from '../actions/baseActions.ts';
import { areActiveElementsDisabled } from '../selectors/areActiveElementsDisabled.ts';

export const CollectionsFilter = () => {
  const dispatch = useDispatch();

  const currentProjectId = useSelector((s) => s.sharedApp.currentProjectId);
  const disableActiveElements = useSelector(areActiveElementsDisabled);

  const filterSearchPhrase = useSelector((s) => s.collectionsApp.filterSearchPhrase);

  const [value, setValue] = useState(() => CollectionsFilterStorage.load(currentProjectId));

  const setFilterSearchPhraseDebounced = useDebouncedCallback((newValue: string) => {
    if (newValue !== filterSearchPhrase) {
      dispatch(filterSearchPhraseChanged(newValue));
      CollectionsFilterStorage.save(newValue, currentProjectId);
    }
  }, 100);

  const onChange = (newValue: string) => {
    setValue(newValue);
    setFilterSearchPhraseDebounced(newValue);
  };

  return (
    <TextFilter
      autofocus
      disabled={disableActiveElements}
      onChange={onChange}
      placeholder="Filter"
      text={value}
    />
  );
};
