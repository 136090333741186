import { Inline } from '@kontent-ai/component-library/Inline';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { forwardRef, HTMLAttributes, PropsWithChildren } from 'react';
import {
  NotificationBarAlert,
  NotificationBarBlank,
  NotificationBarInfo,
  NotificationBarInfoEmphasis,
  NotificationBarSuccess,
  NotificationBarWarning,
} from '../../../component-library/components/NotificationBar/NotificationBar.tsx';
import { IconName } from '../constants/iconEnumGenerated.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../utils/dataAttributes/DataUiAttributes.ts';

const Tools = (props: PropsWithChildren) => (
  <Inline
    spacing={Spacing.S}
    css={`
      & .btn-wrapper {
        margin: 0;
      }
    `}
    {...props}
  />
);

export enum NotificationBarType {
  Error = 'error',
  InfoLight = 'info-light',
  InfoDark = 'info-dark',
  Success = 'success',
  Warning = 'warning',
  Blank = 'blank',
}

const NotificationBarTypeToFlavouredNotificationBarMap = {
  [NotificationBarType.Blank]: NotificationBarBlank,
  [NotificationBarType.Error]: NotificationBarAlert,
  [NotificationBarType.InfoDark]: NotificationBarInfoEmphasis,
  [NotificationBarType.InfoLight]: NotificationBarInfo,
  [NotificationBarType.Success]: NotificationBarSuccess,
  [NotificationBarType.Warning]: NotificationBarWarning,
} satisfies Record<
  NotificationBarType,
  | typeof NotificationBarAlert
  | typeof NotificationBarWarning
  | typeof NotificationBarSuccess
  | typeof NotificationBarInfo
>;

export type INotificationBarStateProps = HTMLAttributes<HTMLDivElement> & {
  readonly className?: string;
  readonly iconName?: IconName;
  readonly message?: React.ReactNode;
  readonly tools?: OneOrMore<React.ReactNode>;
  readonly type: NotificationBarType;
  readonly uiElement?: DataUiElement;
};

export interface INotificationBarDispatchProps {
  readonly onClosePanel?: () => void;
}

export type NotificationBarProps = INotificationBarStateProps & INotificationBarDispatchProps;

/**
 * @deprecated Use NotificationBar from Component Library
 */
export const NotificationBar = forwardRef<HTMLDivElement, NotificationBarProps>(
  ({ iconName, message, onClosePanel, tools, type, uiElement, ...otherProps }, ref) => {
    if (!message) {
      return null;
    }

    const NotificationBarComponent = NotificationBarTypeToFlavouredNotificationBarMap[type];

    return (
      <NotificationBarComponent
        onDismiss={onClosePanel as () => void}
        ref={ref}
        actions={{ alignment: 'left', renderActions: () => <Tools>{tools}</Tools> }}
        {...(uiElement ? getDataUiElementAttribute(uiElement) : {})}
        {...otherProps}
      >
        {message}
      </NotificationBarComponent>
    );
  },
);

NotificationBar.displayName = 'NotificationBar';
