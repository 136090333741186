import { Box } from '@kontent-ai/component-library/Box';
import { Checkbox, CheckboxState } from '@kontent-ai/component-library/Checkbox';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, Typography } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { FieldPathByValue, useController } from 'react-hook-form';
import { HookFormProps } from '../../../../../_shared/types/hookFormProps.ts';
import { FormFieldError } from '../../../../../_shared/uiComponents/FormAlert/FormFieldError.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { showFieldError } from '../../../../../_shared/utils/validation/showFieldError.ts';
import { MapiKeyFormShape, Permissions } from '../../validation/mapiKeyValidationConfig.ts';

type Props = {
  readonly formProps: HookFormProps<MapiKeyFormShape>;
  readonly isProjectManager: boolean;
  readonly isKeyTargetedToAllEnvironments: boolean;
  readonly name: FieldPathByValue<MapiKeyFormShape, Permissions>;
};

const getCheckboxState = (
  showError: boolean,
  shouldBeDisabled: boolean,
  isProjectManager: boolean,
): CheckboxState => {
  if (shouldBeDisabled) {
    return 'disabled';
  }

  if (showError) {
    return 'alert';
  }

  return isProjectManager ? 'default' : 'disabled';
};

const getCheckboxTooltip = (isProjectManager: boolean, defaultCheckboxTooltip: string): string =>
  isProjectManager ? defaultCheckboxTooltip : 'Only Project managers can change this limitation.';

export const ValidatedPermissionsCheckboxes: React.FC<Props> = ({
  isProjectManager,
  isKeyTargetedToAllEnvironments,
  formProps,
  name,
}) => {
  const {
    field,
    fieldState: { error },
    formState,
  } = useController({
    control: formProps.control,
    name,
  });

  const showError = showFieldError(formState, error);

  const {
    canManageAssets,
    canManageContent,
    canManageContentModel,
    canManageEnvironmentSettings,
    canManageEnvironments,
    canReadAssets,
    canReadContent,
  } = field.value;

  const onToggleReadContent = (value: boolean) => {
    field.onChange({
      ...field.value,
      canReadContent: value,
    });
  };

  const onToggleManageContent = (value: boolean) => {
    if (value) {
      field.onChange({
        ...field.value,
        canReadContent: true,
        canManageContent: true,
      });
    } else {
      field.onChange({
        ...field.value,
        canManageContent: false,
      });
    }
  };

  const onToggleReadAssets = (value: boolean) => {
    field.onChange({
      ...field.value,
      canReadAssets: value,
    });
  };

  const onToggleManageAssets = (value: boolean) => {
    if (value) {
      field.onChange({
        ...field.value,
        canReadAssets: true,
        canManageAssets: true,
      });
    } else {
      field.onChange({
        ...field.value,
        canManageAssets: false,
      });
    }
  };

  const onToggleManageContentModel = (value: boolean) => {
    field.onChange({
      ...field.value,
      canManageContentModel: value,
    });
  };

  const onToggleManageEnvironmentSettings = (value: boolean) => {
    field.onChange({
      ...field.value,
      canManageEnvironmentSettings: value,
    });
  };

  const onToggleManageEnvironments = (value: boolean) => {
    field.onChange({
      ...field.value,
      canManageEnvironments: value,
    });
  };

  return (
    <Stack spacing={Spacing.XL}>
      <Box typography={Typography.LabelLarge}>Set the API key permissions:</Box>
      <Stack
        spacing={Spacing.S}
        {...getDataUiElementAttribute(DataUiElement.ManagementApiPermissionsCard)}
      >
        <Checkbox
          checked={canReadContent}
          caption="Read content items and their variants in all languages regardless of content group and content type permissions."
          checkboxState={getCheckboxState(showError, canManageContent, isProjectManager)}
          tooltipPlacement="top-start"
          tooltipText={getCheckboxTooltip(
            isProjectManager,
            canManageContent
              ? 'To deselect "Read content" permission, you have to deselect "Edit, create, and delete content" permission first.'
              : '',
          )}
          onToggle={onToggleReadContent}
          {...getDataUiElementAttribute(DataUiElement.ManagementApiReadContentPermissionCheckbox)}
        >
          Read content
        </Checkbox>
        <Checkbox
          checked={canManageContent}
          caption="Create, edit, and delete content items and their variants regardless of content group and content type permissions."
          checkboxState={getCheckboxState(showError, false, isProjectManager)}
          tooltipPlacement="top-start"
          tooltipText={getCheckboxTooltip(isProjectManager, '')}
          onToggle={onToggleManageContent}
          {...getDataUiElementAttribute(DataUiElement.ManagementApiManageContentPermissionCheckbox)}
        >
          Create, edit, and delete content
        </Checkbox>
        <Checkbox
          checked={canReadAssets}
          caption="Read assets, asset folders, and asset renditions regardless of collection permissions."
          checkboxState={getCheckboxState(showError, canManageAssets, isProjectManager)}
          tooltipPlacement="top-start"
          tooltipText={getCheckboxTooltip(
            isProjectManager,
            canManageAssets
              ? 'To deselect "Read assets" permission, you have to deselect "Edit, create, and delete assets" permission first.'
              : '',
          )}
          onToggle={onToggleReadAssets}
          {...getDataUiElementAttribute(DataUiElement.ManagementApiReadAssetsPermissionCheckbox)}
        >
          Read assets
        </Checkbox>
        <Checkbox
          checked={canManageAssets}
          caption="Create, edit, and delete assets, asset folders, and asset renditions regardless of collection permissions."
          checkboxState={getCheckboxState(showError, false, isProjectManager)}
          tooltipPlacement="top-start"
          tooltipText={getCheckboxTooltip(isProjectManager, '')}
          onToggle={onToggleManageAssets}
          {...getDataUiElementAttribute(DataUiElement.ManagementApiManageAssetsPermissionCheckbox)}
        >
          Create, edit, and delete assets
        </Checkbox>
        <Checkbox
          checked={canManageContentModel}
          caption="Create, read, edit, and delete assets types, content types, content type snippets, and taxonomies."
          checkboxState={getCheckboxState(showError, false, isProjectManager)}
          tooltipPlacement="top-start"
          tooltipText={getCheckboxTooltip(isProjectManager, '')}
          onToggle={onToggleManageContentModel}
          {...getDataUiElementAttribute(
            DataUiElement.ManagementApiManageContentModelPermissionCheckbox,
          )}
        >
          Manage content model
        </Checkbox>
        <Checkbox
          checked={canManageEnvironmentSettings}
          caption="Manage collections, localization, preview URLs, spaces, webhooks, and workflows."
          checkboxState={getCheckboxState(showError, false, isProjectManager)}
          tooltipPlacement="top-start"
          tooltipText={getCheckboxTooltip(isProjectManager, '')}
          onToggle={onToggleManageEnvironmentSettings}
          {...getDataUiElementAttribute(
            DataUiElement.ManagementApiManageEnvironmentSettingsPermissionCheckbox,
          )}
        >
          Manage environment settings
        </Checkbox>
        <Checkbox
          checked={canManageEnvironments}
          caption="Clone, rename, swap, and delete environments."
          checkboxState={getCheckboxState(
            showError,
            !isKeyTargetedToAllEnvironments,
            isProjectManager,
          )}
          tooltipPlacement="top-start"
          tooltipText={getCheckboxTooltip(
            isProjectManager,
            isKeyTargetedToAllEnvironments
              ? ''
              : 'To enable this permission, set the Limitations to All environments.',
          )}
          onToggle={onToggleManageEnvironments}
          {...getDataUiElementAttribute(
            DataUiElement.ManagementApiManageEnvironmentsPermissionCheckbox,
          )}
        >
          Manage environments
        </Checkbox>
        <FormFieldError isDisplayed={showError}>{error?.message}</FormFieldError>
      </Stack>
    </Stack>
  );
};

ValidatedPermissionsCheckboxes.displayName = 'ValidatedPermissionsCheckboxes';
