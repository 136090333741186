import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { getAvailableContentTypesForContentComponent } from '../../../../itemEditor/features/NewContentItem/utils/getAvailableContentTypesForContentComponent.ts';

export const getContentComponentTooltip = (state: IStore): string => {
  const contentTypesForComponent = getAvailableContentTypesForContentComponent(state);

  const firstContentType = contentTypesForComponent.first();

  if (contentTypesForComponent.size === 1 && firstContentType) {
    return `Insert new ${firstContentType.name}`;
  }

  return 'Insert new component';
};
