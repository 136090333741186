import { ThunkFunction } from '../../../@types/Dispatcher.type.ts';
import { GetAuthToken } from '../../../_shared/types/GetAuthToken.type.ts';
import {
  disableBrowserSpecificFeatures,
  ignoreInsertKey,
  logUnhandledPromiseRejection,
  registerUrlFactory,
  setupComponentLibrary,
} from '../../../_shared/utils/bootstrap.ts';
import { bootstrapContentComponentElementComponents } from '../../../applications/itemEditor/features/ContentComponent/utils/contentComponentElementComponentBootstrapper.ts';
import { bootstrapExpandedElementComponents } from '../../../applications/itemEditor/features/LinkedItems/utils/expandedElementComponentBootstrapper.ts';

interface IDeps {
  readonly initializeRepositories: (getAuthToken: GetAuthToken) => ThunkFunction;
}

export const createBootstrapAction =
  ({ initializeRepositories }: IDeps) =>
  (getAuthToken: GetAuthToken): ThunkFunction =>
  (dispatch) => {
    setupComponentLibrary();
    registerUrlFactory();
    logUnhandledPromiseRejection();
    ignoreInsertKey();
    disableBrowserSpecificFeatures();
    // CMP-473 this is here because of cyclic dependencies within expandable linked items
    bootstrapExpandedElementComponents();
    // CMP-704 this is here because of cyclic dependencies within nested content components in rich text element
    bootstrapContentComponentElementComponents();
    dispatch(initializeRepositories(getAuthToken));
  };
