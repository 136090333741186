import { IListingContentItem } from '../../data/models/listingContentItems/IListingContentItem.ts';
import { ContentItemId } from '../models/ContentItemId.type.ts';
import { IStore } from '../stores/IStore.type.ts';
import { getListingContentItem as getContentItem } from '../utils/getListingContentItem.ts';
import { getSelectedLanguageId } from './getSelectedLanguageId.ts';

export const getListingContentItem = (
  state: IStore,
  contentItemId: ContentItemId,
): IListingContentItem | null => {
  const selectedLanguageId = getSelectedLanguageId(state);
  const { defaultById, byId } = state.data.listingContentItems;

  return selectedLanguageId
    ? getContentItem(contentItemId, selectedLanguageId, byId, defaultById)
    : null;
};
