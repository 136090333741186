import { toArray } from '@kontent-ai/utils';
import React, { PropsWithChildren, ComponentProps, useId } from 'react';
import { SrOnly } from '../../styles/srOnly.tsx';
import { IconSize } from '../../tokens/quarks/iconSize.ts';
import { Icons } from '../Icons/components/icons.ts';
import { Tooltip } from '../Tooltip/Tooltip.tsx';
import { MenuItem } from './MenuItem.tsx';

const outsideLink = (
  <Tooltip placement="top-start" tooltipText="Opens in a new tab">
    <Icons.ArrowRightTopSquare size={IconSize.S} />
  </Tooltip>
);

type MenuOutwardLinkProps = ComponentProps<typeof MenuItem> &
  Readonly<{
    href: string;
  }>;

export const MenuOutwardLink = React.forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<MenuOutwardLinkProps>
>((props, forwardedRef) => {
  const { trailingElements, ...otherProps } = props;

  const adjustedTrailingElements = [...toArray(trailingElements), outsideLink];

  const descriptionId = useId();

  return (
    <>
      <MenuItem
        ref={forwardedRef}
        {...{
          target: '_blank',
          rel: 'noopener noreferrer',
        }}
        trailingElements={adjustedTrailingElements}
        aria-describedby={descriptionId}
        {...otherProps}
      />
      <SrOnly id={descriptionId}>Opens in a new tab</SrOnly>
    </>
  );
});
