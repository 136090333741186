import React from 'react';
import {
  UnpublishingValidationResult,
  validateUnpublishingConfiguration,
} from '../../../../../applications/itemEditor/features/ContentItemEditing/utils/workflow/unpublishingValidationUtils.ts';
import { useDispatch } from '../../../../hooks/useDispatch.ts';
import { useSelector } from '../../../../hooks/useSelector.ts';
import { DataUiWorkflowAction } from '../../../../utils/dataAttributes/DataUiAttributes.ts';
import {
  changeScheduleToUnpublishMethod,
  scheduledToUnpublishChanged,
} from '../../actions/changeWorkflowStepModalActions.ts';
import { ScheduleMethodSection } from '../../components/Elements/ScheduleMethodSection.tsx';
import { ScheduleMethod } from '../../constants/scheduleMethods.ts';
import { DateIsInPastError } from '../../constants/uiConstants.ts';
import { IChangeWorkflowStepModalData } from '../../reducers/IChangeWorkflowStepModalData.ts';

export const UnpublishMethodSection: React.FC = () => {
  const dispatch = useDispatch();

  const error = useSelector((s) => getError(s.contentApp.changeWorkflowStepModalData));
  const scheduleMethod = useSelector(
    (s) => s.contentApp.changeWorkflowStepModalData.scheduleMethod,
  );
  const scheduledToUnpublishAt = useSelector(
    (s) => s.contentApp.changeWorkflowStepModalData.scheduledToUnpublishAt,
  );
  const scheduledUnpublishDisplayTimeZone = useSelector(
    (s) => s.contentApp.changeWorkflowStepModalData.scheduledUnpublishDisplayTimeZone,
  );

  return (
    <ScheduleMethodSection
      onScheduleMethodChange={(method: ScheduleMethod) =>
        dispatch(changeScheduleToUnpublishMethod(method))
      }
      onScheduleChange={(scheduledTo: string | null, timeZoneId: string) =>
        dispatch(scheduledToUnpublishChanged(scheduledTo, timeZoneId))
      }
      scheduledAt={scheduledToUnpublishAt}
      scheduleDisplayTimeZone={scheduledUnpublishDisplayTimeZone}
      selectedScheduleMethod={scheduleMethod}
      invalidDateError={error}
      options={options}
      sectionTitle="Unpublishing"
    />
  );
};

UnpublishMethodSection.displayName = 'UnpublishMethodSection';

const options = [
  {
    id: ScheduleMethod.Now,
    label: 'Unpublish now',
    dataUiAttribute: DataUiWorkflowAction.SelectNow,
  },
  {
    id: ScheduleMethod.Schedule,
    label: 'Schedule unpublish',
    dataUiAttribute: DataUiWorkflowAction.SelectSchedule,
  },
];

const getError = (changeWorkflowStepModalData: IChangeWorkflowStepModalData) => {
  const unpublishingValidationResult = validateUnpublishingConfiguration(
    changeWorkflowStepModalData,
  );

  switch (unpublishingValidationResult) {
    case UnpublishingValidationResult.UnpublishDateIsInPast: {
      return DateIsInPastError;
    }
    default: {
      return undefined;
    }
  }
};
