import { AiActionName } from '../../../repositories/serverModels/ai/AiActionName.type.ts';
import { ErrorOperationResponseMessage } from '../../../repositories/serverModels/ai/AiServerModels.response.ts';
import { CategorizeItemResponseMessage } from '../../../repositories/serverModels/ai/actions/AiServerModels.categorizeItem.ts';
import { ChangeToneResponseMessage } from '../../../repositories/serverModels/ai/actions/AiServerModels.changeTone.ts';
import { CreateTitleResponseMessage } from '../../../repositories/serverModels/ai/actions/AiServerModels.createTitle.ts';
import { DescribeImageResponseMessage } from '../../../repositories/serverModels/ai/actions/AiServerModels.describeImage.ts';
import { ImproveContentByBriefResponseMessage } from '../../../repositories/serverModels/ai/actions/AiServerModels.improveContentByBrief.ts';
import { InlinePlainTextInstructionResponseMessage } from '../../../repositories/serverModels/ai/actions/AiServerModels.inlinePlainTextInstruction.ts';
import { InlineRichTextInstructionResponseMessage } from '../../../repositories/serverModels/ai/actions/AiServerModels.inlineRichTextInstruction.ts';
import { MakeShorterResponseMessage } from '../../../repositories/serverModels/ai/actions/AiServerModels.makeShorter.ts';
import { MatchWritingStyleOfItemResponseMessage } from '../../../repositories/serverModels/ai/actions/AiServerModels.matchWritingStyleOfItem.ts';
import { SummarizeResponseMessage } from '../../../repositories/serverModels/ai/actions/AiServerModels.summarize.ts';
import { TranslateContentResponseMessage } from '../../../repositories/serverModels/ai/actions/AiServerModels.translateContent.ts';
import { VariantTranslationTaskParams } from './VariantTranslationTaskParams.type.ts';

export interface ISignalRConnectionCallbacks {
  readonly onConnected: () => void;
  readonly onConnectingFailed: (reason: SignalRConnectionFailReason) => void;
  readonly onNotified: <TNotificationType extends keyof Notifications = never>(
    type: TNotificationType,
    args: Notifications[TNotificationType]['payload'],
  ) => void;
}

export enum SignalRConnectionFailReason {
  StartTimeout = 'StartTimeout',
  TooManyStartTimeouts = 'TooManyStartTimeouts',
  Unspecified = 'Unspecified',
}

export enum ServerMethod {
  EnsureClientState = 'ensureClientState',
  OnElementLockReleased = 'onElementLockReleased',
  OnElementLockRequested = 'onElementLockRequested',
  OnItemEntered = 'onItemEntered',
  OnItemLeft = 'onItemLeft',
}

export type ServerMethods = {
  [ServerMethod.EnsureClientState]: {
    readonly arguments: {
      readonly methodName: ServerMethod.EnsureClientState;
      readonly payload: {
        readonly itemId: Uuid;
        readonly variantId: Uuid;
        readonly elementId: Uuid | null;
      };
    };
    readonly returnType: undefined;
  };
  [ServerMethod.OnElementLockReleased]: {
    readonly arguments: {
      readonly methodName: ServerMethod.OnElementLockReleased;
      readonly payload: {
        readonly itemId: Uuid;
        readonly variantId: Uuid;
        readonly elementId: Uuid;
      };
    };
    readonly returnType: undefined;
  };
  [ServerMethod.OnElementLockRequested]: {
    readonly arguments: {
      readonly methodName: ServerMethod.OnElementLockRequested;
      readonly payload: {
        readonly itemId: Uuid;
        readonly variantId: Uuid;
        readonly elementId: Uuid;
      };
    };
    readonly returnType: ElementLockStatus | null;
  };
  [ServerMethod.OnItemEntered]: {
    readonly arguments: {
      readonly methodName: ServerMethod.OnItemEntered;
      readonly payload: {
        readonly itemId: Uuid;
        readonly variantId: Uuid;
      };
    };
    readonly returnType: undefined;
  };
  [ServerMethod.OnItemLeft]: {
    readonly arguments: {
      readonly methodName: ServerMethod.OnItemLeft;
      readonly payload: {
        readonly itemId: Uuid;
        readonly variantId: Uuid;
      };
    };
    readonly returnType: undefined;
  };
};

export enum ElementLockStatus {
  LockFailed = 0,
  LockAcquired = 1,
}

export enum Notification {
  ContentChange = 'contentChange',
  ItemLiveUsersChange = 'itemLiveUsersChange',
  LockedElementsChange = 'lockedElementsChange',
  AiOperationResponse = 'aiOperationResponse',
  VariantTranslationTaskNotification = 'variantTranslationTaskNotification',
}

export type Notifications = {
  [Notification.ContentChange]: {
    readonly type: Notification.ContentChange;
    readonly payload: {
      readonly itemId: Uuid;
      readonly variantId?: Uuid;
      readonly collectionId: Uuid;
      readonly appInstanceId: Uuid;
      readonly projectId: Uuid;
      readonly changeBy?: Uuid;
      readonly changeByManageApi: boolean;
      readonly changeReason: string;
      readonly difference: {
        readonly assignmentDifferences: ReadonlyArray<AssignmentComparisonDifference>;
        readonly changedElements: UuidArray;
        readonly codenameChanged: boolean;
        readonly collectionChanged: boolean;
        readonly nameChanged: boolean;
        readonly sitemapChanged: boolean;
      };
    };
  };
  [Notification.ItemLiveUsersChange]: {
    readonly type: Notification.ItemLiveUsersChange;
    readonly payload: {
      readonly itemId: Uuid;
      readonly itemLiveUserIds: ReadonlyArray<UserId>;
      readonly variantId: Uuid;
    };
  };
  [Notification.LockedElementsChange]: {
    readonly type: Notification.LockedElementsChange;
    readonly payload: {
      readonly itemId: Uuid;
      readonly variantId: Uuid;
      readonly lockedElementSessions: ReadonlyArray<{
        readonly userId: UserId;
        readonly elementId: Uuid;
        readonly validUntil: DateTimeStamp;
      }>;
    };
  };
  [Notification.AiOperationResponse]: {
    readonly type: Notification.AiOperationResponse;
    readonly payload: AnyAiMessagePayload;
  };
  [Notification.VariantTranslationTaskNotification]: {
    readonly type: Notification.VariantTranslationTaskNotification;
    readonly payload: VariantTranslationTaskParams;
  };
};

export enum AssignmentComparisonDifference {
  Workflow = 'workflow',
  WorkflowStep = 'workflow-step',
  PublishScheduleTime = 'publish-schedule-time',
  UnpublishScheduleTime = 'unpublish-schedule-time',
  DueDate = 'due-date',
  Assignee = 'assignee',
  Note = 'note',
}

export type AiActionNameToMessagePayload = {
  [AiActionName.CategorizeItem]: CategorizeItemResponseMessage | ErrorOperationResponseMessage;
  [AiActionName.ChangeTone]: ChangeToneResponseMessage | ErrorOperationResponseMessage;
  [AiActionName.MatchWritingStyleOfItem]:
    | MatchWritingStyleOfItemResponseMessage
    | ErrorOperationResponseMessage;
  [AiActionName.CreateTitle]: CreateTitleResponseMessage | ErrorOperationResponseMessage;
  [AiActionName.DescribeImage]: DescribeImageResponseMessage | ErrorOperationResponseMessage;
  [AiActionName.ImproveContentByBrief]:
    | ImproveContentByBriefResponseMessage
    | ErrorOperationResponseMessage;
  [AiActionName.MakeShorter]: MakeShorterResponseMessage | ErrorOperationResponseMessage;
  [AiActionName.Summarize]: SummarizeResponseMessage | ErrorOperationResponseMessage;
  [AiActionName.RichTextInlineInstruction]:
    | InlineRichTextInstructionResponseMessage
    | ErrorOperationResponseMessage;
  [AiActionName.PlainTextInlineInstruction]:
    | InlinePlainTextInstructionResponseMessage
    | ErrorOperationResponseMessage;
  [AiActionName.TranslateContent]: TranslateContentResponseMessage | ErrorOperationResponseMessage;
};

export type AnyAiMessagePayload = AiActionNameToMessagePayload[AiActionName];
