import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import {
  Webhooks_ListingInit_Finished,
  Webhooks_ListingInit_Started,
} from '../../constants/webhooksActionTypes.ts';

interface IDeps {
  readonly loadWebhooks: (abortSignal?: AbortSignal) => ThunkPromise;
}

const initWebhookListingStarted = { type: Webhooks_ListingInit_Started } as const;
const initWebhookListingFinished = { type: Webhooks_ListingInit_Finished } as const;

export type InitWebhookListingActionsType =
  | typeof initWebhookListingStarted
  | typeof initWebhookListingFinished;

export const createInitWebhookListingAction =
  (deps: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(initWebhookListingStarted);

    await dispatch(deps.loadWebhooks(abortSignal));

    dispatch(initWebhookListingFinished);
  };
