import { Paper, PaperLevel, paperBorderRadius } from '@kontent-ai/component-library/Paper';
import { Spacing, spacingPopupDistance } from '@kontent-ai/component-library/tokens';
import { Placement } from '@kontent-ai/component-library/types';
import { areShallowEqual } from '@kontent-ai/utils';
import { Variation } from '@popperjs/core';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { BasePlacement } from 'tippy.js';
import { ArrowSize } from '../../../../../../../component-library/components/Dialogs/Popover/ArrowSizeEnum.ts';
import { toolbarArrowSize } from '../../../../../../../component-library/components/Dialogs/Popover/tokens.ts';
import {
  IAdjustTippyOptions,
  usePopover,
} from '../../../../../../../component-library/components/Dialogs/Popover/usePopover.tsx';
import { getPlacement } from '../../../../../../../component-library/components/Dialogs/Popover/utils/placementUtils.ts';
import {
  createAddArrow,
  createAddFlipping,
  createAddOffset,
  createAddPreventOverflow,
} from '../../../../../../../component-library/components/Dialogs/Popover/utils/tippyOptionsUtils.ts';
import { ScrollContainerContext } from '../../../../../../../component-library/components/ScrollContainer/ScrollContainerContext.tsx';
import { usePreventOverflowFromScrollContainer } from '../../../../../../../component-library/components/ScrollContainer/usePreventOverflowFromScrollContainer.ts';
import { useEventListener } from '../../../../../../_shared/hooks/useEventListener.ts';
import {
  Callback,
  RegisterCallback,
} from '../../../../../../_shared/types/RegisterCallback.type.ts';
import { ArrowPosition } from '../../../../../../_shared/uiComponents/Popover/Popover.tsx';
import {
  DataUiElement,
  Popovers,
  getDataUiElementAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { compose } from '../../../../../../_shared/utils/func/compose.ts';
import { preventDefault } from '../../../../../../_shared/utils/func/functionalTools.ts';
import { getDomSelection } from '../../../../../../_shared/utils/selectionUtils.ts';
import {
  InlineToolbarPosition,
  ToolbarAttachedTo,
  adjustPositionToReference,
  getInlineToolbarPosition,
  isToolbarDetached,
  tippyOptionsForDetachedInlineToolbar,
} from '../../utils/inlineToolbarPositioningUtils.ts';
import { getToolbarPopperOffset } from '../../utils/toolbarUtils.ts';
import { InlineToolbarAnchor } from '../InlineToolbarAnchor.tsx';

function getBasePlacement(state: IInlineToolbarState): BasePlacement {
  switch (state.attachedTo) {
    case ToolbarAttachedTo.BottomTextFromRight:
      return 'right';
    case ToolbarAttachedTo.TextFromAbove:
      return 'top';
    case ToolbarAttachedTo.TextFromBelow:
      return 'bottom';
    case ToolbarAttachedTo.TopTextFromRight:
      return 'right';

    default:
      // Not used default
      return 'top';
  }
}

function getVariation(state: IInlineToolbarState): Variation | null {
  switch (state.arrowPosition) {
    case ArrowPosition.End:
      return 'end';
    case ArrowPosition.Start:
      return 'start';
    default:
      return null;
  }
}

function getToolbarPlacement(state: IInlineToolbarState): Placement {
  const basePlacement = getBasePlacement(state);
  const variation = getVariation(state);

  return getPlacement([basePlacement, variation]);
}

interface IInlineToolbarState extends InlineToolbarPosition {}

type InlineToolbarProps = {
  readonly editorRef: React.RefObject<HTMLDivElement>;
  readonly registerUpdateToolbarPosition: RegisterCallback<Callback>;
  readonly renderContent: () => React.ReactElement;
};

const initialToolbarState: IInlineToolbarState = {
  arrowPosition: ArrowPosition.Middle,
  orientation: undefined,
  attachedTo: ToolbarAttachedTo.ViewPortUpperSide,
  left: 0,
  top: 0,
};

const updatePositionState = (
  toolbarRef: React.RefObject<HTMLDivElement>,
  editorContentRef: React.RefObject<HTMLDivElement>,
  scrollContainerRef: React.RefObject<Element>,
  updateState: React.Dispatch<React.SetStateAction<IInlineToolbarState>>,
) => {
  updateState((oldState) => {
    const domSelection = getDomSelection();

    if (
      !toolbarRef.current ||
      !editorContentRef.current ||
      !domSelection ||
      domSelection.isCollapsed
    ) {
      return oldState;
    }
    const bodyRectangle = document.body.getBoundingClientRect();
    const editorRectangle = editorContentRef.current.getBoundingClientRect();
    const toolbarRectangle = toolbarRef.current.getBoundingClientRect();
    const scrollContainerRectangle = (
      scrollContainerRef.current ?? document.body
    ).getBoundingClientRect();

    const position = getInlineToolbarPosition(
      domSelection,
      toolbarRectangle,
      bodyRectangle,
      editorRectangle,
      scrollContainerRectangle,
      oldState.arrowPosition,
    );

    if (!position) {
      return oldState;
    }

    const relativePosition = adjustPositionToReference(position, editorRectangle);
    return areShallowEqual(oldState, relativePosition) ? oldState : relativePosition;
  });
};

export const InlineToolbar: React.FC<InlineToolbarProps> = ({
  editorRef,
  registerUpdateToolbarPosition,
  renderContent,
}) => {
  const [toolbarState, setToolbarState] = useState<IInlineToolbarState>(initialToolbarState);
  const toolbarRef = useRef<HTMLDivElement | null>(null);
  const { scrollContainerRef, tippyBoundaryRef } = useContext(ScrollContainerContext);

  const updatePositionStateCallback = useCallback(
    () => updatePositionState(toolbarRef, editorRef, tippyBoundaryRef, setToolbarState),
    [editorRef, tippyBoundaryRef],
  );

  const toolbarRefToForward = useCallback(
    (toolbarElement: HTMLDivElement | null) => {
      toolbarRef.current = toolbarElement;

      if (toolbarElement) {
        // Initial positioning when the toolbar is mounted
        updatePositionStateCallback();
      }
    },
    [updatePositionStateCallback],
  );

  // Position is changed from sticking to text to sticking to view port based on scrolling.
  useEventListener('scroll', updatePositionStateCallback, scrollContainerRef.current);
  useEventListener('resize', updatePositionStateCallback, self);

  useEffect(
    () => registerUpdateToolbarPosition(updatePositionStateCallback),
    [registerUpdateToolbarPosition, updatePositionStateCallback],
  );

  const isDetached = isToolbarDetached(toolbarState.attachedTo);

  const placement = getToolbarPlacement(toolbarState);

  const { preventOverflowModifier, boundaryProps } = usePreventOverflowFromScrollContainer(
    toolbarArrowSize / 2,
  );

  const adjustTippyOptions: IAdjustTippyOptions = isDetached
    ? (options) => ({
        ...options,
        ...tippyOptionsForDetachedInlineToolbar,
        // Even when toolbar is detached, we render the arrow so that transition between detached toolbar and toolbar with arrow positions it properly.
        // That is why we apply the same offset as for positioning with arrow.
        offset: getToolbarPopperOffset,
      })
    : compose(
        createAddFlipping(boundaryProps),
        createAddArrow(Spacing.S),
        createAddPreventOverflow(preventOverflowModifier.options),
        createAddOffset(getToolbarPopperOffset),
      );

  const { Popover, popoverProps, targetProps } = usePopover({
    adjustTippyOptions,
    disabledFocusLock: true,
    isOpen: true,
    placement,
    shouldCloseOnInteractOutside: () => false,
    shouldCloseOnBlur: false,
  });

  const renderToolbar = (ref: React.Ref<HTMLDivElement>) => (
    <Paper component="section" level={PaperLevel.Popout} ref={ref}>
      <div
        className="rte__inline-toolbar"
        // Prevent editor focus lost when toolbar button is clicked.
        onMouseDown={preventDefault}
        onMouseUp={preventDefault}
        {...getDataUiObjectNameAttribute(Popovers.RteInlineToolbar)}
        {...getDataUiElementAttribute(DataUiElement.Popover)}
      >
        {renderContent()}
      </div>
    </Paper>
  );

  return (
    <>
      <InlineToolbarAnchor
        // Even when toolbar is detached, we render the arrow so that transition between detached toolbar and toolbar with arrow positions it properly.
        // That's why we need to compensate the position of the toolbar back to its original point using offsets reverse to the ones applied by the arrow modifier.
        left={toolbarState.left - (isDetached ? toolbarArrowSize / 2 + spacingPopupDistance : 0)}
        top={toolbarState.top + (isDetached ? paperBorderRadius : 0)}
        ref={targetProps.ref}
      />
      <Popover
        {...popoverProps}
        // We hide the arrow when the toolbar is detached
        arrowColor={isDetached ? 'transparent' : undefined}
        arrowSize={ArrowSize.S}
      >
        {renderToolbar(toolbarRefToForward)}
      </Popover>
    </>
  );
};

InlineToolbar.displayName = 'InlineToolbar';
