import { WebSpotlightPreferencesStorageKey } from '../_shared/constants/localStorageKeys.ts';
import {
  WebSpotlightPreviewResolutionType,
  fitToScreenResolution,
} from '../applications/webSpotlight/models/webSpotlightPreviewResolutionType.ts';
import { StorageParams, getProjectSpecificStorage } from './projectSpecificStorage.ts';

export interface IWebSpotlightPreferences {
  readonly customPreviewHeight: number;
  readonly customPreviewScale: number;
  readonly customPreviewWidth: number;
  readonly isPreviewAutoRefreshEnabled: boolean;
  readonly previewResolutionType: WebSpotlightPreviewResolutionType;
  readonly spaceId: Uuid | null;
}

export const webSpotlightPreferencesInitialState: IWebSpotlightPreferences = {
  customPreviewHeight: fitToScreenResolution.height,
  customPreviewScale: fitToScreenResolution.scale,
  customPreviewWidth: fitToScreenResolution.width,
  isPreviewAutoRefreshEnabled: true,
  previewResolutionType: WebSpotlightPreviewResolutionType.FitScreen,
  spaceId: null,
};

const webSpotlightPreferencesStorageParams: StorageParams<IWebSpotlightPreferences> = {
  key: WebSpotlightPreferencesStorageKey,
  initialState: webSpotlightPreferencesInitialState,
  fromJS: (raw) => raw as any,
};

export const WebSpotlightPreferencesStorage = getProjectSpecificStorage<IWebSpotlightPreferences>(
  webSpotlightPreferencesStorageParams,
);
