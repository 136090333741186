import { useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { isErrorStatus } from '../../../../../_shared/models/StatusMessage.ts';
import { getItemSavingStatus } from '../../ContentItemEditing/containers/ContentItemSavingStatus.tsx';

interface IUsePendingDialogResult {
  readonly isPending: boolean;
  readonly showDialog: boolean;
  readonly hasError: boolean;
}

const minimumPendingTimeInMs = 1000;

export function usePendingDialog(
  isPending: boolean,
  onConfirm: () => void,
): IUsePendingDialogResult {
  const isUiBlockingMessageDisplayed = useSelector((state) => !!state.sharedApp.uiBlockingMessage);
  const mountedWithUIBlockingMessage = useRef(isUiBlockingMessageDisplayed).current;

  const [displayAsPending, setDisplayAsPending] = useState(!mountedWithUIBlockingMessage);

  const debouncedSetDisplayAsPending = useDebouncedCallback(
    setDisplayAsPending,
    minimumPendingTimeInMs,
  );

  useEffect(() => {
    if (!mountedWithUIBlockingMessage) {
      debouncedSetDisplayAsPending(isPending);
    }
    return debouncedSetDisplayAsPending.cancel;
  }, [mountedWithUIBlockingMessage, isPending, debouncedSetDisplayAsPending]);

  // We don't display the dialog when there is UI blocking message
  // instead, we dismiss it automatically and continue once all pending operations are done
  useEffect(() => {
    if (isUiBlockingMessageDisplayed && !isPending) {
      onConfirm();
    }
  }, [isUiBlockingMessageDisplayed, isPending, onConfirm]);

  const savingStatus = useSelector(getItemSavingStatus);

  return {
    isPending: displayAsPending,
    showDialog: !isUiBlockingMessageDisplayed,
    hasError: isErrorStatus(savingStatus.messageType),
  };
}
