import { assert } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { TrackUserEventWithDataAction } from '../../../../../../_shared/models/TrackUserEvent.type.ts';
import { ICreateNewContentItemAction } from '../../../NewContentItem/actions/thunks/createNewContentItem.ts';
import { markEditedItemAsTemporary } from '../contentItemEditingActions.ts';

interface IDeps {
  readonly trackUserEventWithData: TrackUserEventWithDataAction;
  readonly archiveTemporaryContentItem: () => ThunkPromise;
  readonly createNewContentItem: ICreateNewContentItemAction;
}

export type CreateNewItemFromType = (destinationCollectionId?: Uuid) => ThunkPromise<IResult>;

interface IResult {
  readonly createdItemId: Uuid;
}

export const createCreateNewItemFromType =
  (deps: IDeps): CreateNewItemFromType =>
  (destinationCollectionId) =>
  async (dispatch, getState): Promise<{ createdItemId: Uuid }> => {
    const {
      contentApp: { editedContentItem, editedContentItemVariant, loadedContentItemTypes },
    } = getState();

    //  If user creates a new temporary item, the previous one gets deleted
    dispatch(deps.archiveTemporaryContentItem());

    assert(
      !!editedContentItem,
      () =>
        'createNewItemFromType.ts: Cannot create another item without original editedContentItem.',
    );

    const editedContentItemType = loadedContentItemTypes.get(
      editedContentItem.editedContentItemTypeId,
    );

    assert(
      !!editedContentItemType,
      () => 'createNewItemFromType.ts: Cannot create another item with no content type ID.',
    );
    assert(
      !!editedContentItemVariant,
      () => 'createNewItemFromType.ts: Cannot create another item with no variantId.',
    );

    const createdItem = await dispatch(
      deps.createNewContentItem({
        typeId: editedContentItemType.id,
        collectionId: destinationCollectionId ?? editedContentItem.collectionId,
      }),
    );

    dispatch(
      markEditedItemAsTemporary({
        itemId: createdItem.item.id,
        variantId: editedContentItemVariant.id.variantId,
        parentItem: null,
      }),
    );

    return { createdItemId: createdItem.item.id };
  };
