import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

export interface IElementLocksObserverStateProps {
  readonly hasLockedElements: boolean;
  readonly releaseLockTimeout: number;
}

export interface IElementLocksObserverDispatchProps {
  readonly releaseExpiredElementLocks: () => void;
}

type ElementLocksObserverProps = IElementLocksObserverStateProps &
  IElementLocksObserverDispatchProps;

export const ElementLocksObserver: React.FC<ElementLocksObserverProps> = (props) => {
  const { hasLockedElements, releaseLockTimeout, releaseExpiredElementLocks } = props;

  useEffect(() => {
    const timeoutId = hasLockedElements
      ? self.setTimeout(() => releaseExpiredElementLocks(), releaseLockTimeout)
      : undefined;
    return () => self.clearTimeout(timeoutId);
  }, [hasLockedElements, releaseLockTimeout, releaseExpiredElementLocks]);

  return null;
};

const propTypes: PropTypesShape<ElementLocksObserverProps> = {
  hasLockedElements: PropTypes.bool.isRequired,
  releaseLockTimeout: PropTypes.number.isRequired,

  releaseExpiredElementLocks: PropTypes.func.isRequired,
};

ElementLocksObserver.displayName = 'ElementLocksObserver';
ElementLocksObserver.propTypes = propTypes;
