import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IProjectContributor } from '../../../../../../../data/models/users/ProjectContributor.ts';
import {
  ICommentThreadItem,
  ICommentThreadItemContentModel,
} from '../../../../../models/comments/CommentThreadItem.ts';
import {
  CommentThreadType,
  ICommentThread,
} from '../../../../../models/comments/CommentThreads.ts';
import { isSuggestion } from '../../../../../models/comments/Suggestion.ts';
import { CommentThreadItemContent } from './CommentThreadItemContent.tsx';
import { CommentThreadItemEditor } from './CommentThreadItemEditor.tsx';
import { CommentThreadItemHeader } from './CommentThreadItemHeader.tsx';
import { CommentThreadItemSegmentInfo } from './CommentThreadItemSegmentInfo.tsx';

export interface ICommentThreadItemOwnProps {
  readonly className: string;
  readonly commentThread: ICommentThread;
  readonly commentThreadItem: ICommentThreadItem;
  readonly index: number;
  readonly onDomReferenceCreated?: (ref: HTMLElement) => void;
  readonly isInlineThreadWithRemovedContent?: boolean;
}

export interface ICommentThreadItemStateProps {
  readonly allowEdit: boolean;
  readonly allowResolve: boolean;
  readonly allowUnresolve: boolean;
  readonly approveInfo: string;
  readonly canApproveSuggestion: boolean;
  readonly createdBy: IProjectContributor | null;
  readonly elementName: string | null;
  readonly elementSegment: string | null;
  readonly commentThreadType: CommentThreadType;
  readonly isLastApprovedSuggestion?: boolean;
  readonly shouldDisplaySegmentInfo: boolean;
  readonly suggestionApprovedBy: IProjectContributor | null;
}

export interface ICommentThreadItemDispatchProps {
  readonly onApproveSuggestion: () => void;
  readonly onResolveThreadAfterSuggestionWasApproved: () => void;
  readonly onCancelEdit: () => void;
  readonly onSubmitEdit: (content: ICommentThreadItemContentModel) => Promise<void>;
  readonly onResolve: () => void;
}

export interface ICommentThreadItemProps
  extends ICommentThreadItemStateProps,
    ICommentThreadItemDispatchProps,
    ICommentThreadItemOwnProps {}

interface ICommentThreadItemState {
  readonly isApproveAnimating: boolean;
}

export class CommentThreadItem extends React.PureComponent<
  ICommentThreadItemProps,
  ICommentThreadItemState
> {
  static displayName = 'CommentThreadItem';

  static propTypes: PropTypesShape<ICommentThreadItemProps> = {
    shouldDisplaySegmentInfo: PropTypes.bool.isRequired,
    allowEdit: PropTypes.bool.isRequired,
    allowResolve: PropTypes.bool.isRequired,
    allowUnresolve: PropTypes.bool.isRequired,
    approveInfo: PropTypes.string.isRequired,
    canApproveSuggestion: PropTypes.bool.isRequired,
    className: PropTypes.string.isRequired,
    commentThread: PropTypes.object.isRequired,
    commentThreadItem: PropTypes.object.isRequired,
    createdBy: PropTypes.object,
    elementName: PropTypes.string,
    elementSegment: PropTypes.string,
    commentThreadType: PropTypes.string,
    index: PropTypes.number.isRequired,
    isLastApprovedSuggestion: PropTypes.bool,
    onDomReferenceCreated: PropTypes.func,
    suggestionApprovedBy: PropTypes.object,
    isInlineThreadWithRemovedContent: PropTypes.bool,

    onApproveSuggestion: PropTypes.func.isRequired,
    onResolveThreadAfterSuggestionWasApproved: PropTypes.func.isRequired,
    onCancelEdit: PropTypes.func.isRequired,
    onSubmitEdit: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
  };

  readonly state: ICommentThreadItemState = {
    isApproveAnimating: false,
  };

  private readonly commentElementReferenceCreated = (element: HTMLElement | null) => {
    if (this.props.onDomReferenceCreated && element) {
      this.props.onDomReferenceCreated(element);
    }
  };

  private readonly approveSuggestion = (): void => {
    this.setState(() => ({ isApproveAnimating: true }));
    this.props.onApproveSuggestion();
  };

  private readonly approveAnimationFinished = (): void => {
    this.setState(() => ({ isApproveAnimating: false }));
    this.props.onResolveThreadAfterSuggestionWasApproved();
  };

  render(): JSX.Element {
    const {
      shouldDisplaySegmentInfo,
      allowEdit,
      allowResolve,
      allowUnresolve,
      approveInfo,
      canApproveSuggestion,
      commentThread,
      commentThreadItem,
      createdBy,
      elementName,
      elementSegment,
      commentThreadType,
      isLastApprovedSuggestion,
      suggestionApprovedBy,
      onResolve,
    } = this.props;

    const itemIsSuggestion = isSuggestion(commentThreadItem);

    return (
      <div
        className={classNames('comment', this.props.className, {
          'comment--is-suggestion': itemIsSuggestion,
          'comment--is-editing': commentThreadItem.isEditing,
        })}
        ref={this.commentElementReferenceCreated}
        {...getDataUiElementAttribute(DataUiElement.CommentThreadItem)}
      >
        <CommentThreadItemHeader
          commentThread={commentThread}
          commentThreadItem={commentThreadItem}
          allowEdit={allowEdit}
          allowResolve={allowResolve}
          allowUnresolve={allowUnresolve}
          createdBy={createdBy}
          isLastApprovedSuggestion={isLastApprovedSuggestion}
          approveInfo={approveInfo}
          onApproveSuggestion={canApproveSuggestion ? this.approveSuggestion : undefined}
          onResolve={onResolve}
        />
        {shouldDisplaySegmentInfo && (
          <CommentThreadItemSegmentInfo
            elementName={elementName}
            commentThreadType={commentThreadType}
            elementSegment={elementSegment}
            isInlineThreadWithRemovedContent={this.props.isInlineThreadWithRemovedContent}
          />
        )}
        {commentThreadItem.isEditing ? ( // show edit form when editing comment message
          <CommentThreadItemEditor
            submitButtonText={commentThreadItem.isSubmitting ? 'Sending…' : 'Save'}
            commentThreadItem={commentThreadItem}
            isEditing={commentThreadItem.isEditing}
            isInputDisabled={false}
            isButtonDisabled={commentThreadItem.isSubmitting}
            onSubmit={this.props.onSubmitEdit}
            onCancel={this.props.onCancelEdit}
            areButtonsDisplayed
          />
        ) : (
          <CommentThreadItemContent
            commentThreadItem={commentThreadItem}
            commentThread={commentThread}
            isLastApprovedSuggestion={isLastApprovedSuggestion}
            suggestionApprovedBy={suggestionApprovedBy}
            isApproveAnimating={this.state.isApproveAnimating}
            onApproveAnimationFinished={this.approveAnimationFinished}
          />
        )}
      </div>
    );
  }
}
