import { InputState } from '@kontent-ai/component-library/Input';
import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { ComponentProps, ReactElement } from 'react';
import { FieldPathByValue } from 'react-hook-form';
import { DefaultTag } from '../../../../../../component-library/components/Tag/DefaultTag.tsx';
import { ValidatedMultipleOptionSelect } from '../../../../../_shared/components/input/ValidatedMultipleOptionSelect.tsx';
import { HookFormProps } from '../../../../../_shared/types/hookFormProps.ts';
import {
  DataUiElement,
  DataUiInput,
  getDataUiElementAttribute,
  getDataUiInputAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  AllEnvironmentsTagId,
  EnvironmentOption,
} from '../../selectors/getSelectedEnvironmentOptions.ts';
import { DapiKeyFormShape } from '../../validation/dapiKeyValidationConfig.ts';
import { MapiKeyFormShape } from '../../validation/mapiKeyValidationConfig.ts';

type ValidatedApiEnvironmentsSelectorProps<TFormShape extends DapiKeyFormShape | MapiKeyFormShape> =
  {
    readonly allEnvironmentOptions: ReadonlyArray<EnvironmentOption>;
    readonly canUpdateKey: boolean;
    readonly caption: string;
    readonly disabledTooltipText: string | undefined;
    readonly formProps: HookFormProps<TFormShape>;
    readonly fieldName: FieldPathByValue<TFormShape, ReadonlyArray<Uuid>>;
    readonly renderSelectedEnvironmentOption: (
      environmentOption: EnvironmentOption,
      defaultTagProps: Pick<ComponentProps<typeof DefaultTag>, 'label' | 'removalState'>,
    ) => JSX.Element;
  };

const ValidatedApiEnvironmentsSelectorComponent: React.FC = <
  TFormShape extends DapiKeyFormShape | MapiKeyFormShape,
>({
  allEnvironmentOptions,
  canUpdateKey,
  caption,
  disabledTooltipText,
  formProps,
  fieldName,
  renderSelectedEnvironmentOption,
}: ValidatedApiEnvironmentsSelectorProps<TFormShape>) => {
  return (
    <Stack spacing={Spacing.XL}>
      <Label size={LabelSize.L}>The API key has access only to the following environments:</Label>
      <ValidatedMultipleOptionSelect<TFormShape, EnvironmentOption>
        caption={caption}
        inputDataAttributes={getDataUiInputAttribute(DataUiInput.Environments)}
        formProps={formProps}
        items={allEnvironmentOptions}
        inputState={canUpdateKey ? InputState.Default : InputState.Disabled}
        label="Environments"
        name={fieldName}
        normalize={normalizeSelectedOptions}
        placeholder="Select environments"
        renderSelectedOption={(_id, environmentOption, defaultTagProps) =>
          renderSelectedEnvironmentOption(environmentOption, defaultTagProps)
        }
        tooltipPlacement="bottom"
        tooltipText={canUpdateKey ? '' : disabledTooltipText}
        {...getDataUiElementAttribute(DataUiElement.EnvironmentsSelector)}
      />
    </Stack>
  );
};

ValidatedApiEnvironmentsSelectorComponent.displayName = 'ValidatedApiEnvironmentsSelector';
type ValidatedApiEnvironmentsSelectorType = <
  TFormShape extends DapiKeyFormShape | MapiKeyFormShape,
>(
  props: ValidatedApiEnvironmentsSelectorProps<TFormShape>,
) => ReactElement;

export const ValidatedApiEnvironmentsSelector =
  ValidatedApiEnvironmentsSelectorComponent as ValidatedApiEnvironmentsSelectorType;

const normalizeSelectedOptions = (
  newItems: ReadonlyArray<EnvironmentOption>,
  oldItems: ReadonlyArray<EnvironmentOption>,
): ReadonlyArray<EnvironmentOption> => {
  const newItemsContainAllEnvironmentsTag = newItems.find(
    (item) => item.id === AllEnvironmentsTagId,
  );
  const oldItemsContainAllEnvironmentsTag = oldItems.find(
    (item) => item.id === AllEnvironmentsTagId,
  );

  const isAllEnvironmentsTagAdded =
    newItemsContainAllEnvironmentsTag && !oldItemsContainAllEnvironmentsTag;
  const isSpecificEnvironmentAddedToAllEnvironmentsTag =
    newItemsContainAllEnvironmentsTag && oldItemsContainAllEnvironmentsTag && oldItems.length === 1;

  if (isAllEnvironmentsTagAdded) {
    return newItems.filter((item) => item.id === AllEnvironmentsTagId);
  }

  if (isSpecificEnvironmentAddedToAllEnvironmentsTag) {
    return newItems.filter((item) => item.id !== AllEnvironmentsTagId);
  }

  return newItems;
};
