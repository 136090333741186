import { Box } from '@kontent-ai/component-library/Box';
import { animated, useTransition } from '@react-spring/web';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { IUserInfo } from '../../../../../_shared/models/UserInfo.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { renderDatetimeString } from '../../../../../_shared/utils/dateTime/timeUtils.ts';
import { makeSpacesUnbreakable } from '../../../../../_shared/utils/stringUtils.ts';
import { formatCurrentUserName, formatUserName } from '../../../../../_shared/utils/usersUtils.ts';
import { Workflow } from '../../../../../data/models/workflow/Workflow.ts';
import {
  IWorkflowStep,
  WorkflowStepAction,
  emptyWorkflowStep,
} from '../../../../../data/models/workflow/WorkflowStep.ts';
import { renderWorkflowStepColor } from '../../../../../data/models/workflow/utils/renderWorkflowStepColor.ts';
import { getWorkflowStepsById } from '../../../../../data/reducers/workflow/selectors/workflowSelectors.ts';
import { IAssignmentNormalized } from '../../../models/contentItem/AssignmentNormalized.ts';

interface IAssignmentItemProps {
  readonly assignedBy: IUserInfo | null;
  readonly assignees: ReadonlyArray<IUserInfo> | null;
  readonly assignment: IAssignmentNormalized | null;
  readonly currentUserId: UserId;
  readonly isCurrent: boolean;
  readonly workflows: ReadonlyMap<Uuid, Workflow>;
}

const propTypes: PropTypesShape<IAssignmentItemProps> = {
  assignedBy: PropTypes.object,
  assignees: PropTypes.instanceOf<ReadonlyArray<IUserInfo>>(Array),
  assignment: PropTypes.object,
  currentUserId: PropTypes.string,
  isCurrent: PropTypes.bool.isRequired,
  workflows: PropTypes.object.isRequired,
};

const NotSet = '(Not set)';
const ScheduledStepName = 'Scheduled to publish';

const getWorkflow = (
  workflows: ReadonlyMap<Uuid, Workflow>,
  assignment: IAssignmentNormalized,
): Workflow | undefined =>
  assignment.workflowId ? workflows.get(assignment.workflowId) : undefined;

const getWorkflowStep = (
  workflow: Workflow,
  assignment: IAssignmentNormalized,
): IWorkflowStep | undefined => {
  const workflowSteps = getWorkflowStepsById(workflow);

  return workflowSteps && assignment.workflowStepId
    ? workflowSteps.get(assignment.workflowStepId)
    : undefined;
};

const getWorkflowStepName = (workflowStep: IWorkflowStep): string => {
  if (workflowStep.action === WorkflowStepAction.ScheduleToPublish) {
    return ScheduledStepName;
  }

  return workflowStep.name;
};

const Assignees: React.FC<{
  readonly assignees: ReadonlyArray<IUserInfo> | null;
}> = ({ assignees }) => {
  if (!assignees?.length) {
    return <div className="revision-item__detail-data">(No assignees assigned)</div>;
  }

  return (
    <>
      {assignees.map((person: IUserInfo) => (
        <div key={person.userId} className="revision-item__detail-data">
          {formatUserName(person)}
        </div>
      ))}
    </>
  );
};

export const AssignmentItem: React.FC<IAssignmentItemProps> = ({
  assignedBy,
  assignees,
  assignment,
  currentUserId,
  isCurrent,
  workflows,
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    setExpanded((prevValue) => !prevValue);
  };

  const transitions = useTransition(expanded, {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
  });

  if (!assignment) {
    return null;
  }

  const createdDate = makeSpacesUnbreakable(renderDatetimeString(assignment.createdAt));
  const dueDate = assignment.due
    ? makeSpacesUnbreakable(renderDatetimeString(assignment.due))
    : NotSet;
  const unpublishDate = assignment.scheduledToUnpublishAt
    ? makeSpacesUnbreakable(renderDatetimeString(assignment.scheduledToUnpublishAt))
    : NotSet;

  const workflow = getWorkflow(workflows, assignment);
  const workflowName = workflow?.name ?? '∅ Deleted workflow';
  const showWorkflowName = workflows.size > 1 || !workflow;
  const workflowStep = workflow && getWorkflowStep(workflow, assignment);
  const workflowStepName =
    (workflowStep && getWorkflowStepName(workflowStep)) || '∅ Deleted workflow step';
  const workflowStepColor = workflowStep?.color ?? emptyWorkflowStep.color;

  return (
    <div
      className={classNames('revision-item revision-item revision-item--workflow-status', {
        'revision-item--current-version': isCurrent,
      })}
    >
      <div className="revision-item__status-pane">
        <Box
          className="revision-item__status"
          backgroundColor={renderWorkflowStepColor(workflowStepColor)}
        />
      </div>
      <div className="revision-item__details-pane" data-hj-suppress="">
        {isCurrent && <div className="revision-item__current-version-label">Current version</div>}
        <div className="revision-item__title" title={workflowStepName}>
          {workflowStepName}
        </div>
        {assignedBy && (
          <div className="revision-item__detail-note">
            set by {formatCurrentUserName(currentUserId, false)(assignedBy)} on {createdDate}
          </div>
        )}
        {transitions(
          (style, item) =>
            item && (
              <animated.div style={style}>
                {showWorkflowName && (
                  <div className="revision-item__detail-list">
                    <div className="revision-item__detail-term">Workflow</div>
                    <div
                      className="revision-item__detail-data"
                      {...getDataUiElementAttribute(DataUiElement.RevisionItemDetailWorkflowName)}
                    >
                      {workflowName}
                    </div>
                  </div>
                )}
                <div className="revision-item__detail-list">
                  <div className="revision-item__detail-term">Contributors</div>
                  <Assignees assignees={assignees} />
                </div>
                <div className="revision-item__detail-list">
                  <div className="revision-item__detail-term">Unpublishing date</div>
                  <div className="revision-item__detail-data">{unpublishDate}</div>
                </div>
                <div className="revision-item__detail-list">
                  <div className="revision-item__detail-term">Due by</div>
                  <div className="revision-item__detail-data">{dueDate}</div>
                </div>
                <div className="revision-item__detail-list">
                  <div className="revision-item__detail-term">Note</div>
                  <div className="revision-item__detail-data">
                    {assignment.note ? assignment.note.noteText : '(Not set)'}
                  </div>
                </div>
              </animated.div>
            ),
        )}
        <div
          className="revision-item__action-link"
          onClick={toggleExpanded}
          {...getDataUiActionAttribute(expanded ? DataUiAction.Collapse : DataUiAction.Expand)}
        >
          <span className="action-link action-link--300">
            {expanded ? 'Less details' : 'More details'}
          </span>
        </div>
      </div>
    </div>
  );
};

AssignmentItem.displayName = 'AssignmentItem';
AssignmentItem.propTypes = propTypes;
