import { Input } from '@kontent-ai/component-library/Input';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { SelectorType } from '../constants/orderedSelectors.ts';
import { SelectorHeader } from './SelectorHeader.tsx';

type Props = {
  readonly autoFocus: boolean;
  readonly index: number;
  readonly onNameChange: (name: string) => void;
  readonly name: string;
};

export const NameInput: React.FC<Props> = ({ autoFocus, index, name, onNameChange }) => (
  <Stack spacing={Spacing.L}>
    <SelectorHeader index={index} type={SelectorType.Name} />
    <Input
      autoFocus={autoFocus}
      placeholder="Choose content item name"
      value={name}
      onChange={(e) => onNameChange(e.currentTarget.value)}
      {...getDataUiInputAttribute(DataUiInput.ItemName)}
    />
  </Stack>
);

NameInput.displayName = 'NameInput';
