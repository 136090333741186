import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import {
  Environment_InitSettings_Failed,
  Environment_InitSettings_Finished,
  Environment_InitSettings_Started,
} from '../../constants/environmentActionTypes.ts';

interface IDeps {
  readonly loadCollections: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadProjects: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadWorkflows: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly startPollingProjects: (abortSignal?: AbortSignal) => ThunkPromise;
}

const started = () =>
  ({
    type: Environment_InitSettings_Started,
  }) as const;

const finished = () =>
  ({
    type: Environment_InitSettings_Finished,
  }) as const;

const failed = () =>
  ({
    type: Environment_InitSettings_Failed,
  }) as const;

export type InitEnvironmentSettingsActionsType = ReturnType<
  typeof started | typeof failed | typeof finished
>;

export const createInitEnvironmentSettingsAction =
  (deps: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(started());

    try {
      await Promise.all([
        dispatch(deps.loadProjects(abortSignal)),
        dispatch(deps.loadCollections(abortSignal)),
        dispatch(deps.loadWorkflows(abortSignal)),
        dispatch(deps.startPollingProjects(abortSignal)),
      ]);

      dispatch(finished());
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failed());
      }

      throw error;
    }
  };
