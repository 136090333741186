import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../../../_shared/uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../../../_shared/uiComponents/Button/buttonStyle.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type UsersToolbarActionsProps = {
  readonly createNewItemLinkPath: string | undefined;
};

export const UsersToolbarActions: React.FC<UsersToolbarActionsProps> = ({
  createNewItemLinkPath,
}) =>
  createNewItemLinkPath ? (
    <Link to={createNewItemLinkPath} {...getDataUiActionAttribute(DataUiAction.CreateNew)}>
      <Button style={ButtonStyle.Primary}>Invite Users</Button>
    </Link>
  ) : null;

UsersToolbarActions.displayName = 'UsersToolbarActions';
