import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Button } from '@kontent-ai/component-library/Button';
import { Card } from '@kontent-ai/component-library/Card';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { useEnableFeatureIntercomMessage } from '../../hooks/useEnableFeatureIntercomMessage.ts';
import { InnovationLabCardComponent } from '../../types/InnovationLabCardComponent.type.ts';
import { isInnovationLabFeatureAvailable } from '../../utils/innovationLabFeatureStatusUtils.ts';
import { InnovationLabFeatureCard } from '../InnovationLabFeatureCard.tsx';
import { InnovationLabFeatureGenericallyUnavailable } from '../InnovationLabFeatureGenericallyUnavailable.tsx';
import {
  InnovationLabActivationState,
  InnovationLabStatus,
} from '../InnovationLabFeatureStatus.tsx';

const isSecureAccessEnabled = (state: IStore) =>
  state.sharedApp.projectProperties.SecureAssets
    ? state.sharedApp.projectProperties.SecureAssets === 'Enabled'
    : false;

const getSecureAssetsActivationState = ({
  sharedApp: {
    projectProperties: { SecureAssets },
  },
}: IStore): InnovationLabActivationState => {
  const hasSecureAccess = SecureAssets ? SecureAssets === 'Enabled' : null;

  switch (hasSecureAccess) {
    case true:
      return 'active';

    case false:
      return 'inactive';

    case null:
      return 'unknown';
  }
};

export const SecureAssetsCard: InnovationLabCardComponent = ({ featureInfo }) => {
  const activationState = useSelector(getSecureAssetsActivationState);
  const featureState = featureInfo.status;
  const featureName = SecureAssetsCard.featureName;
  const sendIntercomMessage = useEnableFeatureIntercomMessage(featureName);
  const onCardActionButtonClick = isInnovationLabFeatureAvailable(featureState)
    ? sendIntercomMessage
    : undefined;

  const isButtonDisabled =
    !isInnovationLabFeatureAvailable(featureState) && activationState !== 'active';

  return (
    <InnovationLabFeatureCard
      cardLabel={featureName}
      component="section"
      featureState={featureState}
    >
      <Card.Headline
        renderAuxiliaryElements={() => (
          /*
           * Note that support needs to mark the feature as enabled in the portal once it is set up on our side and the key is sent to customer
           */
          <InnovationLabStatus activationState={activationState} />
        )}
      >
        {featureName}
      </Card.Headline>

      <Card.Body>
        <Stack spacing={Spacing.M}>
          <p>
            Do you manage confidential assets or run an intranet portal?{' '}
            <b>Keep your assets secure and private.</b>
          </p>
          <p>
            <OutwardLink href="https://kontent.ai/learn/docs/security/secure-access/javascript#a-retrieve-assets-securely">
              Restrict access to your assets
            </OutwardLink>{' '}
            by requiring an API key. Once set up, only the apps that use your API key can fetch your
            assets.
          </p>
          <InnovationLabFeatureGenericallyUnavailable
            activationState={activationState}
            featureState={featureState}
          />
        </Stack>
      </Card.Body>
      <Card.Footer>
        <Button
          buttonStyle="tertiary"
          disabled={isButtonDisabled}
          onClick={onCardActionButtonClick}
          size="small"
          tooltipText={
            isButtonDisabled
              ? 'This feature is currently unavailable.'
              : 'You need to contact us to get this feature activated.'
          }
        >
          <Button.Label>Contact support</Button.Label>
        </Button>
      </Card.Footer>
    </InnovationLabFeatureCard>
  );
};

SecureAssetsCard.isFeatureEnabledSelector = isSecureAccessEnabled;
SecureAssetsCard.featureName = 'Secure access to assets';
SecureAssetsCard.releaseDate = '2022-02-02T00:00:00.000Z';
