import PropTypes from 'prop-types';
import React, { ReactNode } from 'react';
import { Stack } from '../../../layout/Stack/Stack.tsx';
import { Spacing } from '../../../tokens/quarks/spacing.ts';

type Props = {
  readonly children: OneOrMore<ReactNode>;
};

const propTypes: PropTypeMap<Props> = {
  children: PropTypes.node.isRequired,
};

export const VerticalTabsList: React.FC<Props> = ({ children }) => {
  return (
    <Stack component="ul" spacing={Spacing.S}>
      {Array.isArray(children)
        ? React.Children.map(
            children,
            (childElement) => childElement && <li key={childElement.key}>{childElement}</li>,
          )
        : children && <li>{children}</li>}
    </Stack>
  );
};

VerticalTabsList.displayName = 'VerticalTabsList';
VerticalTabsList.propTypes = propTypes;
