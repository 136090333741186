import { Box } from '@kontent-ai/component-library/Box';
import { Card } from '@kontent-ai/component-library/Card';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, Typography } from '@kontent-ai/component-library/tokens';
import React, { useContext } from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { WorkflowOverviewState } from '../models/WorkflowOverviewState.ts';
import { IWorkflowOverview } from '../utils/createWorkflowOverview.ts';
import { ProjectOverviewPageContext } from './ProjectOverviewPageContext.tsx';
import { WorkflowOverview } from './WorkflowOverview.tsx';
import { WorkflowOverviewSkeleton } from './WorkflowOverviewSkeleton.tsx';

interface IWorkflowOverviewProps {
  readonly workflowOverviewState: WorkflowOverviewState;
  readonly workflows: readonly IWorkflowOverview[];
}

export const WorkflowsOverview: React.FC<IWorkflowOverviewProps> = ({
  workflowOverviewState,
  workflows,
}) => {
  const { viewWorkflowStep } = useContext(ProjectOverviewPageContext);
  const maxStepCount = workflows
    .flatMap((workflow) => workflow.steps)
    .reduce(
      (previousMaxCount, currentStep) => Math.max(previousMaxCount, currentStep?.count ?? 0),
      0,
    );

  return (
    <Card
      cardLabel="Workflow steps overview"
      component="section"
      {...getDataUiElementAttribute(DataUiElement.WorkflowOverview)}
    >
      <Card.Headline>Workflow steps overview</Card.Headline>
      <Card.Body>
        <Stack spacing={Spacing.XXL}>
          <Box typography={Typography.Subheadline}>
            Click a workflow step below to see a list of content items in that particular step. The
            list shows only content items edited in the last 90 days.
          </Box>
          {workflows.map((workflow) =>
            workflowOverviewState === WorkflowOverviewState.Loading ? (
              <WorkflowOverviewSkeleton
                key={workflow.id}
                name={workflow.name}
                stepsCount={workflow.steps.length}
              />
            ) : (
              <WorkflowOverview
                key={workflow.id}
                workflowOverviewState={workflowOverviewState}
                name={workflow.name}
                steps={workflow.steps}
                maxStepCount={maxStepCount}
                onViewWorkflowStep={(stepId) => viewWorkflowStep(workflow.id, stepId)}
              />
            ),
          )}
        </Stack>
      </Card.Body>
    </Card>
  );
};
WorkflowsOverview.displayName = 'WorkflowOverview';
