import { Box } from '@kontent-ai/component-library/Box';
import { Card } from '@kontent-ai/component-library/Card';
import { InputState } from '@kontent-ai/component-library/Input';
import { paperBorderRadius } from '@kontent-ai/component-library/Paper';
import { IBaseSelectItem, ItemId } from '@kontent-ai/component-library/Selects';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import { Stack } from '@kontent-ai/component-library/Stack';
import {
  Spacing,
  Typography,
  colorTextHint,
  colorTextLowEmphasis,
  px,
  spacingCard,
} from '@kontent-ai/component-library/tokens';
import React from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly collectionItems: ReadonlyArray<IBaseSelectItem>;
  readonly disabledItemIds: Iterable<ItemId>;
  readonly onChange: (collectionId: Uuid | null) => void;
  readonly selectedCollectionId: Uuid | null;
  readonly isDisabled: boolean;
};

export const AssetCollection: React.FC<Props> = ({
  collectionItems,
  disabledItemIds,
  onChange,
  selectedCollectionId,
  isDisabled,
}) => {
  return (
    <Card
      css={`
        border-radius: ${px(paperBorderRadius)};
        background: ${isDisabled ? 'transparent' : ''}`}
      cardLabel="Collection"
      component="section"
    >
      <Card.Body>
        <Stack spacing={spacingCard}>
          <Stack spacing={Spacing.M}>
            <Box color={colorTextLowEmphasis} typography={Typography.TitleLarge}>
              Asset collection
            </Box>
            <Box color={colorTextHint} typography={Typography.BodySmall}>
              Only users with access to the selected collection can work with the asset.
            </Box>
          </Stack>
          <SingleSelect
            disabledItemIds={disabledItemIds}
            items={collectionItems}
            inputState={isDisabled ? InputState.Disabled : InputState.Default}
            onSelectionChange={onChange}
            placeholder="Select a collection"
            selectedItemId={selectedCollectionId}
            {...getDataUiElementAttribute(DataUiElement.AssetCollection)}
          />
        </Stack>
      </Card.Body>
    </Card>
  );
};

AssetCollection.displayName = 'AssetCollection';
