import { History } from 'history';
import {
  Dispatch,
  GetState,
  ThunkFunction,
  ThunkPromise,
} from '../../../../../@types/Dispatcher.type.ts';
import { onboardingNotificationTriggered } from '../../../../../_shared/actions/thunkSharedActions.ts';
import {
  EditContentTypeRoute,
  EditContentTypeRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import {
  TrackUserEventWithData,
  TrackUserEventWithDataAction,
} from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { IContentTypeCreatedEventData } from '../../../../../_shared/models/TrackUserEventData.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { getFirstCollectionAndLanguageForContentTypeAndCurrentUser } from '../../../../../_shared/utils/permissions/getFirstCollectionAndLanguageForContentTypeAndCurrentUser.ts';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getContentTypeFromServerModel } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { OnboardingNotification } from '../../../../../data/models/user/OnboardingNotification.ts';
import {
  ContentTypeServerModel,
  CreateNewContentTypeServerModel,
} from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { ContentTypeElementServerModel } from '../../../../../repositories/serverModels/contentModels/sharedContentTypeModels.type.ts';
import {
  IContentGroup,
  getContentGroupServerModel,
} from '../../../../contentInventory/content/models/contentTypeElements/types/ContentGroup.ts';
import { typeEditorSaveAttempted } from '../../../shared/actions/sharedContentModelsActions.ts';
import { IBaseTypeElementData } from '../../../shared/models/elements/types/TypeElementData.ts';
import { getContentTypeConversionOptions } from '../../../shared/selectors/contentTypeElementSelector.ts';
import { createActiveConditionIds } from '../../../shared/utils/conditionUtils.ts';
import { ITrackAssetLimitConfigurationChanged } from '../../../shared/utils/intercomHelpers/trackAssetLimitsUtils.ts';
import { ITrackCustomTypeElementUpserted } from '../../../shared/utils/intercomHelpers/trackCustomTypeElementConfig.ts';
import { isTypeValid } from '../../../shared/utils/typeValidationUtils.ts';
import { UntitledContentTypeName } from '../../constants/contentTypeConstants.ts';
import {
  contentTypeCreationFinished,
  contentTypeCreationStarted,
  contentTypeInvalidElementShowWarning,
} from '../contentTypesActions.ts';

interface IDeps {
  readonly contentTypeRepository: {
    readonly createContentType: (
      contentType: CreateNewContentTypeServerModel,
    ) => Promise<ContentTypeServerModel>;
  };
  readonly convertTypeElementsToServerModel: (
    typeElementRecord: ReadonlyArray<IBaseTypeElementData>,
  ) => Array<ContentTypeElementServerModel>;
  readonly getContentTypeCreatedEventData: (
    typeId: Uuid,
    elements: ReadonlyArray<IBaseTypeElementData>,
  ) => IContentTypeCreatedEventData;
  readonly loadContentTypesData: () => ThunkPromise;
  readonly saveEditedMultipleChoiceOption: () => ThunkFunction;
  readonly trackAssetLimitConfigurationChanged: ITrackAssetLimitConfigurationChanged;
  readonly trackCustomTypeElementUpserted: ITrackCustomTypeElementUpserted;
  readonly trackUserEventWithData: TrackUserEventWithDataAction;
  readonly validateTypeElement: (updatedTypeElement: IBaseTypeElementData) => ThunkFunction;
}

export const createCreateContentTypeAction =
  (deps: IDeps) =>
  (history: History, onSuccess?: () => void, onFail?: () => void): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    dispatch(deps.saveEditedMultipleChoiceOption());

    const {
      sharedApp: { currentProjectId },
      contentModelsApp: {
        typeEditor: { editedType },
      },
      data: { user, languages, collections },
    } = getState();

    dispatch(typeEditorSaveAttempted(createActiveConditionIds(editedType)));

    editedType.typeElements.forEach((element) => dispatch(deps.validateTypeElement(element)));

    const { validationResults } = getState().contentModelsApp.typeEditor;
    if (!isTypeValid(validationResults)) {
      dispatch(contentTypeInvalidElementShowWarning());
      if (onFail) {
        onFail();
      }
      return;
    }

    dispatch(contentTypeCreationStarted());

    const name = editedType.name || UntitledContentTypeName;
    const contentGroups = editedType.contentGroups
      .map((group: IContentGroup) => getContentGroupServerModel(group))
      .toArray();

    const contentElements = deps.convertTypeElementsToServerModel(editedType.typeElements);

    const contentTypeForServer: CreateNewContentTypeServerModel = {
      contentElements,
      contentGroups,
      name,
    };
    const createdContentTypeServerModel =
      await deps.contentTypeRepository.createContentType(contentTypeForServer);
    const createdContentType = getContentTypeFromServerModel(
      createdContentTypeServerModel,
      getContentTypeConversionOptions(getState()),
    );
    const { languageId, collectionId } = getFirstCollectionAndLanguageForContentTypeAndCurrentUser(
      user,
      createdContentType.id,
      currentProjectId,
      languages,
      collections,
      Capability.CreateContent,
    );

    dispatch(contentTypeCreationFinished(createdContentType));

    const trackEvent: TrackUserEventWithData = (...args) =>
      dispatch(deps.trackUserEventWithData(...args));
    deps.trackAssetLimitConfigurationChanged(trackEvent, createdContentType.typeElements);
    deps.trackCustomTypeElementUpserted(trackEvent, createdContentType.typeElements);
    dispatch(deps.loadContentTypesData());
    dispatch(
      deps.trackUserEventWithData(
        TrackedEvent.ContentTypeCreated,
        deps.getContentTypeCreatedEventData(createdContentType.id, createdContentType.typeElements),
      ),
    );
    dispatch(
      onboardingNotificationTriggered(OnboardingNotification.ContentTypeEditedNotification, {
        editedTypeId: createdContentType.id,
        languageIdToCreateItem: languageId,
        collectionIdToCreateItem: collectionId,
      }),
    );

    if (onSuccess) {
      onSuccess();
    } else {
      history.push(
        buildPath<EditContentTypeRouteParams>(EditContentTypeRoute, {
          projectId: currentProjectId,
          contentTypeId: createdContentType.id,
        }),
      );
    }
  };
