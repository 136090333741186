import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../../../../@types/Action.type.ts';
import { Shared_ModalDismissed } from '../../../../../../_shared/constants/sharedActionTypes.ts';
import { LoadingStatus } from '../../../../../../_shared/models/LoadingStatusEnum.ts';
import { CascadeNodeId } from '../../../../../contentInventory/content/stores/IContentAppStoreState.ts';
import {
  Content_Editing_PublishContentItemVariantStarted,
  Content_Editing_ScheduledPublishingStarted,
} from '../../../../constants/editorActionTypes.ts';
import {
  ContentEditing_CascadeModal_ExpandNodeFinished,
  ContentEditing_CascadeModal_ExpandNodeStarted,
  ContentEditing_CascadeModal_PublishingStarted,
  ContentEditing_CascadeModal_SchedulingStarted,
} from '../../../../features/CascadePublish/constants/cascadeModalActionTypes.ts';

const initialState: ReadonlyMap<CascadeNodeId, LoadingStatus> = new Map();

export const nodesLoadingStatus = (
  state = initialState,
  action: Action,
): ReadonlyMap<CascadeNodeId, LoadingStatus> => {
  switch (action.type) {
    case ContentEditing_CascadeModal_ExpandNodeStarted:
      return Collection.add(state, [action.payload.nodeId, LoadingStatus.Loading]);

    case ContentEditing_CascadeModal_ExpandNodeFinished:
      return Collection.add(state, [action.payload.nodeId, LoadingStatus.Loaded]);

    case Shared_ModalDismissed:
    case Content_Editing_PublishContentItemVariantStarted:
    case Content_Editing_ScheduledPublishingStarted:
    case ContentEditing_CascadeModal_PublishingStarted:
    case ContentEditing_CascadeModal_SchedulingStarted:
      return initialState;

    default:
      return state;
  }
};
