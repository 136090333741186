import { useContext, useEffect } from 'react';
import { WebSpotlightContext } from '../context/WebSpotlightContext.tsx';

export const useWebSpotlightPreviewInitialization = () => {
  const { setIsPreviewIFrameInitialized } = useContext(WebSpotlightContext);

  useEffect(() => {
    setIsPreviewIFrameInitialized(true);
    return () => {
      setIsPreviewIFrameInitialized(false);
    };
  }, [setIsPreviewIFrameInitialized]);
};
