import { BaseColor, Spacing, colorDividerDefault } from '@kontent-ai/component-library/tokens';
import { CLPropTypes } from '@kontent-ai/component-library/validators';
import PropTypes from 'prop-types';
import React from 'react';
import {
  DividerDirection,
  StyledDivider,
  StyledDividerWrapper,
} from './components/StyledDivider.tsx';

export interface IDividerProps {
  readonly className?: string;
  readonly direction: DividerDirection;
  readonly color?: BaseColor;
  readonly offsetBefore: Spacing;
  readonly offsetAfter: Spacing;
}

const propTypes: PropTypeMap<IDividerProps> = {
  className: PropTypes.string,
  direction: PropTypes.oneOf(Object.values(DividerDirection)).isRequired,
  color: CLPropTypes.colors.baseColor,
  offsetBefore: PropTypes.oneOf(Object.values(Spacing)).isRequired,
  offsetAfter: PropTypes.oneOf(Object.values(Spacing)).isRequired,
};

export const Divider = React.forwardRef<HTMLDivElement, React.PropsWithChildren<IDividerProps>>(
  (
    { className, direction, color = colorDividerDefault, offsetBefore, offsetAfter, children },
    forwardedRef,
  ) => (
    <StyledDividerWrapper
      className={className}
      ref={forwardedRef}
      $direction={direction}
      offsetAfter={offsetAfter}
    >
      {children}
      <StyledDivider $color={color} $direction={direction} offsetBefore={offsetBefore} />
    </StyledDividerWrapper>
  ),
);

Divider.displayName = 'Divider';
Divider.propTypes = propTypes;
