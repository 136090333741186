import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import { IconName } from '../../constants/iconEnumGenerated.ts';
import { Icon } from '../../uiComponents/Icon/Icon.tsx';
import { IgnoreByGrammarly } from '../IgnoreByGrammarly.tsx';

export interface IAssetTileFolderSummary {
  readonly name: string;
  readonly pathTooltip?: string;
}

type Props = IAssetTileFolderSummary;

const propTypes: PropTypesShape<Props> = {
  name: PropTypes.string.isRequired,
  pathTooltip: PropTypes.string,
};

const AssetTileFolderSummary: React.FC<Props> = (props) => {
  const { name, pathTooltip } = props;

  return (
    <div className="asset-thumbnail__folder">
      {pathTooltip && (
        <Tooltip tooltipText={pathTooltip} placement="bottom">
          <div className="asset-thumbnail__folder-tooltip">
            <Icon iconName={IconName.Ellipsis} className="asset-thumbnail__folder-ellipsis" />
            <Icon iconName={IconName.ChevronRight} className="asset-thumbnail__folder-separator" />
          </div>
        </Tooltip>
      )}
      <Icon iconName={IconName.Folder} />
      <span className="asset-thumbnail__folder-name" title={name}>
        <IgnoreByGrammarly text={name} />
      </span>
    </div>
  );
};

AssetTileFolderSummary.displayName = 'AssetTileFolderSummary';
AssetTileFolderSummary.propTypes = propTypes;

export { AssetTileFolderSummary };
