import { differenceInSeconds } from 'date-fns';
import React, { useState } from 'react';
import { useVariantTranslation } from '../../../../../../_shared/features/AI/hooks/useVariantTranslation.ts';
import { ContentItemId } from '../../../../../../_shared/models/ContentItemId.type.ts';
import { VariantTranslationStatus } from '../../../../../../_shared/utils/translations/variantTranslationStatus.ts';
import { TypeElement } from '../../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { TranslationNotificationBar as TranslationNotificationBarComponent } from './TranslationNotificationBar.tsx';
import { TranslationStatus } from './TranslationStatus.tsx';

type TranslationProgressNotificationBarProps = {
  readonly userId: string;
  readonly contentItemId: ContentItemId;
  readonly projectEnvironmentId: Uuid;
  readonly typeElements: ReadonlyArray<TypeElement>;
};

const currentUserShouldNotSeeProgress = (
  userId: string,
  translationStartedBy: string | null,
  translationStatus: VariantTranslationStatus,
) => userId === translationStartedBy && translationStatus === VariantTranslationStatus.InProgress;

const shouldHideNotificationsAfterCompletion = (
  translationStatus: VariantTranslationStatus,
  completedAt: string | null,
) =>
  translationStatus !== VariantTranslationStatus.InProgress &&
  completedAt &&
  differenceInSeconds(new Date(), new Date(completedAt)) > 20;

export const TranslationNotificationBar: React.FC<TranslationProgressNotificationBarProps> = ({
  userId,
  contentItemId,
  projectEnvironmentId,
  typeElements,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const {
    translationStatus,
    translationStatusPerElement,
    retryTranslation,
    translationStartedBy,
    completedAt,
  } = useVariantTranslation(projectEnvironmentId, contentItemId.itemId, contentItemId.variantId);

  if (
    !translationStatus ||
    translationStatus === VariantTranslationStatus.Cancelled ||
    !isOpen ||
    currentUserShouldNotSeeProgress(userId, translationStartedBy, translationStatus) ||
    shouldHideNotificationsAfterCompletion(translationStatus, completedAt)
  ) {
    return null;
  }

  return (
    <TranslationNotificationBarComponent
      statusComponent={
        <TranslationStatus
          onRetry={retryTranslation}
          translationStatus={translationStatus}
          translationStatusPerElement={translationStatusPerElement}
          typeElements={typeElements}
        />
      }
      onClose={() => setIsOpen(false)}
    />
  );
};
