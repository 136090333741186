import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { upsertUserProperty } from '../../../_shared/actions/thunkSharedActions.ts';
import {
  ContentItemPreviewRoute,
  ContentItemsRoute,
  ContentItemsRouteParams,
  WebSpotlightContentRoute,
  WebSpotlightRouteParams,
} from '../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { WebSpotlightTreeCollapsedServerKey } from '../../../_shared/models/UserPropertiesServerKeys.ts';
import { buildPath, matchPath } from '../../../_shared/utils/routing/routeTransitionUtils.ts';
import {
  expandWebSpotlightNode,
  selectWebSpotlightNode,
} from '../actions/thunkWebSpotlightActions.ts';
import { webSpotlightNodeCollapsed } from '../actions/webSpotlightActions.ts';
import { WebSpotlightTree as WebSpotlightTreeComponent } from '../components/tree/WebSpotlightTree.tsx';
import { NodeId } from '../types/nodeId.type.ts';
import { WebSpotlightTabName } from '../types/webSpotlightTabName.ts';

type Props = {
  readonly rootItemId: Uuid;
};

export const WebSpotlightTree: React.FC<Props> = ({ rootItemId }) => {
  const editedContentItem = useSelector((state) => state.contentApp.editedContentItem);
  const listingContentItems = useSelector((state) => state.data.listingContentItems.byId);
  const expandedNodesData = useSelector((state) => state.webSpotlightApp.expandedNodesData);
  const focusedNodeId = useSelector((state) => state.webSpotlightApp.focusedNodeId);
  const nodesBeingLoaded = useSelector((state) => state.webSpotlightApp.nodesBeingLoaded);
  const subpagesById = useSelector((state) => state.webSpotlightApp.subpagesById);
  const isCollapsed = useSelector(
    (state) => state.sharedApp.userProperties.webSpotlightTreeCollapsed,
  );

  const history = useHistory();
  const currentPath = history.location.pathname;
  const matchParams = matchPath<ContentItemsRouteParams>(currentPath, ContentItemsRoute);

  const dispatch = useDispatch();

  const onNodeCollapsed = useCallback(
    (nodeId: NodeId) => dispatch(webSpotlightNodeCollapsed(nodeId)),
    [],
  );
  const onNodeExpanded = useCallback(
    (nodeId: NodeId, itemId: Uuid) =>
      dispatch(
        expandWebSpotlightNode({
          itemId,
          nodeId,
        }),
      ),
    [],
  );
  const onNodeSelected = useCallback(
    (nodeId: NodeId, itemId: Uuid, path: string) => {
      history.push(path);
      dispatch(
        selectWebSpotlightNode({
          itemId,
          nodeId,
        }),
      );
    },
    [history],
  );
  const onToggleTree = useCallback(
    (newIsCollapsed: boolean) =>
      dispatch(upsertUserProperty(WebSpotlightTreeCollapsedServerKey, String(newIsCollapsed))),
    [],
  );

  if (!matchParams) {
    return null;
  }

  const rootPath = buildPath<WebSpotlightRouteParams>(WebSpotlightContentRoute, {
    projectId: matchParams.projectId,
    variantId: matchParams.variantId,
    spaceId: matchParams.spaceId,
  });

  const previewMatchParams = matchPath<WebSpotlightRouteParams>(
    currentPath,
    ContentItemPreviewRoute,
  );
  const selectedTab = previewMatchParams ? WebSpotlightTabName.Preview : WebSpotlightTabName.Editor;

  return (
    <WebSpotlightTreeComponent
      contentItems={listingContentItems}
      currentPath={currentPath}
      editedContentItem={editedContentItem}
      expandedNodesData={expandedNodesData}
      focusedNodeId={focusedNodeId}
      isCollapsed={isCollapsed}
      nodesBeingLoaded={nodesBeingLoaded}
      onNodeCollapsed={onNodeCollapsed}
      onNodeExpanded={onNodeExpanded}
      onNodeSelected={onNodeSelected}
      onToggleTree={onToggleTree}
      rootItemId={rootItemId}
      rootPath={rootPath}
      selectedLanguageId={matchParams.variantId}
      selectedTab={selectedTab}
      subpagesById={subpagesById}
    />
  );
};

WebSpotlightTree.displayName = 'WebSpotlightTreeContainer';
