import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Box } from '@kontent-ai/component-library/Box';
import { CheckboxItem, CheckboxState } from '@kontent-ai/component-library/Checkbox';
import { CheckboxGroup } from '@kontent-ai/component-library/CheckboxGroup';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, Typography } from '@kontent-ai/component-library/tokens';
import { Fragment } from 'react';
import { documentationLinks } from '../../../../_shared/constants/documentationLinks.ts';
import { HookFormProps } from '../../../../_shared/types/hookFormProps.ts';
import { EntityWebhookEntityType } from '../../../../data/models/webhooks/EntityWebhookEntityType.ts';
import { EntityWebhookAction } from '../../../../data/models/webhooks/EntityWebhookSetting.ts';
import { allCheckBoxValue } from '../../constants/entityWebhookConstants.ts';
import { IEntityWebhookFormShape } from '../../models/IEntityWebhookFormShape.type.ts';
import { anyCurrentlyVisibleEventIsSelected } from '../../utils/entityTriggersChecks.ts';
import { IEventTrigger } from './EntityWebhookEventTriggerBarListing.tsx';
import { EntityWebhookContentItemFilter as EntityWebhookContentItemFilterComponent } from './filters/EntityWebhookContentItemFilter.tsx';
import { EntityWebhookContentTypeFilter as EntityWebhookContentTypeFilterComponent } from './filters/EntityWebhookContentTypeFilter.tsx';
import { EntityWebhookLanguageFilter as EntityWebhookLanguageFilterComponent } from './filters/EntityWebhookLanguageFilter.tsx';
import { EntityWebhookTaxonomyFilter as EntityWebhookTaxonomyFilterComponent } from './filters/EntityWebhookTaxonomyFilter.tsx';
import { EntityWebhookWorkflowStepChangedFilter as EntityWebhookWorkflowStepChangedFilterComponent } from './filters/EntityWebhookWorkflowStepChangedFilter.tsx';

type CheckBoxGroupStateProps = {
  readonly checkBoxState: CheckboxState;
  readonly checkBoxGroupState: CheckboxState;
};

const getCheckBoxState = (
  enabled: boolean,
  checkedBarItem: boolean,
  selectedEvents: ReadonlyArray<string>,
  allActionOptions: EntityWebhookAction[],
): CheckBoxGroupStateProps => {
  if (!enabled) {
    return {
      checkBoxState: 'disabled',
      checkBoxGroupState: 'disabled',
    };
  }

  if (checkedBarItem && !anyCurrentlyVisibleEventIsSelected(selectedEvents, allActionOptions)) {
    return {
      checkBoxState: 'default',
      checkBoxGroupState: 'alert',
    };
  }

  return {
    checkBoxState: checkedBarItem ? 'default' : 'disabled',
    checkBoxGroupState: 'default',
  };
};

type EntityWebhookEventTriggerDetailProps = {
  readonly enabled: boolean;
  readonly eventTriggerName: string;
  readonly eventTriggerType: EntityWebhookEntityType;
  readonly eventTriggers: ReadonlyArray<IEventTrigger>;
  readonly formProps: HookFormProps<IEntityWebhookFormShape>;
  readonly onEventTriggerChange: (selectedEventsCallbackValue: ReadonlyArray<string>) => void;
  readonly checkedBarItem: boolean;
  readonly selectedEvents: ReadonlyArray<string>;
};

export const EntityWebhookEventTriggerDetail = ({
  enabled,
  eventTriggerName,
  eventTriggerType,
  eventTriggers,
  formProps,
  onEventTriggerChange,
  checkedBarItem,
  selectedEvents,
}: EntityWebhookEventTriggerDetailProps) => {
  const allActionOptions = eventTriggers.map((option) => option.action);
  const checkBoxGroupState = getCheckBoxState(
    enabled,
    checkedBarItem,
    selectedEvents,
    allActionOptions,
  );
  const indeterminate =
    selectedEvents.every((selectedEvent: string) => selectedEvent !== allCheckBoxValue) &&
    anyCurrentlyVisibleEventIsSelected(selectedEvents, allActionOptions);

  return (
    <Stack spacing={Spacing.L}>
      <Box typography={Typography.HeadlineMedium}>Events</Box>
      <Box>
        <CheckboxGroup
          key="all"
          ariaLabel="EntityWebhookEventTriggers"
          groupSubLabel={
            <>
              Trigger the webhook when the specified {eventTriggerName.toLowerCase()}{' '}
              <OutwardLink href={documentationLinks.webhookTriggersAndEvents}>events</OutwardLink>{' '}
              occur.
            </>
          }
          selectedValues={selectedEvents}
          onChange={onEventTriggerChange}
          alertMessage="Select at least one event."
          checkboxGroupState={checkBoxGroupState.checkBoxGroupState}
        >
          <CheckboxItem
            checkboxState={checkBoxGroupState.checkBoxState}
            value={allCheckBoxValue}
            indeterminate={indeterminate}
          >
            Select all
          </CheckboxItem>
          <Box paddingLeft={Spacing.XL}>
            {eventTriggers.map((trigger) => {
              return (
                <Fragment key={trigger.action}>
                  <CheckboxItem
                    checkboxState={checkBoxGroupState.checkBoxState}
                    value={trigger.action}
                    caption={
                      trigger.action === EntityWebhookAction.Created &&
                      [
                        EntityWebhookEntityType.ContentType,
                        EntityWebhookEntityType.Language,
                        EntityWebhookEntityType.Taxonomy,
                      ].includes(eventTriggerType)
                        ? 'Filters don’t apply to this event.'
                        : null
                    }
                  >
                    {trigger.title}
                  </CheckboxItem>
                  {trigger.action === EntityWebhookAction.WorkflowStepChanged && (
                    <EntityWebhookWorkflowStepChangedFilterComponent formProps={formProps} />
                  )}
                </Fragment>
              );
            })}
          </Box>
        </CheckboxGroup>
      </Box>
      {eventTriggerType === EntityWebhookEntityType.ContentItem && (
        <EntityWebhookContentItemFilterComponent formProps={formProps} />
      )}
      {eventTriggerType === EntityWebhookEntityType.ContentType && (
        <EntityWebhookContentTypeFilterComponent formProps={formProps} />
      )}
      {eventTriggerType === EntityWebhookEntityType.Language && (
        <EntityWebhookLanguageFilterComponent formProps={formProps} />
      )}
      {eventTriggerType === EntityWebhookEntityType.Taxonomy && (
        <EntityWebhookTaxonomyFilterComponent formProps={formProps} />
      )}
    </Stack>
  );
};

EntityWebhookEventTriggerDetail.displayName = 'EntityWebhookEventTriggerDetail';
