import React, { useContext, useMemo } from 'react';
import { ICompiledContentItemElementData } from '../../../models/contentItemElements/ICompiledContentItemElement.ts';

export type IItemElementsContextValue = {
  readonly elements: ReadonlyArray<ICompiledContentItemElementData> | null;
  readonly snapshotTime: DateTimeStamp | null;
};

const defaultContext: IItemElementsContextValue = {
  elements: null,
  snapshotTime: null,
};

const createItemElementsContextProvider =
  (
    context: React.Context<IItemElementsContextValue>,
  ): React.FC<React.PropsWithChildren<IItemElementsContextValue>> =>
  ({ children, elements, snapshotTime }) => {
    const value = useMemo<IItemElementsContextValue>(
      () => ({ elements, snapshotTime }),
      [elements, snapshotTime],
    );

    return <context.Provider value={value}>{children}</context.Provider>;
  };

export const ItemElementsContext = React.createContext<IItemElementsContextValue>(defaultContext);
export const ItemElementsContextProvider = createItemElementsContextProvider(ItemElementsContext);

export const OriginalItemElementsContext =
  React.createContext<IItemElementsContextValue>(defaultContext);
export const OriginalItemElementsContextProvider = createItemElementsContextProvider(
  OriginalItemElementsContext,
);

export const UseOriginalItemElements: React.FC<React.PropsWithChildren<NoProps>> = ({
  children,
}) => {
  const values = useContext(OriginalItemElementsContext);

  return <ItemElementsContextProvider {...values}>{children}</ItemElementsContextProvider>;
};

UseOriginalItemElements.displayName = 'UseOriginalItemElements';
