import { Button, ButtonStyle } from '@kontent-ai/component-library/Button';
import { IconName, Icons } from '@kontent-ai/component-library/Icons';
import { Inline } from '@kontent-ai/component-library/Inline';
import { spacingBetweenButtonsHorizontal } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import { ReactNode } from 'react';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { ModalDialog } from './ModalDialog.tsx';

export interface IConfirmationDialogStateProps {
  readonly children: ReactNode;
  readonly closeImmediatelyAfterConfirm?: boolean;
  readonly confirmButtonStyle?: ButtonStyle;
  readonly confirmButtonDestructive?: boolean;
  readonly confirmButtonIcon?: IconName;
  readonly confirmButtonText: string;
  readonly confirmButtonTooltipText?: string;
  readonly customClassName?: string;
  readonly dataUiElement?: DataUiElement;
  readonly hasConfirmButtonLoader?: boolean;
  readonly headerContent: string;
  readonly headerTooltip?: string;
  readonly isConfirmButtonDisabled?: boolean;
  readonly useDefaultAutofocus?: boolean;
}

export interface IConfirmationDialogDispatchProps {
  readonly onConfirm: () => void;
  readonly onClose: () => void;
  readonly onCancel?: () => void;
}

interface IConfirmationDialogProps
  extends IConfirmationDialogStateProps,
    IConfirmationDialogDispatchProps {}

export const ConfirmationDialog = (props: IConfirmationDialogProps) => {
  const confirm = () => {
    props.onConfirm();
    if (props.closeImmediatelyAfterConfirm ?? true) {
      props.onClose();
    }
  };

  const cancel = () => {
    props.onCancel?.();
    props.onClose();
  };

  const confirmButtonStyle = props.confirmButtonStyle ?? 'primary';
  const destructive = props.confirmButtonDestructive || !props.confirmButtonStyle;

  return (
    <ModalDialog
      customClassName={classNames('confirmation-dialog', props.customClassName)}
      dataUiElement={props.dataUiElement ?? DataUiElement.ConfirmationDialog}
      onClose={cancel}
      headerContent={props.headerContent}
      headerTooltip={props.headerTooltip}
      bodyContent={<div className="confirmation-dialog__body">{props.children}</div>}
      footerContentRight={
        <div>
          <div className="confirmation-dialog__action-buttons">
            <Inline spacingX={spacingBetweenButtonsHorizontal}>
              <Button
                autoFocus={props.useDefaultAutofocus ?? true}
                onClick={cancel}
                buttonStyle="secondary"
                {...getDataUiActionAttribute(DataUiAction.Cancel)}
              >
                Cancel
              </Button>
              <Button
                buttonStyle={confirmButtonStyle}
                destructive={destructive}
                destructiveIcon={
                  props.confirmButtonIcon ? Icons[props.confirmButtonIcon] : undefined
                }
                disabled={props.isConfirmButtonDisabled || props.hasConfirmButtonLoader}
                onClick={confirm}
                tooltipPlacement="bottom-end"
                tooltipText={props.confirmButtonTooltipText}
                {...getDataUiActionAttribute(DataUiAction.Confirm)}
              >
                {props.hasConfirmButtonLoader && <Button.ProgressIcon />}
                <Button.Label>{props.confirmButtonText}</Button.Label>
              </Button>
            </Inline>
          </div>
        </div>
      }
    />
  );
};
