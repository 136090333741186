import { ElementType as DeliverElementType } from '@kontent-ai/delivery-sdk/dist/cjs/elements/element-type';
import { ElementUpdateData } from '@kontent-ai/smart-link';
import { GetState, ThunkFunction, ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import { IMultipleChoiceTypeElement } from '../../../contentInventory/content/models/contentTypeElements/MultipleChoiceTypeElement.ts';
import { ITaxonomyTypeElement } from '../../../contentInventory/content/models/contentTypeElements/TaxonomyTypeElement.ts';
import { EditableTypeElement } from '../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { IAssetItemElement } from '../../../itemEditor/models/contentItemElements/AssetItemElement.ts';
import { ICustomItemElement } from '../../../itemEditor/models/contentItemElements/CustomItemElement.ts';
import { DateTimeItemElement } from '../../../itemEditor/models/contentItemElements/DateTimeItemElement.ts';
import { ICompiledContentItemElementData } from '../../../itemEditor/models/contentItemElements/ICompiledContentItemElement.ts';
import { IMultipleChoiceItemElement } from '../../../itemEditor/models/contentItemElements/MultipleChoiceItemElement.ts';
import { INumberItemElement } from '../../../itemEditor/models/contentItemElements/NumberItemElement.ts';
import { IRichTextItemElement } from '../../../itemEditor/models/contentItemElements/RichTextItemElement.ts';
import { ITaxonomyItemElement } from '../../../itemEditor/models/contentItemElements/TaxonomyItemElement.ts';
import { ITextItemElement } from '../../../itemEditor/models/contentItemElements/TextItemElement.ts';
import { IUrlSlugItemElement } from '../../../itemEditor/models/contentItemElements/UrlSlugItemElement.ts';
import { isRichTextElement } from '../../../itemEditor/models/contentItemElements/compiledItemElementTypeGuards.ts';
import { ILinkedItemsElement } from '../../../itemEditor/models/contentItemElements/modularItems/ILinkedItemsElement.ts';
import { getServerNumberValue } from '../../../itemEditor/utils/itemElementDataConverters/numberItemElementDataConverter.ts';
import {
  ElementPreviewDataResolvers,
  ModularContentPreviewDataContext,
} from '../../types/ElementPreviewDataResolvers.type.ts';
import { getDeliveryContentComponentIdMap } from '../../utils/livePreviewUtils/getDeliveryContentComponentIdMap.ts';
import {
  getAssetData,
  getLinkedItemData,
  getMultipleChoiceValues,
  getPlainText,
  getTaxonomyData,
} from '../../utils/livePreviewUtils/getElementDataForPreviewUtils.ts';
import { getRichTextData } from '../../utils/livePreviewUtils/getRichTextElementDataForPreview.ts';

const elementTypeValueMap: ElementPreviewDataResolvers = {
  [ElementType.DateTime]: (element: DateTimeItemElement) => ({
    type: DeliverElementType.DateTime,
    data: {
      value: element.value || null,
      displayTimeZone: element.displayTimeZone,
    },
  }),
  [ElementType.MultipleChoice]: (
    element: IMultipleChoiceItemElement,
    typeElement: IMultipleChoiceTypeElement,
  ) => ({
    type: DeliverElementType.MultipleChoice,
    data: {
      value: getMultipleChoiceValues(element, typeElement),
    },
  }),
  [ElementType.Number]: (element: INumberItemElement) => ({
    type: DeliverElementType.Number,
    data: {
      value: getServerNumberValue(element.value),
    },
  }),
  [ElementType.Text]: (element: ITextItemElement) => ({
    type: DeliverElementType.Text,
    data: { value: getPlainText(element) },
  }),
  [ElementType.RichText]: (element: IRichTextItemElement, _, getState, context) => {
    if (!context) {
      return null;
    }

    const data = getRichTextData(element, getState, context);

    return (
      data && {
        type: DeliverElementType.RichText,
        data,
      }
    );
  },
  [ElementType.Custom]: (element: ICustomItemElement) => ({
    type: DeliverElementType.Custom,
    data: {
      value: element.value,
    },
  }),
  [ElementType.UrlSlug]: (element: IUrlSlugItemElement) => ({
    type: DeliverElementType.UrlSlug,
    data: {
      value: getPlainText(element),
    },
  }),
  [ElementType.Taxonomy]: (
    element: ITaxonomyItemElement,
    typeElement: ITaxonomyTypeElement,
    getState: GetState,
  ) => {
    const data = getTaxonomyData(element, typeElement, getState);

    return (
      data && {
        type: DeliverElementType.Taxonomy,
        data,
      }
    );
  },
  [ElementType.Asset]: (element: IAssetItemElement, _, getState: GetState) => {
    const data = getAssetData(element, getState);

    return (
      data && {
        type: DeliverElementType.Asset,
        data,
      }
    );
  },
  [ElementType.LinkedItems]: (element: ILinkedItemsElement, _, getState: GetState) => {
    const data = getLinkedItemData(element, getState);

    return {
      type: DeliverElementType.ModularContent,
      data,
    };
  },
};

interface IDeps {
  readonly ensureContentLinksDefaultVariantsLoaded: (
    rootRichTextElement: IRichTextItemElement,
  ) => ThunkPromise;
}

export const getElementDataForPreviewActionCreator =
  (deps: IDeps) =>
  (
    element: ICompiledContentItemElementData,
    typeElement: EditableTypeElement,
  ): ThunkFunction<Promise<ElementUpdateData | null>> =>
  async (dispatch, getState) => {
    const {
      contentApp: { editedContentItem },
      sharedApp: {
        selectedLanguage: { id: selectedLanguageId },
      },
    } = getState();

    if (!editedContentItem || !selectedLanguageId) {
      return null;
    }

    const getValue = elementTypeValueMap[element.type];

    if (!getValue) {
      return null;
    }

    if (isRichTextElement(element)) {
      await dispatch(deps.ensureContentLinksDefaultVariantsLoaded(element));

      const deliveryContentComponentIds = await getDeliveryContentComponentIdMap(
        editedContentItem.id,
        selectedLanguageId,
        element.elementId,
        Array.from(element.contentComponents.values()),
      );

      const modularContentContext: ModularContentPreviewDataContext = {
        rootRichTextElement: element,
        elementResolvers: elementTypeValueMap,
        deliveryContentComponentIds,
      };

      return getValue(element, typeElement, getState, modularContentContext);
    }

    return getValue(element, typeElement, getState);
  };
