import React, { useMemo } from 'react';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { ModalContentItemSelectorLayout } from '../../../../_shared/features/ContentItemModalLayout/ModalContentItemSelectorLayout.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { ContentItemFilterOrigin } from '../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { ITableRowSkeletComponentOwnProps } from '../../../../_shared/uiComponents/ScrollTable/ScrollTable.tsx';
import { IListingContentItem } from '../../../../data/models/listingContentItems/IListingContentItem.ts';
import { getItemListForScrollTable } from '../../../../data/reducers/listingContentItems/selectors/getItemListForScrollTable.ts';
import { isItemsInitialLoadFinished } from '../../../../data/reducers/listingContentItems/selectors/isItemsInitialLoadFinished.ts';
import {
  clearContentItemListingFilter,
  filterContentItemsInDialog,
  loadListingContentItemsForInventory,
} from '../../../contentInventory/content/features/ContentItemInventory/actions/thunkContentItemInventoryActions.ts';
import { ContentItemScrollTable } from '../../../contentInventory/content/features/ContentItemInventory/containers/ItemInventoryScrollTable/ContentItemScrollTable.tsx';
import { ContentItemScrollTableEmptyState } from '../../../contentInventory/content/features/ContentItemInventory/containers/ItemInventoryScrollTable/ContentItemScrollTableEmptyState.tsx';
import { ContentItemScrollTableTitle } from '../../../contentInventory/content/features/ContentItemInventory/containers/ItemInventoryScrollTable/ContentItemScrollTableTitle.tsx';
import {
  ContentItemFilter,
  isContentItemFilterInitialized,
} from '../../../contentInventory/content/features/ListingFilter/containers/ContentItemFilter.tsx';
import { PreselectedFilterIds } from '../../../contentInventory/content/features/ListingFilter/hooks/useSetUpContentItemFilter.ts';
import { getNumberOfItemsInViewport } from '../../../contentInventory/content/reducers/listingUi/selectors/getNumberOfItemsInViewport.ts';
import { useSpacesIds } from '../../../environmentSettings/spaces/hooks/useSpaceIds.ts';
import {
  modalContentItemSelectorClosed,
  modalContentItemSelectorOpened,
} from '../actions/modalContentItemSelectorActions.ts';
import { ContentItemSelectorScrollTableHead } from './ContentItemSelectorScrollTable/ContentItemSelectorScrollTableHead.tsx';
import { ContentItemSelectorScrollTableRow } from './ContentItemSelectorScrollTable/ContentItemSelectorScrollTableRow.tsx';

interface IProps {
  readonly allowedContentTypeIds?: ReadonlyArray<Uuid>;
  readonly collectionId?: Uuid | undefined;
  readonly forceContentTypesTooltipText?: string;
  readonly forcedContentTypeIds?: ReadonlySet<Uuid>;
  readonly getItemDisabledTooltipMessage?: (item: IListingContentItem) => string | undefined;
  readonly onClose: () => void;
  readonly onSelect: (contentItemId: Uuid) => void;
  readonly titleBarText: string;
}

const renderRowItem = (
  params: ITableRowSkeletComponentOwnProps<IListingContentItem>,
  getItemDisabledTooltipMessage?: (item: IListingContentItem) => string | undefined,
) => {
  const itemDisabledTooltipMessage = getItemDisabledTooltipMessage?.(params.item);

  return (
    <ContentItemSelectorScrollTableRow
      disabledClickMessage={itemDisabledTooltipMessage}
      key={params.item.item.id + params.index}
      index={params.index}
      item={params.item}
      isDisabled={!!itemDisabledTooltipMessage}
      onItemClick={itemDisabledTooltipMessage ? undefined : params.onItemClick}
      onItemDoubleClick={itemDisabledTooltipMessage ? undefined : params.onItemDoubleClick}
    />
  );
};

const getItemsForScrollTable = (state: IStore) => {
  const contentItemListingScrollTableState =
    state.contentApp.listingUi.contentItemListingScrollTableState;
  const listingContentItems = state.data.listingContentItems;

  return getItemListForScrollTable(
    listingContentItems,
    getNumberOfItemsInViewport(contentItemListingScrollTableState),
  );
};

export const ModalContentItemSelector: React.FC<IProps> = ({
  allowedContentTypeIds,
  collectionId,
  forceContentTypesTooltipText,
  forcedContentTypeIds,
  getItemDisabledTooltipMessage,
  onClose,
  onSelect,
  titleBarText,
}) => {
  const dispatch = useDispatch();
  const isInitRetrieving = useSelector(
    (state) => !isItemsInitialLoadFinished(state.data.listingContentItems),
  );
  const scrollTableItems = useSelector(getItemsForScrollTable);
  const renderScrollTable = useSelector(
    (s) =>
      isItemsInitialLoadFinished(s.data.listingContentItems) &&
      isContentItemFilterInitialized(
        ContentItemFilterOrigin.ModalContentItemSelector,
        s.contentApp.listingUi.filterStatus,
      ),
  );
  const listingItemsLoadingStatus = useSelector((s) => s.data.listingContentItems.loadingStatus);
  const spaceIds = useSpacesIds(collectionId);

  const closeModal = () => {
    dispatch(modalContentItemSelectorClosed());
    onClose();
  };

  const openModal = () => {
    dispatch(modalContentItemSelectorOpened());
  };

  const selectContentItem = (contentItemId: Uuid) => {
    dispatch(modalContentItemSelectorClosed());
    onSelect(contentItemId);
  };

  const preselectedFilterIds = useMemo<PreselectedFilterIds>(
    () => ({
      collectionIds: collectionId ? [collectionId] : undefined,
      contentTypeIds: allowedContentTypeIds,
      spaceIds,
    }),
    [allowedContentTypeIds, collectionId, spaceIds],
  );

  return (
    <ModalContentItemSelectorLayout
      titleBarText={titleBarText}
      isInitRetrieving={isInitRetrieving}
      onInit={openModal}
      onClose={closeModal}
      renderItemFilter={() => (
        <ContentItemFilter
          clearFilter={() => dispatch(clearContentItemListingFilter(forcedContentTypeIds))}
          forceContentTypesTooltipText={forceContentTypesTooltipText}
          forcedContentTypeIds={forcedContentTypeIds}
          listingItemsLoadingStatus={listingItemsLoadingStatus}
          onFilterChange={() => dispatch(filterContentItemsInDialog())}
          origin={ContentItemFilterOrigin.ModalContentItemSelector}
          preselectedFilterIds={preselectedFilterIds}
        />
      )}
      renderScrollTable={(ref) => {
        return renderScrollTable ? (
          <ContentItemScrollTable
            items={scrollTableItems}
            onItemClick={selectContentItem}
            onItemDoubleClick={selectContentItem}
            onLoadContentItems={loadListingContentItemsForInventory(true)}
            parentContainerRef={ref}
            renderEmptyState={() => (
              <ContentItemScrollTableEmptyState
                forcedContentTypeIds={forcedContentTypeIds}
                isInDialog
              />
            )}
            renderRowItem={(params) => renderRowItem(params, getItemDisabledTooltipMessage)}
            renderTableHead={() => (
              <ContentItemSelectorScrollTableHead
                onItemOrderChanged={() => dispatch(loadListingContentItemsForInventory())}
              />
            )}
            renderTableTitle={() => <ContentItemScrollTableTitle />}
            withColumnSettings
          />
        ) : (
          <Loader />
        );
      }}
    />
  );
};

ModalContentItemSelector.displayName = 'ModalContentItemSelector';
