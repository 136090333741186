import { combineReducers } from '@reduxjs/toolkit';
import { contributors } from './contributors.ts';
import { dueDate } from './dueDate.ts';
import { isInBulk } from './isInBulk.ts';
import { note } from './note.ts';
import { savingError } from './savingError.ts';
import { scheduleMethod } from './scheduleMethod.ts';
import { scheduledPublishDisplayTimeZone } from './scheduledPublishDisplayTimeZone.ts';
import { scheduledToPublishAt } from './scheduledToPublishAt.ts';
import { scheduledToUnpublishAt } from './scheduledToUnpublishAt.ts';
import { scheduledUnpublishDisplayTimeZone } from './scheduledUnpublishDisplayTimeZone.ts';
import { showAllContributors } from './showAllContributors.ts';
import { workflowStep } from './workflowStep.ts';
import { workflowStepAction } from './workflowStepAction.ts';

export const changeWorkflowStepModalData = combineReducers({
  contributors,
  dueDate,
  isInBulk,
  note,
  savingError,
  scheduledToPublishAt,
  scheduledPublishDisplayTimeZone,
  scheduledToUnpublishAt,
  scheduledUnpublishDisplayTimeZone,
  scheduleMethod,
  showAllContributors,
  workflowStepAction,
  workflowStep,
});
