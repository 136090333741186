import PropTypes from 'prop-types';
import React, { SVGProps } from 'react';
import styled from 'styled-components';
import { rotate } from '../../styles/animations.tsx';
import { getDataUiComponentAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { IIconProps } from './IIconProps.type.ts';
import { Icons } from './components/icons.ts';
import { iconPropTypes } from './iconPropTypes.ts';

export const AnimatedProgressIconWrapper = styled.span<Pick<AnimatedProgressIconProps, 'display'>>`
  display: ${({ display }) => display};

  animation-direction: normal;
  animation-delay: 0s;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: ${rotate};
  animation-timing-function: linear;
`;

type AnimatedProgressIconProps = Omit<IIconProps, 'size'> & {
  // it needs to be "block-ish" for the animation to work
  readonly display?: 'block' | 'inline-block';
};
type AnimatedProgressIconWithoutSVGProps = Omit<
  AnimatedProgressIconProps,
  keyof Omit<SVGProps<SVGSVGElement>, 'children' | 'color' | 'display' | 'ref'>
>;
const propTypes: PropTypeMap<AnimatedProgressIconWithoutSVGProps> = {
  ...iconPropTypes,
  display: PropTypes.oneOf(['block', 'inline-block']),
};

export const AnimatedProgressIcon = React.forwardRef<SVGSVGElement, AnimatedProgressIconProps>(
  ({ display = 'block', ...props }, forwardedRef) => {
    return (
      <AnimatedProgressIconWrapper
        display={display}
        {...getDataUiComponentAttribute(AnimatedProgressIcon)}
      >
        <Icons.Spinner display="block" ref={forwardedRef} {...props} />
      </AnimatedProgressIconWrapper>
    );
  },
);

AnimatedProgressIcon.displayName = 'AnimatedProgressIcon';
AnimatedProgressIcon.propTypes = propTypes;
