import React from 'react';
import { useParams } from 'react-router';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { openProject } from '../actions/thunkSmartLinkActions.ts';
import { SmartLink } from '../components/SmartLink.tsx';
import { EnvironmentRouteParams } from '../constants/routePaths.ts';

export const OpenProjectSmartLink: React.FC = () => {
  const dispatch = useDispatch();
  const { projectId } = useParams<EnvironmentRouteParams>();

  const onMount = () => dispatch(openProject(projectId));

  return <SmartLink onMount={onMount} />;
};

OpenProjectSmartLink.displayName = 'OpenProjectSmartLink';
