import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import PropTypes from 'prop-types';
import React from 'react';
import { ValidatedInput } from '../../../../_shared/components/input/ValidatedInput.tsx';
import { ValidatedOptionWithInputAttribute } from '../../../../_shared/components/input/ValidatedOption.tsx';
import { getLandingPageLinks } from '../../../../_shared/constants/landingPageLinks.ts';
import { ValidationConstants } from '../../../../_shared/constants/validationConstants.ts';
import { OptionMode } from '../../../../_shared/models/optionMode.ts';
import { HookFormProps, hookFormProps } from '../../../../_shared/types/hookFormProps.ts';
import { DataUiInput } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export interface INewSubscriptionFormShape {
  readonly subscriptionName: string;
  readonly acceptTerms: boolean;
}

type Props = {
  readonly children: React.ReactNode;
  readonly formProps: HookFormProps<INewSubscriptionFormShape>;
  readonly onSubmit: (() => void) | null;
};

const propTypes: PropTypesShape<Props> = {
  children: PropTypes.node.isRequired,
  formProps: hookFormProps.isRequired,
  onSubmit: PropTypes.func,
};

export const NewSubscriptionForm: React.FC<Props> = ({ children, formProps, onSubmit }) => (
  <form className="form" onSubmit={onSubmit ?? undefined}>
    <ValidatedInput<INewSubscriptionFormShape>
      name="subscriptionName"
      label="Subscription name"
      maxLength={ValidationConstants.SubscriptionNameMaxLength}
      dataUiInputName={DataUiInput.SubscriptionName}
      autoFocus
      formProps={formProps}
    />
    <div className="form__message">{children}</div>
    <ValidatedOptionWithInputAttribute
      mode={OptionMode.InvertedMultiple}
      dataUiAttribute={DataUiInput.Checkbox}
      name="acceptTerms"
      formProps={formProps}
    >
      <span>
        I accept the&nbsp;
        <OutwardLink href={getLandingPageLinks().termsOfService} stopClickPropagation>
          Terms of Service
        </OutwardLink>
      </span>
    </ValidatedOptionWithInputAttribute>
  </form>
);

NewSubscriptionForm.displayName = 'NewSubscriptionForm';
NewSubscriptionForm.propTypes = propTypes;
