import React from 'react';
import {
  CompiledTypeElementType,
  ElementType,
} from '../../contentInventory/content/models/ContentItemElementType.ts';
import { IItemElementOwnProps } from '../features/ContentItemEditing/components/elements/IItemElementOwnProps.type.ts';
import { DateTime } from '../features/ContentItemEditing/containers/elements/DateTime.tsx';
import { MultipleChoice } from '../features/ContentItemEditing/containers/elements/MultipleChoice.tsx';
import { NumberElement } from '../features/ContentItemEditing/containers/elements/NumberElement.tsx';
import { Asset } from '../features/ContentItemEditing/containers/elements/asset/Asset.tsx';
import { CustomElement } from '../features/ContentItemEditing/containers/elements/customElement/CustomElement.tsx';
import { LinkedItems } from '../features/ContentItemEditing/containers/elements/modularContent/LinkedItems.tsx';
import { RichString } from '../features/ContentItemEditing/containers/elements/richString/RichString.tsx';
import { StringElement } from '../features/ContentItemEditing/containers/elements/stringElement/StringElement.tsx';
import { Taxonomy } from '../features/ContentItemEditing/containers/elements/taxonomy/Taxonomy.tsx';
import { UrlSlug } from '../features/ContentItemEditing/containers/elements/urlSlug/UrlSlug.tsx';

type ItemElement = React.ComponentType<IItemElementOwnProps<any>>;

const componentMap: { readonly [key in CompiledTypeElementType]: ItemElement } = {
  [ElementType.Asset]: Asset,
  [ElementType.Custom]: CustomElement,
  [ElementType.DateTime]: DateTime,
  [ElementType.Guidelines]: () => null,
  [ElementType.LinkedItems]: LinkedItems,
  [ElementType.MultipleChoice]: MultipleChoice,
  [ElementType.Number]: NumberElement,
  [ElementType.RichText]: RichString,
  [ElementType.Subpages]: LinkedItems,
  [ElementType.Taxonomy]: Taxonomy,
  [ElementType.Text]: StringElement,
  [ElementType.UrlSlug]: UrlSlug,
};

export const getItemElementEditComponent = (type: CompiledTypeElementType): ItemElement =>
  componentMap[type];
