import React from 'react';
import { DataTableRow } from '../../../../../_shared/components/DataTable/DataTableRow.tsx';
import { LinkDataTableCell } from '../../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { IUserInfo } from '../../../../../_shared/models/UserInfo.ts';
import { pluralizeWithCount } from '../../../../../_shared/utils/stringUtils.ts';
import { IListingVariantData } from '../../../../../data/models/listingContentItems/IListingVariantData.ts';
import { WorkflowStatusCellContent } from '../../../../contentInventory/content/features/ContentItemInventory/components/ItemInventoryScrollTable/Cells/ContentItemScrollTableCellsContents.tsx';
import { ScrollTableItemContributors } from '../../../contentStatus/components/ScrollTableItemContributors.tsx';
import { daysSinceDate } from '../../../utils/daysSinceDate.ts';

type UnchangedUnpublishedWidgetDetailRowProps = Readonly<{
  collectionName: string;
  contributors: ReadonlyArray<IUserInfo>;
  itemName: string;
  lastEnteredWorkflowStep: Date;
  linkPath: string | null;
  variant: IListingVariantData;
  variantName: string;
}>;

export const UnchangedUnpublishedWidgetDetailRow: React.FC<
  UnchangedUnpublishedWidgetDetailRowProps
> = ({
  collectionName,
  contributors,
  itemName,
  lastEnteredWorkflowStep,
  linkPath,
  variant,
  variantName,
}) => {
  const itemId = `${variant.id.itemId}:${variant.id.variantId}`;

  return (
    <DataTableRow dataUiObjectName={itemId} id={itemId}>
      <LinkDataTableCell linkPath={linkPath}>{itemName}</LinkDataTableCell>
      <LinkDataTableCell linkPath={linkPath}>{variantName}</LinkDataTableCell>
      <LinkDataTableCell linkPath={linkPath}>{collectionName}</LinkDataTableCell>
      <LinkDataTableCell linkPath={linkPath}>
        <ScrollTableItemContributors contributors={contributors} />
      </LinkDataTableCell>
      <LinkDataTableCell linkPath={linkPath}>
        <WorkflowStatusCellContent variant={variant} />
      </LinkDataTableCell>
      <LinkDataTableCell linkPath={linkPath}>
        {pluralizeWithCount('day', daysSinceDate(lastEnteredWorkflowStep))}
      </LinkDataTableCell>
    </DataTableRow>
  );
};

UnchangedUnpublishedWidgetDetailRow.displayName = 'UnchangedUnpublishedWidgetDetailRow';
