import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { ILoadAssetsAction } from '../../../../../../data/actions/thunks/assets/loadAssets.ts';
import { AssetReference } from '../../../../models/contentItemElements/AssetItemElement.ts';
import { ICompiledContentItemElementData } from '../../../../models/contentItemElements/ICompiledContentItemElement.ts';
import {
  IGetItemIdsFromElements,
  IGetReferencesFromElements,
  ReferenceType,
} from '../../../../utils/itemReferences/itemElementReferencesUtils.ts';
import { IEnsureLoadedContentTypesAction } from '../../../LoadedItems/actions/thunks/ensureLoadedContentTypes.ts';
import { ILoadListingItemsAction } from '../../../LoadedItems/actions/thunks/loadListingItems.ts';

export type ILoadContentItemReferencesAction = (
  elements: ReadonlyArray<ICompiledContentItemElementData>,
  abortSignal?: AbortSignal,
) => ThunkPromise;

interface ILoadContentItemReferencesActionDependency {
  readonly getReferencedContentItemIds: IGetItemIdsFromElements;
  readonly loadListingItems: ILoadListingItemsAction;
  readonly getAssetReferences: IGetReferencesFromElements<AssetReference>;
  readonly loadAssets: ILoadAssetsAction;
  readonly getContentTypeIdsUsedInContentComponents: IGetReferencesFromElements;
  readonly ensureLoadedContentTypes: IEnsureLoadedContentTypesAction;
}

export const loadContentItemReferencesActionCreator =
  (deps: ILoadContentItemReferencesActionDependency): ILoadContentItemReferencesAction =>
  (elements, abortSignal) =>
  async (dispatch) => {
    const itemIds = deps
      .getReferencedContentItemIds(elements, ReferenceType.ModularItemsAndTextLinks)
      .toArray();

    const assetReferences = deps.getAssetReferences(elements);

    const contentTypeIds = new Set(
      deps.getContentTypeIdsUsedInContentComponents(elements).toArray(),
    );

    await Promise.all([
      dispatch(deps.ensureLoadedContentTypes(contentTypeIds, abortSignal)),
      dispatch(deps.loadListingItems(itemIds, abortSignal)),
      dispatch(deps.loadAssets(assetReferences.toArray(), abortSignal)),
    ]);
  };
