import React from 'react';
import {
  ItemEditingForms,
  getDataUiObjectNameAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { NoteFormBase } from '../../../containers/details/Note/NoteFormBase.tsx';
import { INoteFormShape } from '../../../models/INoteFormShape.type.ts';
import { LeafActionFormProps } from '../EditingLeafActionForm.tsx';

type NoteFormProps = LeafActionFormProps & {
  readonly initialNote: string | null | undefined;
  readonly onCancel: () => void;
  readonly onConfirm: (values: INoteFormShape) => Promise<void>;
};

export const NoteForm: React.FC<NoteFormProps> = (props) => (
  <div
    className="editing-actions-assignment-form"
    {...getDataUiObjectNameAttribute(ItemEditingForms.Note)}
  >
    <NoteFormBase {...props} />
  </div>
);

NoteForm.displayName = 'NoteForm';
