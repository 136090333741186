import { AnimatedProgressIcon, Icons } from '@kontent-ai/component-library/Icons';
import { Column, Row } from '@kontent-ai/component-library/Row';
import {
  SimpleStatusAlign,
  SimpleStatusDefault,
  SimpleStatusError,
  SimpleStatusSuccess,
} from '@kontent-ai/component-library/SimpleStatus';
import { IconSize, Spacing } from '@kontent-ai/component-library/tokens';
import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { AiOperationState } from '../../../../../../../_shared/features/AI/types/AiOperationState.ts';
import {
  AiError,
  getAiErrorMessage,
} from '../../../../../../../_shared/features/AI/types/aiErrors.ts';
import { translateContentErrorMessageByErrorCode } from '../../../../../../richText/plugins/ai/utils/translateContentAiErrors.ts';

type AiTranslateStatusProps = {
  readonly aiOperationState: AiOperationState;
  readonly error: AiError | null;
};

export const AiTranslateStatus: React.FC<AiTranslateStatusProps> = ({
  aiOperationState,
  error,
}) => {
  if (aiOperationState === AiOperationState.Pending) {
    return <PendingStatus />;
  }

  if (error) {
    return <ErrorStatus error={error} />;
  }

  if (aiOperationState === AiOperationState.Finished) {
    return <FinishedStatus />;
  }

  return null;
};

AiTranslateStatus.displayName = 'AiTranslateStatus';

const PendingStatus: React.FC = () => {
  const [showTranslationIsDelayed, setShowTranslationIsDelayed] = useState(false);
  const debouncedShowTranslationIsDelayed = useDebouncedCallback(
    () => setShowTranslationIsDelayed(true),
    15000,
  );

  useEffect(() => {
    debouncedShowTranslationIsDelayed();
    return debouncedShowTranslationIsDelayed.cancel;
  }, [debouncedShowTranslationIsDelayed]);

  return (
    <TranslationStatusWrapper>
      <SimpleStatusDefault
        label={`Translation in progress…${
          showTranslationIsDelayed ? ' this may take a while.' : ''
        }`}
        icon={AnimatedProgressIcon}
        iconAlign={SimpleStatusAlign.Right}
      />
    </TranslationStatusWrapper>
  );
};

const FinishedStatus: React.FC = () => (
  <TranslationStatusWrapper>
    <SimpleStatusSuccess
      label="Translation finished"
      icon={Icons.CbCheckPreview}
      iconAlign={SimpleStatusAlign.Right}
    />
  </TranslationStatusWrapper>
);

const ErrorStatus: React.FC<{ readonly error: AiError }> = ({ error }) => (
  <TranslationStatusWrapper>
    <SimpleStatusError
      icon={Icons.ExclamationTriangleInverted}
      iconAlign={SimpleStatusAlign.Right}
      label={getAiErrorMessage(error, translateContentErrorMessageByErrorCode)}
    />
  </TranslationStatusWrapper>
);

const TranslationStatusWrapper: React.FC<React.PropsWithChildren<NoProps>> = ({ children }) => (
  <Row alignY="center" noWrap spacingX={Spacing.XS}>
    <Column width="content">
      <Icons.KopilotAi size={IconSize.S} />
    </Column>
    <Column>{children}</Column>
  </Row>
);
