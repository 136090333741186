import {
  EditableElementType,
  ElementType,
} from '../../contentInventory/content/models/ContentItemElementType.ts';
import { IAssetItemElement } from '../models/contentItemElements/AssetItemElement.ts';
import { ICustomItemElement } from '../models/contentItemElements/CustomItemElement.ts';
import { DateTimeItemElement } from '../models/contentItemElements/DateTimeItemElement.ts';
import { ICompiledContentItemElementData } from '../models/contentItemElements/ICompiledContentItemElement.ts';
import { IMultipleChoiceItemElement } from '../models/contentItemElements/MultipleChoiceItemElement.ts';
import { INumberItemElement } from '../models/contentItemElements/NumberItemElement.ts';
import { IRichTextItemElement } from '../models/contentItemElements/RichTextItemElement.ts';
import { ITaxonomyItemElement } from '../models/contentItemElements/TaxonomyItemElement.ts';
import { ITextItemElement } from '../models/contentItemElements/TextItemElement.ts';
import { IUrlSlugItemElement } from '../models/contentItemElements/UrlSlugItemElement.ts';
import { ILinkedItemsElement } from '../models/contentItemElements/modularItems/ILinkedItemsElement.ts';

type ElementValueSelector = (element: ICompiledContentItemElementData) => unknown;

const elementValueSelectors: Record<EditableElementType, ElementValueSelector> = {
  [ElementType.Asset]: (element: IAssetItemElement) => element.value,
  [ElementType.Custom]: (element: ICustomItemElement) => element.value,
  [ElementType.DateTime]: (element: DateTimeItemElement) => element._dateTime,
  [ElementType.LinkedItems]: (element: ILinkedItemsElement) => element.value,
  [ElementType.MultipleChoice]: (element: IMultipleChoiceItemElement) => element.options,
  [ElementType.Number]: (element: INumberItemElement) => element._number,
  [ElementType.RichText]: (element: IRichTextItemElement) => element._editorState,
  [ElementType.Subpages]: (element: ILinkedItemsElement) => element.value,
  [ElementType.Taxonomy]: (element: ITaxonomyItemElement) => element.value,
  [ElementType.Text]: (element: ITextItemElement) => element._editorState,
  [ElementType.UrlSlug]: (element: IUrlSlugItemElement) => element._editorState,
};

export const getItemElementValueForErrorValidation = <
  TItemElement extends ICompiledContentItemElementData,
>(
  itemElement: TItemElement,
): any => {
  const valueSelector = elementValueSelectors[itemElement.type];
  return valueSelector(itemElement);
};
