import { Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import React, { useCallback } from 'react';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { Task } from '../../../../../../../_shared/models/Task.ts';
import { filterOutNullishOrEmpty } from '../../../../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import { getContributorsAvailableAsAssigneesInTasks } from '../../../../../../../_shared/utils/permissions/getAvailableContributors.ts';
import { cancelTaskEditing } from '../../../actions/contentItemEditingActions.ts';
import { archiveTask, updateTask } from '../../../actions/thunkContentItemEditingActions.ts';
import { EditTaskFormBase as EditTaskFormBaseComponent } from '../../../components/details/Tasks/EditTaskFormBase.tsx';
import { ITaskFormShape } from '../../../models/ITaskFormShape.type.ts';
import { isTaskArchiving as isTaskArchivingSelector } from '../../../selectors/isTaskArchiving.ts';
import { isTaskSubmitting as isTaskSubmittingSelector } from '../../../selectors/isTaskSubmitting.ts';

type Props = {
  readonly taskId: Uuid;
};

const getTaskAssigneeId = (task: Task | null | undefined) => {
  return Array.from(task?.assignees ?? [])[0] ?? null;
};

const EditTaskFormBaseContainer: React.FC<Props> = ({ taskId }) => {
  const allActiveContributors = useSelector(
    (s) => getContributorsAvailableAsAssigneesInTasks(s).allActive,
  );
  const isTaskSubmitting = useSelector((s) => isTaskSubmittingSelector(s, taskId));
  const isTaskArchiving = useSelector((s) => isTaskArchivingSelector(s, taskId));
  const task = useSelector((s) => s.data.tasks.byId.get(taskId));

  const defaultValues: ITaskFormShape = {
    assignedContributorId: getTaskAssigneeId(task),
    taskDescription: task?.description ?? '',
    taskDueDate: task ? { value: task.dueDate ?? '', isValid: true } : { value: '', isValid: true },
  };

  const dispatch = useDispatch();

  const onConfirm = (values: ITaskFormShape) =>
    dispatch(
      updateTask(
        taskId,
        values.taskDescription,
        filterOutNullishOrEmpty([values.assignedContributorId]),
        values.taskDueDate,
      ),
    );
  const onCancel = () => dispatch(cancelTaskEditing(taskId));
  const onArchive = () => dispatch(archiveTask(taskId));

  const hasFormUnsavedChanges = useCallback(
    (values: ITaskFormShape): boolean => {
      const changedAssigneesId = values.assignedContributorId;

      return (
        changedAssigneesId !== getTaskAssigneeId(task) ||
        values.taskDescription !== task?.description
      );
    },
    [task],
  );

  return (
    <EditTaskFormBaseComponent
      allActiveContributors={allActiveContributors}
      defaultValues={defaultValues}
      hasFormUnsavedChanges={hasFormUnsavedChanges}
      isTaskSubmitting={isTaskSubmitting}
      isTaskArchiving={isTaskArchiving}
      onCancel={onCancel}
      onConfirm={onConfirm}
      taskId={taskId}
      renderExtraButton={() => (
        <Button
          buttonStyle="secondary"
          destructive
          disabled={isTaskSubmitting || isTaskArchiving}
          type="button"
          destructiveIcon={Icons.Bin}
          onClick={() => onArchive()}
          size="small"
        >
          {isTaskArchiving ? 'Deleting…' : 'Delete'}
        </Button>
      )}
    />
  );
};

EditTaskFormBaseContainer.displayName = 'EditTaskFormBaseContainer';

export { EditTaskFormBaseContainer as EditTaskFormBase };
