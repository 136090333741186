import { Icons } from '@kontent-ai/component-library/Icons';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { BaseColor, Spacing } from '@kontent-ai/component-library/tokens';
import { memoize } from '@kontent-ai/memoization';
import { notNull, notUndefined } from '@kontent-ai/utils';
import classNames from 'classnames';
import React, { memo, useContext, useMemo, useRef, useState } from 'react';
import { ConnectDragSource } from 'react-dnd';
import { CreateAutoScrollId } from '../../../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { IgnoreByGrammarly } from '../../../../../../_shared/components/IgnoreByGrammarly.tsx';
import { IconName } from '../../../../../../_shared/constants/iconEnumGenerated.ts';
import { EditorPaperContext } from '../../../../../../_shared/contexts/EditorPaperContext.tsx';
import { useAutoScroll } from '../../../../../../_shared/hooks/useAutoScroll.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiComponentIdAttribute,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getReachedPaperThresholds } from '../../../../../../_shared/utils/editorViewContext/editorPaperContext/utils/getReachedPaperThresholds.ts';
import { getDefaultLanguage } from '../../../../../../data/reducers/languages/selectors/getLanguages.ts';
import { ICompiledContentType } from '../../../../../contentInventory/content/models/CompiledContentType.ts';
import { useItemVariantId } from '../../../../../itemEditor/features/ContentComponent/context/ContentItemContext.tsx';
import { useDeliveryContentComponentId } from '../../../../../itemEditor/features/ContentComponent/hooks/useDeliveryContentComponentId.tsx';
import { ContentNestingContext } from '../../../../../itemEditor/features/ContentItemEditing/context/ContentNestingContext.tsx';
import { IBaseTextItemElement } from '../../../../../itemEditor/models/contentItemElements/IBaseTextItemElement.ts';
import { ICompiledContentItemElementData } from '../../../../../itemEditor/models/contentItemElements/ICompiledContentItemElement.ts';
import {
  isRichTextElement,
  isTextElement,
} from '../../../../../itemEditor/models/contentItemElements/compiledItemElementTypeGuards.ts';
import { getElementById } from '../../../../../itemEditor/stores/utils/contentItemElementsUtils.ts';
import { useContentComponent } from '../../../../hooks/useContentComponent.ts';
import { exportToServerPlainText } from '../../../../utils/export/plainText/exportToPlainText.ts';
import { ContentComponentConversionConfirmationDialog } from '../../containers/dialogs/ContentComponentConversionConfirmationDialog.tsx';
import {
  CollapsedContentComponentCssClass,
  ContentComponentCssClass,
  ContentComponentHeaderCssClass,
} from '../../utils/contentComponentRenderingUtils.ts';
import { ActionButton } from '../actions/ActionButton.tsx';
import { ContentComponentActions } from '../actions/ContentComponentActions.tsx';
import { ContentComponentCollapseAction } from '../actions/ContentComponentCollapseAction.tsx';
import { ContentComponentDragAction } from '../actions/ContentComponentDragAction.tsx';
import { ContentComponentDeleteConfirmationDialog } from '../dialogs/ContentComponentDeleteConfirmationDialog.tsx';

interface IContentComponentAutoScrollProps {
  readonly componentRef: React.RefObject<HTMLElement>;
}

const ContentComponentAutoScroll: React.FC<IContentComponentAutoScrollProps> = ({
  componentRef,
}) => {
  const deliveryContentComponentId = useDeliveryContentComponentId();
  const { itemId } = useItemVariantId();
  const scrollId = CreateAutoScrollId.forContentComponent(deliveryContentComponentId ?? '', itemId);
  useAutoScroll({
    scrollId,
    scrollTargetRef: componentRef,
  });

  return null;
};
ContentComponentAutoScroll.displayName = 'ContentComponentAutoScroll';

export interface IContentComponentItemStateProps {
  readonly canCreateContent: boolean;
  readonly canUpdate: boolean;
  readonly contentType?: ICompiledContentType;
  readonly isCollapsed: boolean;
  readonly isConversionPossible?: boolean;
  readonly isEntryTypeAllowed: boolean;
}

export interface IContentComponentItemDispatchProps {
  readonly onCollapse: (contentComponentId: Uuid) => void;
  readonly onExpand: (contentComponentId: Uuid) => void;
}

export interface IContentComponentItemOwnProps {
  readonly connectDragSource?: ConnectDragSource;
  readonly contentComponentId: Uuid;
  readonly isDragging: boolean;
  readonly onConvertComponentToItemVariant?: (selectedWorkflowId?: Uuid) => void;
  readonly onDelete: () => void;
  readonly renderExpanded?: () => React.ReactNode;
}

interface IContentComponentItemProps
  extends IContentComponentItemStateProps,
    IContentComponentItemDispatchProps,
    IContentComponentItemOwnProps {}

export const ContentComponentItem: React.FC<IContentComponentItemProps> = memo(
  ({
    canCreateContent,
    canUpdate,
    connectDragSource,
    contentComponentId,
    contentType,
    isCollapsed,
    isConversionPossible,
    isDragging,
    isEntryTypeAllowed,
    onCollapse,
    onConvertComponentToItemVariant,
    onDelete,
    onExpand,
    renderExpanded,
  }) => {
    const defaultLanguage = useSelector(getDefaultLanguage);

    const [isConvertConfirmationPending, setIsConvertConfirmationPending] = useState(false);
    const [isDeleteConfirmationPending, setIsDeleteConfirmationPending] = useState(false);

    const { isTopLevel } = useContext(ContentNestingContext);
    const paperContext = useContext(EditorPaperContext);
    const reachedThresholds = getReachedPaperThresholds(paperContext.thresholds);
    const displayContentType = reachedThresholds.sizeL || reachedThresholds.sizeM;
    const contentComponent = useContentComponent(contentComponentId);
    const contentComponentTitle = useMemo(
      () =>
        contentComponent?.elements &&
        contentType?.contentElements
          .map((typeElement) => getElementById(typeElement.elementId, contentComponent.elements))
          .filter(notUndefined)
          .map(getTitle)
          .find(notNull),
      [contentComponent?.elements, contentType?.contentElements],
    );
    const hasElementsForTitle =
      !!contentComponentTitle || !!contentComponent?.elements.some(isElementForTitle);

    const ref = useRef<HTMLDivElement>(null);

    const isExpanded = !isCollapsed && !isDragging && !!renderExpanded;

    const hideConvertConfirmationDialog = () => {
      setIsConvertConfirmationPending(false);
    };

    const hideDeleteConfirmationDialog = () => {
      setIsDeleteConfirmationPending(false);
    };

    const handleConvertComponentToItemVariant = (selectedWorkflowId?: Uuid) => {
      hideConvertConfirmationDialog();
      onConvertComponentToItemVariant?.(selectedWorkflowId);
    };

    const handleConvertButtonClicked = () => {
      setIsConvertConfirmationPending(!isConvertConfirmationPending);
    };

    const handleDeleteComponentToItemVariant = () => {
      hideDeleteConfirmationDialog();
      onDelete();
    };

    const handleDeleteButtonClicked = () => {
      setIsDeleteConfirmationPending(!isDeleteConfirmationPending);
    };

    const toggleCollapse = () => {
      const action = isCollapsed ? onExpand : onCollapse;
      action(contentComponentId);
    };

    return (
      <div
        className={classNames(ContentComponentCssClass, {
          [CollapsedContentComponentCssClass]: !isExpanded,
          'content-component--is-disabled': !canUpdate,
          'content-component--is-dragging': isDragging,
          'content-component--is-top-level': isTopLevel,
          'content-component--is-not-allowed': !isEntryTypeAllowed,
          'content-component--has-content-groups': !contentType?.contentGroups.isEmpty(),
        })}
        ref={ref}
        {...getDataUiElementAttribute(DataUiElement.ContentComponentItem)}
        {...getDataUiComponentIdAttribute(contentComponentId)}
      >
        <ContentComponentAutoScroll componentRef={ref} />
        <div
          className={classNames(ContentComponentHeaderCssClass, {
            [`${ContentComponentHeaderCssClass}--is-disabled`]: !canUpdate,
          })}
        >
          {canUpdate && (
            <ContentComponentActions>
              <ContentComponentDragAction
                connectDragSource={connectDragSource}
                isCompact={reachedThresholds.sizeXS}
              />
            </ContentComponentActions>
          )}
          <ContentComponentActions>
            <ContentComponentCollapseAction
              onClick={toggleCollapse}
              isCollapsed={!isExpanded}
              isCompact={reachedThresholds.sizeXS}
            />
          </ContentComponentActions>
          <div
            title={contentComponentTitle ?? createNoTitleFallback(hasElementsForTitle)}
            className="content-component__title"
            onClick={toggleCollapse}
            {...getDataUiElementAttribute(DataUiElement.ContentComponentTitle)}
          >
            <Row css="max-width: 100%" spacingX={Spacing.S} alignY="center" noWrap>
              <Column
                css={`
                overflow: hidden;
                text-overflow: ellipsis;
                ${contentComponentTitle ? '' : `color: ${BaseColor.Gray40}`}
              `}
              >
                <IgnoreByGrammarly
                  text={contentComponentTitle ?? createNoTitleFallback(hasElementsForTitle)}
                />
              </Column>
              <Column width="content">
                <Tooltip
                  tooltipText={createTitleTooltip(hasElementsForTitle, contentComponentTitle)}
                  placement="top"
                >
                  <Icons.ICircle />
                </Tooltip>
              </Column>
            </Row>
          </div>
          {displayContentType && (
            <div
              title={contentType?.name}
              onClick={toggleCollapse}
              className={classNames('content-component__description', {
                'content-component__description--is-invalid': !isEntryTypeAllowed,
              })}
              {...getDataUiElementAttribute(DataUiElement.ContentComponentType)}
            >
              <IgnoreByGrammarly text={contentType?.name} />
            </div>
          )}
          <ContentComponentActions>
            {canUpdate && canCreateContent && handleConvertComponentToItemVariant && (
              <ContentComponentConversionConfirmationDialog
                contentTypeId={contentType?.id}
                isOpen={isConvertConfirmationPending}
                onCancel={hideConvertConfirmationDialog}
                onConvert={handleConvertComponentToItemVariant}
                renderTarget={(targetProps) => (
                  <ActionButton
                    disabled={!isConversionPossible}
                    isCompact={reachedThresholds.sizeXS}
                    isActionConfirmationPending={isConvertConfirmationPending}
                    dataUiActionAttribute={
                      DataUiAction.ShowContentComponentConversionConfirmationDialog
                    }
                    tooltipText={
                      isConversionPossible
                        ? 'Reuse as content item'
                        : `Components with non-localizable elements can be converted only in ${defaultLanguage.name}.`
                    }
                    iconName={IconName.Convert}
                    onClick={handleConvertButtonClicked}
                    {...targetProps}
                  />
                )}
              />
            )}
            {canUpdate && (
              <ContentComponentDeleteConfirmationDialog
                onDelete={handleDeleteComponentToItemVariant}
                onCancel={hideDeleteConfirmationDialog}
                isOpen={isDeleteConfirmationPending}
                renderTarget={(targetProps) => (
                  <ActionButton
                    isCompact={reachedThresholds.sizeXS}
                    isActionConfirmationPending={isDeleteConfirmationPending}
                    dataUiActionAttribute={DataUiAction.Delete}
                    tooltipText="Delete"
                    className="content-component__action--is-destructive"
                    iconName={IconName.Bin}
                    onClick={handleDeleteButtonClicked}
                    buttonClassName={classNames('btn--quinary-destructive', {
                      'btn--quinary-destructive-activated': isDeleteConfirmationPending,
                    })}
                    {...targetProps}
                  />
                )}
              />
            )}
          </ContentComponentActions>
        </div>
        {isExpanded && renderExpanded?.()}
      </div>
    );
  },
);

ContentComponentItem.displayName = 'ContentComponentItem';

const createTitleTooltip = (
  hasElementsValidForTitle: boolean,
  title: string | undefined,
): string => {
  if (!hasElementsValidForTitle) {
    return 'Would you like to see a preview of what’s inside? Let us know via chat.';
  }
  if (!title) {
    return 'Add some content to a text element to see the text preview.';
  }
  return 'This is a sneak peek of what’s inside the component.';
};

const createNoTitleFallback = (hasElementsValidForTitle: boolean): string =>
  hasElementsValidForTitle
    ? 'No text preview available.'
    : 'Expand the component to see what’s inside.';

const isElementForTitle = (
  element: ICompiledContentItemElementData,
): element is IBaseTextItemElement => isTextElement(element) || isRichTextElement(element);

const getTitle = memoize.weak((element: ICompiledContentItemElementData): string | null =>
  isElementForTitle(element)
    ? exportToServerPlainText(element._editorState.getCurrentContent())
        .split('\n')
        .map((l) => l.trim())
        .find((l) => l) ?? null
    : null,
);
