import { Collection } from '@kontent-ai/utils';
import { forwardRef } from 'react';
import { DataUiRteAction } from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { BlockType, getBlockTypeIconName } from '../../../../utils/blocks/blockType.ts';
import { EditorFeatureLimitations } from '../../../apiLimitations/api/EditorFeatureLimitations.ts';
import {
  TopLevelBlockCategoryFeature,
  isTextFeatureAllowed,
} from '../../../apiLimitations/api/editorLimitationUtils.ts';
import { RichTextInputCommand } from '../../../keyboardShortcuts/api/EditorCommand.ts';
import { ParagraphToolbar } from '../../../textBlockTypes/components/ParagraphToolbar.tsx';
import { BlockToolbarButton } from './BlockToolbarButton.tsx';

export type Resettable = {
  readonly reset: () => void;
};

export type ParagraphToolbarButtonProps = {
  readonly fullBlockTypesAtSelection: ReadonlySet<BlockType>;
  readonly hidesDisallowedFeatures: boolean;
  readonly isToolbarVertical: boolean;
  readonly limitations: EditorFeatureLimitations;
  readonly onCommand: (command: RichTextInputCommand) => void;
};

export const ParagraphToolbarButton = forwardRef<Resettable, ParagraphToolbarButtonProps>(
  (
    {
      isToolbarVertical,
      hidesDisallowedFeatures,
      fullBlockTypesAtSelection,
      limitations,
      onCommand,
    },
    ref,
  ) => {
    const textAllowed = isTextFeatureAllowed(
      TopLevelBlockCategoryFeature.Text,
      fullBlockTypesAtSelection,
      limitations,
    );
    const currentBlockType = Collection.getFirst(Collection.getValues(fullBlockTypesAtSelection));

    if ((hidesDisallowedFeatures && !textAllowed) || !currentBlockType) {
      return null;
    }

    return (
      <BlockToolbarButton
        buttonDescription={textAllowed ? 'Adjust text formatting' : 'Text is not allowed here'}
        disabled={!textAllowed}
        iconName={getBlockTypeIconName(currentBlockType)}
        ref={ref}
        toolbarPlacement={isToolbarVertical ? 'right' : 'top-start'}
        fallbackToolbarPlacements={isToolbarVertical ? ['right'] : ['bottom-start']}
        uiAction={DataUiRteAction.ChangeBlockType}
      >
        <ParagraphToolbar
          fullBlockTypesAtSelection={fullBlockTypesAtSelection}
          hidesDisallowedFeatures={hidesDisallowedFeatures}
          limitations={limitations}
          onCommand={onCommand}
        />
      </BlockToolbarButton>
    );
  },
);

ParagraphToolbarButton.displayName = 'ParagraphButton';
