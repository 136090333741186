import { Action } from '../../../../../../../@types/Action.type.ts';
import { IContributorsFormState } from '../../../../../../contentInventory/content/stores/IContentAppStoreState.ts';
import {
  ContentItemEditing_ContributorsForm_Changed,
  ContentItemEditing_ContributorsForm_Reset,
  ContentItemEditing_Sidebar_Section_Cleaned_Up,
} from '../../../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

const initialState: IContributorsFormState = {
  unsavedValues: null,
};

export const contributors = (
  state: IContributorsFormState = initialState,
  action: Action,
): IContributorsFormState => {
  switch (action.type) {
    case ContentItemEditing_ContributorsForm_Changed:
      return {
        unsavedValues: action.payload.formValues,
      };

    case ContentItemEditing_ContributorsForm_Reset:
    case ContentItemEditing_Sidebar_Section_Cleaned_Up:
      return initialState;

    default:
      return state;
  }
};
