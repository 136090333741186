import PropTypes from 'prop-types';
import React from 'react';
import { Box } from '../../../layout/Box/Box.tsx';
import { spacingCard } from '../../../tokens/decision/spacing.ts';

interface ICardFooterProps {
  readonly children: React.ReactNode;
}

const propTypes: PropTypeMap<ICardFooterProps> = {
  children: PropTypes.node.isRequired,
};

export const CardFooter = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<ICardFooterProps>
>(({ children, ...otherProps }, forwardedRef) => (
  <Box ref={forwardedRef} paddingTop={spacingCard} css="grid-area: footer" {...otherProps}>
    {children}
  </Box>
));

CardFooter.displayName = 'CardFooter';
CardFooter.propTypes = propTypes;
