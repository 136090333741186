import PropTypes from 'prop-types';
import React from 'react';
import {
  CustomTypeElementPropTypes,
  ICustomTypeElement,
} from '../../../../../contentInventory/content/models/contentTypeElements/CustomTypeElement.ts';
import {
  CustomItemElementPropTypes,
  ICustomItemElement,
} from '../../../../models/contentItemElements/CustomItemElement.ts';
import { ElementValue } from '../../../../types/CustomElementApi.ts';
import { ItemElement } from '../../../ContentItemEditing/containers/elements/ItemElement.tsx';
import { ItemElementRefresher } from '../../../ContentItemEditing/containers/elements/ItemElementRefresher.tsx';
import { CustomElementSandbox } from '../../../ContentItemEditing/containers/elements/customElement/CustomElementSandbox.tsx';
import { IRevisionItemElementProps } from '../../utils/itemElementRevisionComponentCreator.ts';
import { CustomElementDiff } from './subelements/CustomElementDiff.tsx';

interface ICustomElementProps {
  readonly getElementValue: (elementCodename: string) => ElementValue | undefined;
}

type CustomElementProps = IRevisionItemElementProps<ICustomItemElement, ICustomTypeElement> &
  ICustomElementProps;

const propTypes: PropTypeMap<CustomElementProps> = {
  elementData: CustomItemElementPropTypes.isRequired,
  hideValidationStatus: PropTypes.bool,
  getElementValue: PropTypes.func.isRequired,
  originalElementData: CustomItemElementPropTypes,
  revisionElementModifiedBy: PropTypes.string,
  typeElement: CustomTypeElementPropTypes.isRequired,
};

export const CustomElement: React.FC<CustomElementProps> = ({
  elementData,
  hideValidationStatus,
  getElementValue,
  originalElementData,
  typeElement,
  revisionElementModifiedBy,
}) => (
  <ItemElement
    disabled
    hideValidationStatus={hideValidationStatus}
    isDisplayOnly
    revisionChangedBy={revisionElementModifiedBy}
    typeElement={typeElement}
  >
    <ItemElementRefresher
      elementData={elementData}
      renderInput={(refresherElementData: ICustomItemElement) =>
        originalElementData ? (
          <CustomElementDiff
            elementData={refresherElementData}
            originalElementData={originalElementData}
          />
        ) : (
          <CustomElementSandbox
            className="custom-element__sandbox"
            typeElement={typeElement}
            value={refresherElementData.value}
            getElementValue={getElementValue}
            disabled
          />
        )
      }
    />
  </ItemElement>
);

CustomElement.displayName = 'CustomElement (Revisions)';
CustomElement.propTypes = propTypes;
