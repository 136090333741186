import { Collection } from '@kontent-ai/utils';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Loader } from '../../../../../_shared/components/Loader.tsx';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import {
  createUserDetailLink,
  createUserListingLink,
} from '../../../../../_shared/utils/routing/projectSubscriptionRoutingUtils.ts';
import { IProjectContributor } from '../../../../../data/models/users/ProjectContributor.ts';
import { initializePeopleListingForProject } from '../../actions/thunkUsersActions.ts';
import { userListingLeft } from '../../actions/usersActions.ts';
import { UserListing } from '../../components/userListing/UserListing.tsx';
import { useUserListingDisplayedUsers } from '../../hooks/useUserListingDisplayedUsers.ts';
import { filterByNameOrEmail } from '../../selectors/filterUsers.ts';
import { getUserListingState } from '../../utils/getUserListingState.ts';

type Props = {
  readonly projectId: Uuid;
  readonly subscriptionId?: Uuid;
};

const UserListingContainer: React.FC<Props> = ({ projectId, subscriptionId }) => {
  const loadingStatus = useSelector((state) => state.usersApp.listingLoadingStatus);
  const nameFilter = useSelector((state) => state.usersApp.filter.byName);
  const projectContributors = useSelector((state) => state.usersApp.projectContributors);

  const displayedUsers = useUserListingDisplayedUsers();

  const onCreateUserDetailLink = useCallback(
    (userId: Uuid) =>
      createUserDetailLink({
        projectId,
        subscriptionId,
        userId,
      }),
    [projectId, subscriptionId],
  );

  const foundAnyContributorsByName = useMemo(
    () =>
      Collection.getValues(projectContributors).filter(
        (contributor: IProjectContributor) =>
          !contributor.isVirtual && filterByNameOrEmail(contributor, nameFilter),
      ).length > 0,
    [projectContributors, nameFilter],
  );

  const userListingState = useSelector((state) =>
    getUserListingState(state, displayedUsers.length, foundAnyContributorsByName),
  );
  const userListingLink = useMemo(
    () => createUserListingLink({ projectId, subscriptionId }),
    [projectId, subscriptionId],
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializePeopleListingForProject(projectId));

    return () => {
      dispatch(userListingLeft());
    };
  }, [projectId]);

  if (loadingStatus !== LoadingStatus.Loaded) {
    return <Loader />;
  }

  return (
    <UserListing
      onCreateUserDetailLink={onCreateUserDetailLink}
      state={userListingState}
      userListingLink={userListingLink}
    />
  );
};

UserListingContainer.displayName = 'UserListingContainer';

export { UserListingContainer as UserListing };
