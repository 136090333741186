import classNames from 'classnames';
import { Pathname } from 'history';
import React, { ReactNode } from 'react';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { DataTableAction, DataTableActions } from './DataTableActions.tsx';
import { DataTableCreateNewButton } from './DataTableCreateNewButton.tsx';

export interface IDataTableProps {
  readonly actions?: ReadonlyArray<DataTableAction>;
  readonly className?: string;
  readonly createNewItemLabel?: string;
  readonly createNewItemLinkPath?: Pathname;
  readonly createNewItemTooltip?: string;
  readonly dataUiCollectionName: DataUiCollection;
  readonly header?: ReactNode;
  readonly infoMessage?: ReactNode;
  readonly isCreateNewDisabled?: boolean;
  readonly noBoxShadow?: boolean;
  readonly onCreateNewItem?: (event: any) => void;
  readonly pagination?: ReactNode;
  readonly secondaryAction?: ReactNode;
  readonly title?: ReactNode;
}

export const DataTable: React.FC<React.PropsWithChildren<IDataTableProps>> = (props) => {
  const {
    actions,
    children,
    className,
    createNewItemLinkPath,
    dataUiCollectionName,
    header,
    infoMessage,
    isCreateNewDisabled,
    noBoxShadow,
    onCreateNewItem,
    pagination,
    secondaryAction,
    title,
  } = props;

  const isCreateNewButton = onCreateNewItem || isCreateNewDisabled || createNewItemLinkPath;
  const areActionsNotEmpty = !!actions?.length;

  return (
    <div
      className={classNames('data-table', className, { 'data-table--no-shadow': noBoxShadow })}
      {...getDataUiCollectionAttribute(dataUiCollectionName)}
    >
      {(isCreateNewButton || areActionsNotEmpty || secondaryAction || title) && (
        <div className="data-table__actions-pane">
          {title}
          <div className="data-table__actions u-space-between--2">
            {secondaryAction}
            {isCreateNewButton && (
              <DataTableCreateNewButton showOtherActions={areActionsNotEmpty} {...props} />
            )}
            {areActionsNotEmpty && <DataTableActions actions={actions} />}
          </div>
        </div>
      )}
      <table
        className={classNames('data-table__grid', { 'data-table__grid--no-items': !children })}
      >
        {header}
        <tbody>{children}</tbody>
      </table>
      {pagination && <div className="data-table__pagination">{pagination}</div>}
      {infoMessage}
    </div>
  );
};

DataTable.displayName = 'DataTable';
