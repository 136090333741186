import {
  BaseColor,
  Size,
  Spacing,
  Typography,
  colorTextDefaultInverse,
  px,
} from '@kontent-ai/component-library/tokens';
import { DataUiAttributes, getDataAttribute } from '@kontent-ai/component-library/utils';
import styled from 'styled-components';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../utils/dataAttributes/DataUiAttributes.ts';
import { mainContentId } from './SkipToMainContent.tsx';

const StyledMainLayoutGrid = styled.div.attrs(
  getDataAttribute(DataUiAttributes.Component, 'StyledMainLayoutGrid'),
)`
  position: absolute;
  inset: 0;
  display: grid;
  grid-template:
    "warnings warnings" auto
    "status-bar status-bar" auto
    "nav-menu app-view" minmax(0, 1fr) / max-content minmax(0, 1fr);
`;

const WarningBar = styled.div.attrs(getDataAttribute(DataUiAttributes.Component, 'WarningBar'))`
  grid-area: warnings;
`;

const StatusBar = styled.div.attrs({
  ...getDataAttribute(DataUiAttributes.Component, 'StatusBar'),
  ...getDataUiElementAttribute(DataUiElement.StatusBar),
})`
  grid-area: status-bar;
  ${Typography.UIParagraph}
  position: relative;
  display: flex;
  width: 100%;
  height: ${px(Size.L)};
  padding: ${px(Spacing.XS)} ${px(Spacing.XL)} ${px(Spacing.XS)} ${px(Spacing.L)};
  line-height: 1; // Reset from typography styles
  color: ${colorTextDefaultInverse};
  background-color: ${BaseColor.Black};
  align-items: center;
  flex: 0 0 auto;
  gap: ${px(Spacing.XXL)};
  `;

const NavMenu = styled.div.attrs(getDataAttribute(DataUiAttributes.Component, 'NavMenu'))`
  grid-area: nav-menu;
`;

const AppView = styled.main.attrs({
  ...getDataAttribute(DataUiAttributes.Component, 'AppView'),
  ...{ id: mainContentId },
})`
  grid-area: app-view;
`;

export const MainLayoutGrid = Object.assign(StyledMainLayoutGrid, {
  WarningBar,
  StatusBar,
  NavMenu,
  AppView,
});
