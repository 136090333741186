import { VerticalMenuItem, useVerticalMenu } from '@kontent-ai/component-library/VerticalMenu';
import { BaseColor } from '@kontent-ai/component-library/tokens';
import { ReactNode } from 'react';
import { useHistory } from 'react-router';
import { SelectGroupRenderMode } from '../../../../../component-library/components/StatusBar/Selects/types/SelectGroupRenderMode.ts';
import { WorkflowStatus } from '../../../../../component-library/components/WorkflowStatus/WorkflowStatus.tsx';
import { trackProjectSettingsClick } from '../../../../applications/projectSettings/root/actions/thunkProjectSettingsActions.ts';
import { AppNames } from '../../../constants/applicationNames.ts';
import { ProjectSettingsSourceTypes } from '../../../models/TrackUserEventData.ts';
import { StatefulDropDown } from '../../../uiComponents/DropDown/StatefulDropDown.tsx';
import {
  DataUiAction,
  DataUiCollection,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { StatusBarDropDownSelected } from '../../StatusBar/StatusBarDropDownSelected.tsx';
import { ItemSettings } from '../ItemSettings.tsx';
import { SelectMenuItem } from '../SelectMenuItem.tsx';
import { optionMaxWidth, projectMenuTippyOptions } from '../constants.ts';
import { MenuItem } from '../types.type.ts';

const renderStatusTag = (project: ProjectMenuItem): ReactNode => {
  if (project.isPlanExpired) {
    return (
      <WorkflowStatus
        primary={{
          name: 'Expired',
          background: BaseColor.Red60,
        }}
      />
    );
  }

  if (project.isPlanSuspended) {
    return (
      <WorkflowStatus
        primary={{
          name: 'Suspended',
          background: BaseColor.Red30,
        }}
      />
    );
  }

  return null;
};

export type ProjectMenuItem = MenuItem &
  VerticalMenuItem<ProjectMenuItem> & {
    readonly isPlanExpired: boolean;
    readonly isPlanSuspended: boolean;
    readonly settingsEnabled: boolean;
    readonly settingsLink: string;
  };

type ProjectSelectProps = {
  readonly projectItems: ReadonlyArray<ProjectMenuItem>;
  readonly renderMode: SelectGroupRenderMode;
  readonly selectedProjectName: string;
};

export const ProjectSelect: React.FC<ProjectSelectProps> = ({
  projectItems,
  selectedProjectName,
  renderMode,
}) => {
  const history = useHistory();

  const {
    verticalMenuState: projectVerticalMenuState,
    VerticalMenu,
    verticalMenuProps,
  } = useVerticalMenu<ProjectMenuItem>([
    {
      id: 'projects',
      items: projectItems,
      label: 'Projects',
      type: 'section',
    },
  ]);

  return (
    <StatefulDropDown
      optionListDataUiAttributes={getDataUiCollectionAttribute(DataUiCollection.Projects)}
      renderContent={(closeMenu) => (
        <VerticalMenu
          {...verticalMenuProps}
          state={projectVerticalMenuState}
          pinnedItemId="projects-pinned"
          maxWidth={optionMaxWidth}
          renderItem={({ item }) =>
            item.value && (
              <SelectMenuItem
                closeMenu={closeMenu}
                item={item.value}
                statusTag={renderStatusTag(item.value)}
                settings={
                  item.value.settingsEnabled && (
                    <ItemSettings
                      onClick={() => {
                        closeMenu();
                        if (item.value) {
                          trackProjectSettingsClick(
                            item.value.id,
                            ProjectSettingsSourceTypes.ProjectSelector,
                          );
                          history.push(item.value.settingsLink);
                        }
                      }}
                      tooltipText={AppNames.ProjectSettings}
                    />
                  )
                }
              />
            )
          }
        />
      )}
      renderSelectedOption={(ref, toggleProjectList, isOpen) => (
        <StatusBarDropDownSelected
          dataUiAttributes={getDataUiActionAttribute(DataUiAction.OpenProjectMenu)}
          isExpanded={isOpen}
          onClick={toggleProjectList}
          ref={ref}
          renderMode={renderMode}
        >
          {selectedProjectName}
        </StatusBarDropDownSelected>
      )}
      tippyOptions={projectMenuTippyOptions}
    />
  );
};

ProjectSelect.displayName = 'ProjectSelect';
