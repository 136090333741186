import React from 'react';
import { EnvironmentSettingsApp } from '../../root/components/EnvironmentSettingsApp.tsx';
import { RedirectIfMultipleWorkflowsAreNotAvailable } from '../containers/RedirectIfMultipleWorkflowsAreNotAvailable.tsx';
import { WorkflowsListing } from '../containers/WorkflowsListing.tsx';

type WorkflowsListingPageProps = {
  readonly subscriptionId?: Uuid;
};

export const WorkflowsListingPage: React.FC<WorkflowsListingPageProps> = ({ subscriptionId }) => (
  <RedirectIfMultipleWorkflowsAreNotAvailable>
    <EnvironmentSettingsApp>
      <WorkflowsListing subscriptionId={subscriptionId} />
    </EnvironmentSettingsApp>
  </RedirectIfMultipleWorkflowsAreNotAvailable>
);

WorkflowsListingPage.displayName = 'WorkflowsListingPage';
