import { Collection } from '@kontent-ai/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { isCodenameUnique } from '../../../applications/contentModels/shared/utils/typeCodenameUtils.ts';
import {
  ArrowPosition,
  Popover,
  PopoverIsPointing,
  PopoverMessage,
} from '../../uiComponents/Popover/Popover.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { Codename } from './Codename.tsx';

export interface ICodenamePopoverDataProps {
  readonly arrowPosition?: ArrowPosition;
  readonly codename: string;
  readonly isEditable?: boolean;
  readonly maxLength?: number;
  readonly relatedCodenames?: ReadonlySet<string>;
  readonly snippetCodename?: string | null;
}

export interface ICodenamePopoverCallbackProps {
  readonly onAfterCopied: () => void;
  readonly onCodenameChange?: (codename: string) => void;
  readonly onCopied?: () => void;
  readonly onEdited?: () => void;
}

type CodenamePopoverProps = ICodenamePopoverDataProps & ICodenamePopoverCallbackProps;

const propTypes: PropTypeMap<CodenamePopoverProps> = {
  arrowPosition: PropTypes.oneOf(Object.values(ArrowPosition)),
  codename: PropTypes.string.isRequired,
  isEditable: PropTypes.bool,
  maxLength: PropTypes.number,
  onAfterCopied: PropTypes.func.isRequired,
  onCodenameChange: PropTypes.func,
  onCopied: PropTypes.func,
  onEdited: PropTypes.func,
  relatedCodenames: PropTypes.instanceOf<ReadonlySet<string>>(Set),
  snippetCodename: PropTypes.string,
};

export const CodenamePopover: React.FC<CodenamePopoverProps> = (props) => {
  const isCodenameUniqueCallback = (codename: string) =>
    isCodenameUnique(
      codename,
      props.relatedCodenames && Collection.remove(props.relatedCodenames, props.codename),
    );

  return (
    <Popover
      orientation={PopoverIsPointing.Up}
      arrowPosition={props.arrowPosition || ArrowPosition.End}
      hintDataAttribute={getDataUiElementAttribute(DataUiElement.CodenameForm)}
      hasFullWidth
    >
      <PopoverMessage>
        <Codename
          codename={props.codename}
          isEditable={props.isEditable}
          isCodenameUnique={isCodenameUniqueCallback}
          maxLength={props.maxLength}
          onCodenameChange={props.onCodenameChange}
          onCopyCompleted={props.onAfterCopied}
          onEdited={props.onEdited}
          snippetCodename={props.snippetCodename}
          onCopied={props.onCopied}
        />
      </PopoverMessage>
    </Popover>
  );
};

CodenamePopover.displayName = 'CodenamePopover';
CodenamePopover.propTypes = propTypes;
