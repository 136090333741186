import { Action } from '../../../../../../@types/Action.type.ts';
import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { ContentItemSidebarEditableSection } from '../../constants/ContentItemSidebarEditableSection.ts';
import {
  ContentItemEditing_Sidebar_Section_UpdateFinished,
  ContentItemEditing_Sidebar_Section_UpdateStarted,
} from '../../constants/contentItemEditingActionTypes.ts';
import { shouldCloseSidebarForm } from '../../selectors/shouldCloseSidebarForm.ts';

const startUpdateAction = (submittingSection: ContentItemSidebarEditableSection) =>
  ({
    type: ContentItemEditing_Sidebar_Section_UpdateStarted,
    payload: {
      submittingSection,
    },
  }) as const;

const updatedAction = (
  submittedSection: ContentItemSidebarEditableSection,
  shouldSectionClose: boolean,
) =>
  ({
    type: ContentItemEditing_Sidebar_Section_UpdateFinished,
    payload: {
      shouldSectionClose,
      submittedSection,
    },
  }) as const;

const contentItemEditingSidebarSectionUpdated =
  (submittingSection: ContentItemSidebarEditableSection): ThunkFunction =>
  (dispatch, getState) => {
    const closeSubmittingSection = shouldCloseSidebarForm(getState(), submittingSection);

    dispatch(updatedAction(submittingSection, closeSubmittingSection));
  };

export type CreateUpdateContentItemEditingSidebarSectionActionsType = ReturnType<
  typeof updatedAction | typeof startUpdateAction
>;

type ActionsCreator = (section: ContentItemSidebarEditableSection) => {
  startSidebarSectionUpdate: Action;
  finishSidebarSectionUpdate: ThunkFunction;
};
export const createUpdateContentItemEditingSidebarSectionActions: ActionsCreator = (section) => ({
  startSidebarSectionUpdate: startUpdateAction(section),
  finishSidebarSectionUpdate: contentItemEditingSidebarSectionUpdated(section),
});
