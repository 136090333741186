import { areMembersEqual, notNullNorUndefined } from '@kontent-ai/utils';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { createFormValidationResolver } from '../../../../../_shared/utils/validation/createFormValidationResolver.ts';
import { areMultipleWorkflowsEnabledForCurrentProject } from '../../../selectors/allowedFeaturesUtils.ts';
import {
  cancelWorkflowStepEditor,
  editedWorkflowStepUnsavedChangesUpdated,
  saveArchivedWorkflowStepEditor,
} from '../../actions/workflowActions.ts';
import { ArchivedStepEditor } from '../../components/stepEditors/ArchivedStepEditor.tsx';
import { IArchivedWorkflowStepFormShape } from '../../model/IArchivedWorkflowStepFormShape.type.ts';
import { getOrderedAllSteps } from '../../selectors/getOrderedWorkflowSteps.ts';
import { getSortedRoleOptionsListWithAllRolesFirst } from '../../selectors/getSortedRoleOptionsListWithAllRolesFirst.ts';
import {
  getDefaultRolesForFormInput,
  handleAllRolesRoleOnSubmit,
} from '../../utils/allRolesRoleBehaviorUtils.ts';
import { getTransitionFromSteps } from '../../utils/stepEditorInitialValuesUtils.ts';
import { archivedWorkflowStepValidationConfig } from '../../validation/archivedWorkflowStepValidation.ts';

type Props = {
  readonly id: Uuid;
};

const ArchivedStepEditorContainer: React.FC<Props> = ({ id }) => {
  const workflowStep = useSelector((s) => s.workflowsApp.editorUi.archivedWorkflowStep);
  const allRenderedSteps = useSelector((s) => getOrderedAllSteps(s.workflowsApp.editorUi));
  const allRoles = useSelector((s) =>
    getSortedRoleOptionsListWithAllRolesFirst(s.data.roles.rolesById),
  );

  const dispatch = useDispatch();
  const onStatusEditingCanceled = () => dispatch(cancelWorkflowStepEditor());

  const defaultValues = useMemo(
    (): IArchivedWorkflowStepFormShape => ({
      transitionFromStepIds: getTransitionFromSteps(allRenderedSteps, workflowStep.id, false).map(
        (step) => step.id,
      ),
      roleIds: getDefaultRolesForFormInput(workflowStep.roleIds, allRoles, false).map(
        (role) => role.id,
      ),
    }),
    [allRenderedSteps, workflowStep, allRoles],
  );

  const formProps = useForm<IArchivedWorkflowStepFormShape>({
    defaultValues,
    resolver: createFormValidationResolver(archivedWorkflowStepValidationConfig, {}),
  });

  const submitForm = formProps.handleSubmit((values) =>
    dispatch(
      saveArchivedWorkflowStepEditor({
        stepId: id,
        transitionsFrom: new Set(
          values.transitionFromStepIds
            .map((stepId) => allRenderedSteps.find((step) => step.id === stepId))
            .filter(notNullNorUndefined),
        ),
        roleIds: handleAllRolesRoleOnSubmit(values.roleIds),
      }),
    ),
  );

  const { watch } = formProps;

  useEffect(() => {
    const subscription = watch((updatedStep: IArchivedWorkflowStepFormShape) => {
      const hasUnsavedChanges = hasEditorUnsavedChanges(updatedStep, defaultValues);

      dispatch(editedWorkflowStepUnsavedChangesUpdated(hasUnsavedChanges));
    });

    return () => {
      dispatch(editedWorkflowStepUnsavedChangesUpdated(false));
      subscription.unsubscribe();
    };
  }, [watch, defaultValues]);

  const restoreFromArchivedStepMessage = useSelector(areMultipleWorkflowsEnabledForCurrentProject)
    ? 'Archived items can be restored by the roles specified below. Restored items move to the first step.'
    : 'Archived items can be restored by the roles specified below. Restored items move to the first step available to a given role.';

  return (
    <ArchivedStepEditor
      allRenderedSteps={allRenderedSteps}
      allRoles={allRoles}
      formProps={formProps}
      onStatusEditingCanceled={onStatusEditingCanceled}
      onSubmit={submitForm}
      restoreFromArchivedStepMessage={restoreFromArchivedStepMessage}
      workflowStep={workflowStep}
    />
  );
};

ArchivedStepEditorContainer.displayName = 'ArchivedStepEditorContainer';
export { ArchivedStepEditorContainer as ArchivedStepEditor };

const hasEditorUnsavedChanges = (
  values: IArchivedWorkflowStepFormShape,
  otherValues: IArchivedWorkflowStepFormShape,
): boolean =>
  !areMembersEqual(values.transitionFromStepIds, otherValues.transitionFromStepIds) ||
  !areMembersEqual(values.roleIds, otherValues.roleIds);
