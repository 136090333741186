import React from 'react';
import { Link } from 'react-router-dom';
import { IconName } from '../../../../constants/iconEnumGenerated.ts';
import { Icon } from '../../../../uiComponents/Icon/Icon.tsx';

type Props = {
  readonly onClick: () => void;
  readonly workflowPath: string;
};

export const ConfigureWorkflowLink: React.FC<Props> = ({ onClick, workflowPath }) => (
  <div className="configure-workflow-link">
    <Icon iconName={IconName.ICircle} />
    <span className="configure-workflow-link__hint-text">
      You can configure workflow in&nbsp;
      <Link to={workflowPath} onClick={onClick}>
        Project settings
      </Link>
      .
    </span>
  </div>
);

ConfigureWorkflowLink.displayName = 'ConfigureWorkflowLink';
