import Immutable from 'immutable';
import { IRole } from '../../../../data/models/roles/IRole.ts';

export const deletedRoleIdBase = 'DeletedRole';

export const getDeletedRole = (key: string): IRole => ({
  id: `${deletedRoleIdBase}-${key}`,
  name: 'Deleted role',
  capabilities: Immutable.Set(),
  isReadonly: true,
  codename: '',
});
