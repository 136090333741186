import { InvariantException } from '@kontent-ai/errors';
import { IStore } from '../../../_shared/stores/IStore.type.ts';
import { ICompiledContentType } from '../../contentInventory/content/models/CompiledContentType.ts';

export function getEditedContentItemTypeOrThrow(state: IStore): ICompiledContentType {
  const {
    contentApp: { editedContentItem, loadedContentItemTypes },
  } = state;

  if (!editedContentItem) {
    throw InvariantException('editedContentItem is not loaded');
  }

  const editedContentItemType = loadedContentItemTypes.get(
    editedContentItem.editedContentItemTypeId,
  );
  if (!editedContentItemType) {
    throw InvariantException('editedContentItemType is not loaded');
  }

  return editedContentItemType;
}
