import { assert } from '@kontent-ai/utils';
import { History } from 'history';
import { Dispatch, GetState, ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { rememberReturnScrollId } from '../../../../../../_shared/actions/sharedActions.ts';
import { CreateAutoScrollId } from '../../../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { getLinkedContentItemPath } from '../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import {
  IContentItemWithVariantsServerModel,
  RequiredItemCreationParams,
} from '../../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import { CollectionSection } from '../../../NewContentItem/constants/collectionSection.ts';
import { ContentTypeSection } from '../../../NewContentItem/constants/contentTypeSection.ts';
import { getNewContentItemForm } from '../../../NewContentItem/selectors/newContentItemDialog.ts';
import { markEditedItemAsTemporary } from '../contentItemEditingActions.ts';

type CreateNewLinkedItemProps = {
  readonly insertAtIndex: number | null;
};

interface IDeps {
  readonly createNewLinkedItem: (
    createItemParams: RequiredItemCreationParams,
  ) => ThunkPromise<IContentItemWithVariantsServerModel>;
}

export const createNewItemFromLinkedItemsActionCreator =
  (deps: IDeps) =>
  (
    creationProps: CreateNewLinkedItemProps,
    elementId: Uuid,
    history: History,
    onAddNewItem: (
      itemId: Uuid,
      contentTypeSection: ContentTypeSection,
      collectionSection: CollectionSection,
      insertAtIndex: number | null,
    ) => void,
  ): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const state = getState();
    const {
      contentApp: { editedContentItem, editedContentItemVariant },
    } = state;

    if (!editedContentItemVariant || !editedContentItem) {
      return;
    }

    const { insertAtIndex } = creationProps;
    const creationParams = getItemCreationParams(state);
    const contentItem = await dispatch(deps.createNewLinkedItem(creationParams));
    const itemId = contentItem.item.id;
    const { collectionSection, contentTypeSection } = getNewContentItemForm(state);

    onAddNewItem(itemId, contentTypeSection, collectionSection, insertAtIndex);

    const currentPath = location.pathname;

    dispatch(
      markEditedItemAsTemporary({
        itemId,
        variantId: editedContentItemVariant.id.variantId,
        parentItem: {
          itemId: editedContentItemVariant.id.itemId,
          elementId,
        },
      }),
    );

    const targetPath = getLinkedContentItemPath(currentPath, itemId);

    history.push(targetPath);

    const returnScrollId = CreateAutoScrollId.forContentItem(itemId);
    dispatch(rememberReturnScrollId(targetPath, { path: currentPath, scrollId: returnScrollId }));
  };

const getItemCreationParams = (state: IStore): RequiredItemCreationParams => {
  const { collectionId, contentTypeId } = getNewContentItemForm(state);

  assert(
    collectionId,
    () => `${__filename}: Can’t create a content item without selected collection`,
  );
  assert(
    contentTypeId,
    () => `${__filename}: Can’t create a content item without selected content type.`,
  );

  return {
    collectionId,
    typeId: contentTypeId,
  };
};
