import { ContentState } from 'draft-js';
import { ContentItemId } from '../../../../_shared/models/ContentItemId.type.ts';
import { convertContentToServerModel } from '../../../../applications/richText/utils/serverModel/editorServerModelUtils.ts';
import {
  DraftJsOperationInput,
  ItemVariantIdOperationInput,
  StringOperationInput,
} from '../AiServerModels.input.type.ts';
import {
  ElementOperationTrackingData,
  MultipleInputsOperationParamsModelBase,
} from '../AiServerModels.params.ts';
import {
  CumulatedOperationResponseMessageTemplate,
  ErrorOperationResponseMessage,
  OperationResponseMessage,
  isErrorOperationMessage,
} from '../AiServerModels.response.ts';
import {
  CumulatedDraftJsResult,
  isCumulatedDraftJsOperationResponseMessage,
} from '../AiServerModels.result.ts';

export type MatchWritingStyleOfItemParams =
  MultipleInputsOperationParamsModelBase<ElementOperationTrackingData> & {
    readonly actionName: 'MatchWritingStyle';
    readonly inputs: {
      readonly content: DraftJsOperationInput;
      readonly elementId: StringOperationInput;
      readonly referenceItemVariantId: ItemVariantIdOperationInput;
    };
  };

export const createMatchWritingStyleOfItemParams = (
  contentState: ContentState,
  typeElementId: Uuid,
  referenceItemVariantId: ContentItemId,
  trackingData: ElementOperationTrackingData,
): MatchWritingStyleOfItemParams => ({
  actionName: 'MatchWritingStyle',
  type: 'multiple-inputs-request-v1',
  inputs: {
    content: {
      type: 'draft-js',
      value: convertContentToServerModel(contentState),
    },
    elementId: {
      type: 'string',
      value: typeElementId,
    },
    referenceItemVariantId: {
      type: 'item-variant-id',
      value: {
        itemId: referenceItemVariantId.itemId,
        variantId: referenceItemVariantId.variantId,
      },
    },
  },
  trackingData,
});

export type MatchWritingStyleOfItemResponseMessage =
  CumulatedOperationResponseMessageTemplate<CumulatedDraftJsResult>;

export const isMatchWritingStyleOfItemMessage = (
  message: OperationResponseMessage,
): message is MatchWritingStyleOfItemResponseMessage | ErrorOperationResponseMessage =>
  isCumulatedDraftJsOperationResponseMessage(message) || isErrorOperationMessage(message);
