import { usePrevious } from '@kontent-ai/hooks';
import { EditorProps, EditorState } from 'draft-js';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { getDataAttribute } from '../../../../_shared/utils/dataAttributes/DataAttributes.ts';
import { CommentsContext } from '../../../itemEditor/components/CommentsContext.tsx';
import { ElementAttributes } from '../../../itemEditor/constants/elementAttributes.ts';
import { CommentThreadItemType } from '../../../itemEditor/models/comments/CommentThreadItem.ts';
import { CommentThreadState } from '../../../itemEditor/types/CommentThreadState.ts';
import { useEditorApi } from '../../editorCore/hooks/useEditorApi.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginApi } from '../../editorCore/types/Editor.api.type.ts';
import { CanUpdateContent, ExecuteChange } from '../../editorCore/types/Editor.base.type.ts';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { WithoutProps } from '../../editorCore/types/Editor.contract.type.ts';
import { DecoratedEditor } from '../../editorCore/types/Editor.decorated.type.ts';
import {
  Apply,
  EditorPlugin,
  PluginState,
  Render,
} from '../../editorCore/types/Editor.plugins.type.ts';
import { EditorChangeReason } from '../../editorCore/types/EditorChangeReason.ts';
import { DecorableFunction, Decorator, decorable } from '../../editorCore/utils/decorable.ts';
import { mergeInlineStyles } from '../../editorCore/utils/editorComponentUtils.ts';
import { withDisplayName } from '../../editorCore/utils/withDisplayName.ts';
import { BlockType } from '../../utils/blocks/blockType.ts';
import { isAtEntityEdge } from '../../utils/blocks/editorBlockUtils.ts';
import {
  createSelection,
  doesSelectionContainText,
  getFullBlockTypesAtSelection,
  getMetadataAtSelection,
} from '../../utils/editorSelectionUtils.ts';
import { EditorFeatureLimitations } from '../apiLimitations/api/EditorFeatureLimitations.ts';
import { areAllTextBlocksAllowed } from '../apiLimitations/api/editorLimitationUtils.ts';
import { EditorChangeCallback, OnChangePlugin } from '../behavior/OnChangePlugin.tsx';
import {
  CanHandleNewCharsNatively,
  CustomInputHandlingPlugin,
} from '../customInputHandling/CustomInputHandlingPlugin.tsx';
import {
  ExecuteCommand,
  KeyboardShortcutsPlugin,
} from '../keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { TextInputCommand } from '../keyboardShortcuts/api/EditorCommand.ts';
import { InlineToolbarPlugin } from '../toolbars/InlineToolbarPlugin.tsx';
import { getToolbarButtonTooltipText } from '../toolbars/utils/toolbarUtils.ts';
import { EditorCommentApi } from './api/EditorCommentApi.type.ts';
import { editorCommentApi } from './api/editorCommentApi.ts';
import { getInlineStyleWithCommentIds } from './api/editorCommentStyleUtils.ts';
import {
  findCommentChanges,
  getSelectedThreadSegmentId,
  isAtCommentEdge,
} from './api/editorCommentUtils.ts';
import { IApprovedSuggestion, findSuggestionsToApply } from './api/editorSuggestionUtils.ts';
import { AddCommentButton } from './components/AddCommentButton.tsx';
import { AddSuggestionButton } from './components/AddSuggestionButton.tsx';

export type OnAddComment = (
  editorState: EditorState,
  type: CommentThreadItemType,
  api: PluginApi<CommentsPlugin>,
) => EditorState;

export type CanCreateComment = () => boolean;

type CreateComment = (type: CommentThreadItemType, customBlockKey?: string) => Promise<void>;

type CommentsPluginState = {
  readonly canCreateComment: DecorableFunction<CanCreateComment>;
  readonly createComment: CreateComment;
};

export type CommentsPluginProps = {
  readonly allowCreateCommentThread: boolean;
  readonly approvedSuggestions: ReadonlyArray<IApprovedSuggestion>;
  readonly commentThreadIdMapping: ReadonlyMap<Uuid, Uuid>;
  readonly commentThreads: ReadonlyMap<Uuid, CommentThreadState>;
  readonly focusedCommentThreadId: Uuid | null;
  readonly onBlurCommentThread: () => void;
  readonly onAddComment: OnAddComment;
  readonly onFocusCommentThread: (threadId: Uuid) => void;
  readonly onSuggestionApplied: (suggestion: IApprovedSuggestion) => void;
};

export type CommentsPlugin = EditorPlugin<
  CommentsPluginState,
  CommentsPluginProps,
  EditorCommentApi,
  [
    InlineToolbarPlugin,
    KeyboardShortcutsPlugin<TextInputCommand>,
    OnChangePlugin,
    CustomInputHandlingPlugin,
  ]
>;

interface ICommentButtonsProps {
  readonly fullBlockTypesAtSelection: ReadonlySet<BlockType>;
  readonly limitations: EditorFeatureLimitations;
  readonly onCreateComment: CreateComment;
}

const CommentButtons: React.FC<ICommentButtonsProps> = ({
  fullBlockTypesAtSelection,
  limitations,
  onCreateComment,
}) => {
  const addComment = useCallback(
    () => onCreateComment(CommentThreadItemType.Comment),
    [onCreateComment],
  );
  const addSuggestion = useCallback(
    () => onCreateComment(CommentThreadItemType.Suggestion),
    [onCreateComment],
  );

  const textChangeDisabled = !areAllTextBlocksAllowed(fullBlockTypesAtSelection, limitations);

  return (
    <>
      <AddCommentButton onClick={addComment} />
      <AddSuggestionButton
        disabled={textChangeDisabled}
        onClick={addSuggestion}
        tooltipText={getToolbarButtonTooltipText('Add suggestion', textChangeDisabled)}
      />
    </>
  );
};

CommentButtons.displayName = 'CommentButtons';

type CommentChangesHandlerPluginProps = Pick<
  CommentsPluginProps,
  'approvedSuggestions' | 'commentThreadIdMapping' | 'commentThreads' | 'onSuggestionApplied'
>;

type CommentChangesHandlerProps = CommentChangesHandlerPluginProps & {
  readonly api: Pick<PluginApi<CommentsPlugin>, 'applyApprovedSuggestions' | 'syncComments'>;
  readonly executeChange: ExecuteChange;
};

const CommentChangesHandler: React.FC<CommentChangesHandlerProps> = ({
  executeChange,
  api,
  approvedSuggestions,
  commentThreadIdMapping,
  commentThreads,
  onSuggestionApplied,
}) => {
  // Handle approved suggestions
  const previousApprovedSuggestions = usePrevious(approvedSuggestions);
  useEffect(() => {
    const suggestionsToApprove = findSuggestionsToApply(
      previousApprovedSuggestions,
      approvedSuggestions,
    );
    if (suggestionsToApprove) {
      executeChange(
        (editorState) =>
          api.applyApprovedSuggestions(
            editorState,
            suggestionsToApprove,
            onSuggestionApplied,
            commentThreadIdMapping,
          ),
        EditorChangeReason.Comment,
      );
    }
  }, [
    api,
    approvedSuggestions,
    commentThreadIdMapping,
    executeChange,
    onSuggestionApplied,
    previousApprovedSuggestions,
  ]);

  const previousCommentThreads = usePrevious(commentThreads);
  useEffect(() => {
    const commentChanges = findCommentChanges(previousCommentThreads, commentThreads);
    if (commentChanges) {
      executeChange(
        (editorState) => api.syncComments(editorState, commentChanges),
        EditorChangeReason.Comment,
      );
    }
  }, [commentThreads, previousCommentThreads, api, executeChange]);

  return null;
};

CommentChangesHandler.displayName = 'CommentChangesHandler';

const EditorWithComments: DecoratedEditor<
  WithoutProps<CommentsPlugin>,
  CommentChangesHandlerPluginProps
> = ({
  baseRender,
  state,
  onSuggestionApplied,
  commentThreadIdMapping,
  commentThreads,
  approvedSuggestions,
}) => {
  const baseCustomStyleFn = state.editorProps?.customStyleFn;
  const customStyleFn = useCallback<Required<EditorProps>['customStyleFn']>(
    (style, block) =>
      mergeInlineStyles(getInlineStyleWithCommentIds(style), baseCustomStyleFn?.(style, block)),
    [baseCustomStyleFn],
  );

  const stateWithComments: PluginState<CommentsPlugin> = {
    ...state,
    editorProps: {
      ...state.editorProps,
      customStyleFn,
    },
    rteInputProps: {
      ...state.rteInputProps,
      ...getDataAttribute(ElementAttributes.BlurCommentThreadOnClick, 'false'),
    },
  };

  return (
    <>
      {baseRender(stateWithComments)}
      <CommentChangesHandler
        api={state.getApi()}
        executeChange={state.executeChange}
        approvedSuggestions={approvedSuggestions}
        commentThreadIdMapping={commentThreadIdMapping}
        commentThreads={commentThreads}
        onSuggestionApplied={onSuggestionApplied}
      />
    </>
  );
};

EditorWithComments.displayName = 'EditorWithComments';

const canHandleNewCharsNatively: Decorator<CanHandleNewCharsNatively> =
  (baseCanHandleNewCharsNatively) => (params) => {
    if (!baseCanHandleNewCharsNatively(params)) {
      return false;
    }

    const { editorState } = params;
    const content = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const block = content.getBlockForKey(selection.getStartKey());
    if (block) {
      const offset = selection.getStartOffset();

      // Comment and entity edge behavior is customized, we need the customization
      if (isAtCommentEdge(block, offset) || isAtEntityEdge(block, offset)) {
        return false;
      }
    }

    return true;
  };

const canUpdateContent: Decorator<CanUpdateContent> = (baseCanUpdateContent) => (changeReason) =>
  // Even reviewer can add comments
  changeReason === EditorChangeReason.Comment || baseCanUpdateContent(changeReason);

export const useComments: PluginCreator<CommentsPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('CommentsPlugin', {
        ComposedEditor: (props) => {
          const {
            allowCreateCommentThread,
            approvedSuggestions,
            commentThreadIdMapping,
            commentThreads,
            focusedCommentThreadId,
            onAddComment,
            onBlurCommentThread,
            onFocusCommentThread,
            onSuggestionApplied,
            onSelectionChange: baseOnSelectionChange,
          } = props;

          const { allowNewComments } = useContext(CommentsContext);

          const focusSelectedCommentThread = useCallback(
            (newEditorState: EditorState): void => {
              const selection = newEditorState.getSelection();
              if (!selection.getHasFocus()) {
                return;
              }

              const focusedThreadIsUnsaved =
                !!focusedCommentThreadId &&
                commentThreads.get(focusedCommentThreadId) === CommentThreadState.Unsaved;
              if (focusedThreadIsUnsaved && selection.isCollapsed()) {
                // Do not blur new unsaved comment (selection is placed after the comment)
                return;
              }

              const content = newEditorState.getCurrentContent();
              const selectedThreadSegmentId = getSelectedThreadSegmentId(
                content,
                selection,
                commentThreads,
              );

              if (selectedThreadSegmentId) {
                const selectedThreadId = commentThreadIdMapping.get(selectedThreadSegmentId);

                if (focusedCommentThreadId !== selectedThreadId && selectedThreadId !== undefined) {
                  onFocusCommentThread(selectedThreadId);
                }
              } else if (focusedCommentThreadId) {
                onBlurCommentThread();
              }
            },
            [
              onFocusCommentThread,
              onBlurCommentThread,
              commentThreads,
              commentThreadIdMapping,
              focusedCommentThreadId,
            ],
          );

          const onSelectionChange: EditorChangeCallback = useCallback(
            async (editorState, changeReason) => {
              await baseOnSelectionChange?.(editorState, changeReason);
              focusSelectedCommentThread(editorState);
            },
            [baseOnSelectionChange, focusSelectedCommentThread],
          );

          const render: Decorator<Render<CommentsPlugin>> = useCallback(
            (baseRender) => (state) => (
              <EditorWithComments
                state={state}
                baseRender={baseRender}
                approvedSuggestions={approvedSuggestions}
                commentThreadIdMapping={commentThreadIdMapping}
                commentThreads={commentThreads}
                onSuggestionApplied={onSuggestionApplied}
              />
            ),
            [approvedSuggestions, commentThreadIdMapping, commentThreads, onSuggestionApplied],
          );

          const renderInlineToolbarButtons: Decorator<Render<CommentsPlugin>> = useCallback(
            (baseRender) => (state) => {
              const { editorState } = state;
              const content = editorState.getCurrentContent();
              const selection = editorState.getSelection();

              const fullBlockTypesAtSelection = getFullBlockTypesAtSelection(content, selection);
              const metadataAtSelection = getMetadataAtSelection(content, selection);
              const selectionContainsText = doesSelectionContainText(
                selection,
                metadataAtSelection,
              );
              const textCanBeCommented =
                allowNewComments &&
                selectionContainsText &&
                allowCreateCommentThread &&
                state.canCreateComment();

              if (!textCanBeCommented) {
                return baseRender(state);
              }

              return (
                <>
                  {baseRender(state)}
                  <CommentButtons
                    fullBlockTypesAtSelection={fullBlockTypesAtSelection}
                    limitations={state.getApi().getLimitations()}
                    onCreateComment={state.createComment}
                  />
                </>
              );
            },
            [allowCreateCommentThread, allowNewComments],
          );

          const apply: Apply<CommentsPlugin> = useCallback(
            (state) => {
              state.canHandleNewCharsNatively.decorate(canHandleNewCharsNatively);
              state.canUpdateContent.decorate(canUpdateContent);
              state.render.decorate(render);
              state.renderInlineToolbarButtons.decorate(renderInlineToolbarButtons);

              const canCreateComment = decorable<CanCreateComment>(() => true);

              const createComment: CreateComment = async (type, customBlockKey) => {
                // Propagate potential pending content changes, so that they do not mix with comment change
                await state.propagatePendingContentChanges();
                await state.executeChange((editorState) => {
                  const editorStateWithTargetSelection = customBlockKey
                    ? // When triggered for a custom block, we place the selection after it to give the add method a proper content and keep the focus of created comment
                      EditorState.acceptSelection(
                        editorState,
                        createSelection(
                          editorState.getCurrentContent().getBlockAfter(customBlockKey)?.getKey() ??
                            editorState.getSelection().getAnchorKey(),
                        ),
                      )
                    : editorState;

                  return onAddComment(editorStateWithTargetSelection, type, state.getApi());
                }, EditorChangeReason.Comment);
                // Do not wait for debounce, propagate changes immediately after any comment change,
                // so that the comments displayed in outer component could re-render asap.
                await state.propagatePendingContentChanges();
              };

              const executeCommand: Decorator<ExecuteCommand<TextInputCommand>> =
                (baseExecuteCommand) => (command, isShiftPressed) => {
                  const canCreateNewComment =
                    allowNewComments && allowCreateCommentThread && canCreateComment();

                  switch (command) {
                    case TextInputCommand.AddComment: {
                      if (canCreateNewComment) {
                        createComment(CommentThreadItemType.Comment);
                      }
                      return true;
                    }

                    case TextInputCommand.AddSuggestion: {
                      if (canCreateNewComment) {
                        createComment(CommentThreadItemType.Suggestion);
                      }
                      return true;
                    }

                    default:
                      return baseExecuteCommand(command, isShiftPressed);
                  }
                };

              state.executeCommand.decorate(executeCommand);

              return {
                canCreateComment,
                createComment,
              };
            },
            [
              allowCreateCommentThread,
              allowNewComments,
              onAddComment,
              render,
              renderInlineToolbarButtons,
            ],
          );

          const { getApiMethods } = useEditorApi<CommentsPlugin>(editorCommentApi);

          return useEditorWithPlugin(
            baseEditor,
            {
              ...props,
              onSelectionChange,
            },
            { apply, getApiMethods },
          );
        },
      }),
    [baseEditor],
  );
