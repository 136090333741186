import { Box } from '@kontent-ai/component-library/Box';
import { Callout } from '@kontent-ai/component-library/Callout';
import { InputState } from '@kontent-ai/component-library/Input';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, gridUnit, px } from '@kontent-ai/component-library/tokens';
import { forwardRef, useMemo } from 'react';
import styled from 'styled-components';
import { ModalDialog } from '../../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import { useWorkflowSelection } from '../../../../../_shared/hooks/WorkflowSelect/useWorkflowSelection.tsx';
import {
  DataUiAction,
  DataUiCollection,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { WorkflowIsRequiredWarning } from '../../../constants/warningMessageTemplates.ts';

export interface IChangeWorkflowDialogProps {
  readonly currentWorkflowName: string;
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly onSubmit: (selectedWorkflowId: Uuid) => void;
  readonly showWillBeMovedToFirstStepWarning: boolean;
  readonly workflowOptions: readonly WorkflowSelectorItem[];
  readonly workflowTooltipText?: string;
}

export type WorkflowSelectorItem = {
  readonly id: string;
  readonly name: string;
};

const NonBreakableEmphasis = styled.strong`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ChangeWorkflowDialog = forwardRef<HTMLDivElement, IChangeWorkflowDialogProps>(
  (
    {
      currentWorkflowName,
      isOpen,
      onClose,
      onSubmit,
      showWillBeMovedToFirstStepWarning,
      workflowOptions,
      workflowTooltipText,
    },
    ref,
  ) => {
    const { disableSelection, selectedWorkflowId, setSelectedWorkflowId } = useWorkflowSelection(
      workflowOptions,
      isOpen,
    );

    const singleSelectItems = useMemo(
      () =>
        workflowOptions.map(({ id, name }) => ({
          id,
          label: name,
        })),
      [workflowOptions],
    );

    return (
      <ModalDialog
        headline="Change workflow"
        isDismissable
        isOpen={isOpen}
        onClose={onClose}
        primaryAction={{
          disabled: !selectedWorkflowId,
          onClick: () => {
            if (selectedWorkflowId) {
              onSubmit(selectedWorkflowId);
              onClose();
            }
          },
          text: 'Change workflow',
          tooltipText: selectedWorkflowId ? undefined : WorkflowIsRequiredWarning,
          ...getDataUiActionAttribute(DataUiAction.Confirm),
        }}
        ref={ref}
        {...getDataUiElementAttribute(DataUiElement.Dialog)}
      >
        <Box css={`max-width: ${px(90 * gridUnit)}`}>
          <Stack spacing={Spacing.XL}>
            <Row alignX="start" alignY="center" noWrap spacingX={Spacing.S}>
              <Column
                css={`
                display: flex;
                white-space: nowrap;
              `}
                width="fit-content"
              >
                Change&nbsp;<NonBreakableEmphasis>{currentWorkflowName}</NonBreakableEmphasis>
                &nbsp;to
              </Column>
              <Column width="content">
                <SingleSelect
                  ariaLabel="Workflow"
                  items={singleSelectItems}
                  onSelectionChange={setSelectedWorkflowId}
                  selectedItemId={selectedWorkflowId}
                  inputState={disableSelection ? InputState.Disabled : undefined}
                  placeholder="Select a workflow"
                  verticalMenuDataAttributes={getDataUiCollectionAttribute(
                    DataUiCollection.Workflows,
                  )}
                  tooltipText={workflowTooltipText}
                  {...getDataUiCollectionAttribute(DataUiCollection.Workflows)}
                />
              </Column>
            </Row>
            {showWillBeMovedToFirstStepWarning ? (
              <Callout calloutType="friendlyWarning">
                <p>
                  The content item will move to the <strong>first step</strong> of the new workflow.
                </p>
              </Callout>
            ) : (
              <Callout calloutType="quickTip">
                <p>
                  Published, scheduled, and archived content items will remain in their current
                  workflow step.
                </p>
              </Callout>
            )}
          </Stack>
        </Box>
      </ModalDialog>
    );
  },
);

ChangeWorkflowDialog.displayName = 'ChangeWorkflowDialog';
