import PropTypes from 'prop-types';
import React from 'react';
import { isAssetLimited } from '../../../../../../../../_shared/utils/assets/assetValidationUtils.ts';
import { AssetFileTypeOption } from '../../../../../../../contentInventory/content/models/assetFileTypeOptions.ts';
import { AssetLimitations } from '../../../../../../../richText/plugins/apiLimitations/api/EditorFeatureLimitations.ts';
import { IAssetWarningResult } from '../../../../../../utils/itemElementWarningCheckers/types/IAssetWarningResult.type.ts';
import { FormattedFileSize } from '../../asset/FormattedFileSize.tsx';
import { ItemElementLimitType, ItemLimitStatusMessage } from './ItemLimitStatusMessage.tsx';

interface IAssetLimitStatusMessageProps {
  readonly limitations: AssetLimitations;
  readonly validationResult?: IAssetWarningResult | null;
  readonly prefix: string;
}

const propTypes: PropTypesShape<IAssetLimitStatusMessageProps> = {
  limitations: PropTypes.object.isRequired,
  validationResult: PropTypes.object,
  prefix: PropTypes.string.isRequired,
};

export const AssetLimitStatusMessage: React.FC<IAssetLimitStatusMessageProps> = (
  props: IAssetLimitStatusMessageProps,
): JSX.Element | null => {
  const { limitations, validationResult, prefix } = props;

  const isLimited = isAssetLimited(limitations);
  if (!isLimited) {
    return null;
  }

  const minWidth = limitations.minWidth;
  const maxWidth = limitations.maxWidth;
  const minHeight = limitations.minHeight;
  const maxHeight = limitations.maxHeight;
  const allowResponsiveImagesOnly = limitations.fileType === AssetFileTypeOption.ResponsiveImages;
  const fileSize = limitations.fileSize;

  const isWidthLimited = !!maxWidth || !!minWidth;
  const isHeightLimited = !!maxHeight || !!minHeight;

  return (
    <span className="content-item-element__status-segment">
      <span>{prefix} </span>
      {isWidthLimited && (
        <ItemLimitStatusMessage
          min={minWidth}
          max={maxWidth}
          type={ItemElementLimitType.ImageWidth}
          isLimitValueMet={!!validationResult?.isWidthLimitMet}
        />
      )}
      {isHeightLimited && (
        <ItemLimitStatusMessage
          min={minHeight}
          max={maxHeight}
          type={ItemElementLimitType.ImageHeight}
          isLimitValueMet={!!validationResult?.isHeightLimitMet}
        />
      )}
      {allowResponsiveImagesOnly && (
        <ItemLimitStatusMessage
          type={ItemElementLimitType.ResponsiveImagesOnly}
          isLimitValueMet={!!validationResult?.isResponsiveImageTypeLimitMet}
        />
      )}
      <FormattedFileSize
        wrapperClassName="content-item-element__status-item"
        messageBefore="max. file size "
        className="content-item-element__status-fragment"
        invalidClassName={
          validationResult?.isFileSizeLimitMet
            ? ''
            : 'content-item-element__status-fragment--is-invalid'
        }
        fileSizeInBytes={fileSize}
      />
    </span>
  );
};

AssetLimitStatusMessage.propTypes = propTypes;
AssetLimitStatusMessage.displayName = 'AssetLimitStatusMessage';
