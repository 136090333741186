import { mergeProps } from '@react-aria/utils';
import React, { RefObject } from 'react';
import { IconSize } from '../../../tokens/quarks/iconSize.ts';
import { Icons } from '../../Icons/components/icons.ts';
import { Menu } from '../../Menu/Menu.tsx';
import { AppMenuItem } from '../types/AppMenuItem.type.ts';
import { NavigationBarButton } from './NavigationBarItems.tsx';

interface IMoreAppsProps {
  readonly items: ReadonlyArray<AppMenuItem>;
}

const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
  e.stopPropagation();
  e.preventDefault();
};

export const MoreApps: React.FC<IMoreAppsProps> = ({ items }) => {
  return (
    <Menu>
      <Menu.Trigger>
        {(ref: RefObject<HTMLButtonElement>, triggerProps, isOpen) => (
          <NavigationBarButton
            {...mergeProps(triggerProps, {
              onClick: handleOnClick,
            })}
            icon="Ellipsis"
            isActive={isOpen}
            label="More"
            ref={ref}
            tooltipText={isOpen ? undefined : 'More'}
          />
        )}
      </Menu.Trigger>
      <Menu.List placement="right top">
        {items.map((item) => {
          const ItemIcon = Icons[item.icon];
          return (
            <Menu.RouterLink
              key={item.id}
              id={item.id}
              label={item.label}
              leadingElement={<ItemIcon size={IconSize.M} />}
              menuItemState={item.isActive ? 'selected' : 'default'}
              to={item.to}
            />
          );
        })}
      </Menu.List>
    </Menu>
  );
};

MoreApps.displayName = 'MoreApps';
