import React from 'react';
import { useParams } from 'react-router';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { editItemVariantCodenameByItemId } from '../actions/thunks/editItemVariantCodenameByItemId.ts';
import { SmartLink } from '../components/SmartLink.tsx';
import { EditItemVariantCodenameRouteParams } from '../constants/routePaths.ts';

export const EditItemVariantCodenameSmartLink: React.FC = () => {
  const dispatch = useDispatch();

  const { projectId, itemGuid, variantCodename } = useParams<EditItemVariantCodenameRouteParams>();

  const onMount = () =>
    dispatch(
      editItemVariantCodenameByItemId({
        projectId,
        variantCodename,
        itemGuid,
      }),
    );

  return <SmartLink onMount={onMount} />;
};

EditItemVariantCodenameSmartLink.displayName = 'EditItemVariantCodenameSmartLink';
