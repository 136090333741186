/*
 Returns a function, that, when invoked, will only be triggered at most once during a given window of time.
 Normally, the throttled function will run as much as it can, without ever going more than once per wait duration;
 but if you’d like to disable the execution on the leading edge, pass {leading: false}.
 To disable execution on the trailing edge, ditto.
 */
import { ICancellablePromise, delay, swallowCancelledPromiseError } from '@kontent-ai/utils';

export enum WaitBehavior {
  // The time to the next execution starts when the execution starts (the execution time is included in the interval)
  TimeBetweenCalls = 'TimeBetweenCalls',
  // The time to the next execution starts when the execution finishes (the execution time itself is skipped)
  IdleTimeBetweenCalls = 'IdleTimeBetweenCalls',
}

type ThrottleOptions = {
  readonly behavior?: WaitBehavior;
  // When false, the execution is always delayed with a timeout, even when next execution time has already elapsed
  readonly immediate?: boolean;
  // When false, the first call doesn't immediately execute, but waits the specified interval
  readonly leading?: boolean;
  readonly trailing?: boolean;
};

export type ThrottledFunction<T extends AnyFunction> = T & {
  readonly cancel: () => void;
};

export function throttle<T extends AnyFunction>(
  func: T,
  wait: number,
  options: ThrottleOptions = {},
): ThrottledFunction<T> {
  let context: any = null;
  let args: any = null;
  let timeout: ICancellablePromise | null = null;
  let lastExecuted = 0;

  const cancelScheduled = (): void => {
    if (timeout) {
      timeout.cancel();
      timeout = null;
    }
  };

  const execute = (now: number | undefined) => {
    try {
      if (options.behavior === WaitBehavior.IdleTimeBetweenCalls) {
        func.apply(context, args);
        lastExecuted = Date.now();
      } else {
        lastExecuted = now ?? Date.now();
        func.apply(context, args);
      }
    } finally {
      context = args = null;
    }
  };

  const delayedExecute = () => {
    timeout = null;
    execute(undefined);
  };

  const scheduledDelayed = function (timeToNextExecution: number) {
    timeout = delay(timeToNextExecution).then(delayedExecute).catch(swallowCancelledPromiseError);
  };

  const result = function (this: any) {
    const now = Date.now();
    if (!lastExecuted && options.leading === false) {
      lastExecuted = now;
    }
    const timeSinceLastExecuted = now - lastExecuted;
    const timeToNextExecution = Math.max(0, wait - timeSinceLastExecuted);
    context = this;
    // biome-ignore lint/style/noArguments:
    args = arguments;
    if (options.immediate !== false && timeToNextExecution <= 0) {
      cancelScheduled();
      execute(now);
    } else if (!timeout && options.trailing !== false) {
      scheduledDelayed(timeToNextExecution);
    }
  };

  return Object.assign(result, {
    cancel: cancelScheduled,
  } as any);
}
