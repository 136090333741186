import React from 'react';
import { stringifyContentItemId } from '../../../../../_shared/models/utils/contentItemIdUtils.ts';
import { calculateTagBackgroundColor } from '../../../../../_shared/utils/workflow/calculateTagBackgroundColor.ts';
import { daysSinceDate } from '../../../utils/daysSinceDate.ts';
import { HorizontalProgressBar } from '../../shared/components/HorizontalProgressBar.tsx';
import {
  StyledDataTableCell,
  StyledDataTableRow,
} from '../../shared/components/StyledDataTable.tsx';
import { UnchangedUnpublishedItem } from '../types/UnchangedUnpublishedItem.type.ts';

type UnchangedUnpublishedContentWidgetRowProps = Readonly<{
  unchangedUnpublishedItem: UnchangedUnpublishedItem;
  linkPath: string | null;
  longestItemStaleness: number;
}>;

export const UnchangedUnpublishedWidgetRow: React.FC<UnchangedUnpublishedContentWidgetRowProps> = ({
  unchangedUnpublishedItem,
  linkPath,
  longestItemStaleness,
}) => {
  const stalenessDaysCount = daysSinceDate(unchangedUnpublishedItem.lastEnteredWorkflowStep);
  const workflowStep =
    unchangedUnpublishedItem.itemWithVariant.variant?.actualWorkflowStatus ??
    unchangedUnpublishedItem.itemWithVariant.variant?.assignment.workflowStatus;

  return (
    <StyledDataTableRow
      dataUiObjectName={stringifyContentItemId(unchangedUnpublishedItem.itemWithVariant.variant.id)}
      id={stringifyContentItemId(unchangedUnpublishedItem.itemWithVariant.variant.id)}
    >
      <StyledDataTableCell linkPath={linkPath}>
        {unchangedUnpublishedItem.itemWithVariant.item.name}
      </StyledDataTableCell>
      <StyledDataTableCell linkPath={linkPath}>
        {workflowStep && (
          <HorizontalProgressBar
            itemTypeName="day"
            $color={calculateTagBackgroundColor(workflowStep.color)}
            total={longestItemStaleness}
            value={stalenessDaysCount}
          >
            <HorizontalProgressBar.Label
              backgroundColor={calculateTagBackgroundColor(workflowStep.color)}
            >
              {workflowStep.name}
            </HorizontalProgressBar.Label>
          </HorizontalProgressBar>
        )}
      </StyledDataTableCell>
    </StyledDataTableRow>
  );
};

UnchangedUnpublishedWidgetRow.displayName = 'UnchangedUnpublishedWidgetRow';
