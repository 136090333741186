import React, { useEffect } from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { loadTimeline } from '../../Timeline/actions/thunkTimelineActions.ts';
import { ContentItemDetails } from '../components/ContentItemDetails.tsx';

const ContentItemDetailsContainer: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadTimeline());
  }, []);

  return <ContentItemDetails />;
};

ContentItemDetailsContainer.displayName = 'ContentItemDetailsContainer';

export { ContentItemDetailsContainer as ContentItemDetails };
