import React, { ReactNode, useState } from 'react';
import { useHistory } from 'react-router';
import { EnsureValidState } from '../../../_shared/components/EnsureValidState.tsx';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { ensureWebSpotlight } from '../actions/thunkWebSpotlightActions.ts';

type Props = {
  readonly children: ReactNode;
};

export const EnsureWebSpotlight: React.FC<Props> = ({ children }) => {
  const [isStateEnsured, setIsStateEnsured] = useState(false);
  const projectId = useSelector((s) => s.sharedApp.currentProjectId);

  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <EnsureValidState
      isStateEnsured={isStateEnsured}
      onRouteEntered={() =>
        dispatch(ensureWebSpotlight(projectId, history)).then(() => setIsStateEnsured(true))
      }
    >
      {children}
    </EnsureValidState>
  );
};

EnsureWebSpotlight.displayName = 'EnsureWebSpotlight';
