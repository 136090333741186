import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import {
  ControlShortcutTemplate,
  ShortcutSymbols,
} from '../../../../../../../_shared/constants/shortcutSymbols.ts';
import { Button, ButtonSize } from '../../../../../../../_shared/uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import {
  DataUiCommentsAction,
  getDataUiCommentActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface INewCommentThreadActionsBarProps {
  readonly isSubmitting: boolean;
  readonly isSubmitButtonDisabled: boolean;

  readonly onCancelEditing: () => void;
}

export class NewCommentThreadActionsBar extends React.PureComponent<INewCommentThreadActionsBarProps> {
  static displayName = 'NewCommentThreadActionsBar';
  static propTypes: PropTypesShape<INewCommentThreadActionsBarProps> = {
    isSubmitButtonDisabled: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,

    onCancelEditing: PropTypes.func.isRequired,
  };

  render() {
    const { onCancelEditing, isSubmitting, isSubmitButtonDisabled } = this.props;

    return (
      <div className="comment-editor__actions">
        <Tooltip
          tooltipText={isSubmitting ? undefined : 'Cancel'}
          placement="bottom-end"
          shortcuts={ShortcutSymbols.Escape}
        >
          <Button
            style={ButtonStyle.Secondary}
            size={ButtonSize.S}
            onClick={isSubmitting ? undefined : onCancelEditing}
            disabled={isSubmitting}
            {...getDataUiCommentActionAttribute(DataUiCommentsAction.Cancel)}
          >
            Cancel
          </Button>
        </Tooltip>
        <Tooltip
          tooltipText={!isSubmitting && !isSubmitButtonDisabled ? 'Comment' : undefined}
          placement="bottom-end"
          shortcuts={ControlShortcutTemplate(ShortcutSymbols.Enter)}
        >
          <Button
            style={ButtonStyle.Primary}
            size={ButtonSize.S}
            type="submit"
            disabled={isSubmitButtonDisabled}
            {...getDataUiCommentActionAttribute(DataUiCommentsAction.SaveComment)}
          >
            {isSubmitting ? 'Commenting…' : 'Comment'}
          </Button>
        </Tooltip>
      </div>
    );
  }
}
