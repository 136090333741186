import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import React from 'react';
import {
  HotkeysHandler,
  HotkeysMode,
} from '../../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import { HtmlSettingsPageTitle } from '../../../../_shared/components/HtmlSettingsPageTitle.tsx';
import { HandleUnsavedFormOnNavigation } from '../../../../_shared/containers/HandleUnsavedFormOnNavigation.tsx';
import { EnvironmentSettingsAppNames } from '../../root/constants/EnvironmentSettingsAppNames.ts';
import { WorkflowName } from '../containers/WorkflowName.tsx';
import { WorkflowLimitations } from '../containers/limitations/WorkflowLimitations.tsx';
import { WorkflowStepList } from './WorkflowStepList.tsx';

interface IWorkflowEditorProps {
  readonly hasUnsavedChanges: boolean;
  readonly isAdvancedEditorEnabled: boolean;
  readonly isBeingSaved: boolean;
  readonly isCentered: boolean;
  readonly onSaveWorkflow: (onSuccess?: () => void, onFail?: () => void) => void;
  readonly pageTitle?: string;
}

export const WorkflowEditor: React.FC<IWorkflowEditorProps> = ({
  hasUnsavedChanges,
  isAdvancedEditorEnabled,
  isBeingSaved,
  isCentered,
  onSaveWorkflow,
  pageTitle,
}) => {
  const onControlSHandler = (event: KeyboardEvent): void => {
    event.preventDefault();

    if (!isBeingSaved) {
      onSaveWorkflow();
    }
  };

  return (
    <>
      <HtmlSettingsPageTitle
        settingsAppName={EnvironmentSettingsAppNames.Workflow}
        customName={pageTitle}
      />
      <HandleUnsavedFormOnNavigation
        hasUnsavedChanges={hasUnsavedChanges}
        isBeingSaved={isBeingSaved}
        onSaveChanges={onSaveWorkflow}
      />
      <HotkeysHandler
        mode={HotkeysMode.Dual}
        handlers={{
          onControlS: onControlSHandler,
        }}
        className={classNames(
          'canvas__inner-section',
          'canvas__inner-section--restricted-width',
          'workflow-settings',
          {
            'canvas__inner-section--centered': isCentered,
          },
        )}
      >
        <Stack spacing={Spacing.XL}>
          {isAdvancedEditorEnabled && (
            <>
              <WorkflowName />
              <WorkflowLimitations />
            </>
          )}
          <WorkflowStepList isAdvancedEditorEnabled={isAdvancedEditorEnabled} />
        </Stack>
      </HotkeysHandler>
    </>
  );
};

WorkflowEditor.displayName = 'WorkflowEditor';
