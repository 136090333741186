import { Collection } from '@kontent-ai/utils';
import React, { useMemo } from 'react';
import {
  allCollectionsOption,
  allLanguagesOption,
  userStatusOptions,
} from '../../../../../_shared/constants/userListingFilter.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import {
  ICollectionFilterOption,
  ILanguageFilterOption,
  IRoleFilterOption,
  IStatusFilterOption,
} from '../../../../../_shared/models/UserListingFilterOption.type.ts';
import { compose } from '../../../../../_shared/utils/func/compose.ts';
import { getCollectionsFilterOptions } from '../../../../../_shared/utils/userListingFilterUtils.ts';
import { ILanguage } from '../../../../../data/models/languages/Language.ts';
import { IRole } from '../../../../../data/models/roles/IRole.ts';
import {
  IProjectContributor,
  getRolesInAllCollectionsAndLanguages,
} from '../../../../../data/models/users/ProjectContributor.ts';
import { ILanguagesData } from '../../../../../data/reducers/languages/ILanguagesData.type.ts';
import { addInactiveLanguageSuffix } from '../../../localization/utils/languageUtils.ts';
import { getSortedRolesMemoized } from '../../../roles/selectors/getSortedRolesList.ts';
import { areLanguageRolesEnabledForCurrentProject } from '../../../selectors/allowedFeaturesUtils.ts';
import {
  clearFilter,
  trackUserListingFilterChange,
  updateSavedConfig,
} from '../../actions/thunkUsersActions.ts';
import {
  collectionsFilterUpdated,
  languagesFilterUpdated,
  nameListingFilterChanged,
  rolesFilterUpdated,
  statusFilterUpdated,
} from '../../actions/usersActions.ts';
import { UserListingFilter as UserListingFilterComponent } from '../../components/userListing/UserListingFilter.tsx';
import { isAnyFilterActive } from '../../selectors/userListingFilterSelectors.ts';
import { getUsedLanguageIds } from '../../utils/getUsedLanguageIds.ts';

export const UserListingFilter: React.FC = () => {
  const dispatch = useDispatch();

  const byId = useSelector((s) => s.data.collections.byId);
  const languages = useSelector((s) => s.data.languages);
  const rolesById = useSelector((s) => s.data.roles.rolesById);
  const projectContributors = useSelector((s) => s.usersApp.projectContributors);
  const areLanguageRolesEnabled = useSelector((s) => areLanguageRolesEnabledForCurrentProject(s));
  const showClearFilterButton = useSelector((state) => isAnyFilterActive(state.usersApp.filter));

  const byName = useSelector((s) => s.usersApp.filter.byName);
  const selectedStatusId = useSelector((s) => s.usersApp.filter.byStatus);

  const selectedLanguageIds = useSelector((s) => s.usersApp.filter.byLanguage);
  const languageOptions = useMemo(
    () => getLanguagesOptions(languages, projectContributors),
    [languages, projectContributors],
  );

  const selectedRoleIds = useSelector((s) => s.usersApp.filter.byRoles);
  const roles = getSortedRolesMemoized(rolesById);
  const roleOptions = useMemo(
    () => roles.map(({ id, name }: IRole) => ({ id, label: name })),
    [roles],
  );

  const selectedCollectionIds = useSelector((s) => s.usersApp.filter.byCollection);
  const memoizedCollectionValues = useMemo(() => Collection.getValues(byId), [byId]);
  const collectionOptions = getCollectionsFilterOptions(memoizedCollectionValues);

  const onUpdateCollectionFilter = (updatedCollectionIds: ReadonlySet<IRoleFilterOption['id']>) => {
    const allCollectionWasAdded =
      updatedCollectionIds.has(allCollectionsOption.id) &&
      !selectedCollectionIds.has(allCollectionsOption.id);

    const resolvedCollectionIds = new Set(
      allCollectionWasAdded
        ? [allCollectionsOption.id]
        : [...updatedCollectionIds].filter(
            (collectionId: ICollectionFilterOption['id']) =>
              collectionId !== allCollectionsOption.id,
          ),
    );

    dispatch(collectionsFilterUpdated(resolvedCollectionIds));
    dispatch(trackUserListingFilterChange());
    dispatch(updateSavedConfig());
  };

  const onUpdateLanguageFilter = (updatedLanguageIds: ReadonlySet<ILanguageFilterOption['id']>) => {
    const allLanguagesOptionWasAdded =
      updatedLanguageIds.has(allLanguagesOption.id) &&
      !selectedLanguageIds.has(allLanguagesOption.id);

    const resolvedCollectionIds = new Set(
      allLanguagesOptionWasAdded
        ? [allLanguagesOption.id]
        : [...updatedLanguageIds].filter(
            (languageId: ILanguageFilterOption['id']) => languageId !== allLanguagesOption.id,
          ),
    );

    dispatch(languagesFilterUpdated(resolvedCollectionIds));
    dispatch(trackUserListingFilterChange());
    dispatch(updateSavedConfig());
  };

  const onUpdateStatusFilter = (statusId: IStatusFilterOption['id']) => {
    dispatch(statusFilterUpdated(statusId));
    dispatch(trackUserListingFilterChange());
    dispatch(updateSavedConfig());
  };

  const onUpdateRoleFilter = (updatedRolesIds: ReadonlySet<IRoleFilterOption['id']>) => {
    dispatch(rolesFilterUpdated(updatedRolesIds));
    dispatch(trackUserListingFilterChange());
    dispatch(updateSavedConfig());
  };

  const onUpdateNameFilter = (name: string) => {
    dispatch(nameListingFilterChanged(name));
    dispatch(updateSavedConfig());
  };

  return (
    <UserListingFilterComponent
      areCollectionsEnabled={memoizedCollectionValues.length > 1}
      areLanguageRolesEnabled={areLanguageRolesEnabled}
      clearSearchPhrase={compose(dispatch, clearFilter)}
      collections={collectionOptions}
      languages={languageOptions}
      nameFilter={byName}
      onUpdateCollectionFilter={onUpdateCollectionFilter}
      onUpdateLanguageFilter={onUpdateLanguageFilter}
      onUpdateNameFilter={onUpdateNameFilter}
      onUpdateRoleFilter={onUpdateRoleFilter}
      onUpdateStatusFilter={onUpdateStatusFilter}
      roles={roleOptions}
      selectedCollectionIds={selectedCollectionIds}
      selectedLanguageIds={selectedLanguageIds}
      selectedRoleIds={selectedRoleIds}
      selectedStatusId={selectedStatusId}
      showClearFilterButton={showClearFilterButton}
      statusOptions={userStatusOptions}
    />
  );
};

UserListingFilter.displayName = 'UserListingFilter';

const getLanguagesOptions = (
  languages: ILanguagesData,
  projectContributors: ReadonlyMap<Uuid, IProjectContributor>,
): ReadonlyArray<ILanguageFilterOption> => {
  const usedLanguageIds = new Set(
    Collection.getValues(projectContributors).flatMap(
      compose(Collection.getValues, getUsedLanguageIds, getRolesInAllCollectionsAndLanguages),
    ),
  );

  return (
    languages.byId
      //  Hide inactive languages that are not explicitly assigned to any role
      .filter((language: ILanguage) => !language.inactive || usedLanguageIds.has(language.id))
      .map(
        (language: ILanguage): ILanguageFilterOption => ({
          id: language.id,
          inactive: language.inactive,
          label: addInactiveLanguageSuffix(language).name,
        }),
      )
      .toList()
      .unshift({
        id: languages.defaultLanguage.id,
        label: languages.defaultLanguage.name,
      })
      .unshift(allLanguagesOption)
      .toArray()
  );
};
