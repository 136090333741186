import { IconName } from '../../../../../../_shared/constants/iconEnumGenerated.ts';
import { ControlShortcutTemplate } from '../../../../../../_shared/constants/shortcutSymbols.ts';
import {
  DataUiRteAction,
  getDataUiActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  IForwardedRefProps,
  forwardRef,
} from '../../../../../../_shared/utils/forwardedRefProps.tsx';
import {
  IToggleButtonProps,
  RTEToolbarIconButton,
} from '../../../toolbars/components/RTEToolbarButton.tsx';

const ItalicButtonComponent = (
  props: IToggleButtonProps & IForwardedRefProps<HTMLButtonElement>,
) => (
  <RTEToolbarIconButton
    {...props}
    iconName={IconName.I}
    ref={props.forwardedRef}
    shortcut={ControlShortcutTemplate('I')}
    tooltipPlacement={props.tooltipPlacement ?? 'bottom-start'}
    tooltipText={props.tooltipText ?? 'Italic'}
    {...getDataUiActionAttribute(DataUiRteAction.Italic)}
  />
);

export const ItalicButton = forwardRef(ItalicButtonComponent);
