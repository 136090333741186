import PropTypes from 'prop-types';
import React from 'react';
import { ValidatedInput } from '../../../_shared/components/input/ValidatedInput.tsx';
import { HookFormProps, hookFormProps } from '../../../_shared/types/hookFormProps.ts';
import { Button } from '../../../_shared/uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../../_shared/uiComponents/Button/buttonStyle.ts';
import { LabelFor } from '../../../_shared/uiComponents/LabelFor/LabelFor.tsx';
import { IUserProfileFormShape } from '../models/IUserProfileFormShape.type.ts';

type Props = {
  readonly email: string;
  readonly formProps: HookFormProps<IUserProfileFormShape>;
  readonly isPristine: boolean;
  readonly isSubmitting: boolean;
  readonly onSubmit: () => void;
};

const propTypes: PropTypesShape<Props> = {
  email: PropTypes.string.isRequired,
  formProps: hookFormProps.isRequired,
  isPristine: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export const UserProfileForm: React.FC<Props> = ({
  email,
  formProps,
  isPristine,
  isSubmitting,
  onSubmit,
}) => (
  <section className="card__pane">
    <form onSubmit={onSubmit}>
      <div className="user-profile__section-heading">
        <h2 className="user-profile__section-title user-profile__section-title--with-status-message">
          Profile details
        </h2>
      </div>
      <div className="user-profile__form-group">
        <LabelFor
          className="user-profile__label"
          target={(id: Uuid) => (
            <input
              id={id}
              autoComplete="off"
              type="text"
              value={email}
              className="form__text-field"
              disabled
              readOnly
            />
          )}
        >
          Email
        </LabelFor>
      </div>
      <div className="user-profile__form-group">
        <ValidatedInput<IUserProfileFormShape>
          label="First name"
          name="firstName"
          maxLength={200}
          formProps={formProps}
        />
      </div>
      <div className="user-profile__form-group">
        <ValidatedInput<IUserProfileFormShape>
          label="Last name"
          name="lastName"
          maxLength={200}
          formProps={formProps}
        />
      </div>
      <div className="user-profile__form-submit-group">
        <Button
          style={ButtonStyle.Primary}
          type="submit"
          disabled={isSubmitting || isPristine}
          hasLoader={isSubmitting}
        >
          {isSubmitting ? 'Updating profile' : 'Update profile'}
        </Button>
      </div>
    </form>
  </section>
);

UserProfileForm.displayName = 'UserProfileForm';
UserProfileForm.propTypes = propTypes;
