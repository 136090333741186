import { useCallback, useMemo } from 'react';
import { IRichTextWarningResult } from '../../../../itemEditor/utils/itemElementWarningCheckers/types/IRichTextItemElementWarningResult.type.ts';
import { emptyItemElementWarningResult } from '../../../../itemEditor/utils/itemElementWarningCheckers/types/Warnings.ts';
import { useEditorWithPlugin } from '../../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginCreator } from '../../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../../editorCore/types/Editor.contract.type.ts';
import { Apply, EditorPlugin, Render } from '../../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../../editorCore/utils/decorable.ts';
import { withDisplayName } from '../../../editorCore/utils/withDisplayName.ts';
import { EditorLimitations } from '../../../plugins/apiLimitations/api/EditorFeatureLimitations.ts';
import { emptyViolatedFeaturesResult } from '../../../plugins/apiLimitations/api/editorLimitationUtils.ts';
import { StylesPlugin } from '../../../plugins/visuals/StylesPlugin.tsx';
import { RichTextElementStatus } from './components/RichTextElementStatus.tsx';

const defaultValidationResult: IRichTextWarningResult = {
  ...emptyItemElementWarningResult,
  containsOnlyAllowedContentTypes: true,
  containsOnlyAllowedItemLinkTypes: true,
  isFileSizeLimitMet: true,
  isHeightLimitMet: true,
  isMaxCharsLimitMet: true,
  isMaxWordsLimitMet: true,
  isResponsiveImageTypeLimitMet: true,
  isWidthLimitMet: true,
  violatedFeatures: emptyViolatedFeaturesResult,
};

type RichTextInputStatusPluginProps = {
  readonly hidesDisallowedFeatures?: boolean;
  readonly limitations: EditorLimitations;
  readonly validationResult?: IRichTextWarningResult;
};

export type RichTextInputStatusPlugin = EditorPlugin<
  None,
  RichTextInputStatusPluginProps,
  None,
  [StylesPlugin]
>;

export const useRichTextInputStatus: PluginCreator<RichTextInputStatusPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('RichTextInputStatusPlugin', {
        ComposedEditor: (props) => {
          const { validationResult, hidesDisallowedFeatures, limitations } = props;

          const renderStatus: Decorator<Render<RichTextInputStatusPlugin>> = useCallback(
            (baseRenderStatus) => (state) => {
              const { editorState } = state;

              const content = editorState.getCurrentContent();
              const selection = editorState.getSelection();
              const hasFocus = selection.getHasFocus();

              return (
                <>
                  {baseRenderStatus(state)}
                  <RichTextElementStatus
                    content={content}
                    onClick={state.focus}
                    editorHasFocus={hasFocus}
                    hideLimitationStatus={hidesDisallowedFeatures}
                    limitations={limitations}
                    validationResult={validationResult ?? defaultValidationResult}
                  />
                </>
              );
            },
            [hidesDisallowedFeatures, limitations, validationResult],
          );

          const apply: Apply<RichTextInputStatusPlugin> = useCallback(
            (state) => {
              state.renderStatus.decorate(renderStatus);
              return {};
            },
            [renderStatus],
          );

          return useEditorWithPlugin(baseEditor, props, { apply });
        },
      }),
    [baseEditor],
  );
