import { MatchAiTask } from '../../../../_shared/features/AI/hooks/aiTasks/matchAiTask.type.ts';
import { AiActionName } from '../../../../repositories/serverModels/ai/AiActionName.type.ts';

// This solution for getting task in progress should be replace with useAiTasksProgress hook
export const matchAnyTranslateDescriptionAiTaskInProgress =
  (assetId: Uuid): MatchAiTask =>
  (aiTasks) =>
    aiTasks.find(({ action, context }) => {
      return (
        !action.isFinished &&
        action.name === AiActionName.TranslateContent &&
        'assetId' in context &&
        context.assetId === assetId
      );
    }) ?? null;
