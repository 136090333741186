import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import React from 'react';
import { Button, ButtonSize } from '../../../../../_shared/uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { UnableToRestoreProjectLimitReachedMessage } from '../../../constants/UIConstants.ts';
import { IProjectViewModel } from '../../../selectors/createProjectList.ts';
import { ProjectUiTransitionState } from '../../../types/projectUiTransitionState.ts';

interface IActivateActionButtonProps {
  readonly project: IProjectViewModel;
  readonly isStatusChanging: boolean;
  readonly onActivate?: () => void;
}

export const ProjectTransitionButton: React.FC<IActivateActionButtonProps> = ({
  project,
  onActivate,
  isStatusChanging,
}) => {
  const hasCapacityToActivateProject =
    !project.subscriptionProjectsLimit ||
    project.subscriptionProjectsLimit > project.subscriptionProjectsCount;

  return (
    <div className="my-project__button-wrapper">
      <Tooltip
        tooltipText={
          hasCapacityToActivateProject
            ? UnableToRestoreProjectLimitReachedMessage(
                project.subscriptionProjectsLimit,
                project.subscriptionName,
              )
            : undefined
        }
        placement="bottom"
      >
        <Button
          style={ButtonStyle.Secondary}
          size={ButtonSize.Block}
          onClick={onActivate}
          disabled={isStatusChanging || !hasCapacityToActivateProject}
          {...getDataUiActionAttribute(DataUiAction.Activate)}
        >
          {getProjectTransitionButtonText(project)}
        </Button>
      </Tooltip>
    </div>
  );
};

ProjectTransitionButton.displayName = 'ProjectTransitionButton';

const getProjectTransitionButtonText = (project: IProjectViewModel): string => {
  if (project.uiTransitionState === ProjectUiTransitionState.Activating) {
    return 'Activating';
  }
  if (project.uiTransitionState === ProjectUiTransitionState.Deactivating) {
    return 'Archiving';
  }
  if (project.inactive) {
    return 'Restore project';
  }
  if (project.isBeingDeleted) {
    return 'Deleting project';
  }
  return '';
};
