import PropTypes from 'prop-types';
import React from 'react';
import { Menu } from '../../../constants/Menu.ts';
import { mainMenuStructure } from '../../../constants/editingActions/mainMenuStructure.ts';
import { EditingAction } from '../../../models/EditingAction.ts';
import { ActionsMenuGroup } from './ActionsMenuGroup.tsx';

type ActionsMainMenuProps = {
  readonly actions: readonly EditingAction[];
};

const propTypes: PropTypesShape<ActionsMainMenuProps> = {
  actions: PropTypes.arrayOf(PropTypes.oneOf(Object.values(EditingAction))).isRequired,
};

export const ActionsMainMenu: React.FC<ActionsMainMenuProps> = (props) => (
  <div className="content-editing-actions__main-menu" key={Menu.MainMenu}>
    {mainMenuStructure.map((group) => (
      <ActionsMenuGroup
        allGroupActions={group.actions}
        availableActions={props.actions}
        key={group.key}
        title={group.title}
      />
    ))}
  </div>
);

ActionsMainMenu.displayName = 'ActionsMainMenu';
ActionsMainMenu.propTypes = propTypes;
