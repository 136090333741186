import { EditorState } from 'draft-js';
import React, { useCallback } from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { isUrlSlugElement } from '../../../../../models/contentItemElements/compiledItemElementTypeGuards.ts';
import { getElementById } from '../../../../../stores/utils/contentItemElementsUtils.ts';
import {
  onUrlSlugElementValueChange,
  regenerateUrlSlug,
} from '../../../actions/thunkContentItemEditingActions.ts';
import {
  UrlSlug as UrlSlugComponent,
  UrlSlugExtraProps,
  UrlSlugProps,
} from '../../../components/elements/urlSlug/UrlSlug.tsx';
import { getEditedContentItemTypeElementData } from '../../selectors/getEditedContentItemTypeElementData.ts';

type Props = Omit<UrlSlugProps, keyof UrlSlugExtraProps>;

export const UrlSlug: React.FC<Props> = (props) => {
  const {
    typeElement: { elementId, dependentTextElementId },
  } = props;

  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const elementData = useSelector((state) =>
    getElementById(elementId, state.contentApp.editedContentItemVariantElements),
  );

  const dependentTextElementName = useSelector((state) =>
    dependentTextElementId
      ? getEditedContentItemTypeElementData(state, dependentTextElementId)?.name ?? ''
      : undefined,
  );

  const onChange = useCallback(
    (newEditorState: EditorState) =>
      dispatch(onUrlSlugElementValueChange(elementId, newEditorState)),
    [elementId],
  );
  const onAutoGenerated = useCallback(
    () => dispatch(regenerateUrlSlug({ elementId, pathname })),
    [elementId, pathname],
  );

  if (!isUrlSlugElement(elementData)) {
    return null;
  }

  return (
    <UrlSlugComponent
      {...props}
      dependentTextElementName={dependentTextElementName}
      elementData={elementData}
      onAutoGenerated={onAutoGenerated}
      onChange={onChange}
    />
  );
};

UrlSlug.displayName = 'UrlSlugContainer';
