import { recommendedLinkedItemsRepository } from '../../applications/itemEditor/features/LinkedItemsAiAssistant/repositories/recommendedLinkedItemsRepository.ts';
import { missionControlRepository } from '../../applications/unifiedMissionControl/repositories/missionControlRepository.ts';
import { accountRepository } from '../../repositories/accountRepository.ts';
import { aiRepository } from '../../repositories/aiRepository.ts';
import { apiKeysRepository } from '../../repositories/apiKeysRepository.ts';
import { assetFolderRepository } from '../../repositories/assetFolderRepository.ts';
import { assetRenditionRepository } from '../../repositories/assetRenditionRepository.ts';
import { assetRepository } from '../../repositories/assetRepository.ts';
import { assetTypeRepository } from '../../repositories/assetTypeRepository.ts';
import { auditLogRepository } from '../../repositories/auditLogRepository.ts';
import { collectionsRepository } from '../../repositories/collectionsRepository.ts';
import { commentRepository } from '../../repositories/commentRepository.ts';
import { contentItemRepository } from '../../repositories/contentItemRepository.ts';
import { contentTypeRepository } from '../../repositories/contentTypeRepository.ts';
import { contentTypeSnippetRepository } from '../../repositories/contentTypeSnippetRepository.ts';
import { entityWebhookRepository } from '../../repositories/entityWebhookRepository.ts';
import { eventRepository } from '../../repositories/eventRepository.ts';
import { filterRepository } from '../../repositories/filterRepository.ts';
import { generalPropertyRepository } from '../../repositories/generalPropertyRepository.ts';
import { innovationLabRepository } from '../../repositories/innovationLabRepository.ts';
import {
  IRepository,
  PublicRepository,
  RepositoryWithContext,
} from '../../repositories/interfaces/repository.type.ts';
import { inviteRepository } from '../../repositories/inviteRepository.ts';
import { languageRepository } from '../../repositories/languageRepository.ts';
import { peopleRepository } from '../../repositories/peopleRepository.ts';
import { previewConfigurationRepository } from '../../repositories/previewConfigurationRepository.ts';
import { productUpdateRepository } from '../../repositories/productUpdateRepository.ts';
import { projectContainerRepository } from '../../repositories/projectContainerRepository.ts';
import { projectRepository } from '../../repositories/projectRepository.ts';
import { relationRepository } from '../../repositories/relationRepository.ts';
import { roleRepository } from '../../repositories/roleRepository.ts';
import { sitemapRepository } from '../../repositories/sitemapRepository.ts';
import { spacesRepository } from '../../repositories/spacesRepository.ts';
import { subscriptionRepository } from '../../repositories/subscriptionRepository.ts';
import { taskRepository } from '../../repositories/taskRepository.ts';
import { taxonomyRepository } from '../../repositories/taxonomyRepository.ts';
import { translateVariantRepository } from '../../repositories/translateVariantRepository.ts';
import { uiPreferenceRepository } from '../../repositories/uiPreferenceRepository.ts';
import { userRepository } from '../../repositories/userRepository.ts';
import { webSpotlightRepository } from '../../repositories/webSpotlightRepository.ts';
import { webhookRepository } from '../../repositories/webhookRepository.ts';
import { workflowRepository } from '../../repositories/workflowRepository.ts';
import { GetAuthToken } from '../types/GetAuthToken.type.ts';
import { IRequestContext } from '../utils/restProvider.ts';

interface IRepositoryDependencies {
  readonly getAppInstanceId: () => string;
  readonly getAuthToken: GetAuthToken;
  readonly getCurrentMasterEnvironmentId: () => Uuid | null;
  readonly getCurrentProjectContainerId: () => Uuid | null;
  readonly getCurrentProjectId: () => Uuid | null;
  readonly handleServerErrorResponse: (request: XMLHttpRequest) => Promise<void>;
  readonly handleServerResponse: (request: XMLHttpRequest) => any;
}

const repositories = {
  accountRepository,
  aiRepository,
  apiKeysRepository,
  assetFolderRepository,
  assetRenditionRepository,
  assetRepository,
  assetTypeRepository,
  auditLogRepository,
  collectionsRepository,
  commentRepository,
  contentItemRepository,
  missionControlRepository,
  contentTypeRepository,
  contentTypeSnippetRepository,
  innovationLabRepository,
  entityWebhookRepository,
  eventRepository,
  filterRepository,
  generalPropertyRepository,
  inviteRepository,
  languageRepository,
  peopleRepository,
  previewConfigurationRepository,
  productUpdateRepository,
  projectContainerRepository,
  projectRepository,
  recommendedLinkedItemsRepository,
  relationRepository,
  roleRepository,
  sitemapRepository,
  spacesRepository,
  subscriptionRepository,
  taskRepository,
  taxonomyRepository,
  translateVariantRepository,
  uiPreferenceRepository,
  userRepository,
  webSpotlightRepository,
  webhookRepository,
  workflowRepository,
} as const;

type IRepositoriesWithContext = typeof repositories;

const createRequestContext = async ({
  getAppInstanceId,
  getAuthToken,
  getCurrentMasterEnvironmentId,
  getCurrentProjectContainerId,
  getCurrentProjectId,
}: IRepositoryDependencies): Promise<IRequestContext> => {
  const appInstanceId = getAppInstanceId() ?? undefined;

  // All repository methods are enhanced with the authentication and app params, it is up to the repository implementation whether it will use it or not
  const projectId = getCurrentProjectId() ?? undefined;
  const projectContainerId = getCurrentProjectContainerId() ?? undefined;
  const masterEnvironmentId = getCurrentMasterEnvironmentId() ?? undefined;
  const authToken = await getAuthToken();

  return {
    appInstanceId,
    authToken,
    masterEnvironmentId,
    projectId,
    projectContainerId,
  };
};

type IRepositories = {
  readonly [RepositoryName in keyof IRepositoriesWithContext]: PublicRepository<
    IRepositoriesWithContext[RepositoryName]
  >;
};

const initPublicRepository = <
  TRepository extends IRepository,
  TPublicRepository extends PublicRepository<TRepository>,
>(
  repository: TRepository,
  publicRepository: TPublicRepository,
  dependencies: IRepositoryDependencies,
) => {
  const authenticateRepositoryMethod = <
    TMethod extends TRepository[keyof TRepository],
    TPublicMethod extends TPublicRepository[keyof TPublicRepository],
  >(
    repoMethod: TMethod,
    repositoryDependencies: IRepositoryDependencies,
  ) =>
    (async (...args) => {
      const requestContext = await createRequestContext(repositoryDependencies);
      const decoratedRepoMethod = repoMethod.bind(repository, requestContext, ...args) as TMethod;

      try {
        const response = await decoratedRepoMethod();
        return repositoryDependencies.handleServerResponse(response);
      } catch (error) {
        if (error instanceof XMLHttpRequest) {
          await repositoryDependencies.handleServerErrorResponse(error);
          // eslint-disable-next-line @typescript-eslint/only-throw-error
          throw error;
        }

        if (error instanceof Error) {
          throw error;
        }

        throw new Error(`Unknown error: ${JSON.stringify(error)}`);
      }
    }) as TPublicMethod;

  const castRepositoryMethodName = <MethodName extends keyof TRepository>(
    repositoryMethodName: string,
  ) => repositoryMethodName as MethodName;
  const castPublicMethodName = <MethodName extends keyof TPublicRepository>(
    repositoryMethodName: string,
  ) => repositoryMethodName as MethodName;

  Object.keys(repository).forEach((methodName) => {
    const repositoryMethod = repository[castRepositoryMethodName(methodName)];
    const publicRepositoryMethod = authenticateRepositoryMethod(repositoryMethod, dependencies);

    publicRepository[castPublicMethodName(methodName)] = publicRepositoryMethod;
  });
};

const createThrowingRepository = <TPublicRepository extends IRepositories[keyof IRepositories]>(
  repositoryTemplate: RepositoryWithContext<TPublicRepository>,
): TPublicRepository => {
  const publicRepository = {} as TPublicRepository;

  const createThrowingMethod = <
    TPublicMethod extends TPublicRepository[keyof TPublicRepository],
  >() =>
    (() => {
      throw new Error('Repository is not initialized, call initializeRepositories first.');
    }) as unknown as TPublicMethod;

  const castPublicMethodName = <MethodName extends keyof TPublicRepository>(
    repositoryMethodName: string,
  ) => repositoryMethodName as MethodName;

  Object.keys(repositoryTemplate).forEach((methodName) => {
    publicRepository[castPublicMethodName(methodName)] = createThrowingMethod();
  });

  return publicRepository;
};

const createThrowingRepositories = (
  rawRepositories: Record<keyof IRepositories, RepositoryWithContext<any>>,
): IRepositories => {
  const repositoryEntries = Object.entries(rawRepositories).map(([key, repository]) => [
    key,
    createThrowingRepository(repository),
  ]);

  return Object.fromEntries(repositoryEntries);
};

const publicRepositories = createThrowingRepositories({
  accountRepository,
  aiRepository,
  apiKeysRepository,
  assetFolderRepository,
  assetRenditionRepository,
  assetRepository,
  assetTypeRepository,
  auditLogRepository,
  collectionsRepository,
  commentRepository,
  contentItemRepository,
  missionControlRepository,
  contentTypeRepository,
  contentTypeSnippetRepository,
  innovationLabRepository,
  entityWebhookRepository,
  eventRepository,
  filterRepository,
  generalPropertyRepository,
  inviteRepository,
  languageRepository,
  peopleRepository,
  previewConfigurationRepository,
  productUpdateRepository,
  projectContainerRepository,
  projectRepository,
  recommendedLinkedItemsRepository,
  relationRepository,
  roleRepository,
  sitemapRepository,
  spacesRepository,
  subscriptionRepository,
  taskRepository,
  taxonomyRepository,
  translateVariantRepository,
  uiPreferenceRepository,
  userRepository,
  webSpotlightRepository,
  webhookRepository,
  workflowRepository,
});

export function initializeRepositories(dependencies: IRepositoryDependencies): void {
  // Public repositories must be initialized by mutating existing objects as they are statically imported (spreaded from repositoryCollection)
  // and therefore their default (throwing) implementation could stay in the modules that use them
  const init = <RepositoryName extends keyof IRepositoriesWithContext>(
    repositoryName: RepositoryName,
  ) => {
    initPublicRepository(
      repositories[repositoryName],
      publicRepositories[repositoryName],
      dependencies,
    );
  };

  Object.keys(publicRepositories).forEach(init);
}

export const repositoryCollection: Readonly<IRepositories> = publicRepositories;
