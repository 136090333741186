import { memoize } from '@kontent-ai/memoization';
import { Collection } from '@kontent-ai/utils';
import { DefaultWorkflowId } from '../../../../_shared/constants/variantIdValues.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { logError } from '../../../../_shared/utils/logError.ts';
import { getAllWorkflowSteps } from '../../../../_shared/utils/workflow/getAllWorkflowSteps.ts';
import { Workflow } from '../../../models/workflow/Workflow.ts';
import {
  IAssignmentWorkflowStep,
  IWorkflowStep,
  createAssignmentWorkflowStep,
} from '../../../models/workflow/WorkflowStep.ts';

export const getWorkflowStepsById = memoize.maxOne(
  (workflow: Workflow): ReadonlyMap<Uuid, IWorkflowStep> =>
    getAllWorkflowSteps(workflow).reduce(
      (reduced, step) => reduced.set(step.id, step),
      new Map<Uuid, IWorkflowStep>(),
    ),
);

export const getAllWorkflowStepsById = memoize.weak(
  (workflowsMap: ReadonlyMap<Uuid, Workflow>): ReadonlyMap<Uuid, IAssignmentWorkflowStep> =>
    Collection.getValues(workflowsMap)
      .flatMap((workflow) =>
        getAllWorkflowSteps(workflow).map((step) =>
          createAssignmentWorkflowStep(step, workflow.id),
        ),
      )
      .reduce(
        // this actually removes any duplicate steps so each workflow step with unique ID is present but once
        (reduced, step) => reduced.set(step.id, step),
        new Map<Uuid, IAssignmentWorkflowStep>(),
      ),
);

export const areWorkflowsLoaded = (state: IStore): boolean =>
  !!state.data.workflows.byId.get(DefaultWorkflowId)?.publishedStep.id;

export const getWorkflowStep = (workflow: Workflow, stepId: Uuid): IWorkflowStep | undefined => {
  const workflowStep = getAllWorkflowSteps(workflow).find((step) => step.id === stepId);

  if (!workflowStep) {
    logError(
      `workflowSelector.ts: Workflow step with ID ${stepId} does not exist in the workflow ${workflow.id}.`,
    );
  }

  return workflowStep;
};

export const getWorkflowStepName = (workflow: Workflow, stepId: Uuid | null): string | null => {
  if (!stepId) {
    return null;
  }
  const allSteps = getAllWorkflowSteps(workflow);
  return allSteps.find((step) => step.id === stepId)?.name ?? null;
};

export const getWorkflow = (state: IStore, workflowId: Uuid): Workflow | undefined => {
  const { byId: workflows, loadingStatus } = state.data.workflows;

  const workflow = workflows.get(workflowId);

  if (loadingStatus === LoadingStatus.Loaded && !workflow) {
    logError(`Could not find workflow with id: ${workflowId}.`);
  }

  return workflow;
};

export const getWorkflowStepByIds = (
  state: IStore,
  workflowId: Uuid,
  stepId: Uuid,
): IWorkflowStep | undefined => {
  const workflow = getWorkflow(state, workflowId);

  return workflow ? getWorkflowStep(workflow, stepId) : undefined;
};
