import { memoize } from '@kontent-ai/memoization';
import { contentInventoryRequiredCapabilities } from '../../../applications/contentInventory/shared/utils/contentInventoryRequiredCapabilities.ts';
import { getContentModelsRequiredCapabilities } from '../../../applications/contentModels/shared/utils/contentModelsTabsStructure.ts';
import { environmentSettingsRequiredCapabilities } from '../../../applications/environmentSettings/utils/environmentSettingsMenuStructureUtils.tsx';
import { webSpotlightRequiredCapabilities } from '../../../applications/webSpotlight/utils/webSpotlightRequiredCapabilities.ts';
import { ProjectRoutes } from '../../components/Project.tsx';
import {
  ContentAppEntryRoute,
  ContentModelsRoute,
  EnvironmentSettingsRoute,
  HomeRootRoute,
  MissionControlRootRoute,
  WebSpotlightEntryRoute,
} from '../../constants/routePaths.ts';
import { noCapabilitiesRequired } from '../permissions/capability.ts';

export const getProjectEnvironmentRoutes = memoize.maxOne(
  (
    isSitemapEnabled: boolean,
    isWebSpotlightEnabled: boolean,
    canAccessEnvironmentSettings: boolean,
    canAccessMissionControl: boolean,
    isUnifiedMissionControlEnabled: boolean,
  ): ProjectRoutes => {
    return {
      ...(isUnifiedMissionControlEnabled && {
        unifiedMissionControl: {
          path: MissionControlRootRoute,
          requiredCapabilities: contentInventoryRequiredCapabilities,
        },
      }),
      ...(isWebSpotlightEnabled && {
        webSpotlight: {
          path: WebSpotlightEntryRoute,
          requiredCapabilities: webSpotlightRequiredCapabilities,
        },
      }),
      ...(!isUnifiedMissionControlEnabled &&
        canAccessMissionControl && {
          missionControl: {
            path: MissionControlRootRoute,
            requiredCapabilities: contentInventoryRequiredCapabilities,
          },
        }),
      home: {
        path: HomeRootRoute,
        requiredCapabilities: contentInventoryRequiredCapabilities,
      },
      content: {
        path: ContentAppEntryRoute,
        requiredCapabilities: contentInventoryRequiredCapabilities,
      },
      contentModels: {
        path: ContentModelsRoute,
        requiredCapabilities: getContentModelsRequiredCapabilities(isSitemapEnabled),
      },
      environmentSettings: {
        path: EnvironmentSettingsRoute,
        requiredCapabilities: canAccessEnvironmentSettings
          ? noCapabilitiesRequired
          : environmentSettingsRequiredCapabilities(),
      },
    };
  },
);
