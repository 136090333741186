import { InvariantException } from '@kontent-ai/errors';
import { Collection } from '@kontent-ai/utils';
import React, { useCallback } from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { isCollectionsConfigurationVisible } from '../../../../_shared/selectors/contentCollections.ts';
import { isLegacyWebhookConfigurationEnabled } from '../../../../_shared/selectors/isLegacyWebhookConfigurationEnabled.ts';
import { getSelectedSubscription } from '../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { getCurrentProject } from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { canAccessInnovationLab } from '../../innovationLab/selectors/canAccessInnovationLab.ts';
import {
  areSpacesEnabledForCurrentProject,
  isAuditLogEnabledForCurrentProjectPlan,
} from '../../selectors/allowedFeaturesUtils.ts';
import { isEnvironmentGeneralSettingsTabVisible } from '../../selectors/environmentSettingsMenuCustomVisibilities.ts';
import {
  EnvironmentSettingsMenuStructureGroupItem,
  environmentSettingsMenuStructure,
} from '../../utils/environmentSettingsMenuStructure.tsx';
import { getFilteredOutMenuStructure } from '../../utils/environmentSettingsMenuStructureUtils.tsx';
import { initEnvironmentSettings } from '../actions/thunkEnvironmentSettingsActions.ts';
import { EnvironmentSettings as EnvironmentSettingsComponent } from '../components/EnvironmentSettings.tsx';

const findFirstAllowedChildRoute = (
  menuStructure: ReadonlyArray<EnvironmentSettingsMenuStructureGroupItem>,
): string | null => {
  return menuStructure[0]?.routePath ?? null;
};

export const EnvironmentSettings: React.FC = () => {
  const dispatch = useDispatch();

  const projectId = useSelector((s) => getCurrentProject(s).projectId);
  const subscriptionId = useSelector(
    (state) => getSelectedSubscription(state)?.subscriptionId ?? '',
  );
  const projectContainerId = useSelector((s) => getCurrentProject(s).projectContainerId);
  const areCollectionsEnabled = useSelector((s) =>
    isCollectionsConfigurationVisible(s, Collection.getValues(s.data.collections.byId)),
  );
  const areGeneralSettingsVisible = useSelector((s) => isEnvironmentGeneralSettingsTabVisible(s));
  const areSpacesEnabled = useSelector(areSpacesEnabledForCurrentProject);
  const isAuditLogEnabled = useSelector(isAuditLogEnabledForCurrentProjectPlan);
  const firstAllowedChildRoute = useSelector((s) =>
    findFirstAllowedChildRoute(getFilteredOutMenuStructure(s, environmentSettingsMenuStructure())),
  );
  const areLegacyWebhooksEnabled = useSelector(isLegacyWebhookConfigurationEnabled);
  const isInnovationLabAccessible = useSelector(canAccessInnovationLab);

  const onInit = useCallback(() => dispatch(initEnvironmentSettings()), []);

  if (!firstAllowedChildRoute) {
    throw InvariantException(
      `Cannot render ${EnvironmentSettingsComponent.displayName} when user has none of the required permissions.`,
    );
  }

  return (
    <EnvironmentSettingsComponent
      areCollectionsEnabled={areCollectionsEnabled}
      areLegacyWebhooksEnabled={areLegacyWebhooksEnabled}
      areGeneralSettingsVisible={areGeneralSettingsVisible}
      areSpacesEnabled={areSpacesEnabled}
      firstAllowedChildRoute={firstAllowedChildRoute}
      isAuditLogEnabled={isAuditLogEnabled}
      isInnovationLabAccessible={isInnovationLabAccessible}
      onInit={onInit}
      projectId={projectId}
      subscriptionId={subscriptionId}
      projectContainerId={projectContainerId}
    />
  );
};

EnvironmentSettings.displayName = 'EnvironmentSettings';
