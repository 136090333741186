import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../@types/Dispatcher.type.ts';
import {
  EnsureValidState,
  IEnsureValidStateDispatchProps,
  IEnsureValidStateStateProps,
} from '../../../../../_shared/components/EnsureValidState.tsx';
import { archiveTemporaryContentItem } from '../actions/thunkContentItemEditingActions.ts';

const mapStateToProps = (): IEnsureValidStateStateProps => ({
  isStateEnsured: true,
});

const mapDispatchToProps = (dispatch: Dispatch): IEnsureValidStateDispatchProps => ({
  onRouteLeft: () => dispatch(archiveTemporaryContentItem()),
});

export const EnsureTemporaryItemDeletion: React.ComponentType = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EnsureValidState);
