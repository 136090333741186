import Immutable from 'immutable';
import React, { useEffect } from 'react';
import { IProjectListingUser } from '../../../applications/projects/models/ProjectListingUser.ts';
import { Button } from '../../uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../uiComponents/Button/buttonStyle.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { formatUserName } from '../../utils/usersUtils.ts';
import { Loader } from '../Loader.tsx';
import { ModalDialog } from '../ModalDialog/ModalDialog.tsx';

export interface IAvailableSubscriptionAdminsProps {
  readonly admins: Immutable.List<IProjectListingUser> | null;
  readonly loadAdmins: () => void;
  readonly onClose: () => void;
  readonly subscriptionName?: string;
}

export const AvailableSubscriptionAdmins: React.FC<IAvailableSubscriptionAdminsProps> = ({
  admins,
  loadAdmins,
  onClose,
  subscriptionName,
}) => {
  useEffect(() => {
    loadAdmins();
  }, [loadAdmins]);

  const subscriptionSpecifier = subscriptionName ? `“${subscriptionName}”` : 'your';

  const body = admins ? (
    <div>
      {admins.toArray().map((admin) => (
        <p key={admin.userId}>{formatUserName(admin)}</p>
      ))}
    </div>
  ) : (
    <Loader />
  );

  const footer = (
    <Button
      autoFocus
      onClick={onClose}
      style={ButtonStyle.Primary}
      {...getDataUiActionAttribute(DataUiAction.CloseSubscriptionAdminInfo)}
    >
      Close
    </Button>
  );

  return (
    <ModalDialog
      bodyContent={body}
      dataUiElement={DataUiElement.SubscriptionAdminsDialog}
      footerContentRight={footer}
      headerContent={`Admins of ${subscriptionSpecifier} subscription`}
      onClose={onClose}
    />
  );
};

AvailableSubscriptionAdmins.displayName = 'AvailableSubscriptionAdmins';
