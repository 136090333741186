import { Box } from '@kontent-ai/component-library/Box';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import { RadioButton, RadioGroup } from '@kontent-ai/component-library/RadioGroup';
import { Row } from '@kontent-ai/component-library/Row';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { isAfter } from 'date-fns';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  formatDatetimeToReadable,
  formatTimeToReadable,
  parseDatetime,
} from '../../../../../../component-library/components/DatetimePicker/datetimeUtils.ts';
import { DatetimePickerPlaceholder } from '../../../../../applications/projects/constants/UIConstants.ts';
import { Caption } from '../../../../components/DateTimeWithTimeZonePicker/Caption.tsx';
import {
  DatetimePicker,
  DatetimePickerRefType,
} from '../../../../components/DatetimePicker/DatetimePicker.tsx';
import { ModalDialogAnimationFinishedContext } from '../../../../components/ModalDialog/ModalDialogAnimationFinishedContext.tsx';
import { OptionType } from '../../../../components/Options/createOptionsListComponent.tsx';
import { TimeZoneSelect } from '../../../../components/TimeZoneSelect/TimeZoneSelect.tsx';
import {
  DataUiCollection,
  DataUiWorkflowAction,
  getDataUiCollectionAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../utils/dataAttributes/DataUiAttributes.ts';
import {
  getNextFullHourDate,
  getTodayReadableDate,
  toLocalTime,
} from '../../../../utils/dateTime/timeUtils.ts';
import { transferBetweenTimeZonesKeepingLocalTime } from '../../../../utils/dateTime/timeZoneUtils.ts';
import { ScheduleMethod } from '../../constants/scheduleMethods.ts';
import { ModalSection } from '../ModalSection.tsx';

type ScheduleMethodSectionProps = {
  readonly invalidDateError?: string;
  readonly onScheduleChange: (dateTimeUtc: string | null, timeZoneId: string) => void;
  readonly onScheduleMethodChange: (selectedOption: ScheduleMethod) => void;
  readonly options: ReadonlyArray<OptionType<DataUiWorkflowAction>>;
  readonly scheduledAt: DateTimeStamp | null;
  readonly scheduleDisplayTimeZone: string;
  readonly scheduledToMaxValue?: DateTimeStamp | null;
  readonly scheduledToMinValue?: DateTimeStamp | null;
  readonly sectionTitle: string;
  readonly selectedScheduleMethod: ScheduleMethod;
};

export const ScheduleMethodSection: React.FC<ScheduleMethodSectionProps> = ({
  invalidDateError,
  onScheduleChange,
  onScheduleMethodChange,
  options,
  scheduledAt,
  scheduleDisplayTimeZone,
  scheduledToMaxValue,
  scheduledToMinValue,
  sectionTitle,
  selectedScheduleMethod,
}) => {
  const selectScheduleMethod = useCallback(
    (newScheduleMethod: ScheduleMethod) => {
      // prevents date time picker clean up after reopening calendar
      if (selectedScheduleMethod !== newScheduleMethod) {
        onScheduleMethodChange(newScheduleMethod);
      }
    },
    [selectedScheduleMethod, onScheduleMethodChange],
  );

  const datetimePickerRef = useRef<DatetimePickerRefType>(null);
  const [shouldFocusDatePicker, setShouldFocusDatePicker] = useState(false);
  const enterAnimationFinished = useContext(ModalDialogAnimationFinishedContext);
  const shouldOpenDatePicker = selectedScheduleMethod === ScheduleMethod.Schedule;

  useEffect(() => {
    if (shouldOpenDatePicker) {
      setShouldFocusDatePicker(true);
    }
  }, [shouldOpenDatePicker]);

  useEffect(() => {
    if (enterAnimationFinished && shouldFocusDatePicker) {
      datetimePickerRef.current?.focusInputAtTheEnd();
      setShouldFocusDatePicker(false);
    }
  }, [enterAnimationFinished, shouldFocusDatePicker]);

  const scheduledToMaxValueLocalDate = useMemo(
    () => toLocalTime(scheduledToMaxValue),
    [scheduledToMaxValue],
  );
  const scheduledToMinValueLocalDate = useMemo(
    () => toLocalTime(scheduledToMinValue),
    [scheduledToMinValue],
  );

  const scheduledChanged = (newUtcDate: string | null, newTimeZoneId: string): void => {
    const utcDateTimeInNewTimeZone =
      newUtcDate &&
      newTimeZoneId &&
      transferBetweenTimeZonesKeepingLocalTime(newUtcDate, scheduleDisplayTimeZone, newTimeZoneId);

    onScheduleChange(utcDateTimeInNewTimeZone ?? newUtcDate, newTimeZoneId);
  };

  return (
    <ModalSection key="scheduleMethod">
      <Stack align="start" spacing={Spacing.XS}>
        <RadioGroup
          name="Publishing method"
          groupLabel={sectionTitle}
          onChange={onScheduleMethodChange}
          value={selectedScheduleMethod}
          {...getDataUiCollectionAttribute(DataUiCollection.ScheduleMethodSelector)}
        >
          {options.map((option) => (
            <Row key={option.id}>
              <RadioButton
                value={option.id}
                radioButtonState={option.disabled ? 'disabled' : 'default'}
                tooltipText={option.tooltipText}
                {...getDataUiObjectNameAttribute(option.dataUiAttribute ?? '')}
              >
                {option.label}
              </RadioButton>
            </Row>
          ))}
        </RadioGroup>

        <Box paddingLeft={Spacing.XXL}>
          <Row>
            <Stack spacing={Spacing.S}>
              <Inline spacingX={Spacing.S}>
                <DatetimePicker
                  errorMessage={invalidDateError}
                  defaultDate={getDefaultDate(scheduledToMinValue)}
                  defaultTime={getDefaultTime(scheduledToMinValue)}
                  maxValue={scheduledToMaxValueLocalDate ? scheduledToMaxValueLocalDate : undefined}
                  minValue={scheduledToMinValueLocalDate ?? minValue}
                  onChange={(dateTime) =>
                    dateTime.isValid && scheduledChanged(dateTime.value, scheduleDisplayTimeZone)
                  }
                  onOpenCalendar={() => selectScheduleMethod(ScheduleMethod.Schedule)}
                  placeholder={DatetimePickerPlaceholder}
                  ref={datetimePickerRef}
                  timeZoneId={scheduleDisplayTimeZone ?? undefined}
                  value={scheduledAt}
                  hideUtcLabel
                />
                <TimeZoneSelect
                  selectedTimeZoneId={scheduleDisplayTimeZone}
                  onChange={(timeZoneId) => scheduledChanged(scheduledAt, timeZoneId)}
                />
              </Inline>
              <Caption
                date={scheduledAt}
                timeZone={scheduleDisplayTimeZone}
                renderStyledText={(text) => <Label size={LabelSize.M}>{text}</Label>}
              />
            </Stack>
          </Row>
        </Box>
      </Stack>
    </ModalSection>
  );
};

ScheduleMethodSection.displayName = 'ScheduleMethodSection';

const minValue = new Date();

const getDefaultDate = (scheduledToMinValue: DateTimeStamp | null | undefined): string => {
  const now = new Date();

  const scheduledToMinValueDate = scheduledToMinValue
    ? parseDatetime(scheduledToMinValue, true)
    : null;

  const scheduledToMinAsDefault =
    scheduledToMinValueDate &&
    isAfter(scheduledToMinValueDate, now) &&
    formatDatetimeToReadable(scheduledToMinValueDate, false);

  return scheduledToMinAsDefault || getTodayReadableDate();
};

const getDefaultTime = (scheduledToMinValue: DateTimeStamp | null | undefined): string => {
  const defaultTime = getNextFullHourDate();

  const scheduledToMinValueDate = scheduledToMinValue
    ? parseDatetime(scheduledToMinValue, true)
    : null;

  const scheduledToMinAsDefault =
    scheduledToMinValueDate &&
    isAfter(scheduledToMinValueDate, defaultTime) &&
    formatTimeToReadable(scheduledToMinValueDate);

  return (
    scheduledToMinAsDefault ||
    defaultTime.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
    })
  );
};
