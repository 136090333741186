import { memoize } from '@kontent-ai/memoization';
import { IUserProjectInfo } from '../../../data/models/user/UserProjectInfo.ts';

export enum Capability {
  NEVER_ALLOWED = 'NEVER_ALLOWED',
  UNKNOWN = 'UNKNOWN',
  // The decision to keep the old enum value is described in the task KCL-10386
  // and should be corrected upon completing this task
  ManagePersonalApiKey = 'access-api-keys',
  AccessAuditLog = 'access-audit-log',
  ConfigureCodenames = 'configure-codenames',
  ConfigureContentTypes = 'configure-content_types',
  ConfigureLanguages = 'manage-languages',
  ConfigurePreviewUrl = 'configure-preview-url',
  ConfigureSitemap = 'configure-sitemap',
  ConfigureTaxonomy = 'configure-taxonomy',
  ConfigureWorkflow = 'configure-workflow',
  CreateContent = 'create-content',
  DeleteContent = 'delete-content',
  ManageAssets = 'manage-assets',
  ManageCollections = 'manage-collections',
  ManageEnvironments = 'manage-environments',
  ManageRoles = 'manage-roles',
  ManageSpaces = 'manage-spaces',
  ManageUsers = 'manage-users',
  ManageWebhooks = 'manage-webhooks',
  UpdateContent = 'update-content',
  ViewContent = 'view-content',
  ViewAssets = 'view-assets',
  UpdateAssets = 'update-assets',
  CreateAssets = 'create-assets',
  DeleteAssets = 'delete-assets',
}

const excludedCapabilities = [Capability.UNKNOWN, Capability.NEVER_ALLOWED];
export const allCapabilities = Object.values(Capability).filter(
  (c) => !excludedCapabilities.includes(c),
);

export interface ICapability {
  readonly canOneOf: (capabilities: ReadonlyArray<Capability>) => boolean;
  readonly can: (capability: Capability) => boolean;
}

function hasPermission(permission: Capability, project: IUserProjectInfo): boolean {
  return project.capabilities.includes(permission);
}

// While it may seem user may have only one set of permissions (for current project), this is called for all projects in project selector
// That is why we need to memoize multiple instances
export const getUserCapability = memoize.weak((project: IUserProjectInfo): ICapability => {
  return {
    canOneOf: (capabilities: ReadonlyArray<Capability>) =>
      capabilities === noCapabilitiesRequired ||
      capabilities.some((permissionKey) => hasPermission(permissionKey, project)),
    can: (capability: Capability) => hasPermission(capability, project),
  };
});

export const noCapabilitiesRequired = [] as const;
export const neverAllowed = [Capability.NEVER_ALLOWED] as const;
