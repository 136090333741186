import { omit } from '@kontent-ai/component-library/component-utils';
import { getDataUiComponentAttribute } from '@kontent-ai/component-library/utils';
import PropTypes from 'prop-types';
import React, { PropsWithChildren, PropsWithoutRef, RefAttributes, WeakValidationMap } from 'react';
import {
  BaseNotificationBar,
  NotificationBarProps,
  notificationBarPropTypes,
} from './components/BaseNotificationBar.tsx';
import { NotificationBarType } from './notificationBarType.ts';

const createFlavoredNotificationBar = <TProps extends PropsWithChildren<NotificationBarProps>>(
  flavor: NotificationBarType,
  propTypes: WeakValidationMap<PropsWithoutRef<TProps> & RefAttributes<HTMLDivElement>>,
) => {
  const FlavoredNotificationBar = React.forwardRef(
    (props: PropsWithChildren<TProps>, forwardedRef: React.Ref<HTMLDivElement>) => (
      <BaseNotificationBar
        notificationBarType={flavor}
        ref={forwardedRef}
        {...getDataUiComponentAttribute(FlavoredNotificationBar)}
        {...props}
      />
    ),
  );

  FlavoredNotificationBar.propTypes = propTypes;
  FlavoredNotificationBar.displayName = `NotificationBar${flavor}`;

  return FlavoredNotificationBar;
};

/**
 * Info
 */
export type NotificationBarInfoProps = NotificationBarProps;
const propTypesInfo: PropTypeMap<NotificationBarInfoProps> = notificationBarPropTypes;

export const NotificationBarInfo = createFlavoredNotificationBar<NotificationBarInfoProps>(
  'Info',
  propTypesInfo,
);

/**
 * Alert
 */
export type NotificationBarAlertProps = Omit<NotificationBarProps, 'hideIcon' | 'onDismiss'> &
  Required<Pick<NotificationBarProps, 'onDismiss'>>;
const propTypesAlert: PropTypeMap<NotificationBarAlertProps> = {
  ...omit(notificationBarPropTypes, ['hideIcon']),
  onDismiss: PropTypes.func.isRequired,
};

export const NotificationBarAlert = createFlavoredNotificationBar<NotificationBarAlertProps>(
  'Alert',
  propTypesAlert,
);

/**
 * Warning
 */
export type NotificationBarWarningProps = Omit<NotificationBarProps, 'hideIcon'>;
const propTypesWarning: PropTypeMap<NotificationBarWarningProps> = omit(notificationBarPropTypes, [
  'hideIcon',
]);

export const NotificationBarWarning = createFlavoredNotificationBar<NotificationBarWarningProps>(
  'Warning',
  propTypesWarning,
);

/**
 * FriendlyWarning
 */
export type NotificationBarFriendlyWarningProps = Omit<NotificationBarProps, 'hideIcon'>;
const propTypesFriendlyWarning: PropTypeMap<NotificationBarFriendlyWarningProps> = omit(
  notificationBarPropTypes,
  ['hideIcon'],
);

export const NotificationBarFriendlyWarning =
  createFlavoredNotificationBar<NotificationBarFriendlyWarningProps>(
    'FriendlyWarning',
    propTypesFriendlyWarning,
  );

/**
 * Blank
 */
export type NotificationBarBlankProps = Omit<NotificationBarProps, 'hideIcon'>;
const propTypesBlank: PropTypeMap<NotificationBarBlankProps> = omit(notificationBarPropTypes, [
  'hideIcon',
]);

export const NotificationBarBlank = createFlavoredNotificationBar<NotificationBarBlankProps>(
  'Blank',
  propTypesBlank,
);

/**
 * Success
 */
export type NotificationBarSuccessProps = Omit<NotificationBarProps, 'hideIcon'>;
const propTypesSuccess: PropTypeMap<NotificationBarSuccessProps> = omit(notificationBarPropTypes, [
  'hideIcon',
]);

export const NotificationBarSuccess = createFlavoredNotificationBar<NotificationBarSuccessProps>(
  'Success',
  propTypesSuccess,
);

/**
 * InfoEmphasis
 */
export type NotificationBarInfoEmphasisProps = NotificationBarProps;
const propTypesPublished: PropTypeMap<NotificationBarInfoEmphasisProps> = notificationBarPropTypes;

export const NotificationBarInfoEmphasis =
  createFlavoredNotificationBar<NotificationBarInfoEmphasisProps>(
    'InfoEmphasis',
    propTypesPublished,
  );
