import { isElement } from '@kontent-ai/DOM';
import { Stack } from '@kontent-ai/component-library/Stack';
import { ShortcutsConfig, useHotkeys } from '@kontent-ai/component-library/hooks';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { useObserveElementPresence } from '@kontent-ai/hooks';
import React from 'react';
import { createPortal } from 'react-dom';
import { HtmlSettingsPageTitle } from '../../../_shared/components/HtmlSettingsPageTitle.tsx';
import {
  NotificationBar,
  NotificationBarType,
} from '../../../_shared/components/NotificationBar.tsx';
import { HandleUnsavedFormOnNavigation } from '../../../_shared/containers/HandleUnsavedFormOnNavigation.tsx';
import { HookFormProps } from '../../../_shared/types/hookFormProps.ts';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { EnvironmentSettingsAppNames } from '../../environmentSettings/root/constants/EnvironmentSettingsAppNames.ts';
import { EntityWebhookSecret } from '../containers/settings/EntityWebhookSecret.tsx';
import { IEntityWebhookFormShape } from '../models/IEntityWebhookFormShape.type.ts';
import { EntityWebhookCustomHeaders } from './settings/EntityWebhookCustomHeaders.tsx';
import { EntityWebhookGeneralSettings } from './settings/EntityWebhookGeneralSettings.tsx';
import { EntityWebhookSettings } from './settings/EntityWebhookSettings.tsx';
import { EntityWebhookTriggers } from './settings/EntityWebhookTriggers.tsx';
import { EntityWebhookCreatorOrEditorToolbarActions } from './toolbar/EntityWebhookCreatorOrEditorToolbarActions.tsx';
import { EntityWebhookToolbarSaveButtonElementId } from './toolbar/EntityWebhookToolbarActions.tsx';

type EntityWebhookEditorFormProps = {
  readonly customName: string;
  readonly entityWebhookActionIsInProgress: boolean;
  readonly entityWebhookId: Uuid;
  readonly enabled: boolean;
  readonly hasError: boolean;
  readonly hasUnsavedChanges: boolean;
  readonly isValid: boolean;
  readonly formProps: HookFormProps<IEntityWebhookFormShape>;
  readonly onSetEntityWebhookActionIsInProgress: (entityWebhookActionIsInProgress: boolean) => void;
  readonly onCloseNotificationBar: () => void;
  readonly onSubmit: (e?: KeyboardEvent | React.BaseSyntheticEvent) => Promise<void>;
};

export const EntityWebhookEditorForm = ({
  customName,
  enabled,
  entityWebhookActionIsInProgress,
  entityWebhookId,
  formProps,
  hasError,
  hasUnsavedChanges,
  isValid,
  onSetEntityWebhookActionIsInProgress,
  onCloseNotificationBar,
  onSubmit,
}: EntityWebhookEditorFormProps) => {
  const handleUnsavedChanges = async (onSuccess: () => void, onFail: () => void) => {
    try {
      await onSubmit();

      if (isValid) {
        onSuccess();
      } else {
        onFail();
      }
    } catch {
      onFail();
    }
  };

  const hotkeysProps = useHotkeys({
    [ShortcutsConfig.ControlS]: (e: KeyboardEvent) => {
      onSubmit(e);
    },
  });

  const { current: entityWebhookToolbarSaveButtonContainerElement } = useObserveElementPresence(
    EntityWebhookToolbarSaveButtonElementId,
  );

  return (
    <>
      <HtmlSettingsPageTitle
        customName={customName}
        settingsAppName={EnvironmentSettingsAppNames.Webhooks}
      />
      <HandleUnsavedFormOnNavigation
        hasUnsavedChanges={hasUnsavedChanges}
        isBeingSaved={entityWebhookActionIsInProgress}
        onSaveChanges={handleUnsavedChanges}
      />
      <div className="canvas">
        <div
          className="canvas__workspace canvas__workspace--detached-scrollbars canvas__workspace--in-entity-webhook"
          {...getDataUiAppNameAttribute(DataUiAppName.Webhooks)}
        >
          <div className="canvas__wrapper" {...hotkeysProps}>
            <section className="canvas__content">
              {hasError && (
                <div className="canvas__notifications">
                  <NotificationBar
                    message="Webhook cannot be saved. Correct the highlighted elements first."
                    onClosePanel={onCloseNotificationBar}
                    type={NotificationBarType.Error}
                  />
                </div>
              )}

              <div className="canvas__content-pane">
                <div className="canvas__inner-section canvas__inner-section--restricted-width canvas__inner-section--centered">
                  <Stack spacing={Spacing.XL}>
                    <EntityWebhookGeneralSettings enabled={enabled} formProps={formProps} />
                    <EntityWebhookSettings>
                      <>
                        <EntityWebhookSecret enabled={enabled} formProps={formProps} />
                        <EntityWebhookCustomHeaders enabled={enabled} formProps={formProps} />
                      </>
                    </EntityWebhookSettings>
                    <EntityWebhookTriggers enabled={enabled} formProps={formProps} />
                  </Stack>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      {isElement(entityWebhookToolbarSaveButtonContainerElement) &&
        createPortal(
          <EntityWebhookCreatorOrEditorToolbarActions
            enabled={enabled}
            entityWebhookActionIsInProgress={entityWebhookActionIsInProgress}
            entityWebhookId={entityWebhookId}
            entityWebhookName={customName}
            onSetEntityWebhookActionIsInProgress={onSetEntityWebhookActionIsInProgress}
            onSubmit={onSubmit}
          />,
          entityWebhookToolbarSaveButtonContainerElement,
        )}
    </>
  );
};

EntityWebhookEditorForm.displayName = 'EntityWebhookEditorForm';
