import Immutable from 'immutable';
import React from 'react';
import { DataTable } from '../../../../_shared/components/DataTable/DataTable.tsx';
import {
  Column,
  DataTableHeadRow,
} from '../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import { DataTableRow } from '../../../../_shared/components/DataTable/DataTableRow.tsx';
import { LinkDataTableCell } from '../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { HtmlSettingsPageTitle } from '../../../../_shared/components/HtmlSettingsPageTitle.tsx';
import { OrderByDirection } from '../../../../_shared/models/OrderBy.ts';
import { ListingMessage } from '../../../../_shared/uiComponents/ListingMessage/ListingMessage.tsx';
import { Tag } from '../../../../_shared/uiComponents/Tag/Tag.tsx';
import {
  DataUiAppName,
  DataUiCollection,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { TagColor } from '../../../../data/constants/tagColor.ts';
import { EnvironmentSettingsAppNames } from '../../root/constants/EnvironmentSettingsAppNames.ts';
import {
  IWorkflowListingOperationStatus,
  WorkflowListingOperationType,
} from '../model/WorkflowListingOperation.ts';

export type WorkflowItem = {
  readonly id: Uuid;
  readonly isDefault: boolean;
  readonly name: string;
  readonly workflowEditorPath: string;
  readonly itemsUsage: number | undefined;
};

type WorkflowsListingProps = {
  readonly createNewWorkflowPath: string | undefined;
  readonly workflows: readonly WorkflowItem[];
  readonly operationStatus: IWorkflowListingOperationStatus;
  readonly onRestoreWorkflow: () => void;
};

const workflowsDataTableHeadColumns: Immutable.List<Column> = Immutable.List([
  {
    columnName: 'Name',
    orderBy: OrderByDirection.None,
  },
  {
    columnName: 'Content items',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--4',
  },
]);

export const WorkflowsListing: React.FC<WorkflowsListingProps> = ({
  workflows,
  createNewWorkflowPath,
  operationStatus,
  onRestoreWorkflow,
}) => (
  <div
    className="canvas__inner-section canvas__inner-section--restricted-width"
    {...getDataUiAppNameAttribute(DataUiAppName.MultipleWorkflows)}
  >
    <HtmlSettingsPageTitle settingsAppName={EnvironmentSettingsAppNames.MultipleWorkflows} />
    <DataTable
      createNewItemLinkPath={createNewWorkflowPath}
      isCreateNewDisabled={false}
      dataUiCollectionName={DataUiCollection.WorkflowList}
      title={
        <ListingMessage
          statusInfoMessage={{
            text: operationStatus.message,
            style: operationStatus.messageStyle,
          }}
          onUndo={
            operationStatus.operationType === WorkflowListingOperationType.Deleted
              ? onRestoreWorkflow
              : null
          }
        />
      }
      header={<DataTableHeadRow columns={workflowsDataTableHeadColumns} />}
    >
      {workflows.map((workflow: WorkflowItem) => (
        <DataTableRow id={workflow.id} key={workflow.id} dataUiObjectName={workflow.name}>
          <LinkDataTableCell linkPath={workflow.workflowEditorPath}>
            {workflow.name}
            {workflow.isDefault && (
              <Tag color={TagColor.Gray} title="Default" customClass="data-table__tag">
                Default
              </Tag>
            )}
          </LinkDataTableCell>
          <LinkDataTableCell linkPath={workflow.workflowEditorPath}>
            {workflow.itemsUsage ?? '–'}
          </LinkDataTableCell>
        </DataTableRow>
      ))}
    </DataTable>
  </div>
);

WorkflowsListing.displayName = 'WorkflowsListing';
