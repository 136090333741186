import { Typography, gridUnit, px } from '@kontent-ai/component-library/tokens';
import { DataUiCLElement, getDataUiCLElementAttribute } from '@kontent-ai/component-library/utils';
import styled from 'styled-components';

const contentMinWidth = 20 * gridUnit;

const elementAttribute = getDataUiCLElementAttribute(DataUiCLElement.NotificationBarContent);

export const StyledNotificationBarContent = styled.div.attrs(elementAttribute)`
  min-width: ${px(contentMinWidth)};
  word-break: break-word;
  ${Typography.TitleLarge};
`;
