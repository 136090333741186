import PropTypes, { ValidationMap } from 'prop-types';
import { ContentTypeConversionOptions } from '../../../../../data/models/contentModelsApp/ContentTypeConversionOptions.type.ts';
import {
  LinkedItemsRelationTypeServerModel,
  LinkedItemsTypeElementServerModel,
} from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import { QuantityOption } from '../../../../contentInventory/content/models/quantityOptions.ts';
import { getQuantityUnitOption } from '../../utils/typeElementDataConverters/utils/typeElementDataConvertersUtils.ts';
import {
  BaseTypeElementDataValidationMap,
  EmptyBaseTypeElementData,
  IBaseTypeElementData,
  ITypeElementDataWithAllowedContentTypesLimitation,
  ITypeElementDataWithNumberOfItemsLimit,
  TypeElementDataWithNumberOfItemsLimitValidationMap,
  TypeElementWithAllowedContentTypesLimitationValidationMap,
  getBaseTypeElementDataFromServerModel,
} from './types/TypeElementData.ts';
import { TypeElementConfigurability } from './types/elementConfigurabilityOptions.ts';

export enum LinkedItemsRelationType {
  LinkedItems = 'linked-items',
  Subpages = 'subpages',
}

export interface ILinkedItemsTypeElementData
  extends IBaseTypeElementData,
    ITypeElementDataWithNumberOfItemsLimit,
    ITypeElementDataWithAllowedContentTypesLimitation {
  readonly defaultValue: ReadonlyArray<Uuid>;
  readonly relationType: LinkedItemsRelationType;
}

export const EmptyLinkedItemsTypeElementData: ILinkedItemsTypeElementData = {
  ...EmptyBaseTypeElementData,
  _configurability: TypeElementConfigurability.Recommended,
  _quantityUnitOption: QuantityOption.Minimum,
  allowedTypes: [],
  defaultValue: [],
  maxItems: null,
  minItems: null,
  relationType: LinkedItemsRelationType.LinkedItems,
  type: ElementType.LinkedItems,
};

export const LinkedItemsTypeElementDataPropTypesShape = PropTypes.exact<
  ValidationMap<ILinkedItemsTypeElementData>
>({
  ...BaseTypeElementDataValidationMap,
  ...TypeElementDataWithNumberOfItemsLimitValidationMap,
  ...TypeElementWithAllowedContentTypesLimitationValidationMap,
  defaultValue: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  relationType: PropTypes.oneOf(Object.values(LinkedItemsRelationType)).isRequired,
});

function getElementType(
  serverModel: LinkedItemsTypeElementServerModel,
  conversionOptions: ContentTypeConversionOptions,
): ElementType.Subpages | ElementType.LinkedItems {
  if (!conversionOptions.subpagesElementEnabled) {
    return ElementType.LinkedItems;
  }

  return serverModel.relationType === LinkedItemsRelationTypeServerModel.Subpages
    ? ElementType.Subpages
    : ElementType.LinkedItems;
}

export function getLinkedItemsTypeElementDataFromServerModel(
  serverModel: LinkedItemsTypeElementServerModel,
  conversionOptions: ContentTypeConversionOptions,
): ILinkedItemsTypeElementData {
  const quantityUnitOption = getQuantityUnitOption(serverModel.minItems, serverModel.maxItems);

  return {
    ...getBaseTypeElementDataFromServerModel(serverModel),
    _configurability: EmptyLinkedItemsTypeElementData._configurability,
    _quantityUnitOption: quantityUnitOption,
    allowedTypes: serverModel.allowedTypes,
    defaultValue: serverModel.default?.global.value.map(({ _id }) => _id) ?? [],
    maxItems: serverModel.maxItems
      ? serverModel.maxItems.toString()
      : EmptyLinkedItemsTypeElementData.maxItems,
    minItems: serverModel.minItems
      ? serverModel.minItems.toString()
      : EmptyLinkedItemsTypeElementData.minItems,
    relationType:
      serverModel.relationType === LinkedItemsRelationTypeServerModel.Subpages
        ? LinkedItemsRelationType.Subpages
        : LinkedItemsRelationType.LinkedItems,
    type: getElementType(serverModel, conversionOptions),
  };
}
