import { Avatar, AvatarSize } from '@kontent-ai/component-library/Avatar';
import { useAttachRef, usePrevious } from '@kontent-ai/hooks';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useOnClickOutside } from '../../../../../../_shared/hooks/useOnClickOutside.ts';
import { IUserInfo } from '../../../../../../_shared/models/UserInfo.ts';
import {
  DataUiCollection,
  DataUiElement,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  IForwardedRefProps,
  forwardRef,
  forwardedRefProps,
} from '../../../../../../_shared/utils/forwardedRefProps.tsx';
import {
  formatUserName,
  getExistingUserNewColorGradient,
  getUserInitials,
} from '../../../../../../_shared/utils/usersUtils.ts';
import {
  CommentThreadItemType,
  ICommentThreadItemContentModel,
} from '../../../../models/comments/CommentThreadItem.ts';
import { ICommentThread } from '../../../../models/comments/CommentThreads.ts';
import { CommentThreadCssClass } from '../../constants/uiConstants.ts';
import { NewCommentThreadItem } from './threadItem/NewCommentThreadItem.tsx';

const isThreadType = (type?: string): type is CommentThreadItemType => !!type;

export interface IUnsavedInlineCommentThreadOwnProps {
  readonly commentThread: ICommentThread;
  readonly onFocused: () => void;
}

export interface IUnsavedInlineCommentThreadStateProps {
  readonly currentUser: IUserInfo;
  readonly type: string | undefined;
  readonly isFocused: boolean;
}

export interface IUnsavedInlineCommentThreadDispatchProps {
  readonly onBlur: () => void;
  readonly onCancel: () => void;
  readonly onSubmit: (
    type: CommentThreadItemType,
    content: ICommentThreadItemContentModel,
  ) => Promise<void>;
  readonly onFocus: () => void;
}

interface IUnsavedInlineCommentThreadProps
  extends IUnsavedInlineCommentThreadStateProps,
    IUnsavedInlineCommentThreadDispatchProps,
    IUnsavedInlineCommentThreadOwnProps,
    IForwardedRefProps<HTMLDivElement> {}

const UnsavedInlineCommentThread: React.FC<IUnsavedInlineCommentThreadProps> = ({
  commentThread,
  currentUser,
  forwardedRef,
  isFocused,
  onBlur,
  onCancel,
  onFocus,
  onFocused,
  onSubmit,
  type,
}) => {
  const commentEditorRef = React.useRef<NewCommentThreadItem>(null);
  const { refObject, refToForward } = useAttachRef(forwardedRef);

  const previousIsFocused = usePrevious(isFocused);

  useEffect(() => {
    if (!previousIsFocused && isFocused) {
      onFocused();
    }
  }, [previousIsFocused, isFocused, onFocused]);

  const onClickOutside = (): void => {
    if (!commentThread.isSubmitting) {
      const commentEditor = commentEditorRef.current;

      if (isFocused) {
        onBlur();
      }

      if (commentEditor && !commentEditor.isTextValid()) {
        onCancel();
      }
    }
  };

  useOnClickOutside(refObject, onClickOutside);

  return (
    <div
      id={commentThread.id}
      className={classNames(CommentThreadCssClass, {
        'comment-thread--has-focus': isFocused,
      })}
      onClick={onFocus}
      ref={refToForward}
      {...getDataUiCollectionAttribute(DataUiCollection.CommentThreadItems)}
      {...getDataUiElementAttribute(DataUiElement.CommentThread)}
    >
      <div className="comment-thread__comment comment">
        <div className="comment__header">
          <Avatar
            backgroundGradient={getExistingUserNewColorGradient(currentUser)}
            label={formatUserName(currentUser)}
            initials={getUserInitials(currentUser)}
            size={AvatarSize.S}
          />
          <div className="comment__author-timestamp">
            <div className="comment__author">{formatUserName(currentUser)}</div>
            <div className="comment__info">{type}</div>
          </div>
        </div>

        {isThreadType(type) && (
          <NewCommentThreadItem
            elementSegment={commentThread.elementSegment}
            inputValue=""
            isEditing
            autoFocus
            isSubmitting={commentThread.isSubmitting}
            onCancel={onCancel}
            onSubmit={onSubmit}
            ref={commentEditorRef}
            type={type}
          />
        )}
      </div>
    </div>
  );
};

UnsavedInlineCommentThread.displayName = 'UnsavedInlineCommentThread';
UnsavedInlineCommentThread.propTypes = {
  ...forwardedRefProps,
  currentUser: PropTypes.object.isRequired as any,
  commentThread: PropTypes.object.isRequired as any,
  type: PropTypes.string,
  isFocused: PropTypes.bool.isRequired,

  onBlur: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onFocused: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const UnsavedInlineCommentThreadRFC = forwardRef(UnsavedInlineCommentThread);
export { UnsavedInlineCommentThreadRFC as UnsavedInlineCommentThread };
