import { ComponentLibraryGlobals } from '@kontent-ai/component-library/globals';
import { isAbortedXMLHttpRequest } from '@kontent-ai/errors';
import { ICurrentUserInfo } from '../../data/models/user/CurrentUserInfo.ts';
import { isCampaignRelatedCookieAvailable, trackUserCampaign } from './campaignUtils.ts';
import { logError, trackError } from './logError.ts';
import { createUrlFactory, registerUrlFactory as regUrlFactory } from './urlFactory.ts';

export const registerUrlFactory = () => {
  regUrlFactory(createUrlFactory(self._envConfig));
};

export function logUnhandledPromiseRejection(): void {
  // Handles uncaught error within promises
  self.addEventListener('unhandledrejection', (e: PromiseRejectionEvent): void => {
    if (isAbortedXMLHttpRequest(e.reason)) {
      e.preventDefault();
      return;
    }

    if (e.reason) {
      trackError('Unhandled promise rejection.', e.reason);
    }
  });
}

export function ignoreInsertKey(): void {
  window.addEventListener('keydown', (ev) => {
    // Disable Insert key switching as it is not compatible with rich text editor, always keep regular collapsed cursor
    // The only thing we can be certain of is that a first visit to the UI has the insert disabled by default
    // Refreshes over the same URL may remember the insert key state (current Chrome behavior)
    // that is why we are not able to track it as a toggle because we don't know the initial state
    if (ev.key && ev.key.toLowerCase() === 'insert') {
      ev.preventDefault();
    }
  });
}

export function trackCampaign(userInfo: ICurrentUserInfo): void {
  if (isCampaignRelatedCookieAvailable(document.cookie)) {
    trackUserCampaign(userInfo.userId, document.cookie);
  }
}

export function gtmTrackUser(userInfo: ICurrentUserInfo): void {
  if (
    window.dataLayer &&
    typeof window.dataLayer.push === 'function' &&
    self._envConfig.isGtmEnabled
  ) {
    window.dataLayer.push({
      userID: userInfo.userId,
    });
  }
}

export const disableBrowserSpecificFeatures = () => {
  // Disable firefox UI for tables.
  document.execCommand('enableObjectResizing', false);
};

export const setupComponentLibrary = (): void => {
  ComponentLibraryGlobals.assign({ logError });
};
