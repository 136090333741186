import { Paper, PaperLevel } from '@kontent-ai/component-library/Paper';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import {
  PortalContainerContext,
  PortalContainerContextProvider,
} from '@kontent-ai/component-library/context';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { identity } from '@kontent-ai/utils';
import { Placement } from '@popperjs/core';
import { ReactNode, forwardRef, useContext, useImperativeHandle, useState } from 'react';
import { ArrowSize } from '../../../../../../../component-library/components/Dialogs/Popover/ArrowSizeEnum.ts';
import {
  IAdjustTippyOptions,
  usePopover,
} from '../../../../../../../component-library/components/Dialogs/Popover/usePopover.tsx';
import {
  createAddArrow,
  createAddFlipping,
  createAddOffset,
  createAddPreventOverflow,
} from '../../../../../../../component-library/components/Dialogs/Popover/utils/tippyOptionsUtils.ts';
import { IconName } from '../../../../../../_shared/constants/iconEnumGenerated.ts';
import { Icon } from '../../../../../../_shared/uiComponents/Icon/Icon.tsx';
import {
  DataUiRteAction,
  getDataUiActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { compose } from '../../../../../../_shared/utils/func/compose.ts';
import { getToolbarPopperOffset } from '../../utils/toolbarUtils.ts';
import { Resettable } from './BlockToolbar.tsx';

interface IBlockToolbarButtonProps {
  readonly buttonDescription: string;
  readonly buttonText?: string;
  readonly children: ReactNode;
  readonly disabled?: boolean;
  readonly iconName?: IconName;
  readonly toolbarPlacement: 'top-start' | 'bottom-start' | 'right';
  readonly fallbackToolbarPlacements?: ReadonlyArray<Placement>;
  readonly uiAction: DataUiRteAction;
}

export const BlockToolbarButton = forwardRef<Resettable, IBlockToolbarButtonProps>(
  (
    {
      buttonDescription,
      buttonText,
      children,
      disabled,
      fallbackToolbarPlacements,
      iconName,
      toolbarPlacement,
      uiAction,
    },
    forwardedRef,
  ) => {
    const { portalContainerRef } = useContext(PortalContainerContext);

    const [isToolbarVisible, setIsToolbarVisible] = useState(false);

    useImperativeHandle(forwardedRef, () => ({
      reset: closeToolbar,
    }));

    const adjustTippyOptions: IAdjustTippyOptions = compose(
      fallbackToolbarPlacements
        ? createAddFlipping({ fallbackPlacements: fallbackToolbarPlacements })
        : identity,
      createAddPreventOverflow({
        mainAxis: false,
        altAxis: true,
        padding: {
          bottom: ArrowSize.S / 2,
          right: ArrowSize.S / 2,
          top: ArrowSize.S / 2,
        },
      }),
      createAddOffset(getToolbarPopperOffset),
      createAddArrow(Spacing.S),
    );

    const closeToolbar = (): void => {
      setIsToolbarVisible(false);
    };

    const openToolbar = (): void => {
      setIsToolbarVisible(true);
    };

    const { Popover, targetProps, popoverProps } = usePopover({
      adjustTippyOptions,
      disabledFocusLock: true,
      isOpen: isToolbarVisible,
      placement: toolbarPlacement,
      shouldCloseOnBlur: false,
      onClose: closeToolbar,
    });

    return (
      <>
        <Tooltip tooltipText={buttonDescription} placement="bottom">
          <span>
            {' '}
            {/* Wrapper needed so that Tooltip works when button is disabled */}
            <button
              onClick={disabled || isToolbarVisible ? closeToolbar : openToolbar}
              className="block-toolbar__button"
              disabled={disabled}
              {...targetProps}
              {...getDataUiActionAttribute(uiAction)}
            >
              <span className="block-toolbar__button-content">
                {iconName ? (
                  <span className="block-toolbar__button-icon">
                    <Icon iconName={iconName} screenReaderText={buttonDescription} />
                  </span>
                ) : (
                  <>{buttonText}</>
                )}
              </span>
            </button>
          </span>
        </Tooltip>
        {isToolbarVisible && (
          <Popover {...popoverProps} arrowSize={ArrowSize.S}>
            <Paper level={PaperLevel.Popout}>
              {/* Popover has its own portal context, so we need to re-subscribe to the root portal context in order for tooltips to work properly. */}
              <PortalContainerContextProvider portalContainerRef={portalContainerRef}>
                {children}
              </PortalContainerContextProvider>
            </Paper>
          </Popover>
        )}
      </>
    );
  },
);

BlockToolbarButton.displayName = 'BlockToolbarButton';
