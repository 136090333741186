import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { createFormValidationResolver } from '../../../../../../../_shared/utils/validation/createFormValidationResolver.ts';
import { taskFormChanged, tasksFormReset } from '../../../actions/contentItemEditingActions.ts';
import {
  TaskForm,
  TaskFormProps,
  TaskFormSharedProps,
} from '../../../components/details/Tasks/TaskForm.tsx';
import { ITaskFormShape } from '../../../models/ITaskFormShape.type.ts';
import { getTaskAssigneesWithoutAccess } from '../../../selectors/getTaskAssigneesWithoutAccess.ts';
import { taskFormValidationConfig } from '../../../validation/taskFormValidation.ts';
import { useIsWarningDisplayed } from '../../hooks/useIsWarningDisplayed.ts';

export type TaskFormContainerProps = {
  readonly defaultValues: ITaskFormShape;
  readonly hasFormUnsavedChanges: (values: ITaskFormShape) => boolean;
  readonly taskId: Uuid;
  readonly onCancel: () => void;
  readonly onConfirm: (values: ITaskFormShape) => Promise<void>;
  readonly renderExtraButton?: () => React.ReactNode;
};

type Props = TaskFormProps & TaskFormSharedProps & TaskFormContainerProps;

const TaskFormContainer: React.FC<Props> = ({
  allActiveContributors,
  defaultValues,
  hasFormUnsavedChanges,
  isTaskSubmitting,
  isTaskArchiving,
  onCancel,
  onConfirm,
  renderExtraButton,
  submitButtonName,
  submitButtonText,
  taskId,
}) => {
  const currentUserId = useSelector((s) => s.data.user.info.userId);
  const unsavedValues = useSelector(
    (s) => s.contentApp.editorUi.editingActions.forms.tasks.get(taskId)?.unsavedValues,
  );

  const formProps = useForm<ITaskFormShape>({
    defaultValues: unsavedValues || defaultValues,
    resolver: createFormValidationResolver(taskFormValidationConfig, {}),
  });

  const { handleSubmit, watch } = formProps;
  const dispatch = useDispatch();

  useEffect(() => {
    const subscription = watch((formValues: ITaskFormShape) => {
      if (hasFormUnsavedChanges(formValues)) {
        dispatch(taskFormChanged(taskId, formValues));
      } else {
        dispatch(tasksFormReset(taskId));
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, hasFormUnsavedChanges, taskId]);

  const formValues = watch();
  const hasUnsavedChanges = hasFormUnsavedChanges(formValues);
  const isWarningDisplayed = useIsWarningDisplayed(hasUnsavedChanges);
  const submitForm = handleSubmit(onConfirm);
  const assignees = allActiveContributors.filter(
    (contributor) => contributor.userId === formValues.assignedContributorId,
  );

  const assigneesWithoutAccess = useSelector((s) => getTaskAssigneesWithoutAccess(s, assignees));

  const resetForm = (): void => {
    onCancel();
    dispatch(tasksFormReset(taskId));
  };

  return (
    <TaskForm
      allActiveContributors={allActiveContributors}
      assigneesWithoutAccess={assigneesWithoutAccess}
      currentUserId={currentUserId}
      formProps={formProps}
      isTaskSubmitting={isTaskSubmitting}
      isTaskArchiving={isTaskArchiving}
      isWarningDisplayed={isWarningDisplayed}
      onReset={resetForm}
      onSubmit={submitForm}
      submitButtonName={submitButtonName}
      submitButtonText={submitButtonText}
      renderExtraButton={renderExtraButton}
    />
  );
};

TaskFormContainer.displayName = 'TaskFormContainer';
export { TaskFormContainer as TaskForm };
