import { noOperation } from '@kontent-ai/utils';
import classNames from 'classnames';
import React from 'react';
import { OptionsList } from '../../../../../../../_shared/components/Options/OptionsList.tsx';
import { IGetOptionClassNameInput } from '../../../../../../../_shared/components/Options/createOptionsListComponent.tsx';
import { multipleChoiceModeToOptionMode } from '../../../../../../../_shared/models/utils/multipleChoiceModeToOptionMode.ts';
import { getOrderedOptionsList } from '../../../../../../../_shared/selectors/multipleChoiceOptions.ts';
import {
  IMultipleChoiceTypeElement,
  MultipleChoiceTypeElementPropTypes,
} from '../../../../../../contentInventory/content/models/contentTypeElements/MultipleChoiceTypeElement.ts';
import {
  IMultipleChoiceItemElement,
  MultipleChoiceItemElementPropTypes,
} from '../../../../../models/contentItemElements/MultipleChoiceItemElement.ts';
import { MultipleChoiceInput } from '../../../../ContentItemEditing/components/elements/multipleChoice/MultipleChoiceInput.tsx';
import { IRevisionDiffElementProps } from '../../../types/IRevisionDiffElementProps.type.ts';

const getDiffedOptionClassNameCreator =
  (originalElementData: IMultipleChoiceItemElement) =>
  (input: IGetOptionClassNameInput): string => {
    const { isSelected, option } = input;

    const isAdded = isSelected && !originalElementData.options.includes(option.id);
    const isRemoved = !isSelected && originalElementData.options.includes(option.id);

    return classNames({
      'option--is-added': isAdded,
      'option--is-removed': isRemoved,
    });
  };

interface IMultipleChoiceDiffProps extends IRevisionDiffElementProps<IMultipleChoiceItemElement> {
  readonly typeElement: IMultipleChoiceTypeElement;
}

const propTypes: PropTypeMap<IMultipleChoiceDiffProps> = {
  elementData: MultipleChoiceItemElementPropTypes.isRequired,
  originalElementData: MultipleChoiceItemElementPropTypes,
  typeElement: MultipleChoiceTypeElementPropTypes.isRequired,
};

const MultipleChoiceDiff: React.FC<IMultipleChoiceDiffProps> = (props) => {
  const { elementData, originalElementData, typeElement } = props;

  const getOptionClassName = originalElementData
    ? getDiffedOptionClassNameCreator(originalElementData)
    : undefined;

  if (originalElementData) {
    const orderedOptions = getOrderedOptionsList(typeElement.options, typeElement.optionsOrder);
    return (
      <OptionsList
        disabled
        getOptionClassName={getOptionClassName}
        isItemElement
        mode={multipleChoiceModeToOptionMode(typeElement.mode)}
        options={orderedOptions}
        selectedOptions={elementData.options}
      />
    );
  }

  return (
    <MultipleChoiceInput
      autoFocus={false}
      disabled
      defaultValue={typeElement.defaultValue}
      elementData={elementData}
      typeElement={typeElement}
      onSelectedOptionsUpdate={noOperation}
    />
  );
};

MultipleChoiceDiff.displayName = 'MultipleChoiceDiff';
MultipleChoiceDiff.propTypes = propTypes;

export { MultipleChoiceDiff };
