import React, { ReactNode } from 'react';
import { useHistory, useParams } from 'react-router';
import { EnsureValidState } from '../../../../_shared/components/EnsureValidState.tsx';
import { SubscriptionEnvironmentSettingsRouteParams } from '../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { isUuid } from '../../../../_shared/utils/validation/typeValidators.ts';
import { getCurrentProject } from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { ensureSubscriptionCurrentProject } from '../actions/thunkSubscriptionManagementActions.ts';

type Props = {
  readonly children: ReactNode;
};

export const EnsureSubscriptionCurrentProject: React.FC<Props> = ({ children }) => {
  const history = useHistory();
  const { projectId } = useParams<SubscriptionEnvironmentSettingsRouteParams>();

  const isStateEnsured = useSelector((state) => {
    const currentProject = getCurrentProject(state);

    return isUuid(currentProject.projectId) && currentProject.projectId === projectId;
  });

  const dispatch = useDispatch();
  const onRouteEntered = () => dispatch(ensureSubscriptionCurrentProject(projectId, history));

  return (
    <EnsureValidState isStateEnsured={isStateEnsured} onRouteEntered={onRouteEntered}>
      {children}
    </EnsureValidState>
  );
};

EnsureSubscriptionCurrentProject.displayName = 'EnsureSubscriptionCurrentProject';
