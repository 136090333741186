import { getDataUiComponentAttribute } from '@kontent-ai/component-library/utils';
import { AriaDialogProps } from '@react-types/dialog';
import PropTypes from 'prop-types';
import React, { WeakValidationMap } from 'react';
import {
  BaseModalDialogComponent,
  IBaseModalDialogProps,
  baseModalDialogPropTypes,
} from './BaseModalDialogComponent.tsx';

export interface IModalDialogProps extends IBaseModalDialogProps {
  readonly minWidth?: string;
  readonly minHeight?: string;
}

const propTypes: Required<WeakValidationMap<Omit<IModalDialogProps, keyof AriaDialogProps>>> = {
  ...baseModalDialogPropTypes,
  minWidth: PropTypes.string,
  minHeight: PropTypes.string,
};

export const ModalDialog = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<IModalDialogProps>
>(({ children, ...props }, forwardedRef) => (
  <BaseModalDialogComponent
    ref={forwardedRef}
    {...props}
    {...getDataUiComponentAttribute(ModalDialog)}
  >
    {children}
  </BaseModalDialogComponent>
));

ModalDialog.displayName = 'ModalDialog';
ModalDialog.propTypes = propTypes;
