import { Action } from '../../../@types/Action.type.ts';
import {
  WebSpotlight_LocalizedRoute_Left,
  WebSpotlight_Node_Selected,
  WebSpotlight_SetSharedPreviewData,
} from '../constants/webSpotlightActionTypes.ts';

export interface ISharedPreviewData {
  readonly url: string | null;
  readonly width: number | null;
  readonly height: number | null;
}

export const sharedPreviewData = (state = null, action: Action): ISharedPreviewData | null => {
  switch (action.type) {
    case WebSpotlight_Node_Selected:
    case WebSpotlight_LocalizedRoute_Left:
      return null;

    case WebSpotlight_SetSharedPreviewData:
      return action.payload;

    default:
      return state;
  }
};
