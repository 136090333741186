import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';

interface IDeps {
  readonly loadAssetType: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadCollections: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadSpaces: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadTaxonomyGroups: (abortSignal?: AbortSignal) => ThunkPromise;
}

export const createEnsureAssetLibraryRelatedDataAction =
  (deps: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      data: { assetTypes, collections, taxonomyGroups },
    } = getState();

    const areAssetTypesEmpty = assetTypes.loadingStatus === LoadingStatus.InitialEmpty;
    const areCollectionsEmpty = collections.loadingStatus === LoadingStatus.InitialEmpty;
    const areTaxonomyGroupsEmpty = taxonomyGroups.loadingStatus === LoadingStatus.InitialEmpty;

    const shouldLoadData = areAssetTypesEmpty || areCollectionsEmpty || areTaxonomyGroupsEmpty;

    if (!shouldLoadData) {
      return;
    }

    await Promise.all([
      dispatch(deps.loadAssetType(abortSignal)),
      dispatch(deps.loadCollections(abortSignal)),
      dispatch(deps.loadSpaces(abortSignal)),
      dispatch(deps.loadTaxonomyGroups(abortSignal)),
    ]);
  };
