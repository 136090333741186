import React, { memo } from 'react';
import { DataUiInput } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { createEditorWithPlugins } from '../../editorCore/Editor.composition.tsx';
import { EditorProps } from '../../editorCore/types/Editor.composition.type.ts';
import { useDiff } from '../../plugins/diff/DiffPlugin.tsx';
import { useComposedViewer } from './SimpleMultilineTextViewer.tsx';

const useComposedDiffViewer = () => createEditorWithPlugins(useComposedViewer(), useDiff);

export type SimpleMultilineTextDiffProps = EditorProps<typeof useComposedDiffViewer>;

export const SimpleMultilineTextDiff: React.FC<SimpleMultilineTextDiffProps> = memo((props) => {
  const { ComposedEditor } = useComposedDiffViewer();

  return (
    <ComposedEditor dataUiInput={props.dataUiInput ?? DataUiInput.ItemTextElement} {...props} />
  );
});

SimpleMultilineTextDiff.displayName = 'SimpleMultilineTextDiff';
