import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { logError } from '../../../../_shared/utils/logError.ts';
import { ICollection } from '../../../../data/models/collections/Collection.ts';

export const getCollection = (state: IStore, collectionId: Uuid): ICollection | undefined => {
  const collection = state.data.collections.byId.get(collectionId);

  if (!collection) {
    logError(`Collection with id ${collectionId} was not found.`);
  }

  return collection;
};
