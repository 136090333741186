import PropTypes, { ValidationMap } from 'prop-types';
import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  emptyEditorState,
  emptyJsonValue,
} from '../../../richText/utils/general/editorEmptyValues.ts';
import { UrlSlugMode } from '../../constants/urlSlugMode.ts';
import {
  BaseTextItemElementValidationMap,
  IBaseTextItemElement,
  IBaseTextItemElementValue,
} from './IBaseTextItemElement.ts';

interface IUrlSlugItemElementValue extends IBaseTextItemElementValue {
  readonly mode: string;
}

export interface IUrlSlugItemElement extends IUrlSlugItemElementValue, IBaseTextItemElement {}

export const UrlSlugItemElementPropTypesShape = PropTypes.shape<ValidationMap<IUrlSlugItemElement>>(
  {
    ...BaseTextItemElementValidationMap,
    mode: PropTypes.string.isRequired,
  },
);

export const EmptyUrlSlugItemElementValue: IUrlSlugItemElementValue = {
  _editorState: emptyEditorState,
  jsonValue: emptyJsonValue,
  mode: UrlSlugMode.Auto,
};

export const EmptyUrlSlugItemElement: IUrlSlugItemElement = {
  elementId: '',
  lastModifiedAt: null,
  lastModifiedBy: null,
  type: ElementType.UrlSlug,
  ...EmptyUrlSlugItemElementValue,
};

export const newUrlSlugItemElement = (
  params: Partial<IUrlSlugItemElement>,
): IUrlSlugItemElement => ({
  ...EmptyUrlSlugItemElement,
  ...params,
});
