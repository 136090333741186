import PropTypes from 'prop-types';
import React from 'react';

function splitLines(text: string): ReadonlyArray<string> {
  const lines = (text || '').split(/[\r\n]/);
  return lines;
}

interface ITextToProps {
  readonly text: string | null;
  readonly className?: string;
}

const propTypes: PropTypesShape<ITextToProps> = {
  text: PropTypes.string,
  className: PropTypes.string,
};

export const TextToParagraphs: React.FC<ITextToProps> = (props) => {
  const lines = splitLines(props.text || '');
  return (
    <>
      {lines.map((paragraph, position) => (
        <p key={position} className={props.className}>
          {paragraph}
        </p>
      ))}
    </>
  );
};
TextToParagraphs.displayName = 'TextToParagraphs';
TextToParagraphs.propTypes = propTypes;

export const TextToParagraph: React.FC<ITextToProps> = (props) => {
  const lines = splitLines(props.text || '');
  return (
    <p className={props.className}>
      {lines.map((line, position) => {
        const isFirst = !position;
        return (
          <React.Fragment key={position}>
            {!isFirst && <br />}
            {line}
          </React.Fragment>
        );
      })}
    </p>
  );
};
TextToParagraph.displayName = 'TextToParagraph';
TextToParagraph.propTypes = propTypes;
