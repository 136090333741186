import PropTypes from 'prop-types';
import React from 'react';
import {
  ITaxonomyTypeElement,
  TaxonomyTypeElementPropTypes,
} from '../../../../contentInventory/content/models/contentTypeElements/TaxonomyTypeElement.ts';
import {
  ITaxonomyItemElement,
  TaxonomyItemElementPropTypes,
} from '../../../models/contentItemElements/TaxonomyItemElement.ts';
import { TaxonomyDiff } from '../../Revisions/components/elements/subelements/TaxonomyDiff.tsx';
import { ExpandedItemElement } from '../containers/ExpandedItemElement.tsx';
import { ExpandedItemElementDataProps } from './types/expandedItemElement.type.ts';

type ExpandedTaxonomyElementProps = ExpandedItemElementDataProps<
  ITaxonomyTypeElement,
  ITaxonomyItemElement
>;

const propTypes: PropTypeMap<ExpandedTaxonomyElementProps> = {
  className: PropTypes.string,
  elementData: TaxonomyItemElementPropTypes.isRequired,
  originalElementData: TaxonomyItemElementPropTypes,
  typeElement: TaxonomyTypeElementPropTypes.isRequired,
};

export const ExpandedTaxonomyElement: React.FC<ExpandedTaxonomyElementProps> = ({
  className,
  elementData,
  originalElementData,
  typeElement,
}) => {
  return (
    <ExpandedItemElement
      className={className}
      disabled
      hideValidationStatus
      typeElement={typeElement}
    >
      <TaxonomyDiff
        elementData={elementData}
        originalElementData={originalElementData}
        typeElement={typeElement}
      />
    </ExpandedItemElement>
  );
};

ExpandedTaxonomyElement.displayName = 'ExpandedTaxonomyElement';
ExpandedTaxonomyElement.propTypes = propTypes;
