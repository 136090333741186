import { combineReducers } from '@reduxjs/toolkit';
import { contentStatusUnresolvedCommentCounts } from '../../applications/unifiedMissionControl/contentStatus/reducers/contentStatusComments/contentStatusUnresolvedCommentCounts.ts';
import { contentStatusContentItems } from '../../applications/unifiedMissionControl/contentStatus/reducers/contentStatusContentItems/contentStatusContentItems.ts';
import { contentStatusUnresolvedTaskCounts } from '../../applications/unifiedMissionControl/contentStatus/reducers/contentStatusTasks/contentStatusUnresolvedTaskCounts.ts';
import { apiKeys } from './apiKeys/apiKeys.ts';
import { assetFolders } from './assetFolders/assetFolders.ts';
import { assetRenditions } from './assetRenditions/assetRenditions.ts';
import { assetTypes } from './assetTypes/assetTypes.ts';
import { assets } from './assets/assets.ts';
import { assignedContentItems } from './assignedContentItems/assignedContentItems.ts';
import { auditLog } from './auditLog/auditLog.ts';
import { collections } from './collections/collections.ts';
import { contentTypeComponentsUsage } from './contentTypeComponentsUsage/contentTypeComponentsUsage.ts';
import { contentTypeUsages } from './contentTypeUsages/contentTypeUsages.ts';
import { contentTypes } from './contentTypes/contentTypes.ts';
import { entityWebhooks } from './entityWebhooks/entityWebhooks.ts';
import { filters } from './filters/filters.ts';
import { languages } from './languages/languages.ts';
import { latestProductUpdateInfo } from './latestProductUpdate/latestProductUpdateInfo.ts';
import { listingContentItems } from './listingContentItems/listingContentItems.ts';
import { listingContentItemsWithAllVariants } from './listingContentItemsWithAllVariants/listingContentItemsWithAllVariants.ts';
import { plans } from './plans/plans.ts';
import { projectContainerActiveUsers } from './projectContainerActiveUsers/projectContainerActiveUsers.ts';
import { projects } from './projects/projects.ts';
import { recentlyEditedContentItems } from './recentlyEditedContentItems/recentlyEditedContentItems.ts';
import { roles } from './roles/roles.ts';
import { sitemap } from './sitemap/sitemap.ts';
import { snippets } from './snippets/snippets.ts';
import { spaces } from './spaces/spaces.ts';
import { subscriptions } from './subscriptions/subscriptions.ts';
import { tasks } from './tasks/tasks.ts';
import { taxonomyGroups } from './taxonomyGroups/taxonomyGroups.ts';
import { user } from './user/user.ts';
import { users } from './users/users.ts';
import { webhooks } from './webhooks/webhooks.ts';
import { workflows } from './workflow/workflows.ts';
import { yourTasksContentItems } from './yourTasksContentItems/yourTasksContentItems.ts';

export const data = combineReducers({
  apiKeys,
  assetFolders,
  assetRenditions,
  assetTypes,
  assets,
  assignedContentItems,
  auditLog,
  collections,
  contentTypeComponentsUsage,
  contentTypeUsages,
  contentTypes,
  entityWebhooks,
  filters,
  languages,
  latestProductUpdateInfo,
  listingContentItems,
  listingContentItemsWithAllVariants,
  plans,
  contentStatusCommentCounts: contentStatusUnresolvedCommentCounts,
  contentStatusContentItems,
  contentStatusTaskCounts: contentStatusUnresolvedTaskCounts,
  projectContainerActiveUsers,
  projects,
  recentlyEditedContentItems,
  roles,
  sitemap,
  snippets,
  spaces,
  subscriptions,
  tasks,
  taxonomyGroups,
  user,
  users,
  webhooks,
  workflows,
  yourTasksContentItems,
});
