import {
  ICollectionFilterOption,
  IEnvironmentFilterOption,
  ILanguageFilterOption,
  IProjectFilterOption,
  IRoleFilterOption,
  IStatusFilterOption,
} from '../models/UserListingFilterOption.type.ts';

export enum ByStatus {
  Active = 'active',
  Deactivated = 'deactivated',
  ActiveAndDeactivated = 'activeAndDeactivated',
}

export const anyRoleOption: IRoleFilterOption = {
  id: 'any-role',
  label: 'Any role',
};

export const anyCollectionOption: ICollectionFilterOption = {
  id: 'any-collection',
  label: 'Any collection',
};

export const allCollectionsOption: ICollectionFilterOption = {
  id: 'all-collections',
  label: 'All collections',
};

export const anyLanguageOption: ILanguageFilterOption = {
  id: 'any-language',
  label: 'Any language',
};

export const allLanguagesOption: ILanguageFilterOption = {
  id: 'all-languages',
  label: 'All languages',
};

export const anyProjectOption: IProjectFilterOption = {
  id: 'any-project',
  label: 'Any project',
};

export const anyEnvironmentOption: IEnvironmentFilterOption = {
  id: 'any-environment',
  label: 'Any environment',
};

export const activeUsersOption: IStatusFilterOption = {
  id: ByStatus.Active,
  label: 'Active users',
};

export const deactivatedUsersOption: IStatusFilterOption = {
  id: ByStatus.Deactivated,
  label: 'Deactivated users',
};

export const activeAndDeactivatedUsersOption: IStatusFilterOption = {
  id: ByStatus.ActiveAndDeactivated,
  label: 'Active and deactivated users',
};

export const userStatusOptions: ReadonlyArray<IStatusFilterOption> = [
  activeUsersOption,
  deactivatedUsersOption,
  activeAndDeactivatedUsersOption,
] as const;
