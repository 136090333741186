import { Box } from '@kontent-ai/component-library/Box';
import PropTypes from 'prop-types';
import React from 'react';
import {
  AnimatedChevron,
  RestDirection,
} from '../../../uiComponents/AnimatedChevron/AnimatedChevron.tsx';
import { SquareButtonShell } from '../../../uiComponents/Button/SquareButtonShell.tsx';
import { ButtonStyle } from '../../../uiComponents/Button/buttonStyle.ts';
import { SquareButtonSize } from '../../../uiComponents/Button/squareButtonSize.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';

interface IBarItemExpandCollapseActionProps {
  readonly disabled?: boolean;
  readonly isCollapsed: boolean;
  readonly onExpand: () => void;
  readonly onCollapse: () => void;
}

const propTypes: PropTypesShape<IBarItemExpandCollapseActionProps> = {
  disabled: PropTypes.bool,
  isCollapsed: PropTypes.bool.isRequired,
  onExpand: PropTypes.func.isRequired,
  onCollapse: PropTypes.func.isRequired,
};

export const BarItemExpandCollapseAction: React.FC<IBarItemExpandCollapseActionProps> = ({
  disabled,
  isCollapsed,
  onExpand,
  onCollapse,
}) => (
  <Box className="bar-item__action">
    <SquareButtonShell
      disabled={disabled}
      onClick={disabled ? undefined : isCollapsed ? onExpand : onCollapse}
      screenReaderText={isCollapsed ? 'Expand' : 'Collapse'}
      style={ButtonStyle.Quinary}
      size={SquareButtonSize.Quinary}
      {...getDataUiActionAttribute(isCollapsed ? DataUiAction.Expand : DataUiAction.Collapse)}
    >
      <AnimatedChevron isTurned={!isCollapsed} restDirection={RestDirection.Down} />
    </SquareButtonShell>
  </Box>
);

BarItemExpandCollapseAction.displayName = 'BarItemExpandCollapseAction';
BarItemExpandCollapseAction.propTypes = propTypes;
