import Immutable from 'immutable';
import { Action } from '../../../../../../@types/Action.type.ts';
import { ContentItemSidebarEditableSection } from '../../../../features/ContentItemEditing/constants/ContentItemSidebarEditableSection.ts';
import {
  ContentItemEditing_CreateTask_Failed,
  ContentItemEditing_CreateTask_Finished,
  ContentItemEditing_CreateTask_Started,
  ContentItemEditing_Init_Started,
  ContentItemEditing_Sidebar_Section_Focused,
  ContentItemEditing_Sidebar_Section_UpdateFinished,
  ContentItemEditing_Sidebar_Section_UpdateStarted,
  ContentItemEditing_TaskEditing_Started,
  ContentItemEditing_UpdateTask_Started,
} from '../../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { NewTaskId } from '../../../../features/ContentItemEditing/constants/taskConstants.ts';

const initialState = Immutable.Set<string>();

export const formsToClose = (
  state: Immutable.Set<string> = initialState,
  action: Action,
): Immutable.Set<string> => {
  switch (action.type) {
    case ContentItemEditing_Init_Started:
      return initialState;

    case ContentItemEditing_CreateTask_Started:
      return state.add(NewTaskId);

    case ContentItemEditing_UpdateTask_Started:
      return state.add(action.payload.taskId);

    case ContentItemEditing_CreateTask_Failed:
    case ContentItemEditing_CreateTask_Finished:
      return state.remove(NewTaskId);

    case ContentItemEditing_TaskEditing_Started:
      return state.remove(action.payload.taskId);

    case ContentItemEditing_Sidebar_Section_Focused:
      return action.payload.section !== ContentItemSidebarEditableSection.Tasks
        ? state.remove(action.payload.section)
        : state;

    case ContentItemEditing_Sidebar_Section_UpdateStarted:
      return state.add(action.payload.submittingSection);

    case ContentItemEditing_Sidebar_Section_UpdateFinished:
      return state.remove(action.payload.submittedSection);

    default:
      return state;
  }
};
