import PropTypes, { ValidationMap } from 'prop-types';
import { PropTypeOrNull } from '../../../../_shared/utils/propTypesValidators.ts';
import { ItemElementTypes } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import { ICompiledElementBase } from '../../../contentInventory/content/models/contentTypeElements/types/CompiledElementBase.type.ts';

export interface ICompiledContentItemElementData extends ICompiledElementBase {
  readonly lastModifiedBy: string | null;
  readonly lastModifiedAt: DateTimeStamp | null;
}

export const CompiledContentItemElementDataValidationMap: ValidationMap<ICompiledContentItemElementData> =
  {
    type: PropTypes.oneOf(ItemElementTypes).isRequired,
    elementId: PropTypes.string.isRequired,
    lastModifiedAt: PropTypeOrNull(PropTypes.string),
    lastModifiedBy: PropTypeOrNull(PropTypes.string),
  };
