import classNames from 'classnames';
import React, { memo, useState } from 'react';
import { HideOutsideViewport } from '../../../../../../../_shared/components/HideOutsideViewport.tsx';
import { SimpleLinkedItem } from '../../../../LinkedItems/containers/SimpleLinkedItem.tsx';

type Props = {
  readonly className?: string;
  readonly contentItemId: Uuid;
  readonly hideOutsideViewport?: boolean;
  readonly renderExpanded: (contentItemId: string) => React.ReactNode;
};
export const SimpleLinkedListItem: React.FC<Props> = memo(
  ({ className, contentItemId, hideOutsideViewport, renderExpanded }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const renderVisible = (ref?: React.RefObject<HTMLDivElement>) => (
      <SimpleLinkedItem
        className={className}
        contentItemId={contentItemId}
        onExpandedChanged={setIsExpanded}
        ref={ref}
        renderExpanded={renderExpanded}
      />
    );

    const renderHidden = (ref: React.RefObject<HTMLDivElement>) => (
      <div className="bar-item__node" ref={ref}>
        <div className="bar-item__wrapper">
          <div className="bar-item__pane">
            <div className={classNames(className, 'bar-item__bar')} />
          </div>
        </div>
      </div>
    );

    return hideOutsideViewport ? (
      <HideOutsideViewport
        alwaysVisible={isExpanded}
        delay={50}
        renderHidden={renderHidden}
        renderVisible={renderVisible}
        rootMargin="200px 0px 200px 0px"
      />
    ) : (
      renderVisible()
    );
  },
);

SimpleLinkedListItem.displayName = 'SimpleLinkedListItem';
