import { assert, createGuid } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { WorkflowStepColor } from '../../../../../data/constants/WorkflowStepColor.ts';
import { Workflow } from '../../../../../data/models/workflow/Workflow.ts';
import {
  ArchivedWorkflowStepId,
  PublishedWorkflowStepId,
  WorkflowStepAction,
} from '../../../../../data/models/workflow/WorkflowStep.ts';
import { PredefinedRoles } from '../../../roles/constants/predefinedRoles.ts';
import { archivedStepConstantValues } from '../../constants/archivedStepValues.ts';
import {
  publishedStepConstantValues,
  scheduledStepConstantValues,
} from '../../constants/publishedStepValues.ts';
import {
  Workflow_InitializeCreating_Finished,
  Workflow_InitializeCreating_Started,
} from '../../constants/workflowActionTypes.ts';

interface IInitWorkflowCreatingActionDependencies {
  readonly loadContentTypes: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadRoles: (abortSignal?: AbortSignal) => ThunkPromise;
}

type PredefinedWorkflow = Omit<Workflow, 'id' | 'codename'>;

const started = () => ({ type: Workflow_InitializeCreating_Started }) as const;

const finished = (workflow: PredefinedWorkflow) =>
  ({
    type: Workflow_InitializeCreating_Finished,
    payload: {
      workflow,
    },
  }) as const;

export type InitWorkflowCreatingActions = ReturnType<typeof started | typeof finished>;

const getPredefinedWorkflow = (projectManagerRoleId: Uuid): PredefinedWorkflow => ({
  name: '',
  archivedStep: {
    ...archivedStepConstantValues,
    roleIds: new Set<Uuid>([projectManagerRoleId]),
  },
  steps: [
    {
      id: createGuid(),
      name: 'Draft',
      codename: '',
      color: WorkflowStepColor.Red,
      action: WorkflowStepAction.NoAction,
      transitionsTo: new Set<Uuid>([PublishedWorkflowStepId, ArchivedWorkflowStepId]),
      roleIds: new Set<Uuid>([projectManagerRoleId]),
    },
  ],
  publishedStep: {
    ...publishedStepConstantValues,
    roleIdsForUnpublish: new Set<Uuid>([projectManagerRoleId]),
    roleIdsForCreateNew: new Set<Uuid>([projectManagerRoleId]),
  },
  scheduledStep: {
    ...scheduledStepConstantValues,
    transitionsTo: new Set<Uuid>(),
    roleIds: new Set<Uuid>([projectManagerRoleId]),
  },
  scopes: [
    {
      id: createGuid(),
      collections: new Set<Uuid>(),
      contentTypes: new Set<Uuid>(),
    },
  ],
});

const getProjectManagerRoleId = (state: IStore): Uuid => {
  const {
    data: {
      roles: { rolesById },
    },
  } = state;

  const roles = Array.from(rolesById.values());
  const projectManagerRole = roles.find((role) => role.codename === PredefinedRoles.ProjectManager);

  assert(
    !!projectManagerRole,
    () => 'createInitWorkflowCreatingAction: could not find projectManager',
  );

  return projectManagerRole.id;
};

export const createInitWorkflowCreatingAction =
  ({ loadContentTypes, loadRoles }: IInitWorkflowCreatingActionDependencies) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    dispatch(started());

    await Promise.all([dispatch(loadRoles(abortSignal)), dispatch(loadContentTypes(abortSignal))]);

    const state = getState();
    const projectManagerRoleId = getProjectManagerRoleId(state);

    dispatch(finished(getPredefinedWorkflow(projectManagerRoleId)));
  };
