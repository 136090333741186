import { Direction } from '@kontent-ai/types';
import { createGuid } from '@kontent-ai/utils';
import { DraftBlockRenderConfig, EditorProps as DraftJSEditorProps } from 'draft-js';
import Immutable from 'immutable';
import React, { useCallback, useMemo, useState } from 'react';
import { useEditorApi } from '../../editorCore/hooks/useEditorApi.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import {
  CanUpdateContent,
  GetBaseBlockRenderMap,
  IsEditorLocked,
  RemoveInvalidState,
} from '../../editorCore/types/Editor.base.type.ts';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { DecoratedEditor } from '../../editorCore/types/Editor.decorated.type.ts';
import {
  Apply,
  EditorPlugin,
  PluginState,
  Render,
} from '../../editorCore/types/Editor.plugins.type.ts';
import {
  EditorChangeReason,
  internalChangeReasons,
} from '../../editorCore/types/EditorChangeReason.ts';
import { Decorator, decorable } from '../../editorCore/utils/decorable.ts';
import {
  BaseBlockRenderMap,
  mergeBlockRenderMaps,
} from '../../editorCore/utils/editorComponentUtils.ts';
import { withDisplayName } from '../../editorCore/utils/withDisplayName.ts';
import { BaseBlockType, BlockType, ObjectBlockType } from '../../utils/blocks/blockType.ts';
import { isNewBlockPlaceholder } from '../../utils/blocks/blockTypeUtils.ts';
import { getBaseBlockType } from '../../utils/blocks/editorBlockGetters.ts';
import { IEditorBlockProps } from '../../utils/blocks/editorBlockUtils.ts';
import { findNewBlockPlaceholder } from '../../utils/general/editorContentUtils.ts';
import { ModalsPlugin, OnCloseModal } from '../ModalsPlugin.tsx';
import { UndoRedoPlugin } from '../undoRedo/UndoRedoPlugin.tsx';
import { EditorCustomBlocksApi } from './api/EditorCustomBlocksApi.type.ts';
import { editorCustomBlocksApi } from './api/editorCustomBlocksApi.ts';
import { Placeholder } from './components/Placeholder.tsx';

type CreateNewBlockPlaceholder = (blockType: ObjectBlockType) => Promise<string | null>;

type CancelNewBlock = (blockKey: string) => void;

export type DeleteCustomBlock = (blockKey: string) => void;

type CustomBlocksPluginState = {
  readonly editedBlockKey: string | null;
  readonly cancelNewBlock: CancelNewBlock;
  readonly createNewBlockPlaceholder: CreateNewBlockPlaceholder;
  readonly deleteCustomBlock: DeleteCustomBlock;
  readonly resetEditedBlockKey: () => void;
};

export type CustomBlocksPlugin = EditorPlugin<
  CustomBlocksPluginState,
  None,
  EditorCustomBlocksApi,
  [UndoRedoPlugin, ModalsPlugin]
>;

const PlaceholderBlock: React.FC<IEditorBlockProps> = ({ block }) => {
  const blockKey = block.getKey();

  return <Placeholder blockKey={blockKey} />;
};

PlaceholderBlock.displayName = 'PlaceholderBlock';

const newBlockPlaceholderRenderMap: BaseBlockRenderMap = Immutable.Map<
  BaseBlockType,
  DraftBlockRenderConfig
>({
  [BaseBlockType.NewBlockPlaceholder]: {
    element: 'div',
  },
});

const getBaseBlockRenderMap: Decorator<GetBaseBlockRenderMap> = (baseGetBaseBlockRenderMap) => () =>
  mergeBlockRenderMaps(baseGetBaseBlockRenderMap(), newBlockPlaceholderRenderMap);

const EditorWithCustomBlocks: DecoratedEditor<CustomBlocksPlugin> = ({ baseRender, state }) => {
  const {
    editorProps: { blockRendererFn: baseBlockRendererFn },
  } = state;

  const blockRendererFn = useCallback<Required<DraftJSEditorProps>['blockRendererFn']>(
    (block) => {
      const baseBlockType = getBaseBlockType(block);
      if (baseBlockType === BlockType.NewBlockPlaceholder) {
        return {
          component: PlaceholderBlock,
          editable: false,
        };
      }

      return baseBlockRendererFn?.(block) ?? null;
    },
    [baseBlockRendererFn],
  );

  const stateWithCustomBlocks: PluginState<CustomBlocksPlugin> = {
    ...state,
    editorProps: {
      ...state.editorProps,
      blockRendererFn,
    },
  };

  return baseRender(stateWithCustomBlocks);
};

EditorWithCustomBlocks.displayName = 'EditorWithCustomBlocks';

export const useCustomBlocks: PluginCreator<CustomBlocksPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('CustomBlocksPlugin', {
        ComposedEditor: (props) => {
          const render: Decorator<Render<CustomBlocksPlugin>> = useCallback(
            (baseRender) => (state) => {
              return baseRender(state);
            },
            [],
          );

          const [editedBlockKey, setEditedBlockKey] = useState<string | null>(null);
          const resetEditedBlockKey = useCallback(() => setEditedBlockKey(null), []);

          const removeInvalidState: Decorator<RemoveInvalidState> = useCallback(
            (baseRemoveInvalidState) => (editorState) => {
              if (editedBlockKey) {
                const content = editorState.getCurrentContent();
                if (!content.getBlockForKey(editedBlockKey)) {
                  setEditedBlockKey(null);
                }
              }
              baseRemoveInvalidState(editorState);
            },
            [editedBlockKey],
          );

          const isEditorLocked: Decorator<IsEditorLocked> = useCallback(
            (baseIsEditorLocked) => () => !!editedBlockKey || baseIsEditorLocked(),
            [editedBlockKey],
          );

          const canUpdateContent: Decorator<CanUpdateContent> = useCallback(
            (baseCanUpdateContent) => (changeReason) =>
              // Even reviewer can add comments
              internalChangeReasons.has(changeReason ?? EditorChangeReason.Regular)
                ? baseCanUpdateContent(changeReason)
                : !editedBlockKey && baseCanUpdateContent(changeReason),
            [editedBlockKey],
          );

          const apply: Apply<CustomBlocksPlugin> = useCallback(
            (state) => {
              state.canUpdateContent.decorate(canUpdateContent);
              state.isEditorLocked.decorate(isEditorLocked);
              state.removeInvalidState.decorate(removeInvalidState);
              state.render.decorate(render);
              state.getBaseBlockRenderMap.decorate(getBaseBlockRenderMap);

              const deleteCustomBlock: DeleteCustomBlock = (blockKey) =>
                state.executeChange((editorState) =>
                  state.getApi().deleteObjectBlock(editorState, blockKey, Direction.Forward),
                );

              const createNewBlockPlaceholder: CreateNewBlockPlaceholder = async (blockType) => {
                let placeholderBlockKey: string | null = null;
                await state.executeChange((editorState) => {
                  const selection = editorState.getSelection();
                  const placeholderId = createGuid();
                  const newEditorState = state
                    .getApi()
                    .createNewBlockPlaceholder(editorState, selection, blockType, placeholderId);
                  if (newEditorState !== editorState) {
                    const placeholder = findNewBlockPlaceholder(
                      newEditorState.getCurrentContent(),
                      placeholderId,
                    );
                    if (placeholder) {
                      placeholderBlockKey = placeholder.getKey();
                      setEditedBlockKey(placeholderBlockKey);
                      return newEditorState;
                    }
                  }
                  return editorState;
                });
                return placeholderBlockKey;
              };

              const cancelNewBlock = decorable<CancelNewBlock>((blockKey) =>
                state.executeChange((editorState) => {
                  const newEditorState = state
                    .getApi()
                    .undoNewBlockPlaceholder(editorState, blockKey);
                  setEditedBlockKey(null);
                  return newEditorState;
                }, EditorChangeReason.Undo),
              );

              const onCloseModal: Decorator<OnCloseModal> = (baseOnCloseModal) => () => {
                if (editedBlockKey) {
                  const editorState = state.getEditorState();
                  const content = editorState.getCurrentContent();
                  const block = content.getBlockForKey(editedBlockKey);
                  if (isNewBlockPlaceholder(block)) {
                    cancelNewBlock(editedBlockKey);
                    return true;
                  }
                }
                return baseOnCloseModal();
              };

              state.onCloseModal.decorate(onCloseModal);

              return {
                cancelNewBlock,
                createNewBlockPlaceholder,
                deleteCustomBlock,
                editedBlockKey,
                resetEditedBlockKey,
              };
            },
            [
              canUpdateContent,
              editedBlockKey,
              isEditorLocked,
              removeInvalidState,
              render,
              resetEditedBlockKey,
            ],
          );

          const { getApiMethods } = useEditorApi<CustomBlocksPlugin>(editorCustomBlocksApi);

          return useEditorWithPlugin(baseEditor, props, { apply, getApiMethods });
        },
      }),
    [baseEditor],
  );
