import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import Immutable from 'immutable';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { ModalDialog } from '../../../../../_shared/components/ModalDialog/ModalDialog.tsx';
import {
  NotificationBar,
  NotificationBarType,
} from '../../../../../_shared/components/NotificationBar.tsx';
import { HandleUnsavedFormOnNavigation } from '../../../../../_shared/containers/HandleUnsavedFormOnNavigation.tsx';
import { SubscriptionPlanIncludedUserLimitReachedWarning } from '../../../../../_shared/containers/SubscriptionWarnings/SubscriptionPlanIncludedUserLimitReachedWarning.tsx';
import { Button } from '../../../../../_shared/uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import {
  DataUiAction,
  DataUiElement,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IExistingUserToInvite } from '../../../../../data/models/users/ExistingUserToInvite.ts';
import { ICollectionGroupRoles } from '../../../../../data/models/users/ProjectContributor.ts';
import { invitationInProgress } from '../../constants/errorMessages.ts';
import { InvitationEmailInput } from '../../containers/invitations/InvitationEmailInput.tsx';
import { Invitation } from '../../reducers/IUsersAppState.type.ts';
import { CollectionPermissionsQuickTip } from '../CollectionPermissionsQuickTip.tsx';
import { LanguageRolesQuickTip } from '../LanguageRolesQuickTip.tsx';
import { UserConfiguration } from '../collections/UserConfiguration.tsx';
import { InviteButton } from './InviteButton.tsx';

export interface IInvitationModalStateProps {
  readonly collectionGroups: readonly ICollectionGroupRoles[];
  readonly collidingContributorEmails: ReadonlySet<string>;
  readonly createUserDetailLink: (userId: UserId) => string;
  readonly disabledInviteTooltip: string | undefined;
  readonly emailInputValidationMessages: ReadonlyArray<string>;
  readonly emailsCountedInSubscriptionLimits: ReadonlyArray<string>;
  readonly environmentName: string;
  readonly errorMessage: string | undefined;
  readonly existingUsersByEmail: Immutable.Map<string, IExistingUserToInvite>;
  readonly hasUnsavedChanges: boolean;
  readonly initialEmail: string | undefined;
  readonly invitations: Immutable.List<Invitation>;
  readonly isInitialized: boolean;
  readonly isInviting: boolean;
  readonly savingError: string | null;
  readonly shouldConfirmInvite: boolean;
  readonly shouldHighlightNewUsers: boolean;
  readonly userListingLink: string;
}

export interface IInvitationModalDispatchProps {
  readonly changeInvitations: (newSelected: Immutable.List<Invitation>) => void;
  readonly changeSearchText: (newEmail: string) => void;
  readonly hideNotificationBar: () => void;
  readonly onClose: () => void;
  readonly onCollectionGroupsChange: (collectionGroups: readonly ICollectionGroupRoles[]) => void;
  readonly onInitialize: (initialEmail: string | undefined) => void;
  readonly submitInvitations: (onSuccess?: () => void, onFail?: () => void) => void;
}

type Props = IInvitationModalStateProps & IInvitationModalDispatchProps;

const InvitationModalBody: React.FC<Props> = (props) => {
  const disabledTooltip = props.isInviting ? invitationInProgress : undefined;

  return (
    <div className="user-modal__body">
      <div className="user-modal__content">
        <div className="user-modal__form">
          <Stack spacing={Spacing.L}>
            <div className="user-modal__form-inputs-pane">
              <InvitationEmailInput {...props} />
            </div>
            <SubscriptionPlanIncludedUserLimitReachedWarning
              newEmails={props.emailsCountedInSubscriptionLimits}
            />
            <div className="user-modal__role-builder-pane">
              <UserConfiguration
                collectionGroups={props.collectionGroups}
                disabledTooltip={disabledTooltip}
                onCollectionGroupsChange={props.onCollectionGroupsChange}
              />
            </div>
            <LanguageRolesQuickTip isRoleBuilderValid={!props.errorMessage} />
            <CollectionPermissionsQuickTip />
          </Stack>
        </div>
      </div>
      <div className="user-modal__footer">
        <NotificationBar
          type={NotificationBarType.Error}
          message={props.savingError}
          onClosePanel={props.hideNotificationBar}
        />
      </div>
    </div>
  );
};

const InvitationModalBodyMemoized = React.memo(InvitationModalBody);

export const InvitationModal: React.FC<Props> = (props) => {
  const { environmentName, initialEmail, onClose, onInitialize, userListingLink } = props;
  const history = useHistory();
  useEffect(() => {
    onInitialize(initialEmail);

    return onClose;
  }, [onInitialize, onClose, initialEmail]);

  if (!props.isInitialized) {
    return null;
  }

  const close = () => history.push(userListingLink);

  const disabledTooltip = props.disabledInviteTooltip || props.errorMessage;

  return (
    <>
      <HandleUnsavedFormOnNavigation
        disabledTooltip={disabledTooltip}
        isBeingSaved={props.isInviting}
        onSaveChanges={props.submitInvitations}
        hasUnsavedChanges={props.hasUnsavedChanges}
      />
      <ModalDialog
        dataUiElement={DataUiElement.InvitationModalDialog}
        headerContent={`Invite users to “${environmentName}” environment`}
        bodyContent={<InvitationModalBodyMemoized {...props} />}
        onClose={close}
        footerContentRight={
          <>
            <Button
              style={ButtonStyle.Secondary}
              onClick={close}
              disabled={props.isInviting}
              dataUiAction={DataUiAction.Cancel}
            >
              Cancel
            </Button>
            <InviteButton
              disabledTooltip={disabledTooltip}
              onInvite={() => props.submitInvitations(close)}
              shouldConfirm={props.shouldConfirmInvite}
              emailsCountedInSubscriptionLimits={props.emailsCountedInSubscriptionLimits}
              isInvitationInProgress={props.isInviting}
            />
          </>
        }
        withFullWidthBodyContent
        withDividers
      />
    </>
  );
};

InvitationModal.displayName = 'InvitationModal';
