import React, { useCallback, useRef } from 'react';
import { useRouteMatch } from 'react-router';
import { trackUserEventWithData } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import {
  ContentItemPreviewRoute,
  ContentItemRouteParams,
} from '../../../../../../_shared/constants/routePaths.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import {
  ContentItemEditingEventOrigins,
  ContentItemEditingEventTypes,
} from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import {
  closeContentItemEditingSidebar,
  initializeContentItemEditingSidebar,
} from '../../actions/contentItemEditingActions.ts';
import { openContentItemEditingSidebar } from '../../actions/thunks/openContentItemEditingSidebar.ts';
import {
  ContentItemSidebar as ContentItemSidebarComponent,
  ContentItemSidebarOwnProps,
} from '../../components/sidebar/ContentItemSidebar.tsx';

const useWasEverTrue = (bool: boolean): boolean => {
  const wasEverTrue = useRef(false);
  wasEverTrue.current = wasEverTrue.current || bool;

  return wasEverTrue.current;
};

const useShouldOpenByDefault = (): boolean => {
  const prefersClosedEditingSidebar = useSelector(
    (state) => state.sharedApp.userProperties.prefersClosedEditingSidebar,
  );
  const wasPrefersClosedEditingSidebarEverTrue = useWasEverTrue(prefersClosedEditingSidebar); // don't open sidebar when the preference changes

  const isInPreview = !!useRouteMatch<ContentItemRouteParams<Uuid>>(ContentItemPreviewRoute);

  return !isInPreview && !wasPrefersClosedEditingSidebarEverTrue;
};

export const ContentItemSidebar: React.FC<ContentItemSidebarOwnProps<HTMLElement>> = (props) => {
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(closeContentItemEditingSidebar());
    dispatch(
      trackUserEventWithData(TrackedEvent.ContentItemEditing, {
        action: ContentItemEditingEventTypes.ContentItemDetailsSidebarClosed,
        origin: ContentItemEditingEventOrigins.ItemDetails,
      }),
    );
  }, []);

  const onOpen = useCallback(() => dispatch(openContentItemEditingSidebar()), []);

  const onInit = useCallback(() => dispatch(initializeContentItemEditingSidebar()), []);

  return (
    <ContentItemSidebarComponent
      {...props}
      isInitialized={useSelector(
        (state) => state.contentApp.editorUi.contentItemSidebar.isInitialized,
      )}
      isOpen={useSelector((state) => state.contentApp.editorUi.contentItemSidebar.isOpen)}
      onClose={onClose}
      onOpen={onOpen}
      onInit={onInit}
      shouldOpenByDefault={useShouldOpenByDefault()}
    />
  );
};
