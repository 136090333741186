import { History } from 'history';
import { ThunkFunction, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import {
  ContentTypeSnippetsRoute,
  EnvironmentRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IContentTypeSnippet } from '../../../../../data/models/contentModelsApp/snippets/ContentTypeSnippet.ts';
import { VariantUsageQueryServerModel } from '../../../../../repositories/serverModels/ContentItemFilterWithContinuationServerModel.ts';
import { ILoadContentTypeReferencesForTypeEditingAction } from '../../../shared/actions/thunks/loadContentTypeReferencesForTypeEditing.ts';
import { IBaseTypeElementData } from '../../../shared/models/elements/types/TypeElementData.ts';
import {
  ContentTypeSnippet_Editor_InitFinished,
  ContentTypeSnippet_Editor_InitStarted,
} from '../../constants/snippetActionTypes.ts';

interface IDeps {
  readonly contentItemRepository: {
    readonly projectContainsPublishedItems: (
      filter: VariantUsageQueryServerModel,
      abortSignal?: AbortSignal,
    ) => Promise<boolean>;
  };
  readonly loadAssetType: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadCollections: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadContentTypeReferencesForTypeEditing: ILoadContentTypeReferencesForTypeEditingAction;
  readonly loadContentTypes: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadContentTypesUsage: (abortSignal?: AbortSignal) => ThunkPromise;

  readonly loadSnippetsData: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadTaxonomyGroups: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly validateTypeElement: (updatedTypeElement: IBaseTypeElementData) => ThunkFunction;
}

interface IActionParams {
  readonly editedContentTypeSnippetId: Uuid;
  readonly history: History;
}

const initContentTypeSnippetEditorStarted = () =>
  ({
    type: ContentTypeSnippet_Editor_InitStarted,
  }) as const;

const initContentTypeSnippetEditorFinished = (
  isEditedContentTypeSnippetUsedInPublishedItem: boolean,
  editedContentTypeSnippet: IContentTypeSnippet,
  editedContentTypeSnippetElements: ReadonlyArray<IBaseTypeElementData>,
) =>
  ({
    type: ContentTypeSnippet_Editor_InitFinished,
    payload: {
      isEditedContentTypeSnippetUsedInPublishedItem,
      editedContentTypeSnippet,
      editedContentTypeSnippetElements,
    },
  }) as const;

export type InitContentTypeSnippetEditorActionsType = ReturnType<
  typeof initContentTypeSnippetEditorStarted | typeof initContentTypeSnippetEditorFinished
>;

export const createInitContentTypeSnippetEditorAction =
  (deps: IDeps) =>
  (
    { editedContentTypeSnippetId, history }: IActionParams,
    abortSignal?: AbortSignal,
  ): ThunkPromise =>
  async (dispatch, getState) => {
    dispatch(initContentTypeSnippetEditorStarted());

    await dispatch(deps.loadSnippetsData(abortSignal));

    const {
      sharedApp: { currentProjectId },
      data: { snippets },
    } = getState();

    const loadProjectContainsPublishedItems = async (): Promise<boolean> => {
      const typesReferencingSnippets = snippets.snippetUsage.get(editedContentTypeSnippetId);
      return (
        !!typesReferencingSnippets &&
        (await deps.contentItemRepository.projectContainsPublishedItems(
          {
            typeIds: typesReferencingSnippets.toArray(),
          },
          abortSignal,
        ))
      );
    };

    const editedContentTypeSnippet = snippets.byId.get(editedContentTypeSnippetId);
    if (!editedContentTypeSnippet || editedContentTypeSnippet.isArchived) {
      history.push(
        buildPath<EnvironmentRouteParams>(ContentTypeSnippetsRoute, {
          projectId: currentProjectId,
        }),
      );
      return;
    }

    const [isEditedContentTypeSnippetUsedInPublishedItem] = await Promise.all([
      loadProjectContainsPublishedItems(),
      dispatch(deps.loadAssetType(abortSignal)),
      dispatch(deps.loadContentTypesUsage(abortSignal)),
      dispatch(deps.loadContentTypes(abortSignal)),
      dispatch(deps.loadTaxonomyGroups(abortSignal)),
      dispatch(deps.loadCollections(abortSignal)),
      dispatch(
        deps.loadContentTypeReferencesForTypeEditing(
          editedContentTypeSnippet.typeElements,
          abortSignal,
        ),
      ),
    ]);

    editedContentTypeSnippet.typeElements.forEach((element) =>
      dispatch(deps.validateTypeElement(element)),
    );

    dispatch(
      initContentTypeSnippetEditorFinished(
        isEditedContentTypeSnippetUsedInPublishedItem,
        editedContentTypeSnippet,
        editedContentTypeSnippet.typeElements,
      ),
    );
  };
