import {
  Collection,
  makeCancellablePromise,
  swallowCancelledPromiseError,
} from '@kontent-ai/utils';
import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import {
  SpaceRoute,
  SpaceRouteParams,
  SpacesRoute,
  SpacesRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { areCollectionsVisibleForSpaces } from '../../../../_shared/selectors/contentCollections.ts';
import { createPreviewConfigurationLink } from '../../../../_shared/utils/routing/projectSubscriptionRoutingUtils.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getCurrentProjectId } from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { initializeSpacesApp } from '../actions/thunkSpacesActions.ts';
import { SpacesApp as SpacesAppComponent } from '../components/SpacesApp.tsx';

type Props = {
  readonly initiallyExpandedSpaceId: Uuid | null;
};

const SpacesApp: React.FC<Props> = ({ initiallyExpandedSpaceId }) => {
  const dispatch = useDispatch();
  const [isInitialized, setIsInitialized] = useState(false);
  const previewUrlsAppRoute = useSelector((s) =>
    createPreviewConfigurationLink({
      projectId: getCurrentProjectId(s),
      subscriptionId: s.subscriptionApp.selectedSubscriptionId,
    }),
  );
  const showCollections = useSelector((s) =>
    areCollectionsVisibleForSpaces(s, Collection.getValues(s.data.collections.byId)),
  );

  useEffect(() => {
    setIsInitialized(false);

    const init = makeCancellablePromise(() => dispatch(initializeSpacesApp()))
      .then(() => setIsInitialized(true))
      .catch(swallowCancelledPromiseError);

    return () => init.cancel();
  }, []);

  if (!isInitialized) {
    return <Loader />;
  }

  return (
    <SpacesAppComponent
      initiallyExpandedSpaceId={initiallyExpandedSpaceId}
      previewUrlsAppRoute={previewUrlsAppRoute}
      showCollections={showCollections}
    />
  );
};

SpacesApp.displayName = 'SpacesAppContainer';

const SpacesAppWrapper: React.FC = () => {
  const [initiallyExpandedSpaceId, setInitiallyExpandedSpaceId] = useState<Uuid | null>(null);
  const spaceMatch = useRouteMatch<SpaceRouteParams>(SpaceRoute);
  const projectId = useSelector((s) => s.sharedApp.currentProjectId);

  const history = useHistory();

  useEffect(() => {
    if (!spaceMatch?.params.spaceId) {
      return;
    }

    setInitiallyExpandedSpaceId(spaceMatch.params.spaceId);

    const spacesRoute = buildPath<SpacesRouteParams>(SpacesRoute, {
      projectId,
    });

    history.push(spacesRoute);
  }, [history, spaceMatch, projectId]);

  return <SpacesApp initiallyExpandedSpaceId={initiallyExpandedSpaceId} />;
};

SpacesAppWrapper.displayName = 'SpacesAppWrapper';

export { SpacesAppWrapper as SpacesApp };
