import { assert, Collection } from '@kontent-ai/utils';
import {
  Dispatch,
  GetState,
  ThunkFunction,
  ThunkPromise,
} from '../../../../../../@types/Dispatcher.type.ts';
import { DefaultVariantId } from '../../../../../../_shared/constants/variantIdValues.ts';
import { MemoizedContentItemId } from '../../../../../../_shared/models/ContentItemId.type.ts';
import { ActiveCapabilityType } from '../../../../../../_shared/models/activeCapability.type.ts';
import { getMemoizedContentItemId } from '../../../../../../_shared/models/utils/contentItemIdUtils.ts';
import { areFallbacksForLinkedContentEnabled } from '../../../../../../_shared/selectors/fallbacksForLinkedContent.ts';
import { getListingContentItem } from '../../../../../../_shared/selectors/getListingContentItem.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { distinctFilter } from '../../../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import { getNotTranslatedListingContentItemIds } from '../../../../../../_shared/utils/contentItemUtils.ts';
import { hasActiveVariantCapability } from '../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { hasCapabilityInLanguage } from '../../../../../../_shared/utils/permissions/capabilitiesInLanguageUtils.ts';
import { Capability } from '../../../../../../_shared/utils/permissions/capability.ts';
import { LoadListingContentItemsByIdsAction } from '../../../../../../data/actions/thunks/listingContentItems/loadListingContentItemsByIds.ts';
import {
  IListingContentItem,
  getListingContentItemFromJS,
} from '../../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { ICompiledContentType } from '../../../../../contentInventory/content/models/CompiledContentType.ts';
import { CascadeNodeId } from '../../../../../contentInventory/content/stores/IContentAppStoreState.ts';
import { isDefaultVariantRequiredForPublish } from '../../../ContentItemEditing/utils/itemValidationUtils.ts';
import { IEnsureLoadedContentTypesAction } from '../../../LoadedItems/actions/thunks/ensureLoadedContentTypes.ts';
import {
  ContentEditing_CascadeModal_ExpandNodeFinished,
  ContentEditing_CascadeModal_ExpandNodeStarted,
} from '../../constants/cascadeModalActionTypes.ts';
import { groupItemIdsByVariantId } from '../../utils/groupContentItemIds.ts';
import { hasChildItemAnyPublishingDependencies } from '../../utils/hasChildItemAnyPublishingDependencies.ts';
import { nodesSelected } from '../cascadeModalActions.ts';

const started = (contentItemId: MemoizedContentItemId, nodeId: CascadeNodeId) =>
  ({
    type: ContentEditing_CascadeModal_ExpandNodeStarted,
    payload: {
      contentItemId,
      nodeId,
    },
  }) as const;

const finished = (
  editedItemId: Uuid,
  editedVariantId: Uuid,
  parentItemId: Uuid,
  parentVariantId: Uuid,
  allChildNodes: ReadonlyMap<Uuid, ReadonlyArray<IListingContentItem>>,
  nodeId: CascadeNodeId,
) =>
  ({
    type: ContentEditing_CascadeModal_ExpandNodeFinished,
    payload: {
      allChildNodes,
      editedItemId,
      editedVariantId,
      nodeId,
      parentItemId,
      parentVariantId,
    },
  }) as const;

export type ExpandNodeActionType = ReturnType<typeof started | typeof finished>;

interface IParams {
  readonly itemId: Uuid;
  readonly nodeId: CascadeNodeId;
  readonly shouldSelectSelf: boolean;
  readonly variantId: Uuid;
}

interface IDeps {
  readonly loadListingContentItemsByIds: LoadListingContentItemsByIdsAction;
  readonly ensureLoadedContentTypes: IEnsureLoadedContentTypesAction;
  readonly loadListingItemsWithAllVariants: (itemIds: UuidArray) => ThunkPromise;
}

const getReferencedContentItemIds = (
  item: IListingContentItem,
  variantId: Uuid,
): ReadonlyArray<MemoizedContentItemId> => {
  return item && !item.item.archived && item.variant && !item.variant.isArchived
    ? item.variant.modularItemIds.map((itemId) => getMemoizedContentItemId(itemId, variantId))
    : [];
};

const getDependencyContentItemIds = (
  item: IListingContentItem,
  variantId: Uuid,
  type: ICompiledContentType,
  selectedLanguageId: Uuid,
): ReadonlyArray<MemoizedContentItemId> => {
  const referencedIds = getReferencedContentItemIds(item, variantId);

  const editingDefaultVariant = selectedLanguageId === DefaultVariantId;
  const hasNonLocalizableElements = type.contentElements.some(
    (element) => element.isNonLocalizable,
  );

  return !editingDefaultVariant && hasNonLocalizableElements
    ? referencedIds.concat(getMemoizedContentItemId(item.item.id, DefaultVariantId))
    : referencedIds;
};

const selectSelf =
  (contentItemId: MemoizedContentItemId, type: ICompiledContentType): ThunkFunction =>
  (dispatch, getState) => {
    const state = getState();
    const defaultContentItemId = getMemoizedContentItemId(contentItemId.itemId, DefaultVariantId);

    const itemWithDefaultVariant = getListingContentItem(state, defaultContentItemId);

    const selectedLanguageId = getSelectedLanguageIdOrThrow(state);
    const canViewDefaultVariant = hasActiveVariantCapability(
      ActiveCapabilityType.ViewContent,
      itemWithDefaultVariant,
    );

    const dependsOnDefaultVariant = isDefaultVariantRequiredForPublish(
      type,
      selectedLanguageId === DefaultVariantId,
      canViewDefaultVariant,
      itemWithDefaultVariant,
    );

    const selectedContentItemIds = dependsOnDefaultVariant
      ? [contentItemId, defaultContentItemId]
      : [contentItemId];

    dispatch(nodesSelected(selectedContentItemIds));
  };

const loadDirectChildNodes =
  (
    deps: IDeps,
    editedItemId: Uuid,
    selectedLanguageId: Uuid,
    listingContentItem: IListingContentItem,
    variantId: Uuid,
    type: ICompiledContentType,
    nodeId: CascadeNodeId,
    contentItemId: MemoizedContentItemId,
  ) =>
  async (dispatch: Dispatch, getState: GetState) => {
    const {
      item: { id: itemId },
    } = listingContentItem;

    dispatch(started(contentItemId, nodeId));

    const itemsToBeProcessed = getDependencyContentItemIds(
      listingContentItem,
      variantId,
      type,
      selectedLanguageId,
    );
    const itemIdsToProcessByVariantId = groupItemIdsByVariantId(itemsToBeProcessed);

    const itemsByVariantIdPromises = Collection.getEntries(itemIdsToProcessByVariantId)
      .filter(([languageId]) =>
        hasCapabilityInLanguage(getState(), Capability.ViewContent, languageId),
      )
      .map<Promise<[variantId: Uuid, listingContentItems: ReadonlyArray<IListingContentItem>]>>(
        async ([languageId, itemIds]) => {
          const loadedItemsServerData = await dispatch(
            deps.loadListingContentItemsByIds(languageId, itemIds.filter(distinctFilter)),
          );
          const loadedItems = loadedItemsServerData.map(getListingContentItemFromJS);
          return [languageId, loadedItems];
        },
      );

    const itemsByVariantId = new Map(await Promise.all(itemsByVariantIdPromises));
    const items = Array.from(itemsByVariantId.values()).flat();
    const typeIds = new Set(items.map((item) => item.item.typeId));

    await dispatch(deps.ensureLoadedContentTypes(typeIds));

    if (areFallbacksForLinkedContentEnabled(getState())) {
      const notTranslatedItemIds = getNotTranslatedListingContentItemIds(items);
      await dispatch(deps.loadListingItemsWithAllVariants(notTranslatedItemIds));
    }

    dispatch(
      finished(editedItemId, selectedLanguageId, itemId, variantId, itemsByVariantId, nodeId),
    );
  };

export const createExpandNodeAction =
  (deps: IDeps) =>
  ({ itemId, nodeId, shouldSelectSelf, variantId }: IParams): ThunkPromise =>
  async (dispatch, getState) => {
    const state = getState();

    const {
      contentApp: { editedContentItem, editorUi, loadedContentItemTypes },
    } = state;

    const editedItemId = editedContentItem?.id;

    assert(editedItemId, () => `${__filename}: editedContentItem is not loaded`);

    const contentItemId = getMemoizedContentItemId(itemId, variantId);
    const item = getListingContentItem(state, contentItemId);

    assert(item, () => `${__filename}: Item ${JSON.stringify(contentItemId)} is not found`);

    const typeId = item?.item.typeId;
    const type = typeId && loadedContentItemTypes.get(typeId);

    assert(
      type,
      () => `${__filename}: Action cannot be processed when no itemType is present in the state.`,
    );

    const { expandedNodesData } = editorUi.cascadePublish;
    const selectedLanguageId = getSelectedLanguageIdOrThrow(state);
    const isExpanded = expandedNodesData.has(nodeId);

    const canViewDefaultVariant = hasCapabilityInLanguage(
      state,
      Capability.ViewContent,
      DefaultVariantId,
    );

    const nodeHasAnyDependencies = hasChildItemAnyPublishingDependencies(
      item,
      type,
      editedItemId,
      canViewDefaultVariant,
    );
    const hasToLoadChildNodes = !isExpanded && nodeHasAnyDependencies;

    if (hasToLoadChildNodes) {
      await dispatch(
        loadDirectChildNodes(
          deps,
          editedItemId,
          selectedLanguageId,
          item,
          variantId,
          type,
          nodeId,
          contentItemId,
        ),
      );
    }

    if (shouldSelectSelf) {
      dispatch(selectSelf(contentItemId, type));
    }
  };
