import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Placement, placements } from '@kontent-ai/component-library/types';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { MouseEvent } from 'react';
import { TagColor } from '../../../data/constants/tagColor.ts';
import { IconName } from '../../constants/iconEnumGenerated.ts';
import { Icon } from '../../uiComponents/Icon/Icon.tsx';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
  getDataUiObjectNameAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

export interface IMultipleOptionSelectOptionProps<T> {
  readonly color: TagColor;
  readonly getOptionName: (option: any) => string;
  readonly getOptionTooltip?: (option: T) => string | undefined;
  readonly isDisabled?: boolean;
  readonly onClick?: (option: T) => void;
  readonly onRemove?: (option: T) => void;
  readonly option: T;
  readonly tooltipPlacement?: Placement;
}

export class MultipleOptionSelectOption<T> extends React.PureComponent<
  IMultipleOptionSelectOptionProps<T>
> {
  static displayName = 'MultipleOptionSelectOption';

  static propTypes: PropTypesShape<IMultipleOptionSelectOptionProps<any>> = {
    color: PropTypes.string.isRequired,
    getOptionName: PropTypes.func.isRequired,
    getOptionTooltip: PropTypes.func,
    isDisabled: PropTypes.bool,
    onClick: PropTypes.func,
    onRemove: PropTypes.func,
    option: PropTypes.any.isRequired,
    tooltipPlacement: PropTypes.oneOf(placements),
  };

  static defaultProps: Pick<IMultipleOptionSelectOptionProps<unknown>, 'color'> = {
    color: TagColor.LightBlue,
  };

  private readonly remove = (event: MouseEvent<HTMLDivElement>): void => {
    if (!this.props.isDisabled && this.props.onRemove) {
      event.stopPropagation();
      this.props.onRemove(this.props.option);
    }
  };

  // Do not propagate focus into ItemElement.
  // This is needed so that ItemElement doesn't stay focused when option tile is removed.
  private readonly onFocus = (e: React.FocusEvent): void => {
    e.stopPropagation();
  };

  private readonly onClick = (event: MouseEvent<HTMLDivElement>): void => {
    if (!this.props.isDisabled && this.props.onClick) {
      event.stopPropagation();
      this.props.onClick(this.props.option);
    }
  };

  render() {
    const {
      getOptionName,
      getOptionTooltip,
      isDisabled,
      onRemove,
      option,
      tooltipPlacement = 'bottom-start',
    } = this.props;

    const optionName = getOptionName(option);

    return (
      <Tooltip
        placement={tooltipPlacement}
        tooltipText={getOptionTooltip?.(option)}
        {...getDataUiObjectNameAttribute(optionName)}
      >
        <div
          className={classNames(
            'multi-select__option',
            `multi-select__option--${this.props.color}`,
            { 'multi-select__option--is-disabled': isDisabled },
          )}
          onFocus={this.onFocus}
          {...getDataUiElementAttribute(DataUiElement.MultiSelectOption)}
        >
          <div
            className={classNames('multi-select__option-name', {
              'multi-select__option-name--is-clickable':
                !!this.props.onClick && !this.props.isDisabled,
            })}
            data-hj-suppress=""
            onClick={this.onClick}
            {...getDataUiElementAttribute(DataUiElement.MultiSelectOptionName)}
          >
            {optionName}
          </div>
          {!isDisabled && onRemove && (
            <div
              className="multi-select__option-remove-button"
              onClick={this.remove}
              {...getDataUiActionAttribute(DataUiAction.Remove)}
            >
              <Icon iconName={IconName.Times} />
            </div>
          )}
        </div>
      </Tooltip>
    );
  }
}
