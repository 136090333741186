import { EditorState } from 'draft-js';
import PropTypes from 'prop-types';
import React, { ChangeEvent, FormEvent } from 'react';
import { HotkeysHandler } from '../../../../../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import { ValidationConstants } from '../../../../../../../_shared/constants/validationConstants.ts';
import { LabelFor } from '../../../../../../../_shared/uiComponents/LabelFor/LabelFor.tsx';
import { isEmptyOrWhitespace } from '../../../../../../../_shared/utils/stringUtils.ts';
import { IFocusable } from '../../../../../../richText/plugins/behavior/FocusPlugin.tsx';
import {
  DebouncedChanges,
  EditorChangeCallback,
} from '../../../../../../richText/plugins/behavior/OnChangePlugin.tsx';
import { createSimpleTextValueEditorState } from '../../../../../../richText/utils/editorSimpleTextValueUtils.ts';
import { emptyEditorState } from '../../../../../../richText/utils/general/editorEmptyValues.ts';
import {
  CommentThreadItemType,
  ICommentThreadItemContentModel,
} from '../../../../../models/comments/CommentThreadItem.ts';
import { CommentInput } from '../../../containers/comments/input/CommentInput.tsx';
import { SuggestionInput } from '../input/SuggestionInput.tsx';
import { NewCommentThreadItemActionsBar } from './NewCommentThreadItemActionsBar.tsx';

interface INewCommentThreadItemCallbackProps {
  readonly onBlur?: (isCommentPending: boolean) => void;
  readonly onCancel: () => void;
  readonly onSubmit: (
    type: CommentThreadItemType,
    content: ICommentThreadItemContentModel,
  ) => Promise<void>;
}

interface INewCommentThreadItemDataProps {
  readonly elementSegment: string | null;
  readonly inputValue: string;
  readonly isEditing: boolean;
  readonly autoFocus?: boolean;
  readonly isSubmitting: boolean;
  readonly type: CommentThreadItemType;
}

interface INewCommentThreadItemState {
  readonly commentContent: EditorState;
  readonly lastKnownHasFocus: boolean;
  readonly previousType: CommentThreadItemType;
  readonly suggestionText: string;
}

type NewCommentThreadItemProps = INewCommentThreadItemCallbackProps &
  INewCommentThreadItemDataProps;

export class NewCommentThreadItem extends React.PureComponent<
  NewCommentThreadItemProps,
  INewCommentThreadItemState
> {
  static displayName = 'NewCommentThreadItem';

  static propTypes: PropTypesShape<NewCommentThreadItemProps> = {
    elementSegment: PropTypes.string,
    inputValue: PropTypes.string,
    isEditing: PropTypes.bool.isRequired,
    autoFocus: PropTypes.bool,
    isSubmitting: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,

    onBlur: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  readonly state: INewCommentThreadItemState = {
    commentContent: emptyEditorState,
    lastKnownHasFocus: false,
    previousType: CommentThreadItemType.Comment,
    suggestionText: '',
  };

  private readonly focusableRef = React.createRef<IFocusable>();
  private readonly debouncedChangesRef = React.createRef<DebouncedChanges>();
  private readonly suggestionRefObj = React.createRef<SuggestionInput>();

  static getDerivedStateFromProps(
    nextProps: NewCommentThreadItemProps,
    state: INewCommentThreadItemState,
  ): Partial<INewCommentThreadItemState> | null {
    if (state.previousType !== nextProps.type) {
      const defaultText =
        nextProps.type === CommentThreadItemType.Suggestion ? nextProps.elementSegment : '';
      const validText = defaultText?.substr(0, ValidationConstants.CommentTextMaxLength) || '';

      return {
        commentContent: createSimpleTextValueEditorState(validText),
        suggestionText: validText,
        previousType: nextProps.type,
      };
    }
    return null;
  }

  private readonly isCommentThreadItem = (): boolean =>
    this.props.type === CommentThreadItemType.Comment;

  public isTextValid = (): boolean => {
    if (this.isCommentThreadItem()) {
      return !isEmptyOrWhitespace(this.state.commentContent.getCurrentContent().getPlainText());
    }

    return !isEmptyOrWhitespace(this.state.suggestionText);
  };

  private readonly isSubmitButtonDisabled = (): boolean => {
    return !this.isTextValid() || this.props.isSubmitting;
  };

  private readonly onSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.stopPropagation();
    event.preventDefault();

    this.handleSubmitThreadItem();
  };

  private readonly onCommentChange: EditorChangeCallback = (editorState) => {
    this.setState(() => ({ commentContent: editorState }));
    return Promise.resolve();
  };

  private readonly onSuggestionChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    const inputValue = event.target.value;

    this.setState(() => ({ suggestionText: inputValue }));
  };

  private readonly onCommentSelectionChange: EditorChangeCallback = (editorState) => {
    const hasFocus = editorState.getSelection().getHasFocus();
    if (this.state.lastKnownHasFocus !== hasFocus) {
      this.setState(
        () => ({ lastKnownHasFocus: hasFocus }),
        () => {
          if (!hasFocus) {
            this.onBlur();
          }
        },
      );
    }
    return Promise.resolve();
  };

  private readonly onCancelItemShortcut = (): void => {
    this.cancelEditing();

    const commentInput = this.focusableRef.current;
    const suggestionInput = this.suggestionRefObj.current;

    if (commentInput) {
      commentInput.blur();
    } else if (suggestionInput) {
      suggestionInput.blur();
    }
  };

  private readonly handleSubmitThreadItem = (): void => {
    if (this.isTextValid()) {
      this.submitThreadItem();
    }
  };

  private readonly submitThreadItem = async (): Promise<void> => {
    await this.debouncedChangesRef.current?.propagatePendingContentChanges();
    const content = this.isCommentThreadItem()
      ? {
          type: this.props.type,
          content: this.state.commentContent.getCurrentContent(),
        }
      : {
          type: this.props.type,
          suggestedText: this.state.suggestionText,
        };
    await this.props.onSubmit(this.props.type, content);
  };

  private readonly cancelEditing = (): void => {
    this.props.onCancel();
  };

  private readonly onBlur = (): void => {
    const onBlur = this.props.onBlur;
    if (onBlur) {
      onBlur(this.isTextValid() && this.props.type === CommentThreadItemType.Comment);
    }
  };

  componentDidUpdate(prevProps: NewCommentThreadItemProps): void {
    if (!prevProps.isEditing && this.props.isEditing) {
      this.focus();
    }
  }

  public focus = (): void => {
    const commentInput = this.focusableRef.current;
    const suggestionInput = this.suggestionRefObj.current;

    if (commentInput) {
      commentInput.focus();
    } else if (suggestionInput) {
      suggestionInput.focus();
    }
  };

  render(): JSX.Element {
    const { isSubmitting, autoFocus } = this.props;
    const isSuggestion = !this.isCommentThreadItem();

    return (
      <form className="form comment-editor__form" onSubmit={this.onSubmit}>
        <LabelFor
          isHidden
          target={(id: Uuid) => (
            <HotkeysHandler
              handlers={{
                onControlEnter: this.handleSubmitThreadItem,
              }}
            >
              {isSuggestion ? (
                <SuggestionInput
                  ref={this.suggestionRefObj}
                  autoFocus={autoFocus}
                  inputId={id}
                  inputValue={this.state.suggestionText}
                  onChange={this.onSuggestionChange}
                  onEscape={this.onCancelItemShortcut}
                />
              ) : (
                <CommentInput
                  autoFocus={autoFocus}
                  debouncedChangesRef={this.debouncedChangesRef}
                  disabled={isSubmitting}
                  editorState={this.state.commentContent}
                  focusableRef={this.focusableRef}
                  onContentChange={this.onCommentChange}
                  onEscape={this.onCancelItemShortcut}
                  onSelectionChange={this.onCommentSelectionChange}
                />
              )}
            </HotkeysHandler>
          )}
        >
          {isSuggestion ? 'Suggest changes' : 'Comment'}
        </LabelFor>
        <NewCommentThreadItemActionsBar
          isSubmitting={isSubmitting}
          onCancelEditing={this.cancelEditing}
          isSubmitButtonDisabled={this.isSubmitButtonDisabled()}
          isSuggestion={isSuggestion}
        />
      </form>
    );
  }
}
