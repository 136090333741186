import React, { useCallback, useRef } from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getIsAiOperationInProgress } from '../../../selectors/getIsAiOperationInProgress.ts';
import { changesDiscarded } from '../../ContentItemEditing/actions/contentItemEditingActions.ts';
import { AiActionInProgressDialog } from '../components/AiActionInProgressDialog.tsx';
import { AssetsUploadingDialog } from '../components/AsssetUploadingDialog.tsx';
import { UnsavedChangesDialog } from '../components/UnsavedChangesDialog.tsx';
import { IPendingChangesDialogProps } from '../types/IPendingChangesDialogProps.type.ts';

export const PendingChangesDialog: React.FC<IPendingChangesDialogProps> = ({
  onCancel,
  onConfirm: baseOnConfirm,
}) => {
  const hasUnsavedAssets = useSelector(
    (state) => state.contentApp.editorUi.editedContentItemVariantUploadingAssets.length > 0,
  );
  const mountedWithUnsavedAssets = useRef(hasUnsavedAssets).current;
  const isAiOperationInProgress = useSelector(getIsAiOperationInProgress);
  const mountedWithAiOperationInProgress = useRef(isAiOperationInProgress).current;

  const dispatch = useDispatch();
  const onConfirm = useCallback(() => {
    dispatch(changesDiscarded());
    baseOnConfirm();
  }, [baseOnConfirm]);

  if (mountedWithAiOperationInProgress) {
    return <AiActionInProgressDialog onCancel={onCancel} onConfirm={onConfirm} />;
  }

  if (mountedWithUnsavedAssets) {
    return <AssetsUploadingDialog onCancel={onCancel} onConfirm={onConfirm} />;
  }

  return <UnsavedChangesDialog onCancel={onCancel} onConfirm={onConfirm} />;
};

PendingChangesDialog.displayName = 'PendingChangesDialog';
