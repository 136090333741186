import { Collection } from '@kontent-ai/utils';
import { Reducer } from '@reduxjs/toolkit';
import { Action } from '../../../../../@types/Action.type.ts';
import {
  ContentItemEditing_ItemAiAction_Finished,
  ContentItemEditing_ItemAiAction_Started,
} from '../../../../../_shared/features/AI/constants/aiActionTypes.ts';
import { PendingOperationId } from '../../../../contentInventory/content/stores/IContentAppStoreState.ts';
import { isItemEditorAiOperationId } from '../../../../contentInventory/content/utils/itemEditorOperationIdUtils.ts';
import {
  ContentItemEditing_AutoDispatchSave_Cancelled,
  ContentItemEditing_AutoDispatchSave_Pending,
  ContentItemEditing_Changes_Discarded,
  ContentItemEditing_Init_Aborted,
  ContentItemEditing_Init_Failed,
  ContentItemEditing_Init_FinishedNoVariant,
  ContentItemEditing_Init_Ready,
  ContentItemEditing_Init_Started,
  ContentItemEditing_ItemElementsSaving_Failed,
  ContentItemEditing_ItemElementsSaving_Finished,
  ContentItemEditing_ItemSaving_Finished,
  ContentItemEditing_PendingAiOperations_Discarded,
  ContentItemEditing_Saving_Failed,
  ContentItemEditing_ShouldAutoDispatchCheck_Finished,
  ContentItemEditing_ShouldAutoDispatchCheck_Pending,
} from '../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

const initialState: ReadonlySet<PendingOperationId> = new Set();

export const pendingOperationIds: Reducer<ReadonlySet<PendingOperationId>, Action> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case ContentItemEditing_AutoDispatchSave_Pending:
      return Collection.addMany(state, action.payload.operationIds);

    case ContentItemEditing_Init_Started:
    case ContentItemEditing_ShouldAutoDispatchCheck_Pending:
    case ContentItemEditing_ItemAiAction_Started:
      return Collection.add(state, action.payload.operationId);

    case ContentItemEditing_Init_Failed:
    case ContentItemEditing_Init_Aborted:
    case ContentItemEditing_Init_Ready:
    case ContentItemEditing_Init_FinishedNoVariant:
    case ContentItemEditing_ItemSaving_Finished:
    case ContentItemEditing_Saving_Failed:
    case ContentItemEditing_ItemAiAction_Finished:
    case ContentItemEditing_ShouldAutoDispatchCheck_Finished:
      return Collection.remove(state, action.payload.operationId);

    case ContentItemEditing_AutoDispatchSave_Cancelled:
    case ContentItemEditing_ItemElementsSaving_Finished:
    case ContentItemEditing_ItemElementsSaving_Failed:
      return Collection.removeMany(state, action.payload.operationIds);

    case ContentItemEditing_PendingAiOperations_Discarded:
      return Collection.filter(state, (id) => !isItemEditorAiOperationId(id));

    case ContentItemEditing_Changes_Discarded:
      return initialState;

    default:
      return state;
  }
};
