import React from 'react';
import { modalWithPropertiesOpened } from '../../../../../../_shared/actions/sharedActions.ts';
import { ModalDialogType } from '../../../../../../_shared/constants/modalDialogType.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { ActiveCapabilityType } from '../../../../../../_shared/models/activeCapability.type.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { UnpublishDateSection as UnpublishDateSectionComponent } from '../../components/details/UnpublishDateSection.tsx';

export const UnpublishDateSection: React.FC = () => {
  const unpublishDate = useSelector(
    (s) => s.contentApp.editedContentItemVariant?.assignment.scheduledToUnpublishAt ?? null,
  );
  const canCancelUnpublish = useSelector((s) =>
    s.contentApp.editorUi.activeCapabilities.variantCapabilities.includes(
      ActiveCapabilityType.Unpublish,
    ),
  );

  const dispatch = useDispatch();

  const cancelScheduledUnpublish = () =>
    dispatch(
      modalWithPropertiesOpened({
        dialogType: ModalDialogType.CancelItemScheduledUnpublishConfirmationDialog,
        actionOrigin: ContentItemEditingEventOrigins.ItemDetails,
      }),
    );

  return (
    <UnpublishDateSectionComponent
      canCancelUnpublish={canCancelUnpublish}
      cancelScheduledUnpublish={cancelScheduledUnpublish}
      unpublishDate={unpublishDate}
    />
  );
};

UnpublishDateSection.displayName = 'UnpublishDateSection';
