import Immutable from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../../../@types/Dispatcher.type.ts';
import { trackUserEventWithData } from '../../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../../_shared/constants/trackedEvent.ts';
import {
  ContentItemEditingEventOrigins,
  ContentItemEditingEventTypes,
} from '../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { ElementType } from '../../../../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  CustomElementSelectionDialog as CustomElementSelectionDialogComponent,
  ICustomElementSelectionDialogDispatchProps,
  ICustomElementSelectionDialogOwnProps,
} from '../../../components/elements/customElement/CustomElementSelectionDialog.tsx';

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: ICustomElementSelectionDialogOwnProps,
): ICustomElementSelectionDialogDispatchProps => ({
  onAssetSelected: (id: Uuid) => {
    dispatch(
      trackUserEventWithData(TrackedEvent.ContentItemEditing, {
        action: ContentItemEditingEventTypes.AssignAsset,
        origin: ContentItemEditingEventOrigins.Paper,
        contentElementType: ElementType.Custom,
        selectedItemsCount: 1,
      }),
    );
    ownProps.onAssetSelected(id);
  },
  onAssetsSelected: (ids: Immutable.List<Uuid>) => {
    dispatch(
      trackUserEventWithData(TrackedEvent.ContentItemEditing, {
        action: ContentItemEditingEventTypes.AssignAsset,
        origin: ContentItemEditingEventOrigins.Paper,
        contentElementType: ElementType.Custom,
        selectedItemsCount: ids.count(),
      }),
    );
    ownProps.onAssetsSelected(ids);
  },
  onItemSelected: (id: Uuid) => {
    dispatch(
      trackUserEventWithData(TrackedEvent.ContentItemEditing, {
        action: ContentItemEditingEventTypes.LinkContentItem,
        allowedContentTypesCount: 0,
        origin: ContentItemEditingEventOrigins.Paper,
        contentElementType: ElementType.Custom,
        selectedItemsCount: 1,
      }),
    );
    ownProps.onItemSelected(id);
  },
  onItemsSelected: (ids: ReadonlyArray<Uuid>) => {
    dispatch(
      trackUserEventWithData(TrackedEvent.ContentItemEditing, {
        action: ContentItemEditingEventTypes.LinkContentItem,
        allowedContentTypesCount: 0,
        origin: ContentItemEditingEventOrigins.Paper,
        contentElementType: ElementType.Custom,
        selectedItemsCount: ids.length,
      }),
    );
    ownProps.onItemsSelected(ids);
  },
});

export const CustomElementSelectionDialog: React.ComponentType<
  React.PropsWithChildren<ICustomElementSelectionDialogOwnProps>
> = connect(null, mapDispatchToProps)(CustomElementSelectionDialogComponent);
