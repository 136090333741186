import React, { useEffect } from 'react';
import { loadListingItemsWithAllVariants } from '../../../data/actions/thunkDataActions.ts';
import { getActiveLanguagesWithFallbackChain } from '../../../data/reducers/languages/selectors/getLanguages.ts';
import { FallbacksPopoverContent as Component } from '../../components/FallbacksForLinkedContent/FallbacksPopoverContent.tsx';
import { useDispatch } from '../../hooks/useDispatch.ts';
import { useSelector } from '../../hooks/useSelector.ts';
import { getSelectedLanguageId } from '../../selectors/getSelectedLanguageId.ts';
import { getTranslatedVariants } from '../../selectors/getTranslatedVariants.ts';

type Props = {
  readonly itemId: Uuid;
  readonly onClose: () => void;
};

export const FallbacksPopoverContent: React.FC<Props> = ({ itemId, onClose }) => {
  const activeLanguages = useSelector((s) => getActiveLanguagesWithFallbackChain(s.data.languages));
  const selectedLanguageId = useSelector(getSelectedLanguageId);
  const selectedLinkedItem = useSelector((s) => s.data.listingContentItems.byId.get(itemId));
  const translatedVariants = useSelector((s) => getTranslatedVariants(itemId, s));

  const dispatch = useDispatch();

  useEffect(() => {
    if (translatedVariants === null) {
      dispatch(loadListingItemsWithAllVariants([itemId]));
    }
  }, [translatedVariants, itemId]);

  if (!selectedLinkedItem || !selectedLanguageId) {
    return null;
  }

  return (
    <Component
      activeLanguages={activeLanguages}
      translatedVariants={translatedVariants}
      selectedLinkedItem={selectedLinkedItem}
      selectedLanguageId={selectedLanguageId}
      onClose={onClose}
    />
  );
};

FallbacksPopoverContent.displayName = 'FallbacksPopoverContent';
