import { InvariantException } from '@kontent-ai/errors';
import { memoize } from '@kontent-ai/memoization';
import { assert, Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';
import {
  IValidationResult,
  emptyValidationResult,
} from '../../../_shared/utils/validation/ValidationResult.ts';
import { IAssetRendition } from '../../../data/models/assetRenditions/AssetRendition.ts';
import { IAsset } from '../../../data/models/assets/Asset.ts';
import { ITaxonomyGroup } from '../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { Languages } from '../../../data/models/languages/Language.ts';
import { IListingContentItem } from '../../../data/models/listingContentItems/IListingContentItem.ts';
import { ICompiledContentType } from '../../contentInventory/content/models/CompiledContentType.ts';
import { TypeElement } from '../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { isEditableElement } from '../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { getAllUsedContentComponents } from '../../richText/plugins/contentComponents/api/editorContentComponentUtils.ts';
import { IContentComponent } from '../models/contentItem/ContentComponent.ts';
import { ICompiledContentItemElementData } from '../models/contentItemElements/ICompiledContentItemElement.ts';
import { IRichTextItemElement } from '../models/contentItemElements/RichTextItemElement.ts';
import { getItemElementOrDefault } from '../stores/utils/contentItemElementsUtils.ts';
import { ItemElementErrorResult } from './elementErrorCheckers/types/Errors.ts';
import { IGetItemElementValidationResults } from './getItemElementValidationResult.ts';
import { ItemElementFriendlyWarningResult } from './itemElementFriendlyWarningCheckers/types/FriendlyWarnings.ts';
import { ItemElementWarningResult } from './itemElementWarningCheckers/types/Warnings.ts';

const aggregateValidationResults = memoize.weak(
  (...results: ReadonlyArray<IValidationResult>): IValidationResult => {
    const warnings = new Map<UuidPath, ItemElementWarningResult>(
      results.flatMap((result) => Collection.getEntries(result.warnings)),
    );
    const friendlyWarnings = new Map<UuidPath, ItemElementFriendlyWarningResult>(
      results.flatMap((result) => Collection.getEntries(result.friendlyWarnings)),
    );
    const errors = new Map<UuidPath, ItemElementErrorResult>(
      results.flatMap((result) => Collection.getEntries(result.errors)),
    );

    return {
      warnings,
      friendlyWarnings,
      errors,
    };
  },
);

const getComponentElements = (
  component: IContentComponent,
  elements: ReadonlyArray<TypeElement>,
): ReadonlyArray<ICompiledContentItemElementData> =>
  elements
    .filter(isEditableElement)
    .map((typeElement) => getItemElementOrDefault(typeElement, component.elements));

const getContentComponentValidationResults = memoize.weak(
  (
    component: IContentComponent,
    contentComponentTypeIds: ReadonlyMap<Uuid, Uuid>,
    getItemElementValidationResult: IGetItemElementValidationResults,
    loadedContentTypes: Immutable.Map<Uuid, ICompiledContentType>,
    loadedAssets: Immutable.Map<Uuid, IAsset>,
    loadedAssetRenditions: ReadonlyMap<Uuid, IAssetRendition>,
    taxonomyGroups: Immutable.Map<Uuid, ITaxonomyGroup>,
    loadedEntries: Immutable.Map<Uuid, IListingContentItem>,
    currentLanguageId: Uuid,
    languages: Languages,
    areAssetRenditionsEnabled: boolean,
  ): IValidationResult => {
    const contentType = loadedContentTypes.get(component.contentTypeId);
    const componentElements = getComponentElements(component, contentType?.contentElements ?? []);

    const elementResults = componentElements.map((componentItemElement) => {
      const typeElement = contentType?.contentElements.find(
        ({ elementId }) => elementId === componentItemElement.elementId,
      );
      if (!typeElement || !isEditableElement(typeElement)) {
        return emptyValidationResult;
      }

      return getItemElementValidationResult(
        typeElement,
        componentItemElement,
        loadedContentTypes,
        loadedAssets,
        loadedAssetRenditions,
        taxonomyGroups,
        loadedEntries,
        currentLanguageId,
        languages,
        areAssetRenditionsEnabled,
        component.id,
        contentComponentTypeIds,
      );
    });

    const allResults = aggregateValidationResults(...elementResults);
    return allResults;
  },
);

export const accumulateContentComponentValidationResults = (
  getItemElementValidationResult: IGetItemElementValidationResults,
  element: IRichTextItemElement,
  contentComponentTypeIds: ReadonlyMap<Uuid, Uuid>,
  loadedContentTypes: Immutable.Map<Uuid, ICompiledContentType>,
  loadedAssets: Immutable.Map<Uuid, IAsset>,
  loadedAssetRenditions: ReadonlyMap<Uuid, IAssetRendition>,
  taxonomyGroups: Immutable.Map<Uuid, ITaxonomyGroup>,
  loadedEntries: Immutable.Map<Uuid, IListingContentItem>,
  currentLanguageId: Uuid,
  languages: Languages,
  areAssetRenditionsEnabled: boolean,
): IValidationResult => {
  if (!loadedContentTypes) {
    throw InvariantException('No content type loaded.');
  }

  const contentComponents = getAllUsedContentComponents(
    element._editorState.getCurrentContent(),
    element.contentComponents,
  );
  const componentResults = contentComponents.map((contentComponent, index) => {
    assert(
      contentComponent,
      () =>
        `Content component at index ${index} is falsy. Value: ${JSON.stringify(contentComponent)}`,
    );
    return getContentComponentValidationResults(
      contentComponent,
      contentComponentTypeIds,
      getItemElementValidationResult,
      loadedContentTypes,
      loadedAssets,
      loadedAssetRenditions,
      taxonomyGroups,
      loadedEntries,
      currentLanguageId,
      languages,
      areAssetRenditionsEnabled,
    );
  });

  const allResults = aggregateValidationResults(...componentResults);
  return allResults;
};
