import { HTMLProps, useCallback, useMemo } from 'react';
import { stopPropagation } from '../../../../_shared/utils/func/functionalTools.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { GetEditorRef, useGetEditorRef } from '../../editorCore/hooks/useGetEditorRef.ts';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { Apply, EditorPlugin, Render } from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import { withDisplayName } from '../../editorCore/utils/withDisplayName.ts';

export type WrapperProps = Readonly<Omit<HTMLProps<HTMLDivElement>, 'ref' | 'onMouseDown'>>;

type WrapperPluginState = {
  readonly wrapperProps: WrapperProps;
  readonly getWrapperRef: GetEditorRef<HTMLDivElement>;
};

export type WrapperPlugin = EditorPlugin<WrapperPluginState>;

const initialWrapperProps: WrapperProps = {};

const render: Decorator<Render<WrapperPlugin>> = (baseRender) => (state) => (
  <div
    ref={state.getWrapperRef()}
    // Propagation of mouse down/up event is disabled
    // to allow proper timely reaction to selection change
    // to avoid blur event for the already focused comment that is raised on click outside the comment
    onMouseDown={stopPropagation}
    {...state.wrapperProps}
  >
    {baseRender(state)}
  </div>
);

export const useWrapper: PluginCreator<WrapperPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('WrapperPlugin', {
        ComposedEditor: (props) => {
          const getWrapperRef = useGetEditorRef<HTMLDivElement>();

          const apply: Apply<WrapperPlugin> = useCallback(
            (state) => {
              state.render.decorate(render);
              return {
                wrapperProps: initialWrapperProps,
                getWrapperRef,
              };
            },
            [getWrapperRef],
          );

          return useEditorWithPlugin(baseEditor, props, { apply });
        },
      }),
    [baseEditor],
  );
