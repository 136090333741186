import PropTypes, { ValidationMap } from 'prop-types';
import {
  ConditionPropTypeShape,
  TypeElementCondition,
  convertConditionFromServerModel,
} from '../../../../../../_shared/models/utils/TypeElementCondition.ts';
import { PropTypeOrNull } from '../../../../../../_shared/utils/propTypesValidators.ts';
import { ContentTypeElementServerModel } from '../../../../../../repositories/serverModels/contentModels/sharedContentTypeModels.type.ts';
import {
  TypeElementType,
  TypeElementTypes,
} from '../../../../../contentInventory/content/models/ContentItemElementType.ts';
import { AssetFileTypeOption } from '../../../../../contentInventory/content/models/assetFileTypeOptions.ts';
import { FileSizeUnit } from '../../../../../contentInventory/content/models/fileSizeOptions.ts';
import { QuantityOption } from '../../../../../contentInventory/content/models/quantityOptions.ts';
import { UnitOption } from '../../../../../contentInventory/content/models/textLengthOptions.ts';
import {
  TableBlockCategoryFeature,
  TextBlockTypeFeature,
  TextFormattingFeature,
  TopLevelBlockCategoryFeature,
} from '../../../../../richText/plugins/apiLimitations/api/editorLimitationUtils.ts';
import { TypeElementConfigurability } from './elementConfigurabilityOptions.ts';

export interface IBaseTypeElementData {
  readonly _configurability: TypeElementConfigurability;
  readonly codename: string | null;
  readonly condition: TypeElementCondition | null;
  readonly contentGroupId: Uuid | null;
  readonly elementId: Uuid;
  readonly guidelines: string;
  readonly isNonLocalizable: boolean;
  readonly isRequired: boolean;
  readonly name: string;
  readonly type: TypeElementType;
}

export type BaseTypeElementEditableData = Omit<IBaseTypeElementData, '_configurability' | 'type'>;

export const EmptyBaseTypeElementData: BaseTypeElementEditableData = {
  codename: null,
  condition: null,
  contentGroupId: null,
  elementId: '',
  guidelines: '',
  isNonLocalizable: false,
  isRequired: false,
  name: '',
};

export const BaseTypeElementDataValidationMap: ValidationMap<IBaseTypeElementData> = {
  codename: PropTypeOrNull(PropTypes.string),
  condition: PropTypeOrNull(ConditionPropTypeShape),
  contentGroupId: PropTypeOrNull(PropTypes.string),
  elementId: PropTypes.string.isRequired,
  guidelines: PropTypes.string.isRequired,
  isNonLocalizable: PropTypes.bool.isRequired,
  isRequired: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(TypeElementTypes).isRequired,
  _configurability: PropTypes.oneOf(Object.values(TypeElementConfigurability)).isRequired,
};

export interface ITypeElementDataWithNumberOfItemsLimit {
  readonly _quantityUnitOption: QuantityOption;
  readonly maxItems: string | null;
  readonly minItems: string | null;
}

export const TypeElementDataWithNumberOfItemsLimitValidationMap: ValidationMap<ITypeElementDataWithNumberOfItemsLimit> =
  {
    _quantityUnitOption: PropTypes.oneOf(Object.values(QuantityOption)).isRequired,
    maxItems: PropTypeOrNull(PropTypes.string),
    minItems: PropTypeOrNull(PropTypes.string),
  };

export interface ITypeElementDataWithFileSizeLimit {
  readonly _fileSizeUnitOption: FileSizeUnit;
  readonly fileSize: string | null;
}

export const TypeElementDataWithFileSizeLimitValidationMap: ValidationMap<ITypeElementDataWithFileSizeLimit> =
  {
    _fileSizeUnitOption: PropTypes.oneOf(Object.values(FileSizeUnit)).isRequired,
    fileSize: PropTypeOrNull(PropTypes.string),
  };

export interface ITypeElementDataWithAssetDimensions {
  readonly _heightOption: QuantityOption;
  readonly _widthOption: QuantityOption;
  readonly maxHeight: string | null;
  readonly maxWidth: string | null;
  readonly minHeight: string | null;
  readonly minWidth: string | null;
}

export const TypeElementDataWithAssetDimensionsValidationMap: ValidationMap<ITypeElementDataWithAssetDimensions> =
  {
    _heightOption: PropTypes.oneOf(Object.values(QuantityOption)).isRequired,
    _widthOption: PropTypes.oneOf(Object.values(QuantityOption)).isRequired,
    maxHeight: PropTypeOrNull(PropTypes.string),
    maxWidth: PropTypeOrNull(PropTypes.string),
    minHeight: PropTypeOrNull(PropTypes.string),
    minWidth: PropTypeOrNull(PropTypes.string),
  };

export interface ITypeElementDataWithAssetTypeLimitation {
  readonly fileType: AssetFileTypeOption;
}

export const TypeElementDataWithAssetTypeLimitationValidationMap: ValidationMap<ITypeElementDataWithAssetTypeLimitation> =
  {
    fileType: PropTypes.oneOf(Object.values(AssetFileTypeOption)).isRequired,
  };

export interface ITypeElementDataWithAllowedItemLinkTypesLimitation {
  readonly allowedItemLinkTypes: ReadonlyArray<Uuid>;
}

export const TypeElementWithAllowedItemLinkTypesLimitationValidationMap: ValidationMap<ITypeElementDataWithAllowedItemLinkTypesLimitation> =
  {
    allowedItemLinkTypes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  };

export interface ITypeElementDataWithAllowedContentTypesLimitation {
  readonly allowedTypes: ReadonlyArray<Uuid>;
}

export const TypeElementWithAllowedContentTypesLimitationValidationMap: ValidationMap<ITypeElementDataWithAllowedContentTypesLimitation> =
  {
    allowedTypes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  };

export interface ITypeElementDataWithTextLimit {
  readonly _lengthUnitOption: UnitOption;
  readonly maxChars: string | null;
  readonly maxWords: string | null;
}

export const TypeElementDataWithTextLimitValidationMap: ValidationMap<ITypeElementDataWithTextLimit> =
  {
    _lengthUnitOption: PropTypes.oneOf(Object.values(UnitOption)).isRequired,
    maxChars: PropTypeOrNull(PropTypes.string),
    maxWords: PropTypeOrNull(PropTypes.string),
  };

export type ValidationRegex = {
  readonly regex: string;
  readonly flags: string | null;
  readonly validationMessage: string | null;
  readonly isActive: boolean;
};

export const emptyValidationRegex: ValidationRegex = {
  regex: '',
  flags: null,
  validationMessage: null,
  isActive: false,
};

export interface ITypeElementDataWithValidationRegex {
  readonly validationRegex: ValidationRegex | null;
}

export const TypeElementDataWithValidationRegexValidationMap: ValidationMap<ITypeElementDataWithValidationRegex> =
  {
    validationRegex: PropTypeOrNull(
      PropTypes.shape({
        regex: PropTypes.string.isRequired,
        flags: PropTypeOrNull(PropTypes.string),
        validationMessage: PropTypeOrNull(PropTypes.string),
        isActive: PropTypes.bool.isRequired,
      }),
    ),
  };

export interface ITypeElementDataWithLimitationCategories {
  readonly allowedBlocks: ReadonlySet<TopLevelBlockCategoryFeature>;
  readonly allowedTextBlocks: ReadonlySet<TextBlockTypeFeature>;
  readonly allowedTextFormatting: ReadonlySet<TextFormattingFeature>;
  readonly allowedTableBlocks: ReadonlySet<TableBlockCategoryFeature>;
  readonly allowedTableTextBlocks: ReadonlySet<TextBlockTypeFeature>;
  readonly allowedTableTextFormatting: ReadonlySet<TextFormattingFeature>;
}

export const TypeElementDataWithLimitationCategoriesValidationMap: ValidationMap<ITypeElementDataWithLimitationCategories> =
  {
    allowedBlocks: PropTypes.instanceOf(Set<TopLevelBlockCategoryFeature>).isRequired,
    allowedTextBlocks: PropTypes.instanceOf(Set<TextBlockTypeFeature>).isRequired,
    allowedTextFormatting: PropTypes.instanceOf(Set<TextFormattingFeature>).isRequired,
    allowedTableBlocks: PropTypes.instanceOf(Set<TableBlockCategoryFeature>).isRequired,
    allowedTableTextBlocks: PropTypes.instanceOf(Set<TextBlockTypeFeature>).isRequired,
    allowedTableTextFormatting: PropTypes.instanceOf(Set<TextFormattingFeature>).isRequired,
  };

export function getBaseTypeElementDataFromServerModel(
  serverModel: ContentTypeElementServerModel,
): Omit<IBaseTypeElementData, '_configurability' | 'type'> {
  return {
    codename: serverModel.codeName,
    condition: convertConditionFromServerModel(serverModel.conditions),
    contentGroupId: serverModel.contentGroupId,
    elementId: serverModel.elementId,
    guidelines: serverModel.guidelines || EmptyBaseTypeElementData.guidelines,
    isNonLocalizable: !!serverModel.isNonLocalizable,
    isRequired: !!serverModel.isRequired,
    name: serverModel.name,
  };
}
