import { IconButton } from '@kontent-ai/component-library/Button';
import { Clipboard } from '@kontent-ai/component-library/Clipboard';
import { InputState, InputType } from '@kontent-ai/component-library/Input';
import { ICancellablePromise, delay, swallowCancelledPromiseError } from '@kontent-ai/utils';
import PropTypes from 'prop-types';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { EnterHotkeyDisabler } from '../../../../_shared/components/Hotkeys/EnterHotkeyDisabler.tsx';
import {
  DataUiAction,
  DataUiInput,
  getDataUiActionAttribute,
  getDataUiInputAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly readonly?: boolean;
  readonly errorMessage: string | undefined;
  readonly inputId: Uuid;
  readonly onChange: (value: string) => void;
  readonly onGenerateNewSecret: () => Promise<string | undefined>;
  readonly value: string | undefined;
};

export const SecretField = forwardRef<HTMLInputElement, Props>(
  ({ readonly, errorMessage, inputId, onChange, onGenerateNewSecret, value }, ref) => {
    const showMessageDelayRef = useRef<ICancellablePromise>();

    const [currentMessage, setCurrentMessage] = useState<string | null>(null);
    const [secretGenerationError, setSecretGenerationError] = useState<string | null>(null);

    const onShowMessage = useCallback((message: string, hideAfter: number = 1000): void => {
      setCurrentMessage(message);

      showMessageDelayRef.current?.cancel();
      showMessageDelayRef.current = delay(hideAfter)
        .then(() => {
          setCurrentMessage(null);
        })
        .catch(swallowCancelledPromiseError);
    }, []);

    const generateNewSecret = useCallback(async (): Promise<void> => {
      setCurrentMessage('Generating secret...');
      setSecretGenerationError('');

      try {
        const secret = await onGenerateNewSecret();

        if (secret) {
          onChange(secret);
          onShowMessage('New secret generated!');
        } else {
          Promise.reject('Invalid secret');
        }
      } catch {
        setSecretGenerationError(
          'I wasn’t able to generate new secret for you. Please contact us for help.',
        );
        setCurrentMessage(null);
      }
    }, [onGenerateNewSecret, onShowMessage, onChange]);

    useEffect(() => {
      if (!value) {
        generateNewSecret();
      }

      return showMessageDelayRef.current?.cancel;
    }, [value, generateNewSecret]);

    return (
      <EnterHotkeyDisabler>
        <Clipboard
          auxiliaryElement={
            <IconButton
              buttonState={readonly ? 'disabled' : 'default'}
              buttonStyle="tertiary"
              iconName="RotateDoubleRight"
              onClick={generateNewSecret}
              size="medium"
              tooltipPlacement="top-start"
              tooltipText="Generate new secret"
              {...getDataUiActionAttribute(DataUiAction.Regenerate)}
            />
          }
          caption={errorMessage || secretGenerationError || ''}
          id={inputId}
          inputState={
            errorMessage || secretGenerationError ? InputState.Alert : InputState.ReadOnly
          }
          overlayMessage={currentMessage || ''}
          ref={ref}
          type={InputType.Password}
          value={value || ''}
          {...getDataUiInputAttribute(DataUiInput.Secret)}
        />
      </EnterHotkeyDisabler>
    );
  },
);

SecretField.displayName = 'SecretField';
SecretField.propTypes = {
  readonly: PropTypes.bool,
  errorMessage: PropTypes.string,
  inputId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onGenerateNewSecret: PropTypes.func.isRequired,
  value: PropTypes.string,
};
