import { Action } from '../../../../../@types/Action.type.ts';
import { ContentItemIdString } from '../../../../../_shared/models/ContentItemId.type.ts';
import {
  Data_ContentStatusTaskCounts_Started,
  Data_ContentStatusTaskCounts_Success,
} from '../../constants/contentStatusActionTypes.ts';
import {
  ValueWithLoadingStatus,
  commentsOrTasksLoadedReducer,
  commentsOrTasksStartedReducer,
} from '../contentStatusComments/byId.ts';

export type ContentStatusTaskCount = ValueWithLoadingStatus<number>;

const initialState: ReadonlyMap<ContentItemIdString, ContentStatusTaskCount> = new Map();

export function byId(
  state: ReadonlyMap<ContentItemIdString, ContentStatusTaskCount> = initialState,
  action: Action,
): ReadonlyMap<ContentItemIdString, ContentStatusTaskCount> {
  switch (action.type) {
    case Data_ContentStatusTaskCounts_Success: {
      return commentsOrTasksLoadedReducer(state, action.payload);
    }

    case Data_ContentStatusTaskCounts_Started: {
      return commentsOrTasksStartedReducer(state, action.payload);
    }

    default:
      return state;
  }
}
