import { Box } from '@kontent-ai/component-library/Box';
import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import ImmutablePropTypes from 'immutable-prop-types';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Breadcrumbs as NewBreadcrumbs } from '../../../component-library/components/Breadcrumbs/Breadcrumbs.tsx';
import { IBreadcrumbsItem } from '../../../component-library/components/Breadcrumbs/components/BreadcrumbsItem.tsx';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../utils/dataAttributes/DataUiAttributes.ts';

export type NavigationBreadcrumb = {
  readonly label: string;
  readonly to: string;
};

type ConvertListToArray<T> = (list: Immutable.List<T>) => ReadonlyArray<T>;
const toList: ConvertListToArray<IBreadcrumbsItem> = (list) => {
  return list.toArray();
};
const memoizedToArray = memoize.weak(toList);

export interface IBreadcrumbsProps {
  readonly breadcrumbs: Immutable.List<NavigationBreadcrumb>;
  readonly renderSavingStatusComponent?: () => React.ReactNode;
}

const propTypes: PropTypeMap<IBreadcrumbsProps> = {
  breadcrumbs: ImmutablePropTypes.list,
  renderSavingStatusComponent: PropTypes.func,
};

export const Breadcrumbs: React.FC<IBreadcrumbsProps> = ({
  renderSavingStatusComponent,
  breadcrumbs,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <Box
      display="flex"
      flexBasis="auto"
      flexGrow={1}
      flexShrink={1}
      minWidth={0}
      ref={containerRef}
    >
      <NewBreadcrumbs {...getDataUiCollectionAttribute(DataUiCollection.Breadcrumbs)}>
        <NewBreadcrumbs.Items containerRef={containerRef} items={memoizedToArray(breadcrumbs)} />
        <NewBreadcrumbs.Cell>{renderSavingStatusComponent?.()}</NewBreadcrumbs.Cell>
      </NewBreadcrumbs>
    </Box>
  );
};

Breadcrumbs.propTypes = propTypes;
Breadcrumbs.displayName = 'Breadcrumbs';
