import { AriaDialogProps } from '@react-types/dialog';
import React, { WeakValidationMap } from 'react';
import {
  BaseModalDialogComponent,
  IBaseModalDialogProps,
  baseModalDialogPropTypes,
} from './BaseModalDialogComponent.tsx';

export interface IFullScreenModalDialogProps extends IBaseModalDialogProps {}

const propTypes: Required<
  WeakValidationMap<Omit<IFullScreenModalDialogProps, keyof AriaDialogProps>>
> = {
  ...baseModalDialogPropTypes,
};

export const FullScreenModalDialog = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<IFullScreenModalDialogProps>
>(({ children, ...props }, forwardedRef) => (
  <BaseModalDialogComponent isFullScreen withDividers ref={forwardedRef} {...props}>
    {children}
  </BaseModalDialogComponent>
));

FullScreenModalDialog.displayName = 'FullScreenModalDialog';
FullScreenModalDialog.propTypes = propTypes;
