import { useCallback, useEffect, useRef, useState } from 'react';
import { AiActionName } from '../../../../../repositories/serverModels/ai/AiActionName.type.ts';
import { createCancelOperationParams } from '../../../../../repositories/serverModels/ai/AiServerModels.cancelOperation.ts';
import { AiActionNameToParameters } from '../../../../../repositories/serverModels/ai/AiServerModels.params.ts';
import { useAiTaskManager } from '../../../../contexts/AiTaskManagerProvider.tsx';
import { repositoryCollection } from '../../../../repositories/repositories.ts';
import { AiActionNameToMessagePayload } from '../../../../services/signalR/signalRClient.type.ts';
import { OnAiTaskUpdate } from '../../types/OnAiTaskUpdate.type.ts';
import { IsFinished, ResultSelector } from '../../types/ResultSelector.type.ts';
import { MatchAiTask } from './matchAiTask.type.ts';

/**
 * Hook that can create a new AI task for AI operation. When all necessary parameters provided, it takes over already running AI task when re-mounted.
 * @param actionName An AI action name.
 * @param selectResult Transforms AI action messages and context into a result.
 * @param matchAiTask A function that receives information about all running AI tasks and returns an id of the task the hook should take over.
 * @param onUpdateInBackground A callback that is called when the hook is not rendered and new AI message related to the AI task is received.
 */
export const useAiTask = <TActionName extends AiActionName, TResult extends IsFinished>(
  actionName: TActionName,
  selectResult: ResultSelector<TResult>,
  matchAiTask?: MatchAiTask,
  onUpdateInBackground?: OnAiTaskUpdate<AiActionNameToMessagePayload[TActionName]>,
): {
  readonly cancel: () => Promise<void>;
  readonly isRunning: boolean;
  readonly result: ReturnType<typeof selectResult>;
  readonly run: (
    actionParameters: AiActionNameToParameters[TActionName],
    aiTaskContext?: ReadonlyRecord<string, unknown>,
  ) => Promise<void>;
} => {
  const aiTaskManager = useAiTaskManager();

  const [aiTaskId, setAiTaskId] = useState<Uuid | null>(
    () => matchAiTask?.(aiTaskManager.getAllTasks())?.id ?? null,
  );

  const selectResultRef = useRef(selectResult);
  selectResultRef.current = selectResult;

  const [result, setResult] = useState(() => {
    const emptyResult = selectResultRef.current([], { hasTimedOut: false });
    if (!aiTaskId) return emptyResult;
    const currentTaskActionStatus = aiTaskManager.getTaskActionStatus(aiTaskId);
    const currentTaskActionMessages = aiTaskManager.getTaskActionMessages(aiTaskId);
    if (!currentTaskActionStatus || !currentTaskActionMessages) return emptyResult;
    return selectResultRef.current(currentTaskActionMessages, currentTaskActionStatus);
  });

  const cancel = useCallback(async (): Promise<void> => {
    setResult(selectResultRef.current([], { hasTimedOut: false }));

    if (!aiTaskId) return;

    setAiTaskId(null);

    await aiTaskManager.cancel(aiTaskId, (operationId) =>
      repositoryCollection.aiRepository.cancelAction(createCancelOperationParams(operationId)),
    );
  }, [aiTaskId, aiTaskManager]);

  useEffect(() => {
    if (!aiTaskId) return;

    aiTaskManager.changeProcessMessagesCallback<typeof actionName>(
      aiTaskId,
      (messages, aiActionStatus) => {
        const actionResult = selectResultRef.current(messages, aiActionStatus);
        setResult(actionResult);
        return actionResult;
      },
    );

    return () => {
      if (onUpdateInBackground) {
        aiTaskManager.changeProcessMessagesCallback<typeof actionName>(
          aiTaskId,
          onUpdateInBackground,
        );
      }
    };
  }, [aiTaskId, aiTaskManager, onUpdateInBackground]);

  const run = useCallback(
    async (
      actionParameters: AiActionNameToParameters[TActionName],
      aiTaskContext?: ReadonlyRecord<string, unknown>,
    ): Promise<void> => {
      cancel();

      const taskId = await aiTaskManager.createTask(
        actionName,
        actionParameters,
        (parameters) => repositoryCollection.aiRepository.createAction(parameters),
        (messages, aiActionStatus) => {
          const actionResult = selectResultRef.current(messages, aiActionStatus);
          setResult(actionResult);
          return actionResult;
        },
        aiTaskContext,
      );

      setAiTaskId(taskId);
    },
    [cancel, aiTaskManager, actionName],
  );

  const cleanupCancelRef = useRef<() => Promise<void>>(() => Promise.resolve());
  cleanupCancelRef.current = async (): Promise<void> => {
    if (!aiTaskId || onUpdateInBackground) return;
    await aiTaskManager.cancel(aiTaskId, (operationId) =>
      repositoryCollection.aiRepository.cancelAction(createCancelOperationParams(operationId)),
    );
  };

  useEffect(() => {
    return () => {
      cleanupCancelRef.current();
    };
  }, []);

  return {
    isRunning: !!aiTaskId && !result.isFinished,
    result,
    cancel,
    run,
  };
};
