import Immutable from 'immutable';
import ImmutablePropTypes from 'immutable-prop-types';
import React from 'react';
import { DataTable } from '../../../../../_shared/components/DataTable/DataTable.tsx';
import { DataTableHeadRow } from '../../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import { DataTableNameCell } from '../../../../../_shared/components/DataTable/DataTableNameCell.tsx';
import { DataTableRow } from '../../../../../_shared/components/DataTable/DataTableRow.tsx';
import { LinkDataTableCell } from '../../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { LastUpdatedAt } from '../../../../../_shared/components/LastUpdatedAt.tsx';
import { WorkflowStatusTagForVariant } from '../../../../../_shared/containers/Workflow/WorkflowStatusTagForVariant.tsx';
import { OrderByDirection } from '../../../../../_shared/models/OrderBy.ts';
import { DataUiCollection } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ViewItemsProps } from './ListingView.tsx';

const headColumns = Immutable.List([
  {
    columnName: 'Name',
    orderBy: OrderByDirection.None,
  },
  {
    columnName: 'Content type',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--4',
  },
  {
    columnName: 'Workflow step',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--5',
  },
  {
    columnName: 'Last updated',
    orderBy: OrderByDirection.Descending,
    className: 'data-table__column--4',
  },
]);

export const WorkflowStepView: React.FC<ViewItemsProps> = ({ contentItems }) => (
  <DataTable
    dataUiCollectionName={DataUiCollection.ContentItems}
    header={<DataTableHeadRow columns={headColumns} />}
  >
    {contentItems.toArray().map((contentItem) => {
      const {
        contentType,
        id,
        lastUpdatedAt,
        linkTo,
        name,
        publishingState,
        scheduledToPublishAt,
        scheduledToUnpublishAt,
        workflowStatus,
      } = contentItem;
      return (
        <DataTableRow dataUiObjectName={name} id={id} key={id}>
          <DataTableNameCell contentItem={contentItem} />
          <LinkDataTableCell linkPath={linkTo} title={contentType} openInNewTab>
            {contentType}
          </LinkDataTableCell>
          <LinkDataTableCell linkPath={linkTo} openInNewTab>
            <WorkflowStatusTagForVariant
              workflowStatus={workflowStatus}
              publishingState={publishingState}
              scheduledToPublishAt={scheduledToPublishAt}
              scheduledToUnpublishAt={scheduledToUnpublishAt}
            />
          </LinkDataTableCell>
          <LinkDataTableCell linkPath={linkTo} openInNewTab>
            <LastUpdatedAt time={lastUpdatedAt} />
          </LinkDataTableCell>
        </DataTableRow>
      );
    })}
  </DataTable>
);

WorkflowStepView.displayName = 'WorkflowStepView';
const propTypes: PropTypesShape<ViewItemsProps> = {
  contentItems: ImmutablePropTypes.list.isRequired,
};
WorkflowStepView.propTypes = propTypes;
