import { InvariantException } from '@kontent-ai/errors';
import React, { useEffect, useState } from 'react';
import { Loader } from '../../../_shared/components/Loader.tsx';
import { RetrievingItemsMessage } from '../../../_shared/components/RetrievingItemsMessage.tsx';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { initializePreviewConfiguration } from '../actions/thunkPreviewConfigurationActions.ts';
import { PreviewConfiguration } from './PreviewConfiguration.tsx';

enum InitStatus {
  Init = 'Init',
  Success = 'Success',
  Failed = 'Failed',
}

export const PreviewConfigurationApp: React.FC = () => {
  const dispatch = useDispatch();

  const [initStatus, setInitStatus] = useState<InitStatus>(InitStatus.Init);

  useEffect(() => {
    dispatch(initializePreviewConfiguration())
      .then(() => setInitStatus(InitStatus.Success))
      .catch(() => setInitStatus(InitStatus.Failed));
  }, []);

  switch (initStatus) {
    case InitStatus.Init:
      return <Loader />;
    case InitStatus.Success:
      return <PreviewConfiguration />;
    case InitStatus.Failed:
      return <RetrievingItemsMessage message="Loading failed" />;
    default:
      throw InvariantException(
        `${__filename}: Invalid preview configuration app init status: ${initStatus}.`,
      );
  }
};

PreviewConfigurationApp.displayName = 'PreviewConfigurationApp';
