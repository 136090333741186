import { useCallback, useMemo } from 'react';
import { useEditorApi } from '../../editorCore/hooks/useEditorApi.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { Apply, EditorPlugin } from '../../editorCore/types/Editor.plugins.type.ts';
import { EditorChangeReason } from '../../editorCore/types/EditorChangeReason.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import { withDisplayName } from '../../editorCore/utils/withDisplayName.ts';
import {
  ExecuteCommand,
  KeyboardShortcutsPlugin,
} from '../keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { TextInputCommand } from '../keyboardShortcuts/api/EditorCommand.ts';
import { EditorUndoRedoApi } from './api/EditorUndoRedoApi.type.ts';
import { editorUndoRedoApi } from './api/editorUndoRedoApi.ts';

type UndoRedoPluginState = {
  readonly undo: () => void;
};

export type UndoRedoPlugin = EditorPlugin<
  UndoRedoPluginState,
  None,
  EditorUndoRedoApi,
  [KeyboardShortcutsPlugin<TextInputCommand>]
>;

export const useUndoRedo: PluginCreator<UndoRedoPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('UndoRedoPlugin', {
        ComposedEditor: (props) => {
          const apply: Apply<UndoRedoPlugin> = useCallback((state) => {
            const undo = async () => {
              state.removeInvalidState(
                await state.executeChange(
                  (editorState) => state.getApi().undo(editorState),
                  EditorChangeReason.Undo,
                ),
              );
            };

            const redo = async () => {
              state.removeInvalidState(
                await state.executeChange(
                  (editorState) => state.getApi().redo(editorState),
                  EditorChangeReason.Redo,
                ),
              );
            };

            const executeCommand: Decorator<ExecuteCommand<TextInputCommand>> =
              (baseExecuteCommand) => (command, isShiftPressed) => {
                switch (command) {
                  case TextInputCommand.Undo: {
                    undo();
                    return true;
                  }

                  case TextInputCommand.Redo: {
                    redo();
                    return true;
                  }

                  default:
                    return baseExecuteCommand(command, isShiftPressed);
                }
              };

            state.executeCommand.decorate(executeCommand);

            return { undo };
          }, []);

          const { getApiMethods } = useEditorApi<UndoRedoPlugin>(editorUndoRedoApi);

          return useEditorWithPlugin(baseEditor, props, { apply, getApiMethods });
        },
      }),
    [baseEditor],
  );
