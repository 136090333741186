import { Dispatch, GetState, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { showErrorNotification } from '../../../../../_shared/actions/sharedActions.ts';
import { createTaxonomyGroupServerModel } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { ITaxonomyGroupServerModel } from '../../../../../repositories/serverModels/contentModels/TaxonomyGroupServerModel.type.ts';
import { TaxonomyTermEditorIsNotConfirmed } from '../../../shared/constants/errorMessageTemplates.ts';
import { SaveTaxonomyGroupErrorMessage } from '../../constants/uiConstants.ts';
import {
  taxonomyGroupSavingFailed,
  taxonomyGroupSavingFinished,
  taxonomyGroupSavingStarted,
} from '../taxonomyActions.ts';

interface IUpdateTaxonomyGroupActionDependencies {
  readonly taxonomyRepository: {
    readonly updateTaxonomyGroup: (
      taxonomyGroup: ITaxonomyGroupServerModel,
    ) => Promise<ITaxonomyGroupServerModel>;
  };
  readonly loadTaxonomyGroups: () => ThunkPromise;
}

export const createUpdateEditedTaxonomyGroupAction =
  ({ taxonomyRepository, loadTaxonomyGroups }: IUpdateTaxonomyGroupActionDependencies) =>
  (onSuccess?: () => void, onFail?: () => void): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const { editedTaxonomyGroup, editedTaxonomyTermId, isCreateNewFormDisplayed } =
      getState().taxonomyApp;

    if (editedTaxonomyTermId || isCreateNewFormDisplayed) {
      dispatch(showErrorNotification(TaxonomyTermEditorIsNotConfirmed));
      onFail?.();
      return;
    }

    dispatch(taxonomyGroupSavingStarted());
    const taxonomyGroupServerModel = createTaxonomyGroupServerModel(editedTaxonomyGroup);

    try {
      const taxonomyGroup = await taxonomyRepository.updateTaxonomyGroup(taxonomyGroupServerModel);

      dispatch(taxonomyGroupSavingFinished(taxonomyGroup));
      dispatch(loadTaxonomyGroups());

      onSuccess?.();
    } catch {
      dispatch(taxonomyGroupSavingFailed(SaveTaxonomyGroupErrorMessage));
      onFail?.();
    }
  };
