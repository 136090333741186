import React from 'react';
import { feedbackModalSubmitted, modalDismissed } from '../../actions/sharedActions.ts';
import { UmuxFeedbackModal } from '../../components/UmuxFeedback/UmuxFeedbackModal.tsx';
import { useUserFeedback } from '../../hooks/UserFeedback/useUserFeedback.ts';
import { useDispatch } from '../../hooks/useDispatch.ts';
import { AiFeedbackTimestamps } from '../../models/UserPropertiesServerKeys.ts';
import { UserFeedbackSource } from '../../models/events/UserFeedbackEventData.type.ts';

export const AiFeedbackDialog: React.FC = () => {
  const dispatch = useDispatch();

  const { dismissUserFeedback, submitUserFeedback } = useUserFeedback(
    UserFeedbackSource.Ai,
    AiFeedbackTimestamps,
    (userProperties) => userProperties.aiFeedbackTimestamps,
  );

  const close = () => {
    dispatch(modalDismissed());
    dismissUserFeedback();
  };

  const submit = async (ease: string, requirements: string, comment: string): Promise<void> => {
    await submitUserFeedback(ease, requirements, comment);
    dispatch(feedbackModalSubmitted());
  };

  return (
    <UmuxFeedbackModal
      dismissFeedbackButtonText="I changed my mind"
      easeTitle="The current AI feature improves the efficiency of my content creation process."
      headerContent="How helpful is AI when you create content?"
      onClose={close}
      onSubmit={submit}
      overAllOtherElements
      requirementsTitle="The AI feature meets my content creation needs."
      subtitle="Think about your experience with using the AI assistant in Kontent.ai."
    />
  );
};

AiFeedbackDialog.displayName = 'AiFeedbackDialog';
