import { ContentState, EditorChangeType, EditorState, SelectionState } from 'draft-js';
import { containsNewLinks } from '../plugins/links/api/editorLinkUtils.ts';
import { updateWithUndoFlag } from '../plugins/undoRedo/api/editorUndoUtils.ts';
import { getBlocksDirection } from './blocks/editorBlockUtils.ts';
import {
  ensureConsistency,
  ensureEditorStateConsistency,
  ensureValidSelection,
  removeForcedSelection,
} from './consistency/editorConsistencyUtils.ts';
import { createSelection, setContentSelection, setHasFocus } from './editorSelectionUtils.ts';
import { IContentChangeInput, IContentChangeResult } from './general/editorContentUtils.ts';

function setPushedContentSelection(
  editorState: EditorState,
  newContent: ContentState,
  allowUndo: boolean,
): ContentState {
  const selection = editorState.getSelection();

  // Even when we update the selection, we still need to keep the focus flag consistent, because close to blur/focus, it may be different
  // If we didn't do this, and the editor had previous changes but no focus, moving hasFocus to selection may cause stealing the focus
  const hasDifferentFocus =
    selection.getHasFocus() !== newContent.getSelectionAfter().getHasFocus();

  // When undo is not allowed, we need to keep the selection before from the original content
  // so that selection after undo matches the available undo step
  const keepOriginalSelectionBefore = !allowUndo;

  if (hasDifferentFocus || keepOriginalSelectionBefore) {
    const selectionBefore = keepOriginalSelectionBefore
      ? editorState.getCurrentContent().getSelectionBefore()
      : newContent.getSelectionBefore();

    return setContentSelection(
      newContent,
      selectionBefore,
      newContent.getSelectionAfter(),
      selection.getHasFocus(),
    );
  }

  return newContent;
}

function pushNewEditorContent(
  editorState: EditorState,
  newContent: ContentState,
  changeType: EditorChangeType,
  allowUndo: boolean,
): EditorState {
  const contentToPush = setPushedContentSelection(editorState, newContent, allowUndo);

  const newEditorState = updateWithUndoFlag(
    editorState,
    (withUndoFlag) => {
      const withPushedContent = EditorState.push(withUndoFlag, contentToPush, changeType);
      const withValidSelection = ensureValidSelection(withPushedContent, null);

      // EditorState.push may force the selection, but we don't want that
      // Changing and forcing selection is responsibility of particular methods changing content
      return removeForcedSelection(withValidSelection);
    },
    allowUndo && editorState.getAllowUndo(),
  );

  return newEditorState;
}

export type IContentChange = (input: IContentChangeInput) => IContentChangeResult;

export enum SelectionAfter {
  // Default. Applies the selection updated within the content change and forces focus to the editor
  // no matter what it was before. Use for standard editing actions.
  NewWithFocus = 'NewWithFocus',

  // Applies the selection updated within the content change while keeping the editor
  // focused/unfocused the same way as before. Use for actions that modify the content and can be
  // triggered externally by async processes (shouldn't steal the selection from other editor)
  NewWithOriginalFocus = 'NewWithOriginalFocus',

  // Keeps the original selection exactly as it was.
  // Use for actions that don't modify content and can be triggered externally.
  Original = 'Original',
}

export type ExecuteContentChange = (
  editorState: EditorState,
  selection: SelectionState,
  change: IContentChange,
  changeType: EditorChangeType,
  allowUndo?: boolean,
  selectionAfter?: SelectionAfter,
) => EditorState;

export const executeContentChange: ExecuteContentChange = (
  editorState: EditorState,
  selection: SelectionState,
  change: IContentChange,
  changeType: EditorChangeType,
  allowUndo: boolean = true,
  selectionAfter: SelectionAfter = SelectionAfter.NewWithFocus,
): EditorState => {
  const content = editorState.getCurrentContent();
  const input = {
    content,
    selection,
  };

  const updated = change(input);
  if (!updated.wasModified) {
    return editorState;
  }

  const consistent = ensureConsistency(updated, null);

  // Previous content with placeholder (dialog) for a new link is not allowed in undo stack, because undo would cause inconsistency with locking the editor
  // Undo state for creating of a new link is already put on the stack by the placeholder creation operation
  const undo = allowUndo && !containsNewLinks(content);

  const newEditorState = pushNewEditorContent(editorState, consistent.content, changeType, undo);
  switch (selectionAfter) {
    case SelectionAfter.Original:
      return newEditorState;

    case SelectionAfter.NewWithOriginalFocus:
      return editorState.getSelection().getHasFocus()
        ? EditorState.forceSelection(newEditorState, consistent.selection)
        : EditorState.acceptSelection(newEditorState, setHasFocus(consistent.selection, false));

    case SelectionAfter.NewWithFocus:
      return EditorState.forceSelection(newEditorState, consistent.selection);
  }
};

export const moveCaretToEditorStart = (editorState: EditorState): EditorState => {
  const content = editorState.getCurrentContent();
  const blockMap = content.getBlockMap();
  const firstBlockKey = blockMap.first()?.getKey();
  if (firstBlockKey === undefined) {
    return editorState;
  }
  const selection = createSelection(firstBlockKey);

  return EditorState.forceSelection(editorState, selection);
};

export const moveCaretToEditorEnd = (editorState: EditorState): EditorState => {
  return EditorState.moveFocusToEnd(editorState);
};

export function setEditorStateSelection(
  editorState: EditorState,
  selection: SelectionState,
  forceSelection?: boolean,
): EditorState {
  const existingMustForceSelection = editorState.mustForceSelection();
  const mustForceSelection = forceSelection ?? existingMustForceSelection;
  if (
    editorState.getSelection() === selection &&
    mustForceSelection === existingMustForceSelection
  ) {
    // Already in the desired state
    return editorState;
  }
  if (mustForceSelection) {
    return EditorState.forceSelection(editorState, selection);
  }
  return EditorState.acceptSelection(editorState, selection);
}

export function applyEditorStateChanges(
  newState: EditorState,
  oldState: EditorState,
  allowEditContent: boolean,
): EditorState {
  const newContent = newState.getCurrentContent();
  const oldSelection = oldState.getSelection();
  const newSelection = newState.getSelection();
  const selectionDirection = getBlocksDirection(
    newContent,
    oldSelection.getFocusKey(),
    newSelection.getFocusKey(),
  );

  if (allowEditContent) {
    return ensureEditorStateConsistency(newState, selectionDirection);
  }

  const contentChanged = newContent !== oldState.getCurrentContent();
  const selectionChanged = newSelection !== oldSelection;

  if (contentChanged) {
    // If content changed, we need to force selection to force re-render
    return ensureEditorStateConsistency(
      EditorState.forceSelection(oldState, newSelection),
      selectionDirection,
    );
  }

  if (selectionChanged) {
    // If new state has forced selection, we need to also force it to properly transition the force flag, otherwise accept suffices
    if (newState.mustForceSelection()) {
      return ensureEditorStateConsistency(
        EditorState.forceSelection(oldState, newSelection),
        selectionDirection,
      );
    }

    return ensureEditorStateConsistency(
      EditorState.acceptSelection(oldState, newSelection),
      selectionDirection,
    );
  }

  return oldState;
}
