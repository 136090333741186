import PropTypes from 'prop-types';
import React from 'react';
import { Tag } from '../../../../../../../_shared/uiComponents/Tag/Tag.tsx';
import { TagColor } from '../../../../../../../data/constants/tagColor.ts';

interface IItemElementTagStateProps {
  readonly text: string;
}

export class ItemElementTag extends React.PureComponent<IItemElementTagStateProps> {
  static displayName = 'ItemElementTag';

  static propTypes: PropTypesShape<IItemElementTagStateProps> = {
    text: PropTypes.string.isRequired,
  };

  render() {
    return <Tag color={TagColor.Gray}>{this.props.text}</Tag>;
  }
}
