import { identity } from '@kontent-ai/utils';
import { Action } from '../../../@types/Action.type.ts';
import { parseInnovationLabConsentInfo } from '../../../applications/environmentSettings/innovationLab/utils/parseInnovationLabConsentInfo.ts';
import { ProjectPropertyServerModel } from '../../../repositories/serverModels/ProjectPropertyServerModel.type.ts';
import { AiFeatureSet as AiFeatureSetEnum } from '../../constants/AiFeatureSet.ts';
import { Shared_LoadProjectProperties_Finished } from '../../constants/sharedActionTypes.ts';
import {
  ProjectPropertiesModel,
  ProjectPropertiesStateKeysByServerKeys,
} from '../../models/ProjectPropertiesModel.ts';
import { matchingStrategies, parseArrayOfSpecificStrings } from '../../utils/parseJson.ts';

const initialState: ProjectPropertiesModel = {
  AiFeatureSet: [],
  AssetCollectionMandatory: null,
  AssetReplacement: null,
  MissionControl: null,
  MissionControlConsentInfo: null,
  MultiselectOptimization: null,
  SecureAssets: null,
  SignalRAlternativeTransport: null,
  SpacesInCollections: null,
  WebSpotlightPreviewAutoRefresh: null,
};

type PropertyValueConverter = (
  value: string | null,
) => ProjectPropertiesModel[keyof ProjectPropertiesModel];

const propertyValueConverters: Partial<
  ReadonlyRecord<keyof ProjectPropertiesModel, PropertyValueConverter>
> = {
  AiFeatureSet: (value) =>
    value
      ? parseArrayOfSpecificStrings(
          value,
          Object.values(AiFeatureSetEnum),
          matchingStrategies.caseInsensitive,
        )
      : [],
  MissionControlConsentInfo: parseInnovationLabConsentInfo,
};

const mapPropertyKeyAndValueToState = (
  state: ProjectPropertiesModel,
  propertyKey: string,
  propertyValue: string | null,
): ProjectPropertiesModel => {
  const propName = ProjectPropertiesStateKeysByServerKeys[propertyKey];
  if (!propName) {
    return state;
  }

  return {
    ...state,
    [propName]: (propertyValueConverters[propName] ?? identity)(propertyValue),
  };
};

const mapPropertyToState = (
  state: ProjectPropertiesModel,
  property: ProjectPropertyServerModel,
): ProjectPropertiesModel => mapPropertyKeyAndValueToState(state, property.key, property.value);

export const projectProperties = (state = initialState, action: Action): ProjectPropertiesModel => {
  switch (action.type) {
    case Shared_LoadProjectProperties_Finished: {
      return action.payload.properties.reduce(mapPropertyToState, initialState);
    }

    default:
      return state;
  }
};
