import Immutable from 'immutable';
import React from 'react';
import { useParams } from 'react-router';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { editItemVariant } from '../actions/thunkSmartLinkActions.ts';
import { FocusTarget, IItemElement } from '../actions/thunks/editItemVariant.ts';
import { SmartLink } from '../components/SmartLink.tsx';
import { CommentRouteParams } from '../constants/routePaths.ts';

export const CommentSmartLink: React.FC = () => {
  const dispatch = useDispatch();
  const { projectId, variantGuid, itemGuid, commentThreadId } = useParams<CommentRouteParams>();

  const onMount = () =>
    dispatch(
      editItemVariant({
        projectId,
        variantId: variantGuid,
        path: Immutable.List<IItemElement>([{ itemId: itemGuid }]),
        predefinedFocus: {
          target: FocusTarget.CommentThread,
          commentThreadId,
        },
      }),
    );

  return <SmartLink onMount={onMount} />;
};

CommentSmartLink.displayName = 'CommentSmartLink';
