import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import React from 'react';
import { getDropDown } from '../../../../_shared/components/DropDown.tsx';
import { DataUiCollection } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { logError } from '../../../../_shared/utils/logError.ts';
import { SetUtils } from '../../../../_shared/utils/setUtils/setUtils.ts';
import { SearchScope } from '../models/filter/IListingFilter.ts';

interface ISearchScopeOption {
  readonly text: string;
  readonly value: ReadonlySet<SearchScope>;
}

const DropDown = getDropDown<ISearchScopeOption>();

const scopeOptions: ReadonlyArray<ISearchScopeOption> = [
  {
    text: 'Search in names and content',
    value: new Set<SearchScope>([SearchScope.Name, SearchScope.Content]),
  },
  {
    text: 'Search in names only',
    value: new Set<SearchScope>([SearchScope.Name]),
  },
];

const tooltipText =
  'Searching by content is currently unavailable. For more details, check our status page on https://status.kontent.ai.';

type Props = {
  readonly scope: ReadonlySet<SearchScope>;
  readonly isFulltextDisabled: boolean;
  readonly onChange: (scope: ReadonlySet<SearchScope>) => void;
};

export const ContentItemsSearchScope: React.FC<Props> = (props) => {
  const { onChange, scope, isFulltextDisabled } = props;

  const foundSelectedOption = scopeOptions.find((option) => SetUtils.isEqual(option.value, scope));

  if (!foundSelectedOption) {
    logError(`Option was not found. Search scope: ${JSON.stringify(Array.from(scope))}`);
  }
  const selectedOption = foundSelectedOption ?? scopeOptions[0];

  return (
    <Tooltip tooltipText={isFulltextDisabled ? tooltipText : undefined} placement="top-end">
      <div className="content-item-search-area__search-scope-selector-wrapper">
        <DropDown
          dataUiCollectionName={DataUiCollection.SearchScope}
          selectedOption={selectedOption}
          onSelect={(x) => onChange(x.value)}
          options={scopeOptions}
          renderOptionName={(x) => x.text}
          getOptionId={(x) => x.text}
          disabled={isFulltextDisabled}
          className="combo-box content-item-search-area__search-scope-selector"
        />
      </div>
    </Tooltip>
  );
};

ContentItemsSearchScope.displayName = 'ContentItemsSearchScope';
