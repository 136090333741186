import classnames from 'classnames';
import Immutable from 'immutable';
import ImmutablePropTypes from 'immutable-prop-types';
import PropTypes from 'prop-types';
import React from 'react';
import { getDropDown } from '../../../../../../../_shared/components/DropDown.tsx';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import {
  DataUiCollection,
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { Capability } from '../../../../../../../_shared/utils/permissions/capability.ts';
import { IContentType } from '../../../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IRule } from '../../../../../../../data/models/roles/IRoleSettings.ts';
import {
  cannotRuleGroupAdded,
  cannotRuleGroupRemoved,
  cannotRuleTypeAdded,
  cannotRuleTypeRemoved,
} from '../../../../actions/rolesActions.ts';
import { cannotRuleCapabilityOptions } from '../../../../constants/capabilityActionOptions.ts';
import { RoleEditorStatus } from '../../../../models/RoleEditorStatus.ts';
import { getOptionName } from '../../../../utils/capabilityRulePickerUtils.ts';
import { getCannotRuleTypesValidationError } from '../../../../utils/validationUtils.ts';
import { CapabilityTypesAndGroupsSelector } from './CapabilityTypesAndGroupsSelector.tsx';
import { RuleDeleteButton } from './RuleDeleteButton.tsx';

const ActionDropDown = getDropDown<ReadonlyArray<Capability>>();

interface ICapabilityRuleExceptionPickerProps {
  readonly isDisabled: boolean;
  readonly index: number;
  readonly onRemove: () => void;
  readonly allTypes: Immutable.Map<Uuid, IContentType>;
  readonly capabilityRule: IRule;
  readonly onChange: (newRule: IRule) => void;
  readonly status: RoleEditorStatus;
}

export const CapabilityRuleExceptionPicker: React.ComponentType<
  ICapabilityRuleExceptionPickerProps
> = (props) => {
  const dispatch = useDispatch();

  return (
    <div
      className="capability-rules__rule capability-rules__rule--expanded"
      {...getDataUiElementAttribute(DataUiElement.CapabilityExceptionRule)}
    >
      <div
        className={classnames('capability-rules__rule-part', {
          'capability-rules__rule-part--is-disabled': props.isDisabled,
        })}
      >
        <ActionDropDown
          dataUiCollectionName={DataUiCollection.RuleCapabilities}
          getOptionId={(o) => o.join(',')}
          onSelect={(action) =>
            props.onChange({
              ...props.capabilityRule,
              capabilities: action,
            })
          }
          options={cannotRuleCapabilityOptions}
          renderOptionName={getOptionName}
          selectedOption={props.capabilityRule.capabilities}
          className="combo-box"
          disabled={props.isDisabled}
        />
      </div>
      <div
        className={classnames('capability-rules__rule-part', {
          'capability-rules__rule-part--is-disabled': props.isDisabled,
        })}
      >
        <CapabilityTypesAndGroupsSelector
          allTypes={props.allTypes}
          capabilityRule={props.capabilityRule}
          isDisabled={props.isDisabled}
          placeholder="Select a content type or group"
          validationMessage={
            props.status === RoleEditorStatus.ValidationError
              ? getCannotRuleTypesValidationError(props.capabilityRule)
              : undefined
          }
          onTypeAdded={({ typeId }) =>
            dispatch(cannotRuleTypeAdded(props.allTypes, props.index, typeId))
          }
          onTypeRemoved={({ typeId }) =>
            dispatch(cannotRuleTypeRemoved(props.allTypes, props.index, typeId))
          }
          onGroupAdded={({ typeId, groupId }) =>
            dispatch(cannotRuleGroupAdded(props.allTypes, props.index, typeId, groupId))
          }
          onGroupRemoved={({ typeId, groupId }) =>
            dispatch(cannotRuleGroupRemoved(props.allTypes, props.index, typeId, groupId))
          }
        />
      </div>
      {!props.isDisabled && (
        <RuleDeleteButton disabled={props.isDisabled} onRemove={props.onRemove} />
      )}
    </div>
  );
};

const propTypes: PropTypesShape<ICapabilityRuleExceptionPickerProps> = {
  isDisabled: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  allTypes: ImmutablePropTypes.map.isRequired,
  capabilityRule: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

CapabilityRuleExceptionPicker.propTypes = propTypes;
CapabilityRuleExceptionPicker.displayName = 'CapabilityRuleExceptionPicker';
