import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { DraftDecorator } from 'draft-js';
import React, { useMemo } from 'react';
import { useEditorWithPlugin } from '../../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginCreator } from '../../../editorCore/types/Editor.composition.type.ts';
import { EditorPlugin, Init } from '../../../editorCore/types/Editor.plugins.type.ts';
import { withDisplayName } from '../../../editorCore/utils/withDisplayName.ts';
import { EntityDecoratorProps } from '../../entityApi/api/editorEntityUtils.ts';
import { isWebLink } from '../api/LinkEntity.ts';
import { findLinks } from '../api/editorLinkUtils.ts';

export const SimpleWebLinkEntity: React.FC<EntityDecoratorProps> = ({
  contentState,
  decoratedText,
  entityKey,
}) => {
  const entity = contentState.getEntity(entityKey);
  if (!isWebLink(entity)) {
    return decoratedText;
  }

  const { url } = entity.getData();

  return (
    <OutwardLink href={url} contentEditable={false}>
      {decoratedText}
    </OutwardLink>
  );
};

SimpleWebLinkEntity.displayName = 'SimpleWebLinkEntity';

const init: Init = (state) => {
  const linkDecorator: DraftDecorator = {
    strategy: findLinks,
    component: SimpleWebLinkEntity,
  };

  return {
    decorators: [...state.decorators, linkDecorator],
  };
};

type DisplaySimpleWebLinksPlugin = EditorPlugin;

export const useDisplaySimpleWebLinks: PluginCreator<DisplaySimpleWebLinksPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('DisplaySimpleWebLinksPlugin', {
        ComposedEditor: (props) => useEditorWithPlugin(baseEditor, props, { init }),
      }),
    [baseEditor],
  );
