import PropTypes from 'prop-types';
import React, { InputHTMLAttributes } from 'react';
import {
  BaseInputComponent,
  IBaseInputProps,
  baseInputPropTypes,
} from './components/BaseInputComponent.tsx';
import { InputControl } from './components/InputControl.tsx';
import { InputType } from './inputType.ts';

type InputElementAttributes = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  | 'autoComplete'
  | 'max'
  | 'maxLength'
  | 'min'
  | 'minLength'
  | 'name'
  | 'required'
  | 'step'
  | 'tabIndex'
>;

const inputElementAttributesPropTypes: PropTypeMap<InputElementAttributes> = {
  autoComplete: PropTypes.string,
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxLength: PropTypes.number,
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minLength: PropTypes.number,
  name: PropTypes.string,
  required: PropTypes.bool,
  step: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  tabIndex: PropTypes.number,
};

export interface IInputProps extends IBaseInputProps, InputElementAttributes {
  readonly prefix?: React.ReactNode;
  readonly suffixes?: readonly React.ReactNode[];
  readonly type?: InputType;
}

const inputPropTypes: PropTypeMap<IInputProps> = {
  ...baseInputPropTypes,
  ...inputElementAttributesPropTypes,
  prefix: PropTypes.node,
  suffixes: PropTypes.arrayOf(PropTypes.node),
  type: PropTypes.oneOf(Object.values(InputType)),
};

export const Input = React.forwardRef<HTMLInputElement, IInputProps>(
  (
    {
      prefix,
      suffixes,
      tooltipPlacement = 'top',
      tooltipText,
      type = InputType.Text,
      ...inputProps
    },
    forwardedRef,
  ) => {
    return (
      <BaseInputComponent
        renderControlComponent={(ref, { inputState, ...injectedProps }) => (
          <InputControl
            $inputState={inputState}
            ref={ref as React.Ref<HTMLInputElement>}
            type={type}
            {...injectedProps}
          />
        )}
        ref={forwardedRef}
        {...{
          prefix,
          suffixes,
          tooltipPlacement,
          tooltipText,
          ...inputProps,
        }}
      />
    );
  },
);

Input.displayName = 'Input';
Input.propTypes = inputPropTypes;
