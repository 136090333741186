import PropTypes from 'prop-types';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { untitledContentItem } from '../../../contentInventory/content/constants/uiConstants.ts';
import { PreviewError, getPreviewErrorMessage } from '../../../itemEditor/utils/previewUtils.ts';
import { PreviewContentItemInfo } from '../../../itemEditor/utils/selectDeepestItemWithPreview.ts';
import { getSmartLinkTarget } from '../../../smartLink/utils/smartLinkUtils.ts';

export interface IWebSpotlightPreviewErrorProps {
  readonly error: PreviewError;
  readonly previewContentItemInfo: PreviewContentItemInfo;
}

const propTypes: PropTypesShape<IWebSpotlightPreviewErrorProps> = {
  error: PropTypes.string,
  previewContentItemInfo: PropTypes.object.isRequired,
};

export const WebSpotlightPreviewError: React.FC<IWebSpotlightPreviewErrorProps> = ({
  error,
  previewContentItemInfo,
}) => {
  const listingContentItems = useSelector((state) => state.data.listingContentItems.byId);
  const contentItem = listingContentItems.get(previewContentItemInfo.contentItemId);

  const location = useLocation();
  const contentItemPath =
    contentItem &&
    getSmartLinkTarget(undefined, undefined, contentItem.item.id, location.pathname).path;

  if (
    error === PreviewError.NotTranslated &&
    (previewContentItemInfo.isPageContent || !previewContentItemInfo.isForLastRouteItem) &&
    contentItemPath
  ) {
    return (
      <>
        {previewContentItemInfo.isPageContent ? 'This item' : 'Its parent item'} “
        <Link to={contentItemPath}>{contentItem?.item.name || untitledContentItem}</Link>” is not
        translated yet.
      </>
    );
  }

  const errorMessage = getPreviewErrorMessage(error);

  if (previewContentItemInfo.isPageContent && contentItem && contentItemPath) {
    return (
      <>
        <>
          Preview of this item “
          <Link to={contentItemPath}>{contentItem.item.name || untitledContentItem}</Link>” is not
          set up correctly.
          <br />
        </>
        {errorMessage}
      </>
    );
  }

  return (
    <>
      {!previewContentItemInfo.isForLastRouteItem && contentItemPath && (
        <>
          Preview of the parent item “
          <Link to={contentItemPath}>{contentItem?.item.name || untitledContentItem}</Link>” is not
          set up correctly.
          <br />
        </>
      )}
      {errorMessage}
    </>
  );
};

WebSpotlightPreviewError.displayName = 'WebSpotlightPreviewError';
WebSpotlightPreviewError.propTypes = propTypes;
