import PropTypes from 'prop-types';
import React, { ComponentProps, useRef } from 'react';
import {
  BarItemAction,
  BarItemActionButtons,
  DestructiveBarItemAction,
} from '../../../../_shared/components/BarItems/BarItemActionButtons.tsx';
import { BarItemExpandedSimpleHeader } from '../../../../_shared/components/BarItems/BarItemExpandedSimpleHeader.tsx';
import { HotkeysHandler } from '../../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import { HotkeysHandlers } from '../../../../_shared/components/Hotkeys/hotkeys.type.ts';
import { ShortcutSymbols } from '../../../../_shared/constants/shortcutSymbols.ts';
import { HandleUnsavedFormOnNavigation } from '../../../../_shared/containers/HandleUnsavedFormOnNavigation.tsx';
import { HookFormProps, hookFormProps } from '../../../../_shared/types/hookFormProps.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { WebhookHealthStatus } from '../../constants/webhookHealthStatuses.ts';
import { IWebhookFormShape } from '../../models/IWebhookFormShape.type.ts';
import { WebhookDisableConfirmationModal } from './WebhookDisableConfirmationModal.tsx';
import { WebhookFormFields } from './WebhookFormFields.tsx';
import { WebhookStatus } from './WebhookStatus.tsx';

type Props = {
  readonly workflowStepsInWorkflows: ComponentProps<
    typeof WebhookFormFields
  >['workflowStepsInWorkflows'];
  readonly enabled: boolean;
  readonly formProps: HookFormProps<IWebhookFormShape>;
  readonly hasUnsavedChanges: boolean;
  readonly healthStatus: WebhookHealthStatus;
  readonly isBeingSaved: boolean;
  readonly isNew: boolean;
  readonly isResettingWebhookInProgress: boolean;
  readonly isSettingWebhookStateInProgress: boolean;
  readonly isSubmitSuccessful: boolean;
  readonly isTriggerConfigurationValid: boolean;
  readonly onCancelEdit: () => void;
  readonly onDelete: () => void;
  readonly onDisable: () => void;
  readonly onEnable: () => void;
  readonly onGenerateNewSecret: () => Promise<string | undefined>;
  readonly onSubmit: (e?: KeyboardEvent | React.BaseSyntheticEvent) => Promise<void>;
  readonly webhookName: string;
};

const propTypes: PropTypeMap<Props> = {
  workflowStepsInWorkflows: PropTypes.array.isRequired,
  enabled: PropTypes.bool.isRequired,
  formProps: hookFormProps.isRequired,
  hasUnsavedChanges: PropTypes.bool.isRequired,
  healthStatus: PropTypes.oneOf(Object.values(WebhookHealthStatus)).isRequired,
  isBeingSaved: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  isResettingWebhookInProgress: PropTypes.bool.isRequired,
  isSettingWebhookStateInProgress: PropTypes.bool.isRequired,
  isSubmitSuccessful: PropTypes.bool.isRequired,
  isTriggerConfigurationValid: PropTypes.bool.isRequired,
  onCancelEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDisable: PropTypes.func.isRequired,
  onEnable: PropTypes.func.isRequired,
  onGenerateNewSecret: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  webhookName: PropTypes.string.isRequired,
};

export const WebhookItemEditor: React.FC<Props> = ({
  workflowStepsInWorkflows,
  enabled,
  formProps,
  hasUnsavedChanges,
  healthStatus,
  isBeingSaved,
  isNew,
  isResettingWebhookInProgress,
  isSettingWebhookStateInProgress,
  isSubmitSuccessful,
  isTriggerConfigurationValid,
  onCancelEdit,
  onDelete,
  onDisable,
  onEnable,
  onGenerateNewSecret,
  onSubmit,
  webhookName,
}) => {
  const textareaRef = useRef<HTMLElement>(null);

  const [showDisableConfirmationModal, setShowDisableConfirmationModal] = React.useState(false);

  const disableWebhook = (): void => {
    if (showDisableConfirmationModal) {
      onDisable();
      setShowDisableConfirmationModal(false);
    } else {
      setShowDisableConfirmationModal(true);
    }
  };

  const onCancel = (): void => setShowDisableConfirmationModal(false);

  const webhookActionIsInProgress =
    isResettingWebhookInProgress || isSettingWebhookStateInProgress || isBeingSaved;

  const handleUnsavedChanges = async (onSuccess: () => void, onFail: () => void) => {
    try {
      if (!webhookActionIsInProgress) {
        await onSubmit();
      }

      if (isSubmitSuccessful) {
        onSuccess();
      } else {
        onFail();
      }
    } catch {
      onFail();
    }
  };

  const hotkeysHandlers: HotkeysHandlers = {
    onEscape: onCancelEdit,
    onEnter: (e) => {
      if (webhookActionIsInProgress) {
        return;
      }

      if (enabled) {
        onSubmit(e);
      } else {
        onEnable();
      }
    },
  };

  const saveAction: BarItemAction = {
    text: isBeingSaved ? 'Saving' : 'Save',
    handler: onSubmit,
    dataUIActionName: DataUiAction.Save,
    isDisabled: webhookActionIsInProgress,
    shortcut: ShortcutSymbols.Enter,
  };

  const enableAction: BarItemAction = {
    autoFocus: !enabled,
    text: isSettingWebhookStateInProgress ? 'Enabling' : 'Enable',
    handler: onEnable,
    dataUIActionName: DataUiAction.Enable,
    isDisabled: webhookActionIsInProgress,
    shortcut: ShortcutSymbols.Enter,
  };

  const cancelAction: BarItemAction = {
    handler: onCancelEdit,
    text: 'Cancel',
    dataUIActionName: DataUiAction.Cancel,
    shortcut: ShortcutSymbols.Escape,
  };

  const deleteAction: DestructiveBarItemAction | undefined = isNew
    ? undefined
    : {
        text: 'Delete',
        handler: onDelete,
        dataUIActionName: DataUiAction.Delete,
        icon: 'Bin',
        isDisabled: webhookActionIsInProgress,
      };

  const disableAction: DestructiveBarItemAction | undefined = isNew
    ? undefined
    : {
        text: isSettingWebhookStateInProgress ? 'Disabling' : 'Disable',
        handler: disableWebhook,
        dataUIActionName: DataUiAction.Disable,
        icon: 'Drawers',
        isDisabled: webhookActionIsInProgress,
        renderModalComponent: () =>
          showDisableConfirmationModal && (
            <WebhookDisableConfirmationModal
              webhookName={webhookName}
              onCancel={onCancel}
              onConfirm={disableWebhook}
            />
          ),
      };

  return (
    <HotkeysHandler handlers={hotkeysHandlers} excludedAreas={[textareaRef]}>
      <HandleUnsavedFormOnNavigation
        hasUnsavedChanges={hasUnsavedChanges}
        isBeingSaved={isBeingSaved}
        onSaveChanges={handleUnsavedChanges}
      />
      <div className="bar-item__wrapper" {...getDataUiElementAttribute(DataUiElement.BarItemForm)}>
        <div className="bar-item__pane bar-item__pane--is-expanded">
          <BarItemExpandedSimpleHeader
            additionalTitleClass="bar-item__title--flex-shrink"
            dataUiObjectName={webhookName}
            hasLoader={webhookActionIsInProgress}
            isDraggable
            renderTitle={() => webhookName}
            renderTitleTag={() =>
              !isNew && (
                <WebhookStatus
                  isTriggerConfigurationValid={isTriggerConfigurationValid}
                  healthStatus={healthStatus}
                  isEnabled={enabled}
                  hasLabel
                />
              )
            }
          />
          <div className="bar-item__expansion">
            <WebhookFormFields
              workflowStepsInWorkflows={workflowStepsInWorkflows}
              formProps={formProps}
              onGenerateNewSecret={onGenerateNewSecret}
              enabled={enabled}
              textareaRef={textareaRef}
            />
          </div>
          <BarItemActionButtons
            secondaryAction={cancelAction}
            primaryAction={enabled ? saveAction : enableAction}
            destructiveAction={enabled ? disableAction : deleteAction}
          />
        </div>
      </div>
    </HotkeysHandler>
  );
};

WebhookItemEditor.displayName = 'WebhookItemEditor';
WebhookItemEditor.propTypes = propTypes;
