import React, { useEffect } from 'react';
import { ContentItemVariantsCallout } from '../../../../components/ContentItemVariantsCallout/ContentItemVariantsCallout.tsx';
import { ContentItemVariantWithLanguageName } from '../../../../utils/getContentItemVariantsWithLanguageNames.tsx';

interface IDependentsScheduledEarlierQuickTipProps {
  readonly defaultLanguageName: string;
  readonly dependentVariants: ReadonlyArray<ContentItemVariantWithLanguageName>;
  readonly onInit?: () => void;
}

export const DependentsScheduledEarlierQuickTip: React.FC<
  IDependentsScheduledEarlierQuickTipProps
> = ({ defaultLanguageName, dependentVariants, onInit }) => {
  useEffect(() => {
    onInit?.();
  }, [onInit]);

  return (
    <ContentItemVariantsCallout
      calloutType="quickTip"
      variants={dependentVariants}
      headline="Keep the other languages in mind"
    >
      <p>
        You may want to schedule this item in {defaultLanguageName} for earlier than its other
        language variants that use the {defaultLanguageName} content in non-localizable elements:
      </p>
    </ContentItemVariantsCallout>
  );
};

DependentsScheduledEarlierQuickTip.displayName = 'DependentsScheduledEarlierQuickTip';
