import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { ErrorTag } from '@kontent-ai/component-library/Selects';
import { Collection } from '@kontent-ai/utils';
import React, { ComponentProps, useCallback, useMemo } from 'react';
import { DefaultTag } from '../../../../../component-library/components/Tag/DefaultTag.tsx';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ICollection } from '../../../../data/models/collections/Collection.ts';

interface IProps {
  readonly collections: ReadonlyArray<ICollection>;
  readonly selectedCollectionIds: ReadonlyArray<Uuid>;
  readonly onSelectedIdsChanged: (collectionIds: ReadonlyArray<Uuid>) => void;
}

export const CollectionMultiselect: React.FC<IProps> = ({
  collections,
  selectedCollectionIds,
  onSelectedIdsChanged,
}) => {
  const onChange = useCallback(
    (collectionIds: ReadonlySet<Uuid>) => onSelectedIdsChanged(Array.from(collectionIds)),
    [onSelectedIdsChanged],
  );

  const deletedCollectionIds = useMemo(() => {
    const collectionIds = new Set(collections.map((collection) => collection.id));
    return new Set(
      selectedCollectionIds.filter((collectionId) => !collectionIds.has(collectionId)),
    );
  }, [collections, selectedCollectionIds]);

  const items = useMemo(() => {
    return [
      ...collections.map((collection) => ({
        id: collection.id,
        label: collection.name,
      })),
      ...Collection.getValues(deletedCollectionIds).map((id) => ({
        id,
        label: 'Deleted collection',
        tooltipText: 'This collection was deleted',
      })),
    ];
  }, [collections, deletedCollectionIds]);

  const CollectionTag: React.FC<{
    readonly collectionId: Uuid;
    readonly defaultTagProps: Pick<ComponentProps<typeof DefaultTag>, 'label' | 'removalState'>;
  }> = ({ collectionId, defaultTagProps }) =>
    deletedCollectionIds.has(collectionId) ? (
      <ErrorTag {...defaultTagProps} customTooltipText="This collection was deleted" />
    ) : (
      <DefaultTag {...defaultTagProps} />
    );

  return (
    <MultiSelect
      placeholder="Select collections"
      items={items}
      selectedItemIds={selectedCollectionIds}
      onSelectionChange={onChange}
      disabledItemIds={deletedCollectionIds}
      {...getDataUiCollectionAttribute(DataUiCollection.ContentCollections)}
      renderSelectedOption={(_id, selectedOption, defaultTagProps) => (
        <CollectionTag collectionId={selectedOption.id} defaultTagProps={defaultTagProps} />
      )}
    />
  );
};

CollectionMultiselect.displayName = 'CollectionMultiselect';
