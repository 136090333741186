import PropTypes from 'prop-types';
import React from 'react';
import {
  INumberTypeElement,
  NumberTypeElementPropTypes,
} from '../../../../../contentInventory/content/models/contentTypeElements/NumberTypeElement.ts';
import {
  INumberItemElement,
  NumberItemElementPropTypes,
} from '../../../../models/contentItemElements/NumberItemElement.ts';
import { ItemElement } from '../../../ContentItemEditing/containers/elements/ItemElement.tsx';
import { ItemElementRefresher } from '../../../ContentItemEditing/containers/elements/ItemElementRefresher.tsx';
import { IRevisionItemElementProps } from '../../utils/itemElementRevisionComponentCreator.ts';
import { NumberDiff } from './subelements/NumberDiff.tsx';

type NumberElementProps = IRevisionItemElementProps<INumberItemElement, INumberTypeElement>;

const propTypes: PropTypeMap<NumberElementProps> = {
  elementData: NumberItemElementPropTypes.isRequired,
  hideValidationStatus: PropTypes.bool,
  originalElementData: NumberItemElementPropTypes,
  revisionElementModifiedBy: PropTypes.string,
  typeElement: NumberTypeElementPropTypes.isRequired,
};

export const NumberElement: React.FC<NumberElementProps> = ({
  elementData,
  hideValidationStatus,
  originalElementData,
  revisionElementModifiedBy,
  typeElement,
}) => (
  <ItemElement
    disabled
    isDisplayOnly
    hideValidationStatus={hideValidationStatus}
    typeElement={typeElement}
    revisionChangedBy={revisionElementModifiedBy}
  >
    <ItemElementRefresher
      elementData={elementData}
      renderInput={(refresherElementData: INumberItemElement) => (
        <NumberDiff
          elementData={refresherElementData}
          originalElementData={originalElementData}
          typeElement={typeElement}
        />
      )}
    />
  </ItemElement>
);

NumberElement.displayName = 'NumberElement (Revisions)';
NumberElement.propTypes = propTypes;
