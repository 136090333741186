import { usePrevious } from '@kontent-ai/hooks';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { isCodenameValid } from '../../../applications/contentModels/shared/utils/typeCodenameUtils.ts';
import { getSnippetCodenameElementPrefix } from '../../../applications/contentModels/shared/utils/typeElementCodenameComposer.ts';
import { ValidationConstants } from '../../constants/validationConstants.ts';
import { LabelFor } from '../../uiComponents/LabelFor/LabelFor.tsx';
import { TextField } from '../../uiComponents/TextField/TextField.tsx';
import { DataUiInput } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { HotkeysHandler, HotkeysMode } from '../Hotkeys/HotkeysHandler.tsx';
import { Warning } from '../infos/Warning.tsx';
import { CodenameActionButtonPane } from './CodenameActionButtonPane.tsx';
import { CodenameErrorMessage } from './CodenameErrorMessage.tsx';

interface IEditCodenameProps {
  readonly codename: string;
  readonly friendlyWarningMessage?: string;
  readonly hideTitle?: boolean;
  readonly maxLength?: number;
  readonly snippetCodename?: string | null;
  readonly isCodenameUnique: (codename: string) => boolean;
  readonly onCodenameChange?: (codename: string) => void;
  readonly onDiscardChanges: () => void;
  readonly onFinishEditing: () => void;
  readonly isBeingSaved?: boolean;
  readonly saveCodename?: boolean;
}

export const EditCodename: React.FC<IEditCodenameProps> = ({
  friendlyWarningMessage,
  hideTitle,
  isBeingSaved,
  maxLength,
  saveCodename,
  snippetCodename,
  codename,
  isCodenameUnique,
  onFinishEditing,
  onCodenameChange,
  onDiscardChanges,
}) => {
  const [newCodename, setNewCodename] = useState(codename);
  const [isNewCodenameUnique, setIsNewCodenameUnique] = useState(true);
  const [isNewCodenameValid, setIsNewCodenameValid] = useState(true);

  const codenameInputRef = useRef<HTMLInputElement>(null);
  const previousIsBeingSaved = usePrevious(isBeingSaved);

  useEffect(() => {
    if (codenameInputRef.current) {
      codenameInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const isUnique = isCodenameUnique(newCodename);
    if (!isUnique) {
      setIsNewCodenameUnique(false);
      return;
    }

    if (previousIsBeingSaved && !isBeingSaved) {
      onFinishEditing();
    }
  }, [newCodename, isCodenameUnique, previousIsBeingSaved, isBeingSaved, onFinishEditing]);

  const onConfirm = (): void => {
    const isValid = isCodenameValid(newCodename);
    const isUnique = isCodenameUnique(newCodename);

    if (!isValid || !isUnique) {
      setIsNewCodenameUnique(isUnique);
      setIsNewCodenameValid(isValid);
    } else {
      onCodenameChange?.(newCodename || '');

      if (!saveCodename) {
        onFinishEditing();
      }
    }
  };

  const onControlSHandler = (e: KeyboardEvent): void => {
    e.preventDefault();

    if (!isBeingSaved && isNewCodenameUnique && isNewCodenameValid) {
      onConfirm();
    }
  };

  const onInputChange = (event: any): void => {
    const newCodenameInputValue = event.target.value;

    setNewCodename(newCodenameInputValue);
    setIsNewCodenameValid(isCodenameValid(newCodenameInputValue));
    setIsNewCodenameUnique(true);
  };

  const codenameHasErrors = !isNewCodenameUnique || !isNewCodenameValid;
  const hotkeysHandlers = saveCodename
    ? {
        onControlS: onControlSHandler,
        onEscape: onDiscardChanges,
      }
    : {
        onEnter: onConfirm,
        onEscape: onDiscardChanges,
      };

  return (
    <HotkeysHandler mode={HotkeysMode.InPlace} handlers={hotkeysHandlers}>
      <LabelFor
        isHidden={hideTitle}
        target={(id: Uuid) => (
          <div className="form__input-with-buttons-wrapper align-items--center form__input-with-buttons-wrapper--is-edited">
            {snippetCodename && (
              <div className="codename-editor__snippet-prefix">
                {getSnippetCodenameElementPrefix(snippetCodename)}
              </div>
            )}
            <TextField
              dataUiInputName={DataUiInput.Codename}
              hasError={codenameHasErrors}
              inputId={id}
              maxLength={maxLength || ValidationConstants.CodenameMaxLength}
              onChange={onInputChange}
              placeholder=""
              reference={codenameInputRef}
              value={newCodename || ''}
            />
          </div>
        )}
      >
        Codename
      </LabelFor>
      <CodenameErrorMessage
        isCodenameUnique={isNewCodenameUnique}
        hasCodenameErrors={codenameHasErrors}
      />
      {friendlyWarningMessage && (
        <div className="codename-editor__warning">
          <Warning subtitle="Change the codename?">{friendlyWarningMessage}</Warning>
        </div>
      )}
      <CodenameActionButtonPane
        isConfirmDisabled={codenameHasErrors}
        isSaving={isBeingSaved}
        onConfirm={onConfirm}
        onDiscardChanges={onDiscardChanges}
        saveCodename={saveCodename}
      />
    </HotkeysHandler>
  );
};

EditCodename.displayName = 'EditCodename';
EditCodename.propTypes = {
  codename: PropTypes.string.isRequired,
  friendlyWarningMessage: PropTypes.string,
  hideTitle: PropTypes.bool,
  isBeingSaved: PropTypes.bool,
  isCodenameUnique: PropTypes.func.isRequired,
  maxLength: PropTypes.number,
  onCodenameChange: PropTypes.func,
  onDiscardChanges: PropTypes.func.isRequired,
  onFinishEditing: PropTypes.func.isRequired,
  saveCodename: PropTypes.bool,
  snippetCodename: PropTypes.string,
};
