import React, { useEffect } from 'react';
import { DataTable } from '../../../../_shared/components/DataTable/DataTable.tsx';
import { DataTableHeadRow } from '../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { YourContentTabName } from '../../../../_shared/constants/yourContentTabName.ts';
import { ContentItemId } from '../../../../_shared/models/ContentItemId.type.ts';
import { DataUiCollection } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { YourContentTabSaver } from '../YourContentTabSaver.tsx';
import { YourTask, YourTaskDataProps, tableHeadColumns } from './YourTask.tsx';
import { YourTasksEmptyState } from './YourTasksEmptyState.tsx';

export type YourTaskProps = YourTaskDataProps & ContentItemId;

export type YourTasksStateProps = {
  readonly tasks: readonly YourTaskProps[];
  readonly tasksLoaded: boolean;
};

export type YourTasksDispatchProps = {
  readonly onInit: () => void;
  readonly onNavigateToTask: (task: YourTaskProps) => void;
};

type YourTasksProps = YourTasksDispatchProps & YourTasksStateProps;

export const YourTasks: React.FC<YourTasksProps> = ({
  tasks,
  tasksLoaded,
  onInit,
  onNavigateToTask,
}) => {
  useEffect(() => {
    onInit();
  }, [onInit]);

  if (!tasksLoaded) {
    return (
      <section>
        <DataTable
          noBoxShadow
          dataUiCollectionName={DataUiCollection.ContentItems}
          header={<DataTableHeadRow columns={tableHeadColumns} />}
          infoMessage={
            <EmptyState>
              <Loader />
            </EmptyState>
          }
        />
      </section>
    );
  }

  return (
    <YourContentTabSaver tabName={YourContentTabName.YourTasks} itemCount={tasks.length}>
      <section>
        <DataTable
          noBoxShadow
          dataUiCollectionName={DataUiCollection.ContentItems}
          header={<DataTableHeadRow columns={tableHeadColumns} />}
          infoMessage={tasks.length === 0 ? <YourTasksEmptyState /> : undefined}
        >
          {tasks.map((task) => (
            <YourTask {...task} onNavigateToTask={() => onNavigateToTask(task)} key={task.id} />
          ))}
        </DataTable>
      </section>
    </YourContentTabSaver>
  );
};

YourTasks.displayName = 'YourTasks';
