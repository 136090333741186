import { usePrevious } from '@kontent-ai/hooks';
import React, { useLayoutEffect, useRef, useState } from 'react';
import {
  isEntryIntersectingCallback,
  useIntersectionObserver,
} from '../hooks/useIntersectionObserver.ts';

type HideOutsideViewportProps = IntersectionObserverInit & {
  readonly alwaysVisible?: boolean;
  readonly targetRef?: React.RefObject<HTMLDivElement>;
  readonly renderVisible: (ref: React.RefObject<HTMLDivElement>) => React.ReactElement;
  readonly renderHidden?: (ref: React.RefObject<HTMLDivElement>) => React.ReactElement;
};

export const HideOutsideViewport: React.FC<HideOutsideViewportProps> = ({
  alwaysVisible,
  renderHidden,
  renderVisible,
  targetRef,
  ...initProps
}) => {
  // We need to maintain and toggle separate ref for visible and hidden presentation to let the intersection observer reinitialize and use the proper one
  const visibleRef = useRef<HTMLDivElement>(null);
  const hiddenRef = useRef<HTMLDivElement>(null);
  const [activeRef, setActiveRef] = useState(alwaysVisible ? visibleRef : hiddenRef);

  const isVisible =
    useIntersectionObserver({
      targetRef: targetRef ?? activeRef,
      isIntersectingCallback: isEntryIntersectingCallback,
      options: initProps,
    }) || alwaysVisible;

  const previousIsVisible = usePrevious(isVisible);
  useLayoutEffect(() => {
    if (!targetRef && isVisible !== previousIsVisible) {
      setActiveRef(isVisible ? visibleRef : hiddenRef);
    }
  }, [isVisible, previousIsVisible, targetRef]);

  return isVisible
    ? renderVisible(targetRef ?? visibleRef)
    : renderHidden?.(targetRef ?? hiddenRef) ?? null;
};

HideOutsideViewport.displayName = 'HideOutsideViewport';
