import { Box } from '@kontent-ai/component-library/Box';
import { gridUnit } from '@kontent-ai/component-library/tokens';
import React from 'react';
import {
  ItemEditingForms,
  getDataUiObjectNameAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { DueDateFormBase } from '../../../containers/details/DueDate/DueDateFormBase.tsx';
import { IDueDateFormShape } from '../../../models/IDueDateFormShape.type.ts';
import { shouldCloseOnClickOutsideFactory } from '../../../utils/editingActions/shouldCloseOnClickOutside.ts';
import { LeafActionFormProps } from '../EditingLeafActionForm.tsx';

type DueDateFormProps = LeafActionFormProps & {
  readonly datePickerId?: string;
  readonly initialDueDate: DateTimeStamp | null;
  readonly onCancel: () => void;
  readonly onConfirm: (values: IDueDateFormShape) => Promise<void>;
};

const DueDateFormDatePickerId = 'due-date-form-datepicker';
const shouldCloseOnClickOutside = shouldCloseOnClickOutsideFactory(DueDateFormDatePickerId);
const popUpWidth = 41 * gridUnit;

export const DueDateForm: React.FC<DueDateFormProps> = ({
  useCustomHandleClickOutside,
  ...sharedProps
}) => {
  useCustomHandleClickOutside(sharedProps.onCancel, shouldCloseOnClickOutside);

  return (
    <Box width={popUpWidth} {...getDataUiObjectNameAttribute(ItemEditingForms.DueDate)}>
      <DueDateFormBase datePickerId={DueDateFormDatePickerId} {...sharedProps} />
    </Box>
  );
};

DueDateForm.displayName = 'DueDateForm';
