import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Box } from '@kontent-ai/component-library/Box';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Spacing, colorWarningIcon } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { AutoScrollLink } from '../../../../../../_shared/components/AutoScroll/AutoScrollLink.tsx';
import {
  AutoScrollComponentProps,
  autoScrollPropTypes,
} from '../../../../../../_shared/components/AutoScroll/autoScrollPropTypes.ts';
import { FallbacksStatus } from '../../../../../../_shared/components/FallbacksForLinkedContent/FallbacksStatus.tsx';
import { NotTranslatedOrFallbacksStatus } from '../../../../../../_shared/containers/FallbacksForLinkedContent/NotTranslatedOrFallbacksStatus.tsx';
import { WorkflowStatusTagForVariant } from '../../../../../../_shared/containers/Workflow/WorkflowStatusTagForVariant.tsx';
import { ContentItemStatus } from '../../../../../../_shared/uiComponents/ContentItemStatus/ContentItemStatus.tsx';
import { getContentVariantState } from '../../../../../../_shared/utils/contentItemVariantUtils.ts';
import { PublishingState } from '../../../../../../data/constants/PublishingState.ts';
import { IListingContentItem } from '../../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { IListingVariantData } from '../../../../../../data/models/listingContentItems/IListingVariantData.ts';
import { IAssignmentWorkflowStep } from '../../../../../../data/models/workflow/WorkflowStep.ts';
import { CannotViewItemsMessage } from '../../../../../contentInventory/content/constants/cannotViewMessages.ts';
import { RelatedItemDoesNotExistReason } from '../../../../../itemEditor/constants/errorMessages.ts';

const getTitleText = (itemName: string, typeName: string | null): string =>
  typeName ? `${itemName} (${typeName})` : itemName;

export const NoPermissionsContentLink: React.FC = () => (
  <div className="bar-item__title-pane utility-message">
    <div className="bar-item__title">{CannotViewItemsMessage}</div>
  </div>
);
NoPermissionsContentLink.displayName = 'NoPermissionsContentLink';

export const NonExistingContentLink: React.FC = () => (
  <div className="bar-item__title-pane utility-message">
    <div className="bar-item__title">
      <Tooltip placement="bottom" tooltipText={RelatedItemDoesNotExistReason}>
        <span>Linked content item doesn’t exist</span>
      </Tooltip>
    </div>
  </div>
);
NonExistingContentLink.displayName = 'NonExistingContentLink';

interface INonTranslatedContentLinkProps extends AutoScrollComponentProps {
  readonly contentItem: IListingContentItem;
  readonly contentItemPath?: string;
  readonly contentTypeName: string | null;
  readonly isViewOnly?: boolean;
}

const notTranslatedPropTypes: PropTypesShape<INonTranslatedContentLinkProps> = {
  ...autoScrollPropTypes,
  contentItem: PropTypes.object.isRequired,
  contentItemPath: PropTypes.string,
  contentTypeName: PropTypes.string,
  isViewOnly: PropTypes.bool,
};

export const NonTranslatedContentLink: React.FC<INonTranslatedContentLinkProps> = (props) => {
  const name = props.contentItem.item.name;
  const typName = props.contentTypeName;

  if (!props.contentItemPath) {
    return (
      <div className="bar-item__title-pane">
        <div className="bar-item__title" title={getTitleText(name, typName)}>
          {name}
        </div>
        <Status itemId={props.contentItem.item.id} />
      </div>
    );
  }

  if (props.isViewOnly) {
    return (
      <div className="bar-item__title-pane">
        <OutwardLink
          className="bar-item__title bar-item__title--must-grow"
          href={props.contentItemPath}
          title={getTitleText(name, typName)}
        >
          {name}
        </OutwardLink>
        <Status itemId={props.contentItem.item.id} />
      </div>
    );
  }

  return (
    <div className="bar-item__title-pane">
      <AutoScrollLink
        alternativeScrollIds={props.alternativeScrollIds}
        className="bar-item__title bar-item__title--must-grow"
        scrollId={props.scrollId}
        scrollOptions={props.scrollOptions}
        title={getTitleText(name, typName)}
        to={props.contentItemPath}
      >
        {name}
      </AutoScrollLink>
      <Status itemId={props.contentItem.item.id} />
    </div>
  );
};

NonTranslatedContentLink.displayName = 'NonTranslatedContentLink';
NonTranslatedContentLink.propTypes = notTranslatedPropTypes;

const NotTranslatedStatus: React.FC = () => (
  <Inline spacingX={Spacing.S}>
    <Icons.ExclamationTriangle color={colorWarningIcon} />
    <Label size={LabelSize.L} color={colorWarningIcon}>
      Content item not translated
    </Label>
  </Inline>
);

const Status = (props: { readonly itemId: Uuid }) => (
  <Box paddingX={Spacing.S}>
    <NotTranslatedOrFallbacksStatus
      itemId={props.itemId}
      renderFallbacksStatus={(itemId) => <FallbacksStatus itemId={itemId} />}
      renderNotTranslatedStatus={() => <NotTranslatedStatus />}
    />
  </Box>
);

interface IArchivedContentLinkProps {
  readonly contentItem: IListingContentItem;
  readonly contentTypeName: string | null;
}

const archivedPropTypes: PropTypesShape<IArchivedContentLinkProps> = {
  contentItem: PropTypes.object.isRequired,
  contentTypeName: PropTypes.string,
};

export const ArchivedContentLink: React.FC<IArchivedContentLinkProps> = ({
  contentTypeName,
  contentItem,
}) => (
  <div className="bar-item__title-pane">
    <div
      className="bar-item__title-text bar-item__title-text--deleted"
      title={getTitleText(contentItem.item.name, contentTypeName)}
    >
      {contentItem.item.name}
    </div>
    <div className="bar-item__title-label">(Content item deleted)</div>
  </div>
);
ArchivedContentLink.displayName = 'ArchivedContentLink';
ArchivedContentLink.propTypes = archivedPropTypes;

interface IValidContentLinkProps extends AutoScrollComponentProps {
  readonly contentItem: IListingContentItem;
  readonly contentItemPath: string;
  readonly contentTypeName: string | null;
  readonly isViewOnly?: boolean;
  readonly publishingState: PublishingState | null;
  readonly workflowStatus: IAssignmentWorkflowStep | null;
}

const validContentPropTypes: PropTypesShape<IValidContentLinkProps> = {
  ...autoScrollPropTypes,
  contentItem: PropTypes.object.isRequired,
  contentItemPath: PropTypes.string.isRequired,
  contentTypeName: PropTypes.string,
  isViewOnly: PropTypes.bool,
  publishingState: PropTypes.string,
  workflowStatus: PropTypes.object,
};

export const ValidContentLink: React.FC<IValidContentLinkProps> = (props) => {
  const name = props.contentItem.item.name;

  const status = props.workflowStatus && props.publishingState && (
    <div className="bar-item__tags-pane bar-item__tags-pane--in-popover-link">
      <div className="bar-item__tag bar-item__tag--in-popover-link">
        <WorkflowStatusTagForVariant
          workflowStatus={props.workflowStatus}
          publishingState={props.publishingState}
          scheduledToPublishAt={props.contentItem.variant?.assignment.scheduledToPublishAt || null}
          scheduledToUnpublishAt={
            props.contentItem.variant?.assignment.scheduledToUnpublishAt || null
          }
        />
      </div>
    </div>
  );

  if (props.isViewOnly) {
    return (
      <div className="bar-item__title-pane">
        <a
          className="bar-item__title bar-item__title--must-grow"
          href={props.contentItemPath}
          target="_blank"
          rel="noopener noreferrer"
          title={getTitleText(name, props.contentTypeName)}
        >
          {name}
        </a>
        {status}
      </div>
    );
  }

  return (
    <div className="bar-item__title-pane">
      <AutoScrollLink
        alternativeScrollIds={props.alternativeScrollIds}
        className="bar-item__title bar-item__title--must-grow"
        scrollId={props.scrollId}
        scrollOptions={props.scrollOptions}
        title={getTitleText(name, props.contentTypeName)}
        to={props.contentItemPath}
      >
        {name}
      </AutoScrollLink>
      {status}
    </div>
  );
};
ValidContentLink.displayName = 'ValidContentLink';
ValidContentLink.propTypes = validContentPropTypes;

interface IReadonlyContentLinkProps {
  readonly contentItemVariant: IListingVariantData | null;
  readonly itemName: string;
  readonly cannotViewMessage: string | undefined;
  readonly cannotCreateMessage: string | undefined;
  readonly typeName: string | null;
}

const readonlyContentPropTypes: PropTypesShape<IReadonlyContentLinkProps> = {
  contentItemVariant: PropTypes.object,
  itemName: PropTypes.string.isRequired,
  cannotViewMessage: PropTypes.string,
  cannotCreateMessage: PropTypes.string,
  typeName: PropTypes.string,
};

export const ReadonlyContentLink: React.FC<IReadonlyContentLinkProps> = (props) => (
  <Tooltip tooltipText={props.cannotViewMessage || props.cannotCreateMessage} placement="top">
    <div className="bar-item__title-pane">
      <div className="bar-item__title bar-item__title--must-grow">
        <div
          className={classNames('bar-item__title-name', {
            'bar-item__title-name--is-redacted': !!props.cannotViewMessage,
          })}
          title={getTitleText(props.itemName, props.typeName)}
        >
          <div className="bar-item__title-name-text">{props.itemName}</div>
        </div>
      </div>
      {props.contentItemVariant && !props.contentItemVariant.isArchived ? (
        <div className="bar-item__tags-pane bar-item__tags-pane--in-popover-link">
          <WorkflowStatusTagForVariant
            workflowStatus={props.contentItemVariant.assignment.workflowStatus}
            publishingState={props.contentItemVariant.publishingState}
            scheduledToPublishAt={props.contentItemVariant.assignment.scheduledToPublishAt}
            scheduledToUnpublishAt={props.contentItemVariant.assignment.scheduledToUnpublishAt}
          />
        </div>
      ) : (
        <ContentItemStatus status={getContentVariantState(props.contentItemVariant)} />
      )}
    </div>
  </Tooltip>
);

ReadonlyContentLink.displayName = 'ReadonlyContentLink';
ReadonlyContentLink.propTypes = readonlyContentPropTypes;
