import { Collection } from '@kontent-ai/utils';
import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { Task } from '../../../../../../_shared/models/Task.ts';
import { ContentItemEditing_Sidebar_Opened } from '../../constants/contentItemEditingActionTypes.ts';
import { orderTasks } from '../../utils/orderTasks.ts';

const finished = (orderedTasks: ReadonlyArray<Task>) =>
  ({
    type: ContentItemEditing_Sidebar_Opened,
    payload: {
      orderedTasks,
    },
  }) as const;

export type OpenContentItemEditingSidebarActionsType = ReturnType<typeof finished>;

export const openContentItemEditingSidebar = (): ThunkFunction => (dispatch, getState) => {
  const {
    data: {
      tasks: { byId: tasksById },
      user: {
        info: { userId: currentUserId },
      },
    },
  } = getState();
  const orderedTasks = orderTasks(Collection.getValues(tasksById), currentUserId);

  dispatch(finished(orderedTasks));
};
