import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

export interface IRedirectIfDataProps {
  readonly redirectTo: string;
  readonly doRedirect: boolean;
}

export const RedirectIf: React.FC<React.PropsWithChildren<IRedirectIfDataProps>> = ({
  doRedirect,
  redirectTo,
  children,
}) => {
  const [isRedirecting, setIsRedirecting] = useState(false);

  const shouldStartRedirection = doRedirect && !isRedirecting;

  useEffect(() => {
    if (shouldStartRedirection) {
      self.location.assign(redirectTo);
      setIsRedirecting(true);
    }
  }, [shouldStartRedirection, redirectTo]);

  if (doRedirect || isRedirecting) {
    return null;
  }

  return <>{children}</>;
};

RedirectIf.displayName = 'RedirectIf';
RedirectIf.propTypes = {
  redirectTo: PropTypes.string.isRequired,
  doRedirect: PropTypes.bool.isRequired,
};
