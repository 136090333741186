export const notificationBarTypeValues = [
  'Alert',
  'Blank',
  'FriendlyWarning',
  'Info',
  'InfoEmphasis',
  'Success',
  'Warning',
] as const;
export type NotificationBarType = (typeof notificationBarTypeValues)[number];
