import { memoize } from '@kontent-ai/memoization';
import { makeCancellablePromise, swallowCancelledPromiseError } from '@kontent-ai/utils';
import { format } from 'date-fns';
import Immutable from 'immutable';
import React, { useEffect, useState } from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import { getTimestamp } from '../../../../_shared/utils/dateTime/timeUtils.ts';
import { getSelectedSubscription } from '../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import {
  selectBillingHistoryItem,
  unselectBillingHistoryItem,
} from '../actions/billingHistoryActions.ts';
import { getBillingHistoryItems } from '../actions/thunkBillingHistoryActions.ts';
import { BillingHistory as BillingHistoryComponent } from '../components/BillingHistory.tsx';
import { IBillingHistoryItem } from '../models/BillingHistoryItem.ts';

const memoizedBillingHistoryItems = memoize.allForever(
  (
    billingHistoryItems: Immutable.List<IBillingHistoryItem>,
  ): Immutable.List<IBillingHistoryItem> => {
    return billingHistoryItems
      .sort((first: IBillingHistoryItem, second: IBillingHistoryItem) => {
        if (!second || !first) {
          return 0;
        }

        return getTimestamp(second.periodEnd) - getTimestamp(first.periodEnd);
      })
      .toList();
  },
);

export const BillingHistory: React.FC = () => {
  // eslint-disable-next-line react/hook-use-state
  const [pdfExportAbortController] = useState(() => new AbortController());

  const subscriptionId = useSelector((s) => getSelectedSubscription(s)?.subscriptionId ?? '');
  const billingHistoryItems = useSelector((s) =>
    memoizedBillingHistoryItems(s.data.subscriptions.billingHistoryItems),
  );
  const selectedBillingHistoryItemId = useSelector(
    (s) => s.subscriptionApp.billingHistory.selectedItemId,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const initPromise = makeCancellablePromise(() =>
      dispatch(getBillingHistoryItems(subscriptionId)),
    ).catch(swallowCancelledPromiseError);

    dispatch(unselectBillingHistoryItem());

    return () => {
      initPromise.cancel();
      pdfExportAbortController.abort();
    };
  }, [pdfExportAbortController, subscriptionId]);

  const onSelectBillingHistoryItem = (billingHistoryItemId: Uuid) =>
    dispatch(selectBillingHistoryItem(billingHistoryItemId));
  const onUnselectBillingHistoryItem = () => dispatch(unselectBillingHistoryItem());

  const onExportBillingHistoryItemToPdf = async () => {
    if (!selectedBillingHistoryItemId) {
      return;
    }

    const exportedBillingPdf =
      await repositoryCollection.subscriptionRepository.exportBillingHistoryItemToPdf(
        subscriptionId,
        selectedBillingHistoryItemId,
        pdfExportAbortController.signal,
      );

    const historyItem = billingHistoryItems.find(
      (item: IBillingHistoryItem) => item.id === selectedBillingHistoryItemId,
    );
    const periodDate = historyItem?.periodEnd ? new Date(historyItem.periodEnd) : new Date();
    const fileNameDate = format(periodDate, 'yyyy-MM');
    const fileName = `Kontent.aiUsageReport-${fileNameDate}.pdf`;

    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(exportedBillingPdf);
    elem.download = fileName;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  };

  return (
    <BillingHistoryComponent
      billingHistoryItems={billingHistoryItems}
      onExportBillingHistoryItemToPdf={onExportBillingHistoryItemToPdf}
      onSelectBillingHistoryItem={onSelectBillingHistoryItem}
      onUnselectBillingHistoryItem={onUnselectBillingHistoryItem}
      selectedBillingHistoryItemId={selectedBillingHistoryItemId}
    />
  );
};

BillingHistory.displayName = 'BillingHistory';
