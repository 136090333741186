import { delay } from '@kontent-ai/utils';
import { DraftHandleValue, EditorProps } from 'draft-js';
import { useCallback, useMemo } from 'react';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { DecoratedEditor } from '../../editorCore/types/Editor.decorated.type.ts';
import {
  Apply,
  EditorPlugin,
  PluginState,
  Render,
} from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import { ShouldDeferGrammarlyReplace } from '../../editorCore/utils/editorComponentUtils.ts';
import { withDisplayName } from '../../editorCore/utils/withDisplayName.ts';
import { ClipboardPlugin } from '../clipboard/ClipboardPlugin.tsx';

type SpellCheckPluginProps = {
  readonly spellCheck?: boolean;
};

export type SpellCheckPlugin = EditorPlugin<None, SpellCheckPluginProps, None, [ClipboardPlugin]>;

const EditorWithSpellCheck: DecoratedEditor<SpellCheckPlugin, SpellCheckPluginProps> = ({
  baseRender,
  spellCheck,
  state,
}) => {
  const {
    editorProps: { handlePastedText, spellCheck: parentSpellCheck },
  } = state;

  const deferredHandlePastedText = useCallback<Required<EditorProps>['handlePastedText']>(
    (text, html, editorState): DraftHandleValue => {
      if (handlePastedText) {
        delay(0).then(() => handlePastedText(text, html, editorState));
        return 'handled';
      }
      return 'not-handled';
    },
    [handlePastedText],
  );

  const stateWithClipboardEvents: PluginState<SpellCheckPlugin> = {
    ...state,
    editorProps: {
      ...state.editorProps,
      handlePastedText:
        handlePastedText && ShouldDeferGrammarlyReplace
          ? deferredHandlePastedText
          : handlePastedText,
      spellCheck:
        // If a parent plugin disables the spellcheck explicitly, we don't allow it
        parentSpellCheck !== false && (spellCheck ?? true),
    },
  };

  return baseRender(stateWithClipboardEvents);
};

EditorWithSpellCheck.displayName = 'EditorWithSpellCheck';

export const useSpellCheck: PluginCreator<SpellCheckPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('SpellCheckPlugin', {
        ComposedEditor: (props) => {
          const { spellCheck } = props;

          const render: Decorator<Render<SpellCheckPlugin>> = useCallback(
            (baseRender) => (state) => (
              <EditorWithSpellCheck baseRender={baseRender} state={state} spellCheck={spellCheck} />
            ),
            [spellCheck],
          );

          const apply: Apply<SpellCheckPlugin> = useCallback(
            (state) => {
              state.render.decorate(render);

              return {};
            },
            [render],
          );

          return useEditorWithPlugin(baseEditor, props, { apply });
        },
      }),
    [baseEditor],
  );
