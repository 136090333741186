import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { getDateTimeElementErrors } from '../../../../../itemEditor/utils/elementErrorCheckers/dateTimeElementErrorChecker.ts';
import { IDateTimeTypeElementData } from '../../../../shared/models/elements/DateTimeTypeElementData.ts';
import { ITypeElementValidatorDependencies } from '../../../../shared/utils/typeElementValidators/types/ITypeElementValidatorDependencies.type.ts';
import { IBaseTypeElementData } from '../../../models/elements/types/TypeElementData.ts';
import { mapElementErrorToTypeElementErrorResult } from '../../../utils/typeElementValidators/mapElementErrorToTypeElementErrorResult.ts';
import { DatetimeElementValidationResult } from '../../../utils/typeElementValidators/types/DatetimeElementValidationResult.type.ts';
import { createErrorMessagesFromConditionValidationResult } from '../../../utils/typeElementValidators/utils/conditions/createErrorMessagesFromConditionValidationResult.ts';
import {
  ConditionValidationData,
  getConditionValidationResult,
} from '../../../utils/typeElementValidators/utils/conditions/getConditionValidationResult.ts';

function getDateTimeTypeElementValidationResult(
  { typeElementValidationUtils }: ITypeElementValidatorDependencies,
  typeElement: IDateTimeTypeElementData,
  conditionValidationData: ConditionValidationData,
): DatetimeElementValidationResult {
  const { getNameValidationResult, getGuidelinesValidationResult } = typeElementValidationUtils;

  const nameValidationResult = getNameValidationResult(typeElement);
  const guidelinesValidationResult = getGuidelinesValidationResult(typeElement);

  const errorMessages: Array<string> = [];
  if (!nameValidationResult.isValid && nameValidationResult.errorMessage) {
    errorMessages.push(nameValidationResult.errorMessage);
  }

  if (!guidelinesValidationResult.isValid && guidelinesValidationResult.errorMessage) {
    errorMessages.push(guidelinesValidationResult.errorMessage);
  }

  const defaultValueResult = getDateTimeElementErrors({
    value: typeElement.defaultValue,
  });

  errorMessages.push(
    ...mapElementErrorToTypeElementErrorResult(defaultValueResult, typeElement.type).errorMessages,
  );

  const conditionValidationResult = getConditionValidationResult(
    typeElement,
    conditionValidationData,
  );

  return {
    conditionValidationResult,
    isDefaultValueValid: !defaultValueResult.errors.length,
    isNameValid: nameValidationResult.isValid,
    isGuidelinesValid: guidelinesValidationResult.isValid,
    errorMessages: [
      ...createErrorMessagesFromConditionValidationResult(conditionValidationResult),
      ...errorMessages,
    ],
  };
}

export const createValidateDateTimeTypeElementAction =
  (deps: ITypeElementValidatorDependencies) =>
  (typeElement: IBaseTypeElementData): ThunkFunction =>
  (dispatch, getState) => {
    const validationResult = getDateTimeTypeElementValidationResult(
      deps,
      typeElement as IDateTimeTypeElementData,
      deps.getConditionValidationData(getState),
    );

    dispatch(deps.typeElementValidated(typeElement.elementId, validationResult));
  };
