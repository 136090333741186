import { StrictMode, useRef } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { App } from './_shared/containers/App.tsx';
import { RedirectIfUserIsIncomplete } from './_shared/containers/routing/RedirectIfUserIsIncomplete.tsx';
import { AuthTokenProvider } from './_shared/contexts/AuthTokenProvider.tsx';
import { useAnimationsAccessibility } from './_shared/hooks/useAnimationsAccessibility.ts';
import { useBrowserActivationClasses } from './_shared/hooks/useBrowserActivationClasses.ts';
import { createClientReduxStore } from './_shared/stores/clientReduxStore.ts';
import { Initializer } from './initialization/containers/Initializer.tsx';

export const Root = () => {
  useAnimationsAccessibility();
  useBrowserActivationClasses();
  const storeRef = useRef(createClientReduxStore());

  return (
    <StrictMode>
      <Provider store={storeRef.current}>
        <HelmetProvider>
          <BrowserRouter>
            <AuthTokenProvider>
              <Initializer>
                <RedirectIfUserIsIncomplete>
                  <App />
                </RedirectIfUserIsIncomplete>
              </Initializer>
            </AuthTokenProvider>
          </BrowserRouter>
        </HelmetProvider>
      </Provider>
    </StrictMode>
  );
};
