import { InvariantException } from '@kontent-ai/errors';
import { createGuid } from '@kontent-ai/utils';
import { History } from 'history';
import { Dispatch, GetState } from '../../../../../../@types/Dispatcher.type.ts';
import { contentItemNameChanged } from '../contentItemEditingActions.ts';
import { InitContentItemEditing } from './initContentItemEditing.ts';

interface IDeps {
  readonly initContentItemEditing: InitContentItemEditing;
}

export const createRefreshItemEditing =
  (deps: IDeps) => (history: History) => async (dispatch: Dispatch, getState: GetState) => {
    const {
      contentApp: { editedContentItemVariant, editedContentItemVariantComments },
    } = getState();

    if (!editedContentItemVariant) {
      throw InvariantException(
        'refreshItemEditing: Cannot refresh item editing when "editedContentItemVariant" is falsy.',
      );
    }

    await dispatch(
      deps.initContentItemEditing(
        history,
        editedContentItemVariantComments.focusedCommentThreadId,
        createGuid(),
      ),
    );

    const {
      contentApp: { editedContentItem: refreshedEditedContentItem },
    } = getState();

    if (!refreshedEditedContentItem) {
      throw InvariantException(
        'refreshItemEditing: Cannot refresh breadcrumb when "refreshedEditedContentItem" is falsy.',
      );
    }

    dispatch(
      contentItemNameChanged(refreshedEditedContentItem.name, refreshedEditedContentItem.id),
    );
  };
