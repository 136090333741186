import { Button, ButtonSize } from '../../../../../../../_shared/uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../../../../../../_shared/uiComponents/Button/buttonStyle.ts';

type Props = {
  readonly children: string;
  readonly onClick: () => void;
};

export const ShowAllSavedFiltersButton = ({ onClick, children }: Props) => (
  <div className="saved-filters__show-all-filters">
    <Button style={ButtonStyle.Tertiary} size={ButtonSize.S} onClick={onClick}>
      {children}
    </Button>
  </div>
);
