import Immutable from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../@types/Dispatcher.type.ts';
import { getCurrentProject } from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { onboardingNotificationClosed } from '../../actions/sharedActions.ts';
import { trackUserEventWithData } from '../../actions/thunks/trackUserEvent.ts';
import {
  IOnboardingNotificationBarProps,
  OnboardingNotificationBar,
} from '../../components/OnboardingNotifications/OnboardingNotificationBar.tsx';
import { TrackedEvent } from '../../constants/trackedEvent.ts';
import { IStore } from '../../stores/IStore.type.ts';
import { getUrlFactory } from '../../utils/urlFactory.ts';

const Message: React.FC = () => (
  <>🚀 Awesome! Your content is blasting into the world. 🚀 Want to see it in action?</>
);

interface ContentPublishingOnboardingNotificationOwnProps {
  readonly publishedContentItemIds: Immutable.List<Uuid>;
}

interface StateProps {
  readonly message: React.ReactNode;
  readonly buttonMessage: string;
  readonly currentProjectId: Uuid;
}

function mapStateToProps(state: IStore): StateProps {
  return {
    buttonMessage: 'See JSON output',
    message: <Message />,
    currentProjectId: getCurrentProject(state).projectId,
  };
}

interface DispatchProps {
  readonly onButtonClick: (currentProjectId: Uuid) => () => void;
  readonly onClose: () => void;
  readonly onInit: () => void;
}

function mapDispatchToProps(
  dispatch: Dispatch,
  ownProps: ContentPublishingOnboardingNotificationOwnProps,
): DispatchProps {
  return {
    onButtonClick: (currentProjectId: Uuid) => () => {
      dispatch(
        trackUserEventWithData(TrackedEvent.FeatureUsed, {
          feature: 'onboarding-notification--cta',
          info: 'content-publishing--json',
        }),
      );
      dispatch(onboardingNotificationClosed());
      const jsonSourceUrl = `${getUrlFactory().getDeliverLiveUrl()}/${currentProjectId}/items?system.id[in]=${ownProps.publishedContentItemIds.join(
        ',',
      )}`;
      const jsonPrettifierUrl = getUrlFactory().getJsonPrettifierUrl(jsonSourceUrl);
      window.open(jsonPrettifierUrl, '_blank');
    },
    onClose: () => {
      dispatch(onboardingNotificationClosed());
    },
    onInit: () => {
      dispatch(
        trackUserEventWithData(TrackedEvent.FeatureUsed, {
          feature: 'onboarding-notification--displayed',
          info: 'content-publishing--json',
        }),
      );
    },
  };
}

function mergeProps(
  stateProps: StateProps,
  dispatchProps: DispatchProps,
): IOnboardingNotificationBarProps {
  return {
    message: stateProps.message,
    buttonMessage: stateProps.buttonMessage,
    onButtonClick: dispatchProps.onButtonClick(stateProps.currentProjectId),
    onClose: dispatchProps.onClose,
    onInit: dispatchProps.onInit,
  };
}

export const ContentPublishingOnboardingTechnicalUserNotification = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(OnboardingNotificationBar);
