import { areShallowEqual } from '@kontent-ai/utils';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from '../../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../../_shared/hooks/useSelector.ts';
import { IUserInfo } from '../../../../../../../../_shared/models/UserInfo.ts';
import { getEditedContentItemVariant } from '../../../../../../../../_shared/selectors/getEditedContentItemVariant.ts';
import { getSelectedLanguageId } from '../../../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { IStore } from '../../../../../../../../_shared/stores/IStore.type.ts';
import {
  getActiveAssignedContributors,
  getAllActiveContributors,
  getAvailableContributorsForEditing,
} from '../../../../../../../../_shared/utils/permissions/getAvailableContributors.ts';
import { createFormValidationResolver } from '../../../../../../../../_shared/utils/validation/createFormValidationResolver.ts';
import { IContentType } from '../../../../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IProjectContributor } from '../../../../../../../../data/models/users/ProjectContributor.ts';
import { getWorkflow } from '../../../../../../../../data/reducers/workflow/selectors/workflowSelectors.ts';
import {
  contributorsFormChanged,
  contributorsFormReset,
} from '../../../../actions/contentItemEditingActions.ts';
import { ContributorsFormBase } from '../../../../components/details/Contributors/ContributorsForm/ContributorsFormBase.tsx';
import { AssignmentSections } from '../../../../constants/AssignmentSections.ts';
import { IContributorsFormShape } from '../../../../models/IContributorsFormShape.type.ts';
import {
  isOtherAssignmentSectionSubmitting as isOtherAssignmentSectionSubmittingSelector,
  isSectionSubmitting as isSectionSubmittingSelector,
} from '../../../../selectors/isSectionSubmitting.ts';
import { contributorsFormBaseValidationConfig } from '../../../../validation/contributorsFormBaseValidation.ts';
import { useIsWarningDisplayed } from '../../../hooks/useIsWarningDisplayed.ts';

export type ContributorsFormBaseContainerProps = {
  readonly initialContributors: ReadonlySet<IUserInfo>;
  readonly onCancel: () => void;
  readonly onConfirm: (values: IContributorsFormShape) => Promise<void>;
};

const getAvailableContributors = (
  state: IStore,
  activeContributors: ReadonlyArray<IProjectContributor>,
): ReadonlyArray<IProjectContributor> => {
  const selectedLanguageId = getSelectedLanguageId(state);
  const { contentTypes, roles } = state.data;
  const { editedContentItem, editedContentItemVariant } = state.contentApp;
  const typeId = editedContentItem?.editedContentItemTypeId ?? '';
  const itemType: IContentType | undefined = contentTypes.byId.get(typeId);
  const workflowStatus = editedContentItemVariant?.assignment.workflowStatus;

  if (!selectedLanguageId || !itemType || !workflowStatus) {
    return [];
  }

  const currentWorkflow = getWorkflow(state, workflowStatus.workflowId);

  return getAvailableContributorsForEditing(
    workflowStatus,
    itemType,
    activeContributors,
    contentTypes.byId,
    roles.rolesById,
    currentWorkflow,
    selectedLanguageId,
    editedContentItem?.collectionId ?? null,
  );
};

const hasFormUnsavedChanges = (a: IContributorsFormShape, b: IContributorsFormShape): boolean =>
  !areShallowEqual(
    a.contributors.map((c) => c.userId),
    b.contributors.map((c) => c.userId),
  );

const ContributorsFormBaseContainer: React.FC<ContributorsFormBaseContainerProps> = (props) => {
  const activeContributors = useSelector((s) => getAllActiveContributors(s.data.users.usersById));
  const availableContributors = useSelector((s) => getAvailableContributors(s, activeContributors));
  const currentUserId = useSelector((s) => s.data.user.info.userId);
  const unsavedValues = useSelector(
    (s) => s.contentApp.editorUi.editingActions.forms.contributors.unsavedValues,
  );
  const isOtherAssignmentSectionSubmitting = useSelector((s) =>
    isOtherAssignmentSectionSubmittingSelector(s, AssignmentSections.Contributors),
  );
  const isSectionSubmitting = useSelector((s) =>
    isSectionSubmittingSelector(s, AssignmentSections.Contributors),
  );
  const workflowStepName = useSelector(
    (s) => getEditedContentItemVariant(s)?.assignment.workflowStatus.name ?? '',
  );

  const defaultValues = useMemo(
    (): IContributorsFormShape => ({
      contributors: getActiveAssignedContributors(props.initialContributors, activeContributors),
      showAll: false,
    }),
    [props.initialContributors, activeContributors],
  );

  const formProps = useForm<IContributorsFormShape>({
    defaultValues: unsavedValues || defaultValues,
    resolver: createFormValidationResolver(contributorsFormBaseValidationConfig, {
      availableContributors,
      workflowStepName,
    }),
  });

  const { handleSubmit, watch } = formProps;
  const dispatch = useDispatch();

  useEffect(() => {
    const subscription = watch((formValues: IContributorsFormShape) => {
      if (hasFormUnsavedChanges(formValues, defaultValues)) {
        dispatch(contributorsFormChanged(formValues));
      } else {
        dispatch(contributorsFormReset());
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, defaultValues]);

  const formValues = watch();
  const hasUnsavedChanges = hasFormUnsavedChanges(formValues, defaultValues);
  const isWarningDisplayed = useIsWarningDisplayed(hasUnsavedChanges);
  const submitForm = handleSubmit(props.onConfirm);

  const resetForm = () => {
    props.onCancel();
    dispatch(contributorsFormReset());
  };

  return (
    <ContributorsFormBase
      activeContributors={activeContributors}
      availableContributors={availableContributors}
      currentUserId={currentUserId}
      formProps={formProps}
      isOtherAssignmentSectionSubmitting={isOtherAssignmentSectionSubmitting}
      isSectionSubmitting={isSectionSubmitting}
      isWarningDisplayed={isWarningDisplayed}
      onReset={resetForm}
      onSubmit={submitForm}
      showAll={formValues.showAll}
      workflowStepName={workflowStepName}
    />
  );
};

ContributorsFormBaseContainer.displayName = 'ContributorsFormBaseContainer';

export { ContributorsFormBaseContainer as ContributorsFormBase };
