import 'focus-visible';
import 'styles';
import './app/_shared/debug/renderReason.ts';
import './configs/config.ts';
// The newline is a workaround for keeping the side effect imports unaffected by Biome sort https://github.com/biomejs/biome/issues/817

// eslint-disable-next-line react/no-deprecated
import { render } from 'react-dom';
import { Root } from './app/Root.tsx';
import { initializeSentry } from './app/_shared/sentry.ts';
import { initializeApplicationInsights } from './app/_shared/utils/applicationInsights.ts';

initializeApplicationInsights();
initializeSentry();

render(<Root />, document.getElementById('react-container'));
