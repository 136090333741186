import { RouterNavLink } from '@kontent-ai/component-library/Anchor';
import { MenuItemWithMouseHover } from '@kontent-ai/component-library/MenuItem';
import {
  VerticalMenuFrame,
  VerticalMenuItem,
  useNonAccessibleVerticalMenu,
} from '@kontent-ai/component-library/VerticalMenu';
import { CLPropTypes } from '@kontent-ai/component-library/validators';
import PropTypes from 'prop-types';
import React from 'react';
import { DropDownMenu } from '../../../DropDownMenu/DropDownMenu.tsx';
import { defaultDropdownTippyOptions } from '../../../DropDownMenu/DropDownMenuPositioner.tsx';
import { SelectCell } from '../../Selects/SelectCell.tsx';
import { SelectGroup } from '../../Selects/SelectGroup.tsx';

type CompactHorizontalTabsProps<HorizontalTabItem extends VerticalMenuItem<HorizontalTabItem>> = {
  readonly children?: never;
  readonly getItemProps: (menuItem: HorizontalTabItem) => {
    readonly label: string;
    readonly pathname: string;
  };
  readonly items: readonly HorizontalTabItem[];
  readonly selectedItem: HorizontalTabItem;
};

const propTypes: PropTypeMap<CompactHorizontalTabsProps<any>> = {
  children: CLPropTypes.never,
  getItemProps: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  selectedItem: PropTypes.object.isRequired,
};

export const CompactHorizontalTabs = React.forwardRef(
  <HorizontalTabItem extends VerticalMenuItem<HorizontalTabItem>>(
    { getItemProps, items, selectedItem }: CompactHorizontalTabsProps<HorizontalTabItem>,
    forwardedRef: React.Ref<HTMLDivElement>,
  ): ReturnType<React.FC<CompactHorizontalTabsProps<HorizontalTabItem>>> => {
    const { verticalMenuState } = useNonAccessibleVerticalMenu<HorizontalTabItem>(items);

    return (
      <SelectGroup
        ref={forwardedRef}
        selects={[selectedItem]}
        renderSelect={(item: HorizontalTabItem) => (
          <DropDownMenu
            tippyOptions={defaultDropdownTippyOptions}
            renderTrigger={(triggerProps, isOptionListVisible) => (
              <SelectCell
                isActive={isOptionListVisible}
                label={getItemProps(item).label}
                {...triggerProps}
              />
            )}
            renderDropDown={(hideMenu) => (
              <VerticalMenuFrame
                renderItem={(itemProps) => {
                  if (!itemProps.item.value) {
                    return null;
                  }
                  const { label, pathname, ...restProps } = getItemProps(itemProps.item.value);
                  return (
                    <RouterNavLink to={pathname} {...restProps}>
                      <MenuItemWithMouseHover
                        menuItemState={
                          itemProps.item.value === selectedItem ? 'selected' : 'default'
                        }
                        text={label}
                        onPress={hideMenu}
                      />
                    </RouterNavLink>
                  );
                }}
                state={verticalMenuState}
              />
            )}
          />
        )}
      />
    );
  },
);

CompactHorizontalTabs.displayName = 'CompactHorizontalTabs';
CompactHorizontalTabs.propTypes = propTypes;
