import React from 'react';
import { TaskForm, TaskFormContainerProps } from '../../../containers/details/Tasks/TaskForm.tsx';
import { TaskFormSharedProps } from './TaskForm.tsx';

export const AddTaskFormBase: React.FC<
  React.PropsWithChildren<TaskFormContainerProps & TaskFormSharedProps>
> = (props) => (
  <TaskForm
    {...props}
    submitButtonName="Add"
    submitButtonText={props.isTaskSubmitting ? 'Adding…' : 'Add'}
  />
);

AddTaskFormBase.displayName = 'AddTaskFormBase';
