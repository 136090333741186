import { RouterLink } from '@kontent-ai/component-library/Anchor';
import { StatusBarIconButton } from '@kontent-ai/component-library/StatusBar';
import { mergeProps } from '@react-aria/utils';
import { LocationDescriptor } from 'history';
import PropTypes from 'prop-types';
import React, { ComponentProps } from 'react';

export interface IBackButtonProps {
  readonly disabled?: boolean;
  readonly to: LocationDescriptor;
}

const propTypes: PropTypeMap<IBackButtonProps> = {
  disabled: PropTypes.bool,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

const goBackText = 'Go back';

export const BackButton: React.FC<React.PropsWithChildren<IBackButtonProps>> = ({
  to,
  disabled,
  ...otherProps
}) => {
  const linkProps: Omit<ComponentProps<typeof RouterLink>, 'ref'> = {
    to,
  };

  return (
    <StatusBarIconButton
      component={disabled ? 'button' : RouterLink}
      disabled={disabled}
      iconName="ChevronLeft"
      label={goBackText}
      {...mergeProps(disabled ? {} : linkProps, otherProps)}
    />
  );
};

BackButton.displayName = 'BackButton';
BackButton.propTypes = propTypes;
