import { useRef } from 'react';

/**
 * Decides whether the unsaved changes warning should be displayed.
 * If there were no unsaved changes initially, no warning is shown regardless of the actual form state.
 * If there were unsaved changes initially then a warning is shown if the form contains unsaved changes.
 * @param {boolean} hasUnsavedChanges - Flag that indicates if the current form has unsaved changes
 * @return {boolean} Flag that determines whether to show a warning for unsaved changes
 */
export const useIsWarningDisplayed = (hasUnsavedChanges: boolean): boolean => {
  const hadInitialUnsavedChangesRef = useRef(hasUnsavedChanges);

  return hadInitialUnsavedChangesRef.current && hasUnsavedChanges;
};
