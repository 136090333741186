import { useAttachRef } from '@kontent-ai/hooks';
import { usePress } from '@react-aria/interactions';
import { OverlayTriggerProps, useOverlay, useOverlayTrigger } from '@react-aria/overlays';
import { mergeProps } from '@react-aria/utils';
import { useOverlayTriggerState } from '@react-stately/overlays';
import PropTypes from 'prop-types';
import React from 'react';
import {
  DropDownMenuPositioner,
  DropDownMenuPositionerProps,
  dropdownMenuPositionerPropTypes,
} from './DropDownMenuPositioner.tsx';

export type DropDownMenuControlledProps = DropDownMenuPositionerProps & {
  readonly onDropDownVisibilityChange?: (isOpen: boolean) => void;
  readonly triggerId?: string;
  readonly type?: OverlayTriggerProps['type'];
};

const dropdownMenuControlledPropTypes: PropTypeMap<DropDownMenuControlledProps> = {
  onDropDownVisibilityChange: PropTypes.func,
  triggerId: PropTypes.string,
  type: PropTypes.oneOf(['dialog', 'menu', 'listbox', 'tree', 'grid']),
  ...dropdownMenuPositionerPropTypes,
};

export const DropDownMenuControlled: React.FC<DropDownMenuControlledProps> = ({
  allowAnimation = true,
  dropDownRef,
  isDropDownVisible,
  onDropDownVisibilityChange,
  renderDropDown,
  renderTrigger,
  tippyOptions,
  triggerRef,
  triggerId,
  type = 'menu',
}) => {
  const { refObject: triggerRefObject, refToForward: triggerRefToForward } =
    useAttachRef<HTMLElement>(triggerRef);

  const { refObject: dropDownRefObject, refToForward: dropDownRefToForward } =
    useAttachRef<HTMLElement>(dropDownRef);

  const triggerState = useOverlayTriggerState({
    isOpen: isDropDownVisible,
    onOpenChange: onDropDownVisibilityChange,
  });
  const { triggerProps: overlayTriggerProps, overlayProps: overlayPropsFromTrigger } =
    useOverlayTrigger({ type }, triggerState, triggerRefObject);

  const { pressProps } = usePress(overlayTriggerProps);

  const { overlayProps } = useOverlay(
    {
      isDismissable: !!onDropDownVisibilityChange,
      isOpen: isDropDownVisible,
      onClose: () => {
        onDropDownVisibilityChange?.(false);
      },
      shouldCloseOnInteractOutside: (element) =>
        !(
          triggerRefObject.current?.contains(element) ||
          dropDownRefObject.current?.contains(element)
        ),
    },
    dropDownRefObject,
  );

  return (
    <DropDownMenuPositioner
      allowAnimation={allowAnimation}
      dropDownRef={dropDownRefToForward}
      isDropDownVisible={isDropDownVisible}
      renderDropDown={(triggerWidth, trigger, dropDownProps) =>
        renderDropDown(
          triggerWidth,
          trigger,
          mergeProps(overlayPropsFromTrigger, overlayProps, dropDownProps),
        )
      }
      renderTrigger={(triggerProps) =>
        renderTrigger(mergeProps(pressProps, triggerProps, { id: triggerId }))
      }
      tippyOptions={tippyOptions}
      triggerRef={triggerRefToForward}
    />
  );
};

DropDownMenuControlled.displayName = 'DropDownMenuControlled';
DropDownMenuControlled.propTypes = dropdownMenuControlledPropTypes;
