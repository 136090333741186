import { Action } from '../../../../../../../@types/Action.type.ts';
import { ITaskFormState } from '../../../../../../contentInventory/content/stores/IContentAppStoreState.ts';
import {
  ContentItemEditing_Sidebar_Section_Cleaned_Up,
  ContentItemEditing_TasksForm_Changed,
  ContentItemEditing_TasksForm_Reset,
} from '../../../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

const initialState: ReadonlyMap<Uuid, ITaskFormState> = new Map<Uuid, ITaskFormState>();

export const tasks = (
  state: ReadonlyMap<Uuid, ITaskFormState> = initialState,
  action: Action,
): ReadonlyMap<Uuid, ITaskFormState> => {
  switch (action.type) {
    case ContentItemEditing_TasksForm_Changed: {
      const { taskId, formValues } = action.payload;
      const newState = new Map<Uuid, ITaskFormState>(state);

      newState.set(taskId, {
        unsavedValues: formValues,
      });

      return newState;
    }

    case ContentItemEditing_TasksForm_Reset: {
      const newState = new Map<Uuid, ITaskFormState>(state);
      newState.delete(action.payload.taskId);
      return newState;
    }

    case ContentItemEditing_Sidebar_Section_Cleaned_Up:
      return initialState;

    default:
      return state;
  }
};
