import { Button } from '@kontent-ai/component-library/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';

export interface INoItemsFoundEmptyStateProps {
  readonly onClearFilter: () => void;
}

const propTypes: PropTypesShape<INoItemsFoundEmptyStateProps> = {
  onClearFilter: PropTypes.func.isRequired,
};

export const NoItemsFoundEmptyState: React.FC<INoItemsFoundEmptyStateProps> = (props) => (
  <EmptyState size="fullOffset">
    <EmptyState.Title>We couldn’t find any matches.</EmptyState.Title>
    <EmptyState.Content>
      <EmptyState.ContentGroup>
        <p>
          There’s no content item that was edited in the last 90 days and matches the current
          filters.
        </p>
        <p>Try using different filters.</p>
      </EmptyState.ContentGroup>
    </EmptyState.Content>
    <EmptyState.Footer>
      <Button buttonStyle="primary" onClick={props.onClearFilter}>
        Clear the filters
      </Button>
    </EmptyState.Footer>
  </EmptyState>
);

NoItemsFoundEmptyState.displayName = 'NoItemsFoundEmptyState';
NoItemsFoundEmptyState.propTypes = propTypes;
