import { Box } from '@kontent-ai/component-library/Box';
import React from 'react';
import { LastUpdatedAt } from '../../../../../_shared/components/LastUpdatedAt.tsx';
import { ItemColumnCode } from '../../../../../_shared/constants/itemColumnCode.ts';
import { ScrollTableCellWrappedByLink } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableCellWrappedByLink.tsx';
import { ScrollTableCheckboxCell } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableCheckboxCell.tsx';
import { ScrollTableRow } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableRow.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { ContentItemPublishedDate } from '../../../../contentInventory/content/features/ContentItemInventory/components/ItemInventoryScrollTable/Cells/ContentItemPublishedDate.tsx';
import {
  EmptyCellContent,
  ItemCollectionCellContent,
  ItemNameCellContentWithHighlight,
  ItemSpaceCellContent,
  ItemTypeCellContent,
  OpenInNewTabCell,
  getTextForTableCell,
} from '../../../../contentInventory/content/features/ContentItemInventory/components/ItemInventoryScrollTable/Cells/ContentItemScrollTableCellsContents.tsx';
import {
  SpaceNamesMetadata,
  getCollapsedSpaceNames,
} from '../../../../environmentSettings/spaces/utils/spacesUtils.ts';
import { ListingContentItemWithScore } from '../types/ListingContentItemWithScore.type.ts';

type Props = {
  readonly allowNavigationWithOnClick?: boolean;
  readonly collectionName: string;
  readonly disabledCheckboxMessage?: string;
  readonly disabledClickMessage?: string;
  readonly filterSearchPhrase: string;
  readonly hiddenColumns: ReadonlySet<ItemColumnCode>;
  readonly hideVariantSpecificInfo?: boolean;
  readonly index: number;
  readonly isDisabled?: boolean;
  readonly isHighlighted?: boolean;
  readonly isItemChecked: boolean;
  readonly item: ListingContentItemWithScore;
  readonly itemLinkPath: string;
  readonly itemType: IContentType;
  readonly onCheckboxClick?: (shiftPressed: boolean) => void;
  readonly onItemClick?: (shiftPressed: boolean) => void;
  readonly onItemDoubleClick?: () => void;
  readonly openInNewWindowDisabledMessage?: string;
  readonly renderWorkflowStatus: () => React.ReactElement;
  readonly showOpenInNewWindowCell?: boolean;
  readonly showScoreColumn: boolean;
  readonly spaceData: SpaceNamesMetadata;
};

export const RecommendedLinkedItemsScrollTableRow: React.FC<Props> = ({
  allowNavigationWithOnClick,
  collectionName,
  disabledCheckboxMessage,
  disabledClickMessage,
  filterSearchPhrase,
  hiddenColumns,
  hideVariantSpecificInfo,
  index,
  isDisabled,
  isHighlighted,
  isItemChecked,
  item,
  itemLinkPath,
  itemType,
  onCheckboxClick,
  onItemClick,
  onItemDoubleClick,
  openInNewWindowDisabledMessage,
  renderWorkflowStatus,
  showOpenInNewWindowCell,
  showScoreColumn,
  spaceData,
}) => {
  const clickableCellProps = {
    allowNavigationWithOnClick,
    linkPath: itemLinkPath,
    onItemClick,
    onItemDoubleClick,
    tooltipText: disabledClickMessage,
  };

  const displayNames = getCollapsedSpaceNames(spaceData);

  return (
    <ScrollTableRow
      objectName={item.item.name}
      isDisabled={isDisabled}
      isHighlighted={isHighlighted}
      isSelected={isItemChecked}
    >
      {onCheckboxClick && (
        <ScrollTableCheckboxCell
          disabledMessage={disabledCheckboxMessage}
          id={item.item.id}
          isItemChecked={isItemChecked}
          name={`Content item — ${item.item.name}`}
          onCheckboxClick={onCheckboxClick}
          tabIndex={index}
        />
      )}

      <ScrollTableCellWrappedByLink
        size={10}
        isGrowing
        title={item.item.name}
        dataUiAttribute={getDataUiElementAttribute(DataUiElement.ItemsTableColumnName)}
        {...clickableCellProps}
      >
        <ItemNameCellContentWithHighlight
          item={item}
          filterSearchPhrase={filterSearchPhrase}
          disabled={isDisabled}
          hideVariantSpecificInfo={hideVariantSpecificInfo}
        />
      </ScrollTableCellWrappedByLink>

      {showOpenInNewWindowCell && (
        <OpenInNewTabCell path={itemLinkPath} disabledMessage={openInNewWindowDisabledMessage} />
      )}

      {!hiddenColumns.has(ItemColumnCode.WorkflowStep) && (
        <ScrollTableCellWrappedByLink
          size={4}
          isGrowing
          dataUiAttribute={getDataUiElementAttribute(DataUiElement.ItemsTableColumnWorkflowStep)}
          {...clickableCellProps}
        >
          {renderWorkflowStatus()}
        </ScrollTableCellWrappedByLink>
      )}

      {!hiddenColumns.has(ItemColumnCode.ContentType) && (
        <ScrollTableCellWrappedByLink
          size={7}
          isGrowing
          title={itemType.name}
          dataUiAttribute={getDataUiElementAttribute(DataUiElement.ItemsTableColumnType)}
          {...clickableCellProps}
        >
          <ItemTypeCellContent itemType={itemType} />
        </ScrollTableCellWrappedByLink>
      )}

      {!hiddenColumns.has(ItemColumnCode.Collection) && (
        <ScrollTableCellWrappedByLink
          size={7}
          isGrowing
          title={getTextForTableCell(collectionName)}
          dataUiAttribute={getDataUiElementAttribute(DataUiElement.ItemsTableColumnCollection)}
          {...clickableCellProps}
        >
          <ItemCollectionCellContent collectionName={collectionName} />
        </ScrollTableCellWrappedByLink>
      )}

      {!hiddenColumns.has(ItemColumnCode.Space) && (
        <ScrollTableCellWrappedByLink
          size={7}
          isGrowing
          title={spaceData.usedSpaces.map(({ name }) => name).join(', ')}
          dataUiAttribute={getDataUiElementAttribute(DataUiElement.ItemsTableColumnSpace)}
          {...clickableCellProps}
        >
          <ItemSpaceCellContent spaceNames={displayNames} />
        </ScrollTableCellWrappedByLink>
      )}

      {!hiddenColumns.has(ItemColumnCode.LastPublishedAt) && (
        <ScrollTableCellWrappedByLink
          size={4}
          dataUiAttribute={getDataUiElementAttribute(DataUiElement.ItemsTableColumnPublished)}
          {...clickableCellProps}
        >
          {item.variant ? (
            <ContentItemPublishedDate
              publishingState={item.variant.publishingState}
              lastPublishedAt={item.variant.lastPublishedAt}
            />
          ) : (
            <EmptyCellContent />
          )}
        </ScrollTableCellWrappedByLink>
      )}

      {!hiddenColumns.has(ItemColumnCode.LastModifiedAt) && (
        <ScrollTableCellWrappedByLink
          size={4}
          dataUiAttribute={getDataUiElementAttribute(DataUiElement.ItemsTableColumnLastModified)}
          {...clickableCellProps}
        >
          {item.variant ? <LastUpdatedAt time={item.variant.lastModified} /> : <EmptyCellContent />}
        </ScrollTableCellWrappedByLink>
      )}

      {showScoreColumn && (
        <ScrollTableCellWrappedByLink size={4} isGrowing {...clickableCellProps}>
          {item.score ? <Box>{item.score.toFixed(3)}</Box> : <EmptyCellContent />}
        </ScrollTableCellWrappedByLink>
      )}
    </ScrollTableRow>
  );
};
