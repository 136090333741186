import PropTypes, { ValidationMap } from 'prop-types';
import { PropTypeOrNull } from '../../../../_shared/utils/propTypesValidators.ts';
import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  CompiledContentItemElementDataValidationMap,
  ICompiledContentItemElementData,
} from './ICompiledContentItemElement.ts';

interface ITaxonomyItemElementValue {
  readonly value: ReadonlySet<Uuid>;
}

export interface ITaxonomyItemElement
  extends ITaxonomyItemElementValue,
    ICompiledContentItemElementData {
  readonly groupId: Uuid | null;
}

export const EmptyTaxonomyItemElementValue: ITaxonomyItemElementValue = {
  value: new Set(),
};

export const EmptyTaxonomyItemElement: ITaxonomyItemElement = {
  elementId: '',
  groupId: null,
  lastModifiedAt: null,
  lastModifiedBy: null,
  type: ElementType.Taxonomy,
  ...EmptyTaxonomyItemElementValue,
};

export const TaxonomyItemElementPropTypes = PropTypes.exact<ValidationMap<ITaxonomyItemElement>>({
  ...CompiledContentItemElementDataValidationMap,
  groupId: PropTypeOrNull(PropTypes.string),
  value: PropTypes.instanceOf<ReadonlySet<Uuid>>(Set).isRequired,
});

export const newTaxonomyElement = (
  params: Partial<ITaxonomyItemElement>,
): ITaxonomyItemElement => ({
  ...EmptyTaxonomyItemElement,
  ...params,
});
