import { PortalContainerContextProvider } from '@kontent-ai/component-library/context';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { ScrollContainerContextProvider } from '../../../../component-library/components/ScrollContainer/ScrollContainerContext.tsx';
import {
  DataUiElement,
  ObjectWithDataAttribute,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import {
  IForwardedRefProps,
  forwardRef,
  forwardedRefProps,
} from '../../utils/forwardedRefProps.tsx';

export enum PopoverIsPointing {
  Up = 'popover--is-pointing-up',
  Down = 'popover--is-pointing-down',
  Left = 'popover--is-pointing-left',
  Right = 'popover--is-pointing-right',
}

export enum ArrowPosition {
  Start = 'popover--has-pointer-at-start',
  Middle = 'popover--has-pointer-at-middle',
  End = 'popover--has-pointer-at-end',
}

export enum PopoverWidth {
  IsSmall = 'popover--is-small',
  IsDefault = ' ',
  IsMediumWide = 'popover--is-medium-wide',
  IsWide = 'popover--is-wide',
}

type PopoverBaseProps = {
  readonly arrowPosition?: ArrowPosition;
  readonly className?: string;
  readonly isDark?: boolean;
  readonly width?: PopoverWidth;
};

const popoverBasePropTypes: PropTypesShape<PopoverBaseProps> = {
  arrowPosition: PropTypes.oneOf(Object.values(ArrowPosition)),
  className: PropTypes.string,
  isDark: PropTypes.bool,
  width: PropTypes.string,
};

export interface IPopoverProps extends PopoverBaseProps {
  readonly arrowOffset?: number;
  readonly hasFullWidth?: boolean;
  readonly hintDataAttribute?: ObjectWithDataAttribute;
  readonly orientation?: PopoverIsPointing;
  readonly popoverDataAttribute?: ObjectWithDataAttribute;
  readonly style?: React.CSSProperties;
}

export const popoverPropTypes: PropTypesShape<IPopoverProps> = {
  ...popoverBasePropTypes,
  arrowOffset: PropTypes.number,
  hasFullWidth: PropTypes.bool,
  hintDataAttribute: PropTypes.object,
  orientation: PropTypes.oneOf(Object.values(PopoverIsPointing)),
  popoverDataAttribute: PropTypes.object,
  style: PropTypes.object,
};

const Popover: React.FC<
  React.PropsWithChildren<IPopoverProps & IForwardedRefProps<HTMLDivElement>>
> = ({
  arrowOffset,
  arrowPosition,
  children,
  className,
  forwardedRef,
  hasFullWidth,
  hintDataAttribute,
  isDark,
  orientation,
  popoverDataAttribute,
  style,
  width,
}) => {
  const portalContainerRef = useRef<HTMLDivElement>(null);

  return (
    <PortalContainerContextProvider portalContainerRef={portalContainerRef}>
      <div
        className={classNames('popover', className, orientation, arrowPosition, width, {
          'popover--is-dark': isDark,
          'popover--has-full-width': hasFullWidth,
        })}
        ref={portalContainerRef}
        style={style}
        {...getDataUiElementAttribute(DataUiElement.Popover)}
        {...popoverDataAttribute}
      >
        <div className="popover__hint" {...hintDataAttribute} ref={forwardedRef}>
          <ScrollContainerContextProvider tippyBoundaryRef={null}>
            {children}
          </ScrollContainerContextProvider>
          <div className="popover__arrow" style={{ transform: `translateX(${arrowOffset}px)` }} />
        </div>
      </div>
    </PortalContainerContextProvider>
  );
};
Popover.displayName = 'Popover';
Popover.propTypes = popoverPropTypes;

const PopoverFRC = forwardRef(Popover);
export { PopoverFRC as Popover };

interface IPopoverActionsPane {
  readonly dataAttribute?: ObjectWithDataAttribute;
}

const actionsPanePropTypes: PropTypesShape<IPopoverActionsPane> = {
  dataAttribute: PropTypes.object,
};

export const PopoverActionsPane: React.FC<React.PropsWithChildren<IPopoverActionsPane>> = (
  props,
) => {
  return (
    <div className="popover__action" {...props.dataAttribute}>
      {props.children}
    </div>
  );
};
PopoverActionsPane.displayName = 'PopoverActionsPane';
PopoverActionsPane.propTypes = actionsPanePropTypes;

export const PopoverMessage = React.forwardRef<HTMLDivElement, React.PropsWithChildren<NoProps>>(
  (props, forwardedRef) => (
    <div className="popover__message" ref={forwardedRef}>
      {props.children}
    </div>
  ),
);
PopoverMessage.displayName = 'PopoverMessage';

interface IStickyPopoverProps extends PopoverBaseProps, IForwardedRefProps<any> {
  readonly attachedTo: React.RefObject<HTMLElement>;
  readonly orientation?: PopoverIsPointing | undefined;
  readonly popoverRef: React.RefObject<any>;
}

const stickyPopoverPropTypes: PropTypesShape<IStickyPopoverProps> = {
  ...popoverBasePropTypes,
  ...forwardedRefProps,
  attachedTo: PropTypes.object.isRequired,
  orientation: PropTypes.oneOf(Object.values(PopoverIsPointing)),
  popoverRef: PropTypes.object.isRequired,
};

export function enhanceWithStickyPopover<PropsToExtend extends IForwardedRefProps<HTMLElement>>(
  Component: React.ComponentType<React.PropsWithChildren<PropsToExtend>>,
) {
  const StickyPopoverComponent: React.FC<
    React.PropsWithChildren<PropsToExtend & IStickyPopoverProps>
  > = ({
    arrowPosition,
    attachedTo,
    className,
    isDark,
    orientation,
    popoverRef,
    width,
    ...otherProps
  }) => {
    return (
      <PopoverFRC
        className={className}
        arrowOffset={0}
        orientation={orientation ?? PopoverIsPointing.Down}
        isDark={isDark}
        arrowPosition={arrowPosition || ArrowPosition.Start}
        width={width || PopoverWidth.IsWide}
      >
        <Component {...(otherProps as PropsToExtend)} />
      </PopoverFRC>
    );
  };

  StickyPopoverComponent.displayName = `enhanceWithStickyPopover(${Component.displayName})`;
  StickyPopoverComponent.propTypes = stickyPopoverPropTypes as any;

  return forwardRef(StickyPopoverComponent);
}
