import { useAttachRef } from '@kontent-ai/hooks';
import React, { useCallback } from 'react';
import { CreateAutoScrollId } from '../../../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { useAutoScroll } from '../../../../../../_shared/hooks/useAutoScroll.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { forwardRef } from '../../../../../../_shared/utils/forwardedRefProps.tsx';
import {
  CommentThreadItemType,
  ICommentThreadItemContentModel,
} from '../../../../models/comments/CommentThreadItem.ts';
import { CommentThreadType } from '../../../../models/comments/CommentThreads.ts';
import { isThreadResolved } from '../../../../utils/commentUtils.ts';
import {
  commentThreadReplyCanceled,
  commentThreadReplyFocusLost,
  commentThreadReplyStarted,
  commentThreadResolveUndoCancel,
} from '../../actions/contentItemEditingActions.ts';
import {
  blurCommentThread,
  createCommentThreadItemAction,
  focusCommentThread,
  unresolveCommentThread,
} from '../../actions/thunkContentItemEditingActions.ts';
import {
  CommentThread as CommentThreadComponent,
  ICommentThreadOwnProps,
} from '../../components/comments/CommentThread.tsx';
import { getNewCommentThreadItemType } from '../../utils/inlineCommentUtils.ts';

// Regular comment thread is not using auto-scroll.
// It is positioned by special processed in InlineCommentPane and FocusedCommentThreadScroller.
const CommentThreadContainer: React.FC<ICommentThreadOwnProps> = (props) => {
  const isFocused = useSelector(
    (s) =>
      s.contentApp.editedContentItemVariantComments.focusedCommentThreadId ===
      props.commentThread.id,
  );
  const resolvedBy = useSelector(
    (s) => s.data.users.usersById.get(props.commentThread.resolvedBy || '') ?? null,
  );
  const type = useSelector((s) =>
    getNewCommentThreadItemType(
      s.contentApp.editorUi.newCommentThreadItemTypeMappings,
      props.commentThread.id,
    ),
  );

  const dispatch = useDispatch();
  const onBlur = useCallback(() => dispatch(blurCommentThread()), []);
  const onBlurNewItemText = useCallback(
    (isCommentPending: boolean) =>
      dispatch(commentThreadReplyFocusLost(props.commentThread.id, isCommentPending)),
    [props.commentThread.id],
  );
  const onCancelNewItem = useCallback(
    () => dispatch(commentThreadReplyCanceled(props.commentThread.id)),
    [props.commentThread.id],
  );
  const onFocus = useCallback(
    () => dispatch(focusCommentThread(props.commentThread.id)),
    [props.commentThread.id],
  );
  const onResolveUndo = useCallback(
    () => dispatch(unresolveCommentThread(props.commentThread.id)),
    [props.commentThread.id],
  );
  const onResolveUndoCancel = useCallback(
    () => dispatch(commentThreadResolveUndoCancel(props.commentThread.id)),
    [props.commentThread.id],
  );
  const onStartNewItem = useCallback(
    (newCommentType: CommentThreadItemType) =>
      dispatch(commentThreadReplyStarted(props.commentThread.id, newCommentType)),
    [props.commentThread.id],
  );
  const onSubmitNewItem = useCallback(
    (newCommentType: CommentThreadItemType, content: ICommentThreadItemContentModel) =>
      dispatch(createCommentThreadItemAction(props.commentThread.id, newCommentType, content)),
    [props.commentThread.id],
  );

  return (
    <CommentThreadComponent
      ref={props.forwardedRef}
      canSuggest={props.commentThread.threadType === CommentThreadType.RichText}
      className={props.className}
      commentThread={props.commentThread}
      isFocused={isFocused}
      isInlineThreadWithRemovedContent={props.isInlineThreadWithRemovedContent}
      isResolved={isThreadResolved(props.commentThread)}
      onBlur={onBlur}
      onBlurNewItemText={onBlurNewItemText}
      onCancelNewItem={onCancelNewItem}
      onFocus={onFocus}
      onFocused={props.onFocused}
      onResized={props.onResized}
      onResolveUndo={onResolveUndo}
      onResolveUndoCancel={onResolveUndoCancel}
      onStartNewItem={onStartNewItem}
      onSubmitNewItem={onSubmitNewItem}
      resolvedBy={resolvedBy}
      showReferenceForInlineThreads={props.showReferenceForInlineThreads}
      type={type}
    />
  );
};
CommentThreadContainer.displayName = 'CommentThreadContainer';
export const CommentThread = forwardRef(CommentThreadContainer);

// Comment thread in discussion is using auto-scroll to handle scrolling for comment link from notification e-mail.
const CommentSectionThreadContainer: React.FC<ICommentThreadOwnProps> = ({
  forwardedRef,
  ...props
}) => {
  const scrollId = CreateAutoScrollId.forCommentThread(props.commentThread.id);
  const { refObject: scrollTargetRef, refToForward } = useAttachRef(forwardedRef);
  useAutoScroll({ scrollTargetRef, scrollId });

  return <CommentThread ref={refToForward} {...props} />;
};
CommentSectionThreadContainer.displayName = 'CommentSectionThreadContainer';
export const CommentSectionThread = forwardRef(CommentSectionThreadContainer);
