import React from 'react';
import { IconName } from '../../../../../../_shared/constants/iconEnumGenerated.ts';
import { ControlAltShortcutTemplate } from '../../../../../../_shared/constants/shortcutSymbols.ts';
import {
  DataUiRteAction,
  getDataUiActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  IForwardedRefProps,
  forwardRef,
} from '../../../../../../_shared/utils/forwardedRefProps.tsx';
import {
  IToggleButtonProps,
  RTEToolbarIconButton,
  toggleButtonPropTypes,
} from '../../../toolbars/components/RTEToolbarButton.tsx';

const Heading3ButtonComponent: React.FC<
  IToggleButtonProps & IForwardedRefProps<HTMLButtonElement>
> = (props) => (
  <RTEToolbarIconButton
    {...props}
    iconName={IconName.H3}
    ref={props.forwardedRef}
    shortcut={ControlAltShortcutTemplate(3)}
    tooltipPlacement={props.tooltipPlacement ?? 'bottom-start'}
    tooltipText={props.tooltipText ?? 'Heading 3'}
    {...getDataUiActionAttribute(DataUiRteAction.HeaderThree)}
  />
);

Heading3ButtonComponent.displayName = 'Heading3Button';
Heading3ButtonComponent.propTypes = toggleButtonPropTypes;

export const Heading3Button = forwardRef(Heading3ButtonComponent);
