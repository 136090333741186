import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import React, { useState } from 'react';
import { Button } from '../../uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../uiComponents/Button/buttonStyle.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

export enum UmuxFeedbackButtonOrder {
  SubmitFirst = 'submit-first',
  CancelFirst = 'cancel-first',
}

interface IUmuxFeedbackQuestionnaireFooterProps {
  readonly buttonOrder: UmuxFeedbackButtonOrder;
  readonly cancelButtonStyle: ButtonStyle;
  readonly dismissFeedbackButtonText: string;
  readonly isDisabled: boolean;
  readonly onSubmit: () => void;
  readonly onClose: () => void;
  readonly overAllOtherElements?: boolean;
  readonly sendFeedbackButtonText: string;
  readonly submitButtonStyle: ButtonStyle;
}

export const UmuxFeedbackQuestionnaireFooter: React.FC<IUmuxFeedbackQuestionnaireFooterProps> = ({
  buttonOrder,
  cancelButtonStyle,
  dismissFeedbackButtonText,
  isDisabled,
  sendFeedbackButtonText,
  submitButtonStyle,
  overAllOtherElements,
  onSubmit,
  onClose,
}) => {
  const [isDismissInProgress, setIsDismissInProgress] = useState(false);
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  const dismissFeedback = (): void => {
    setIsDismissInProgress(true);
    onClose();
  };

  const submitFeedback = (): void => {
    setIsSubmitInProgress(true);
    onSubmit();
  };

  const actionInProgress = isDismissInProgress || isSubmitInProgress;

  const submitButton = (
    <Tooltip
      tippyOptions={overAllOtherElements ? { appendTo: 'parent' } : undefined}
      tooltipText={isDisabled ? 'Please fill the survey before sending.' : undefined}
      placement="bottom"
    >
      <Button
        onClick={submitFeedback}
        style={submitButtonStyle}
        disabled={isDisabled || actionInProgress}
        hasLoader={isSubmitInProgress}
        {...getDataUiActionAttribute(DataUiAction.SendUserFeedback)}
      >
        {sendFeedbackButtonText}
      </Button>
    </Tooltip>
  );

  const cancelButton = (
    <Button
      style={cancelButtonStyle}
      onClick={dismissFeedback}
      disabled={actionInProgress}
      hasLoader={isDismissInProgress}
      {...getDataUiActionAttribute(DataUiAction.DismissUserFeedback)}
    >
      {dismissFeedbackButtonText}
    </Button>
  );

  const buttons =
    buttonOrder === UmuxFeedbackButtonOrder.SubmitFirst ? (
      <>
        {submitButton}
        {cancelButton}
      </>
    ) : (
      <>
        {cancelButton}
        {submitButton}
      </>
    );

  return <div className="feedback-modal__action-buttons">{buttons}</div>;
};

UmuxFeedbackQuestionnaireFooter.displayName = 'UmuxFeedbackQuestionnaireFooter';
