import PropTypes, { ValidationMap } from 'prop-types';
import { PropTypeOrNull } from '../../../../../_shared/utils/propTypesValidators.ts';
import { TaxonomyCompiledTypeElementServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { TypeElementConfigurability } from '../../../../contentModels/shared/models/elements/types/elementConfigurabilityOptions.ts';
import { getQuantityUnitOption } from '../../../../contentModels/shared/utils/typeElementDataConverters/utils/typeElementDataConvertersUtils.ts';
import { ElementType } from '../ContentItemElementType.ts';
import { QuantityOption } from '../quantityOptions.ts';
import {
  BaseTypeElementValidationMap,
  EmptyBaseTypeElement,
  IBaseTypeElement,
  ITypeElementWithNumberOfItemsLimit,
  NumberOfItemsLimitParametersValidationMap,
  getBaseTypeElementFromServerModel,
} from './types/TypeElement.ts';

export const EmptyTaxonomyTypeElement: ITaxonomyTypeElement = {
  ...EmptyBaseTypeElement,
  _configurability: TypeElementConfigurability.Recommended,
  _quantityUnitOption: QuantityOption.Exactly,
  defaultValue: [],
  maxItems: null,
  minItems: null,
  taxonomyGroupId: null,
  type: ElementType.Taxonomy,
};

export interface ITaxonomyTypeElement extends IBaseTypeElement, ITypeElementWithNumberOfItemsLimit {
  readonly defaultValue: UuidArray;
  readonly taxonomyGroupId: Uuid | null;
  readonly type: ElementType.Taxonomy;
}

export const TaxonomyTypeElementPropTypes = PropTypes.exact<ValidationMap<ITaxonomyTypeElement>>({
  ...BaseTypeElementValidationMap([ElementType.Taxonomy]),
  ...NumberOfItemsLimitParametersValidationMap,
  defaultValue: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  taxonomyGroupId: PropTypeOrNull(PropTypes.string),
});

export function getTaxonomyTypeElementFromServerModel(
  serverModel: TaxonomyCompiledTypeElementServerModel,
): ITaxonomyTypeElement {
  const quantityUnitOption = getQuantityUnitOption(serverModel.minItems, serverModel.maxItems);

  return {
    ...getBaseTypeElementFromServerModel(serverModel),
    _configurability: EmptyTaxonomyTypeElement._configurability,
    _quantityUnitOption: quantityUnitOption,
    defaultValue: serverModel.default?.global.value ?? [],
    taxonomyGroupId: serverModel.taxonomyGroupId ?? EmptyTaxonomyTypeElement.taxonomyGroupId,
    type: ElementType.Taxonomy,
    maxItems: serverModel.maxItems ? serverModel.maxItems : EmptyTaxonomyTypeElement.maxItems,
    minItems: serverModel.minItems ? serverModel.minItems : EmptyTaxonomyTypeElement.minItems,
  };
}
