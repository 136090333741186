import React, { memo } from 'react';
import {
  createEditorWithPlugins,
  getBaseEditor,
  usePluginWithParams,
} from '../../editorCore/Editor.composition.tsx';
import { EditorProps } from '../../editorCore/types/Editor.composition.type.ts';
import { useApiLimitations } from '../../plugins/apiLimitations/ApiLimitationsPlugin.tsx';
import { SimpleTextEditorLimitations } from '../../plugins/apiLimitations/api/EditorFeatureLimitations.ts';
import { useCompositionModeAdjustments } from '../../plugins/behavior/CompositionModeAdjustmentsPlugin.tsx';
import { useExternalUpdate } from '../../plugins/behavior/ExternalUpdatePlugin.tsx';
import { useFocus } from '../../plugins/behavior/FocusPlugin.tsx';
import { useForceSelectionOnBlur } from '../../plugins/behavior/ForceSelectionOnBlurPlugin.tsx';
import { useForceSingleUnstyledLine } from '../../plugins/behavior/ForceSingleUnstyledLinePlugin.tsx';
import { useOnChange } from '../../plugins/behavior/OnChangePlugin.tsx';
import { useClipboard } from '../../plugins/clipboard/ClipboardPlugin.tsx';
import { useComments } from '../../plugins/comments/CommentsPlugin.tsx';
import {
  EnterKeyBehavior,
  useCustomInputHandling,
} from '../../plugins/customInputHandling/CustomInputHandlingPlugin.tsx';
import { useKeyboardShortcuts } from '../../plugins/keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { textKeyCommandMap } from '../../plugins/keyboardShortcuts/api/editorKeyboardUtils.ts';
import { useInlineToolbar } from '../../plugins/toolbars/InlineToolbarPlugin.tsx';
import { useUndoRedo } from '../../plugins/undoRedo/UndoRedoPlugin.tsx';
import { usePlaceholder } from '../../plugins/visuals/PlaceholderPlugin.tsx';
import { useStyles } from '../../plugins/visuals/StylesPlugin.tsx';
import { useWrapper } from '../../plugins/visuals/WrapperPlugin.tsx';
import { useAutoGeneratedUrlSlug } from './plugins/AutoGeneratedUrlSlugPlugin.tsx';
import { useDisableCommentsForAutoGeneratedUrlSlug } from './plugins/DisableCommentsForAutoGeneratedUrlSlugPlugin.tsx';
import { useUrlSlugInputStatus } from './plugins/UrlSlugInputStatusPlugin.tsx';

const useComposedEditor = () =>
  createEditorWithPlugins(
    getBaseEditor(),
    usePlaceholder,
    useWrapper,
    useCompositionModeAdjustments,
    useAutoGeneratedUrlSlug,
    useFocus,
    usePluginWithParams(useApiLimitations, SimpleTextEditorLimitations),
    usePluginWithParams(useKeyboardShortcuts, textKeyCommandMap),
    useUndoRedo,
    useStyles,
    useInlineToolbar,
    useForceSelectionOnBlur,
    useForceSingleUnstyledLine,
    useOnChange,
    usePluginWithParams(useCustomInputHandling, EnterKeyBehavior.AlwaysSoftNewLine),
    useComments,
    useClipboard,
    useDisableCommentsForAutoGeneratedUrlSlug,
    useUrlSlugInputStatus,
    useExternalUpdate,
  );

export type UrlSlugInputProps = EditorProps<typeof useComposedEditor>;

export const UrlSlugInput: React.FC<UrlSlugInputProps> = memo((props) => {
  const { ComposedEditor } = useComposedEditor();

  return <ComposedEditor {...props} />;
});

UrlSlugInput.displayName = 'UrlSlugInput';
