import { assert } from '@kontent-ai/utils';
import { Dispatch, GetState, ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { IPreviewConfigurationRepository } from '../../../../repositories/interfaces/IPreviewConfigurationRepository.type.ts';
import {
  PreviewConfiguration_Saving_Finished,
  PreviewConfiguration_Saving_Started,
} from '../../constants/previewConfigurationActionTypes.ts';
import { IPreviewConfiguration } from '../../models/PreviewConfiguration.type.ts';
import { createPreviewConfigurationServerModel } from '../../utils/createPreviewConfigurationServerModel.ts';
import { getPreviewConfigurationFromServerModel } from '../../utils/getPreviewConfigurationFromServerModel.ts';
import { showServerErrorMessage } from '../previewConfigurationActions.ts';

interface IDeps {
  readonly previewConfigurationRepository: Pick<IPreviewConfigurationRepository, 'save'>;
}

const started = () => ({ type: PreviewConfiguration_Saving_Started }) as const;

const succeeded = (previewConfiguration: IPreviewConfiguration) =>
  ({
    type: PreviewConfiguration_Saving_Finished,
    payload: {
      previewConfiguration,
    },
  }) as const;

export type UpdatePreviewConfigurationActionsType = ReturnType<typeof started | typeof succeeded>;

export const updatePreviewConfigurationActionCreator =
  (deps: IDeps) =>
  (onSuccess?: () => void, onFail?: () => void): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {
      previewConfigurationApp: { editedPreviewConfiguration, previewConfiguration },
      sharedApp: { currentProjectId },
      data: {
        contentTypes: { byId: typesById },
      },
    } = getState();

    assert(
      editedPreviewConfiguration,
      () => `${__filename}: Edited preview configuration was not found.`,
    );

    dispatch(started());

    const configurationToSave = createPreviewConfigurationServerModel(
      editedPreviewConfiguration,
      typesById,
    );

    try {
      const previewConfigurationServerModel = await deps.previewConfigurationRepository.save(
        currentProjectId,
        configurationToSave,
      );
      const previewConfigurationFromServer = getPreviewConfigurationFromServerModel(
        previewConfigurationServerModel,
        previewConfiguration,
        true,
      );

      dispatch(succeeded(previewConfigurationFromServer));
      onSuccess?.();
    } catch {
      dispatch(showServerErrorMessage());
      onFail?.();
    }
  };
