import { Action } from '../../../../../../@types/Action.type.ts';
import { itemEditorOperationIds } from '../../../../../contentInventory/content/utils/itemEditorOperationIdUtils.ts';
import {
  ContentItemEditing_CodenameSaving_NotUniqueError,
  ContentItemEditing_Init_Ready,
  ContentItemEditing_ItemSaving_Finished,
  ContentItemEditing_Saving_Failed,
  ContentItemEditing_Saving_Started,
} from '../../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { ContentItemCodenameSavingStatus } from '../../../../models/contentItem/ContentItemCodenameSavingStatus.ts';

const initialState = ContentItemCodenameSavingStatus.Saved;

export const codenameSavingStatus = (
  state: ContentItemCodenameSavingStatus = initialState,
  action: Action,
): ContentItemCodenameSavingStatus => {
  switch (action.type) {
    case ContentItemEditing_Init_Ready:
      return initialState;

    case ContentItemEditing_ItemSaving_Finished: {
      if (action.payload.operationId === itemEditorOperationIds.codename) {
        return ContentItemCodenameSavingStatus.Saved;
      }
      return state;
    }

    case ContentItemEditing_Saving_Started: {
      if (action.payload.operationId === itemEditorOperationIds.codename) {
        return ContentItemCodenameSavingStatus.IsBeingSaved;
      }
      return state;
    }

    case ContentItemEditing_CodenameSaving_NotUniqueError:
      return ContentItemCodenameSavingStatus.CodenameNotUniqueError;

    case ContentItemEditing_Saving_Failed: {
      if (action.payload.operationId === itemEditorOperationIds.codename) {
        return ContentItemCodenameSavingStatus.Saved;
      }
      return state;
    }

    default:
      return state;
  }
};
