import PropTypes from 'prop-types';
import React from 'react';
import { AssetReference } from '../../../../../models/contentItemElements/AssetItemElement.ts';
import { AssetViewerTile } from '../../../../LinkedItems/containers/AssetViewerTile.tsx';
import { IMergedListItem } from '../../../utils/getListDiff.ts';

export interface ISimpleAssetListDataProps {
  assetReferences: ReadonlyArray<IMergedListItem<AssetReference>>;
  getAssetTileClassName?: (assetWitDiffMetadata: IMergedListItem<AssetReference>) => string;
}

const propTypes: PropTypeMap<ISimpleAssetListDataProps> = {
  assetReferences: PropTypes.array.isRequired,
  getAssetTileClassName: PropTypes.func,
};

export const SimpleAssetList: React.FC<ISimpleAssetListDataProps> = ({
  assetReferences,
  getAssetTileClassName,
}) => (
  <div className="row">
    {assetReferences.map(
      (asset, index) =>
        asset?.value && (
          <div
            key={`${JSON.stringify(asset.value)}_${index}`}
            className="col-sm-12 col-md-8 col-lg-8 asset-tile-wrapper"
          >
            <AssetViewerTile
              className={getAssetTileClassName?.(asset)}
              assetId={asset.value.id}
              renditionId={asset.value.renditions[0]?.id}
            />
          </div>
        ),
    )}
  </div>
);

SimpleAssetList.displayName = 'SimpleAssetList';
SimpleAssetList.propTypes = propTypes;
