import React from 'react';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { handleUnsuccessfulRedirect } from '../actions/smartLinkActions.ts';
import { SmartLink } from '../components/SmartLink.tsx';
import { LinkIsInvalidMessage } from '../constants/uiConstants.ts';

export const OtherRouteSmartLink: React.FC = () => {
  const dispatch = useDispatch();
  const onMount = () => dispatch(handleUnsuccessfulRedirect(null, LinkIsInvalidMessage, null));

  return <SmartLink onMount={onMount} />;
};

OtherRouteSmartLink.displayName = 'OtherRouteSmartLink';
