import { Color, Typography } from '@kontent-ai/component-library/tokens';
import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styled, { css } from 'styled-components';
import { DataUiInput, getDataUiInputAttribute } from '../utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly autoFocus?: boolean;
  readonly color?: Color;
  readonly dataUiInput: DataUiInput;
  readonly hasNotAllowedCursorWhenDisabled?: boolean;
  readonly id?: Uuid;
  readonly isDisabled: boolean;
  readonly maxLength?: number;
  readonly onBlur?: () => void;
  readonly onChange?: (name: string) => void;
  readonly onFocus?: () => void;
  readonly placeholder: string;
  readonly typography?: Typography;
  readonly value?: string;
};

const AutoSizedTextArea = styled(TextareaAutosize)<{
  readonly $hasNotAllowedCursorWhenDisabled?: boolean;
  readonly $typography?: Typography;
  readonly $color?: Color;
}>`
  display: block;
  
  width: 100%;
  padding: 0;
  border: none;
  resize: none;
  
  ${(props) => props.$typography}
  
  ${(props) =>
    props.$color &&
    css`
    color: ${props.$color};
  `}

  &[disabled] {
    background-color: transparent;
    cursor: ${(props) => (props.$hasNotAllowedCursorWhenDisabled ? 'not-allowed' : 'default')};
  }
`;

export const MultilineInput: React.FC<Props> = ({
  color,
  dataUiInput,
  hasNotAllowedCursorWhenDisabled,
  isDisabled,
  onChange,
  typography,
  ...restProps
}) => (
  <AutoSizedTextArea
    $color={color}
    $hasNotAllowedCursorWhenDisabled={hasNotAllowedCursorWhenDisabled}
    $typography={typography}
    autoComplete="off"
    disabled={isDisabled}
    onChange={(event) => onChange?.(event.target.value)}
    {...restProps}
    {...getDataUiInputAttribute(dataUiInput)}
  />
);

MultilineInput.displayName = 'MultilineInput';
