import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { SidebarSection } from '../../../../_shared/components/Sidebar/SidebarSection.tsx';
import { SidebarSubmitButtonWithErrorMessage } from '../../../../_shared/components/Sidebar/SidebarSubmitButtonWithErrorMessage.tsx';
import { Sidebar } from '../../../../_shared/containers/Sidebar/SidebarContainer.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { SubscriptionFormContext } from '../../shared/components/SubscriptionFormContext.tsx';
import { NewSubscriptionForm } from '../containers/NewSubscriptionForm.tsx';
import { INewSubscriptionFormShape } from './NewSubscriptionForm.tsx';

type CreateNewSubscriptionModalProps = {
  readonly closeDialog: () => void;
  readonly creatingInProgress: boolean;
  readonly errorMessage: string;
  readonly onFormSubmit: (values: INewSubscriptionFormShape) => void;
  readonly showSidebar: boolean;
};

export const CreateNewSubscriptionModal: React.FC<CreateNewSubscriptionModalProps> = ({
  closeDialog,
  creatingInProgress,
  errorMessage,
  onFormSubmit,
  showSidebar,
}) => {
  const { submitForm } = useContext(SubscriptionFormContext);
  const onSubmit = !submitForm || creatingInProgress ? undefined : submitForm;

  const submitButton = (
    <SidebarSubmitButtonWithErrorMessage
      onSubmit={onSubmit}
      buttonLabel={creatingInProgress ? 'Creating subscription' : 'Create subscription'}
      dataUiAttribute={getDataUiActionAttribute(DataUiAction.CreateNew)}
      disabled={creatingInProgress}
      errorMessage={errorMessage}
    />
  );

  return (
    <Sidebar
      isVisible={showSidebar}
      onClose={closeDialog}
      onSubmit={onSubmit}
      titleElement="Create a new subscription"
      submitElement={submitButton}
    >
      <SidebarSection>
        <NewSubscriptionForm onFormSubmit={onFormSubmit}>
          <p>What will happen?</p>
          <ul>
            <li>The subscription will use the Developer plan, starting a new billing period.</li>
            <li>The new plan becomes effective immediately.</li>
            <li>The subscription will not contain any projects.</li>
            <li>You will become the admin of the new subscription.</li>
          </ul>
        </NewSubscriptionForm>
      </SidebarSection>
    </Sidebar>
  );
};

CreateNewSubscriptionModal.displayName = 'CreateNewSubscriptionModal';
CreateNewSubscriptionModal.propTypes = {
  showSidebar: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  creatingInProgress: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
};
