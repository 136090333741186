import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { BarItemAction } from '../../../../_shared/components/BarItems/Actions/BarItemAction.tsx';
import { BarItemEditAction } from '../../../../_shared/components/BarItems/Actions/BarItemEditAction.tsx';
import { BarItemActions } from '../../../../_shared/components/BarItems/BarItemActions.tsx';
import { BarItemTitle } from '../../../../_shared/components/BarItems/BarItemTitle.tsx';
import { BarItemWithLoader } from '../../../../_shared/components/BarItems/BarItemWithLoader.tsx';
import { IconName } from '../../../../_shared/constants/iconEnumGenerated.ts';
import { DataUiAction } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IWebhookSetting } from '../../../../data/models/webhooks/WebhookSetting.ts';
import { WebhookHealthStatus } from '../../constants/webhookHealthStatuses.ts';
import { WebhookBarItemResetAction } from './WebhookBarItemResetAction.tsx';
import { WebhookStatus } from './WebhookStatus.tsx';

export interface IWebhookBarDataProps {
  readonly isTriggerConfigurationValid: boolean;
  readonly isEditable: boolean;
  readonly hasLoader?: boolean;
  readonly pathToMessages: string;
  readonly webhookSetting: IWebhookSetting;
}

export interface IWebhookBarCallbackProps {
  readonly onReset: () => void;
  readonly onShowEditor: () => void;
}

export const WebhookBar: React.FC<IWebhookBarDataProps & IWebhookBarCallbackProps> = ({
  webhookSetting,
  onReset,
  onShowEditor,
  isEditable,
  pathToMessages,
  isTriggerConfigurationValid,
  hasLoader,
}) => {
  const showResetAction =
    webhookSetting.enabled &&
    (webhookSetting.healthStatus === WebhookHealthStatus.Dead ||
      webhookSetting.healthStatus === WebhookHealthStatus.Failing);

  return (
    <div className="bar-item__wrapper">
      <div className="bar-item__pane" data-hj-suppress="">
        <BarItemWithLoader
          hasLoader={hasLoader}
          additionalBarItemClass={isEditable ? 'bar-item__bar--is-clickable' : undefined}
        >
          <div className="bar-item__bar-pane">
            <BarItemActions>
              <div className="webhook-status">
                <WebhookStatus
                  isTriggerConfigurationValid={isTriggerConfigurationValid}
                  healthStatus={webhookSetting.healthStatus}
                  isEnabled={webhookSetting.enabled}
                />
              </div>
              {showResetAction && <WebhookBarItemResetAction onClick={onReset} />}
            </BarItemActions>
            <BarItemTitle
              dataUiObjectName={webhookSetting.name}
              disabled={!webhookSetting.enabled}
              onClick={isEditable ? onShowEditor : undefined}
            >
              {webhookSetting.name}
            </BarItemTitle>
          </div>
          <div className="bar-item__bar-pane bar-item__bar-pane--xl">
            <BarItemTitle
              disabled={!webhookSetting.enabled}
              onClick={isEditable ? onShowEditor : undefined}
              tooltip={webhookSetting.url}
            >
              {webhookSetting.url}
            </BarItemTitle>
            <BarItemActions>
              <Link to={pathToMessages} className="u-no-text-decoration">
                <Tooltip placement="top-start" tooltipText="Debugging">
                  <BarItemAction
                    iconName={IconName.Bug}
                    dataUiActionName={DataUiAction.OpenWebhook}
                    screenReaderText="Debugging"
                  />
                </Tooltip>
              </Link>
              <BarItemEditAction onClick={onShowEditor} disabled={!isEditable} />
            </BarItemActions>
          </div>
        </BarItemWithLoader>
      </div>
    </div>
  );
};

WebhookBar.displayName = 'WebhookBar';

const propTypes: PropTypesShape<IWebhookBarDataProps & IWebhookBarCallbackProps> = {
  isTriggerConfigurationValid: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
  hasLoader: PropTypes.bool,
  onShowEditor: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  pathToMessages: PropTypes.string,
  webhookSetting: PropTypes.object.isRequired,
};
WebhookBar.propTypes = propTypes;
