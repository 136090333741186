import { memoize } from '@kontent-ai/memoization';
import { DataDraftJsAttributes } from '../../../_shared/utils/dataAttributes/DataDraftJsAttributes.ts';
import { shallowEqual } from '../../../_shared/utils/shallowEqual.ts';
import { getCommentClassName } from '../../richText/plugins/comments/api/editorCommentStyleUtils.ts';
import { IComponentPathItem } from '../../richText/plugins/contentComponents/api/editorContentComponentUtils.ts';
import { ElementAttributes } from '../constants/elementAttributes.ts';
import { CommentThreadItemType, ICommentThreadItem } from '../models/comments/CommentThreadItem.ts';
import {
  CommentThreadType,
  ICommentThread,
  IInlineCommentThread,
} from '../models/comments/CommentThreads.ts';

export interface ICommentThreadWithLocation {
  readonly commentThread: IInlineCommentThread;
  readonly componentPath: ReadonlyArray<IComponentPathItem> | null;
}

export const EmptyThreadReferences: ReadonlyArray<ICommentThreadWithLocation> = [];

export function updateCommentThread(
  commentThreads: ReadonlyArray<ICommentThread>,
  threadId: Uuid,
  updater: (commentThread: ICommentThread) => ICommentThread,
): ReadonlyArray<ICommentThread> {
  const newThreads = commentThreads.map((thread) =>
    thread.id === threadId ? updater(thread) : thread,
  );

  return shallowEqual(commentThreads, newThreads) ? commentThreads : newThreads;
}

export function updateCommentThreadItem(
  commentThreads: ReadonlyArray<ICommentThread>,
  threadId: Uuid,
  itemId: Uuid,
  updater: (commentThread: ICommentThreadItem) => ICommentThreadItem,
): ReadonlyArray<ICommentThread> {
  const newCommentThreads = updateCommentThread(commentThreads, threadId, (t) => {
    const itemIndex = t.threadItems.findIndex((c: ICommentThreadItem) => c.id === itemId);
    if (itemIndex < 0) {
      return t;
    }

    const newThreadItems = t.threadItems.update(itemIndex, updater);

    return {
      ...t,
      threadItems: newThreadItems,
    };
  });

  return newCommentThreads;
}

export function setEditedThreadItem(
  commentThreads: ReadonlyArray<ICommentThread>,
  threadId: Uuid,
  itemId: Uuid | null,
): ReadonlyArray<ICommentThread> {
  const newCommentThreads = updateCommentThread(commentThreads, threadId, (t) => ({
    ...t,
    threadItems: t.threadItems
      .map((commentThread: ICommentThreadItem) => ({
        ...commentThread,
        isEditing: commentThread.id === itemId,
      }))
      .toList(),
  }));

  return newCommentThreads;
}

export const getResolvedCommentThreads = memoize.maxOne(
  (threads: ReadonlyArray<ICommentThread>): ReadonlyArray<ICommentThread> =>
    threads.filter(isThreadResolved).sort(threadComparer),
);

export const getDiscussionsThreads = memoize.maxOne(
  (threads: ReadonlyArray<ICommentThread>): ReadonlyArray<ICommentThread> =>
    threads.filter((thread) => !isThreadResolved(thread) && !isThreadInline(thread)),
);

export const filterUnsavedComments = (
  threads: ReadonlyArray<ICommentThread>,
): ReadonlyArray<ICommentThread> =>
  threads.filter(isThreadSaved).map((t) => (t.isReplying ? { ...t, isReplying: false } : t));

export function removeUnsavedThread(
  threads: ReadonlyArray<ICommentThread>,
  threadId: Uuid,
): ReadonlyArray<ICommentThread> {
  const savedThreads = threads.filter((thread) => isThreadSaved(thread) || thread.id !== threadId);

  // Do not create new reference if unchanged
  return savedThreads.length === threads.length ? threads : savedThreads;
}

export function isThreadSaved(thread: ICommentThread): boolean {
  return thread && !thread.isUnsaved;
}

export function isThreadInline(thread: ICommentThread): thread is IInlineCommentThread {
  return !!thread.elementId;
}

export function isThreadResolved(thread: ICommentThread): boolean {
  return !!thread.resolvedAt && !thread.isInUndoResolvedState;
}

export function dateComparer(a: DateTimeStamp | null, b: DateTimeStamp | null): number {
  if (!a && !b) {
    return 0;
  }

  if (a && b) {
    if (a > b) {
      return 1;
    }

    if (a < b) {
      return -1;
    }

    return 0;
  }

  if (a && !b) {
    return -1;
  }

  return 1;
}

export function threadComparer(threadA: ICommentThread, threadB: ICommentThread): number {
  const aResolvedAt = threadA.resolvedAt;
  const bResolvedAt = threadB.resolvedAt;

  if (!aResolvedAt && !bResolvedAt) {
    const threadAMostRecentCommentDate = threadA.threadItems.reduce(reduceToMostRecentDate, '');
    const threadBMostRecentCommentDate = threadB.threadItems.reduce(reduceToMostRecentDate, '');

    if (threadAMostRecentCommentDate > threadBMostRecentCommentDate) {
      return -1;
    }

    return 1;
  }

  return -dateComparer(aResolvedAt, bResolvedAt);
}

function reduceToMostRecentDate(previousValue: string, comment: ICommentThreadItem): DateTimeStamp {
  const commentDate = comment.updatedAt || comment.createdAt;

  if (previousValue < commentDate) {
    return commentDate;
  }

  return previousValue;
}

const getComponentIdSelector = (commentThread: ICommentThread) =>
  commentThread.contentComponentId
    ? `[${ElementAttributes.ContentComponentId}="${commentThread.contentComponentId}"]`
    : `:not([${ElementAttributes.ContentComponentId}])`;

export const getElementSelector = (commentThread: ICommentThread): string =>
  `.content-item-element${getComponentIdSelector(commentThread)}[${ElementAttributes.ElementId}="${
    commentThread.elementId
  }"]`;

export const getCommentedElementSelector = (commentThread: ICommentThread): string =>
  `.content-item-element${getComponentIdSelector(commentThread)}[${ElementAttributes.ElementId}="${
    commentThread.elementId
  }"][${ElementAttributes.ElementSupportsComments}]`;

export const getCommentedLinkedItemSelector = (commentThread: ICommentThread): string =>
  `${getElementSelector(commentThread)} *[${ElementAttributes.LinkedItemId}="${
    commentThread.externalSegmentId
  }"]`;

export const getCommentedTextSelector = (commentThread: ICommentThread): string | null =>
  commentThread.externalSegmentId
    ? `${getElementSelector(commentThread)} span.${getCommentClassName(
        commentThread.externalSegmentId,
      )}[${DataDraftJsAttributes.OffsetKey}]`
    : null;

export const getCommentedSegmentSelector = (commentThread: ICommentThread): string | null =>
  commentThread.externalSegmentId
    ? `${getElementSelector(commentThread)} *[${ElementAttributes.RichTextCommentSegmentId}="${
        commentThread.externalSegmentId
      }"]`
    : null;

export const getCommentedAssetSelector = (focusedThread: ICommentThread): string | null =>
  focusedThread.elementSegment
    ? `${getElementSelector(focusedThread)} .asset-thumbnail[${ElementAttributes.AssetId}="${
        focusedThread.externalSegmentId
      }"]`
    : null;

export const hasCommentThreadSuggestions = (thread: ICommentThread): boolean =>
  thread.threadItems.some(
    (item: ICommentThreadItem) => item.type === CommentThreadItemType.Suggestion,
  );

export function getCommentedFragmentCssSelector(
  focusedThread: ICommentThread | null,
): string | null {
  if (!focusedThread) {
    return null;
  }

  switch (focusedThread.threadType) {
    case CommentThreadType.Asset:
      return getCommentedAssetSelector(focusedThread);

    case CommentThreadType.RichText:
      return getCommentedTextSelector(focusedThread);

    case CommentThreadType.MultipleChoice:
    case CommentThreadType.Number:
    case CommentThreadType.Taxonomy:
    case CommentThreadType.DateTime:
    case CommentThreadType.CustomElement:
      return getCommentedElementSelector(focusedThread);

    case CommentThreadType.InlineImage:
    case CommentThreadType.RichTextLinkedItem:
      return getCommentedSegmentSelector(focusedThread);

    case CommentThreadType.ElementLinkedItem:
      return getCommentedLinkedItemSelector(focusedThread);

    default:
      return null;
  }
}
