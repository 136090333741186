import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import { ContentItemId } from '../../../../../_shared/models/ContentItemId.type.ts';
import { PublishingState } from '../../../../../data/constants/PublishingState.ts';
import { IContentItemVariantServerModel } from '../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import { IAssignment, emptyAssignment, getAssignmentFromServerModel } from '../Assignment.ts';

export type EditedContentItemVariant = {
  readonly id: ContentItemId;
  readonly elements: Immutable.List<Uuid>;
  readonly contentLastUpdatedAt: DateTimeStamp | null;
  readonly contentLastUpdatedBy: string;
  readonly lastPublishedAt: DateTimeStamp | null;
  readonly lastPublishedBy: string;
  readonly publishingState: PublishingState;
  readonly assignment: IAssignment;
  readonly isArchived: boolean;
  readonly isComplete: boolean;
};

export const emptyContentItemVariant: EditedContentItemVariant = {
  id: {
    itemId: '',
    variantId: '',
  },
  elements: Immutable.List<Uuid>(),
  contentLastUpdatedAt: null,
  contentLastUpdatedBy: '',
  lastPublishedAt: null,
  lastPublishedBy: '',
  publishingState: PublishingState.None,
  assignment: emptyAssignment,
  isArchived: false,
  isComplete: false,
};

const createContentItemId = memoize.maxOne(
  (itemId: Uuid, variantId: Uuid): ContentItemId => ({
    itemId,
    variantId,
  }),
);

export const getContentItemVariantFromServerModel = (
  serverModel: IContentItemVariantServerModel,
): EditedContentItemVariant => {
  const {
    id,
    contentElements,
    contentLastUpdated,
    contentLastUpdatedBy,
    lastPublishedAt,
    lastPublishedBy,
    publishingState,
    assignment,
    archived,
    isComplete,
  } = serverModel;

  const elementsIds = Immutable.List(contentElements.map((el) => el.elementId));
  const assignmentRecord = getAssignmentFromServerModel(assignment);

  return {
    id: createContentItemId(id.itemId, id.variantId),
    elements: elementsIds,
    contentLastUpdatedAt: contentLastUpdated,
    contentLastUpdatedBy,
    lastPublishedAt,
    lastPublishedBy,
    publishingState,
    assignment: assignmentRecord,
    isArchived: archived,
    isComplete,
  };
};

export const createEditedContentItemVariant = (
  variant?: Partial<EditedContentItemVariant>,
): EditedContentItemVariant =>
  variant
    ? {
        id: variant.id ?? emptyContentItemVariant.id,
        elements: variant.elements ?? emptyContentItemVariant.elements,
        contentLastUpdatedAt:
          variant.contentLastUpdatedAt ?? emptyContentItemVariant.contentLastUpdatedAt,
        contentLastUpdatedBy:
          variant.contentLastUpdatedBy ?? emptyContentItemVariant.contentLastUpdatedBy,
        lastPublishedAt: variant.lastPublishedAt ?? emptyContentItemVariant.lastPublishedAt,
        lastPublishedBy: variant.lastPublishedBy ?? emptyContentItemVariant.lastPublishedBy,
        publishingState: variant.publishingState ?? emptyContentItemVariant.publishingState,
        assignment: variant.assignment ?? emptyContentItemVariant.assignment,
        isArchived: variant.isArchived ?? emptyContentItemVariant.isArchived,
        isComplete: variant.isComplete ?? emptyContentItemVariant.isComplete,
      }
    : { ...emptyContentItemVariant };
