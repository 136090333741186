import { Button } from '@kontent-ai/component-library/Button';
import { IconName, Icons } from '@kontent-ai/component-library/Icons';
import React from 'react';
import { ObjectWithDataAttribute } from '../../../../utils/dataAttributes/DataUiAttributes.ts';

export type WorkflowSubmitButtonProps = {
  readonly dataUiAttribute: ObjectWithDataAttribute;
  readonly destructive?: boolean;
  readonly disabled: boolean;
  readonly iconName?: IconName;
  readonly onSubmit: (() => void) | undefined;
  readonly text: string;
  readonly tooltipText?: string;
  readonly isLoading?: boolean;
};

export const WorkflowSubmitButton: React.FC<WorkflowSubmitButtonProps> = (props) => (
  <Button
    buttonStyle="primary"
    destructive={props.destructive}
    destructiveIcon={props.iconName ? Icons[props.iconName] : Icons.Box}
    disabled={props.disabled || props.isLoading}
    onClick={props.disabled ? undefined : props.onSubmit}
    tooltipPlacement="top"
    tooltipText={props.tooltipText}
    type="button"
    {...props.dataUiAttribute}
  >
    {props.isLoading && <Button.ProgressIcon />}
    <Button.Label>{props.text}</Button.Label>
  </Button>
);

WorkflowSubmitButton.displayName = 'WorkflowSubmitButton';
