import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { HtmlPageTitle } from '../../../../_shared/components/HtmlPageTitle.tsx';
import { AuthorizedSection as AuthorizedSectionComponent } from '../../../../_shared/components/routing/AuthorizedSection.tsx';
import { useRedirectPropsWithSameSearch } from '../../../../_shared/components/routing/useRedirectPropsWithSameSearch.tsx';
import { AppNames } from '../../../../_shared/constants/applicationNames.ts';
import {
  AuditLogRoute,
  CollectionsRoute,
  EnvironmentRouteParams,
  EnvironmentsRoute,
  GeneralEnvironmentSettingsRoute,
  LegacyWebhookMessagesRoute,
  LegacyWebhookMessagesRouteParams,
  LegacyWebhooksRoute,
  LocalizationRoute,
  PreviewURLsRoute,
  RoleCreatorRoute,
  RoleEditorRoute,
  RoleEditorRouteParams,
  RolesRoute,
  SpacesRoute,
  UsersRoute,
  WebhookEditorRouteParams,
  WebhookMessagesRoute,
  WebhookMessagesRouteParams,
  WebhooksCreatingRoute,
  WebhooksEditingRoute,
  WebhooksRoute,
  WorkflowCreatingRoute,
  WorkflowEditingRoute,
  WorkflowEditingRouteParams,
  WorkflowsRoute,
} from '../../../../_shared/constants/routePaths.ts';
import { AuthorizedSection } from '../../../../_shared/containers/routing/AuthorizedSection.tsx';
import {
  IRouteContext,
  RouteContext,
} from '../../../../_shared/containers/routing/RouteContext.tsx';
import { Capability } from '../../../../_shared/utils/permissions/capability.ts';
import { createEnvironmentsLink } from '../../../../_shared/utils/routing/projectRoutingUtils.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { EnsureEntityWebhookEditorData } from '../../../entityWebhooks/containers/EnsureEntityWebhookEditorData.tsx';
import { EntityWebhookCreatorForm } from '../../../entityWebhooks/containers/EntityWebhookCreatorForm.tsx';
import { EntityWebhookEditor } from '../../../entityWebhooks/containers/EntityWebhookEditor.tsx';
import { EntityWebhookListing } from '../../../entityWebhooks/containers/EntityWebhookListing.tsx';
import { PreviewConfigurationApp } from '../../../previewConfiguration/containers/PreviewConfigurationApp.tsx';
import { WebhookListingPage } from '../../../webhooks/containers/WebhookListing.tsx';
import { WebhookMessageListing } from '../../../webhooks/containers/WebhookMessageListing.tsx';
import { AuditLog } from '../../auditLog/containers/AuditLog.tsx';
import { CollectionsApp } from '../../collections/containers/CollectionsApp.tsx';
import { GeneralSettings } from '../../general/containers/GeneralSettings.tsx';
import { InnovationLabApp } from '../../innovationLab/components/InnovationLabApp.tsx';
import {
  InnovationLabSettingsRoute,
  ObsoleteEarlyAccessProgramSettingsRoute,
} from '../../innovationLab/constants/routePaths.ts';
import { LanguagesListing } from '../../localization/containers/LanguagesListing.tsx';
import { RoleCreator } from '../../roles/containers/RoleCreator.tsx';
import { RoleEditor } from '../../roles/containers/RoleEditor.tsx';
import { RoleListing } from '../../roles/containers/RoleListing.tsx';
import { SpacesApp } from '../../spaces/containers/SpacesApp.tsx';
import { UserListing } from '../../users/containers/userListing/UserListing.tsx';
import { WorkflowCreatorPage } from '../../workflow/components/WorkflowCreatorPage.tsx';
import { WorkflowsListingPage } from '../../workflow/components/WorkflowsListingPage.tsx';
import { UpsellMultipleWorkflows } from '../../workflow/containers/UpsellMultipleWorkflows.tsx';
import { WorkflowEditorPage } from '../../workflow/containers/WorkflowEditorPage.tsx';
import { EnvironmentSettingsAppNames } from '../constants/EnvironmentSettingsAppNames.ts';
import { EnvironmentSettingsApp } from './EnvironmentSettingsApp.tsx';

type EnvironmentSettingsProps = {
  readonly areCollectionsEnabled: boolean;
  readonly areLegacyWebhooksEnabled: boolean;
  readonly areGeneralSettingsVisible: boolean;
  readonly areSpacesEnabled: boolean;
  readonly firstAllowedChildRoute: string;
  readonly isAuditLogEnabled: boolean;
  readonly isInnovationLabAccessible: boolean;
  readonly onInit: () => void;
  readonly projectContainerId: Uuid;
  readonly projectId: Uuid;
  readonly subscriptionId: Uuid;
};

export const EnvironmentSettings: React.FC<EnvironmentSettingsProps> = ({
  areCollectionsEnabled,
  areLegacyWebhooksEnabled,
  areGeneralSettingsVisible,
  areSpacesEnabled,
  firstAllowedChildRoute,
  isAuditLogEnabled,
  isInnovationLabAccessible,
  onInit,
  projectContainerId,
  projectId,
  subscriptionId,
}) => {
  useEffect(() => {
    onInit();
  }, [onInit]);
  const getRedirectPropsWithSameSearch = useRedirectPropsWithSameSearch();

  return (
    <>
      <HtmlPageTitle appName={AppNames.EnvironmentSettings} />
      <Switch>
        <Route path={InnovationLabSettingsRoute}>
          <AuthorizedSectionComponent
            appName={EnvironmentSettingsAppNames.InnovationLab}
            isAuthorized={isInnovationLabAccessible}
            projectId={projectId}
          >
            <EnvironmentSettingsApp>
              <InnovationLabApp />
            </EnvironmentSettingsApp>
          </AuthorizedSectionComponent>
        </Route>
        <Route path={ObsoleteEarlyAccessProgramSettingsRoute}>
          <Redirect
            to={buildPath<EnvironmentRouteParams>(InnovationLabSettingsRoute, {
              projectId,
            })}
          />
        </Route>
        <Route path={GeneralEnvironmentSettingsRoute}>
          <AuthorizedSectionComponent
            appName={EnvironmentSettingsAppNames.General}
            isAuthorized={areGeneralSettingsVisible}
            projectId={projectId}
          >
            <EnvironmentSettingsApp>
              <GeneralSettings />
            </EnvironmentSettingsApp>
          </AuthorizedSectionComponent>
        </Route>
        <Route path={EnvironmentsRoute}>
          <Redirect to={createEnvironmentsLink({ projectContainerId })} />
        </Route>
        <Route path={UsersRoute}>
          <AuthorizedSection
            requiresOneOfCapabilities={[Capability.ManageUsers]}
            appName={EnvironmentSettingsAppNames.Users}
          >
            <EnvironmentSettingsApp>
              <UserListing projectId={projectId} />
            </EnvironmentSettingsApp>
          </AuthorizedSection>
        </Route>
        <Route path={RoleCreatorRoute}>
          <RouteContext>
            {({ history }) => (
              <AuthorizedSection
                requiresOneOfCapabilities={[Capability.ManageRoles]}
                appName={EnvironmentSettingsAppNames.Roles}
              >
                <RoleCreator projectId={projectId} history={history} />
              </AuthorizedSection>
            )}
          </RouteContext>
        </Route>
        <Route path={RoleEditorRoute}>
          <RouteContext>
            {({ match }: IRouteContext<RoleEditorRouteParams>) => (
              <AuthorizedSection
                requiresOneOfCapabilities={[Capability.ManageRoles]}
                appName={EnvironmentSettingsAppNames.Roles}
              >
                <RoleEditor projectId={projectId} roleId={match.params.roleId} />
              </AuthorizedSection>
            )}
          </RouteContext>
        </Route>
        <Route path={RolesRoute}>
          <AuthorizedSection
            requiresOneOfCapabilities={[Capability.ManageRoles]}
            appName={EnvironmentSettingsAppNames.Roles}
          >
            <EnvironmentSettingsApp>
              <RoleListing projectId={projectId} />
            </EnvironmentSettingsApp>
          </AuthorizedSection>
        </Route>
        <Route path={WorkflowCreatingRoute}>
          <AuthorizedSection
            requiresOneOfCapabilities={[Capability.ConfigureWorkflow]}
            appName={EnvironmentSettingsAppNames.MultipleWorkflows}
          >
            <WorkflowCreatorPage />
          </AuthorizedSection>
        </Route>
        <Route path={WorkflowEditingRoute}>
          <RouteContext>
            {({ match }: IRouteContext<WorkflowEditingRouteParams>) => (
              <AuthorizedSection
                requiresOneOfCapabilities={[Capability.ConfigureWorkflow]}
                appName={EnvironmentSettingsAppNames.Workflow}
              >
                <WorkflowEditorPage workflowId={match.params.workflowId} />
                <UpsellMultipleWorkflows />
              </AuthorizedSection>
            )}
          </RouteContext>
        </Route>
        <Route path={WorkflowsRoute}>
          <AuthorizedSection
            requiresOneOfCapabilities={[Capability.ConfigureWorkflow]}
            appName={EnvironmentSettingsAppNames.MultipleWorkflows}
          >
            <WorkflowsListingPage />
          </AuthorizedSection>
        </Route>
        {areSpacesEnabled && (
          <Route path={SpacesRoute}>
            <AuthorizedSection
              requiresOneOfCapabilities={[Capability.ManageSpaces]}
              appName={EnvironmentSettingsAppNames.Spaces}
            >
              <EnvironmentSettingsApp>
                <SpacesApp />
              </EnvironmentSettingsApp>
            </AuthorizedSection>
          </Route>
        )}
        {areCollectionsEnabled && (
          <Route path={CollectionsRoute}>
            <AuthorizedSection
              requiresOneOfCapabilities={[Capability.ManageCollections]}
              appName={EnvironmentSettingsAppNames.Collections}
            >
              <EnvironmentSettingsApp>
                <CollectionsApp />
              </EnvironmentSettingsApp>
            </AuthorizedSection>
          </Route>
        )}
        <Route path={LocalizationRoute}>
          <AuthorizedSection
            requiresOneOfCapabilities={[Capability.ConfigureLanguages]}
            appName={EnvironmentSettingsAppNames.Localization}
          >
            <EnvironmentSettingsApp>
              <LanguagesListing />
            </EnvironmentSettingsApp>
          </AuthorizedSection>
        </Route>
        <Route path={PreviewURLsRoute}>
          <AuthorizedSection
            requiresOneOfCapabilities={[Capability.ConfigurePreviewUrl]}
            appName={EnvironmentSettingsAppNames.PreviewConfiguration}
          >
            <EnvironmentSettingsApp>
              <PreviewConfigurationApp />
            </EnvironmentSettingsApp>
          </AuthorizedSection>
        </Route>
        <Route path={WebhooksCreatingRoute}>
          <AuthorizedSection
            requiresOneOfCapabilities={[Capability.ManageWebhooks]}
            appName={EnvironmentSettingsAppNames.Webhooks}
          >
            <EnsureEntityWebhookEditorData>
              <EntityWebhookCreatorForm />
            </EnsureEntityWebhookEditorData>
          </AuthorizedSection>
        </Route>
        <Route path={WebhooksEditingRoute}>
          <RouteContext>
            {({ match }: IRouteContext<WebhookEditorRouteParams>) => (
              <AuthorizedSection
                requiresOneOfCapabilities={[Capability.ManageWebhooks]}
                appName={EnvironmentSettingsAppNames.Webhooks}
              >
                <EnsureEntityWebhookEditorData editedEntityWebhookId={match.params.webhookId}>
                  <EntityWebhookEditor editedEntityWebhookId={match.params.webhookId} />
                </EnsureEntityWebhookEditorData>
              </AuthorizedSection>
            )}
          </RouteContext>
        </Route>
        <Route path={WebhookMessagesRoute}>
          <RouteContext>
            {({ match }: IRouteContext<WebhookMessagesRouteParams>) => (
              <AuthorizedSection
                requiresOneOfCapabilities={[Capability.ManageWebhooks]}
                appName={EnvironmentSettingsAppNames.Webhooks}
              >
                <WebhookMessageListing webhookId={match.params.webhookId} />
              </AuthorizedSection>
            )}
          </RouteContext>
        </Route>
        <Route path={WebhooksRoute}>
          <AuthorizedSection
            appName={EnvironmentSettingsAppNames.Webhooks}
            requiresOneOfCapabilities={[Capability.ManageWebhooks]}
          >
            <EnvironmentSettingsApp>
              <EntityWebhookListing projectId={projectId} subscriptionId={subscriptionId} />
            </EnvironmentSettingsApp>
          </AuthorizedSection>
        </Route>
        {areLegacyWebhooksEnabled && [
          <Route key={LegacyWebhookMessagesRoute} path={LegacyWebhookMessagesRoute}>
            <RouteContext>
              {({ match }: IRouteContext<LegacyWebhookMessagesRouteParams>) => (
                <AuthorizedSection
                  requiresOneOfCapabilities={[Capability.ManageWebhooks]}
                  appName={EnvironmentSettingsAppNames.LegacyWebhooks}
                >
                  <WebhookMessageListing webhookId={match.params.webhookId} />
                </AuthorizedSection>
              )}
            </RouteContext>
          </Route>,
          <Route key={LegacyWebhooksRoute} path={LegacyWebhooksRoute}>
            <AuthorizedSection
              requiresOneOfCapabilities={[Capability.ManageWebhooks]}
              appName={EnvironmentSettingsAppNames.LegacyWebhooks}
            >
              <EnvironmentSettingsApp>
                <WebhookListingPage />
              </EnvironmentSettingsApp>
            </AuthorizedSection>
          </Route>,
        ]}
        {isAuditLogEnabled && (
          <Route path={AuditLogRoute}>
            <AuthorizedSection
              requiresOneOfCapabilities={[Capability.AccessAuditLog]}
              appName={EnvironmentSettingsAppNames.AuditLog}
            >
              <EnvironmentSettingsApp>
                <AuditLog />
              </EnvironmentSettingsApp>
            </AuthorizedSection>
          </Route>
        )}
        <Route>
          <Redirect
            {...getRedirectPropsWithSameSearch({
              to: buildPath<EnvironmentRouteParams>(firstAllowedChildRoute, { projectId }),
            })}
          />
        </Route>
      </Switch>
    </>
  );
};

EnvironmentSettings.displayName = 'EnvironmentSettings';
