import { Node } from '@react-types/shared';
import { ReactElement } from 'react';
import { ListChildComponentProps } from 'react-window';
import { VerticalMenuFrameProps } from '../VerticalMenuFrame.tsx';
import { verticalMenuScrollablePartPadding } from '../decisionTokens.ts';
import { VerticalMenuItem, VerticalMenuSection } from '../types.ts';
import { isItemNode, isSectionNode } from '../utils/utils.tsx';
import { Section } from './Section.tsx';

export interface IItemProps<TItem extends VerticalMenuItem<TItem>>
  extends Pick<VerticalMenuFrameProps<TItem>, 'pinnedItemId' | 'renderItem' | 'state'>,
    Omit<ListChildComponentProps, 'data'> {
  readonly item: Node<VerticalMenuSection<TItem> | TItem> | undefined;
}

export const VirtualizedRow = <TItem extends VerticalMenuItem<TItem>>(
  props: IItemProps<TItem>,
): ReactElement | null => {
  const { item, pinnedItemId, renderItem, state, style, index, ...otherProps } = props;

  if (!item || item.key === pinnedItemId) {
    return null;
  }

  const top = (typeof style.top === 'string' ? Number.parseFloat(style.top) : style.top) ?? 0;

  const styleWithPadding = {
    ...style,
    top: top + verticalMenuScrollablePartPadding,
  };

  if (isSectionNode(item)) {
    return (
      <div key={item.key} style={styleWithPadding} {...otherProps}>
        <Section
          isFirst={index === 0}
          {...{
            item,
            pinnedItemId,
            renderItem,
            state,
          }}
        />
      </div>
    );
  }

  if (isItemNode(item)) {
    return (
      <div key={item.key} style={styleWithPadding} {...otherProps}>
        {renderItem({
          item,
          level: item.level,
          state,
        })}
      </div>
    );
  }

  return null;
};

VirtualizedRow.displayName = 'VirtualizedRow';
