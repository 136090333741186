import React, { useState } from 'react';
import {
  ContentChangesWarningModal,
  ContentChangesWarningSubtitle,
} from '../../../../../_shared/components/ModalDialog/ContentChangesWarningModal.tsx';
import { SaveElementsWithAffectedLocalizationWarning } from '../../../../../_shared/components/ModalDialog/SaveElementsWithAffectedLocalizationWarning.tsx';
import { StatusBarActions } from '../../../../../_shared/components/StatusBar/StatusBarActions.tsx';
import { IBaseTypeElementData } from '../../models/elements/types/TypeElementData.ts';

interface IContentTypeOrSnippetEditorToolbarActionsProps {
  readonly codename?: string | null;
  readonly elementsWithAffectedLocalization: ReadonlyArray<IBaseTypeElementData>;
  readonly isBeingSaved: boolean;
  readonly isCodenameEditable?: boolean;
  readonly isDuplicateDisabled?: boolean;
  readonly isDeleteDisabled?: boolean;
  readonly isLoading: boolean;
  readonly disabledDeleteTooltip?: string;
  readonly relatedCodenames?: ReadonlySet<string>;
  readonly hideLongProcessingChangesWarning?: boolean;

  readonly onCodenameChange?: (codename: string) => void;
  readonly onCodenameCopy?: () => void;
  readonly onCodenameEdit?: () => void;
  readonly onDuplicate?: () => void;
  readonly onDelete?: () => void;
  readonly onSave: () => void;
  readonly onDismissWarning: () => void;
}

export const ContentTypeOrSnippetEditorToolbarActions: React.FC<
  IContentTypeOrSnippetEditorToolbarActionsProps
> = ({
  codename,
  elementsWithAffectedLocalization,
  isBeingSaved,
  isCodenameEditable,
  relatedCodenames,
  isDuplicateDisabled,
  isLoading,
  hideLongProcessingChangesWarning,
  onSave,
  onCodenameChange,
  onCodenameCopy,
  onCodenameEdit,
  onDuplicate,
  onDismissWarning,
}) => {
  const [showDialog, setShowDialog] = useState(false);

  const onSaveChanges = () => {
    if (!hideLongProcessingChangesWarning || elementsWithAffectedLocalization.length > 0) {
      setShowDialog(true);
    } else {
      onSave();
    }
  };

  const onConfirmDialog = () => {
    setShowDialog(false);
    onSave();
  };

  const onCancelDialog = () => {
    setShowDialog(false);
  };

  return (
    <>
      <StatusBarActions
        codename={codename}
        isBeingSaved={isBeingSaved}
        isCodenameEditable={isCodenameEditable}
        relatedCodenames={relatedCodenames}
        isDuplicateDisabled={isDuplicateDisabled}
        isLoading={isLoading}
        onSave={onSaveChanges}
        onCodenameChange={onCodenameChange}
        onCodenameCopy={onCodenameCopy}
        onCodenameEdit={onCodenameEdit}
        onDuplicate={onDuplicate}
      />
      <ContentChangesWarningModal
        onCancel={onCancelDialog}
        onConfirm={onConfirmDialog}
        onDismissWarning={onDismissWarning}
        isOpen={showDialog}
        warningSubtitle={ContentChangesWarningSubtitle.ContentTypeEditing}
        hideLongProcessingChangesWarning={hideLongProcessingChangesWarning}
        renderAdditionalModalContent={() => (
          <SaveElementsWithAffectedLocalizationWarning
            elementsWithAffectedLocalization={elementsWithAffectedLocalization}
          />
        )}
      />
    </>
  );
};

ContentTypeOrSnippetEditorToolbarActions.displayName = 'ContentTypeOrSnippetEditorToolbarActions';
