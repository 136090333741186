import { makeCancellablePromise, swallowCancelledPromiseError } from '@kontent-ai/utils';
import React, { useEffect } from 'react';
import { EnsureValidState } from '../../../_shared/components/EnsureValidState.tsx';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../_shared/models/LoadingStatusEnum.ts';
import { IStore } from '../../../_shared/stores/IStore.type.ts';
import {
  initEntityWebhookCreator,
  initEntityWebhookEditor,
} from '../actions/thunkEntityWebhookActions.ts';

type EnsureWebhookEditorDataProps = {
  readonly editedEntityWebhookId?: Uuid;
  readonly children: React.ReactNode;
};

const getAreFilterEntitiesLoaded = (s: IStore): boolean =>
  [
    s.data.collections.loadingStatus,
    s.data.contentTypes.loadingStatus,
    s.data.languages.byIdLoadingStatus,
    s.data.taxonomyGroups.loadingStatus,
    s.data.workflows.loadingStatus,
  ].every((ls) => ls === LoadingStatus.Loaded);

export const EnsureEntityWebhookEditorData = ({
  editedEntityWebhookId,
  children,
}: EnsureWebhookEditorDataProps) => {
  const dispatch = useDispatch();

  const areFilterEntitiesLoaded = useSelector(getAreFilterEntitiesLoaded);

  const isEntityWebhookLoaded =
    useSelector((s) => s.data.entityWebhooks.loadingStatus) === LoadingStatus.Loaded;

  useEffect(() => {
    const { cancel } = makeCancellablePromise(() =>
      editedEntityWebhookId
        ? dispatch(initEntityWebhookEditor(editedEntityWebhookId))
        : dispatch(initEntityWebhookCreator()),
    ).catch(swallowCancelledPromiseError);

    return cancel;
  }, [editedEntityWebhookId]);

  return (
    <EnsureValidState isStateEnsured={areFilterEntitiesLoaded && isEntityWebhookLoaded}>
      {children}
    </EnsureValidState>
  );
};

EnsureEntityWebhookEditorData.displayName = 'EnsureEntityWebhookEditorDataContainer';
