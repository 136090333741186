import PropTypes from 'prop-types';
import { IconSize } from '../../tokens/quarks/iconSize.ts';
import { CLPropTypes } from '../../validators/propTypes.ts';
import { IIconWithoutSVGProps } from './IIconProps.type.ts';

export const iconPropTypes: PropTypeMap<IIconWithoutSVGProps> = {
  color: CLPropTypes.colors.color,
  screenReaderText: PropTypes.string,
  size: PropTypes.oneOf(Object.values(IconSize)),
  children: CLPropTypes.never,
  ref: CLPropTypes.refObject,
};
