import React from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { newContentItemSelectedLanguageChanged } from '../actions/newContentItemActions.ts';
import { validateSelectedValues } from '../actions/thunkNewContentItemActions.ts';
import { LanguageSelector } from '../components/LanguageSelector.tsx';
import { getLanguageOptions } from '../selectors/getLanguageOptions.ts';
import { getNewContentItemForm } from '../selectors/newContentItemDialog.ts';

interface ILanguageDropdownComponentProps {
  readonly index: number;
}

const LanguageSelectorContainer: React.FC<ILanguageDropdownComponentProps> = ({ index }) => {
  const selectedLanguageId = useSelector((s) => getNewContentItemForm(s).languageId);
  const languageOptions = useSelector((s) => getLanguageOptions(s, Capability.CreateContent));

  const dispatch = useDispatch();
  const onSelect = (languageId: Uuid) => {
    dispatch(newContentItemSelectedLanguageChanged(languageId));
    dispatch(validateSelectedValues());
  };

  return (
    <LanguageSelector
      currentLanguageId={selectedLanguageId}
      index={index}
      languageOptions={languageOptions}
      onSelect={onSelect}
    />
  );
};

LanguageSelectorContainer.displayName = 'LanguageSelectorContainer';

export { LanguageSelectorContainer as LanguageSelector };
