import { EditorState } from 'draft-js';
import PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';
import {
  ITextTypeElement,
  TextTypeElementPropTypes,
} from '../../../../contentInventory/content/models/contentTypeElements/TextTypeElement.ts';
import { IFocusable } from '../../../../richText/plugins/behavior/FocusPlugin.tsx';
import {
  ITextItemElement,
  TextItemElementPropTypes,
} from '../../../models/contentItemElements/TextItemElement.ts';
import { SimpleMultilineTextInput } from '../../ContentItemEditing/containers/elements/stringElement/SimpleMultilineTextInput.tsx';
import { IContentComponentItemElementOwnProps } from '../IContentComponentItemElementOwnProps.type.ts';
import { ContentComponentItemElement } from '../containers/ContentComponentItemElement.tsx';

interface IContentComponentStringElementProps
  extends IContentComponentItemElementOwnProps<ITextItemElement, ITextTypeElement> {}

const propTypes: PropTypeMap<IContentComponentStringElementProps> = {
  autoFocus: PropTypes.bool.isRequired,
  contentComponentId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  elementData: TextItemElementPropTypes.isRequired,
  onUpdate: PropTypes.func.isRequired,
  typeElement: TextTypeElementPropTypes.isRequired,
  validationResultSelectorId: PropTypes.string.isRequired,
};

export const ContentComponentStringElement: React.FC<IContentComponentStringElementProps> = (
  props,
) => {
  const {
    autoFocus,
    contentComponentId,
    disabled,
    elementData,
    onUpdate,
    typeElement,
    validationResultSelectorId,
  } = props;

  const editorRef = useRef<IFocusable>(null);

  const onContentChange = useCallback(
    (newState: EditorState): Promise<void> => {
      onUpdate({
        ...elementData,
        _editorState: newState,
      });
      return Promise.resolve();
    },
    [elementData, onUpdate],
  );

  const focusEditorAtTheStart = useCallback(() => {
    editorRef.current?.focusAtTheStart();
  }, []);

  const focusEditorAtTheEnd = useCallback(() => {
    if (editorRef.current) {
      editorRef.current.focusAtTheEnd();
    }
  }, []);

  return (
    <ContentComponentItemElement
      typeElement={typeElement}
      validationResultSelectorId={validationResultSelectorId}
      disabled={disabled}
      contentComponentId={contentComponentId}
      onHeaderClick={focusEditorAtTheStart}
      onContentClick={focusEditorAtTheEnd}
    >
      <SimpleMultilineTextInput
        ref={editorRef}
        disabled={disabled}
        editorState={elementData._editorState}
        className="rte--in-content-item-element"
        onContentChange={onContentChange}
        autoFocus={autoFocus}
        typeElement={typeElement}
      />
    </ContentComponentItemElement>
  );
};

ContentComponentStringElement.displayName = 'ContentComponentStringElement';
ContentComponentStringElement.propTypes = propTypes;
