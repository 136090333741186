import { useCallback, useContext, useEffect } from 'react';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { getSelectedLanguageCodename } from '../../../_shared/selectors/getSelectedLanguageCodename.ts';
import { IStore } from '../../../_shared/stores/IStore.type.ts';
import { getCurrentProjectId } from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { startPreviewApiChangesPolling } from '../actions/thunkWebSpotlightActions.ts';
import { WebSpotlightContext } from '../context/WebSpotlightContext.tsx';
import {
  isPreviewAutoRefreshFeatureAvailable,
  isPreviewAutoRefreshFeatureEnabledByUser,
} from '../selectors/webSpotlightSelectors.ts';
import { useIsPreviewUrlOutdated } from './useIsPreviewUrlOutdated.ts';

export function usePreviewApiPolling(): void {
  const dispatch = useDispatch();
  const currentProjectId = useSelector(getCurrentProjectId);
  const selectedLanguageCodename = useSelector(getSelectedLanguageCodename);
  const isAutoRefreshFeatureAvailable = useSelector(isPreviewAutoRefreshFeatureAvailable);
  const isAutoRefreshFeatureEnabled = useSelector(isPreviewAutoRefreshFeatureEnabledByUser);
  const lastModifiedPreviewItems = useSelector(
    (s: IStore) => s.webSpotlightApp.lastModifiedPreviewItems,
  );

  const isPreviewUrlOutdated = useIsPreviewUrlOutdated();

  const { refreshPreview, supportedSmartLinkFeatures } = useContext(WebSpotlightContext);

  const onChangeReady = useCallback(
    (itemCodename: string) => {
      if (isAutoRefreshFeatureEnabled) {
        refreshPreview({
          isManualRefresh: false,
          data: {
            projectId: currentProjectId,
            languageCodename: selectedLanguageCodename,
            updatedItemCodename: itemCodename,
          },
          isPreviewUrlOutdated,
        });
      }
    },
    [
      refreshPreview,
      currentProjectId,
      selectedLanguageCodename,
      isAutoRefreshFeatureEnabled,
      isPreviewUrlOutdated,
    ],
  );

  const startPreviewApiPolling = useCallback(
    (signal: AbortSignal) => {
      dispatch(
        startPreviewApiChangesPolling({
          signal,
          onChangeReady,
        }),
      );
    },
    [onChangeReady],
  );

  useEffect(() => {
    const isAutoRefreshFeatureSupported = supportedSmartLinkFeatures?.refreshHandler;
    if (
      !isAutoRefreshFeatureAvailable ||
      !isAutoRefreshFeatureSupported ||
      lastModifiedPreviewItems.size === 0
    ) {
      return;
    }

    const abortController = new AbortController();
    const signal = abortController.signal;

    startPreviewApiPolling(signal);

    return () => {
      abortController.abort();
    };
  }, [
    isAutoRefreshFeatureAvailable,
    lastModifiedPreviewItems,
    startPreviewApiPolling,
    supportedSmartLinkFeatures,
  ]);
}
