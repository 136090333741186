import { useCallback } from 'react';
import { AiActionName } from '../../../../../repositories/serverModels/ai/AiActionName.type.ts';
import { createCancelOperationParams } from '../../../../../repositories/serverModels/ai/AiServerModels.cancelOperation.ts';
import { AiActionNameToParameters } from '../../../../../repositories/serverModels/ai/AiServerModels.params.ts';
import { useAiTaskManager } from '../../../../contexts/AiTaskManagerProvider.tsx';
import { repositoryCollection } from '../../../../repositories/repositories.ts';
import {
  AiActionNameToMessagePayload,
  AnyAiMessagePayload,
} from '../../../../services/signalR/signalRClient.type.ts';
import { AiTaskMeta } from '../../../../utils/aiTasks/aiTaskManager.ts';
import { OnAiTaskUpdate } from '../../types/OnAiTaskUpdate.type.ts';
import { MatchAiTask } from './matchAiTask.type.ts';

export type Cancel = (matchAiTask: MatchAiTask) => Promise<void>;

export type HandOver = (
  matchAiTask: MatchAiTask,
  onUpdateInBackground: OnAiTaskUpdate<AiActionNameToMessagePayload[AiActionName]>,
) => void;

export type Run = <TActionName extends AiActionName>(
  actionName: TActionName,
  actionParameters: AiActionNameToParameters[TActionName],
  onUpdateInForeground: OnAiTaskUpdate<AiActionNameToMessagePayload[TActionName]>,
  aiTaskContext?: ReadonlyRecord<string, unknown>,
) => Promise<{
  readonly cancel: () => Promise<void>;
}>;

export type TakeOver = (
  matchAiTask: MatchAiTask,
  onUpdateInForeground: OnAiTaskUpdate<AnyAiMessagePayload>,
) => AiTaskMeta | null;

/**
 * A hook that can manage AI tasks. It does not store any information about the task. It serves as a React facade over the AI task manager.
 */
export const useAiTasks = (): {
  readonly cancel: Cancel;
  readonly handOver: HandOver;
  readonly run: Run;
  readonly takeOver: TakeOver;
} => {
  const aiTaskManager = useAiTaskManager();

  const cancel = useCallback<Cancel>(
    async (matchAiTask) => {
      const aiTask = matchAiTask(aiTaskManager.getAllTasks());
      if (!aiTask) return;
      await aiTaskManager.cancel(aiTask.id, (operationId) =>
        repositoryCollection.aiRepository.cancelAction(createCancelOperationParams(operationId)),
      );
    },
    [aiTaskManager],
  );

  const handOver = useCallback<HandOver>(
    (matchAiTask, onUpdateInBackground) => {
      const aiTask = matchAiTask(aiTaskManager.getAllTasks());
      if (aiTask) aiTaskManager.changeProcessMessagesCallback(aiTask.id, onUpdateInBackground);
    },
    [aiTaskManager],
  );

  const run = useCallback<Run>(
    async (actionName, actionParameters, onUpdateInForeground, aiTaskContext) => {
      const aiTaskId = await aiTaskManager.createTask(
        actionName,
        actionParameters,
        (parameters) => repositoryCollection.aiRepository.createAction(parameters),
        onUpdateInForeground,
        aiTaskContext,
      );

      return {
        cancel: async () => {
          await aiTaskManager.cancel(aiTaskId, (operationId) =>
            repositoryCollection.aiRepository.cancelAction(
              createCancelOperationParams(operationId),
            ),
          );
        },
      };
    },
    [aiTaskManager],
  );

  const takeOver = useCallback<TakeOver>(
    (matchAiTask, onUpdateInForeground) => {
      const aiTask = matchAiTask(aiTaskManager.getAllTasks());
      if (!aiTask) return null;
      const currentTaskActionStatus = aiTaskManager.getTaskActionStatus(aiTask.id);
      const currentTaskActionMessages = aiTaskManager.getTaskActionMessages(aiTask.id);
      if (!currentTaskActionStatus || !currentTaskActionMessages) return null;
      aiTaskManager.changeProcessMessagesCallback(aiTask.id, onUpdateInForeground);
      return aiTask;
    },
    [aiTaskManager],
  );

  return {
    cancel,
    handOver,
    run,
    takeOver,
  };
};
