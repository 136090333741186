import { combineReducers } from '@reduxjs/toolkit';
import { archivedWorkflowStep } from './editorUi/archivedWorkflowStep.ts';
import { cancellationToken } from './editorUi/cancellationToken.ts';
import { draggedWorkflowStepId } from './editorUi/draggedWorkflowStepId.ts';
import { editedWorkflowCodename } from './editorUi/editedWorkflowCodename.ts';
import { editedWorkflowId } from './editorUi/editedWorkflowId.ts';
import { editedWorkflowName } from './editorUi/editedWorkflowName.ts';
import { editedWorkflowScopesById } from './editorUi/editedWorkflowScopesById.ts';
import { editedWorkflowStepId } from './editorUi/editedWorkflowStepId.ts';
import { hasEditedWorkflowStepUnsavedChanges } from './editorUi/hasEditedWorkflowStepUnsavedChanges.ts';
import { isEditedWorkflowStepNew } from './editorUi/isEditedWorkflowStepNew.ts';
import { publishedWorkflowStep } from './editorUi/publishedWorkflowStep.ts';
import { regularWorkflowSteps } from './editorUi/regularWorkflowSteps.ts';
import { regularWorkflowStepsOrder } from './editorUi/regularWorkflowStepsOrder.ts';
import { workflowErrors } from './editorUi/workflowErrors.ts';
import { workflowLoadingStatus } from './editorUi/workflowLoadingStatus.ts';
import { workflowStepsUsage } from './editorUi/workflowStepsUsage.ts';
import { loadingStatus } from './listingUi/listingLoadingStatus.ts';
import { operationStatus } from './listingUi/operationStatus.ts';

export const workflowsAppReducer = combineReducers({
  editorUi: combineReducers({
    archivedWorkflowStep,
    cancellationToken,
    draggedWorkflowStepId,
    editedWorkflowCodename,
    editedWorkflowId,
    editedWorkflowName,
    editedWorkflowScopesById,
    editedWorkflowStepId,
    hasEditedWorkflowStepUnsavedChanges,
    isEditedWorkflowStepNew,
    publishedWorkflowStep,
    regularWorkflowSteps,
    regularWorkflowStepsOrder,
    workflowLoadingStatus,
    workflowErrors,
    workflowStepsUsage,
  }),
  listingUi: combineReducers({
    loadingStatus,
    operationStatus,
  }),
});
