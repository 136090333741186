import React from 'react';
import { ContentItemId } from '../../../../../_shared/models/ContentItemId.type.ts';
import { ListingMessage } from '../../../../../_shared/uiComponents/ListingMessage/ListingMessage.tsx';
import { DataUiElement } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IStatusInfoMessage } from '../../../../contentInventory/assets/models/IStatusInfoMessage.type.ts';
import { FailedItemsModal } from '../../../../contentInventory/content/components/FailedItemsModal/FailedItemsModal.tsx';

export interface ICascadeFailedItemsModalProps {
  readonly failedItemIds: ReadonlyArray<ContentItemId>;
  readonly modalTitle: string;
  readonly onClose: () => void;
  readonly statusInfoMessage: IStatusInfoMessage;
}

export const CascadeFailedItemsModal: React.ComponentType<ICascadeFailedItemsModalProps> = (
  props,
) => (
  <FailedItemsModal
    modalTitle={props.modalTitle}
    dataUiElement={DataUiElement.CascadePublishFailedItemsDialog}
    failedItemIds={props.failedItemIds}
    renderScrollTableTitle={() => <ListingMessage statusInfoMessage={props.statusInfoMessage} />}
    onClose={props.onClose}
    showCollectionColumn={false}
  />
);

CascadeFailedItemsModal.displayName = 'CascadeFailedItemsModal';
