import { Pathname } from 'history';
import ImmutablePropTypes from 'immutable-prop-types';
import PropTypes from 'prop-types';
import React from 'react';
import { BackNavigation } from './AppBar/BackNavigation.tsx';
import { Breadcrumbs, IBreadcrumbsProps } from './Breadcrumbs.tsx';

export interface IApplicationBreadcrumbsProps
  extends Pick<IBreadcrumbsProps, 'breadcrumbs' | 'renderSavingStatusComponent'> {
  readonly navigateBackPath?: Pathname;
}

const propTypes: PropTypeMap<IApplicationBreadcrumbsProps> = {
  breadcrumbs: ImmutablePropTypes.list.isRequired,
  navigateBackPath: PropTypes.string,
  renderSavingStatusComponent: PropTypes.func,
};

export const ApplicationBreadcrumbs: React.FC<
  React.PropsWithChildren<IApplicationBreadcrumbsProps>
> = ({ navigateBackPath, children, ...breadcrumbsProps }) => {
  if (breadcrumbsProps.breadcrumbs.isEmpty()) {
    return null;
  }

  return (
    <>
      {navigateBackPath && <BackNavigation to={navigateBackPath} />}
      <Breadcrumbs {...breadcrumbsProps} />
      {children}
    </>
  );
};

ApplicationBreadcrumbs.displayName = 'ApplicationBreadcrumbs';
ApplicationBreadcrumbs.propTypes = propTypes;
