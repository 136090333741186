import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Loader } from '../../../../../_shared/components/Loader.tsx';
import { AuthorizedSection } from '../../../../../_shared/components/routing/AuthorizedSection.tsx';
import { ApiKeyDetailAccessDeniedRouteSegment } from '../../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ApiKeyType } from '../../../../../_shared/models/ApiKeyType.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { formatUserName } from '../../../../../_shared/utils/usersUtils.ts';
import { getCurrentProjectContainer } from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { apiKeyDetailsAlertDismissed } from '../../actions/apiKeysActions.ts';
import { createApiKey, initNewApiKey } from '../../actions/thunkApiKeysActions.ts';
import { ApiKey, emptyApiKey } from '../../models/ApiKey.ts';
import { getAccessibleEnvironmentIds } from '../../selectors/getAccessibleEnvironmentIds.ts';
import { hasCapabilityInAnyEnvironment } from '../../selectors/hasCapabilityInAnyEnvironment.ts';
import { PersonalMapiKeyFormShape } from '../../validation/personalMapiKeyValidationConfig.ts';
import { PersonalMapiKeyDetailForm } from './PersonalMapiKeyDetailForm.tsx';

export const NewPersonalMapiKeyDetail: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentProjectContainer = useSelector(getCurrentProjectContainer);
  const isAuthorized = useSelector((state) =>
    hasCapabilityInAnyEnvironment(state, Capability.ManagePersonalApiKey),
  );
  const apiKeyName = `${useSelector((s) => formatUserName(s.data.user.info))}’s key`;
  const environments = useSelector(getAccessibleEnvironmentIds);
  const loadingStatus = useSelector((state) => state.apiKeysApp.detailLoadingStatus);
  const actionStatus = useSelector((state) => state.apiKeysApp.detailActionStatus);

  const onSave = (
    mapiKeyFormShape: PersonalMapiKeyFormShape,
    isFromUnsavedChangesDialog: boolean,
  ) => {
    const apiKey: ApiKey = {
      ...emptyApiKey,
      name: apiKeyName,
      expiresAt: mapiKeyFormShape.expiresAt.value,
      type: ApiKeyType.MAPIPat,
    };

    return dispatch(
      createApiKey(
        apiKey,
        isFromUnsavedChangesDialog ? undefined : history,
        currentProjectContainer.projectContainerId,
      ),
    );
  };

  useEffect(() => {
    dispatch(initNewApiKey(ApiKeyType.MAPIPat));
  }, []);

  if (loadingStatus !== LoadingStatus.Loaded) {
    return <Loader />;
  }

  return (
    <AuthorizedSection
      appName={ApiKeyDetailAccessDeniedRouteSegment}
      isAuthorized={isAuthorized}
      projectId={currentProjectContainer.masterEnvironmentId}
      projectContainerId={currentProjectContainer.projectContainerId}
    >
      <PersonalMapiKeyDetailForm
        apiKey={getNewApiKey(apiKeyName, environments)}
        apiKeyName={apiKeyName}
        apiKeyActionStatus={actionStatus}
        onAlertDismiss={() => dispatch(apiKeyDetailsAlertDismissed())}
        onSave={onSave}
      />
    </AuthorizedSection>
  );
};

NewPersonalMapiKeyDetail.displayName = 'NewPersonalMapiKeyDetail';

const getNewApiKey = (name: string, environments: ReadonlyArray<Uuid>): ApiKey => ({
  ...emptyApiKey,
  name,
  type: ApiKeyType.MAPIPat,
  environments,
});
