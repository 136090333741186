import { DraftInlineStyle } from 'draft-js';
import React from 'react';
import { BlockType } from '../../../utils/blocks/blockType.ts';
import { IAggregatedMetadata } from '../../../utils/metadata/editorMetadataUtils.ts';
import { EditorFeatureLimitations } from '../../apiLimitations/api/EditorFeatureLimitations.ts';
import { RichTextInputCommand } from '../../keyboardShortcuts/api/EditorCommand.ts';
import { ButtonInputProps, getToolbarButtonProps } from '../../toolbars/utils/toolbarUtils.ts';
import { BoldButton } from './buttons/BoldButton.tsx';
import { CodeButton } from './buttons/CodeButton.tsx';
import { ItalicButton } from './buttons/ItalicButton.tsx';
import { SubscriptButton } from './buttons/SubscriptButton.tsx';
import { SuperscriptButton } from './buttons/SuperscriptButton.tsx';

export interface IInlineStylesToolbarButtonsProps {
  readonly currentVisualStyle: DraftInlineStyle | null;
  readonly fullBlockTypesAtSelection: ReadonlySet<BlockType>;
  readonly hidesDisallowedFeatures: boolean;
  readonly limitations: EditorFeatureLimitations;
  readonly metadataAtSelection: IAggregatedMetadata | null;
  readonly onCommand: (command: RichTextInputCommand) => void;
}

export const InlineStylesToolbarButtons: React.FC<IInlineStylesToolbarButtonsProps> = ({
  currentVisualStyle,
  fullBlockTypesAtSelection,
  hidesDisallowedFeatures,
  limitations,
  metadataAtSelection,
  onCommand,
}) => {
  const buttonProps: ButtonInputProps = {
    fullBlockTypesAtSelection,
    currentVisualStyle,
    hidesDisallowedFeatures,
    limitations,
    metadataAtSelection,
    onCommand,
    selectionContainsText: null,
  };

  const boldProps = getToolbarButtonProps(RichTextInputCommand.Bold, buttonProps);
  const italicProps = getToolbarButtonProps(RichTextInputCommand.Italic, buttonProps);
  const superscriptProps = getToolbarButtonProps(RichTextInputCommand.Superscript, buttonProps);
  const subscriptProps = getToolbarButtonProps(RichTextInputCommand.Subscript, buttonProps);
  const codeProps = getToolbarButtonProps(RichTextInputCommand.Code, buttonProps);

  return (
    <>
      {boldProps && <BoldButton {...boldProps} />}
      {italicProps && <ItalicButton {...italicProps} />}
      {superscriptProps && <SuperscriptButton {...superscriptProps} />}
      {subscriptProps && <SubscriptButton {...subscriptProps} />}
      {codeProps && <CodeButton {...codeProps} />}
    </>
  );
};

InlineStylesToolbarButtons.displayName = 'InlineStylesToolbarButtons';
