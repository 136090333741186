import PropTypes from 'prop-types';
import React, { ReactElement, useEffect, useRef } from 'react';
import {
  EmptyObject,
  FieldPathByValue,
  FieldValues,
  useController,
  useWatch,
} from 'react-hook-form';
import { HookFormProps, hookFormProps } from '../../types/hookFormProps.ts';
import { generateCodename } from '../../utils/codenameGenerator.ts';
import { showFieldError } from '../../utils/validation/showFieldError.ts';
import { CodenameEditorBase } from './CodenameEditor.tsx';

type Props<TFormShape extends FieldValues> = {
  readonly dependantFieldName: FieldPathByValue<TFormShape, string>;
  readonly formProps: HookFormProps<TFormShape>;
  readonly initialValue: string;
  readonly name: FieldPathByValue<TFormShape, string>;
  readonly readOnly?: boolean;
};

const propTypes: PropTypesShape<Props<any>> = {
  dependantFieldName: PropTypes.string.isRequired,
  formProps: hookFormProps.isRequired,
  initialValue: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

export const DependantCodenameEditor = <TFormShape extends FieldValues = EmptyObject>({
  dependantFieldName,
  formProps,
  initialValue,
  name,
  readOnly,
}: Props<TFormShape>): ReactElement => {
  const onChangeRef = useRef<(value: string) => void>();
  const { control } = formProps;
  const dependantFieldValue = useWatch({
    control: formProps.control,
    name: dependantFieldName,
  }) as string;

  const { field, fieldState, formState } = useController({ control, name });
  const { isTouched, error } = fieldState;
  const showError = showFieldError(formState, error);

  onChangeRef.current = field.onChange;

  useEffect(() => {
    if (!isTouched && !initialValue) {
      const codename = generateCodename(dependantFieldValue);

      onChangeRef.current?.(codename);
    }
  }, [isTouched, initialValue, dependantFieldValue]);

  return (
    <CodenameEditorBase
      ref={field.ref}
      value={field.value as string}
      error={error}
      onBlur={field.onBlur}
      onChange={field.onChange}
      showError={showError}
      readOnly={readOnly}
    />
  );
};

(DependantCodenameEditor as React.FC).displayName = 'DependantCodenameEditor';
(DependantCodenameEditor as React.FC<Props<any>>).propTypes = propTypes;
