import { useCallback, useMemo } from 'react';
import { UrlSlugElementStatus } from '../../../../itemEditor/features/ContentItemEditing/components/elements/urlSlug/UrlSlugElementStatus.tsx';
import { IUrlSlugWarningResult } from '../../../../itemEditor/utils/itemElementWarningCheckers/types/IUrlSlugWarningResult.type.ts';
import { useEditorWithPlugin } from '../../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginCreator } from '../../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../../editorCore/types/Editor.contract.type.ts';
import { Apply, EditorPlugin, Render } from '../../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../../editorCore/utils/decorable.ts';
import { withDisplayName } from '../../../editorCore/utils/withDisplayName.ts';
import { UrlSlugLimitations } from '../../../plugins/apiLimitations/api/EditorFeatureLimitations.ts';
import { StylesPlugin } from '../../../plugins/visuals/StylesPlugin.tsx';
import { AutoGeneratedUrlSlugPlugin } from './AutoGeneratedUrlSlugPlugin.tsx';

type UrlSlugInputStatusPluginProps = {
  readonly defaultValue?: string;
  readonly dependentTextFieldName?: string;
  readonly errorMessages: ReadonlyArray<string>;
  readonly limitations: UrlSlugLimitations;
  readonly validationResult: IUrlSlugWarningResult;
};

export type UrlSlugInputStatusPlugin = EditorPlugin<
  None,
  UrlSlugInputStatusPluginProps,
  None,
  [StylesPlugin, AutoGeneratedUrlSlugPlugin]
>;

export const useUrlSlugInputStatus: PluginCreator<UrlSlugInputStatusPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('UrlSlugInputStatusPlugin', {
        ComposedEditor: (props) => {
          const {
            customMode,
            dependentTextFieldName,
            errorMessages,
            limitations,
            validationResult,
          } = props;

          const renderStatus: Decorator<Render<UrlSlugInputStatusPlugin>> = useCallback(
            (baseRenderStatus) => (state) => {
              const { editorState } = state;

              const generatedFrom = dependentTextFieldName
                ? dependentTextFieldName
                : 'content item name';
              const hasFocus = editorState.getSelection().getHasFocus();
              const hasText = editorState.getCurrentContent().hasText();

              return (
                <>
                  {baseRenderStatus(state)}
                  <UrlSlugElementStatus
                    customMode={customMode}
                    errorMessages={errorMessages}
                    generatedFrom={generatedFrom}
                    hasFocus={hasFocus}
                    hasText={hasText}
                    limitations={limitations}
                    validationResult={validationResult}
                  />
                </>
              );
            },
            [customMode, dependentTextFieldName, errorMessages, limitations, validationResult],
          );

          const apply: Apply<UrlSlugInputStatusPlugin> = useCallback(
            (state) => {
              state.renderStatus.decorate(renderStatus);
              return {};
            },
            [renderStatus],
          );

          return useEditorWithPlugin(baseEditor, props, { apply });
        },
      }),
    [baseEditor],
  );
