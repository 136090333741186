import classNames from 'classnames';
import React, { useRef } from 'react';
import { useScrollInfo } from '../../hooks/useScrollInfo.ts';

export interface IModalDialogTwoColumnBodyLayoutProps {
  readonly leftColumnElement: React.ReactNode;
  readonly rightColumnElement: React.ReactNode;
  readonly topElement: React.ReactNode;
  readonly notificationBar: React.ReactNode;
}

export const ModalDialogTwoColumnBodyLayout: React.FC<IModalDialogTwoColumnBodyLayoutProps> = (
  props,
) => {
  const leftColumnRef = useRef<HTMLDivElement>(null);
  const rightColumnRef = useRef<HTMLDivElement>(null);

  const { isScrollable: isLeftColumnScrollable, height: leftColumnHeight } =
    useScrollInfo(leftColumnRef);

  const { height: rightColumnHeight } = useScrollInfo(rightColumnRef);

  const maxColumnHeight = Math.max(leftColumnHeight, rightColumnHeight);

  return (
    <div
      className={classNames('modal-dialog-two-column-layout', {
        'modal-dialog-two-column-layout--narrow': !props.rightColumnElement,
      })}
    >
      {props.topElement && (
        <div className="modal-dialog-two-column-layout__top">{props.topElement}</div>
      )}
      <div className="modal-dialog-two-column-layout__sections">
        <div className="modal-dialog-two-column-layout__section-wrapper">
          <div ref={leftColumnRef} className="modal-dialog-two-column-layout__section">
            {props.leftColumnElement}
          </div>
        </div>
        {props.rightColumnElement && (
          <>
            <div
              className="modal-dialog-two-column-layout__sections-separator"
              style={{ height: isLeftColumnScrollable ? maxColumnHeight : 'auto' }}
            />
            <div className="modal-dialog-two-column-layout__section-wrapper">
              <div
                className={classNames(
                  'modal-dialog-two-column-layout__section',
                  'modal-dialog-two-column-layout__section--right',
                  {
                    'modal-dialog-two-column-layout__section--with-own-scroll':
                      !isLeftColumnScrollable,
                  },
                )}
                ref={rightColumnRef}
              >
                {props.rightColumnElement}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="modal-dialog-two-column-layout__notification-bar-wrapper">
        {props.notificationBar}
      </div>
    </div>
  );
};

ModalDialogTwoColumnBodyLayout.displayName = 'ModalDialogTwoColumnBodyLayout';
