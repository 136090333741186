import React, { useEffect } from 'react';
import { tryEnsuringSelectedLanguageId } from '../actions/thunkSharedActions.ts';
import { useDispatch } from '../hooks/useDispatch.ts';
import { useSelector } from '../hooks/useSelector.ts';
import { isSelectedLanguageIdInitialized } from '../selectors/getSelectedLanguageId.ts';
import { Loader } from './Loader.tsx';

interface IEnsureSelectedVariantIdProps {
  readonly children: React.ReactNode;
}

// This should be rendered right after EnsureCurrentProject
// as info about selected language is crucial for evaluation view-content permission
export const TryEnsuringSelectedVariantId: React.FC<IEnsureSelectedVariantIdProps> = ({
  children,
}) => {
  const isLanguageInitialized = useSelector(isSelectedLanguageIdInitialized);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLanguageInitialized) {
      dispatch(tryEnsuringSelectedLanguageId());
    }
  }, [isLanguageInitialized]);

  return isLanguageInitialized ? <>{children}</> : <Loader />;
};

TryEnsuringSelectedVariantId.displayName = 'TryEnsuringSelectedVariantId';
