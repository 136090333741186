import { Callout } from '@kontent-ai/component-library/Callout';
import { ChangeEvent, useState } from 'react';
import { HotkeysHandler } from '../../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import { ConfirmationDialog } from '../../../../_shared/components/ModalDialog/ConfirmationDialog.tsx';
import { TextField } from '../../../../_shared/uiComponents/TextField/TextField.tsx';
import { DataUiInput } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { trimAndRemoveMultipleSpaces } from '../../../../_shared/utils/stringUtils.ts';

type Props = {
  readonly onClose: () => void;
  readonly onProjectDelete: () => void;
  readonly projectName: string;
};

export const ProjectDeleteConfirmationDialog = ({
  onProjectDelete,
  projectName,
  onClose,
}: Props) => {
  const [confirmedProjectName, setConfirmedProjectName] = useState('');
  const [isConfirmationValid, setIsConfirmationValid] = useState(false);

  const onConfirmationInputChanged = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;

    setIsConfirmationValid(projectName === trimAndRemoveMultipleSpaces(value));
    setConfirmedProjectName(value);
  };

  const deleteProject = (): void => {
    if (isConfirmationValid) {
      onProjectDelete();
    }
  };

  const projectNameRequiresTrim = projectName.length > 45;
  const trimmedProjectName = projectNameRequiresTrim
    ? `${projectName.substr(0, 45)}…`
    : projectName;

  return (
    <ConfirmationDialog
      confirmButtonText="Delete project"
      confirmButtonTooltipText={
        isConfirmationValid ? 'Delete project' : `Project name doesn’t match “${projectName}”. `
      }
      headerContent={`Deleting the “${trimmedProjectName}” project`}
      headerTooltip={projectNameRequiresTrim ? `Deleting the “${projectName}” project` : undefined}
      isConfirmButtonDisabled={!isConfirmationValid}
      onClose={onClose}
      onConfirm={deleteProject}
    >
      <Callout calloutType="warning" headline="This action can’t be undone.">
        <p>What happens when you delete this project?</p>
        <ul>
          <li>The project’s content will be deleted.</li>
          <li>Users will lose access to this project.</li>
          <li>All languages and project settings will be deleted.</li>
          <li>All environments in the project will be deleted.</li>
        </ul>
      </Callout>
      <p className="project-delete-dialog__confirm-label form__label">
        To confirm you want to delete this project, enter its name
      </p>
      <HotkeysHandler
        handlers={{
          onEnter: deleteProject,
        }}
      >
        <TextField
          hasError={!isConfirmationValid && confirmedProjectName !== ''}
          inputId="project-name"
          onChange={onConfirmationInputChanged}
          placeholder="Project name"
          value={confirmedProjectName}
          autoFocus
          dataUiInputName={DataUiInput.ProjectName}
        />
      </HotkeysHandler>
    </ConfirmationDialog>
  );
};
