import React from 'react';
import { IconName } from '../../../../../../_shared/constants/iconEnumGenerated.ts';
import { ControlShiftShortcutTemplate } from '../../../../../../_shared/constants/shortcutSymbols.ts';
import {
  DataUiRteAction,
  getDataUiActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  IForwardedRefProps,
  forwardRef,
} from '../../../../../../_shared/utils/forwardedRefProps.tsx';
import {
  IToggleButtonProps,
  RTEToolbarIconButton,
  toggleButtonPropTypes,
} from '../../../toolbars/components/RTEToolbarButton.tsx';

const SuperscriptButtonComponent: React.FC<
  IToggleButtonProps & IForwardedRefProps<HTMLButtonElement>
> = (props) => (
  <RTEToolbarIconButton
    {...props}
    iconName={IconName.Superscript}
    ref={props.forwardedRef}
    tooltipPlacement={props.tooltipPlacement ?? 'bottom-start'}
    tooltipText={props.tooltipText ?? 'Superscript'}
    shortcut={ControlShiftShortcutTemplate('.')}
    {...getDataUiActionAttribute(DataUiRteAction.Superscript)}
  />
);

SuperscriptButtonComponent.displayName = 'SuperscriptButton';
SuperscriptButtonComponent.propTypes = toggleButtonPropTypes;

export const SuperscriptButton = forwardRef(SuperscriptButtonComponent);
