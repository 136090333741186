import React from 'react';
import { useRouteMatch } from 'react-router';
import {
  EnsureValidState,
  IEnsureValidStateOwnProps,
} from '../../../../_shared/components/EnsureValidState.tsx';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { ProjectSettingsWithMenuLayout } from '../../root/components/ProjectSettingsWithMenuLayout.tsx';
import { ProjectSettingsMenu } from '../../root/containers/ProjectSettingsMenu.tsx';
import { apiKeysListingLeft } from '../actions/apiKeysActions.ts';
import { initApiKeyListing } from '../actions/thunkApiKeysActions.ts';
import { areApiKeysLoaded } from '../selectors/apiKeysLoadedSelectors.ts';

const getMenuLayout = (url: string) => (
  <ProjectSettingsWithMenuLayout
    renderContent={() => <Loader />}
    renderSettingsMenu={() => <ProjectSettingsMenu url={url} />}
  />
);

export const EnsureApiKeyListingInitialized: React.FC<
  React.PropsWithChildren<IEnsureValidStateOwnProps>
> = ({ children }) => {
  const { url } = useRouteMatch();
  const areKeysLoaded = useSelector(areApiKeysLoaded);
  const dispatch = useDispatch();

  return (
    <EnsureValidState
      isStateEnsured={areKeysLoaded}
      onRouteEntered={() => dispatch(initApiKeyListing())}
      onRouteLeft={() => dispatch(apiKeysListingLeft())}
      renderLoader={() => getMenuLayout(url)}
    >
      {children}
    </EnsureValidState>
  );
};

EnsureApiKeyListingInitialized.displayName = 'EnsureApiKeyListingInitialized';
