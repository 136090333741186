import { Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { IAssetService } from '../../../../applications/contentInventory/content/features/Asset/services/assetService.ts';
import { AssetReference } from '../../../../applications/itemEditor/models/contentItemElements/AssetItemElement.ts';
import { IAssetRenditionRepository } from '../../../../repositories/interfaces/IAssetRenditionRepository.type.ts';
import { Data_Assets_Loaded } from '../../../constants/dataActionTypes.ts';
import {
  IAssetRendition,
  mapAssetRenditionFromServerModel,
} from '../../../models/assetRenditions/AssetRendition.ts';
import { EmptyAsset, IAsset } from '../../../models/assets/Asset.ts';

export type ILoadAssetsAction = (
  assetIds: ReadonlyArray<AssetReference>,
  abortSignal?: AbortSignal,
) => ThunkPromise;

interface ILoadAssetsActionDependency {
  readonly assetService: IAssetService;
  readonly assetRenditionRepository: Pick<IAssetRenditionRepository, 'getRenditionsByIds'>;
}

export const assetsLoaded = (
  assets: ReadonlyArray<IAsset>,
  renditions: ReadonlyArray<IAssetRendition>,
) =>
  ({
    type: Data_Assets_Loaded,
    payload: {
      assets,
      renditions,
    },
  }) as const;

export type LoadAssetsActionsType = ReturnType<typeof assetsLoaded>;

export const loadAssetsActionCreator =
  (dependency: ILoadAssetsActionDependency): ILoadAssetsAction =>
  (assetReferences, abortSignal): ThunkPromise =>
  async (dispatch) => {
    if (assetReferences.length === 0) {
      return;
    }

    const assetIds = assetReferences.map((assetReference) => assetReference.id);
    const assetsPromise = dependency.assetService.getAssetsByIds(assetIds, abortSignal);

    const renditionIdsByAssetId = Collection.addMany(
      new Map<Uuid, ReadonlySet<Uuid>>(),
      assetReferences.map((assetReference) => [
        assetReference.id,
        new Set(assetReference.renditions.map((renditionReference) => renditionReference.id)),
      ]),
      (a, b) => new Set([...a, ...b]),
    );
    const renditionsResponse = await dependency.assetRenditionRepository.getRenditionsByIds(
      renditionIdsByAssetId,
      abortSignal,
    );
    const renditions = renditionsResponse.data.map(mapAssetRenditionFromServerModel);

    const existingAssets = await assetsPromise;

    // it is possible to insert assets that do not exist by external ids in CM API,
    // we do not want to remove the references because they may be imported later
    const missingAssets = Immutable.Set.of(...assetIds)
      .subtract(existingAssets.map((asset) => asset.id))
      .map(
        (id: Uuid): IAsset => ({
          ...EmptyAsset,
          id,
        }),
      )
      .toArray();

    dispatch(assetsLoaded([...existingAssets, ...missingAssets], renditions));
  };
