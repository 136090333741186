import PropTypes from 'prop-types';
import React from 'react';
import { RestoreRevisionButton } from '../../../containers/StatusBar/revisions/RestoreRevisionButton.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';

type ContentItemCompareRevisionTitleProps = {
  readonly canRestoreRevision: boolean;
};

const propTypes: PropTypesShape<ContentItemCompareRevisionTitleProps> = {
  canRestoreRevision: PropTypes.bool.isRequired,
};

export const ContentItemCompareRevisionTitle: React.FC<ContentItemCompareRevisionTitleProps> = ({
  canRestoreRevision,
}) => (
  <>
    <div className="status-bar__title-pane">
      <h3 className="status-bar__title" {...getDataUiElementAttribute(DataUiElement.RevisionTitle)}>
        Comparing selected versions
      </h3>
    </div>
    {canRestoreRevision && <RestoreRevisionButton>Restore older version</RestoreRevisionButton>}
  </>
);

ContentItemCompareRevisionTitle.propTypes = propTypes;
ContentItemCompareRevisionTitle.displayName = 'ContentItemCompareRevisionTitle';
