import { Box } from '@kontent-ai/component-library/Box';
import { InputState } from '@kontent-ai/component-library/Input';
import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { colorAlertBackgroundInverse } from '@kontent-ai/component-library/tokens';
import PropTypes from 'prop-types';
import React from 'react';
import { DefaultTag } from '../../../../../../component-library/components/Tag/DefaultTag.tsx';
import { Tag } from '../../../../../../component-library/components/Tag/Tag.tsx';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export type CollectionOption = {
  readonly id: Uuid;
  readonly label: string;
  readonly isArchived: boolean;
};

const collectionOptionPropTypes: PropTypeMap<CollectionOption> = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isArchived: PropTypes.bool.isRequired,
};

interface ICollectionsLimitationsProps {
  readonly allCollections: ReadonlyArray<CollectionOption>;
  readonly isDisabled: boolean;
  readonly label?: string;
  readonly onSelectedCollectionsChanged: (options: ReadonlySet<Uuid>) => void;
  readonly selectedCollectionsIds: ReadonlySet<Uuid>;
  readonly tooltipText?: string;
}

const propTypes: PropTypeMap<ICollectionsLimitationsProps> = {
  allCollections: PropTypes.arrayOf(PropTypes.shape(collectionOptionPropTypes).isRequired)
    .isRequired,
  isDisabled: PropTypes.bool.isRequired,
  label: PropTypes.string,
  onSelectedCollectionsChanged: PropTypes.func.isRequired,
  selectedCollectionsIds: PropTypes.instanceOf<ReadonlySet<Uuid>>(Set).isRequired,
  tooltipText: PropTypes.string,
};

const anyCollectionLabel: string = 'Any collection';

export const CollectionsLimitations: React.FC<ICollectionsLimitationsProps> = ({
  allCollections,
  isDisabled,
  label,
  onSelectedCollectionsChanged,
  selectedCollectionsIds,
  tooltipText,
}) => (
  <Box>
    <MultiSelect<CollectionOption>
      inputState={isDisabled ? InputState.Disabled : InputState.Default}
      items={allCollections}
      label={label}
      onSelectionChange={onSelectedCollectionsChanged}
      placeholder={anyCollectionLabel}
      placeholderType="tag"
      renderMenuOption={(optionProps) => (optionProps.item.value?.isArchived ? '' : undefined)}
      renderSelectedOption={(_id, selectedItem, defaultTagProps) =>
        selectedItem.isArchived ? (
          <Tag background={colorAlertBackgroundInverse} {...defaultTagProps} />
        ) : (
          <DefaultTag {...defaultTagProps} />
        )
      }
      selectedItemIds={selectedCollectionsIds}
      tooltipText={tooltipText}
      {...getDataUiCollectionAttribute(DataUiCollection.ContentCollections)}
    />
  </Box>
);

CollectionsLimitations.displayName = 'CollectionsLimitations';
CollectionsLimitations.propTypes = propTypes;
