import React from 'react';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import { ControlAltShortcutTemplate } from '../../../../../_shared/constants/shortcutSymbols.ts';
import {
  DataUiRteAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IForwardedRefProps, forwardRef } from '../../../../../_shared/utils/forwardedRefProps.tsx';
import {
  ISpecificButtonProps,
  RTEToolbarIconButton,
  specificButtonPropTypes,
} from '../../toolbars/components/RTEToolbarButton.tsx';

const AddCommentButtonComponent: React.FC<
  React.PropsWithChildren<ISpecificButtonProps & IForwardedRefProps<HTMLButtonElement>>
> = (props) => (
  <RTEToolbarIconButton
    {...props}
    iconName={IconName.Bubble}
    ref={props.forwardedRef}
    shortcut={ControlAltShortcutTemplate('M')}
    tooltipPlacement={props.tooltipPlacement ?? 'bottom-start'}
    tooltipText={props.tooltipText ?? 'Add comment'}
    {...getDataUiActionAttribute(DataUiRteAction.AddComment)}
  />
);

AddCommentButtonComponent.displayName = 'AddCommentButton';
AddCommentButtonComponent.propTypes = specificButtonPropTypes;

export const AddCommentButton = forwardRef(AddCommentButtonComponent);
