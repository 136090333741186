import { colorSuccessIcon } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { ContentItemId } from '../../../../../_shared/models/ContentItemId.type.ts';
import { getContentItemPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { HorizontalProgressBar } from '../../shared/components/HorizontalProgressBar.tsx';
import {
  StyledDataTableCell,
  StyledDataTableRow,
} from '../../shared/components/StyledDataTable.tsx';

interface IUnchangedPublishedItemsWidgetRowProps {
  readonly id: ContentItemId;
  readonly itemName: string;
  readonly longestItemStaleness: number;
  readonly stalenessDaysCount: number;
  readonly useRowLinks: boolean;
}

export const UnchangedPublishedItemsWidgetRow: React.FC<IUnchangedPublishedItemsWidgetRowProps> = ({
  id,
  itemName,
  longestItemStaleness,
  stalenessDaysCount,
  useRowLinks,
}) => {
  const pathToItem = useRowLinks
    ? getContentItemPath(window.location.pathname, id.itemId, id.variantId)
    : null;

  return (
    <StyledDataTableRow key={id.itemId} dataUiObjectName={id.itemId} id={id.itemId}>
      <StyledDataTableCell linkPath={pathToItem}>{itemName}</StyledDataTableCell>
      <StyledDataTableCell linkPath={pathToItem}>
        <HorizontalProgressBar
          $color={colorSuccessIcon}
          total={longestItemStaleness}
          value={stalenessDaysCount}
          itemTypeName="day"
        />
      </StyledDataTableCell>
    </StyledDataTableRow>
  );
};

UnchangedPublishedItemsWidgetRow.displayName = 'UnchangedPublishedItemsWidgetRow';
