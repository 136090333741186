import Immutable from 'immutable';
import { Action } from '../../../@types/Action.type.ts';
import { ContextualHelpType } from '../../../data/models/contextualHelp/ContextualHelpTypeEnum.ts';
import { OnboardingNotification } from '../../../data/models/user/OnboardingNotification.ts';
import { UserPropertyServerModel } from '../../../repositories/serverModels/UserPropertyServerModel.type.ts';
import { ProjectOrderBy } from '../../constants/projectOrderBy.ts';
import {
  Shared_EmailNotifications_Changed,
  Shared_EmailNotifications_Saving,
  Shared_UserProperties_LoadingFinished,
  Shared_UserProperties_UpsertStarted,
} from '../../constants/sharedActionTypes.ts';
import { BusinessRole } from '../../models/BusinessRole.ts';
import { BusinessType } from '../../models/BusinessType.ts';
import {
  UserPropertiesModel,
  UserPropertiesStateKeysByServerKeys,
  UserPropertyServerKey,
} from '../../models/UserPropertiesModel.ts';
import { resolvePropertyValue } from '../../utils/userPropertiesUtils.ts';

const initialState: UserPropertiesModel = {
  aiFeatureSet: [],
  aiFeedbackTimestamps: {
    answeredAt: null,
    dismissedAt: null,
    displayedAt: null,
  },
  businessRole: BusinessRole.NotProvided,
  businessType: BusinessType.NotProvided,
  commentThreadsOnRemovedContentOnboardingShownAt: '',
  companyName: '',
  contentModelingFeedback: {
    answeredAt: null,
    dismissedAt: null,
    displayedAt: null,
  },
  contextualHelpSettings: Immutable.Map<ContextualHelpType, boolean>(),
  discoverCommentsDialogDisplayedAt: '',
  dismissedCollectionsPermissionsRoleBuilderPopover: false,
  dismissedNewCollectionsAppInSettingsOnboarding: false,
  dismissedNewWebhooksOnboarding: false,
  dismissedOldCollectionsAppInSettingsOnboarding: false,
  dismissedPossiblyIncorrectPlacementWarning: false,
  dismissedProjectSettingsCollectionsBadge: false,
  emailNotificationAssignmentDisabled: 'false',
  emailNotificationCommentsDisabled: 'false',
  emailNotificationMentionsDisabled: 'false',
  emailNotificationReportsWithZeroPriceDisabled: 'false',
  emailNotificationTasksDisabled: 'false',
  emailNotificationWebhookNotificationsDisabled: 'false',
  emailNotificationsBeingChanged: false,
  emailNotificationsBeingSaved: false,
  filledBusinessRole: '',
  hasCreatedSeventhOrMoreWorkflowStep: false,
  intercomMoveInfoBubbleDismissedAt: undefined,
  isAzureMarketplaceUser: false,
  isSentryBugReportWidgetEnabled: false,
  longProcessingChangesWarningDismissed: false,
  onboardingNotificationsSettings: Immutable.Map<OnboardingNotification, boolean>(),
  phoneNumber: '',
  prefersClosedEditingSidebar: false,
  productUpdateOpenedAt: '',
  projectOrderBy: ProjectOrderBy.ProjectName,
  signupReason: '',
  subscribedToNewsletterAt: '',
  upsellMultipleWorkflowsAfterCreatingStepDismissed: false,
  upsellMultipleWorkflowsInitialDismissed: false,
  userFeedback: {
    answeredAt: null,
    dismissedAt: null,
    displayedAt: null,
  },
  webSpotlightTreeCollapsed: false,
};

const isValidUserPropName = (propName: string): propName is keyof UserPropertiesModel =>
  Object.keys(initialState).includes(propName);

const mapPropertyKeyAndValueToState = (
  state: UserPropertiesModel,
  propertyKey: UserPropertyServerKey,
  propertyValue: string,
): UserPropertiesModel => {
  const propName: string | undefined = UserPropertiesStateKeysByServerKeys[propertyKey];
  if (!propName || !isValidUserPropName(propName)) {
    return state;
  }

  return {
    ...state,
    [propName]: resolvePropertyValue(propName, propertyValue) ?? initialState[propName],
  };
};

const mapPropertyToState = (
  state: UserPropertiesModel,
  property: UserPropertyServerModel,
): UserPropertiesModel => mapPropertyKeyAndValueToState(state, property.key, property.value);

export const userProperties = (state = initialState, action: Action): UserPropertiesModel => {
  switch (action.type) {
    case Shared_UserProperties_LoadingFinished: {
      return action.payload.properties.reduce(mapPropertyToState, initialState);
    }

    case Shared_UserProperties_UpsertStarted: {
      return mapPropertyKeyAndValueToState(
        state,
        action.payload.propertyKey,
        action.payload.propertyValue,
      );
    }

    case Shared_EmailNotifications_Changed: {
      return {
        ...state,
        emailNotificationsBeingChanged: action.payload.changed,
      };
    }

    case Shared_EmailNotifications_Saving: {
      return {
        ...state,
        emailNotificationsBeingSaved: action.payload.saving,
      };
    }

    default:
      return state;
  }
};
