import { Card } from '@kontent-ai/component-library/Card';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { HtmlSettingsPageTitle } from '../../../../_shared/components/HtmlSettingsPageTitle.tsx';
import { PageTitle } from '../../../../_shared/components/PageTitle.tsx';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { CollectionsMap } from '../../../../data/models/collections/Collection.ts';
import { EnvironmentSettingsAppNames } from '../../root/constants/EnvironmentSettingsAppNames.ts';
import { CollectionsFilter } from './CollectionsFilter.tsx';
import { CollectionsListing } from './CollectionsListing.tsx';
import { CollectionsQuickTip } from './CollectionsQuickTip.tsx';

type CollectionsAppProps = {
  readonly collectionIds: ReadonlyArray<Uuid>;
  readonly collectionsMap: CollectionsMap;
  readonly showSpaces: boolean;
};

export const CollectionsApp: React.FC<CollectionsAppProps> = (props) => (
  <div className="canvas__inner-section">
    <HtmlSettingsPageTitle settingsAppName={EnvironmentSettingsAppNames.Collections} />
    <Stack spacing={Spacing.XL}>
      <PageTitle>{EnvironmentSettingsAppNames.Collections}</PageTitle>
      <Row spacing={Spacing.L}>
        <Column flexFactor={5} flexBasis={600} maxWidth={1000 + Spacing.L}>
          <Card
            component="section"
            cardLabel={EnvironmentSettingsAppNames.Collections}
            {...getDataUiAppNameAttribute(DataUiAppName.ContentCollections)}
          >
            <Card.Body>
              <Stack spacing={Spacing.XL}>
                <CollectionsFilter />
                <CollectionsListing
                  collectionIds={props.collectionIds}
                  collectionsMap={props.collectionsMap}
                />
              </Stack>
            </Card.Body>
          </Card>
        </Column>
        <Column flexFactor={1} flexBasis={250}>
          <CollectionsQuickTip showSpaces={props.showSpaces} />
        </Column>
      </Row>
    </Stack>
  </div>
);

CollectionsApp.displayName = 'CollectionsApp';
