import { memoize } from '@kontent-ai/memoization';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { EditedContentItemVariant } from '../../../../models/contentItem/edited/EditedContentItemVariant.ts';
import { EditingAction } from '../../models/EditingAction.ts';
import {
  isAssignPeopleAvailable,
  isChangeDueDateAvailable,
  isChangeNoteAvailable,
  isChangeWorkflowAvailable,
  isChangeWorkflowStepAvailable,
  isPublishAvailable,
  isSetDueDateAvailable,
  isSetNoteAvailable,
} from './availabilityUtils/areAssignmentChangesAvailable.ts';
import {
  isCloseInlineCommentsAvailable,
  isOpenInlineCommentsAvailable,
  isOpenResolvedCommentsAvailable,
} from './availabilityUtils/areCommentsAvailable.ts';
import {
  isCloseComponentsAvailable,
  isOpenComponentsAvailable,
} from './availabilityUtils/areComponentsAvailable.ts';
import {
  isDeleteItemAvailable,
  isPublishPreventsDeletingItemAvailable,
  isWebSpotlightPreventsDeletingItemAvailable,
} from './availabilityUtils/areDeletesAvailable.ts';
import {
  isDuplicateItemAvailable,
  isDuplicateItemWithContentAvailable,
  isDuplicateItemWithoutContentAvailable,
} from './availabilityUtils/areDuplicationsAvailable.ts';
import {
  isDisabledPreviewItemAvailable,
  isPreviewItemAvailable,
  isPreviewItemInMultipleSpacesAvailable,
  isPreviewSampleAppItemAvailable,
  isSetupItemPreviewAvailable,
} from './availabilityUtils/arePreviewsAvailable.ts';
import { isChangeCollectionAvailable } from './availabilityUtils/isChangeCollectionAvailable.ts';
import { isCopyFromLanguageAvailable } from './availabilityUtils/isCopyFromLanguageAvailable.ts';
import { isDiscardNewVersionAvailable } from './availabilityUtils/isDiscardNewVersionAvailable.ts';
import {
  isVariantTranslationDisabled,
  isVariantTranslationEnabled,
} from './availabilityUtils/isVariantTranslationEnabled.ts';
import { isViewCodenameAvailable } from './availabilityUtils/isViewCodenameAvailable.ts';

export type ContentItemEditingActionAvailabilityProvider = (
  state: IStore,
  editedContentItemVariant: EditedContentItemVariant,
  currentPath: string,
) => boolean;

type ActionsAvailabilityMap = {
  readonly [key in EditingAction]: ContentItemEditingActionAvailabilityProvider;
};

const isAlwaysAvailable = () => true;
const isNeverAvailable = () => false;

const actionsAvailabilityMap: ActionsAvailabilityMap = {
  [EditingAction.addTask]: isAlwaysAvailable,
  [EditingAction.assignPeople]: isAssignPeopleAvailable,
  [EditingAction.changeCollection]: isChangeCollectionAvailable,
  [EditingAction.changeDueDate]: isChangeDueDateAvailable,
  [EditingAction.changeNote]: isChangeNoteAvailable,
  [EditingAction.changeStep]: isChangeWorkflowStepAvailable,
  [EditingAction.changeWorkflow]: isChangeWorkflowAvailable,
  [EditingAction.closeComponents]: isCloseComponentsAvailable,
  [EditingAction.closeInlineComments]: isCloseInlineCommentsAvailable,
  [EditingAction.compareVersions]: isAlwaysAvailable,
  [EditingAction.copyFromLanguage]: isCopyFromLanguageAvailable,
  [EditingAction.deleteItem]: isDeleteItemAvailable,
  [EditingAction.demonstrateDisabledPreviewItem]: isDisabledPreviewItemAvailable,
  [EditingAction.demonstratePublishPreventsDeletingItem]: isPublishPreventsDeletingItemAvailable,
  [EditingAction.demonstrateWebSpotlightPreventsDeletingItem]:
    isWebSpotlightPreventsDeletingItemAvailable,
  [EditingAction.disabledTranslateVariant]: isVariantTranslationDisabled,
  [EditingAction.discardNewVersion]: isDiscardNewVersionAvailable,
  [EditingAction.duplicateItemWithContent]: isDuplicateItemWithContentAvailable,
  [EditingAction.duplicateItemWithoutContent]: isDuplicateItemWithoutContentAvailable,
  [EditingAction.none]: isNeverAvailable,
  [EditingAction.openComponents]: isOpenComponentsAvailable,
  [EditingAction.openDiscussions]: isAlwaysAvailable,
  [EditingAction.openInlineComments]: isOpenInlineCommentsAvailable,
  [EditingAction.openResolvedComments]: isOpenResolvedCommentsAvailable,
  [EditingAction.previewItem]: isPreviewItemAvailable,
  [EditingAction.previewItemInMultipleSpaces]: isPreviewItemInMultipleSpacesAvailable,
  [EditingAction.previewSampleAppItem]: isPreviewSampleAppItemAvailable,
  [EditingAction.publishItem]: isPublishAvailable,
  [EditingAction.setDueDate]: isSetDueDateAvailable,
  [EditingAction.setNote]: isSetNoteAvailable,
  [EditingAction.setupItemPreview]: isSetupItemPreviewAvailable,
  [EditingAction.shareLink]: isAlwaysAvailable,
  [EditingAction.showDuplicationOptions]: isDuplicateItemAvailable,
  [EditingAction.showMainMenu]: isAlwaysAvailable,
  [EditingAction.translateVariant]: isVariantTranslationEnabled,
  [EditingAction.viewCodename]: isViewCodenameAvailable,
};

const memoizeLatestActions = memoize.maxNWithTransformedArgs(
  (actions: ReadonlyArray<EditingAction>): ReadonlyArray<EditingAction> => actions,
  (args) => args[0],
  1,
);

export const getAvailableContentItemEditingActions = (
  state: IStore,
  currentPath: string,
): readonly EditingAction[] => {
  const variant = state.contentApp.editedContentItemVariant;

  if (!variant) {
    return [];
  }

  const availableActions = Object.keys(actionsAvailabilityMap)
    .filter((action) => actionsAvailabilityMap[action](state, variant, currentPath))
    .map((action) => EditingAction[action]);

  return memoizeLatestActions(availableActions);
};
