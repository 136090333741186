import PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';
import { NumberValue } from '../../../../../_shared/models/NumberValue.ts';
import {
  INumberTypeElement,
  NumberTypeElementPropTypes,
} from '../../../../contentInventory/content/models/contentTypeElements/NumberTypeElement.ts';
import {
  INumberItemElement,
  NumberItemElementPropTypes,
} from '../../../models/contentItemElements/NumberItemElement.ts';
import { NumberInput } from '../../ContentItemEditing/components/NumberInput.tsx';
import { ReadonlyEmptyElementPlaceholder } from '../../ContentItemEditing/models/ReadonlyEmptyElementPlaceholder.ts';
import { IContentComponentItemElementOwnProps } from '../IContentComponentItemElementOwnProps.type.ts';
import { ContentComponentItemElement } from '../containers/ContentComponentItemElement.tsx';

export interface IContentComponentNumberElementStateProps {
  readonly showError?: boolean;
}

export interface IContentComponentNumberElementOwnProps
  extends IContentComponentItemElementOwnProps<INumberItemElement, INumberTypeElement> {}

interface INumberElementProps
  extends IContentComponentNumberElementStateProps,
    IContentComponentNumberElementOwnProps {}

const propTypes: PropTypeMap<INumberElementProps> = {
  showError: PropTypes.bool,
  elementData: NumberItemElementPropTypes.isRequired,
  typeElement: NumberTypeElementPropTypes.isRequired,
  disabled: PropTypes.bool.isRequired,
  autoFocus: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  validationResultSelectorId: PropTypes.string.isRequired,
  contentComponentId: PropTypes.string.isRequired,
};

export const ContentComponentNumberElement: React.FC<INumberElementProps> = ({
  elementData,
  typeElement,
  disabled,
  autoFocus,
  validationResultSelectorId,
  showError,
  contentComponentId,
  onUpdate,
}) => {
  const numberInputRef = useRef<HTMLInputElement>(null);

  const onChange = useCallback(
    (number: NumberValue): void => {
      onUpdate({
        ...elementData,
        value: number.value,
        _number: number,
      });
    },
    [elementData, onUpdate],
  );

  const handleFocusInput = useCallback(() => numberInputRef.current?.focus(), []);

  return (
    <ContentComponentItemElement
      contentComponentId={contentComponentId}
      disabled={disabled}
      onHeaderClick={handleFocusInput}
      onContentClick={handleFocusInput}
      isCompact
      typeElement={typeElement}
      validationResultSelectorId={validationResultSelectorId}
    >
      <NumberInput
        ref={numberInputRef}
        autoFocus={autoFocus}
        className="content-item-element__text-field"
        disabled={disabled}
        isValid={!showError}
        onChange={onChange}
        placeholder={disabled ? ReadonlyEmptyElementPlaceholder.NumberElement : 'Enter a number…'}
        typeElement={typeElement}
        value={elementData.value}
        defaultValue={typeElement.defaultValue}
      />
    </ContentComponentItemElement>
  );
};

ContentComponentNumberElement.displayName = 'ContentComponentNumberElement';
ContentComponentNumberElement.propTypes = propTypes;
