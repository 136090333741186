import { History } from 'history';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { AssignmentSections } from '../../constants/AssignmentSections.ts';
import { ContentItemSidebarEditableSection } from '../../constants/ContentItemSidebarEditableSection.ts';
import { createUpdateContentItemEditingSidebarSectionActions } from './createUpdateContentItemEditingSidebarSection.ts';
import { IUpdateAssignmentSectionParams } from './updateAssignment.ts';

interface IDeps {
  readonly updateAssignmentSection: (args: IUpdateAssignmentSectionParams) => ThunkPromise;
}

const { startSidebarSectionUpdate, finishSidebarSectionUpdate } =
  createUpdateContentItemEditingSidebarSectionActions(ContentItemSidebarEditableSection.DueDate);

type Parameters = {
  readonly actionOrigin: ContentItemEditingEventOrigins;
  readonly dueDate: DateTimeStamp;
  readonly deps: IDeps;
  readonly history: History;
};

const updateDueDate =
  ({ actionOrigin, dueDate, deps, history }: Parameters): ThunkPromise =>
  async (dispatch) => {
    await dispatch(
      deps.updateAssignmentSection({
        actionOrigin,
        history,
        getUpdatedAssignment: () => ({
          due: dueDate,
        }),
        submittingSection: AssignmentSections.DueDate,
      }),
    );
  };

export const createUpdateDueDateInSidebar =
  (deps: IDeps) =>
  (history: History, dueDate: DateTimeStamp): ThunkPromise =>
  async (dispatch) => {
    dispatch(startSidebarSectionUpdate);

    await dispatch(
      updateDueDate({
        actionOrigin: ContentItemEditingEventOrigins.ItemDetails,
        dueDate,
        deps,
        history,
      }),
    );

    dispatch(finishSidebarSectionUpdate);
  };

export const createUpdateDueDate =
  (deps: IDeps) =>
  (
    history: History,
    dueDate: DateTimeStamp,
    actionOrigin: ContentItemEditingEventOrigins,
  ): ThunkPromise =>
  async (dispatch) =>
    await dispatch(updateDueDate({ actionOrigin, dueDate, deps, history }));
