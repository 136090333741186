import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { EditorState } from 'draft-js';
import PropTypes from 'prop-types';
import React, { ChangeEvent, FormEvent } from 'react';
import { HotkeysHandler } from '../../../../../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import {
  ControlShortcutTemplate,
  ShortcutSymbols,
} from '../../../../../../../_shared/constants/shortcutSymbols.ts';
import { Button, ButtonSize } from '../../../../../../../_shared/uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import { LabelFor } from '../../../../../../../_shared/uiComponents/LabelFor/LabelFor.tsx';
import {
  DataUiCommentsAction,
  getDataUiCommentActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { isEmptyOrWhitespace } from '../../../../../../../_shared/utils/stringUtils.ts';
import { IFocusable } from '../../../../../../richText/plugins/behavior/FocusPlugin.tsx';
import {
  DebouncedChanges,
  EditorChangeCallback,
} from '../../../../../../richText/plugins/behavior/OnChangePlugin.tsx';
import { emptyContentState } from '../../../../../../richText/utils/general/editorEmptyValues.ts';
import { isComment } from '../../../../../models/comments/Comment.ts';
import {
  CommentThreadItemType,
  ICommentThreadItem,
  ICommentThreadItemContentModel,
} from '../../../../../models/comments/CommentThreadItem.ts';
import { isSuggestion } from '../../../../../models/comments/Suggestion.ts';
import { CommentInput } from '../../../containers/comments/input/CommentInput.tsx';
import { SuggestionInput } from '../input/SuggestionInput.tsx';

interface ICommentThreadItemEditorCallbackProps {
  readonly onBlur?: (isCommentPending: boolean) => void;
  readonly onCancel: () => void;
  readonly onFocus?: () => void;
  readonly onSubmit: (content: ICommentThreadItemContentModel) => Promise<void>;
}

interface ICommentThreadItemEditorDataProps {
  readonly areButtonsDisplayed: boolean;
  readonly commentThreadItem: ICommentThreadItem;
  readonly isButtonDisabled: boolean;
  readonly isEditing: boolean;
  readonly isInputDisabled: boolean;
  readonly submitButtonText: string;
}

interface ICommentThreadItemEditorState {
  readonly lastKnownHasFocus: boolean;
  readonly commentContent: EditorState;
  readonly suggestionText: string;
}

type CommentThreadItemEditorProps = ICommentThreadItemEditorCallbackProps &
  ICommentThreadItemEditorDataProps;

export class CommentThreadItemEditor extends React.PureComponent<
  CommentThreadItemEditorProps,
  ICommentThreadItemEditorState
> {
  static displayName = 'CommentThreadItemEditor';

  static propTypes: PropTypesShape<CommentThreadItemEditorProps> = {
    areButtonsDisplayed: PropTypes.bool,
    commentThreadItem: PropTypes.object.isRequired,
    isButtonDisabled: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    isInputDisabled: PropTypes.bool.isRequired,
    submitButtonText: PropTypes.string.isRequired,

    onBlur: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
  };

  readonly state: ICommentThreadItemEditorState = {
    lastKnownHasFocus: false,
    commentContent: EditorState.createWithContent(
      isComment(this.props.commentThreadItem)
        ? this.props.commentThreadItem.content
        : emptyContentState,
    ),
    suggestionText: isSuggestion(this.props.commentThreadItem)
      ? this.props.commentThreadItem.suggestedText
      : '',
  };

  private readonly focusableRef = React.createRef<IFocusable>();
  private readonly suggestionRefObj = React.createRef<SuggestionInput>();
  private readonly debouncedChangesRef = React.createRef<DebouncedChanges>();

  private readonly isSuggestion = () => isSuggestion(this.props.commentThreadItem);

  componentDidMount(): void {
    this.onFocus();
  }

  componentDidUpdate(prevProps: CommentThreadItemEditorProps): void {
    if (!prevProps.isEditing && this.props.isEditing) {
      this.onFocus();
    }
  }

  private readonly onFocus = (): void => {
    this.props.onFocus?.();

    if (this.isSuggestion()) {
      this.suggestionRefObj.current?.focus();
    } else {
      this.focusableRef.current?.focus();
    }
  };

  private readonly isTextValid = (): boolean => {
    if (this.isSuggestion()) {
      return !isEmptyOrWhitespace(this.state.suggestionText);
    }

    return !isEmptyOrWhitespace(this.state.commentContent?.getCurrentContent().getPlainText());
  };

  private readonly isSubmitButtonDisabled = (): boolean => {
    return !this.isTextValid() || this.props.isButtonDisabled;
  };

  private readonly onSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.stopPropagation();
    event.preventDefault();

    this.handleSubmit();
  };

  private readonly onSuggestionChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    const inputValue = event.target.value;
    this.setState(() => ({ suggestionText: inputValue }));
  };

  private readonly onCommentChange: EditorChangeCallback = (editorState) => {
    this.setState(() => ({ commentContent: editorState }));
    return Promise.resolve();
  };

  private readonly onCancelShortcut = (): void => {
    this.cancelEditing();
    this.onBlur();
  };

  private readonly handleSubmit = (): void => {
    if (this.isTextValid()) {
      this.submit();
      this.onBlur();
    }
  };

  private readonly submit = async (): Promise<void> => {
    await this.debouncedChangesRef.current?.propagatePendingContentChanges();
    const content = this.isSuggestion()
      ? {
          type: CommentThreadItemType.Suggestion,
          suggestedText: this.state.suggestionText,
        }
      : {
          type: CommentThreadItemType.Comment,
          content: this.state.commentContent.getCurrentContent(),
        };
    await this.props.onSubmit(content);
  };

  private readonly cancelEditing = (): void => {
    this.props.onCancel();
  };

  private readonly onBlur = (): void => {
    if (this.props.onBlur) {
      if (this.isSuggestion()) {
        this.props.onBlur(!this.state.suggestionText);
      } else {
        this.props.onBlur(
          !!this.state.commentContent &&
            !this.state.commentContent.getCurrentContent().getPlainText(),
        );
      }
    }
  };

  private readonly onCommentSelectionChange: EditorChangeCallback = (editorState) => {
    const hasFocus = editorState.getSelection().getHasFocus();
    if (this.state.lastKnownHasFocus !== hasFocus) {
      this.setState(
        () => ({ lastKnownHasFocus: hasFocus }),
        () => {
          if (!hasFocus) {
            this.onBlur();
          }
        },
      );
    }
    return Promise.resolve();
  };

  render(): JSX.Element {
    const { isInputDisabled, areButtonsDisplayed, submitButtonText } = this.props;

    return (
      <form className="form comment-editor__form" onSubmit={this.onSubmit}>
        <div className="comment-editor__guideline">
          {this.isSuggestion() ? 'Editing suggested changes:' : 'Editing comment:'}
        </div>
        <LabelFor
          isHidden
          target={(id: Uuid) => (
            <HotkeysHandler
              handlers={{
                onControlEnter: this.handleSubmit,
              }}
            >
              {this.isSuggestion() ? (
                <SuggestionInput
                  ref={this.suggestionRefObj}
                  inputId={id}
                  inputValue={this.state.suggestionText}
                  onChange={this.onSuggestionChange}
                  onEscape={this.onCancelShortcut}
                />
              ) : (
                <CommentInput
                  debouncedChangesRef={this.debouncedChangesRef}
                  disabled={isInputDisabled}
                  editorState={this.state.commentContent}
                  focusableRef={this.focusableRef}
                  onContentChange={this.onCommentChange}
                  onEscape={this.onCancelShortcut}
                  onSelectionChange={this.onCommentSelectionChange}
                />
              )}
            </HotkeysHandler>
          )}
        >
          {this.isSuggestion() ? 'Suggestion' : 'Comment'}
        </LabelFor>
        {areButtonsDisplayed && (
          <div className="comment-editor__actions">
            <Tooltip
              tooltipText="Cancel editing"
              placement="bottom-end"
              shortcuts={ShortcutSymbols.Escape}
            >
              <Button
                style={ButtonStyle.Secondary}
                size={ButtonSize.S}
                disabled={isInputDisabled}
                onClick={isInputDisabled ? undefined : this.cancelEditing}
                {...getDataUiCommentActionAttribute(DataUiCommentsAction.Cancel)}
              >
                Cancel
              </Button>
            </Tooltip>
            <Tooltip
              tooltipText={this.isSubmitButtonDisabled() ? undefined : 'Save changes'}
              placement="bottom-end"
              shortcuts={ControlShortcutTemplate(ShortcutSymbols.Enter)}
            >
              <Button
                style={ButtonStyle.Primary}
                size={ButtonSize.S}
                type="submit"
                disabled={this.isSubmitButtonDisabled()}
                {...getDataUiCommentActionAttribute(DataUiCommentsAction.SaveComment)}
              >
                {submitButtonText}
              </Button>
            </Tooltip>
          </div>
        )}
      </form>
    );
  }
}
