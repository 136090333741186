import {
  Dispatch,
  GetState,
  ThunkFunction,
  ThunkPromise,
} from '../../../../../@types/Dispatcher.type.ts';
import { onboardingNotificationTriggered } from '../../../../../_shared/actions/thunkSharedActions.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import {
  TrackUserEventWithData,
  TrackUserEventWithDataAction,
} from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { IContentTypeModifiedEventData } from '../../../../../_shared/models/TrackUserEventData.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { getFirstCollectionAndLanguageForContentTypeAndCurrentUser } from '../../../../../_shared/utils/permissions/getFirstCollectionAndLanguageForContentTypeAndCurrentUser.ts';
import {
  convertContentTypeToServerModel,
  getContentTypeFromServerModel,
} from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { OnboardingNotification } from '../../../../../data/models/user/OnboardingNotification.ts';
import { ContentTypeServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import {
  saveEditedTypeStarted,
  typeEditorSaveAttempted,
} from '../../../shared/actions/sharedContentModelsActions.ts';
import { IBaseTypeElementData } from '../../../shared/models/elements/types/TypeElementData.ts';
import { getContentTypeConversionOptions } from '../../../shared/selectors/contentTypeElementSelector.ts';
import { createActiveConditionIds } from '../../../shared/utils/conditionUtils.ts';
import { ITrackAssetLimitConfigurationChanged } from '../../../shared/utils/intercomHelpers/trackAssetLimitsUtils.ts';
import { ITrackCustomTypeElementUpserted } from '../../../shared/utils/intercomHelpers/trackCustomTypeElementConfig.ts';
import { isTypeValid } from '../../../shared/utils/typeValidationUtils.ts';
import {
  contentTypeInvalidElementShowWarning,
  saveEditedContentTypeFinished,
} from '../contentTypesActions.ts';

interface IDeps {
  readonly contentTypeRepository: {
    readonly updateContentType: (
      contentType: ContentTypeServerModel,
    ) => Promise<ContentTypeServerModel>;
  };
  readonly getContentTypeModifiedEventData: (
    typeId: Uuid,
    elements: ReadonlyArray<IBaseTypeElementData>,
    originalElements: ReadonlyArray<IBaseTypeElementData>,
  ) => IContentTypeModifiedEventData;
  readonly loadContentTypesData: () => ThunkPromise;
  readonly saveEditedMultipleChoiceOption: () => ThunkFunction;
  readonly trackAssetLimitConfigurationChanged: ITrackAssetLimitConfigurationChanged;
  readonly trackCustomTypeElementUpserted: ITrackCustomTypeElementUpserted;
  readonly trackUserEventWithData: TrackUserEventWithDataAction;
  readonly validateTypeElement: (updatedTypeElement: IBaseTypeElementData) => ThunkFunction;
}

export const createSaveContentTypeAction =
  (deps: IDeps) =>
  (onSuccess?: () => void, onFail?: () => void): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    dispatch(deps.saveEditedMultipleChoiceOption());

    const {
      sharedApp: { currentProjectId },
      contentModelsApp: {
        contentTypes: {
          data: { originalContentType },
        },
        typeEditor: { editedType },
      },
      data: { user, languages, collections },
    } = getState();

    dispatch(typeEditorSaveAttempted(createActiveConditionIds(editedType)));

    editedType.typeElements.forEach((element) => dispatch(deps.validateTypeElement(element)));

    if (!isTypeValid(getState().contentModelsApp.typeEditor.validationResults)) {
      dispatch(contentTypeInvalidElementShowWarning());
      if (onFail) {
        onFail();
      }
      return;
    }

    dispatch(saveEditedTypeStarted());
    const contentTypeForServer = convertContentTypeToServerModel(editedType);
    const updatedContentTypeServerModel =
      await deps.contentTypeRepository.updateContentType(contentTypeForServer);
    const updatedContentType = getContentTypeFromServerModel(
      updatedContentTypeServerModel,
      getContentTypeConversionOptions(getState()),
    );
    const { languageId, collectionId } = getFirstCollectionAndLanguageForContentTypeAndCurrentUser(
      user,
      updatedContentType.id,
      currentProjectId,
      languages,
      collections,
      Capability.CreateContent,
    );

    dispatch(saveEditedContentTypeFinished(updatedContentType));

    const { typeElements: originalTypeElements } = originalContentType;

    const trackEvent: TrackUserEventWithData = (...args) =>
      dispatch(deps.trackUserEventWithData(...args));
    deps.trackAssetLimitConfigurationChanged(
      trackEvent,
      updatedContentType.typeElements,
      originalTypeElements,
    );
    deps.trackCustomTypeElementUpserted(
      trackEvent,
      updatedContentType.typeElements,
      originalTypeElements,
    );
    dispatch(deps.loadContentTypesData());
    dispatch(
      deps.trackUserEventWithData(
        TrackedEvent.ContentTypeUpdated,
        deps.getContentTypeModifiedEventData(
          updatedContentType.id,
          updatedContentType.typeElements,
          originalTypeElements,
        ),
      ),
    );
    dispatch(
      onboardingNotificationTriggered(OnboardingNotification.ContentTypeEditedNotification, {
        editedTypeId: editedType.id,
        languageIdToCreateItem: languageId,
        collectionIdToCreateItem: collectionId,
      }),
    );

    if (onSuccess) {
      onSuccess();
    }
  };
