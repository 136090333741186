import React from 'react';
import { IconName } from '../../../../../../_shared/constants/iconEnumGenerated.ts';
import { ControlShortcutTemplate } from '../../../../../../_shared/constants/shortcutSymbols.ts';
import {
  DataUiRteAction,
  getDataUiActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  IForwardedRefProps,
  forwardRef,
} from '../../../../../../_shared/utils/forwardedRefProps.tsx';
import {
  ISpecificButtonProps,
  RTEToolbarIconButton,
  specificButtonPropTypes,
} from '../../../toolbars/components/RTEToolbarButton.tsx';

const UnlinkButtonComponent: React.FC<
  ISpecificButtonProps & IForwardedRefProps<HTMLButtonElement>
> = (props) => (
  <RTEToolbarIconButton
    {...props}
    iconName={IconName.ChainSlash}
    ref={props.forwardedRef}
    shortcut={ControlShortcutTemplate('K')}
    tooltipPlacement={props.tooltipPlacement ?? 'bottom-start'}
    tooltipText={props.tooltipText ?? 'Unlink'}
    {...getDataUiActionAttribute(DataUiRteAction.Unlink)}
  />
);

UnlinkButtonComponent.displayName = 'UnlinkButton';
UnlinkButtonComponent.propTypes = specificButtonPropTypes;

export const UnlinkButton = forwardRef(UnlinkButtonComponent);
