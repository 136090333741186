import { makeCancellablePromise, swallowCancelledPromiseError } from '@kontent-ai/utils';
import React, { useEffect } from 'react';
import { ProjectOrderBy } from '../../../../../_shared/constants/projectOrderBy.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { emptyPlan } from '../../../../../data/models/plans/Plan.ts';
import { emptySubscription } from '../../../../../data/models/subscriptions/Subscription.ts';
import {
  areAllSubscriptionsExpired,
  getAdministratedSubscriptions,
  getSelectedSubscription,
} from '../../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { showCreateProjectModal } from '../../../../projects/actions/projectsActions.ts';
import {
  initializeProjectManagement,
  toggleProjectActiveState,
} from '../../../../projects/actions/thunkProjectsActions.ts';
import { createProjectList } from '../../../../projects/selectors/createProjectList.ts';
import { ProjectUiTransitionState } from '../../../../projects/types/projectUiTransitionState.ts';
import { SubscriptionProjects as SubscriptionProjectsComponent } from '../../components/SubscriptionProjects/SubscriptionProjects.tsx';

export const SubscriptionProjects: React.FC = () => {
  const projectsLoaded = useSelector(
    (state) => state.data.projects.loadingStatus === LoadingStatus.Loaded,
  );
  const isCreatingProject = useSelector((state) => state.projectsApp.creatingProject);

  const administratedSubscriptions = useSelector((state) =>
    getAdministratedSubscriptions(
      state.data.subscriptions.administratedIds,
      state.data.subscriptions.byId,
    ),
  );
  const selectedSubscription = useSelector(
    (state) => getSelectedSubscription(state) ?? emptySubscription,
  );
  const selectedSubscriptionPlan = useSelector(
    (state) =>
      state.data.plans.byId.get(selectedSubscription?.currentPlan.planId ?? '') ?? emptyPlan,
  );

  const projectsList = useSelector((state) => createProjectList(state, ProjectOrderBy.ProjectName));
  const isEnvironmentsColumnVisible =
    selectedSubscriptionPlan.features.maxProjectEnvironments !== 0 &&
    projectsList.filter((p) => !!p && p.environmentsCount > 0).length > 0;

  const activeProjects = projectsList.filter((project) => !project?.inactive);
  const isProjectsLimitReached =
    !!selectedSubscriptionPlan.features.maxSubscriptionProjects &&
    activeProjects.length >= selectedSubscriptionPlan.features?.maxSubscriptionProjects;

  const dispatch = useDispatch();
  const onShowCreateProjectModal = () => dispatch(showCreateProjectModal());
  const onToggleProjectActiveState = (projectId: Uuid, activate: ProjectUiTransitionState) =>
    dispatch(toggleProjectActiveState(projectId, activate));

  useEffect(() => {
    const initPromise = makeCancellablePromise(() => dispatch(initializeProjectManagement())).catch(
      swallowCancelledPromiseError,
    );

    return () => initPromise.cancel();
  }, []);

  return (
    <SubscriptionProjectsComponent
      allSubscriptionsExpired={areAllSubscriptionsExpired(administratedSubscriptions)}
      projectsList={projectsList}
      isCurrentUserAdmin={administratedSubscriptions.length > 0}
      selectedSubscriptionIsActive={selectedSubscription.isActive}
      isEnvironmentsColumnVisible={isEnvironmentsColumnVisible}
      projectsLoaded={projectsLoaded}
      isCreatingProject={isCreatingProject}
      isProjectsLimitReached={isProjectsLimitReached}
      projectsLimitCount={selectedSubscriptionPlan.features?.maxSubscriptionProjects ?? 0}
      onToggleProjectActiveState={onToggleProjectActiveState}
      onShowCreateProjectModal={onShowCreateProjectModal}
    />
  );
};

SubscriptionProjects.displayName = 'SubscriptionProjectsContainer';
