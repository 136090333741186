import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { OptionMode } from '../../models/optionMode.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { OptionProps, OptionVariant, optionModePropType } from './createOptionComponent.tsx';

export type OptionType<TDataUiActionType = never> = {
  readonly dataId?: string;
  readonly dataUiAttribute?: TDataUiActionType;
  readonly disabled?: boolean;
  readonly id: string;
  readonly label: string;
  readonly tooltipText?: string;
};

interface IOptionsListDataProps<TDataUiActionType> {
  readonly autoFocus?: boolean;
  readonly className?: string;
  readonly dataUiAttribute?: DataUiCollection;
  readonly disabled?: boolean;
  readonly isItemElement?: boolean;
  readonly mode: OptionMode;
  readonly options: ReadonlyArray<OptionType<TDataUiActionType>>;
  readonly selectedOptions: readonly string[];
}

export interface IGetOptionClassNameInput<TDataUiActionType = never> {
  readonly isSelected: boolean;
  readonly option: OptionType<TDataUiActionType>;
}

interface IOptionsListCallbacksProps<TDataUiActionType> {
  readonly getOptionClassName?: (input: IGetOptionClassNameInput<TDataUiActionType>) => string;
  readonly onOptionClick?: (optionTypeId: string) => void;
}

type OptionsListProps<TDataUiActionType = undefined> = IOptionsListDataProps<TDataUiActionType> &
  IOptionsListCallbacksProps<TDataUiActionType>;

export const createOptionsListComponent = <TDataUiActionType extends string = never>(
  OptionComponent: React.ComponentType<OptionProps<TDataUiActionType>>,
) => {
  const OptionsList = React.forwardRef<HTMLDivElement, OptionsListProps<TDataUiActionType>>(
    (
      {
        autoFocus,
        className,
        dataUiAttribute,
        disabled,
        getOptionClassName,
        isItemElement,
        mode,
        onOptionClick,
        options,
        selectedOptions,
      },
      ref,
    ) => {
      const [lastTabIndex, setLastTabIndex] = useState(0);
      const clearTabIndex = () => setLastTabIndex(-1);

      return (
        <div
          ref={ref}
          className={classNames('option__list', className, {
            'option__list--item-element': isItemElement,
          })}
          {...(dataUiAttribute && getDataUiCollectionAttribute(dataUiAttribute))}
        >
          {options.map((optionType, index) => {
            const isDisabled = disabled || optionType.disabled;
            const isSelected = selectedOptions.some((optionId) => optionId === optionType.id);
            const selectOption =
              onOptionClick && !isDisabled ? () => onOptionClick(optionType.id) : undefined;
            const setTabIndex = () => setLastTabIndex(index);

            const getOptionClassNameInput: IGetOptionClassNameInput<TDataUiActionType> = {
              isSelected,
              option: optionType,
            };

            return (
              <OptionComponent
                autoFocus={index === lastTabIndex && autoFocus}
                className={getOptionClassName?.(getOptionClassNameInput)}
                dataId={optionType.dataId}
                dataUiAttribute={optionType.dataUiAttribute}
                disabled={isDisabled}
                isSelected={isSelected}
                key={`${optionType.id}_${!!disabled}`}
                label={optionType.label}
                mode={mode}
                onBlur={clearTabIndex}
                onFocus={setTabIndex}
                onOptionSelected={selectOption}
                variant={isItemElement ? OptionVariant.ContentItem : undefined}
                tooltipText={optionType.tooltipText}
              />
            );
          })}
        </div>
      );
    },
  );

  OptionsList.displayName = `${OptionComponent.displayName}List`;
  OptionsList.propTypes = {
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    dataUiAttribute: PropTypes.oneOf(Object.values(DataUiCollection)),
    disabled: PropTypes.bool,
    isItemElement: PropTypes.bool,
    mode: optionModePropType.isRequired,
    options: PropTypes.arrayOf<OptionType<TDataUiActionType>>(PropTypes.any).isRequired,
    selectedOptions: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,

    // callback props
    getOptionClassName: PropTypes.func,
    onOptionClick: PropTypes.func,
  };

  return OptionsList;
};
