import { useAttachRef } from '@kontent-ai/hooks';
import { useMenuItem } from '@react-aria/menu';
import { mergeProps } from '@react-aria/utils';
import React, { useContext, PropsWithChildren, useLayoutEffect } from 'react';
import { colorIconDefault } from '../../tokens/decision/colors.ts';
import { IconSize } from '../../tokens/quarks/iconSize.ts';
import { Icons } from '../Icons/components/icons.ts';
import { MenuItem as MenuItemComponent } from '../MenuItem/MenuItem.tsx';
import { VerticalMenuItem } from '../VerticalMenu/types.ts';
import { VerticalMenuState } from '../VerticalMenu/useNonAccessibleVerticalMenu.ts';
import { DescendantContext, useDescendant } from './DescendantContext.tsx';
import { MenuContext } from './MenuContext.tsx';
import { SubmenuContext } from './SubmenuContext.tsx';

type InitializedSubmenuTriggerItemProps = SubmenuItemProps &
  Readonly<{
    state: VerticalMenuState<any>;
  }>;

const InitializedSubmenuTriggerItem = React.forwardRef<
  HTMLDivElement,
  InitializedSubmenuTriggerItemProps
>((props, forwardedRef) => {
  const { id, state, ...otherProps } = props;
  const menuContext = useContext(MenuContext);
  if (!menuContext) {
    throw new Error('SubmenuItem must be used within Menu component.');
  }
  const submenuContext = useContext(SubmenuContext);
  if (!submenuContext) {
    throw new Error('SubmenuTriggerItem must be used within Submenu component.');
  }

  const triggerProps = submenuContext?.submenuTriggerProps;
  const { refObject, refToForward } = useAttachRef(forwardedRef);

  const { menuItemProps } = useMenuItem(
    {
      ...triggerProps,
      key: id,
    },
    state,
    refObject,
  );

  useLayoutEffect(() => {
    submenuContext.setSubmenuTriggerKey(id);
  }, [id, submenuContext.setSubmenuTriggerKey]);

  const selectionManager = state.getSelectionManager();
  const isFocused = selectionManager?.focusedKey === id;

  return (
    <MenuItemComponent
      menuItemState="default"
      text={props.label}
      ref={refToForward}
      isHovered={isFocused}
      trailingElements={<Icons.ChevronRight size={IconSize.S} color={colorIconDefault} />}
      {...mergeProps(menuItemProps, otherProps)}
    />
  );
});

type SubmenuItemProps = Pick<VerticalMenuItem<any>, 'id' | 'label'>;

export const SubmenuTriggerItem = (props: PropsWithChildren<SubmenuItemProps>) => {
  const descendantContext = useContext(DescendantContext);

  if (!descendantContext) {
    throw new Error('MenuItem must be used within Menu component');
  }

  const submenuContext = useContext(SubmenuContext);
  if (!submenuContext) {
    throw new Error('SubmenuTriggerItem must be used within Submenu component.');
  }

  const triggerRef = submenuContext?.submenuTriggerRef;

  const { initializedRef, uninitializedRef } = useDescendant(props, triggerRef);

  if (descendantContext.state.collection.getItem(props.id)) {
    return (
      <InitializedSubmenuTriggerItem
        state={descendantContext.state}
        ref={initializedRef}
        {...props}
      />
    );
  }

  return <MenuItemComponent menuItemState="default" text={props.label} ref={uninitializedRef} />;
};
