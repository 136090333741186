import PropTypes from 'prop-types';
import React from 'react';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

export interface IScrollTableHeadRowProps {
  readonly collectionName: DataUiCollection;
  readonly children: React.ReactNode | React.ReactNodeArray;
}

export const scrollTableHeadRowPropTypes: PropTypesShape<IScrollTableHeadRowProps> = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  collectionName: PropTypes.string.isRequired,
};

export const ScrollTableHeadRow: React.FC<IScrollTableHeadRowProps> = ({
  collectionName,
  children,
}) => (
  <div className="scroll-table__head" {...getDataUiCollectionAttribute(collectionName)}>
    {children}
  </div>
);

ScrollTableHeadRow.displayName = 'ScrollTableHeadRow';
ScrollTableHeadRow.propTypes = scrollTableHeadRowPropTypes;
