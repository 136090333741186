import React from 'react';
import { RedirectIfMultipleWorkflowsAreNotAvailable } from '../containers/RedirectIfMultipleWorkflowsAreNotAvailable.tsx';
import { WorkflowCreator } from '../containers/WorkflowCreator.tsx';
import { WorkflowSettingsPage } from './WorkflowSettingsPage.tsx';

export const WorkflowCreatorPage: React.FC = () => (
  <RedirectIfMultipleWorkflowsAreNotAvailable>
    <WorkflowSettingsPage>
      <WorkflowCreator />
    </WorkflowSettingsPage>
  </RedirectIfMultipleWorkflowsAreNotAvailable>
);

WorkflowCreatorPage.displayName = 'WorkflowCreatorPage';
