import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from '../../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../../_shared/hooks/useSelector.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import {
  DataUiWorkflowAction,
  ItemEditingForms,
} from '../../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { abandonAssignmentSectionChange } from '../../../../actions/contentItemEditingActions.ts';
import {
  changeNote,
  updateNoteInSidebar,
} from '../../../../actions/thunkContentItemEditingActions.ts';
import { SectionFormWrapper } from '../../../../components/details/SectionFormWrapper.tsx';
import { SectionLink } from '../../../../components/details/SectionLink.tsx';
import { NonClickableSection } from '../../../../components/sidebar/sectionTypes/NonClickableSection.tsx';
import { AssignmentSections } from '../../../../constants/AssignmentSections.ts';
import { INoteFormShape } from '../../../../models/INoteFormShape.type.ts';
import { getNoteState } from '../../../selectors/getNoteState.ts';
import { NoteFormBase } from '../NoteFormBase.tsx';

export const NonClickableNoteSection: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const onCancel = () => dispatch(abandonAssignmentSectionChange(AssignmentSections.Note));
  const onClick = () => dispatch(changeNote(ContentItemEditingEventOrigins.ItemDetails));
  const onConfirm = (values: INoteFormShape) => dispatch(updateNoteInSidebar(history, values.note));

  const { note, isEditing } = useSelector(getNoteState);

  return (
    <NonClickableSection dataUiNameAttribute={ItemEditingForms.Note}>
      {isEditing ? (
        <SectionFormWrapper>
          <NoteFormBase initialNote={note?.text} onCancel={onCancel} onConfirm={onConfirm} />
        </SectionFormWrapper>
      ) : (
        <SectionLink
          dataUiAttribute={DataUiWorkflowAction.EditNote}
          onEditClick={onClick}
          text="Add note"
        />
      )}
    </NonClickableSection>
  );
};

NonClickableNoteSection.displayName = 'NonClickableNoteSection';
