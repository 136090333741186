import { ScrollElementsToViewOptions, scrollElementsToView } from '@kontent-ai/DOM';
import { CancellableExecutor } from '@kontent-ai/utils';
import { ScrollExecutedCallback } from '../../../_shared/hooks/useAutoScroll.ts';
import { ContentItemId } from '../../../_shared/models/ContentItemId.type.ts';
import { waitUntilFocusAndScrollAreNotDeferred } from '../../../_shared/utils/autoScrollUtils.ts';
import { getBlockIdClassName } from '../../richText/editorCore/utils/editorComponentUtils.ts';
import { getBlockByTargetId } from '../../richText/utils/blocks/getBlockByTargetId.ts';
import { IRichTextItemElement } from '../models/contentItemElements/RichTextItemElement.ts';

const getRteTargetCssSelector = (
  targetBlockKey: string,
  elementId: string,
  contentComponentId?: string,
) => {
  const blockIdClassName = getBlockIdClassName(targetBlockKey);
  return contentComponentId
    ? `[data-content-component-id="${contentComponentId}"][data-element-id="${elementId}"] .${blockIdClassName}`
    : `:not([data-content-component-id])[data-element-id="${elementId}"] .${blockIdClassName}`;
};

const getLinkedItemElementTargetCssSelector = (
  targetId: string,
  elementId: string,
  contentComponentId?: string,
) => {
  return contentComponentId
    ? `[data-content-component-id="${contentComponentId}"][data-element-id="${elementId}"] [data-linked-item-id="${targetId}"]`
    : `:not([data-content-component-id])[data-element-id="${elementId}"] [data-linked-item-id="${targetId}"]`;
};

const scrollToElement = (
  elements: ReadonlyArray<HTMLElement>,
  options?: ScrollElementsToViewOptions,
  onScrollExecuted?: ScrollExecutedCallback,
): void => {
  scrollElementsToView(elements, options);
  onScrollExecuted?.();
};

export const deferredScrollToRteTarget = (
  targetId: string,
  contentItemIdentifier: ContentItemId,
  elementData: IRichTextItemElement,
  scrollOptions?: ScrollElementsToViewOptions,
  rootRichTextElementId?: Uuid,
  contentComponentId?: string,
  onScrollExecuted?: ScrollExecutedCallback,
): (() => void) => {
  const abortController = new AbortController();
  const signal = abortController.signal;
  let scrollExecutor: CancellableExecutor<any> | null = null;

  const cancellationCallback = (): void => {
    abortController.abort();
  };

  signal.addEventListener('abort', () => {
    if (scrollExecutor) {
      scrollExecutor.cancel();
    }
  });

  const content = elementData._editorState.getCurrentContent();
  getBlockByTargetId(
    content,
    targetId,
    contentItemIdentifier,
    rootRichTextElementId ?? elementData.elementId,
  ).then((targetBlock) => {
    if (!signal.aborted && targetBlock) {
      const targetBlockKey = targetBlock.getKey();
      const targetCssSelector = getRteTargetCssSelector(
        targetBlockKey,
        elementData.elementId,
        contentComponentId,
      );
      const element = document.querySelector(targetCssSelector) as HTMLElement;

      if (element) {
        scrollExecutor = waitUntilFocusAndScrollAreNotDeferred(scrollToElement);
        scrollExecutor([element], scrollOptions, onScrollExecuted);
      }
    }
  });

  return cancellationCallback;
};

const scrollToLinkedItemElementTarget = (
  targetId: string,
  elementId: string,
  scrollOptions?: ScrollElementsToViewOptions,
  contentComponentId?: string,
  onScrollExecuted?: ScrollExecutedCallback,
): void => {
  const targetCssSelector = getLinkedItemElementTargetCssSelector(
    targetId,
    elementId,
    contentComponentId,
  );
  const element = document.querySelector(targetCssSelector) as HTMLElement;
  if (element) {
    scrollElementsToView([element], scrollOptions);
    onScrollExecuted?.();
  }
};

export const deferredScrollToLinkedItemElementTarget = (
  targetId: string,
  elementId: string,
  scrollOptions?: ScrollElementsToViewOptions,
  contentComponentId?: string,
  onScrollExecuted?: ScrollExecutedCallback,
): (() => void) => {
  const executeScroll = waitUntilFocusAndScrollAreNotDeferred(scrollToLinkedItemElementTarget);
  executeScroll(targetId, elementId, scrollOptions, contentComponentId, onScrollExecuted);
  return executeScroll.cancel;
};
