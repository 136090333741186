import PropTypes from 'prop-types';
import React from 'react';
import {
  DateTimeTypeElementPropTypes,
  IDateTimeTypeElement,
} from '../../../../../contentInventory/content/models/contentTypeElements/DateTimeTypeElement.ts';
import {
  DateTimeItemElement,
  DateTimeItemElementPropTypes,
} from '../../../../models/contentItemElements/DateTimeItemElement.ts';
import { ItemElement } from '../../../ContentItemEditing/containers/elements/ItemElement.tsx';
import { ItemElementRefresher } from '../../../ContentItemEditing/containers/elements/ItemElementRefresher.tsx';
import { IRevisionItemElementProps } from '../../utils/itemElementRevisionComponentCreator.ts';
import { DateTimeDiff } from './subelements/DateTimeDiff.tsx';

type DateTimeProps = IRevisionItemElementProps<DateTimeItemElement, IDateTimeTypeElement>;

const propTypes: PropTypeMap<DateTimeProps> = {
  elementData: DateTimeItemElementPropTypes.isRequired,
  hideValidationStatus: PropTypes.bool,
  originalElementData: DateTimeItemElementPropTypes,
  revisionElementModifiedBy: PropTypes.string,
  typeElement: DateTimeTypeElementPropTypes.isRequired,
};

export const DateTime: React.FC<DateTimeProps> = ({
  elementData,
  hideValidationStatus,
  originalElementData,
  revisionElementModifiedBy,
  typeElement,
}) => (
  <ItemElement
    disabled
    hideValidationStatus={hideValidationStatus}
    isDisplayOnly
    typeElement={typeElement}
    revisionChangedBy={revisionElementModifiedBy}
  >
    <ItemElementRefresher
      elementData={elementData}
      renderInput={(refresherElementData: DateTimeItemElement) => (
        <DateTimeDiff
          elementData={refresherElementData}
          originalElementData={originalElementData}
          typeElement={typeElement}
        />
      )}
    />
  </ItemElement>
);

DateTime.displayName = 'DateTime (Revisions)';
DateTime.propTypes = propTypes;
