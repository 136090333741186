import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { abandonAssignmentSectionChange } from '../../../actions/contentItemEditingActions.ts';
import { updateDueDate } from '../../../actions/thunkContentItemEditingActions.ts';
import { LeafActionFormProps } from '../../../components/editingActions/EditingLeafActionForm.tsx';
import { DueDateForm as DueDateFormComponent } from '../../../components/editingActions/leafActionForms/DueDateForm.tsx';
import { AssignmentSections } from '../../../constants/AssignmentSections.ts';

export const DueDateForm: React.FC<LeafActionFormProps> = ({
  actionOrigin,
  useCustomHandleClickOutside,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const initialDueDate = useSelector(
    (s) => s.contentApp.editedContentItemVariant?.assignment.due ?? null,
  );

  return (
    <DueDateFormComponent
      actionOrigin={actionOrigin}
      initialDueDate={initialDueDate}
      onCancel={() => dispatch(abandonAssignmentSectionChange(AssignmentSections.DueDate))}
      onConfirm={(values) => dispatch(updateDueDate(history, values.date.value, actionOrigin))}
      useCustomHandleClickOutside={useCustomHandleClickOutside}
    />
  );
};

DueDateForm.displayName = 'DueDateForm';
