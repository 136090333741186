import { ButtonStyle, buttonStyleValues } from '@kontent-ai/component-library/Button';
import { IconName, Icons } from '@kontent-ai/component-library/Icons';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { ObjectWithDataAttribute } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { EditingActionsButton } from '../actions/EditingActionsButton.tsx';
import { EditingActionsLinkWrapper } from '../actions/EditingActionsLinkWrapper.tsx';

export type QuickActionsButtonProps = {
  readonly buttonClassName?: string;
  readonly dataAttributes?: ObjectWithDataAttribute;
  readonly disabled?: boolean;
  readonly iconName: IconName;
  readonly isActive?: boolean;
  readonly buttonStyle: ButtonStyle;
  readonly onClick: () => void;
  readonly outwardLinkUrl?: string;
  readonly text: string;
  readonly tooltip?: string;
  readonly withSubmenu?: boolean;
};

const propTypes: PropTypeMap<QuickActionsButtonProps> = {
  buttonClassName: PropTypes.string,
  dataAttributes: PropTypes.object,
  disabled: PropTypes.bool,
  iconName: PropTypes.oneOf(Object.keys(Icons)).isRequired,
  isActive: PropTypes.bool,
  buttonStyle: PropTypes.oneOf(buttonStyleValues).isRequired,
  onClick: PropTypes.func.isRequired,
  outwardLinkUrl: PropTypes.string,
  text: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  withSubmenu: PropTypes.bool,
};

export const QuickActionsButton = forwardRef<HTMLButtonElement, QuickActionsButtonProps>(
  (props, forwardedRef) => (
    <div className="quick-actions__button">
      <Tooltip tooltipText={props.tooltip} placement="bottom">
        <EditingActionsLinkWrapper
          onClick={props.onClick}
          disabled={props.disabled}
          outwardLinkUrl={props.outwardLinkUrl}
        >
          <EditingActionsButton
            ref={forwardedRef}
            buttonStyle={props.buttonStyle}
            disabled={props.disabled}
            iconName={props.iconName}
            isActive={props.isActive}
            onClick={props.onClick}
            text={props.text}
            {...props.dataAttributes}
          />
        </EditingActionsLinkWrapper>
      </Tooltip>
    </div>
  ),
);

QuickActionsButton.displayName = 'QuickActionsButton';
QuickActionsButton.propTypes = propTypes;
