import { Collection } from '@kontent-ai/utils';
import { useMemo } from 'react';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { DataUiCollection } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { Capability } from '../../../../../../../_shared/utils/permissions/capability.ts';
import { filterCollectionsByCapabilityInLanguage } from '../../../../../../../_shared/utils/permissions/filterCollectionsByCapability.ts';
import { IUser } from '../../../../../../../data/reducers/user/IUser.type.ts';
import { getCurrentProject } from '../../../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { ListingFilterCategorySelector } from '../../components/ListingFilterCategorySelector.tsx';
import {
  ICollectionSelectItem,
  getCollectionOptions,
} from '../../utils/collectionsFilterSelectorUtils.ts';

type Props = {
  readonly areSpacesInCollectionsEnabled: boolean;
  readonly user: IUser;
  readonly selectedLanguageId: Uuid;
  readonly selectedCollectionIds: ReadonlySet<Uuid>;
  readonly selectedSpaceIds: ReadonlySet<Uuid>;
  readonly onCollectionSelectionChanged: (ids: ReadonlySet<Uuid>) => void;
};

export const CollectionsFilterSelector = (props: Props) => {
  const { projectId } = useSelector(getCurrentProject);
  const collectionsById = useSelector((state) => state.data.collections.byId);
  const collections = useMemo(() => Collection.getValues(collectionsById), [collectionsById]);
  const spacesById = useSelector((state) => state.data.spaces.byId);
  const spaces = useMemo(() => Collection.getValues(spacesById), [spacesById]);

  const selectedSpaces = useMemo(
    () => spaces.filter((space) => props.selectedSpaceIds.has(space.id)),
    [props.selectedSpaceIds, spaces],
  );

  const options = useMemo(
    () =>
      getCollectionOptions(
        selectedSpaces,
        filterCollectionsByCapabilityInLanguage(
          collections,
          props.selectedLanguageId,
          Capability.ViewContent,
          projectId,
          props.user,
        ),
        props.areSpacesInCollectionsEnabled,
      ),
    [
      collections,
      projectId,
      props.areSpacesInCollectionsEnabled,
      props.selectedLanguageId,
      props.user,
      selectedSpaces,
    ],
  );

  const selectedOptionIds = useMemo(
    () =>
      collections
        .filter((collection) => props.selectedCollectionIds.has(collection.id))
        .map((c) => c.id),
    [collections, props.selectedCollectionIds],
  );

  return collections.length > 1 ? (
    <ListingFilterCategorySelector<ICollectionSelectItem>
      options={options}
      onChange={props.onCollectionSelectionChanged}
      collection={DataUiCollection.ContentCollections}
      placeholder="Select a collection"
      selectedOptionIds={selectedOptionIds}
      title="Collection"
    />
  ) : null;
};
