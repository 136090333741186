import { Callout } from '@kontent-ai/component-library/Callout';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { EnsuredSubscriptionRouteParams, PlanSelectionRoute } from '../../constants/routePaths.ts';
import { Button } from '../../uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../uiComponents/Button/buttonStyle.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { IntercomUtils } from '../../utils/intercomUtils.ts';
import { buildPath } from '../../utils/routing/routeTransitionUtils.ts';

export interface ISubscriptionPlanLimitReachedWarningDataProps {
  readonly currentUserIsAdmin: boolean;
  readonly hideButton?: boolean;
  readonly isVisible: boolean;
  readonly planIsCustom: boolean;
  readonly subscriptionId: Uuid | undefined | null;
}

export interface ISubscriptionPlanLimitReachedWarningCallbackProps {
  readonly closeInvitationModalDialog: () => void;
}

const propTypes: PropTypesShape<
  ISubscriptionPlanLimitReachedWarningDataProps & ISubscriptionPlanLimitReachedWarningCallbackProps
> = {
  closeInvitationModalDialog: PropTypes.func,
  currentUserIsAdmin: PropTypes.bool.isRequired,
  hideButton: PropTypes.bool,
  isVisible: PropTypes.bool.isRequired,
  planIsCustom: PropTypes.bool.isRequired,
  subscriptionId: PropTypes.string,
};

const showIntercom = (closeInvitationModalDialog: () => void): void => {
  IntercomUtils.showMessage();
  closeInvitationModalDialog();
};

const ProjectManagerMessage: React.FC = () => (
  <p>
    To add more users to the project, contact your subscription admin to upgrade the subscription.
  </p>
);

type SubscriptionAdminCustomPlanMessageProps = {
  readonly onCloseInvitationModalDialog: () => void;
  readonly hideButton?: boolean;
};

const SubscriptionAdminCustomPlanMessage: React.FC<SubscriptionAdminCustomPlanMessageProps> = ({
  onCloseInvitationModalDialog,
  hideButton,
}) => (
  <>
    <p>To add more users to the project, contact us to adjust the plan to your needs.</p>
    {!hideButton && (
      <Button
        onClick={() => showIntercom(onCloseInvitationModalDialog)}
        style={ButtonStyle.Primary}
        className="u-spacing-top-l"
      >
        Contact us
      </Button>
    )}
  </>
);

type SubscriptionAdminMessageProps = {
  readonly subscriptionId: Uuid | undefined | null;
  readonly onCloseInvitationModalDialog: () => void;
  readonly hideButton?: boolean;
};

const SubscriptionAdminMessage: React.FC<SubscriptionAdminMessageProps> = ({
  onCloseInvitationModalDialog,
  subscriptionId,
  hideButton,
}) => (
  <>
    <p>To invite more users, you need to upgrade your subscription plan.</p>
    {subscriptionId && (
      <Link
        className="u-no-text-decoration"
        to={buildPath<EnsuredSubscriptionRouteParams>(PlanSelectionRoute, { subscriptionId })}
      >
        {!hideButton && (
          <Button
            style={ButtonStyle.Primary}
            onClick={onCloseInvitationModalDialog}
            dataUiAction={DataUiAction.ChangePlan}
            className="u-spacing-top-l"
          >
            Select plan
          </Button>
        )}
      </Link>
    )}
  </>
);

export const SubscriptionPlanMaxUserLimitReachedWarning: React.FC<
  ISubscriptionPlanLimitReachedWarningDataProps & ISubscriptionPlanLimitReachedWarningCallbackProps
> = ({
  closeInvitationModalDialog,
  currentUserIsAdmin,
  isVisible,
  planIsCustom,
  subscriptionId,
  hideButton,
}) =>
  isVisible ? (
    <Callout
      headline="You have reached your plan limit"
      calloutType="friendlyWarning"
      maxWidth={960}
    >
      <div
        {...getDataUiElementAttribute(DataUiElement.SubscriptionPlanMaxUsersLimitReachedWarning)}
      >
        <p>You have reached the active user limit of your current subscription plan.</p>
        {currentUserIsAdmin ? (
          planIsCustom ? (
            <SubscriptionAdminCustomPlanMessage
              onCloseInvitationModalDialog={closeInvitationModalDialog}
              hideButton={hideButton}
            />
          ) : (
            <SubscriptionAdminMessage
              subscriptionId={subscriptionId}
              onCloseInvitationModalDialog={closeInvitationModalDialog}
              hideButton={hideButton}
            />
          )
        ) : (
          <ProjectManagerMessage />
        )}
      </div>
    </Callout>
  ) : null;

SubscriptionPlanMaxUserLimitReachedWarning.displayName =
  'SubscriptionPlanMaxUserLimitReachedWarning';
SubscriptionPlanMaxUserLimitReachedWarning.propTypes = propTypes;
