import Immutable from 'immutable';
import React, { useState, useEffect } from 'react';
import { FullScreenModalDialog } from '../../../../../../component-library/components/Dialogs/ModalDialog/FullScreenModalDialog.tsx';
import {
  Column,
  DataTableHeadRow,
} from '../../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import { OrderByDirection } from '../../../../../_shared/models/OrderBy.ts';
import { stringifyContentItemId } from '../../../../../_shared/models/utils/contentItemIdUtils.ts';
import { DataUiCollection } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { MissionControlRepository } from '../../../../unifiedMissionControl/repositories/MissionControlRepository.type.ts';
import { useIsDemoModeEnabled } from '../../../contexts/MissionControlDemoContext.tsx';
import { daysSinceDate } from '../../../utils/daysSinceDate.ts';
import { StyledDataTable } from '../../shared/components/StyledDataTable.tsx';
import { Widget } from '../../shared/components/Widget.tsx';
import { WidgetListingContentShowMoreButton } from '../../shared/components/WidgetListingContentShowMoreButton.tsx';
import { WidgetListingStateContent } from '../../shared/components/WidgetListingStateContent.tsx';
import { AllContentUpToDateMessage } from '../../shared/constants/uiConstants.ts';
import {
  entityListDetailDisplayCount,
  entityListWidgetDisplayCount,
} from '../../shared/constants/widgetConstants.ts';
import { useWidgetListingDataFetcher } from '../../shared/hooks/useWidgetListingDataFetcher.tsx';
import { UnchangedUnpublishedWidgetDetailContainer } from '../containers/UnchangedUnpublishedWidgetDetailContainer.tsx';
import { createUnchangedUnpublishedItemFromServerModel } from '../utils/createUnchangedUnpublishedItemFromServerModel.ts';
import { getVariantLinkPath } from '../utils/getVariantLinkPath.ts';
import { UnchangedUnpublishedWidgetRow } from './UnchangedUnpublishedWidgetRow.tsx';

type UnchangedUnpublishedWidgetProps = Readonly<{
  itemsFetcher: MissionControlRepository['getUnchangedUnpublishedItems'];
  useRowLinks: boolean;
}>;

export const UnchangedUnpublishedWidget: React.FC<UnchangedUnpublishedWidgetProps> = ({
  itemsFetcher,
  useRowLinks,
}) => {
  const isDemoModeEnabled = useIsDemoModeEnabled();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const {
    fetchInit,
    fetchMore,
    state: unchangedItemsState,
    data: unchangedItems,
  } = useWidgetListingDataFetcher(itemsFetcher, createUnchangedUnpublishedItemFromServerModel);

  useEffect(() => fetchInit(entityListWidgetDisplayCount).cancel, [fetchInit]);

  const openDetail = () => {
    fetchMore(entityListDetailDisplayCount);
    setIsDialogOpen(true);
  };

  const closeDetail = () => setIsDialogOpen(false);

  const longestItemStaleness = daysSinceDate(unchangedItems[0]?.lastEnteredWorkflowStep);

  return (
    <>
      <Widget>
        <Widget.Title isDemoMode={isDemoModeEnabled} text="Unchanged unpublished items" />
        <WidgetListingStateContent
          state={unchangedItemsState}
          emptyStateMessage={AllContentUpToDateMessage}
        >
          <Widget.Body>
            <StyledDataTable
              dataUiCollectionName={DataUiCollection.ContentItems}
              header={<DataTableHeadRow columns={widgetTableHeadColumns} />}
            >
              {unchangedItems.slice(0, entityListWidgetDisplayCount).map((unchangedItem) => (
                <UnchangedUnpublishedWidgetRow
                  key={stringifyContentItemId(unchangedItem.itemWithVariant.variant.id)}
                  linkPath={
                    useRowLinks ? getVariantLinkPath(unchangedItem.itemWithVariant.variant) : null
                  }
                  longestItemStaleness={longestItemStaleness}
                  unchangedUnpublishedItem={unchangedItem}
                />
              ))}
            </StyledDataTable>
          </Widget.Body>

          <Widget.Footer>
            <Widget.CenterContent>
              <WidgetListingContentShowMoreButton onClick={openDetail} />
            </Widget.CenterContent>
          </Widget.Footer>
        </WidgetListingStateContent>
      </Widget>

      <FullScreenModalDialog
        headline="Unchanged unpublished items"
        isOpen={isDialogOpen}
        isDismissable
        onClose={closeDetail}
      >
        <UnchangedUnpublishedWidgetDetailContainer
          useRowLinks={useRowLinks}
          unchangedItems={unchangedItems}
          dataState={unchangedItemsState}
        />
      </FullScreenModalDialog>
    </>
  );
};

const widgetTableHeadColumns: Immutable.List<Column> = Immutable.List([
  {
    columnName: 'Name',
    orderBy: OrderByDirection.None,
  },
  {
    columnName: 'Days in this workflow step',
    orderBy: OrderByDirection.Descending,
  },
]);

UnchangedUnpublishedWidget.displayName = 'UnchangedUnpublishedWidget';
