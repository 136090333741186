import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { AppNames } from '../../../../../_shared/constants/applicationNames.ts';
import {
  ContentStatusRoute,
  EnvironmentRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IContentItemStatus } from '../../../../contentInventory/content/models/filter/IContentItemStatus.ts';
import { IPublishingState } from '../../../../contentInventory/content/models/filter/IPublishingState.ts';
import { contentItemsBreadcrumbsOriginChanged } from '../../../../contentInventory/shared/actions/contentInventoryActions.ts';

interface IDeps {
  readonly loadLanguages: (abortSignal?: AbortSignal) => ThunkPromise;
}

export const getArrayOfStringFlags = (
  flagObject: IPublishingState | IContentItemStatus,
): readonly string[] =>
  Object.entries(flagObject)
    .filter(([, value]) => value)
    .map(([flag]) => flag);

export const createInitializeContentStatusAction =
  (deps: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      sharedApp: { currentProjectId },
    } = getState();

    await dispatch(deps.loadLanguages(abortSignal));

    const contentStatusPath = buildPath<EnvironmentRouteParams>(ContentStatusRoute, {
      projectId: currentProjectId,
    });

    dispatch(
      contentItemsBreadcrumbsOriginChanged({
        path: contentStatusPath,
        title: AppNames.ContentStatus,
      }),
    );
  };
