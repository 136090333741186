import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { IgnoreByGrammarly } from '../../components/IgnoreByGrammarly.tsx';
import { VariantCompletionStatus } from '../../utils/contentItemVariantUtils.ts';

export interface IContentItemStatusDataProps {
  readonly status: VariantCompletionStatus;
}

interface IContentItemStatus {
  readonly className: string;
  readonly name: string;
  readonly title: string;
}

const getStatusDetails = (status: VariantCompletionStatus): IContentItemStatus => {
  switch (status) {
    case VariantCompletionStatus.notTranslated:
      return {
        className: 'status--is-missing',
        name: 'Not translated',
        title: 'This item is not yet translated to the current language.',
      };

    case VariantCompletionStatus.unfinished:
      return {
        className: 'status',
        name: 'Unfinished',
        title: 'One or more elements in this content item are not filled in correctly.',
      };

    case VariantCompletionStatus.ready:
      return {
        className: 'status--is-ready',
        name: 'Ready',
        title: 'Everything is filled in correctly, this item can be published.',
      };

    case VariantCompletionStatus.allDone:
      return {
        className: 'status--is-all-done',
        name: 'All done',
        title: 'This item is already published! No further action is needed.',
      };

    default:
      throw Error(`[ContentItemStatus.tsx] Unknown content item variant status: "${status}"`);
  }
};

const propTypes: PropTypesShape<IContentItemStatusDataProps> = {
  status: PropTypes.string.isRequired,
};

export const ContentItemStatus: React.FC<IContentItemStatusDataProps> = ({ status }) => {
  const statusDetails = getStatusDetails(status);

  return (
    <span className={classNames('status', statusDetails.className)} title={statusDetails.title}>
      <IgnoreByGrammarly text={statusDetails.name} />
    </span>
  );
};

ContentItemStatus.displayName = 'ContentItemStatus';
ContentItemStatus.propTypes = propTypes;
