import { useCallback, useMemo } from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { repositoryCollection } from '../../../../../_shared/repositories/repositories.ts';
import { getSortedContentTypesByName } from '../../../../contentModels/shared/utils/typeUtils.ts';
import { UnchangedPublishedItemsListingRequestOptionsModel } from '../../../../unifiedMissionControl/widgets/unchangedPublishedContent/types/UnchangedPublishedItemsListingRequestOptionsModel.type.ts';
import { UnchangedPublishedItemsWidget } from '../components/UnchangedPublishedItemsWidget.tsx';
import { ContentTypeOption } from '../types/ContentTypeOption.type.ts';

const { missionControlRepository } = repositoryCollection;

export const UnchangedPublishedItemsWidgetLiveContainer: React.FC = () => {
  const contentTypesById = useSelector((state) => state.data.contentTypes.byId);
  const contentTypeOptions: ContentTypeOption = useMemo(
    () =>
      getSortedContentTypesByName(contentTypesById)
        .map((contentType) => ({
          id: contentType.id,
          label: contentType.name,
        }))
        .toArray(),
    [contentTypesById],
  );

  const itemsFetcher = useCallback(
    (
      requestOptions: UnchangedPublishedItemsListingRequestOptionsModel,
      abortSignal?: AbortSignal,
    ) => missionControlRepository.getUnchangedPublishedItems(requestOptions, abortSignal),
    [],
  );

  return (
    <UnchangedPublishedItemsWidget
      contentTypeOptions={contentTypeOptions}
      itemsFetcher={itemsFetcher}
      useRowLinks
    />
  );
};

UnchangedPublishedItemsWidgetLiveContainer.displayName =
  'UnchangedPublishedItemsWidgetLiveContainer';
