import { ISpace } from '../../../data/models/space/space.ts';
import { IWebSpotlightPreferences } from '../../../localStorages/webSpotlightPreferencesStorage.ts';

export const getInitialWebSpotlightSpace = (
  spaces: ReadonlyArray<ISpace>,
  preferences: IWebSpotlightPreferences,
): ISpace | null => {
  const preferredSpace = preferences.spaceId
    ? spaces.find((space) => space.id === preferences.spaceId)
    : null;

  if (preferredSpace) {
    return preferredSpace;
  }

  const spaceWithRootItem = spaces.find((space) => !!space.webSpotlightRootItemId);

  if (spaceWithRootItem) {
    return spaceWithRootItem;
  }

  return spaces[0] ?? null;
};

export const getInitialWebSpotlightSpaceForCustomRootItem = (
  spaces: ReadonlyArray<ISpace>,
  preferences: IWebSpotlightPreferences,
): ISpace | null => {
  const preferredSpace = preferences.spaceId
    ? spaces.find((space) => space.id === preferences.spaceId)
    : null;

  if (preferredSpace) {
    return preferredSpace;
  }

  return spaces[0] ?? null;
};
