import { DraftBlockRenderConfig, EditorProps as DraftJSEditorProps } from 'draft-js';
import Immutable from 'immutable';
import React, { useCallback, useMemo, useState } from 'react';
import { DataUiElement } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { UseOriginalItemElements } from '../../../itemEditor/features/ContentComponent/context/ItemElementsContext.tsx';
import { ContentOverlayPlaceholder } from '../../../itemEditor/features/LinkedItems/components/ContentOverlay.tsx';
import { DiffType } from '../../../itemEditor/features/Revisions/utils/DiffType.ts';
import { EditorSizeHandler } from '../../components/utility/EditorSizeHandler.tsx';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { GetBaseBlockRenderMap } from '../../editorCore/types/Editor.base.type.ts';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { None, WithoutProps } from '../../editorCore/types/Editor.contract.type.ts';
import { DecoratedEditor } from '../../editorCore/types/Editor.decorated.type.ts';
import {
  Apply,
  EditorPlugin,
  PluginState,
  Render,
} from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import {
  BaseBlockRenderMap,
  getContentOverlayClass,
  getContentOverlayId,
  mergeBlockRenderMaps,
} from '../../editorCore/utils/editorComponentUtils.ts';
import { withDisplayName } from '../../editorCore/utils/withDisplayName.ts';
import { BaseBlockType, BlockType } from '../../utils/blocks/blockType.ts';
import { getBaseBlockType } from '../../utils/blocks/editorBlockGetters.ts';
import { IEditorBlockProps } from '../../utils/blocks/editorBlockUtils.ts';
import { getContentComponentBlocks } from '../../utils/general/editorContentGetters.ts';
import { CustomBlockWrapper } from '../customBlocks/components/CustomBlockWrapper.tsx';
import { getDiffType } from '../diff/api/editorDiffUtils.ts';
import { OnHighlightedBlocksChanged, StylesPlugin } from '../visuals/StylesPlugin.tsx';
import { getContentComponentId } from './api/editorContentComponentUtils.ts';
import { ExpandedContentComponentBlocks } from './components/expanded/ExpandedContentComponentBlocks.tsx';
import { ExpandedContentComponent } from './containers/expanded/ExpandedContentComponent.tsx';

export type DisplayContentComponentsPlugin = EditorPlugin<None, None, None, [StylesPlugin]>;

type ContentComponentBlockCustomProps = Pick<
  PluginState<DisplayContentComponentsPlugin>,
  'getEditorId'
>;

const ContentComponentItemBlock = (
  props: React.PropsWithChildren<IEditorBlockProps<ContentComponentBlockCustomProps>>,
) => {
  const {
    block,
    blockProps: { getEditorId },
  } = props;

  const blockKey = block.getKey();
  const contentComponentId = getContentComponentId(block);
  if (!contentComponentId) {
    return null;
  }

  // We use only original data in case the content component is removed. Normally it doesn't matter but in case it is moved (removed + added)
  // both instances display, and we want to avoid displaying of nested changes (which can be also adding text etc.) in the removed instance
  const useOnlyOriginalData = getDiffType(block) === DiffType.Removed;
  const contentComponent = (
    <ExpandedContentComponent
      contentComponentId={contentComponentId}
      renderElements={() => (
        <ContentOverlayPlaceholder overlayId={getContentOverlayId(getEditorId(), blockKey)} />
      )}
    />
  );

  return (
    <CustomBlockWrapper
      key={blockKey}
      className="rte__component"
      uiElement={DataUiElement.ContentComponentItem}
    >
      {useOnlyOriginalData ? (
        <UseOriginalItemElements>{contentComponent}</UseOriginalItemElements>
      ) : (
        contentComponent
      )}
    </CustomBlockWrapper>
  );
};

const EditorWithContentComponents: DecoratedEditor<
  WithoutProps<DisplayContentComponentsPlugin>
> = ({ baseRender, state }) => {
  const {
    getEditorId,
    editorProps: { blockRendererFn: baseBlockRendererFn },
  } = state;

  const blockProps: ContentComponentBlockCustomProps = useMemo(
    () => ({ getEditorId }),
    [getEditorId],
  );

  const blockRendererFn = useCallback<Required<DraftJSEditorProps>['blockRendererFn']>(
    (block) => {
      const baseBlockType = getBaseBlockType(block);
      if (baseBlockType === BlockType.ContentComponent) {
        return {
          component: ContentComponentItemBlock,
          props: blockProps,
          editable: false,
        };
      }

      return baseBlockRendererFn?.(block) ?? null;
    },
    [baseBlockRendererFn, blockProps],
  );

  const stateWithContentComponents: PluginState<DisplayContentComponentsPlugin> = {
    ...state,
    editorProps: {
      ...state.editorProps,
      blockRendererFn,
    },
  };

  return baseRender(stateWithContentComponents);
};

const contentComponentRenderMap: BaseBlockRenderMap = Immutable.Map<
  BaseBlockType,
  DraftBlockRenderConfig
>({
  [BaseBlockType.ContentComponent]: {
    element: 'div',
  },
});

const getBaseBlockRenderMap: Decorator<GetBaseBlockRenderMap> = (baseGetBaseBlockRenderMap) => () =>
  mergeBlockRenderMaps(baseGetBaseBlockRenderMap(), contentComponentRenderMap);

const render: Decorator<Render<DisplayContentComponentsPlugin>> = (baseRender) => (state) => (
  <EditorWithContentComponents baseRender={baseRender} state={state} />
);

export const useDisplayContentComponents: PluginCreator<DisplayContentComponentsPlugin> = (
  baseEditor,
) =>
  useMemo(
    () =>
      withDisplayName('DisplayContentComponentsPlugin', {
        ComposedEditor: (props) => {
          const [highlightedBlockKeys, setHighlightedBlockKeys] = useState<ReadonlySet<string>>(
            new Set(),
          );

          const onHighlightedBlocksChanged: Decorator<OnHighlightedBlocksChanged> = useCallback(
            (baseOnHighlightedBlocksChanged) => (newHighlightedBlockKeys) => {
              setHighlightedBlockKeys(newHighlightedBlockKeys);
              baseOnHighlightedBlocksChanged(newHighlightedBlockKeys);
            },
            [],
          );

          const renderOverlays: Decorator<Render<DisplayContentComponentsPlugin>> = useCallback(
            (baseRenderOverlays) => (state) => {
              const { editorState, getEditorId } = state;
              const content = editorState.getCurrentContent();
              const contentComponentBlocks = getContentComponentBlocks(content);
              const editorId = getEditorId();

              return (
                <>
                  {baseRenderOverlays(state)}
                  <ExpandedContentComponentBlocks
                    contentComponentBlocks={contentComponentBlocks}
                    editorId={editorId}
                    highlightedBlockKeys={highlightedBlockKeys}
                  />
                  <EditorSizeHandler
                    contentOverlayClassName={
                      contentComponentBlocks.length > 0
                        ? getContentOverlayClass(editorId)
                        : undefined
                    }
                    editorRef={state.getWrapperRef()}
                  />
                </>
              );
            },
            [highlightedBlockKeys],
          );

          const apply: Apply<DisplayContentComponentsPlugin> = useCallback(
            (state) => {
              state.getBaseBlockRenderMap.decorate(getBaseBlockRenderMap);
              state.onHighlightedBlocksChanged.decorate(onHighlightedBlocksChanged);
              state.render.decorate(render);
              state.renderOverlays.decorate(renderOverlays);

              return {};
            },
            [onHighlightedBlocksChanged, renderOverlays],
          );

          return useEditorWithPlugin(baseEditor, props, { apply });
        },
      }),
    [baseEditor],
  );
