import React from 'react';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import { ControlAltShortcutTemplate } from '../../../../../_shared/constants/shortcutSymbols.ts';
import {
  DataUiRteAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IForwardedRefProps, forwardRef } from '../../../../../_shared/utils/forwardedRefProps.tsx';
import {
  ISpecificButtonProps,
  RTEToolbarIconButton,
  specificButtonPropTypes,
} from '../../toolbars/components/RTEToolbarButton.tsx';

const AddSuggestionButtonComponent: React.FC<
  React.PropsWithChildren<ISpecificButtonProps & IForwardedRefProps<HTMLButtonElement>>
> = (props) => (
  <RTEToolbarIconButton
    {...props}
    iconName={IconName.BubblePencil}
    ref={props.forwardedRef}
    shortcut={ControlAltShortcutTemplate('S')}
    tooltipPlacement={props.tooltipPlacement ?? 'bottom-start'}
    tooltipText={props.tooltipText ?? 'Add suggestion'}
    {...getDataUiActionAttribute(DataUiRteAction.AddSuggestion)}
  />
);

AddSuggestionButtonComponent.displayName = 'AddSuggestionButton';
AddSuggestionButtonComponent.propTypes = specificButtonPropTypes;

export const AddSuggestionButton = forwardRef(AddSuggestionButtonComponent);
