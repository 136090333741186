import { AppMenuItem, NavigationBar } from '@kontent-ai/component-library/NavigationBar';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { LandmarkAriaLabels } from '../../constants/landmarkAriaLabels.ts';
import { DocsMenu } from '../../containers/MainMenu/DocsMenu.tsx';
import { FeedbackMenu } from '../../containers/MainMenu/FeedbackMenu.tsx';
import { IntercomLauncherButton } from '../../containers/MainMenu/IntercomLauncherButton.tsx';
import { ReportBugButton } from '../../containers/MainMenu/ReportBugButton.tsx';
import { UserMenu } from '../../containers/MainMenu/UserMenu.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { MainLayoutGrid } from '../MainLayoutGrid.tsx';
import { HasProjectOrEnvironmentContext } from '../routing/HasProjectOrEnvironmentContext.tsx';

type Props = {
  readonly menuStructure: ReadonlyArray<AppMenuItem>;
};

const navigationBarContent = (
  <Stack
    component="nav"
    aria-label={LandmarkAriaLabels.UserContext}
    spacing={Spacing.XL}
    align="center"
  >
    <IntercomLauncherButton />
    <ReportBugButton />
    <FeedbackMenu />
    <DocsMenu />
    <UserMenu />
  </Stack>
);

export const MainMenu = ({ menuStructure }: Props) => (
  <MainLayoutGrid.NavMenu>
    <HasProjectOrEnvironmentContext
      inProjectOrEnvironmentContext={() => (
        <NavigationBar
          appMenuItems={menuStructure}
          ariaLabel={LandmarkAriaLabels.Application}
          {...getDataUiElementAttribute(DataUiElement.NavigationBar)}
        >
          {navigationBarContent}
        </NavigationBar>
      )}
      outOfProjectOrEnvironmentContext={() => (
        <NavigationBar ariaLabel={LandmarkAriaLabels.Application} appMenuItems={[]}>
          {navigationBarContent}
        </NavigationBar>
      )}
    />
  </MainLayoutGrid.NavMenu>
);
