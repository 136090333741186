import React from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { Task } from '../../../../../_shared/models/Task.ts';
import { DataUiCollection } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getContentItemPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getUsersByIds } from '../../../../../_shared/utils/usersUtils.ts';
import { TableCellDueDate } from '../../../../contentInventory/content/features/ContentItemInventory/components/TableCellDueDate.tsx';
import { ScrollTableItemContributors } from '../../../contentStatus/components/ScrollTableItemContributors.tsx';
import { useIsDemoModeEnabled } from '../../../contexts/MissionControlDemoContext.tsx';
import {
  StyledDataTableCell,
  StyledDataTableRow,
} from '../../shared/components/StyledDataTable.tsx';

interface ITasksAssignedByYouWidgetRowProps {
  readonly task: Task;
}

export const TasksAssignedByYouWidgetRow: React.FC<ITasksAssignedByYouWidgetRowProps> = ({
  task,
}) => {
  const isDemoModeEnabled = useIsDemoModeEnabled();
  const pathToItem = isDemoModeEnabled
    ? null // Disable redirect in demo mode
    : getContentItemPath(
        window.location.pathname,
        task.contentItemId.itemId,
        task.contentItemId.variantId,
      );

  const usersById = useSelector((s) => s.data.users.usersById);
  const assignees = getUsersByIds(usersById, Array.from(task.assignees));

  return (
    <StyledDataTableRow dataUiObjectName={DataUiCollection.ContentTasks} id={task.id}>
      <StyledDataTableCell linkPath={pathToItem}>{task.description}</StyledDataTableCell>
      <StyledDataTableCell linkPath={pathToItem}>
        <ScrollTableItemContributors contributors={assignees} />
      </StyledDataTableCell>
      <StyledDataTableCell linkPath={pathToItem}>
        <TableCellDueDate dueDate={task.dueDate} />
      </StyledDataTableCell>
    </StyledDataTableRow>
  );
};

TasksAssignedByYouWidgetRow.displayName = 'TasksAssignedByYouWidgetRow';
