import React from 'react';
import { ContentItemEditingEventOrigins } from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { CodenameForm } from '../../containers/editingActions/leafActionForms/CodenameForm.tsx';
import { ContributorsForm } from '../../containers/editingActions/leafActionForms/ContributorsForm.tsx';
import { CopyFromLanguageOptions } from '../../containers/editingActions/leafActionForms/CopyFromLanguageOptions.tsx';
import { DueDateForm } from '../../containers/editingActions/leafActionForms/DueDateForm.tsx';
import { DuplicationOptions } from '../../containers/editingActions/leafActionForms/DuplicationOptions.tsx';
import { NotSetupPreviewPopupForm } from '../../containers/editingActions/leafActionForms/NotSetupPreviewPopupForm.tsx';
import { NoteForm } from '../../containers/editingActions/leafActionForms/NoteForm.tsx';
import { PreviewLinksForm } from '../../containers/editingActions/leafActionForms/PreviewLinksForm.tsx';
import { ShareItemForm } from '../../containers/editingActions/leafActionForms/ShareItemForm.tsx';
import { TranslateFromLanguageOptions } from '../../containers/editingActions/leafActionForms/TranslateFromLanguageOptions.tsx';
import { EditingAction } from '../../models/EditingAction.ts';
import { AddTaskForm } from './leafActionForms/AddTaskForm.tsx';

export type UseCustomHandleClickOutside = (
  onClickOutside: () => void,
  shouldCloseOnClickOutside?: (element: HTMLElement) => boolean,
) => void;

export type LeafActionFormProps = {
  readonly actionOrigin: ContentItemEditingEventOrigins;
  readonly useCustomHandleClickOutside: UseCustomHandleClickOutside;
};

type EditingLeafActionFormProps = LeafActionFormProps & {
  readonly activatedAction: EditingAction;
};

export const EditingLeafActionForm: React.FC<EditingLeafActionFormProps> = ({
  activatedAction,
  ...leafActionFormProps
}) => {
  switch (activatedAction) {
    case EditingAction.assignPeople:
      return <ContributorsForm {...leafActionFormProps} />;

    case EditingAction.addTask:
      return <AddTaskForm {...leafActionFormProps} />;

    case EditingAction.changeNote:
    case EditingAction.setNote:
      return <NoteForm {...leafActionFormProps} />;

    case EditingAction.changeDueDate:
    case EditingAction.setDueDate:
      return <DueDateForm {...leafActionFormProps} />;

    case EditingAction.viewCodename:
      return <CodenameForm {...leafActionFormProps} />;

    case EditingAction.copyFromLanguage:
      return <CopyFromLanguageOptions {...leafActionFormProps} />;

    case EditingAction.setupItemPreview:
      return <NotSetupPreviewPopupForm {...leafActionFormProps} />;

    case EditingAction.previewItemInMultipleSpaces:
      return <PreviewLinksForm {...leafActionFormProps} />;

    case EditingAction.previewSampleAppItem:
      return <NotSetupPreviewPopupForm {...leafActionFormProps} />;

    case EditingAction.shareLink:
      return <ShareItemForm {...leafActionFormProps} />;

    case EditingAction.showDuplicationOptions:
      return <DuplicationOptions {...leafActionFormProps} />;

    case EditingAction.translateVariant:
      return <TranslateFromLanguageOptions {...leafActionFormProps} />;

    default:
      return null;
  }
};
