import { ContentItemId } from '../../../../_shared/models/ContentItemId.type.ts';
import { PublishingState } from '../../../../data/constants/PublishingState.ts';
import {
  IActiveCapabilitiesForVariant,
  createActiveCapabilitiesForVariantFromServerModel,
} from '../../../../data/models/activeCapabilities.ts';
import { IListingContentItem } from '../../../../data/models/listingContentItems/IListingContentItem.ts';
import { IContentItemVariantReferenceServerModel } from '../../../../repositories/serverModels/ContentItemUsageModel.type.ts';
import { IAssignment, getAssignmentFromServerModel } from './Assignment.ts';

export interface IContentItemVariantReference {
  readonly activeCapabilities: IActiveCapabilitiesForVariant;
  readonly assignment: IAssignment;
  readonly id: ContentItemId;
  readonly isArchived: boolean;
  readonly isPublishedVersion: boolean;
  readonly name: string;
  readonly publishingState: PublishingState;
  readonly typeId: Uuid;
}

export const getContentItemVariantReferenceFromServerModel = (
  serverModel: IContentItemVariantReferenceServerModel,
): IContentItemVariantReference => ({
  activeCapabilities: createActiveCapabilitiesForVariantFromServerModel(
    serverModel.activeCapabilities,
  ),
  assignment: getAssignmentFromServerModel(serverModel.assignment),
  id: serverModel._id,
  isArchived: serverModel.archived,
  isPublishedVersion: serverModel.isPublishedVersion,
  name: serverModel.name,
  publishingState: serverModel.publishingState,
  typeId: serverModel.typeId,
});

export const getContentItemVariantReferenceFromListingItem = (
  listingItem: IListingContentItem | null,
): IContentItemVariantReference | null =>
  listingItem?.variant
    ? {
        activeCapabilities: listingItem.activeCapabilities,
        assignment: listingItem.variant.assignment,
        id: listingItem.variant.id,
        isArchived: listingItem.variant.isArchived,
        isPublishedVersion: listingItem.isPublishedVersion,
        name: listingItem.item.name,
        publishingState: listingItem.variant.publishingState,
        typeId: listingItem.item.typeId,
      }
    : null;
