import PropTypes from 'prop-types';
import React from 'react';
import { Loader, LoaderType } from '../../../../_shared/components/Loader.tsx';
import { SidebarSection } from '../../../../_shared/components/Sidebar/SidebarSection.tsx';
import { SidebarSubmitButton } from '../../../../_shared/components/Sidebar/SidebarSubmitButton.tsx';
import { Sidebar } from '../../../../_shared/containers/Sidebar/SidebarContainer.tsx';
import { CommonPlanName } from '../../../../data/constants/CommonPlanName.ts';
import { IPlan } from '../../../../data/models/plans/Plan.ts';
import { ISubscription } from '../../../../data/models/subscriptions/Subscription.ts';
import { FastSpringWidget } from '../../BillingInformation/containers/FastSpringWidget.tsx';
import { PlanUsageComparison, getPlanName } from '../../shared/utils/planUtils.ts';

type ChangePlanModalProps = {
  readonly closeDialog: () => void;
  readonly showSidebar: boolean;
  readonly currentSubscription: ISubscription;
  readonly changeIsInProgress: boolean;
  readonly changeSubscriptionPlan: (plan: string, id: Uuid, skipCreditCardCheck?: boolean) => void;
  readonly planComparison: PlanUsageComparison;
  readonly targetPlan?: IPlan;
  readonly currentPlan: IPlan;
  readonly isPlanDowngrade: boolean;
  readonly hasExtraUsage: boolean;
};

const propTypes: PropTypesShape<ChangePlanModalProps> = {
  showSidebar: PropTypes.bool.isRequired,
  currentSubscription: PropTypes.object.isRequired,
  changeIsInProgress: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  changeSubscriptionPlan: PropTypes.func.isRequired,
  targetPlan: PropTypes.object,
  currentPlan: PropTypes.object.isRequired,
  planComparison: PropTypes.object.isRequired,
  isPlanDowngrade: PropTypes.bool.isRequired,
  hasExtraUsage: PropTypes.bool.isRequired,
};

export const ChangePlanModal: React.FC<ChangePlanModalProps> = ({
  showSidebar,
  currentSubscription,
  changeIsInProgress,
  closeDialog,
  changeSubscriptionPlan,
  planComparison,
  targetPlan,
  currentPlan,
  isPlanDowngrade,
  hasExtraUsage,
}) => {
  if (!targetPlan) {
    return (
      <Sidebar isVisible={showSidebar} onClose={closeDialog} titleElement="Change plan">
        <SidebarSection>
          <Loader text="Loading plan details" type={LoaderType.Sidebar} />
        </SidebarSection>
      </Sidebar>
    );
  }

  const targetPlanNamePrefix = getPlanName(targetPlan.name);
  const currentPlanNamePrefix = getPlanName(currentPlan.name);

  const isChangeDisabled = planComparison.isExceeding;
  const creditCardNotConnected =
    (targetPlanNamePrefix === CommonPlanName.Professional ||
      targetPlanNamePrefix === CommonPlanName.Business ||
      targetPlanNamePrefix === CommonPlanName.Premium ||
      hasExtraUsage) &&
    !currentSubscription.isFsSubscriptionConnected;
  const subscriptionIsActive = currentSubscription.isActive;
  const targetIsDeveloperPlan = targetPlanNamePrefix === CommonPlanName.Developer;
  const onSubmit = () =>
    changeSubscriptionPlan(
      targetPlan.name,
      currentSubscription.subscriptionId,
      creditCardNotConnected,
    );

  const submitButton = !isChangeDisabled && (
    <div className="sidebar__submit-action">
      {creditCardNotConnected ? (
        <FastSpringWidget
          buttonText="Select plan"
          onFastSpringAccountConnected={onSubmit}
          buttonIsPrimary
        />
      ) : (
        <SidebarSubmitButton
          disabled={changeIsInProgress}
          isBeingSubmitted={changeIsInProgress}
          onSubmit={onSubmit}
          text={changeIsInProgress ? 'Changing plan' : 'Select plan'}
        />
      )}
    </div>
  );

  return (
    <Sidebar
      isVisible={showSidebar}
      onClose={closeDialog}
      onSubmit={changeIsInProgress ? undefined : onSubmit}
      titleElement="Selecting a plan"
      submitElement={submitButton}
    >
      {!isChangeDisabled && (
        <SidebarSection>
          <div className="plan-selection__change-modal">
            <p>You are about to select a {targetPlan.displayName} plan.</p>
            <p>What will happen?</p>
            <ul>
              {!isPlanDowngrade && creditCardNotConnected && (
                <li>You will be asked to enter your credit card details.</li>
              )}
              <li>The new plan will be effective immediately.</li>
              <li>You will start a new billing period.</li>
              {(isPlanDowngrade || !currentPlan.isTrial) && (
                <>
                  {subscriptionIsActive &&
                    ((currentPlanNamePrefix !== CommonPlanName.Starter &&
                      currentPlanNamePrefix !== CommonPlanName.Developer) ||
                      hasExtraUsage) && (
                      <li>
                        We will charge you only for the duration of your previous billing period.
                      </li>
                    )}
                  {!targetIsDeveloperPlan && (
                    <li>
                      Your credit card will not be charged right now{' '}
                      {subscriptionIsActive &&
                        'and you can always switch back to the previous plan.'}
                    </li>
                  )}
                </>
              )}
              {isPlanDowngrade && subscriptionIsActive && (
                <li>
                  You will lose access to any features exclusive to the {currentPlan.displayName}{' '}
                  plan.
                </li>
              )}
              {!isPlanDowngrade && !targetIsDeveloperPlan && !currentPlan.isTrial && (
                <li>
                  You will gain access to features exclusive to the {targetPlan.displayName} plan.
                </li>
              )}
            </ul>
          </div>
        </SidebarSection>
      )}
    </Sidebar>
  );
};

ChangePlanModal.displayName = 'ChangePlanModal';
ChangePlanModal.propTypes = propTypes;
