import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Placement } from '@kontent-ai/component-library/types';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { MouseEventHandler } from 'react';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import { Icon } from '../../../../../_shared/uiComponents/Icon/Icon.tsx';
import { getDataAttributeProps } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IForwardedRefProps, forwardRef } from '../../../../../_shared/utils/forwardedRefProps.tsx';

const LeftMouseButton = 0;

const onToolSelectedWithLeftButtonVerification =
  (action: MouseEventHandler<HTMLButtonElement>) =>
  (e: React.MouseEvent<HTMLButtonElement>): void => {
    if (e.button === LeftMouseButton) {
      action(e);
    }
  };

export interface ISpecificButtonProps {
  readonly disabled?: boolean;
  readonly isFocusable?: boolean;
  readonly hasHiddenShortcut?: boolean;
  readonly onClick: MouseEventHandler<HTMLButtonElement>;
  readonly tooltipPlacement?: Placement;
  readonly tooltipText?: string;
}

export const specificButtonPropTypes: PropTypesShape<ISpecificButtonProps> = {
  disabled: PropTypes.bool,
  isFocusable: PropTypes.bool,
  hasHiddenShortcut: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  tooltipPlacement: PropTypes.string,
  tooltipText: PropTypes.string,
};

export interface IToggleButtonProps extends ISpecificButtonProps {
  readonly isActive?: boolean;
  readonly isViolated?: boolean;
}

export const toggleButtonPropTypes: PropTypesShape<IToggleButtonProps> = {
  ...specificButtonPropTypes,
  isActive: PropTypes.bool,
  isViolated: PropTypes.bool,
};

export interface IRTEToolbarButtonProps {
  readonly disabled?: boolean;
  readonly hasHiddenShortcut?: boolean;
  readonly hasIcon?: boolean;
  readonly isActive?: boolean;
  readonly isFocusable?: boolean;
  readonly isViolated?: boolean;
  readonly onClick: MouseEventHandler<HTMLButtonElement>;
  readonly shortcut?: string;
  readonly tooltipPlacement: Placement;
  readonly tooltipText?: string;
}

const buttonPropTypes: PropTypesShape<IRTEToolbarButtonProps> = {
  disabled: PropTypes.bool,
  hasHiddenShortcut: PropTypes.bool,
  hasIcon: PropTypes.bool,
  isActive: PropTypes.bool,
  isFocusable: PropTypes.bool,
  isViolated: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  shortcut: PropTypes.string,
  tooltipPlacement: PropTypes.string,
  tooltipText: PropTypes.string,
};

const RTEToolbarButtonComponent: React.FC<
  React.PropsWithChildren<IRTEToolbarButtonProps & IForwardedRefProps<HTMLButtonElement>>
> = ({
  children,
  disabled,
  forwardedRef,
  hasHiddenShortcut,
  hasIcon,
  isActive,
  isFocusable,
  isViolated,
  onClick,
  shortcut,
  tooltipPlacement,
  tooltipText,
  ...rest
}) => (
  <Tooltip
    tooltipText={tooltipText}
    shortcuts={disabled || hasHiddenShortcut ? undefined : shortcut}
    placement={tooltipPlacement}
  >
    <span className="rte-toolbar-button">
      {' '}
      {/* Wrapper needed so that Tooltip works when button is disabled */}
      <button
        ref={forwardedRef}
        tabIndex={isFocusable ? 0 : -1}
        className={classNames('rte-toolbar-button__button', {
          'rte-toolbar-button__button--has-icon-as-text': !hasIcon,
          'rte-toolbar-button__button--is-active': isActive,
          'rte-toolbar-button__button--is-violated': isViolated,
        })}
        onClick={onToolSelectedWithLeftButtonVerification(onClick)}
        disabled={disabled}
        {...getDataAttributeProps(rest)}
      >
        {children}
      </button>
    </span>
  </Tooltip>
);

RTEToolbarButtonComponent.propTypes = buttonPropTypes;

export const RTEToolbarButton = forwardRef(RTEToolbarButtonComponent);

export interface IRTEToolbarIconButtonProps extends IRTEToolbarButtonProps {
  readonly iconName: IconName;
}

const iconButtonPropsTypes: PropTypesShape<IRTEToolbarIconButtonProps> = {
  ...buttonPropTypes,
  iconName: PropTypes.string.isRequired,
};

const RTEToolbarIconButtonComponent: React.FC<
  IRTEToolbarIconButtonProps & IForwardedRefProps<HTMLButtonElement>
> = ({ iconName, tooltipText, forwardedRef, ...rest }) => (
  <RTEToolbarButton ref={forwardedRef} tooltipText={tooltipText} hasIcon={!!iconName} {...rest}>
    <Icon iconName={iconName} screenReaderText={tooltipText} />
  </RTEToolbarButton>
);

RTEToolbarIconButtonComponent.propTypes = iconButtonPropsTypes;

export const RTEToolbarIconButton = forwardRef(RTEToolbarIconButtonComponent);
