import { isString } from '../../../../../_shared/utils/stringUtils.ts';
import {
  createSelection,
  getMetadataAtSelection,
  setContentSelection,
} from '../../../utils/editorSelectionUtils.ts';
import {
  IContentChangeInput,
  IContentChangeResult,
} from '../../../utils/general/editorContentUtils.ts';
import { aggregateAnyChars } from '../../../utils/metadata/editorMetadataUtils.ts';
import { applyInlineStyleForSelectedChars } from '../../inlineStyles/api/editorStyleUtils.ts';
import { DraftJSInlineStyle } from '../../inlineStyles/api/inlineStyles.ts';
import { updateText } from '../../textApi/api/editorTextUtils.ts';

export enum MarkdownInlineShorthand {
  Code = '`',
  Bold = '*',
  Italic = '_',
}

export type MarkdownInlineConversionResult = {
  readonly markdownShorthand: MarkdownInlineShorthand;
};

const inlineMarkdownShorthands: ReadonlyArray<MarkdownInlineShorthand> =
  Object.values(MarkdownInlineShorthand);

type InlineConversion = {
  readonly shorthand: MarkdownInlineShorthand;
  readonly convertedFrom: string;
  readonly convertedTo: string;
};

function isWhitespace(ch: string): boolean {
  return /\s/.test(ch);
}

function isAllowedSeparator(separator: string | undefined, forShorthand: string): boolean {
  // Allow whitespace or other shorthand character as a separator in case one wants to apply more shorthands at once
  return (
    isString(separator) &&
    (isWhitespace(separator) ||
      (inlineMarkdownShorthands.includes(separator as MarkdownInlineShorthand) &&
        isString(forShorthand[0]) &&
        !separator.startsWith(forShorthand[0])))
  );
}

export const evaluateMarkdownInlineConversion = (
  evaluatedChars: string,
): InlineConversion | null => {
  const eligibleShorthand = inlineMarkdownShorthands.find((shorthand) =>
    evaluatedChars.endsWith(shorthand),
  );
  if (!eligibleShorthand) {
    return null;
  }

  const endShorthandIndex = evaluatedChars.length - eligibleShorthand.length;

  const charBeforeShorthand = evaluatedChars[endShorthandIndex - 1];
  if (!charBeforeShorthand) {
    return null;
  }

  // Do not convert immediately after white space so that _variable1 _variable2 doesn't get converted
  if (isWhitespace(charBeforeShorthand)) {
    return null;
  }

  const startShorthandIndex = evaluatedChars.lastIndexOf(
    eligibleShorthand,
    endShorthandIndex - eligibleShorthand.length,
  );
  const separator = evaluatedChars[startShorthandIndex - 1];

  if (
    startShorthandIndex >= 0 &&
    // There must be at least one extra character between shorthands so we have something to convert and do not drop shorthand chars on their own
    startShorthandIndex < endShorthandIndex - eligibleShorthand.length &&
    // Shorthand start must be at the start of the block or preceded by whitespace
    // so that we don`t accidentally convert earlier text containing shorthand chars, e.g. f**k
    (startShorthandIndex === 0 || isAllowedSeparator(separator, eligibleShorthand))
  ) {
    return {
      shorthand: eligibleShorthand,
      convertedFrom: evaluatedChars.substring(
        startShorthandIndex,
        endShorthandIndex + eligibleShorthand.length,
      ),
      convertedTo: evaluatedChars.substring(
        startShorthandIndex + eligibleShorthand.length,
        endShorthandIndex,
      ),
    };
  }

  return null;
};

export const MarkdownInlineShorthandStyleMap: {
  readonly [key in MarkdownInlineShorthand]: DraftJSInlineStyle;
} = {
  [MarkdownInlineShorthand.Code]: DraftJSInlineStyle.Code,
  [MarkdownInlineShorthand.Bold]: DraftJSInlineStyle.Bold,
  [MarkdownInlineShorthand.Italic]: DraftJSInlineStyle.Italic,
} as const;

export const applyAutomaticMarkdownInlineConversion = (
  input: IContentChangeInput,
  onMarkdownConversion?: (conversionResult: MarkdownInlineConversionResult) => void,
): IContentChangeResult => {
  const { content, selection } = input;

  if (!selection.isCollapsed()) {
    return input;
  }

  const currentBlockKey = selection.getStartKey();
  const positionAfterShorthand = selection.getStartOffset();
  const currentBlock = content.getBlockForKey(currentBlockKey);

  const previousCharacters = currentBlock.getText().substr(0, positionAfterShorthand);
  const conversion = evaluateMarkdownInlineConversion(previousCharacters);
  if (!conversion) {
    return input;
  }

  const positionBeforeShorthand = positionAfterShorthand - conversion.convertedFrom.length;
  const selectionOverShorthand = createSelection(
    currentBlockKey,
    positionBeforeShorthand,
    currentBlockKey,
    positionAfterShorthand,
  );

  // Do not convert if style is already applied
  const metadataAtSelection = getMetadataAtSelection(content, selectionOverShorthand);
  if (!metadataAtSelection) {
    return input;
  }

  const style = MarkdownInlineShorthandStyleMap[conversion.shorthand];
  const stylesAtShorthand = aggregateAnyChars(
    metadataAtSelection.styleAtAnyTopLevelChars,
    metadataAtSelection.styleAtAnyTableChars,
  );
  if (stylesAtShorthand?.contains(style)) {
    return input;
  }

  const withStyleApplied = applyInlineStyleForSelectedChars(
    {
      content: input.content,
      selection: selectionOverShorthand,
    },
    style,
  );

  const shorthandStartSelection = createSelection(
    currentBlockKey,
    positionBeforeShorthand,
    currentBlockKey,
    positionBeforeShorthand + conversion.shorthand.length,
  );
  const withShorthandStartRemoved = updateText(
    {
      content: withStyleApplied.content,
      selection: shorthandStartSelection,
    },
    '',
  );

  const shorthandEndSelection = createSelection(
    currentBlockKey,
    positionAfterShorthand - 2 * conversion.shorthand.length,
    currentBlockKey,
    positionAfterShorthand - conversion.shorthand.length,
  );
  const withShorthandEndRemoved = updateText(
    {
      content: withShorthandStartRemoved.content,
      selection: shorthandEndSelection,
    },
    '',
  );

  const result = {
    content: setContentSelection(
      withShorthandEndRemoved.content,
      input.selection,
      withShorthandEndRemoved.selection,
    ),
    selection: withShorthandEndRemoved.selection,
    wasModified: true,
  };

  if (onMarkdownConversion) {
    onMarkdownConversion({
      markdownShorthand: conversion.shorthand,
    });
  }

  return result;
};
