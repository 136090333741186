import { assert } from '@kontent-ai/utils';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import {
  ContentItemPreviewRoute,
  ContentItemRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import {
  matchPath,
  parseContentItemIds,
} from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { loadItemPreviewInfo } from '../../actions/thunkWebSpotlightActions.ts';
import { itemPreviewInfoReloadRequested } from '../../actions/webSpotlightActions.ts';
import { WebSpotlightPreviewContent } from './WebSpotlightPreviewContent.tsx';

export const WebSpotlightSmartPreview: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const itemPreviewInfo = useSelector((state) => state.webSpotlightApp.itemPreviewInfo);
  const isPreviewConfigurationLoaded = useSelector(
    (state) => !!state.contentApp.previewConfiguration,
  );
  const sharedPreviewUrl = useSelector(
    (state) => state.webSpotlightApp.sharedPreviewData?.url ?? null,
  );
  const currentPath = location.pathname;
  const matchedParams = matchPath<ContentItemRouteParams<string>>(
    currentPath,
    ContentItemPreviewRoute,
  );
  assert(matchedParams, () => 'Current route does not fit to ContentItemPreviewRoute');
  const routeContentItemIds = parseContentItemIds(matchedParams.contentItemIds);
  const isCorrectPreviewInfoLoaded = itemPreviewInfo?.routeContentItemIds === routeContentItemIds;

  useEffect(() => {
    dispatch(itemPreviewInfoReloadRequested());
  }, []);

  useEffect(() => {
    if (!isCorrectPreviewInfoLoaded && isPreviewConfigurationLoaded) {
      dispatch(loadItemPreviewInfo(routeContentItemIds));
    }
  }, [isCorrectPreviewInfoLoaded, routeContentItemIds, isPreviewConfigurationLoaded]);

  if (!itemPreviewInfo || !isCorrectPreviewInfoLoaded || !isPreviewConfigurationLoaded) {
    return <Loader />;
  }

  return (
    <WebSpotlightPreviewContent
      previewContentItemInfo={itemPreviewInfo.previewContentItemInfo}
      previewUrlInfo={itemPreviewInfo.previewUrlInfo}
      sharedPreviewUrl={sharedPreviewUrl}
    />
  );
};

WebSpotlightSmartPreview.displayName = 'WebSpotlightSmartPreview';
