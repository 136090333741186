import React, { useCallback } from 'react';
import { FindRightAssetTrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import {
  closeAssetSelectorOnCancel,
  closeAssetSelectorOnInsert,
} from '../../../actions/thunkAssetLibraryActions.ts';
import { AssetsOrderBy } from '../../../types/orderBy.type.ts';
import { initializeAssetDialog } from '../actions/thunkModalAssetSelectorActions.ts';
import {
  IModalAssetSelectorOwnProps,
  ModalAssetSelector as ModalAssetSelectorComponent,
} from '../components/ModalAssetSelector.tsx';

export const ModalAssetSelector: React.FC<IModalAssetSelectorOwnProps> = ({
  onClose,
  onSelect,
  ...otherProps
}) => {
  const dispatch = useDispatch();

  const onAssetSelect = useCallback(
    (assetId: Uuid) => {
      dispatch(closeAssetSelectorOnInsert([assetId]));
      onSelect(assetId);
    },
    [onSelect],
  );

  const onCancel = useCallback(() => {
    dispatch(closeAssetSelectorOnCancel(FindRightAssetTrackedEvent.ClosedModalSelector));
    onClose?.();
  }, [onClose]);

  const onInit = useCallback(
    (orderBy: AssetsOrderBy) => {
      dispatch(initializeAssetDialog({ orderBy, showImagesOnly: !!otherProps.showImagesOnly }));
    },
    [otherProps.showImagesOnly],
  );

  return (
    <ModalAssetSelectorComponent
      {...otherProps}
      onSelect={onAssetSelect}
      onClose={onCancel}
      onInit={onInit}
    />
  );
};
