import React from 'react';
import { modalDismissed } from '../../../../_shared/actions/sharedActions.ts';
import { ConfirmationDialog } from '../../../../_shared/components/ModalDialog/ConfirmationDialog.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { compose } from '../../../../_shared/utils/func/compose.ts';
import { taxonomyTermRemoved } from '../actions/taxonomyActions.ts';
import { getNode } from '../utils/taxonomyTreeUtils.ts';

export const TermRemoveConfirmationDialog: React.FC = () => {
  const termId = useSelector((s) => s.taxonomyApp.editedTaxonomyTermId);
  const termName = useSelector(
    (s) => getNode(s.taxonomyApp.editedTaxonomyGroup, termId)?.name || '',
  );
  const dispatch = useDispatch();

  const onClose = compose(dispatch, modalDismissed);
  const onConfirm = () => dispatch(taxonomyTermRemoved(termId));

  return (
    <ConfirmationDialog
      closeImmediatelyAfterConfirm
      confirmButtonStyle="primary"
      confirmButtonDestructive
      confirmButtonText="Remove taxonomy terms"
      headerContent="Remove taxonomy term"
      onClose={onClose}
      onConfirm={onConfirm}
    >
      <p>
        This will also remove <strong>all nested taxonomy terms.</strong>
      </p>
      <p>
        Do you want to remove <strong>{termName}</strong> and all its nested terms?
      </p>
    </ConfirmationDialog>
  );
};

TermRemoveConfirmationDialog.displayName = 'TermRemoveConfirmationDialog';
