import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { BarItemAnimation } from '../../../_shared/components/BarItems/BarItemAnimation.tsx';
import { IWebhookSetting } from '../../../data/models/webhooks/WebhookSetting.ts';
import { WebhookBar } from '../containers/webhookBarItems/WebhookBar.tsx';
import { WebhookItemEditor } from '../containers/webhookEditors/WebhookItemEditor.tsx';

type Props = {
  readonly isEdited: boolean;
  readonly webhookSetting: IWebhookSetting;
};

export const WebhookItem: React.FC<Props> = ({ webhookSetting, isEdited }) => (
  <li className={classNames('bar-item__node', isEdited && 'bar-item__node--with-top-margin')}>
    <BarItemAnimation
      estimatedMaxHeightWhenExpanded={1200}
      renderCollapsed={() => <WebhookBar webhookSetting={webhookSetting} />}
      renderExpanded={() => <WebhookItemEditor webhookSetting={webhookSetting} />}
      shouldBeExpanded={isEdited}
    />
  </li>
);

WebhookItem.displayName = 'WebhookItem';
WebhookItem.propTypes = {
  isEdited: PropTypes.bool.isRequired,
  webhookSetting: PropTypes.object.isRequired as any,
};
