import { Box } from '@kontent-ai/component-library/Box';
import { Toggle } from '@kontent-ai/component-library/Toggle';
import { Spacing } from '@kontent-ai/component-library/tokens';
import Immutable from 'immutable';
import ImmutablePropTypes from 'immutable-prop-types';
import PropTypes from 'prop-types';
import React from 'react';
import {
  DataUiCollection,
  DataUiSwitchAction,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { DefaultSkinSidebar } from '../../../../contentInventory/shared/components/DefaultSkinSidebar.tsx';
import { RevisionTimelineList } from '../containers/RevisionTimelineList.tsx';

export type RevisionTimelineProps = {
  readonly isInitialized: boolean;
  readonly onCompareWithPrevious: () => void;
  readonly paths: Immutable.Map<Uuid, string> | null;
};

const propTypes: PropTypesShape<RevisionTimelineProps> = {
  onCompareWithPrevious: PropTypes.func.isRequired,
  paths: ImmutablePropTypes.map,
  isInitialized: PropTypes.bool.isRequired,
};

export const RevisionTimeline: React.FC<RevisionTimelineProps> = (props) => {
  return props.isInitialized ? (
    <DefaultSkinSidebar
      key="versions"
      {...getDataUiCollectionAttribute(DataUiCollection.Revisions)}
    >
      <Box padding={Spacing.XXL} paddingBottom={Spacing.XL}>
        <Toggle
          status="off"
          labelText="Compare versions"
          onToggleOn={props.onCompareWithPrevious}
          {...getDataUiActionAttribute(DataUiSwitchAction.SwitchDifferences)}
        />
      </Box>
      <RevisionTimelineList paths={props.paths} />
    </DefaultSkinSidebar>
  ) : null;
};

RevisionTimeline.displayName = 'RevisionTimeline';
RevisionTimeline.propTypes = propTypes;
