import { Card } from '@kontent-ai/component-library/Card';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, spacingCard } from '@kontent-ai/component-library/tokens';
import { DataUiCLElement, getDataUiCLElementAttribute } from '@kontent-ai/component-library/utils';
import PropTypes from 'prop-types';
import React, { WeakValidationMap } from 'react';
import { DialogState } from '../DialogStateEnum.ts';
import {
  DialogFooterAction,
  IDialogAction,
  IDialogActionWithMoreOptions,
  dialogActionPropTypes,
  dialogActionWithMoreOptionsPropTypes,
} from './DialogFooterAction.tsx';

export interface IDialogFooterProps {
  readonly dialogState: DialogState;
  readonly isDismissable: boolean;
  readonly onClose?: () => void;
  readonly primaryAction?: IDialogActionWithMoreOptions;
  readonly cancelAction?: IDialogAction;
  readonly extraAction?: IDialogActionWithMoreOptions;
  readonly renderNotificationBar?: () => React.ReactNode;
}

export const dialogFooterPropTypes: Required<WeakValidationMap<IDialogFooterProps>> = {
  dialogState: PropTypes.oneOf(Object.values(DialogState)).isRequired,
  isDismissable: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  primaryAction: PropTypes.shape<WeakValidationMap<IDialogActionWithMoreOptions>>(
    dialogActionWithMoreOptionsPropTypes,
  ),
  cancelAction: PropTypes.shape<WeakValidationMap<IDialogAction>>(dialogActionPropTypes),
  extraAction: PropTypes.shape<WeakValidationMap<IDialogActionWithMoreOptions>>(
    dialogActionWithMoreOptionsPropTypes,
  ),
  renderNotificationBar: PropTypes.func,
};

export const DialogFooter: React.FC<IDialogFooterProps> = ({
  dialogState,
  isDismissable,
  onClose,
  primaryAction,
  cancelAction,
  extraAction,
  renderNotificationBar,
}) => (
  <Card.Footer>
    <Stack spacing={spacingCard}>
      {renderNotificationBar?.()}
      <Row spacing={Spacing.M}>
        <Column>
          {extraAction && (
            <DialogFooterAction
              buttonStyle="secondary"
              dialogState={dialogState}
              {...extraAction}
            />
          )}
        </Column>
        <Column width="content">
          <Inline spacing={Spacing.M}>
            {isDismissable && onClose && (
              <DialogFooterAction
                buttonStyle="secondary"
                text="Cancel"
                dialogState={dialogState}
                {...cancelAction}
                onClick={(e) => {
                  cancelAction?.onClick?.(e);
                  onClose();
                }}
              />
            )}
            {primaryAction && (
              <DialogFooterAction
                buttonStyle="primary"
                dialogState={dialogState}
                {...primaryAction}
                {...getDataUiCLElementAttribute(DataUiCLElement.DialogPrimaryAction)}
              />
            )}
          </Inline>
        </Column>
      </Row>
    </Stack>
  </Card.Footer>
);

DialogFooter.displayName = 'DialogFooter';
DialogFooter.propTypes = dialogFooterPropTypes;
