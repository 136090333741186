import React, { useMemo, useState } from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { areCollectionPermissionsEnabledForCurrentProject } from '../../../../../_shared/selectors/contentCollections.ts';
import { compose } from '../../../../../_shared/utils/func/compose.ts';
import { always } from '../../../../../_shared/utils/func/functionalTools.ts';
import { hasToUsePaidUserSlot } from '../../../../../_shared/utils/subscriptionUsageUtils.ts';
import { getCurrentProject } from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { areLanguageRolesEnabledForCurrentProject } from '../../../selectors/allowedFeaturesUtils.ts';
import { initializeInviteModal, submitInvitations } from '../../actions/thunkUsersActions.ts';
import {
  changeInvitations,
  changeSearchText,
  closeInviteModal,
  hideNotificationBarInInvitation,
  invitationCollectionGroupsChanged,
} from '../../actions/usersActions.ts';
import {
  IInvitationModalDispatchProps,
  IInvitationModalStateProps,
  InvitationModal as InvitationModalComponent,
} from '../../components/invitations/InvitationModal.tsx';
import { invalidInvitationsInviteButtonTooltip } from '../../constants/errorMessages.ts';
import { useProjectManagerSelectionValidation } from '../../hooks/useProjectManagerSelectionValidation.ts';
import { getAdvancedRoleBuilderErrorMessage } from '../../selectors/getAdvancedRoleBuilderErrorMessage.ts';
import { hasInviteUnsavedChanges } from '../../selectors/inviteModalValidators.ts';
import {
  createDisabledInviteTooltip,
  createInvitationEmailInputValidationMessages,
  getNumberOfUsersAboveSubscriptionLimit,
} from '../../selectors/userModalsMessageSelectors.ts';
import { findCollidingContributorEmails } from '../../utils/findCollidingContributorEmails.ts';
import { getNewEmailsCountedInSubscriptionLimitsSelector } from '../../utils/getNewEmailsCountedInSubscriptionLimits.ts';

interface IOwnProps {
  readonly userListingLink: string;
  readonly createUserDetailLink: (userId: UserId) => string;
  readonly initialUrlEncodedEmail: string | undefined;
}

export const InvitationModal: React.FC<IOwnProps> = (props) => {
  const [triedInviteWithNonEmptyText, setTriedInviteWithNonEmptyText] = useState(false);

  const collectionGroups = useSelector((s) => s.usersApp.userInvitation.collectionGroups);
  const existingUsersByEmail = useSelector((s) => s.usersApp.userInvitation.existingUsersByEmail);
  const invitations = useSelector((s) => s.usersApp.userInvitation.invitations);
  const isInitialized = useSelector((s) => s.usersApp.userInvitation.isInitialized);
  const isSaving = useSelector((s) => s.usersApp.userInvitation.isSaving);
  const savingError = useSelector((s) => s.usersApp.userInvitation.savingError);
  const isSearchTextEmpty = useSelector(
    (s) => s.usersApp.userInvitation.searchText.trim().length === 0,
  );
  const isProjectManagerSelectionValid = useProjectManagerSelectionValidation(collectionGroups);

  const dispatch = useDispatch();
  const callbackProps = useMemo(
    (): Omit<IInvitationModalDispatchProps, 'submitInvitations'> => ({
      changeSearchText: compose(
        setTriedInviteWithNonEmptyText,
        always(false),
        dispatch,
        changeSearchText,
      ),
      hideNotificationBar: compose(dispatch, hideNotificationBarInInvitation),
      onClose: compose(dispatch, closeInviteModal),
      onInitialize: compose(dispatch, initializeInviteModal),
      onCollectionGroupsChange: compose(dispatch, invitationCollectionGroupsChanged),
      changeInvitations: compose(dispatch, changeInvitations),
    }),
    [],
  );

  const submit = useMemo(
    () =>
      isSearchTextEmpty
        ? compose(dispatch, submitInvitations)
        : compose(setTriedInviteWithNonEmptyText, always(true)),
    [isSearchTextEmpty],
  );

  const projectContributors = useSelector((s) => s.usersApp.projectContributors);
  const disabledInviteTooltip = useSelector(createDisabledInviteTooltip);
  const emailInputValidationMessages = useSelector((s) =>
    createInvitationEmailInputValidationMessages(s, triedInviteWithNonEmptyText),
  );
  const hasUnsavedChanges = useSelector(hasInviteUnsavedChanges);
  const areLanguageRolesEnabled = useSelector(areLanguageRolesEnabledForCurrentProject);
  const areCollectionPermissionsEnabled = useSelector(
    areCollectionPermissionsEnabledForCurrentProject,
  );

  const { createUserDetailLink, userListingLink } = props;

  const newEmails = useSelector(getNewEmailsCountedInSubscriptionLimitsSelector);
  const hasToPayForNewUsers = useSelector((state) => hasToUsePaidUserSlot(state, newEmails));
  const numberOfUsersAboveSubscriptionLimit = useSelector(getNumberOfUsersAboveSubscriptionLimit);
  const environmentName = useSelector((s) => getCurrentProject(s).environmentName);
  const errorMessage = getAdvancedRoleBuilderErrorMessage(
    collectionGroups,
    areLanguageRolesEnabled,
    areCollectionPermissionsEnabled,
    isProjectManagerSelectionValid,
  );

  const stateProps: IInvitationModalStateProps = {
    collectionGroups,
    collidingContributorEmails: findCollidingContributorEmails(projectContributors, invitations),
    createUserDetailLink,
    disabledInviteTooltip:
      !disabledInviteTooltip && triedInviteWithNonEmptyText
        ? invalidInvitationsInviteButtonTooltip
        : disabledInviteTooltip,
    emailInputValidationMessages,
    environmentName,
    errorMessage,
    existingUsersByEmail,
    hasUnsavedChanges,
    emailsCountedInSubscriptionLimits: newEmails,
    initialEmail: props.initialUrlEncodedEmail && decodeURIComponent(props.initialUrlEncodedEmail),
    invitations,
    isInitialized,
    isInviting: isSaving,
    savingError,
    shouldConfirmInvite: hasToPayForNewUsers && isSearchTextEmpty,
    shouldHighlightNewUsers: numberOfUsersAboveSubscriptionLimit > 0 || hasToPayForNewUsers,
    userListingLink,
  };

  return <InvitationModalComponent {...stateProps} {...callbackProps} submitInvitations={submit} />;
};
