import { InputState } from '@kontent-ai/component-library/Input';
import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { ComponentProps, forwardRef } from 'react';
import { DefaultTag } from '../../../../../../component-library/components/Tag/DefaultTag.tsx';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  formatCurrentUserName,
  formatUserName,
  makeCurrentUserFirst,
  userLastNameComparer,
} from '../../../../../_shared/utils/usersUtils.ts';
import { IProjectContributor } from '../../../../../data/models/users/ProjectContributor.ts';

type ContributorSelectorProps = {
  readonly assignedContributors: ReadonlyArray<UserId>;
  readonly autoFocus?: boolean;
  readonly availableContributors: ReadonlyArray<IProjectContributor>;
  readonly className?: string;
  readonly currentUserId: UserId;
  readonly isDisabled?: boolean;
  readonly onContributorListChanged: (contributors: ReadonlyArray<IProjectContributor>) => void;
  readonly validationMessage?: string;
};

interface ContributorOption {
  readonly id: UserId;
  readonly label: string;
  readonly tagLabel: string;
}

const getInputState = (
  isDisabled: boolean,
  validationMessage: string | null | undefined,
): InputState => {
  if (isDisabled) {
    return InputState.Disabled;
  }
  if (validationMessage) {
    return InputState.Alert;
  }
  return InputState.Default;
};

const renderSelectedOption = (
  _id: Uuid,
  selectedItem: ContributorOption,
  defaultTagProps: ComponentProps<typeof DefaultTag>,
) => <DefaultTag {...defaultTagProps} label={selectedItem.tagLabel} />;

export const ContributorSelector = forwardRef<HTMLDivElement, ContributorSelectorProps>(
  (props, ref) => {
    const {
      assignedContributors,
      autoFocus,
      availableContributors,
      className,
      currentUserId,
      isDisabled,
      onContributorListChanged,
      validationMessage,
    } = props;

    if (availableContributors.length === 0) {
      return null;
    }

    const contributors = makeCurrentUserFirst(
      [...availableContributors].sort(userLastNameComparer),
      currentUserId,
    );
    const options = contributors.map(
      (option): ContributorOption => ({
        id: option.userId,
        label: formatCurrentUserName(currentUserId)(option),
        tagLabel: formatUserName(option),
      }),
    );

    return (
      <div className={className}>
        <MultiSelect<ContributorOption>
          ref={ref}
          caption={validationMessage}
          autoFocus={autoFocus}
          inputState={getInputState(!!isDisabled, validationMessage)}
          items={options}
          selectedItemIds={assignedContributors}
          renderSelectedOption={renderSelectedOption}
          onSelectionChange={(selectedIds) => {
            const newContributors = contributors.filter((option) => selectedIds.has(option.userId));
            onContributorListChanged(newContributors);
          }}
          placeholder="Select a contributor"
          {...getDataUiCollectionAttribute(DataUiCollection.Contributors)}
        />
      </div>
    );
  },
);

ContributorSelector.displayName = 'ContributorSelector';
