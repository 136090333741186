import { assert, Collection, areMembersEqual, notNullNorUndefined } from '@kontent-ai/utils';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { upsertUserProperty } from '../../../../../_shared/actions/thunkSharedActions.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { HasCreatedSeventhOrMoreWorkflowStep } from '../../../../../_shared/models/UserPropertiesServerKeys.ts';
import { createFormValidationResolver } from '../../../../../_shared/utils/validation/createFormValidationResolver.ts';
import {
  cancelWorkflowStepCreator,
  cancelWorkflowStepEditor,
  deleteEditedWorkflowStep,
  editedWorkflowStepUnsavedChangesUpdated,
  saveWorkflowStepEditor,
} from '../../actions/workflowActions.ts';
import { StepEditor } from '../../components/stepEditors/StepEditor.tsx';
import { IWorkflowStepFormShape } from '../../model/IWorkflowStepFormShape.type.ts';
import { getOrderedAllSteps } from '../../selectors/getOrderedWorkflowSteps.ts';
import {
  getDefaultRolesForFormInput,
  handleAllRolesRoleOnSubmit,
} from '../../utils/allRolesRoleBehaviorUtils.ts';
import {
  getTransitionFromSteps,
  getTransitionToSteps,
} from '../../utils/stepEditorInitialValuesUtils.ts';
import { workflowStepValidationConfig } from '../../validation/workflowStepValidation.ts';

type Props = {
  readonly id: Uuid;
};

const StepEditorContainer: React.FC<Props> = ({ id }) => {
  const workflowStep = useSelector((s) =>
    s.workflowsApp.editorUi.regularWorkflowSteps.find((step) => step.id === id),
  );
  assert(!!workflowStep, () => `StepEditorContainer: workflowStep with id ${id} not found.`);

  const errorMessage = useSelector((s) => s.workflowsApp.editorUi.workflowErrors.get(id));
  const isUsed = useSelector((s) => !!s.workflowsApp.editorUi.workflowStepsUsage.get(id));

  const isProtected = useSelector((s) => {
    const { archivedWorkflowStep, publishedWorkflowStep, regularWorkflowStepsOrder } =
      s.workflowsApp.editorUi;

    const protectedStepsIds = [
      regularWorkflowStepsOrder[0],
      publishedWorkflowStep.id,
      archivedWorkflowStep.id,
    ];

    return protectedStepsIds.includes(id);
  });

  const isNew = useSelector((s) => s.workflowsApp.editorUi.isEditedWorkflowStepNew);
  const allRenderedSteps = useSelector((s) => getOrderedAllSteps(s.workflowsApp.editorUi));
  const allRolesById = useSelector((s) => s.data.roles.rolesById);

  const dispatch = useDispatch();
  const onStatusDeleted = () => dispatch(deleteEditedWorkflowStep(id));
  const onStatusEditingCanceled = () => dispatch(cancelWorkflowStepEditor());
  const onCreatingCanceled = () => dispatch(cancelWorkflowStepCreator(id));

  const defaultValues = useMemo(
    (): IWorkflowStepFormShape => ({
      name: workflowStep.name,
      color: workflowStep.color,
      transitionToStepIds: getTransitionToSteps(allRenderedSteps, workflowStep).map(
        (step) => step.id,
      ),
      transitionFromStepIds: getTransitionFromSteps(allRenderedSteps, workflowStep.id, isNew).map(
        (step) => step.id,
      ),
      roleIds: getDefaultRolesForFormInput(
        workflowStep.roleIds,
        Collection.getValues(allRolesById),
        isNew,
      ).map((role) => role.id),
    }),
    [workflowStep, allRenderedSteps, allRolesById, isNew],
  );

  const formProps = useForm<IWorkflowStepFormShape>({
    defaultValues,
    resolver: createFormValidationResolver(workflowStepValidationConfig, {}),
  });

  const { handleSubmit, watch } = formProps;

  const submitForm = handleSubmit((values) => {
    dispatch(
      saveWorkflowStepEditor({
        stepId: id,
        name: values.name,
        color: values.color,
        transitionsTo: new Set(
          values.transitionToStepIds
            .map((stepId) => allRenderedSteps.find((step) => step.id === stepId))
            .filter(notNullNorUndefined),
        ),
        transitionsFrom: new Set(
          values.transitionFromStepIds
            .map((stepId) => allRenderedSteps.find((step) => step.id === stepId))
            .filter(notNullNorUndefined),
        ),
        roleIds: handleAllRolesRoleOnSubmit(values.roleIds),
      }),
    );

    if (isNew && allRenderedSteps.length >= 7) {
      dispatch(upsertUserProperty(HasCreatedSeventhOrMoreWorkflowStep, 'true'));
    }
  });

  const { name: workflowStepName, color: workflowStepColor } = watch();

  useEffect(() => {
    const susbscription = watch((updatedStep: IWorkflowStepFormShape) => {
      const hasUnsavedChanges = hasEditorUnsavedChanges(updatedStep, defaultValues);

      dispatch(editedWorkflowStepUnsavedChangesUpdated(hasUnsavedChanges));
    });

    return () => {
      dispatch(editedWorkflowStepUnsavedChangesUpdated(false));
      susbscription.unsubscribe();
    };
  }, [watch, defaultValues]);

  return (
    <StepEditor
      allRenderedSteps={allRenderedSteps}
      errorMessage={errorMessage}
      formProps={formProps}
      isNew={isNew}
      isProtected={isProtected}
      isUsed={isUsed}
      onCreatingCanceled={onCreatingCanceled}
      onStatusDeleted={onStatusDeleted}
      onStatusEditingCanceled={onStatusEditingCanceled}
      onSubmit={submitForm}
      workflowStepId={workflowStep.id}
      workflowStepColor={workflowStepColor}
      workflowStepName={workflowStepName}
    />
  );
};

StepEditorContainer.displayName = 'StepEditorContainer';
export { StepEditorContainer as StepEditor };

const hasEditorUnsavedChanges = (
  values: IWorkflowStepFormShape,
  otherValues: IWorkflowStepFormShape,
): boolean =>
  values.name !== otherValues.name ||
  values.color !== otherValues.color ||
  !areMembersEqual(values.transitionFromStepIds, otherValues.transitionFromStepIds) ||
  !areMembersEqual(values.transitionToStepIds, otherValues.transitionToStepIds) ||
  !areMembersEqual(values.roleIds, otherValues.roleIds);
