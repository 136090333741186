import PropTypes from 'prop-types';
import React from 'react';
import { Guidelines } from '../../../../../_shared/containers/Guidelines.tsx';
import {
  GuidelinesTypeElementPropTypesShape,
  IGuidelinesTypeElement,
} from '../../../../contentInventory/content/models/contentTypeElements/GuidelinesTypeElement.ts';
import { ItemElement } from '../../ContentItemEditing/containers/elements/ItemElement.tsx';

interface IGuidelinesElementProps {
  readonly disabled: boolean;
  readonly typeElement: IGuidelinesTypeElement;
}

const propTypes: PropTypesShape<IGuidelinesElementProps> = {
  disabled: PropTypes.bool.isRequired,
  typeElement: GuidelinesTypeElementPropTypesShape.isRequired,
};

export const ContentComponentGuidelinesElement: React.FC<IGuidelinesElementProps> = ({
  disabled,
  typeElement,
}) => (
  <ItemElement typeElement={typeElement} disabled={disabled}>
    <div className="content-component__element">
      <Guidelines typeElement={typeElement} />
    </div>
  </ItemElement>
);

ContentComponentGuidelinesElement.displayName = 'ContentComponentGuidelinesElement';
ContentComponentGuidelinesElement.propTypes = propTypes;
