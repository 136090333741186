import { MatchAiTask } from '../../../../_shared/features/AI/hooks/aiTasks/matchAiTask.type.ts';
import { AiActionName } from '../../../../repositories/serverModels/ai/AiActionName.type.ts';

export const matchDescribeImageAiTask = (assetId: Uuid): MatchAiTask => {
  return (aiTasks) => {
    return (
      aiTasks.find(
        ({ action, context }) =>
          action.name === AiActionName.DescribeImage &&
          'assetId' in context &&
          context.assetId === assetId,
      ) ?? null
    );
  };
};
