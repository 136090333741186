import React, { useCallback } from 'react';
import { useLocation } from 'react-router';
import { RouteChangeHandler as EnsureFocusedTaskComponent } from '../../../../_shared/components/RouteChangeHandler.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { HighlightedTask_Ensured } from '../constants/contentInventoryActionTypes.ts';

interface IContainerProps {
  readonly taskId: Uuid;
}

const highlightTask = (pathToItem: string, taskId: Uuid) =>
  ({
    type: HighlightedTask_Ensured,
    payload: {
      pathToItem,
      taskId,
    },
  }) as const;

export type EnsureHighlightedTaskActionTypes = ReturnType<typeof highlightTask>;

export const EnsureHighlightedTask: React.FC<IContainerProps> = ({ taskId }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const onDidMount = useCallback(
    () => dispatch(highlightTask(location.pathname, taskId)),
    [location.pathname, taskId],
  );

  return <EnsureFocusedTaskComponent onDidMount={onDidMount} />;
};

EnsureHighlightedTask.displayName = 'EnsureHighlightedTask';
