import React, { useMemo } from 'react';
import { BarItemActionButtons } from '../../../../../_shared/components/BarItems/BarItemActionButtons.tsx';
import {
  HotkeysHandler,
  HotkeysMode,
} from '../../../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import { HotkeysHandlers } from '../../../../../_shared/components/Hotkeys/hotkeys.type.ts';
import { ShortcutSymbols } from '../../../../../_shared/constants/shortcutSymbols.ts';
import { useScrollIntoViewOnMount } from '../../../../../_shared/hooks/useScrollIntoViewOnMount.ts';
import { HookFormProps } from '../../../../../_shared/types/hookFormProps.ts';
import {
  isPublishedWorkflowStep,
  isScheduledToPublishWorkflowStep,
} from '../../../../../_shared/utils/contentItemUtils.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IRole } from '../../../../../data/models/roles/IRole.ts';
import { mapRoleToOption } from '../../../../../data/models/roles/RoleOption.ts';
import {
  ArchivedStep,
  IWorkflowStep,
  mapWorkflowStepToOption,
} from '../../../../../data/models/workflow/WorkflowStep.ts';
import { IArchivedWorkflowStepFormShape } from '../../model/IArchivedWorkflowStepFormShape.type.ts';
import { PlaceholderOuterAction } from '../stepBarItems/PlaceholderOuterAction.tsx';
import { ArchivedWorkflowStepFormFields } from './ArchivedWorkflowStepFormFields.tsx';
import { StepEditorHeader } from './StepEditorHeader.tsx';

type Props = {
  readonly allRenderedSteps: ReadonlyArray<IWorkflowStep>;
  readonly allRoles: ReadonlyArray<IRole>;
  readonly formProps: HookFormProps<IArchivedWorkflowStepFormShape>;
  readonly onStatusEditingCanceled: () => void;
  readonly onSubmit: () => void;
  readonly restoreFromArchivedStepMessage: string;
  readonly workflowStep: ArchivedStep;
};

export const ArchivedStepEditor: React.FC<Props> = (props) => {
  const scrollRef = useScrollIntoViewOnMount<HTMLDivElement>();

  const transitionFromOptions = useMemo(
    () =>
      props.allRenderedSteps
        .filter((step) => !!step && step.id !== props.workflowStep.id)
        .filter((step) => !isScheduledToPublishWorkflowStep(step))
        .filter((step) => !isPublishedWorkflowStep(step))
        .map(mapWorkflowStepToOption),
    [props.allRenderedSteps, props.workflowStep.id],
  );

  const permanentOptions = useMemo(
    () =>
      props.allRenderedSteps
        .filter((step) => isPublishedWorkflowStep(step))
        .map(mapWorkflowStepToOption),
    [props.allRenderedSteps],
  );

  const roleOptions = useMemo(() => props.allRoles.map(mapRoleToOption), [props.allRoles]);

  const handlers: HotkeysHandlers = {
    onEscape: props.onStatusEditingCanceled,
    onEnter: props.onSubmit,
  };

  return (
    <div
      className="bar-item__wrapper bar-item__wrapper--is-flexible"
      ref={scrollRef}
      {...getDataUiElementAttribute(DataUiElement.WorkflowStepForm)}
    >
      <div className="bar-item__pane bar-item__pane--is-expanded">
        <HotkeysHandler mode={HotkeysMode.Dual} handlers={handlers}>
          <StepEditorHeader color={props.workflowStep.color} stepName={props.workflowStep.name} />
          <ArchivedWorkflowStepFormFields
            transitionFromOptions={transitionFromOptions}
            permanentTransitionFromOptions={permanentOptions}
            roleOptions={roleOptions}
            stepName={props.workflowStep.name}
            restoreFromArchivedStepMessage={props.restoreFromArchivedStepMessage}
            formProps={props.formProps}
          />
          <BarItemActionButtons
            secondaryAction={{
              handler: props.onStatusEditingCanceled,
              text: 'Cancel',
              dataUIActionName: DataUiAction.Cancel,
              shortcut: ShortcutSymbols.Escape,
            }}
            primaryAction={{
              text: 'Confirm',
              handler: props.onSubmit,
              dataUIActionName: DataUiAction.Save,
              shortcut: ShortcutSymbols.Enter,
            }}
          />
        </HotkeysHandler>
      </div>
      <div className="bar-item__outer-actions-pane">
        <PlaceholderOuterAction />
      </div>
    </div>
  );
};

ArchivedStepEditor.displayName = 'ArchivedStepEditor';
