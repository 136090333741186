import { Callout } from '@kontent-ai/component-library/Callout';
import PropTypes from 'prop-types';
import React from 'react';

interface IExpiredPlanMessageProps {
  readonly isExpired: boolean;
  readonly isTrial: boolean;
  readonly planDisplayName: string;
}

export const ExpiredPlanMessage: React.FC<IExpiredPlanMessageProps> = ({
  isExpired,
  isTrial,
  planDisplayName,
}) =>
  isExpired ? (
    <Callout
      calloutType="friendlyWarning"
      headline={
        isTrial ? 'Your free trial has come to an end' : `Your ${planDisplayName} plan has expired`
      }
    >
      Select your plan to continue
    </Callout>
  ) : null;

ExpiredPlanMessage.displayName = 'ExpiredPlanMessage';

const propTypes: PropTypesShape<IExpiredPlanMessageProps> = {
  isExpired: PropTypes.bool.isRequired,
  isTrial: PropTypes.bool.isRequired,
  planDisplayName: PropTypes.string.isRequired,
};
ExpiredPlanMessage.propTypes = propTypes;
