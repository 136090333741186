import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { IMultipleChoiceTypeElementData } from '../../../../shared/models/elements/MultipleChoiceTypeElementData.ts';
import { ITypeElementValidatorDependencies } from '../../../../shared/utils/typeElementValidators/types/ITypeElementValidatorDependencies.type.ts';
import { MultipleChoiceTypeElementValidationResult } from '../../../utils/typeElementValidators/types/MultipleChoiceTypeElementValidationResult.type.ts';
import { createErrorMessagesFromConditionValidationResult } from '../../../utils/typeElementValidators/utils/conditions/createErrorMessagesFromConditionValidationResult.ts';
import {
  ConditionValidationData,
  getConditionValidationResult,
} from '../../../utils/typeElementValidators/utils/conditions/getConditionValidationResult.ts';

const getMultipleChoiceTypeElementValidationResult = (
  { typeElementValidationUtils }: ITypeElementValidatorDependencies,
  typeElement: IMultipleChoiceTypeElementData,
  conditionValidationData: ConditionValidationData,
): MultipleChoiceTypeElementValidationResult => {
  const { getNameValidationResult, getGuidelinesValidationResult } = typeElementValidationUtils;

  const nameValidationResult = getNameValidationResult(typeElement);
  const guidelinesValidationResult = getGuidelinesValidationResult(typeElement);

  const errorMessages: Array<string> = [];

  if (!nameValidationResult.isValid && nameValidationResult.errorMessage) {
    errorMessages.push(nameValidationResult.errorMessage);
  }

  if (!guidelinesValidationResult.isValid && guidelinesValidationResult.errorMessage) {
    errorMessages.push(guidelinesValidationResult.errorMessage);
  }

  const conditionValidationResult = getConditionValidationResult(
    typeElement,
    conditionValidationData,
  );

  return {
    conditionValidationResult,
    isNameValid: nameValidationResult.isValid,
    isGuidelinesValid: guidelinesValidationResult.isValid,
    errorMessages: [
      ...createErrorMessagesFromConditionValidationResult(conditionValidationResult),
      ...errorMessages,
    ],
  };
};

export const createValidateMultipleChoiceTypeElementAction =
  (deps: ITypeElementValidatorDependencies) =>
  (typeElement: IMultipleChoiceTypeElementData): ThunkFunction =>
  (dispatch, getState) => {
    const validationResult = getMultipleChoiceTypeElementValidationResult(
      deps,
      typeElement,
      deps.getConditionValidationData(getState),
    );

    dispatch(deps.typeElementValidated(typeElement.elementId, validationResult));
  };
