import { useCallback, useMemo } from 'react';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { ApplyEditorStateChanges } from '../../editorCore/types/Editor.base.type.ts';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { Apply, EditorPlugin } from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import { withDisplayName } from '../../editorCore/utils/withDisplayName.ts';

export type ForceSingleUnstyledBlockPlugin = EditorPlugin;

const applyEditorStateChanges: Decorator<ApplyEditorStateChanges> =
  (baseApplyEditorStateChanges) => (params) => {
    const allowedNewState = baseApplyEditorStateChanges(params);
    const hasSingleBlock = allowedNewState.getCurrentContent().getBlockMap().size === 1;
    return hasSingleBlock ? allowedNewState : params.oldState;
  };

export const useForceSingleUnstyledBlock: PluginCreator<ForceSingleUnstyledBlockPlugin> = (
  baseEditor,
) =>
  useMemo(
    () =>
      withDisplayName('ForceSingleUnstyledBlockPlugin', {
        ComposedEditor: (props) => {
          const apply: Apply<ForceSingleUnstyledBlockPlugin> = useCallback((state) => {
            state.applyEditorStateChanges.decorate(applyEditorStateChanges);
            return {};
          }, []);

          return useEditorWithPlugin(baseEditor, props, { apply });
        },
      }),
    [baseEditor],
  );
