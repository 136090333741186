import PropTypes from 'prop-types';
import React from 'react';
import {
  BaseAvatarComponent,
  IBaseAvatarProps,
  baseAvatarPropTypes,
} from '../components/BaseAvatarComponent.tsx';
import { StyledLinkAvatar } from '../components/StyledAvatar.tsx';

export interface ILinkAvatarProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement>,
    IBaseAvatarProps {
  readonly activated?: boolean;
}

const propTypes: PropTypeMap<
  Omit<ILinkAvatarProps, keyof React.AnchorHTMLAttributes<HTMLAnchorElement>>
> = {
  ...baseAvatarPropTypes,
  activated: PropTypes.bool,
};

export const LinkAvatar = React.forwardRef<
  HTMLAnchorElement,
  React.PropsWithChildren<ILinkAvatarProps>
>(
  (
    {
      activated,
      backgroundGradient,
      boxShadow,
      image,
      initials,
      label,
      size,
      tooltipPlacement,
      children, // Just to omit them from linkProps
      ...linkProps
    },
    forwardedRef,
  ) => {
    return (
      <BaseAvatarComponent
        renderContainer={(injectedProps) => (
          <StyledLinkAvatar
            aria-label={label}
            boxShadow={boxShadow}
            ref={forwardedRef}
            $activated={activated}
            $size={size}
            {...linkProps}
            {...injectedProps}
          />
        )}
        {...{
          backgroundGradient,
          boxShadow,
          image,
          initials,
          label,
          size,
          tooltipPlacement,
          tooltipText: label,
        }}
      />
    );
  },
);

LinkAvatar.displayName = 'LinkAvatar';
LinkAvatar.propTypes = propTypes;
