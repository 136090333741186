import { Box } from '@kontent-ai/component-library/Box';
import { Spacing, Typography, colorTextLowEmphasis } from '@kontent-ai/component-library/tokens';
import React from 'react';

interface IItemElementLabelProps {
  readonly alignedRight?: boolean;
  readonly id?: string;
}

export const ItemElementLabel: React.FC<React.PropsWithChildren<IItemElementLabelProps>> = ({
  alignedRight,
  children,
  id,
}) => {
  const labelAttributes: Omit<JSX.IntrinsicElements['label'], 'color' | 'ref'> = {
    htmlFor: id,
  };

  return (
    <Box
      color={colorTextLowEmphasis}
      className="content-item-element__label"
      css="word-break: break-word"
      display="flex"
      typography={Typography.TitleLarge}
      marginLeft={alignedRight ? 'auto' : Spacing.None}
      paddingLeft={alignedRight ? Spacing.S : Spacing.None}
      component="label"
      {...labelAttributes}
    >
      {children}
    </Box>
  );
};

ItemElementLabel.displayName = 'ItemElementLabel';
