import { ContentItemId } from '../../../../_shared/models/ContentItemId.type.ts';
import { logError } from '../../../../_shared/utils/logError.ts';
import { ITimelineItemServerModel } from '../../../../repositories/serverModels/ITimelineServerModel.type.ts';
import {
  IAssignmentNormalized,
  getAssignmentNormalizedFromServerModel,
} from '../contentItem/AssignmentNormalized.ts';

export enum TimelineItemType {
  Revision = 'revision',
  Restored = 'restored-from-revision',
  Assignment = 'assignment',
  DiscardedTo = 'discarded-to-revision',
}

export type TimelineItem = {
  readonly contentItemId: ContentItemId;
  readonly itemId: Uuid;
  readonly time: DateTimeStamp;
  readonly lastModified: DateTimeStamp;
  readonly lastModifiedBy: string;
  readonly type: TimelineItemType;
  readonly revisionId: Uuid | null;
  // type specific
  readonly restoredFrom: Uuid | null;
  readonly assignment: IAssignmentNormalized | null;
  readonly contentChangedBy: ReadonlyArray<UserId>;
};

const emptyItem: TimelineItem = {
  contentItemId: {
    itemId: '',
    variantId: '',
  },
  itemId: '',
  time: '',
  lastModified: '',
  lastModifiedBy: '',
  type: TimelineItemType.Revision,
  revisionId: null,
  // type specific
  restoredFrom: null,
  assignment: null,
  contentChangedBy: [],
};

const getTimelineItemTypeFromJS = (rawType: string): TimelineItemType => {
  switch (rawType) {
    case 'revision':
      return TimelineItemType.Revision;
    case 'assignment':
      return TimelineItemType.Assignment;
    case 'restored-from-revision':
      return TimelineItemType.Restored;
    case 'discarded-to-revision':
      return TimelineItemType.DiscardedTo;
    default: {
      logError(`[TimelineItem.ts] Unknown revision type ${rawType}.`);
      return emptyItem.type;
    }
  }
};

export const getTimelineItemFromJS = (item: ITimelineItemServerModel): TimelineItem => ({
  contentItemId: {
    itemId: item.contentItemId?.itemId ?? emptyItem.contentItemId.itemId,
    variantId: item.contentItemId?.variantId ?? emptyItem.contentItemId.variantId,
  },
  itemId: item.itemId ?? emptyItem.itemId,
  time: item.time ?? emptyItem.time,
  lastModified: item.time ?? emptyItem.lastModified,
  lastModifiedBy: item.by ?? emptyItem.lastModifiedBy,
  type: getTimelineItemTypeFromJS(item.type),
  // type specific
  restoredFrom: item.restoredFrom ?? emptyItem.restoredFrom,
  assignment:
    (item.assignment && getAssignmentNormalizedFromServerModel(item.assignment)) ??
    emptyItem.assignment,
  revisionId: item.revisionId ?? emptyItem.revisionId,
  contentChangedBy: item.contentChangedBy ?? emptyItem.contentChangedBy,
});
