import { assert } from '@kontent-ai/utils';
import { ContentState, EditorState } from 'draft-js';
import { useCallback } from 'react';
import { useEditorStateCallbacks } from '../../../editorCore/hooks/useEditorStateCallbacks.ts';
import { None } from '../../../editorCore/types/Editor.contract.type.ts';
import { Apply, EditorPlugin } from '../../../editorCore/types/Editor.plugins.type.ts';
import { EditorChangeReason } from '../../../editorCore/types/EditorChangeReason.ts';
import { ClipboardPlugin } from '../../clipboard/ClipboardPlugin.tsx';
import { createSelectionForSuggestedContent } from '../helpers/adjustSelectionUtils.ts';

type PluginWithReplaceSelection = EditorPlugin<None, None, None, [ClipboardPlugin]>;

type ReplaceSelection = (suggestedContent: ContentState) => Promise<EditorState>;

export const useReplaceSelection = (
  actionEditorState: EditorState | null,
): {
  readonly replaceSelection: ReplaceSelection;
  readonly decorateWithReplaceCallbacks: Apply<PluginWithReplaceSelection>;
} => {
  const { decorateWithEditorStateCallbacks, executeChange, getApi } =
    useEditorStateCallbacks<PluginWithReplaceSelection>();

  const replaceSelection: ReplaceSelection = useCallback(
    async (suggestedContent) => {
      assert(
        actionEditorState,
        () => 'Cannot execute replaceSelection, actionEditorState is unavailable',
      );

      const selection = actionEditorState?.getSelection();
      const api = getApi();

      return await executeChange((editorState) => {
        const updatedEditorState = api.pasteContent(editorState, selection, {
          content: suggestedContent,
          pastedMetadata: new Map(),
        });
        const selectionOfSuggestedContent = createSelectionForSuggestedContent(
          editorState.getCurrentContent(),
          updatedEditorState.getCurrentContent(),
          selection,
          updatedEditorState.getSelection(),
        );

        return EditorState.forceSelection(updatedEditorState, selectionOfSuggestedContent);
      }, EditorChangeReason.Internal);
    },
    [executeChange, getApi, actionEditorState],
  );

  return {
    decorateWithReplaceCallbacks: decorateWithEditorStateCallbacks,
    replaceSelection,
  };
};
