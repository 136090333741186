import { assert } from '@kontent-ai/utils';
import { Dispatch, GetState, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { logError } from '../../../../../_shared/utils/logError.ts';
import { getWorkflow } from '../../../../../data/reducers/workflow/selectors/workflowSelectors.ts';
import {
  Workflow_Delete_Failed,
  Workflow_Delete_Finished,
  Workflow_Delete_Started,
} from '../../constants/workflowActionTypes.ts';
import { fireDeleteWorkflowEvents } from './fireWorkflowIntercomEvents.ts';

interface IDeps {
  readonly workflowRepository: {
    readonly archive: (workflowId: Uuid) => Promise<void>;
  };
}

interface IParams {
  readonly redirectToWorkflowListing: (workflowId: Uuid) => void;
}

const started = () =>
  ({
    type: Workflow_Delete_Started,
  }) as const;

const finished = (workflowId: Uuid) =>
  ({
    type: Workflow_Delete_Finished,
    payload: { workflowId },
  }) as const;

const failed = (errorMessage: string) =>
  ({
    type: Workflow_Delete_Failed,
    payload: { errorMessage },
  }) as const;

export type DeleteWorkflowActionsType = ReturnType<
  typeof started | typeof finished | typeof failed
>;

export const createDeleteWorkflowAction =
  ({ workflowRepository }: IDeps) =>
  ({ redirectToWorkflowListing }: IParams): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState) => {
    try {
      const state = getState();
      const {
        workflowsApp: {
          editorUi: { editedWorkflowId },
        },
      } = state;
      const oldWorkflow = !!editedWorkflowId && getWorkflow(state, editedWorkflowId);

      assert(
        oldWorkflow,
        () =>
          `${__filename}: There was no corresponding workflow found for "${editedWorkflowId}" (editedWorkflowId).`,
      );

      dispatch(started());

      await workflowRepository.archive(oldWorkflow.id);

      dispatch(finished(oldWorkflow.id));
      dispatch(fireDeleteWorkflowEvents(oldWorkflow));

      redirectToWorkflowListing(editedWorkflowId);
    } catch (e) {
      logError(`${__filename}: Failed to delete workflow.`, e);
      dispatch(failed('Failed to delete workflow.'));
      throw e;
    }
  };
