import { CLPropTypes } from '@kontent-ai/component-library/validators';
import PropTypes from 'prop-types';
import React from 'react';
import { StyledTabs } from './StyledTabs.tsx';

type ObjectWithId = { readonly id: string };

type ExpandedHorizontalTabsProps<HorizontalTabItem extends ObjectWithId> = {
  readonly ariaLabel?: string;
  readonly children?: never;
  readonly items: readonly HorizontalTabItem[];
  readonly renderItem: (item: HorizontalTabItem) => React.ReactNode;
};

const propTypes: PropTypeMap<ExpandedHorizontalTabsProps<any>> = {
  ariaLabel: PropTypes.string,
  items: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
  children: CLPropTypes.never,
};

export const ExpandedHorizontalTabs = React.forwardRef<
  HTMLElement,
  ExpandedHorizontalTabsProps<any>
>(({ ariaLabel, items, renderItem, ...restProps }, forwardedRef) => (
  <StyledTabs as="nav" aria-label={ariaLabel} ref={forwardedRef} {...restProps}>
    {items.map((item) => (
      <React.Fragment key={item.id}>{renderItem(item)}</React.Fragment>
    ))}
  </StyledTabs>
));

ExpandedHorizontalTabs.propTypes = propTypes;
ExpandedHorizontalTabs.displayName = 'ExpandedHorizontalTabs';
