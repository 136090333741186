import { Box } from '@kontent-ai/component-library/Box';
import { Card } from '@kontent-ai/component-library/Card';
import { Clipboard } from '@kontent-ai/component-library/Clipboard';
import { InputState } from '@kontent-ai/component-library/Input';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { isUuid } from '../../../../_shared/utils/validation/typeValidators.ts';
import { EnvironmentIdLoadingFailed } from '../constants/UIConstants.ts';

export interface IEnvironmentIdCardProps {
  readonly environmentName: string;
  readonly currentProjectId: Uuid;
}

export const EnvironmentIdCard: React.FC<IEnvironmentIdCardProps> = ({
  currentProjectId,
  environmentName,
}) => {
  const errorMessage = !isUuid(currentProjectId) && EnvironmentIdLoadingFailed;

  return (
    <Card
      cardLabel="Environment ID"
      component="section"
      {...getDataUiElementAttribute(DataUiElement.EnvironmentIdCard)}
    >
      <Card.Headline {...getDataUiElementAttribute(DataUiElement.EnvironmentIdCardTitle)}>
        Environment ID
      </Card.Headline>
      <Card.Body>
        <Stack spacing={Spacing.XL}>
          <Box component="p">Use the environment ID when making API calls.</Box>
          <Box component="p">The ID is unique for each environment.</Box>
          <Box {...getDataUiElementAttribute(DataUiElement.EnvironmentIdInput)}>
            <Clipboard
              caption={errorMessage || ''}
              inputState={errorMessage ? InputState.Alert : InputState.ReadOnly}
              label={`Environment ID for ${environmentName}`}
              value={currentProjectId}
            />
          </Box>
        </Stack>
      </Card.Body>
    </Card>
  );
};

EnvironmentIdCard.displayName = 'EnvironmentIdCard';
