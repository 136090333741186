import { History } from 'history';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { getPathWithReplacedComparedTimelineItemId } from '../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IContentItemRepository } from '../../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { TimelineItem } from '../../../../models/revisions/TimeLineItem.ts';
import { fetchTimelineUntilRevisionFound } from '../../utils/fetchTimelineItemsUtils.ts';
import { getSelectedItemOrFallbackWithRevisionId } from '../../utils/revisionUtils.ts';
import { comparisonEnabled } from '../revisionsActions.ts';

interface IDeps {
  readonly loadComparisonRevision: (
    contentItemId: Uuid,
    timelineItem: TimelineItem,
    abortSignal?: AbortSignal,
  ) => ThunkPromise;
  readonly contentItemRepository: IContentItemRepository;
}

export interface IInitCompareRevisionParams {
  readonly contentItemId: Uuid;
  readonly compareItemId: Uuid;
  readonly history: History;
}

export const createInitCompareRevisionAction =
  (deps: IDeps) =>
  (
    { history, contentItemId, compareItemId }: IInitCompareRevisionParams,
    abortSignal?: AbortSignal,
  ): ThunkPromise =>
  async (dispatch, getState) => {
    dispatch(comparisonEnabled());

    const { selectedComparisonRevision, selectedComparisonRevisionElements } =
      getState().contentApp;

    if (
      !selectedComparisonRevision ||
      !selectedComparisonRevisionElements ||
      selectedComparisonRevision.itemId !== compareItemId
    ) {
      const timeline = await fetchTimelineUntilRevisionFound(
        dispatch,
        getState,
        contentItemId,
        compareItemId,
        abortSignal,
      );
      const compareTimelineItem = getSelectedItemOrFallbackWithRevisionId(timeline, compareItemId);

      if (compareTimelineItem.itemId === compareItemId) {
        await dispatch(
          deps.loadComparisonRevision(contentItemId, compareTimelineItem, abortSignal),
        );
      } else {
        history.push(
          getPathWithReplacedComparedTimelineItemId(
            history.location.pathname,
            compareTimelineItem.itemId,
          ),
        );
      }
    }
  };
