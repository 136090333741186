import { notNull } from '@kontent-ai/utils';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { AiImageDescriptionCard } from '../components/featureCards/AiImageDescriptionCard.tsx';
import { AiItemCategorizationCard } from '../components/featureCards/AiItemCategorizationCard.tsx';
import { AiLinkedItemsCard } from '../components/featureCards/AiLinkedItemsCard.tsx';
import { AssetCollectionMandatoryCard } from '../components/featureCards/AssetCollectionMandatoryCard.tsx';
import { AssetReplacementCard } from '../components/featureCards/AssetReplacementCard.tsx';
import { AssetTaggingAiCard } from '../components/featureCards/AssetTaggingAiCard.tsx';
import { AuthorAssistCard } from '../components/featureCards/AuthorAssistCard.tsx';
import { ElementAiTranslationCard } from '../components/featureCards/ElementAiTranslationCard.tsx';
import { MissionControlCard } from '../components/featureCards/MissionControlCard.tsx';
import { SecureAssetsCard } from '../components/featureCards/SecureAssetsCard.tsx';
import { SortBy, Status } from '../constants/filters.ts';
import { InnovationLabCardComponent } from '../types/InnovationLabCardComponent.type.ts';
import {
  InnovationLabFeatureCodename,
  innovationLabFeatureCodenames,
} from '../types/InnovationLabFeatureCodenames.ts';
import {
  InnovationLabFeatureInfo,
  InnovationLabFeatureSet,
} from '../types/InnovationLabFeatureSet.type.ts';

type Feature = {
  readonly codename: InnovationLabFeatureCodename;
  readonly Card: InnovationLabCardComponent;
  readonly featureInfo: InnovationLabFeatureInfo;
};

const FeatureCodenameToCard: ReadonlyRecord<
  InnovationLabFeatureCodename,
  InnovationLabCardComponent
> = {
  'ai-linked-items': AiLinkedItemsCard,
  'ai-image-description': AiImageDescriptionCard,
  'ai-item-categorization': AiItemCategorizationCard,
  'ai-asset-tagging': AssetTaggingAiCard,
  'ai-element-translation': ElementAiTranslationCard,
  'asset-collection-mandatory': AssetCollectionMandatoryCard,
  'secure-assets': SecureAssetsCard,
  'asset-replace': AssetReplacementCard,
  'content-status': MissionControlCard,
  'ai-richtext-beta': AuthorAssistCard,
};

const sortFeatures =
  (sortBy: SortBy) =>
  (featureA: Feature, featureB: Feature): number => {
    switch (sortBy) {
      case SortBy.Newest:
        return -featureA.Card.releaseDate.localeCompare(featureB.Card.releaseDate);
      case SortBy.Oldest:
        return featureA.Card.releaseDate.localeCompare(featureB.Card.releaseDate);
      case SortBy.ByNameAsc:
        return featureA.Card.featureName.localeCompare(featureB.Card.featureName);
      case SortBy.ByNameDesc:
        return -featureA.Card.featureName.localeCompare(featureB.Card.featureName);
      default:
        return featureA.Card.releaseDate.localeCompare(featureB.Card.releaseDate);
    }
  };

const filterByStatus =
  (status: Status, state: IStore) =>
  (feature: Feature): boolean => {
    switch (status) {
      case Status.All:
        return true;
      case Status.Active:
        return feature.Card.isFeatureEnabledSelector(state);
      case Status.Inactive:
        return !feature.Card.isFeatureEnabledSelector(state);
      default:
        return false;
    }
  };

const mapCodenameToFeature = (
  codename: InnovationLabFeatureCodename,
  featureSet: InnovationLabFeatureSet,
): Feature | null => {
  const featureInfo = featureSet[codename];
  if (!featureInfo) {
    return null;
  }

  return {
    codename,
    Card: FeatureCodenameToCard[codename],
    featureInfo,
  };
};

const isAvailable = (isUnifiedMissionControlEnabled: boolean) => {
  if (!isUnifiedMissionControlEnabled) {
    return () => true;
  }

  return (feature: Feature) => feature.codename !== 'content-status';
};

export const getInnovationLabFeatures =
  (
    featureSet: InnovationLabFeatureSet | null,
    sortBy: SortBy,
    status: Status,
    isUnifiedMissionControlEnabled: boolean,
  ) =>
  (state: IStore): ReadonlyArray<Feature> =>
    featureSet
      ? innovationLabFeatureCodenames
          .map((codename) => mapCodenameToFeature(codename, featureSet))
          .filter(notNull)
          .filter(isAvailable(isUnifiedMissionControlEnabled))
          .filter(filterByStatus(status, state))
          .sort(sortFeatures(sortBy))
      : [];
