import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { CSSProperties } from 'react';
import { ObjectWithDataAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import {
  IForwardedRefProps,
  forwardRef,
  forwardedRefProps,
} from '../../utils/forwardedRefProps.tsx';

interface IDropDownOptionListProps extends IForwardedRefProps<HTMLDivElement> {
  readonly children: React.ReactNode;
  readonly className?: string;
  readonly longer?: boolean;
  readonly hasTrailingElements?: boolean;
  readonly optionListDataUiAttributes: ObjectWithDataAttribute;
  readonly style?: CSSProperties;
}

const propTypes: PropTypesShape<IDropDownOptionListProps> = {
  ...forwardedRefProps,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  longer: PropTypes.bool,
  hasTrailingElements: PropTypes.bool,
  optionListDataUiAttributes: PropTypes.object.isRequired,
  style: PropTypes.object,
};

const DropDownOptionList: React.FC<IDropDownOptionListProps> = (props) => (
  <div
    className={classNames(props.className, 'dropdown-options')}
    data-hj-suppress=""
    ref={props.forwardedRef}
    style={props.style}
  >
    <div
      className={classNames('dropdown-options__pane', {
        'dropdown-options__pane--longer': props.longer,
        'dropdown-options__pane--has-trailing-elements': props.hasTrailingElements,
      })}
      role="listbox"
      {...props.optionListDataUiAttributes}
    >
      {props.children}
    </div>
  </div>
);

DropDownOptionList.displayName = 'DropDownOptionList';
DropDownOptionList.propTypes = propTypes;

const DropDownOptionListFRC = forwardRef(DropDownOptionList);

export { DropDownOptionListFRC as DropDownOptionList };
