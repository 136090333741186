import Immutable from 'immutable';
import { isEmail } from '../../../../_shared/utils/validation/isEmail.ts';
import { Invitation } from '../reducers/IUsersAppState.type.ts';

const separatingRegex = /[,\s]+/;

const createInvitation = (email: string): Invitation => ({
  email,
});

const parse = (text: string): [Immutable.List<Invitation>, string] => {
  const parts = text.split(separatingRegex);
  const partsButLast = parts.slice(0, parts.length - 1);

  const invitations: Invitation[] = partsButLast.filter(isEmail).map(createInvitation);

  const remainingText = invitations.reduce(
    (currentText, i) =>
      currentText.replace(
        new RegExp(i.email.replace('+', '\\+') + separatingRegex.source, 'i'),
        '',
      ),
    text,
  );

  return [Immutable.List(invitations), remainingText];
};

export const parseInvitations = (text: string): Immutable.List<Invitation> => parse(text)[0];

export const findTextWithoutInvitations = (text: string): string => parse(text)[1];

export const countInvitationTokensWithoutLeadingSpaces = (text: string): number =>
  text.trimLeft().split(separatingRegex).length;
