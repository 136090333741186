import React from 'react';
import { useHistory, useParams } from 'react-router';
import { SubscriptionEnvironmentRoleEditorRouteParams } from '../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { createRoleEditingBackLink } from '../../../../_shared/utils/routing/projectSubscriptionRoutingUtils.ts';
import { deleteEditedRole, updateRole } from '../actions/thunkRolesActions.ts';
import { RoleEditorToolbarActions as RoleEditorToolbarActionsComponent } from '../components/RoleEditorToolbarActions.tsx';
import { PredefinedRoles } from '../constants/predefinedRoles.ts';
import { getCustomRolesLimitInfo } from '../selectors/customRoleLimit.ts';
import { isRoleUsedByAnyUser } from '../selectors/isRoleUsed.ts';
import { isRoleUsedByWorkflowStep as isRoleUsedByWorkflowStepSelector } from '../selectors/isRoleUsedByWorkflowStep.ts';
import { areCustomRolesOverLimit } from '../utils/customRolesUtils.ts';

export const SubscriptionManagerRoleEditorToolbarActions: React.FC = () => {
  const { projectId, subscriptionId } = useParams<SubscriptionEnvironmentRoleEditorRouteParams>();
  const history = useHistory();
  const dispatch = useDispatch();

  const status = useSelector((s) => s.rolesApp.editorUi.status);
  const isEditedRoleReadOnly = useSelector((s) => {
    const { editedRole } = s.rolesApp.editorUi;
    return editedRole.isReadonly && editedRole.codename !== PredefinedRoles.LocalProjectManager;
  });
  const isRoleUsedByUser = useSelector((s) =>
    isRoleUsedByAnyUser(s.rolesApp.editorUi.editedRole, s.data.users.usersById),
  );
  const isRoleUsedByWorkflowStep = useSelector((s) =>
    isRoleUsedByWorkflowStepSelector(s.rolesApp.editorUi.editedRole, s.data.workflows.byId),
  );
  const isReadonly = useSelector(
    (s) =>
      areCustomRolesOverLimit(getCustomRolesLimitInfo(s)) ||
      s.rolesApp.editorUi.editedRole.isReadonly,
  );

  const handleSave = () => dispatch(updateRole());

  const handleDelete = () =>
    dispatch(
      deleteEditedRole({
        history,
        redirectToLink: createRoleEditingBackLink({
          projectId,
          subscriptionId,
        }),
      }),
    );

  return (
    <RoleEditorToolbarActionsComponent
      status={status}
      isEditedRoleReadOnly={isEditedRoleReadOnly}
      isRoleUsedByUser={isRoleUsedByUser}
      isRoleUsedByWorkflowStep={isRoleUsedByWorkflowStep}
      readOnly={isReadonly}
      onSave={handleSave}
      onDelete={handleDelete}
    />
  );
};

SubscriptionManagerRoleEditorToolbarActions.displayName =
  'SubscriptionManagerRoleEditorToolbarActions';
