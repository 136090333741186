import PropTypes from 'prop-types';
import React from 'react';
import {
  DateTimeTypeElementPropTypes,
  IDateTimeTypeElement,
} from '../../../../contentInventory/content/models/contentTypeElements/DateTimeTypeElement.ts';
import {
  DateTimeItemElement,
  DateTimeItemElementPropTypes,
} from '../../../models/contentItemElements/DateTimeItemElement.ts';
import { DateTimeDiff } from '../../Revisions/components/elements/subelements/DateTimeDiff.tsx';
import { ExpandedItemElement } from '../containers/ExpandedItemElement.tsx';
import { ExpandedItemElementDataProps } from './types/expandedItemElement.type.ts';

type ExpandedDateTimeElementProps = ExpandedItemElementDataProps<
  IDateTimeTypeElement,
  DateTimeItemElement
>;

const propTypes: PropTypeMap<ExpandedDateTimeElementProps> = {
  className: PropTypes.string,
  elementData: DateTimeItemElementPropTypes.isRequired,
  originalElementData: DateTimeItemElementPropTypes,
  typeElement: DateTimeTypeElementPropTypes.isRequired,
};

export const ExpandedDateTimeElement: React.FC<ExpandedDateTimeElementProps> = ({
  className,
  elementData,
  originalElementData,
  typeElement,
}) => (
  <ExpandedItemElement
    className={className}
    disabled
    hideValidationStatus
    typeElement={typeElement}
  >
    <DateTimeDiff
      elementData={elementData}
      originalElementData={originalElementData}
      typeElement={typeElement}
    />
  </ExpandedItemElement>
);

ExpandedDateTimeElement.displayName = 'ExpandedDateTimeElement';
ExpandedDateTimeElement.propTypes = propTypes;
