import { isElementFullyVisible, scrollToView } from '@kontent-ai/DOM';
import { Direction } from '@kontent-ai/types';
import { EditorState } from 'draft-js';
import { useCallback, useMemo } from 'react';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { Apply, EditorPlugin } from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import {
  BlockClassName,
  getBlockIdClassName,
} from '../../editorCore/utils/editorComponentUtils.ts';
import { withDisplayName } from '../../editorCore/utils/withDisplayName.ts';
import { getBlockKey } from '../../utils/blocks/editorBlockGetters.ts';
import {
  createCollapsedSelectionAtBlockEdge,
  createSelection,
  getEditorSelectionDirection,
} from '../../utils/editorSelectionUtils.ts';
import {
  ExecuteCommand,
  KeyboardShortcutsPlugin,
} from '../keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { RichTextInputCommand } from '../keyboardShortcuts/api/EditorCommand.ts';
import {
  getAdjacentBlockAcceptingSelection,
  getCommandDirection,
  getSleeveOnOppositeSideOfTheCustomBlock,
  isSelectionCommand,
} from '../keyboardShortcuts/api/editorCommandUtils.ts';
import { WrapperPlugin } from '../visuals/WrapperPlugin.tsx';

export type CustomCaretHandlingPlugin = EditorPlugin<
  None,
  None,
  None,
  [WrapperPlugin, KeyboardShortcutsPlugin<RichTextInputCommand>]
>;

export const useCustomCaretHandling: PluginCreator<CustomCaretHandlingPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('CustomCaretHandlingPlugin', {
        ComposedEditor: (props) => {
          const apply: Apply<CustomCaretHandlingPlugin> = useCallback((state) => {
            const scrollToBlock = (blockKey: string, commandDirection: Direction) => {
              const blockDOMNode = state
                .getWrapperRef()
                .current?.querySelector(`.${BlockClassName}.${getBlockIdClassName(blockKey)}`);
              if (blockDOMNode && !isElementFullyVisible(blockDOMNode)) {
                scrollToView(
                  blockDOMNode,
                  commandDirection === Direction.Forward ? 'end' : 'start',
                );
              }
            };

            const executeCommand: Decorator<ExecuteCommand<RichTextInputCommand>> =
              (baseExecuteCommand) => (command, isShiftPressed) => {
                switch (command) {
                  case RichTextInputCommand.MoveCaretToNextBlock:
                  case RichTextInputCommand.MoveCaretToPreviousBlock:
                  case RichTextInputCommand.AdjustSelectionToNextBlock:
                  case RichTextInputCommand.AdjustSelectionToPreviousBlock: {
                    const direction = getCommandDirection(command);
                    let scrollToBlockKey: string | null = null;

                    state
                      .executeChange((editorState) => {
                        const content = editorState.getCurrentContent();
                        const selection = editorState.getSelection();
                        const blockKey = selection.getFocusKey();
                        const targetBlock = getAdjacentBlockAcceptingSelection(
                          content,
                          blockKey,
                          direction,
                        );
                        if (targetBlock) {
                          const newSelection = isSelectionCommand(command)
                            ? createSelection(
                                selection.getAnchorKey(),
                                selection.getAnchorOffset(),
                                targetBlock.getKey(),
                                0,
                                getEditorSelectionDirection(
                                  content,
                                  selection.getAnchorKey(),
                                  selection.getAnchorOffset(),
                                  targetBlock.getKey(),
                                  0,
                                ),
                                selection.getHasFocus(),
                              )
                            : createCollapsedSelectionAtBlockEdge(
                                targetBlock,
                                direction === Direction.Forward ? 'start' : 'end',
                                selection.getHasFocus(),
                              );
                          scrollToBlockKey = getBlockKey(targetBlock);
                          return EditorState.forceSelection(editorState, newSelection);
                        }
                        return editorState;
                      })
                      .then(() => {
                        if (scrollToBlockKey) {
                          scrollToBlock(scrollToBlockKey, direction);
                        }
                      });
                    return true;
                  }

                  case RichTextInputCommand.MoveCaretToEndOfLine:
                  case RichTextInputCommand.MoveCaretToStartOfLine:
                  case RichTextInputCommand.AdjustSelectionToEndOfLine:
                  case RichTextInputCommand.AdjustSelectionToStartOfLine: {
                    const direction = getCommandDirection(command);
                    let scrollToBlockKey: string | null = null;

                    state
                      .executeChange((editorState) => {
                        const content = editorState.getCurrentContent();
                        const selection = editorState.getSelection();
                        const blockKey = selection.getFocusKey();
                        const targetBlock = getSleeveOnOppositeSideOfTheCustomBlock(
                          content,
                          blockKey,
                          direction,
                        );
                        if (targetBlock) {
                          const newSelection = isSelectionCommand(command)
                            ? createSelection(
                                selection.getAnchorKey(),
                                selection.getAnchorOffset(),
                                targetBlock.getKey(),
                                0,
                                getEditorSelectionDirection(
                                  content,
                                  selection.getAnchorKey(),
                                  selection.getAnchorOffset(),
                                  targetBlock.getKey(),
                                  0,
                                ),
                                selection.getHasFocus(),
                              )
                            : createCollapsedSelectionAtBlockEdge(
                                targetBlock,
                                'start',
                                selection.getHasFocus(),
                              );
                          scrollToBlockKey = getBlockKey(targetBlock);
                          return EditorState.forceSelection(editorState, newSelection);
                        }
                        return editorState;
                      })
                      .then(() => {
                        if (scrollToBlockKey) {
                          scrollToBlock(scrollToBlockKey, direction);
                        }
                      });
                    return true;
                  }

                  default:
                    return baseExecuteCommand(command, isShiftPressed);
                }
              };

            state.executeCommand.decorate(executeCommand);

            return {};
          }, []);

          return useEditorWithPlugin(baseEditor, props, { apply });
        },
      }),
    [baseEditor],
  );
