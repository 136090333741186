import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import React from 'react';
import { ControlShortcutTemplate, ShortcutSymbols } from '../../constants/shortcutSymbols.ts';
import { Button } from '../../uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../uiComponents/Button/buttonStyle.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { EnterHotkeyDisabler } from '../Hotkeys/EnterHotkeyDisabler.tsx';
import { SaveChangesButton } from '../SaveChangesButton.tsx';

type Props = {
  readonly isSaving?: boolean;
  readonly isConfirmDisabled: boolean;
  readonly onConfirm: () => void;
  readonly onDiscardChanges: () => void;
  readonly saveCodename?: boolean;
};

export const CodenameActionButtonPane: React.FC<Props> = (props) => {
  const { isConfirmDisabled, isSaving, onConfirm, onDiscardChanges, saveCodename } = props;

  return (
    <div className="codename-editor__action-button-pane">
      <EnterHotkeyDisabler>
        {!isSaving && (
          <Tooltip tooltipText="Discard" shortcuts={ShortcutSymbols.Escape} placement="bottom-end">
            <Button
              style={ButtonStyle.Secondary}
              onClick={onDiscardChanges}
              {...getDataUiActionAttribute(DataUiAction.Discard)}
            >
              Discard
            </Button>
          </Tooltip>
        )}
        <SaveChangesButton
          buttonStyle="default"
          dataUiAction={saveCodename ? DataUiAction.Save : DataUiAction.Confirm}
          disabled={isConfirmDisabled}
          isBeingSaved={isSaving || false}
          onSave={onConfirm}
          shortcuts={saveCodename ? ControlShortcutTemplate('S') : ShortcutSymbols.Enter}
          text={saveCodename ? 'Save' : 'Confirm'}
        />
      </EnterHotkeyDisabler>
    </div>
  );
};

CodenameActionButtonPane.displayName = 'CodenameActionButtonPane';
