import PropTypes from 'prop-types';
import React from 'react';
import { IconName } from '../../../../_shared/constants/iconEnumGenerated.ts';
import { DropDownOption } from '../../../../_shared/uiComponents/DropDown/DropDownOption.tsx';
import { DropDownOptionName } from '../../../../_shared/uiComponents/DropDown/DropDownOptionName.tsx';
import { Icon } from '../../../../_shared/uiComponents/Icon/Icon.tsx';
import { ObjectWithDataAttribute } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface ITreeItemMoreActionsOptionProps {
  readonly dataAttributes?: ObjectWithDataAttribute;
  readonly disabled?: boolean;
  readonly disabledTooltip?: string;
  readonly iconName?: IconName;
  readonly loading: boolean;
  readonly onClick: (e: React.MouseEvent) => void;
  readonly text: string;
}

const propTypes: PropTypesShape<ITreeItemMoreActionsOptionProps> = {
  dataAttributes: PropTypes.object,
  disabled: PropTypes.bool,
  disabledTooltip: PropTypes.string,
  iconName: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

const preventEventPropagation = (e: React.MouseEvent) => {
  e.stopPropagation();
  e.preventDefault();
};

export const TreeItemMoreActionsOption: React.FC<ITreeItemMoreActionsOptionProps> = ({
  dataAttributes,
  disabled,
  disabledTooltip,
  iconName,
  loading,
  onClick,
  text,
}) => {
  const isDisabled = disabled || loading;

  return (
    // If DropDownOption is disabled, "undefined" is passed to onClick which in this case
    // leads up to unwanted event propagation
    <div onClick={isDisabled ? preventEventPropagation : undefined}>
      <DropDownOption
        isDisabled={isDisabled}
        onClick={onClick}
        tooltipText={disabled ? disabledTooltip : undefined}
        dataUiAttributes={dataAttributes}
      >
        <div className="actions-menu-item">
          {iconName && <Icon iconName={iconName} className="actions-menu-item__icon" />}
          <DropDownOptionName text={text} />
        </div>
        {loading && (
          <Icon
            iconName={IconName.Spinner}
            className="dropdown-option__icon dropdown-option__icon--loading"
          />
        )}
      </DropDownOption>
    </div>
  );
};

TreeItemMoreActionsOption.displayName = 'TreeItemMoreActionsOption';
TreeItemMoreActionsOption.propTypes = propTypes;
