import { Action } from '../../../../../@types/Action.type.ts';
import { StatusMessageStyle } from '../../../../contentInventory/content/reducers/listingUi/statusInfo/selectors/statusMessageStyle.ts';
import {
  Workflow_Delete_Finished,
  Workflow_Restore_Finished,
  Workflow_Restore_Started,
  Workflows_ListingInit_Left,
} from '../../constants/workflowActionTypes.ts';
import {
  IWorkflowListingOperationStatus,
  WorkflowListingOperationType,
} from '../../model/WorkflowListingOperation.ts';

const initialState: IWorkflowListingOperationStatus = {
  message: 'Workflows',
  messageStyle: StatusMessageStyle.Headline,
  operationType: WorkflowListingOperationType.NoOperation,
  affectedWorkflowId: null,
};

export const operationStatus = (
  state: IWorkflowListingOperationStatus = initialState,
  action: Action,
): IWorkflowListingOperationStatus => {
  switch (action.type) {
    case Workflows_ListingInit_Left:
      return initialState;

    case Workflow_Restore_Finished: {
      return {
        message: 'Workflow restored',
        messageStyle: StatusMessageStyle.Updated,
        operationType: WorkflowListingOperationType.Restored,
        affectedWorkflowId: null,
      };
    }

    case Workflow_Restore_Started: {
      return {
        message: 'Undoing',
        messageStyle: StatusMessageStyle.Progress,
        operationType: WorkflowListingOperationType.Deleting,
        affectedWorkflowId: null,
      };
    }

    case Workflow_Delete_Finished: {
      return {
        message: 'Workflow deleted',
        messageStyle: StatusMessageStyle.Deleted,
        operationType: WorkflowListingOperationType.Deleted,
        affectedWorkflowId: action.payload.workflowId,
      };
    }

    default:
      return state;
  }
};
