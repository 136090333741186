import { Avatar, AvatarSize } from '@kontent-ai/component-library/Avatar';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { BoxShadow } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { useLocation } from 'react-router';
import { IconName } from '../../../../../../../_shared/constants/iconEnumGenerated.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { Button, ButtonSize } from '../../../../../../../_shared/uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import { Icon } from '../../../../../../../_shared/uiComponents/Icon/Icon.tsx';
import { DataUiAction } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  formatUserName,
  getUserInitials,
  getUserNewColorGradient,
} from '../../../../../../../_shared/utils/usersUtils.ts';
import { IProjectContributor } from '../../../../../../../data/models/users/ProjectContributor.ts';
import { startContentItemElementRefresh } from '../../../actions/thunkContentItemEditingActions.ts';

interface IItemElementSimultaneousEditingStatusProps {
  readonly elementId: Uuid;
  readonly isOutdated: boolean;
  readonly isRefreshPending: boolean;
  readonly lockedByUser: IProjectContributor | null;
}

const propTypes: PropTypesShape<IItemElementSimultaneousEditingStatusProps> = {
  elementId: PropTypes.string.isRequired,
  isOutdated: PropTypes.bool.isRequired,
  isRefreshPending: PropTypes.bool.isRequired,
  lockedByUser: PropTypes.object,
};

const ItemElementSimultaneousEditingStatus: React.FC<IItemElementSimultaneousEditingStatusProps> = (
  props,
): JSX.Element | null => {
  const { elementId, isOutdated, isRefreshPending, lockedByUser } = props;

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const refreshElement = useCallback(
    () => dispatch(startContentItemElementRefresh({ elementId, pathname })),
    [elementId, pathname],
  );

  const isLocked = !!lockedByUser;
  const isUnlockedOutdated = !isLocked && isOutdated;
  if (!isLocked && !isOutdated) {
    return null;
  }

  const LockedStatus = () => (
    <>
      <Avatar
        backgroundGradient={getUserNewColorGradient(lockedByUser)}
        label={formatUserName(lockedByUser)}
        initials={getUserInitials(lockedByUser)}
        size={AvatarSize.S}
        boxShadow={BoxShadow.M}
      />
      <div className="content-item-element__editing-status-text-wrapper">
        <span className="content-item-element__editing-status-username">
          {formatUserName(lockedByUser)}
        </span>
        <span
          className={classNames('content-item-element__editing-status-text', {
            'content-item-element__editing-status-text--is-last': !isOutdated,
          })}
        >
          {' is editing this right now'}
        </span>
      </div>
    </>
  );

  const UnlockedOutdatedStatus = () => (
    <>
      <Icon className="content-item-element__editing-status-icon" iconName={IconName.ICircle} />
      <div className="content-item-element__editing-status-text">
        This element has been changed recently
      </div>
    </>
  );

  const RefreshButton = () => (
    <Tooltip
      tooltipText={
        isRefreshPending
          ? 'Refreshing the element'
          : 'Refresh this element to see the latest changes'
      }
      placement="bottom-end"
    >
      <Button
        className="content-item-element__editing-status-button-wrapper"
        customButtonClassName="content-item-element__editing-status-button"
        disabled={isRefreshPending}
        onClick={refreshElement}
        size={ButtonSize.S}
        style={ButtonStyle.Tertiary}
        hasLoader={isRefreshPending}
        dataUiAction={DataUiAction.Refresh}
      >
        {!isRefreshPending && <i className={classNames(IconName.RotateDoubleRight, 'btn__icon')} />}
        Refresh
      </Button>
    </Tooltip>
  );

  return (
    <div
      className={classNames('content-item-element__editing-status', {
        'content-item-element__editing-status--unlocked-outdated': isUnlockedOutdated,
      })}
    >
      {isLocked && <LockedStatus />}
      {isUnlockedOutdated && <UnlockedOutdatedStatus />}
      {isOutdated && <RefreshButton />}
    </div>
  );
};

ItemElementSimultaneousEditingStatus.displayName = 'ItemElementSimultaneousEditingStatus';
ItemElementSimultaneousEditingStatus.propTypes = propTypes;

const ItemElementSimultaneousEditingStatusMemo = memo(ItemElementSimultaneousEditingStatus);
export { ItemElementSimultaneousEditingStatusMemo as ItemElementSimultaneousEditingStatus };
