import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ListingMessage } from '../../../../../_shared/uiComponents/ListingMessage/ListingMessage.tsx';
import {
  moveAssetsBackToCollection,
  moveAssetsBackToFolder,
  moveOpenedAssetFolderBack,
  restoreAssetFolder,
  restoreAssets,
} from '../../actions/thunkAssetLibraryActions.ts';
import { getStatusMessageText } from '../../reducers/selectors/statusInfoMessage.ts';
import { getStatusMessageStyle } from '../../reducers/selectors/statusMessageStyle.ts';
import { StatusMessageAction } from '../../stores/IAssetLibraryState.ts';
import { LastAssetsAction } from '../../types/lastAssetsAction.ts';
import { AssetsOrderBy } from '../../types/orderBy.type.ts';

const useUndoAction = (
  lastAction: LastAssetsAction,
  orderBy: AssetsOrderBy,
): (() => void) | null => {
  const dispatch = useDispatch();

  switch (lastAction.type) {
    case StatusMessageAction.ArchivedAssets:
      return lastAction.successfulIds.size ? () => dispatch(restoreAssets({ orderBy })) : null;

    case StatusMessageAction.ArchivedFolder:
      return lastAction.successfulAssetsIds.size || lastAction.successfulFoldersIds.size
        ? () => dispatch(restoreAssetFolder({ orderBy }))
        : null;

    case StatusMessageAction.MovedAssetsToCollection:
      return lastAction.successfulIds.size
        ? () => dispatch(moveAssetsBackToCollection({ orderBy }))
        : null;

    case StatusMessageAction.MovedAssetsToFolder:
      return lastAction.successfulIds.size
        ? () => dispatch(moveAssetsBackToFolder({ orderBy }))
        : null;

    case StatusMessageAction.MovedFolder:
      return () => dispatch(moveOpenedAssetFolderBack());

    default:
      return null;
  }
};

type Props = {
  readonly orderBy: AssetsOrderBy;
  readonly selectedAssetsCount: number;
};

export const AssetListingInfoMessage = ({ orderBy, selectedAssetsCount }: Props) => {
  const style = useSelector((s) => getStatusMessageStyle(s, { selectedAssetsCount }));
  const text = useSelector((s) => getStatusMessageText(s, { selectedAssetsCount }));
  const lastAction = useSelector((s) => s.assetLibraryApp.lastAction);

  const onUndo = useUndoAction(lastAction, orderBy);

  return <ListingMessage statusInfoMessage={{ style, text }} onUndo={onUndo} />;
};
