import React from 'react';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import { ControlAltShortcutTemplate } from '../../../../../_shared/constants/shortcutSymbols.ts';
import {
  DataUiRteAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IForwardedRefProps, forwardRef } from '../../../../../_shared/utils/forwardedRefProps.tsx';
import {
  IToggleButtonProps,
  RTEToolbarIconButton,
  specificButtonPropTypes,
} from '../../toolbars/components/RTEToolbarButton.tsx';

const ImageButtonComponent: React.FC<IToggleButtonProps & IForwardedRefProps<HTMLButtonElement>> = (
  props,
) => (
  <RTEToolbarIconButton
    {...props}
    iconName={IconName.Picture}
    ref={props.forwardedRef}
    shortcut={ControlAltShortcutTemplate('A')}
    tooltipPlacement={props.tooltipPlacement ?? 'bottom-start'}
    tooltipText={props.tooltipText ?? (props.disabled ? 'Images are not allowed' : 'Insert image')}
    {...getDataUiActionAttribute(DataUiRteAction.AddImage)}
  />
);

ImageButtonComponent.displayName = 'ImageButton';
ImageButtonComponent.propTypes = specificButtonPropTypes;

export const ImageButton = forwardRef(ImageButtonComponent);
