import {
  makeCancellablePromise,
  noOperation,
  swallowCancelledPromiseError,
} from '@kontent-ai/utils';
import React, { useEffect } from 'react';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { emptySubscription } from '../../../../data/models/subscriptions/Subscription.ts';
import { getSelectedSubscription } from '../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { loadSubscriptionBillingInformation } from '../actions/thunkBillingInformationActions.ts';
import { BillingInformation as BillingInformationComponent } from '../components/BillingInformation.tsx';
import { emptySubscriptionBillingInformation } from '../models/SubscriptionBillingInformation.ts';

export const BillingInformation: React.FC = () => {
  const selectedSubscription = useSelector((s) => getSelectedSubscription(s) ?? emptySubscription);
  const plan = useSelector((s) => s.data.plans.byId.get(selectedSubscription.currentPlan.planId));

  const shouldLoadBillingInformationDetails =
    selectedSubscription.isFsSubscriptionConnected && !plan?.isPrepaid;
  const billingInformation = useSelector((s) =>
    shouldLoadBillingInformationDetails
      ? s.data.subscriptions.subscriptionBillingInformation
      : null,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!shouldLoadBillingInformationDetails) {
      return noOperation;
    }

    const initPromise = makeCancellablePromise(() =>
      dispatch(loadSubscriptionBillingInformation(selectedSubscription.subscriptionId)),
    ).catch(swallowCancelledPromiseError);

    return () => initPromise.cancel();
  }, [selectedSubscription.subscriptionId, shouldLoadBillingInformationDetails]);

  if (billingInformation === emptySubscriptionBillingInformation) {
    return <Loader />;
  }

  return (
    <BillingInformationComponent
      billingInformation={billingInformation}
      isMarketplaceSubscription={!!selectedSubscription.azureMarketplaceSubscriptionId}
      subscriptionId={selectedSubscription.subscriptionId}
    />
  );
};

BillingInformation.displayName = 'BillingInformation';
