import React, { ComponentProps } from 'react';
import { useWatch } from 'react-hook-form';
import { DefaultTag } from '../../../../../../component-library/components/Tag/DefaultTag.tsx';
import { HookFormProps } from '../../../../../_shared/types/hookFormProps.ts';
import { joinWithOxfordComma } from '../../../../../_shared/utils/stringUtils.ts';
import { ApiKeyEnvironmentOption } from '../../components/ApiKeyDetail/ApiKeyEnvironmentOption.tsx';
import { ValidatedApiEnvironmentsSelector } from '../../components/ApiKeyDetail/ValidatedApiEnvironmentsSelector.tsx';
import {
  DapiKeyDisabledEnvironment,
  DapiKeyDisabledEnvironments,
} from '../../constants/ApiKeyDetailTemplates.ts';
import { EnvironmentOption } from '../../selectors/getSelectedEnvironmentOptions.ts';
import { getDisabledApiEnvironmentNames } from '../../utils/getDisabledApiEnvironmentNames.ts';
import { DapiKeyFormShape } from '../../validation/dapiKeyValidationConfig.ts';

type ValidatedDapiKeyEnvironmentsSelectorProps = {
  readonly allEnvironmentOptions: ReadonlyArray<EnvironmentOption>;
  readonly canUpdateKey: boolean;
  readonly disabledTooltipText: string | undefined;
  readonly formProps: HookFormProps<DapiKeyFormShape>;
};

export const ValidatedDapiKeyEnvironmentsSelector: React.FC<
  ValidatedDapiKeyEnvironmentsSelectorProps
> = ({ allEnvironmentOptions, canUpdateKey, disabledTooltipText, formProps }) => {
  const [selectedEnvironments, hasSecureDeliveryAccess] = useWatch({
    control: formProps.control,
    name: ['environments', 'access.hasSecureDeliveryAccess'],
  });
  const disabledApiEnvironmentNames = getDisabledApiEnvironmentNames(
    allEnvironmentOptions,
    selectedEnvironments,
  );

  return (
    <ValidatedApiEnvironmentsSelector
      allEnvironmentOptions={allEnvironmentOptions}
      canUpdateKey={canUpdateKey}
      caption={getCaption(disabledApiEnvironmentNames, hasSecureDeliveryAccess)}
      disabledTooltipText={disabledTooltipText}
      formProps={formProps}
      fieldName="environments"
      renderSelectedEnvironmentOption={renderSelectedEnvironmentOption(
        hasSecureDeliveryAccess,
        canUpdateKey,
      )}
    />
  );
};

const renderSelectedEnvironmentOption =
  (hasSecureDeliveryAccess: boolean, canUpdateApiKey: boolean) =>
  (
    environmentOption: EnvironmentOption,
    defaultTagProps: ComponentProps<typeof DefaultTag>,
  ): JSX.Element => (
    <ApiKeyEnvironmentOption
      canUpdateApiKey={canUpdateApiKey}
      hasError={!environmentOption.isApiEnabled && hasSecureDeliveryAccess}
      tooltipText={DapiKeyDisabledEnvironment(environmentOption.label)}
      defaultTagProps={defaultTagProps}
      environmentOption={environmentOption}
    />
  );

const getCaption = (
  disabledApiEnvironmentNames: ReadonlyArray<string>,
  isSecureAccessEnabled: boolean,
): string => {
  if (!isSecureAccessEnabled) {
    return '';
  }

  switch (disabledApiEnvironmentNames.length) {
    case 0:
      return '';
    case 1:
      return DapiKeyDisabledEnvironment(disabledApiEnvironmentNames);
    default:
      return DapiKeyDisabledEnvironments(joinWithOxfordComma(disabledApiEnvironmentNames));
  }
};

ValidatedDapiKeyEnvironmentsSelector.displayName = 'ValidatedDapiKeyEnvironmentsSelector';
