import Immutable from 'immutable';
import React from 'react';
import { ModalViewer } from '../../../../../../../_shared/components/Modal/ModalViewer.tsx';
import { ModalViewerPosition } from '../../../../../../../_shared/components/Modal/ModalViewerPosition.ts';
import { ModalAssetSelector } from '../../../../../../contentInventory/assets/features/ModalAssetSelector/containers/ModalAssetSelector.tsx';
import { ModalMultipleAssetsSelector } from '../../../../../../contentInventory/assets/features/ModalAssetSelector/containers/ModalMultipleAssetsSelector.tsx';
import { AssetFileTypeOption } from '../../../../../../contentInventory/content/models/assetFileTypeOptions.ts';
import { ModalContentItemSelector } from '../../../../../../features/ModalContentItemSelector/containers/ModalContentItemSelector.tsx';
import { ModalMultipleContentItemsSelector } from '../../../../../../features/ModalContentItemSelector/containers/ModalMultipleContentItemsSelector.tsx';
import { AssetLimitations } from '../../../../../../richText/plugins/apiLimitations/api/EditorFeatureLimitations.ts';
import { CustomElementDialogSelectionMode, CustomElementDialogType } from './customElementTypes.ts';

const CustomElementAssetLimitations: AssetLimitations = {
  fileSize: null,
  fileType: AssetFileTypeOption.Any,
  maxHeight: null,
  maxWidth: null,
  minHeight: null,
  minWidth: null,
};

export interface ICustomElementSelectionDialogOwnProps {
  readonly elementName: string;
  readonly isOpen: boolean;
  readonly mode: CustomElementDialogSelectionMode;
  readonly onAssetSelected: (id: Uuid) => void;
  readonly onAssetsSelected: (ids: Immutable.List<Uuid>) => void;
  readonly onCloseAssetSelector: () => void;
  readonly onCloseItemSelector: () => void;
  readonly onItemSelected: (id: Uuid) => void;
  readonly onItemsSelected: (ids: ReadonlyArray<Uuid>) => void;
  readonly type: CustomElementDialogType | null;
}

export interface ICustomElementSelectionDialogDispatchProps {
  readonly onAssetSelected: (id: Uuid) => void;
  readonly onAssetsSelected: (ids: Immutable.List<Uuid>) => void;
  readonly onItemSelected: (id: Uuid) => void;
  readonly onItemsSelected: (ids: ReadonlyArray<Uuid>) => void;
}

export type CustomElementSelectionDialogProps = ICustomElementSelectionDialogOwnProps &
  ICustomElementSelectionDialogDispatchProps;

export const CustomElementSelectionDialog: React.FC<CustomElementSelectionDialogProps> = ({
  elementName,
  isOpen,
  mode,
  onAssetSelected,
  onAssetsSelected,
  onCloseAssetSelector,
  onCloseItemSelector,
  onItemSelected,
  onItemsSelected,
  type,
}) => {
  const renderDialog = (): JSX.Element => {
    const editedEntityName = `${elementName} (Custom element)`;

    if (
      type === CustomElementDialogType.ItemSelector &&
      mode === CustomElementDialogSelectionMode.Single
    ) {
      return (
        <ModalContentItemSelector
          titleBarText={`Insert an existing content item to ${editedEntityName}`}
          onClose={onCloseItemSelector}
          onSelect={onItemSelected}
        />
      );
    }
    if (
      type === CustomElementDialogType.ItemSelector &&
      mode === CustomElementDialogSelectionMode.Multiple
    ) {
      return (
        <ModalMultipleContentItemsSelector
          onClose={onCloseItemSelector}
          onSelect={onItemsSelected}
          titleBarText={`Insert existing content items to ${editedEntityName}`}
        />
      );
    }
    if (
      type === CustomElementDialogType.AssetSelector &&
      mode === CustomElementDialogSelectionMode.Multiple
    ) {
      return (
        <ModalMultipleAssetsSelector
          limitations={CustomElementAssetLimitations}
          onClose={onCloseAssetSelector}
          onSelect={onAssetsSelected}
          primaryButtonText="Insert"
          showImagesOnly={false}
          titleBarText={`Insert assets to ${editedEntityName}`}
        />
      );
    }

    if (
      type === CustomElementDialogType.ImageSelector &&
      mode === CustomElementDialogSelectionMode.Multiple
    ) {
      return (
        <ModalMultipleAssetsSelector
          limitations={CustomElementAssetLimitations}
          onClose={onCloseAssetSelector}
          onSelect={onAssetsSelected}
          primaryButtonText="Insert"
          showImagesOnly
          titleBarText={`Insert assets to ${editedEntityName}`}
        />
      );
    }

    return (
      <ModalAssetSelector
        editedEntityName={editedEntityName}
        onClose={onCloseAssetSelector}
        onSelect={onAssetSelected}
        showImagesOnly={type === CustomElementDialogType.ImageSelector}
      />
    );
  };

  return (
    <ModalViewer
      dialogClassName="dialog"
      isDialogVisible={isOpen}
      position={ModalViewerPosition.Center}
    >
      {renderDialog()}
    </ModalViewer>
  );
};
CustomElementSelectionDialog.displayName = 'CustomElementSelectionDialog';
