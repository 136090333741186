import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { ReactNode, useState } from 'react';
import { isSelectionCollapsed } from '../../../../../../_shared/utils/selectionUtils.ts';
import { PhoneLinkProperties } from '../../../../../itemEditor/models/contentItemElements/richText/PhoneLinkProperties.type.ts';
import { LinkDetail } from '../../../../../itemEditor/utils/itemElementDataConverters/utils/LinkDetail.tsx';
import { EditorLinkStatus } from '../../../apiLimitations/api/EditorLinkStatus.ts';
import { InlineContentWithDialog } from '../../components/dialog/InlineContentWithDialog.tsx';
import { PhoneLinkDialog } from './PhoneLinkDialog.tsx';
import { PhoneLinkInfo } from './PhoneLinkInfo.tsx';

enum DialogType {
  None = 'none',
  Info = 'info',
  Edit = 'edit',
}

interface IPhoneLinkProps {
  readonly children: ReadonlyArray<React.ReactNode>;
  readonly disabled?: boolean;
  readonly onCancel?: () => void;
  readonly onEdit?: () => Promise<void>;
  readonly onUnlink?: () => void;
  readonly onUpdate?: (values: PhoneLinkProperties) => void;
  readonly phoneNumber: string;
  readonly status?: EditorLinkStatus;
  readonly text: string;
}

const propTypes: PropTypeMap<IPhoneLinkProps> = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  disabled: PropTypes.bool,
  onCancel: PropTypes.func,
  onEdit: PropTypes.func,
  onUnlink: PropTypes.func,
  onUpdate: PropTypes.func,
  phoneNumber: PropTypes.string.isRequired,
  status: PropTypes.oneOf(Object.values(EditorLinkStatus)),
  text: PropTypes.string.isRequired,
};

export const PhoneLink: React.FC<IPhoneLinkProps> = (props) => {
  const [dialog, setDialog] = useState(DialogType.None);

  const startEdit = (): void => {
    if (props.onEdit) {
      props.onEdit().then(() => setDialog(DialogType.Edit));
    }
  };

  const cancelEdit = (): void => {
    if (props.onCancel) {
      props.onCancel();
      setDialog(DialogType.None);
    }
  };

  const updateLink = (values: PhoneLinkProperties): void => {
    if (props.onUpdate) {
      props.onUpdate(values);
      setDialog(DialogType.None);
    }
  };

  const toggleActive = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    if (dialog !== DialogType.Edit) {
      const selectionCollapsed = isSelectionCollapsed();
      setDialog(
        dialog === DialogType.None && selectionCollapsed ? DialogType.Info : DialogType.None,
      );
    }
    e.preventDefault();
  };

  const handleClose = (): void => {
    if (dialog === DialogType.Info) {
      setDialog(DialogType.None);
    }
  };

  const renderPopover = (): ReactNode => {
    const { disabled, phoneNumber, status, onUnlink } = props;

    if (dialog === DialogType.Info) {
      return (
        <LinkDetail disabled={disabled} onEdit={startEdit} onUnlink={onUnlink} status={status}>
          <PhoneLinkInfo disabled={disabled} phoneNumber={phoneNumber} />
        </LinkDetail>
      );
    }

    if (dialog === DialogType.Edit) {
      return (
        <PhoneLinkDialog
          linkText={props.text}
          onCancel={cancelEdit}
          onUnlink={onUnlink}
          onUpsert={updateLink}
          phoneNumber={phoneNumber}
        />
      );
    }

    return null;
  };

  return (
    <InlineContentWithDialog
      isInfo={dialog === DialogType.Info}
      renderContent={(ref) => (
        <a
          ref={ref}
          className={classNames('rte__link', 'rte__link--is-clickable', {
            'rte__link--is-edited': dialog === DialogType.Edit,
          })}
          onClick={toggleActive}
          data-phone-number={props.phoneNumber}
        >
          {props.children}
        </a>
      )}
      renderDialog={renderPopover}
      onClose={handleClose}
    />
  );
};

PhoneLink.displayName = 'PhoneLink';
PhoneLink.propTypes = propTypes;
