import PropTypes from 'prop-types';
import React from 'react';
import { NumberValue } from '../../../../../../_shared/models/NumberValue.ts';
import { INumberTypeElement } from '../../../../../contentInventory/content/models/contentTypeElements/NumberTypeElement.ts';
import {
  INumberItemElement,
  NumberItemElementPropTypes,
} from '../../../../models/contentItemElements/NumberItemElement.ts';
import { ItemElement } from '../../containers/elements/ItemElement.tsx';
import { ItemElementRefresher } from '../../containers/elements/ItemElementRefresher.tsx';
import { ReadonlyEmptyElementPlaceholder } from '../../models/ReadonlyEmptyElementPlaceholder.ts';
import { NumberInput } from '../NumberInput.tsx';
import { IItemElementComponentOwnProps } from './IItemElementOwnProps.type.ts';

export type NumberElementProps = IItemElementComponentOwnProps<
  INumberItemElement,
  INumberTypeElement
> & {
  readonly onValueChange: (value: NumberValue) => void;
  readonly showError?: boolean;
};

export class NumberElement extends React.PureComponent<NumberElementProps> {
  static displayName = 'NumberElement';

  static propTypes: PropTypesShape<NumberElementProps> = {
    elementData: NumberItemElementPropTypes.isRequired,
    onValueChange: PropTypes.func.isRequired,
    typeElement: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
    autoFocus: PropTypes.bool.isRequired,
    showError: PropTypes.bool,
  };

  private readonly _numberInputRef = React.createRef<HTMLInputElement>();

  private readonly _focusInput = () => {
    this._numberInputRef.current?.focus();
  };

  render(): JSX.Element {
    const { elementData, typeElement, disabled, showError, autoFocus } = this.props;

    return (
      <ItemElement
        typeElement={typeElement}
        disabled={disabled}
        onHeaderClick={this._focusInput}
        onContentClick={this._focusInput}
        isCompact
      >
        <ItemElementRefresher
          elementData={elementData}
          renderInput={(refresherItemElement: INumberItemElement) => (
            <NumberInput
              ref={this._numberInputRef}
              autoFocus={autoFocus}
              className="content-item-element__text-field"
              disabled={disabled}
              isValid={!showError}
              onChange={this.props.onValueChange}
              placeholder={
                disabled ? ReadonlyEmptyElementPlaceholder.NumberElement : 'Enter a number…'
              }
              typeElement={typeElement}
              value={refresherItemElement.value}
              defaultValue={typeElement.defaultValue}
            />
          )}
        />
      </ItemElement>
    );
  }
}
