import { createGuid } from '@kontent-ai/utils';
import {
  ContentState,
  DraftDecorator,
  DraftDecoratorComponentProps,
  EditorState,
  EntityInstance,
  SelectionState,
} from 'draft-js';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import {
  ActionResult,
  getContentStateActionResult,
  getStringActionResult,
} from '../../../../_shared/features/AI/helpers/transformAiResult.ts';
import { useAiTasks } from '../../../../_shared/features/AI/hooks/aiTasks/useAiTasks.ts';
import {
  FinishedActionParams,
  useAiActionTracking,
} from '../../../../_shared/features/AI/hooks/useAiActionTracking.ts';
import { usePendingAiActionWithCallback } from '../../../../_shared/features/AI/hooks/usePendingAiActionWithCallback.ts';
import { getAiErrorMessage } from '../../../../_shared/features/AI/types/aiErrors.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import {
  AiActionSource,
  AiFollowingAction,
  TrackingAiActionName,
} from '../../../../_shared/models/events/AiActionEventData.type.ts';
import { logError } from '../../../../_shared/utils/logError.ts';
import { isString } from '../../../../_shared/utils/stringUtils.ts';
import { AiActionName } from '../../../../repositories/serverModels/ai/AiActionName.type.ts';
import {
  InlineInstructionActionName,
  InlineInstructionBaseInputParams,
} from '../../../../repositories/serverModels/ai/actions/AiServerModels.inlineInstruction.ts';
import { createInlinePlainTextInstructionParams } from '../../../../repositories/serverModels/ai/actions/AiServerModels.inlinePlainTextInstruction.ts';
import { createInlineRichTextInstructionParams } from '../../../../repositories/serverModels/ai/actions/AiServerModels.inlineRichTextInstruction.ts';
import { useEditorStateCallbacks } from '../../editorCore/hooks/useEditorStateCallbacks.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { ApplyEditorStateChanges, GetEditorId } from '../../editorCore/types/Editor.base.type.ts';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { Apply, Init, Render } from '../../editorCore/types/Editor.plugins.type.ts';
import { EditorChangeReason } from '../../editorCore/types/EditorChangeReason.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import { withDisplayName } from '../../editorCore/utils/withDisplayName.ts';
import { ActiveMenuContext } from '../../plugins/inlineAi/instructions/ActiveMenuContext.tsx';
import {
  FinishedInstruction,
  getInstructionMenuId,
  isInstructionMenuId,
} from '../../plugins/inlineAi/instructions/FinishedInstruction.tsx';
import {
  NewInstructionContent,
  NewInstructionStart,
} from '../../plugins/inlineAi/instructions/NewInstruction.tsx';
import {
  AiInstructionEntity,
  FinishedAiInstructionData,
  isAiInstruction,
  isFinishedAiInstruction,
  isNewAiInstruction,
} from '../../plugins/inlineAi/utils/InstructionEntity.ts';
import { editorInlineErrorMessageByErrorCode } from '../../plugins/inlineAi/utils/editorInlineAiErrors.ts';
import {
  createFinishedInstruction,
  findFinishedInstructions,
  findInstructionContents,
  findInstructionStarts,
  getActiveInstructionEntityKey,
} from '../../plugins/inlineAi/utils/editorInlineAiUtils.ts';
import { keepCaretPositionInViewport } from '../../plugins/inlineAi/utils/keepCaretPositionInViewport.ts';
import { useActiveFinishedInstruction } from '../../plugins/inlineAi/utils/useActiveFinishedInstruction.tsx';
import { createEmptyContent } from '../../utils/blocks/editorBlockUtils.ts';
import {
  createSelection,
  getMetadataAtSelection,
  getSelectedText,
  getSelectionForEntity,
} from '../../utils/editorSelectionUtils.ts';
import { createSimpleTextValueContent } from '../../utils/editorSimpleTextValueUtils.ts';
import {
  findAiContent,
  getAiSessionIdAtSelection,
  markWholeContentAsAi,
} from '../ai/utils/editorAiUtils.ts';
import { EditorChangeCallback } from '../behavior/OnChangePlugin.tsx';
import {
  CanHandleNewCharsNatively,
  PostProcessInsertedChars,
} from '../customInputHandling/CustomInputHandlingPlugin.tsx';
import { EntityDecoratorProps } from '../entityApi/api/editorEntityUtils.ts';
import { AtomicEntity } from '../entityApi/components/AtomicEntity.tsx';
import { TextBlockTypesPlugin } from '../textBlockTypes/TextBlockTypesPlugin.tsx';
import {
  CanDisplayBlockToolbar,
  RenderBlockToolbarContent,
} from '../toolbars/BlockToolbarPlugin.tsx';
import { CanDisplayInlineToolbar } from '../toolbars/InlineToolbarPlugin.tsx';
import { InlineAiPlugin } from './InlineAiPlugin.type.ts';
import { createEditorInlineAiApi } from './api/editorInlineAiApi.ts';
import { EditorWithInlineAi } from './components/EditorWithInlineAi.tsx';
import { KopilotButton } from './components/KopilotButton.tsx';
import {
  atomicInstructionChar,
  instructionPlaceholder,
  instructionTriggerSequence,
} from './constants/aiConstants.ts';

const canHandleNewCharsNatively: Decorator<CanHandleNewCharsNatively> =
  (baseCanHandleNewCharsNatively) => (params) => {
    if (!baseCanHandleNewCharsNatively(params)) {
      return false;
    }
    return !params.chars.endsWith(
      instructionTriggerSequence[instructionTriggerSequence.length - 1] ?? '',
    );
  };

type InstructionEntityCustomProps = {
  readonly canUpdateContent: () => boolean;
  readonly focusInstruction: (entityKey: string) => void;
  readonly getEditorId: GetEditorId;
  readonly onAccept: (entityKey: string) => void;
  readonly onDiscard: (entityKey: string) => void;
  readonly onEditInstruction: (entityKey: string) => void;
  readonly onInstructionStartRemoved: (entityKey: string) => void;
  readonly onTryAgain: (entityKey: string) => void;
};

// We split the rendering of start of the instruction and its content so that we can style the Kopilot icon differently
// while keeping natural caret behavior
const NewInstructionStartEntity: React.FC<EntityDecoratorProps<InstructionEntityCustomProps>> = ({
  canUpdateContent,
  children,
  decoratedText,
  entityKey,
  onInstructionStartRemoved,
}) => {
  useEffect(() => {
    // When Kopilot icon (the start of the instruction) gets deleted, we revert the rest of the instruction text to a regular text
    // We can tell this happened when instruction starting char is no longer the atomic char representing the instruction
    if (decoratedText !== atomicInstructionChar) {
      onInstructionStartRemoved(entityKey);
    }
  }, [onInstructionStartRemoved, decoratedText, entityKey]);

  return (
    <NewInstructionStart disabled={!canUpdateContent()} key={entityKey} entityKey={entityKey}>
      {children}
    </NewInstructionStart>
  );
};

const NewInstructionContentEntity: React.FC<EntityDecoratorProps<InstructionEntityCustomProps>> = ({
  entityKey,
  children,
}) => {
  return (
    <NewInstructionContent key={entityKey} className="rte__ai" entityKey={entityKey}>
      {children}
    </NewInstructionContent>
  );
};

const FinishedInstructionEntity: React.FC<EntityDecoratorProps<InstructionEntityCustomProps>> = (
  props,
) => {
  const {
    canUpdateContent,
    contentState,
    entityKey,
    children,
    focusInstruction,
    getEditorId,
    offsetKey,
    onAccept,
    onDiscard,
    onEditInstruction,
    onTryAgain,
  } = props;
  const entity = contentState.getEntity(entityKey);
  if (!isFinishedAiInstruction(entity)) {
    return null;
  }

  return (
    <AtomicEntity
      {...props}
      key={entityKey}
      renderContent={(content) => (
        <FinishedInstruction
          data={entity.getData()}
          disabled={!canUpdateContent()}
          editorId={getEditorId()}
          offsetKey={offsetKey}
          onAccept={() => onAccept(entityKey)}
          onClick={() => focusInstruction(entityKey)}
          onDiscard={() => onDiscard(entityKey)}
          onEditInstruction={() => onEditInstruction(entityKey)}
          onTryAgain={() => onTryAgain(entityKey)}
          snapshotTime={null}
        >
          {content}
        </FinishedInstruction>
      )}
    >
      {children}
    </AtomicEntity>
  );
};

FinishedInstructionEntity.displayName = 'FinishedInstructionEntity';

type AiContentCustomProps = {
  readonly onClick: (blockKey: string, start: number) => void;
};

const AiContent: React.FC<DraftDecoratorComponentProps & AiContentCustomProps> = ({
  blockKey,
  children,
  onClick,
  start,
}) => {
  const handleClick = useCallback(() => onClick(blockKey, start), [blockKey, onClick, start]);

  return <span onClick={handleClick}>{children}</span>;
};

type PluginParams = [InlineInstructionActionName];

export type EntityTypeGuard<T extends EntityInstance> = (
  entity: EntityInstance,
  entityKey: string,
) => entity is T;

const getInstructionContext = <T extends AiInstructionEntity>(
  editorState: EditorState,
  entityKey: string,
  predicate?: EntityTypeGuard<T>,
): {
  readonly instruction: T;
  readonly selection: SelectionState;
} | null => {
  const content = editorState.getCurrentContent();
  const selection = getSelectionForEntity(content, entityKey);
  if (!selection) {
    return null;
  }

  const entity = content.getEntity(entityKey);
  return isAiInstruction(entity) && predicate?.(entity, entityKey) !== false
    ? {
        instruction: entity,
        selection,
      }
    : null;
};

const createContentStateFromContent = (content: ContentState | string): ContentState =>
  isString(content) ? createSimpleTextValueContent(content) : content;

export const useInlineAi: PluginCreator<InlineAiPlugin, PluginParams> = (baseEditor, actionName) =>
  useMemo(
    () =>
      withDisplayName('InlineAiPlugin', {
        ComposedEditor: (props) => {
          const { element, onSelectionChange: baseOnSelectionChange } = props;

          const dispatch = useDispatch();
          const elementName = element?.elementName ?? null;
          const elementId = element?.elementId ?? null;
          const editedVariantId = useSelector(
            (s) => s.contentApp.editedContentItemVariant?.id ?? null,
          );
          const language = useSelector(
            (s) =>
              (editedVariantId && s.data.languages.byId.get(editedVariantId.variantId)) ??
              s.data.languages.defaultLanguage,
          );

          const { run } = useAiTasks();

          const {
            getElementOperationTrackingData,
            trackFinishedAction,
            trackFollowingAction,
            trackStartingAction,
          } = useAiActionTracking(element);

          const {
            decorateWithEditorStateCallbacks,
            canUpdateContent,
            executeChange,
            getApi,
            getEditorId,
            getEditorState,
            getRteInputRef,
          } = useEditorStateCallbacks<InlineAiPlugin>();

          const { activeMenuId, setActiveMenuId } = useContext(ActiveMenuContext);

          const {
            activeFinishedInstructionSessionId,
            resetActiveFinishedInstruction,
            updateActiveFinishedInstruction,
          } = useActiveFinishedInstruction(getEditorId);

          const isInstructionMenuDisplayed = isInstructionMenuId(activeMenuId);

          const canDisplayInlineToolbar: Decorator<CanDisplayInlineToolbar> = useCallback(
            (baseCanDisplayInlineToolbar) => (editorState) =>
              !isInstructionMenuDisplayed &&
              !getActiveInstructionEntityKey(
                editorState.getCurrentContent(),
                editorState.getSelection(),
              ) &&
              baseCanDisplayInlineToolbar(editorState),
            [isInstructionMenuDisplayed],
          );

          const canDisplayBlockToolbar: Decorator<CanDisplayBlockToolbar> = useCallback(
            (baseCanDisplayBlockToolbar) => (editorState) =>
              !isInstructionMenuDisplayed &&
              !getActiveInstructionEntityKey(
                editorState.getCurrentContent(),
                editorState.getSelection(),
              ) &&
              baseCanDisplayBlockToolbar(editorState),
            [isInstructionMenuDisplayed],
          );

          const { cancelPendingAiAction, startPendingAiAction, finishPendingAiAction } =
            usePendingAiActionWithCallback();

          const newInstruction = useCallback(() => {
            executeChange((editorState) => {
              const api = getApi();
              const withInstructionChar = api.insertNewChars(
                editorState,
                instructionTriggerSequence,
              );
              const data = {
                source: AiActionSource.BlockToolbar,
                aiSessionId: createGuid(),
              };
              const withInstruction = api.applyNewAiInstruction(withInstructionChar, data);
              trackStartingAction({
                action: TrackingAiActionName.NewInlineInstruction,
                ...data,
              });

              return withInstruction;
            });
          }, [executeChange, getApi, trackStartingAction]);

          const onInstructionStartRemoved = useCallback(
            (entityKey: string): void => {
              if (canUpdateContent()) {
                // Cancel the instruction, but keep the remaining text
                executeChange((editorState) =>
                  getApi().removeEntities(
                    editorState,
                    (entity, key) => isNewAiInstruction(entity) && key === entityKey,
                    (originalText, entity) => {
                      if (isNewAiInstruction(entity)) {
                        trackFollowingAction({
                          action: AiFollowingAction.Cancel,
                          aiSessionId: entity.getData().aiSessionId,
                        });
                      }
                      return originalText;
                    },
                    // Do not record undo step for this, as the undo step for removal of the instruction start is already recorded
                    false,
                  ),
                );
              }
            },
            [canUpdateContent, executeChange, getApi, trackFollowingAction],
          );

          const onFinished = useCallback(
            (aiSessionId: Uuid, trackingParams: FinishedActionParams) => {
              finishPendingAiAction(aiSessionId);

              trackFinishedAction({
                ...trackingParams,
                aiSessionId,
              });
            },
            [trackFinishedAction, finishPendingAiAction],
          );

          const applyResult = useCallback(
            (
              data: FinishedAiInstructionData,
              result: ActionResult<string> | ActionResult<ContentState>,
            ) => {
              keepCaretPositionInViewport(getRteInputRef(), () =>
                executeChange((editorState) => {
                  const resultContent = result.content
                    ? createContentStateFromContent(result.content)
                    : createEmptyContent();

                  const resultContentWithInstruction = createFinishedInstruction(
                    {
                      content: resultContent,
                      selection: createSelection(resultContent.getFirstBlock().getKey()),
                    },
                    {
                      ...data,
                      ...(result.error
                        ? {
                            error: getAiErrorMessage(
                              result.error,
                              editorInlineErrorMessageByErrorCode,
                            ),
                          }
                        : undefined),
                      ...(result.isFinished ? { isFinished: true } : undefined),
                      lastModifiedAt: new Date().toUTCString(),
                    },
                  ).content;

                  const resultAiContent = markWholeContentAsAi(
                    resultContentWithInstruction,
                    data.aiSessionId,
                  );
                  const newEditorState = getApi().replaceAiContent(
                    editorState,
                    data.aiSessionId,
                    resultAiContent,
                    false,
                  );

                  // Automatically show menu when finished and the focus stayed in the finished instruction
                  if (
                    result.isFinished &&
                    getAiSessionIdAtSelection(
                      newEditorState.getCurrentContent(),
                      newEditorState.getSelection(),
                    ) === data.aiSessionId
                  ) {
                    setActiveMenuId(getInstructionMenuId(getEditorId(), data.aiSessionId));
                  }

                  return newEditorState;
                }, EditorChangeReason.Internal),
              );
            },
            [getRteInputRef, executeChange, getApi, getEditorId, setActiveMenuId],
          );

          const submitInstruction = useCallback(
            async (
              entityKey: string,
              instruction: string,
              source: AiActionSource,
            ): Promise<boolean> => {
              if (!canUpdateContent()) {
                return false;
              }

              if (!editedVariantId) {
                logError(`EditedVariantId is ${editedVariantId}`);
                return false;
              }

              if (!elementId) {
                logError(`ElementId is ${elementId}`);
                return false;
              }

              let finishedEntityKey: string | null = null;
              const withFinishedInstruction = await executeChange((editorState) => {
                const newInstructionContext = getInstructionContext(editorState, entityKey);
                if (!newInstructionContext) {
                  return editorState;
                }

                const newEditorState = getApi().createFinishedAiInstruction(
                  editorState,
                  newInstructionContext.selection,
                  {
                    instruction,
                    aiSessionId: newInstructionContext.instruction.getData().aiSessionId,
                    lastModifiedAt: new Date().toUTCString(),
                  },
                );
                finishedEntityKey = newEditorState.getCurrentContent().getLastCreatedEntityKey();

                return newEditorState;
              });

              // Start the AI operation
              if (!finishedEntityKey) {
                return false;
              }

              const finishedInstructionContext = getInstructionContext(
                withFinishedInstruction,
                finishedEntityKey,
                isFinishedAiInstruction,
              );
              if (!finishedInstructionContext) {
                return false;
              }

              const data = finishedInstructionContext.instruction.getData();
              const { aiSessionId } = data;

              trackStartingAction({
                action: actionName,
                aiSessionId,
                source,
              });
              const withInstructionPlaceholder = getApi().insertNewChars(
                EditorState.forceSelection(
                  withFinishedInstruction,
                  finishedInstructionContext.selection,
                ),
                instructionPlaceholder,
              );

              const itemName = dispatch(
                (_, getState) => getState().contentApp.editedContentItem?.name ?? null,
              );

              // Make sure that the same session ID doesn't run twice and older result doesn't overwrite newer one
              // This primarily handles the case when you get back to the edited instruction via undo, explicit cancel is handled in specific methods
              cancelPendingAiAction(aiSessionId);
              resetActiveFinishedInstruction(aiSessionId);

              const baseParams: InlineInstructionBaseInputParams = {
                elementId,
                elementName,
                instruction,
                itemName,
                itemVariantId: editedVariantId,
                languageCodename: language.codename,
                languageName: language.name,
              };

              switch (actionName) {
                case AiActionName.PlainTextInlineInstruction: {
                  const { cancel } = await run(
                    actionName,
                    createInlinePlainTextInstructionParams(
                      {
                        ...baseParams,
                        contentState: withInstructionPlaceholder.getCurrentContent(),
                      },
                      getElementOperationTrackingData(aiSessionId),
                    ),
                    (messages, context) => {
                      const result = getStringActionResult(messages, context);
                      applyResult(data, result);
                      if (result.isFinished && result.trackingParams) {
                        onFinished(data.aiSessionId, result.trackingParams);
                      }
                      return { isFinished: result.isFinished };
                    },
                  );
                  applyResult(data, getStringActionResult([], { hasTimedOut: false }));
                  startPendingAiAction(aiSessionId, cancel);
                  break;
                }

                case AiActionName.RichTextInlineInstruction: {
                  const { cancel } = await run(
                    actionName,
                    createInlineRichTextInstructionParams(
                      {
                        ...baseParams,
                        contentState: withInstructionPlaceholder.getCurrentContent(),
                      },
                      getElementOperationTrackingData(aiSessionId),
                    ),
                    (messages, context) => {
                      const result = getContentStateActionResult(messages, context);
                      applyResult(data, result);
                      if (result.isFinished && result.trackingParams) {
                        onFinished(data.aiSessionId, result.trackingParams);
                      }
                      return { isFinished: result.isFinished };
                    },
                  );
                  applyResult(data, getContentStateActionResult([], { hasTimedOut: false }));
                  startPendingAiAction(aiSessionId, cancel);
                  break;
                }

                default: {
                  throw new Error(`Unknown AiActionName: ${actionName}`);
                }
              }

              return true;
            },
            [
              actionName,
              applyResult,
              canUpdateContent,
              cancelPendingAiAction,
              editedVariantId,
              elementId,
              elementName,
              executeChange,
              getApi,
              getElementOperationTrackingData,
              language,
              onFinished,
              resetActiveFinishedInstruction,
              run,
              startPendingAiAction,
              trackStartingAction,
            ],
          );

          const onAccept = useCallback(
            (entityKey: string): void => {
              if (canUpdateContent()) {
                executeChange((editorState) => {
                  const instructionContext = getInstructionContext(editorState, entityKey);
                  if (!instructionContext) {
                    return editorState;
                  }

                  const aiSessionId = instructionContext.instruction.getData().aiSessionId;
                  trackFollowingAction({
                    action: AiFollowingAction.ReplaceSelection,
                    aiSessionId,
                  });

                  return getApi().acceptFinishedAiInstruction(editorState, aiSessionId);
                });
              }
            },
            [canUpdateContent, executeChange, getApi, trackFollowingAction],
          );

          const onDiscard = useCallback(
            (entityKey: string): void => {
              if (canUpdateContent()) {
                executeChange((editorState) => {
                  const instructionContext = getInstructionContext(editorState, entityKey);
                  if (!instructionContext) {
                    return editorState;
                  }

                  const aiSessionId = instructionContext.instruction.getData().aiSessionId;
                  trackFollowingAction({
                    action: AiFollowingAction.Discard,
                    aiSessionId,
                  });
                  cancelPendingAiAction(aiSessionId);

                  return getApi().deleteAiContent(editorState, aiSessionId);
                });
              }
            },
            [canUpdateContent, executeChange, getApi, cancelPendingAiAction, trackFollowingAction],
          );

          const onTryAgain = useCallback(
            async (entityKey: string) => {
              const editorState = getEditorState();
              const content = editorState.getCurrentContent();
              const entity = content.getEntity(entityKey);
              if (isFinishedAiInstruction(entity)) {
                const { instruction, aiSessionId } = entity.getData();

                trackFollowingAction({
                  action: AiFollowingAction.TryAgain,
                  aiSessionId,
                });
                cancelPendingAiAction(aiSessionId);

                await submitInstruction(entityKey, instruction, AiActionSource.ActionMenu);
              }
            },
            [getEditorState, cancelPendingAiAction, submitInstruction, trackFollowingAction],
          );

          const onEditInstruction = useCallback(
            (entityKey: string): void => {
              if (canUpdateContent()) {
                executeChange((editorState) => {
                  const instructionContext = getInstructionContext(
                    editorState,
                    entityKey,
                    isFinishedAiInstruction,
                  );
                  if (!instructionContext) {
                    return editorState;
                  }

                  const data = instructionContext.instruction.getData();

                  trackFollowingAction({
                    action: AiFollowingAction.EditInputs,
                    aiSessionId: data.aiSessionId,
                  });
                  cancelPendingAiAction(data.aiSessionId);

                  return getApi().editFinishedAiInstruction(
                    editorState,
                    data,
                    AiActionSource.ActionMenu,
                  );
                });
              }
            },
            [canUpdateContent, executeChange, getApi, cancelPendingAiAction, trackFollowingAction],
          );

          const onEscape = useCallback(async (): Promise<boolean> => {
            if (canUpdateContent()) {
              let someInstructionCancelled = false;
              await executeChange((editorState) => {
                const content = editorState.getCurrentContent();
                const selection = editorState.getSelection();
                const metadataAtSelection = getMetadataAtSelection(content, selection);
                if (metadataAtSelection) {
                  // Remove all new instruction entities at the selection and replace them with the original text
                  return getApi().removeEntities(
                    editorState,
                    (entity, entityKey) =>
                      isNewAiInstruction(entity) &&
                      !!(
                        metadataAtSelection.entityKeyAtAnyTableChars?.has(entityKey) ||
                        metadataAtSelection.entityKeyAtAnyTopLevelChars?.has(entityKey)
                      ),
                    (originalText, entity) => {
                      if (!isNewAiInstruction(entity)) {
                        return originalText;
                      }

                      const { aiSessionId, source } = entity.getData();

                      trackFollowingAction({
                        action: AiFollowingAction.Escape,
                        aiSessionId,
                      });
                      someInstructionCancelled = true;

                      const originalPrefix =
                        source === AiActionSource.TextShortcut ? instructionTriggerSequence : '';
                      return originalPrefix + originalText.substring(atomicInstructionChar.length);
                    },
                  );
                }
                return editorState;
              });
              return someInstructionCancelled;
            }
            return false;
          }, [canUpdateContent, executeChange, getApi, trackFollowingAction]);

          const onSubmit = useCallback(
            async (entityKey: string) => {
              if (!canUpdateContent()) {
                return;
              }

              const editorState = getEditorState();
              const content = editorState.getCurrentContent();

              const instructionSelection = getSelectionForEntity(content, entityKey);
              if (!instructionSelection) {
                return;
              }

              const entity = content.getEntity(entityKey);
              if (!isNewAiInstruction(entity)) {
                return;
              }

              const instruction = getSelectedText(content, instructionSelection);
              if (
                instruction.startsWith(atomicInstructionChar) &&
                instruction.length > atomicInstructionChar.length
              ) {
                await submitInstruction(
                  entityKey,
                  instruction.substring(atomicInstructionChar.length),
                  entity.getData().source,
                );
              }
            },
            [canUpdateContent, getEditorState, submitInstruction],
          );

          const focusInstruction = useCallback(
            (entityKey: string) =>
              executeChange((editorState) => {
                const selection = getApi().getSelectionForEntity(editorState, entityKey);
                if (!selection) {
                  return editorState;
                }

                return EditorState.forceSelection(
                  editorState,
                  createSelection(selection.getEndKey(), selection.getEndOffset()),
                );
              }),
            [getApi, executeChange],
          );

          const onSelectionChange: EditorChangeCallback = useCallback(
            async (editorState, changeReason) => {
              await baseOnSelectionChange?.(editorState, changeReason);
              updateActiveFinishedInstruction(editorState);
            },
            [baseOnSelectionChange, updateActiveFinishedInstruction],
          );

          const onAiContentClick = useCallback(
            (blockKey: string, start: number) => {
              const editorState = getEditorState();
              const activeAiSessionId = getAiSessionIdAtSelection(
                editorState.getCurrentContent(),
                createSelection(blockKey, start + 1),
              );
              if (activeAiSessionId) {
                const menuId = getInstructionMenuId(getEditorId(), activeAiSessionId);
                setActiveMenuId((prev) => (menuId === prev ? null : menuId));
              }
            },
            [getEditorId, getEditorState, setActiveMenuId],
          );

          const init: Init = useCallback(
            (state) => {
              const InstructionCustomProps: InstructionEntityCustomProps = {
                canUpdateContent,
                focusInstruction,
                getEditorId,
                onAccept,
                onDiscard,
                onEditInstruction,
                onInstructionStartRemoved,
                onTryAgain,
              };
              const instructionDecorators: ReadonlyArray<
                DraftDecorator<InstructionEntityCustomProps> | DraftDecorator<AiContentCustomProps>
              > = [
                {
                  strategy: findInstructionStarts,
                  component: NewInstructionStartEntity,
                  props: InstructionCustomProps,
                },
                {
                  strategy: findInstructionContents,
                  component: NewInstructionContentEntity,
                  props: InstructionCustomProps,
                },
                {
                  strategy: findFinishedInstructions,
                  component: FinishedInstructionEntity,
                  props: InstructionCustomProps,
                },
                {
                  strategy: findAiContent,
                  component: AiContent,
                  props: { onClick: onAiContentClick },
                },
              ];

              return {
                decorators: [...state.decorators, ...instructionDecorators],
              };
            },
            [
              canUpdateContent,
              focusInstruction,
              getEditorId,
              onAccept,
              onAiContentClick,
              onEditInstruction,
              onDiscard,
              onInstructionStartRemoved,
              onTryAgain,
            ],
          );

          const render: Decorator<Render<InlineAiPlugin>> = useCallback(
            (baseRender) => (state) => (
              <EditorWithInlineAi
                activeFinishedInstructionSessionId={activeFinishedInstructionSessionId}
                baseRender={baseRender}
                element={element}
                onEscape={onEscape}
                onSubmit={onSubmit}
                state={state}
              />
            ),
            [activeFinishedInstructionSessionId, element, onEscape, onSubmit],
          );

          const renderBlockToolbarContent: Decorator<
            RenderBlockToolbarContent<TextBlockTypesPlugin>
          > = useCallback(
            (baseRender) => (state, isToolbarVertical) => {
              if (!state.canUpdateContent()) {
                return baseRender(state, isToolbarVertical);
              }

              return (
                <>
                  <KopilotButton onClick={newInstruction} />
                  {baseRender(state, isToolbarVertical)}
                </>
              );
            },
            [newInstruction],
          );

          const applyEditorStateChanges: Decorator<ApplyEditorStateChanges> = useCallback(
            (baseApplyEditorStateChanges) => (params) => {
              const allowedNewState = baseApplyEditorStateChanges(params);

              const { oldState } = params;

              return getApi().removeAbandonedEmptyInstruction(allowedNewState, oldState);
            },
            [getApi],
          );

          const apply: Apply<InlineAiPlugin> = useCallback(
            (state) => {
              decorateWithEditorStateCallbacks(state);
              state.render.decorate(render);

              const onNewCharsInserted: Decorator<PostProcessInsertedChars> =
                (baseOnNewCharsInserted) => (params) => {
                  const newEditorState = baseOnNewCharsInserted(params);
                  const data = {
                    source: AiActionSource.TextShortcut,
                    aiSessionId: createGuid(),
                  };
                  const withNewInstruction = state
                    .getApi()
                    .applyNewAiInstruction(newEditorState, data);
                  if (withNewInstruction !== newEditorState) {
                    trackStartingAction({
                      action: TrackingAiActionName.NewInlineInstruction,
                      ...data,
                    });
                  }

                  return withNewInstruction;
                };

              state.canHandleNewCharsNatively.decorate(canHandleNewCharsNatively);
              state.postProcessInsertedChars.decorate(onNewCharsInserted);
              state.renderBlockToolbarContent?.decorate(renderBlockToolbarContent);
              state.canDisplayInlineToolbar?.decorate(canDisplayInlineToolbar);
              state.canDisplayBlockToolbar?.decorate(canDisplayBlockToolbar);
              state.applyEditorStateChanges.decorate(applyEditorStateChanges);

              return {};
            },
            [
              applyEditorStateChanges,
              canDisplayBlockToolbar,
              canDisplayInlineToolbar,
              decorateWithEditorStateCallbacks,
              render,
              renderBlockToolbarContent,
              trackStartingAction,
            ],
          );

          return useEditorWithPlugin(
            baseEditor,
            {
              ...props,
              onSelectionChange,
            },
            {
              init,
              apply,
              getApiMethods: createEditorInlineAiApi,
            },
          );
        },
      }),
    [baseEditor, actionName],
  );
