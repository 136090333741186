import { getScrollParent } from '@kontent-ai/DOM';
import Immutable from 'immutable';
import ImmutablePropTypes from 'immutable-prop-types';
import PropTypes from 'prop-types';
import React from 'react';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { DebouncedFunction, debounce } from '../../utils/func/debounce.ts';
import { throttle } from '../../utils/func/throttle.ts';
import { IScrollState, areScrollStatsTheSame } from '../../utils/scrollGridUtils.ts';
import { ScrollGridDefaultTileSkelet } from './ScrollGridDefaultTileSkelet.tsx';

export interface IScrollGridDataProps<ItemType, GridConfigType> {
  readonly items: Immutable.List<ItemType | null>;
  readonly collectionName?: DataUiCollection;
  readonly scrollGridState: IScrollState;
  readonly isWithAnchor?: boolean;
  readonly searchPhrase?: string;
  readonly config?: GridConfigType;
}

export interface IScrollGridCallbacksProps {
  readonly onChange: (state: IScrollGridComponentState) => void;
  readonly onItemClick?: (itemId: Uuid) => void;
  readonly onItemDoubleClick?: (itemId: Uuid) => void;
}

interface IScrollGridProps<ItemType, GridConfigType>
  extends IScrollGridDataProps<ItemType, GridConfigType>,
    IScrollGridCallbacksProps {}

interface IScrollGridComponentState extends IScrollState {}

interface ITileProps<ItemType, GridConfigType> {
  readonly item: ItemType;
  readonly searchPhrase?: string;
  readonly config?: GridConfigType;
  readonly onClick?: (itemId: Uuid) => void;
  readonly onDoubleClick?: (itemId: Uuid) => void;
}

type ScrollGridComponentParams<ItemType, GridConfigType> = {
  readonly TileSkeletComponent?: React.ComponentType;
  readonly TileComponent: React.ComponentType<ITileProps<ItemType, GridConfigType>>;
  readonly EmptyStateComponent?: React.ComponentType;
};

export function getScrollGridComponent<ItemType extends { id: Uuid }, GridConfigType>({
  TileSkeletComponent,
  TileComponent,
  EmptyStateComponent,
}: ScrollGridComponentParams<ItemType, GridConfigType>) {
  return class ScrollGrid extends React.PureComponent<
    IScrollGridProps<ItemType, GridConfigType>,
    IScrollGridComponentState
  > {
    static displayName = 'ScrollGrid';

    static propTypes: PropTypesShape<IScrollGridProps<ItemType, GridConfigType>> = {
      items: ImmutablePropTypes.list.isRequired,
      scrollGridState: PropTypes.object.isRequired,
      collectionName: PropTypes.string,
      searchPhrase: PropTypes.string,
      isWithAnchor: PropTypes.bool,
      config: PropTypes.object,
      onChange: PropTypes.func.isRequired,
      onItemClick: PropTypes.func,
      onItemDoubleClick: PropTypes.func,
    };

    private readonly _propagateUpdate = throttle(() => {
      if (!areScrollStatsTheSame(this._lastDispatchedScrollGridStats, this.state)) {
        this._lastDispatchedScrollGridStats = this.state;
        this.props.onChange(this.state);
      }
    }, 200);

    private _scrollParent: Element | null = null;
    private _lastDispatchedScrollGridStats: IScrollState | null = null;
    private _scrollWatcherRegistered: boolean = false;
    private readonly _recalculateAvailableHeightDelay: DebouncedFunction;

    constructor(props: IScrollGridProps<ItemType, GridConfigType>) {
      super(props);

      this.state = {
        scrollHeightPx: 0,
        availableHeightPx: 0,
        scrollPositionPx: 0,
      };
      this._recalculateAvailableHeightDelay = debounce(this._recalculateAvailableHeight, 100);
    }

    componentDidMount() {
      this._updateScrollPositionAccordingToProps(this.props.scrollGridState);
      this._moveScroll(this.props.scrollGridState.scrollPositionPx);
      this._recalculateAvailableHeight();
      this._registerResizeWatcher();
      this._registerScrollWatcher();
    }

    componentDidUpdate(prevProps: IScrollGridProps<ItemType, GridConfigType>) {
      if (!prevProps.items.isEmpty() && this.props.items.isEmpty()) {
        this._unregisterScrollWatcher();
      }

      // on project change or filter change, the stats in the store will be changed
      if (
        !areScrollStatsTheSame(prevProps.scrollGridState, this.props.scrollGridState) &&
        !areScrollStatsTheSame(this.props.scrollGridState, this.state)
      ) {
        this._updateScrollPositionAccordingToProps(this.props.scrollGridState);
      }

      this._recalculateAvailableHeight();
      this._registerScrollWatcher();
      this._propagateUpdate();
    }

    componentWillUnmount() {
      this._recalculateAvailableHeightDelay.cancel();
      this._unregisterScrollWatcher();
    }

    _updateScrollPositionAccordingToProps = (storeScrollState: IScrollState): void =>
      this.setState(() => ({
        scrollPositionPx: storeScrollState.scrollPositionPx,
      }));

    _recalculateAvailableHeight = (): void => {
      const scrollParent = this._scrollParent;
      if (scrollParent) {
        this.setState(() => ({
          availableHeightPx: scrollParent.clientHeight,
          scrollHeightPx: scrollParent.scrollHeight,
        }));
      }
    };

    _updateScrollPosition = (event: WheelEvent): void => {
      const scrollPosition = (event.target as HTMLElement).scrollTop;
      this.setState(() => ({
        scrollPositionPx: scrollPosition,
      }));
    };

    _registerResizeWatcher = (): void =>
      window.addEventListener('resize', () => this._recalculateAvailableHeightDelay);

    _registerScrollWatcher = (): void => {
      const scroll = this._scrollParent;
      if (!this._scrollWatcherRegistered && scroll) {
        this._scrollWatcherRegistered = true;
        scroll.addEventListener('scroll', this._updateScrollPosition);
      }
    };

    _moveScroll = (pixelsY: number) => {
      if (this.props.isWithAnchor && this._scrollParent) {
        this._scrollParent.scrollTo(0, pixelsY);
      }
    };

    _unregisterScrollWatcher = (): void => {
      if (this._scrollWatcherRegistered && this._scrollParent) {
        this._scrollParent.removeEventListener('scroll', this._updateScrollPosition);
      }
      this._scrollWatcherRegistered = false;
      window.removeEventListener('resize', this._recalculateAvailableHeight);
    };

    _getRow = (item: ItemType | null, index: number): JSX.Element => {
      const RowSkeletComponent = TileSkeletComponent
        ? TileSkeletComponent
        : ScrollGridDefaultTileSkelet;
      return item ? (
        <TileComponent
          onClick={this.props.onItemClick}
          onDoubleClick={this.props.onItemDoubleClick}
          key={item.id}
          item={item}
          searchPhrase={this.props.searchPhrase}
          config={this.props.config}
        />
      ) : (
        <RowSkeletComponent key={index} />
      );
    };

    render() {
      const { collectionName, items } = this.props;

      return (
        <div
          className="scroll-grid"
          {...(collectionName && getDataUiCollectionAttribute(collectionName))}
        >
          {items.isEmpty() && EmptyStateComponent ? (
            <EmptyStateComponent />
          ) : (
            <div
              className="scroll-grid__container"
              ref={(c) => {
                this._scrollParent = getScrollParent(c);
              }}
            >
              <div className="scroll-grid__body">{items.toArray().map(this._getRow)}</div>
            </div>
          )}
        </div>
      );
    }
  };
}
