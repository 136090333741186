import { BoxBorder } from '@kontent-ai/component-library/Box';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { ReactNode, RefObject } from 'react';

interface IWebSpotlightWorkspaceProps {
  readonly children?: ReactNode;
  readonly withItemEditor?: boolean;
  readonly workspaceRef?: RefObject<HTMLDivElement>;
}

const propTypes: PropTypesShape<IWebSpotlightWorkspaceProps> = {
  children: PropTypes.node,
  withItemEditor: PropTypes.bool,
  workspaceRef: PropTypes.object,
};

export const WebSpotlightWorkspace: React.FC<IWebSpotlightWorkspaceProps> = ({
  children,
  withItemEditor,
  workspaceRef,
}) => (
  <div
    className={classNames('web-spotlight__workspace', {
      'web-spotlight__workspace--with-item-editor': withItemEditor,
    })}
    css={`border-top: ${BoxBorder.None};`}
    ref={workspaceRef}
  >
    {children}
  </div>
);

WebSpotlightWorkspace.displayName = 'WebSpotlightWorkspace';
WebSpotlightWorkspace.propTypes = propTypes;
