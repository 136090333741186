import React, { useCallback, useContext } from 'react';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { EditableTypeElement } from '../../../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { useItemVariantId } from '../../../../../../itemEditor/features/ContentComponent/context/ContentItemContext.tsx';
import { contentComponentElementComponentLocator } from '../../../../../../itemEditor/features/ContentComponent/utils/contentComponentElementComponentLocator.ts';
import { changeRichStringElementValue } from '../../../../../../itemEditor/features/ContentItemEditing/actions/thunkContentItemEditingActions.ts';
import { RichTextItemElementContext } from '../../../../../../itemEditor/features/ContentItemEditing/context/RichTextItemElementContext.tsx';
import { updateContentComponentElement } from '../../../../../../itemEditor/features/ContentItemEditing/utils/contentComponentUtils.ts';
import { ICompiledContentItemElementData } from '../../../../../../itemEditor/models/contentItemElements/ICompiledContentItemElement.ts';
import { isRichTextElement } from '../../../../../../itemEditor/models/contentItemElements/compiledItemElementTypeGuards.ts';
import { getElementById } from '../../../../../../itemEditor/stores/utils/contentItemElementsUtils.ts';
import { createItemElementWithInitialValue } from '../../../../../../itemEditor/utils/itemElementCreator.ts';
import { getValidationSelectorId } from '../../../../../../itemEditor/utils/itemElementWarningCheckers/utils/getValidationSelectorId.ts';

interface IEditableContentComponentItemElementProps {
  readonly autoFocus: boolean;
  readonly contentComponentId: Uuid;
  readonly disabled: boolean;
  readonly typeElement: EditableTypeElement;
}

export const EditableContentComponentItemElement: React.FC<
  IEditableContentComponentItemElementProps
> = ({ autoFocus, contentComponentId, disabled, typeElement }) => {
  const { elementId } = typeElement;

  const dispatch = useDispatch();
  const itemId = useItemVariantId();
  const { rootRichTextElementId } = useContext(RichTextItemElementContext);
  const contentComponentElement = useSelector((state) => {
    if (!rootRichTextElementId) {
      return null;
    }

    const topLevelElementData = getElementById(
      rootRichTextElementId,
      state.contentApp.editedContentItemVariantElements,
    );
    if (!isRichTextElement(topLevelElementData)) {
      return null;
    }

    const contentComponent = topLevelElementData.contentComponents.get(contentComponentId) ?? null;
    return contentComponent && getElementById(typeElement.elementId, contentComponent.elements);
  });

  const onUpdate = useCallback(
    (newComponentElementData: ICompiledContentItemElementData) => {
      if (rootRichTextElementId) {
        dispatch(
          changeRichStringElementValue(itemId, rootRichTextElementId, (elementData) =>
            updateContentComponentElement(
              elementData,
              contentComponentId,
              elementId,
              () => newComponentElementData,
              () => createItemElementWithInitialValue(typeElement),
            ),
          ),
        );
      }
    },
    [itemId, rootRichTextElementId, contentComponentId, elementId, typeElement],
  );

  const elementData = contentComponentElement ?? createItemElementWithInitialValue(typeElement);
  const validationResultSelectorId = getValidationSelectorId(
    typeElement.elementId,
    contentComponentId,
  );

  const ElementComponent = contentComponentElementComponentLocator.getComponent(typeElement.type);

  return (
    <ElementComponent
      key={typeElement.elementId}
      typeElement={typeElement}
      elementData={elementData}
      autoFocus={autoFocus}
      disabled={disabled}
      onUpdate={onUpdate}
      validationResultSelectorId={validationResultSelectorId}
      contentComponentId={contentComponentId}
    />
  );
};

EditableContentComponentItemElement.displayName = 'EditableContentComponentItemElement';
