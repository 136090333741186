import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import { ControlAltShortcutTemplate } from '../../../../../_shared/constants/shortcutSymbols.ts';
import {
  DataUiRteAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IForwardedRefProps, forwardRef } from '../../../../../_shared/utils/forwardedRefProps.tsx';
import {
  ISpecificButtonProps,
  RTEToolbarIconButton,
} from '../../toolbars/components/RTEToolbarButton.tsx';

const TableButtonComponent = (
  props: ISpecificButtonProps & IForwardedRefProps<HTMLButtonElement>,
) => (
  <RTEToolbarIconButton
    {...props}
    iconName={IconName.Table}
    ref={props.forwardedRef}
    tooltipPlacement={props.tooltipPlacement ?? 'bottom-start'}
    tooltipText={
      props.tooltipText ?? (props.disabled ? 'Tables cannot be added here' : 'Insert table')
    }
    shortcut={ControlAltShortcutTemplate('T')}
    {...getDataUiActionAttribute(DataUiRteAction.AddTable)}
  />
);

export const TableButton = forwardRef(TableButtonComponent);
