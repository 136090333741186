import React, { ReactNode, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { loadSubscriptionProperties } from '../../../../_shared/actions/thunkSharedActions.ts';
import { EnsureValidState } from '../../../../_shared/components/EnsureValidState.tsx';
import { SubscriptionRouteParams } from '../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { isUuid } from '../../../../_shared/utils/validation/typeValidators.ts';
import { getSelectedSubscription } from '../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { clearSelectedSubscription } from '../actions/subscriptionManagementActions.ts';
import { ensureSelectedSubscription } from '../actions/thunkSubscriptionManagementActions.ts';

type Props = {
  readonly children: ReactNode;
};

const useLoadSubscriptionProperties = (
  currenSubscriptionId: Uuid | null,
  isStateEnsured: boolean,
) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (isStateEnsured && isUuid(currenSubscriptionId)) {
      dispatch(loadSubscriptionProperties(currenSubscriptionId));
    }
  }, [isStateEnsured, currenSubscriptionId]);
};

export const EnsureSelectedSubscription: React.FC<Props> = ({ children }) => {
  const history = useHistory();
  const { subscriptionId } = useParams<SubscriptionRouteParams>();

  const isStateEnsured = useSelector((state) => {
    const selectedSubscription = getSelectedSubscription(state);

    return (
      !!selectedSubscription &&
      selectedSubscription.subscriptionId === subscriptionId &&
      !!state.data.subscriptions.subscriptionUsages.size
    );
  });

  useLoadSubscriptionProperties(subscriptionId ?? null, isStateEnsured);

  const dispatch = useDispatch();
  const onRouteEntered = () => dispatch(ensureSelectedSubscription(subscriptionId, history));
  const onRouteLeft = () => dispatch(clearSelectedSubscription());

  return (
    <EnsureValidState
      isStateEnsured={isStateEnsured}
      onRouteEntered={onRouteEntered}
      onRouteLeft={onRouteLeft}
    >
      {children}
    </EnsureValidState>
  );
};

EnsureSelectedSubscription.displayName = 'EnsureSelectedSubscription';
