import { Direction } from '@kontent-ai/types';
import { Collection, noOperation } from '@kontent-ai/utils';
import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { ConnectDropTarget } from 'react-dnd';
import {
  DropFilesHandler,
  DropFilesParams,
  FileDropContainer,
  IRenderFilesPreview,
} from '../../../../_shared/components/DragDrop/FileDropContainer.tsx';
import { DefaultCollectionId } from '../../../../_shared/constants/variantIdValues.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { areCollectionsVisibleForAssets } from '../../../../_shared/selectors/contentCollections.ts';
import { Capability } from '../../../../_shared/utils/permissions/capability.ts';
import { currentUserHasCapabilities } from '../../../../_shared/utils/permissions/capabilityUtils.ts';
import { RequiredAssetCreationMetadata } from '../../../../repositories/serverModels/AssetServerModels.type.ts';
import { Dropzone } from '../../../contentInventory/assets/components/UploadDropzone.tsx';
import { RootFolderId } from '../../../contentInventory/assets/constants/assetFolderConstants.ts';
import { AssetsUploadToCollectionDialog } from '../../../contentInventory/assets/containers/AssetListing/AssetsUploadToCollectionDialog.tsx';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { BaseEditorProps } from '../../editorCore/types/Editor.base.type.ts';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { DecoratedEditor } from '../../editorCore/types/Editor.decorated.type.ts';
import {
  Apply,
  EditorPlugin,
  PluginState,
  Render,
} from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import {
  RteAcceptsFilesClassName,
  findDropFilesTarget,
} from '../../editorCore/utils/editorComponentUtils.ts';
import { withDisplayName } from '../../editorCore/utils/withDisplayName.ts';
import { isContentEmpty } from '../../utils/general/editorContentUtils.ts';
import { GetIsDragging } from '../dragDrop/DragDropPlugin.tsx';
import { UploadFiles, UploadFilesPlugin } from '../uploadFiles/UploadFilesPlugin.tsx';
import { DraggedFilesPreview } from './components/DraggedFilesPreview.tsx';

export type DropFilesPlugin = EditorPlugin<None, None, None, [UploadFilesPlugin]>;

type EditorWithDropFilesProps = Pick<BaseEditorProps, 'disabled'> & {
  readonly canUploadAsset: boolean;
  readonly dragFilesPreviewMounted: () => void;
  readonly dragFilesPreviewUnmounted: () => void;
  readonly onDropFiles: DropFilesHandler;
  readonly rteRef: React.RefObject<HTMLDivElement>;
};

const EditorWithDropFiles: DecoratedEditor<DropFilesPlugin, EditorWithDropFilesProps> = ({
  baseRender,
  canUploadAsset,
  disabled,
  dragFilesPreviewMounted,
  dragFilesPreviewUnmounted,
  onDropFiles,
  rteRef,
  state,
}) => {
  const { getEditorState } = state;

  const renderFilesPreview: IRenderFilesPreview = useCallback(
    (contentRef, files, target, direction) => {
      // If the editor is empty, we only render the preview at its start to avoid visual ambiguity
      const renderDirection = isContentEmpty(getEditorState().getCurrentContent())
        ? Direction.Backward
        : direction;

      return (
        <DraggedFilesPreview
          direction={renderDirection}
          files={files}
          contentRef={contentRef}
          onMount={dragFilesPreviewMounted}
          onUnmount={dragFilesPreviewUnmounted}
          targetBlockElement={target.element}
        />
      );
    },
    [dragFilesPreviewMounted, dragFilesPreviewUnmounted, getEditorState],
  );

  const getState = (connectDropTarget?: ConnectDropTarget): PluginState<DropFilesPlugin> => ({
    ...state,
    rteProps: {
      ...state.rteProps,
      className: classNames(state.rteProps.className, {
        [RteAcceptsFilesClassName]: !disabled,
      }),
      connectDropTarget,
    },
  });

  if (!canUploadAsset) {
    // This dropzone serves only to display the disabled state while dragging.
    return (
      <Dropzone disabled onDrop={noOperation}>
        {baseRender(getState())}
      </Dropzone>
    );
  }

  return (
    <FileDropContainer
      canDrop={!disabled}
      contentRef={rteRef}
      findTarget={findDropFilesTarget}
      onDropFiles={onDropFiles}
      renderContent={(connectDropTarget) => baseRender(getState(connectDropTarget))}
      renderPreview={renderFilesPreview}
    />
  );
};

EditorWithDropFiles.displayName = 'EditorWithDropFiles';

export const useDropFiles: PluginCreator<DropFilesPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('DropFilesPlugin', {
        ComposedEditor: (props) => {
          const { disabled } = props;

          const areCollectionsVisible = useSelector((state) =>
            areCollectionsVisibleForAssets(
              state,
              Collection.getValues(state.data.collections.byId),
            ),
          );
          const canUploadAsset = useSelector((state) =>
            currentUserHasCapabilities(state, Capability.CreateAssets),
          );
          const [dropFilesParams, setDropFilesParams] = useState<DropFilesParams | null>(null);

          const [activeDraggedFilesPreviews, setActiveDraggedFilesPreviews] = useState(0);

          const dragFilesPreviewMounted = useCallback(
            () => setActiveDraggedFilesPreviews((prevState) => prevState + 1),
            [],
          );

          const dragFilesPreviewUnmounted = useCallback(
            () => setActiveDraggedFilesPreviews((prevState) => prevState - 1),
            [],
          );

          const render: Decorator<Render<DropFilesPlugin>> = useCallback(
            (baseRender) => (state) => (
              <EditorWithDropFiles
                baseRender={baseRender}
                canUploadAsset={canUploadAsset}
                disabled={disabled}
                dragFilesPreviewMounted={dragFilesPreviewMounted}
                dragFilesPreviewUnmounted={dragFilesPreviewUnmounted}
                rteRef={state.getRteRef()}
                state={state}
                onDropFiles={(params) =>
                  areCollectionsVisible
                    ? setDropFilesParams(params)
                    : onDropFilesWithMetadata(state.uploadFiles, params, {
                        collectionId: DefaultCollectionId,
                        folderId: RootFolderId,
                      })
                }
              />
            ),
            [
              canUploadAsset,
              disabled,
              dragFilesPreviewMounted,
              dragFilesPreviewUnmounted,
              areCollectionsVisible,
            ],
          );

          const isDraggingFiles = activeDraggedFilesPreviews > 0;
          const getIsDragging: Decorator<GetIsDragging> = useCallback(
            (baseGetIsDragging) => () => isDraggingFiles || baseGetIsDragging(),
            [isDraggingFiles],
          );

          const apply: Apply<DropFilesPlugin> = useCallback(
            (state) => {
              state.render.decorate(render);
              state.getIsDragging.decorate(getIsDragging);

              const renderModal: Decorator<Render<DropFilesPlugin>> =
                (baseRenderModalToViewer) => (previousState) => {
                  if (dropFilesParams) {
                    return (
                      <AssetsUploadToCollectionDialog
                        handleClose={() => setDropFilesParams(null)}
                        handleSelect={(collectionId: Uuid | null) =>
                          onDropFilesWithMetadata(previousState.uploadFiles, dropFilesParams, {
                            collectionId,
                            folderId: RootFolderId,
                          })
                        }
                      />
                    );
                  }
                  return baseRenderModalToViewer(previousState);
                };
              state.renderModal.decorate(renderModal);

              return {};
            },
            [render, getIsDragging, dropFilesParams],
          );

          return useEditorWithPlugin(baseEditor, props, { apply });
        },
      }),
    [baseEditor],
  );

const onDropFilesWithMetadata = async (
  uploadFiles: UploadFiles,
  { files, target, direction }: DropFilesParams,
  metadata: RequiredAssetCreationMetadata,
): Promise<void> => {
  const targetBlockId = target.id;

  if (targetBlockId) {
    await uploadFiles(files, metadata, targetBlockId, direction);
  }
};
