import React from 'react';
import { ModalDialogType } from '../../../../../_shared/constants/modalDialogType.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { compose } from '../../../../../_shared/utils/func/compose.ts';
import { pluralizeWithoutCount } from '../../../../../_shared/utils/stringUtils.ts';
import {
  CascadeModalFooter,
  CascadeModalFooterOwnProps,
} from '../components/CascadeModalFooter.tsx';
import { getSelectedItems } from '../selectors/getSelectedItems.ts';
import { getCannotPublishReasonForContext } from '../utils/getCannotPublishReason.ts';

const getSelectedItemsCount = (s: IStore) =>
  getSelectedItems(s, getCannotPublishReasonForContext(ModalDialogType.CascadePublishDialog))
    .length;
const createTooltipText = (selectedItemsCount: number) =>
  selectedItemsCount > 0
    ? undefined
    : 'Select what to schedule. Already published content or content scheduled for earlier won’t be affected.';
const createCallToAction = (selectedItemsCount: number) =>
  selectedItemsCount > 0
    ? `Schedule item with selected ${pluralizeWithoutCount('item', selectedItemsCount)}`
    : 'Schedule';

export const CascadeScheduleModalFooter: React.FC<CascadeModalFooterOwnProps> = (props) => {
  const tooltipText = useSelector(compose(createTooltipText, getSelectedItemsCount));
  const callToAction = useSelector(compose(createCallToAction, getSelectedItemsCount));

  return (
    <CascadeModalFooter
      callToAction={callToAction}
      isPublishDisabled={props.isPublishDisabled}
      tooltipText={tooltipText}
      onPublish={props.onPublish}
      onClose={props.onClose}
    />
  );
};

CascadeScheduleModalFooter.displayName = 'CascadeScheduleModalFooter';
