import { InvariantException } from '@kontent-ai/errors';
import { memoize } from '@kontent-ai/memoization';
import { Direction } from '@kontent-ai/types';
import { assert } from '@kontent-ai/utils';
import {
  CharacterMetadata,
  CompositeDecorator,
  ContentBlock,
  ContentState,
  RawDraftContentBlock,
  RawDraftEntity,
  SelectionState,
  convertFromRaw,
  genKey,
} from 'draft-js';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { getItemsDirection } from '../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import { getBlocks } from '../general/editorContentGetters.ts';
import { NextBlockType, PreviousBlockType } from './blockData.ts';
import {
  BaseBlockType,
  BlockType,
  changeBaseBlockType,
  getBaseType,
  getNestedBlockType,
  isBlockTypeWithSleeves,
  isMutableBlockType,
} from './blockType.ts';
import {
  CustomBlockSleevePosition,
  findSiblingBlock,
  getCustomBlockSleevePosition,
  isCustomBlockSleeve,
  isListItem,
  isTableCell,
  isTextBlock,
} from './blockTypeUtils.ts';
import {
  getBaseBlockType,
  getBlockKey,
  getBlockLength,
  getFullBlockType,
  getNextBlockType,
  getPreviousBlockType,
} from './editorBlockGetters.ts';

export const RichTextCommentSegment = 'commentSegmentId';

export type BidiDirection = 'LTR' | 'RTL' | 'NEUTRAL';

export interface IEditorBlockProps<
  TCustomProps extends ReadonlyRecord<string, any> = Record<string, never>,
> {
  readonly contentState: ContentState;
  readonly block: ContentBlock;
  readonly customStyleMap?: AnyObject;
  readonly customStyleFn?: AnyFunction;
  readonly tree: Immutable.List<any>;
  readonly selection: SelectionState;
  readonly decorator: CompositeDecorator;
  readonly forceSelection: boolean;
  readonly direction: BidiDirection;
  readonly blockProps: TCustomProps;
  readonly startIndent?: boolean;
  readonly blockStyleFn: AnyFunction;
}

export const EditorBlockPropTypes: PropTypesShape<IEditorBlockProps> = {
  contentState: PropTypes.instanceOf(ContentState).isRequired,
  block: PropTypes.instanceOf(ContentBlock).isRequired,
  customStyleMap: PropTypes.object,
  customStyleFn: PropTypes.func,
  tree: PropTypes.object.isRequired,
  selection: PropTypes.instanceOf(SelectionState).isRequired,
  decorator: PropTypes.instanceOf(CompositeDecorator),
  forceSelection: PropTypes.bool.isRequired,
  direction: PropTypes.string,
  blockProps: PropTypes.object,
  startIndent: PropTypes.number,
  blockStyleFn: PropTypes.func,
};

export type IRawBlock = RawDraftContentBlock & {
  type: BlockType;
};

export type RawEntityMap = Record<string, RawDraftEntity>;

export type IRawBlockInput = Partial<IRawBlock> & Pick<IRawBlock, 'type'>;

export function createEmptyContent(): ContentState {
  return createContent([getUnstyledBlock([])]);
}

export function createContent(blocks: ReadonlyArray<ContentBlock>): ContentState {
  return ContentState.createFromBlockArray([...blocks]);
}

export function createContentFromRawBlocks(
  parentBlockTypes: ReadonlyArray<BaseBlockType>,
  blocks: ReadonlyArray<IRawBlockInput>,
  entityMap?: RawEntityMap,
): ContentState {
  const rawState: any = {
    entityMap: entityMap ?? {},
    blocks: blocks.map((block) => ({
      ...createRawBlock(block),
      type: getNestedBlockType(parentBlockTypes, block.type),
    })),
  };

  const contentState = convertFromRaw(rawState);
  return contentState;
}

export function convertRawBlockToContentBlock(
  parentBlockTypes: ReadonlyArray<BaseBlockType>,
  rawBlock: IRawBlock,
  entityMap?: RawEntityMap,
): ContentBlock {
  return createContentFromRawBlocks(parentBlockTypes, [rawBlock], entityMap).getFirstBlock();
}

const createEmptyRawBlock = (): IRawBlock => ({
  key: genKey(),
  text: '',
  type: BlockType.Unstyled,
  depth: 0,
  inlineStyleRanges: [],
  entityRanges: [],
});

export function createRawBlock(props: IRawBlockInput): IRawBlock {
  const newBlock: IRawBlock = {
    ...createEmptyRawBlock(),
    ...props,
  };

  return newBlock;
}

export function createEmptyRawParagraph(): IRawBlock {
  return createRawBlock({ type: BlockType.Unstyled });
}

export function getUnstyledBlock(
  parentBlockTypes: ReadonlyArray<BaseBlockType>,
  text: string = '',
  depth: number = 0,
): ContentBlock {
  const rawBlock = createRawBlock({
    type: BlockType.Unstyled,
    text,
    depth,
  });
  return convertRawBlockToContentBlock(parentBlockTypes, rawBlock);
}

export function setBlockType(block: ContentBlock, blockType: BlockType): ContentBlock {
  const updatedBlock = block.set('type', blockType) as ContentBlock;

  // Make sure that the depth is reset to 0 for blocks without depth
  const isDepthAllowed = isListItem(updatedBlock) || isTableCell(updatedBlock);
  if (!isDepthAllowed && updatedBlock.getDepth() > 0) {
    return updateBlockDepth(updatedBlock, () => 0);
  }
  return updatedBlock;
}

export function changeBlockType(block: ContentBlock, blockType: BaseBlockType): ContentBlock {
  const currentFullBlockType = getFullBlockType(block);
  const currentBlockType = getBaseType(currentFullBlockType);
  if (
    currentBlockType === blockType ||
    !isMutableBlockType(currentBlockType) ||
    !isMutableBlockType(blockType)
  ) {
    return block;
  }

  const newBlockType = changeBaseBlockType(currentFullBlockType, blockType);
  return setBlockType(block, newBlockType);
}

export const setCharacterList = (
  block: ContentBlock,
  characterList: Immutable.List<CharacterMetadata>,
): ContentBlock => block.set('characterList', characterList) as ContentBlock;

export enum BlockEdgeStatus {
  Inside = 'inside',
  StartEdge = 'start',
  EndEdge = 'end',
  EmptyBlockEdge = 'empty',
}

export function getBlockEdgeStatus(
  block: ContentBlock,
  indexOfCharacterInBlock: number,
): BlockEdgeStatus {
  const length = getBlockLength(block);
  if (length === 0) {
    return BlockEdgeStatus.EmptyBlockEdge;
  }

  if (indexOfCharacterInBlock === 0) {
    return BlockEdgeStatus.StartEdge;
  }

  return indexOfCharacterInBlock === length ? BlockEdgeStatus.EndEdge : BlockEdgeStatus.Inside;
}

export function isAtBlockEnd(block: ContentBlock, index: number): boolean {
  const edgeStatus = getBlockEdgeStatus(block, index);
  return edgeStatus === BlockEdgeStatus.EndEdge || edgeStatus === BlockEdgeStatus.EmptyBlockEdge;
}

export const isAtBlockEdge = memoize.weak((block: ContentBlock, index: number): boolean => {
  return getBlockEdgeStatus(block, index) !== BlockEdgeStatus.Inside;
});

export const removeMetadata = memoize.weak((block: ContentBlock): ContentBlock => {
  let data = block.getData();
  if (getNextBlockType(block)) {
    data = data.delete(NextBlockType);
  }
  if (getPreviousBlockType(block)) {
    data = data.delete(PreviousBlockType);
  }
  return block.set('data', data) as ContentBlock;
});

export function getBlockDataValue<TValue>(block: ContentBlock, name: string): TValue | undefined {
  return block.getData()?.get(name);
}

export function setBlockDataValue<T>(
  block: ContentBlock,
  name: string,
  value: T | undefined,
): ContentBlock {
  const data = block.getData() || Immutable.Map<any, any>();
  const newData = value === undefined ? data.remove(name) : data.set(name, value);
  return block.merge({ data: newData }) as ContentBlock;
}

export function setBlockText(
  block: ContentBlock,
  textData: {
    text: string;
    characterList: Immutable.List<CharacterMetadata>;
  },
): ContentBlock {
  if (textData.characterList.size !== textData.text.length) {
    throw InvariantException('All text data must match in length');
  }

  return block.merge({
    text: textData.text,
    characterList: textData.characterList,
  }) as ContentBlock;
}

export const getBlocksDirection = (
  content: ContentState,
  fromKey: Uuid,
  toKey: Uuid,
): Direction | null => {
  const blocks = getBlocks(content);
  return getItemsDirection(blocks, fromKey, toKey, getBlockKey);
};

export function updateBlockDepth(
  block: ContentBlock,
  updater: (depth: number) => number,
): ContentBlock {
  return block.update('depth', updater) as ContentBlock;
}

export interface IFilteredBlocksResult {
  readonly blocks: ReadonlyArray<ContentBlock>;
  readonly removedIndices: ReadonlyArray<number>;
}

export function filterBlocks(
  blocks: ReadonlyArray<ContentBlock>,
  predicate: (block: ContentBlock, index: number, blocks: ReadonlyArray<ContentBlock>) => boolean,
  allowEmptyResult?: boolean,
): IFilteredBlocksResult {
  const newBlocks: Array<ContentBlock> = [];
  const removedIndices: Array<number> = [];
  let changed = false;

  const skipBlockIndexes = new Set<number>();
  let pendingSleeveBefore = false;

  const blockIsNotAContentBlockMessage = (index: number) => () =>
    `${__filename}.filterBlocks: The block at index "${index}" is not a content block.`;

  for (let i = 0; i < blocks.length; i++) {
    if (skipBlockIndexes.has(i)) {
      pendingSleeveBefore = false;
      removedIndices.push(i);
      continue;
    }
    const block = blocks[i];
    assert(block, blockIsNotAContentBlockMessage(i));
    const keep = predicate(block, i, blocks);
    if (keep) {
      newBlocks.push(block);
      pendingSleeveBefore =
        getCustomBlockSleevePosition(i, blocks) === CustomBlockSleevePosition.BeforeOwner;
      continue;
    }
    if (isBlockTypeWithSleeves(getBaseBlockType(block))) {
      // Remove extra empty paragraphs for removed blocks automatically to keep result consistent
      if (pendingSleeveBefore) {
        removedIndices.push(i - 1);
        newBlocks.pop();
      }
      const next = findSiblingBlock(i, blocks, Direction.Forward);
      if (next.block && isCustomBlockSleeve(next.index, blocks)) {
        skipBlockIndexes.add(next.index);
      }
    }
    removedIndices.push(i);
    pendingSleeveBefore = false;
    changed = true;
  }

  if (!changed) {
    return {
      blocks,
      removedIndices: [],
    };
  }

  if (!newBlocks.length && !allowEmptyResult) {
    return {
      blocks: [getUnstyledBlock([])],
      removedIndices,
    };
  }

  return {
    blocks: newBlocks,
    removedIndices,
  };
}

export function findBlockIndex(blocks: ReadonlyArray<ContentBlock>, blockKey: string): number {
  return blocks.findIndex((block) => block.getKey() === blockKey);
}

export function getCustomBlockForSleeve(
  content: ContentState,
  sleeveBlockKey: string,
): ContentBlock | null {
  const blocks = getBlocks(content);
  const blockIndex = findBlockIndex(blocks, sleeveBlockKey);
  const customBlockSleevePosition = getCustomBlockSleevePosition(blockIndex, blocks);
  if (customBlockSleevePosition === CustomBlockSleevePosition.None) {
    return null;
  }

  const customBlockOffset =
    customBlockSleevePosition === CustomBlockSleevePosition.BeforeOwner ? 1 : -1;
  const customBlock = blocks[blockIndex + customBlockOffset];

  return customBlock ?? null;
}

export const canMergeBlocks = (block1: ContentBlock, block2: ContentBlock): boolean =>
  // only text blocks can be merged
  isTextBlock(block1) && isTextBlock(block2);

export const setBlockKey = (block: ContentBlock, newKey: string): ContentBlock =>
  block.merge({ key: newKey }) as ContentBlock;

export function isAtEntityEdge(block: ContentBlock, offset: number): boolean {
  return block.getEntityAt(offset) !== block.getEntityAt(offset - 1);
}
