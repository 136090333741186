import { combineReducers } from '@reduxjs/toolkit';
import Immutable from 'immutable';
import { PreviewApiDataStatus } from '../models/PreviewApiDataStatus.ts';
import { NodeId } from '../types/nodeId.type.ts';
import { IWebSpotlightConfiguration, configuration } from './configuration.ts';
import { IExpandedNodesData, expandedNodesData } from './expandedNodesData.ts';
import { focusedNodeId } from './focusedNodeId.ts';
import { isPreviewAutoRefreshEnabled } from './isPreviewAutoRefreshEnabled.ts';
import { ItemPreviewInfo, itemPreviewInfo } from './itemPreviewInfo.ts';
import {
  LastModificationDateByItemCodename,
  lastModifiedPreviewItems,
} from './lastModifiedPreviewItems.ts';
import { IMoreActionsCheckResult, moreActionsCheckResult } from './moreActionsCheckResult.ts';
import { nodesBeingLoaded } from './nodesBeingLoaded.ts';
import { previewApiDataStatus } from './previewApiDataStatus.ts';
import { rootItemId } from './rootItemId.ts';
import { ISharedPreviewData, sharedPreviewData } from './sharedPreviewData.ts';
import { spaceId } from './spaceId.ts';
import { subpagesById } from './subpagesById.ts';

export interface IWebSpotlightAppStoreState {
  readonly configuration: IWebSpotlightConfiguration | null;
  readonly expandedNodesData: Immutable.Map<NodeId, IExpandedNodesData>;
  readonly focusedNodeId: NodeId;
  readonly isPreviewAutoRefreshEnabled: boolean;
  readonly itemPreviewInfo: ItemPreviewInfo | null;
  readonly lastModifiedPreviewItems: LastModificationDateByItemCodename;
  readonly moreActionsCheckResult: IMoreActionsCheckResult | null;
  readonly nodesBeingLoaded: Immutable.Set<NodeId>;
  readonly previewApiDataStatus: PreviewApiDataStatus;
  readonly rootItemId: Uuid | null;
  readonly sharedPreviewData: ISharedPreviewData | null;
  readonly spaceId: Uuid | null;
  readonly subpagesById: Immutable.Map<Uuid, UuidArray>;
}

export const webSpotlightReducer = combineReducers({
  configuration,
  expandedNodesData,
  focusedNodeId,
  isPreviewAutoRefreshEnabled,
  itemPreviewInfo,
  lastModifiedPreviewItems,
  moreActionsCheckResult,
  nodesBeingLoaded,
  previewApiDataStatus,
  rootItemId,
  sharedPreviewData,
  spaceId,
  subpagesById,
});
