import { EditorState } from 'draft-js';
import PropTypes, { ValidationMap } from 'prop-types';
import {
  CompiledContentItemElementDataValidationMap,
  ICompiledContentItemElementData,
} from './ICompiledContentItemElement.ts';

export interface IBaseTextItemElementValue {
  readonly jsonValue: string;
  readonly _editorState: EditorState;
}

export interface IBaseTextItemElement
  extends IBaseTextItemElementValue,
    ICompiledContentItemElementData {}

export const BaseTextItemElementValidationMap: ValidationMap<IBaseTextItemElement> = {
  ...CompiledContentItemElementDataValidationMap,
  jsonValue: PropTypes.string.isRequired,
  _editorState: PropTypes.instanceOf(EditorState).isRequired,
};
