import { InvariantException } from '@kontent-ai/errors';
import { History } from 'history';
import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import {
  ApiKeyDetailRouteParams,
  DapiKeyDetailRoute,
} from '../../../../../_shared/constants/routePaths.ts';
import { ApiKeyType } from '../../../../../_shared/models/ApiKeyType.ts';
import { DateTime } from '../../../../../_shared/models/DateTime.ts';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import {
  ApiKeyDetail_OldDapiRegeneration_Failed,
  ApiKeyDetail_OldDapiRegeneration_Finished,
  ApiKeyDetail_OldDapiRegeneration_Started,
} from '../../constants/apiKeysActionTypes.ts';
import { ApiKey } from '../../models/ApiKey.ts';

const started = () =>
  ({
    type: ApiKeyDetail_OldDapiRegeneration_Started,
  }) as const;

const failed = () =>
  ({
    type: ApiKeyDetail_OldDapiRegeneration_Failed,
  }) as const;

const success = () =>
  ({
    type: ApiKeyDetail_OldDapiRegeneration_Finished,
  }) as const;

interface IRegenerateOldDapiKeyDependencies {
  readonly createApiKey: (apiKey: ApiKey) => ThunkPromise;
  readonly revokeApiKey: (tokenSeedId: Uuid) => ThunkPromise;
}

export type RegenerateOldDapiKeyActionsType = ReturnType<
  typeof started | typeof failed | typeof success
>;

export const regenerateOldDapiKeyCreator =
  ({ createApiKey, revokeApiKey }: IRegenerateOldDapiKeyDependencies) =>
  (
    apiKey: ApiKey,
    expirationDate: DateTime,
    history: History,
    projectContainerId: Uuid,
  ): ThunkPromise =>
  async (dispatch: Dispatch, getState): Promise<void> => {
    try {
      dispatch(started());

      await dispatch(
        createApiKey({
          ...apiKey,
          expiresAt: expirationDate.value,
          type: ApiKeyType.DAPI,
        }),
      );
      await dispatch(revokeApiKey(apiKey.tokenSeedId));

      history.push(
        buildPath<ApiKeyDetailRouteParams>(DapiKeyDetailRoute, {
          projectContainerId,
          tokenSeedId: getState().data.apiKeys.keyDetail.tokenSeedId,
        }),
      );

      dispatch(success());
    } catch (error) {
      dispatch(failed());
      throw InvariantException(
        `Could not regenerate api key because of the following error: ${JSON.stringify(error)}`,
      );
    }
  };
