import { Inline } from '@kontent-ai/component-library/Inline';
import { Paper, PaperLevel } from '@kontent-ai/component-library/Paper';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, {
  PropsWithChildren,
  RefObject,
  createRef,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import {
  DropDownMenuPositioner,
  DropDownMenuPositionerProps,
  DropdownTippyOptions,
} from '../../../../../../../component-library/components/DropDownMenu/DropDownMenuPositioner.tsx';
import { ScrollContainerContext } from '../../../../../../../component-library/components/ScrollContainer/ScrollContainerContext.tsx';
import { usePreventOverflowFromScrollContainer } from '../../../../../../../component-library/components/ScrollContainer/usePreventOverflowFromScrollContainer.ts';
import { useEventListener } from '../../../../../../_shared/hooks/useEventListener.ts';
import {
  Callback,
  RegisterCallback,
} from '../../../../../../_shared/types/RegisterCallback.type.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { preventDefault } from '../../../../../../_shared/utils/func/functionalTools.ts';
import { getDomSelection } from '../../../../../../_shared/utils/selectionUtils.ts';
import {
  SpaceForHorizontalToolbar,
  getBlockToolbarPosition,
} from '../../utils/blockToolbarPositioningUtils.ts';

export type Resettable = {
  readonly reset: () => void;
};

interface IBlockToolbarState {
  readonly top: number | null;
  readonly isVertical: boolean;
}

const initialToolbarState: IBlockToolbarState = {
  top: null,
  isVertical: false,
};

const updatePositionState = (
  toolbarRef: RefObject<HTMLDivElement>,
  editorContentRef: RefObject<HTMLDivElement>,
  scrollContainerRef: RefObject<Element>,
  updateState: React.Dispatch<React.SetStateAction<IBlockToolbarState>>,
) => {
  updateState((oldState) => {
    const domSelection = getDomSelection();

    if (!editorContentRef.current || !toolbarRef.current || !domSelection) {
      return oldState;
    }

    const toolbarRectangle = toolbarRef.current.getBoundingClientRect();
    const editorRectangle = editorContentRef.current.getBoundingClientRect();
    const scrollContainerRectangle = (
      scrollContainerRef.current ?? document.body
    ).getBoundingClientRect();

    const position = getBlockToolbarPosition(
      domSelection,
      toolbarRectangle,
      editorRectangle,
      scrollContainerRectangle,
    );
    if (!position) {
      return oldState;
    }

    // Move toolbar to the middle of the selected line
    const availableLeftSpace = editorRectangle.left - scrollContainerRectangle.left;
    const isVertical = availableLeftSpace < SpaceForHorizontalToolbar;

    const stateIsUnchanged = position.top === oldState.top && isVertical === oldState.isVertical;
    if (stateIsUnchanged) {
      return oldState;
    }

    return {
      top: position.top,
      isVertical,
    };
  });
};

type BlockToolbarAnchorProps = {
  readonly top: number | null;
};
export const BlockToolbarAnchor = styled.div.attrs<BlockToolbarAnchorProps>(({ top }) => ({
  // We use transform for the positioning to minimize the scrolling delay
  style: top ? { transform: `translateY(${top}px)` } : undefined,
}))<BlockToolbarAnchorProps>`
  width: 0;
  height: 0;
  position: relative;
`;

type BlockToolbarProps = {
  readonly editorRef: RefObject<HTMLDivElement>;
  readonly isInGuidelinesElement?: boolean;
  readonly registerUpdateToolbarPosition: RegisterCallback<Callback>;
  readonly renderContent: (isToolbarVertical: boolean) => React.ReactElement | null;
};

export const BlockToolbar = forwardRef<Resettable, PropsWithChildren<BlockToolbarProps>>(
  ({ editorRef, registerUpdateToolbarPosition, renderContent }, forwardedRef) => {
    const [toolbarState, setToolbarState] = useState<IBlockToolbarState>(initialToolbarState);
    const toolbarRef = useRef<HTMLDivElement | null>(null);
    const { scrollContainerRef, tippyBoundaryRef } = useContext(ScrollContainerContext);

    const updatePositionStateCallback = useCallback(
      () => updatePositionState(toolbarRef, editorRef, tippyBoundaryRef, setToolbarState),
      [editorRef, tippyBoundaryRef],
    );

    const toolbarRefToForward = useCallback(
      (toolbarElement: HTMLDivElement | null) => {
        toolbarRef.current = toolbarElement;

        if (toolbarElement) {
          // Initial positioning when the toolbar is mounted
          updatePositionStateCallback();
        }
      },
      [updatePositionStateCallback],
    );

    useEventListener('scroll', updatePositionStateCallback, scrollContainerRef.current);
    useEventListener('resize', updatePositionStateCallback, self);

    useEffect(
      () => registerUpdateToolbarPosition(updatePositionStateCallback),
      [registerUpdateToolbarPosition, updatePositionStateCallback],
    );

    const insertButtonRef = createRef<Resettable>();
    const changeButtonRef = createRef<Resettable>();

    useImperativeHandle(forwardedRef, () => ({
      reset: () => {
        const insertButton = insertButtonRef.current;
        if (insertButton) {
          insertButton.reset();
        }
        const changeButton = changeButtonRef.current;
        if (changeButton) {
          changeButton.reset();
        }
      },
    }));

    const isPositioned = toolbarState.top !== null;

    const { preventOverflowModifier } = usePreventOverflowFromScrollContainer();

    const tippyOptions: DropdownTippyOptions = {
      placement: 'left',
      offset: [0, Spacing.S],
      popperOptions: {
        modifiers: [preventOverflowModifier],
      },
    };

    const toolbarEventParams = {
      onMouseDown: preventDefault,
      onMouseUp: preventDefault,
    };

    const content = renderContent(toolbarState.isVertical);
    if (!content) {
      return null;
    }

    const renderToolbar: DropDownMenuPositionerProps['renderDropDown'] = (
      _triggerWidth,
      _triggerRef,
      menuProps,
    ) => (
      <Paper
        {...menuProps}
        component="section"
        css={`
          transition: ${isPositioned ? 'opacity' : 'none'};
          transition-delay: ${isPositioned ? '150ms' : '0'};
        `}
        opacity={isPositioned ? 1 : 0}
        level={PaperLevel.Popout}
        ref={toolbarRefToForward}
        padding={Spacing.S}
        {...toolbarEventParams}
      >
        {toolbarState.isVertical ? (
          <Stack
            spacing={Spacing.S}
            align="center"
            {...getDataUiElementAttribute(DataUiElement.RteBlockToolbar)}
          >
            {content}
          </Stack>
        ) : (
          <Inline
            spacingX={Spacing.S}
            align="center"
            {...getDataUiElementAttribute(DataUiElement.RteBlockToolbar)}
          >
            {content}
          </Inline>
        )}
      </Paper>
    );

    return (
      <DropDownMenuPositioner
        allowAnimation={false}
        isDropDownVisible
        renderDropDown={renderToolbar}
        renderTrigger={(triggerProps) => (
          <BlockToolbarAnchor {...triggerProps} top={isPositioned ? toolbarState.top : null} />
        )}
        tippyOptions={tippyOptions}
      />
    );
  },
);

BlockToolbar.displayName = 'BlockToolbar';
