import { MultilineClipboard } from '@kontent-ai/component-library/Clipboard';
import PropTypes from 'prop-types';
import React from 'react';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import {
  ArrowPosition,
  Popover,
  PopoverIsPointing,
  PopoverMessage,
  PopoverWidth,
} from '../../../../_shared/uiComponents/Popover/Popover.tsx';
import {
  DataUiElement,
  DataUiInput,
  getDataUiElementAttribute,
  getDataUiInputAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export interface IWebhookMessageDialogPopoverStateProps {
  readonly labelText: string;
  readonly content: string;
  readonly verticalArrowPosition: ArrowPosition;
  readonly isLoading?: boolean;
}

export interface IWebhookMessageDialogPopoverDispatchProps {
  readonly onAfterCopied?: () => void;
}

type WebhookMessageDialogPopoverProps = IWebhookMessageDialogPopoverStateProps &
  IWebhookMessageDialogPopoverDispatchProps;

export const WebhookMessageDialogPopover: React.FC<WebhookMessageDialogPopoverProps> = (props) => (
  <Popover
    orientation={PopoverIsPointing.Up}
    arrowPosition={props.verticalArrowPosition}
    hintDataAttribute={getDataUiElementAttribute(DataUiElement.WebhookMessageDialog)}
    hasFullWidth
    width={PopoverWidth.IsWide}
  >
    {props.isLoading ? (
      <Loader />
    ) : (
      <PopoverMessage>
        <MultilineClipboard
          label={props.labelText}
          onCopyCompleted={props.onAfterCopied}
          rows={12}
          value={props.content}
          {...getDataUiInputAttribute(DataUiInput.Text)}
        />
      </PopoverMessage>
    )}
  </Popover>
);

WebhookMessageDialogPopover.displayName = 'WebhookMessageDialogPopover';
const propTypes: PropTypesShape<WebhookMessageDialogPopoverProps> = {
  content: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  labelText: PropTypes.string.isRequired,
  onAfterCopied: PropTypes.func,
  verticalArrowPosition: PropTypes.string.isRequired,
};
WebhookMessageDialogPopover.propTypes = propTypes;
