import { IAssignmentWorkflowStep } from '../../../../data/models/workflow/WorkflowStep.ts';
import { DateTime } from '../../../models/DateTime.ts';
import { IUserInfo } from '../../../models/UserInfo.ts';
import {
  ChangeWorkflowStep_DueDate_Changed,
  ChangeWorkflowStep_InitialChangeWorkflowStepModalData_Set,
  ChangeWorkflowStep_Note_Changed,
  ChangeWorkflowStep_NotificationBar_Hidden,
  ChangeWorkflowStep_ScheduleToPublishMethod_Changed,
  ChangeWorkflowStep_ScheduleToUnpublishMethod_Changed,
  ChangeWorkflowStep_ScheduledToPublish_Changed,
  ChangeWorkflowStep_ScheduledToUnpublish_Changed,
  ChangeWorkflowStep_SelectedContributors_Changed,
  ChangeWorkflowStep_ShowAllContributors_Selected,
  ChangeWorkflowStep_ShowAvailableContributors_Selected,
  ChangeWorkflowStep_WorkflowStep_Selected,
} from '../constants/changeWorkflowStepActionTypes.ts';
import { ScheduleMethod } from '../constants/scheduleMethods.ts';
import { IChangeWorkflowStepModalData } from '../reducers/IChangeWorkflowStepModalData.ts';
import { ChangeWorkflowStepModalAction } from '../types/workflowStepModalActionTypes.ts';

export const setInitialChangeWorkflowStepModalData = (
  changeWorkflowStepModalData: IChangeWorkflowStepModalData,
) =>
  ({
    type: ChangeWorkflowStep_InitialChangeWorkflowStepModalData_Set,
    payload: {
      changeWorkflowStepModalData,
    },
  }) as const;

export const hideNotificationBarInChangeWorkflowStepModal = () =>
  ({
    type: ChangeWorkflowStep_NotificationBar_Hidden,
  }) as const;

export const selectWorkflowStep = (
  workflowStepAction: ChangeWorkflowStepModalAction,
  workflowStep: IAssignmentWorkflowStep,
  errorMessage?: string,
) =>
  ({
    type: ChangeWorkflowStep_WorkflowStep_Selected,
    payload: {
      workflowStepAction,
      workflowStep,
      errorMessage,
    },
  }) as const;

export const changeScheduleToPublishMethod = (method: ScheduleMethod) =>
  ({
    type: ChangeWorkflowStep_ScheduleToPublishMethod_Changed,
    payload: { method },
  }) as const;

export const changeScheduleToUnpublishMethod = (method: ScheduleMethod) =>
  ({
    type: ChangeWorkflowStep_ScheduleToUnpublishMethod_Changed,
    payload: { method },
  }) as const;

export const noteChanged = (newNote: string) =>
  ({
    type: ChangeWorkflowStep_Note_Changed,
    payload: {
      newNote,
    },
  }) as const;

export const scheduledToPublishChanged = (
  scheduledToPublishAt: DateTimeStamp | null,
  timeZoneId: string,
) =>
  ({
    type: ChangeWorkflowStep_ScheduledToPublish_Changed,
    payload: {
      scheduledToPublishAt,
      timeZoneId,
    },
  }) as const;

export const scheduledToUnpublishChanged = (
  scheduledToUnpublishAt: DateTimeStamp | null,
  timeZoneId: string,
) =>
  ({
    type: ChangeWorkflowStep_ScheduledToUnpublish_Changed,
    payload: {
      scheduledToUnpublishAt,
      timeZoneId,
    },
  }) as const;

export const selectedContributorsChanged = (contributors: ReadonlySet<IUserInfo>) =>
  ({
    type: ChangeWorkflowStep_SelectedContributors_Changed,
    payload: {
      contributors,
    },
  }) as const;

export const dueDateChanged = (dateTime: DateTime) =>
  ({
    type: ChangeWorkflowStep_DueDate_Changed,
    payload: {
      dateTime,
    },
  }) as const;

export const showAvailableContributors = () =>
  ({
    type: ChangeWorkflowStep_ShowAvailableContributors_Selected,
  }) as const;

export const showAllContributors = () =>
  ({
    type: ChangeWorkflowStep_ShowAllContributors_Selected,
  }) as const;
