import { focusAtTheEnd } from '@kontent-ai/DOM';
import { InputState, Textarea } from '@kontent-ai/component-library/Input';
import { useAttachRef } from '@kontent-ai/hooks';
import React, { ReactElement, Ref, forwardRef, useEffect } from 'react';
import { FieldPathByValue, FieldValues, useController } from 'react-hook-form';
import { HookFormProps } from '../../types/hookFormProps.ts';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

type Props<TFormShape extends FieldValues> = {
  readonly autoFocus?: boolean;
  readonly formProps: HookFormProps<TFormShape>;
  readonly isDisabled?: boolean;
  readonly label?: string;
  readonly name: FieldPathByValue<TFormShape, string>;
  readonly placeholder?: string;
  readonly rows?: number;
};

const ValidatedTextAreaRFC = <TFormShape extends FieldValues>(
  props: Props<TFormShape>,
  ref: Ref<HTMLTextAreaElement>,
): ReactElement => {
  const { autoFocus, formProps, isDisabled, label, name, placeholder, rows } = props;

  const { refObject, refToForward } = useAttachRef(ref);

  // Workaround for react-hook-form as the form ref callback changes on each render
  // which means it always passes null and then the element as an instance and we do not want to react to it on every render.
  useEffect(() => {
    if (autoFocus) {
      focusAtTheEnd(refObject.current);
    }
  }, [refObject, autoFocus]);

  const {
    field: { ref: formRef, ...inputProps },
    fieldState: { error },
  } = useController({ control: formProps.control, name });

  return (
    <Textarea
      {...inputProps}
      caption={error?.message ?? ''}
      {...getDataUiInputAttribute(DataUiInput.Task)}
      inputState={getInputState({ isDisabled: !!isDisabled, hasError: !!error })}
      label={label}
      placeholder={placeholder}
      ref={(instance) => {
        formRef(instance);
        refToForward(instance);
      }}
      rows={rows}
    />
  );
};

type ComponentCallback = <TFormShape extends FieldValues = EmptyObject>(
  props: Props<TFormShape>,
) => ReactElement;
type ComponentType = ComponentCallback & Pick<React.FC, 'displayName'>;

export const ValidatedTextArea = forwardRef(ValidatedTextAreaRFC) as ComponentType;

ValidatedTextArea.displayName = 'ValidatedTextArea';

const getInputState = (inputState: {
  readonly isDisabled: boolean;
  readonly hasError: boolean;
}): InputState => {
  if (inputState.isDisabled) {
    return InputState.Disabled;
  }
  if (inputState.hasError) {
    return InputState.Alert;
  }
  return InputState.Default;
};
