import PropTypes from 'prop-types';
import React from 'react';
import { DropDownCaptionOption } from '../../../../../../../_shared/uiComponents/DropDown/DropDownCaptionOption.tsx';
import { DropDownOptionsGroup } from '../../../../../../../_shared/uiComponents/DropDown/DropDownOptionsGroup.tsx';

export type ActionsMenuSubMenuOwnProps = {
  readonly caption: string;
  readonly children: React.ReactNode;
};

export type ActionsMenuSubMenuDispatchProps = {
  readonly onNavigateBack: () => void;
};

type ActionsMenuSubMenuProps = ActionsMenuSubMenuOwnProps & ActionsMenuSubMenuDispatchProps;

const propTypes: PropTypesShape<ActionsMenuSubMenuProps> = {
  caption: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onNavigateBack: PropTypes.func.isRequired,
};

export const ActionsMenuSubMenu: React.FC<React.PropsWithChildren<ActionsMenuSubMenuProps>> = (
  props,
) => (
  <>
    <DropDownOptionsGroup>
      <DropDownCaptionOption name={props.caption} onClick={props.onNavigateBack} />
    </DropDownOptionsGroup>
    <DropDownOptionsGroup>{props.children}</DropDownOptionsGroup>
  </>
);

ActionsMenuSubMenu.displayName = 'ActionsMenuSubMenu';
ActionsMenuSubMenu.propTypes = propTypes;
