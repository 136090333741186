import { Checkbox, CheckboxState } from '@kontent-ai/component-library/Checkbox';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { FieldPathByValue, useController } from 'react-hook-form';
import { HookFormProps } from '../../../../../_shared/types/hookFormProps.ts';
import { FormFieldError } from '../../../../../_shared/uiComponents/FormAlert/FormFieldError.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { showFieldError } from '../../../../../_shared/utils/validation/showFieldError.ts';
import { DapiAccess, DapiKeyFormShape } from '../../validation/dapiKeyValidationConfig.ts';

type Props = {
  readonly checkboxState: CheckboxState;
  readonly formProps: HookFormProps<DapiKeyFormShape>;
  readonly name: FieldPathByValue<DapiKeyFormShape, DapiAccess>;
  readonly tooltip?: AccessTooltips;
};

export type AccessTooltips = {
  readonly contentPreviewTooltip: string;
  readonly secureAccessTooltip: string;
};

export const ValidatedDapiAccessCheckboxes: React.FC<Props> = ({
  checkboxState,
  formProps,
  name,
  tooltip,
}) => {
  const {
    field,
    fieldState: { error },
    formState,
  } = useController({
    control: formProps.control,
    name,
  });

  const showError = showFieldError(formState, error);

  const { hasPreviewDeliveryAccess, hasSecureDeliveryAccess } = field.value;

  const onToggleSecure = (value: boolean) => {
    field.onChange({
      hasPreviewDeliveryAccess,
      hasSecureDeliveryAccess: value,
    });
  };

  const onTogglePreview = (value: boolean) => {
    field.onChange({
      hasPreviewDeliveryAccess: value,
      hasSecureDeliveryAccess,
    });
  };

  return (
    <Stack spacing={Spacing.XL} {...getDataUiElementAttribute(DataUiElement.DapiAccessCard)}>
      <Checkbox
        checked={hasSecureDeliveryAccess}
        caption="Use the API key with environments that require an API key for each Delivery API request."
        checkboxState={showError ? 'alert' : checkboxState}
        tooltipPlacement="top-start"
        tooltipText={tooltip?.secureAccessTooltip}
        onToggle={onToggleSecure}
        {...getDataUiElementAttribute(DataUiElement.DapiSecureAccessCheckbox)}
      >
        Secure access
      </Checkbox>

      <Checkbox
        checked={hasPreviewDeliveryAccess}
        caption="Use the API key to get latest content, both published and unpublished, via Preview API."
        checkboxState={showError ? 'alert' : checkboxState}
        tooltipPlacement="top-start"
        tooltipText={tooltip?.contentPreviewTooltip}
        onToggle={onTogglePreview}
        {...getDataUiElementAttribute(DataUiElement.DapiPreviewAccessCheckbox)}
      >
        Content preview
      </Checkbox>

      <FormFieldError isDisplayed={showError}>{error?.message}</FormFieldError>
    </Stack>
  );
};

ValidatedDapiAccessCheckboxes.displayName = 'ValidatedDapiAccessCheckboxes';
