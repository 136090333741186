import React from 'react';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import { ControlAltShortcutTemplate } from '../../../../../_shared/constants/shortcutSymbols.ts';
import {
  DataUiRteAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IForwardedRefProps, forwardRef } from '../../../../../_shared/utils/forwardedRefProps.tsx';
import {
  ISpecificButtonProps,
  RTEToolbarIconButton,
  specificButtonPropTypes,
} from '../../toolbars/components/RTEToolbarButton.tsx';

const ModularButtonComponent: React.FC<
  ISpecificButtonProps & IForwardedRefProps<HTMLButtonElement>
> = (props) => (
  <RTEToolbarIconButton
    {...props}
    iconName={IconName.Puzzle}
    ref={props.forwardedRef}
    shortcut={ControlAltShortcutTemplate('I')}
    tooltipPlacement={props.tooltipPlacement ?? 'bottom-start'}
    tooltipText={
      props.tooltipText ??
      (props.disabled ? 'Content items cannot be added here' : 'Insert existing item')
    }
    {...getDataUiActionAttribute(DataUiRteAction.AddItem)}
  />
);

ModularButtonComponent.displayName = 'ModularButton';
ModularButtonComponent.propTypes = specificButtonPropTypes;

export const ModularButton = forwardRef(ModularButtonComponent);
