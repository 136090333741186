import PropTypes from 'prop-types';
import React from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

type SidebarSectionProps = {
  readonly title?: string;
};

const propTypes: PropTypeMap<SidebarSectionProps> = {
  title: PropTypes.string,
};

export const SidebarSection: React.FC<React.PropsWithChildren<SidebarSectionProps>> = (props) => (
  <div className="sidebar__section" {...getDataUiElementAttribute(DataUiElement.SidebarSection)}>
    {props.title ? <h2 className="sidebar__section-title">{props.title}</h2> : null}
    {props.children}
  </div>
);

SidebarSection.displayName = 'SidebarSection';
SidebarSection.propTypes = propTypes;
