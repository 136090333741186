import { useContext } from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { isPublishedWorkflowStep } from '../../../../../_shared/utils/contentItemUtils.ts';
import { PublishingState } from '../../../../../data/constants/PublishingState.ts';
import { TypeElement } from '../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { ICompiledContentItemElementData } from '../../../models/contentItemElements/ICompiledContentItemElement.ts';
import { useItemVariantId } from '../../ContentComponent/context/ContentItemContext.tsx';
import { ContentNestingContext } from '../../ContentItemEditing/context/ContentNestingContext.tsx';
import { useIsElementEditableInEditedVariant } from '../../ContentItemEditing/hooks/useIsElementEditableInEditedVariant.ts';
import { ReadonlyEmptyElementPlaceholder } from '../../ContentItemEditing/models/ReadonlyEmptyElementPlaceholder.ts';

export const useRenderPlaceholderText = (
  placeholder: ReadonlyEmptyElementPlaceholder,
  originalElementData: ICompiledContentItemElementData | null | undefined,
  typeElement: TypeElement,
): string | null => {
  const { isCurrentLevelContentComponent } = useContext(ContentNestingContext);
  const { itemId } = useItemVariantId();
  const defaultListingItem = useSelector((state) =>
    state.data.listingContentItems.defaultById.get(itemId),
  );
  const itemVariantAssignment = useSelector(
    (state) => state.contentApp.editedContentItemVariant?.assignment,
  );

  const isElementEditableInVariant = useIsElementEditableInEditedVariant(typeElement);
  const isVariantPublished = isPublishedWorkflowStep(itemVariantAssignment?.workflowStatus);
  const isDefaultVariantPublished =
    defaultListingItem?.variant?.publishingState === PublishingState.Published;

  if (!originalElementData) {
    return !isElementEditableInVariant &&
      isVariantPublished &&
      !isDefaultVariantPublished &&
      !isCurrentLevelContentComponent
      ? `${placeholder} Make sure this item is published in the default language first.`
      : placeholder;
  }

  return null;
};
