import { SrOnly } from '@kontent-ai/component-library/styles';
import {
  Spacing,
  gridUnit,
  px,
  shadowFocusStyles,
  transition250,
} from '@kontent-ai/component-library/tokens';
import PropTypes from 'prop-types';
import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import {
  IWorkflowStepConfig,
  WorkflowStep,
  workflowStepBorderRadius,
  workflowStepConfigPropTypes,
} from './WorkflowStep.tsx';

export const workflowStepMinWidth = 5 * gridUnit;
export const secondaryWorkflowStepOffset = -1 * Spacing.S;

const Container = styled.div<{
  readonly isFocusVisible?: boolean;
  readonly isFullSize?: boolean;
}>`
  position: relative;
  display: flex;

  max-width: ${({ isFullSize }) => (isFullSize ? 'none' : '100%')};

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;

    display: block;
    width: 100%;
    height: 100%;

    border-radius: ${px(workflowStepBorderRadius)};
    ${shadowFocusStyles};
    opacity: ${({ isFocusVisible }) => (isFocusVisible ? 1 : 0)};
    transition: opacity ${transition250};

    pointer-events: none;
  }
`;

const BaseStep = styled.div<{
  readonly isShrinkable?: boolean;
}>`
  min-width: ${px(workflowStepMinWidth)};
  flex-shrink: ${({ isShrinkable }) => (isShrinkable ? 1 : 0)};
`;
const PrimaryStep = styled(BaseStep)`
  position: relative; /* 'lift up' above the SecondaryStep */
`;

const SecondaryStep = styled(BaseStep)`
  margin-left: ${px(secondaryWorkflowStepOffset)};
  flex-grow: 1;
  flex-basis: ${px(workflowStepMinWidth)};
`;

export interface IWorkflowStepsProps {
  readonly isFocusVisible?: boolean;
  readonly isFullSize?: boolean;
  readonly isPrimaryOverflowing?: boolean;
  readonly isSecondaryOverflowing?: boolean;
  readonly primary: IWorkflowStepConfig;
  readonly primaryRef?: React.RefObject<HTMLDivElement>;
  readonly secondary?: IWorkflowStepConfig;
  readonly secondaryRef?: React.RefObject<HTMLDivElement>;
}

export const workflowStepsPropTypes: PropTypeMap<IWorkflowStepsProps> = {
  isFocusVisible: PropTypes.bool,
  isFullSize: PropTypes.bool,
  isPrimaryOverflowing: PropTypes.bool,
  isSecondaryOverflowing: PropTypes.bool,
  primary: PropTypes.shape(workflowStepConfigPropTypes).isRequired,
  primaryRef: PropTypes.any,
  secondary: PropTypes.shape(workflowStepConfigPropTypes),
  secondaryRef: PropTypes.any,
};

interface IExtendedIWorkflowStepsProps
  extends IWorkflowStepsProps,
    HTMLAttributes<HTMLDivElement> {}

export const WorkflowSteps = React.forwardRef<HTMLDivElement, IExtendedIWorkflowStepsProps>(
  (
    {
      isFocusVisible,
      isFullSize,
      isPrimaryOverflowing,
      isSecondaryOverflowing,
      primary,
      primaryRef,
      secondary,
      secondaryRef,
      ...otherProps
    },
    forwardedRef,
  ) => (
    <Container
      isFocusVisible={isFocusVisible}
      isFullSize={isFullSize}
      ref={forwardedRef}
      {...otherProps}
    >
      <PrimaryStep isShrinkable={!isFullSize && isPrimaryOverflowing}>
        <WorkflowStep isOverflowing={isPrimaryOverflowing} ref={primaryRef} {...primary} />
      </PrimaryStep>

      {secondary && (
        <>
          <SrOnly>+</SrOnly>
          <SecondaryStep isShrinkable={!isFullSize && isSecondaryOverflowing}>
            <WorkflowStep
              isOverflowing={isSecondaryOverflowing}
              ref={secondaryRef}
              {...secondary}
            />
          </SecondaryStep>
        </>
      )}
    </Container>
  ),
);

WorkflowSteps.displayName = 'WorkflowSteps';
WorkflowSteps.propTypes = workflowStepsPropTypes;
