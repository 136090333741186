import { Card } from '@kontent-ai/component-library/Card';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import Immutable from 'immutable';
import ImmutablePropTypes from 'immutable-prop-types';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { CreateNewBarItemHidingButtonAnimated } from '../../../_shared/components/BarItems/CreateNewBarItemHidingButtonAnimated.tsx';
import { HtmlSettingsPageTitle } from '../../../_shared/components/HtmlSettingsPageTitle.tsx';
import { Loader } from '../../../_shared/components/Loader.tsx';
import { PageTitle } from '../../../_shared/components/PageTitle.tsx';
import { Warning } from '../../../_shared/components/infos/Warning.tsx';
import { LoadingStatus } from '../../../_shared/models/LoadingStatusEnum.ts';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  IWebhookSetting,
  emptyObject as emptyWebhook,
} from '../../../data/models/webhooks/WebhookSetting.ts';
import { EnvironmentSettingsAppNames } from '../../environmentSettings/root/constants/EnvironmentSettingsAppNames.ts';
import { WebhookItem } from './WebhookItem.tsx';
import { LegacyWebhookQuickTip } from './messages/LegacyWebhookQuickTip.tsx';
import { WebhookEmptyState } from './messages/WebhookEmptyState.tsx';

type Props = {
  readonly createIsEnabled: boolean;
  readonly createWebhook: () => void;
  readonly editedWebhookId: Uuid | null;
  readonly loadingStatus: LoadingStatus;
  readonly newWebhook: IWebhookSetting | undefined;
  readonly onInit: () => void;
  readonly onLeave: () => void;
  readonly webhooks: Immutable.List<IWebhookSetting>;
  readonly webhooksWithNoTrigger?: Immutable.List<IWebhookSetting>;
};

const propTypes: PropTypesShape<Props> = {
  createIsEnabled: PropTypes.bool.isRequired,
  createWebhook: PropTypes.func.isRequired,
  editedWebhookId: PropTypes.string,
  loadingStatus: PropTypes.oneOf(Object.values(LoadingStatus)).isRequired,
  newWebhook: PropTypes.object,
  onInit: PropTypes.func.isRequired,
  onLeave: PropTypes.func.isRequired,
  webhooks: ImmutablePropTypes.list.isRequired,
  webhooksWithNoTrigger: ImmutablePropTypes.list,
};

export const WebhookListing: React.FC<Props> = ({
  createIsEnabled,
  createWebhook,
  editedWebhookId,
  loadingStatus,
  newWebhook,
  onInit,
  onLeave,
  webhooks,
  webhooksWithNoTrigger,
}) => {
  useEffect(() => {
    onInit();

    return onLeave;
  }, [onInit, onLeave]);

  const renderWebhookItem = (webhook: IWebhookSetting): JSX.Element => {
    const isEdited = !!editedWebhookId && editedWebhookId === webhook.id;

    return <WebhookItem key={webhook.id} isEdited={isEdited} webhookSetting={webhook} />;
  };

  const renderCreateNewButton = (): JSX.Element | null => (
    <CreateNewBarItemHidingButtonAnimated
      estimatedMaxHeightWhenExpanded={1200}
      isCreatingAllowed={createIsEnabled}
      isFormDisplayed={!!newWebhook}
      itemName="Webhook"
      onCreateNewClick={createWebhook}
    >
      {renderWebhookItem(newWebhook || emptyWebhook)}
    </CreateNewBarItemHidingButtonAnimated>
  );

  const renderDisabledWebhookList = (): JSX.Element | null => {
    const disabledWebhooks = webhooks.filter((webhook: IWebhookSetting) => !webhook.enabled);
    if (disabledWebhooks.count() === 0) {
      return null;
    }

    return (
      <>
        <li className="bar-item__section-title">Disabled</li>
        {disabledWebhooks.map(renderWebhookItem)}
      </>
    );
  };

  const getWebhookList = (): JSX.Element => {
    const enabledWebhooks = webhooks.toArray().filter((webhook) => webhook.enabled);

    return (
      <>
        {webhooksWithNoTrigger && !webhooksWithNoTrigger.isEmpty() && (
          <div className="webhook-listing__misconfigured-webhook-callout">
            <Warning>
              <ul>
                {webhooksWithNoTrigger.toArray().map((webhook) => (
                  <li key={webhook.id}>
                    {`The “${webhook.name}” webhook is misconfigured. Add at least one trigger to start receiving notifications.`}
                  </li>
                ))}
              </ul>
            </Warning>
          </div>
        )}
        <ul className="bar-item__list">
          {enabledWebhooks.map(renderWebhookItem)}
          {renderDisabledWebhookList()}
          {renderCreateNewButton()}
        </ul>
      </>
    );
  };

  const getContent = () => {
    if (loadingStatus !== LoadingStatus.Loaded) {
      return <Loader />;
    }

    if (webhooks.size <= 0 && !newWebhook) {
      return (
        <div {...getDataUiAppNameAttribute(DataUiAppName.LegacyWebhooks)}>
          <WebhookEmptyState onCreateButtonNewClick={createWebhook} />
        </div>
      );
    }

    return (
      <Stack spacing={Spacing.XL}>
        <PageTitle>{EnvironmentSettingsAppNames.LegacyWebhooks}</PageTitle>
        <Row spacing={Spacing.L}>
          <Column flexFactor={5} flexBasis={600} maxWidth={1000 + Spacing.L}>
            <Card
              cardLabel={EnvironmentSettingsAppNames.LegacyWebhooks}
              component="section"
              {...getDataUiAppNameAttribute(DataUiAppName.LegacyWebhooks)}
            >
              <Card.Body>{getWebhookList()}</Card.Body>
            </Card>
          </Column>
          <Column flexFactor={1} flexBasis={250}>
            <LegacyWebhookQuickTip />
          </Column>
        </Row>
      </Stack>
    );
  };

  return (
    <div className="canvas__inner-section">
      <HtmlSettingsPageTitle settingsAppName={EnvironmentSettingsAppNames.LegacyWebhooks} />
      {getContent()}
    </div>
  );
};

WebhookListing.displayName = 'WebhookListing';
WebhookListing.propTypes = propTypes;
