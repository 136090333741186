import { memoize } from '@kontent-ai/memoization';
import React, { useCallback } from 'react';
import { useLocation } from 'react-router';
import { trackUserEventWithData } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { TasksEventActions } from '../../../../_shared/models/events/TaskEventData.type.ts';
import { Capability } from '../../../../_shared/utils/permissions/capability.ts';
import { getContentItemPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { Languages } from '../../../../data/models/languages/Language.ts';
import { getAllActiveLanguagesForCurrentUserInAnyCollection } from '../../../../data/reducers/languages/selectors/getLanguages.ts';
import { getCurrentProject } from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { initYourTasks } from '../../actions/thunkYourContentActions.ts';
import {
  YourTaskProps,
  YourTasks as YourTasksComponent,
} from '../../components/YourTasks/YourTasks.tsx';
import { YourTasks_GoToTask_Selected } from '../../constants/yourContentActionTypes.ts';
import { YourTask, getYourTasks } from '../../selectors/getYourTasks.ts';

const createEnhanceTaskWithPath = (pathname: string) => (task: YourTask) => ({
  ...task,
  pathToItem: getContentItemPath(pathname, task.itemId, task.variantId),
});

const createHideTaskLanguage = (viewableLanguages: Languages) => (task: YourTask) => ({
  ...task,
  contentItemVariantName: viewableLanguages.size === 1 ? undefined : task.contentItemVariantName,
});

const memoizedYourTasksMapping = memoize.weak(
  (yourRawTasks: readonly YourTask[], viewableLanguages: Languages, pathname: string) => {
    return yourRawTasks
      .map(createHideTaskLanguage(viewableLanguages))
      .map(createEnhanceTaskWithPath(pathname));
  },
);

const navigateToTask = (pathToItem: string, taskId: Uuid) =>
  ({
    type: YourTasks_GoToTask_Selected,
    payload: {
      pathToItem,
      taskId,
    },
  }) as const;

export type YourTasksActionsType = ReturnType<typeof navigateToTask>;

export const YourTasks: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const yourTasks = useSelector(getYourTasks);
  const viewableLanguages = useSelector((s) =>
    getAllActiveLanguagesForCurrentUserInAnyCollection(
      s.data.user,
      getCurrentProject(s).projectId,
      s.data.languages,
      Capability.ViewContent,
    ),
  );

  const tasksLoaded = useSelector((s) => s.data.tasks.loadingStatus === LoadingStatus.Loaded);

  const yourTasksMapped = memoizedYourTasksMapping(yourTasks, viewableLanguages, location.pathname);

  const onNavigateToTask = ({ id: taskId, itemId, pathToItem, variantId }: YourTaskProps) => {
    dispatch(navigateToTask(pathToItem, taskId));
    dispatch(
      trackUserEventWithData(TrackedEvent.Tasks, {
        action: TasksEventActions.NavigatedFromYourTasks,
        itemId,
        taskId,
        variantId,
      }),
    );
  };

  const initTasks = useCallback(() => dispatch(initYourTasks()), []);

  return (
    <YourTasksComponent
      onInit={initTasks}
      onNavigateToTask={onNavigateToTask}
      tasks={yourTasksMapped}
      tasksLoaded={tasksLoaded}
    />
  );
};

YourTasks.displayName = 'YourTasks';
