import Immutable from 'immutable';
import React from 'react';
import {
  EditableTypeElement,
  TypeElement,
} from '../../../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import {
  isCustomTypeElement,
  isGuidelinesTypeElement,
} from '../../../../../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { expandedContentComponentElementComponentLocator } from '../../../../../../itemEditor/features/ContentComponent/utils/contentComponentElementComponentLocator.ts';
import { getElementValueForCustomElement } from '../../../../../../itemEditor/features/ContentItemEditing/utils/getElementValueForCustomElement.ts';
import { ExpandedCustomElement } from '../../../../../../itemEditor/features/LinkedItems/components/ExpandedCustomElement.tsx';
import { IContentComponent } from '../../../../../../itemEditor/models/contentItem/ContentComponent.ts';
import { ICompiledContentItemElementData } from '../../../../../../itemEditor/models/contentItemElements/ICompiledContentItemElement.ts';
import { isCustomElement } from '../../../../../../itemEditor/models/contentItemElements/compiledItemElementTypeGuards.ts';
import { createItemElementWithInitialValue } from '../../../../../../itemEditor/utils/itemElementCreator.ts';
import { getContentElements } from '../../../api/editorContentComponentUtils.ts';

type ExpandedContentComponentElementProps = {
  readonly contentComponent: IContentComponent;
  readonly currentTypeElement: TypeElement;
  readonly originalContentComponent: IContentComponent | undefined;
  readonly typeElements: ReadonlyArray<TypeElement>;
};

const getElement = (
  elements: Immutable.Map<Uuid, ICompiledContentItemElementData>,
  typeElement: EditableTypeElement,
) =>
  elements.find((e) => e.elementId === typeElement.elementId) ??
  createItemElementWithInitialValue(typeElement);

export const ExpandedContentComponentElement: React.FC<ExpandedContentComponentElementProps> = ({
  contentComponent,
  currentTypeElement,
  originalContentComponent,
  typeElements,
}) => {
  if (isGuidelinesTypeElement(currentTypeElement)) {
    const GuidelinesComponent = expandedContentComponentElementComponentLocator.getGuidelines();
    return (
      <GuidelinesComponent key={currentTypeElement.elementId} typeElement={currentTypeElement} />
    );
  }

  const currentTypeElementId = currentTypeElement.elementId;
  const contentElements = getContentElements(contentComponent);
  const originalContentElements =
    (originalContentComponent && getContentElements(originalContentComponent)) || null;

  const element = getElement(contentElements, currentTypeElement);
  const originalElement =
    originalContentElements && getElement(originalContentElements, currentTypeElement);

  if (isCustomTypeElement(currentTypeElement)) {
    if (isCustomElement(element) && (!originalElement || isCustomElement(originalElement))) {
      return (
        <ExpandedCustomElement
          key={currentTypeElementId}
          typeElement={currentTypeElement}
          elementData={element}
          originalElementData={originalElement}
          getElementValue={(elementCodename) =>
            getElementValueForCustomElement(
              contentComponent.elements,
              currentTypeElementId,
              typeElements,
              elementCodename,
            )
          }
          className="expanded-content-component__element"
        />
      );
    }
    return null;
  }

  const ElementComponent = expandedContentComponentElementComponentLocator.getComponent(
    currentTypeElement.type,
  );

  return (
    <ElementComponent
      key={currentTypeElementId}
      typeElement={currentTypeElement}
      elementData={element}
      originalElementData={originalElement}
    />
  );
};

ExpandedContentComponentElement.displayName = 'ExpandedContentComponentElement';
