import { makeCancellablePromise, swallowCancelledPromiseError } from '@kontent-ai/utils';
import { useEffect, useState } from 'react';
import { EnsureValidState } from '../../../../../../_shared/components/EnsureValidState.tsx';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { ensureInventoryRelatedData } from '../../ContentItemInventory/actions/thunkContentItemInventoryActions.ts';

type Props = {
  readonly children: OneOrMore<JSX.Element>;
};

export const EnsureInventoryRelatedData = (props: Props) => {
  const [dataLoaded, setDataLoaded] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const promise = makeCancellablePromise(() => dispatch(ensureInventoryRelatedData()))
      .then(() => setDataLoaded(true))
      .catch(swallowCancelledPromiseError);
    return promise.cancel;
  }, []);

  return <EnsureValidState isStateEnsured={dataLoaded}>{props.children}</EnsureValidState>;
};
