import { History } from 'history';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import {
  ContentItemPreviewRoute,
  ContentItemRoute,
  ContentItemRouteParams,
  ContentItemsAppRouteSegment,
  WebSpotlightEntryRoute,
  WebSpotlightEntryRouteParams,
  WebSpotlightRoute,
  WebSpotlightRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { Capability } from '../../../../_shared/utils/permissions/capability.ts';
import { currentUserHasCapabilities } from '../../../../_shared/utils/permissions/capabilityUtils.ts';
import {
  buildPath,
  matchPath,
  parseContentItemIds,
} from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { sortSpacesByName } from '../../../../_shared/utils/spaces/sortSpacesByName.ts';
import { ISpace } from '../../../../data/models/space/space.ts';
import { IProjectSpecificStorage } from '../../../../localStorages/projectSpecificStorage.ts';
import { IWebSpotlightPreferences } from '../../../../localStorages/webSpotlightPreferencesStorage.ts';
import { WebSpotlight_Preferences_Loaded } from '../../constants/webSpotlightActionTypes.ts';
import {
  getInitialWebSpotlightSpace,
  getInitialWebSpotlightSpaceForCustomRootItem,
} from '../../utils/getInitialWebSpotlightSpace.ts';
import { getRelevantSpacesForWebSpotlightSelector } from '../../utils/getRelevantSpacesForWebSpotlightSelector.ts';

type Deps = {
  readonly loadSpaces: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly selectSpace: (
    spaceId: Uuid | null,
    rootItemId: Uuid | null,
    path: string,
    abortSignal?: AbortSignal,
  ) => ThunkPromise;
  readonly webSpotlightPreferencesStorage: IProjectSpecificStorage<IWebSpotlightPreferences>;
};

const preferencesLoaded = (preferences: Omit<IWebSpotlightPreferences, 'spaceId'>) =>
  ({
    type: WebSpotlight_Preferences_Loaded,
    payload: preferences,
  }) as const;

export type EnsureWebSpotlightActionsType = ReturnType<typeof preferencesLoaded>;

export const createEnsureWebSpotlightAction =
  (deps: Deps) =>
  (projectId: Uuid, history: History, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const preferences = deps.webSpotlightPreferencesStorage.load(projectId);
    dispatch(preferencesLoaded(preferences));

    await dispatch(deps.loadSpaces(abortSignal));

    const state = getState();
    const {
      data: { spaces },
      sharedApp: { selectedLanguage },
    } = state;

    const canManageSpaces = currentUserHasCapabilities(state, Capability.ManageSpaces);
    const relevantSpaces = getRelevantSpacesForWebSpotlightSelector(
      spaces.byId.values(),
      canManageSpaces,
    );
    const sortedSpaces = sortSpacesByName(relevantSpaces);

    const variantId = selectedLanguage?.id ?? '';
    const noSpacesRoute = buildPath<WebSpotlightEntryRouteParams>(WebSpotlightEntryRoute, {
      projectId,
      variantId,
    });

    const redirectToAndSelectSpace = async (space: ISpace, customRootItemId?: Uuid) => {
      const rootItemIdToSelect = customRootItemId || space.webSpotlightRootItemId;

      if (rootItemIdToSelect) {
        const spaceWithRootItemPath = buildPath<ContentItemRouteParams<UuidArray>>(
          ContentItemPreviewRoute,
          {
            projectId,
            variantId,
            spaceId: space.id,
            app: ContentItemsAppRouteSegment.WebSpotlight,
            contentItemIds: [rootItemIdToSelect],
          },
        );

        history.push(spaceWithRootItemPath);
        await dispatch(
          deps.selectSpace(space.id, rootItemIdToSelect, spaceWithRootItemPath, abortSignal),
        );
      } else {
        const spaceWithoutRootItemPath = buildPath<WebSpotlightRouteParams>(WebSpotlightRoute, {
          spaceId: space.id,
          variantId,
          projectId,
        });

        history.push(spaceWithoutRootItemPath);
        await dispatch(deps.selectSpace(space.id, null, spaceWithoutRootItemPath, abortSignal));
      }
    };

    const handleInitialState = async () => {
      const initialSpace = getInitialWebSpotlightSpace(sortedSpaces, preferences);

      if (initialSpace) {
        await redirectToAndSelectSpace(initialSpace);
      } else {
        history.push(noSpacesRoute);
        await dispatch(deps.selectSpace(null, null, noSpacesRoute, abortSignal));
      }
    };

    const { rootItemId, spaceId } = parseParamsFromRoute(history.location.pathname);

    if (spaceId) {
      const space = sortedSpaces.find(({ id }) => id === spaceId);

      if (!space) {
        await handleInitialState();
      } else if (!rootItemId && space.webSpotlightRootItemId) {
        await redirectToAndSelectSpace(space);
      } else {
        await dispatch(
          deps.selectSpace(space.id, rootItemId, history.location.pathname, abortSignal),
        );
      }
    } else if (rootItemId) {
      // Old links with root item before space id was part of the URL
      const correctedSpace = sortedSpaces.find(
        (space) => space.webSpotlightRootItemId === rootItemId,
      );

      if (correctedSpace) {
        await redirectToAndSelectSpace(correctedSpace);
      } else {
        const initialSpace = getInitialWebSpotlightSpaceForCustomRootItem(
          sortedSpaces,
          preferences,
        );

        if (initialSpace) {
          await redirectToAndSelectSpace(initialSpace, rootItemId);
        } else {
          await dispatch(
            deps.selectSpace(null, rootItemId, history.location.pathname, abortSignal),
          );
        }
      }
    } else {
      await handleInitialState();
    }
  };

type InitialParams = {
  readonly rootItemId: Uuid | null;
  readonly spaceId: Uuid | null;
};

const parseParamsFromRoute = (currentPath: string): InitialParams => {
  const contentItemRouteMatch = matchPath<ContentItemRouteParams<string>>(
    currentPath,
    ContentItemRoute,
  );
  const webSpotlightRouteMatch = matchPath<WebSpotlightRouteParams>(currentPath, WebSpotlightRoute);

  if (contentItemRouteMatch) {
    return {
      rootItemId: parseContentItemIds(contentItemRouteMatch.contentItemIds)[0] ?? null,
      spaceId: contentItemRouteMatch.spaceId ?? null,
    };
  }

  return {
    rootItemId: null,
    spaceId: webSpotlightRouteMatch?.spaceId ?? null,
  };
};
