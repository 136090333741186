import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { ReactNode, useState } from 'react';
import { isSelectionCollapsed } from '../../../../../../_shared/utils/selectionUtils.ts';
import { WebLinkProperties } from '../../../../../itemEditor/models/contentItemElements/richText/WebLinkProperties.type.ts';
import { LinkDetail } from '../../../../../itemEditor/utils/itemElementDataConverters/utils/LinkDetail.tsx';
import { EditorLinkStatus } from '../../../apiLimitations/api/EditorLinkStatus.ts';
import { InlineContentWithDialog } from '../../components/dialog/InlineContentWithDialog.tsx';
import { WebLinkDialog } from './WebLinkDialog.tsx';
import { WebLinkInfo } from './WebLinkInfo.tsx';

interface IWebLinkProps {
  readonly children: ReadonlyArray<React.ReactNode>;
  readonly disabled?: boolean;
  readonly onCancel?: () => void;
  readonly onEdit?: () => Promise<void>;
  readonly onUnlink?: () => void;
  readonly onUpdate?: (values: WebLinkProperties) => void;
  readonly openInNewWindow?: boolean;
  readonly status?: EditorLinkStatus;
  readonly text: string;
  readonly title?: string;
  readonly url: string;
}

enum DialogType {
  None = 'none',
  Info = 'info',
  Edit = 'edit',
}

const propTypes: PropTypeMap<IWebLinkProps> = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  disabled: PropTypes.bool,
  onCancel: PropTypes.func,
  onEdit: PropTypes.func,
  onUnlink: PropTypes.func,
  onUpdate: PropTypes.func,
  openInNewWindow: PropTypes.bool,
  status: PropTypes.oneOf(Object.values(EditorLinkStatus)),
  text: PropTypes.string.isRequired,
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
};

export const WebLink: React.FC<IWebLinkProps> = (props) => {
  const [dialog, setDialog] = useState(DialogType.None);

  const startEdit = (): void => {
    if (props.onEdit) {
      props.onEdit().then(() => setDialog(DialogType.Edit));
    }
  };

  const cancelEdit = (): void => {
    if (props.onCancel) {
      props.onCancel();
      setDialog(DialogType.None);
    }
  };

  const updateLink = (values: WebLinkProperties): void => {
    if (props.onUpdate) {
      props.onUpdate(values);
      setDialog(DialogType.None);
    }
  };

  const toggleActive = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    if (dialog !== DialogType.Edit) {
      const selectionCollapsed = isSelectionCollapsed();
      setDialog(
        dialog === DialogType.None && selectionCollapsed ? DialogType.Info : DialogType.None,
      );
    }
    e.preventDefault();
  };

  const handleClose = (): void => {
    if (dialog === DialogType.Info) {
      setDialog(DialogType.None);
    }
  };

  const renderPopover = (): ReactNode => {
    if (dialog === DialogType.Info) {
      return (
        <LinkDetail
          disabled={props.disabled}
          onEdit={startEdit}
          onUnlink={props.onUnlink}
          status={props.status}
        >
          <WebLinkInfo
            disabled={props.disabled}
            title={props.title}
            url={props.url}
            openInNewWindow={props.openInNewWindow}
          />
        </LinkDetail>
      );
    }

    if (dialog === DialogType.Edit) {
      return (
        <WebLinkDialog
          linkText={props.text}
          linkTitle={props.title}
          linkUrl={props.url}
          onCancel={cancelEdit}
          onUnlink={props.onUnlink}
          onUpsert={updateLink}
          openInNewWindow={props.openInNewWindow}
        />
      );
    }

    return null;
  };

  return (
    <InlineContentWithDialog
      isInfo={dialog === DialogType.Info}
      renderContent={(ref) => (
        <a
          className={classNames('rte__link', 'rte__link--is-clickable', {
            'rte__link--is-edited': dialog === DialogType.Edit,
          })}
          onClick={toggleActive}
          ref={ref}
        >
          {props.children}
        </a>
      )}
      renderDialog={renderPopover}
      onClose={handleClose}
    />
  );
};

WebLink.displayName = 'WebLink';
WebLink.propTypes = propTypes;
