import { Collection } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { logError } from '../../../../../_shared/utils/logError.ts';
import { createCollectionsServerUpdateModel } from '../../../../../data/models/collections/Collection.ts';
import { ICollectionsRepository } from '../../../../../repositories/interfaces/ICollectionsRepository.type.ts';
import { ICollectionsServerResponseModel } from '../../../../../repositories/serverModels/CollectionsServerModel.type.ts';
import {
  ServerApiErrorCode,
  tryParseApiError,
} from '../../../../../repositories/serverModels/ServerApiError.ts';
import {
  collectionsConfigurationIsNotValid,
  collectionsWereEdited,
  creatorIsOpenedDuringSave,
  failedToSaveCollections,
} from '../../constants/UIConstants.tsx';
import {
  Collections_Save_Failed,
  Collections_Save_Finished,
  Collections_Save_Started,
} from '../../constants/actionTypes.ts';

type Deps = {
  readonly collectionsRepository: Pick<ICollectionsRepository, 'update'>;
  readonly trackUserEvent: TrackUserEventWithDataAction;
};

const started = () =>
  ({
    type: Collections_Save_Started,
  }) as const;

const finished = (collections: ICollectionsServerResponseModel) =>
  ({
    type: Collections_Save_Finished,
    payload: collections,
  }) as const;

const failed = (errorMessage: string) =>
  ({
    type: Collections_Save_Failed,
    payload: {
      errorMessage,
    },
  }) as const;

export type SaveEditedCollectionsActionsType =
  | ReturnType<typeof started>
  | ReturnType<typeof finished>
  | ReturnType<typeof failed>;

export const createSaveEditedCollections =
  (deps: Deps) =>
  (onSuccess?: () => void, onFail?: () => void): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      collectionsApp: {
        collectionIds,
        editedCollectionId,
        editedCollections,
        editedCollectionsETag,
        isCreatorOpen,
      },
    } = getState();

    if (isCreatorOpen || editedCollectionId !== null) {
      dispatch(failed(creatorIsOpenedDuringSave));
      onFail?.();
      return;
    }

    dispatch(started());

    try {
      const result = await deps.collectionsRepository.update(
        createCollectionsServerUpdateModel(
          collectionIds,
          Collection.getValues(editedCollections),
          editedCollectionsETag,
        ),
      );

      dispatch(finished(result));
      onSuccess?.();

      dispatch(
        deps.trackUserEvent(TrackedEvent.CollectionsUpdated, {
          'collections-count': editedCollections.size,
          'collections-ids': collectionIds,
        }),
      );
    } catch (e) {
      const parsedError = tryParseApiError(e);
      switch (parsedError?.code) {
        case ServerApiErrorCode.ContentWasModified: {
          dispatch(failed(collectionsWereEdited));
          break;
        }

        case ServerApiErrorCode.CollectionsConfigurationNotValid: {
          //TODO KCL-5018, KCL-5645 find out, what the issue is and provide a detailed message
          dispatch(failed(collectionsConfigurationIsNotValid));
          break;
        }

        default: {
          logError(e);
          dispatch(failed(failedToSaveCollections));
        }
      }
      onFail?.();
    }
  };
