import React from 'react';
import { ConfirmationDialog } from '../../../../../_shared/components/ModalDialog/ConfirmationDialog.tsx';
import { CancelSchedulePublishFriendlyWarning } from '../../features/ContentItemInventory/components/CancelSchedulePublishFriendlyWarning.tsx';

type Props = {
  readonly isOpen: boolean;
  readonly onCancel: () => void;
  readonly onConfirm: () => void;
};

export const CancelSchedulePublishConfirmationDialog: React.FC<Props> = ({
  isOpen,
  onCancel,
  onConfirm,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <ConfirmationDialog
      confirmButtonText="Remove schedule"
      headerContent="Remove the publishing schedule"
      onConfirm={onConfirm}
      onClose={onCancel}
    >
      <p className="confirmation-dialog__section">
        The scheduled date will be removed and <strong>the item will not get published.</strong>
      </p>
      <p className="confirmation-dialog__section">
        If the item has a published version, it will remain intact.
      </p>
      <p className="confirmation-dialog__section">
        Are you sure you want to remove the publishing schedule?
      </p>
      <CancelSchedulePublishFriendlyWarning />
    </ConfirmationDialog>
  );
};

CancelSchedulePublishConfirmationDialog.displayName = 'CancelSchedulePublishConfirmationDialog';
