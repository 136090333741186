import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import React, { RefObject } from 'react';
import { BarItemAction } from '../../../../../../../_shared/components/BarItems/Actions/BarItemAction.tsx';
import { IconName } from '../../../../../../../_shared/constants/iconEnumGenerated.ts';
import { createDropDown } from '../../../../../../../_shared/uiComponents/DropDown/DropDown.tsx';
import { DropDownOption } from '../../../../../../../_shared/uiComponents/DropDown/DropDownOption.tsx';
import { DropDownOptionName } from '../../../../../../../_shared/uiComponents/DropDown/DropDownOptionName.tsx';
import { Icon } from '../../../../../../../_shared/uiComponents/Icon/Icon.tsx';
import {
  DataUiAction,
  DataUiCollection,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface ILinkedItemMoreActionsMenuProps {
  readonly isCompact?: boolean;
  readonly onDuplicateItem: () => void;
  readonly onRemoveItem: () => void;
}

type LinkedItemAction = {
  readonly dataUiAction: DataUiAction;
  readonly icon: IconName;
  readonly onClick: () => void;
  readonly text: string;
};

const DropDown = createDropDown<LinkedItemAction>();

export const LinkedItemMoreActionsMenu: React.FC<ILinkedItemMoreActionsMenuProps> = ({
  isCompact,
  onDuplicateItem,
  onRemoveItem,
}) => {
  const dropDownActions: ReadonlyArray<LinkedItemAction> = [
    {
      text: 'Duplicate',
      onClick: () => onDuplicateItem(),
      icon: IconName.DocCopy,
      dataUiAction: DataUiAction.Duplicate,
    },
    {
      text: 'Remove',
      onClick: () => onRemoveItem(),
      icon: IconName.Times,
      dataUiAction: DataUiAction.Delete,
    },
  ];

  return (
    <DropDown
      optionListDataUiAttributes={getDataUiCollectionAttribute(
        DataUiCollection.MoreActionsDropdown,
      )}
      options={dropDownActions}
      renderOption={(hideMenu, option: LinkedItemAction) => (
        <DropDownOption
          onClick={() => {
            hideMenu();
            option.onClick();
          }}
          key={option.text}
          dataUiAttributes={getDataUiActionAttribute(option.dataUiAction)}
        >
          <div className="actions-menu-item">
            <Icon className="actions-menu-item__icon" iconName={option.icon} />
            <DropDownOptionName text={option.text} />
          </div>
        </DropDownOption>
      )}
      renderSelectedOption={(ref, toggleMoreActions, isOpen) => (
        <Tooltip tooltipText={isOpen ? undefined : 'More actions'} placement="bottom">
          <BarItemAction
            screenReaderText="More actions"
            iconName={IconName.Ellipsis}
            isCompact={isCompact}
            onClick={toggleMoreActions}
            ref={ref as RefObject<HTMLDivElement>}
            dataUiActionName={DataUiAction.MoreActions}
          />
        </Tooltip>
      )}
    />
  );
};

LinkedItemMoreActionsMenu.displayName = 'LinkedItemMoreActionsMenu';
