import React, { useMemo } from 'react';
import { BarItemActionButtons } from '../../../../../_shared/components/BarItems/BarItemActionButtons.tsx';
import { BarItemError } from '../../../../../_shared/components/BarItems/BarItemError.tsx';
import { HotkeysHandler } from '../../../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import { ShortcutSymbols } from '../../../../../_shared/constants/shortcutSymbols.ts';
import { useScrollIntoViewOnMount } from '../../../../../_shared/hooks/useScrollIntoViewOnMount.ts';
import { HookFormProps } from '../../../../../_shared/types/hookFormProps.ts';
import {
  isArchivedWorkflowStep,
  isPublishedWorkflowStep,
  isScheduledToPublishWorkflowStep,
} from '../../../../../_shared/utils/contentItemUtils.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { WorkflowStepColor } from '../../../../../data/constants/WorkflowStepColor.ts';
import {
  IWorkflowStep,
  mapWorkflowStepToOption,
} from '../../../../../data/models/workflow/WorkflowStep.ts';
import { IWorkflowStepFormShape } from '../../model/IWorkflowStepFormShape.type.ts';
import { PlaceholderOuterAction } from '../stepBarItems/PlaceholderOuterAction.tsx';
import { StepEditorHeader } from './StepEditorHeader.tsx';
import { WorkflowStepFormFields } from './WorkflowStepFormFields.tsx';

type Props = {
  readonly allRenderedSteps: ReadonlyArray<IWorkflowStep>;
  readonly errorMessage?: string;
  readonly formProps: HookFormProps<IWorkflowStepFormShape>;
  readonly isNew: boolean;
  readonly isProtected: boolean;
  readonly isUsed: boolean;
  readonly onCreatingCanceled: () => void;
  readonly onStatusDeleted: () => void;
  readonly onStatusEditingCanceled: () => void;
  readonly onSubmit: () => void;
  readonly workflowStepId: Uuid;
  readonly workflowStepColor: WorkflowStepColor;
  readonly workflowStepName: string;
};

export const StepEditor: React.FC<Props> = (props) => {
  const scrollRef = useScrollIntoViewOnMount<HTMLDivElement>();

  const allStepsExceptThisStep = useMemo(
    () => props.allRenderedSteps.filter((s) => s.id !== props.workflowStepId),
    [props.allRenderedSteps, props.workflowStepId],
  );
  const transitionToOptions = useMemo(
    () =>
      allStepsExceptThisStep
        .filter((step) => !isScheduledToPublishWorkflowStep(step))
        .map(mapWorkflowStepToOption),
    [allStepsExceptThisStep],
  );
  const transitionFromOptions = useMemo(
    () =>
      allStepsExceptThisStep
        .filter((step) => !isScheduledToPublishWorkflowStep(step))
        .filter((step) => !isPublishedWorkflowStep(step))
        .filter((step) => !isArchivedWorkflowStep(step))
        .map(mapWorkflowStepToOption),
    [allStepsExceptThisStep],
  );

  return (
    <HotkeysHandler
      className="bar-item__wrapper bar-item__wrapper--is-flexible"
      handlers={{
        onEscape: props.isNew ? props.onCreatingCanceled : props.onStatusEditingCanceled,
        onEnter: props.onSubmit,
      }}
      {...getDataUiElementAttribute(
        props.isNew ? DataUiElement.WorkflowStepFormNew : DataUiElement.WorkflowStepForm,
      )}
    >
      <div className="bar-item__pane bar-item__pane--is-expanded" ref={scrollRef}>
        <StepEditorHeader color={props.workflowStepColor} stepName={props.workflowStepName} />
        <WorkflowStepFormFields
          key={props.workflowStepId}
          formProps={props.formProps}
          transitionToOptions={transitionToOptions}
          transitionFromOptions={transitionFromOptions}
          workflowStepName={props.workflowStepName}
        />
        <BarItemActionButtons
          secondaryAction={{
            handler: props.isNew ? props.onCreatingCanceled : props.onStatusEditingCanceled,
            text: 'Cancel',
            dataUIActionName: DataUiAction.Cancel,
            shortcut: ShortcutSymbols.Escape,
          }}
          primaryAction={{
            text: 'Confirm',
            handler: props.onSubmit,
            dataUIActionName: DataUiAction.Save,
            shortcut: ShortcutSymbols.Enter,
          }}
          destructiveAction={
            !props.isProtected && !props.isNew
              ? {
                  text: 'Delete',
                  isDisabled: props.isUsed,
                  tooltipText: props.isUsed
                    ? 'Workflow step is currently used in one or more content items.'
                    : undefined,
                  handler: props.onStatusDeleted,
                  icon: 'Bin',
                  dataUIActionName: DataUiAction.Delete,
                }
              : undefined
          }
        />
        <BarItemError showError={!!props.errorMessage} error={props.errorMessage} />
      </div>
      <div className="bar-item__outer-actions-pane">
        <PlaceholderOuterAction />
      </div>
    </HotkeysHandler>
  );
};

StepEditor.displayName = 'StepEditor';
