import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { IProjectDetails } from '../../../../data/models/projects/ProjectDetails.ts';
import { CopyState } from '../../../projects/constants/copyState.ts';
import { isProjectInSettingUpUiTransitionState } from '../../../projects/utils/projectUtils.ts';
import { EnvironmentUiState } from '../types/EnvironmentUiState.ts';
import { compareEnvironments } from '../utils/environmentsUtils.ts';
import { getEnvironments } from './getEnvironments.ts';

export interface IEnvironmentViewModel {
  readonly activeLanguagesCount: number;
  readonly copyState: CopyState;
  readonly createdAt: DateTimeStamp;
  readonly environmentName: string;
  readonly isBeingDeleted: boolean;
  readonly isBeingRenamed: boolean;
  readonly isBeingSwapped: boolean;
  readonly isMasterEnvironment: boolean;
  readonly isSettingUp: boolean;
  readonly environmentId: Uuid;
  readonly sourceEnvironmentName: string;
  readonly productionFrom: DateTimeStamp;
  readonly productionTo: DateTimeStamp;
}

const getSourceEnvironmentName = (
  sourceEnvId: Uuid,
  environments: ReadonlyArray<IProjectDetails>,
): string => {
  if (sourceEnvId === '') {
    return '';
  }
  return environments.find((env) => env?.projectId === sourceEnvId)?.environmentName || '';
};

const getEnvironmentsForListing = (
  state: IStore,
  masterEnvironmentId: Uuid,
  includeIncomplete: boolean = true,
): Array<IEnvironmentViewModel> => {
  const {
    projectsApp: { projectsUiTransitionState },
  } = state;

  const environments = getEnvironments(state, masterEnvironmentId, includeIncomplete);

  return environments
    .toSorted((a, b) => compareEnvironments(a, b))
    .map((environment: IProjectDetails): IEnvironmentViewModel => {
      const environmentState = state.environmentsApp.environmentUiStates.get(environment.projectId);
      return {
        activeLanguagesCount: environment.activeLanguagesCount,
        copyState: environment.copyState,
        createdAt: environment.projectCreatedAt,
        environmentId: environment.projectId,
        environmentName: environment.environmentName,
        isBeingDeleted: environmentState === EnvironmentUiState.Deleting,
        isBeingRenamed: environmentState === EnvironmentUiState.Renaming,
        isBeingSwapped: environmentState === EnvironmentUiState.Swapping,
        isMasterEnvironment: environment.projectId === environment.masterEnvironmentId,
        isSettingUp: isProjectInSettingUpUiTransitionState(
          environment.projectId,
          projectsUiTransitionState,
        ),
        productionFrom: environment.productionFrom,
        productionTo: environment.productionTo,
        sourceEnvironmentName: getSourceEnvironmentName(
          environment.sourceEnvironmentId,
          environments,
        ),
      };
    });
};

export type EnvironmentListingModel = {
  readonly productionEnvironment: IEnvironmentViewModel | null;
  readonly nonProductionEnvironments: ReadonlyArray<IEnvironmentViewModel>;
};

export const getSplitEnvironmentsForListing = (
  state: IStore,
  masterEnvironmentId: Uuid,
  includeIncomplete: boolean = true,
): EnvironmentListingModel => {
  const sortedEnvironments = getEnvironmentsForListing(
    state,
    masterEnvironmentId,
    includeIncomplete,
  );

  const productionIndex = sortedEnvironments.findIndex((env) => env.isMasterEnvironment);
  const productionEnvironment =
    productionIndex < 0 ? null : sortedEnvironments.splice(productionIndex, 1)[0] ?? null;

  return {
    productionEnvironment,
    nonProductionEnvironments: sortedEnvironments,
  };
};
