import { InvariantException } from '@kontent-ai/errors';
import {
  CompiledTypeElementType,
  ElementType,
  ItemElementType,
} from '../../contentInventory/content/models/ContentItemElementType.ts';
import { areTextEditorStatesTheSame } from '../../richText/utils/compare/areTextEditorStatesTheSame.ts';
import { ICompiledContentItemElementData } from '../models/contentItemElements/ICompiledContentItemElement.ts';
import { areAssetItemElementsTheSame } from './itemElementsEqualityCheckers/assetItemElementsEqualityChecker.ts';
import { areCustomItemElementsTheSame } from './itemElementsEqualityCheckers/customItemElementsEqualityChecker.ts';
import { areDateTimeItemElementsTheSame } from './itemElementsEqualityCheckers/dateTimeItemElementEqualityChecker.ts';
import { areModularContentsItemElementsTheSame } from './itemElementsEqualityCheckers/modularContentItemElementsEqualityChecker.ts';
import { areMultipleChoicesItemElementsTheSame } from './itemElementsEqualityCheckers/multipleChoiceItemElementsEqualityChecker.ts';
import { createRichTextItemElementsEqualityChecker } from './itemElementsEqualityCheckers/richTextItemElementsEqualityChecker.ts';
import { areStringItemElementsTheSame } from './itemElementsEqualityCheckers/stringItemElementsEqualityChecker.ts';
import { taxonomyItemElementsEqualityChecker } from './itemElementsEqualityCheckers/taxonomyItemElementsEqualityChecker.ts';
import { createTextItemElementsEqualityChecker } from './itemElementsEqualityCheckers/textItemElementsEqualityChecker.ts';
import { IEqualityCheckerDependencies } from './itemElementsEqualityCheckers/types/IEqualityCheckerDependencies.type.ts';
import { IItemElementsEqualityChecker } from './itemElementsEqualityCheckers/types/IItemElementsEqualityChecker.type.ts';
import { areUrlSlugsItemElementsTheSame } from './itemElementsEqualityCheckers/urlSlugItemElementsEqualityChecker.ts';

const dependencyUtils: IEqualityCheckerDependencies = {
  areTextEditorStatesTheSame,
  getItemElementEqualityChecker,
};

const areRichTextElementsTheSame = createRichTextItemElementsEqualityChecker(dependencyUtils);
const areTextElementsTheSame = createTextItemElementsEqualityChecker(dependencyUtils);

const validatorsMap: ReadonlyRecord<ItemElementType, IItemElementsEqualityChecker> = {
  [ElementType.Asset]: areAssetItemElementsTheSame,
  [ElementType.DateTime]: areDateTimeItemElementsTheSame,
  [ElementType.LinkedItems]: areModularContentsItemElementsTheSame,
  [ElementType.MultipleChoice]: areMultipleChoicesItemElementsTheSame,
  [ElementType.Number]: areStringItemElementsTheSame,
  [ElementType.Custom]: areCustomItemElementsTheSame,
  [ElementType.RichText]: areRichTextElementsTheSame,
  [ElementType.Text]: areTextElementsTheSame,
  [ElementType.Taxonomy]: taxonomyItemElementsEqualityChecker,
  [ElementType.UrlSlug]: areUrlSlugsItemElementsTheSame,
};

const isExpectedKey = (key: CompiledTypeElementType): key is keyof typeof validatorsMap =>
  (Object.keys(validatorsMap) as ReadonlyArray<CompiledTypeElementType>).includes(key);

export function getItemElementEqualityChecker(
  type: CompiledTypeElementType,
): (item1: ICompiledContentItemElementData, item2: ICompiledContentItemElementData) => boolean {
  if (isExpectedKey(type)) {
    return validatorsMap[type];
  }

  throw InvariantException(`For the given type "${type}" no expected equality checker was set.`);
}
