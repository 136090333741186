import { BaseColor } from '@kontent-ai/component-library/tokens';
import { getReadableTextColor } from '@kontent-ai/component-library/utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { getDataUiObjectNameAttribute } from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { WorkflowStepColor } from '../../../../../../data/constants/WorkflowStepColor.ts';
import { renderWorkflowStepColor } from '../../../../../../data/models/workflow/utils/renderWorkflowStepColor.ts';

interface IStepColorPickerItemProps {
  readonly color: WorkflowStepColor;
  readonly isSelected: boolean;
  readonly onClick: () => void;
}

const StyledPickerItem = styled.li<{ readonly $color: BaseColor }>`${({ $color }) => ({
  backgroundColor: $color,
  color: getReadableTextColor($color),
  '--background-color': $color,
})}`;

const StepColorPickerItem: React.FC<IStepColorPickerItemProps> = ({
  color,
  isSelected,
  onClick,
}) => (
  <StyledPickerItem
    $color={renderWorkflowStepColor(color)}
    onClick={isSelected ? undefined : onClick}
    className={classNames('workflow-step__color-picker-item', {
      'workflow-step__color-picker-item--is-selected': isSelected,
    })}
    {...getDataUiObjectNameAttribute(color)}
  />
);

StepColorPickerItem.displayName = 'StepColorPickerItem';

const propTypes: PropTypesShape<IStepColorPickerItemProps> = {
  color: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
StepColorPickerItem.propTypes = propTypes;

export { StepColorPickerItem };
