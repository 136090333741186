import { History } from 'history';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { IUserIdentifier } from '../../../../../../_shared/models/UserIdentifier.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { AssignmentSections } from '../../constants/AssignmentSections.ts';
import { ContentItemSidebarEditableSection } from '../../constants/ContentItemSidebarEditableSection.ts';
import { createUpdateContentItemEditingSidebarSectionActions } from './createUpdateContentItemEditingSidebarSection.ts';
import { IUpdateAssignmentSectionParams } from './updateAssignment.ts';

interface IDeps {
  readonly updateAssignmentSection: (args: IUpdateAssignmentSectionParams) => ThunkPromise;
}

type Parameters = {
  readonly actionOrigin: ContentItemEditingEventOrigins;
  readonly contributors: ReadonlyArray<IUserIdentifier>;
  readonly deps: IDeps;
  readonly history: History;
};

const updateContributors =
  ({ actionOrigin, contributors, deps, history }: Parameters): ThunkPromise =>
  async (dispatch) => {
    await dispatch(
      deps.updateAssignmentSection({
        actionOrigin,
        history,
        getUpdatedAssignment: () => ({
          assignees: new Set(contributors),
        }),
        submittingSection: AssignmentSections.Contributors,
      }),
    );
  };

const { startSidebarSectionUpdate, finishSidebarSectionUpdate } =
  createUpdateContentItemEditingSidebarSectionActions(
    ContentItemSidebarEditableSection.Contributors,
  );

export const createUpdateContributorsInSidebar =
  (deps: IDeps) =>
  (history: History, contributors: ReadonlyArray<IUserIdentifier>): ThunkPromise =>
  async (dispatch) => {
    dispatch(startSidebarSectionUpdate);

    await dispatch(
      updateContributors({
        actionOrigin: ContentItemEditingEventOrigins.ItemDetails,
        contributors,
        deps,
        history,
      }),
    );

    dispatch(finishSidebarSectionUpdate);
  };

export const createUpdateContributors =
  (deps: IDeps) =>
  (
    history: History,
    contributors: ReadonlyArray<IUserIdentifier>,
    actionOrigin: ContentItemEditingEventOrigins,
  ): ThunkPromise =>
  async (dispatch) =>
    await dispatch(updateContributors({ actionOrigin, contributors, deps, history }));
