import React, { ReactNode } from 'react';
import { useHistory, useParams } from 'react-router';
import { EnsureValidState } from '../../../../../_shared/components/EnsureValidState.tsx';
import {
  ContentItemRevisionViewerRouteParams,
  ContentItemTimelineItemRoute,
  ContentItemTimelineRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import {
  buildPath,
  parseContentItemIds,
} from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { initViewRevision } from '../../Revisions/actions/thunkRevisionsActions.ts';

interface IEnsureSelectedRevisionOwnProps {
  readonly contentItemId: Uuid;
  readonly children: ReactNode;
  readonly variantId: Uuid;
  readonly timelineItemId: Uuid;
}

export const EnsureSelectedRevision: React.FC<IEnsureSelectedRevisionOwnProps> = ({
  contentItemId,
  children,
  timelineItemId,
  variantId,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const routeParams = useParams<ContentItemRevisionViewerRouteParams<string>>();

  const isStateEnsured = useSelector((state) => {
    const {
      contentApp: { selectedRevision },
    } = state;

    return (
      !!selectedRevision &&
      selectedRevision.contentItemId.itemId === contentItemId &&
      selectedRevision.contentItemId.variantId === variantId &&
      selectedRevision.itemId === timelineItemId
    );
  });

  const onRouteEntered = () =>
    dispatch(
      initViewRevision({
        contentItemId,
        timelineItemId,
        history,
        routeParams,
      }),
    ).catch(() => {
      const fallbackPath = buildPath<ContentItemTimelineRouteParams<UuidArray>>(
        ContentItemTimelineItemRoute,
        {
          ...routeParams,
          contentItemIds: parseContentItemIds(routeParams.contentItemIds),
        },
      );
      history.push(fallbackPath);
    });

  return (
    <EnsureValidState isStateEnsured={isStateEnsured} onRouteEntered={onRouteEntered}>
      {children}
    </EnsureValidState>
  );
};

EnsureSelectedRevision.displayName = 'EnsureSelectedRevision';
