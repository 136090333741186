import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { StatusBarActions } from '../../../../../_shared/components/StatusBar/StatusBarActions.tsx';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { compose } from '../../../../../_shared/utils/func/compose.ts';
import { always } from '../../../../../_shared/utils/func/functionalTools.ts';
import { createNewTaxonomyGroup } from '../../actions/thunkTaxonomyActions.ts';
import { TaxonomyEditorStatus } from '../../models/TaxonomyEditorStatus.ts';

export const TaxonomyCreatorToolbarActions: React.FC = () => {
  const codename = useSelector((s) => s.taxonomyApp.editedTaxonomyGroup.codename);
  const isBeingSaved = useSelector(
    (s) => s.taxonomyApp.status === TaxonomyEditorStatus.IsBeingSaved,
  );
  const isLoading = useSelector((s) => s.taxonomyApp.status === TaxonomyEditorStatus.Loading);

  const history = useHistory();
  const dispatch = useDispatch();
  const onSave = useMemo(
    () => compose(dispatch, createNewTaxonomyGroup, always(history)),
    [history],
  );

  return (
    <StatusBarActions
      codename={codename}
      isBeingSaved={isBeingSaved}
      isLoading={isLoading}
      onSave={onSave}
    />
  );
};

TaxonomyCreatorToolbarActions.displayName = 'TaxonomyCreatorToolbarActions';
