import { combineReducers } from '@reduxjs/toolkit';
import { contributors } from './forms/contributors.ts';
import { dueDate } from './forms/dueDate.ts';
import { note } from './forms/note.ts';
import { tasks } from './forms/tasks.ts';

export const forms = combineReducers({
  contributors,
  dueDate,
  note,
  tasks,
});
