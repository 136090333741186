import { isElement } from '@kontent-ai/DOM';
import { useObserveElementPresence, usePrevious } from '@kontent-ai/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { getContentStateActionResult } from '../../../../../../_shared/features/AI/helpers/transformAiResult.ts';
import { useAiTask } from '../../../../../../_shared/features/AI/hooks/aiTasks/useAiTask.ts';
import { useOnFinishedAiActionTask } from '../../../../../../_shared/features/AI/hooks/aiTasks/useOnFinishedAiActionTask.ts';
import { useAiActionTrackingWithSession } from '../../../../../../_shared/features/AI/hooks/useAiActionTrackingWithSession.ts';
import { usePendingAiActionWithSession } from '../../../../../../_shared/features/AI/hooks/usePendingAiActionWithSession.ts';
import { AiActionTrackingProps } from '../../../../../../_shared/features/AI/types/AiActionTrackingProps.type.ts';
import { AiError } from '../../../../../../_shared/features/AI/types/aiErrors.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { repositoryCollection } from '../../../../../../_shared/repositories/repositories.ts';
import { getLanguage } from '../../../../../../data/reducers/languages/selectors/getLanguages.ts';
import { AiActionName } from '../../../../../../repositories/serverModels/ai/AiActionName.type.ts';
import { createTranslateContentRequest } from '../../../../../../repositories/serverModels/ai/actions/AiServerModels.translateContent.ts';
import { ElementType } from '../../../../../contentInventory/content/models/ContentItemElementType.ts';
import { createAiTranslateButtonPlaceholderId } from '../../../../../itemEditor/features/ContentItemEditing/components/elements/subComponents/AiTranslateButtonPlaceholder.tsx';
import { AiTranslateButton } from '../../../../../itemEditor/features/ContentItemEditing/containers/elements/subComponents/AiTranslateButton.tsx';
import { loadRichTextReferences } from '../../../../../itemEditor/features/LoadedItems/actions/thunkLoadedItemsActions.ts';
import { EmptyContentComponents } from '../../../../../itemEditor/models/contentItem/ContentComponent.ts';
import { IBaseTextItemElement } from '../../../../../itemEditor/models/contentItemElements/IBaseTextItemElement.ts';
import { getElementById } from '../../../../../itemEditor/stores/utils/contentItemElementsUtils.ts';
import { convertElementsToDomainModel } from '../../../../../itemEditor/stores/utils/contentItemHelperMethods.ts';
import { useEditorStateCallbacks } from '../../../../editorCore/hooks/useEditorStateCallbacks.ts';
import { useEditorWithPlugin } from '../../../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginCreator } from '../../../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../../../editorCore/types/Editor.contract.type.ts';
import { Apply, EditorPlugin, Render } from '../../../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../../../editorCore/utils/decorable.ts';
import { withDisplayName } from '../../../../editorCore/utils/withDisplayName.ts';
import { isContentComponent } from '../../../../utils/blocks/blockTypeUtils.ts';
import { createContent, filterBlocks } from '../../../../utils/blocks/editorBlockUtils.ts';
import {
  getSelectionOfAllContent,
  moveCaretToSelectionEnd,
} from '../../../../utils/editorSelectionUtils.ts';
import { getBlocks } from '../../../../utils/general/editorContentGetters.ts';
import {
  IContentChangeInput,
  IContentChangeResult,
  insertNewChars,
  isContentEmpty,
  splitBlock,
} from '../../../../utils/general/editorContentUtils.ts';
import { emptyContentState } from '../../../../utils/general/editorEmptyValues.ts';
import { pasteContent } from '../../../../utils/import/editorImportUtils.ts';
import { StylesPlugin } from '../../../visuals/StylesPlugin.tsx';

export type TranslateContentPlugin = EditorPlugin<
  None,
  AiActionTrackingProps,
  None,
  [StylesPlugin]
>;

export const useTranslateContentPlugin: PluginCreator<TranslateContentPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('TranslateContentPlugin', {
        ComposedEditor: (props) => {
          const [lastError, setLastError] = useState<AiError | null>(null);
          const [initialContentWithSelection, setInitialContentWithSelection] =
            useState<IContentChangeInput | null>(null);

          const languages = useSelector((s) => s.data.languages);
          const dispatch = useDispatch();

          const {
            aiSessionId,
            elementOperationTrackingData,
            trackFinishedAction,
            trackStartingAction,
          } = useAiActionTrackingWithSession(props.element);

          const { decorateWithEditorStateCallbacks, executeChange, getApi, getEditorState } =
            useEditorStateCallbacks<TranslateContentPlugin>();

          const { aiOperationState, startPendingAiAction, finishPendingAiAction } =
            usePendingAiActionWithSession(aiSessionId);

          const { run, result } = useAiTask(
            AiActionName.TranslateContent,
            getContentStateActionResult,
          );

          useOnFinishedAiActionTask(result.isFinished, () => {
            if (result.trackingParams) {
              trackFinishedAction(result.trackingParams);
            }
            setLastError(result.error);
            setInitialContentWithSelection(null);
            finishPendingAiAction();
          });

          const startTranslateContentAction = useCallback(
            async (sourceVariantId: Uuid) => {
              if (!props.element.itemId) {
                return;
              }

              const {
                itemId: { itemId, variantId: targetVariantId },
                elementId,
              } = props.element;

              const sourceLanguage = getLanguage(languages, sourceVariantId);
              const targetLanguage = getLanguage(languages, targetVariantId);
              if (!sourceLanguage || !targetLanguage) {
                return;
              }

              startPendingAiAction();

              const variantServerModel =
                await repositoryCollection.contentItemRepository.getVariantWithElements(
                  itemId,
                  sourceVariantId,
                  { elementIds: [elementId] },
                );
              const elements = convertElementsToDomainModel(variantServerModel.contentElements);
              const content =
                getElementById<IBaseTextItemElement>(
                  elementId,
                  elements,
                )?._editorState?.getCurrentContent() || emptyContentState;

              await dispatch(loadRichTextReferences([content], EmptyContentComponents));

              run(
                createTranslateContentRequest(
                  content,
                  sourceLanguage.name,
                  sourceLanguage.codename,
                  targetLanguage.name,
                  targetLanguage.codename,
                  elementOperationTrackingData,
                ),
              );

              trackStartingAction({
                action: AiActionName.TranslateContent,
                sourceVariantId,
                sourceLanguageCode: sourceLanguage.codename,
                targetLanguageCode: targetLanguage.codename,
              });
            },
            [
              languages,
              run,
              props.element,
              elementOperationTrackingData,
              startPendingAiAction,
              trackStartingAction,
            ],
          );

          const previousAiResultContent = usePrevious(result.content);

          useEffect(() => {
            if (result.content && !previousAiResultContent) {
              const currentContent = getEditorState().getCurrentContent();
              setInitialContentWithSelection(
                moveCaretToSelectionEnd({
                  content: currentContent,
                  selection: getSelectionOfAllContent(currentContent),
                }),
              );
            }
          }, [getEditorState, previousAiResultContent, result.content]);

          useEffect(() => {
            const appendTranslationToInitialContent = (
              input: IContentChangeInput,
            ): IContentChangeResult => {
              if (!initialContentWithSelection || !result.content) {
                return input;
              }

              const resultWithoutComponents = createContent(
                filterBlocks(getBlocks(result.content), (b) => !isContentComponent(b)).blocks,
              );

              if (isContentEmpty(initialContentWithSelection.content)) {
                return pasteContent(initialContentWithSelection, resultWithoutComponents);
              }

              const initialContentWithNewLine =
                props.element.elementType === ElementType.RichText
                  ? splitBlock(initialContentWithSelection)
                  : insertNewChars(initialContentWithSelection, '\n');

              return pasteContent(initialContentWithNewLine, resultWithoutComponents);
            };

            if (result.content) {
              executeChange((editorState) =>
                getApi().executeContentChange(
                  editorState,
                  editorState.getSelection(),
                  appendTranslationToInitialContent,
                  'insert-fragment',
                  false,
                ),
              );
            }
          }, [
            executeChange,
            getApi,
            initialContentWithSelection,
            props.element.elementType,
            result.content,
          ]);

          const { current: itemElementAiActionsContainer } = useObserveElementPresence(
            createAiTranslateButtonPlaceholderId(
              props.element.elementId,
              props.element.contentComponentId,
            ),
          );

          const renderOverlays: Decorator<Render<TranslateContentPlugin>> = useCallback(
            (baseRenderOverlays) => (state) => (
              <>
                {baseRenderOverlays(state)}
                {isElement(itemElementAiActionsContainer) &&
                  createPortal(
                    <AiTranslateButton
                      perform={startTranslateContentAction}
                      aiOperationState={aiOperationState}
                      error={lastError}
                    />,
                    itemElementAiActionsContainer,
                  )}
              </>
            ),
            [
              startTranslateContentAction,
              aiOperationState,
              lastError,
              itemElementAiActionsContainer,
            ],
          );

          const apply: Apply<TranslateContentPlugin> = useCallback(
            (state) => {
              decorateWithEditorStateCallbacks(state);
              state.renderOverlays.decorate(renderOverlays);

              return {};
            },
            [decorateWithEditorStateCallbacks, renderOverlays],
          );

          return useEditorWithPlugin(baseEditor, props, { apply });
        },
      }),
    [baseEditor],
  );
