import { focusAtTheEnd } from '@kontent-ai/DOM';
import { InputState, Textarea } from '@kontent-ai/component-library/Input';
import { useAttachRef } from '@kontent-ai/hooks';
import React, { forwardRef } from 'react';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly autoFocus?: boolean;
  readonly dataUiInputName?: DataUiInput;
  readonly disabled?: boolean;
  readonly onChange: (value: string) => void;
  readonly placeholder?: string;
  readonly rows?: number;
  readonly value: string;
};

export const NoteFormTextArea = forwardRef<HTMLTextAreaElement, Props>(
  (
    { autoFocus, dataUiInputName = DataUiInput.Text, disabled, onChange, placeholder, rows, value },
    ref,
  ) => {
    const { refObject, refToForward } = useAttachRef(ref);

    React.useEffect(() => {
      if (autoFocus) {
        focusAtTheEnd(refObject.current);
      }
    }, [autoFocus, refObject]);

    return (
      <Textarea
        inputState={disabled ? InputState.Disabled : InputState.Default}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => onChange(e.currentTarget.value)}
        placeholder={placeholder}
        ref={refToForward}
        rows={rows}
        value={value}
        {...getDataUiInputAttribute(dataUiInputName)}
      />
    );
  },
);

NoteFormTextArea.displayName = 'NoteFormTextArea';
