import { Button, ButtonStyle } from '@kontent-ai/component-library/Button';
import { AnimatedProgressIcon, Icon, Icons } from '@kontent-ai/component-library/Icons';
import PropTypes from 'prop-types';
import React, { ComponentProps, RefObject, WeakValidationMap } from 'react';
import { DialogState } from '../DialogStateEnum.ts';

type ButtonProps = ComponentProps<typeof Button>;

export interface IDialogAction extends Omit<ButtonProps, 'buttonDisplay' | 'buttonStyle' | 'size'> {
  readonly ref?: RefObject<HTMLButtonElement>;
}

export const dialogActionPropTypes: Required<
  WeakValidationMap<Omit<IDialogAction, keyof ButtonProps>>
> = {
  ref: PropTypes.any,
};

export interface IDialogActionWithMoreOptions extends IDialogAction {
  readonly text: string;
  readonly iconBefore?: Icon;
  readonly iconAfter?: Icon;
}

export const dialogActionWithMoreOptionsPropTypes: Required<
  WeakValidationMap<Omit<IDialogActionWithMoreOptions, keyof ButtonProps>>
> = {
  ...dialogActionPropTypes,
  text: PropTypes.string.isRequired,
  iconBefore: PropTypes.oneOf([...Object.values(Icons), AnimatedProgressIcon]),
  iconAfter: PropTypes.oneOf([...Object.values(Icons), AnimatedProgressIcon]),
};

interface IDialogFooterActionProps extends IDialogActionWithMoreOptions {
  readonly dialogState: DialogState;
  readonly buttonStyle: ButtonStyle;
}

export const DialogFooterAction = React.forwardRef<HTMLButtonElement, IDialogFooterActionProps>(
  (
    { buttonStyle, dialogState, disabled, iconBefore, iconAfter, text, ...otherProps },
    forwardedRef,
  ) => {
    const isInProgress = buttonStyle === 'primary' && dialogState === DialogState.InProgress;

    return (
      <Button
        disabled={isInProgress || disabled}
        buttonStyle={buttonStyle}
        ref={forwardedRef}
        size="medium"
        {...otherProps}
      >
        {iconBefore && <Button.Icon icon={isInProgress ? AnimatedProgressIcon : iconBefore} />}
        <Button.Label>{text}</Button.Label>
        {iconAfter && <Button.Icon icon={isInProgress ? AnimatedProgressIcon : iconAfter} />}
      </Button>
    );
  },
);

DialogFooterAction.displayName = 'DialogFooterAction';
