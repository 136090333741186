import { Icons } from '@kontent-ai/component-library/Icons';
import { IconSize } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { useVariantTranslation } from '../../../../../../../_shared/features/AI/hooks/useVariantTranslation.ts';
import { ContentItemId } from '../../../../../../../_shared/models/ContentItemId.type.ts';
import { ButtonStyle } from '../../../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import { DataUiAction } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { VariantQuickActionMenu } from './VariantQuickActionMenu.tsx';
import { MenuOption } from './VariantQuickActionsNotificationBar.tsx';

type TranslateFromLanguageQuickMenuProps = {
  readonly contentItemId: ContentItemId;
  readonly menuOptions: ReadonlyArray<MenuOption>;
  readonly onTranslationStarted: () => void;
  readonly projectEnvironmentId: Uuid;
  readonly userId: string;
};

export const TranslateFromLanguageQuickMenu: React.FC<TranslateFromLanguageQuickMenuProps> = ({
  contentItemId,
  menuOptions,
  onTranslationStarted,
  projectEnvironmentId,
  userId,
}) => {
  const { startTranslation } = useVariantTranslation(
    projectEnvironmentId,
    contentItemId.itemId,
    contentItemId.variantId,
  );

  return (
    <VariantQuickActionMenu
      buttonContent={
        <>
          <Icons.KopilotAi size={IconSize.S} /> Translate with AI
        </>
      }
      buttonStyle={ButtonStyle.Primary}
      dataUiAction={DataUiAction.AiAction}
      disabledOptionTooltipText="You can only translate from language variants assigned to you."
      menuOptions={menuOptions}
      onVariantSelect={async (languageId) => {
        await startTranslation(languageId, userId).then(onTranslationStarted);
      }}
    />
  );
};

TranslateFromLanguageQuickMenu.displayName = 'TranslateFromLanguageQuickMenu';
