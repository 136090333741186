import PropTypes from 'prop-types';
import { IconName } from '../../../../_shared/constants/iconEnumGenerated.ts';
import { Icon } from '../../../../_shared/uiComponents/Icon/Icon.tsx';
import { OnboardingCard } from './OnboardingCard.tsx';

export enum OnboardingCardsSectionType {
  Business = 'business',
  Technical = 'technical',
}

interface IOnboardingCardsSectionProps {
  readonly type: OnboardingCardsSectionType;
  readonly onClickEditContent: () => void;
  readonly onClickFindContent: () => void;
  readonly onClickPreviewContent: () => void;
  readonly onClickSeeJSON: () => void;
  readonly onClickStartModeling: () => void;
}

const CardsPaneDelimiter = () => (
  <div className="quickstart__onboarding-cards-delimiter">
    <Icon
      className="quickstart__onboarding-cards-delimiter-icon"
      iconName={IconName.ChevronRight}
    />
  </div>
);

CardsPaneDelimiter.displayName = 'CardsPaneDelimiter';

export const OnboardingCardsSection = (props: IOnboardingCardsSectionProps) => (
  <div className="quickstart__onboarding-cards-section">
    {props.type === OnboardingCardsSectionType.Technical ? (
      <>
        <OnboardingCard
          onClick={props.onClickStartModeling}
          title="Start out right with content models"
          text="Content types act as templates for your content. Get to work faster by exploring our example types to get inspired or creating your own types."
          buttonText="Start modeling"
          imageClassName="onboarding-card__image--model-content"
          isButtonPrimary
        />
        <CardsPaneDelimiter />
        <OnboardingCard
          onClick={props.onClickEditContent}
          title="Create and publish your content"
          text="Don’t wait for development to finish – start work on content during the development process. Then publish to put it all together."
          buttonText="Edit and publish content"
          imageClassName="onboarding-card__image--create-content"
        />
        <CardsPaneDelimiter />
        <OnboardingCard
          onClick={props.onClickSeeJSON}
          title="Deploy to any device with our API"
          text="Use dynamic content pulled from a single hub to deliver engaging personalized experiences across the channels you need now and those you’ll need in the future."
          buttonText="See JSON response"
          imageClassName="onboarding-card__image--deliver"
        />
      </>
    ) : (
      <>
        <OnboardingCard
          onClick={props.onClickFindContent}
          title="Find what to work on"
          text="Your content will help you find relevant content to work on."
          buttonText="See your content"
          imageClassName="onboarding-card__image--find-content"
          isButtonPrimary
        />
        <CardsPaneDelimiter />
        <OnboardingCard
          onClick={props.onClickEditContent}
          title="Create and publish your content"
          text="Don’t wait for development to finish – start work on content during the development process. Then publish to put it all together."
          buttonText="Edit and publish content"
          imageClassName="onboarding-card__image--create-business-content"
        />
        <CardsPaneDelimiter />
        <OnboardingCard
          onClick={props.onClickPreviewContent}
          title="Preview your content"
          text="You can easily preview and edit unpublished content directly from Kontent.ai."
          buttonText="Preview your content"
          imageClassName="onboarding-card__image--preview-content"
        />
      </>
    )}
  </div>
);

const propTypes: PropTypeMap<IOnboardingCardsSectionProps> = {
  onClickPreviewContent: PropTypes.func.isRequired,
  onClickFindContent: PropTypes.func.isRequired,
  onClickEditContent: PropTypes.func.isRequired,
  onClickSeeJSON: PropTypes.func.isRequired,
  onClickStartModeling: PropTypes.func.isRequired,
  type: PropTypes.oneOf(Object.values(OnboardingCardsSectionType)).isRequired,
};

OnboardingCardsSection.displayName = 'OnboardingCardsSection';
OnboardingCardsSection.propTypes = propTypes;
