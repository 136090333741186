import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import ImmutablePropTypes from 'immutable-prop-types';
import PropTypes, { ValidationMap } from 'prop-types';
import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  CompiledContentItemElementDataValidationMap,
  ICompiledContentItemElementData,
} from './ICompiledContentItemElement.ts';

export type AssetRenditionReference = {
  readonly id: Uuid;
};

export type AssetReference = {
  readonly id: Uuid;
  readonly renditions: ReadonlyArray<AssetRenditionReference>;
};

export const createAssetReferenceMemoized = memoize.maxN(
  (assetId: Uuid, renditions?: ReadonlyArray<AssetRenditionReference>): AssetReference => ({
    id: assetId,
    renditions: renditions ?? [],
  }),
  1_000,
);

interface IAssetItemElementValue {
  readonly value: Immutable.OrderedMap<Uuid, AssetReference>;
}

export interface IAssetItemElement
  extends IAssetItemElementValue,
    ICompiledContentItemElementData {}

export const EmptyAssetItemElementValue: IAssetItemElementValue = {
  value: Immutable.OrderedMap(),
};

export const EmptyAssetItemElement: IAssetItemElement = {
  elementId: '',
  lastModifiedAt: null,
  lastModifiedBy: null,
  type: ElementType.Asset,
  ...EmptyAssetItemElementValue,
};

export const newAssetItemElement = (params: Partial<IAssetItemElement>): IAssetItemElement => ({
  ...EmptyAssetItemElement,
  ...params,
});

export const AssetItemElementPropTypesShape = PropTypes.exact<ValidationMap<IAssetItemElement>>({
  ...CompiledContentItemElementDataValidationMap,
  value: ImmutablePropTypes.map.isRequired,
});
