import {
  ContentItemId,
  ContentItemIdString,
  MemoizedContentItemId,
} from '../ContentItemId.type.ts';

export const MissingItemId: Uuid = '00000000-0000-0000-0000-000000000000';

export const isMissingItemIdentifier = (itemId: Uuid) => itemId === MissingItemId;

export const stringifyContentItemId = (id: ContentItemId): ContentItemIdString =>
  `${id.itemId}:${id.variantId}`;

// We memoize item IDs in a simple static map, as memoize utils is too slow
// to handle many new memoizations when a lot of items loads in cascade publishing dialog
const memoizedIds = new Map<Uuid, Map<Uuid, MemoizedContentItemId>>();

const ensureValue = <TKey, TValue>(
  parentMap: Map<TKey, TValue>,
  key: TKey,
  factory: () => TValue,
): TValue => {
  const childMap = parentMap.get(key);
  if (childMap) {
    return childMap;
  }

  const newValue: TValue = factory();
  parentMap.set(key, newValue);
  return newValue;
};

export const getMemoizedContentItemId = (itemId: Uuid, variantId: Uuid): MemoizedContentItemId => {
  const variantMap = ensureValue(
    memoizedIds,
    variantId,
    () => new Map<Uuid, MemoizedContentItemId>(),
  );
  return ensureValue(variantMap, itemId, () => ({ itemId, variantId }) as MemoizedContentItemId);
};

export const memoizeContentItemId = (contentItemId: ContentItemId): MemoizedContentItemId =>
  getMemoizedContentItemId(contentItemId.itemId, contentItemId.variantId);
