import { OrderBy } from '../../../../_shared/models/OrderBy.ts';
import { IAssetRendition } from '../../../../data/models/assetRenditions/AssetRendition.ts';
import { IAsset } from '../../../../data/models/assets/Asset.ts';
import { ServerApiErrorCode } from '../../../../repositories/serverModels/ServerApiError.ts';
import {
  AssetLibrary_AssetEditor_ModalClosed,
  AssetLibrary_AssetEditor_SaveFailed,
  AssetLibrary_AssetEditor_SaveFinished,
  AssetLibrary_AssetEditor_SaveStarted,
  AssetLibrary_Asset_CodenameChanged,
  AssetLibrary_Asset_Edit,
  AssetLibrary_Asset_RenditionCreated,
  AssetLibrary_Asset_RenditionUpdated,
  AssetLibrary_Asset_TitleChanged,
  AssetLibrary_Assets_RemoveFailedAsset,
  AssetLibrary_Assets_RemoveTemporaryAssetIdentifiers,
  AssetLibrary_Assets_ReportUploadProgress,
  AssetLibrary_Assets_ShowUnsupportedFileTypeError,
  AssetLibrary_Folders_Navigating,
  AssetLibrary_Folders_Opened,
  AssetLibrary_OrderBy_Changed,
  AssetLibrary_Query_Changed,
  AssetLibrary_Query_FilterCleared,
  AssetLibrary_Route_Left,
  AssetLibrary_ScrollGridState_Changed,
} from '../constants/assetLibraryActionTypes.ts';
import { IAssetLibraryQuery } from '../models/IAssetLibraryQuery.ts';
import { IScrollGridState } from '../stores/IAssetLibraryState.ts';
import { AssetQueryOrigin } from '../utils/assetLibraryQueryUtils.ts';
import { AssetOrderingCode } from '../utils/assetListingOrderingUtils.ts';

export const setEditedAsset = (asset: IAsset) =>
  ({
    type: AssetLibrary_Asset_Edit,
    payload: {
      asset,
    },
  }) as const;

export const assetTitleChanged = (title: string, placeholder: string) =>
  ({
    type: AssetLibrary_Asset_TitleChanged,
    payload: {
      title,
      placeholder,
    },
  }) as const;

export const assetCodenameChanged = (codename: string) =>
  ({
    type: AssetLibrary_Asset_CodenameChanged,
    payload: {
      codename,
    },
  }) as const;

export const queryFilterCleared = () =>
  ({
    type: AssetLibrary_Query_FilterCleared,
  }) as const;

export const queryChanged = (partialQuery: Partial<IAssetLibraryQuery>) =>
  ({
    type: AssetLibrary_Query_Changed,
    payload: {
      partialQuery,
    },
  }) as const;

export const assetScrollGridStateChanged = (state: IScrollGridState) =>
  ({
    type: AssetLibrary_ScrollGridState_Changed,
    payload: { ...state },
  }) as const;

export const removeTemporaryAssetIdentifiers = (oldAssetIds: UuidArray) =>
  ({
    type: AssetLibrary_Assets_RemoveTemporaryAssetIdentifiers,
    payload: {
      oldAssetIds,
    },
  }) as const;

export const saveEditedAssetStart = () =>
  ({
    type: AssetLibrary_AssetEditor_SaveStarted,
  }) as const;

export const saveEditedAssetFinish = (
  asset: IAsset,
  query: IAssetLibraryQuery,
  originalAsset: IAsset | undefined,
) =>
  ({
    type: AssetLibrary_AssetEditor_SaveFinished,
    payload: {
      asset,
      query,
      originalAsset,
    },
  }) as const;

export const saveEditedAssetFail = (
  apiError: ServerApiErrorCode | undefined,
  message: string | null,
) =>
  ({
    type: AssetLibrary_AssetEditor_SaveFailed,
    payload: {
      apiError,
      message,
    },
  }) as const;

export const assetsRouteLeft = (origin: AssetQueryOrigin) =>
  ({
    type: AssetLibrary_Route_Left,
    payload: { origin },
  }) as const;

export const assetEditorModalClosed = () =>
  ({
    type: AssetLibrary_AssetEditor_ModalClosed,
  }) as const;

export const navigatingToFolder = () =>
  ({
    type: AssetLibrary_Folders_Navigating,
  }) as const;

export const assetFolderOpened = (assetFolderId: Uuid) =>
  ({
    type: AssetLibrary_Folders_Opened,
    payload: {
      assetFolderId,
    },
  }) as const;

export const assetRenditionCreated = (rendition: IAssetRendition) =>
  ({
    type: AssetLibrary_Asset_RenditionCreated,
    payload: {
      rendition,
    },
  }) as const;

export const assetRenditionUpdated = (rendition: IAssetRendition) =>
  ({
    type: AssetLibrary_Asset_RenditionUpdated,
    payload: {
      rendition,
    },
  }) as const;

export const assetListingOrderingUpdated = (orderingData: OrderBy<AssetOrderingCode>) =>
  ({
    type: AssetLibrary_OrderBy_Changed,
    payload: {
      orderingData,
    },
  }) as const;

export const removeFailedAsset = (oldAssetId: Uuid) =>
  ({
    type: AssetLibrary_Assets_RemoveFailedAsset,
    payload: {
      oldAssetId,
    },
  }) as const;

export const reportAssetUploadProgress = (assetId: Uuid, sizeTotal: number, sizeUploaded: number) =>
  ({
    type: AssetLibrary_Assets_ReportUploadProgress,
    payload: {
      assetId,
      percentUploaded: Math.round(((sizeUploaded / sizeTotal) * 1000) / 10),
      sizeUploaded,
    },
  }) as const;

export const showUnsupportedAssetFileTypeError = () =>
  ({
    type: AssetLibrary_Assets_ShowUnsupportedFileTypeError,
  }) as const;
