import React, { useCallback } from 'react';
import { repositoryCollection } from '../../../../../_shared/repositories/repositories.ts';
import { ListingRequestOptionsModel } from '../../../../../repositories/utils/ensureAllRequestedDataFetched.ts';
import { TaskWidgetDetailTableLiveContainer } from '../../shared/containers/TaskWidgetDetailTableLiveContainer.tsx';
import { TasksAssignedByYouWidget } from '../components/TasksAssignedByYouWidget.tsx';

const { missionControlRepository } = repositoryCollection;

export const TasksAssignedByYouWidgetLiveContainer: React.FC = () => {
  const tasksFetcher = useCallback(
    (requestOptions: ListingRequestOptionsModel, abortSignal: AbortSignal) =>
      missionControlRepository.getTasksCreatedByCurrentUser(requestOptions, abortSignal),
    [],
  );

  return (
    <TasksAssignedByYouWidget
      tasksFetcher={tasksFetcher}
      WidgetDetail={TaskWidgetDetailTableLiveContainer}
    />
  );
};

TasksAssignedByYouWidgetLiveContainer.displayName = 'TasksAssignedByYouWidgetLiveContainer';
