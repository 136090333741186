import { Dispatch } from '../../../../../../@types/Dispatcher.type.ts';
import { showErrorNotification } from '../../../../../../_shared/actions/sharedActions.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { IContentItemRepository } from '../../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import {
  ServerApiErrorCode,
  tryParseApiError,
} from '../../../../../../repositories/serverModels/ServerApiError.ts';
import { JsonPatchOperation } from '../../../../../../repositories/utils/jsonPatchConstants.ts';
import { itemEditorOperationIds } from '../../../../../contentInventory/content/utils/itemEditorOperationIdUtils.ts';
import {
  CodenameIsNotUniqueErrorMessage,
  CodenameSavingFailedErrorMessage,
} from '../../constants/uiConstants.ts';
import { getContentItemSavingOriginFromRoute } from '../../utils/itemEditingUtils.ts';
import {
  contentItemCodenameSavingNotUniqueError,
  contentItemSavingFailed,
  contentItemSavingFinished,
  contentItemSavingStarted,
} from '../contentItemEditingActions.ts';

interface ISaveContentItemCodenameToServerDependencies {
  readonly contentItemRepository: IContentItemRepository;
}

export const createSaveContentItemCodenameToServerAction =
  (deps: ISaveContentItemCodenameToServerDependencies) =>
  (codename: string) =>
  async (dispatch: Dispatch, getState: () => IStore): Promise<void> => {
    const {
      contentApp: {
        editedContentItem,
        listingUi: { filter, orderBy },
      },
      data: {
        listingContentItems: { usedSearchMethod },
      },
    } = getState();

    if (editedContentItem === null) {
      return Promise.reject('Missing edited item');
    }

    try {
      dispatch(contentItemSavingStarted(itemEditorOperationIds.codename));

      const origin = getContentItemSavingOriginFromRoute(window.location.pathname);
      const itemServerModel = await deps.contentItemRepository.patchItemProperty(
        editedContentItem.id,
        JsonPatchOperation.Replace,
        itemEditorOperationIds.codename,
        codename,
      );

      dispatch(
        contentItemSavingFinished({
          filter,
          orderBy,
          itemPreviouslyUpdatedAt: editedContentItem.lastModifiedAt,
          itemServerModel,
          operationId: itemEditorOperationIds.codename,
          origin,
          usedSearchMethod,
        }),
      );
    } catch (error) {
      const apiError = tryParseApiError(error);

      if (apiError && apiError.code === ServerApiErrorCode.CodenameIsNotUnique) {
        dispatch(contentItemCodenameSavingNotUniqueError());

        const isCodenameEditorVisible = getState().contentApp.editorUi.codenameEditor.isVisible;
        if (!isCodenameEditorVisible) {
          dispatch(showErrorNotification(CodenameIsNotUniqueErrorMessage));
        }
      } else {
        dispatch(showErrorNotification(CodenameSavingFailedErrorMessage));
      }

      dispatch(
        contentItemSavingFailed({
          operationId: itemEditorOperationIds.codename,
          apiError,
        }),
      );
    }
  };
