import React from 'react';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { redirectToAssetsLibrary } from '../actions/thunkSmartLinkActions.ts';
import { SmartLink } from '../components/SmartLink.tsx';

export const AssetLibrarySmartLink: React.FC = () => {
  const dispatch = useDispatch();
  const onMount = () => dispatch(redirectToAssetsLibrary());

  return <SmartLink onMount={onMount} />;
};

AssetLibrarySmartLink.displayName = 'AssetLibrarySmartLink';
