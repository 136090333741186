import classNames from 'classnames';
import React from 'react';
import { Button } from '../../../../_shared/uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../../../_shared/uiComponents/Button/buttonStyle.ts';

export enum PlaceholderImageStyle {
  EmptyProject = 'my-project__placeholder-empty-project-image',
  SampleProject = 'my-project__placeholder-sample-project-image',
  ExperimentalSampleProject = 'my-project__placeholder-experimental-sample-project-image',
}

interface IMyProjectPlaceholderProps {
  readonly imageStyle: PlaceholderImageStyle;
  readonly title: string;
  readonly text: string;
  readonly buttonLabel: string;
  readonly onCreateButtonClick: () => void;
}

export const MyProjectPlaceholder: React.FC<IMyProjectPlaceholderProps> = ({
  onCreateButtonClick,
  imageStyle,
  title,
  text,
  buttonLabel,
}) => (
  <div className="my-project my-project--is-placeholder">
    <div className={classNames('my-project__placeholder-image', imageStyle)} />
    <div className="my-project__placeholder-title">{title}</div>
    <div className="my-project__placeholder-text">{text}</div>
    <Button style={ButtonStyle.Primary} onClick={onCreateButtonClick}>
      {buttonLabel}
    </Button>
  </div>
);

MyProjectPlaceholder.displayName = 'MyProjectPlaceholder';
