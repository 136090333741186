import { DOMRectLike } from '@kontent-ai/DOM';
import { spacingPopupDistance } from '@kontent-ai/component-library/tokens';
import { Direction } from '@kontent-ai/types';
import { TippyProps } from '@tippyjs/react';
import { ArrowSize } from '../../../../../../component-library/components/Dialogs/Popover/ArrowSizeEnum.ts';
import {
  ArrowPosition,
  PopoverIsPointing,
} from '../../../../../_shared/uiComponents/Popover/Popover.tsx';
import { getMax, getMin } from '../../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import {
  DOMSelectionLike,
  getSelectionDirection,
} from '../../../../../_shared/utils/selectionUtils.ts';
import { SpaceTakenByActionButtons } from '../../../../itemEditor/features/ContentItemEditing/constants/uiConstants.ts';
import {
  NodePredicate,
  NumberRange,
  getDistanceFromRange,
  getRelevantRectanglesForPositioning,
  getTargetSelectionRectangle,
  isObjectBlockWrapper,
  isTextBlockContent,
} from './toolbarPositioningUtils.ts';

export const ToolbarOffsetFromContainer = 16;

const toolbarArrowSize = ArrowSize.S;

const offCenterWidthRatio = 0.4;

// Selection rectangles that are next to each other have sometimes little 'holes' between them.

export enum ToolbarAttachedTo {
  TextFromAbove = 'TextFromAbove',
  TextFromBelow = 'TextFromBelow',
  TopTextFromRight = 'TopTextFromRight',
  BottomTextFromRight = 'BottomTextFromRight',
  ViewPortUpperSide = 'ViewPortUpperSide',
  ViewPortLowerSide = 'ViewPortLowerSide',
}

export type VerticalPosition = {
  readonly top: number;
  readonly attachedTo: ToolbarAttachedTo;
};

type HorizontalPosition = {
  readonly left: number;
  readonly orientation: PopoverIsPointing | undefined;
};

export type InlineToolbarPosition = VerticalPosition &
  HorizontalPosition & {
    readonly arrowPosition: ArrowPosition | undefined;
  };

const isNodeRelevantForInlineToolbar: NodePredicate = (node) =>
  isObjectBlockWrapper(node) || isTextBlockContent(node);

const isAttachedToTextVertically = (
  attachedTo: ToolbarAttachedTo,
): attachedTo is ToolbarAttachedTo.TextFromAbove | ToolbarAttachedTo.TextFromBelow =>
  attachedTo === ToolbarAttachedTo.TextFromAbove || attachedTo === ToolbarAttachedTo.TextFromBelow;

/**
 * This function adds position reference needed for corresponding positioning which eliminates scroll lag.
 */
export const adjustPositionToReference = (
  position: InlineToolbarPosition,
  referenceRectangle: DOMRectLike,
): InlineToolbarPosition => {
  return {
    ...position,
    top: position.top - referenceRectangle.top,
    left: position.left - referenceRectangle.left,
  };
};

export const getVerticalPosition = (
  toolbarRectangle: DOMRectLike,
  scrollParentRectangle: DOMRectLike,
  selectionRectangles: ReadonlyArray<DOMRectLike>,
  selectionDirection: Direction,
): VerticalPosition => {
  const mostTopsideSelectionRectangle = getMin(selectionRectangles, (rect) => rect.top);
  const lowestSelectionRectangle = getMax(selectionRectangles, (rect) => rect.bottom);
  const selectionIsOneLine = selectionRectangles.length <= 1;

  const supposedBelowTextToolbarBottom =
    lowestSelectionRectangle.bottom + toolbarRectangle.height + toolbarArrowSize;
  const supposedAboveTextToolbarTop =
    mostTopsideSelectionRectangle.top - toolbarRectangle.height - toolbarArrowSize;
  const supposedRightOfTopTextToolbarTop = mostTopsideSelectionRectangle.top;
  const supposedRightOfBottomTextToolbarBottom = lowestSelectionRectangle.bottom;

  const minAllowedToolbarTop =
    scrollParentRectangle.top + SpaceTakenByActionButtons + ToolbarOffsetFromContainer;
  const maxAllowedToolbarBottom =
    scrollParentRectangle.top +
    scrollParentRectangle.height -
    toolbarRectangle.height -
    ToolbarOffsetFromContainer;

  const isEnoughSpaceAboveSelection = supposedAboveTextToolbarTop >= minAllowedToolbarTop;
  const isEnoughSpaceBelowSelection = supposedBelowTextToolbarBottom <= maxAllowedToolbarBottom;

  const preferUprightPosition = selectionDirection === Direction.Backward || selectionIsOneLine;

  if (preferUprightPosition) {
    if (isEnoughSpaceAboveSelection) {
      return {
        attachedTo: ToolbarAttachedTo.TextFromAbove,
        top: mostTopsideSelectionRectangle.top,
      };
    }
    if (isEnoughSpaceBelowSelection) {
      return {
        attachedTo: ToolbarAttachedTo.TextFromBelow,
        top: lowestSelectionRectangle.bottom,
      };
    }
    if (minAllowedToolbarTop <= supposedRightOfTopTextToolbarTop) {
      return {
        attachedTo: ToolbarAttachedTo.TopTextFromRight,
        top: mostTopsideSelectionRectangle.top + toolbarRectangle.height / 2,
      };
    }
    return {
      attachedTo: ToolbarAttachedTo.ViewPortUpperSide,
      top: minAllowedToolbarTop,
    };
  }

  if (isEnoughSpaceBelowSelection) {
    return {
      attachedTo: ToolbarAttachedTo.TextFromBelow,
      top: lowestSelectionRectangle.bottom,
    };
  }
  if (isEnoughSpaceAboveSelection) {
    return {
      attachedTo: ToolbarAttachedTo.TextFromAbove,
      top: mostTopsideSelectionRectangle.top,
    };
  }
  if (maxAllowedToolbarBottom >= supposedRightOfBottomTextToolbarBottom) {
    return {
      attachedTo: ToolbarAttachedTo.BottomTextFromRight,
      top: lowestSelectionRectangle.bottom - toolbarRectangle.height / 2,
    };
  }

  return {
    attachedTo: ToolbarAttachedTo.ViewPortLowerSide,
    top: maxAllowedToolbarBottom,
  };
};

const getNewArrowPosition = (
  verticalPosition: VerticalPosition,
  toolbarRectangle: DOMRectLike,
  boundingElementRectangle: DOMRectLike,
  selectionRectangles: ReadonlyArray<DOMRectLike>,
  currentArrowPosition: ArrowPosition | undefined,
): ArrowPosition | undefined => {
  if (!isAttachedToTextVertically(verticalPosition.attachedTo)) {
    return ArrowPosition.Middle;
  }

  const selectionTargetRectangle = getTargetSelectionRectangle(
    selectionRectangles,
    verticalPosition.attachedTo === ToolbarAttachedTo.TextFromAbove,
  );
  const selectionCenter = selectionTargetRectangle.left + selectionTargetRectangle.width / 2;

  const leftHandSide: NumberRange = {
    min: boundingElementRectangle.left,
    max: boundingElementRectangle.left + boundingElementRectangle.width * offCenterWidthRatio,
  };
  const rightHandSide: NumberRange = {
    min: boundingElementRectangle.left + boundingElementRectangle.width * (1 - offCenterWidthRatio),
    max: boundingElementRectangle.left + boundingElementRectangle.width,
  };
  const center: NumberRange = {
    min: boundingElementRectangle.left + toolbarRectangle.width / 2,
    max:
      boundingElementRectangle.left + boundingElementRectangle.width - toolbarRectangle.width / 2,
  };

  const intervals = {
    [ArrowPosition.Start]: leftHandSide,
    [ArrowPosition.Middle]: center,
    [ArrowPosition.End]: rightHandSide,
  };

  // Keep the same position if possible:
  const currentOverlap = currentArrowPosition
    ? getDistanceFromRange(intervals[currentArrowPosition], selectionCenter)
    : 1;
  if (currentOverlap <= 0) {
    return currentArrowPosition;
  }

  // Try middle (preferred position):
  const middleOverlap = getDistanceFromRange(intervals[ArrowPosition.Middle], selectionCenter);
  const endOverlap = getDistanceFromRange(intervals[ArrowPosition.End], selectionCenter);
  const startOverlap = getDistanceFromRange(intervals[ArrowPosition.Start], selectionCenter);

  const possiblePositions = [
    { position: ArrowPosition.Middle, overlap: middleOverlap },
    { position: ArrowPosition.End, overlap: endOverlap },
    { position: ArrowPosition.Start, overlap: startOverlap },
  ];

  const fittingPosition = possiblePositions.find((item) => item.overlap <= 0);
  if (fittingPosition) {
    return fittingPosition.position;
  }

  // In case nothing fits, we use the position with the smallest overlap
  const sortedByOverlap = possiblePositions.sort((a, b) => a.overlap - b.overlap);
  const leastOverlappingPosition = sortedByOverlap[0];

  return leastOverlappingPosition?.position;
};

const getHorizontalPosition = (
  verticalPosition: VerticalPosition,
  toolbarRectangle: DOMRectLike,
  editorRectangle: DOMRectLike,
  selectionRectangles: ReadonlyArray<DOMRectLike>,
): HorizontalPosition => {
  if (isAttachedToTextVertically(verticalPosition.attachedTo)) {
    const isUpright = verticalPosition.attachedTo === ToolbarAttachedTo.TextFromAbove;
    const targetSelectionRectangle = getTargetSelectionRectangle(selectionRectangles, isUpright);

    const baseLeft = targetSelectionRectangle.left + targetSelectionRectangle.width / 2;

    return {
      left: baseLeft,
      orientation: isUpright ? PopoverIsPointing.Down : PopoverIsPointing.Up,
    };
  }

  const rightmostEdgeOfSelection = Math.max(...selectionRectangles.map((rect) => rect.right));
  const isEnoughPlaceOnRight =
    rightmostEdgeOfSelection + toolbarRectangle.width + toolbarArrowSize <= editorRectangle.right;

  if (isToolbarDetached(verticalPosition.attachedTo)) {
    return {
      left: isEnoughPlaceOnRight
        ? // We preserve the same space from the selection edge as there would be in case of the arrow
          // to prevent the toolbar to change position upon transition between detached and attached to the text
          rightmostEdgeOfSelection + toolbarArrowSize / 2 + spacingPopupDistance
        : editorRectangle.right - toolbarRectangle.width,
      orientation: undefined,
    };
  }

  return {
    left: isEnoughPlaceOnRight
      ? rightmostEdgeOfSelection
      : editorRectangle.right - toolbarRectangle.width,
    orientation: isEnoughPlaceOnRight ? PopoverIsPointing.Left : undefined,
  };
};

export const isToolbarDetached = (attachedTo: ToolbarAttachedTo) => {
  return (
    attachedTo === ToolbarAttachedTo.ViewPortUpperSide ||
    attachedTo === ToolbarAttachedTo.ViewPortLowerSide
  );
};

export const getInlineToolbarPosition = (
  domSelection: DOMSelectionLike,
  toolbarRectangle: DOMRectLike,
  fitIntoRectangle: DOMRectLike,
  editorRectangle: DOMRectLike,
  scrollContainerRectangle: DOMRectLike,
  currentArrowPosition: ArrowPosition | undefined,
): InlineToolbarPosition | null => {
  if (domSelection.isCollapsed) {
    return null;
  }
  const selectionDirection = getSelectionDirection(domSelection);
  const selectionRepresentingRectangles = getRelevantRectanglesForPositioning(
    domSelection,
    isNodeRelevantForInlineToolbar,
  );
  if (selectionRepresentingRectangles.length === 0) {
    return null;
  }

  const verticalPosition = getVerticalPosition(
    toolbarRectangle,
    scrollContainerRectangle,
    selectionRepresentingRectangles,
    selectionDirection,
  );
  const arrowPosition = getNewArrowPosition(
    verticalPosition,
    toolbarRectangle,
    fitIntoRectangle,
    selectionRepresentingRectangles,
    currentArrowPosition,
  );
  const horizontalPosition = getHorizontalPosition(
    verticalPosition,
    toolbarRectangle,
    editorRectangle,
    selectionRepresentingRectangles,
  );

  const position = {
    ...verticalPosition,
    ...horizontalPosition,
    arrowPosition,
  };

  return position;
};

// In order to keep internal toolbar state within transition between detached toolbar and toolbar with arrow
// we need to keep the component tree consistent.
// That's why we render it via popover even in this case, just reconfiguring its positioning to a fixed position.
export const tippyOptionsForDetachedInlineToolbar: TippyProps = {
  placement: 'right-start',
  popperOptions: {
    modifiers: [
      {
        name: 'flip',
        enabled: false,
      },
    ],
    strategy: 'fixed',
  },
};
