import { AiOperationState } from '../../../../../../../_shared/features/AI/types/AiOperationState.ts';
import { AiError } from '../../../../../../../_shared/features/AI/types/aiErrors.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { getSelectedLanguageId } from '../../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { getCurrentProjectId } from '../../../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { AiTranslateButton as AiTranslateButtonComponent } from '../../../components/elements/subComponents/AiTranslateButton.tsx';
import { getSourceLanguagesForVariantTranslation } from '../../selectors/getSourceLanguagesForVariantTranslation.ts';

type Props = {
  readonly aiOperationState: AiOperationState;
  readonly error: AiError | null;
  readonly perform: (sourceLanguageId: string) => void;
};

export const AiTranslateButton: React.FC<Props> = ({ aiOperationState, error, perform }) => {
  const availableLanguages = useSelector((store) => {
    const collectionId = store.contentApp.editedContentItem?.collectionId ?? null;

    return getSourceLanguagesForVariantTranslation(
      store.data.languages,
      getSelectedLanguageId(store),
      store.contentApp.contentItemVariants,
      store.data.user,
      getCurrentProjectId(store),
      collectionId,
    );
  });

  return (
    <AiTranslateButtonComponent
      availableLanguages={availableLanguages}
      disabled={!availableLanguages.length || aiOperationState === AiOperationState.Pending}
      error={error}
      aiOperationState={aiOperationState}
      perform={perform}
    />
  );
};

AiTranslateButton.displayName = 'AiTranslateButtonContainer';
