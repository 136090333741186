import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import {
  modalDismissed,
  modalWithPropertiesOpened,
} from '../../../../../../_shared/actions/sharedActions.ts';
import { ModalDialogType } from '../../../../../../_shared/constants/modalDialogType.ts';
import { DefaultVariantId } from '../../../../../../_shared/constants/variantIdValues.ts';
import { ContentItemId } from '../../../../../../_shared/models/ContentItemId.type.ts';
import { ActiveCapabilityType } from '../../../../../../_shared/models/activeCapability.type.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { getEditedContentItemDefaultVariant } from '../../../../../../_shared/selectors/getEditedContentItemDefaultVariant.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { hasActiveVariantCapability } from '../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { getEditedContentItemTypeOrThrow } from '../../../../selectors/getEditedContentTypeOrThrow.ts';
import { getModalDialogActionOrigin } from '../../../../selectors/getModalDialogActionOrigin.ts';
import { getFirstLevelChildrenItemIds } from '../../../CascadePublish/selectors/getFirstLevelChildrenItemIds.ts';
import { isPublishingStepAvailableForEditedItemDefaultVariant } from '../../utils/editingActions/availabilityUtils/areAssignmentChangesAvailable.ts';
import { isItemReadyToPublish } from '../../utils/itemValidationUtils.ts';

interface IDeps {
  readonly publishContentItemVariant: (
    id: ContentItemId,
    actionOrigin: ContentItemEditingEventOrigins,
  ) => ThunkPromise;
}

export const publishOneOrOpenModalActionCreator =
  (deps: IDeps) =>
  (id: ContentItemId): ThunkPromise =>
  async (dispatch, getState) => {
    const state = getState();

    const { itemValidationErrors, itemValidationWarnings } = state.contentApp;

    const editedContentItemType = getEditedContentItemTypeOrThrow(state);
    const itemWithDefaultVariant = getEditedContentItemDefaultVariant(state);
    const actionOrigin = getModalDialogActionOrigin(state);
    const canViewDefaultVariant = hasActiveVariantCapability(
      ActiveCapabilityType.ViewContent,
      itemWithDefaultVariant,
    );
    const canPublishDefaultVariant =
      isPublishingStepAvailableForEditedItemDefaultVariant(itemWithDefaultVariant, state) &&
      hasActiveVariantCapability(ActiveCapabilityType.UpdateAssignment, itemWithDefaultVariant);
    const selectedLanguageId = getSelectedLanguageIdOrThrow(state);
    const isEditingDefaultVariant = selectedLanguageId === DefaultVariantId;

    const isReadyToPublish = isItemReadyToPublish({
      canPublishDefaultVariant,
      canViewDefaultVariant,
      editedContentItemType,
      isEditingDefaultVariant,
      itemValidationErrors,
      itemValidationWarnings,
      itemWithDefaultVariant,
    });

    if (!isReadyToPublish) {
      dispatch(modalDismissed());
      return;
    }

    const containsNonLocalizableElements = editedContentItemType.contentElements.some(
      (el) => el.isNonLocalizable,
    );
    if (
      (isEditingDefaultVariant || !containsNonLocalizableElements) &&
      getFirstLevelChildrenItemIds(state).size === 0
    ) {
      return dispatch(deps.publishContentItemVariant(id, actionOrigin));
    }

    dispatch(modalDismissed());
    dispatch(
      modalWithPropertiesOpened({
        dialogType: ModalDialogType.CascadePublishDialog,
        actionOrigin,
      }),
    );
  };
