import { Icons } from '@kontent-ai/component-library/Icons';
import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { IBaseSelectItem, ISelectSection } from '@kontent-ai/component-library/Selects';
import { SimpleStatusWarning } from '@kontent-ai/component-library/SimpleStatus';
import { Collection, alphabetically, createCompare } from '@kontent-ai/utils';
import { useMemo } from 'react';
import { ElasticSearchUnavailableTaxonomyFilterMessage } from '../../../../../../../_shared/constants/uiConstants.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { DataUiCollection } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  onTaxonomySelectionChange,
  toSelectSection,
} from '../../../../../../../_shared/utils/taxonomies/taxonomyUtils.ts';
import { ListingFilterCategory } from '../../../../../shared/components/filter/ListingFilterCategory.tsx';
import { createTaxonomyFilterItemId } from '../../../../../shared/utils/taxonomyFilterItemIdUtils.ts';
import { getTaxonomiesForItemFilterSelector } from '../../utils/taxonomyFilterSelectorUtils.ts';

type Props = {
  readonly displayWarningStatus: boolean;
  readonly onTaxonomySelectionChanged: (groupToTerms: ReadonlyMap<Uuid, ReadonlySet<Uuid>>) => void;
  readonly selectedTaxonomyNodes: ReadonlyMap<Uuid, ReadonlySet<Uuid>>;
};

const hasAnySelectableItem = (taxonomies: readonly ISelectSection<IBaseSelectItem>[]): boolean =>
  !!taxonomies.length && taxonomies.some((taxonomy) => taxonomy.items.length);

export const ItemsTaxonomyFilterSelector = (props: Props) => {
  const taxonomyGroupsById = useSelector((s) => s.data.taxonomyGroups.byId);
  const taxonomyGroups = useMemo(
    () => taxonomyGroupsById.valueSeq().toArray(),
    [taxonomyGroupsById],
  );

  const taxonomies = useMemo(
    () =>
      getTaxonomiesForItemFilterSelector(taxonomyGroups)
        .map(toSelectSection)
        .sort(createCompare({ compare: alphabetically, select: (item) => item.label })),
    [taxonomyGroups],
  );

  const selectedTaxonomyIds = useMemo(() => {
    return Collection.getEntries(props.selectedTaxonomyNodes).flatMap(([groupId, termIds]) =>
      [...termIds].map((termId) => createTaxonomyFilterItemId(groupId, termId)),
    );
  }, [props.selectedTaxonomyNodes]);

  return hasAnySelectableItem(taxonomies) ? (
    <ListingFilterCategory
      title="Taxonomy"
      collectionName={DataUiCollection.Taxonomies}
      renderFilterCategoryStatus={() =>
        props.displayWarningStatus ? (
          <SimpleStatusWarning
            icon={Icons.ExclamationTriangle}
            label="Temporarily limited"
            tooltipText={ElasticSearchUnavailableTaxonomyFilterMessage}
            tooltipPlacement="top"
          />
        ) : null
      }
    >
      <MultiSelect
        placeholder="Select a taxonomy term"
        items={taxonomies}
        selectedItemIds={selectedTaxonomyIds}
        onSelectionChange={(selectedIds) =>
          onTaxonomySelectionChange(selectedIds, props.onTaxonomySelectionChanged)
        }
      />
    </ListingFilterCategory>
  ) : null;
};
