import PropTypes from 'prop-types';
import React from 'react';
import { AutoScrollId } from '../../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { DisabledUsageInItemVariant } from '../../../../../_shared/components/Usages/DisabledUsageInItemVariant.tsx';
import { EnabledUsageInItemVariant } from '../../../../../_shared/components/Usages/EnabledUsageInItemVariant.tsx';
import { UsageItemReference } from '../../../../../_shared/utils/usage/usageUtils.ts';
import { IContentItemVariantReference } from '../../../../itemEditor/models/contentItem/ContentItemVariantReference.ts';

export interface ISingleAssetUsageStateProps {
  readonly assetScrollId: AutoScrollId;
  readonly disabledMessage: string | undefined;
  readonly typeName: string | null;
  readonly variantReferenceBuildLink: (variantReference: IContentItemVariantReference) => string;
}

export interface ISingleAssetUsageDispatchProps {
  readonly onUsageClick: (() => void) | undefined;
}

export interface ISingleAssetUsageOwnProps {
  readonly scrollId?: AutoScrollId;
  readonly usage: UsageItemReference;
}

type Props = ISingleAssetUsageStateProps &
  ISingleAssetUsageDispatchProps &
  ISingleAssetUsageOwnProps;

const propTypes: PropTypesShape<Props> = {
  assetScrollId: PropTypes.object.isRequired,
  disabledMessage: PropTypes.string,
  onUsageClick: PropTypes.func,
  scrollId: PropTypes.object,
  typeName: PropTypes.string,
  usage: PropTypes.object.isRequired,
  variantReferenceBuildLink: PropTypes.func.isRequired,
};

const SingleAssetUsage: React.FC<Props> = (props) => {
  const {
    disabledMessage,
    assetScrollId,
    onUsageClick,
    scrollId,
    typeName,
    usage,
    variantReferenceBuildLink,
  } = props;

  if (disabledMessage) {
    return (
      <DisabledUsageInItemVariant message={disabledMessage} variantReference={usage.primary} />
    );
  }

  return (
    <EnabledUsageInItemVariant
      buildLink={variantReferenceBuildLink}
      onClick={onUsageClick}
      scrollId={scrollId}
      toScrollId={assetScrollId}
      typeName={typeName}
      usage={usage}
    />
  );
};

SingleAssetUsage.displayName = 'SingleAssetUsage';
SingleAssetUsage.propTypes = propTypes;

export { SingleAssetUsage };
