import { EditorState } from 'draft-js';
import { useCallback, useMemo, useState } from 'react';
import {
  IRelativeInsertPosition,
  SmartLinkCommand,
} from '../../../../_shared/models/SmartLinkCommand.ts';
import { RTECommandSource } from '../../../../_shared/models/events/RTECommandEventData.type.ts';
import { isRelativeInsertPosition } from '../../../../_shared/models/utils/smartLinkCommandUtils.ts';
import { ElementReference } from '../../../itemEditor/features/ContentItemEditing/containers/hooks/useItemElementReference.ts';
import { useEditorApi } from '../../editorCore/hooks/useEditorApi.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { RemoveInvalidState } from '../../editorCore/types/Editor.base.type.ts';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { None, WithoutProps } from '../../editorCore/types/Editor.contract.type.ts';
import { DecoratedEditor } from '../../editorCore/types/Editor.decorated.type.ts';
import { Apply, EditorPlugin, Render } from '../../editorCore/types/Editor.plugins.type.ts';
import { EditorChangeReason } from '../../editorCore/types/EditorChangeReason.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import { withDisplayName } from '../../editorCore/utils/withDisplayName.ts';
import { ModalsPlugin } from '../ModalsPlugin.tsx';
import { ContentComponentsPlugin } from '../contentComponents/ContentComponentsPlugin.tsx';
import { LinkedItemsPlugin } from '../linkedItems/LinkedItemsPlugin.tsx';
import { CanDisplayBlockToolbar } from '../toolbars/BlockToolbarPlugin.tsx';
import { EditorSmartLinkApi } from './api/EditorSmartLinkApi.type.ts';
import { editorSmartLinkApi } from './api/editorSmartLinkApi.ts';
import {
  getPossiblyIncorrectPlacementWarningTypeFromSmartLinkCommand,
  getTargetContentModuleBlockKeys,
  smartLinkCommandToRichTextInputCommand,
} from './api/editorSmartLinkUtils.ts';
import { SmartLinkCommandConnector } from './components/SmartLinkCommandConnector.tsx';
import {
  PossiblyIncorrectPlacementWarning,
  PossiblyIncorrectPlacementWarningType,
} from './containers/PossiblyIncorrectPlacementWarning.tsx';

type SmartLinkPluginProps = {
  readonly isPossiblyIncorrectPlacementWarningDismissed?: boolean;
};

export type SmartLinkPlugin = EditorPlugin<
  None,
  SmartLinkPluginProps,
  EditorSmartLinkApi,
  [ModalsPlugin, ContentComponentsPlugin, LinkedItemsPlugin]
>;

type OnCommandExecuting = (editorState: EditorState, command: SmartLinkCommand) => void;

type EditorWithSmartLinkProps = {
  readonly element: ElementReference;
  readonly onCommandExecuting: OnCommandExecuting;
};

const EditorWithSmartLink: DecoratedEditor<
  WithoutProps<SmartLinkPlugin>,
  EditorWithSmartLinkProps
> = ({ baseRender, element, onCommandExecuting, state }) => {
  const { executeExternalAction, handleCommand, getApi } = state;

  const executeSmartLinkCommand = useCallback(
    async (command: SmartLinkCommand) => {
      const { itemId, rootRichTextElementId } = element;

      if (!itemId || !rootRichTextElementId) {
        return;
      }

      const newEditorState = await executeExternalAction(
        (editorState) =>
          getApi().setSmartLinkTargetSelection(editorState, command, itemId, rootRichTextElementId),
        EditorChangeReason.Internal,
      );

      onCommandExecuting(newEditorState, command);

      const inputCommand = smartLinkCommandToRichTextInputCommand(command);
      handleCommand(inputCommand, RTECommandSource.SmartLinkCommand);
    },
    [element, handleCommand, executeExternalAction, getApi, onCommandExecuting],
  );

  return (
    <>
      {baseRender(state)}
      <SmartLinkCommandConnector
        element={element}
        // We need to close all opened modals related to rich text elements before starting to process
        // the newly received command. This includes removing old placeholders.
        onCommandReceived={state.onCloseModal}
        onExecuteCommand={executeSmartLinkCommand}
      />
    </>
  );
};

interface IPossiblyIncorrectPlacementWarningState {
  readonly targetBlockKeys: ReadonlySet<string>;
  readonly insertPosition: IRelativeInsertPosition;
  readonly visible: boolean;
  readonly warningType: PossiblyIncorrectPlacementWarningType;
}

export const useSmartLink: PluginCreator<SmartLinkPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('SmartLinkPlugin', {
        ComposedEditor: (props) => {
          const { isPossiblyIncorrectPlacementWarningDismissed, element } = props;
          const [
            possiblyIncorrectPlacementWarningState,
            setPossiblyIncorrectPlacementWarningState,
          ] = useState<IPossiblyIncorrectPlacementWarningState | null>(null);

          const removeInvalidState: Decorator<RemoveInvalidState> = useCallback(
            (baseRemoveInvalidState) => (editorState) => {
              if (possiblyIncorrectPlacementWarningState) {
                const content = editorState.getCurrentContent();
                const someTargetBlocksRemain = [
                  ...possiblyIncorrectPlacementWarningState.targetBlockKeys,
                ].some((blockKey) => !!content.getBlockForKey(blockKey));
                if (!someTargetBlocksRemain) {
                  setPossiblyIncorrectPlacementWarningState(null);
                }
              }
              baseRemoveInvalidState(editorState);
            },
            [possiblyIncorrectPlacementWarningState],
          );

          const onCommandExecuting: OnCommandExecuting = useCallback(
            (editorState, command) => {
              const { insertPosition } = command.data;
              if (
                !isPossiblyIncorrectPlacementWarningDismissed &&
                isRelativeInsertPosition(insertPosition)
              ) {
                const targetBlockKeys = getTargetContentModuleBlockKeys(
                  editorState.getCurrentContent(),
                  insertPosition.targetId,
                );

                // We can't correctly determine the position for the new component/linked item if the
                // target linked item has duplicates in the same RTE. In this case, we use the last duplicate of
                // the target linked item and insert a new component/linked item relative to it. After that, we
                // display a warning message to the user that the position of the inserted element may not have
                // been correctly determined.
                setPossiblyIncorrectPlacementWarningState(
                  targetBlockKeys.size > 1
                    ? {
                        targetBlockKeys,
                        warningType:
                          getPossiblyIncorrectPlacementWarningTypeFromSmartLinkCommand(command),
                        visible: false, // set to false, because warning should be shown only after the type/item selector is hidden
                        insertPosition,
                      }
                    : null,
                );
              }
            },
            [isPossiblyIncorrectPlacementWarningDismissed],
          );

          const showPendingPossiblyIncorrectPlacementWarning: Decorator<() => void> = useCallback(
            (base) => () => {
              setPossiblyIncorrectPlacementWarningState(
                (prevState) =>
                  prevState && {
                    ...prevState,
                    visible: true,
                  },
              );
              base();
            },
            [],
          );

          const canDisplayBlockToolbar: Decorator<CanDisplayBlockToolbar> = useCallback(
            (baseCanDisplayBlockToolbar) => (editorState) =>
              !possiblyIncorrectPlacementWarningState?.visible &&
              baseCanDisplayBlockToolbar(editorState),
            [possiblyIncorrectPlacementWarningState],
          );

          const render: Decorator<Render<SmartLinkPlugin>> = useCallback(
            (baseRender) => (state) => (
              <EditorWithSmartLink
                element={element}
                baseRender={baseRender}
                onCommandExecuting={onCommandExecuting}
                state={state}
              />
            ),
            [element, onCommandExecuting],
          );

          const renderModalToViewer: Decorator<Render<SmartLinkPlugin>> = useCallback(
            (baseRenderModalToViewer) => (state) => {
              if (possiblyIncorrectPlacementWarningState?.visible) {
                return (
                  <PossiblyIncorrectPlacementWarning
                    insertPosition={possiblyIncorrectPlacementWarningState.insertPosition}
                    warningType={possiblyIncorrectPlacementWarningState.warningType}
                    onClose={() => setPossiblyIncorrectPlacementWarningState(null)}
                  />
                );
              }

              return baseRenderModalToViewer(state);
            },
            [possiblyIncorrectPlacementWarningState],
          );

          const apply: Apply<SmartLinkPlugin> = useCallback(
            (state) => {
              state.canDisplayBlockToolbar.decorate(canDisplayBlockToolbar);
              state.onContentComponentCreated.decorate(
                showPendingPossiblyIncorrectPlacementWarning,
              );
              state.onLinkedItemsInserted.decorate(showPendingPossiblyIncorrectPlacementWarning);
              state.removeInvalidState.decorate(removeInvalidState);
              state.render.decorate(render);
              state.renderModalToViewer.decorate(renderModalToViewer);

              return {};
            },
            [
              canDisplayBlockToolbar,
              removeInvalidState,
              render,
              renderModalToViewer,
              showPendingPossiblyIncorrectPlacementWarning,
            ],
          );

          const { getApiMethods } = useEditorApi<SmartLinkPlugin>(editorSmartLinkApi);

          return useEditorWithPlugin(baseEditor, props, { apply, getApiMethods });
        },
      }),
    [baseEditor],
  );
