import classNames from 'classnames';
import Immutable from 'immutable';
import React from 'react';
import { TabBadgeType } from '../../../../../_shared/components/TabMenu/TabBadgeType.tsx';
import { TabMenu } from '../../../../../_shared/components/TabMenu/TabMenu.tsx';
import { LandmarkAriaLabels } from '../../../../../_shared/constants/landmarkAriaLabels.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IContentGroup } from '../../../../contentInventory/content/models/contentTypeElements/types/ContentGroup.ts';
import { IContentGroupTabOwnProps } from './ContentGroupTab.tsx';

export const ContentGroupTabsCssClass = 'content-group-tabs';

export interface IContentGroupTabsOwnProps {
  readonly hideComments?: boolean;
}

export interface IContentGroupTabsStateProps {
  readonly badgeType: TabBadgeType | undefined;
  readonly canEditContent: boolean;
  readonly contentTypeGroups: Immutable.List<IContentGroup>;
  readonly incompleteElementsPerGroup: Immutable.Map<Uuid, number>;
  readonly selectedContentGroupId: Uuid | null;
  readonly tabItemComponent: React.ComponentType<IContentGroupTabOwnProps>;
}

export interface IContentGroupTabsDispatchProps {
  readonly onSelect: (id: Uuid) => void;
}

export type ContentGroupTabsProps = IContentGroupTabsOwnProps &
  IContentGroupTabsDispatchProps &
  IContentGroupTabsStateProps;

export const ContentGroupTabs: React.FC<ContentGroupTabsProps> = ({
  badgeType,
  canEditContent,
  contentTypeGroups,
  hideComments,
  incompleteElementsPerGroup,
  onSelect,
  selectedContentGroupId,
  tabItemComponent: TabItemComponent,
}) => {
  if (contentTypeGroups.isEmpty()) {
    return null;
  }

  return (
    <TabMenu
      ariaLabel={LandmarkAriaLabels.ContentGroups}
      className={classNames(ContentGroupTabsCssClass, 'tabbed-navigation--in-content-pane', {
        'tabbed-navigation--has-disabled-content': !canEditContent,
      })}
      dataUiAttributes={getDataUiCollectionAttribute(DataUiCollection.ContentGroups)}
    >
      {contentTypeGroups.toArray().map((group) => (
        <TabItemComponent
          key={group.id}
          contentGroup={group}
          selected={selectedContentGroupId === group.id}
          badgeType={incompleteElementsPerGroup.get(group.id) ? badgeType : undefined}
          incompleteElements={incompleteElementsPerGroup.get(group.id) ?? 0}
          hideComments={hideComments}
          onSelect={() => onSelect(group.id)}
        />
      ))}
    </TabMenu>
  );
};

ContentGroupTabs.displayName = 'ContentGroupTabs';
