import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { getNumberElementErrors } from '../../../../../itemEditor/utils/elementErrorCheckers/numberElementErrorChecker.ts';
import { INumberTypeElementData } from '../../../../shared/models/elements/NumberTypeElementData.ts';
import { ITypeElementValidatorDependencies } from '../../../../shared/utils/typeElementValidators/types/ITypeElementValidatorDependencies.type.ts';
import { NumberTypeElementValidationResult } from '../../../../shared/utils/typeElementValidators/types/NumberTypeElementValidationResult.type.ts';
import { mapElementErrorToTypeElementErrorResult } from '../../../utils/typeElementValidators/mapElementErrorToTypeElementErrorResult.ts';
import { createErrorMessagesFromConditionValidationResult } from '../../../utils/typeElementValidators/utils/conditions/createErrorMessagesFromConditionValidationResult.ts';
import {
  ConditionValidationData,
  getConditionValidationResult,
} from '../../../utils/typeElementValidators/utils/conditions/getConditionValidationResult.ts';

function getNumberTypeElementValidationResult(
  { typeElementValidationUtils }: ITypeElementValidatorDependencies,
  typeElement: INumberTypeElementData,
  conditionValidationData: ConditionValidationData,
): NumberTypeElementValidationResult {
  const { getNameValidationResult, getGuidelinesValidationResult } = typeElementValidationUtils;

  const nameValidationResult = getNameValidationResult(typeElement);
  const guidelinesValidationResult = getGuidelinesValidationResult(typeElement);

  const errorMessages: Array<string> = [];
  if (!nameValidationResult.isValid && nameValidationResult.errorMessage) {
    errorMessages.push(nameValidationResult.errorMessage);
  }

  if (!guidelinesValidationResult.isValid && guidelinesValidationResult.errorMessage) {
    errorMessages.push(guidelinesValidationResult.errorMessage);
  }

  const defaultValueResult = getNumberElementErrors({
    value: typeElement.defaultValue,
  });

  errorMessages.push(
    ...mapElementErrorToTypeElementErrorResult(defaultValueResult, typeElement.type).errorMessages,
  );

  const conditionValidationResult = getConditionValidationResult(
    typeElement,
    conditionValidationData,
  );

  return {
    conditionValidationResult,
    errorMessages: [
      ...createErrorMessagesFromConditionValidationResult(conditionValidationResult),
      ...errorMessages,
    ],
    isDefaultValueValid: !defaultValueResult.errors.length,
    isGuidelinesValid: guidelinesValidationResult.isValid,
    isNameValid: nameValidationResult.isValid,
    isRangeValid: true,
  };
}

export const createValidateNumberTypeElementAction =
  (deps: ITypeElementValidatorDependencies) =>
  (typeElement: INumberTypeElementData): ThunkFunction =>
  (dispatch, getState) => {
    const validationResult = getNumberTypeElementValidationResult(
      deps,
      typeElement,
      deps.getConditionValidationData(getState),
    );

    dispatch(deps.typeElementValidated(typeElement.elementId, validationResult));
  };
