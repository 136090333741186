import PropTypes, { ValidationMap } from 'prop-types';
import { AssetCompiledTypeElementServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { getAssetReferenceDomainModel } from '../../../../contentModels/shared/models/elements/AssetTypeElementData.ts';
import { TypeElementConfigurability } from '../../../../contentModels/shared/models/elements/types/elementConfigurabilityOptions.ts';
import { getQuantityUnitOption } from '../../../../contentModels/shared/utils/typeElementDataConverters/utils/typeElementDataConvertersUtils.ts';
import { AssetReference } from '../../../../itemEditor/models/contentItemElements/AssetItemElement.ts';
import { ElementType } from '../ContentItemElementType.ts';
import { defaultAssetFileTypeOption } from '../assetFileTypeOptions.ts';
import { FileSizeUnit } from '../fileSizeOptions.ts';
import { QuantityOption } from '../quantityOptions.ts';
import {
  BaseTypeElementValidationMap,
  EmptyBaseTypeElement,
  IBaseTypeElement,
  ITypeElementWithAssetLimit,
  ITypeElementWithNumberOfItemsLimit,
  NumberOfItemsLimitParametersValidationMap,
  TypeElementWithAssetLimitValidationMap,
  getBaseTypeElementFromServerModel,
} from './types/TypeElement.ts';

interface IAssetTypeElementDataWithDefaultValue {
  readonly defaultValue: ReadonlyArray<AssetReference>;
}

export interface IAssetTypeElement
  extends IBaseTypeElement,
    ITypeElementWithAssetLimit,
    ITypeElementWithNumberOfItemsLimit,
    IAssetTypeElementDataWithDefaultValue {
  readonly type: ElementType.Asset;
}

export const EmptyAssetTypeElement: IAssetTypeElement = {
  ...EmptyBaseTypeElement,
  _configurability: TypeElementConfigurability.Recommended,
  _fileSizeUnitOption: FileSizeUnit.Byte,
  _quantityUnitOption: QuantityOption.Exactly,
  defaultValue: [],
  fileSize: null,
  fileType: defaultAssetFileTypeOption,
  maxHeight: null,
  maxItems: null,
  maxWidth: null,
  minHeight: null,
  minItems: null,
  minWidth: null,
  type: ElementType.Asset,
};

export function getAssetTypeElementFromServerModel(
  serverModel: AssetCompiledTypeElementServerModel,
): IAssetTypeElement {
  const quantityUnitOption = getQuantityUnitOption(serverModel.minItems, serverModel.maxItems);
  const defaultValue = getAssetReferenceDomainModel(serverModel.default?.global.value);

  return {
    ...getBaseTypeElementFromServerModel(serverModel),
    _configurability: EmptyAssetTypeElement._configurability,
    _fileSizeUnitOption: FileSizeUnit.Byte,
    _quantityUnitOption: quantityUnitOption,
    defaultValue,
    fileSize: serverModel.fileSize,
    fileType: serverModel.assetFileType || EmptyAssetTypeElement.fileType,
    maxHeight: serverModel.maxHeight,
    maxItems: serverModel.maxItems ? serverModel.maxItems : EmptyAssetTypeElement.maxItems,
    maxWidth: serverModel.maxWidth,
    minHeight: serverModel.minHeight,
    minItems: serverModel.minItems ? serverModel.minItems : EmptyAssetTypeElement.minItems,
    minWidth: serverModel.minWidth,
    type: ElementType.Asset,
  };
}

const assetReferencePropTypes: PropTypeMap<AssetReference> = {
  id: PropTypes.string.isRequired,
  renditions: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired)
    .isRequired,
};

const AssetTypeElementWithDefaultValueValidationMap: ValidationMap<IAssetTypeElementDataWithDefaultValue> =
  {
    defaultValue: PropTypes.arrayOf(PropTypes.shape(assetReferencePropTypes).isRequired).isRequired,
  };

export const AssetTypeElementPropType = PropTypes.exact<ValidationMap<IAssetTypeElement>>({
  ...BaseTypeElementValidationMap([ElementType.Asset]),
  ...TypeElementWithAssetLimitValidationMap,
  ...NumberOfItemsLimitParametersValidationMap,
  ...AssetTypeElementWithDefaultValueValidationMap,
});
