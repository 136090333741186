import { assert, Collection } from '@kontent-ai/utils';
import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { ContentItemId } from '../../../../../../_shared/models/ContentItemId.type.ts';
import { isConditionalElementsFeatureEnabled } from '../../../../../../_shared/selectors/conditionalElements.ts';
import { isCreatingRenditionsEnabled } from '../../../../../../_shared/selectors/enhancedAssetManagement.ts';
import { emptyValidationResult } from '../../../../../../_shared/utils/validation/ValidationResult.ts';
import { getCurrentProjectPlan } from '../../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import {
  isEditableElement,
  isRichTextTypeElement,
} from '../../../../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { IRichTextItemElement } from '../../../../models/contentItemElements/RichTextItemElement.ts';
import { getElementByIdOrThrow } from '../../../../stores/utils/contentItemElementsUtils.ts';
import { getItemElementValidationResult } from '../../../../utils/getItemElementValidationResult.ts';
import { isElementEditableInVariant } from '../../../../utils/itemElementUtils.ts';
import { areIdsEquivalent } from '../../utils/itemEditingUtils.ts';
import {
  getModifiedComponents,
  processComponentConditionalElements,
} from '../../utils/richStringElementValueChangedUtils.ts';
import { elementValueChanged } from '../contentItemEditingActions.ts';

export type IRichStringElementValueChangedAction = (
  contentItemId: ContentItemId,
  elementId: Uuid,
  newElementData: Pick<Partial<IRichTextItemElement>, '_editorState' | 'contentComponents'>,
) => ThunkFunction;

export const createRichStringElementValueChangedAction =
  (): IRichStringElementValueChangedAction =>
  (contentItemId, elementId, newElementData) =>
  (dispatch, getState) => {
    const state = getState();
    const {
      data: { languages, listingContentItems, assets, assetRenditions, taxonomyGroups },
      contentApp: {
        editedContentItemVariantElements,
        loadedContentItemTypes,
        editedContentItem,
        editedContentItemVariant,
      },
    } = state;

    if (
      !editedContentItem ||
      !editedContentItemVariant ||
      !areIdsEquivalent(contentItemId, editedContentItemVariant.id)
    ) {
      return;
    }

    const editedContentItemType = loadedContentItemTypes.get(
      editedContentItem.editedContentItemTypeId,
    );
    assert(
      editedContentItemType,
      () => 'richStringElementValueChanged: "editedContentItemType" is null',
    );

    const areAssetRenditionsEnabled = isCreatingRenditionsEnabled(getCurrentProjectPlan(state));

    const richTextElement = getElementByIdOrThrow<IRichTextItemElement>(
      elementId,
      editedContentItemVariantElements,
    );
    let updatedElement: IRichTextItemElement = {
      ...richTextElement,
      ...newElementData,
    };

    const typeElement = editedContentItemType.contentElements.find(
      (element) => element.elementId === elementId,
    );
    assert(
      isRichTextTypeElement(typeElement) && isEditableElement(typeElement),
      () => `richStringElementValueChanged: Element ${typeElement?.name} is not rich text`,
    );

    if (isConditionalElementsFeatureEnabled()) {
      const modifiedComponents = getModifiedComponents({
        originalComponents: richTextElement.contentComponents,
        updatedComponents: updatedElement.contentComponents,
      });

      for (const modifiedComponent of modifiedComponents) {
        const processedComponent = processComponentConditionalElements(
          modifiedComponent,
          loadedContentItemTypes,
        );
        updatedElement = {
          ...updatedElement,
          contentComponents: Collection.replace(
            updatedElement.contentComponents,
            processedComponent.id,
            processedComponent,
          ),
        };
      }
    }

    const validationResult = isElementEditableInVariant(
      typeElement,
      editedContentItemVariant.id.variantId,
    )
      ? getItemElementValidationResult(
          typeElement,
          updatedElement,
          loadedContentItemTypes,
          assets.byId,
          assetRenditions.byId,
          taxonomyGroups.byId,
          listingContentItems.byId,
          editedContentItemVariant.id.variantId,
          languages.byId,
          areAssetRenditionsEnabled,
        )
      : emptyValidationResult;

    dispatch(
      elementValueChanged({
        elementData: updatedElement,
        itemId: editedContentItem.id,
        typeElement,
        validationResult,
      }),
    );
  };
