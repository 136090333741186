import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { checkboxGroupSpacingBetweenOptions } from '@kontent-ai/component-library/CheckboxGroup';
import { Stack } from '@kontent-ai/component-library/Stack';
import Immutable from 'immutable';
import React from 'react';
import { QuickTip } from '../../../../../../_shared/components/infos/QuickTip.tsx';
import { documentationLinks } from '../../../../../../_shared/constants/documentationLinks.ts';
import { Capability } from '../../../../../../_shared/utils/permissions/capability.ts';
import { CapabilityOption } from '../../../containers/CapabilityOption.tsx';
import { CapabilityConfigurationCard } from './CapabilityConfigurationCard.tsx';

type Props = {
  readonly areCollectionsEnabled: boolean;
  readonly areSpacesEnabled: boolean;
  readonly isAuditLogEnabled: boolean;
};

const manageDevelopmentSettingsOptions = Immutable.Set([
  Capability.ConfigureCodenames,
  Capability.ConfigurePreviewUrl,
  Capability.ManageWebhooks,
]);

export const ProjectCapabilitiesConfigurationCard: React.FC<Props> = ({
  areCollectionsEnabled,
  areSpacesEnabled,
  isAuditLogEnabled,
}) => (
  <CapabilityConfigurationCard title="Settings" dataUiObjectName="settings-tile">
    <div className="card__content card__content--full-width">
      <Stack spacing={checkboxGroupSpacingBetweenOptions}>
        <CapabilityOption
          optionName="Manage environment"
          checkboxOption={Capability.ManageEnvironments}
        >
          Allows users to copy, delete, and rename this environment.
        </CapabilityOption>
        <CapabilityOption
          optionName="Invite and manage project members"
          checkboxOption={Capability.ManageUsers}
        >
          Allows users to invite and manage other users on the project. This includes inviting and
          assigning users the Project manager role. Any action regarding users with the Project
          manager role affects their permissions in all environments.
        </CapabilityOption>
        <CapabilityOption
          optionName="Manage custom roles"
          checkboxOption={Capability.ManageRoles}
        />
        <CapabilityOption
          optionName="Manage workflow steps"
          checkboxOption={Capability.ConfigureWorkflow}
        />
        {areSpacesEnabled && (
          <CapabilityOption optionName="Manage spaces" checkboxOption={Capability.ManageSpaces} />
        )}
        {areCollectionsEnabled && (
          <CapabilityOption
            optionName="Manage collections"
            checkboxOption={Capability.ManageCollections}
          />
        )}
        <CapabilityOption
          optionName="Manage localization"
          checkboxOption={Capability.ConfigureLanguages}
        />
        <CapabilityOption
          optionName="Create a personal API key"
          checkboxOption={Capability.ManagePersonalApiKey}
        >
          Allows users to create and use personal API key with Management API. The API key
          dynamically inherits the user’s current permissions.
        </CapabilityOption>
        <CapabilityOption
          optionName="Manage development settings"
          checkboxOption={manageDevelopmentSettingsOptions}
        >
          Allows users to configure webhooks, preview URLs and codenames of content items.
        </CapabilityOption>
        {isAuditLogEnabled && (
          <CapabilityOption
            optionName="Access audit log"
            checkboxOption={Capability.AccessAuditLog}
          />
        )}
      </Stack>
    </div>
    <div className="card__content">
      <QuickTip>
        Understand{' '}
        <OutwardLink href={documentationLinks.permissions}>what each permission allows</OutwardLink>{' '}
        to set up your role correctly.
      </QuickTip>
    </div>
  </CapabilityConfigurationCard>
);

ProjectCapabilitiesConfigurationCard.displayName = 'ProjectCapabilitiesConfigurationCard';
