import PropTypes from 'prop-types';
import React from 'react';
import { PaperLevel } from '../../containers/Paper/Paper.tsx';
import {
  colorBackgroundLowEmphasis,
  colorTextDefault,
  colorTextDefaultInverse,
} from '../../tokens/decision/colors.ts';
import { spacingCard } from '../../tokens/decision/spacing.ts';
import { BaseColor } from '../../tokens/quarks/colors.ts';
import { getDataUiComponentAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { CardContextProvider } from './CardContext.tsx';
import { CardBody } from './components/CardBody.tsx';
import { CardCloseButton } from './components/CardCloseButton.tsx';
import { CardContainer } from './components/CardContainer.tsx';
import { CardFooter } from './components/CardFooter.tsx';
import { CardHeadline } from './components/CardHeadline.tsx';
import { CardOverline } from './components/CardOverline.tsx';
import { cardBorderRadius } from './tokens.ts';
import { CardVariant, cardVariants } from './variant.ts';

const cardComponentTypes = ['section', 'article'] as const;
type CardComponentType = (typeof cardComponentTypes)[number];

export type CardProps = Readonly<{
  /** The Card label needs to be provided for accessibility */
  cardLabel: string;
  className?: string;
  /** The component value needs to be section or article */
  component: CardComponentType;
  level?: PaperLevel;
  variant?: CardVariant;
}>;

const propTypes: PropTypeMap<CardProps> = {
  cardLabel: PropTypes.string.isRequired,
  className: PropTypes.string,
  component: PropTypes.oneOf(cardComponentTypes).isRequired,
  level: PropTypes.oneOf(Object.values(PaperLevel)),
  variant: PropTypes.oneOf(cardVariants),
};

const variantToBackgroundColor = {
  default: undefined,
  disabled: colorBackgroundLowEmphasis,
  inverse: BaseColor.Gray80,
} satisfies ReadonlyRecord<CardVariant, BaseColor | undefined>;

const variantToColor = {
  default: colorTextDefault,
  disabled: colorTextDefault,
  inverse: colorTextDefaultInverse,
} satisfies ReadonlyRecord<CardVariant, BaseColor | undefined>;

export const CardWithRef = React.forwardRef<HTMLDivElement, React.PropsWithChildren<CardProps>>(
  (
    {
      cardLabel,
      children,
      className,
      component,
      level = PaperLevel.Default,
      variant = 'default',
      ...otherProps
    },
    forwardedRef,
  ) => (
    <CardContainer
      aria-label={cardLabel}
      backgroundColor={variantToBackgroundColor[variant]}
      borderColor={variant === 'inverse' ? colorBackgroundLowEmphasis : undefined}
      borderRadius={cardBorderRadius}
      className={className}
      color={variantToColor[variant]}
      component={component}
      display="grid"
      level={level}
      padding={spacingCard}
      ref={forwardedRef}
      {...getDataUiComponentAttribute(CardWithRef)}
      {...otherProps}
    >
      <CardContextProvider variant={variant}>{children}</CardContextProvider>
    </CardContainer>
  ),
);

CardWithRef.propTypes = propTypes;
CardWithRef.displayName = 'Card';

const cardComposition = {
  CloseButton: CardCloseButton,
  Overline: CardOverline,
  Headline: CardHeadline,
  Body: CardBody,
  Footer: CardFooter,
} as const;

export const Card = Object.assign(CardWithRef, cardComposition);
