import { Collection } from '@kontent-ai/utils';
import { IUserIdentifier } from '../../../../../../_shared/models/UserIdentifier.ts';
import { IUserInfo } from '../../../../../../_shared/models/UserInfo.ts';
import { ActiveCapabilityType } from '../../../../../../_shared/models/activeCapability.type.ts';
import { getCurrentUser } from '../../../../../../_shared/selectors/getCurrentUser.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { getUsersInfo } from '../../../../../../_shared/utils/usersUtils.ts';
import { ContentItemSidebarEditableSection } from '../../constants/ContentItemSidebarEditableSection.ts';
import { isSidebarSectionSelected } from '../../utils/isSidebarSectionSelected.ts';

interface IContributorsSectionState {
  readonly currentUserId: UserId;
  readonly initialContributors: ReadonlySet<IUserInfo>;
  readonly isEditing: boolean;
  readonly isHidden: boolean;
}

export const getContributorState = (state: IStore): IContributorsSectionState => {
  const {
    contentApp: { editedContentItemVariant },
    data: {
      users: { usersById },
    },
  } = state;

  const canEdit = hasActiveVariantCapabilityForEditedItem(
    ActiveCapabilityType.UpdateAssignment,
    state,
  );
  const initialContributorIdentifiers = editedContentItemVariant
    ? editedContentItemVariant.assignment.assignees
    : new Set<IUserIdentifier>();

  const initialContributors = getUsersInfo(initialContributorIdentifiers, usersById);

  return {
    currentUserId: getCurrentUser(state).userId,
    initialContributors,
    isEditing: isSidebarSectionSelected(state, ContentItemSidebarEditableSection.Contributors),
    isHidden: Collection.isEmpty(initialContributors) && !canEdit,
  };
};
