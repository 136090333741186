export const menuItemStateOptions = [
  'default',
  'disabled',
  'destructive',
  'error',
  'error-selected',
  'selected',
  'readonly',
] as const;

export type MenuItemState = (typeof menuItemStateOptions)[number];
