import { MemoizedContentItemId } from '../../../../../_shared/models/ContentItemId.type.ts';
import { CascadeNodeId } from '../../../../contentInventory/content/stores/IContentAppStoreState.ts';
import {
  ContentEditing_CascadeModal_NodeCollapsed,
  ContentEditing_CascadeModal_NodeDeselected,
  ContentEditing_CascadeModal_NodesSelected,
} from '../constants/cascadeModalActionTypes.ts';

export const nodesSelected = (contentItemIds: ReadonlyArray<MemoizedContentItemId>) =>
  ({
    type: ContentEditing_CascadeModal_NodesSelected,
    payload: {
      contentItemIds,
    },
  }) as const;

export const nodeDeselected = (contentItemId: MemoizedContentItemId) =>
  ({
    type: ContentEditing_CascadeModal_NodeDeselected,
    payload: { contentItemId },
  }) as const;

export const nodeCollapsed = (nodeId: CascadeNodeId) =>
  ({
    type: ContentEditing_CascadeModal_NodeCollapsed,
    payload: {
      nodeId,
    },
  }) as const;
