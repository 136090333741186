import React, { useCallback, useMemo } from 'react';
import { useEditorWithPlugin } from '../../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginCreator } from '../../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../../editorCore/types/Editor.contract.type.ts';
import { Apply, EditorPlugin } from '../../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../../editorCore/utils/decorable.ts';
import { withDisplayName } from '../../../editorCore/utils/withDisplayName.ts';
import { EntityDecoratorProps } from '../../entityApi/api/editorEntityUtils.ts';
import { DisplayLinksPlugin, GetLinkEntityComponent } from '../DisplayLinksPlugin.tsx';
import { isAssetLink } from '../api/LinkEntity.ts';
import { LinkType } from '../api/LinkType.ts';
import { AssetLink } from './containers/AssetLink.tsx';

type DisplayAssetLinksPluginProps = {
  readonly isViewOnly?: boolean;
};

export type DisplayAssetLinksPlugin = EditorPlugin<
  None,
  DisplayAssetLinksPluginProps,
  None,
  [DisplayLinksPlugin]
>;

const AssetLinkEntity: React.FC<
  React.PropsWithChildren<EntityDecoratorProps<DisplayAssetLinksPluginProps>>
> = ({ children, contentState, entityKey, isViewOnly }) => {
  const entity = contentState.getEntity(entityKey);
  if (!isAssetLink(entity)) {
    return children;
  }

  const { assetId } = entity.getData();

  return (
    <AssetLink
      assetId={assetId}
      disabled
      entityKey={entityKey}
      isViewOnly={isViewOnly}
      key={entityKey}
    >
      {children}
    </AssetLink>
  );
};

AssetLinkEntity.displayName = 'AssetLinkEntity';

export const useDisplayAssetLinks: PluginCreator<DisplayAssetLinksPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('DisplayAssetLinksPlugin', {
        ComposedEditor: (props) => {
          const { isViewOnly } = props;

          const apply: Apply<DisplayAssetLinksPlugin> = useCallback(
            (state) => {
              const getLinkEntityComponent: Decorator<
                GetLinkEntityComponent<DisplayAssetLinksPluginProps>
              > = (baseGetLinkEntityComponent) => (linkType) => {
                const customLinkProps: DisplayAssetLinksPluginProps = {
                  isViewOnly,
                };

                switch (linkType) {
                  case LinkType.Asset:
                    return {
                      component: AssetLinkEntity,
                      props: customLinkProps,
                    };

                  default:
                    return baseGetLinkEntityComponent(linkType);
                }
              };

              state.getLinkEntityComponent.decorate(getLinkEntityComponent);

              return {};
            },
            [isViewOnly],
          );

          return useEditorWithPlugin(baseEditor, props, { apply });
        },
      }),
    [baseEditor],
  );
