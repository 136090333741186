import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { ConnectDragSource } from 'react-dnd';
import { DragAction } from '../../../../../../_shared/components/DragDrop/DragAction.tsx';

interface IContentComponentDragAction {
  readonly connectDragSource?: ConnectDragSource;
  readonly disabled?: boolean;
  readonly isCompact?: boolean;
}

const propTypes: PropTypesShape<IContentComponentDragAction> = {
  connectDragSource: PropTypes.func,
  disabled: PropTypes.bool,
  isCompact: PropTypes.bool,
};

export const ContentComponentDragAction: React.FC<IContentComponentDragAction> = ({
  connectDragSource,
  disabled,
  isCompact,
}) => (
  <DragAction
    className={classNames('content-component__action', {
      'content-component__action--is-compact': isCompact,
    })}
    connectDragSource={connectDragSource}
    disabled={disabled}
  />
);

ContentComponentDragAction.displayName = 'ContentComponentDragAction';
ContentComponentDragAction.propTypes = propTypes;
