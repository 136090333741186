import React from 'react';
import { feedbackModalSubmitted, modalDismissed } from '../../actions/sharedActions.ts';
import { UmuxFeedbackModal } from '../../components/UmuxFeedback/UmuxFeedbackModal.tsx';
import { useUserFeedback } from '../../hooks/UserFeedback/useUserFeedback.ts';
import { useDispatch } from '../../hooks/useDispatch.ts';
import { UserFeedbackServerKey } from '../../models/UserPropertiesServerKeys.ts';
import { UserFeedbackSource } from '../../models/events/UserFeedbackEventData.type.ts';
import { helpUsImproveUserPropertiesSelector } from '../../selectors/umuxSurveySelectors.ts';
import { compose } from '../../utils/func/compose.ts';

export const HelpUsImproveSurveyDialog: React.FC = () => {
  const dispatch = useDispatch();

  const { dismissUserFeedback, submitUserFeedback } = useUserFeedback(
    UserFeedbackSource.HelpUsImprove,
    UserFeedbackServerKey,
    helpUsImproveUserPropertiesSelector,
  );

  const close = compose(() => dispatch(modalDismissed()), dismissUserFeedback);
  const submit = async (ease: string, requirements: string, comment: string): Promise<void> => {
    await submitUserFeedback(ease, requirements, comment);
    dispatch(feedbackModalSubmitted());
  };

  return (
    <UmuxFeedbackModal
      dismissFeedbackButtonText="I don’t want to answer now"
      easeTitle="Kontent.ai is easy to use."
      headerContent="What is your overall experience with Kontent.ai?"
      onClose={close}
      onSubmit={submit}
      requirementsTitle="Kontent.ai’s functionality meets my needs."
    />
  );
};

HelpUsImproveSurveyDialog.displayName = 'HelpUsImproveSurveyDialog';
