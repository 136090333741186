import React, { ReactElement } from 'react';
import { FieldPathByValue, useController } from 'react-hook-form';
import { DatetimePicker } from '../../../../../_shared/components/DatetimePicker/DatetimePicker.tsx';
import {
  getMaximalExpiration,
  getMinimalExpiration,
} from '../../../../../_shared/constants/apiKeyExpirations.ts';
import { ApiKeyType } from '../../../../../_shared/models/ApiKeyType.ts';
import { DateTime } from '../../../../../_shared/models/DateTime.ts';
import { HookFormProps } from '../../../../../_shared/types/hookFormProps.ts';
import { LocalTimeZoneId } from '../../../../../_shared/utils/dateTime/timeZoneUtils.ts';
import { DapiKeyFormShape } from '../../validation/dapiKeyValidationConfig.ts';
import { MapiKeyFormShape } from '../../validation/mapiKeyValidationConfig.ts';
import { PersonalMapiKeyFormShape } from '../../validation/personalMapiKeyValidationConfig.ts';

type Props<TFormShape extends DapiKeyFormShape | PersonalMapiKeyFormShape | MapiKeyFormShape> = {
  readonly apiKeyType: ApiKeyType.DAPI | ApiKeyType.MAPIPat | ApiKeyType.MAPI;
  readonly formProps: HookFormProps<TFormShape>;
  readonly name: FieldPathByValue<TFormShape, DateTime>;
};

const ValidatedApiKeyExpirationDatePickerComponent: React.FC = <
  TFormShape extends DapiKeyFormShape | PersonalMapiKeyFormShape | MapiKeyFormShape,
>({
  apiKeyType,
  formProps,
  name,
}: Props<TFormShape>): ReactElement => {
  const minExpirationDate: Date = getMinimalExpiration(apiKeyType);
  const maxExpirationDate: Date = getMaximalExpiration(apiKeyType);

  const {
    field,
    fieldState: { error },
  } = useController({
    control: formProps.control,
    name,
  });

  const onChange = (dateTime: DateTime) => field.onChange(dateTime);

  const expiresAt = field.value as DateTime;

  return (
    <DatetimePicker
      errorMessage={error?.message}
      isFullWidth
      maxValue={maxExpirationDate}
      minValue={minExpirationDate}
      onChange={onChange}
      timeZoneId={LocalTimeZoneId}
      title="Expiration date"
      value={expiresAt.value?.toString() || ''}
      hasError={!!error}
    />
  );
};

ValidatedApiKeyExpirationDatePickerComponent.displayName = 'ValidatedApiKeyExpirationDatePicker';
type ValidatedApiKeyExpirationDatePickerType = <
  TFormShape extends DapiKeyFormShape | PersonalMapiKeyFormShape | MapiKeyFormShape,
>(
  props: Props<TFormShape>,
) => ReactElement;

export const ValidatedApiKeyExpirationDatePicker =
  ValidatedApiKeyExpirationDatePickerComponent as ValidatedApiKeyExpirationDatePickerType;
