import { Column } from '@kontent-ai/component-library/Row';
import {
  AttachVisibleItemRefCallback,
  SliceDirection,
  SliceFrom,
  useSliceOverflowingItems,
} from '@kontent-ai/component-library/hooks';
import { px } from '@kontent-ai/component-library/tokens';
import { CLPropTypes } from '@kontent-ai/component-library/validators';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { BreadcrumbsContext } from '../BreadcrumbsContext.tsx';
import { breadcrumbsHeight } from '../decisionTokens.ts';
import { BreadcrumbsItem, IBreadcrumbsItem, breadcrumbsItemPropTypes } from './BreadcrumbsItem.tsx';
import { BreadcrumbsVerticalMenu } from './BreadcrumbsVerticalMenu.tsx';

export interface IBreadcrumbsItemsProps {
  readonly containerRef: React.RefObject<HTMLElement>;
  readonly items: ReadonlyArray<IBreadcrumbsItem>;
  readonly children?: never;
}

const propTypes: PropTypeMap<IBreadcrumbsItemsProps> = {
  containerRef: PropTypes.any.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(breadcrumbsItemPropTypes).isRequired).isRequired,
  children: CLPropTypes.never,
};

const StyledNavigation = styled(Column)`
  height: ${px(breadcrumbsHeight)};
  min-width: 0;
  display: flex;
  align-items: center;
`;

const StyledList = styled.ol`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
`;

interface IShortenedBreadcrumbsItems {
  readonly attachVisibleItemRef: AttachVisibleItemRefCallback;
  readonly visibleItems: ReadonlyArray<IBreadcrumbsItem>;
  readonly hiddenItems: ReadonlyArray<IBreadcrumbsItem>;
}

const ShortenedBreadcrumbsItems: React.FC<IShortenedBreadcrumbsItems> = ({
  attachVisibleItemRef,
  visibleItems,
  hiddenItems,
}) => (
  <>
    {!!hiddenItems.length && <BreadcrumbsVerticalMenu items={hiddenItems} />}
    {visibleItems.map((item: IBreadcrumbsItem, index) => (
      <BreadcrumbsItem
        key={item.to}
        label={item.label}
        to={item.to}
        ref={attachVisibleItemRef(index)}
      />
    ))}
  </>
);

const getHideableItems = (
  items: ReadonlyArray<IBreadcrumbsItem>,
): ReadonlyArray<IBreadcrumbsItem> => items.slice(1, -1);

export const BreadcrumbsItems = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<IBreadcrumbsItemsProps>
>(({ containerRef, items, ...restProps }, forwardedRef) => {
  const hideableItems = React.useMemo(() => getHideableItems(items), [items]);
  const firstItem = items[0];
  const lastItem = items.length > 1 ? items[items.length - 1] : null;

  const { attachVisibleItemRef, visibleItems, hiddenItems } = useSliceOverflowingItems(
    hideableItems,
    containerRef,
    SliceFrom.Beginning,
    SliceDirection.Horizontal,
  );

  const context = React.useContext(BreadcrumbsContext);
  if (context === undefined) {
    throw new Error('Breadcrumbs.Items must be used within a Breadcrumbs component');
  }
  const { allowEllipsis, setAllowEllipsis } = context;

  useEffect(() => {
    setAllowEllipsis(visibleItems.length === 0);
  }, [setAllowEllipsis, visibleItems.length]);

  return (
    <StyledNavigation
      aria-label="Breadcrumb"
      component="nav"
      ref={forwardedRef}
      width={allowEllipsis ? 'fit-content' : 'content'}
      {...restProps}
    >
      <StyledList>
        {firstItem && (
          <BreadcrumbsItem
            label={firstItem.label}
            to={firstItem.to}
            lastItem={!lastItem}
            allowEllipsis={allowEllipsis}
          />
        )}

        <ShortenedBreadcrumbsItems
          {...{
            attachVisibleItemRef,
            visibleItems,
            hiddenItems,
          }}
        />

        {lastItem && (
          <BreadcrumbsItem
            label={lastItem.label}
            to={lastItem.to}
            lastItem
            allowEllipsis={allowEllipsis}
          />
        )}
      </StyledList>
    </StyledNavigation>
  );
});

BreadcrumbsItems.displayName = 'BreadcrumbsItems';
BreadcrumbsItems.propTypes = propTypes;
