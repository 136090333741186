import PropTypes from 'prop-types';
import React from 'react';
import { Box } from '../../layout/Box/Box.tsx';
import { StyledChildren } from './components/StyledChildren.tsx';
import { StyledSkeleton } from './components/StyledSkeleton.tsx';
import { SkeletonType, SkeletonTypeToSkeletonBorderRadiusMap, skeletonTypes } from './types.ts';

const wrappingSkeletonWidths = ['max-content', 'auto'] as const;
type WrappingSkeletonWidth = (typeof wrappingSkeletonWidths)[number];

type WrappingSkeletonProps = Readonly<{
  isLoading: boolean;
  type: SkeletonType;
  width?: WrappingSkeletonWidth;
  children: React.ReactNode;
}>;

const skeletonPropTypes: PropTypeMap<WrappingSkeletonProps> = {
  isLoading: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(skeletonTypes).isRequired,
  width: PropTypes.oneOf(wrappingSkeletonWidths),
  children: PropTypes.node.isRequired,
};

export const WrappingSkeleton = React.forwardRef<HTMLSpanElement, WrappingSkeletonProps>(
  ({ isLoading, type, width = 'max-content', children }, ref) =>
    isLoading ? (
      <StyledSkeleton
        $width={width}
        borderRadius={SkeletonTypeToSkeletonBorderRadiusMap[type]}
        ref={ref}
      >
        <StyledChildren>{children}</StyledChildren>
      </StyledSkeleton>
    ) : (
      <Box component="span" display="block" ref={ref}>
        {children}
      </Box>
    ),
);

WrappingSkeleton.displayName = 'WrappingSkeleton';
WrappingSkeleton.propTypes = skeletonPropTypes;
