import { InputState } from '@kontent-ai/component-library/Input';
import { ISelectItem } from '@kontent-ai/component-library/Selects';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import React, { ComponentProps, ReactElement } from 'react';
import { FieldPathByValue, FieldValues, useController } from 'react-hook-form';
import { HookFormProps } from '../../types/hookFormProps.ts';
import { showFieldError } from '../../utils/validation/showFieldError.ts';

type Props<TFormShape extends FieldValues, TItem extends ISelectItem<TItem>> = ComponentProps<
  typeof SingleSelect<TItem>
> & {
  readonly formProps: HookFormProps<TFormShape>;
  readonly name: FieldPathByValue<TFormShape, string | null>;
};

export const ValidatedSingleSelect = <
  TFormValues extends FieldValues,
  TItem extends ISelectItem<TItem>,
>(
  props: Props<TFormValues, TItem>,
): ReactElement | null => {
  const { formProps, name, items, label, inputState, caption, ...otherProps } = props;

  const { field, fieldState, formState } = useController({
    control: formProps.control,
    name,
  });

  if (!items) {
    return null;
  }

  const { error } = fieldState;
  const showError = showFieldError(formState, error);

  return (
    <SingleSelect<TItem>
      label={label}
      ref={field.ref}
      onSelectionChange={field.onChange}
      selectedItemId={field.value}
      items={items}
      inputState={showError ? InputState.Alert : inputState}
      caption={showError ? error?.message : caption}
      {...otherProps}
    />
  );
};

(ValidatedSingleSelect as React.FC).displayName = 'ValidatedSingleSelect';
