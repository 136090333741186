import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';

interface IInitApiKeysSettingsDependencies {
  readonly loadApiKeysSettingsData: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadProjects: (abortSignal?: AbortSignal) => ThunkPromise;
}

export const initApiKeysSettingsCreator =
  (deps: IInitApiKeysSettingsDependencies) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    await Promise.all([
      dispatch(deps.loadApiKeysSettingsData(abortSignal)),
      dispatch(deps.loadProjects(abortSignal)),
    ]);
  };
