import { Box } from '@kontent-ai/component-library/Box';
import classNames from 'classnames';
import { MouseEvent, ReactNode } from 'react';
import { IconName } from '../../constants/iconEnumGenerated.ts';
import {
  DataUiAction,
  DataUiElement,
  DataUiWorkflowAction,
  getDataAttributeProps,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { IForwardedRefProps, forwardRef } from '../../utils/forwardedRefProps.tsx';
import { Icon } from '../Icon/Icon.tsx';
import { ButtonStyle } from './buttonStyle.ts';

export enum ButtonSize {
  // no size/default -> M
  S = 'btn--s', // Used in the notification bar
  M = 'btn--m',
  Block = 'btn--block',
}

type ButtonType = 'submit' | 'reset' | 'button';

type Props = IForwardedRefProps<HTMLDivElement> & {
  readonly autoFocus?: boolean;
  readonly className?: string;
  readonly customButtonClassName?: string;
  readonly dataUiAction?: DataUiAction | DataUiWorkflowAction;
  readonly disabled?: boolean;
  readonly disableTabulator?: boolean;
  readonly hasLoader?: boolean;
  readonly isActive?: boolean;
  readonly name?: string;
  readonly onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  readonly size?: ButtonSize;
  readonly style: ButtonStyle;
  readonly title?: string;
  readonly type?: ButtonType;
  readonly children?: ReactNode;
};

const Button = ({ style, ...safeProps }: Props) => {
  return (
    // The div is there for the tooltip to work on disabled buttons
    <div
      className={classNames('btn-wrapper', classNames(safeProps.className), {
        'btn-wrapper--block': safeProps.size === ButtonSize.Block,
      })}
      ref={safeProps.forwardedRef}
    >
      <button
        autoFocus={safeProps.autoFocus}
        className={classNames('btn', style, safeProps.customButtonClassName, safeProps.size, {
          active: safeProps.isActive,
        })}
        disabled={safeProps.disabled}
        name={safeProps.name}
        onClick={safeProps.onClick}
        tabIndex={safeProps.disableTabulator ? -1 : undefined}
        title={safeProps.title}
        type={safeProps.type}
        {...getDataAttributeProps(safeProps)}
      >
        {safeProps.hasLoader && (
          <Icon
            iconName={IconName.Spinner}
            className={classNames('spin-500 btn__icon', {
              'btn__icon--loader': safeProps.size !== ButtonSize.S,
            })}
            dataAttribute={getDataUiElementAttribute(DataUiElement.SavingInProgressButtonIcon)}
          />
        )}
        <Box component="span" display="flex" flexGrow={1} justifyContent="center">
          {safeProps.children}
        </Box>
      </button>
    </div>
  );
};

/**
 * @deprecated Use Button from @kontent-ai/component-library instead
 * */
const ButtonRFC = forwardRef(Button);
export { ButtonRFC as Button };
