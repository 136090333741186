import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../../../../@types/Action.type.ts';
import { Shared_ModalDismissed } from '../../../../../../_shared/constants/sharedActionTypes.ts';
import { MemoizedContentItemId } from '../../../../../../_shared/models/ContentItemId.type.ts';
import {
  Content_Editing_PublishContentItemVariantStarted,
  Content_Editing_ScheduledPublishingStarted,
} from '../../../../constants/editorActionTypes.ts';
import {
  ContentEditing_CascadeModal_NodeDeselected,
  ContentEditing_CascadeModal_NodesSelected,
  ContentEditing_CascadeModal_PublishingStarted,
  ContentEditing_CascadeModal_SchedulingStarted,
} from '../../../../features/CascadePublish/constants/cascadeModalActionTypes.ts';

const initialState: ReadonlySet<MemoizedContentItemId> = new Set();

// always use getSelectedItems to obtain the selection
// we need to post-filter the dependencies that could have been removed in the meantime
export const selectedItems = (
  state = initialState,
  action: Action,
): ReadonlySet<MemoizedContentItemId> => {
  switch (action.type) {
    case ContentEditing_CascadeModal_NodeDeselected:
      return Collection.remove(state, action.payload.contentItemId);

    case ContentEditing_CascadeModal_NodesSelected:
      return Collection.addMany(state, action.payload.contentItemIds);

    case Shared_ModalDismissed:
    case Content_Editing_PublishContentItemVariantStarted:
    case Content_Editing_ScheduledPublishingStarted:
    case ContentEditing_CascadeModal_PublishingStarted:
    case ContentEditing_CascadeModal_SchedulingStarted:
      return initialState;

    default:
      return state;
  }
};
