import Immutable from 'immutable';
import React from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getEditedContentItem } from '../../../../../_shared/selectors/getEditedContentItem.ts';
import { getSelectedLanguageId } from '../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { TimelineItem } from '../../../models/revisions/TimeLineItem.ts';
import { getAreAnyContentGroupsVisible } from '../../../selectors/getAreAnyContentGroupsVisible.ts';
import { getEditedContentItemType } from '../../../selectors/getEditedContentItemType.ts';
import {
  getElementsChangedBy,
  getSelectedContentGroupIdFromStateOrFirst,
  getTypeElementsInContentGroup,
} from '../../../stores/utils/contentItemElementsUtils.ts';
import { CreateContentGroupTabsId } from '../../ContentItemEditing/utils/contentGroupTabsId.ts';
import { RevisionItemElements as RevisionItemElementsComponent } from '../components/RevisionItemElements.tsx';

const getDisplayElementChangesSince = (
  entryTimeline: Immutable.List<TimelineItem>,
  selectedId?: Uuid,
  originalId?: Uuid,
): DateTimeStamp | null => {
  const indexSelected = entryTimeline.findIndex((e) => e?.itemId === selectedId);
  const indexOriginal = entryTimeline.findIndex((e) => e?.itemId === originalId);

  const isSingleRevision = indexSelected < 0 || indexOriginal < 0;
  if (isSingleRevision) {
    const previousIndex = Math.max(indexSelected, indexOriginal) + 1;
    return previousIndex < 0 ? null : entryTimeline.get(previousIndex)?.time ?? null;
  }

  const isAdjacentRevisionDiff = Math.abs(indexOriginal - indexSelected) === 1;
  return isAdjacentRevisionDiff
    ? entryTimeline.get(Math.max(indexSelected, indexOriginal))?.time ?? null
    : null;
};

export const RevisionItemElements: React.FC = () => {
  const areAnyContentGroupsVisible = useSelector(getAreAnyContentGroupsVisible);

  const editedContentItemType = useSelector(getEditedContentItemType);
  const editedContentItem = useSelector(getEditedContentItem);

  const selectedRevision = useSelector((state) => state.contentApp.selectedRevision);
  const selectedRevisionElements = useSelector(
    (state) => state.contentApp.selectedRevisionElements,
  );
  const entryTimeline = useSelector((state) => state.contentApp.entryTimeline);

  const selectedComparisonRevision = useSelector(
    (state) => state.contentApp.selectedComparisonRevision,
  );
  const selectedComparisonRevisionElements = useSelector(
    (state) => state.contentApp.selectedComparisonRevisionElements,
  );

  const selectedLanguageId = useSelector(getSelectedLanguageId);
  const originalIsNewer =
    !!selectedRevision &&
    !!selectedComparisonRevision &&
    selectedRevision.time < selectedComparisonRevision.time;

  const elementsTime = originalIsNewer ? selectedComparisonRevision?.time : selectedRevision?.time;
  const originalTime = originalIsNewer ? selectedRevision?.time : selectedComparisonRevision?.time;

  const elements =
    originalIsNewer && selectedComparisonRevisionElements
      ? selectedComparisonRevisionElements
      : selectedRevisionElements;
  const originalElements = originalIsNewer
    ? selectedRevisionElements
    : selectedComparisonRevisionElements;

  const typeElementsInContentGroup = useSelector((state) => {
    if (!editedContentItemType) {
      return null;
    }

    const contentGroupTabsId = CreateContentGroupTabsId.forContentItem(editedContentItem.id);
    const selectedContentGroupId = getSelectedContentGroupIdFromStateOrFirst(
      contentGroupTabsId,
      editedContentItemType.contentGroups,
      state,
    );

    return getTypeElementsInContentGroup(
      editedContentItemType.contentElements,
      selectedContentGroupId,
    );
  });

  if (
    !selectedLanguageId ||
    !editedContentItemType ||
    !typeElementsInContentGroup ||
    !entryTimeline ||
    !elements ||
    !elementsTime
  ) {
    return null;
  }

  const displayElementChangesSince = getDisplayElementChangesSince(
    entryTimeline,
    selectedRevision?.itemId,
    selectedComparisonRevision?.itemId,
  );
  const elementsChangedBy =
    displayElementChangesSince &&
    elements &&
    getElementsChangedBy(elements, displayElementChangesSince);

  return (
    <RevisionItemElementsComponent
      areAnyContentGroupsVisible={areAnyContentGroupsVisible}
      contentItemId={editedContentItem.id ?? ''}
      contentType={editedContentItemType}
      elements={elements}
      elementsChangedBy={elementsChangedBy ? elementsChangedBy : undefined}
      elementsSnapshotTime={elementsTime}
      originalElements={originalElements}
      originalSnapshotTime={originalTime ?? null}
      selectedLanguageId={selectedLanguageId}
      typeElementsInContentGroup={typeElementsInContentGroup}
    />
  );
};

RevisionItemElements.displayName = 'RevisionItemElements';
