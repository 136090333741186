import PropTypes, { ValidationMap } from 'prop-types';
import { Validator } from 'react';
import { DateTime, createDateTime } from '../../../../_shared/models/DateTime.ts';
import { DefaultDisplayTimeZoneId } from '../../../../_shared/utils/dateTime/timeZoneUtils.ts';
import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  CompiledContentItemElementDataValidationMap,
  ICompiledContentItemElementData,
} from './ICompiledContentItemElement.ts';

type DateTimeItemElementValue = {
  readonly _dateTime: DateTime;
  readonly displayTimeZone: string;
  readonly value: DateTimeStamp;
};

export type DateTimeItemElement = DateTimeItemElementValue & ICompiledContentItemElementData;

export const EmptyDateTimeItemElementValue: DateTimeItemElementValue = {
  _dateTime: createDateTime(),
  displayTimeZone: DefaultDisplayTimeZoneId,
  value: '',
};

export const EmptyDateTimeItemElement: DateTimeItemElement = {
  elementId: '',
  lastModifiedAt: null,
  lastModifiedBy: null,
  type: ElementType.DateTime,
  ...EmptyDateTimeItemElementValue,
};

export const newDateTimeElement = (params: Partial<DateTimeItemElement>): DateTimeItemElement => ({
  ...EmptyDateTimeItemElement,
  ...params,
});

export const DateTimeItemElementPropTypes = PropTypes.exact<ValidationMap<DateTimeItemElement>>({
  ...CompiledContentItemElementDataValidationMap,
  _dateTime: PropTypes.object.isRequired as Validator<DateTime>,
  value: PropTypes.string.isRequired,
  displayTimeZone: PropTypes.string.isRequired || null,
});
