import { isElement } from '@kontent-ai/DOM';
import { Button } from '@kontent-ai/component-library/Button';
import { InputState } from '@kontent-ai/component-library/Input';
import { useObserveElementPresence } from '@kontent-ai/hooks';
import React, { useCallback, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useForm } from 'react-hook-form';
import { ValidatedInput } from '../../../../../_shared/components/input/ValidatedInput.tsx';
import { getDefaultExpiration } from '../../../../../_shared/constants/apiKeyExpirations.ts';
import { ValidationConstants } from '../../../../../_shared/constants/validationConstants.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { ApiKeyType } from '../../../../../_shared/models/ApiKeyType.ts';
import {
  DataUiAction,
  DataUiInput,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { createFormValidationResolver } from '../../../../../_shared/utils/validation/createFormValidationResolver.ts';
import { IProjectContainerActiveUser } from '../../../../../data/models/projectContainerActiveUsers/ProjectContainerActiveUser.ts';
import { UntitledManagementApiKeyName } from '../../../../environmentSettings/roles/constants/UIConstants.ts';
import { apiKeyFormSavingInterrupted } from '../../actions/apiKeysActions.ts';
import { ApiKeyDetailLayout } from '../../components/ApiKeyDetail/ApiKeyDetailLayout.tsx';
import { ApiKeyDetailToolbarSaveButtonElementId } from '../../components/ApiKeyDetail/ApiKeyDetailToolbarActions.tsx';
import { HandleUnsavedApiKeyFormOnNavigation } from '../../components/ApiKeyDetail/HandleUnsavedApiKeyFormOnNavigation.tsx';
import { MapiKeyDetail } from '../../components/ApiKeyDetail/MapiKeyDetail.tsx';
import { SharedWithUsersSelector } from '../../components/ApiKeyDetail/SharedWithUsersSelector.tsx';
import { ValidatedApiKeyExpirationDatePicker } from '../../components/ApiKeyDetail/ValidatedApiKeyExpirationDatePicker.tsx';
import { ValidatedPermissionsCheckboxes } from '../../components/ApiKeyDetail/ValidatedPermissionsCheckboxes.tsx';
import { ApiKey } from '../../models/ApiKey.ts';
import { MapiKeyCapability } from '../../models/MapiKeyCapability.type.ts';
import { ApiKeyActionStatus } from '../../reducers/IApiKeysAppStoreState.type.ts';
import { hiddenEnvironmentsOptionId } from '../../selectors/getHiddenEnvironmentOption.ts';
import {
  AllEnvironmentsTagId,
  EnvironmentOption,
} from '../../selectors/getSelectedEnvironmentOptions.ts';
import {
  MapiKeyFormShape,
  mapiKeyValidationConfig,
} from '../../validation/mapiKeyValidationConfig.ts';
import { ValidatedMapiKeyEnvironmentsSelector } from './ValidatedMapiKeyEnvironmentsSelector.tsx';

const getDisabledTooltipForEnvironmentSelector = (
  isProjectManager: boolean,
  canKeyManageEnvironments: boolean,
): string => {
  if (!isProjectManager) {
    return 'Only Project managers can change this limitation.';
  }
  if (canKeyManageEnvironments) {
    return 'To limit the API key to specific environments, deselect the Manage environments permission.';
  }
  return '';
};

const isKeyTargetedToAllEnvironments = (selectedEnvironmentIds: ReadonlyArray<Uuid>): boolean => {
  return selectedEnvironmentIds.includes(AllEnvironmentsTagId);
};

export type MapiKeyDetailFormProps = {
  readonly allEnvironmentOptions: ReadonlyArray<EnvironmentOption>;
  readonly apiKey: ApiKey;
  readonly apiKeyActionStatus: ApiKeyActionStatus;
  readonly isProjectManager: boolean;
  readonly projectContainerActiveUsers: ReadonlyArray<IProjectContainerActiveUser>;
  readonly onAlertDismiss: () => void;
  readonly onRegenerate?: () => void;
  readonly onSave: (
    updatedKey: MapiKeyFormShape,
    isFromUnsavedChangesDialog: boolean,
  ) => Promise<void>;
};

export const MapiKeyDetailForm: React.FC<MapiKeyDetailFormProps> = ({
  allEnvironmentOptions,
  apiKey,
  apiKeyActionStatus,
  isProjectManager,
  projectContainerActiveUsers,
  onAlertDismiss,
  onRegenerate,
  onSave,
}) => {
  const dispatch = useDispatch();

  const { current: apiKeyDetailToolbarSaveButtonContainerElement } = useObserveElementPresence(
    ApiKeyDetailToolbarSaveButtonElementId,
  );

  const defaultExpirationDate = useMemo(() => getDefaultExpiration(ApiKeyType.MAPI), []);
  const formProps = useForm<MapiKeyFormShape>({
    defaultValues: {
      environments: [],
      expiresAt: { value: defaultExpirationDate, isValid: true },
      name: '',
      permissions: {
        canManageAssets: false,
        canManageContent: false,
        canManageContentModel: false,
        canManageEnvironmentSettings: false,
        canManageEnvironments: false,
        canReadAssets: false,
        canReadContent: false,
      },
      sharedWithUsers: [],
    },
    resolver: createFormValidationResolver(mapiKeyValidationConfig, {
      projectContainerActiveUsers,
    }),
  });

  const {
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = formProps;
  const { name: keyName, permissions, environments: selectedKeyEnvironments } = watch();

  const onSaveChanges = handleSubmit(
    async (values): Promise<void> => {
      reset(values);
      await onSave(values, false);
    },
    (): void => {
      dispatch(apiKeyFormSavingInterrupted());
    },
  );

  const onSaveFromUnsavedChangesDialog = async (): Promise<boolean> => {
    let isValid: boolean = true;

    await handleSubmit(
      async (values): Promise<void> => {
        reset(values);
        await onSave(values, true);
      },
      (): void => {
        isValid = false;
        dispatch(apiKeyFormSavingInterrupted());
      },
    )();

    return isValid;
  };

  const propagateApiKeyChangesToForm = useCallback(
    () =>
      reset({
        environments: apiKey.hasAccessToAllEnvironments
          ? [AllEnvironmentsTagId]
          : isProjectManager
            ? apiKey.environments
            : [...apiKey.environments, hiddenEnvironmentsOptionId],
        expiresAt: {
          value: apiKey.expiresAt || defaultExpirationDate,
          isValid: true,
        },
        name: apiKey.name,
        permissions: {
          canManageAssets: apiKey.mapiKeyCapabilities.includes(MapiKeyCapability.ManageAssets),
          canManageContent: apiKey.mapiKeyCapabilities.includes(MapiKeyCapability.ManageContent),
          canManageContentModel: apiKey.mapiKeyCapabilities.includes(
            MapiKeyCapability.ManageContentModel,
          ),
          canManageEnvironmentSettings: apiKey.mapiKeyCapabilities.includes(
            MapiKeyCapability.ManageEnvironmentSettings,
          ),
          canManageEnvironments: apiKey.mapiKeyCapabilities.includes(
            MapiKeyCapability.ManageEnvironments,
          ),
          canReadAssets: apiKey.mapiKeyCapabilities.includes(MapiKeyCapability.ReadAssets),
          canReadContent: apiKey.mapiKeyCapabilities.includes(MapiKeyCapability.ReadContent),
        },
        sharedWithUsers: apiKey.sharedWithUsers,
      }),
    [
      reset,
      apiKey.hasAccessToAllEnvironments,
      apiKey.environments,
      apiKey.expiresAt,
      apiKey.name,
      apiKey.mapiKeyCapabilities,
      apiKey.sharedWithUsers,
      defaultExpirationDate,
      isProjectManager,
    ],
  );

  useEffect(() => {
    propagateApiKeyChangesToForm();
  }, [propagateApiKeyChangesToForm]);

  return (
    <ApiKeyDetailLayout
      apiKeyActionStatus={apiKeyActionStatus}
      onAlertDismiss={onAlertDismiss}
      apiKeyName={keyName || UntitledManagementApiKeyName}
    >
      <MapiKeyDetail
        apiKey={apiKey}
        onRegenerate={onRegenerate}
        renderDatePicker={() => (
          <ValidatedApiKeyExpirationDatePicker<MapiKeyFormShape>
            apiKeyType={ApiKeyType.MAPI}
            formProps={formProps}
            name="expiresAt"
          />
        )}
        renderSharedWithUsers={
          isProjectManager
            ? () => (
                <SharedWithUsersSelector<MapiKeyFormShape>
                  formProps={formProps}
                  name="sharedWithUsers"
                  projectContainerActiveUsers={projectContainerActiveUsers}
                  selectedUsersSelectorInputState={InputState.Default}
                />
              )
            : undefined
        }
        renderNameInput={() => (
          <ValidatedInput<MapiKeyFormShape>
            label="Name"
            name="name"
            maxLength={ValidationConstants.ApiKeyNameMaxLength}
            dataUiInputName={DataUiInput.EntityName}
            formProps={formProps}
            inputState={isProjectManager ? InputState.Default : InputState.ReadOnly}
            tooltipText={
              isProjectManager ? '' : 'Only Project managers can change this limitation.'
            }
            tooltipPlacement="top"
            placeholder="Name your API key. Example: Management API key"
          />
        )}
        renderPermissions={() => (
          <ValidatedPermissionsCheckboxes
            formProps={formProps}
            isProjectManager={isProjectManager}
            isKeyTargetedToAllEnvironments={isKeyTargetedToAllEnvironments(selectedKeyEnvironments)}
            name="permissions"
          />
        )}
        renderEnvironmentsSelector={() => (
          <ValidatedMapiKeyEnvironmentsSelector
            allEnvironmentOptions={allEnvironmentOptions}
            canUpdateKey={isProjectManager && !permissions.canManageEnvironments}
            disabledTooltipText={getDisabledTooltipForEnvironmentSelector(
              isProjectManager,
              permissions.canManageEnvironments,
            )}
            formProps={formProps}
          />
        )}
      />
      <HandleUnsavedApiKeyFormOnNavigation
        isDirty={isDirty}
        onSaveChanges={onSaveFromUnsavedChangesDialog}
      />
      {isElement(apiKeyDetailToolbarSaveButtonContainerElement) &&
        isProjectManager &&
        createPortal(
          <Button
            buttonStyle="primary"
            onClick={onSaveChanges}
            {...getDataUiActionAttribute(DataUiAction.Save)}
          >
            Save changes
          </Button>,
          apiKeyDetailToolbarSaveButtonContainerElement,
        )}
    </ApiKeyDetailLayout>
  );
};

MapiKeyDetailForm.displayName = 'MapiKeyDetailForm';
