import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { OrderBy } from '../../../../_shared/models/OrderBy.ts';
import { AssignedContentItemsOrderBy } from '../../../itemEditor/reducers/editorUi/reducers/assignedItemsOrdering.ts';

interface IDeps {
  readonly loadCollections: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadContentTypes: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadLanguages: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadContentItemsAssignedToCurrentUser: (
    orderBy: OrderBy<AssignedContentItemsOrderBy>,
    abortSignal?: AbortSignal,
  ) => ThunkPromise;
  readonly loadSpaces: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadWorkflows: (abortSignal?: AbortSignal) => ThunkPromise;
}

export const createInitContentItemsAssignedToYouAction =
  (deps: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      contentApp: {
        listingUi: {
          yourContentUi: { assignedItemsOrdering },
        },
      },
    } = getState();

    await Promise.all([
      dispatch(deps.loadCollections(abortSignal)),
      dispatch(deps.loadContentTypes(abortSignal)),
      dispatch(deps.loadLanguages(abortSignal)),
      dispatch(deps.loadContentItemsAssignedToCurrentUser(assignedItemsOrdering, abortSignal)),
      dispatch(deps.loadSpaces(abortSignal)),
      dispatch(deps.loadWorkflows(abortSignal)),
    ]);
  };
