import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface IItemElementStatusProps {
  dataUiElement?: DataUiElement;
  isInvalid?: boolean;
  message?: string;
}

export class StatusMessage extends React.PureComponent<IItemElementStatusProps> {
  static displayName = 'StatusMessage';

  static propTypes: PropTypesShape<IItemElementStatusProps> = {
    dataUiElement: PropTypes.string,
    isInvalid: PropTypes.bool,
    message: PropTypes.string,
  };

  render() {
    const { dataUiElement, isInvalid, message } = this.props;

    if (!message) {
      return null;
    }

    return (
      <span
        className={classNames('content-item-element__status-item', {
          'content-item-element__status-item--is-invalid': isInvalid,
        })}
        {...(dataUiElement && getDataUiElementAttribute(dataUiElement))}
      >
        {message}
      </span>
    );
  }
}
