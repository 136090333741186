import { Box } from '@kontent-ai/component-library/Box';
import { Spacing, Typography } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { useUserFeedbackInput } from '../../hooks/UserFeedback/useUserFeedbackInput.ts';
import { ButtonStyle } from '../../uiComponents/Button/buttonStyle.ts';
import { DataUiElement } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { HotkeysHandler } from '../Hotkeys/HotkeysHandler.tsx';
import { ModalDialog } from '../ModalDialog/ModalDialog.tsx';
import { UmuxFeedbackQuestionnaire } from './UmuxFeedbackQuestionnaire.tsx';
import {
  UmuxFeedbackButtonOrder,
  UmuxFeedbackQuestionnaireFooter,
} from './UmuxFeedbackQuestionnaireFooter.tsx';

interface IUmuxFeedbackModalProps {
  readonly dismissFeedbackButtonText: string;
  readonly easeTitle: string;
  readonly headerContent: string;
  readonly subtitle?: string;
  readonly onClose: () => void;
  readonly onSubmit: (ease: string, requirements: string, comment: string) => Promise<void>;
  readonly overAllOtherElements?: boolean;
  readonly requirementsTitle: string;
}

export const UmuxFeedbackModal: React.FC<IUmuxFeedbackModalProps> = ({
  dismissFeedbackButtonText,
  easeTitle,
  headerContent,
  subtitle,
  onClose,
  onSubmit,
  overAllOtherElements,
  requirementsTitle,
}) => {
  const {
    ease,
    onEaseChange,
    onOptionalCommentChange,
    onRequirementsChange,
    optionalComment,
    requirements,
  } = useUserFeedbackInput();

  return (
    <ModalDialog
      overAllOtherElements={overAllOtherElements}
      headerContent={headerContent}
      dataUiElement={DataUiElement.UmuxFeedbackModalDialog}
      bodyContent={
        <HotkeysHandler
          handlers={{
            onEscape: onClose,
          }}
          className="feedback-modal-wrapper"
        >
          {subtitle && (
            <Box marginBottom={Spacing.L} typography={Typography.Subheadline}>
              {subtitle}
            </Box>
          )}
          <UmuxFeedbackQuestionnaire
            ease={ease}
            easeTitle={easeTitle}
            onEaseChange={onEaseChange}
            onOptionalCommentChange={onOptionalCommentChange}
            onRequirementsChange={onRequirementsChange}
            optionalComment={optionalComment}
            requirements={requirements}
            requirementsTitle={requirementsTitle}
          />
        </HotkeysHandler>
      }
      footerContentRight={
        <UmuxFeedbackQuestionnaireFooter
          overAllOtherElements={overAllOtherElements}
          buttonOrder={UmuxFeedbackButtonOrder.CancelFirst}
          cancelButtonStyle={ButtonStyle.Secondary}
          dismissFeedbackButtonText={dismissFeedbackButtonText}
          isDisabled={!requirements || !ease}
          onClose={onClose}
          onSubmit={() => onSubmit(ease, requirements, optionalComment)}
          sendFeedbackButtonText="Submit feedback"
          submitButtonStyle={ButtonStyle.Primary}
        />
      }
      onClose={onClose}
    />
  );
};

UmuxFeedbackModal.displayName = 'UmuxFeedbackModal';
