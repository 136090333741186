import { Icons } from '@kontent-ai/component-library/Icons';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { IconSize, Spacing, colorIconDefault } from '@kontent-ai/component-library/tokens';
import React, { ComponentProps } from 'react';
import { DataTableNameCell } from '../../../../_shared/components/DataTable/DataTableNameCell.tsx';
import { DataTableRow } from '../../../../_shared/components/DataTable/DataTableRow.tsx';
import { LinkDataTableCell } from '../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { IUserInfo } from '../../../../_shared/models/UserInfo.ts';
import { pluralizeWithCount } from '../../../../_shared/utils/stringUtils.ts';
import { ScrollTableItemContributors } from '../../contentStatus/components/ScrollTableItemContributors.tsx';

type Props = Readonly<{
  contentType: string;
  contributors: ReadonlyArray<IUserInfo>;
  id: Uuid;
  isComplete: boolean;
  languageName: string;
  linkTo: string;
  name: string;
  onClick: () => void;
  timeInHours: number;
}>;

export const ContentPaceDetailTableRow: React.FC<Props> = ({
  contentType,
  contributors,
  id,
  isComplete,
  languageName,
  linkTo,
  name,
  onClick,
  timeInHours,
}) => {
  const flooredTime = Math.floor(timeInHours);
  const showLessThan = timeInHours < 1 && timeInHours > 0;

  const cellCommonProps = {
    linkPath: linkTo,
    openInNewTab: true,
    onClick,
  } satisfies Partial<ComponentProps<typeof LinkDataTableCell>>;

  return (
    <DataTableRow dataUiObjectName={name} id={id}>
      <DataTableNameCell
        contentItem={{
          name,
          linkTo,
          isComplete,
        }}
        onClick={onClick}
      />
      <LinkDataTableCell {...cellCommonProps} title={contentType}>
        {contentType}
      </LinkDataTableCell>
      <LinkDataTableCell {...cellCommonProps}>{languageName}</LinkDataTableCell>
      <LinkDataTableCell {...cellCommonProps}>
        <ScrollTableItemContributors contributors={contributors} />
      </LinkDataTableCell>
      <LinkDataTableCell {...cellCommonProps}>
        <Row spacing={Spacing.S}>
          <Column width="content">
            <Icons.ClockBack aria-hidden color={colorIconDefault} size={IconSize.S} />
          </Column>
          <Column>{showLessThan ? '< 1 hour' : pluralizeWithCount('hour', flooredTime)}</Column>
        </Row>
      </LinkDataTableCell>
    </DataTableRow>
  );
};

ContentPaceDetailTableRow.displayName = 'ContentPaceDetailTableRow';
