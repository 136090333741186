import { makeCancellablePromise, swallowCancelledPromiseError } from '@kontent-ai/utils';
import React, { useEffect, useState } from 'react';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import {
  getCurrentProject,
  getCurrentProjectId,
} from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import {
  canActivateApiStatus,
  canManageApiKey,
  canManageEnvironment,
  canManageWebSpotlight,
} from '../../selectors/environmentSettingsMenuCustomVisibilities.ts';
import { loadEnvironmentApiStatus } from '../actions/thunkApiActivationStatusActions.ts';
import { GeneralSettings as GeneralSettingsComponent } from '../components/GeneralSettings.tsx';
import { ApiActivationStatusCard } from './ApiActivationStatusCard.tsx';
import { EnvironmentIdCard } from './EnvironmentIdCard.tsx';
import { EnvironmentNameCard } from './EnvironmentNameCard.tsx';
import { WebSpotlightStatusTile } from './WebSpotlightStatusTile.tsx';

export const GeneralSettings: React.FC = () => {
  const dispatch = useDispatch();

  const [isLoaded, setIsLoaded] = useState(false);

  const isEnvironmentNameVisible = useSelector((s) => canManageEnvironment(getCurrentProject(s)));
  const isEnvironmentIdVisible = useSelector((s) => canManageApiKey(getCurrentProject(s)));
  const isWebSpotlightVisible = useSelector(canManageWebSpotlight);
  const isApiActivationStatusVisible = useSelector(canActivateApiStatus);
  const currentEnvironmentId = useSelector(getCurrentProjectId);

  useEffect(() => {
    setIsLoaded(false);
    const loadActivationStatus = makeCancellablePromise(() =>
      isApiActivationStatusVisible
        ? dispatch(loadEnvironmentApiStatus(currentEnvironmentId))
        : Promise.resolve(),
    );
    loadActivationStatus.then(() => setIsLoaded(true)).catch(swallowCancelledPromiseError);

    return loadActivationStatus.cancel;
  }, [currentEnvironmentId, isApiActivationStatusVisible]);

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <GeneralSettingsComponent
      renderBody={() => (
        <>
          {isEnvironmentNameVisible && <EnvironmentNameCard />}
          {isEnvironmentIdVisible && <EnvironmentIdCard />}
          {isApiActivationStatusVisible && <ApiActivationStatusCard />}
          {isWebSpotlightVisible && <WebSpotlightStatusTile />}
        </>
      )}
    />
  );
};

GeneralSettings.displayName = 'GeneralSettings';
