import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { ContentItemSidebarEditableSection } from '../constants/ContentItemSidebarEditableSection.ts';
import { isSidebarSectionSelected } from '../utils/isSidebarSectionSelected.ts';

/***
 * @param section Section of the sidebar that is ought to be closed
 * @param formId Optional identifier of section's sub-form (if section only has single form, section's name is used for form's name)
 */
export const shouldCloseSidebarForm = (
  state: IStore,
  section: ContentItemSidebarEditableSection,
  formId?: Uuid,
): boolean =>
  isSidebarSectionSelected(state, section) &&
  state.contentApp.editorUi.contentItemSidebar.formsToClose.has(formId || section);
