import { EditorProps } from 'draft-js';
import { useCallback, useMemo } from 'react';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { DecoratedEditor } from '../../editorCore/types/Editor.decorated.type.ts';
import {
  Apply,
  EditorPlugin,
  PluginState,
  Render,
} from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import { mergeInlineStyles } from '../../editorCore/utils/editorComponentUtils.ts';
import { withDisplayName } from '../../editorCore/utils/withDisplayName.ts';
import { getInlineStyleWithAiIds } from './utils/editorAiStyleUtils.ts';

export type AiStylesPlugin = EditorPlugin;

const EditorWithAiStyles: DecoratedEditor<AiStylesPlugin> = ({ baseRender, state }) => {
  const { customStyleFn: baseCustomStyleFn } = state.editorProps;

  const customStyleFn = useCallback<Required<EditorProps>['customStyleFn']>(
    (style, block) =>
      mergeInlineStyles(getInlineStyleWithAiIds(style), baseCustomStyleFn?.(style, block)),
    [baseCustomStyleFn],
  );

  const stateWithAiStyles: PluginState<AiStylesPlugin> = {
    ...state,
    editorProps: {
      ...state.editorProps,
      customStyleFn,
    },
  };

  return baseRender(stateWithAiStyles);
};

EditorWithAiStyles.displayName = 'EditorWithAiStyles';

const render: Decorator<Render<AiStylesPlugin>> = (baseRender) => (state) => (
  <EditorWithAiStyles baseRender={baseRender} state={state} />
);

export const useAiStyles: PluginCreator<AiStylesPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('AiStylesPlugin', {
        ComposedEditor: (props) => {
          const apply: Apply<AiStylesPlugin> = useCallback((state) => {
            state.render.decorate(render);

            return {};
          }, []);

          return useEditorWithPlugin(baseEditor, props, { apply });
        },
      }),
    [baseEditor],
  );
