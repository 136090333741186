/**
 * Creates an object with the properties of 'obj' except for those in 'keys'
 * @param obj The source object
 * @param keys The property paths to pick
 */
export const omit = <T, K extends keyof T>(obj: T, keys: K[]): Omit<T, K> => ({
  ...keys.reduce((result, key) => {
    const { [key]: omitted, ...rest } = result;

    return rest;
  }, obj),
});
