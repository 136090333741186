import { RouterNavLinkProps } from '@kontent-ai/component-library/Anchor';
import { LabelSize } from '@kontent-ai/component-library/Label';
import { OptionalTooltip } from '@kontent-ai/component-library/Tooltip';
import { ComponentLibraryGlobals } from '@kontent-ai/component-library/globals';
import { useOurFocusRing } from '@kontent-ai/component-library/hooks';
import { CLPropTypes } from '@kontent-ai/component-library/validators';
import PropTypes from 'prop-types';
import React from 'react';
import { StyledCell, StyledLabel } from './components/StyledCell.tsx';

interface IHorizontalTabsCellWithoutHTMLAttrsProps
  extends Pick<RouterNavLinkProps, 'to' | 'exact' | 'strict' | 'isActive'> {
  readonly label: string;
  readonly children?: never;
}

export interface IHorizontalTabsCellProps
  extends IHorizontalTabsCellWithoutHTMLAttrsProps,
    Omit<RouterNavLinkProps, 'activeClassName' | 'activeStyle' | 'children'> {}

const propTypes: PropTypeMap<IHorizontalTabsCellWithoutHTMLAttrsProps> = {
  label: PropTypes.string.isRequired,
  children: CLPropTypes.never,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]).isRequired,
  exact: PropTypes.bool,
  strict: PropTypes.bool,
  isActive: PropTypes.func,
};

export const HorizontalTabsCell = React.forwardRef<HTMLAnchorElement, IHorizontalTabsCellProps>(
  ({ label, to, exact, strict, isActive, ...otherProps }, forwardedRef) => {
    const { isFocusVisible, focusProps } = useOurFocusRing();

    return (
      <OptionalTooltip
        placement="bottom-start"
        text={label}
        customRenderText={(ref) => (
          <StyledCell
            to={to}
            exact={exact}
            strict={strict}
            isActive={isActive}
            $isFocusVisible={isFocusVisible}
            innerRef={forwardedRef}
            activeClassName="is-active"
            $skipAnimation={ComponentLibraryGlobals.skipAnimation}
            {...focusProps}
            {...otherProps}
          >
            <StyledLabel size={LabelSize.L} ref={ref}>
              {label}
            </StyledLabel>
          </StyledCell>
        )}
      />
    );
  },
);

HorizontalTabsCell.displayName = 'HorizontalTabsCell';
HorizontalTabsCell.propTypes = propTypes;
