import {
  CompiledTypeElementType,
  ElementType,
} from '../../contentInventory/content/models/ContentItemElementType.ts';
import { getAssetItemElementCommentManager } from './itemElementCommentManagers/assetItemElementCommentManager.ts';
import { getBaseTextItemElementCommentManager } from './itemElementCommentManagers/baseTextItemElementCommentManager.ts';
import { getLinkedItemCommentManager } from './itemElementCommentManagers/getLinkedItemElementCommentManager.ts';
import { getSimpleItemElementCommentManager } from './itemElementCommentManagers/getSimpleItemElementCommentManager.ts';
import { IItemElementCommentManager } from './itemElementCommentManagers/types/IItemElementCommentManager.type.ts';

export interface IItemElementCommentManagerDependencies {
  getItemElementCommentManager: (type: CompiledTypeElementType) => IItemElementCommentManager;
}

const baseTextItemElementCommentManager = getBaseTextItemElementCommentManager({
  getItemElementCommentManager,
});

const noCommentsManager: IItemElementCommentManager = {
  getCommentThreadOffset: () => null,
  getCommentThreadReferences: () => [],
};

const managersMap: { readonly [key in CompiledTypeElementType]: IItemElementCommentManager } = {
  [ElementType.Asset]: getAssetItemElementCommentManager(),
  [ElementType.Custom]: getSimpleItemElementCommentManager(),
  [ElementType.DateTime]: getSimpleItemElementCommentManager(),
  [ElementType.Guidelines]: noCommentsManager,
  [ElementType.LinkedItems]: getLinkedItemCommentManager(),
  [ElementType.MultipleChoice]: getSimpleItemElementCommentManager(),
  [ElementType.Number]: getSimpleItemElementCommentManager(),
  [ElementType.RichText]: baseTextItemElementCommentManager,
  [ElementType.Subpages]: getLinkedItemCommentManager(),
  [ElementType.Taxonomy]: getSimpleItemElementCommentManager(),
  [ElementType.Text]: baseTextItemElementCommentManager,
  [ElementType.UrlSlug]: baseTextItemElementCommentManager,
};

export function getItemElementCommentManager(
  type: CompiledTypeElementType,
): IItemElementCommentManager {
  return managersMap[type];
}
