import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

// Ensures that the plain content items are wrapped in an element to properly apply ellipsis in flex layout under table cell
export function wrapPlainContentItems(
  children: React.ReactNode,
): ReadonlyArray<React.ReactNode> | null | undefined {
  const content = React.Children.map(children, (child, index) => {
    if (typeof child !== 'object') {
      return (
        <span key={index} className="data-table__cell-item-content-segment">
          {child}
        </span>
      );
    }

    return child;
  });

  return content;
}

export interface IDataTableCellProps {
  readonly className?: string;
  readonly disabled?: boolean;
  readonly hasCheckbox?: boolean;
  readonly hasLink?: boolean;
  readonly isExpandable?: boolean;
  readonly isMultiline?: boolean;
  readonly onClick?: () => void;
  readonly title?: string | null;
}

export const propTypes: PropTypesShape<IDataTableCellProps> = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hasCheckbox: PropTypes.bool,
  hasLink: PropTypes.bool,
  isExpandable: PropTypes.bool,
  isMultiline: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string,
};

export const DataTableCell: React.FC<React.PropsWithChildren<IDataTableCellProps>> = ({
  children,
  className,
  disabled,
  hasCheckbox,
  hasLink,
  isExpandable,
  isMultiline,
  onClick,
  title,
}): JSX.Element => (
  <td
    title={title ?? ''}
    className={classNames('data-table__cell-item', className, {
      'data-table__cell-item--checkbox': hasCheckbox,
      'data-table__cell-item--has-link': hasLink,
      'data-table__cell-item--is-expandable': isExpandable,
      'data-table__cell-item--multiline': isMultiline,
      'data-table__cell-item--is-disabled': disabled,
    })}
    onClick={disabled ? undefined : onClick}
    data-hj-suppress=""
  >
    {hasLink || hasCheckbox ? (
      children
    ) : (
      <div className="data-table__cell-item-content">{wrapPlainContentItems(children)}</div>
    )}
  </td>
);

DataTableCell.displayName = 'DataTableCell';
DataTableCell.propTypes = propTypes;
