import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { ApiKeyType } from '../../../../../_shared/models/ApiKeyType.ts';
import {
  NewApiKeyDetail_Loading_Finished,
  NewApiKeyDetail_Loading_Started,
} from '../../constants/apiKeysActionTypes.ts';

interface IDeps {
  readonly loadProjects: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadApiStatus: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadProjectContainerActiveUsers: (abortSignal?: AbortSignal) => ThunkPromise;
}

const started = () =>
  ({
    type: NewApiKeyDetail_Loading_Started,
  }) as const;

const finished = () =>
  ({
    type: NewApiKeyDetail_Loading_Finished,
  }) as const;

export type InitNewApiKeyActionsType = ReturnType<typeof started | typeof finished>;

export const initNewApiKeyCreator =
  (deps: IDeps) =>
  (apiKeyType: ApiKeyType, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(started());

    await Promise.all([
      dispatch(deps.loadProjects(abortSignal)),
      dispatch(deps.loadApiStatus(abortSignal)),
    ]);

    if (apiKeyType === ApiKeyType.DAPI || apiKeyType === ApiKeyType.MAPI) {
      await dispatch(deps.loadProjectContainerActiveUsers(abortSignal));
    }

    dispatch(finished());
  };
