import { Box } from '@kontent-ai/component-library/Box';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { NotificationBarWarning } from '../../../../../../../component-library/components/NotificationBar/NotificationBar.tsx';

export const SignalRTooManyTimeoutsWarning: React.FC = () => (
  <NotificationBarWarning>
    <Row spacing={Spacing.S} alignY="center" noWrap>
      <Column>
        <Box>
          We’re having issues with simultaneous editing in your browser. You can keep editing your
          content, but you won’t get real-time updates on changes made by your colleagues.
        </Box>
      </Column>
      <Column>
        <Tooltip
          tooltipText="If you’ve opened Kontent.ai in multiple browser tabs, try closing a few tabs. Restarting your browser can help too. If this doesn’t work, your connection might be limited by your network settings."
          placement="bottom"
        >
          <Icons.QuestionCircle />
        </Tooltip>
      </Column>
    </Row>
  </NotificationBarWarning>
);

SignalRTooManyTimeoutsWarning.displayName = 'SignalRTooManyTimeoutsWarning';
