import React, { useCallback } from 'react';
import { trackUserEvent } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { ILanguage, Languages } from '../../../../../../data/models/languages/Language.ts';
import { ContentItemPaneElementsGroup } from '../../../../../itemEditor/features/ContentItemEditing/components/ContentItemPaneElementsGroup.tsx';
import { AssetDescription } from '../../../containers/AssetEditing/AssetDescription.tsx';

interface IAssetDescriptionsProps {
  readonly allLanguages: Languages;
  readonly assetId: Uuid;
  readonly canUpdateAssets: boolean;
  readonly descriptions: Immutable.Map<Uuid, string>;
  readonly isImage: boolean;
  readonly isLanguageDescriptionEditableMap: Immutable.Map<Uuid, boolean>;
  readonly languagesToShow: Languages;
  readonly onChange: (variantId: Uuid, newDescription: string) => void;
}

export const AssetDescriptions: React.FC<IAssetDescriptionsProps> = ({
  allLanguages,
  assetId,
  canUpdateAssets,
  descriptions,
  isImage,
  isLanguageDescriptionEditableMap,
  languagesToShow,
  onChange,
}) => {
  const dispatch = useDispatch();
  const copyDescription = useCallback(
    (sourceVariantId: Uuid, targetVariantId: Uuid): void => {
      const newDescription = descriptions.get(sourceVariantId, '');
      onChange(targetVariantId, newDescription);
      dispatch(trackUserEvent(TrackedEvent.AssetDescriptionCopiedFromAnotherLanguage));
    },
    [descriptions, onChange],
  );

  const nonEmptyLanguages = allLanguages
    .filter((lang: ILanguage) => descriptions.has(lang.id) && !!descriptions.get(lang.id))
    .toArray();

  return (
    <ContentItemPaneElementsGroup
      isDisabled={!canUpdateAssets}
      hasTopRoundedCorners
      hasBottomRoundedCorners
    >
      {languagesToShow.toArray().map((language) => (
        <AssetDescription
          assetId={assetId}
          key={language.id}
          isEditable={
            canUpdateAssets && (isLanguageDescriptionEditableMap.get(language.id) ?? false)
          }
          descriptionLabel={
            allLanguages.size > 0 ? `Description in ${language.name}` : 'Description'
          }
          isImage={isImage}
          language={language}
          languageDescription={descriptions.get(language.id) || ''}
          otherNonEmptyLanguages={nonEmptyLanguages.filter(
            (lang: ILanguage) => lang.id !== language.id,
          )}
          onChangeDescription={onChange}
          onCopyDescription={copyDescription}
        />
      ))}
    </ContentItemPaneElementsGroup>
  );
};

AssetDescriptions.displayName = 'AssetDescriptions';
