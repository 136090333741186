import { isAbortError, isXMLHttpRequest } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import {
  DapiKeyDetailRoute,
  MapiKeyDetailRoute,
  PersonalMapiKeyDetailRoute,
} from '../../../../../_shared/constants/routePaths.ts';
import { ApiKeyType, DapiKeyTypes } from '../../../../../_shared/models/ApiKeyType.ts';
import { matchPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IApiKeysRepository } from '../../../../../repositories/interfaces/IApiKeysRepository.type.ts';
import { isProjectManagerInAnyEnvironment } from '../../../root/selectors/isProjectManagerInAnyEnvironment.ts';
import {
  ApiKeyDetail_Init_Finished,
  ApiKeyDetail_Init_Started,
  ApiKeyDetail_Loading_Failed,
  ApiKeyDetail_Loading_Finished,
  ApiKeyDetail_Loading_Started,
  MapiKeyDetail_Editor_NameLoaded,
} from '../../constants/apiKeysActionTypes.ts';
import { ApiKey, createApiKeyFromServerModel, emptyApiKey } from '../../models/ApiKey.ts';
import { IApiKeyFilter, emptyApiKeyFilter } from '../../models/ApiKeyFilter.ts';
import { IApiKeyListingData } from '../../models/ApiKeyListingData.ts';

const initApiKeyStarted = (tokenSeedId: Uuid, loadedApiKey: ApiKey) =>
  ({
    type: ApiKeyDetail_Init_Started,
    payload: {
      loadedApiKey,
      tokenSeedId,
    },
  }) as const;

const initApiKeyFinished = () =>
  ({
    type: ApiKeyDetail_Init_Finished,
  }) as const;

const started = () =>
  ({
    type: ApiKeyDetail_Loading_Started,
  }) as const;

const failed = () =>
  ({
    type: ApiKeyDetail_Loading_Failed,
  }) as const;

const success = (apiKey: ApiKey) =>
  ({
    type: ApiKeyDetail_Loading_Finished,
    payload: { apiKey },
  }) as const;

const ensureNameInBreadcrumbs = (name: string) =>
  ({
    type: MapiKeyDetail_Editor_NameLoaded,
    payload: {
      name,
    },
  }) as const;

export type LoadApiKeyActionsType = ReturnType<
  | typeof started
  | typeof failed
  | typeof success
  | typeof ensureNameInBreadcrumbs
  | typeof initApiKeyStarted
  | typeof initApiKeyFinished
>;

const convertListingPersonalMapiKeyToDetail = (apiKey: IApiKeyListingData): ApiKey => ({
  ...emptyApiKey,
  environments: apiKey.environments,
  expiresAt: apiKey.expiresAt ?? '',
  hasAccessToAllEnvironments: apiKey.hasAccessToAllEnvironments,
  name: apiKey.name,
  tokenSeedId: apiKey.seedId,
  type: apiKey.type,
});

interface IEnsureMapiKeyDetailDependencies {
  readonly loadMapiKeyListing: (filter: IApiKeyFilter, abortSignal?: AbortSignal) => ThunkPromise;
  readonly tokenSeedId: Uuid;
}

const ensurePersonalMapiKeyDetail =
  (
    { loadMapiKeyListing, tokenSeedId }: IEnsureMapiKeyDetailDependencies,
    abortSignal?: AbortSignal,
  ): ThunkPromise =>
  async (dispatch, getState) => {
    try {
      await dispatch(loadMapiKeyListing(emptyApiKeyFilter, abortSignal));
      const apiKey = getState().data.apiKeys.mapiListingData.find(
        (key) => key.seedId === tokenSeedId,
      );
      if (!apiKey) {
        dispatch(failed());
        return;
      }

      dispatch(success(convertListingPersonalMapiKeyToDetail(apiKey)));
      dispatch(ensureNameInBreadcrumbs(apiKey.name));
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failed());
      }

      throw error;
    }
  };

interface ILoadApiKeyDependencies {
  readonly apiKeysRepository: Pick<IApiKeysRepository, 'getApiKey'>;
  readonly loadMapiKeyListing: (filter: IApiKeyFilter, abortSignal?: AbortSignal) => ThunkPromise;
  readonly tokenSeedId: Uuid;
  readonly url: string;
}

const loadApiKey =
  (
    { apiKeysRepository, loadMapiKeyListing, tokenSeedId, url }: ILoadApiKeyDependencies,
    abortSignal?: AbortSignal,
  ): ThunkPromise =>
  async (dispatch, getState) => {
    try {
      if (getState().data.apiKeys.keyDetail.tokenSeedId !== tokenSeedId) {
        dispatch(started());
      }

      const serverModel = await apiKeysRepository.getApiKey(tokenSeedId, abortSignal);
      const apiKey = createApiKeyFromServerModel(serverModel);

      if (matchPath(url, PersonalMapiKeyDetailRoute) && apiKey.type === ApiKeyType.MAPIPat) {
        dispatch(success(apiKey));
        return;
      }

      if (matchPath(url, MapiKeyDetailRoute) && apiKey.type === ApiKeyType.MAPI) {
        dispatch(success(apiKey));
        return;
      }

      if (matchPath(url, DapiKeyDetailRoute) && DapiKeyTypes.includes(apiKey.type)) {
        dispatch(success(apiKey));
        return;
      }

      dispatch(failed());
    } catch (error) {
      if (
        isXMLHttpRequest(error) &&
        error.status === 403 &&
        matchPath(url, PersonalMapiKeyDetailRoute) &&
        isProjectManagerInAnyEnvironment(getState())
      ) {
        await dispatch(
          ensurePersonalMapiKeyDetail({ tokenSeedId, loadMapiKeyListing }, abortSignal),
        );
        return;
      }

      if (!isAbortError(error)) {
        dispatch(failed());
      }

      throw error;
    }
  };

interface IInitApiKeyDependencies {
  readonly apiKeysRepository: Pick<IApiKeysRepository, 'getApiKey'>;
  readonly loadMapiKeyListing: (filter: IApiKeyFilter, abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadProjectContainerActiveUsers: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadProjects: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadApiStatus: (abortSignal?: AbortSignal) => ThunkPromise;
}

export const initApiKeyCreator =
  ({
    apiKeysRepository,
    loadMapiKeyListing,
    loadProjectContainerActiveUsers,
    loadProjects,
    loadApiStatus,
  }: IInitApiKeyDependencies) =>
  (tokenSeedId: Uuid, url: string, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    dispatch(initApiKeyStarted(tokenSeedId, getState().data.apiKeys.keyDetail));

    await dispatch(loadProjects(abortSignal));
    await Promise.all([
      dispatch(
        loadApiKey(
          {
            apiKeysRepository,
            loadMapiKeyListing,
            tokenSeedId,
            url,
          },
          abortSignal,
        ),
      ),
      dispatch(loadApiStatus(abortSignal)),
      dispatch(loadProjectContainerActiveUsers(abortSignal)),
    ]);

    dispatch(initApiKeyFinished());
  };
