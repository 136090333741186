import { usePrevious } from '@kontent-ai/hooks';
import { Loader } from '@storybook/components';
import { IStore } from 'client/app/_shared/stores/IStore.type';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { trackUserEventWithData } from '../../../_shared/actions/thunks/trackUserEvent.ts';
import { ActiveProjectsRoute } from '../../../_shared/constants/routePaths.ts';
import { TrackedEvent } from '../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../_shared/models/LoadingStatusEnum.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { isSampleProject } from '../../../_shared/utils/sampleItemsUtils.ts';
import { IProjectDetails } from '../../../data/models/projects/ProjectDetails.ts';
import {
  areAllSubscriptionsCanceled,
  areAllSubscriptionsExpired,
  getAdministratedSubscriptions,
  hasEmployeePlan,
} from '../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import {
  projectCopyStateStop,
  showCreateProjectModal,
  showCreateSampleProjectModal,
} from '../actions/projectsActions.ts';
import { initializeProjectManagement } from '../actions/thunkProjectsActions.ts';
import { MyProjectsPage } from '../components/MyProjectsPage.tsx';
import {
  MyProjectPlaceholder,
  PlaceholderImageStyle,
} from '../components/projects/MyProjectPlaceholder.tsx';
import { SampleProjectType } from '../constants/sampleProjectType.ts';
import { IProjectViewModel, createProjectList } from '../selectors/createProjectList.ts';
import { ExpiredSubscriptionsWarning } from './ExpiredSubscriptionsWarning.tsx';
import { MyProjectsEmptyState } from './MyProjectsEmptyState.tsx';
import { StartTrialQuickTip } from './StartTrialQuickTip.ts';
import { SuspendedSubscriptionsWarning } from './SuspendedSubscriptionsWarning.tsx';
import { TrialActivationModal } from './TrialActivationModal.tsx';
import { CloneProjectSidebarModal } from './projects/CloneProjectSidebarModal.tsx';
import { CreateProjectModal } from './projects/CreateProjectModal.tsx';
import { CreateSampleProjectModal } from './projects/CreateSampleProjectModal.tsx';
import { MyProject } from './projects/MyProject.tsx';
import { ProjectDeleteConfirmationDialog } from './projects/ProjectDeleteConfirmationDialog.tsx';

interface IMyProjectsProps {
  readonly projectsToShow: 'active' | 'archived';
}

export const MyProjects = (props: IMyProjectsProps) => {
  const [projectIdToDelete, setProjectIdToDelete] = useState<Uuid | null>(null);
  const [sampleProjectType, setSampleProjectType] = useState(SampleProjectType.DancingGoat);

  const dispatch = useDispatch();
  const projectsLoaded = useSelector((s) => s.data.projects.loadingStatus === LoadingStatus.Loaded);
  const creatingProject = useSelector((s) => s.projectsApp.creatingProject);
  const projectList = useSelector((s) =>
    createProjectList(s, s.sharedApp.userProperties.projectOrderBy).filter(
      createShouldBeShown(props.projectsToShow),
    ),
  );

  const canCreateProject = useSelector(canCreateProjectSelector);
  const canCreateDancingGoatProject = useSelector(canCreateSampleProjectSelector);

  const onOpenEmptyProjectModal = () => dispatch(showCreateProjectModal());
  const createOnOpenSampleProjectModal = (type: SampleProjectType) => () => {
    setSampleProjectType(type);
    dispatch(showCreateSampleProjectModal());
    dispatch(
      trackUserEventWithData(TrackedEvent.FeatureUsed, {
        feature: 'create-sample-project-modal--opened',
      }),
    );
  };

  useEffect(() => {
    dispatch(initializeProjectManagement(true));

    return () => {
      dispatch(projectCopyStateStop());
    };
  }, []);

  const history = useHistory();

  const previousCreatingProject = usePrevious(creatingProject);
  useEffect(() => {
    if (creatingProject && !previousCreatingProject) {
      history.push(ActiveProjectsRoute);
    }
  }, [history, creatingProject, previousCreatingProject]);

  if (!projectsLoaded) {
    return <Loader />;
  }

  return (
    <MyProjectsPage>
      <ExpiredSubscriptionsWarning />
      <SuspendedSubscriptionsWarning />

      <div className="my-projects" {...getDataUiCollectionAttribute(DataUiCollection.Projects)}>
        {projectList.map((project) => (
          <MyProject
            key={project.projectId}
            project={project}
            onDeleteProject={() => setProjectIdToDelete(project.projectId)}
          />
        ))}

        {canCreateProject && props.projectsToShow === 'active' && (
          <MyProjectPlaceholder
            onCreateButtonClick={createOnOpenSampleProjectModal(SampleProjectType.HealthTech)}
            imageStyle={PlaceholderImageStyle.ExperimentalSampleProject}
            title="See multi-brand project"
            text="Check an example of a multi-brand fully-featured project for a healthtech company."
            buttonLabel="Create multi-brand project"
          />
        )}

        {canCreateDancingGoatProject && props.projectsToShow === 'active' && (
          <MyProjectPlaceholder
            onCreateButtonClick={createOnOpenSampleProjectModal(SampleProjectType.DancingGoat)}
            imageStyle={PlaceholderImageStyle.SampleProject}
            title="See a complete project"
            text="Want an example of a fully featured project with a website and mobile app?"
            buttonLabel="Create complete sample project"
          />
        )}

        {canCreateProject && props.projectsToShow === 'active' && (
          <MyProjectPlaceholder
            onCreateButtonClick={onOpenEmptyProjectModal}
            imageStyle={PlaceholderImageStyle.EmptyProject}
            title="New project"
            text="Tried the sample project? Now it’s time to start yours."
            buttonLabel="Create new project"
          />
        )}
      </div>
      {props.projectsToShow === 'active' && <StartTrialQuickTip />}

      <MyProjectsEmptyState activeProjectsShown={props.projectsToShow === 'active'} />
      <TrialActivationModal />
      <CreateProjectModal />
      <CreateSampleProjectModal type={sampleProjectType} />
      <CloneProjectSidebarModal />
      {projectIdToDelete && (
        <ProjectDeleteConfirmationDialog
          projectId={projectIdToDelete}
          onClose={() => setProjectIdToDelete(null)}
        />
      )}
    </MyProjectsPage>
  );
};

MyProjects.displayName = 'MyProjects';

const createShouldBeShown =
  (shownProjects: 'active' | 'archived') =>
  (project: IProjectViewModel): boolean => {
    if (shownProjects === 'archived') {
      return project.inactive;
    }

    return !project.inactive;
  };

const canCreateProjectSelector = (s: IStore) => {
  const administratedSubscriptions = getAdministratedSubscriptions(
    s.data.subscriptions.administratedIds,
    s.data.subscriptions.byId,
  );
  return (
    administratedSubscriptions.length > 0 &&
    !areAllSubscriptionsExpired(administratedSubscriptions) &&
    !areAllSubscriptionsCanceled(administratedSubscriptions)
  );
};

const canCreateSampleProjectSelector = (s: IStore) => {
  const administratedSubscriptionIds = getAdministratedSubscriptions(
    s.data.subscriptions.administratedIds,
    s.data.subscriptions.byId,
  ).map((p) => p.subscriptionId);
  const administratedSubscriptionContainsSampleProject = s.data.projects.byId
    .filter((project: IProjectDetails) =>
      administratedSubscriptionIds.includes(project.subscriptionId),
    )
    .some((project: IProjectDetails) => isSampleProject(project) && !project.inactive);
  return (
    canCreateProjectSelector(s) &&
    (hasEmployeePlan(s) || !administratedSubscriptionContainsSampleProject)
  );
};
