import { memoize } from '@kontent-ai/memoization';
import { Collection } from '@kontent-ai/utils';
import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { StatusBarActions } from '../../../../_shared/components/StatusBar/StatusBarActions.tsx';
import { DefaultWorkflowId } from '../../../../_shared/constants/variantIdValues.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { OperationStatus } from '../../../../_shared/models/OperationStatus.ts';
import { isMultipleWorkflowsConfigurationVisible } from '../../../../_shared/selectors/Workflows/isMultipleWorkflowsConfigurationVisible.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { compose } from '../../../../_shared/utils/func/compose.ts';
import { createWorkflowListingLink } from '../../../../_shared/utils/routing/projectSubscriptionRoutingUtils.ts';
import {
  pluralizeWithCount,
  singularizeWithoutCount,
} from '../../../../_shared/utils/stringUtils.ts';
import { Workflow } from '../../../../data/models/workflow/Workflow.ts';
import { getCurrentProjectId } from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { deleteWorkflow, saveWorkflowToServer } from '../actions/thunkWorkflowActions.ts';
import { workflowCodenameChanged } from '../actions/workflowActions.ts';

const getWorkflowsCodenamesWithoutEditedWorkflowCodename = memoize.maxOne(
  (workflows: ReadonlyMap<Uuid, Workflow>, editedWorkflowId: Uuid | null): ReadonlySet<string> => {
    const editedWorkflow = editedWorkflowId ? workflows.get(editedWorkflowId) : undefined;

    const codenamesWithoutEditedWorkflowCodename = Collection.getValues(workflows)
      .map((workflow) => workflow.codename)
      .filter((codename) => codename !== editedWorkflow?.codename);

    return new Set(codenamesWithoutEditedWorkflowCodename);
  },
);

const getDisabledDeleteTooltip = ({ workflowsApp: { editorUi }, data: { workflows } }: IStore) => {
  if (editorUi.editedWorkflowId === DefaultWorkflowId) {
    return 'You can’t delete the default workflow.';
  }

  if (editorUi.editedWorkflowId) {
    const usage = workflows.usage.get(editorUi.editedWorkflowId);
    if (usage === undefined) {
      return 'You can’t delete this workflow because the usage loading has failed.';
    }
    if (usage > 0) {
      return `You can’t delete this workflow because ${pluralizeWithCount(
        'content item',
        usage,
      )} still ${singularizeWithoutCount('use', usage)} it.`;
    }
  }
  return undefined;
};

export const WorkflowToolbarActions: React.FC = () => {
  const isBeingSaved = useSelector(
    (s) => s.projectsApp.savingStatus.workflow.status === OperationStatus.Pending,
  );
  const isLoading = useSelector(
    (s) => s.workflowsApp.editorUi.workflowLoadingStatus !== LoadingStatus.Loaded,
  );
  const isAdvancedEditorEnabled = useSelector(isMultipleWorkflowsConfigurationVisible);
  const codename = useSelector((s) => s.workflowsApp.editorUi.editedWorkflowCodename);
  const relatedCodenames = useSelector((state) =>
    getWorkflowsCodenamesWithoutEditedWorkflowCodename(
      state.data.workflows.byId,
      state.workflowsApp.editorUi.editedWorkflowId,
    ),
  );
  const subscriptionId = useSelector((s) => s.subscriptionApp.selectedSubscriptionId);
  const projectId = useSelector(getCurrentProjectId);
  const disabledDeleteTooltip = useSelector(getDisabledDeleteTooltip);

  const dispatch = useDispatch();
  const history = useHistory();
  const onSave = useCallback(() => dispatch(saveWorkflowToServer({})), []);
  const onCodenameChange = useMemo(() => compose(dispatch, workflowCodenameChanged), []);
  const onDelete = useCallback(
    () =>
      dispatch(
        deleteWorkflow({
          redirectToWorkflowListing: () =>
            history.push(
              createWorkflowListingLink({
                projectId,
                subscriptionId,
              }),
            ),
        }),
      ),
    [history, projectId, subscriptionId],
  );

  return (
    <StatusBarActions
      isBeingSaved={isBeingSaved}
      codename={isAdvancedEditorEnabled ? codename : null}
      isLoading={isLoading}
      onSave={onSave}
      onDelete={isAdvancedEditorEnabled ? onDelete : undefined}
      isDeleteDisabled={!!disabledDeleteTooltip}
      disabledDeleteTooltip={disabledDeleteTooltip}
      onCodenameChange={onCodenameChange}
      relatedCodenames={relatedCodenames}
      isCodenameEditable
    />
  );
};

WorkflowToolbarActions.displayName = 'WorkflowToolbarActions';
