import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { IconName } from '../../../constants/iconEnumGenerated.ts';
import { SquareButton } from '../../../uiComponents/Button/SquareButton.tsx';
import { ButtonStyle } from '../../../uiComponents/Button/buttonStyle.ts';
import { SquareButtonSize } from '../../../uiComponents/Button/squareButtonSize.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';
import {
  IForwardedRefProps,
  forwardRef,
  forwardedRefProps,
} from '../../../utils/forwardedRefProps.tsx';

interface IBarItemActionProps extends IForwardedRefProps<HTMLDivElement> {
  readonly buttonClassName?: string;
  readonly className?: string;
  readonly dataUiActionName: DataUiAction;
  readonly disabled?: boolean;
  readonly iconName: IconName;
  readonly isActive?: boolean;
  readonly isCompact?: boolean;
  readonly onClick?: (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => void;
  readonly onFocus?: (e: React.FocusEvent) => void;
  readonly screenReaderText: string;
  readonly tooltipText?: string;
}

const barItemActionPropTypes: PropTypesShape<IBarItemActionProps> = {
  ...forwardedRefProps,
  buttonClassName: PropTypes.string,
  className: PropTypes.string,
  dataUiActionName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  iconName: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  isCompact: PropTypes.bool,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  screenReaderText: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
};

const BarItemAction: React.FC<React.PropsWithChildren<IBarItemActionProps>> = ({
  children,
  buttonClassName,
  className,
  dataUiActionName,
  disabled,
  forwardedRef,
  iconName,
  isActive,
  isCompact,
  onClick,
  onFocus,
  screenReaderText,
  tooltipText,
  ...otherProps
}) => (
  <div className="bar-item__action-wrapper" ref={forwardedRef}>
    <SquareButton
      className={classNames('bar-item__action', className, {
        'bar-item__action--is-disabled': disabled,
        'bar-item__action--size-xs': isCompact,
      })}
      customButtonClassName={buttonClassName}
      disabled={disabled}
      iconName={iconName}
      isActive={isActive}
      onClick={onClick}
      onFocus={onFocus}
      screenReaderText={screenReaderText}
      size={SquareButtonSize.Quinary}
      style={ButtonStyle.Quinary}
      tooltipText={tooltipText}
      {...getDataUiActionAttribute(dataUiActionName)}
      {...otherProps}
    />
    {children}
  </div>
);
BarItemAction.displayName = 'BarItemAction';
BarItemAction.propTypes = barItemActionPropTypes;

const BarItemActionFRC = forwardRef(BarItemAction);
export { BarItemActionFRC as BarItemAction };
