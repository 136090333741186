import React from 'react';
import { DataUiElement } from '../../../../utils/dataAttributes/DataUiAttributes.ts';
import { renderDatetimeString } from '../../../../utils/dateTime/timeUtils.ts';
import { ModalSection } from '../ModalSection.tsx';

export interface IUnpublishDateStateProps {
  readonly scheduledToUnpublishAt: DateTimeStamp;
}

export const UnpublishDateSection: React.FC<IUnpublishDateStateProps> = ({
  scheduledToUnpublishAt,
}) => (
  <ModalSection
    title="To be unpublished on"
    key="unpublishSection"
    dataUiElementName={DataUiElement.UnpublishDateSection}
  >
    <p>{renderDatetimeString(scheduledToUnpublishAt)}</p>
  </ModalSection>
);

UnpublishDateSection.displayName = 'UnpublishDateSection';
