import { Action } from '../../../../../../../@types/Action.type.ts';
import { IDueDateFormState } from '../../../../../../contentInventory/content/stores/IContentAppStoreState.ts';
import {
  ContentItemEditing_DueDateForm_Changed,
  ContentItemEditing_DueDateForm_Reset,
  ContentItemEditing_Sidebar_Section_Cleaned_Up,
} from '../../../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

const initialState: IDueDateFormState = {
  unsavedValues: null,
};

export const dueDate = (
  state: IDueDateFormState = initialState,
  action: Action,
): IDueDateFormState => {
  switch (action.type) {
    case ContentItemEditing_DueDateForm_Changed:
      return {
        unsavedValues: action.payload.formValues,
      };

    case ContentItemEditing_DueDateForm_Reset:
    case ContentItemEditing_Sidebar_Section_Cleaned_Up:
      return initialState;

    default:
      return state;
  }
};
