import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../../../../@types/Action.type.ts';
import { Shared_ModalDismissed } from '../../../../../../_shared/constants/sharedActionTypes.ts';
import { getMemoizedContentItemId } from '../../../../../../_shared/models/utils/contentItemIdUtils.ts';
import {
  CascadeNodeId,
  ExpandedCascadeNodeData,
} from '../../../../../contentInventory/content/stores/IContentAppStoreState.ts';
import {
  Content_Editing_PublishContentItemVariantStarted,
  Content_Editing_ScheduledPublishingStarted,
} from '../../../../constants/editorActionTypes.ts';
import {
  ContentEditing_CascadeAction_UndoStarted,
  ContentEditing_CascadeModal_ChildNodesInitialized,
  ContentEditing_CascadeModal_ExpandNodeFinished,
  ContentEditing_CascadeModal_ExpandNodeStarted,
  ContentEditing_CascadeModal_NodeCollapsed,
  ContentEditing_CascadeModal_PublishingStarted,
  ContentEditing_CascadeModal_SchedulingStarted,
} from '../../../../features/CascadePublish/constants/cascadeModalActionTypes.ts';
import { shouldChildItemBeRendered } from '../../../../features/CascadePublish/utils/shouldChildItemBeRendered.ts';

const initialState: ReadonlyMap<CascadeNodeId, ExpandedCascadeNodeData> = new Map();

export const expandedNodesData = (
  state = initialState,
  action: Action,
): ReadonlyMap<CascadeNodeId, ExpandedCascadeNodeData> => {
  switch (action.type) {
    case ContentEditing_CascadeModal_ChildNodesInitialized:
      return new Map(
        Array.from(action.payload.initialChildNodes).map(
          ({ contentItemId, nodeId }): [CascadeNodeId, ExpandedCascadeNodeData] => [
            nodeId,
            {
              childContentItemIds: new Set(),
              contentItemId,
            },
          ],
        ),
      );

    case ContentEditing_CascadeModal_ExpandNodeStarted:
      return Collection.add(state, [
        action.payload.nodeId,
        {
          contentItemId: action.payload.contentItemId,
          childContentItemIds: new Set(),
        },
      ]);

    case ContentEditing_CascadeModal_ExpandNodeFinished: {
      const {
        allChildNodes,
        editedItemId,
        nodeId: parentNodeId,
        parentItemId,
        parentVariantId,
      } = action.payload;

      const childContentItemIds = Array.from(allChildNodes.entries()).flatMap(
        ([variantId, items]) =>
          items
            .filter((item) =>
              shouldChildItemBeRendered({
                editedItemId,
                item,
                itemVariantId: variantId,
                parentItemId,
                parentVariantId,
              }),
            )
            .map((item) =>
              getMemoizedContentItemId(item.item.id, item.variant?.id.variantId ?? variantId),
            ),
      );

      return Collection.add(state, [
        parentNodeId,
        {
          contentItemId: getMemoizedContentItemId(parentItemId, parentVariantId),
          childContentItemIds: new Set(childContentItemIds),
        },
      ]);
    }

    case ContentEditing_CascadeModal_NodeCollapsed:
      return Collection.remove(state, action.payload.nodeId);

    case Shared_ModalDismissed:
    case Content_Editing_PublishContentItemVariantStarted:
    case Content_Editing_ScheduledPublishingStarted:
    case ContentEditing_CascadeModal_PublishingStarted:
    case ContentEditing_CascadeModal_SchedulingStarted:
    case ContentEditing_CascadeAction_UndoStarted:
      return initialState;

    default:
      return state;
  }
};
