import React from 'react';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import { SquareButton } from '../../../../../_shared/uiComponents/Button/SquareButton.tsx';
import { ButtonStyle } from '../../../../../_shared/uiComponents/Button/buttonStyle.ts';

export const PlaceholderOuterAction: React.FC = () => (
  <div className="bar-item__outer-action bar-item__outer-action--is-hidden">
    <SquareButton style={ButtonStyle.Secondary} iconName={IconName.Plus} screenReaderText="" />
  </div>
);

PlaceholderOuterAction.displayName = 'PlaceholderOuterAction';
