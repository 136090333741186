import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import React, { useCallback } from 'react';
import { Loader } from '../../../../../_shared/components/Loader.tsx';
import { ShortcutSymbols } from '../../../../../_shared/constants/shortcutSymbols.ts';
import { NotificationBar } from '../../../../../_shared/containers/NotificationBar.tsx';
import { Button } from '../../../../../_shared/uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IAsset } from '../../../../../data/models/assets/Asset.ts';
import { ITaxonomyGroup } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import {
  AssetEditor,
  EditingLanguages,
} from '../../../../contentInventory/assets/containers/AssetEditor.tsx';
import { ModalDialog } from '../../ContentItemEditing/components/modalDialog/ModalDialog.tsx';
import { AssetEditorDialogSidebar } from './AssetEditorDialogSidebar.tsx';

type Props = {
  readonly assetTitle: string | null;
  readonly assetTitlePlaceholder: string | null;
  readonly editedAsset: IAsset | null;
  readonly canUpdateAssets: boolean;
  readonly disabledSaveButtonTooltipText?: string;
  readonly isBeingSaved: boolean;
  readonly isLoading: boolean;
  readonly isSaveButtonDisabled: boolean;
  readonly onCancel: () => void;
  readonly onChange: (asset: IAsset) => void;
  readonly onSaveAsset: (updatedAsset: IAsset) => void;
  readonly taxonomyGroups: Immutable.Map<string, ITaxonomyGroup>;
};

export const AssetEditorDialog: React.FC<Props> = ({
  assetTitle,
  assetTitlePlaceholder,
  canUpdateAssets,
  disabledSaveButtonTooltipText,
  editedAsset,
  isBeingSaved,
  isLoading,
  isSaveButtonDisabled,
  onCancel,
  onChange,
  onSaveAsset,
  taxonomyGroups,
}) => {
  const update = useCallback(() => {
    if (editedAsset) {
      onSaveAsset(editedAsset);
    }
  }, [editedAsset, onSaveAsset]);

  const buttons = (
    <>
      <Tooltip tooltipText="Cancel" placement="top" shortcuts={ShortcutSymbols.Escape}>
        <Button
          style={ButtonStyle.Secondary}
          onClick={onCancel}
          {...getDataUiActionAttribute(DataUiAction.Cancel)}
        >
          Cancel
        </Button>
      </Tooltip>
      {canUpdateAssets && (
        <Tooltip
          tooltipText={isSaveButtonDisabled ? disabledSaveButtonTooltipText : 'Update'}
          placement="top"
          shortcuts={isSaveButtonDisabled ? undefined : ShortcutSymbols.Enter}
        >
          <Button
            style={ButtonStyle.Primary}
            onClick={update}
            disabled={isSaveButtonDisabled}
            {...getDataUiActionAttribute(DataUiAction.Save)}
          >
            {isBeingSaved ? 'Updating...' : 'Update'}
          </Button>
        </Tooltip>
      )}
    </>
  );

  return (
    <ModalDialog
      buttons={buttons}
      onCancel={onCancel}
      onSubmit={canUpdateAssets ? update : undefined}
      titleBarText={assetTitle || assetTitlePlaceholder || ''}
      notificationBar={<NotificationBar />}
    >
      <div className="canvas canvas--detached-scrollbars">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="canvas__workspace canvas__workspace--detached-scrollbars">
              <section className="canvas__content">
                <div className="canvas__content-pane asset-library-pane">
                  <div className="asset-library-pane__editor-pane">
                    {editedAsset && (
                      <AssetEditor
                        asset={editedAsset}
                        onChange={onChange}
                        editingLanguages={EditingLanguages.CurrentLanguage}
                        isModalAssetEditor
                      />
                    )}
                  </div>
                </div>
              </section>
            </div>
            <AssetEditorDialogSidebar editedAsset={editedAsset} taxonomyGroups={taxonomyGroups} />
          </>
        )}
      </div>
    </ModalDialog>
  );
};

AssetEditorDialog.displayName = 'AssetEditorDialog';
