import { Box } from '@kontent-ai/component-library/Box';
import { AnchorButton } from '@kontent-ai/component-library/Button';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Slider } from '@kontent-ai/component-library/Slider';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { useEffect, useState } from 'react';
import { QuickTip } from '../../../../_shared/components/infos/QuickTip.tsx';
import { LandmarkAriaLabels } from '../../../../_shared/constants/landmarkAriaLabels.ts';
import { validateDimension } from '../../../../_shared/utils/assets/assetValidationUtils.ts';
import { DataUiInput } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { always } from '../../../../_shared/utils/func/functionalTools.ts';
import { IImageTransformation } from '../../../../data/models/assetRenditions/AssetRendition.ts';
import { IImage } from '../../../../data/models/assets/Image.ts';
import { AssetFileTypeOption } from '../../../contentInventory/content/models/assetFileTypeOptions.ts';
import { DefaultSkinSidebar } from '../../../contentInventory/shared/components/DefaultSkinSidebar.tsx';
import { AssetLimitations } from '../../../richText/plugins/apiLimitations/api/EditorFeatureLimitations.ts';
import { IAssetWarningResult } from '../../utils/itemElementWarningCheckers/types/IAssetWarningResult.type.ts';
import { ContentItemElementStatus } from '../ContentItemEditing/components/elements/ContentItemElementStatus.tsx';
import { AssetLimitStatusMessage } from '../ContentItemEditing/components/elements/subComponents/limitInfoMessages/AssetLimitStatusMessage.tsx';
import { buildAssetLink } from '../ContentItemEditing/utils/renditionUtils.ts';
import { ImageEditorInput } from './ImageEditorInput.tsx';
import { Dimensions } from './types/ImageEditorTypes.type.ts';

type ImageEditorSidebarProps = {
  readonly image: IImage;
  readonly onValidityChange: (areInputsValid: boolean) => void;
  readonly transformation: IImageTransformation;
  readonly onDimensionsChange: (newDimensions: Dimensions) => void;
  readonly zoom: number;
  readonly minZoom: number;
  readonly maxZoom: number;
  readonly onZoomChange: (newZoom: number) => void;
  readonly limitations: AssetLimitations;
};

export const ImageEditorSidebar: React.FC<ImageEditorSidebarProps> = ({
  onValidityChange,
  ...props
}) => {
  const [isWidthValid, setIsWidthValid] = useState(true);
  const [isHeightValid, setIsHeightValid] = useState(true);

  const onHeightChange = (changedValue: string, isValid: boolean) => {
    setIsHeightValid(isValid);

    const newHeight = Number(changedValue);

    if (isValid && newHeight !== props.transformation.height) {
      props.onDimensionsChange({
        width: props.transformation.width,
        height: newHeight,
      });
    }
  };

  const onWidthChange = (changedValue: string, isValid: boolean) => {
    setIsWidthValid(isValid);

    const newWidth = Number(changedValue);

    if (isValid && newWidth !== props.transformation.width) {
      props.onDimensionsChange({
        width: newWidth,
        height: props.transformation.height,
      });
    }
  };

  useEffect(() => {
    onValidityChange(isWidthValid && isHeightValid);
  }, [isWidthValid, isHeightValid, onValidityChange]);

  const { maxHeight, minHeight, maxWidth, minWidth } = props.limitations;

  const heightWidthLimitations: AssetLimitations = {
    maxHeight,
    minHeight,
    minWidth,
    maxWidth,
    fileType: AssetFileTypeOption.Any,
    fileSize: null,
  };

  const validationResult: IAssetWarningResult = {
    isWidthLimitMet: validateDimension(minWidth, maxWidth, props.transformation.width),
    isHeightLimitMet: validateDimension(minHeight, maxHeight, props.transformation.height),
    isResponsiveImageTypeLimitMet: true,
    isFileSizeLimitMet: true,
  };

  return (
    <section
      aria-label={LandmarkAriaLabels.AssetDetails}
      css="height: 100%"
      className="canvas__sidebar asset-library-sidebar"
    >
      <DefaultSkinSidebar sidebarContentClassNames="asset-library-sidebar__content">
        <div className="sidebar__section">
          <h3 className="sidebar__section-title">Original dimensions</h3>
          <span className="sidebar__section-content">
            <Row spacingX={Spacing.S}>
              <Column width="1/2">
                <Stack spacing={Spacing.S}>
                  <div>Width</div>
                  <div>{props.image.width} px</div>
                </Stack>
              </Column>
              <Column width="1/2">
                <Stack spacing={Spacing.S}>
                  <div>Height</div>
                  <div>{props.image.height} px</div>
                </Stack>
              </Column>
            </Row>
          </span>
        </div>
        <div className="sidebar__section">
          <h3 className="sidebar__section-title">Customized dimensions</h3>
          <Row spacingX={Spacing.S} noWrap>
            <Column width="1/2">
              <ImageEditorInput
                initialValue={props.transformation.width}
                originalDimension={props.image.width}
                onChange={onWidthChange}
                name="Width"
                dataUiInput={DataUiInput.ImageWidth}
              />
            </Column>
            <Column css="flex-wrap: nowrap" width="1/2">
              <ImageEditorInput
                initialValue={props.transformation.height}
                originalDimension={props.image.height}
                onChange={onHeightChange}
                name="Height"
                dataUiInput={DataUiInput.ImageHeight}
              />
            </Column>
          </Row>
          <Box paddingTop={Spacing.S}>
            <ContentItemElementStatus>
              <AssetLimitStatusMessage
                limitations={heightWidthLimitations}
                validationResult={validationResult}
                prefix="Limited:"
              />
            </ContentItemElementStatus>
          </Box>
        </div>
        <div className="sidebar__section">
          <h3 className="sidebar__section-title">Image scaling</h3>
          <Slider
            disabled={props.maxZoom === props.minZoom}
            label="Zoom"
            maxValue={props.maxZoom}
            minValue={props.maxZoom === props.minZoom ? props.minZoom - 10 : props.minZoom} // TODO KCL-8033: This is a temporary hack, remove this once the Slider components renders the case where minValue === maxValue correctly
            onChange={props.onZoomChange}
            renderMaxValueLabel={always('Max')}
            renderMinValueLabel={always('Min')}
            step={(props.maxZoom - props.minZoom) / 100}
            value={props.zoom}
          />
        </div>
        <div className="sidebar__section">
          <AnchorButton
            buttonStyle="tertiary"
            href={buildAssetLink(props.image._deliveryLink, props.transformation)}
            target="_blank"
          >
            Preview
          </AnchorButton>
        </div>
        <div className="sidebar__section">
          <QuickTip hideTitle>
            These changes are only applied to the image used in the element of this item. The
            original image stays the same.
          </QuickTip>
        </div>
      </DefaultSkinSidebar>
    </section>
  );
};

ImageEditorSidebar.displayName = 'ImageEditorSidebar';
