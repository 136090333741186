import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import {
  ControlShortcutTemplate,
  ShortcutSymbols,
} from '../../../../../../../_shared/constants/shortcutSymbols.ts';
import { Button, ButtonSize } from '../../../../../../../_shared/uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import {
  DataUiCommentsAction,
  getDataUiCommentActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface INewCommentThreadItemActionsBarProps {
  readonly isSubmitting: boolean;
  readonly isSuggestion: boolean;
  readonly isSubmitButtonDisabled: boolean;

  readonly onCancelEditing: () => void;
}

export class NewCommentThreadItemActionsBar extends React.PureComponent<INewCommentThreadItemActionsBarProps> {
  static displayName = 'NewCommentThreadItemActionsBar';
  static propTypes: PropTypesShape<INewCommentThreadItemActionsBarProps> = {
    isSubmitButtonDisabled: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    isSuggestion: PropTypes.bool.isRequired,

    onCancelEditing: PropTypes.func.isRequired,
  };

  private readonly getButtonText = (): string => {
    const { isSuggestion, isSubmitting } = this.props;
    if (isSuggestion) {
      return isSubmitting ? 'Suggesting…' : 'Suggest changes';
    }

    return isSubmitting ? 'Sending…' : 'Send';
  };

  private readonly getSubmitButtonTooltip = (): string =>
    this.props.isSuggestion
      ? 'Suggest a replacement for the highlighted text'
      : 'Save your comment';

  render() {
    const { isSuggestion, onCancelEditing, isSubmitting, isSubmitButtonDisabled } = this.props;

    const cancelButtonTooltip = isSuggestion ? 'Discard your suggestion' : 'Discard your comment';

    return (
      <div className="comment-editor__actions">
        <Tooltip
          tooltipText={isSubmitting ? undefined : cancelButtonTooltip}
          placement="bottom-end"
          shortcuts={ShortcutSymbols.Escape}
        >
          <Button
            style={ButtonStyle.Secondary}
            size={ButtonSize.S}
            onClick={isSubmitting ? undefined : onCancelEditing}
            disabled={isSubmitting}
            {...getDataUiCommentActionAttribute(DataUiCommentsAction.Cancel)}
          >
            Cancel
          </Button>
        </Tooltip>
        <Tooltip
          tooltipText={
            !isSubmitting && !isSubmitButtonDisabled ? this.getSubmitButtonTooltip() : undefined
          }
          placement="bottom-end"
          shortcuts={ControlShortcutTemplate(ShortcutSymbols.Enter)}
        >
          <Button
            style={ButtonStyle.Primary}
            size={ButtonSize.S}
            type="submit"
            disabled={isSubmitButtonDisabled}
            {...getDataUiCommentActionAttribute(DataUiCommentsAction.SaveComment)}
          >
            {this.getButtonText()}
          </Button>
        </Tooltip>
      </div>
    );
  }
}
