import { ThunkFunction, ThunkPromise } from '../../../../@types/Dispatcher.type.ts';

interface IDeps {
  readonly loadPreviewConfiguration: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadContentTypes: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadSpaces: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly validatePreviewConfiguration: () => ThunkFunction;
}

export const initializePreviewConfigurationActionCreator =
  (deps: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    await Promise.all([
      dispatch(deps.loadSpaces(abortSignal)).then(() =>
        dispatch(deps.loadPreviewConfiguration(abortSignal)),
      ),
      dispatch(deps.loadContentTypes(abortSignal)),
    ]);

    // workaround for opening invalid form (e.g. url slug was removed from content type) and submitting via save button that is out of the form context
    dispatch(deps.validatePreviewConfiguration());
  };
