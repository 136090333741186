import PropTypes from 'prop-types';
import React from 'react';
import { Breadcrumbs } from '../containers/Breadcrumbs.tsx';
import { WaitForSelectedVariantId } from './WaitForSelectedVariantId.tsx';

interface IContentItemBreadcrumbs {
  readonly renderSavingStatusComponent?: () => React.ReactNode;
}

const propTypes: PropTypeMap<IContentItemBreadcrumbs> = {
  renderSavingStatusComponent: PropTypes.func,
};
export const ContentItemBreadcrumbs: React.FC<IContentItemBreadcrumbs> = (props) => {
  return (
    <WaitForSelectedVariantId>
      <Breadcrumbs renderSavingStatusComponent={props.renderSavingStatusComponent} />
    </WaitForSelectedVariantId>
  );
};
ContentItemBreadcrumbs.propTypes = propTypes;
ContentItemBreadcrumbs.displayName = 'ContentItemBreadcrumbs';
