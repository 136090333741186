import PropTypes from 'prop-types';
import React from 'react';
import {
  AssetTypeElementPropType,
  IAssetTypeElement,
} from '../../../../../contentInventory/content/models/contentTypeElements/AssetTypeElement.ts';
import {
  AssetItemElementPropTypesShape,
  IAssetItemElement,
} from '../../../../models/contentItemElements/AssetItemElement.ts';
import { ItemElement } from '../../../ContentItemEditing/containers/elements/ItemElement.tsx';
import { ItemElementRefresher } from '../../../ContentItemEditing/containers/elements/ItemElementRefresher.tsx';
import { IRevisionItemElementProps } from '../../utils/itemElementRevisionComponentCreator.ts';
import { AssetDiff } from './subelements/AssetDiff.tsx';

type AssetProps = IRevisionItemElementProps<IAssetItemElement, IAssetTypeElement>;

const propTypes: PropTypeMap<AssetProps> = {
  elementData: AssetItemElementPropTypesShape.isRequired,
  hideValidationStatus: PropTypes.bool,
  originalElementData: AssetItemElementPropTypesShape,
  revisionElementModifiedBy: PropTypes.string,
  typeElement: AssetTypeElementPropType.isRequired,
};

export const Asset: React.FC<AssetProps> = ({
  elementData,
  hideValidationStatus,
  originalElementData,
  revisionElementModifiedBy,
  typeElement,
}) => (
  <ItemElement
    disabled
    hideValidationStatus={hideValidationStatus}
    isDisplayOnly
    typeElement={typeElement}
    revisionChangedBy={revisionElementModifiedBy}
  >
    <ItemElementRefresher
      elementData={elementData}
      renderInput={(refresherElementData: IAssetItemElement) => (
        <AssetDiff
          elementData={refresherElementData}
          originalElementData={originalElementData}
          typeElement={typeElement}
        />
      )}
    />
  </ItemElement>
);

Asset.displayName = 'Asset (Revisions)';
Asset.propTypes = propTypes;
