import { InvariantException } from '@kontent-ai/errors';
import Immutable from 'immutable';
import { Dispatch, GetState, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IRoleRepository } from '../../../../../repositories/interfaces/IRoleRepository.type.ts';
import { IRoleServerModel } from '../../../../../repositories/serverModels/IRoleServerModel.type.ts';
import {
  NoCapabilitySelectedErrorMessage,
  UnableToSaveErrorMessage,
} from '../../constants/UIConstants.ts';
import {
  Role_Update_Failed,
  Role_Update_Finished,
  Role_Update_Started,
} from '../../constants/rolesActionTypes.ts';
import { isRoleUsedByCurrentUser } from '../../selectors/isRoleUsed.ts';
import { getUpdateServerModelFromEditedRole } from '../../utils/getUpdateServerModelFromEditedRole.ts';
import { isRoleValid } from '../../utils/validationUtils.ts';
import { roleValidationFailed } from '../rolesActions.ts';

interface IDeps {
  readonly reloadNormalizedRoleWithSettings: (projectId: Uuid) => ThunkPromise;
  readonly roleRepository: Pick<IRoleRepository, 'updateRole'>;
}

interface IParams {
  readonly onSuccess?: () => void;
  readonly onFail?: () => void;
}

const started = () =>
  ({
    type: Role_Update_Started,
  }) as const;

const finished = (role: IRoleServerModel, allTypes: Immutable.Map<Uuid, IContentType>) =>
  ({
    type: Role_Update_Finished,
    payload: {
      role,
      allTypes,
    },
  }) as const;

const failed = (errorMessage: string) =>
  ({
    type: Role_Update_Failed,
    payload: { errorMessage },
  }) as const;

export type UpdateRoleActionsType = ReturnType<typeof started | typeof finished | typeof failed>;

export const makeUpdateRoleAction =
  (deps: IDeps) =>
  (params: IParams = {}): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {
      rolesApp: {
        editorUi: { editedRole },
      },
      data: { contentTypes },
      sharedApp: { currentProjectId },
    } = getState();

    if (!editedRole) {
      throw InvariantException('updateRole.ts: edited role is null');
    }

    if (editedRole.isReadonly) {
      throw InvariantException('updateRole.ts: you cannot update readonly (default) roles');
    }

    if (!isRoleValid(editedRole)) {
      dispatch(roleValidationFailed());
      if (params.onFail) {
        params.onFail();
      }
      return;
    }

    try {
      dispatch(started());

      const updateServerModel = getUpdateServerModelFromEditedRole(editedRole, contentTypes.byId);
      if (updateServerModel.capabilities.length <= 0) {
        dispatch(failed(NoCapabilitySelectedErrorMessage));
        if (params.onFail) {
          params.onFail();
        }
        return;
      }

      const serverModel = await deps.roleRepository.updateRole(editedRole.id, updateServerModel);
      dispatch(finished(serverModel, contentTypes.byId));
      dispatch(trackUserEvent(TrackedEvent.CustomRoleEdited));

      if (isRoleUsedByCurrentUser(editedRole.id, getState())) {
        await dispatch(deps.reloadNormalizedRoleWithSettings(currentProjectId));
      }

      if (params.onSuccess) {
        params.onSuccess();
      }
    } catch (e) {
      if (params.onFail) {
        params.onFail();
      }
      dispatch(failed(UnableToSaveErrorMessage));

      throw e;
    }
  };
