import { InvariantException } from '@kontent-ai/errors';
import { getLuminance, mix } from 'polished';
import { linearGradient } from '../../utils/gradient.ts';
import { BaseColor, Color } from './colors.ts';

export type GradientType = {
  toString: () => string;
  from: Color;
  to: Color;
};

export enum DarkGradients {
  GrayDark = 'GrayDark',
  OrangeDark = 'OrangeDark',
  RedDark = 'RedDark',
  YellowDark = 'YellowDark',
  NeonGreenDark = 'NeonGreenDark',
  PersianGreenDark = 'PersianGreenDark',
  SkyBlueDark = 'SkyBlueDark',
  OceanBlueDark = 'OceanBlueDark',
  PurpleDark = 'PurpleDark',
  RoseDark = 'RoseDark',
}

enum LightGradients {
  GrayLight = 'GrayLight',
  OrangeLight = 'OrangeLight',
  RedLight = 'RedLight',
  YellowLight = 'YellowLight',
  NeonGreenLight = 'NeonGreenLight',
  PersianGreenLight = 'PersianGreenLight',
  SkyBlueLight = 'SkyBlueLight',
  OceanBlueLight = 'OceanBlueLight',
  PurpleLight = 'PurpleLight',
  RoseLight = 'RoseLight',
}

export enum UniqueGradients {
  GrayVeryLight = 'GrayVeryLight',
  SidebarBackground = 'SidebarBackground',
}

export enum GradientAngle {
  ToRight = 90,
  ToBottomRight = 135,
  ToBottom = 180,
  ToLeft = 270,
  ToTop = 360,
}

const baseColors = [
  'Gray',
  'Orange',
  'Red',
  'Yellow',
  'NeonGreen',
  'PersianGreen',
  'SkyBlue',
  'OceanBlue',
  'Purple',
  'Rose',
] as const;
const variations = {
  Dark: [70, 60],
  Light: [40, 30],
} as const;

const generateGradientFunction = (colorFrom: BaseColor, colorTo: BaseColor): GradientFunction => {
  const gradientFunction = (angle: GradientAngle) => linearGradient(angle, colorFrom, colorTo);
  try {
    gradientFunction.luminance = getLuminance(mix(0.5, colorFrom, colorTo));
  } catch (e) {
    throw new Error(`Error when generating gradient from '${colorFrom}' to '${colorTo}'.`, {
      cause: e,
    });
  }

  return gradientFunction;
};

const generateGradients = () => {
  const gradients = {} as GradientCollection;

  baseColors.forEach((baseColor) => {
    Object.entries(variations).forEach(([variationName, [intensityFrom, intensityTo]]) => {
      const colorFromName = `${baseColor}${intensityFrom}` as const;
      const colorFrom = BaseColor[colorFromName];
      const colorToName = `${baseColor}${intensityTo}` as const;
      const colorTo = BaseColor[colorToName];

      if (!colorFrom || !colorTo) {
        throw InvariantException(
          `Unexpected color token '${
            colorFrom ? colorToName : colorFromName
          }' encountered while generating gradients`,
        );
      }

      const gradientName = `${baseColor}${variationName}` as const;
      gradients[gradientName] = generateGradientFunction(colorFrom, colorTo);
    });
  });

  return gradients;
};

type AllGradientNames =
  | keyof typeof LightGradients
  | keyof typeof DarkGradients
  | keyof typeof UniqueGradients;

export type GradientFunction = (angle?: GradientAngle) => GradientType;

export type GradientCollection = {
  [key in AllGradientNames]: GradientFunction;
};

export const Gradient: GradientCollection = {
  ...generateGradients(),
  GrayVeryLight: generateGradientFunction(BaseColor.Gray30, BaseColor.Gray20),
} as const;

const getDarkGradients = (): DarkGradient => {
  const result: Mutable<DarkGradient> = { ...Gradient };

  baseColors.forEach((baseColor) => {
    const gradientName: `${typeof baseColor}Dark` = `${baseColor}Dark`;

    result[gradientName] = Gradient[gradientName];
  });

  return result;
};

export type DarkGradient = {
  [key in keyof typeof DarkGradients]: GradientFunction;
};

export const DarkGradient: DarkGradient = {
  ...getDarkGradients(),
} as const;
