import { Toggle } from '@kontent-ai/component-library/Toggle';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { DialogState } from '../../../../../component-library/components/Dialogs/DialogStateEnum.ts';
import { usePopoverDialog } from '../../../../../component-library/components/Dialogs/Popover/usePopoverDialog.tsx';
import { addFlipping } from '../../../../../component-library/components/Dialogs/Popover/utils/tippyOptionsUtils.ts';
import { HandleClickOutside } from '../../../../_shared/components/HandleClickOutside.tsx';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IEntityWebhookSetting } from '../../../../data/models/webhooks/EntityWebhookSetting.ts';
import { EntityWebhookStatusTransitionState } from '../../types/EntityWebhookStatusTransitionState.ts';
import { EntityWebhookStatusToggleWarning } from '../EntityWebhookStatusToggleWarning.tsx';

const getLabelText = (
  webhook: IEntityWebhookSetting,
  webhookStatusTransitionState: EntityWebhookStatusTransitionState | undefined,
) => {
  if (webhookStatusTransitionState) {
    return webhookStatusTransitionState === EntityWebhookStatusTransitionState.Enabling
      ? 'Enabling'
      : 'Disabling';
  }
  return webhook.enabled ? 'Enabled' : 'Disabled';
};

type EntityWebhookStatusToggleProps = {
  readonly webhook: IEntityWebhookSetting;
  readonly onEnable: (webhookId: Uuid) => void;
  readonly onDisable: (webhookId: Uuid) => void;
  readonly webhookStatusTransitionState: EntityWebhookStatusTransitionState | undefined;
};

const propTypes: PropTypeMap<EntityWebhookStatusToggleProps> = {
  webhook: PropTypes.any.isRequired,
  onEnable: PropTypes.func.isRequired,
  onDisable: PropTypes.func.isRequired,
  webhookStatusTransitionState: PropTypes.oneOf(Object.values(EntityWebhookStatusTransitionState)),
};

export const EntityWebhookStatusToggle = ({
  webhook,
  onDisable,
  onEnable,
  webhookStatusTransitionState,
}: EntityWebhookStatusToggleProps) => {
  const popoverRef = useRef<HTMLDivElement>(null);
  const labelText = getLabelText(webhook, webhookStatusTransitionState);
  const { PopoverDialog, popoverDialogProps, targetProps, isOpen, openDialog, closeDialog } =
    usePopoverDialog({
      adjustTippyOptions: (tippyOptions) => addFlipping(tippyOptions),
      dialogState: DialogState.Default,
      headline: 'Disable webhook',
      popoverRef,
      primaryAction: {
        text: 'Disable',
        onClick: () => {
          closeDialog();
          onDisable(webhook.id);
        },
      },
    });

  return (
    <>
      <Toggle
        {...targetProps}
        {...getDataUiActionAttribute(DataUiAction.Disable)}
        status={webhook.enabled ? 'on' : 'off'}
        labelText={labelText}
        disabled={!!webhookStatusTransitionState}
        onToggleOn={() => onEnable(webhook.id)}
        onToggleOff={openDialog}
      />
      {isOpen && (
        <HandleClickOutside observedRefs={popoverRef} onClickOutside={closeDialog}>
          <PopoverDialog
            {...popoverDialogProps}
            {...getDataUiElementAttribute(DataUiElement.Dialog)}
          >
            <EntityWebhookStatusToggleWarning entityWebhookName={webhook.name} />
          </PopoverDialog>
        </HandleClickOutside>
      )}
    </>
  );
};

EntityWebhookStatusToggle.propTypes = propTypes;
EntityWebhookStatusToggle.displayName = 'EntityWebhookStatusToggle';
