import { closeNotificationBar } from '../actions/sharedActions.ts';
import {
  HandleUnsavedFormOnNavigation as HandleUnsavedFormOnNavigationComponent,
  HandleUnsavedFormOnNavigationProps,
} from '../components/HandleUnsavedFormOnNavigation.tsx';
import { useDispatch } from '../hooks/useDispatch.ts';

type Props = Omit<HandleUnsavedFormOnNavigationProps, 'onDiscard'>;

export const HandleUnsavedFormOnNavigation = (props: Props) => {
  const dispatch = useDispatch();

  return (
    <HandleUnsavedFormOnNavigationComponent
      onDiscard={() => dispatch(closeNotificationBar())}
      {...props}
    />
  );
};
