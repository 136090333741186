import { Button, ButtonSize } from '../../../../../../../_shared/uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly onFilterRestore: () => void;
  readonly restoreActionInProgress: boolean;
};

export const RestoreSavedFilterButton = ({ onFilterRestore, restoreActionInProgress }: Props) => {
  return (
    <Button
      style={ButtonStyle.Tertiary}
      size={ButtonSize.S}
      onClick={onFilterRestore}
      disabled={restoreActionInProgress}
      {...getDataUiActionAttribute(DataUiAction.Undo)}
    >
      Undo
    </Button>
  );
};
