import React from 'react';
import { IUserInfo } from '../../../../../../../_shared/models/UserInfo.ts';
import {
  ItemEditingForms,
  getDataUiObjectNameAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ContributorsFormBase } from '../../../containers/details/Contributors/ContributorsForm/ContributorsFormBase.tsx';
import { IContributorsFormShape } from '../../../models/IContributorsFormShape.type.ts';
import { LeafActionFormProps } from '../EditingLeafActionForm.tsx';

type ContributorsFormProps = LeafActionFormProps & {
  readonly initialContributors: ReadonlySet<IUserInfo>;
  readonly onCancel: () => void;
  readonly onConfirm: (values: IContributorsFormShape) => Promise<void>;
};

export const ContributorsForm: React.FC<ContributorsFormProps> = ({
  useCustomHandleClickOutside,
  ...sharedProps
}) => {
  return (
    <div
      className="editing-actions-assignment-form"
      {...getDataUiObjectNameAttribute(ItemEditingForms.Contributors)}
    >
      <ContributorsFormBase {...sharedProps} />
    </div>
  );
};

ContributorsForm.displayName = 'ContributorsForm';
