import { nameof } from '@kontent-ai/utils';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { OperationStatus } from '../../../../_shared/models/OperationStatus.ts';
import { hasProjectMultipleWorkflows } from '../../../../_shared/selectors/Workflows/hasProjectMultipleWorkflows.ts';
import { createFormValidationResolver } from '../../../../_shared/utils/validation/createFormValidationResolver.ts';
import { createGetWorkflowDescriptionCapitalized } from '../../../../_shared/utils/workflow/getWorkflowDescription.ts';
import { IWebhookSetting } from '../../../../data/models/webhooks/WebhookSetting.ts';
import { WebhookTriggerType } from '../../../../repositories/serverModels/WebhookServerModel.ts';
import {
  archiveWebhook,
  disableWebhook,
  enableWebhook,
  generateWebhookSecret,
  insertWebhook,
  updateWebhook,
} from '../../actions/thunkWebhooksActions.ts';
import {
  webhookEditCancelled,
  webhookEditInitialCancelled,
} from '../../actions/webhooksActions.ts';
import { WebhookItemEditor } from '../../components/webhookBarItems/WebhookItemEditor.tsx';
import { IWebhookFormShape } from '../../models/IWebhookFormShape.type.ts';
import { getTriggerOperationIds } from '../../utils/getTriggerOperationIds.ts';
import { getWorkflowStepTriggers } from '../../utils/getWorkflowStepTriggers.ts';
import { hasWebhookEditorUnsavedChanges } from '../../utils/hasWebhookEditorUnsavedChanges.ts';
import { isWebhookNew } from '../../utils/isWebhookNew.ts';
import {
  ItemContentTriggers,
  ManagementApiContentTriggers,
  PreviewItemContentTriggers,
  PreviewTaxonomyContentTriggers,
  TaxonomyContentTriggers,
  getItemWorkflowStepTriggerIds,
  hasValidWebhookTriggerConfiguration,
} from '../../utils/webhookSettingsTriggers.ts';
import { webhookItemEditorFormValidationConfig } from '../../validation/webhookSettingValidation.ts';

type Props = {
  readonly webhookSetting: IWebhookSetting;
};

const WebhookItemEditorContainer: React.FC<Props> = ({ webhookSetting }) => {
  const workflowStepsInWorkflows = useSelector((state) => {
    const includeWorkflowNameTooltips = hasProjectMultipleWorkflows(state);
    const getWorkflowStepTriggerDescription = createGetWorkflowDescriptionCapitalized(
      state.data.workflows.byId,
      includeWorkflowNameTooltips,
    );

    return getWorkflowStepTriggers(state.data.workflows.byId, getWorkflowStepTriggerDescription);
  });

  const initialValues = useMemo(
    (): IWebhookFormShape => ({
      name: webhookSetting.name,
      secret: webhookSetting.secret,
      url: webhookSetting.url,
      triggers: {
        deliveryApiItemContentTriggerIds: getTriggerOperationIds(
          webhookSetting.deliveryApiContentChangeTriggers,
          WebhookTriggerType.ContentItemVariant,
          ItemContentTriggers,
        ),
        deliveryApiTaxonomyContentTriggerIds: getTriggerOperationIds(
          webhookSetting.deliveryApiContentChangeTriggers,
          WebhookTriggerType.Taxonomy,
          TaxonomyContentTriggers,
        ),
        itemWorkflowStepIds: getItemWorkflowStepTriggerIds(
          webhookSetting.workflowStepChangeTriggers,
        ),
        managementApiItemContentTriggerIds: getTriggerOperationIds(
          webhookSetting.managementApiContentChangeTriggers,
          WebhookTriggerType.ContentItemVariant,
          ManagementApiContentTriggers,
        ),
        previewDeliveryApiItemContentTriggerIds: getTriggerOperationIds(
          webhookSetting.previewDeliveryApiContentChangeTriggers,
          WebhookTriggerType.ContentItemVariant,
          PreviewItemContentTriggers,
        ),
        previewDeliveryApiTaxonomyContentTriggerIds: getTriggerOperationIds(
          webhookSetting.previewDeliveryApiContentChangeTriggers,
          WebhookTriggerType.Taxonomy,
          PreviewTaxonomyContentTriggers,
        ),
      },
    }),
    [webhookSetting],
  );

  const isBeingSaved = useSelector((state) => {
    const { lastOperation } = state.webhooksApp;
    return !!lastOperation && lastOperation.status === OperationStatus.Pending;
  });

  const isTriggerConfigurationValid = useSelector((state) =>
    hasValidWebhookTriggerConfiguration(state, webhookSetting.id),
  );

  const isResettingWebhookInProgress = useSelector(
    (state) => state.webhooksApp.isResettingWebhookInProgress.get(webhookSetting.id) ?? false,
  );

  const isSettingWebhookStateInProgress = useSelector(
    (state) => state.webhooksApp.isSettingWebhookStateInProgress.get(webhookSetting.id) ?? false,
  );

  const isNew = isWebhookNew(webhookSetting);

  const dispatch = useDispatch();

  const onCancelEdit = () => {
    const cancelWebhookAction = isNew ? webhookEditInitialCancelled : webhookEditCancelled;

    dispatch(cancelWebhookAction(webhookSetting.id));
  };

  const onDelete = () => dispatch(archiveWebhook(webhookSetting));
  const onDisable = () => dispatch(disableWebhook(webhookSetting));
  const onEnable = () => dispatch(enableWebhook(webhookSetting));
  const onGenerateNewSecret = useCallback(
    () => dispatch(generateWebhookSecret(webhookSetting.id)),
    [webhookSetting.id],
  );

  const formProps = useForm<IWebhookFormShape>({
    defaultValues: initialValues,
    resolver: createFormValidationResolver(webhookItemEditorFormValidationConfig, {}),
    //  Otherwise it focuses the first input if we manage to tab out from the unsaved changes dialog and submit the form with enter. This was the default behavior with previous redux-form we decided to keep.
    shouldFocusError: false,
  });

  const { formState, handleSubmit, setValue, watch } = formProps;
  const values = watch();

  const hasUnsavedChanges = isNew || hasWebhookEditorUnsavedChanges(initialValues, values);

  const submitForm = handleSubmit((formValues) => {
    const saveWebhookAction = isNew ? insertWebhook : updateWebhook;

    return dispatch(saveWebhookAction(formValues, webhookSetting));
  });

  const generateWebHookSecret = useCallback(async () => {
    const secret = await onGenerateNewSecret();

    setValue(nameof<IWebhookFormShape>('secret'), secret);

    return secret;
  }, [onGenerateNewSecret, setValue]);

  return (
    <WebhookItemEditor
      workflowStepsInWorkflows={workflowStepsInWorkflows}
      enabled={webhookSetting.enabled}
      formProps={formProps}
      hasUnsavedChanges={hasUnsavedChanges}
      healthStatus={webhookSetting.healthStatus}
      isBeingSaved={isBeingSaved}
      isNew={isNew}
      isSubmitSuccessful={formState.isSubmitSuccessful}
      isTriggerConfigurationValid={isTriggerConfigurationValid}
      onCancelEdit={onCancelEdit}
      onDelete={onDelete}
      onDisable={onDisable}
      onEnable={onEnable}
      onGenerateNewSecret={generateWebHookSecret}
      onSubmit={submitForm}
      webhookName={webhookSetting.name}
      isResettingWebhookInProgress={isResettingWebhookInProgress}
      isSettingWebhookStateInProgress={isSettingWebhookStateInProgress}
    />
  );
};

WebhookItemEditorContainer.displayName = 'WebhookItemEditorContainer';
WebhookItemEditorContainer.propTypes = {
  webhookSetting: PropTypes.object.isRequired as any,
};

export { WebhookItemEditorContainer as WebhookItemEditor };
