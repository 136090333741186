import { DOMRectLike } from '@kontent-ai/DOM';
import { BaseBoxProps, Box } from '@kontent-ai/component-library/Box';
import { Spacing, ZIndex, spacingPopupDistance } from '@kontent-ai/component-library/tokens';
import { useAttachRef, useEventListener } from '@kontent-ai/hooks';
import { areShallowEqual } from '@kontent-ai/utils';
import React, {
  ReactNode,
  Ref,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useResizeObserver from 'use-resize-observer';
import {
  DropDownMenuPositioner,
  DropdownTippyOptions,
} from '../../../../../../component-library/components/DropDownMenu/DropDownMenuPositioner.tsx';
import { ScrollContainerContext } from '../../../../../../component-library/components/ScrollContainer/ScrollContainerContext.tsx';
import { usePreventOverflowFromScrollContainer } from '../../../../../../component-library/components/ScrollContainer/usePreventOverflowFromScrollContainer.ts';
import { aiResultHorizontalMargin } from '../../../../../_shared/constants/aiResultHorizontalMargin.ts';
import { Callback, RegisterCallback } from '../../../../../_shared/types/RegisterCallback.type.ts';
import { DataDraftJsAttributes } from '../../../../../_shared/utils/dataAttributes/DataDraftJsAttributes.ts';
import {
  DOMSelectionLike,
  getDomSelection,
  getSelectionDirection,
} from '../../../../../_shared/utils/selectionUtils.ts';
import { EditorSizeHandler } from '../../../components/utility/EditorSizeHandler.tsx';
import { useEditorStateCallbacks } from '../../../editorCore/hooks/useEditorStateCallbacks.ts';
import { GetEditorRef } from '../../../editorCore/hooks/useGetEditorRef.ts';
import { None } from '../../../editorCore/types/Editor.contract.type.ts';
import {
  DecorateWithCallbacks,
  EditorPlugin,
  Render,
} from '../../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../../editorCore/utils/decorable.ts';
import { useSelfPositioningComponentCallback } from '../../toolbars/hooks/useSelfPositioningComponentCallback.tsx';
import {
  ToolbarAttachedTo,
  VerticalPosition,
  getVerticalPosition,
} from '../../toolbars/utils/inlineToolbarPositioningUtils.ts';
import {
  NodePredicate,
  getRelevantRectanglesForPositioning,
  isObjectBlockWrapper,
} from '../../toolbars/utils/toolbarPositioningUtils.ts';
import { StylesPlugin } from '../../visuals/StylesPlugin.tsx';

type PositionerPlugin = EditorPlugin<None, None, None, [StylesPlugin]>;

type UseResultPositionerReturnType = Readonly<{
  decorateWithPositionerCallbacks: DecorateWithCallbacks<PositionerPlugin>;
  resultPositionerProps: Pick<
    ResultPositionerProps,
    'getEditorRef' | 'onMount' | 'registerUpdateSelfPositioningComponent'
  >;
}>;

export const useResultPositioner = (isActive: boolean): UseResultPositionerReturnType => {
  const {
    registerUpdateSelfPositioningComponent,
    updateSelfPositioningComponent,
    onUpdateDecorator,
  } = useSelfPositioningComponentCallback();

  const { blur, decorateWithEditorStateCallbacks, getRteInputRef } =
    useEditorStateCallbacks<PositionerPlugin>();

  const render: Decorator<Render<PositionerPlugin>> = useCallback(
    (baseRender) => (state) => (
      <>
        {baseRender(state)}
        {isActive && (
          <EditorSizeHandler
            editorRef={state.getWrapperRef()}
            onSizeChanged={updateSelfPositioningComponent}
          />
        )}
      </>
    ),
    [isActive, updateSelfPositioningComponent],
  );

  const decorateWithPositionerCallbacks: DecorateWithCallbacks<PositionerPlugin> = useCallback(
    (state) => {
      decorateWithEditorStateCallbacks(state);
      state.onUpdate.decorate(onUpdateDecorator);
      state.render.decorate(render);
    },
    [decorateWithEditorStateCallbacks, onUpdateDecorator, render],
  );

  const resultPositionerProps = useMemo(
    () => ({
      getEditorRef: getRteInputRef,
      /*
      We need to blur the editor so that only locked editor state highlight (green)
      is displayed, and not overlapped by the standard DOM selection highlight
      The positioner makes a snapshot of the DOM selection during its first render
      to be able to position itself after the blur is done
      If the blur was done prematurely (before this snapshot)
      the AI result would not position correctly, that's why we do it at the positioner mount
      */
      onMount: blur,
      registerUpdateSelfPositioningComponent,
    }),
    [blur, getRteInputRef, registerUpdateSelfPositioningComponent],
  );

  return {
    decorateWithPositionerCallbacks,
    resultPositionerProps,
  };
};

type ResultPositionerProps = Readonly<{
  getEditorRef: GetEditorRef<HTMLDivElement>;
  onMount: () => void;
  registerUpdateSelfPositioningComponent: RegisterCallback<Callback>;
  renderResult: (
    isPositionedAboveContent: boolean,
    resultWidth: number,
    resultRef: Ref<HTMLElement>,
  ) => ReactNode;
}>;

export const ResultPositioner: React.FC<ResultPositionerProps> = ({
  getEditorRef,
  onMount,
  registerUpdateSelfPositioningComponent,
  renderResult,
}) => {
  const {
    anchorLeft,
    anchorTop,
    isPositionedAboveContent,
    resultWidth,
    resultRef,
    resultTippyOptions,
  } = useResultPosition(getEditorRef(), registerUpdateSelfPositioningComponent);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <DropDownMenuPositioner
      isDropDownVisible
      renderDropDown={() => renderResult(isPositionedAboveContent, resultWidth, resultRef)}
      renderTrigger={(triggerProps) => (
        <Anchor {...triggerProps} left={anchorLeft} top={anchorTop} />
      )}
      tippyOptions={resultTippyOptions}
    />
  );
};

ResultPositioner.displayName = 'ResultPositioner';

const Anchor = React.forwardRef<HTMLDivElement, BaseBoxProps>((props, ref) => (
  <Box {...props} height={0} position="relative" ref={ref} width={0} />
));

Anchor.displayName = 'Anchor';

const initialResultPosition: ResultPosition = {
  attachedTo: ToolbarAttachedTo.ViewPortUpperSide,
  top: 0,
  left: 0,
  width: 1000 /* item editing paper width */ - 2 * aiResultHorizontalMargin,
};

type ResultPosition = VerticalPosition & {
  readonly left: number;
  readonly width: number;
};

type UseResultPositionReturnType = Readonly<{
  anchorLeft: number;
  anchorTop: number;
  isPositionedAboveContent: boolean;
  resultWidth: number;
  resultTippyOptions: DropdownTippyOptions | null;
  resultRef: Ref<HTMLElement>;
}>;

const useResultPosition = (
  editorInputRef: RefObject<HTMLDivElement>,
  registerUpdatePosition: RegisterCallback<Callback>,
): UseResultPositionReturnType => {
  const [resultPosition, setResultPosition] = useState<ResultPosition>(initialResultPosition);

  // We need to obtain the selection just before mount and hold onto it, because the user may interact with other parts of the UI,
  // and DOM selection may change, but we still need the result to be positioned to the original selection.
  // eslint-disable-next-line react/hook-use-state
  const [domSelection] = useState<DOMSelectionLike | null>(() => getDomSelection());

  const { refObject: resultRef, refToForward: resultCallbackRef } =
    useAttachRef<HTMLDivElement>(undefined);

  const { scrollContainerRef, tippyBoundaryRef } = useContext(ScrollContainerContext);

  const updatePosition = useCallback(() => {
    if (
      !resultRef.current ||
      !editorInputRef.current ||
      !domSelection ||
      domSelection.isCollapsed
    ) {
      return;
    }

    if (domSelection && isSelectionDisconnected(domSelection)) {
      // Stop positioning once a selection gets disconnected from the DOM, as it won’t get reconnected ever back.
      // We do not log the error as it’s a known issue we don't plan to address ASAP. Uncomment this to detect it after it is fixed.
      // logError('The original selection got disconnected because of unexpected DOM modification. The position can no longer be updated.');
      return;
    }

    const newPosition = calculatePosition(
      resultRef.current,
      editorInputRef.current,
      tippyBoundaryRef.current ?? document.body,
      domSelection,
    );
    setResultPosition((existingPosition) =>
      newPosition && !areShallowEqual(newPosition, existingPosition)
        ? newPosition
        : existingPosition,
    );
  }, [domSelection, editorInputRef, resultRef, tippyBoundaryRef]);

  const resultRefToForward = useCallback(
    (result: HTMLDivElement | null) => {
      resultCallbackRef(result);

      if (result) {
        updatePosition();
      }
    },
    [resultCallbackRef, updatePosition],
  );

  useEventListener('scroll', updatePosition, scrollContainerRef.current); // Position is changed from sticking to text to sticking to view port based on scrolling.
  useEventListener('resize', updatePosition, self);
  useResizeObserver({ ref: resultRef, onResize: updatePosition });
  useEffect(() => registerUpdatePosition(updatePosition), [registerUpdatePosition, updatePosition]);

  const { attachedTo, left, top, width } = resultPosition;

  const isPositionedAboveContent =
    attachedTo === ToolbarAttachedTo.TopTextFromRight ||
    attachedTo === ToolbarAttachedTo.TextFromAbove;

  const resultTippyOptions = useResultTippyOptions(attachedTo, isPositionedAboveContent);

  return {
    anchorLeft: left,
    anchorTop: top,
    isPositionedAboveContent,
    resultWidth: width,
    resultTippyOptions,
    resultRef: resultRefToForward,
  };
};

const isSelectionDisconnected = (domSelection: DOMSelectionLike): boolean =>
  domSelection.anchorNode?.isConnected === false || domSelection.focusNode?.isConnected === false;

const useResultTippyOptions = (
  attachedTo: ToolbarAttachedTo,
  isPositionedAboveContent: boolean,
): DropdownTippyOptions => {
  const isDetached =
    attachedTo === ToolbarAttachedTo.ViewPortUpperSide ||
    attachedTo === ToolbarAttachedTo.ViewPortLowerSide;

  const { preventOverflowModifier } = usePreventOverflowFromScrollContainer(spacingPopupDistance);

  return isDetached
    ? {
        placement: 'right-start',
        popperOptions: {
          modifiers: [
            {
              name: 'flip',
              enabled: false,
            },
          ],
          strategy: 'fixed',
        },
        zIndex: ZIndex.SevenHundred,
      }
    : {
        placement: isPositionedAboveContent ? 'top-start' : 'bottom-start',
        offset: [0, Spacing.S],
        popperOptions: {
          modifiers: [preventOverflowModifier],
        },
        zIndex: ZIndex.SevenHundred,
      };
};

const calculatePosition = (
  resultRef: HTMLDivElement,
  editorContentRef: HTMLDivElement,
  scrollParent: Element,
  domSelection: DOMSelectionLike,
): ResultPosition | null => {
  const editorRectangle = editorContentRef.getBoundingClientRect();
  const resultRectangle = resultRef.getBoundingClientRect();
  const scrollParentRectangle = scrollParent.getBoundingClientRect();

  const verticalPosition = getVerticalResultPosition(
    domSelection,
    resultRectangle,
    scrollParentRectangle,
  );

  if (!verticalPosition) {
    return null;
  }

  const contentRectangle =
    editorContentRef
      .querySelector(`[${DataDraftJsAttributes.Contents}]`)
      ?.getBoundingClientRect() ?? editorRectangle;
  return {
    attachedTo: verticalPosition.attachedTo,
    left: contentRectangle.left - editorRectangle.left + aiResultHorizontalMargin,
    top: verticalPosition.top - editorRectangle.top,
    width: contentRectangle.width - 2 * aiResultHorizontalMargin,
  };
};

const getVerticalResultPosition = (
  domSelection: DOMSelectionLike,
  resultRectangle: DOMRectLike,
  scrollParentRectangle: DOMRectLike,
): VerticalPosition | null => {
  if (domSelection.isCollapsed) {
    return null;
  }

  const selectionDirection = getSelectionDirection(domSelection);
  const selectionRepresentingRectangles = getRelevantRectanglesForPositioning(
    domSelection,
    isNodeRelevantForResult,
  );
  if (selectionRepresentingRectangles.length === 0) {
    return null;
  }

  return getVerticalPosition(
    resultRectangle,
    scrollParentRectangle,
    selectionRepresentingRectangles,
    selectionDirection,
  );
};

const isNodeRelevantForResult: NodePredicate = (node) => !isObjectBlockWrapper(node);
