import React from 'react';
import { useLocation, useParams } from 'react-router';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { redirectToSharedPreview } from '../actions/thunkSmartLinkActions.ts';
import { SmartLink } from '../components/SmartLink.tsx';
import { SharedWebSpotlightPreviewParams } from '../constants/routePaths.ts';

enum SharedPreviewSmartLinkQueryParam {
  URL = 'url',
  Width = 'width',
  Height = 'height',
}

export const WebSpotlightSharedPreviewSmartLink: React.FC = () => {
  const dispatch = useDispatch();
  const { itemGuid, variantGuid, spaceId, projectId } =
    useParams<SharedWebSpotlightPreviewParams>();
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const url = searchParams.get(SharedPreviewSmartLinkQueryParam.URL);
  const width =
    Number.parseInt(searchParams.get(SharedPreviewSmartLinkQueryParam.Width) || '', 10) || null;
  const height =
    Number.parseInt(searchParams.get(SharedPreviewSmartLinkQueryParam.Height) || '', 10) || null;

  const onMount = () =>
    dispatch(
      redirectToSharedPreview({
        itemGuid,
        variantGuid,
        spaceId,
        projectId,
        url,
        width,
        height,
      }),
    );

  return <SmartLink onMount={onMount} />;
};

WebSpotlightSharedPreviewSmartLink.displayName = 'WebSpotlightSharedPreviewSmartLink';
