import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { ProjectSettingsSourceTypes } from '../../../../../_shared/models/TrackUserEventData.ts';
import {
  TrackUserEventAllowedDataWithProject,
  TrackUserEventDirectData,
  UserEvent,
} from '../../../../../_shared/services/eventTrackingService.ts';

interface IDeps {
  readonly ApplicationInsights: {
    readonly logEvent: (eventName: UserEvent, metadata: TrackUserEventDirectData) => void;
  };
}

export const createTrackProjectSettingsEventsAction =
  (deps: IDeps) =>
  (projectId: Uuid, source: ProjectSettingsSourceTypes): void => {
    const eventData: TrackUserEventAllowedDataWithProject = {
      source,
      project: projectId,
    };

    deps.ApplicationInsights.logEvent(TrackedEvent.ProjectSettingsClicked, eventData);
  };
