import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '../../../../../_shared/uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import {
  DataUiAction,
  DataUiWorkflowAction,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type CascadeModalFooterStateProps = {
  readonly tooltipText: string | undefined;
  readonly callToAction: string;
};

export type CascadeModalFooterOwnProps = {
  readonly isPublishDisabled: boolean;
  readonly onClose: () => void;
  readonly onPublish: () => void;
};

type CascadeModalFooterProps = CascadeModalFooterStateProps & CascadeModalFooterOwnProps;

const propTypes: PropTypesShape<CascadeModalFooterProps> = {
  callToAction: PropTypes.string.isRequired,
  isPublishDisabled: PropTypes.bool.isRequired,
  tooltipText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onPublish: PropTypes.func.isRequired,
};

export const CascadeModalFooter: React.FC<CascadeModalFooterProps> = (props) => (
  <>
    <Button
      style={ButtonStyle.Secondary}
      dataUiAction={DataUiAction.CloseDialog}
      onClick={props.onClose}
    >
      Cancel
    </Button>
    <Tooltip placement="top-end" tooltipText={props.tooltipText}>
      <Button
        style={ButtonStyle.Primary}
        dataUiAction={DataUiWorkflowAction.Publish}
        disabled={props.isPublishDisabled}
        onClick={props.onPublish}
      >
        {props.callToAction}
      </Button>
    </Tooltip>
  </>
);

CascadeModalFooter.displayName = 'CascadeModalFooter';
CascadeModalFooter.propTypes = propTypes;
