import Immutable from 'immutable';
import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { isUuid } from '../../../../../../_shared/utils/validation/typeValidators.ts';
import { ITaxonomyGroup } from '../../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { createTaxonomyElementErrorChecker } from '../../../../../itemEditor/utils/elementErrorCheckers/taxonomyElementErrorChecker.ts';
import {
  DefaultValueDoesNotMeetLimitations,
  DeletedTaxonomyTermsInDefaultValue,
  ProvideAPositiveNumberForLimit,
} from '../../../constants/errorMessageTemplates.ts';
import { ITaxonomyTypeElementData } from '../../../models/elements/TaxonomyTypeElementData.ts';
import { mapElementErrorToTypeElementErrorResult } from '../../../utils/typeElementValidators/mapElementErrorToTypeElementErrorResult.ts';
import { ITypeElementValidatorDependencies } from '../../../utils/typeElementValidators/types/ITypeElementValidatorDependencies.type.ts';
import { TaxonomyTypeElementValidationResult } from '../../../utils/typeElementValidators/types/TaxonomyTypeElementValidationResult.type.ts';
import { areLimitationsInTaxonomyDefaultValueMet } from '../../../utils/typeElementValidators/utils/areLimitationsInTaxonomyDefaultValueMet.ts';
import { createErrorMessagesFromConditionValidationResult } from '../../../utils/typeElementValidators/utils/conditions/createErrorMessagesFromConditionValidationResult.ts';
import {
  ConditionValidationData,
  getConditionValidationResult,
} from '../../../utils/typeElementValidators/utils/conditions/getConditionValidationResult.ts';

const getTaxonomyElementErrors = createTaxonomyElementErrorChecker(isUuid);

const getTaxonomyTypeElementValidationResult = (
  { typeElementValidationUtils }: ITypeElementValidatorDependencies,
  typeElement: ITaxonomyTypeElementData,
  taxonomyGroupsById: Immutable.Map<Uuid, ITaxonomyGroup>,
  conditionValidationData: ConditionValidationData,
): TaxonomyTypeElementValidationResult => {
  const { getGuidelinesValidationResult, isLimitValueValid, getNameValidationResult } =
    typeElementValidationUtils;

  const guidelinesValidationResult = getGuidelinesValidationResult(typeElement);
  const isSelectedTaxonomyValid = isUuid(typeElement.taxonomyGroupId);
  const isNumberOfTermsValid =
    isLimitValueValid(typeElement.minItems) && isLimitValueValid(typeElement.maxItems);
  const nameValidationResult = getNameValidationResult(typeElement);

  const defaultValueMeetsLimitations = areLimitationsInTaxonomyDefaultValueMet(
    typeElement,
    taxonomyGroupsById,
  );

  const warnings: Array<string> = [];
  const errorMessages: Array<string> = [];
  if (!nameValidationResult.isValid && nameValidationResult.errorMessage) {
    errorMessages.push(nameValidationResult.errorMessage);
  }

  if (!guidelinesValidationResult.isValid && guidelinesValidationResult.errorMessage) {
    errorMessages.push(guidelinesValidationResult.errorMessage);
  }

  if (!isSelectedTaxonomyValid) {
    errorMessages.push('Please select a taxonomy.');
  }

  if (!isNumberOfTermsValid) {
    errorMessages.push(ProvideAPositiveNumberForLimit(' for the number of terms'));
  }

  const defaultValueResult = getTaxonomyElementErrors({
    value: new Set(typeElement.defaultValue),
  });

  errorMessages.push(
    ...mapElementErrorToTypeElementErrorResult(defaultValueResult, typeElement.type).errorMessages,
  );

  const taxonomyGroup = typeElement.taxonomyGroupId
    ? taxonomyGroupsById.get(typeElement.taxonomyGroupId)
    : undefined;
  const defaultValueContainsDeletedTerms =
    !!taxonomyGroup && typeElement.defaultValue.some((termId) => !taxonomyGroup.terms.get(termId));
  if (defaultValueContainsDeletedTerms) {
    errorMessages.push(DeletedTaxonomyTermsInDefaultValue);
  }

  if (!defaultValueMeetsLimitations) {
    warnings.push(DefaultValueDoesNotMeetLimitations);
  }

  const conditionValidationResult = getConditionValidationResult(
    typeElement,
    conditionValidationData,
  );

  return {
    conditionValidationResult,
    isDefaultValueValid: !defaultValueResult.errors.length && !defaultValueContainsDeletedTerms,
    isGuidelinesValid: guidelinesValidationResult.isValid,
    isSelectedTaxonomyValid,
    isNameValid: nameValidationResult.isValid,
    isNumberOfTermsValid,
    errorMessages: [
      ...createErrorMessagesFromConditionValidationResult(conditionValidationResult),
      ...errorMessages,
    ],
    warningMessages: warnings,
  };
};

export const createValidateTaxonomyTypeElementAction =
  (deps: ITypeElementValidatorDependencies) =>
  (typeElement: ITaxonomyTypeElementData): ThunkFunction =>
  (dispatch, getState) => {
    const taxonomyGroupsById = getState().data.taxonomyGroups.byId;
    const validationResult = getTaxonomyTypeElementValidationResult(
      deps,
      typeElement,
      taxonomyGroupsById,
      deps.getConditionValidationData(getState),
    );

    dispatch(deps.typeElementValidated(typeElement.elementId, validationResult));
  };
