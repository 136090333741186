import { useCallback, useMemo } from 'react';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { ApplyEditorStateChanges } from '../../editorCore/types/Editor.base.type.ts';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { Apply, EditorPlugin } from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import { withDisplayName } from '../../editorCore/utils/withDisplayName.ts';

export type ForceSingleUnstyledLinePlugin = EditorPlugin;

const applyEditorStateChanges: Decorator<ApplyEditorStateChanges> =
  (baseApplyEditorStateChanges) => (params) => {
    const allowedNewState = baseApplyEditorStateChanges(params);
    const content = allowedNewState.getCurrentContent();
    const hasSingleBlock = content.getBlockMap().size === 1;
    if (!hasSingleBlock) {
      return params.oldState;
    }
    const hasNewLines = content.getFirstBlock().getText().includes('\n');
    return hasNewLines ? params.oldState : allowedNewState;
  };

export const useForceSingleUnstyledLine: PluginCreator<ForceSingleUnstyledLinePlugin> = (
  baseEditor,
) =>
  useMemo(
    () =>
      withDisplayName('ForceSingleUnstyledLinePlugin', {
        ComposedEditor: (props) => {
          const apply: Apply<ForceSingleUnstyledLinePlugin> = useCallback((state) => {
            state.applyEditorStateChanges.decorate(applyEditorStateChanges);
            return {};
          }, []);

          return useEditorWithPlugin(baseEditor, props, { apply });
        },
      }),
    [baseEditor],
  );
