import React, { useEffect, useMemo } from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getSelectedSubscription } from '../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { subscriptionUserListingConfigStorage } from '../../../../localStorages/subscriptionUserListingConfigStorage.ts';
import { loadSubscriptionUserListingConfig } from '../actions/thunkSubscriptionUsersActions.ts';

export const SubscriptionUserListingConfig: React.FC = () => {
  const selectedSubscriptionId = useSelector(
    (state) => getSelectedSubscription(state)?.subscriptionId ?? '',
  );
  const configStorage = useMemo(
    () => subscriptionUserListingConfigStorage(selectedSubscriptionId),
    [selectedSubscriptionId],
  );

  const filter = useSelector((s) => s.subscriptionApp.users.listingUi.filter);
  const sortBy = useSelector((s) => s.subscriptionApp.users.listingUi.sortBy);

  const dispatch = useDispatch();

  useEffect(() => {
    const config = configStorage.load();
    dispatch(loadSubscriptionUserListingConfig(config));
  }, [configStorage]);

  useEffect(() => {
    configStorage.save({ ...filter, ...sortBy });
  }, [configStorage, filter, sortBy]);

  return null;
};

SubscriptionUserListingConfig.displayName = 'SubscriptionUserListingConfig';
