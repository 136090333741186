import { Icons } from '@kontent-ai/component-library/Icons';
import { getEnumKeyName } from './enumUtils.ts';

export const getVariableName = (object: AnyObject): string => Object.keys(object)[0] ?? 'no-name';

export const getObjectValueName = <T>(object: AnyObject, value: T): string =>
  `${getVariableName(object)}.${getEnumKeyName(Object.values(object)[0], value)}`;

export const getIconName = <T extends keyof typeof Icons>(val: T): string => `Icons.${val}`;

export const dashify = (str?: string): string => {
  if (str) {
    return str
      .replace(/^[A-Z]/, (starterLetter) => starterLetter.toLocaleLowerCase())
      .replace(/[A-Z]+/g, (foundCaps) => {
        const lowercase = foundCaps.toLocaleLowerCase();
        const lastLetter = lowercase[lowercase.length - 1];
        const allButLastLetter = lowercase.slice(0, -1);
        return `${allButLastLetter ? `-${allButLastLetter}` : ''}-${lastLetter}`;
      });
  }
  return '';
};

export const getStringLiteralValue = <T extends string>(value: T): T => value;
