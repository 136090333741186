import React from 'react';
import { ConfirmationDialog } from '../../../../_shared/components/ModalDialog/ConfirmationDialog.tsx';
import { Warning } from '../../../../_shared/components/infos/Warning.tsx';

type Props = {
  readonly spaceName: string;
  readonly onClose: () => void;
  readonly onConfirm: () => void;
};

export const SpaceDeleteConfirmationDialog: React.FC<Props> = ({
  spaceName,
  onClose,
  onConfirm,
}) => {
  return (
    <ConfirmationDialog
      confirmButtonText="Delete"
      headerContent={`Delete ${spaceName ?? ''} space?`}
      onConfirm={onConfirm}
      onClose={onClose}
    >
      <Warning hideTitle>
        <p>
          Deleting this space will disconnect it from Web Spotlight and remove its preview
          configurations.
        </p>
      </Warning>
    </ConfirmationDialog>
  );
};

SpaceDeleteConfirmationDialog.displayName = 'SpaceDeleteConfirmationDialog';
