import { makeCancellablePromise, swallowCancelledPromiseError } from '@kontent-ai/utils';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { loadSelectedContentItemUsage } from '../features/ContentItemInventory/actions/thunkContentItemInventoryActions.ts';

export const useSelectedContentItemUsedIn = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const isSelectedOneItem = useSelector(
    (s) => s.contentApp.listingUi.itemsSelection.selectedItemIds.size === 1,
  );
  const contentItemUsages = useSelector((s) => s.data.listingContentItems.contentItemUsages);
  const rawSelectedItemIds = useSelector(
    (s) => s.contentApp.listingUi.itemsSelection.selectedItemIds,
  );

  const selectedItemIds = useMemo(
    () => new Set(rawSelectedItemIds.toArray()),
    [rawSelectedItemIds],
  );

  useEffect(() => {
    const fetchUsedIn = makeCancellablePromise(() =>
      isSelectedOneItem ? dispatch(loadSelectedContentItemUsage()) : Promise.resolve(),
    )
      .then(() => setIsLoading(false))
      .catch(swallowCancelledPromiseError);
    return fetchUsedIn.cancel;
  }, [isSelectedOneItem]);

  return {
    isLoading,
    contentItemUsages,
    selectedItemIds,
  };
};
