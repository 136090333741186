import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { ContentItemSidebarEditableSection } from '../constants/ContentItemSidebarEditableSection.ts';
import { EditingAction } from '../models/EditingAction.ts';
import { shouldCloseSidebarForm } from './shouldCloseSidebarForm.ts';

export const shouldCloseTask = (state: IStore, taskId: Uuid) => {
  const { editingActions, tasks } = state.contentApp.editorUi;

  const shouldCloseTasksInSidebar = shouldCloseSidebarForm(
    state,
    ContentItemSidebarEditableSection.Tasks,
    taskId,
  );
  const wasTaskFormOpenInEditingActions = editingActions.activatedAction === EditingAction.addTask;

  return (
    (shouldCloseTasksInSidebar || wasTaskFormOpenInEditingActions) &&
    tasks.selectedTaskId === taskId
  );
};
