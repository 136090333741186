import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { AppNames } from '../../../../../_shared/constants/applicationNames.ts';
import {
  CalendarRoute,
  HomeRouteParams,
  MissionControlCalendarRoute,
  MissionControlLanguageDependentRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { VariantIdMacro } from '../../../../../_shared/constants/variantIdValues.ts';
import { DateRange } from '../../../../../_shared/models/DateRange.type.ts';
import { TrackUserEventWithDataAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { CalendarEventTypes } from '../../../../../_shared/models/TrackUserEventData.ts';
import { HotjarTag, tagRecording } from '../../../../../_shared/utils/hotjarUtils.ts';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { contentItemsBreadcrumbsOriginChanged } from '../../../../contentInventory/shared/actions/contentInventoryActions.ts';
import { getFormattedDateRangeForGivenDateEntry } from '../../utils/calendarUtils.ts';
import {
  calendarInitializationFinished,
  calendarInitializationStarted,
} from '../calendarActions.ts';

interface IDeps {
  readonly loadItemsMatchingFilterWithVariantActiveInPeriod: (
    period: DateRange,
    abortSignal?: AbortSignal,
  ) => ThunkPromise;
  readonly trackUserEventWithData: TrackUserEventWithDataAction;
}

export const createInitializeCalendarAction =
  (deps: IDeps) =>
  (isUnifiedMissionControlEnabled: boolean, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      sharedApp: { currentProjectId },
      calendarApp: { selectedPeriod },
      contentApp: {
        listingUi: { filter },
      },
    } = getState();

    dispatch(calendarInitializationStarted());

    const calendarPath = isUnifiedMissionControlEnabled
      ? buildPath<MissionControlLanguageDependentRouteParams>(MissionControlCalendarRoute, {
          projectId: currentProjectId,
          variantId: VariantIdMacro,
        })
      : buildPath<HomeRouteParams>(CalendarRoute, {
          projectId: currentProjectId,
          variantId: VariantIdMacro,
        });

    dispatch(
      contentItemsBreadcrumbsOriginChanged({
        path: calendarPath,
        title: AppNames.Calendar,
      }),
    );

    const period =
      selectedPeriod.from === selectedPeriod.to
        ? getFormattedDateRangeForGivenDateEntry(selectedPeriod.from)
        : selectedPeriod;

    dispatch(calendarInitializationFinished(filter));

    await dispatch(deps.loadItemsMatchingFilterWithVariantActiveInPeriod(period, abortSignal));

    tagRecording(HotjarTag.CalendarOpened);
    dispatch(
      deps.trackUserEventWithData(TrackedEvent.Calendar, {
        action: CalendarEventTypes.CalendarOpened,
      }),
    );
  };
