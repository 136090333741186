import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ContentItemId } from '../../../../../_shared/models/ContentItemId.type.ts';
import { ActiveCapabilityType } from '../../../../../_shared/models/activeCapability.type.ts';
import { getListingContentItem } from '../../../../../_shared/selectors/getListingContentItem.ts';
import { ScrollTableCell } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableCell.tsx';
import { ScrollTableDefaultRowSkelet } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableDefaultRowSkelet.tsx';
import { ScrollTableRow } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableRow.tsx';
import { getContentItemPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { RedactedItemName } from '../../constants/uiConstants.ts';
import {
  DeletedItemNameCellContent,
  ItemCollectionCellContent,
  ItemNameCellContent,
  ItemTypeCellContent,
  OpenInNewTabCell,
  RedactedItemNameCellContent,
  WorkflowStatusWithFallbacksCellContent,
  getTextForTableCell,
} from '../../features/ContentItemInventory/components/ItemInventoryScrollTable/Cells/ContentItemScrollTableCellsContents.tsx';

export interface IFailedItemRowDataProps {
  readonly contentItemId: ContentItemId;
  readonly showCollectionColumn: boolean;
}

export const FailedItemRow: React.ComponentType<IFailedItemRowDataProps> = ({
  contentItemId,
  showCollectionColumn,
}) => {
  const { pathname } = useLocation();

  const { variantId } = contentItemId;

  const item = useSelector((s) => getListingContentItem(s, contentItemId));
  const itemType = useSelector((s) =>
    s.data.contentTypes.byId.get(item?.item.typeId ?? ''),
  ) as IContentType | null;
  const itemCollection = useSelector((s) =>
    s.data.collections.byId.get(item?.item.collectionId ?? ''),
  );

  if (!item) {
    return <ScrollTableDefaultRowSkelet />;
  }

  if (item.item.archived) {
    return (
      <ScrollTableRow objectName={item.item.name}>
        <ScrollTableCell size={10} isGrowing title={item.item.name}>
          <DeletedItemNameCellContent item={item} />
        </ScrollTableCell>
      </ScrollTableRow>
    );
  }

  const canView = item.activeCapabilities.variantCapabilities.includes(
    ActiveCapabilityType.ViewContent,
  );
  if (!canView) {
    return (
      <ScrollTableRow objectName={RedactedItemName}>
        <ScrollTableCell size={10} isGrowing title={RedactedItemName}>
          <RedactedItemNameCellContent />
        </ScrollTableCell>
      </ScrollTableRow>
    );
  }

  const itemPath = getContentItemPath(pathname, item.item.id, variantId);
  const collectionName = itemCollection?.name;

  return (
    <ScrollTableRow objectName={item.item.name}>
      <ScrollTableCell size={10} isGrowing title={item.item.name}>
        <ItemNameCellContent item={item} variantId={variantId} />
      </ScrollTableCell>

      <OpenInNewTabCell path={itemPath} />

      <ScrollTableCell size={4} isGrowing>
        <WorkflowStatusWithFallbacksCellContent
          variant={item.variant}
          itemId={contentItemId.itemId}
        />
      </ScrollTableCell>

      <ScrollTableCell size={4} isGrowing title={getTextForTableCell(itemType?.name)}>
        <ItemTypeCellContent itemType={itemType} />
      </ScrollTableCell>

      {showCollectionColumn && (
        <ScrollTableCell size={4} isGrowing title={getTextForTableCell(collectionName)}>
          <ItemCollectionCellContent collectionName={collectionName} />
        </ScrollTableCell>
      )}
    </ScrollTableRow>
  );
};

FailedItemRow.displayName = 'FailedItemRow';
