import { Input, InputState } from '@kontent-ai/component-library/Input';
import React, { useEffect } from 'react';
import { useParams, useRouteMatch } from 'react-router';
import { modalOpened } from '../../../../../_shared/actions/sharedActions.ts';
import { Loader } from '../../../../../_shared/components/Loader.tsx';
import { AuthorizedSection } from '../../../../../_shared/components/routing/AuthorizedSection.tsx';
import { ModalDialogType } from '../../../../../_shared/constants/modalDialogType.ts';
import {
  ApiKeyDetailAccessDeniedRouteSegment,
  ApiKeyDetailRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ApiKeyType } from '../../../../../_shared/models/ApiKeyType.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getCurrentProjectContainer } from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { apiKeyDetailsAlertDismissed } from '../../actions/apiKeysActions.ts';
import { initApiKey } from '../../actions/thunkApiKeysActions.ts';
import { ApiKeyDetailLayout } from '../../components/ApiKeyDetail/ApiKeyDetailLayout.tsx';
import { MapiKeyDetail } from '../../components/ApiKeyDetail/MapiKeyDetail.tsx';
import { ReadonlyMapiEnvironmentsSelector } from '../../components/ApiKeyDetail/ReadonlyMapiEnvironmentsSelector.tsx';
import { emptyApiKey } from '../../models/ApiKey.ts';
import { ApiKeyActionStatus } from '../../reducers/IApiKeysAppStoreState.type.ts';
import { getAllEnvironmentOptions } from '../../selectors/getSelectedEnvironmentOptions.ts';

export const PersonalMapiKeyDetail: React.FC = () => {
  const { tokenSeedId } = useParams<ApiKeyDetailRouteParams>();
  const { url } = useRouteMatch();

  const loadingStatus = useSelector((state) => state.apiKeysApp.detailLoadingStatus);
  const actionStatus = useSelector((state) => state.apiKeysApp.detailActionStatus);
  const apiKey = useSelector((state) => state.data.apiKeys.keyDetail);
  const currentProject = useSelector(getCurrentProjectContainer);
  const allEnvironmentOptions = useSelector((state) =>
    getAllEnvironmentOptions(state, ApiKeyType.MAPIPat),
  );

  const dispatch = useDispatch();
  const onRegenerate = () => dispatch(modalOpened(ModalDialogType.ApiKeyRegenerationDialog));

  useEffect(() => {
    dispatch(initApiKey(tokenSeedId, url));
  }, [tokenSeedId, url]);

  const acceptableApiKeyTypes = [ApiKeyType.MAPIPat, emptyApiKey.type];
  if (loadingStatus !== LoadingStatus.Loaded || !acceptableApiKeyTypes.includes(apiKey.type)) {
    return <Loader />;
  }

  const isLoadingFailed =
    loadingStatus === LoadingStatus.Loaded && actionStatus === ApiKeyActionStatus.LoadingFailed;

  return (
    <AuthorizedSection
      appName={ApiKeyDetailAccessDeniedRouteSegment}
      isAuthorized={!isLoadingFailed}
      projectId={currentProject.masterEnvironmentId}
      projectContainerId={currentProject.projectContainerId}
    >
      <ApiKeyDetailLayout
        apiKeyName={apiKey.name}
        apiKeyActionStatus={actionStatus}
        onAlertDismiss={() => dispatch(apiKeyDetailsAlertDismissed())}
      >
        <MapiKeyDetail
          apiKey={apiKey}
          onRegenerate={onRegenerate}
          renderEnvironmentsSelector={() => (
            <ReadonlyMapiEnvironmentsSelector
              allEnvironmentOptions={allEnvironmentOptions}
              apiKey={apiKey}
            />
          )}
          renderNameInput={() => (
            <Input
              label="Name"
              defaultValue={apiKey.name}
              inputState={InputState.ReadOnly}
              tooltipPlacement="top"
              tooltipText="You cannot rename the API key."
              {...getDataUiInputAttribute(DataUiInput.EntityName)}
            />
          )}
        />
      </ApiKeyDetailLayout>
    </AuthorizedSection>
  );
};

PersonalMapiKeyDetail.displayName = 'PersonalMapiKeyDetail';
