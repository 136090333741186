import {
  Dispatch,
  GetState,
  ThunkFunction,
  ThunkPromise,
} from '../../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IAssetTypeRepository } from '../../../../../repositories/interfaces/IAssetTypeRepository.type.ts';
import {
  ServerApiErrorCode,
  tryParseApiError,
} from '../../../../../repositories/serverModels/ServerApiError.ts';
import { saveEditedTypeStarted } from '../../../shared/actions/sharedContentModelsActions.ts';
import { IBaseTypeElementData } from '../../../shared/models/elements/types/TypeElementData.ts';
import { isTypeValid } from '../../../shared/utils/typeValidationUtils.ts';
import {
  AssetType_Editor_SavingFailed,
  AssetType_Editor_SavingFinished,
} from '../../constants/assetTypeActionTypes.ts';
import { TheAssetFeatureIsDowngraded } from '../../constants/messages.ts';
import {
  convertAssetTypeToServerModel,
  getAssetTypeFromServerModel,
} from '../../utils/assetTypeServerModelConvertors.ts';
import { assetTypeInvalidElementShowWarning } from '../assetTypeActions.ts';

interface IDeps {
  readonly assetTypeRepository: Pick<IAssetTypeRepository, 'updateAssetType'>;
  readonly loadAssetType: () => ThunkPromise;
  readonly validateTypeElement: (updatedTypeElement: IBaseTypeElementData) => ThunkFunction;
}

export const saveEditedAssetTypeFinished = (contentType: IContentType) =>
  ({
    type: AssetType_Editor_SavingFinished,
    payload: {
      contentType,
    },
  }) as const;

const saveEditedAssetTypeFailed = (errorMessage: string) =>
  ({
    type: AssetType_Editor_SavingFailed,
    payload: {
      errorMessage,
    },
  }) as const;

export type SaveEditedAssetTypeActionsType =
  | ReturnType<typeof saveEditedAssetTypeFinished>
  | ReturnType<typeof saveEditedAssetTypeFailed>;

export const createSaveAssetTypeAction =
  (deps: IDeps) =>
  (onSuccess?: () => void, onFail?: () => void): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const editedAssetType = getState().contentModelsApp.typeEditor.editedType;
    editedAssetType.typeElements.forEach((element) => dispatch(deps.validateTypeElement(element)));

    if (!isTypeValid(getState().contentModelsApp.typeEditor.validationResults)) {
      dispatch(assetTypeInvalidElementShowWarning());
      onFail?.();
      return;
    }

    try {
      dispatch(saveEditedTypeStarted());
      const assetTypeForServer = convertAssetTypeToServerModel(editedAssetType);
      const updatedAssetTypeServerModel =
        await deps.assetTypeRepository.updateAssetType(assetTypeForServer);
      const updatedAssetType = getAssetTypeFromServerModel(updatedAssetTypeServerModel);
      dispatch(saveEditedAssetTypeFinished(updatedAssetType));

      onSuccess?.();
      dispatch(trackUserEvent(TrackedEvent.AssetTypeUpdated));

      // load new compiled asset type to have the newest data everywhere we use defaultAssetType
      dispatch(deps.loadAssetType());
    } catch (error) {
      if (tryParseApiError(error)?.code === ServerApiErrorCode.MissingPaidFeature) {
        dispatch(saveEditedAssetTypeFailed(TheAssetFeatureIsDowngraded));
      }
      onFail?.();
    }
  };
