import { createGuid } from '@kontent-ai/utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { ReactNode, useRef } from 'react';
import { getDataAttributeProps } from '../../utils/dataAttributes/DataUiAttributes.ts';

export interface ILabelForProps {
  readonly children?: ReactNode;
  readonly className?: string;
  readonly isBold?: boolean;
  readonly isHidden?: boolean;
  readonly target: (inputId: Uuid, labelId: Uuid) => React.ReactNode;
}

const propTypes: PropTypeMap<ILabelForProps> = {
  children: PropTypes.node,
  className: PropTypes.string,
  isBold: PropTypes.bool,
  isHidden: PropTypes.bool,
  target: PropTypes.func.isRequired,
};

export const LabelFor: React.FC<ILabelForProps> = (props) => {
  const { children, className, isBold, isHidden, target } = props;
  const { current: inputId } = useRef(createGuid());
  const { current: labelId } = useRef(createGuid());

  return (
    <>
      {children && (
        <label
          id={labelId}
          htmlFor={inputId}
          className={classNames('form__label', className, {
            'form__label--is-bold': isBold,
            'sr-only': isHidden,
          })}
          {...getDataAttributeProps(props)}
        >
          {children}
        </label>
      )}
      {target(inputId, labelId)}
    </>
  );
};

LabelFor.displayName = 'LabelFor';
LabelFor.propTypes = propTypes;
