import React from 'react';
import { ICompiledContentType } from '../../../../contentInventory/content/models/CompiledContentType.ts';
import { ContentGroupTabs } from '../containers/ContentGroupTabs.tsx';
import { ContentItemCollection } from '../containers/elements/ContentItemCollection.tsx';
import { ItemNameElement } from '../containers/elements/ItemNameElement.tsx';
import { CreateContentGroupTabsId } from '../utils/contentGroupTabsId.ts';
import { ContentItemPaneElementsGroup } from './ContentItemPaneElementsGroup.tsx';

export interface IContentItemHeaderDataProps {
  readonly areAnyContentGroupsVisible: boolean;
  readonly canEditGroupContent: boolean;
  readonly canEditName: boolean;
  readonly contentItemId: Uuid;
  readonly contentType: ICompiledContentType;
  readonly isCollectionInMainPaneEnabled: boolean;
}

export const ContentItemHeader: React.FC<IContentItemHeaderDataProps> = ({
  areAnyContentGroupsVisible,
  canEditGroupContent,
  canEditName,
  contentItemId,
  contentType,
  isCollectionInMainPaneEnabled,
}) => {
  const contentGroupTabsId = CreateContentGroupTabsId.forContentItem(contentItemId);

  return (
    <>
      <ContentItemPaneElementsGroup
        isDisabled={!canEditName}
        hasTopRoundedCorners
        hasBottomRoundedCorners={areAnyContentGroupsVisible || isCollectionInMainPaneEnabled}
      >
        <ItemNameElement />
        {isCollectionInMainPaneEnabled && <ContentItemCollection />}
      </ContentItemPaneElementsGroup>

      {areAnyContentGroupsVisible && (
        <ContentItemPaneElementsGroup areHeaderContentGroups isDisabled={!canEditGroupContent}>
          <ContentGroupTabs
            canEditContent={canEditGroupContent}
            contentGroupTabsId={contentGroupTabsId}
            contentType={contentType}
          />
        </ContentItemPaneElementsGroup>
      )}
    </>
  );
};

ContentItemHeader.displayName = 'ContentItemHeader';
