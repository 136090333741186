import { memoize } from '@kontent-ai/memoization';
import { MemoizedContentItemId } from '../../../../../_shared/models/ContentItemId.type.ts';
import { getMemoizedContentItemId } from '../../../../../_shared/models/utils/contentItemIdUtils.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { IListingContentItems } from '../../../../../data/reducers/listingContentItems/IListingContentItems.type.ts';
import { ICompiledContentItemElementData } from '../../../models/contentItemElements/ICompiledContentItemElement.ts';
import {
  ReferenceType,
  getReferencedContentItemIds,
} from '../../../utils/itemReferences/itemElementReferencesUtils.ts';
import { shouldChildItemBeRendered } from '../utils/shouldChildItemBeRendered.ts';

const getFirstLevelChildrenItemIdsMemoized = memoize.weak(
  (
    editedContentItemVariantElements: ReadonlyArray<ICompiledContentItemElementData>,
    listingContentItems: IListingContentItems,
    editedItemId: Uuid,
    editedVariantId: Uuid,
  ): ReadonlySet<MemoizedContentItemId> => {
    const firstLevelChildrenIds = getReferencedContentItemIds(
      editedContentItemVariantElements,
      ReferenceType.ModularItems,
    )
      .filter((itemId: Uuid) =>
        shouldChildItemBeRendered({
          item: listingContentItems.byId.get(itemId),
          itemVariantId: editedVariantId,
          parentItemId: editedItemId,
          parentVariantId: editedVariantId,
          editedItemId,
        }),
      )
      .map((itemId: Uuid) => getMemoizedContentItemId(itemId, editedVariantId))
      .toArray();

    return new Set(firstLevelChildrenIds);
  },
);

export const getFirstLevelChildrenItemIds = (state: IStore): ReadonlySet<MemoizedContentItemId> => {
  const {
    contentApp: { editedContentItem, editedContentItemVariant, editedContentItemVariantElements },
    data: { listingContentItems },
  } = state;

  if (!editedContentItem || !editedContentItemVariant) {
    return new Set();
  }

  return getFirstLevelChildrenItemIdsMemoized(
    editedContentItemVariantElements,
    listingContentItems,
    editedContentItem.id,
    editedContentItemVariant.id.variantId,
  );
};
