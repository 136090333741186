import { History } from 'history';
import { Dispatch, GetState, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { showErrorNotification } from '../../../../../_shared/actions/sharedActions.ts';
import {
  EditTaxonomyGroupRoute,
  EditTaxonomyGroupRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { createTaxonomyGroupServerModel } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { ITaxonomyGroupServerModel } from '../../../../../repositories/serverModels/contentModels/TaxonomyGroupServerModel.type.ts';
import { TaxonomyTermEditorIsNotConfirmed } from '../../../shared/constants/errorMessageTemplates.ts';
import { CreateTaxonomyGroupErrorMessage } from '../../constants/uiConstants.ts';
import {
  taxonomyGroupCreationFailed,
  taxonomyGroupCreationFinished,
  taxonomyGroupCreationStarted,
} from '../taxonomyActions.ts';

interface ICreateNewTaxonomyGroupActionDependencies {
  readonly taxonomyRepository: {
    readonly createTaxonomyGroup: (
      taxonomyGroup: ITaxonomyGroupServerModel,
    ) => Promise<ITaxonomyGroupServerModel>;
  };
  readonly trackUserEvent: TrackUserEventWithDataAction;
  readonly loadTaxonomyGroups: () => ThunkPromise;
}

export const createCreateNewTaxonomyGroupAction =
  ({
    taxonomyRepository,
    trackUserEvent,
    loadTaxonomyGroups,
  }: ICreateNewTaxonomyGroupActionDependencies) =>
  (history: History, onSuccess?: () => void, onFail?: () => void): ThunkPromise<void> =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const { editedTaxonomyGroup, editedTaxonomyTermId, isCreateNewFormDisplayed } =
      getState().taxonomyApp;
    const { currentProjectId } = getState().sharedApp;

    if (editedTaxonomyTermId || isCreateNewFormDisplayed) {
      dispatch(showErrorNotification(TaxonomyTermEditorIsNotConfirmed));
      if (onFail) {
        onFail();
      }
      return;
    }

    dispatch(taxonomyGroupCreationStarted());

    const newTaxonomyGroup = createTaxonomyGroupServerModel(editedTaxonomyGroup);

    try {
      const taxonomyGroup = await taxonomyRepository.createTaxonomyGroup(newTaxonomyGroup);

      dispatch(taxonomyGroupCreationFinished(taxonomyGroup));
      dispatch(
        trackUserEvent(TrackedEvent.TaxonomyGroupCreated, {
          'taxonomy-group-id': taxonomyGroup.id,
        }),
      );
      dispatch(loadTaxonomyGroups());

      if (onSuccess) {
        onSuccess();
      } else {
        history.push(
          buildPath<EditTaxonomyGroupRouteParams>(EditTaxonomyGroupRoute, {
            projectId: currentProjectId,
            taxonomyGroupId: taxonomyGroup.id,
          }),
        );
      }
    } catch {
      dispatch(taxonomyGroupCreationFailed(CreateTaxonomyGroupErrorMessage));
      if (onFail) {
        onFail();
      }
    }
  };
