import { Collection } from '@kontent-ai/utils';
import { History } from 'history';
import React, { useEffect } from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { isCollectionsConfigurationVisible } from '../../../../_shared/selectors/contentCollections.ts';
import { isAssetTypeEnabled as isAssetTypeEnabledSelector } from '../../../../_shared/selectors/enhancedAssetManagement.ts';
import {
  createNewRoleLink,
  createRoleEditingLink,
} from '../../../../_shared/utils/routing/projectSubscriptionRoutingUtils.ts';
import {
  getCurrentProjectPlan,
  getCurrentProjectSubscription,
} from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { isSitemapEnabled as isSitemapEnabledUtil } from '../../../contentModels/sitemap/utils/sitemapUtils.ts';
import {
  areSpacesEnabledForCurrentProject,
  isAuditLogEnabledForCurrentProjectPlan,
} from '../../selectors/allowedFeaturesUtils.ts';
import { createRole, initRoleCreator } from '../actions/thunkRolesActions.ts';
import { RoleEditor as RoleEditorComponent } from '../components/RoleEditor.tsx';
import { RoleEditorStatus } from '../models/RoleEditorStatus.ts';
import { getCustomRolesLimitInfo } from '../selectors/customRoleLimit.ts';
import { canCreateNewCustomRole } from '../utils/customRolesUtils.ts';

interface IOwnProps {
  readonly history: History;
  readonly projectId: Uuid;
  readonly withSubscriptionIdInRoute?: Uuid;
}

export const RoleCreator: React.FC<IOwnProps> = ({
  history,
  projectId,
  withSubscriptionIdInRoute,
}) => {
  const areCollectionsEnabled = useSelector((s) =>
    isCollectionsConfigurationVisible(s, Collection.getValues(s.data.collections.byId)),
  );
  const areSpacesEnabled = useSelector(areSpacesEnabledForCurrentProject);
  const isAuditLogEnabled = useSelector(isAuditLogEnabledForCurrentProjectPlan);
  const isAssetTypeEnabled = useSelector((s) =>
    isAssetTypeEnabledSelector(getCurrentProjectPlan(s), s.data.assetTypes.defaultAssetType),
  );
  const isSitemapEnabled = useSelector((s) =>
    isSitemapEnabledUtil(getCurrentProjectSubscription(s)),
  );
  const editedRoleName = useSelector((s) => s.rolesApp.editorUi.editedRole.name);
  const canCreateNew = useSelector((s) => canCreateNewCustomRole(getCustomRolesLimitInfo(s)));
  const status = useSelector((s) => s.rolesApp.editorUi.status);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initRoleCreator(history, projectId, withSubscriptionIdInRoute));
  }, [history, projectId, withSubscriptionIdInRoute]);

  const save = (onSuccess?: () => void, onFail?: () => void) => {
    dispatch(
      createRole({
        history,
        getRoleEditingRoute: (roleId: Uuid) =>
          createRoleEditingLink({
            projectId,
            subscriptionId: withSubscriptionIdInRoute,
            roleId,
          }),
        onSuccess,
        onFail,
      }),
    );
  };

  const onControlSHandler = (e: KeyboardEvent): void => {
    e.preventDefault();

    if (canCreateNew && status !== RoleEditorStatus.IsBeingSaved) {
      save();
    }
  };

  return (
    <RoleEditorComponent
      areCollectionsEnabled={areCollectionsEnabled}
      areSpacesEnabled={areSpacesEnabled}
      editedRoleName={editedRoleName}
      isAssetTypeEnabled={isAssetTypeEnabled}
      isAuditLogEnabled={isAuditLogEnabled}
      isRoleBeingCreated
      isSitemapEnabled={isSitemapEnabled}
      newRoleLink={createNewRoleLink({
        projectId,
        subscriptionId: withSubscriptionIdInRoute,
      })}
      onControlSHandler={onControlSHandler}
      onSave={save}
      projectId={projectId}
      readonly={!canCreateNew}
      status={status}
    />
  );
};
