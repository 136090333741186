import { Button } from '@kontent-ai/component-library/Button';
import { Icon, Icons } from '@kontent-ai/component-library/Icons';
import React, { ComponentProps } from 'react';
import {
  trackUserEvent,
  trackUserEventWithData,
} from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import {
  CancelScheduling,
  ChangeUnpublishingOptions,
  ChangeWorkflowStep,
  ChangeWorkflowStepOrPublish,
  CreateNewVersion,
  Publish,
  RestoreFromArchivedStep,
  Unpublish,
} from '../../../../../../_shared/constants/itemActions.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import {
  openPublishModal,
  openRestoreItemFromArchivedStepModal,
  openUnpublishAndArchiveModal,
  openUpdateStepModal,
} from '../../../../../../_shared/features/ChangeWorkflowStepModal/actions/thunkChangeWorkflowStepModalActions.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { ActiveCapabilityType } from '../../../../../../_shared/models/activeCapability.type.ts';
import {
  ContentItemEditingEventOrigins,
  ContentItemEditingEventTypes,
} from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import {
  isArchivedWorkflowStep,
  isPublishedWorkflowStep,
  isScheduleToPublishWorkflowStepSelected,
  isScheduledToPublishWorkflowStep,
} from '../../../../../../_shared/utils/contentItemUtils.ts';
import { isVariantPublished } from '../../../../../../_shared/utils/contentItemVariantUtils.ts';
import {
  DataUiWorkflowAction,
  getDataUiActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getWorkflow } from '../../../../../../data/reducers/workflow/selectors/workflowSelectors.ts';
import { getSelectableWorkflowStepsForEditedVariant } from '../../../../../contentInventory/content/features/ContentItemInventory/selectors/getSelectableWorkflowSteps.ts';
import { getNameOfTheFirstWorkflowStepCurrentRoleCanWorkWith } from '../../../../selectors/getNameOfTheFirstWorkflowStepCurrentRoleCanWorkWith.ts';
import { createNewVersion } from '../../actions/thunkContentItemEditingActions.ts';

const WorkflowStepActionButton: React.FC<
  Readonly<{
    buttonStyle: 'primary' | 'tertiary';
    destructive?: true;
    dataUiAction: DataUiWorkflowAction;
    icon?: Icon;
    label: string;
    onClick: ComponentProps<typeof Button>['onClick'];
  }>
> = ({ dataUiAction, icon, label, ...props }) => (
  <Button buttonDisplay="block" {...props} {...getDataUiActionAttribute(dataUiAction)}>
    {icon && <Button.Icon icon={icon} />}
    <Button.Label
      css={`
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      `}
    >
      {label}
    </Button.Label>
  </Button>
);

WorkflowStepActionButton.displayName = 'WorkflowStepActionButton';

const CreateNewVersionButton: React.FC = () => {
  const dispatch = useDispatch();
  const createNew = async () => {
    await dispatch(
      createNewVersion(() => dispatch(trackUserEvent(TrackedEvent.ContentEntryNewVersion))),
    );
    dispatch(
      trackUserEventWithData(TrackedEvent.ContentItemEditing, {
        action: ContentItemEditingEventTypes.CreateANewVersion,
        origin: ContentItemEditingEventOrigins.ItemDetails,
      }),
    );
  };

  return (
    <WorkflowStepActionButton
      buttonStyle="primary"
      dataUiAction={DataUiWorkflowAction.CreateNewVersion}
      label={CreateNewVersion}
      onClick={createNew}
    />
  );
};

CreateNewVersionButton.displayName = 'CreateNewVersionButton';

const ChangeWorkflowStepButton: React.FC = () => {
  const dispatch = useDispatch();
  const dispatchUpdateStepModal = () => {
    dispatch(openUpdateStepModal(ContentItemEditingEventOrigins.ItemDetails));
  };

  return (
    <WorkflowStepActionButton
      buttonStyle="primary"
      dataUiAction={DataUiWorkflowAction.OpenWorkflowStepModal}
      icon={Icons.ArrowDoubleRight}
      label={ChangeWorkflowStep}
      onClick={dispatchUpdateStepModal}
    />
  );
};

ChangeWorkflowStepButton.displayName = 'ChangeWorkflowStepButton';

const ChangeWorkflowStepOrPublishButton: React.FC = () => {
  const dispatch = useDispatch();
  const dispatchUpdateStepModal = () => {
    dispatch(openUpdateStepModal(ContentItemEditingEventOrigins.ItemDetails));
  };

  return (
    <WorkflowStepActionButton
      buttonStyle="primary"
      dataUiAction={DataUiWorkflowAction.OpenWorkflowStepModal}
      icon={Icons.ArrowDoubleRight}
      label={ChangeWorkflowStepOrPublish}
      onClick={dispatchUpdateStepModal}
    />
  );
};

ChangeWorkflowStepOrPublishButton.displayName = 'ChangeWorkflowStepOrPublishButton';

const PublishButton: React.FC = () => {
  const dispatch = useDispatch();
  const dispatchOpenPublishModal = () => {
    dispatch(openPublishModal(ContentItemEditingEventOrigins.ItemDetails));
  };

  return (
    <WorkflowStepActionButton
      buttonStyle="primary"
      dataUiAction={DataUiWorkflowAction.Publish}
      icon={Icons.PaperPlane}
      label={Publish}
      onClick={dispatchOpenPublishModal}
    />
  );
};

PublishButton.displayName = 'PublishButton';

const CancelSchedulingButton: React.FC<
  Readonly<{
    onCancel: () => void;
  }>
> = ({ onCancel }) => (
  <WorkflowStepActionButton
    buttonStyle="primary"
    dataUiAction={DataUiWorkflowAction.CancelSchedule}
    destructive
    label={CancelScheduling}
    onClick={onCancel}
  />
);

CancelSchedulingButton.displayName = 'CancelSchedulingButton';

const RestoreFromArchivedStepButton: React.FC = () => {
  const dispatch = useDispatch();
  const firstWorkflowStepCurrentRoleCanWorkWithName = useSelector(
    getNameOfTheFirstWorkflowStepCurrentRoleCanWorkWith,
  );
  const restoreFromArchivedStep = () =>
    dispatch(openRestoreItemFromArchivedStepModal(ContentItemEditingEventOrigins.ItemDetails));

  return (
    <WorkflowStepActionButton
      buttonStyle="primary"
      dataUiAction={DataUiWorkflowAction.RestoreFromArchivedStep}
      label={RestoreFromArchivedStep(firstWorkflowStepCurrentRoleCanWorkWithName)}
      onClick={restoreFromArchivedStep}
    />
  );
};

RestoreFromArchivedStepButton.displayName = 'RestoreFromArchivedStepButton';

const AssignmentSectionPrimaryAction: React.FC<
  Readonly<{
    onCancelSchedulingConfirmationDialog: () => void;
  }>
> = ({ onCancelSchedulingConfirmationDialog }) => {
  const variantCapabilities = useSelector(
    (state) => state.contentApp.editorUi.activeCapabilities.variantCapabilities,
  );
  const editedContentItemVariant = useSelector(
    (state) => state.contentApp.editedContentItemVariant,
  );
  const selectableWorkflowSteps = useSelector((state) => {
    if (!state.contentApp.editedContentItemVariant) {
      return [];
    }
    const currentWorkflow = getWorkflow(
      state,
      state.contentApp.editedContentItemVariant.assignment.workflowStatus.workflowId,
    );
    return getSelectableWorkflowStepsForEditedVariant(
      state.contentApp.editedContentItemVariant,
      currentWorkflow ?? null,
    );
  });

  if (!editedContentItemVariant) {
    return null;
  }

  const canCancelSchedule = variantCapabilities.includes(ActiveCapabilityType.CancelSchedule);
  if (isScheduledToPublishWorkflowStep(editedContentItemVariant.assignment.workflowStatus)) {
    return canCancelSchedule ? (
      <CancelSchedulingButton onCancel={onCancelSchedulingConfirmationDialog} />
    ) : null;
  }

  const canCreateNewVersion = variantCapabilities.includes(ActiveCapabilityType.CreateNewVersion);
  if (isPublishedWorkflowStep(editedContentItemVariant.assignment.workflowStatus)) {
    return canCreateNewVersion ? <CreateNewVersionButton /> : null;
  }

  // all the following need update assignment capability
  const canUpdateAssignment = variantCapabilities.includes(ActiveCapabilityType.UpdateAssignment);
  if (!canUpdateAssignment) {
    return null;
  }

  if (isArchivedWorkflowStep(editedContentItemVariant.assignment.workflowStatus)) {
    return <RestoreFromArchivedStepButton />;
  }

  const isPublishOrScheduleAvailable = selectableWorkflowSteps.find(
    (step) => isPublishedWorkflowStep(step) || isScheduledToPublishWorkflowStep(step),
  );

  if (!isPublishOrScheduleAvailable) {
    return <ChangeWorkflowStepButton />;
  }

  const isOtherThanPublishOrScheduleSelectable = selectableWorkflowSteps.find(
    (step) => !isPublishedWorkflowStep(step) && !isScheduledToPublishWorkflowStep(step),
  );

  return isOtherThanPublishOrScheduleSelectable ? (
    <ChangeWorkflowStepOrPublishButton />
  ) : (
    <PublishButton />
  );
};

AssignmentSectionPrimaryAction.displayName = 'AssignmentSectionPrimaryAction';

const UnpublishButton: React.FC = () => {
  const dispatch = useDispatch();
  const editedContentItemVariant = useSelector(
    (state) => state.contentApp.editedContentItemVariant,
  );
  if (!editedContentItemVariant) {
    return null;
  }
  const { scheduledToUnpublishAt } = editedContentItemVariant.assignment;
  const unpublish = () =>
    dispatch(openUnpublishAndArchiveModal(ContentItemEditingEventOrigins.ItemDetails));

  return (
    <WorkflowStepActionButton
      buttonStyle="tertiary"
      dataUiAction={DataUiWorkflowAction.Unpublish}
      icon={Icons.Box}
      label={scheduledToUnpublishAt ? ChangeUnpublishingOptions : Unpublish}
      onClick={unpublish}
    />
  );
};

UnpublishButton.displayName = 'UnpublishButton';

const AssignmentSectionSecondaryAction: React.FC = () => {
  const variantCapabilities = useSelector(
    (state) => state.contentApp.editorUi.activeCapabilities.variantCapabilities,
  );
  const editedContentItemVariant = useSelector(
    (state) => state.contentApp.editedContentItemVariant,
  );

  if (!editedContentItemVariant) {
    return null;
  }

  const hasPublishedVersion = isVariantPublished(editedContentItemVariant);
  const isScheduledToPublish = isScheduleToPublishWorkflowStepSelected(
    editedContentItemVariant.assignment,
  );
  const canUnpublish = variantCapabilities.includes(ActiveCapabilityType.Unpublish);

  if (hasPublishedVersion && !isScheduledToPublish && canUnpublish) {
    return <UnpublishButton />;
  }

  return null;
};

AssignmentSectionSecondaryAction.displayName = 'AssignmentSectionSecondaryAction';

export const AssignmentSectionActions: React.FC<
  Readonly<{
    onCancelSchedulingConfirmationDialog: () => void;
  }>
> = ({ onCancelSchedulingConfirmationDialog }) => (
  <>
    <AssignmentSectionPrimaryAction
      onCancelSchedulingConfirmationDialog={onCancelSchedulingConfirmationDialog}
    />
    <AssignmentSectionSecondaryAction />
  </>
);

AssignmentSectionActions.displayName = 'AssignmentSectionActions';
