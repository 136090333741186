import React from 'react';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { filterOutNullishOrEmpty } from '../../../../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import { getContributorsAvailableAsAssigneesInTasks } from '../../../../../../../_shared/utils/permissions/getAvailableContributors.ts';
import { cancelTaskEditing } from '../../../actions/contentItemEditingActions.ts';
import { createTask } from '../../../actions/thunkContentItemEditingActions.ts';
import { AddTaskFormBase } from '../../../components/details/Tasks/AddTaskFormBase.tsx';
import { NewTaskId } from '../../../constants/taskConstants.ts';
import { ITaskFormShape } from '../../../models/ITaskFormShape.type.ts';
import { isTaskSubmitting as isTaskSubmittingSelector } from '../../../selectors/isTaskSubmitting.ts';

const defaultValues: ITaskFormShape = {
  assignedContributorId: null,
  taskDescription: '',
  taskDueDate: {
    value: '',
    isValid: true,
  },
};

const hasFormUnsavedChanges = (values: ITaskFormShape): boolean =>
  !!values.assignedContributorId || !!values.taskDescription;

const AddTaskFormBaseContainer: React.FC = () => {
  const allActiveContributors = useSelector(
    (s) => getContributorsAvailableAsAssigneesInTasks(s).allActive,
  );
  const isTaskSubmitting = useSelector((s) => isTaskSubmittingSelector(s, NewTaskId));

  const dispatch = useDispatch();

  const onConfirm = (values: ITaskFormShape) =>
    dispatch(
      createTask(
        values.taskDescription,
        filterOutNullishOrEmpty([values.assignedContributorId]),
        values.taskDueDate,
      ),
    );
  const onCancel = () => dispatch(cancelTaskEditing(NewTaskId));

  return (
    <AddTaskFormBase
      allActiveContributors={allActiveContributors}
      defaultValues={defaultValues}
      hasFormUnsavedChanges={hasFormUnsavedChanges}
      isTaskSubmitting={isTaskSubmitting}
      onCancel={onCancel}
      onConfirm={onConfirm}
      taskId={NewTaskId}
    />
  );
};

AddTaskFormBaseContainer.displayName = 'AddTaskFormBaseContainer';

export { AddTaskFormBaseContainer as AddTaskFormBase };
