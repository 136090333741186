import { ReactNode, useEffect, useLayoutEffect, useRef } from 'react';
import { Loader } from './Loader.tsx';

export interface IEnsureValidStateOwnProps {
  readonly children: ReactNode;
  readonly renderLoader?: () => ReactNode;
}

export interface IEnsureValidStateStateProps {
  readonly isStateEnsured: boolean;
}

export interface IEnsureValidStateDispatchProps {
  readonly onRouteEntered?: () => void;
  readonly onRouteLeft?: () => void;
}

type IEnsureValidStateProps = IEnsureValidStateOwnProps &
  IEnsureValidStateStateProps &
  IEnsureValidStateDispatchProps;

export const EnsureValidState = ({
  children,
  isStateEnsured,
  onRouteEntered,
  onRouteLeft,
  renderLoader,
}: IEnsureValidStateProps) => {
  const routeEnteredRef = useRef(onRouteEntered);
  routeEnteredRef.current = onRouteEntered;
  const routeLeftRef = useRef(onRouteLeft);
  routeLeftRef.current = onRouteLeft;

  useEffect(() => {
    routeEnteredRef.current?.();
  }, []);

  useLayoutEffect(() => {
    // Sync cleanup
    return () => routeLeftRef.current?.();
  }, []);

  if (isStateEnsured) {
    return <>{children}</>;
  }
  return renderLoader ? <>{renderLoader()}</> : <Loader />;
};
