import styled from 'styled-components';
import { Paper } from '../../../containers/Paper/Paper.tsx';
import { BoxBorder } from '../../../layout/Box/Box.tsx';
import { colorTextDefault } from '../../../tokens/decision/colors.ts';
import { spacingCard } from '../../../tokens/decision/spacing.ts';
import { BorderRadius } from '../../../tokens/quarks/border.ts';
import { Typography } from '../../../tokens/quarks/typography.ts';
import { px } from '../../../tokens/utils/utils.ts';

type StyledCalloutProps = Readonly<{
  maxWidth?: number;
}>;

export const calloutMinWidth = px(250);

export const StyledCallout = styled(Paper).attrs(({ maxWidth }) => ({
  border: BoxBorder.Default,
  typography: Typography.UIParagraph,
  color: colorTextDefault,
  minWidth: calloutMinWidth,
  borderRadius: BorderRadius.L,
  padding: spacingCard,
  component: 'aside',
  style: {
    maxWidth,
  },
}))<StyledCalloutProps>`
  ${Typography.UIParagraph};
  color: ${colorTextDefault};
  word-break: break-word;
`;
