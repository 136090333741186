import { History } from 'history';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import { projectContextLeft } from '../../../applications/projects/actions/projectsActions.ts';
import { clearSelectedSubscription } from '../../../applications/subscriptionManagement/shared/actions/subscriptionManagementActions.ts';
import { IWebSpotlightConfiguration } from '../../../applications/webSpotlight/reducers/configuration.ts';
import { loadAssetType, loadUserProjectsInfo } from '../../../data/actions/thunkDataActions.ts';
import { IUserProjectInfo } from '../../../data/models/user/UserProjectInfo.ts';
import {
  getCurrentProjectId,
  getCurrentSubscriptionId,
} from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { projectIdStorage } from '../../../localStorages/projectIdStorage.ts';
import { INormalizedProjectUserCollectionGroupsServerModel } from '../../../repositories/serverModels/IRoleServerModel.type.ts';
import { currentProjectUpdated } from '../../actions/sharedActions.ts';
import {
  loadFallbacksForLinkedContentStatus,
  loadProjectFeatureFlags,
  loadProjectProperties,
  loadSubscriptionProperties,
  loadWebSpotlightConfiguration,
} from '../../actions/thunkSharedActions.ts';
import { trackUserEvent } from '../../actions/thunks/trackUserEvent.ts';
import { EnsureValidState, IEnsureValidStateOwnProps } from '../../components/EnsureValidState.tsx';
import {
  EnvironmentRoute,
  EnvironmentRouteParams,
  ProjectsRoute,
} from '../../constants/routePaths.ts';
import { TrackedEvent } from '../../constants/trackedEvent.ts';
import { useDispatch } from '../../hooks/useDispatch.ts';
import { useSelector } from '../../hooks/useSelector.ts';
import { repositoryCollection } from '../../repositories/repositories.ts';
import { buildPath } from '../../utils/routing/routeTransitionUtils.ts';
import { isUuid } from '../../utils/validation/typeValidators.ts';

const areProjectsIdsReconciled = (
  currentProjectId?: Uuid | null,
  desiredProjectId?: Uuid | null,
) => {
  const areProjectsReconciled = isUuid(currentProjectId) && currentProjectId === desiredProjectId;

  return areProjectsReconciled;
};

const isProjectDataEnsured = (
  currentProjectId: Uuid | null,
  areProjectsReconciled: boolean,
  webSpotlightConfiguration: IWebSpotlightConfiguration | null,
  areUserProjectsLoaded: boolean,
) => {
  const isStateEnsured: boolean =
    isUuid(currentProjectId) &&
    areUserProjectsLoaded &&
    areProjectsReconciled &&
    !!webSpotlightConfiguration;

  return isStateEnsured;
};

const useProjectRouteEntered = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Clear selected subscription always on mount
    dispatch(clearSelectedSubscription());

    return () => {
      dispatch(projectContextLeft());
    };
  }, []);
};

const useTrackAndSaveCurrentProject = (
  currentProjectId: Uuid | null,
  areProjectsReconciled: boolean,
  setCurrentProjectId: (projectId: Uuid) => void,
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (areProjectsReconciled && isUuid(currentProjectId)) {
      dispatch(trackUserEvent(TrackedEvent.CurrentProjectUpdated));
      setCurrentProjectId(currentProjectId);
    }
  }, [areProjectsReconciled, currentProjectId, setCurrentProjectId]);
};

const useSetLastLogin = (currentProjectId: Uuid, areProjectsReconciled: boolean) => {
  useEffect(() => {
    if (areProjectsReconciled && isUuid(currentProjectId)) {
      repositoryCollection.projectRepository.setLastLoginForCurrentUser(currentProjectId);
    }
  }, [currentProjectId, areProjectsReconciled]);
};

const useLoadProjectInfo = (areProjectsReconciled: boolean) => {
  const [isUserInfoLoaded, setIsUserInfoLoaded] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!areProjectsReconciled) {
      setIsUserInfoLoaded(false);
      dispatch(loadUserProjectsInfo()).then(() => {
        setIsUserInfoLoaded(true);
      });
    }
  }, [areProjectsReconciled]);

  return isUserInfoLoaded;
};

const useLoadAssetType = (isEnsured: boolean) => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Load asset type after projects have been reconciled
    if (isEnsured) {
      dispatch(loadAssetType());
    }
  }, [isEnsured]);
};

const useLoadIfReconciled = (
  loadFunction: (id: Uuid) => ThunkPromise,
  currentId: Uuid | null,
  areProjectsReconciled: boolean,
) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (areProjectsReconciled && isUuid(currentId)) {
      dispatch(loadFunction(currentId));
    }
  }, [areProjectsReconciled, currentId, loadFunction]);
};

const useReconcileCurrentProjectIdWithRouteParameter = (
  desiredProjectId: Uuid | null,
  userProjects: Immutable.Map<string, IUserProjectInfo> | null,
  history: History,
  getNormalizedRoleWithSettings: (
    projectId: Uuid,
  ) => Promise<INormalizedProjectUserCollectionGroupsServerModel>,
) => {
  const projectToBeSet =
    ((desiredProjectId && userProjects?.get(desiredProjectId)) || userProjects?.first()) ?? null;
  const projectIdToBeSet = projectToBeSet?.projectId;
  const masterEnvironmentIdToBeSet = projectToBeSet?.masterEnvironmentId;

  const dispatch = useDispatch();

  useEffect(() => {
    if (userProjects && (!projectToBeSet || !projectToBeSet.isSetUpCorrectly)) {
      history.push(ProjectsRoute);
    }
  }, [history, projectToBeSet, userProjects]);

  useEffect(() => {
    if (isUuid(projectIdToBeSet) && projectIdToBeSet !== desiredProjectId) {
      history.push(
        buildPath<EnvironmentRouteParams>(EnvironmentRoute, { projectId: projectIdToBeSet }),
      );
    }
  }, [desiredProjectId, history, projectIdToBeSet]);

  useEffect(() => {
    if (
      isUuid(projectIdToBeSet) &&
      isUuid(masterEnvironmentIdToBeSet) &&
      projectIdToBeSet === desiredProjectId
    ) {
      getNormalizedRoleWithSettings(projectIdToBeSet).then((roles) => {
        dispatch(currentProjectUpdated(projectIdToBeSet, masterEnvironmentIdToBeSet, roles));
      });
    }
  }, [
    desiredProjectId,

    getNormalizedRoleWithSettings,
    masterEnvironmentIdToBeSet,
    projectIdToBeSet,
  ]);
};

export const EnsureCurrentProject = (props: IEnsureValidStateOwnProps) => {
  const history = useHistory();

  const currentProjectId = useSelector(getCurrentProjectId);
  const currenSubscriptionId = useSelector(getCurrentSubscriptionId);
  const webSpotlightConfiguration = useSelector((state) => state.webSpotlightApp.configuration);
  const userProjects = useSelector((state) => state.data.user.projectsInfoById);

  const { projectId: projectIdFromParams } = useParams<EnvironmentRouteParams>();
  const storedProjectId = projectIdStorage.load();

  const desiredProjectId = projectIdFromParams || storedProjectId;
  const areProjectsReconciled = areProjectsIdsReconciled(currentProjectId, desiredProjectId);

  const isUserInfoLoaded = useLoadProjectInfo(areProjectsReconciled);

  const isStateEnsured = isProjectDataEnsured(
    currentProjectId,
    areProjectsReconciled,
    webSpotlightConfiguration,
    isUserInfoLoaded,
  );

  useProjectRouteEntered();
  useReconcileCurrentProjectIdWithRouteParameter(
    desiredProjectId,
    userProjects,
    history,
    repositoryCollection.roleRepository.getNormalizedRoleWithSettings,
  );

  useLoadIfReconciled(loadProjectProperties, currentProjectId, areProjectsReconciled);
  useLoadIfReconciled(loadProjectFeatureFlags, currentProjectId, areProjectsReconciled);
  useLoadIfReconciled(loadSubscriptionProperties, currenSubscriptionId, areProjectsReconciled);
  useLoadIfReconciled(loadWebSpotlightConfiguration, currentProjectId, areProjectsReconciled);
  useLoadIfReconciled(loadFallbacksForLinkedContentStatus, currentProjectId, areProjectsReconciled);

  useLoadAssetType(isStateEnsured);
  useTrackAndSaveCurrentProject(currentProjectId, areProjectsReconciled, (projectId: Uuid) =>
    projectIdStorage.save(projectId),
  );
  useSetLastLogin(currentProjectId, areProjectsReconciled);

  return <EnsureValidState {...props} isStateEnsured={isStateEnsured} />;
};
