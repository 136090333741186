import { History } from 'history';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../@types/Dispatcher.type.ts';
import {
  IRouteChangeHandlerCallbackProps,
  RouteChangeHandler,
} from '../../../../_shared/components/RouteChangeHandler.tsx';
import { ContentItemsAppRouteSegment } from '../../../../_shared/constants/routePaths.ts';
import { redirectToLatestPublishedRevision } from '../actions/thunks/redirectToLatestPublishedRevision.ts';

interface IOwnProps {
  readonly projectId: Uuid;
  readonly app: ContentItemsAppRouteSegment;
  readonly history: History;
  readonly variantId: Uuid;
  readonly spaceId: Uuid | undefined;
  readonly contentItemId: Uuid;
}

const mapDispatchToProps = (
  dispatch: Dispatch,
  { projectId, app, variantId, spaceId, contentItemId, history }: IOwnProps,
): IRouteChangeHandlerCallbackProps => ({
  onDidMount: () =>
    dispatch(
      redirectToLatestPublishedRevision(history, projectId, app, variantId, spaceId, contentItemId),
    ),
});

export const RedirectToLatestPublishedRevision: React.ComponentType<IOwnProps> = connect(
  null,
  mapDispatchToProps,
)(RouteChangeHandler);
