import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import {
  isCreationOfContentComponentAllowed,
  isItemExpandable,
} from '../utils/contentNestingUtils.ts';

interface IContentNestingContextState {
  readonly isContentComponentCreationAllowed: boolean;
  readonly isCurrentLevelContentComponent: boolean;
  readonly isItemExpandable: boolean;
  readonly isTopLevel: boolean;
  readonly nestedLevel: number;
}

const defaultContext: IContentNestingContextState = {
  isContentComponentCreationAllowed: true,
  isCurrentLevelContentComponent: false,
  isItemExpandable: true,
  isTopLevel: true,
  nestedLevel: 0,
};

export const ContentNestingContext = React.createContext(defaultContext);

interface IContentNestingContextProviderProps {
  readonly children: React.ReactNode | React.ReactNodeArray;
  readonly isCurrentLevelContentComponent: boolean;
}

const propTypes: PropTypeMap<IContentNestingContextProviderProps> = {
  children: PropTypes.node.isRequired,
  isCurrentLevelContentComponent: PropTypes.bool.isRequired,
};

export const ContentNestingContextProvider: React.FC<IContentNestingContextProviderProps> = ({
  children,
  isCurrentLevelContentComponent,
}) => {
  const previousNestedLevel = useContext(ContentNestingContext).nestedLevel;
  const nestedLevel = previousNestedLevel + 1;
  const contextState: IContentNestingContextState = useMemo(
    () => ({
      isCurrentLevelContentComponent,
      isContentComponentCreationAllowed: isCreationOfContentComponentAllowed(nestedLevel),
      isItemExpandable: isItemExpandable(nestedLevel),
      isTopLevel: nestedLevel === 0,
      nestedLevel,
    }),
    [nestedLevel, isCurrentLevelContentComponent],
  );

  return (
    <ContentNestingContext.Provider value={contextState}>{children}</ContentNestingContext.Provider>
  );
};

ContentNestingContextProvider.displayName = 'ContentNestingContextProvider';
ContentNestingContextProvider.propTypes = propTypes;
