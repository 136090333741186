import { Callout } from '@kontent-ai/component-library/Callout';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { EnvironmentRouteParams, WebhooksRoute } from '../../../../_shared/constants/routePaths.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getCurrentProject } from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';

export const LegacyWebhookQuickTip: React.FC = () => {
  const { projectId } = useSelector(getCurrentProject);
  const newWebhooksPath = buildPath<EnvironmentRouteParams>(WebhooksRoute, { projectId });
  return (
    <Callout calloutType="quickTip" maxWidth={1000}>
      <Stack spacing={Spacing.XL}>
        <p>There’s a better way</p>
        <p>
          We recommend using the improved <Link to={newWebhooksPath}>webhooks</Link> for advanced
          content filters and better performance over the legacy webhooks.
        </p>
      </Stack>
    </Callout>
  );
};

LegacyWebhookQuickTip.displayName = 'LegacyWebhookQuickTip';
