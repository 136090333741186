import React from 'react';
import { connect } from 'react-redux';
import { ActiveCapabilityType } from '../../../../../_shared/models/activeCapability.type.ts';
import { areCollectionsVisibleForCurrentUser } from '../../../../../_shared/selectors/contentCollections.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { areSpacesInCollectionsEnabled } from '../../../../environmentSettings/selectors/allowedFeaturesUtils.ts';
import { getAreAnyContentGroupsVisible } from '../../../selectors/getAreAnyContentGroupsVisible.ts';
import { getCanEditGroupContent } from '../../../selectors/getCanEditGroupContent.ts';
import { getEditedContentItemTypeOrThrow } from '../../../selectors/getEditedContentTypeOrThrow.ts';
import {
  ContentItemHeader as ContentItemHeaderComponent,
  IContentItemHeaderDataProps,
} from '../components/ContentItemHeader.tsx';
import { isContentItemNameAndCodenameEditingAllowed } from '../utils/itemEditingUtils.ts';

function mapStateToProps(state: IStore): IContentItemHeaderDataProps {
  const {
    contentApp: { contentItemVariants, editedContentItem },
    data: { languages },
  } = state;

  const isCollectionInMainPaneEnabled =
    areSpacesInCollectionsEnabled(state) && areCollectionsVisibleForCurrentUser(state);
  const selectedLanguageId = getSelectedLanguageIdOrThrow(state);
  const editedContentItemType = getEditedContentItemTypeOrThrow(state);

  const canUpdateContent = hasActiveVariantCapabilityForEditedItem(
    ActiveCapabilityType.UpdateContent,
    state,
  );
  const canEditGroupContent = getCanEditGroupContent(state);

  const canEditName =
    isContentItemNameAndCodenameEditingAllowed(
      contentItemVariants,
      languages.defaultLanguage.id,
      selectedLanguageId,
    ) && canUpdateContent;

  return {
    areAnyContentGroupsVisible: getAreAnyContentGroupsVisible(state),
    canEditGroupContent,
    canEditName,
    contentItemId: editedContentItem?.id ?? '',
    contentType: editedContentItemType,
    isCollectionInMainPaneEnabled,
  };
}

export const ContentItemHeader: React.ComponentType = connect(mapStateToProps)(
  ContentItemHeaderComponent,
);
