import Immutable from 'immutable';
import {
  CompiledTypeElementType,
  ElementType,
} from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import { IMultipleChoiceTypeElement } from '../../../../contentInventory/content/models/contentTypeElements/MultipleChoiceTypeElement.ts';
import {
  EditableTypeElement,
  TypeElement,
} from '../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import {
  isCustomTypeElement,
  isEditableElement,
} from '../../../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { getSimpleTextValue } from '../../../../richText/utils/editorSimpleTextValueUtils.ts';
import { ICustomItemElement } from '../../../models/contentItemElements/CustomItemElement.ts';
import { DateTimeItemElement } from '../../../models/contentItemElements/DateTimeItemElement.ts';
import { ICompiledContentItemElementData } from '../../../models/contentItemElements/ICompiledContentItemElement.ts';
import { IMultipleChoiceItemElement } from '../../../models/contentItemElements/MultipleChoiceItemElement.ts';
import { INumberItemElement } from '../../../models/contentItemElements/NumberItemElement.ts';
import { ITextItemElement } from '../../../models/contentItemElements/TextItemElement.ts';
import { IUrlSlugItemElement } from '../../../models/contentItemElements/UrlSlugItemElement.ts';
import { getElementById } from '../../../stores/utils/contentItemElementsUtils.ts';
import { ElementValue, IMultipleChoiceElementValueItem } from '../../../types/CustomElementApi.ts';
import { getServerNumberValue } from '../../../utils/itemElementDataConverters/numberItemElementDataConverter.ts';

const getMultipleChoiceValues = (
  element: IMultipleChoiceItemElement,
  typeElement: IMultipleChoiceTypeElement,
): IMultipleChoiceElementValueItem[] => {
  if (!typeElement) {
    return [];
  }

  const values = element.options.map((optionId: Uuid) => {
    const option = typeElement.options[optionId];
    const value = {
      id: optionId,
      name: option?.label ?? '',
      codename: option?.codename ?? '',
    };

    return value;
  });

  return values;
};

type IGetValueForCustomElement = (
  element: ICompiledContentItemElementData,
  typeElement: EditableTypeElement,
) => ElementValue;

const valueMap = Immutable.Map<CompiledTypeElementType, IGetValueForCustomElement>()
  .set(ElementType.DateTime, (element: DateTimeItemElement) => element.value || null)
  .set(
    ElementType.MultipleChoice,
    (element: IMultipleChoiceItemElement, typeElement: IMultipleChoiceTypeElement) =>
      getMultipleChoiceValues(element, typeElement),
  )
  .set(ElementType.Number, (element: INumberItemElement) => getServerNumberValue(element.value))
  .set(ElementType.Text, (element: ITextItemElement) => getSimpleTextValue(element._editorState))
  // Rich text is not included yet because it is not specified yet how to work with its empty value and nested content (components)
  // Also, the value property is not updated properly upon the element update
  // .set(ElementType.RichText, (el: ITextItemElement) => el.value)
  .set(ElementType.Custom, (element: ICustomItemElement) => element.value)
  .set(ElementType.UrlSlug, (element: IUrlSlugItemElement) =>
    getSimpleTextValue(element._editorState),
  );

export const supportedElementTypes: Immutable.Set<ElementType> = valueMap.keySeq().toSet();

export function getElementValue(
  element: ICompiledContentItemElementData,
  typeElement: EditableTypeElement,
): ElementValue | undefined {
  const getValue = valueMap.get(element.type);
  if (!getValue) {
    return undefined;
  }
  return getValue(element, typeElement);
}

export const getElementValueForCustomElement = (
  elements: ReadonlyArray<ICompiledContentItemElementData>,
  forCustomElementId: Uuid,
  typeElements: ReadonlyArray<TypeElement>,
  elementCodename: string,
): ElementValue | undefined => {
  const forElement = typeElements.find((el) => el.elementId === forCustomElementId);
  if (!forElement || !isCustomTypeElement(forElement)) {
    return undefined;
  }

  const typeElement = typeElements.find(
    (el) => el.codename === elementCodename && forElement.allowedElements.contains(el.elementId),
  );
  if (!isEditableElement(typeElement)) {
    return undefined;
  }

  const element = getElementById(typeElement.elementId, elements);
  if (!element) {
    return undefined;
  }

  return getElementValue(element, typeElement);
};
