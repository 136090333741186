import { isAbortError } from '@kontent-ai/errors';
import { History } from 'history';
import { ThunkFunction, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import {
  ContentTypesRoute,
  EnvironmentRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import { redirectToDefaultRoute } from '../../../../../_shared/utils/routing/redirectToDefaultRoute.ts';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getContentTypeFromServerModel } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { VariantUsageQueryServerModel } from '../../../../../repositories/serverModels/ContentItemFilterWithContinuationServerModel.ts';
import { ContentTypeServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import {
  initTypeEditorStarted,
  typeEditorLoaded,
} from '../../../shared/actions/sharedContentModelsActions.ts';
import { ILoadContentTypeReferencesForTypeEditingAction } from '../../../shared/actions/thunks/loadContentTypeReferencesForTypeEditing.ts';
import { IBaseTypeElementData } from '../../../shared/models/elements/types/TypeElementData.ts';
import { getContentTypeConversionOptions } from '../../../shared/selectors/contentTypeElementSelector.ts';
import { initContentTypeEditorFinished } from '../contentTypesActions.ts';

interface IDeps {
  readonly contentItemRepository: {
    readonly projectContainsPublishedItems: (
      filter: VariantUsageQueryServerModel,
      abortSignal?: AbortSignal,
    ) => Promise<boolean>;
  };
  readonly contentTypeRepository: {
    readonly getContentType: (
      contentTypeId: Uuid,
      abortSignal?: AbortSignal,
    ) => Promise<ContentTypeServerModel>;
  };
  readonly loadAssetType: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadCollections: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadContentTypeReferencesForTypeEditing: ILoadContentTypeReferencesForTypeEditingAction;
  readonly loadContentTypes: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadContentTypesUsage: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadSnippets: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadTaxonomyGroups: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadWorkflows: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly validateTypeElement: (updatedTypeElement: IBaseTypeElementData) => ThunkFunction;
}

interface IActionParams {
  readonly editedContentTypeId: Uuid;
  readonly history: History;
}

export const createInitContentTypeEditorAction =
  (deps: IDeps) =>
  ({ editedContentTypeId, history }: IActionParams, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    dispatch(initTypeEditorStarted());

    const projectId = getState().sharedApp.currentProjectId;

    let editedContentTypeServerModel: ContentTypeServerModel | null = null;
    try {
      editedContentTypeServerModel = await deps.contentTypeRepository.getContentType(
        editedContentTypeId,
        abortSignal,
      );
    } catch (error) {
      if (!isAbortError(error)) {
        redirectToDefaultRoute({
          currentProjectId: projectId,
          history,
          error,
        });
      }

      throw error;
    }

    const editedContentType = getContentTypeFromServerModel(
      editedContentTypeServerModel,
      getContentTypeConversionOptions(getState()),
    );

    if (editedContentType.isArchived) {
      history.push(buildPath<EnvironmentRouteParams>(ContentTypesRoute, { projectId }));
      return;
    }

    const [isEditedContentTypeUsedInPublishedItem] = await Promise.all([
      deps.contentItemRepository.projectContainsPublishedItems(
        {
          typeIds: [editedContentTypeId],
        },
        abortSignal,
      ),
      dispatch(deps.loadAssetType(abortSignal)),
      dispatch(deps.loadContentTypesUsage(abortSignal)),
      dispatch(deps.loadCollections(abortSignal)),
      dispatch(
        deps.loadContentTypeReferencesForTypeEditing(editedContentType.typeElements, abortSignal),
      ),
      dispatch(deps.loadContentTypes(abortSignal)),
      dispatch(deps.loadSnippets(abortSignal)),
      dispatch(deps.loadTaxonomyGroups(abortSignal)),
      dispatch(deps.loadWorkflows(abortSignal)),
    ]);

    dispatch(typeEditorLoaded(editedContentType));

    editedContentType.typeElements.forEach((element) =>
      dispatch(deps.validateTypeElement(element)),
    );

    dispatch(initContentTypeEditorFinished(isEditedContentTypeUsedInPublishedItem));
  };
