import { Action } from '../../../../../@types/Action.type.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import {
  Workflows_ListingInit_Finished,
  Workflows_ListingInit_Left,
  Workflows_ListingInit_Started,
} from '../../constants/workflowActionTypes.ts';

const initialState: LoadingStatus = LoadingStatus.InitialEmpty;

export const loadingStatus = (
  state: LoadingStatus = initialState,
  action: Action,
): LoadingStatus => {
  switch (action.type) {
    case Workflows_ListingInit_Started: {
      return LoadingStatus.Loading;
    }

    case Workflows_ListingInit_Finished: {
      return LoadingStatus.Loaded;
    }

    case Workflows_ListingInit_Left: {
      return LoadingStatus.InitialEmpty;
    }

    default:
      return state;
  }
};
