import PropTypes from 'prop-types';
import React from 'react';
import {
  INumberTypeElement,
  NumberTypeElementPropTypes,
} from '../../../../contentInventory/content/models/contentTypeElements/NumberTypeElement.ts';
import {
  INumberItemElement,
  NumberItemElementPropTypes,
} from '../../../models/contentItemElements/NumberItemElement.ts';
import { NumberDiff } from '../../Revisions/components/elements/subelements/NumberDiff.tsx';
import { ExpandedItemElement } from '../containers/ExpandedItemElement.tsx';
import { ExpandedItemElementDataProps } from './types/expandedItemElement.type.ts';

type ExpandedNumberElementProps = ExpandedItemElementDataProps<
  INumberTypeElement,
  INumberItemElement
>;

const propTypes: PropTypeMap<ExpandedNumberElementProps> = {
  className: PropTypes.string,
  elementData: NumberItemElementPropTypes.isRequired,
  originalElementData: NumberItemElementPropTypes,
  typeElement: NumberTypeElementPropTypes.isRequired,
};

export const ExpandedNumberElement: React.FC<ExpandedNumberElementProps> = ({
  elementData,
  originalElementData,
  typeElement,
  className,
}) => (
  <ExpandedItemElement
    className={className}
    disabled
    hideValidationStatus
    typeElement={typeElement}
  >
    <NumberDiff
      elementData={elementData}
      originalElementData={originalElementData}
      typeElement={typeElement}
    />
  </ExpandedItemElement>
);

ExpandedNumberElement.displayName = 'ExpandedNumberElement';
ExpandedNumberElement.propTypes = propTypes;
