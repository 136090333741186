import React, { ReactNode, useCallback } from 'react';
import {
  ISidebarOwnProps,
  Sidebar as SidebarComponent,
} from '../../components/Sidebar/Sidebar.tsx';
import { Shared_AnySidebar_Unmounted } from '../../constants/sharedActionTypes.ts';
import { useDispatch } from '../../hooks/useDispatch.ts';

const createAnySidebarUnmountedAction = () => ({ type: Shared_AnySidebar_Unmounted }) as const;

export type SidebarActionsType = ReturnType<typeof createAnySidebarUnmountedAction>;

type ISidebarPropsWithChildren = ISidebarOwnProps & {
  readonly children: ReactNode;
};

export const Sidebar: React.FC<ISidebarPropsWithChildren> = (props) => {
  const dispatch = useDispatch();

  const onDismiss = useCallback(() => dispatch(createAnySidebarUnmountedAction()), []);

  return <SidebarComponent {...props} onDismiss={onDismiss} />;
};
