import { ICompiledContentItemElementData } from '../contentItemElements/ICompiledContentItemElement.ts';

export interface IContentComponent {
  readonly id: Uuid;
  readonly contentTypeId: Uuid;
  readonly elements: ReadonlyArray<ICompiledContentItemElementData>;
}

export function createContentComponent(props?: Partial<IContentComponent>): IContentComponent {
  return {
    id: '',
    contentTypeId: '',
    elements: [],
    ...props,
  };
}

export const EmptyContentComponents: ReadonlyMap<Uuid, IContentComponent> = new Map<
  Uuid,
  IContentComponent
>();
