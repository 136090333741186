import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { TagColor } from '../../../data/constants/tagColor.ts';
import {
  IForwardedRefProps,
  forwardRef,
  forwardedRefProps,
} from '../../utils/forwardedRefProps.tsx';

type TagProps = IForwardedRefProps<HTMLDivElement> & {
  readonly color: TagColor;
  readonly customClass?: string;
  readonly modifier?: TagModifier;
  readonly title?: string;
};

export enum TagModifier {
  Default = '',
  IsClickable = 'tag--is-clickable',
  IsSticky = 'tag--is-sticky',
}

const propTypes = {
  ...forwardedRefProps,
  color: PropTypes.oneOf(Object.values(TagColor)).isRequired,
  customClass: PropTypes.string,
  modifier: PropTypes.oneOf(Object.values(TagModifier)),
  title: PropTypes.string,
};

const Tag: React.FC<React.PropsWithChildren<TagProps>> = (props) => {
  return (
    <div
      ref={props.forwardedRef}
      className={classNames(
        'tag',
        {
          [`tag--${props.color}`]: props.color !== TagColor.None,
        },
        props.modifier,
        props.customClass,
      )}
      title={props.title}
    >
      {props.children}
    </div>
  );
};

Tag.displayName = 'Tag';
Tag.propTypes = propTypes;

const TagRFC = forwardRef(Tag);
export { TagRFC as Tag };
