import { ObjectWithDataAttribute } from '@kontent-ai/component-library/utils';
import { noOperation } from '@kontent-ai/utils';
import React, { memo, useMemo, useRef, useState } from 'react';
import { CreateAutoScrollId } from '../../../../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { AutoScrollComponentProps } from '../../../../../../../_shared/components/AutoScroll/autoScrollPropTypes.ts';
import { DragSource } from '../../../../../../../_shared/components/DragDrop/DragSource.tsx';
import { DropTarget } from '../../../../../../../_shared/components/DragDrop/DropTarget.tsx';
import {
  DragMoveHandler,
  HoveringCollisionStrategy,
} from '../../../../../../../_shared/components/DragDrop/dragDrop.type.ts';
import { HideOutsideViewport } from '../../../../../../../_shared/components/HideOutsideViewport.tsx';
import { useAutoScroll } from '../../../../../../../_shared/hooks/useAutoScroll.ts';
import { getLinkedItemIdAttribute } from '../../../../../constants/elementAttributes.ts';
import {
  ElementLinkedItem,
  IElementLinkedItemProps,
} from '../../../containers/elements/modularContent/ElementLinkedItem.tsx';

type DraggableLinkedItemProps = Omit<
  IElementLinkedItemProps,
  'connectDragSource' | 'alternativeScrollIds' | 'scrollId' | 'isDragging'
> & {
  readonly hideOutsideViewport?: boolean;
  readonly hoveringCollisionStrategy: HoveringCollisionStrategy;
  readonly modularContentDndIdentifier: string;
  readonly onDragEnd: () => void;
  readonly onDragStart: () => void;
  readonly onMove: DragMoveHandler;
};

export const DraggableLinkedItem: React.FC<DraggableLinkedItemProps> = memo(
  ({
    allowedContentTypes,
    contentComponentId,
    contentItemId,
    displayDragButton,
    elementId,
    hideOutsideViewport,
    hoveringCollisionStrategy,
    isDisabled,
    isPage,
    modularContentDndIdentifier,
    onDelete,
    onDragEnd,
    onDragStart,
    onDuplicate,
    onMove,
    renderExpanded,
  }) => {
    // We memoize scroll IDs only using useMemo because it is much faster than memoizeUtils
    // and we need this component to be as efficient as possible when mounting large linked item lists
    const scrollId = useMemo(
      () =>
        CreateAutoScrollId.forLinkedItem.originalFunction(
          elementId,
          contentComponentId ?? '',
          contentItemId,
        ),
      [contentItemId, contentComponentId, elementId],
    );
    const alternativeScrollIds = useMemo(
      () => [
        CreateAutoScrollId.forContentItem.originalFunction(contentItemId), // Ability to scroll to it by global item id (from usage or after creating new item in linked items)
      ],
      [contentItemId],
    );

    const [isExpanded, setIsExpanded] = useState(false);

    const renderVisible = (ref?: React.RefObject<HTMLDivElement>) => (
      <DropTarget<HTMLDivElement>
        accept={modularContentDndIdentifier}
        canDrop={!isDisabled}
        contentRef={ref}
        hoveringCollisionStrategy={hoveringCollisionStrategy}
        key={contentItemId}
        onMove={onMove}
        parentId={elementId}
        renderDroppable={(droppableRef) => (
          <div className="bar-item__drag-block" ref={droppableRef}>
            <DragSource
              sourceId={contentItemId}
              parentId={elementId}
              type={modularContentDndIdentifier}
              onDragStart={onDragStart}
              onDragEnd={onDragEnd}
              renderDraggable={(connectDragSource, isDragging) => (
                <ElementLinkedItem
                  allowedContentTypes={allowedContentTypes}
                  alternativeScrollIds={alternativeScrollIds}
                  connectDragSource={connectDragSource}
                  contentComponentId={contentComponentId}
                  contentItemId={contentItemId}
                  displayDragButton={displayDragButton}
                  elementId={elementId}
                  isDisabled={!!isDisabled}
                  isDragging={isDragging}
                  isPage={isPage}
                  onDelete={onDelete}
                  onDuplicate={onDuplicate}
                  onExpandedChanged={setIsExpanded}
                  renderExpanded={renderExpanded}
                  scrollId={scrollId}
                />
              )}
              renderPreview={() => (
                <ElementLinkedItem
                  allowedContentTypes={allowedContentTypes}
                  contentComponentId={contentComponentId}
                  contentItemId={contentItemId}
                  displayDragButton
                  elementId={elementId}
                  isDisabled={false}
                  isDragging={false}
                  isPage={isPage}
                  onDelete={noOperation}
                  onDuplicate={noOperation}
                />
              )}
            />
          </div>
        )}
        targetId={contentItemId}
      />
    );

    const renderHidden = (ref: React.RefObject<HTMLDivElement>) => (
      <div className="bar-item__drag-block" ref={ref}>
        <LinkedItemOutOfViewport
          alternativeScrollIds={alternativeScrollIds}
          dataAttributes={{ ...getLinkedItemIdAttribute(contentItemId) }}
          scrollId={scrollId}
        />
      </div>
    );

    return hideOutsideViewport ? (
      <HideOutsideViewport
        alwaysVisible={isExpanded}
        delay={50}
        renderHidden={renderHidden}
        renderVisible={renderVisible}
        rootMargin="200px 0px 200px 0px"
      />
    ) : (
      renderVisible()
    );
  },
);

DraggableLinkedItem.displayName = 'DraggableLinkedItem';

type LinkedItemOutOfViewportProps = AutoScrollComponentProps & {
  readonly dataAttributes: ObjectWithDataAttribute;
};

const LinkedItemOutOfViewport: React.FC<LinkedItemOutOfViewportProps> = ({
  alternativeScrollIds,
  dataAttributes,
  scrollId,
}) => {
  const scrollTargetRef = useRef<HTMLDivElement>(null);

  useAutoScroll({
    alternativeScrollIds,
    scrollId,
    scrollTargetRef,
  });

  return (
    <div className="bar-item__node" ref={scrollTargetRef} {...dataAttributes}>
      <div className="bar-item__wrapper">
        <div className="bar-item__pane">
          <div className="bar-item__bar" />
        </div>
      </div>
    </div>
  );
};

LinkedItemOutOfViewport.displayName = 'LinkedItemSkeleton';
