import React from 'react';
import { modalDismissed } from '../../../../../_shared/actions/sharedActions.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { compose } from '../../../../../_shared/utils/func/compose.ts';
import { cancelContentItemVariantsScheduledUnpublish } from '../../../../itemEditor/features/ContentItemEditing/actions/thunkContentItemEditingActions.ts';
import { CancelScheduleUnpublishConfirmationDialog } from '../../components/cancelConfirmation/CancelScheduleUnpublishConfirmationDialog.tsx';

const CancelScheduleUnpublishConfirmationDialogContainer: React.FC = () => {
  const dispatch = useDispatch();
  const onCancel = compose(dispatch, modalDismissed);
  const onConfirm = compose(dispatch, cancelContentItemVariantsScheduledUnpublish);

  return <CancelScheduleUnpublishConfirmationDialog onCancel={onCancel} onConfirm={onConfirm} />;
};

CancelScheduleUnpublishConfirmationDialogContainer.displayName =
  'CancelScheduleUnpublishConfirmationDialogContainer';

export {
  CancelScheduleUnpublishConfirmationDialogContainer as CancelScheduleUnpublishConfirmationDialog,
};
