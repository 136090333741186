import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { emptyEntityWebhook } from '../../../data/models/webhooks/EntityWebhookSetting.ts';
import { EntityWebhookEditorForm } from './EntityWebhookEditorForm.tsx';

type EntityWebhookEditorProps = {
  readonly editedEntityWebhookId: Uuid;
};

export const EntityWebhookEditor = ({ editedEntityWebhookId }: EntityWebhookEditorProps) => {
  const entityWebhookSetting = useSelector(
    (s) => s.data.entityWebhooks.byId.get(editedEntityWebhookId) ?? emptyEntityWebhook,
  );

  return <EntityWebhookEditorForm entityWebhookSetting={entityWebhookSetting} />;
};

EntityWebhookEditor.displayName = 'EntityWebhookEditorContainer';
