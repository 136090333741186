import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import { InvariantException } from '@kontent-ai/errors';
import { memoize } from '@kontent-ai/memoization';
import React from 'react';
import { anyProjectOption } from '../../../../_shared/constants/userListingFilter.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { IProjectFilterOption } from '../../../../_shared/models/UserListingFilterOption.type.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getProjectsSortedByProjectName } from '../../../projects/selectors/projectSelectors.ts';
import { projectFilterUpdated } from '../actions/subscriptionUserListingActions.ts';
import { getAllMasterEnvironments } from '../selectors/subscriptionUsersUsageSelectors.ts';
import { getProjectFilterOptions } from '../utils/getFilterOptions.ts';

const getProjectOptions = (s: IStore): ReadonlyArray<IProjectFilterOption> => {
  const masterEnvironments = getAllMasterEnvironments(s);
  if (!masterEnvironments) {
    throw InvariantException(
      'SubscriptionUsersListingFilterContainer.tsx: Projects not loaded into the state.',
    );
  }

  const masterEnvironmentsSorted = getProjectsSortedByProjectName(masterEnvironments);
  const withAnyProject = getProjectFilterOptions(masterEnvironmentsSorted, true);
  return withAnyProject;
};

const getProjectOption = memoize.weak(
  (projects: ReadonlyArray<IProjectFilterOption>, projectId: Uuid) =>
    projects.find((option: IProjectFilterOption) => projectId === option.id) || anyProjectOption,
);

const getSelectedProjectOption = (
  s: IStore,
  projects: ReadonlyArray<IProjectFilterOption>,
): IProjectFilterOption => {
  const projectId = s.subscriptionApp.users.listingUi.filter.byProject;
  return getProjectOption(projects, projectId);
};

export const SubscriptionUsersProjectFilter: React.FC = () => {
  const dispatch = useDispatch();
  const projects = useSelector(getProjectOptions);
  const selectedProjectOption = useSelector((s) => getSelectedProjectOption(s, projects));

  const onUpdateFilter = (updatedProjectOptionId: IProjectFilterOption['id']) => {
    const updatedProjectId =
      updatedProjectOptionId === anyProjectOption.id ? '' : updatedProjectOptionId;
    if (updatedProjectId !== selectedProjectOption.id) {
      dispatch(projectFilterUpdated(updatedProjectId));
    }
  };

  return (
    <SingleSelect<IProjectFilterOption>
      label="Project"
      onSelectionChange={onUpdateFilter}
      items={projects}
      selectedItemId={selectedProjectOption.id}
      verticalMenuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.Projects)}
      {...getDataUiCollectionAttribute(DataUiCollection.Projects)}
    />
  );
};

SubscriptionUsersProjectFilter.displayName = 'SubscriptionUsersProjectFilter';
