import { focusAtTheEnd } from '@kontent-ai/DOM';
import { Input, InputState, InputType } from '@kontent-ai/component-library/Input';
import {
  TooltipPropsExtension,
  tooltipExtensionPropTypes,
} from '@kontent-ai/component-library/component-utils';
import { useAttachRef } from '@kontent-ai/hooks';
import PropTypes from 'prop-types';
import React, { ReactElement, Ref, forwardRef, useEffect } from 'react';
import { FieldPathByValue, FieldValues, useController } from 'react-hook-form';
import { HookFormProps, hookFormProps } from '../../types/hookFormProps.ts';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { showFieldError } from '../../utils/validation/showFieldError.ts';

// You should not need to add onChange or onBlur into this component - watching form props should be enough.
// If you really think you need it, make sure to call both passed prop and formProps.register.onXX.
// If you don't, app will work correctly, but ui test wil fail.
type Props<TFormShape extends FieldValues> = Pick<
  TooltipPropsExtension,
  'tooltipText' | 'tooltipPlacement'
> & {
  readonly autoFocus?: boolean;
  readonly dataUiInputName?: DataUiInput;
  readonly formProps: HookFormProps<TFormShape>;
  readonly inputState?: InputState;
  readonly inputSuppressedInHotjar?: boolean;
  readonly label?: string;
  readonly maxLength?: number;
  readonly name: FieldPathByValue<TFormShape, string>;
  readonly placeholder?: string;
  readonly type?: InputType;
};

const propTypes: PropTypeMap<Props<any>> = {
  autoFocus: PropTypes.bool,
  dataUiInputName: PropTypes.oneOf(Object.values(DataUiInput)),
  formProps: hookFormProps.isRequired,
  inputState: PropTypes.oneOf(Object.values(InputState)),
  inputSuppressedInHotjar: PropTypes.bool,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  tooltipPlacement: tooltipExtensionPropTypes.tooltipPlacement,
  tooltipText: tooltipExtensionPropTypes.tooltipText,
  type: PropTypes.oneOf(Object.values(InputType)),
};

export const ValidatedInputComponent = forwardRef(
  <TFormShape extends FieldValues>(
    {
      autoFocus,
      dataUiInputName = DataUiInput.Text,
      formProps,
      inputState = InputState.Default,
      inputSuppressedInHotjar,
      name,
      type = InputType.Text,
      ...inputPassThroughProps
    }: Props<TFormShape>,
    ref: Ref<HTMLInputElement>,
  ): ReactElement => {
    const { refObject, refToForward } = useAttachRef(ref);

    // Workaround for react-hook-form as the form ref callback changes on each render
    // which means it always passes null and then the element as an instance and we do not want to react to it on every render.
    useEffect(() => {
      if (autoFocus) {
        focusAtTheEnd(refObject.current);
      }
    }, [refObject, autoFocus]);

    const {
      field: { ref: formRef, ...inputProps },
      formState,
      fieldState: { error },
    } = useController({
      control: formProps.control,
      name,
    });

    const showError = showFieldError(formState, error);

    return (
      <Input
        inputState={showError ? InputState.Alert : inputState}
        type={type}
        ref={(instance) => {
          formRef(instance);
          refToForward(instance);
        }}
        caption={error?.message}
        {...inputPassThroughProps}
        {...inputProps}
        {...(inputSuppressedInHotjar && { 'data-hj-suppress': '' })}
        {...getDataUiInputAttribute(dataUiInputName)}
      />
    );
  },
);

type ValidatedInputComponentCallback = <TFormShape extends FieldValues = EmptyObject>(
  props: Props<TFormShape>,
) => ReactElement;
type ValidatedInputComponentType = ValidatedInputComponentCallback &
  Pick<React.FC, 'displayName' | 'propTypes'>;

export const ValidatedInput = ValidatedInputComponent as ValidatedInputComponentType;

ValidatedInput.displayName = 'ValidatedInput';
ValidatedInput.propTypes = propTypes;
