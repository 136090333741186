import { Box } from '@kontent-ai/component-library/Box';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { Collection } from '@kontent-ai/utils';
import React from 'react';
import { modalDismissed } from '../../../../../../_shared/actions/sharedActions.ts';
import { Loader, LoaderType } from '../../../../../../_shared/components/Loader.tsx';
import { ConfirmationDialog } from '../../../../../../_shared/components/ModalDialog/ConfirmationDialog.tsx';
import { Warning } from '../../../../../../_shared/components/infos/Warning.tsx';
import { ContentItemUsagesLinks } from '../../../../../../_shared/containers/ContentItemUsagesLinks/ContentItemUsagesLinks.tsx';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { isProjectMultilingual } from '../../../../../../_shared/selectors/isProjectMultilingual.ts';
import { getLanguageName } from '../../../../../../_shared/utils/languageSwitchers/languageSwitcherUtils.ts';
import { getAllLanguagesWithDefaultSuffix } from '../../../../../../data/reducers/languages/selectors/getLanguages.ts';
import { ContentItemUsages } from '../../../../../../data/reducers/listingContentItems/IListingContentItems.type.ts';
import { useSelectedContentItemUsedIn } from '../../../hooks/useSelectedContentItemUsedIn.ts';
import { deleteSelectedContentItems } from '../actions/thunkContentItemInventoryActions.ts';

type FriendlyWarningProps = {
  readonly selectedItemIds: ReadonlySet<Uuid>;
  readonly contentItemUsages: ContentItemUsages;
};

const getItemGrammaticalNumberForm = (size: number) => (size > 1 ? 'items' : 'item');

const getFirstSelectedItemId = (selectedItemIds: ReadonlySet<Uuid>) =>
  Collection.getFirst(Collection.getValues(selectedItemIds)) ?? '';

const FriendlyWarning: React.FC<FriendlyWarningProps> = ({
  selectedItemIds,
  contentItemUsages,
}) => (
  <Box marginTop={Spacing.M}>
    <Warning>
      {selectedItemIds.size === 1 ? (
        <>
          Deleting the following item may cause <strong>broken links</strong> because it’s used in
          other content{' '}
          {getItemGrammaticalNumberForm(
            contentItemUsages.get(getFirstSelectedItemId(selectedItemIds))?.size ?? 0,
          )}
          :
          <ContentItemUsagesLinks contentItemId={getFirstSelectedItemId(selectedItemIds)} />
        </>
      ) : (
        <>
          Deleting the following items may cause <strong>broken links</strong> because they may be
          used in other content items.
        </>
      )}
    </Warning>
  </Box>
);

export const ContentItemsBulkDeleteConfirmationDialog: React.FC = () => {
  const dispatch = useDispatch();
  const languageName = useSelector((s) =>
    getLanguageName(
      getSelectedLanguageIdOrThrow(s),
      getAllLanguagesWithDefaultSuffix(s.data.languages),
    ),
  );
  const isMultilingual = useSelector(isProjectMultilingual);
  const selectedItemsCount = useSelector(
    (s) => s.contentApp.listingUi.itemsSelection.selectedItemIds.size,
  );
  const itemGrammaticalNumberForm = getItemGrammaticalNumberForm(selectedItemsCount);

  const { isLoading, contentItemUsages, selectedItemIds } = useSelectedContentItemUsedIn();

  return (
    <ConfirmationDialog
      onConfirm={() => dispatch(deleteSelectedContentItems())}
      onClose={() => dispatch(modalDismissed())}
      headerContent={`Delete content ${itemGrammaticalNumberForm}`}
      confirmButtonText={`Delete${
        selectedItemsCount > 1 ? ` ${selectedItemsCount}` : ''
      } content ${itemGrammaticalNumberForm}`}
      hasConfirmButtonLoader={isLoading}
    >
      {isLoading ? (
        <Loader type={LoaderType.Sidebar} />
      ) : (
        <>
          <Box component="p">
            {isMultilingual ? (
              <>
                Are you sure you want to{' '}
                <strong>
                  permanently delete the selected content {itemGrammaticalNumberForm} in{' '}
                  {languageName}?
                </strong>
              </>
            ) : (
              <>
                Are you sure you want to{' '}
                <strong>
                  permanently delete the selected content {itemGrammaticalNumberForm}?
                </strong>
              </>
            )}
          </Box>
          {selectedItemsCount === 1 &&
          !contentItemUsages.get(getFirstSelectedItemId(selectedItemIds))?.size ? null : (
            <FriendlyWarning
              selectedItemIds={selectedItemIds}
              contentItemUsages={contentItemUsages}
            />
          )}
        </>
      )}
    </ConfirmationDialog>
  );
};
