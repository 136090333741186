import { isElementVerticallyOverflowing } from '@kontent-ai/DOM';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { css } from 'styled-components';
import useResizeObserver from 'use-resize-observer';
import { Divider } from '../../../../../client/component-library/components/Dividers/Divider.tsx';
import { DividerDirection } from '../../../../../client/component-library/components/Dividers/components/StyledDivider.tsx';
import { ScrollContainerContextProvider } from '../../../../../client/component-library/components/ScrollContainer/ScrollContainerContext.tsx';
import { Box } from '../../../layout/Box/Box.tsx';
import { spacingCard } from '../../../tokens/decision/spacing.ts';
import { Color } from '../../../tokens/quarks/colors.ts';
import { GradientType } from '../../../tokens/quarks/gradients.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { Typography } from '../../../tokens/quarks/typography.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { CLPropTypes } from '../../../validators/propTypes.ts';

const overflowModeValues = ['auto', 'scrollable', 'default'] as const;
type OverflowMode = (typeof overflowModeValues)[number];

interface ICardBodyProps {
  readonly children: React.ReactNode;
  readonly backgroundColor?: Color;
  readonly backgroundImage?: GradientType | string;
  readonly overflowMode?: OverflowMode;
}

const propTypes: PropTypeMap<ICardBodyProps> = {
  children: PropTypes.node.isRequired,
  backgroundColor: CLPropTypes.colors.color,
  backgroundImage: PropTypes.oneOfType([CLPropTypes.colors.gradient, PropTypes.string]),
  overflowMode: PropTypes.oneOf(overflowModeValues),
};

const shouldShowDividers = (overflowMode: OverflowMode, isOverflowing: boolean): boolean => {
  if (overflowMode === 'scrollable') {
    return true;
  }

  if (overflowMode === 'default') {
    return false;
  }

  return isOverflowing;
};

export const CardBody = React.forwardRef<HTMLDivElement, React.PropsWithChildren<ICardBodyProps>>(
  (
    { children, backgroundColor, backgroundImage, overflowMode = 'default', ...otherProps },
    forwardedRef,
  ) => {
    const scrollableRef = useRef<HTMLDivElement>(null);

    const [isOverflowing, setIsOverflowing] = useState(false);

    useResizeObserver({
      ref: scrollableRef,
      onResize: () => {
        setIsOverflowing(isElementVerticallyOverflowing(scrollableRef.current));
      },
    });

    const isScrollable = overflowMode === 'scrollable' || overflowMode === 'auto';
    const showDividers = shouldShowDividers(overflowMode, isOverflowing);

    return (
      <Box
        ref={forwardedRef}
        flexGrow={1}
        flexShrink={1}
        flexBasis="auto"
        display="flex"
        flexDirection="column"
        // offset padding of the whole Card because dividers need to be from edge to edge
        marginX={px(-1 * spacingCard)}
        overflow={isScrollable ? 'hidden' : undefined}
        css="grid-area: body;"
      >
        <ScrollContainerContextProvider
          scrollContainerRef={scrollableRef}
          tippyBoundaryRef={scrollableRef}
        >
          {showDividers && (
            <Divider
              direction={DividerDirection.Horizontal}
              offsetAfter={Spacing.None}
              offsetBefore={Spacing.None}
            />
          )}
          <Box
            ref={scrollableRef}
            backgroundColor={backgroundColor}
            backgroundImage={backgroundImage}
            paddingX={spacingCard}
            paddingY={showDividers ? spacingCard : undefined}
            typography={Typography.UIParagraph}
            css={`
              word-break: break-word;
              ${
                isScrollable &&
                css`
                overflow-x: hidden;
                overflow-y: auto;
              `
              }
              height: 100%;
            `}
            {...otherProps}
          >
            {children}
          </Box>
          {showDividers && (
            <Divider
              direction={DividerDirection.Horizontal}
              offsetAfter={Spacing.None}
              offsetBefore={Spacing.None}
            />
          )}
        </ScrollContainerContextProvider>
      </Box>
    );
  },
);

CardBody.displayName = 'CardBody';
CardBody.propTypes = propTypes;
