import { Collection } from '@kontent-ai/utils';
import React from 'react';
import {
  AuditLogRoute,
  CollectionsRoute,
  DefaultWorkflowRoute,
  GeneralEnvironmentSettingsRoute,
  LegacyWebhooksRoute,
  LocalizationRoute,
  PreviewURLsRoute,
  RolesRoute,
  SpacesRoute,
  SubscriptionEnvironmentAuditLogRoute,
  SubscriptionEnvironmentCollectionsRoute,
  SubscriptionEnvironmentDefaultWorkflowRoute,
  SubscriptionEnvironmentGeneralSettingsRoute,
  SubscriptionEnvironmentLegacyWebhooksRoute,
  SubscriptionEnvironmentLocalizationRoute,
  SubscriptionEnvironmentPreviewURLsRoute,
  SubscriptionEnvironmentRolesRoute,
  SubscriptionEnvironmentSpacesRoute,
  SubscriptionEnvironmentUsersRoute,
  SubscriptionEnvironmentWebhooksRoute,
  SubscriptionEnvironmentWorkflowsRoute,
  UsersRoute,
  WebhooksRoute,
  WorkflowsRoute,
} from '../../../_shared/constants/routePaths.ts';
import { isMultipleWorkflowsConfigurationVisible } from '../../../_shared/selectors/Workflows/isMultipleWorkflowsConfigurationVisible.ts';
import { isCollectionsConfigurationVisible } from '../../../_shared/selectors/contentCollections.ts';
import { isLegacyWebhookConfigurationEnabled } from '../../../_shared/selectors/isLegacyWebhookConfigurationEnabled.ts';
import { IStore } from '../../../_shared/stores/IStore.type.ts';
import {
  DataUiAppName,
  ObjectWithDataAttribute,
  getDataUiNavAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { Capability } from '../../../_shared/utils/permissions/capability.ts';
import {
  InnovationLabSettingsRoute,
  SubscriptionInnovationLabSettingsRoute,
} from '../innovationLab/constants/routePaths.ts';
import { canAccessInnovationLab } from '../innovationLab/selectors/canAccessInnovationLab.ts';
import { NewWebhooksOnboarding as NewWebhooksOnboardingComponent } from '../root/components/NewWebhooksOnboarding.tsx';
import { EnvironmentSettingsAppNames } from '../root/constants/EnvironmentSettingsAppNames.ts';
import {
  areSpacesEnabledForCurrentProject,
  isAuditLogEnabledForCurrentProjectPlan,
} from '../selectors/allowedFeaturesUtils.ts';
import { isEnvironmentGeneralSettingsTabVisible } from '../selectors/environmentSettingsMenuCustomVisibilities.ts';

export type EnvironmentSettingsMenuStructureGroupNestedItem = {
  readonly dataAttributes: ObjectWithDataAttribute;
  readonly isDisabled?: (state: IStore) => boolean;
  readonly renderAuxElements?: () => React.ReactNode;
  readonly routePath: string;
  readonly text: string;
};

type EnvironmentSettingsMenuStructureGroupItemBase = {
  readonly dataAttributes: ObjectWithDataAttribute;
  readonly isDisabled?: (state: IStore) => boolean;
  readonly nestedItems?: ReadonlyArray<EnvironmentSettingsMenuStructureGroupNestedItem>;
  readonly renderAuxElements?: () => React.ReactNode;
  readonly routePath: string;
  readonly text: string;
  readonly customRender?: (defaultItem: React.ReactNode) => React.ReactNode;
  readonly shouldCustomRender?: (state: IStore) => boolean;
  readonly isFeatureEnabled?: (state: IStore) => boolean;
};

type EnvironmentSettingsMenuStructureGroupItemWithoutCapability =
  EnvironmentSettingsMenuStructureGroupItemBase &
    Required<Pick<EnvironmentSettingsMenuStructureGroupItemBase, 'isFeatureEnabled'>>;

export type EnvironmentSettingsMenuStructureGroupItemWithCapability =
  EnvironmentSettingsMenuStructureGroupItemBase & {
    readonly requiredCapability: Capability;
  };

export type EnvironmentSettingsMenuStructureGroupItem =
  | EnvironmentSettingsMenuStructureGroupItemWithoutCapability
  | EnvironmentSettingsMenuStructureGroupItemWithCapability;

const isSimpleWorkflowConfigurationVisible = (state: IStore) =>
  !isMultipleWorkflowsConfigurationVisible(state);

export const environmentSettingsMenuStructure = (
  subscriptionId: Uuid | null = null,
): ReadonlyArray<EnvironmentSettingsMenuStructureGroupItem> => [
  {
    routePath: subscriptionId
      ? SubscriptionEnvironmentGeneralSettingsRoute
      : GeneralEnvironmentSettingsRoute,
    text: EnvironmentSettingsAppNames.General,
    dataAttributes: getDataUiNavAttribute(DataUiAppName.General),
    isFeatureEnabled: isEnvironmentGeneralSettingsTabVisible,
  },
  {
    requiredCapability: Capability.ManageUsers,
    routePath: subscriptionId ? SubscriptionEnvironmentUsersRoute : UsersRoute,
    text: EnvironmentSettingsAppNames.Users,
    dataAttributes: getDataUiNavAttribute(DataUiAppName.Users),
  },
  {
    requiredCapability: Capability.ManageRoles,
    routePath: subscriptionId ? SubscriptionEnvironmentRolesRoute : RolesRoute,
    text: EnvironmentSettingsAppNames.Roles,
    dataAttributes: getDataUiNavAttribute(DataUiAppName.Roles),
  },
  {
    requiredCapability: Capability.ConfigureWorkflow,
    routePath: subscriptionId ? SubscriptionEnvironmentWorkflowsRoute : WorkflowsRoute,
    text: EnvironmentSettingsAppNames.MultipleWorkflows,
    dataAttributes: getDataUiNavAttribute(DataUiAppName.MultipleWorkflows),
    isFeatureEnabled: isMultipleWorkflowsConfigurationVisible,
  },
  {
    requiredCapability: Capability.ConfigureWorkflow,
    routePath: subscriptionId ? SubscriptionEnvironmentDefaultWorkflowRoute : DefaultWorkflowRoute,
    text: EnvironmentSettingsAppNames.Workflow,
    dataAttributes: getDataUiNavAttribute(DataUiAppName.WorkflowStepsListing),
    isFeatureEnabled: isSimpleWorkflowConfigurationVisible,
  },
  {
    requiredCapability: Capability.ManageSpaces,
    routePath: subscriptionId ? SubscriptionEnvironmentSpacesRoute : SpacesRoute,
    text: EnvironmentSettingsAppNames.Spaces,
    dataAttributes: getDataUiNavAttribute(DataUiAppName.Spaces),
    isFeatureEnabled: areSpacesEnabledForCurrentProject,
  },
  {
    requiredCapability: Capability.ManageCollections,
    routePath: subscriptionId ? SubscriptionEnvironmentCollectionsRoute : CollectionsRoute,
    text: EnvironmentSettingsAppNames.Collections,
    dataAttributes: getDataUiNavAttribute(DataUiAppName.ContentCollections),
    isFeatureEnabled: (state: IStore) =>
      isCollectionsConfigurationVisible(state, Collection.getValues(state.data.collections.byId)),
  },
  {
    requiredCapability: Capability.ConfigureLanguages,
    routePath: subscriptionId ? SubscriptionEnvironmentLocalizationRoute : LocalizationRoute,
    text: EnvironmentSettingsAppNames.Localization,
    dataAttributes: getDataUiNavAttribute(DataUiAppName.Localization),
  },
  {
    requiredCapability: Capability.ConfigurePreviewUrl,
    routePath: subscriptionId ? SubscriptionEnvironmentPreviewURLsRoute : PreviewURLsRoute,
    text: EnvironmentSettingsAppNames.PreviewConfiguration,
    dataAttributes: getDataUiNavAttribute(DataUiAppName.PreviewUrls),
  },
  {
    requiredCapability: Capability.ManageWebhooks,
    routePath: subscriptionId ? SubscriptionEnvironmentWebhooksRoute : WebhooksRoute,
    text: EnvironmentSettingsAppNames.Webhooks,
    dataAttributes: getDataUiNavAttribute(DataUiAppName.Webhooks),
    shouldCustomRender: isLegacyWebhookConfigurationEnabled,
    customRender: (defaultItem) => (
      <div key={WebhooksRoute}>
        <NewWebhooksOnboardingComponent step={1} element={defaultItem} />
      </div>
    ),
  },
  {
    requiredCapability: Capability.ManageWebhooks,
    routePath: subscriptionId ? SubscriptionEnvironmentLegacyWebhooksRoute : LegacyWebhooksRoute,
    text: EnvironmentSettingsAppNames.LegacyWebhooks,
    dataAttributes: getDataUiNavAttribute(DataUiAppName.LegacyWebhooks),
    isFeatureEnabled: isLegacyWebhookConfigurationEnabled,
    shouldCustomRender: isLegacyWebhookConfigurationEnabled,
    customRender: (defaultItem) => (
      <div key={LegacyWebhooksRoute}>
        <NewWebhooksOnboardingComponent step={2} element={defaultItem} />
      </div>
    ),
  },
  {
    requiredCapability: Capability.AccessAuditLog,
    routePath: subscriptionId ? SubscriptionEnvironmentAuditLogRoute : AuditLogRoute,
    text: EnvironmentSettingsAppNames.AuditLog,
    dataAttributes: getDataUiNavAttribute(DataUiAppName.AuditLog),
    isFeatureEnabled: isAuditLogEnabledForCurrentProjectPlan,
  },
  {
    routePath: subscriptionId ? SubscriptionInnovationLabSettingsRoute : InnovationLabSettingsRoute,
    text: EnvironmentSettingsAppNames.InnovationLab,
    dataAttributes: getDataUiNavAttribute(DataUiAppName.InnovationLab),
    isFeatureEnabled: canAccessInnovationLab,
  },
];
