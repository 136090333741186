import PropTypes from 'prop-types';
import React from 'react';
import { ObjectWithDataAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { SidebarSubmitButton } from './SidebarSubmitButton.tsx';

export interface ISidebarSubmitButtonWithErrorMessageProps {
  readonly buttonLabel: string;
  readonly onSubmit: (() => void) | undefined;
  readonly disabled: boolean;
  readonly isBeingSubmitted?: boolean;
  readonly dataUiAttribute?: ObjectWithDataAttribute;
  readonly errorMessage: string;
  readonly tooltipText?: string;
}

const propTypes: PropTypesShape<ISidebarSubmitButtonWithErrorMessageProps> = {
  buttonLabel: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  isBeingSubmitted: PropTypes.bool,
  dataUiAttribute: PropTypes.object,
  errorMessage: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
};

export const SidebarSubmitButtonWithErrorMessage: React.FC<
  ISidebarSubmitButtonWithErrorMessageProps
> = ({
  onSubmit,
  disabled,
  dataUiAttribute,
  buttonLabel,
  errorMessage,
  isBeingSubmitted,
  tooltipText,
}) => (
  <>
    <div className="sidebar__submit-action">
      <SidebarSubmitButton
        onSubmit={onSubmit}
        disabled={disabled}
        dataUiAttribute={dataUiAttribute}
        text={buttonLabel}
        tooltipText={tooltipText}
        isBeingSubmitted={isBeingSubmitted}
      />
    </div>
    {errorMessage && <div className="sidebar__submit-alert">{errorMessage}</div>}
  </>
);

SidebarSubmitButtonWithErrorMessage.displayName = 'SidebarSubmitButtonWithErrorMessage';
SidebarSubmitButtonWithErrorMessage.propTypes = propTypes;
