import React, { memo } from 'react';
import {
  DataUiElement,
  DataUiInput,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { createEditorWithPlugins } from '../../editorCore/Editor.composition.tsx';
import { EditorProps } from '../../editorCore/types/Editor.composition.type.ts';
import { useDiff } from '../../plugins/diff/DiffPlugin.tsx';
import { useComposedViewer } from './RichTextViewer.tsx';

const useComposedDiffViewer = () => {
  return createEditorWithPlugins(useComposedViewer(), useDiff);
};

export type RichTextDiffProps = EditorProps<typeof useComposedDiffViewer>;

export const RichTextDiff: React.FC<RichTextDiffProps> = memo((props) => {
  const { ComposedEditor } = useComposedDiffViewer();

  return (
    <ComposedEditor
      dataUiInput={props.dataUiInput ?? DataUiInput.ItemTextElement}
      dataUiElement={props.dataUiElement ?? DataUiElement.RichTextViewer}
      tabIndex={props.tabIndex ?? -1}
      {...props}
    />
  );
});

RichTextDiff.displayName = 'RichTextDiff';
