import { ContentState } from 'draft-js';
import { Dispatch, GetState, ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../../../_shared/models/TrackUserEvent.type.ts';
import { CommentEventTypes } from '../../../../../../_shared/models/events/ContentItemEventData.type.ts';
import { ICommentRepository } from '../../../../../../repositories/interfaces/ICommentRepository.type.ts';
import { createCommentContentServerModel } from '../../../../models/comments/Comment.ts';
import { CommentThreadItemType } from '../../../../models/comments/CommentThreadItem.ts';
import {
  CommentThreadType,
  ICommentThread,
  createCommentThreadDomainModel,
} from '../../../../models/comments/CommentThreads.ts';
import {
  ContentItemEditing_CommentThread_SubmittingFinished,
  ContentItemEditing_CommentThread_SubmittingStarted,
} from '../../constants/contentItemEditingActionTypes.ts';

const newCommentThreadSubmittingFinished = (newCommentThread: ICommentThread) =>
  ({
    type: ContentItemEditing_CommentThread_SubmittingFinished,
    payload: { newCommentThread },
  }) as const;

const newCommentThreadSubmittingStarted = () =>
  ({
    type: ContentItemEditing_CommentThread_SubmittingStarted,
  }) as const;

export type SaveNewCommentThreadToServerActionsType = ReturnType<
  typeof newCommentThreadSubmittingFinished | typeof newCommentThreadSubmittingStarted
>;

interface ISaveNewCommentThreadToServerDependencies {
  readonly commentRepository: ICommentRepository;
  readonly trackUserEventWithData: TrackUserEventWithDataAction;
}

export const createSaveNewCommentThreadToServerAction =
  (deps: ISaveNewCommentThreadToServerDependencies) =>
  (commentContent: ContentState): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {
      contentApp: { editedContentItemVariant },
    } = getState();

    if (!commentContent.hasText() || !editedContentItemVariant) {
      return;
    }
    dispatch(newCommentThreadSubmittingStarted());

    const { itemId, variantId } = editedContentItemVariant.id;

    const newCommentThreadServerModel = await deps.commentRepository.createCommentThread(
      itemId,
      variantId,
      {
        threadType: CommentThreadType.Global,
        threadItems: [
          {
            type: CommentThreadItemType.Comment,
            content: createCommentContentServerModel(commentContent),
          },
        ],
      },
    );

    const newCommentThread = createCommentThreadDomainModel(newCommentThreadServerModel);

    // append new comment thread to stack
    dispatch(newCommentThreadSubmittingFinished(newCommentThread));

    dispatch(
      deps.trackUserEventWithData(TrackedEvent.Comments, {
        action: CommentEventTypes.CommentCreated,
      }),
    );
  };
