import { CharacterMetadata } from 'draft-js';
import Immutable from 'immutable';
import { CompositionEventHandler, useCallback, useMemo } from 'react';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { Apply, EditorPlugin, Render } from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import { getBlockIdClassName } from '../../editorCore/utils/editorComponentUtils.ts';
import { withDisplayName } from '../../editorCore/utils/withDisplayName.ts';
import { isCustomBlockSleeve, isEmptyTextBlock } from '../../utils/blocks/blockTypeUtils.ts';
import { findBlockIndex, setBlockText } from '../../utils/blocks/editorBlockUtils.ts';
import { getBlocks } from '../../utils/general/editorContentGetters.ts';
import { MentionsPlugin } from '../mentions/MentionsPlugin.tsx';
import { WrapperPlugin } from '../visuals/WrapperPlugin.tsx';

export type CompositionModeAdjustmentsPlugin = EditorPlugin<None, None, None, [WrapperPlugin]>;

// Transform empty text blocks and custom sleeve blocks into non-empty regular paragraph text blocks directly in DOM on composition start (IME, speech-to-text).
// Draft-js then takes care of the newly added content on composition end.
export const useCompositionModeAdjustments: PluginCreator<CompositionModeAdjustmentsPlugin> = (
  baseEditor,
) =>
  useMemo(
    () =>
      withDisplayName('CompositionModeAdjustmentsPlugin', {
        ComposedEditor: (props) => {
          const render: Decorator<Render<MentionsPlugin>> = useCallback(
            (baseRender) => (state) => {
              const handleCompositionStart: CompositionEventHandler = () => {
                const selection = state.editorState.getSelection();
                const content = state.editorState.getCurrentContent();
                const blocks = getBlocks(content);

                const selectionFocusIndex = findBlockIndex(blocks, selection.getStartKey());
                const firstBlockAtSelection = content.getBlockForKey(selection.getStartKey());

                if (!firstBlockAtSelection) {
                  return;
                }
                const firstBlockAtSelectionKey = firstBlockAtSelection.getKey();

                if (
                  isEmptyTextBlock(firstBlockAtSelection) ||
                  isCustomBlockSleeve(selectionFocusIndex, blocks)
                ) {
                  const blockIdClass = getBlockIdClassName(firstBlockAtSelectionKey);

                  // Adding random text to make block non-empty, actual content doesn't matter — ContentState is not affected.
                  const textBlock = setBlockText(firstBlockAtSelection, {
                    text: 'a',
                    characterList: Immutable.List<CharacterMetadata>([CharacterMetadata.create()]),
                  });

                  const textBlockClassName = state.editorProps.blockStyleFn?.(textBlock) ?? '';
                  const blockElement = state
                    .getWrapperRef()
                    .current?.getElementsByClassName(blockIdClass)[0];

                  if (!blockElement) {
                    return;
                  }

                  blockElement.className = textBlockClassName;
                }
              };

              const stateWithRestrictedComposition = {
                ...state,
                wrapperProps: {
                  ...state.wrapperProps,
                  onCompositionStart: handleCompositionStart,
                },
              };
              return baseRender(stateWithRestrictedComposition);
            },
            [],
          );

          const apply: Apply<CompositionModeAdjustmentsPlugin> = useCallback(
            (state) => {
              state.render.decorate(render);
              return {};
            },
            [render],
          );

          return useEditorWithPlugin(baseEditor, props, { apply });
        },
      }),
    [baseEditor],
  );
