export type Property = { name: string; type: string };

export function hasProperties(properties: Property[], obj: any): boolean {
  if (!obj || typeof obj !== 'object' || Array.isArray(obj)) {
    return false;
  }

  return properties.reduce((result: boolean, property: Property): boolean => {
    if (!result) {
      return false;
    }
    if (property.type === 'any') {
      return Object.hasOwn(obj, property.name);
    }

    // biome-ignore lint/suspicious/useValidTypeof:
    return Object.hasOwn(obj, property.name) && typeof obj[property.name] === property.type;
  }, true);
}
