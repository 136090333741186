import PropTypes from 'prop-types';
import React, { ReactNode } from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

interface IFormAlertProps {
  readonly children: ReactNode;
  readonly isDisplayed: boolean;
}

export const FormFieldError: React.FC<IFormAlertProps> = ({ children, isDisplayed }) =>
  isDisplayed && children ? (
    <div className="form__alert" {...getDataUiElementAttribute(DataUiElement.NotificationAlert)}>
      {children}
    </div>
  ) : null;

const propTypes: PropTypesShape<IFormAlertProps> = {
  children: PropTypes.node,
  isDisplayed: PropTypes.bool.isRequired,
};
FormFieldError.displayName = 'FormFieldError';
FormFieldError.propTypes = propTypes;
