import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { markEditedItemAsTemporary } from '../../../applications/itemEditor/features/ContentItemEditing/actions/contentItemEditingActions.ts';
import { createNewContentItem } from '../../../applications/itemEditor/features/NewContentItem/actions/thunkNewContentItemActions.ts';
import { onboardingNotificationClosed } from '../../actions/sharedActions.ts';
import { trackUserEventWithData } from '../../actions/thunks/trackUserEvent.ts';
import { OnboardingNotificationBar } from '../../components/OnboardingNotifications/OnboardingNotificationBar.tsx';
import { TrackedEvent } from '../../constants/trackedEvent.ts';
import { useDispatch } from '../../hooks/useDispatch.ts';
import { getContentItemPath } from '../../utils/routing/routeTransitionUtils.ts';

const Message: React.FC = () => (
  <>
    🎉 Nice job! Your content type is now ready. 🎉 Create content based on this type to see it in
    action!
  </>
);

interface IContentTypeEditingOnboardingNotificationProps {
  readonly editedContentTypeId: Uuid;
  readonly languageIdToCreateItem: Uuid;
  readonly collectionIdToCreateItem: Uuid;
}

export const ContentTypeEditingOnboardingNotification: React.FC<
  IContentTypeEditingOnboardingNotificationProps
> = ({ collectionIdToCreateItem, editedContentTypeId, languageIdToCreateItem }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      trackUserEventWithData(TrackedEvent.FeatureUsed, {
        feature: 'onboarding-notification--displayed',
        info: 'content-type-editing',
      }),
    );
  }, []);

  const onClose = () => {
    dispatch(onboardingNotificationClosed());
  };

  const onButtonClick = () => {
    dispatch(
      trackUserEventWithData(TrackedEvent.FeatureUsed, {
        feature: 'onboarding-notification--cta',
        info: 'content-type-editing',
      }),
    );
    dispatch(onboardingNotificationClosed());
    dispatch(
      createNewContentItem({ typeId: editedContentTypeId, collectionId: collectionIdToCreateItem }),
    ).then((createdItem) => {
      dispatch(
        markEditedItemAsTemporary({
          itemId: createdItem.item.id,
          variantId: languageIdToCreateItem,
          parentItem: null,
        }),
      );
      const contentItemPath = getContentItemPath(
        location.pathname,
        createdItem.item.id,
        languageIdToCreateItem,
      );
      history.push(contentItemPath);
    });
  };

  return (
    <OnboardingNotificationBar
      buttonMessage="Create content based on this type"
      message={<Message />}
      onButtonClick={onButtonClick}
      onClose={onClose}
    />
  );
};

ContentTypeEditingOnboardingNotification.displayName = 'ContentTypeEditingOnboardingNotification';
