import Immutable from 'immutable';
import { Capability } from '../../../../_shared/utils/permissions/capability.ts';
import { IContentType } from '../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IRoleSettings } from '../../../../data/models/roles/IRoleSettings.ts';
import {
  Role_Editing_CanRuleGroupAdded,
  Role_Editing_CanRuleGroupRemoved,
  Role_Editing_CanRuleTypeAdded,
  Role_Editing_CanRuleTypeRemoved,
  Role_Editing_CannotRuleGroupAdded,
  Role_Editing_CannotRuleGroupRemoved,
  Role_Editing_CannotRuleTypeAdded,
  Role_Editing_CannotRuleTypeRemoved,
  Role_Editing_CapabilitiesChecked,
  Role_Editing_CapabilitiesUnchecked,
  Role_Editing_NameChanged,
  Role_Editing_RoleSettingsChanged,
  Role_Validation_Failed,
  Roles_Listing_Left,
} from '../constants/rolesActionTypes.ts';

export const roleNameChanged = (name: string) =>
  ({
    type: Role_Editing_NameChanged,
    payload: {
      name,
    },
  }) as const;

export const capabilitiesChecked = (capabilities: Immutable.Set<Capability>) =>
  ({
    type: Role_Editing_CapabilitiesChecked,
    payload: {
      capabilities,
    },
  }) as const;

export const capabilitiesUnchecked = (capabilities: Immutable.Set<Capability>) =>
  ({
    type: Role_Editing_CapabilitiesUnchecked,
    payload: {
      capabilities,
    },
  }) as const;

export const roleSettingsChanged = (roleSettings: IRoleSettings) =>
  ({
    type: Role_Editing_RoleSettingsChanged,
    payload: {
      roleSettings,
    },
  }) as const;

export const canRuleTypeAdded = (
  allTypes: Immutable.Map<Uuid, IContentType>,
  ruleIndex: number,
  typeId: Uuid,
) =>
  ({
    type: Role_Editing_CanRuleTypeAdded,
    payload: {
      allTypes,
      ruleIndex,
      typeId,
    },
  }) as const;

export const canRuleTypeRemoved = (
  allTypes: Immutable.Map<Uuid, IContentType>,
  ruleIndex: number,
  typeId: Uuid,
) =>
  ({
    type: Role_Editing_CanRuleTypeRemoved,
    payload: {
      allTypes,
      ruleIndex,
      typeId,
    },
  }) as const;

export const canRuleGroupAdded = (
  allTypes: Immutable.Map<Uuid, IContentType>,
  ruleIndex: number,
  typeId: Uuid,
  groupId: Uuid,
) =>
  ({
    type: Role_Editing_CanRuleGroupAdded,
    payload: {
      allTypes,
      ruleIndex,
      typeId,
      groupId,
    },
  }) as const;

export const canRuleGroupRemoved = (
  allTypes: Immutable.Map<Uuid, IContentType>,
  ruleIndex: number,
  typeId: Uuid,
  groupId: Uuid,
) =>
  ({
    type: Role_Editing_CanRuleGroupRemoved,
    payload: {
      allTypes,
      ruleIndex,
      typeId,
      groupId,
    },
  }) as const;

export const cannotRuleTypeAdded = (
  allTypes: Immutable.Map<Uuid, IContentType>,
  ruleIndex: number,
  typeId: Uuid,
) =>
  ({
    type: Role_Editing_CannotRuleTypeAdded,
    payload: {
      allTypes,
      ruleIndex,
      typeId,
    },
  }) as const;

export const cannotRuleTypeRemoved = (
  allTypes: Immutable.Map<Uuid, IContentType>,
  ruleIndex: number,
  typeId: Uuid,
) =>
  ({
    type: Role_Editing_CannotRuleTypeRemoved,
    payload: {
      allTypes,
      ruleIndex,
      typeId,
    },
  }) as const;

export const cannotRuleGroupAdded = (
  allTypes: Immutable.Map<Uuid, IContentType>,
  ruleIndex: number,
  typeId: Uuid,
  groupId: Uuid,
) =>
  ({
    type: Role_Editing_CannotRuleGroupAdded,
    payload: {
      allTypes,
      ruleIndex,
      typeId,
      groupId,
    },
  }) as const;

export const cannotRuleGroupRemoved = (
  allTypes: Immutable.Map<Uuid, IContentType>,
  ruleIndex: number,
  typeId: Uuid,
  groupId: Uuid,
) =>
  ({
    type: Role_Editing_CannotRuleGroupRemoved,
    payload: {
      allTypes,
      ruleIndex,
      typeId,
      groupId,
    },
  }) as const;

export const onRoleListingLeft = () =>
  ({
    type: Roles_Listing_Left,
  }) as const;

export const roleValidationFailed = () =>
  ({
    type: Role_Validation_Failed,
  }) as const;
