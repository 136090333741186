import { repositoryCollection } from '../repositories/repositories.ts';
import { expireCookies, getExistingCookies } from './cookieUtils.ts';
import { IntercomUtils } from './intercomUtils.ts';

const { accountRepository } = repositoryCollection;

type StringToStringMap = Record<string, string>;

const campaignCookieNameToPropertyNameMap: StringToStringMap = {
  utm_campaign: 'UTMCampaign',
  utm_term: 'UTMTerm',
  utm_content: 'UTMContent',
  utm_source: 'UTMSource',
  url_referrer: 'URLReferrer',
  utm_medium: 'UTMMedium',
};

export const campaignCookieNames = Object.keys(campaignCookieNameToPropertyNameMap);

function removeCampaignCookies() {
  expireCookies(campaignCookieNames);
}

function getPropertyNameFromCookieName(cookieName: string): string | null {
  return campaignCookieNameToPropertyNameMap[cookieName] || null;
}

function setUserCampaignProperty(userId: string, cookieName: string, cookieValue: string) {
  const name = getPropertyNameFromCookieName(cookieName);
  if (name && cookieValue) {
    accountRepository.upsertProperty(userId, name, cookieValue);
  }
}

function sendCampaignDataToIntercom(cookies: StringToStringMap) {
  const campaignData = campaignCookieNames.reduce((data, cookieName) => {
    return Object.assign({}, data, {
      [cookieName]: cookies[cookieName] || null,
    });
  }, {});
  IntercomUtils.update(campaignData);
}

function trackData(userId: string, documentCookie: string) {
  const cookies = getExistingCookies(documentCookie);
  campaignCookieNames.forEach((name) => setUserCampaignProperty(userId, name, cookies[name] ?? ''));
  sendCampaignDataToIntercom(cookies);
  removeCampaignCookies();
}

export function isCampaignRelatedCookieAvailable(documentCookie: string) {
  const cookies = getExistingCookies(documentCookie);
  return campaignCookieNames.some((name) => Object.hasOwn(cookies, name));
}

export function trackUserCampaign(userId: string, documentCookie: string) {
  const usersOriginIsKnownPromise = accountRepository.getProperty(userId, 'URLReferrer');
  usersOriginIsKnownPromise
    .then(() => removeCampaignCookies())
    .catch(() => {
      trackData(userId, documentCookie);
    });
}

export function shouldTrialBeExtended(documentCookie: string): boolean {
  const extendedTrialParamValue = 'extended_trial';
  const utmCampaignParam = 'utm_campaign';
  const cookies = getExistingCookies(documentCookie);
  return (
    Object.hasOwn(cookies, utmCampaignParam) &&
    cookies[utmCampaignParam] === extendedTrialParamValue
  );
}
