import { Button } from '@kontent-ai/component-library/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';

interface IProps {
  readonly onCallToAction: () => void;
}

const propTypes: PropTypesShape<IProps> = {
  onCallToAction: PropTypes.func.isRequired,
};

export const EmptyProjectEmptyState: React.FC<IProps> = (props) => (
  <EmptyState size="fullOffset">
    <EmptyState.Title>It’s kind of lonely here.</EmptyState.Title>
    <EmptyState.Content>
      Bring your project to life, start creating eye-catching content.
    </EmptyState.Content>
    <EmptyState.Footer>
      <Button buttonStyle="primary" onClick={props.onCallToAction}>
        Create new content item
      </Button>
    </EmptyState.Footer>
  </EmptyState>
);

EmptyProjectEmptyState.displayName = 'EmptyProjectEmptyState';
EmptyProjectEmptyState.propTypes = propTypes;
