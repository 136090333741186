import { Node } from '@react-types/shared';
import { ReactElement } from 'react';
import { Divider } from '../../../../../client/component-library/components/Dividers/Divider.tsx';
import { DividerDirection } from '../../../../../client/component-library/components/Dividers/components/StyledDivider.tsx';
import { dividerBorderWidth } from '../../../../../client/component-library/components/Dividers/decisionTokens.ts';
import { Box } from '../../../layout/Box/Box.tsx';
import { BaseColor } from '../../../tokens/quarks/colors.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { Typography } from '../../../tokens/quarks/typography.ts';
import {
  DataUiCLElement,
  getDataUiCLElementAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { VerticalMenuItem, VerticalMenuSection } from '../types.ts';
import { IItemProps } from './Item.tsx';

const verticalMenuDividerOffsetBefore = Spacing.L;
const verticalMenuDividerOffsetAfter = Spacing.S;
export const verticalMenuDividerSize =
  verticalMenuDividerOffsetBefore + dividerBorderWidth + verticalMenuDividerOffsetAfter;

const verticalMenuSectionPaddingY = Spacing.S;
const verticalMenuSectionTypography = Typography.HeadlineMedium;
const verticalMenuSectionLineHeight = Number.parseInt(
  verticalMenuSectionTypography.lineHeight as string,
  10,
);
export const verticalMenuSectionSize =
  verticalMenuSectionPaddingY + verticalMenuSectionLineHeight + verticalMenuSectionPaddingY;
export const verticalMenuSectionWithDividerSize = verticalMenuDividerSize + verticalMenuSectionSize;

export interface ISectionProps<TItem extends VerticalMenuItem<TItem>>
  extends Omit<IItemProps<TItem>, 'expandedKeys' | 'item'> {
  readonly isFirst?: boolean;
  readonly item: Node<VerticalMenuSection<TItem> | TItem>;
}

export const Section = <TItem extends VerticalMenuItem<TItem>>(
  props: ISectionProps<TItem>,
): ReactElement => {
  const { isFirst, item } = props;

  return (
    <>
      {!isFirst && (
        <Divider
          color={BaseColor.Gray20}
          direction={DividerDirection.Horizontal}
          offsetBefore={Spacing.L}
          offsetAfter={Spacing.S}
        />
      )}
      {item.rendered && (
        <Box paddingX={Spacing.L} paddingY={verticalMenuSectionPaddingY}>
          <Box
            component="h3"
            typography={verticalMenuSectionTypography}
            overflow="hidden"
            css={`
              white-space: nowrap;
              text-overflow: ellipsis;
            `}
            {...getDataUiCLElementAttribute(DataUiCLElement.SectionHeadline)}
          >
            {item.rendered}
          </Box>
        </Box>
      )}
    </>
  );
};

Section.displayName = 'Section';
