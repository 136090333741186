import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { ObjectWithDataAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import {
  IForwardedRefProps,
  forwardRef,
  forwardedRefProps,
} from '../../utils/forwardedRefProps.tsx';
import { AnimatedChevron, RestDirection } from '../AnimatedChevron/AnimatedChevron.tsx';

interface IDropDownSelectedProps extends IForwardedRefProps<HTMLDivElement> {
  readonly children: React.ReactNode;
  readonly className?: string;
  readonly dataUiAttributes?: ObjectWithDataAttribute;
  readonly isOpen: boolean;
  readonly onClick: React.MouseEventHandler<HTMLElement>;
}

const propTypes: PropTypesShape<IDropDownSelectedProps> = {
  ...forwardedRefProps,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dataUiAttributes: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const DropDownSelected: React.FC<IDropDownSelectedProps> = ({
  children,
  className,
  dataUiAttributes,
  forwardedRef,
  isOpen,
  onClick,
}) => {
  return (
    <div
      ref={forwardedRef}
      className={classNames(className, 'dropdown-selected')}
      onClick={onClick}
    >
      <div className="dropdown-selected__pane" {...dataUiAttributes}>
        {children}
        <AnimatedChevron
          className="dropdown-selected__item-icon"
          isTurned={isOpen}
          restDirection={RestDirection.Down}
        />
      </div>
    </div>
  );
};

DropDownSelected.displayName = 'DropDownSelected';
DropDownSelected.propTypes = propTypes;

const DropDownSelectedFRC = forwardRef(DropDownSelected);
export { DropDownSelectedFRC as DropDownSelected };
