import { isSet } from '@kontent-ai/utils';
import { ElementError, ElementErrorResult, emptyElementErrorResult } from './types/Errors.ts';

interface IParams {
  readonly value: ReadonlySet<Uuid>;
}

const invalidElementResult: ElementErrorResult = {
  errors: [ElementError.InvalidTaxonomy],
};

export const createTaxonomyElementErrorChecker =
  (isUuid: (uuid?: string | null | undefined) => boolean) =>
  ({ value }: IParams): ElementErrorResult => {
    const isValid = isSet(value) && [...value].every(isUuid);

    return isValid ? emptyElementErrorResult : invalidElementResult;
  };
