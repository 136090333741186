import { useMenuTrigger } from '@react-aria/menu';
import { useMenuTriggerState } from '@react-stately/menu';
import { MenuTriggerProps } from '@react-types/menu';
import React, { PropsWithChildren } from 'react';
import { MenuContextProvider } from './MenuContext.tsx';
import { MenuItem } from './MenuItem.tsx';
import { MenuList } from './MenuList.tsx';
import { MenuOutwardLink } from './MenuOutwardLink.tsx';
import { MenuRouterLink } from './MenuRouterLink.tsx';
import { MenuTrigger } from './MenuTrigger.tsx';
import { Submenu } from './Submenu.tsx';
import { SubmenuTriggerItem } from './SubmenuTriggerItem.tsx';

type Props = MenuTriggerProps;

const MenuComponent = (props: PropsWithChildren<Props>) => {
  const menuTriggerState = useMenuTriggerState(props);

  const triggerRef = React.useRef(null);
  const menuListRef = React.useRef(null);
  const { menuTriggerProps, menuProps } = useMenuTrigger<any>(
    {
      type: 'menu',
    },
    menuTriggerState,
    triggerRef,
  );

  return (
    <MenuContextProvider
      menuTriggerProps={menuTriggerProps}
      menuTriggerRef={triggerRef}
      menuTriggerState={menuTriggerState}
      menuListProps={menuProps}
      menuListRef={menuListRef}
    >
      {props.children}
    </MenuContextProvider>
  );
};

const menuComposition = {
  Trigger: MenuTrigger,
  List: MenuList,
  Item: MenuItem,
  RouterLink: MenuRouterLink,
  OutwardLink: MenuOutwardLink,
  /**
   * @deprecated Not quite ready yet, DO NOT use in production!
   */
  Submenu,
  SubmenuTrigger: SubmenuTriggerItem,
} as const;

export const Menu = Object.assign(MenuComponent, menuComposition);
