import PropTypes from 'prop-types';
import React from 'react';
import { ContentItemEditingEventOrigins } from '../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { ActionsMenuSubMenu } from '../../../containers/editingActions/actionsMenu/ActionsMenuSubMenu.tsx';
import { EditingAction as EditingActionEnum } from '../../../models/EditingAction.ts';
import { doesActionRepresentMenuWithMultipleOptions } from '../../../utils/editingActions/doesActionRepresentMenuWithMultipleOptions.ts';
import { EditingLeafActionForm, UseCustomHandleClickOutside } from '../EditingLeafActionForm.tsx';
import { EditingAction } from '../actions/EditingAction.tsx';

type ActionsLeafMenuProps = {
  readonly activatedAction: EditingActionEnum;
  readonly useCustomHandleClickOutside: UseCustomHandleClickOutside;
};

export const ActionsLeafMenu: React.FC<ActionsLeafMenuProps> = ({
  activatedAction,
  useCustomHandleClickOutside,
}) => {
  const leafForm = (
    <EditingLeafActionForm
      actionOrigin={ContentItemEditingEventOrigins.MoreActions}
      activatedAction={activatedAction}
      useCustomHandleClickOutside={useCustomHandleClickOutside}
    />
  );

  const isSubMenuWithMultipleOptions = doesActionRepresentMenuWithMultipleOptions(activatedAction);

  return (
    <EditingAction action={activatedAction}>
      {(actionDescriptor) => (
        <ActionsMenuSubMenu caption={actionDescriptor.subMenuCaption || actionDescriptor.caption}>
          {isSubMenuWithMultipleOptions ? (
            leafForm
          ) : (
            <div className="dropdown-item">{leafForm}</div>
          )}
        </ActionsMenuSubMenu>
      )}
    </EditingAction>
  );
};

ActionsLeafMenu.displayName = 'ActionsLeafMenu';
ActionsLeafMenu.propTypes = {
  activatedAction: PropTypes.oneOf(Object.values(EditingActionEnum)).isRequired,
  useCustomHandleClickOutside: PropTypes.func.isRequired,
};
