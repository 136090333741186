import { toArray } from '@kontent-ai/utils';
import { Property } from 'csstype';
import PropTypes, { Validator } from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { getVariableName } from '../../../../storybook/components/Docs/utils.ts';
import {
  PolymorphicComponent,
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../@types/PolymorphicComponent.type.ts';
import { px } from '../../tokens/utils/utils.ts';
import { CLPropTypes } from '../../validators/propTypes.ts';

const getPercentage = (scale: number) => scale * 100;

export const columnWidthMap = {
  '1/2': getPercentage(1 / 2),
  '1/3': getPercentage(1 / 3),
  '1/7': getPercentage(1 / 7),
  '2/3': getPercentage(2 / 3),
  '1/4': getPercentage(1 / 4),
  '3/4': getPercentage(3 / 4),
  '1/5': getPercentage(1 / 5),
  '2/5': getPercentage(2 / 5),
  '3/5': getPercentage(3 / 5),
  '4/5': getPercentage(4 / 5),
};

type Width = keyof typeof columnWidthMap | 'content' | 'fit-content';
type FlexFactor = number | readonly [flexGrow: number, flexShrink: number];

type WidthOrFlexFactor =
  | Readonly<{
      flexFactor?: never;
      flexBasis?: never;
      minWidth?: number;
      maxWidth?: never;
      width?: Width;
    }>
  | Readonly<{
      flexFactor: FlexFactor;
      flexBasis?: Property.FlexBasis<number>;
      minWidth?: number;
      maxWidth?: number;
      width?: never;
    }>;

export type ColumnProps = WidthOrFlexFactor;

const discriminatePropTypeValidator: Validator<FlexFactor | Width> = (
  { flexFactor, width }: ColumnProps,
  propName,
  componentName,
) => {
  if (flexFactor && width) {
    return new Error(
      `[${propName}]: Component ${componentName} does not support both ${getVariableName({
        width,
      })} and ${getVariableName({ flexFactor })} props`,
    );
  }
  return null;
};

const positiveNumberValidator: Validator<FlexFactor> = (
  { flexFactor }: ColumnProps,
  propName,
  componentName,
) => {
  if (flexFactor === undefined) {
    return null;
  }
  const flexFactorTuple = toArray(flexFactor);
  if (flexFactorTuple.some((f) => f < 1)) {
    return new Error(
      `[${propName}]: Component ${componentName} does not support negative integers or zero as ${propName}`,
    );
  }
  return null;
};

const propTypes: PropTypeMap<ColumnProps> = {
  flexFactor: CLPropTypes.multiple(
    PropTypes.oneOfType([
      PropTypes.number.isRequired,
      PropTypes.arrayOf(PropTypes.number.isRequired).isRequired as unknown as Validator<
        readonly [a: number, b: number]
      >,
    ]),
    discriminatePropTypeValidator as Validator<FlexFactor>,
    positiveNumberValidator,
  ) as any,
  flexBasis: CLPropTypes.cssTypes,
  width: CLPropTypes.multiple(
    discriminatePropTypeValidator as Validator<Width>,
    PropTypes.oneOf([...Object.keys(columnWidthMap), 'content', 'fit-content']),
  ) as any, // propTypes do not infer correct type for union types,
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
};

const noWidthStyles = css`
  flex: 1 1 auto;
  max-width: 100%;
`;
const contentWidthStyles = css`
  flex: 0 0 auto;
  max-width: 100%;
  width: auto;
`;
const fitContentWidthStyles = css`
  flex: 0 1 auto;
  max-width: 100%;
  width: auto;
`;

const getWidthStyles = ({
  $width,
  flexFactor,
  minWidth,
}: {
  readonly $width?: Width;
  readonly flexFactor?: FlexFactor;
  readonly minWidth?: number;
}) => {
  if (flexFactor) {
    return;
  }

  if (!$width) {
    return noWidthStyles;
  }

  if ($width === 'content') {
    return contentWidthStyles;
  }

  if ($width === 'fit-content') {
    return fitContentWidthStyles;
  }

  return css`
    flex: 0 0 ${columnWidthMap[$width]}%;
    ${minWidth === undefined ? '' : `min-width: ${px(minWidth)};`};
    max-width: ${columnWidthMap[$width]}%;
    width: ${columnWidthMap[$width]}%;
  `;
};

const getFlexFactorAttrs = ({
  flexFactor,
  flexBasis = 0,
  minWidth,
  maxWidth,
}: {
  readonly flexFactor?: FlexFactor;
  readonly flexBasis?: Property.FlexBasis<number>;
  readonly minWidth?: number;
  readonly maxWidth?: number;
}) => {
  if (flexFactor === undefined) {
    return;
  }
  const [flexGrow, flexShrink = flexGrow] = toArray<number>(flexFactor);
  return {
    style: {
      flexGrow,
      flexShrink,
      flexBasis,
      minWidth,
      maxWidth,
    },
  };
};

const StyledColumn = styled.div.attrs(getFlexFactorAttrs)`
  min-width: 0; /* important to prevent column overflow */
  padding-left: var(--column-spacing-X);

  ${getWidthStyles};
`;

export const Column = React.forwardRef(
  <TElement extends React.ElementType = 'div'>(
    {
      children,
      component,
      flexFactor,
      flexBasis,
      minWidth,
      maxWidth,
      width,
      ...otherProps
    }: React.PropsWithChildren<ColumnProps> & PolymorphicComponentProps<TElement>,
    ref: PolymorphicRef<TElement>,
  ) => (
    <StyledColumn
      as={component ?? 'div'}
      ref={ref}
      $width={width}
      flexFactor={flexFactor}
      flexBasis={flexBasis}
      minWidth={minWidth}
      maxWidth={maxWidth}
      {...otherProps}
    >
      {children}
    </StyledColumn>
  ),
) as PolymorphicComponent<ColumnProps>;

Column.displayName = 'Column';
Column.propTypes = propTypes;
