import { IBaseSelectItem, ISelectSection } from '@kontent-ai/component-library/Selects';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { createCollectionSelectorOptions } from '../utils/createCollectionSelectorOptions.ts';
import { getAvailableCollections } from '../utils/getAvailableCollections.ts';
import { getCollectionSelectorState } from '../utils/getCollectionSelectorState.ts';
import {
  getNewContentItemDialogProperties,
  getNewContentItemForm,
} from './newContentItemDialog.ts';

export const getCollectionOptions = (
  state: IStore,
): ReadonlyArray<ISelectSection<IBaseSelectItem>> => {
  const { origin } = getNewContentItemDialogProperties(state);
  const { languageId } = getNewContentItemForm(state);
  const { recentlyUsedCollectionIds, suggestedCollectionIds } =
    state.contentApp.newContentItem.formConfig.collection;

  const availableCollections = getAvailableCollections(origin, state, languageId);

  const collectionIds =
    suggestedCollectionIds.length > 0 ? suggestedCollectionIds : recentlyUsedCollectionIds ?? [];
  const collections = availableCollections.filter((collection) =>
    collectionIds.includes(collection.id),
  );
  const selectorState = getCollectionSelectorState(collections);

  return createCollectionSelectorOptions(collections, availableCollections, selectorState);
};
