import { ThunkFunction, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { defaultAssetTypeId } from '../../../../../data/constants/defaultAssetType.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IAssetTypeRepository } from '../../../../../repositories/interfaces/IAssetTypeRepository.type.ts';
import { initTypeEditorStarted } from '../../../shared/actions/sharedContentModelsActions.ts';
import { ContentModels_TypeEditor_AssetTypeLoaded } from '../../../shared/constants/sharedContentModelsActionTypes.ts';
import { IBaseTypeElementData } from '../../../shared/models/elements/types/TypeElementData.ts';
import { AssetType_Editor_InitFinished } from '../../constants/assetTypeActionTypes.ts';
import { getAssetTypeFromServerModel } from '../../utils/assetTypeServerModelConvertors.ts';

interface IDeps {
  readonly assetTypeRepository: Pick<IAssetTypeRepository, 'getAssetType'>;
  readonly loadTaxonomyGroups: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly validateTypeElement: (updatedTypeElement: IBaseTypeElementData) => ThunkFunction;
}

const initAssetTypeEditorFinished = () =>
  ({
    type: AssetType_Editor_InitFinished,
  }) as const;

const assetTypeEditorLoaded = (editedContentType: IContentType) =>
  ({
    type: ContentModels_TypeEditor_AssetTypeLoaded,
    payload: {
      editedContentType,
    },
  }) as const;

export type InitAssetTypeEditorActionsType = ReturnType<
  typeof initAssetTypeEditorFinished | typeof assetTypeEditorLoaded
>;

export const createInitAssetTypeEditorAction =
  (deps: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(initTypeEditorStarted());

    const [assetTypeServerModel] = await Promise.all([
      deps.assetTypeRepository.getAssetType(defaultAssetTypeId, abortSignal),
      dispatch(deps.loadTaxonomyGroups(abortSignal)),
    ]);

    const assetType = getAssetTypeFromServerModel(assetTypeServerModel);

    dispatch(assetTypeEditorLoaded(assetType));

    assetType.typeElements.forEach((element) => dispatch(deps.validateTypeElement(element)));

    dispatch(initAssetTypeEditorFinished());
  };
