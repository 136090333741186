import { InvariantException, isXMLHttpRequest } from '@kontent-ai/errors';
import { assert } from '@kontent-ai/utils';
import { Dispatch, GetState, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { showErrorNotification } from '../../../../../_shared/actions/sharedActions.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { logError } from '../../../../../_shared/utils/logError.ts';
import {
  Workflow,
  getServerModelFromWorkflow,
} from '../../../../../data/models/workflow/Workflow.ts';
import { IWorkflowStep } from '../../../../../data/models/workflow/WorkflowStep.ts';
import { getWorkflow } from '../../../../../data/reducers/workflow/selectors/workflowSelectors.ts';
import { IWorkflowRepository } from '../../../../../repositories/interfaces/IWorkflowRepository.type.ts';
import {
  ServerApiErrorCode,
  tryParseApiError,
} from '../../../../../repositories/serverModels/ServerApiError.ts';
import {
  IValidationErrorServerModel,
  IWorkflowServerModel,
  StepsErrorsServerModel,
} from '../../../../../repositories/serverModels/WorkflowServerModel.type.ts';
import { WorkflowStepEditorIsNotConfirmed } from '../../../../contentModels/shared/constants/errorMessageTemplates.ts';
import {
  Workflow_Editing_EmptyTransitionsSaveFailed,
  Workflow_Editing_WorkflowSaveFailed,
  Workflow_Editing_WorkflowSaveFinished,
  Workflow_Editing_WorkflowSaveStarted,
  Workflow_ServerValidation_ReceivedGlobalErrors,
  Workflow_ServerValidation_ReceivedStepErrors,
} from '../../constants/workflowActionTypes.ts';
import {
  WorkflowGlobalValidationError,
  WorkflowStepValidationError,
} from '../../model/WorkflowValidationErrors.ts';
import { getWorkflowStepsWithEmptyTransitionsTo } from '../../utils/getWorkflowStepsWithEmptyTransitionTo.ts';
import { fireChangeWorkflowEvents } from './fireWorkflowIntercomEvents.ts';

interface IDeps {
  readonly workflowRepository: Pick<IWorkflowRepository, 'update' | 'create' | 'validate'>;
  readonly loadWorkflowStepsUsage: (workflowId: Uuid) => ThunkPromise;
  readonly loadWorkflowsUsage: (workflowIds?: ReadonlyArray<Uuid>) => ThunkPromise;
}

interface IParams {
  readonly redirectToWorkflowEditingRoute?: (workflowId: Uuid) => void;
  readonly onSuccess?: () => void;
  readonly onFail?: () => void;
}

const saveWorkflowToServerStarted = () => ({ type: Workflow_Editing_WorkflowSaveStarted }) as const;

const saveWorkflowToServerFinished = (workflow: IWorkflowServerModel) =>
  ({
    type: Workflow_Editing_WorkflowSaveFinished,
    payload: { workflow },
  }) as const;

const saveWorkflowToServerFailed = (errorMessage: string) =>
  ({
    type: Workflow_Editing_WorkflowSaveFailed,
    payload: {
      errorMessage,
    },
  }) as const;

const saveFailedWithEmptyTransitions = (ids: UuidArray) =>
  ({
    type: Workflow_Editing_EmptyTransitionsSaveFailed,
    payload: {
      ids,
    },
  }) as const;

const receivedGlobalServerErrors = (errors: ReadonlySet<string>) =>
  ({
    type: Workflow_ServerValidation_ReceivedGlobalErrors,
    payload: {
      errors,
    },
  }) as const;

const receivedStepServerErrors = (
  errors: ReadonlyMap<Uuid, ReadonlySet<WorkflowStepValidationError>>,
) =>
  ({
    type: Workflow_ServerValidation_ReceivedStepErrors,
    payload: {
      errors,
    },
  }) as const;

type Actions =
  | typeof saveWorkflowToServerStarted
  | typeof saveWorkflowToServerFinished
  | typeof saveWorkflowToServerFailed
  | typeof saveFailedWithEmptyTransitions
  | typeof receivedGlobalServerErrors
  | typeof receivedStepServerErrors;

export type SaveWorkflowToServerActions = ReturnType<Actions>;

const isStepErrors = (errors: string[]): errors is WorkflowStepValidationError[] =>
  errors.every((e) =>
    Object.values(WorkflowStepValidationError).includes(e as WorkflowStepValidationError),
  );

const isGlobalError = (error: string): error is WorkflowGlobalValidationError =>
  Object.values(WorkflowGlobalValidationError).includes(error as WorkflowGlobalValidationError);

const buildGlobalErrorsPayload = (
  globalErrors: readonly IValidationErrorServerModel[],
): Set<string> =>
  globalErrors.reduce((result, { code, message }: IValidationErrorServerModel) => {
    if (!isGlobalError(code)) {
      throw InvariantException(`${__filename}: Server returned unknown global error: '${code}'`);
    }

    return result.add(message);
  }, new Set<string>());

const buildStepErrorsMap = (
  stepsServerErrors: StepsErrorsServerModel,
): Map<Uuid, Set<WorkflowStepValidationError>> => {
  const stepIds = Object.keys(stepsServerErrors);

  return stepIds.reduce((result, stepId: Uuid) => {
    const stepErrors = (stepsServerErrors[stepId] ?? []).map((e) => e.code);
    if (!isStepErrors(stepErrors)) {
      throw InvariantException(
        `${__filename}: Server returned unknown step error in: '${stepErrors}'`,
      );
    }

    return result.set(stepId, new Set(stepErrors));
  }, new Map<Uuid, Set<WorkflowStepValidationError>>());
};

export const createSaveWorkflowToServerAction =
  ({ workflowRepository, loadWorkflowStepsUsage, loadWorkflowsUsage }: IDeps) =>
  ({ onSuccess, onFail, redirectToWorkflowEditingRoute }: IParams): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const state = getState();
    const {
      workflowsApp: {
        editorUi: {
          regularWorkflowSteps,
          regularWorkflowStepsOrder,
          publishedWorkflowStep,
          editedWorkflowStepId,
          editedWorkflowName,
          archivedWorkflowStep,
          editedWorkflowId,
          editedWorkflowCodename,
          editedWorkflowScopesById,
        },
      },
    } = state;

    try {
      // check no editor is opened
      if (editedWorkflowStepId) {
        dispatch(showErrorNotification(WorkflowStepEditorIsNotConfirmed));
        onFail?.();
        return;
      }
      // check no transitionTo property is empty
      const noTransitionSteps = getWorkflowStepsWithEmptyTransitionsTo(regularWorkflowSteps);
      if (noTransitionSteps.length > 0) {
        dispatch(
          saveFailedWithEmptyTransitions(noTransitionSteps.map((step: IWorkflowStep) => step.id)),
        );
        onFail?.();
        return;
      }

      dispatch(saveWorkflowToServerStarted());

      const serverModel = getServerModelFromWorkflow({
        codename: editedWorkflowCodename,
        name: editedWorkflowName,
        regularWorkflowSteps,
        regularWorkflowStepsOrder,
        publishedWorkflowStep,
        archivedWorkflowStep,
        scopes: Array.from(editedWorkflowScopesById.values()),
      });

      const validationResult = await workflowRepository.validate({
        ...serverModel,
        workflowId: editedWorkflowId,
      });
      if (validationResult.isValid) {
        const updatedWorkflowServerModel = editedWorkflowId
          ? await workflowRepository.update(editedWorkflowId, serverModel)
          : await workflowRepository.create(serverModel);

        await dispatch(loadWorkflowStepsUsage(updatedWorkflowServerModel.id));
        await dispatch(loadWorkflowsUsage([updatedWorkflowServerModel.id]));

        const oldSelectedWorkflow = editedWorkflowId
          ? getOldSelectedWorkflow(state, updatedWorkflowServerModel.id)
          : undefined;

        dispatch(saveWorkflowToServerFinished(updatedWorkflowServerModel));
        dispatch(fireChangeWorkflowEvents(updatedWorkflowServerModel, oldSelectedWorkflow));

        onSuccess?.();
        redirectToWorkflowEditingRoute?.(updatedWorkflowServerModel.id);
      } else {
        dispatch(receivedStepServerErrors(buildStepErrorsMap(validationResult.stepErrors)));
        dispatch(
          receivedGlobalServerErrors(buildGlobalErrorsPayload(validationResult.globalErrors)),
        );

        onFail?.();
      }
    } catch (e) {
      if (
        tryParseApiError(e)?.code === ServerApiErrorCode.WorkflowConfigurationIsNotValid &&
        isXMLHttpRequest(e)
      ) {
        dispatch(saveWorkflowToServerFailed(JSON.parse(e.response).description));
      } else {
        dispatch(saveWorkflowToServerFailed(''));
      }

      logError(e);
      onFail?.();
    }
  };

const getOldSelectedWorkflow = (state: IStore, workflowId: Uuid): Workflow | undefined => {
  const workflow = getWorkflow(state, workflowId);

  assert(workflow, () => `${__filename}: Did not find workflow with id ${workflowId}.`);

  return workflow;
};
