import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { SubscriptionUserListingColumnCode } from '../../../applications/subscriptionManagement/SubscriptionUsers/reducers/subscriptionUsersListingUi/sortBy.ts';
import { IconName } from '../../constants/iconEnumGenerated.ts';
import { ItemColumnCode } from '../../constants/itemColumnCode.ts';
import { OrderBy, OrderByDirection } from '../../models/OrderBy.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
  getDataUiObjectNameAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { Icon } from '../Icon/Icon.tsx';
import {
  ScrollTableColumnSize,
  translateColumnSizeToClassSuffix,
} from './types/scrollTableTypes.ts';

type ColumnCode = ItemColumnCode | SubscriptionUserListingColumnCode;
interface IScrollTableHeadColumnPropsClickable {
  readonly code: ColumnCode;
  readonly currentOrdering: OrderBy<ColumnCode> | null;
  readonly dataUiElement?: DataUiElement;
  readonly isGrowing?: boolean;
  readonly name: string;
  readonly onColumnHeadClicked: (columnCode: ColumnCode) => void;
  readonly size: ScrollTableColumnSize;
}

const getOrderingIcon = (
  orderedColumnCode: ColumnCode | undefined,
  columnCode: ColumnCode,
  order: OrderByDirection | undefined,
) => {
  if (
    orderedColumnCode !== columnCode ||
    (orderedColumnCode === columnCode && order === OrderByDirection.Ascending)
  ) {
    return <Icon className="scroll-table__ordering-mark-icon" iconName={IconName.CaretUp} />;
  }
  if (orderedColumnCode === columnCode && order === OrderByDirection.Descending) {
    return <Icon className="scroll-table__ordering-mark-icon" iconName={IconName.CaretDown} />;
  }

  return null;
};

export const ScrollTableHeadColumnClickable: React.FC<IScrollTableHeadColumnPropsClickable> = ({
  code,
  currentOrdering,
  dataUiElement,
  isGrowing,
  name,
  onColumnHeadClicked,
  size,
}) => {
  const currentColumnCode = currentOrdering?.columnCode;
  const currentDirection = currentOrdering?.direction;
  const classSizeSuffix = translateColumnSizeToClassSuffix(size);

  return (
    <div
      onClick={() => onColumnHeadClicked(code)}
      className={classNames(
        'scroll-table__column-head',
        'scroll-table__column-head--is-clickable',
        {
          [`scroll-table__column--growing-${classSizeSuffix}`]: isGrowing,
          [`scroll-table__column--${classSizeSuffix}`]: !isGrowing,
          'scroll-table__column-head--is-selected': currentColumnCode === code,
        },
      )}
      {...(dataUiElement
        ? getDataUiElementAttribute(dataUiElement)
        : getDataUiObjectNameAttribute(name))}
    >
      <div className="scroll-table__column-name">
        {name}
        <div
          className={classNames('scroll-table__ordering-mark', {
            'scroll-table__ordering-mark--hidden': currentColumnCode !== code,
          })}
        >
          {getOrderingIcon(currentColumnCode, code, currentDirection)}
        </div>
      </div>
    </div>
  );
};

ScrollTableHeadColumnClickable.displayName = 'ScrollTableHeadColumnClickable';
const propTypes: PropTypesShape<IScrollTableHeadColumnPropsClickable> = {
  code: PropTypes.string.isRequired,
  currentOrdering: PropTypes.shape({
    columnCode: PropTypes.string.isRequired,
    direction: PropTypes.string.isRequired,
  }),
  dataUiElement: PropTypes.string,
  isGrowing: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onColumnHeadClicked: PropTypes.func.isRequired,
  size: PropTypes.number.isRequired,
};
ScrollTableHeadColumnClickable.propTypes = propTypes;
