import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Callout } from '@kontent-ai/component-library/Callout';
import { Card } from '@kontent-ai/component-library/Card';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { isAssetReplaceFeatureAvailable } from '../../../../contentInventory/assets/selectors/isAssetReplaceFeatureAvailable.ts';
import { useFeatureActivationDialog } from '../../hooks/useFeatureActivationDialog.ts';
import { useGetFeatureActivationState } from '../../hooks/useGetFeatureActivationState.ts';
import { activateInnovationLabFeature } from '../../thunks/activateInnovationLabFeature.ts';
import { deactivateInnovationLabFeature } from '../../thunks/deactivateInnovationLabFeature.ts';
import { InnovationLabCardComponent } from '../../types/InnovationLabCardComponent.type.ts';
import { FeatureActivationDialog } from '../FeatureActivationDialog.tsx';
import { FeatureDeactivationDialog } from '../FeatureDeactivationDialog.tsx';
import { InnovationLabFeatureButton } from '../InnovationLabFeatureButton.tsx';
import { InnovationLabFeatureCard } from '../InnovationLabFeatureCard.tsx';
import { InnovationLabFeatureGenericallyUnavailable } from '../InnovationLabFeatureGenericallyUnavailable.tsx';
import { InnovationLabStatus } from '../InnovationLabFeatureStatus.tsx';
import { InnovationLabGenericConsent } from '../InnovationLabGenericConsent.tsx';
import { InnovationLabGenericDeactivationWarning } from '../InnovationLabGenericDeactivationWarning.tsx';

export const AssetReplacementCard: InnovationLabCardComponent = ({ featureInfo }) => {
  const activationState = useGetFeatureActivationState(
    AssetReplacementCard.isFeatureEnabledSelector,
  );

  const {
    dialogDisplayed,
    isAsyncOperationInProgress,
    onCardActionButtonClick,
    onDialogActionClick,
    onDialogClose,
  } = useFeatureActivationDialog(
    featureInfo,
    activationState,
    activateInnovationLabFeature('asset-replace'),
    deactivateInnovationLabFeature('asset-replace'),
  );

  const featureState = featureInfo.status;

  return (
    <>
      <InnovationLabFeatureCard
        cardLabel={AssetReplacementCard.featureName}
        component="section"
        featureState={featureState}
      >
        <Card.Headline
          renderAuxiliaryElements={() => <InnovationLabStatus activationState={activationState} />}
        >
          {AssetReplacementCard.featureName}
        </Card.Headline>

        <Card.Body>
          <Stack spacing={Spacing.M}>
            <p>
              Effortlessly replace{' '}
              <OutwardLink href="https://kontent.ai/learn/docs/assets/asset-library#a-fast-asset-replacement">
                outdated assets
              </OutwardLink>{' '}
              with their new versions
            </p>
            <p>
              This feature eliminates the need to create a new asset and manually update each
              occurrence of the old one.
            </p>
            <InnovationLabFeatureGenericallyUnavailable
              activationState={activationState}
              featureState={featureState}
            />
          </Stack>
        </Card.Body>
        <Card.Footer>
          <InnovationLabFeatureButton
            activationState={activationState}
            featureName="asset replacement"
            featureState={featureState}
            isAsyncOperationInProgress={isAsyncOperationInProgress}
            onClick={onCardActionButtonClick}
          />
        </Card.Footer>
      </InnovationLabFeatureCard>
      <FeatureActivationDialog
        headline="Activate fast asset replacement"
        isOpen={dialogDisplayed === 'activation'}
        isActivating={isAsyncOperationInProgress}
        onClose={onDialogClose}
        onActivate={onDialogActionClick}
      >
        <Stack spacing={Spacing.M}>
          <Callout calloutType="quickTip" hideSubheadline>
            <p>
              <b>Make sure you understand the effects of using the fast asset replacement:</b>
            </p>
            <ul>
              <li>
                Replacing an asset affects all content items using it, including your published
                content.
              </li>
              <li>
                The asset URL changes upon replacement. This affects any direct links to the asset.
              </li>
              <li>
                The replacement ignores content type limitations for the file type, file size, and
                image resolution.
              </li>
              <li>
                The replacement doesn’t change the current asset renditions. This can make some
                crops nonsensical. You may need to check them manually afterward.
              </li>
              <li>The replacement is immediate and cannot be undone.</li>
            </ul>
          </Callout>
          <InnovationLabGenericConsent />
        </Stack>
      </FeatureActivationDialog>
      <FeatureDeactivationDialog
        headline="Deactivate fast asset replacement"
        isOpen={dialogDisplayed === 'deactivation'}
        isDeactivating={isAsyncOperationInProgress}
        onClose={onDialogClose}
        onDeactivate={onDialogActionClick}
      >
        <InnovationLabGenericDeactivationWarning featureName="fast asset replacement" />
      </FeatureDeactivationDialog>
    </>
  );
};

AssetReplacementCard.isFeatureEnabledSelector = isAssetReplaceFeatureAvailable;
AssetReplacementCard.featureName = 'Fast asset replacement';
AssetReplacementCard.releaseDate = '2021-10-30T00:00:00.000Z';
