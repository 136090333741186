import React, { memo } from 'react';
import { DataUiElement } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { AiActionName } from '../../../../repositories/serverModels/ai/AiActionName.type.ts';
import {
  createEditorWithPlugins,
  getBaseEditor,
  usePluginWithParams,
} from '../../editorCore/Editor.composition.tsx';
import { EditorProps } from '../../editorCore/types/Editor.composition.type.ts';
import { useModals } from '../../plugins/ModalsPlugin.tsx';
import { useTranslateContentPlugin } from '../../plugins/ai/actions/TranslateContent/TranslateContentPlugin.tsx';
import { useAiPlugins } from '../../plugins/ai/useAiPlugins.ts';
import { useApiLimitations } from '../../plugins/apiLimitations/ApiLimitationsPlugin.tsx';
import { EditorFeatureLimitations } from '../../plugins/apiLimitations/api/EditorFeatureLimitations.ts';
import { TopLevelBlockCategoryFeature } from '../../plugins/apiLimitations/api/editorLimitationUtils.ts';
import { useApiStatistics } from '../../plugins/apiStatistics/ApiStatisticsPlugin.tsx';
import { IEditorStatistics } from '../../plugins/apiStatistics/api/editorStatistics.ts';
import { useEditAsset } from '../../plugins/assets/EditAssetPlugin.tsx';
import { useCompositionModeAdjustments } from '../../plugins/behavior/CompositionModeAdjustmentsPlugin.tsx';
import { useCustomCaretHandling } from '../../plugins/behavior/CustomCaretHandlingPlugin.tsx';
import { useFocus } from '../../plugins/behavior/FocusPlugin.tsx';
import { useOnChange } from '../../plugins/behavior/OnChangePlugin.tsx';
import { useSpellCheck } from '../../plugins/behavior/SpellCheckPlugin.tsx';
import { useClearFormatting } from '../../plugins/clearFormatting/ClearFormattingPlugin.tsx';
import { useClipboard } from '../../plugins/clipboard/ClipboardPlugin.tsx';
import { useComments } from '../../plugins/comments/CommentsPlugin.tsx';
import { useContentComponents } from '../../plugins/contentComponents/ContentComponentsPlugin.tsx';
import { useCustomBlocks } from '../../plugins/customBlocks/CustomBlocksPlugin.tsx';
import {
  EnterKeyBehavior,
  useCustomInputHandling,
} from '../../plugins/customInputHandling/CustomInputHandlingPlugin.tsx';
import { useDragDrop } from '../../plugins/dragDrop/DragDropPlugin.tsx';
import { useDropFiles } from '../../plugins/dropFiles/DropFilesPlugin.tsx';
import { useEntityApi } from '../../plugins/entityApi/EntityApiPlugin.tsx';
import { useImages } from '../../plugins/images/ImagesPlugin.tsx';
import { useInlineAiPlugins } from '../../plugins/inlineAi/useInlineAiPlugins.ts';
import { useInlineStyles } from '../../plugins/inlineStyles/InlineStylesPlugin.tsx';
import { useKeyboardShortcuts } from '../../plugins/keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { richTextKeyCommandMap } from '../../plugins/keyboardShortcuts/api/editorKeyboardUtils.ts';
import { useLinkedItems } from '../../plugins/linkedItems/LinkedItemsPlugin.tsx';
import { useLinks } from '../../plugins/links/LinksPlugin.tsx';
import { useAssetLinks } from '../../plugins/links/asset/AssetLinksPlugin.tsx';
import { useContentLinks } from '../../plugins/links/content/ContentLinksPlugin.tsx';
import { useEmailLinks } from '../../plugins/links/email/EmailLinksPlugin.tsx';
import { usePhoneLinks } from '../../plugins/links/phone/PhoneLinksPlugin.tsx';
import { useAutomaticWebLinkConversion } from '../../plugins/links/web/AutomaticWebLinkConversionPlugin.tsx';
import { useWebLinks } from '../../plugins/links/web/WebLinksPlugin.tsx';
import { useSmartLink } from '../../plugins/smartLink/SmartLinkPlugin.tsx';
import { useTables } from '../../plugins/tables/TablesPlugin.tsx';
import { useTextApi } from '../../plugins/textApi/TextApiPlugin.tsx';
import {
  textBlockTypesButtonCount,
  useTextBlockTypes,
} from '../../plugins/textBlockTypes/TextBlockTypesPlugin.tsx';
import { useBlockToolbar } from '../../plugins/toolbars/BlockToolbarPlugin.tsx';
import { useInlineToolbar } from '../../plugins/toolbars/InlineToolbarPlugin.tsx';
import { useUndoRedo } from '../../plugins/undoRedo/UndoRedoPlugin.tsx';
import { useUploadFiles } from '../../plugins/uploadFiles/UploadFilesPlugin.tsx';
import { useLimitationStyles } from '../../plugins/visuals/LimitationStylesPlugin.tsx';
import { usePlaceholder } from '../../plugins/visuals/PlaceholderPlugin.tsx';
import { useStyles } from '../../plugins/visuals/StylesPlugin.tsx';
import { useWrapper } from '../../plugins/visuals/WrapperPlugin.tsx';
import { useRichTextInputStatus } from './plugins/RichTextInputStatusPlugin.tsx';

export const useComposedEditor = (
  limitations: EditorFeatureLimitations,
  statistics: IEditorStatistics | null,
) => {
  const isTextAllowed = limitations.allowedBlocks.has(TopLevelBlockCategoryFeature.Text);
  const placeholder = isTextAllowed ? 'Type your text here…' : 'Add your content here…';

  return createEditorWithPlugins(
    getBaseEditor(),
    usePluginWithParams(usePlaceholder, placeholder),
    useWrapper,
    useCompositionModeAdjustments,
    useFocus,
    usePluginWithParams(useApiLimitations, limitations),
    usePluginWithParams(useApiStatistics, statistics),
    usePluginWithParams(useKeyboardShortcuts, richTextKeyCommandMap),
    useTextApi,
    useClipboard,
    useUndoRedo,
    useStyles,
    useLimitationStyles,
    useModals,
    usePluginWithParams(useInlineToolbar, textBlockTypesButtonCount),
    useBlockToolbar,
    useOnChange,
    useDragDrop,
    usePluginWithParams(useCustomInputHandling, EnterKeyBehavior.SoftNewLineWithShift),
    useTextBlockTypes,
    useInlineStyles,
    useEntityApi,
    useLinks,
    useClearFormatting,
    useComments,
    useCustomBlocks,
    useEditAsset,
    useImages,
    useAutomaticWebLinkConversion,
    useWebLinks,
    useContentLinks,
    useAssetLinks,
    useEmailLinks,
    usePhoneLinks,
    useUploadFiles,
    useDropFiles,
    useTables,
    useLinkedItems,
    useContentComponents,
    useSmartLink,
    useSpellCheck,
    useCustomCaretHandling,
    useRichTextInputStatus,
    useTranslateContentPlugin,
    ...useAiPlugins(),
    ...useInlineAiPlugins(AiActionName.RichTextInlineInstruction),
  );
};

export type RichTextInputProps = EditorProps<typeof useComposedEditor> & {
  readonly statistics?: IEditorStatistics;
};

export const RichTextInput: React.FC<RichTextInputProps> = memo(
  ({ statistics, ...editorProps }) => {
    const { ComposedEditor } = useComposedEditor(editorProps.limitations, statistics ?? null);

    return (
      <ComposedEditor
        dataUiElement={editorProps.dataUiElement ?? DataUiElement.RichTextEditor}
        tabIndex={editorProps.tabIndex ?? -1}
        {...editorProps}
      />
    );
  },
);

RichTextInput.displayName = 'RichTextInput';
