import React from 'react';
import { useParams } from 'react-router';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { openInventoryWithFilter } from '../actions/thunkSmartLinkActions.ts';
import { SmartLink } from '../components/SmartLink.tsx';
import { FilterRouteParams } from '../constants/routePaths.ts';

export const FilterSmartLink: React.FC = () => {
  const dispatch = useDispatch();

  const { projectId, filterId } = useParams<FilterRouteParams>();

  const onMount = () =>
    dispatch(
      openInventoryWithFilter({
        projectId,
        filterId,
      }),
    );

  return <SmartLink onMount={onMount} />;
};

FilterSmartLink.displayName = 'FilterSmartLink';
