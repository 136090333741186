import React, { useCallback, useMemo } from 'react';
import { useEditorWithPlugin } from '../../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginCreator } from '../../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../../editorCore/types/Editor.contract.type.ts';
import { Apply, EditorPlugin } from '../../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../../editorCore/utils/decorable.ts';
import { withDisplayName } from '../../../editorCore/utils/withDisplayName.ts';
import { EntityDecoratorProps } from '../../entityApi/api/editorEntityUtils.ts';
import { DisplayLinksPlugin, GetLinkEntityComponent } from '../DisplayLinksPlugin.tsx';
import { isPhoneLink } from '../api/LinkEntity.ts';
import { LinkType } from '../api/LinkType.ts';
import { PhoneLink } from './components/PhoneLink.tsx';

export type DisplayPhoneLinksPlugin = EditorPlugin<None, None, None, [DisplayLinksPlugin]>;

type PhoneLinkEntityProps = EntityDecoratorProps & {
  readonly children: ReadonlyArray<React.ReactNode>;
};

const PhoneLinkEntity: React.FC<PhoneLinkEntityProps> = ({
  children,
  contentState,
  decoratedText,
  entityKey,
}) => {
  const entity = contentState.getEntity(entityKey);
  if (!isPhoneLink(entity)) {
    return children;
  }

  const { phoneNumber } = entity.getData();

  return (
    <PhoneLink disabled key={entityKey} phoneNumber={phoneNumber} text={decoratedText}>
      {children}
    </PhoneLink>
  );
};

PhoneLinkEntity.displayName = 'PhoneLinkEntity';

export const useDisplayPhoneLinks: PluginCreator<DisplayPhoneLinksPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('DisplayPhoneLinksPlugin', {
        ComposedEditor: (props) => {
          const apply: Apply<DisplayPhoneLinksPlugin> = useCallback((state) => {
            const getLinkEntityComponent: Decorator<GetLinkEntityComponent<None>> =
              (baseGetLinkEntityComponent) => (linkType) => {
                switch (linkType) {
                  case LinkType.Phone:
                    return {
                      component: PhoneLinkEntity,
                      props: {},
                    };

                  default:
                    return baseGetLinkEntityComponent(linkType);
                }
              };

            state.getLinkEntityComponent.decorate(getLinkEntityComponent);

            return {};
          }, []);

          return useEditorWithPlugin(baseEditor, props, { apply });
        },
      }),
    [baseEditor],
  );
