import Immutable from 'immutable';
import React, { useCallback } from 'react';
import { IProjectListingUser } from '../../../applications/projects/models/ProjectListingUser.ts';
import { loadSubscriptionUsers } from '../../../applications/subscriptionManagement/shared/actions/thunkSubscriptionManagementActions.ts';
import { IProjectDetails } from '../../../data/models/projects/ProjectDetails.ts';
import { AvailableSubscriptionAdmins } from '../../components/infos/AvailableSubscriptionAdmins.tsx';
import { useDispatch } from '../../hooks/useDispatch.ts';
import { useSelector } from '../../hooks/useSelector.ts';

const MAX_ADMINS_IN_DIALOG = 10;

interface IAvailableSubscriptionAdminsWithoutCurrentProjectContainerProps {
  readonly onClose: () => void;
  readonly subscriptionId: Uuid;
}

export const AvailableSubscriptionAdminsWithoutCurrentProject: React.FC<
  IAvailableSubscriptionAdminsWithoutCurrentProjectContainerProps
> = ({ onClose, subscriptionId }) => {
  const projectDetail = useSelector(
    (state) =>
      state.data.projects.byId
        .groupBy((project: IProjectDetails) => project.subscriptionId)
        .get(subscriptionId)
        ?.first() ?? null,
  );

  const admins: Immutable.List<IProjectListingUser> | null = projectDetail
    ? projectDetail.projectManagers
        .filter((manager: IProjectListingUser) =>
          projectDetail.subscriptionAdmins.contains(manager.userId),
        )
        .slice(0, MAX_ADMINS_IN_DIALOG)
        .toList()
    : null;

  const dispatch = useDispatch();
  const loadAdmins = useCallback(
    () => dispatch(loadSubscriptionUsers(subscriptionId)),
    [subscriptionId],
  );

  return (
    <AvailableSubscriptionAdmins
      admins={admins}
      loadAdmins={loadAdmins}
      onClose={onClose}
      subscriptionName={projectDetail?.subscriptionName ?? ''}
    />
  );
};

AvailableSubscriptionAdminsWithoutCurrentProject.displayName =
  'AvailableSubscriptionAdminsWithoutCurrentProject';
