import { createGuid } from '@kontent-ai/utils';
import { WorkflowStepColor } from '../../../../data/constants/WorkflowStepColor.ts';
import {
  IWorkflowStep,
  RegularWorkflowStep,
  emptyWorkflowStep,
} from '../../../../data/models/workflow/WorkflowStep.ts';
import {
  Workflow_ArchivedStepEditor_Save,
  Workflow_Editing_CodenameChanged,
  Workflow_Editing_CollectionsScopeChanged,
  Workflow_Editing_ContentTypesScopeChanged,
  Workflow_Editing_Left,
  Workflow_Editing_NameChanged,
  Workflow_Editing_ScopeCreated,
  Workflow_Editing_ScopeDeleted,
  Workflow_Editing_StepCodenameChanged,
  Workflow_Editing_StepDraggingFinished,
  Workflow_Editing_StepDraggingStarted,
  Workflow_Editing_StepMoved,
  Workflow_Editing_WorkflowStepUnsavedChangesUpdated,
  Workflow_PublishedStepEditor_Save,
  Workflow_StepCreator_Cancel,
  Workflow_StepCreator_Initiated,
  Workflow_StepEditor_Cancel,
  Workflow_StepEditor_Delete,
  Workflow_StepEditor_Initiated,
  Workflow_StepEditor_Save,
  Workflows_ListingInit_Left,
} from '../constants/workflowActionTypes.ts';

const getFreshNewStep = (): RegularWorkflowStep => ({
  ...emptyWorkflowStep,
  id: createGuid(),
  name: '',
  codename: '',
  color: WorkflowStepColor.Gray,
});

export const workflowStepCodenameChanged = (stepId: Uuid, stepCodename: string) =>
  ({
    type: Workflow_Editing_StepCodenameChanged,
    payload: {
      stepId,
      stepCodename,
    },
  }) as const;

export const workflowStepCreatorInitialized = (stepId: Uuid) =>
  ({
    type: Workflow_StepCreator_Initiated,
    payload: {
      precedingStepId: stepId,
      workflowStep: getFreshNewStep(),
    },
  }) as const;

export const workflowStepEditorInitiated = (stepId: Uuid) =>
  ({
    type: Workflow_StepEditor_Initiated,
    payload: { stepId },
  }) as const;

export const workflowStepMoved = (sourceId: Uuid, targetId: Uuid) =>
  ({
    type: Workflow_Editing_StepMoved,
    payload: {
      sourceId,
      targetId,
    },
  }) as const;

export const deleteEditedWorkflowStep = (stepId: Uuid) =>
  ({
    type: Workflow_StepEditor_Delete,
    payload: { stepId },
  }) as const;

export const cancelWorkflowStepEditor = () => ({ type: Workflow_StepEditor_Cancel }) as const;

export const cancelWorkflowStepCreator = (stepId: Uuid) =>
  ({
    type: Workflow_StepCreator_Cancel,
    payload: {
      stepId,
    },
  }) as const;

export type SaveWorkflowStepEditorActionPayloadType = {
  readonly stepId: Uuid;
  readonly name: string;
  readonly color: WorkflowStepColor;
  readonly transitionsTo: ReadonlySet<IWorkflowStep>;
  readonly transitionsFrom: ReadonlySet<IWorkflowStep>;
  readonly roleIds: ReadonlySet<Uuid>;
};

export const saveWorkflowStepEditor = (parameters: SaveWorkflowStepEditorActionPayloadType) =>
  ({
    type: Workflow_StepEditor_Save,
    payload: parameters,
  }) as const;

export type SavePublishedWorkflowStepEditorActionPayloadType = {
  readonly stepId: Uuid;
  readonly transitionsFrom: ReadonlySet<IWorkflowStep>;
  readonly roleIdsForUnpublish: ReadonlySet<Uuid>;
  readonly roleIdsForCreateNew: ReadonlySet<Uuid>;
};

export type SaveArchivedWorkflowStepEditorActionPayloadType = {
  readonly stepId: Uuid;
  readonly transitionsFrom: ReadonlySet<IWorkflowStep>;
  readonly roleIds: ReadonlySet<Uuid>;
};

export const savePublishedWorkflowStepEditor = (
  parameters: SavePublishedWorkflowStepEditorActionPayloadType,
) =>
  ({
    type: Workflow_PublishedStepEditor_Save,
    payload: parameters,
  }) as const;

export const saveArchivedWorkflowStepEditor = (
  parameters: SaveArchivedWorkflowStepEditorActionPayloadType,
) =>
  ({
    type: Workflow_ArchivedStepEditor_Save,
    payload: parameters,
  }) as const;

export const startWorkflowStepDragging = (workflowStepId: Uuid) =>
  ({
    type: Workflow_Editing_StepDraggingStarted,
    payload: { workflowStepId },
  }) as const;

export const finishWorkflowStepDragging = () =>
  ({
    type: Workflow_Editing_StepDraggingFinished,
  }) as const;

export const leaveWorkflowEditor = () =>
  ({
    type: Workflow_Editing_Left,
  }) as const;

export const leaveWorkflowsListing = () =>
  ({
    type: Workflows_ListingInit_Left,
  }) as const;

export const workflowNameChanged = (name: string) =>
  ({
    type: Workflow_Editing_NameChanged,
    payload: {
      name,
    },
  }) as const;

export const workflowCodenameChanged = (codename: string) =>
  ({
    type: Workflow_Editing_CodenameChanged,
    payload: {
      codename,
    },
  }) as const;

export const workflowContentTypesScopeChanged = (scopeId: Uuid, contentTypes: ReadonlySet<Uuid>) =>
  ({
    type: Workflow_Editing_ContentTypesScopeChanged,
    payload: {
      scopeId,
      contentTypes,
    },
  }) as const;

export const workflowCollectionsScopeChanged = (scopeId: Uuid, collections: ReadonlySet<Uuid>) =>
  ({
    type: Workflow_Editing_CollectionsScopeChanged,
    payload: {
      scopeId,
      collections,
    },
  }) as const;

export const createWorkflowScope = () =>
  ({
    type: Workflow_Editing_ScopeCreated,
  }) as const;

export const deleteWorkflowScope = (scopeId: Uuid) =>
  ({
    type: Workflow_Editing_ScopeDeleted,
    payload: {
      scopeId,
    },
  }) as const;

export const editedWorkflowStepUnsavedChangesUpdated = (hasUnsavedChanges: boolean) =>
  ({
    type: Workflow_Editing_WorkflowStepUnsavedChangesUpdated,
    payload: {
      hasUnsavedChanges,
    },
  }) as const;
