import { Avatar, AvatarSize } from '@kontent-ai/component-library/Avatar';
import { IconButton } from '@kontent-ai/component-library/Button';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { DarkGradient } from '@kontent-ai/component-library/tokens';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, ButtonSize } from '../../../../../../../_shared/uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import { OptionalTooltip } from '../../../../../../../_shared/uiComponents/Tooltip/OptionalTooltip.tsx';
import {
  DataUiCommentsAction,
  getDataUiCommentActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  getUserFriendlyDateString,
  toLocalTime,
} from '../../../../../../../_shared/utils/dateTime/timeUtils.ts';
import {
  formatUserName,
  getExistingUserNewColorGradient,
  getUserInitials,
} from '../../../../../../../_shared/utils/usersUtils.ts';
import { IProjectContributor } from '../../../../../../../data/models/users/ProjectContributor.ts';
import { ICommentThreadItem } from '../../../../../models/comments/CommentThreadItem.ts';
import { ICommentThread } from '../../../../../models/comments/CommentThreads.ts';
import { isSuggestion } from '../../../../../models/comments/Suggestion.ts';
import { CommentContextMenu } from '../../../containers/comments/CommentContextMenu.tsx';

interface IApproveSuggestionProps {
  readonly approveInfo: string;
  readonly isLastApprovedSuggestion?: boolean;
  readonly onApproveSuggestion?: () => void;
}

const approveSuggestionPropTypes: PropTypesShape<IApproveSuggestionProps> = {
  approveInfo: PropTypes.string,
  isLastApprovedSuggestion: PropTypes.bool,
  onApproveSuggestion: PropTypes.func,
};

const ApproveSuggestion: React.FC<IApproveSuggestionProps> = (props) => {
  if (props.isLastApprovedSuggestion) {
    return null;
  }

  const approveSuggestion = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    props.onApproveSuggestion?.();
  };

  return (
    <IconButton
      buttonState={props.onApproveSuggestion ? 'default' : 'disabled'}
      buttonStyle="secondary"
      iconName="Check"
      onClick={approveSuggestion}
      size="small"
      tooltipPlacement="left"
      tooltipText={props.approveInfo}
      {...getDataUiCommentActionAttribute(DataUiCommentsAction.Approve)}
    />
  );
};

ApproveSuggestion.displayName = 'ApproveSuggestion';
ApproveSuggestion.propTypes = approveSuggestionPropTypes;

export interface ICommentThreadItemHeaderProps {
  readonly allowEdit: boolean;
  readonly allowResolve: boolean;
  readonly allowUnresolve: boolean;
  readonly approveInfo: string;
  readonly commentThread: ICommentThread;
  readonly commentThreadItem: ICommentThreadItem;
  readonly createdBy: IProjectContributor | null;
  readonly isLastApprovedSuggestion?: boolean;
  readonly onApproveSuggestion?: () => void;
  readonly onResolve: () => void;
}

const commentThreadItemHeaderPropTypes: PropTypesShape<ICommentThreadItemHeaderProps> = {
  allowEdit: PropTypes.bool.isRequired,
  allowResolve: PropTypes.bool.isRequired,
  allowUnresolve: PropTypes.bool.isRequired,
  approveInfo: PropTypes.string.isRequired,
  commentThread: PropTypes.object.isRequired,
  commentThreadItem: PropTypes.object.isRequired,
  createdBy: PropTypes.object,
  isLastApprovedSuggestion: PropTypes.bool,
  onApproveSuggestion: PropTypes.func,
  onResolve: PropTypes.func.isRequired,
};

export const TipsFromKontentId = 'tips-from-kentico';
const TipsFromKontentUserName = 'Tips from Kontent.ai';
const TipsFromKontentInitials = 'TK';

export const CommentThreadItemHeader: React.FC<ICommentThreadItemHeaderProps> = (props) => {
  const { allowEdit, allowResolve, allowUnresolve, commentThreadItem, createdBy } = props;

  const itemIsSuggestion = isSuggestion(commentThreadItem);

  const onResolve = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    props.onResolve();
  };

  const isExistingInactiveUser = () => createdBy?.inactive && !createdBy.isGdprForgotten;

  const getUserName = () => {
    if (commentThreadItem.createdBy === TipsFromKontentId) {
      return TipsFromKontentUserName;
    }

    return isExistingInactiveUser()
      ? `${formatUserName(createdBy)} (Inactive)`
      : formatUserName(createdBy);
  };

  const getInitialsOfUser = () => {
    if (commentThreadItem.createdBy === TipsFromKontentId) {
      return TipsFromKontentInitials;
    }

    return getUserInitials(createdBy);
  };

  const getUserColor = () => {
    if (commentThreadItem.createdBy === TipsFromKontentId) {
      return DarkGradient.OrangeDark;
    }

    if (!createdBy || createdBy.isGdprForgotten) {
      return DarkGradient.GrayDark;
    }

    return getExistingUserNewColorGradient(createdBy);
  };

  const userName = getUserName();

  const threadCreatedAt = toLocalTime(commentThreadItem.createdAt);
  const userFriendlyDate = threadCreatedAt
    ? getUserFriendlyDateString(threadCreatedAt, new Date())
    : '';
  const threadUpdatedAt = toLocalTime(commentThreadItem.updatedAt);
  const userFriendlyEditDate = threadUpdatedAt
    ? getUserFriendlyDateString(threadUpdatedAt, new Date())
    : undefined;
  const wasEdited = !!commentThreadItem.updatedAt;

  return (
    <div className="comment__header">
      <Avatar
        backgroundGradient={getUserColor()}
        label={formatUserName(createdBy)}
        initials={getInitialsOfUser()}
        size={AvatarSize.S}
      />
      <div className="comment__author-timestamp">
        <OptionalTooltip text={userName} placement="top" className="comment__author">
          {userName}
        </OptionalTooltip>
        {!wasEdited && (
          <OptionalTooltip text={userFriendlyDate} className="comment__info" placement="top">
            {userFriendlyDate}
          </OptionalTooltip>
        )}
        {wasEdited && (
          <Tooltip
            tooltipText={`${userFriendlyDate} (Edited: ${userFriendlyEditDate})`}
            placement="top"
          >
            <div className="comment__info">{`${userFriendlyDate} (Edited)`}</div>
          </Tooltip>
        )}
      </div>
      <div className="comment__actions">
        {itemIsSuggestion && (
          <ApproveSuggestion
            approveInfo={props.approveInfo}
            onApproveSuggestion={props.onApproveSuggestion}
            isLastApprovedSuggestion={props.isLastApprovedSuggestion}
          />
        )}
        {allowResolve && !itemIsSuggestion && (
          <Button
            style={ButtonStyle.Secondary}
            onClick={onResolve}
            size={ButtonSize.S}
            {...getDataUiCommentActionAttribute(DataUiCommentsAction.MarkAsResolved)}
          >
            Resolve
          </Button>
        )}
        <CommentContextMenu
          allowEdit={allowEdit}
          allowResolve={allowResolve && itemIsSuggestion}
          allowUnresolve={allowUnresolve}
          commentId={props.commentThreadItem.id}
          threadId={props.commentThread.id}
        />
      </div>
    </div>
  );
};

CommentThreadItemHeader.displayName = 'CommentThreadItemHeader';
CommentThreadItemHeader.propTypes = commentThreadItemHeaderPropTypes;
