import PropTypes from 'prop-types';
import React, { MouseEventHandler } from 'react';
import { IconName } from '../../constants/iconEnumGenerated.ts';
import { Icon } from '../Icon/Icon.tsx';
import { DropDownOptionName } from './DropDownOptionName.tsx';

interface IDropDownOptionBadgeProps {
  readonly name: string;
  readonly onClick: MouseEventHandler<HTMLElement>;
}

export const DropDownCaptionOption: React.FC<IDropDownOptionBadgeProps> = ({ name, onClick }) => (
  <div className="dropdown-option" onClick={onClick}>
    <div className="dropdown-option__pane dropdown-option__pane--caption-option">
      <span className="dropdown-option__icon dropdown-option__icon--left">
        <Icon iconName={IconName.ChevronLeft} />
      </span>
      <DropDownOptionName text={name} />
    </div>
  </div>
);

const propTypes: PropTypesShape<IDropDownOptionBadgeProps> = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

DropDownCaptionOption.displayName = 'DropDownCaptionOption';
DropDownCaptionOption.propTypes = propTypes;
