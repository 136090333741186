import React, { memo } from 'react';
import { DataUiInput } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  createEditorWithPlugins,
  getBaseEditor,
  usePluginWithParams,
} from '../../editorCore/Editor.composition.tsx';
import { EditorProps } from '../../editorCore/types/Editor.composition.type.ts';
import { useApiLimitations } from '../../plugins/apiLimitations/ApiLimitationsPlugin.tsx';
import { SimpleTextEditorLimitations } from '../../plugins/apiLimitations/api/EditorFeatureLimitations.ts';
import { useCompositionModeAdjustments } from '../../plugins/behavior/CompositionModeAdjustmentsPlugin.tsx';
import { AutoFocusBehavior, useFocus } from '../../plugins/behavior/FocusPlugin.tsx';
import { useForceSelectionOnBlur } from '../../plugins/behavior/ForceSelectionOnBlurPlugin.tsx';
import { useForceSingleUnstyledBlock } from '../../plugins/behavior/ForceSingleUnstyledBlockPlugin.tsx';
import { useManualReset } from '../../plugins/behavior/ManualResetPlugin.tsx';
import { useOnChange } from '../../plugins/behavior/OnChangePlugin.tsx';
import { useSpellCheck } from '../../plugins/behavior/SpellCheckPlugin.tsx';
import { useClipboard } from '../../plugins/clipboard/ClipboardPlugin.tsx';
import {
  EnterKeyBehavior,
  useCustomInputHandling,
} from '../../plugins/customInputHandling/CustomInputHandlingPlugin.tsx';
import { useEntityApi } from '../../plugins/entityApi/EntityApiPlugin.tsx';
import { useIgnoreCtrlEnter } from '../../plugins/keyboardShortcuts/IgnoreCtrlEnterPlugin.tsx';
import { useKeyboardShortcuts } from '../../plugins/keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { textKeyCommandMap } from '../../plugins/keyboardShortcuts/api/editorKeyboardUtils.ts';
import { useAutomaticWebLinkConversion } from '../../plugins/links/web/AutomaticWebLinkConversionPlugin.tsx';
import { useSimpleWebLinks } from '../../plugins/links/web/SimpleWebLinksPlugin.tsx';
import { useMentions } from '../../plugins/mentions/MentionsPlugin.tsx';
import { useUndoRedo } from '../../plugins/undoRedo/UndoRedoPlugin.tsx';
import { usePlaceholder } from '../../plugins/visuals/PlaceholderPlugin.tsx';
import { useStyles } from '../../plugins/visuals/StylesPlugin.tsx';
import { useWrapper } from '../../plugins/visuals/WrapperPlugin.tsx';
import { useCommentInputStyles } from './plugins/CommentInputStylesPlugin.tsx';

const useComposedEditor = () =>
  createEditorWithPlugins(
    getBaseEditor(),
    usePluginWithParams(usePlaceholder, 'Type “@” to mention other users.'),
    useWrapper,
    useCompositionModeAdjustments,
    usePluginWithParams(useApiLimitations, SimpleTextEditorLimitations),
    useOnChange,
    usePluginWithParams(useKeyboardShortcuts, textKeyCommandMap),
    usePluginWithParams(useCustomInputHandling, EnterKeyBehavior.AlwaysSoftNewLine),
    usePluginWithParams(useFocus, AutoFocusBehavior.WhenInView),
    useClipboard,
    useUndoRedo,
    useStyles,
    useEntityApi,
    useMentions,
    useForceSelectionOnBlur,
    useForceSingleUnstyledBlock,
    //  Handled by parent within Ctrl/Command + Enter for submitting comment
    useIgnoreCtrlEnter,
    useSpellCheck,
    useAutomaticWebLinkConversion,
    useSimpleWebLinks,
    useManualReset,
    useCommentInputStyles,
  );

export type CommentInputProps = EditorProps<typeof useComposedEditor>;

export const CommentInput: React.FC<CommentInputProps> = memo((props) => {
  const { ComposedEditor } = useComposedEditor();

  return <ComposedEditor {...props} dataUiInput={props.dataUiInput ?? DataUiInput.Comment} />;
});

CommentInput.displayName = 'CommentInput';
