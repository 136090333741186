import React, { useCallback, useMemo } from 'react';
import { RTECommandSource } from '../../../../_shared/models/events/RTECommandEventData.type.ts';
import { useEditorApi } from '../../editorCore/hooks/useEditorApi.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import {
  Apply,
  EditorPlugin,
  PluginState,
  Render,
} from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import { withDisplayName } from '../../editorCore/utils/withDisplayName.ts';
import {
  doesSelectionContainText,
  getFullBlockTypesAtSelection,
  getMetadataAtSelection,
} from '../../utils/editorSelectionUtils.ts';
import { areAllTextBlocksAllowed } from '../apiLimitations/api/editorLimitationUtils.ts';
import {
  ExecuteCommand,
  KeyboardShortcutsPlugin,
} from '../keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { RichTextInputCommand } from '../keyboardShortcuts/api/EditorCommand.ts';
import { InlineToolbarPlugin } from '../toolbars/InlineToolbarPlugin.tsx';
import { getToolbarButtonTooltipText } from '../toolbars/utils/toolbarUtils.ts';
import { EditorClearFormattingApi } from './api/EditorClearFormattingApi.type.ts';
import { editorClearFormattingApi } from './api/editorClearFormattingApi.ts';
import { ClearFormattingButton } from './components/ClearFormattingButton.tsx';

export type ClearFormattingPlugin = EditorPlugin<
  None,
  None,
  EditorClearFormattingApi,
  [InlineToolbarPlugin, KeyboardShortcutsPlugin<RichTextInputCommand>]
>;

type ClearFormattingToolbarButtonProps = Pick<
  PluginState<ClearFormattingPlugin>,
  'editorState' | 'handleCommand' | 'getApi'
>;

const ClearFormattingToolbarButton: React.FC<ClearFormattingToolbarButtonProps> = ({
  editorState,
  getApi,
  handleCommand,
}) => {
  const clearFormatting = useCallback((): void => {
    handleCommand(RichTextInputCommand.ClearFormatting, RTECommandSource.InlineToolbar);
  }, [handleCommand]);

  const content = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const fullBlockTypesAtSelection = getFullBlockTypesAtSelection(content, selection);
  const limitations = getApi().getLimitations();
  const textChangeDisabled = !areAllTextBlocksAllowed(fullBlockTypesAtSelection, limitations);

  return (
    <ClearFormattingButton
      disabled={textChangeDisabled}
      onClick={clearFormatting}
      tooltipText={getToolbarButtonTooltipText('Clear formatting', textChangeDisabled)}
    />
  );
};

ClearFormattingToolbarButton.displayName = 'ClearFormattingToolbarButton';

export const useClearFormatting: PluginCreator<ClearFormattingPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('ClearFormattingPlugin', {
        ComposedEditor: (props) => {
          const { disabled } = props;

          const renderInlineToolbarButtons: Decorator<Render<ClearFormattingPlugin>> = useCallback(
            (baseRender) => (state) => {
              const { editorState } = state;
              const content = editorState.getCurrentContent();
              const selection = editorState.getSelection();
              const metadataAtSelection = getMetadataAtSelection(content, selection);
              const selectionContainsText = doesSelectionContainText(
                selection,
                metadataAtSelection,
              );

              const allowClearFormatting =
                state.canUpdateContent() && selectionContainsText && !disabled;
              if (!allowClearFormatting) {
                return baseRender(state);
              }

              return (
                <>
                  {baseRender(state)}
                  <ClearFormattingToolbarButton
                    editorState={state.editorState}
                    getApi={state.getApi}
                    handleCommand={state.handleCommand}
                  />
                </>
              );
            },
            [disabled],
          );

          const apply: Apply<ClearFormattingPlugin> = useCallback(
            (state) => {
              state.renderInlineToolbarButtons.decorate(renderInlineToolbarButtons);

              const executeCommand: Decorator<ExecuteCommand<RichTextInputCommand>> =
                (baseExecuteCommand) => (command, isShiftPressed) => {
                  switch (command) {
                    case RichTextInputCommand.ClearFormatting: {
                      state.executeChange((editorState) =>
                        state.getApi().clearSelectionFormatting(editorState),
                      );
                      return true;
                    }

                    default:
                      return baseExecuteCommand(command, isShiftPressed);
                  }
                };

              state.executeCommand.decorate(executeCommand);

              return {};
            },
            [renderInlineToolbarButtons],
          );

          const { getApiMethods } = useEditorApi<ClearFormattingPlugin>(editorClearFormattingApi);

          return useEditorWithPlugin(baseEditor, props, { apply, getApiMethods });
        },
      }),
    [baseEditor],
  );
