import PropTypes from 'prop-types';
import React, { PropsWithChildren, ReactElement } from 'react';
import { EmptyObject, FieldPathByValue, FieldValues, useController } from 'react-hook-form';
import { OptionMode } from '../../models/optionMode.ts';
import { HookFormProps, hookFormProps } from '../../types/hookFormProps.ts';
import { FormFieldError } from '../../uiComponents/FormAlert/FormFieldError.tsx';
import { showFieldError } from '../../utils/validation/showFieldError.ts';
import { OptionProps, optionModePropType } from '../Options/createOptionComponent.tsx';

type Props<TFormShape extends FieldValues, TDataUiActionType> = PropsWithChildren<{
  readonly dataUiAttribute?: TDataUiActionType;
  readonly formProps: HookFormProps<TFormShape>;
  readonly label?: string;
  readonly mode: OptionMode;
  readonly name: FieldPathByValue<TFormShape, boolean>;
}>;

const propTypes: PropTypesShape<Props<any, string>> = {
  children: PropTypes.node,
  dataUiAttribute: PropTypes.string,
  formProps: hookFormProps.isRequired,
  label: PropTypes.string,
  mode: optionModePropType.isRequired,
  name: PropTypes.string.isRequired,
};

type OptionComponentType<TDataUiActionType> = <TFormShape extends FieldValues = EmptyObject>(
  props: Props<TFormShape, TDataUiActionType>,
) => ReactElement;

export const createValidatedOptionComponent = <TDataUiActionType extends string = never>(
  OptionComponent: React.ComponentType<
    OptionProps<TDataUiActionType> & React.ComponentPropsWithRef<React.ComponentType>
  >,
) => {
  const ValidatedOption: React.FC<Props<any, string>> = <TFormShape extends FieldValues>({
    children,
    dataUiAttribute,
    formProps,
    label,
    mode,
    name,
  }: Props<TFormShape, TDataUiActionType>) => {
    const { field, fieldState, formState } = useController({
      control: formProps.control,
      name,
    });
    const { error } = fieldState;
    const showError = showFieldError(formState, error);

    return (
      <>
        <OptionComponent
          ref={field.ref}
          mode={mode}
          onOptionSelected={field.onChange}
          label={children || label}
          isSelected={field.value as boolean}
          dataUiAttribute={dataUiAttribute}
        />
        <FormFieldError isDisplayed={showError}>{error?.message}</FormFieldError>
      </>
    );
  };

  ValidatedOption.displayName = `Validated${OptionComponent.displayName}`;
  ValidatedOption.propTypes = propTypes;

  return ValidatedOption as OptionComponentType<TDataUiActionType>;
};
