import { useContext, useMemo } from 'react';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { ContentItemId } from '../../../../../../_shared/models/ContentItemId.type.ts';
import { getEditedContentItemVariantId } from '../../../../../../_shared/selectors/getEditedContentItemVariant.ts';
import { ElementType } from '../../../../../contentInventory/content/models/ContentItemElementType.ts';
import { EditableTypeElement } from '../../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { ContentComponentContext } from '../../../ContentComponent/context/ContentComponentContext.tsx';
import { RichTextItemElementContext } from '../../context/RichTextItemElementContext.tsx';
import { areIdsEquivalent as areItemIdsEquivalent } from '../../utils/itemEditingUtils.ts';

export interface ElementReference {
  readonly contentComponentId: Uuid | null;
  readonly elementCodename: string | null;
  readonly elementId: Uuid;
  readonly elementName: string;
  readonly elementType: ElementType;
  readonly itemId: ContentItemId | null;
  readonly rootRichTextElementId: Uuid | null;
}

export const EmptyElementReference: ElementReference = {
  contentComponentId: null,
  elementCodename: null,
  elementId: '',
  elementName: '',
  elementType: ElementType.Text,
  itemId: null,
  rootRichTextElementId: null,
};

export const useItemElementReference = (
  typeElement: EditableTypeElement,
): ElementReference | null => {
  const itemId = useSelector(getEditedContentItemVariantId);
  const { contentComponentId } = useContext(ContentComponentContext);
  const { rootRichTextElementId } = useContext(RichTextItemElementContext);
  const {
    elementId,
    codename: elementCodename,
    name: elementName,
    type: elementType,
  } = typeElement;

  const editedElementId = useMemo(
    (): ElementReference | null =>
      itemId
        ? {
            contentComponentId,
            elementCodename,
            elementId,
            elementName,
            elementType,
            itemId,
            rootRichTextElementId,
          }
        : null,
    [
      contentComponentId,
      elementCodename,
      elementId,
      elementName,
      elementType,
      itemId,
      rootRichTextElementId,
    ],
  );

  return editedElementId;
};

export const areReferencesPointingToSameElement = (
  first: ElementReference,
  second: ElementReference,
): boolean =>
  ((!first.itemId && !second.itemId) ||
    (!!first.itemId && !!second.itemId && areItemIdsEquivalent(first.itemId, second.itemId))) &&
  first.contentComponentId === second.contentComponentId &&
  first.elementId === second.elementId &&
  first.rootRichTextElementId === second.rootRichTextElementId;
