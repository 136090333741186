import PropTypes from 'prop-types';
import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import {
  fadeIn,
  fadeOut,
  getAnimationTime,
} from '../../../../client/component-library/components/Dialogs/animations.ts';
import { ComponentLibraryGlobals } from '../../globals/componentLibraryGlobals.ts';
import { Box } from '../../layout/Box/Box.tsx';
import { colorOverlayBackground } from '../../tokens/decision/colors.ts';
import { overlayZIndex } from '../../tokens/decision/zIndex.ts';
import { easing } from '../../tokens/quarks/transitions.ts';
import { OverlayPortalContainer } from '../utils/OverlayPortalContainer.tsx';

type HTMLProps = Omit<HTMLAttributes<HTMLDivElement>, 'color'>;

interface IOverlayProps {
  readonly isOpen?: boolean;
}

const propTypes: PropTypeMap<IOverlayProps> = {
  isOpen: PropTypes.bool,
};

const StyledBox = styled(Box)<IOverlayProps>`
  ${({ isOpen }) => css`
    transform: translateZ(0);
    will-change: opacity;

    ${
      isOpen !== undefined &&
      !ComponentLibraryGlobals.skipAnimation &&
      css`
      animation: ${isOpen ? fadeIn : fadeOut} ${getAnimationTime()}ms ${easing};
    `
    };
  `}
`;

export const Overlay = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<IOverlayProps & HTMLProps>
>(({ isOpen, ...otherProps }, forwardedRef) => (
  <OverlayPortalContainer>
    <StyledBox
      isOpen={isOpen}
      ref={forwardedRef}
      position="fixed"
      inset={0}
      zIndex={overlayZIndex}
      backgroundColor={colorOverlayBackground}
      {...otherProps}
    />
  </OverlayPortalContainer>
));

Overlay.displayName = 'Overlay';
Overlay.propTypes = propTypes;
