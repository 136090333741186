import React from 'react';
import { Controller } from 'react-hook-form';
import { HookFormProps } from '../../../../../../../_shared/types/hookFormProps.ts';
import { INoteFormShape } from '../../../models/INoteFormShape.type.ts';
import { FormPane } from '../FormPane.tsx';
import { UnsavedChangesWarning } from '../UnsavedChangesWarning.tsx';
import { NoteFormTextArea } from './NoteFormTextArea.tsx';

type Props = {
  readonly formProps: HookFormProps<INoteFormShape>;
  readonly initialNote: string | null | undefined;
  readonly isOtherAssignmentSectionSubmitting: boolean;
  readonly isSectionSubmitting: boolean;
  readonly isWarningDisplayed: boolean;
  readonly onReset: () => void;
  readonly onSubmit: () => void;
};

const getSubmitButtonText = (
  initialNote: string | null | undefined,
  withIngForm: boolean,
): string => {
  if (!initialNote) {
    return withIngForm ? 'Adding...' : 'Add';
  }

  return withIngForm ? 'Changing...' : 'Change';
};

export const NoteFormBase: React.FC<Props> = (props) => (
  <FormPane
    disableCancelButton={props.isSectionSubmitting}
    disableSubmitButton={props.isSectionSubmitting || props.isOtherAssignmentSectionSubmitting}
    onSubmit={props.onSubmit}
    onReset={props.onReset}
    submitUsingControlEnter
    submitButtonTooltipText={getSubmitButtonText(props.initialNote, false)}
    submitButtonText={getSubmitButtonText(props.initialNote, props.isSectionSubmitting)}
  >
    <Controller
      control={props.formProps.control}
      name="note"
      render={({ field }) => (
        <NoteFormTextArea
          ref={field.ref}
          autoFocus
          disabled={props.isSectionSubmitting}
          placeholder="Add any additional instructions or notes for other contributors"
          rows={3}
          onChange={field.onChange}
          value={field.value}
        />
      )}
    />
    {props.isWarningDisplayed && (
      <UnsavedChangesWarning
        submitButtonName={getSubmitButtonText(props.initialNote, false)}
        subjectOfChange="note"
      />
    )}
  </FormPane>
);

NoteFormBase.displayName = 'NoteForm';
