import { usePrevious } from '@kontent-ai/hooks';
import { IUpdateMessageElement } from '@kontent-ai/smart-link/types/lib/IFrameCommunicatorTypes';
import { Collection, noOperation } from '@kontent-ai/utils';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ObservedSize } from 'use-resize-observer';
import { trackUserEventWithData } from '../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { WebSpotlightPreviewResolutionChangeOrigin } from '../../../_shared/models/TrackUserEventData.ts';
import { getEditedContentItem } from '../../../_shared/selectors/getEditedContentItem.ts';
import { getEditedContentItemVariantId } from '../../../_shared/selectors/getEditedContentItemVariant.ts';
import { getSelectedLanguageCodename } from '../../../_shared/selectors/getSelectedLanguageCodename.ts';
import { clamp } from '../../../_shared/utils/numberUtils.ts';
import { getUrlOrigin } from '../../../_shared/utils/urlUtils.ts';
import { getCurrentProjectId } from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { WebSpotlightPreferencesStorage } from '../../../localStorages/webSpotlightPreferencesStorage.ts';
import {
  itemPreviewInfoReloadRequested,
  webSpotlightPreviewRefreshed,
} from '../actions/webSpotlightActions.ts';
import {
  WebSpotlightPreviewMaxResolutionPx,
  WebSpotlightPreviewMinResolutionPx,
} from '../constants/uiConstants.ts';
import { LivePreviewElementUpdater } from '../containers/LivePreviewElementUpdater.tsx';
import {
  IWebSpotlightPreviewResolution,
  WebSpotlightPreviewResolutionType,
  fitToScreenResolution,
  isPredefinedResolutionType,
  webSpotlightPredefinedPreviewResolutions,
} from '../models/webSpotlightPreviewResolutionType.ts';
import {
  IReloadPreviewMessageData,
  ISmartLinkSDKSupportedFeatures,
  IUpdatePreviewSmartLinkSdkHostMessage,
  SmartLinkSdkHostMessage,
  SmartLinkSdkHostMessageType,
} from '../types/SmartLinkSdkApi.ts';
import { FloatingEditorPosition } from '../types/floatingEditorPosition.ts';
import {
  calculateContainerFitResolution,
  calculateContainerFitScale,
  doesPreviewResolutionFitInside,
} from '../utils/webSpotlightPreviewUtils.ts';

interface IRefreshPreviewParams {
  readonly isManualRefresh: boolean;
  readonly data?: IReloadPreviewMessageData;
  readonly isPreviewUrlOutdated?: boolean;
}

interface IWebSpotlightContextState {
  readonly currentPreviewUrl: string;
  readonly editableElementsVisible: boolean;
  readonly floatingEditorPosition: FloatingEditorPosition;
  readonly getPreviewIFrameWindow: () => Window | null;
  readonly isPreviewIFrameInitialized: boolean;
  readonly isSmartLinkSdkInitialized: boolean;
  readonly persistResolutionType: (type: WebSpotlightPreviewResolutionType) => void;
  readonly previewIFrameKey: number;
  readonly previewIFrameRef: React.RefObject<HTMLIFrameElement> | undefined;
  readonly previewIFrameResolution: IWebSpotlightPreviewResolution;
  readonly previewIFrameResolutionType: WebSpotlightPreviewResolutionType;
  readonly previewPaneRef: React.RefObject<HTMLDivElement> | undefined;
  readonly previewUrl: string;
  readonly refreshPreview: (params: IRefreshPreviewParams) => void;
  readonly requestPreviewIFrameCurrentUrl: () => void;
  readonly rescalePreview: (scale: number) => void;
  readonly rescalePreviewToFit: () => void;
  readonly resetPreviewIFrameCurrentUrl: () => void;
  readonly resizePreview: (width: number, height: number) => void;
  readonly rotatePreview: () => void;
  readonly selectPreviewResolutionType: (type: WebSpotlightPreviewResolutionType) => void;
  readonly sendMessageToPreviewIFrame: (message: SmartLinkSdkHostMessage) => void;
  readonly setCurrentPreviewUrl: (url: string) => void;
  readonly setFloatingEditorPosition: (value: FloatingEditorPosition) => void;
  readonly setIsPreviewIFrameInitialized: (value: boolean) => void;
  readonly setIsSmartLinkSdkInitialized: (value: boolean) => void;
  readonly setPreviewIFrameResolution: (resolution: IWebSpotlightPreviewResolution) => void;
  readonly setSupportedSmartLinkFeatures: (value: ISmartLinkSDKSupportedFeatures | null) => void;
  readonly supportedSmartLinkFeatures: ISmartLinkSDKSupportedFeatures | null;
  readonly toggleEditableElements: () => void;
  readonly trackPreviewResolution: (observedSize: ObservedSize) => void;
}

const defaultContext: IWebSpotlightContextState = {
  currentPreviewUrl: '',
  editableElementsVisible: false,
  floatingEditorPosition: FloatingEditorPosition.Right,
  getPreviewIFrameWindow: () => null,
  isPreviewIFrameInitialized: false,
  isSmartLinkSdkInitialized: false,
  persistResolutionType: noOperation,
  previewIFrameKey: 0,
  previewIFrameRef: undefined,
  previewIFrameResolution: fitToScreenResolution,
  previewIFrameResolutionType: WebSpotlightPreviewResolutionType.FitScreen,
  previewPaneRef: undefined,
  previewUrl: '',
  refreshPreview: noOperation,
  requestPreviewIFrameCurrentUrl: noOperation,
  rescalePreview: noOperation,
  rescalePreviewToFit: noOperation,
  resetPreviewIFrameCurrentUrl: noOperation,
  resizePreview: noOperation,
  rotatePreview: noOperation,
  selectPreviewResolutionType: noOperation,
  sendMessageToPreviewIFrame: noOperation,
  setCurrentPreviewUrl: noOperation,
  setFloatingEditorPosition: noOperation,
  setIsPreviewIFrameInitialized: noOperation,
  setIsSmartLinkSdkInitialized: noOperation,
  setPreviewIFrameResolution: noOperation,
  setSupportedSmartLinkFeatures: noOperation,
  supportedSmartLinkFeatures: null,
  toggleEditableElements: noOperation,
  trackPreviewResolution: noOperation,
};

export const WebSpotlightContext = React.createContext<IWebSpotlightContextState>(defaultContext);

export const WebSpotlightContextProvider: React.FC<React.PropsWithChildren<NoProps>> = ({
  children,
}) => {
  const dispatch = useDispatch();

  const previewIFrameRef = useRef<HTMLIFrameElement | null>(null);
  const previewPaneRef = useRef<HTMLDivElement | null>(null);

  const [editableElementsVisible, setEditableElementsVisible] = useState<boolean>(true);
  const [floatingEditorPosition, setFloatingEditorPosition] = useState<FloatingEditorPosition>(
    FloatingEditorPosition.Right,
  );
  const [isPreviewIFrameInitialized, setIsPreviewIFrameInitialized] = useState<boolean>(false);
  const [isSmartLinkSdkInitialized, setIsSmartLinkSdkInitialized] = useState<boolean>(false);
  const [previewIFrameKey, setPreviewIFrameKey] = useState<number>(0);
  const [previewIFrameResolution, setPreviewIFrameResolution] =
    useState<IWebSpotlightPreviewResolution>(defaultContext.previewIFrameResolution);
  const [previewIFrameResolutionType, setPreviewIFrameResolutionType] =
    useState<WebSpotlightPreviewResolutionType>(defaultContext.previewIFrameResolutionType);
  const [supportedSmartLinkFeatures, setSupportedSmartLinkFeatures] =
    useState<ISmartLinkSDKSupportedFeatures | null>(null);

  const projectId = useSelector(getCurrentProjectId);
  const editedItemCodename = useSelector((s) => getEditedContentItem(s).codename);
  const editedContentItemVariantId = useSelector(getEditedContentItemVariantId);
  const languageCodename = useSelector(getSelectedLanguageCodename);

  const selectedProjectId = useSelector(getCurrentProjectId);
  const sharedPreviewData = useSelector((s) => s.webSpotlightApp.sharedPreviewData);

  const [currentPreviewUrl, setCurrentPreviewUrl] = useState<string>('');
  const previewUrl = useSelector(
    (state) => state.webSpotlightApp.itemPreviewInfo?.previewUrlInfo.url || '',
  );

  const getPreviewIFrameWindow = useCallback(
    (): Window | null => previewIFrameRef.current?.contentWindow ?? null,
    [],
  );

  const persistResolutionType = useCallback(
    (type: WebSpotlightPreviewResolutionType) => {
      setPreviewIFrameResolutionType(type);
      WebSpotlightPreferencesStorage.update({ previewResolutionType: type }, selectedProjectId);
    },
    [selectedProjectId],
  );

  const rescalePreview = useCallback(
    (newScale: number) => {
      const newResolution = calculateContainerFitResolution(
        previewPaneRef.current,
        previewIFrameResolution,
        newScale,
      );

      if (
        previewIFrameResolutionType === WebSpotlightPreviewResolutionType.FitScreen ||
        newResolution.width !== previewIFrameResolution.width ||
        newResolution.height !== previewIFrameResolution.height
      ) {
        persistResolutionType(WebSpotlightPreviewResolutionType.Responsive);
      }

      setPreviewIFrameResolution(newResolution);
    },
    [persistResolutionType, previewIFrameResolutionType, previewIFrameResolution],
  );

  const resizePreview = useCallback((newWidth: number, newHeight: number, newScale?: number) => {
    const newResolution = calculateContainerFitScale(
      previewPaneRef.current,
      newWidth,
      newHeight,
      newScale,
    );

    setPreviewIFrameResolution(newResolution);
  }, []);

  const rotatePreview = useCallback(() => {
    const { width: newHeight, height: newWidth } = previewIFrameResolution;

    persistResolutionType(WebSpotlightPreviewResolutionType.Responsive);
    resizePreview(newWidth, newHeight);

    dispatch(
      trackUserEventWithData(TrackedEvent.WebSpotlightPreviewResolutionChanged, {
        origin: WebSpotlightPreviewResolutionChangeOrigin.RotateButton,
      }),
    );
  }, [previewIFrameResolution, resizePreview, persistResolutionType]);

  const rescalePreviewToFit = useCallback(() => {
    const preview = previewIFrameRef.current;
    const container = previewPaneRef.current;
    if (!container || !preview || !isPreviewIFrameInitialized) {
      return;
    }

    const fitsToScreen =
      previewIFrameResolutionType === WebSpotlightPreviewResolutionType.FitScreen;
    const fitsInsideContainer = doesPreviewResolutionFitInside(previewIFrameResolution, container);
    if (!fitsToScreen && !fitsInsideContainer) {
      resizePreview(previewIFrameResolution.width, previewIFrameResolution.height);
    }
  }, [
    isPreviewIFrameInitialized,
    previewIFrameResolutionType,
    previewIFrameResolution,
    resizePreview,
  ]);

  const trackPreviewResolution = useCallback(
    ({ width, height }: ObservedSize) => {
      const preview = previewIFrameRef.current;
      if (!preview || !width || !height || !isPreviewIFrameInitialized) {
        return;
      }

      const fitsToScreen =
        previewIFrameResolutionType === WebSpotlightPreviewResolutionType.FitScreen;
      if (
        fitsToScreen &&
        (width !== previewIFrameResolution.width || height !== previewIFrameResolution.height)
      ) {
        setPreviewIFrameResolution({
          scale: 1,
          width,
          height,
        });
      }
    },
    [
      previewIFrameResolution.width,
      previewIFrameResolution.height,
      previewIFrameResolutionType,
      isPreviewIFrameInitialized,
    ],
  );

  const selectPreviewResolutionType = useCallback(
    (type: WebSpotlightPreviewResolutionType = WebSpotlightPreviewResolutionType.FitScreen) => {
      persistResolutionType(type);

      if (isPredefinedResolutionType(type)) {
        const resolution = webSpotlightPredefinedPreviewResolutions[type];
        resizePreview(resolution.width, resolution.height);
      } else if (type === WebSpotlightPreviewResolutionType.Responsive) {
        const preferences = WebSpotlightPreferencesStorage.load(selectedProjectId);
        const previewRect = previewIFrameRef.current?.getBoundingClientRect();
        const newWidth = preferences.customPreviewWidth || previewRect?.width || 0;
        const newHeight = preferences.customPreviewHeight || previewRect?.height || 0;
        const newScale = preferences.customPreviewScale || undefined;

        resizePreview(newWidth, newHeight, newScale);
      }
    },
    [persistResolutionType, resizePreview, selectedProjectId],
  );

  const sendMessageToPreviewIFrame = useCallback(
    (message: SmartLinkSdkHostMessage): void => {
      const targetWindow = getPreviewIFrameWindow();
      if (targetWindow) {
        // Send the message only to origin generated from previewUrl to prevent others from sniffing
        const targetOrigin = getUrlOrigin(previewUrl);
        targetWindow.postMessage(message, targetOrigin);
      }
    },
    [getPreviewIFrameWindow, previewUrl],
  );

  const updatePreview = useCallback(
    (elements: IUpdateMessageElement[]): void => {
      if (
        !editedContentItemVariantId ||
        !supportedSmartLinkFeatures?.updateHandler ||
        Collection.isEmpty(elements)
      ) {
        return;
      }

      const message: IUpdatePreviewSmartLinkSdkHostMessage = {
        type: SmartLinkSdkHostMessageType.UpdatePreview,
        data: {
          projectId,
          item: {
            id: editedContentItemVariantId?.itemId,
            codename: editedItemCodename,
          },
          variant: {
            id: editedContentItemVariantId?.variantId,
            codename: languageCodename,
          },
          elements,
        },
      };
      sendMessageToPreviewIFrame(message);
    },
    [
      sendMessageToPreviewIFrame,
      supportedSmartLinkFeatures?.updateHandler,
      editedContentItemVariantId,
      languageCodename,
      editedItemCodename,
      projectId,
    ],
  );

  const refreshPreview = useCallback(
    ({ isManualRefresh, data, isPreviewUrlOutdated }: IRefreshPreviewParams) => {
      if (supportedSmartLinkFeatures?.refreshHandler && !isPreviewUrlOutdated) {
        sendMessageToPreviewIFrame({
          type: SmartLinkSdkHostMessageType.RefreshPreview,
          metadata: { manualRefresh: isManualRefresh },
          data,
        });
      } else {
        if (previewIFrameRef?.current) {
          setPreviewIFrameKey((previousState) => previousState + 1);
        }
        if (isManualRefresh || isPreviewUrlOutdated) {
          dispatch(itemPreviewInfoReloadRequested());
        }
      }

      dispatch(webSpotlightPreviewRefreshed(isManualRefresh));
    },
    [sendMessageToPreviewIFrame, supportedSmartLinkFeatures?.refreshHandler],
  );

  const requestPreviewIFrameCurrentUrl = useCallback(() => {
    if (supportedSmartLinkFeatures?.previewIFrameCurrentUrlHandler) {
      sendMessageToPreviewIFrame({
        type: SmartLinkSdkHostMessageType.PreviewIFrameCurrentUrl,
      });
    }
  }, [sendMessageToPreviewIFrame, supportedSmartLinkFeatures?.previewIFrameCurrentUrlHandler]);

  const resetPreviewIFrameCurrentUrl = useCallback(() => setCurrentPreviewUrl(''), []);

  const previousPreviewUrl = usePrevious(previewUrl);
  useEffect(() => {
    if (previousPreviewUrl !== previewUrl) {
      setIsSmartLinkSdkInitialized(false);
    }
  }, [previousPreviewUrl, previewUrl]);

  useEffect(() => {
    if (sharedPreviewData) {
      if (sharedPreviewData.width || sharedPreviewData.height) {
        setPreviewIFrameResolutionType(WebSpotlightPreviewResolutionType.Responsive);

        const width = clamp(
          sharedPreviewData.width || 0,
          WebSpotlightPreviewMinResolutionPx,
          WebSpotlightPreviewMaxResolutionPx,
        );
        const height = clamp(
          sharedPreviewData.height || 0,
          WebSpotlightPreviewMinResolutionPx,
          WebSpotlightPreviewMaxResolutionPx,
        );
        resizePreview(width, height);
      } else {
        setPreviewIFrameResolutionType(WebSpotlightPreviewResolutionType.FitScreen);
      }
    } else {
      const preferences = WebSpotlightPreferencesStorage.load(selectedProjectId);
      selectPreviewResolutionType(preferences.previewResolutionType);
    }
  }, [selectedProjectId, selectPreviewResolutionType, sharedPreviewData, resizePreview]);

  useEffect(() => {
    if (
      previewIFrameResolutionType === WebSpotlightPreviewResolutionType.Responsive &&
      isPreviewIFrameInitialized
    ) {
      WebSpotlightPreferencesStorage.update(
        {
          customPreviewWidth: previewIFrameResolution.width,
          customPreviewHeight: previewIFrameResolution.height,
          customPreviewScale: previewIFrameResolution.scale,
        },
        selectedProjectId,
      );
    }
  }, [
    selectedProjectId,
    previewIFrameResolutionType,
    previewIFrameResolution,
    isPreviewIFrameInitialized,
  ]);

  const contextValue: IWebSpotlightContextState = useMemo(
    () => ({
      currentPreviewUrl,
      editableElementsVisible,
      floatingEditorPosition,
      getPreviewIFrameWindow,
      isPreviewIFrameInitialized,
      isSmartLinkSdkInitialized,
      persistResolutionType,
      previewIFrameKey,
      previewIFrameRef,
      previewIFrameResolution,
      previewIFrameResolutionType,
      previewPaneRef,
      previewUrl,
      refreshPreview,
      requestPreviewIFrameCurrentUrl,
      rescalePreview,
      rescalePreviewToFit,
      resetPreviewIFrameCurrentUrl,
      resizePreview,
      rotatePreview,
      selectPreviewResolutionType,
      sendMessageToPreviewIFrame,
      setCurrentPreviewUrl,
      setFloatingEditorPosition,
      setIsPreviewIFrameInitialized,
      setIsSmartLinkSdkInitialized,
      setPreviewIFrameResolution,
      setSupportedSmartLinkFeatures,
      supportedSmartLinkFeatures,
      toggleEditableElements: () => {
        setEditableElementsVisible(!editableElementsVisible);
      },
      trackPreviewResolution,
    }),
    [
      currentPreviewUrl,
      editableElementsVisible,
      floatingEditorPosition,
      getPreviewIFrameWindow,
      isPreviewIFrameInitialized,
      isSmartLinkSdkInitialized,
      persistResolutionType,
      previewIFrameKey,
      previewIFrameResolution,
      previewIFrameResolutionType,
      previewUrl,
      refreshPreview,
      requestPreviewIFrameCurrentUrl,
      rescalePreview,
      rescalePreviewToFit,
      resetPreviewIFrameCurrentUrl,
      resizePreview,
      rotatePreview,
      selectPreviewResolutionType,
      sendMessageToPreviewIFrame,
      supportedSmartLinkFeatures,
      trackPreviewResolution,
    ],
  );

  return (
    <WebSpotlightContext.Provider value={contextValue}>
      <LivePreviewElementUpdater onUpdate={updatePreview} />
      {children}
    </WebSpotlightContext.Provider>
  );
};

WebSpotlightContextProvider.displayName = 'WebSpotlightContextProvider';
