import PropTypes, { ValidationMap, instanceOf } from 'prop-types';
import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  emptyEditorState,
  emptyJsonValue,
} from '../../../richText/utils/general/editorEmptyValues.ts';
import { EmptyContentComponents, IContentComponent } from '../contentItem/ContentComponent.ts';
import {
  BaseTextItemElementValidationMap,
  IBaseTextItemElement,
  IBaseTextItemElementValue,
} from './IBaseTextItemElement.ts';

interface IRichTextElementValue extends IBaseTextItemElementValue {
  readonly contentComponents: ReadonlyMap<Uuid, IContentComponent>;
  readonly value: string;
}

export interface IRichTextItemElement extends IRichTextElementValue, IBaseTextItemElement {}

export const RichTextItemElementPropTypesShape = PropTypes.exact<
  ValidationMap<IRichTextItemElement>
>({
  ...BaseTextItemElementValidationMap,
  contentComponents: instanceOf<ReadonlyMap<Uuid, IContentComponent>>(Map).isRequired,
  value: PropTypes.string.isRequired,
});

export const EmptyRichTextItemElementValue: IRichTextElementValue = {
  _editorState: emptyEditorState,
  contentComponents: EmptyContentComponents,
  jsonValue: emptyJsonValue,
  value: '',
};

export const EmptyRichTextItemElement: IRichTextItemElement = {
  elementId: '',
  lastModifiedAt: null,
  lastModifiedBy: null,
  type: ElementType.RichText,
  ...EmptyRichTextItemElementValue,
};

export const newRichTextItemElement = (
  params: Partial<IRichTextItemElement>,
): IRichTextItemElement => ({
  ...EmptyRichTextItemElement,
  ...params,
});
