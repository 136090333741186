import React from 'react';
import { BarItemAnimation } from '../../../../_shared/components/BarItems/BarItemAnimation.tsx';
import { DropTarget } from '../../../../_shared/components/DragDrop/DropTarget.tsx';
import { DndTypes } from '../../../../_shared/constants/dndTypes.ts';
import { isWithinTargetInset20 } from '../../../../_shared/utils/dragDrop/hoveringCollisionStrategies.ts';
import {
  DraggableStepBar,
  IStepBarContainerProps,
} from '../containers/stepBarItems/DraggableStepBar.tsx';

export interface IWorkflowStepStateProps extends IStepBarContainerProps {
  readonly editedId?: Uuid;
  readonly onMove: (sourceId: Uuid, targetId: Uuid) => void;
}

export const WorkflowStep: React.FC<React.PropsWithChildren<IWorkflowStepStateProps>> = ({
  canAddNewStep,
  children,
  codename,
  color,
  editedId,
  id,
  isDraggable,
  name,
  onMove,
}) => {
  return (
    <DropTarget<HTMLLIElement>
      accept={DndTypes.WorkflowStep_Bar_Move}
      canDrop={isDraggable}
      onMove={({ sourceId, targetId }) => onMove(sourceId, targetId)}
      parentId=""
      hoveringCollisionStrategy={isWithinTargetInset20}
      renderDroppable={(ref) => (
        <li className="bar-item__node" ref={ref}>
          <BarItemAnimation
            estimatedMaxHeightWhenExpanded={800}
            renderCollapsed={() => (
              <DraggableStepBar
                id={id}
                isDraggable={isDraggable}
                color={color}
                canAddNewStep={canAddNewStep}
                name={name}
                codename={codename}
              />
            )}
            renderExpanded={() => <>{children}</>}
            shouldBeExpanded={id === editedId}
          />
        </li>
      )}
      targetId={id}
    />
  );
};

WorkflowStep.displayName = 'WorkflowStep';
