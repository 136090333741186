import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import { loadCollections, loadLanguages } from '../../data/actions/thunkDataActions.ts';
import {
  getActiveLanguageIds,
  getAllLanguageIds,
} from '../../data/reducers/languages/selectors/getLanguages.ts';
import { getNormalizedRolesWithSettings } from '../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { AppNames } from '../constants/applicationNames.ts';
import {
  AccessDeniedRoute,
  AccessDeniedRouteParams,
  AccessLostDueToDeactivatedLanguageRoute,
  EnvironmentAccessDeniedRouteParams,
  EnvironmentRouteParams,
} from '../constants/routePaths.ts';
import { useDispatch } from '../hooks/useDispatch.ts';
import { useSelector } from '../hooks/useSelector.ts';
import { Capability } from '../utils/permissions/capability.ts';
import { getLanguageIdsWithAllowedCapabilityInAnyCollection } from '../utils/permissions/getLanguageIdsWithAllowedCapability.ts';
import { buildPath } from '../utils/routing/routeTransitionUtils.ts';
import { isUuid } from '../utils/validation/typeValidators.ts';
import { Loader } from './Loader.tsx';

type Props = {
  readonly appName: AppNames;
  readonly children: ReactNode;
};

export const EnsureContentProductionCapabilities: React.FC<Props> = ({ appName, children }) => {
  const [isStateEnsured, setIsStateEnsured] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const ensureState = async () => {
      await Promise.all([dispatch(loadLanguages()), dispatch(loadCollections())]);

      setIsStateEnsured(true);
    };

    ensureState();
  }, []);

  const { projectId } = useParams<EnvironmentRouteParams>();

  const normalizedRoles = useSelector(getNormalizedRolesWithSettings);
  const allLanguageIds = useSelector((s) => getAllLanguageIds(s.data.languages));
  const collectionsMap = useSelector((s) => s.data.collections.byId);

  const languageIdsWithAllowedView = useMemo(
    () =>
      getLanguageIdsWithAllowedCapabilityInAnyCollection(
        normalizedRoles,
        allLanguageIds,
        Capability.ViewContent,
        collectionsMap,
      ),
    [normalizedRoles, allLanguageIds, collectionsMap],
  );

  const hasFallbackLanguage = useSelector((s) => {
    const activeLanguageIds = getActiveLanguageIds(s.data.languages);

    return activeLanguageIds.some((id: Uuid) => languageIdsWithAllowedView.has(id));
  });

  if (!isStateEnsured) {
    return <Loader />;
  }

  if (languageIdsWithAllowedView.isEmpty()) {
    return (
      <Redirect
        to={
          isUuid(projectId)
            ? buildPath<EnvironmentAccessDeniedRouteParams>(AccessDeniedRoute, {
                requestedAppName: appName,
                projectId,
              })
            : buildPath<AccessDeniedRouteParams>(AccessDeniedRoute, { requestedAppName: appName })
        }
      />
    );
  }

  if (!hasFallbackLanguage) {
    return (
      <Redirect
        to={buildPath<EnvironmentRouteParams>(AccessLostDueToDeactivatedLanguageRoute, {
          projectId,
        })}
      />
    );
  }

  return <>{children}</>;
};

EnsureContentProductionCapabilities.displayName = 'EnsureContentProductionCapabilities';
