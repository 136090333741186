import { Collection } from '@kontent-ai/utils';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../../../@types/Dispatcher.type.ts';
import { trackUserEventWithData } from '../../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../../_shared/constants/trackedEvent.ts';
import {
  ContentItemEditingEventOrigins,
  ContentItemEditingEventTypes,
} from '../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { areCollectionsVisibleForAssets } from '../../../../../../../_shared/selectors/contentCollections.ts';
import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import { Capability } from '../../../../../../../_shared/utils/permissions/capability.ts';
import { currentUserHasCapabilities } from '../../../../../../../_shared/utils/permissions/capabilityUtils.ts';
import { removeTemporaryAssetIdentifiers } from '../../../../../../contentInventory/assets/actions/assetLibraryActions.ts';
import { uploadAssetsFromEditing } from '../../../../../../contentInventory/assets/actions/thunkAssetLibraryActions.ts';
import { IOnAssetFinished } from '../../../../../../contentInventory/assets/actions/thunks/createAssets.ts';
import { RootFolderId } from '../../../../../../contentInventory/assets/constants/assetFolderConstants.ts';
import { FileWithThumbnail } from '../../../../../../contentInventory/assets/models/FileWithThumbnail.type.ts';
import { IAssetItemElementWarningResult } from '../../../../../utils/itemElementWarningCheckers/types/IAssetItemElementWarningResult.type.ts';
import {
  AssetComponent as Component,
  IAssetComponentDispatchProps,
  IAssetComponentOwnProps,
  IAssetComponentStateProps,
} from '../../../components/elements/asset/AssetComponent.tsx';
import { determineTopmostContentElementType } from '../../../utils/contentComponentUtils.ts';

const defaultValidationResult = {
  isFileSizeLimitMet: true,
  isNumberOfItemsLimitMet: true,
  isResponsiveImageTypeLimitMet: true,
  isWidthLimitMet: true,
  isHeightLimitMet: true,
};

export interface IAssetComponentContainerProps extends IAssetComponentOwnProps {
  readonly validationResultSelectorId: string;
}

const mapStateToProps = (
  state: IStore,
  ownProps: IAssetComponentContainerProps,
): IAssetComponentStateProps => {
  const {
    data: { assets },
    contentApp: { itemValidationWarnings },
  } = state;

  const validationResult =
    (itemValidationWarnings.get(
      ownProps.validationResultSelectorId,
    ) as IAssetItemElementWarningResult) ?? defaultValidationResult;

  const canCreateAssets = currentUserHasCapabilities(state, Capability.CreateAssets);
  const canViewAssets = currentUserHasCapabilities(state, Capability.ViewAssets);

  return {
    areCollectionsVisible: areCollectionsVisibleForAssets(
      state,
      Collection.getValues(state.data.collections.byId),
    ),
    canCreateAssets,
    canViewAssets,
    validationResult,
    assetsById: assets.byId,
    currentlyUploadedAssets: assets.uploadingAssets,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: IAssetComponentContainerProps,
): IAssetComponentDispatchProps => {
  return {
    onAssetSelected: (assetIds: ReadonlyArray<Uuid>) => {
      dispatch(
        trackUserEventWithData(TrackedEvent.ContentItemEditing, {
          action: ContentItemEditingEventTypes.AssignAsset,
          origin: ContentItemEditingEventOrigins.Paper,
          contentElementType: determineTopmostContentElementType(ownProps),
          selectedItemsCount: assetIds.length,
        }),
      );
    },
    onFilesUpload: (
      files: Map<Uuid, FileWithThumbnail>,
      collectionId: Uuid | null,
      onAssetFinished: IOnAssetFinished,
    ) =>
      dispatch(
        uploadAssetsFromEditing(files, { collectionId, folderId: RootFolderId }, onAssetFinished),
      ),
    onPostprocessingAssetsFinished: (oldAssetIds: UuidArray) =>
      dispatch(removeTemporaryAssetIdentifiers(oldAssetIds)),
  };
};

export const AssetComponent: React.ComponentType<
  React.PropsWithChildren<IAssetComponentContainerProps>
> = connect(mapStateToProps, mapDispatchToProps)(Component);
