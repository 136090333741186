import { Box } from '@kontent-ai/component-library/Box';
import { Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Paper, PaperLevel } from '@kontent-ai/component-library/Paper';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { memoize } from '@kontent-ai/memoization';
import { notNull } from '@kontent-ai/utils';
import { usePopover } from '../../../../../../../../../component-library/components/Dialogs/Popover/usePopover.tsx';
import { trackUserEventWithData } from '../../../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../../_shared/hooks/useSelector.ts';
import { Task } from '../../../../../../../../_shared/models/Task.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { TasksEventActions } from '../../../../../../../../_shared/models/events/TaskEventData.type.ts';
import { IStore } from '../../../../../../../../_shared/stores/IStore.type.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { cancelTaskEditing } from '../../../../actions/contentItemEditingActions.ts';
import { addTask } from '../../../../actions/thunkContentItemEditingActions.ts';
import {
  CollapsedTaskListMaxItemCount,
  TasksList,
} from '../../../../components/details/Tasks/TasksList.tsx';
import { ContentItemSidebarEditableSection } from '../../../../constants/ContentItemSidebarEditableSection.ts';
import { NewTaskId } from '../../../../constants/taskConstants.ts';
import { TaskPopoverDialogWidth } from '../../../../constants/uiConstants.ts';
import { isSidebarSectionSelected } from '../../../../utils/isSidebarSectionSelected.ts';
import { AddTaskFormBase } from '../AddTaskFormBase.tsx';

const isUserAddingNewTask = (state: IStore): boolean => {
  return (
    isSidebarSectionSelected(state, ContentItemSidebarEditableSection.Tasks) &&
    state.contentApp.editorUi.tasks.selectedTaskId === NewTaskId
  );
};

const getCollapsedTaskList = memoize.maxOne((tasks: readonly Task[]): readonly Task[] =>
  tasks.slice(0, CollapsedTaskListMaxItemCount),
);

const getExpandedTaskList = (state: IStore): readonly Task[] => {
  return state.contentApp.editorUi.tasks.orderedTaskIds
    .map((taskId: Uuid) => state.data.tasks.byId.get(taskId) ?? null)
    .filter(notNull);
};

export const NonClickableTasksSection = () => {
  const dispatch = useDispatch();
  const onAddTaskClick = () => dispatch(addTask(ContentItemEditingEventOrigins.ItemDetails));
  const onShowMoreClick = () =>
    dispatch(
      trackUserEventWithData(TrackedEvent.Tasks, {
        action: TasksEventActions.TaskListExpanded,
      }),
    );
  const expandedTasksList = useSelector(getExpandedTaskList);
  const collapsedTasksList = getCollapsedTaskList(expandedTasksList);
  const highlightedTaskIds = useSelector(
    (state) => state.contentApp.editorUi.tasks.highlightedTaskIds,
  );
  const isAddingNewTask = useSelector(isUserAddingNewTask);

  const { popoverProps, targetProps, Popover } = usePopover({
    isOpen: isAddingNewTask,
    onClose: () => dispatch(cancelTaskEditing(NewTaskId)),
    placement: 'left',
  });

  return (
    <>
      {isAddingNewTask && (
        <Popover {...popoverProps}>
          <Paper level={PaperLevel.Popout} padding={Spacing.XL} minWidth={TaskPopoverDialogWidth}>
            <AddTaskFormBase />
          </Paper>
        </Popover>
      )}
      <Box paddingX={Spacing.XXL} display="inline" cursor="default" {...targetProps}>
        <Button
          buttonStyle="tertiary"
          size="small"
          onClick={onAddTaskClick}
          {...getDataUiActionAttribute(DataUiAction.AddTask)}
        >
          <Button.Icon icon={Icons.Plus} />
          Add task
        </Button>
      </Box>
      <TasksList
        collapsedTasksList={collapsedTasksList}
        expandedTasksList={expandedTasksList}
        highlightedTaskIds={highlightedTaskIds}
        onShowMoreClick={onShowMoreClick}
      />
    </>
  );
};
