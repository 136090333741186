import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import { ContributorsSection as ContributorsSectionComponent } from '../../../components/details/Contributors/ContributorsSection.tsx';
import { SectionType } from '../../../components/sidebar/sectionTypes/SectionType.tsx';
import { getContributorState } from '../../selectors/getContributorState.ts';

const getSectionType = (state: IStore): SectionType => {
  const { isEditing } = getContributorState(state);

  return isEditing ? SectionType.NonClickable : SectionType.Clickable;
};

export const ContributorsSection = () => {
  const isHidden = useSelector(getContributorState).isHidden;
  const sectionItemType = useSelector(getSectionType);

  return isHidden ? null : <ContributorsSectionComponent sectionType={sectionItemType} />;
};
