import { usePrevious } from '@kontent-ai/hooks';
import { AsyncResult, Controller, PickAnimated, SpringValues, useSpring } from '@react-spring/web';
import { CSSProperties, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import {
  ContentItemPreviewWithEditorRoute,
  ContentItemPreviewWithEditorRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import { gridUnit } from '../../../../../_shared/constants/styleConstants.ts';
import { isSpringAnimationFinished } from '../../../../../_shared/utils/reactSpringUtils.ts';
import { matchPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { WebSpotlightContext } from '../../../context/WebSpotlightContext.tsx';
import { FloatingEditorPosition } from '../../../types/floatingEditorPosition.ts';

export enum AnimationState {
  Hiding = 'Hiding',
  None = 'None',
  PositionChange = 'PositionChange',
  Showing = 'Showing',
}

const FloatingEditorMargin = gridUnit;
const FloatingEditorWidth = 50 * gridUnit;

const visibleLeft: CSSProperties = {
  transform: 'translateX(0px)',
};

const hiddenLeft: CSSProperties = {
  transform: `translateX(-${FloatingEditorWidth + FloatingEditorMargin}px)`,
};

const visibleRight: CSSProperties = {
  transform: 'translateX(0px)',
};

const hiddenRight: CSSProperties = {
  transform: `translateX(${FloatingEditorWidth + FloatingEditorMargin}px`,
};

interface IUseFloatingEditorPositionProps {
  readonly animationState: AnimationState;
  readonly animatedStyle: SpringValues<PickAnimated<CSSProperties>>;
  readonly renderedPosition: FloatingEditorPosition;
  readonly togglePosition: () => void;
}

export function useFloatingEditorPosition(): IUseFloatingEditorPositionProps {
  const webSpotlightContext = useContext(WebSpotlightContext);
  const position = webSpotlightContext.floatingEditorPosition;

  const location = useLocation();
  const currentPath = location.pathname;

  const [renderedPosition, setRenderedPosition] = useState(position);

  const [animatedStyle, springRef] = useSpring(() => ({
    initial: renderedPosition === FloatingEditorPosition.Left ? hiddenLeft : hiddenRight,
  }));

  const animatedShow = useCallback(
    (atPosition: FloatingEditorPosition): ReadonlyArray<AsyncResult<Controller>> =>
      springRef.start(atPosition === FloatingEditorPosition.Left ? visibleLeft : visibleRight),
    [springRef],
  );

  const animatedHide = useCallback(
    (fromPosition: FloatingEditorPosition): ReadonlyArray<AsyncResult<Controller>> =>
      springRef.start(fromPosition === FloatingEditorPosition.Left ? hiddenLeft : hiddenRight),
    [springRef],
  );

  const hideWithNewStyles = useCallback(
    (newStyles: CSSProperties): ReadonlyArray<AsyncResult<Controller>> =>
      springRef.start({
        immediate: true,
        from: { opacity: 0 },
        to: { opacity: 0, ...newStyles },
      }),
    [springRef],
  );

  const showAtNewPosition = useCallback(
    (newPosition: FloatingEditorPosition): ReadonlyArray<AsyncResult<Controller>> => {
      setRenderedPosition(newPosition);
      return springRef.start({
        immediate: true,
        from: { opacity: 1 },
        to: { opacity: 1 },
      });
    },
    [springRef],
  );

  const [animationState, setAnimationState] = useState(AnimationState.Showing);
  const triggeredInitialShow = useRef(false);

  const initialShow = useCallback(async (): Promise<void> => {
    const animationResult = springRef.start({
      from: renderedPosition === FloatingEditorPosition.Left ? hiddenLeft : hiddenRight,
      to: renderedPosition === FloatingEditorPosition.Left ? visibleLeft : visibleRight,
    });
    if (!(await isSpringAnimationFinished(animationResult))) {
      return;
    }
    setAnimationState(AnimationState.None);
  }, [springRef, renderedPosition]);

  useEffect(() => {
    if (!triggeredInitialShow.current) {
      triggeredInitialShow.current = true;
      initialShow();
    }
  }, [initialShow]);

  const transitionToDifferentItem = useCallback(
    async (oldPosition: FloatingEditorPosition, newPosition: FloatingEditorPosition) => {
      setAnimationState(AnimationState.Hiding);

      const animatedHideResult = animatedHide(oldPosition);
      if (!(await isSpringAnimationFinished(animatedHideResult))) {
        return;
      }

      const hideWithNewStylesAnimation = hideWithNewStyles(
        newPosition === FloatingEditorPosition.Left ? hiddenLeft : hiddenRight,
      );
      if (!(await isSpringAnimationFinished(hideWithNewStylesAnimation))) {
        return;
      }

      setAnimationState(AnimationState.Showing);

      const showAtNewPositionResult = showAtNewPosition(newPosition);
      if (!(await isSpringAnimationFinished(showAtNewPositionResult))) {
        return;
      }

      const animatedShowResult = animatedShow(newPosition);
      if (!(await isSpringAnimationFinished(animatedShowResult))) {
        return;
      }
      setAnimationState(AnimationState.None);
    },
    [animatedHide, animatedShow, hideWithNewStyles, showAtNewPosition],
  );

  const previousPath = usePrevious(currentPath);

  useEffect(() => {
    if (currentPath !== previousPath) {
      const currentRouteParams = matchPath<ContentItemPreviewWithEditorRouteParams<string>>(
        currentPath,
        ContentItemPreviewWithEditorRoute,
      );
      const prevRouteParams = matchPath<ContentItemPreviewWithEditorRouteParams<string>>(
        previousPath,
        ContentItemPreviewWithEditorRoute,
      );

      if (currentRouteParams?.editedItemId !== prevRouteParams?.editedItemId) {
        transitionToDifferentItem(renderedPosition, position);
      }
    }
  }, [currentPath, previousPath, position, renderedPosition, transitionToDifferentItem]);

  const transitionToNewPosition = useCallback(
    async (
      newPosition: FloatingEditorPosition,
    ): Promise<ReadonlyArray<AsyncResult<Controller>>> => {
      const hideWithNewStylesResult = hideWithNewStyles(
        newPosition === FloatingEditorPosition.Left ? visibleLeft : visibleRight,
      );
      if (!(await isSpringAnimationFinished(hideWithNewStylesResult))) {
        return hideWithNewStylesResult;
      }
      return showAtNewPosition(newPosition);
    },
    [hideWithNewStyles, showAtNewPosition],
  );

  const togglePosition = useCallback(async (): Promise<void> => {
    setAnimationState(AnimationState.PositionChange);
    const newPosition =
      renderedPosition === FloatingEditorPosition.Right
        ? FloatingEditorPosition.Left
        : FloatingEditorPosition.Right;
    const transitionToNewPositionResult = await transitionToNewPosition(newPosition);
    if (!(await isSpringAnimationFinished(transitionToNewPositionResult))) {
      return;
    }
    setAnimationState(AnimationState.None);
    webSpotlightContext.setFloatingEditorPosition(newPosition);
  }, [renderedPosition, webSpotlightContext, transitionToNewPosition]);

  return {
    animationState,
    animatedStyle,
    renderedPosition,
    togglePosition,
  };
}
