import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import {
  IForwardedRefProps,
  forwardRef,
  forwardedRefProps,
} from '../../../../../_shared/utils/forwardedRefProps.tsx';
import { AgendaItemsListing } from '../../containers/AgendaItemsListing.tsx';
import { ICalendarEvent } from '../../models/CalendarModels.type.ts';
import { CalendarPopup } from './CalendarPopup.tsx';

export interface IAgendaItemsListingPopupOwnProps {
  readonly currentDate: Date;
  readonly onDismiss: () => void;
}

export interface IAgendaItemsListingPopupStateProps {
  readonly items: readonly ICalendarEvent[];
}

type AgendaItemsListingPopupProps = IAgendaItemsListingPopupOwnProps &
  IAgendaItemsListingPopupStateProps &
  IForwardedRefProps<HTMLDivElement>;

const propTypes: PropTypesShape<AgendaItemsListingPopupProps> = {
  ...forwardedRefProps,
  items: PropTypes.array.isRequired,
  currentDate: PropTypes.instanceOf(Date).isRequired,
  onDismiss: PropTypes.func.isRequired,
};

const AgendaItemsListingPopup: React.FC<AgendaItemsListingPopupProps> = (props) => (
  <CalendarPopup
    headerContent={format(props.currentDate, 'MMMM d, yyyy')}
    onClose={props.onDismiss}
    ref={props.forwardedRef}
    bodyContent={
      <AgendaItemsListing
        className="calendar-popup__agenda-listing"
        items={props.items}
        onDismiss={props.onDismiss}
      />
    }
  />
);

AgendaItemsListingPopup.displayName = 'AgendaItemsListingPopup';
AgendaItemsListingPopup.propTypes = propTypes;

const AgendaItemsListingFRC = forwardRef(AgendaItemsListingPopup);
export { AgendaItemsListingFRC as AgendaItemsListingPopup };
