import React, { memo } from 'react';
import { DataUiElement } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  createEditorWithPlugins,
  getBaseEditor,
  usePluginWithParams,
} from '../../editorCore/Editor.composition.tsx';
import { EditorProps } from '../../editorCore/types/Editor.composition.type.ts';
import { useApiLimitations } from '../../plugins/apiLimitations/ApiLimitationsPlugin.tsx';
import { useCompositionModeAdjustments } from '../../plugins/behavior/CompositionModeAdjustmentsPlugin.tsx';
import { useExternalUpdate } from '../../plugins/behavior/ExternalUpdatePlugin.tsx';
import { useFocus } from '../../plugins/behavior/FocusPlugin.tsx';
import { useReadonly } from '../../plugins/behavior/ReadonlyPlugin.tsx';
import { useSpellCheck } from '../../plugins/behavior/SpellCheckPlugin.tsx';
import { useClipboard } from '../../plugins/clipboard/ClipboardPlugin.tsx';
import { useDisplayImages } from '../../plugins/images/DisplayImagesPlugin.tsx';
import { useDisplayInlineStyles } from '../../plugins/inlineStyles/InlineStylesPlugin.tsx';
import { useKeyboardShortcuts } from '../../plugins/keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { textKeyCommandMap } from '../../plugins/keyboardShortcuts/api/editorKeyboardUtils.ts';
import { useDisplayLinks } from '../../plugins/links/DisplayLinksPlugin.tsx';
import { useDisplayAssetLinks } from '../../plugins/links/asset/DisplayAssetLinksPlugin.tsx';
import { useDisplayContentLinks } from '../../plugins/links/content/DisplayContentLinksPlugin.tsx';
import { useDisplayEmailLinks } from '../../plugins/links/email/DisplayEmailLinksPlugin.tsx';
import { useDisplayPhoneLinks } from '../../plugins/links/phone/DisplayPhoneLinksPlugin.tsx';
import { useDisplayWebLinks } from '../../plugins/links/web/DisplayWebLinksPlugin.tsx';
import { useDisplayTables } from '../../plugins/tables/DisplayTablesPlugin.tsx';
import { useTextApi } from '../../plugins/textApi/TextApiPlugin.tsx';
import { useDisplayTextBlockTypes } from '../../plugins/textBlockTypes/TextBlockTypesPlugin.tsx';
import { usePlaceholder } from '../../plugins/visuals/PlaceholderPlugin.tsx';
import { useStyles } from '../../plugins/visuals/StylesPlugin.tsx';
import { useWrapper } from '../../plugins/visuals/WrapperPlugin.tsx';
import { GuidelinesEditorLimitations } from './guidelinesEditorLimitations.ts';

export const useComposedViewer = () =>
  createEditorWithPlugins(
    getBaseEditor(),
    usePluginWithParams(usePlaceholder, 'There is no content here'),
    useWrapper,
    useCompositionModeAdjustments,
    useFocus,
    usePluginWithParams(useApiLimitations, GuidelinesEditorLimitations),
    usePluginWithParams(useKeyboardShortcuts, textKeyCommandMap),
    useTextApi,
    useClipboard,
    useStyles,
    useDisplayTextBlockTypes,
    useDisplayInlineStyles,
    useDisplayLinks,
    useDisplayWebLinks,
    useDisplayContentLinks,
    useDisplayAssetLinks,
    useDisplayEmailLinks,
    useDisplayPhoneLinks,
    useDisplayImages,
    useDisplayTables,
    useSpellCheck,
    useExternalUpdate,
    useReadonly,
  );

export type GuidelinesViewerProps = EditorProps<typeof useComposedViewer>;

export const GuidelinesViewer: React.FC<GuidelinesViewerProps> = memo((props) => {
  const { ComposedEditor } = useComposedViewer();

  return (
    <ComposedEditor
      dataUiElement={props.dataUiElement ?? DataUiElement.RichTextViewer}
      tabIndex={props.tabIndex ?? -1}
      {...props}
    />
  );
});

GuidelinesViewer.displayName = 'GuidelinesViewer';
