import { memoize } from '@kontent-ai/memoization';
import { isEmptyOrWhitespace } from '../../../../_shared/utils/stringUtils.ts';
import {
  ElementType,
  TypeElementType,
} from '../../../contentInventory/content/models/ContentItemElementType.ts';
import { IBaseTypeElementData } from '../models/elements/types/TypeElementData.ts';

export const elementTypeNameMap: { readonly [key in TypeElementType]: string } = {
  [ElementType.Asset]: 'Asset',
  [ElementType.Subpages]: 'Subpages',
  [ElementType.ContentTypeSnippet]: 'Content type snippet',
  [ElementType.DateTime]: 'Date & time',
  [ElementType.Guidelines]: 'Guidelines',
  [ElementType.LinkedItems]: 'Linked items',
  [ElementType.MultipleChoice]: 'Multiple choice',
  [ElementType.Number]: 'Number',
  [ElementType.Custom]: 'Custom element',
  [ElementType.RichText]: 'Rich text',
  [ElementType.Taxonomy]: 'Taxonomy',
  [ElementType.Text]: 'Text',
  [ElementType.UrlSlug]: 'URL slug',
};

export const withDefaultNameIfNotSet = (element: IBaseTypeElementData): IBaseTypeElementData => {
  if (!isEmptyOrWhitespace(element.name)) {
    return element;
  }

  const typeDisplayName = elementTypeNameMap[element.type].toLowerCase();
  return {
    ...element,
    name: `Untitled ${typeDisplayName}`,
  };
};

export const getTypeElementsInContentGroup = memoize.weak(
  (
    contentElements: ReadonlyArray<IBaseTypeElementData>,
    contentGroupId: Uuid | null,
  ): ReadonlyArray<IBaseTypeElementData> => {
    return contentGroupId
      ? contentElements.filter((element) => element.contentGroupId === contentGroupId)
      : contentElements;
  },
);

export const isTypeElementNew = (typeElementData: IBaseTypeElementData): boolean =>
  typeElementData.codename === null;

export const isTriggerElement = (
  elementId: Uuid,
  typeElements: ReadonlyArray<IBaseTypeElementData>,
): boolean =>
  typeElements.some(
    (element) => element.condition?.isActive && element.condition?.trigger?.elementId === elementId,
  );
