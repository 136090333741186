import React, { useCallback } from 'react';
import { EnsureValidState } from '../../../_shared/components/EnsureValidState.tsx';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { calendarArrangementStorage } from '../../../localStorages/calendarArrangementStorage.ts';
import { calendarArrangementSelected } from '../../contentPlanning/calendar/actions/calendarActions.ts';

export const EnsureSelectedArrangementForCalendar: React.FC<{
  readonly children: React.ReactChild;
}> = ({ children }) => {
  const selectedArrangement = useSelector((s) => s.calendarApp.selectedArrangement);
  const arrangementInLocalStorage = calendarArrangementStorage.load();
  const dispatch = useDispatch();

  const onRouteEntered = useCallback(
    () => dispatch(calendarArrangementSelected(arrangementInLocalStorage)),
    [arrangementInLocalStorage],
  );

  const isStateEnsured = selectedArrangement === arrangementInLocalStorage;

  return (
    <EnsureValidState
      isStateEnsured={isStateEnsured}
      onRouteEntered={isStateEnsured ? undefined : onRouteEntered}
    >
      {children}
    </EnsureValidState>
  );
};
