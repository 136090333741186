import { Collection } from '@kontent-ai/utils';
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { Loader } from '../../../../../_shared/components/Loader.tsx';
import { AuthorizedSection } from '../../../../../_shared/components/routing/AuthorizedSection.tsx';
import { ApiKeyDetailAccessDeniedRouteSegment } from '../../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ApiKeyType } from '../../../../../_shared/models/ApiKeyType.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { getCurrentProjectContainer } from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { isProjectManagerInAnyEnvironment } from '../../../root/selectors/isProjectManagerInAnyEnvironment.ts';
import { apiKeyDetailsAlertDismissed } from '../../actions/apiKeysActions.ts';
import { createApiKey, initNewApiKey } from '../../actions/thunkApiKeysActions.ts';
import { ApiKey, emptyApiKey } from '../../models/ApiKey.ts';
import {
  AllEnvironmentsTagId,
  getAllEnvironmentOptions,
} from '../../selectors/getSelectedEnvironmentOptions.ts';
import { getMapiKeyCapabilitiesFromPermissions } from '../../utils/getMapiKeyCapabilitiesFromPermissions.ts';
import { MapiKeyFormShape } from '../../validation/mapiKeyValidationConfig.ts';
import { MapiKeyDetailForm } from './MapiKeyDetailForm.tsx';

export const NewMapiKeyDetail: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentProjectContainer = useSelector(getCurrentProjectContainer);
  const loadingStatus = useSelector((state) => state.apiKeysApp.detailLoadingStatus);
  const actionStatus = useSelector((state) => state.apiKeysApp.detailActionStatus);
  const allEnvironmentOptions = useSelector((state) =>
    getAllEnvironmentOptions(state, ApiKeyType.MAPI),
  );
  const isProjectManager = useSelector(isProjectManagerInAnyEnvironment);
  const projectContainerActiveUsersById = useSelector(
    (state) => state.data.projectContainerActiveUsers.byId,
  );

  const projectContainerActiveUsers = useMemo(
    () => Collection.getValues(projectContainerActiveUsersById),
    [projectContainerActiveUsersById],
  );

  const onSave = (mapiKeyFormShape: MapiKeyFormShape, isFromUnsavedChangesDialog: boolean) => {
    const hasAccessToAllEnvironments = mapiKeyFormShape.environments.includes(AllEnvironmentsTagId);
    const apiKey: ApiKey = {
      ...emptyApiKey,
      name: mapiKeyFormShape.name,
      environments: hasAccessToAllEnvironments ? [] : mapiKeyFormShape.environments,
      expiresAt: mapiKeyFormShape.expiresAt.value,
      hasAccessToAllEnvironments,
      mapiKeyCapabilities: getMapiKeyCapabilitiesFromPermissions(mapiKeyFormShape.permissions),
      sharedWithUsers: mapiKeyFormShape.sharedWithUsers,
      type: ApiKeyType.MAPI,
    };

    return dispatch(
      createApiKey(
        apiKey,
        isFromUnsavedChangesDialog ? undefined : history,
        currentProjectContainer.projectContainerId,
      ),
    );
  };

  useEffect(() => {
    dispatch(initNewApiKey(ApiKeyType.MAPI));
  }, []);

  if (loadingStatus !== LoadingStatus.Loaded) {
    return <Loader />;
  }

  return (
    <AuthorizedSection
      appName={ApiKeyDetailAccessDeniedRouteSegment}
      isAuthorized={isProjectManager}
      projectId={currentProjectContainer.masterEnvironmentId}
      projectContainerId={currentProjectContainer.projectContainerId}
    >
      <MapiKeyDetailForm
        allEnvironmentOptions={allEnvironmentOptions}
        apiKey={getNewApiKey()}
        apiKeyActionStatus={actionStatus}
        isProjectManager={isProjectManager}
        projectContainerActiveUsers={projectContainerActiveUsers}
        onAlertDismiss={() => dispatch(apiKeyDetailsAlertDismissed())}
        onSave={onSave}
      />
    </AuthorizedSection>
  );
};

NewMapiKeyDetail.displayName = 'NewMapiKeyDetail';

const getNewApiKey = (): ApiKey => ({
  ...emptyApiKey,
  type: ApiKeyType.MAPI,
});
