export const Workflow_Editing_CodenameChanged = 'Workflow_Editing_CodenameChanged';
export const Workflow_Editing_ContentTypesScopeChanged =
  'Workflow_Editing_ContentTypesScopeChanged';
export const Workflow_Editing_CollectionsScopeChanged = 'Workflow_Editing_CollectionsScopeChanged';
export const Workflow_Editing_EmptyTransitionsSaveFailed =
  'Workflow_Editing_EmptyTransitionsSaveFailed';
export const Workflow_Editing_Left = 'Workflow_Editing_Left';
export const Workflow_Editing_NameChanged = 'Workflow_Editing_NameChanged';
export const Workflow_Editing_StepCodenameChanged = 'Workflow_Editing_StepCodenameChanged';
export const Workflow_Editing_StepDraggingStarted = 'Workflow_Editing_StepDraggingStarted';
export const Workflow_Editing_StepDraggingFinished = 'Workflow_Editing_StepDraggingFinished';
export const Workflow_Editing_StepMoved = 'Workflow_Editing_StepMoved';
export const Workflow_Editing_WorkflowSaveFailed = 'Workflow_Editing_WorkflowSaveFailed';
export const Workflow_Editing_WorkflowSaveFinished = 'Workflow_Editing_WorkflowSaveFinished';
export const Workflow_Editing_WorkflowSaveStarted = 'Workflow_Editing_WorkflowSaveStarted';
export const Workflow_Editing_WorkflowStepUnsavedChangesUpdated =
  'Workflow_Editing_WorkflowStepUnsavedChangesUpdated';
export const Workflow_Editing_StepsUsageLoaded = 'Workflow_Editing_StepsUsageLoaded';
export const Workflow_Editing_ScopeCreated = 'Workflow_Editing_ScopeCreated';
export const Workflow_Editing_ScopeDeleted = 'Workflow_Editing_ScopeDeleted';
export const Workflow_InitializeCreating_Started = 'Workflow_InitializeCreating_Started';
export const Workflow_InitializeCreating_Finished = 'Workflow_InitializeCreating_Finished';
export const Workflow_InitializeEditing_Finished = 'Workflow_InitializeEditing_Finished';
export const Workflow_InitializeEditing_Started = 'Workflow_InitializeEditing_Started';
export const Workflow_StepCreator_Initiated = 'Workflow_StepCreator_Initiated';
export const Workflow_StepEditor_Cancel = 'Workflow_StepEditor_Cancel';
export const Workflow_StepCreator_Cancel = 'Workflow_StepCreator_Cancel';
export const Workflow_StepEditor_Delete = 'Workflow_StepEditor_Delete';
export const Workflow_StepEditor_Initiated = 'Workflow_StepEditor_Initiated';
export const Workflow_StepEditor_Save = 'Workflow_StepEditor_Save';
export const Workflow_ArchivedStepEditor_Save = 'Workflow_ArchivedStepEditor_Save';
export const Workflow_PublishedStepEditor_Save = 'Workflow_PublishedStepEditor_Save';
export const Workflow_ServerValidation_ReceivedGlobalErrors =
  'Workflow_ServerValidation_ReceivedGlobalErrors';
export const Workflow_ServerValidation_ReceivedStepErrors =
  'Workflow_ServerValidation_ReceivedStepErrors';
export const Workflows_ListingInit_Started = 'Workflows_ListingInit_Started';
export const Workflows_ListingInit_Finished = 'Workflows_ListingInit_Finished';
export const Workflows_ListingInit_Left = 'Workflows_ListingInit_Left';
export const Workflow_Delete_Started = 'Workflow_Delete_Started';
export const Workflow_Delete_Finished = 'Workflow_Delete_Finished';
export const Workflow_Delete_Failed = 'Workflow_Delete_Failed';
export const Workflow_Restore_Started = 'Workflow_Restore_Started';
export const Workflow_Restore_Finished = 'Workflow_Restore_Finished';
export const Workflow_Restore_Failed = 'Workflow_Restore_Failed';
