import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { ContentItemNotifications } from '../../../../../itemEditor/features/ContentItemEditing/containers/ContentItemNotifications.tsx';
import { FloatingEditorPosition } from '../../../../types/floatingEditorPosition.ts';
import { CloseAction } from './CloseAction.tsx';
import { FloatingEditorHeaderActions } from './FloatingEditorHeaderActions.tsx';
import { MoreActions } from './MoreActions/MoreActions.tsx';
import { OpenInEditorAction } from './OpenInEditorAction.tsx';
import { TogglePositionAction } from './TogglePositionAction.tsx';

interface IFloatingEditorHeaderProps {
  readonly onTogglePosition: () => void;
  readonly position: FloatingEditorPosition;
}

const propTypes: PropTypesShape<IFloatingEditorHeaderProps> = {
  onTogglePosition: PropTypes.func.isRequired,
  position: PropTypes.string.isRequired,
};

const FloatingEditorHeader: React.FC<IFloatingEditorHeaderProps> = ({
  onTogglePosition,
  position,
}) => (
  <div className="floating-editor__header">
    <FloatingEditorHeaderActions>
      <div className="floating-editor__header-actions-group">
        <TogglePositionAction onTogglePosition={onTogglePosition} position={position} />
      </div>
      <div className="floating-editor__header-actions-group">
        <OpenInEditorAction />
        <MoreActions />
        <CloseAction />
      </div>
    </FloatingEditorHeaderActions>
    <ContentItemNotifications useCompactView />
  </div>
);

FloatingEditorHeader.displayName = 'FloatingEditorHeader';
FloatingEditorHeader.propTypes = propTypes;

const FloatingEditorHeaderMemo = memo(FloatingEditorHeader);

export { FloatingEditorHeaderMemo as FloatingEditorHeader };
