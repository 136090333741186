import React from 'react';
import { TypeElement } from '../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import {
  isCustomTypeElement,
  isGuidelinesTypeElement,
} from '../../../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { ICompiledContentItemElementData } from '../../../models/contentItemElements/ICompiledContentItemElement.ts';
import { isCustomElement } from '../../../models/contentItemElements/compiledItemElementTypeGuards.ts';
import { getElementById } from '../../../stores/utils/contentItemElementsUtils.ts';
import { getElementValueForCustomElement } from '../../ContentItemEditing/utils/getElementValueForCustomElement.ts';
import { expandedItemElementComponentLocator } from '../utils/expandedItemElementComponentLocator.ts';
import { ExpandedCustomElement } from './ExpandedCustomElement.tsx';

type ExpandedItemElementProps = {
  readonly elements: ReadonlyArray<ICompiledContentItemElementData>;
  readonly typeElement: TypeElement;
  readonly typeElements: ReadonlyArray<TypeElement>;
};

export const ExpandedItemElement: React.FC<ExpandedItemElementProps> = ({
  elements,
  typeElement,
  typeElements,
}) => {
  if (isGuidelinesTypeElement(typeElement)) {
    const GuidelinesComponent = expandedItemElementComponentLocator.getGuidelines();
    return <GuidelinesComponent key={typeElement.elementId} typeElement={typeElement} />;
  }

  const element = getElementById(typeElement.elementId, elements);
  if (!element) {
    return null;
  }

  if (isCustomTypeElement(typeElement)) {
    if (!isCustomElement(element)) {
      return null;
    }

    return (
      <ExpandedCustomElement
        key={typeElement.elementId}
        className="expanded-item__element"
        elementData={element}
        getElementValue={(elementCodename) =>
          getElementValueForCustomElement(
            elements,
            typeElement.elementId,
            typeElements,
            elementCodename,
          )
        }
        typeElement={typeElement}
      />
    );
  }

  const ElementComponent = expandedItemElementComponentLocator.getComponent(typeElement.type);
  return (
    <ElementComponent key={typeElement.elementId} elementData={element} typeElement={typeElement} />
  );
};

ExpandedItemElement.displayName = 'ExpandedItemElement';
