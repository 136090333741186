import { UseTransitionProps, animated, config, useTransition } from '@react-spring/web';
import Tippy, { TippyProps } from '@tippyjs/react';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useDynamicDebounce } from '../../hooks/useDynamicDebounce.ts';
import { Box } from '../../layout/Box/Box.tsx';
import { colorTextDefaultInverse } from '../../tokens/decision/colors.ts';
import { tooltipZIndex } from '../../tokens/decision/zIndex.ts';
import { BorderRadius } from '../../tokens/quarks/border.ts';
import { BaseColor } from '../../tokens/quarks/colors.ts';
import { BoxShadow } from '../../tokens/quarks/shadow.ts';
import { Spacing, gridUnit } from '../../tokens/quarks/spacing.ts';
import { LetterSpacing, Typography } from '../../tokens/quarks/typography.ts';
import { px } from '../../tokens/utils/utils.ts';
import { Placement, placements } from '../../types/placement.ts';
import { getDataUiComponentAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { CLPropTypes } from '../../validators/propTypes.ts';
import { ShortcutSet } from '../ShortcutSet/ShortcutSet.tsx';
import { PortalContainerContext } from '../_contexts/PortalContainerContext.tsx';
import { Arrow } from './components/Arrow.tsx';

const defaultMaxWidthGU = 50;
const defaultMaxWidth = defaultMaxWidthGU * gridUnit;
const defaultShowDelay = 700;
const defaultHideDelay = 20;
const defaultDelay = [defaultShowDelay, defaultHideDelay] as [number, number];
const defaultOffset = [0, Spacing.S] as [number, number];

export type TooltipTippyOptions = Pick<
  TippyProps,
  'appendTo' | 'offset' | 'onBeforeUpdate' | 'popperOptions' | 'zIndex' | 'arrow'
>;

interface ITooltipTextProps {
  readonly tooltipText: string;
}

const TooltipText: React.FC<ITooltipTextProps> = ({ tooltipText }) => (
  <Box
    component="span"
    display="inline-flex"
    alignItems="center"
    flexGrow={1}
    flexShrink={1}
    flexBasis="auto"
    overflowWrap="anywhere"
    textAlign="left"
    typography={Typography.TitleMedium}
    letterSpacing={LetterSpacing.L}
    color={colorTextDefaultInverse}
    {...getDataUiComponentAttribute(TooltipText)}
  >
    {tooltipText}
  </Box>
);

TooltipText.displayName = 'TooltipText';

export interface ITooltipProps {
  readonly delay?: [showDelay: number, hideDelay: number];
  readonly inlinePositioning?: boolean;
  readonly tooltipText: string | null | undefined;
  readonly placement: Placement | null;
  readonly children: React.ReactElement;
  readonly shortcuts?: string | null;
  /** Cannot be greater than default maximum width */
  readonly maxGridUnitsWidth?: number | null;
  readonly tippyOptions?: TooltipTippyOptions;
  readonly visible?: boolean;
}

export const tooltipPropTypes: PropTypeMap<ITooltipProps> = {
  delay: PropTypes.any,
  inlinePositioning: PropTypes.bool,
  tooltipText: PropTypes.string,
  placement: PropTypes.oneOf(placements).isRequired,
  children: PropTypes.element.isRequired,
  shortcuts: PropTypes.string,
  maxGridUnitsWidth: CLPropTypes.multiple(
    PropTypes.number,
    (props: ITooltipProps, propName, componentName) => {
      if (!props.maxGridUnitsWidth) {
        return null;
      }
      if (props.maxGridUnitsWidth > defaultMaxWidthGU) {
        return new Error(
          `[${propName}] cannot be greater than default maximum width (${defaultMaxWidthGU}) in component ${componentName}`,
        );
      }
      return null;
    },
  ),
  tippyOptions: PropTypes.object,
  visible: PropTypes.bool,
};

const defaultPlacement: Placement = 'top';

const transformAnimations = {
  fromTop: {
    initial: `translateY(${px(-1 * Spacing.XL)})`,
    animated: 'translateY(0px)',
  },
  fromBottom: {
    initial: `translateY(${px(Spacing.XL)})`,
    animated: 'translateY(0px)',
  },
  fromLeft: {
    initial: `translateX(${px(-1 * Spacing.XL)})`,
    animated: 'translateX(0px)',
  },
  fromRight: {
    initial: `translateX(${px(Spacing.XL)})`,
    animated: 'translateX(0px)',
  },
};

const tooltipPlacementToTransformConfig: Record<
  Placement,
  (typeof transformAnimations)['fromBottom']
> = {
  bottom: transformAnimations.fromTop,
  'bottom-end': transformAnimations.fromTop,
  'bottom-start': transformAnimations.fromTop,
  top: transformAnimations.fromBottom,
  'top-end': transformAnimations.fromBottom,
  'top-start': transformAnimations.fromBottom,
  left: transformAnimations.fromRight,
  'left-end': transformAnimations.fromRight,
  'left-start': transformAnimations.fromRight,
  right: transformAnimations.fromLeft,
  'right-end': transformAnimations.fromLeft,
  'right-start': transformAnimations.fromLeft,
};

const getTransitionProps = (
  placement: Placement | null = defaultPlacement,
): UseTransitionProps => ({
  from: {
    opacity: 0,
    ...(placement && {
      transform: tooltipPlacementToTransformConfig[placement].initial,
    }),
  },
  enter: {
    opacity: 1,
    ...(placement && {
      transform: tooltipPlacementToTransformConfig[placement].animated,
    }),
  },
  leave: {
    opacity: 0,
    ...(placement && {
      transform: tooltipPlacementToTransformConfig[placement].initial,
    }),
  },
  delay: 100,
  config: config.default,
});

const TooltipTransition: React.FC<
  React.PropsWithChildren<{
    readonly isVisible: boolean;
    readonly placement: Placement;
  }>
> = ({ placement, isVisible, children }) => {
  const transitionProps = getTransitionProps(placement);
  const transition = useTransition(isVisible, transitionProps);

  return transition(
    (style, item) =>
      item && (
        <animated.div
          style={style}
          css={`
        display: flex;
        width: max-content;
      `}
        >
          {children}
        </animated.div>
      ),
  );
};

export const defaultTooltipTippyOptions = {
  arrow: true,
  zIndex: tooltipZIndex,
  offset: defaultOffset,
} satisfies TooltipTippyOptions;

export const Tooltip: React.FC<React.PropsWithChildren<ITooltipProps>> = ({
  delay = defaultDelay,
  tooltipText,
  shortcuts,
  placement,
  children,
  maxGridUnitsWidth,
  tippyOptions,
  visible: visibleFromProps,
}) => {
  // RTE needs the child DOM nodes structure stable when tooltip changes from valid tooltip to empty one (thus it must be rendered, for now,
  // even when there is no content and rendering only children would normally suffice) E.g. dropdown menus in add link button in rich text
  // when tooltip is being hidden while the menu is open suffers from such DOM change
  const hasContent = !!tooltipText;

  const getDelayForVisibleProp = <TValue extends ITooltipProps['visible']>(
    current: TValue,
    newValue: TValue,
  ): number => {
    if (!current !== !newValue) {
      return newValue ? delay[0] : delay[1];
    }
    return 0;
  };

  const isTooltipVisible = useDynamicDebounce(visibleFromProps, getDelayForVisibleProp);
  const [isCurrentlyVisible, setIsCurrentlyVisible] = React.useState(false);

  const { portalContainerRef } = useContext(PortalContainerContext);

  const useTippyOptions: TippyProps = {
    ...defaultTooltipTippyOptions,
    appendTo: portalContainerRef.current ?? document.body,
    ...tippyOptions,
  };

  return (
    <Tippy
      {...useTippyOptions}
      allowHTML={!!shortcuts}
      delay={delay}
      disabled={!hasContent}
      placement={placement ?? undefined}
      visible={isTooltipVisible}
      onShow={() => setIsCurrentlyVisible(true)}
      onHide={() => setIsCurrentlyVisible(false)}
      render={(attrs) => {
        const dataPlacement = attrs['data-placement'] as Placement;
        return (
          tooltipText &&
          dataPlacement && (
            <TooltipTransition
              key={dataPlacement}
              placement={dataPlacement}
              isVisible={isCurrentlyVisible}
            >
              <Box
                position="relative"
                flexGrow={1}
                flexShrink={0}
                flexBasis="auto"
                display="flex"
                gap={Spacing.XS}
                backgroundColor={BaseColor.Gray100}
                borderRadius={BorderRadius.S}
                boxShadow={BoxShadow.L}
                maxWidth={(maxGridUnitsWidth && maxGridUnitsWidth * gridUnit) ?? defaultMaxWidth}
                paddingX={Spacing.S}
                paddingY={Spacing.XS}
                whiteSpace="pre-wrap"
                pointerEvents="none"
                {...getDataUiComponentAttribute(Tooltip)}
                {...attrs}
              >
                <TooltipText tooltipText={tooltipText} />
                {shortcuts && <ShortcutSet shortcuts={shortcuts} shortcutStyle="inverse" />}
                <Arrow placement={dataPlacement} />
              </Box>
            </TooltipTransition>
          )
        );
      }}
    >
      {children}
    </Tippy>
  );
};

Tooltip.displayName = 'Tooltip';
Tooltip.propTypes = tooltipPropTypes;
