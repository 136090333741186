import React from 'react';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import {
  DataUiRteAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  ISpecificButtonProps,
  RTEToolbarIconButton,
} from '../../toolbars/components/RTEToolbarButton.tsx';

export const ClearFormattingButton: React.FC<ISpecificButtonProps> = (props) => (
  <RTEToolbarIconButton
    {...props}
    iconName={IconName.TWithCross}
    tooltipPlacement={props.tooltipPlacement ?? 'bottom-start'}
    tooltipText={props.tooltipText ?? 'Clear formatting'}
    {...getDataUiActionAttribute(DataUiRteAction.ClearFormatting)}
  />
);

ClearFormattingButton.displayName = 'ClearFormatting';
