import React from 'react';
import { FormGroup } from '../../../../_shared/components/input/FormGroup.tsx';
import { ValidatedInput } from '../../../../_shared/components/input/ValidatedInput.tsx';
import { ValidationConstants } from '../../../../_shared/constants/validationConstants.ts';
import { HookFormProps } from '../../../../_shared/types/hookFormProps.ts';
import { DataUiInput } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ISubscription } from '../../../../data/models/subscriptions/Subscription.ts';
import { ICloneProjectFormShape } from '../../models/ICloneProjectFormShape.type.ts';
import { CopyProjectDocumentationLinksExtended } from './CopyProjectDocumentationLinksExtended.tsx';
import { IncludeContentSelector } from './IncludeContentSelector.tsx';
import { ProjectLocationSelectorWrapper } from './ProjectLocationSelectorWrapper.tsx';
import { SubscriptionSelectorWrapper } from './SubscriptionSelectorWrapper.tsx';

type Props = {
  readonly activeSubscriptions: ReadonlyArray<ISubscription>;
  readonly destinationSubscriptionId: Uuid;
  readonly formProps: HookFormProps<ICloneProjectFormShape>;
  readonly onSubmit: (() => void) | undefined;
};

export const CloneProjectForm: React.FC<Props> = ({
  activeSubscriptions,
  destinationSubscriptionId,
  formProps,
  onSubmit,
}) => (
  <form className="form" onSubmit={onSubmit}>
    <FormGroup>
      <ValidatedInput
        name="projectName"
        label="Project name"
        maxLength={ValidationConstants.ProjectNameMaxLength}
        dataUiInputName={DataUiInput.ProjectName}
        autoFocus
        formProps={formProps}
      />
    </FormGroup>

    <FormGroup>
      <SubscriptionSelectorWrapper<ICloneProjectFormShape>
        activeSubscriptions={activeSubscriptions}
        formProps={formProps}
        name="destinationSubscriptionId"
      />
    </FormGroup>

    <FormGroup>
      <ProjectLocationSelectorWrapper<ICloneProjectFormShape>
        destinationSubscriptionId={destinationSubscriptionId}
        formProps={formProps}
        name="destinationLocationId"
      />
    </FormGroup>
    <FormGroup isCompact>
      <IncludeContentSelector<ICloneProjectFormShape> formProps={formProps} name="includeContent" />
      <CopyProjectDocumentationLinksExtended />
    </FormGroup>
  </form>
);

CloneProjectForm.displayName = 'CloneProjectForm';
