import PropTypes from 'prop-types';
import React from 'react';
import { Codename } from '../../../../../../../_shared/components/Codename/Codename.tsx';
import { gridUnit } from '../../../../../../../_shared/constants/styleConstants.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type CodenameFormProps = {
  readonly codename: string;
  readonly disabledEditingMessage?: string | null;
  readonly footnoteMessage?: string | null;
  readonly friendlyWarningMessage?: string;
  readonly isCodenameUnique: boolean;
  readonly isBeingSaved?: boolean;
  readonly onCodenameChange?: (codename: string) => void;
  readonly onCopied?: () => void;
  readonly onCopyCompleted?: () => void;
  readonly onDiscardChanges?: () => void;
  readonly onEdited?: () => void;
};

const propTypes: PropTypesShape<CodenameFormProps> = {
  codename: PropTypes.string.isRequired,
  disabledEditingMessage: PropTypes.string,
  footnoteMessage: PropTypes.string,
  friendlyWarningMessage: PropTypes.string,
  isCodenameUnique: PropTypes.bool.isRequired,
  isBeingSaved: PropTypes.bool,
  onCodenameChange: PropTypes.func,
  onCopied: PropTypes.func,
  onCopyCompleted: PropTypes.func,
  onDiscardChanges: PropTypes.func,
  onEdited: PropTypes.func,
};

export const codenameFormWidth = 37 * gridUnit;

export const CodenameForm: React.FC<CodenameFormProps> = (props) => (
  <div
    className="form__group form__group--clipboard form__group--medium-width"
    style={{
      width: codenameFormWidth,
    }}
    {...getDataUiElementAttribute(DataUiElement.CodenameForm)}
  >
    <Codename
      {...props}
      hideTitle
      isCodenameUnique={() => props.isCodenameUnique}
      isEditable
      saveCodename
    />
  </div>
);

CodenameForm.displayName = 'CodenameForm';
CodenameForm.propTypes = propTypes;
