import { Box } from '@kontent-ai/component-library/Box';
import { Paper, PaperLevel } from '@kontent-ai/component-library/Paper';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { Indent } from '../../../../../_shared/components/Indent.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IForwardedRefProps, forwardRef } from '../../../../../_shared/utils/forwardedRefProps.tsx';
import { ITaxonomyTerm } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyTerm.ts';
import { AssetFolderBreadcrumbItem } from './AssetFolderBreadcrumbItem.tsx';
import { AssetFolderHomeBreadcrumbItem } from './AssetFolderHomeBreadcrumbItem.tsx';

interface IAssetFoldersBreadcrumbsTooltipOwnProps {
  readonly onFolderOpen: (folderId: Uuid) => void;
  readonly onFolderReset: () => void;
  readonly openedFolders: Immutable.List<ITaxonomyTerm>;
}

type Props = IAssetFoldersBreadcrumbsTooltipOwnProps & IForwardedRefProps<HTMLDivElement>;

const AssetFoldersBreadcrumbsTooltip: React.FC<Props> = ({
  forwardedRef,
  onFolderOpen,
  onFolderReset,
  openedFolders,
}) => {
  const folderNodes = openedFolders.toArray().map((folder, index): JSX.Element => {
    const isLastFolder = index === openedFolders.count() - 1;
    const onClick = (event: React.MouseEvent) => {
      event.stopPropagation();
      onFolderOpen(folder.id);
    };

    return (
      <div key={folder.id} className="asset-breadcrumbs__clickable-tooltip-item" onClick={onClick}>
        <Indent level={index + 1}>
          <AssetFolderBreadcrumbItem
            folder={folder}
            isInTooltip
            isLast={isLastFolder}
            onClick={onClick}
          />
        </Indent>
      </div>
    );
  });

  return (
    <Paper
      ref={forwardedRef}
      level={PaperLevel.Popout}
      {...getDataUiElementAttribute(DataUiElement.AssetFolderBreadcrumbsPopover)}
    >
      <Box paddingY={Spacing.S}>
        <AssetFolderHomeBreadcrumbItem isInTooltip onClick={onFolderReset} />
        {folderNodes}
      </Box>
    </Paper>
  );
};

AssetFoldersBreadcrumbsTooltip.displayName = 'AssetFoldersBreadcrumbsTooltip';

const AssetFoldersBreadcrumbsTooltipFRC = forwardRef(AssetFoldersBreadcrumbsTooltip);
export { AssetFoldersBreadcrumbsTooltipFRC as AssetFoldersBreadcrumbsTooltip };
