import { useHover } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import PropTypes from 'prop-types';
import React, { MouseEventHandler, PropsWithChildren } from 'react';
import { useOurFocusRing } from '../../../hooks/useOurFocusRing.ts';
import { getDataUiComponentAttribute } from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { Tooltip } from '../../Tooltip/Tooltip.tsx';
import { TooltipPropsExtension, tooltipExtensionPropTypes } from '../../_utils/propPrefabs.ts';
import { QuinaryButtonBadge } from './QuinaryButtonBadge.tsx';
import { QuinaryButtonChevron } from './QuinaryButtonChevron.tsx';
import { QuinaryButtonIcon } from './QuinaryButtonIcon.tsx';
import { QuinaryButtonStyle, quinaryButtonStyleValues } from './QuinaryButtonStyle.ts';
import { QuinaryButtonStyleContext } from './QuinaryButtonStyleContext.tsx';
import { StyledQuinaryButtonWrapper } from './StyledQuinaryButtonWrapper.tsx';
import { StyledQuinaryButton } from './components/StyledQuinaryButton.tsx';

interface IQuinaryButtonProps extends TooltipPropsExtension {
  readonly activated?: boolean;
  readonly autoFocus?: boolean;
  readonly buttonStyle?: QuinaryButtonStyle;
  readonly disabled?: boolean;
  readonly disableTabulator?: boolean;
  readonly onClick?: MouseEventHandler<HTMLButtonElement>;
  readonly tooltipText: string;
  readonly ariaLabel?: string;
}

const propTypes: PropTypeMap<IQuinaryButtonProps> = {
  ...tooltipExtensionPropTypes,
  activated: PropTypes.bool,
  autoFocus: PropTypes.bool,
  buttonStyle: PropTypes.oneOf(quinaryButtonStyleValues),
  disabled: PropTypes.bool,
  disableTabulator: PropTypes.bool,
  onClick: PropTypes.func,
  tooltipText: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
};

const QuinaryButtonWithRef = React.forwardRef<
  HTMLButtonElement,
  PropsWithChildren<IQuinaryButtonProps>
>(
  (
    {
      activated,
      autoFocus,
      buttonStyle = 'default',
      children,
      disabled,
      disableTabulator,
      onClick,
      tooltipMaxGridUnitsWidth,
      tooltipPlacement = 'top',
      tooltipShortcuts,
      tooltipText,
      ariaLabel,
      ...props
    },
    ref,
  ) => {
    const { isFocusVisible, focusProps } = useOurFocusRing();
    const { hoverProps, isHovered } = useHover({});

    return (
      <QuinaryButtonStyleContext.Provider value={buttonStyle}>
        <Tooltip
          maxGridUnitsWidth={tooltipMaxGridUnitsWidth}
          placement={tooltipPlacement}
          shortcuts={tooltipShortcuts}
          tooltipText={activated ? '' : tooltipText}
          visible={isFocusVisible || isHovered}
        >
          <StyledQuinaryButtonWrapper>
            <StyledQuinaryButton
              autoFocus={autoFocus}
              onClick={onClick}
              disabled={disabled}
              ref={ref}
              isFocusVisible={isFocusVisible}
              buttonStyle={buttonStyle}
              tabIndex={disableTabulator ? -1 : undefined}
              activated={activated}
              type="button"
              aria-label={ariaLabel}
              {...mergeProps(focusProps, hoverProps, props)}
              {...getDataUiComponentAttribute(QuinaryButton)}
            >
              {children}
            </StyledQuinaryButton>
          </StyledQuinaryButtonWrapper>
        </Tooltip>
      </QuinaryButtonStyleContext.Provider>
    );
  },
);

QuinaryButtonWithRef.displayName = 'QuinaryButton';
QuinaryButtonWithRef.propTypes = propTypes;

const quinaryButtonComposition = {
  Badge: QuinaryButtonBadge,
  Icon: QuinaryButtonIcon,
  Chevron: QuinaryButtonChevron,
} as const;

export const QuinaryButton = Object.assign(QuinaryButtonWithRef, quinaryButtonComposition);
