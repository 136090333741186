import React from 'react';
import { createDropDown } from '../../../../../_shared/uiComponents/DropDown/DropDown.tsx';
import { DropDownOption } from '../../../../../_shared/uiComponents/DropDown/DropDownOption.tsx';
import { DropDownOptionName } from '../../../../../_shared/uiComponents/DropDown/DropDownOptionName.tsx';
import { IDropdownTippyOptions } from '../../../../../_shared/uiComponents/DropDown/dropDownTippyOptions.ts';
import {
  DataUiCollection,
  ObjectWithDataAttribute,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getToolbarButtonTooltipText } from '../../toolbars/utils/toolbarUtils.ts';
import { LinkButton } from './buttons/LinkButton.tsx';

interface ICreateLinkProps {
  readonly isAddLinkAllowed: boolean;
  readonly options: ReadonlyArray<CreateLinkOption>;
}

export type CreateLinkOption = {
  readonly name: string;
  readonly onMouseDown?: (event: any) => void;
  readonly dataAttributes: ObjectWithDataAttribute;
  readonly isDisabled?: boolean;
  readonly tooltipText?: string;
};

const tippyOptions: IDropdownTippyOptions = {
  placement: 'bottom-start',
  popperOptions: {
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-start'],
        },
      },
    ],
  },
};

const CreateLinkDropDown = createDropDown<CreateLinkOption>();
const getLinkButtonTooltipText = (isAddLinkAllowed: boolean, isOpen: boolean) => {
  if (isOpen) {
    return '';
  }
  return isAddLinkAllowed ? 'Insert link' : getToolbarButtonTooltipText('Link', !isAddLinkAllowed);
};

export const CreateLink: React.FC<ICreateLinkProps> = (props) => {
  const { isAddLinkAllowed, options } = props;

  return (
    <CreateLinkDropDown
      optionListDataUiAttributes={getDataUiCollectionAttribute(DataUiCollection.RteLinkMenuOptions)}
      options={options}
      renderOption={(_hideMenu, option) => (
        <DropDownOption
          key={option.name}
          onMouseDown={option.onMouseDown}
          dataUiAttributes={option.dataAttributes}
          isDisabled={option.isDisabled}
          tooltipText={option.tooltipText}
        >
          <DropDownOptionName text={option.name} />
        </DropDownOption>
      )}
      renderSelectedOption={(ref, onClick, isOpen) => (
        <LinkButton
          ref={ref}
          disabled={!isAddLinkAllowed}
          isActive={isOpen}
          onClick={onClick}
          tooltipText={getLinkButtonTooltipText(isAddLinkAllowed, isOpen)}
        />
      )}
      tippyOptions={tippyOptions}
    />
  );
};

CreateLink.displayName = 'CreateLink';
