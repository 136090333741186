import { mergeProps } from '@react-aria/utils';
import { Node } from '@react-types/shared';
import PropTypes from 'prop-types';
import React, { RefAttributes, useRef } from 'react';
import { VariableSizeList as VirtualizedList } from 'react-window';
import { DropDownMenuControlled } from '../../../../../client/component-library/components/DropDownMenu/DropDownMenuControlled.tsx';
import {
  DataUiCLElement,
  ObjectWithDataAttribute,
  getDataUiCLElementAttribute,
  getDataUiComponentAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { IInputProps, Input } from '../../Input/Input.tsx';
import { InputState } from '../../Input/inputStateEnum.ts';
import { simpleMenuItemHeight } from '../../MenuItem/decisionTokens.ts';
import { Tooltip } from '../../Tooltip/Tooltip.tsx';
import { unrequired } from '../../_utils/unrequired.ts';
import { ChevronTrigger } from '../components/ChevronTrigger.tsx';
import { ListBox } from '../components/ListBox.tsx';
import { SelectMenu } from '../components/SelectMenu.tsx';
import { emptySelectionItem } from '../emptySelectionItem.ts';
import { useSelectMenu } from '../hooks/useSelectMenu.tsx';
import { IBaseSelectItem, ISelectItem, ItemId, VirtualizedOrNot } from '../types.ts';
import { UseSingleSelectOptions, useSingleSelect } from './useSingleSelect.ts';
import { UseSingleSelectStateOptions, useSingleSelectState } from './useSingleSelectState.ts';

type PickedInputProps = Partial<
  Pick<
    IInputProps,
    | 'ariaLabel'
    | 'autoFocus'
    | 'caption'
    | 'delayAutoFocus'
    | 'id'
    | 'label'
    | 'placeholder'
    | 'tooltipPlacement'
    | 'tooltipText'
  >
>;

export type SingleSelectProps<TItem extends ISelectItem<TItem>> = PickedInputProps &
  Omit<UseSingleSelectStateOptions<TItem>, 'inputRef'> &
  Pick<UseSingleSelectOptions<TItem>, 'onInputChange'> &
  VirtualizedOrNot<TItem> & {
    readonly children?: never;
    readonly delayAutoFocus?: number;
    readonly inputDataAttributes?: ObjectWithDataAttribute;
    readonly renderPrefix?: (itemId: ItemId, itemNode: Node<TItem>) => React.ReactNode;
    readonly verticalMenuDataAttributes?: ObjectWithDataAttribute;
  };

// ignoring these props because they're messing the propType validation up
const propTypes: PropTypeMap<
  Omit<SingleSelectProps<IBaseSelectItem>, 'children' | 'isVirtualized' | 'optionHeight'>
> = {
  ariaLabel: PropTypes.string,
  autoFocus: PropTypes.bool,
  caption: PropTypes.string,
  customFilterPredicate: PropTypes.func,
  defaultSelectedItemId: PropTypes.string,
  delayAutoFocus: PropTypes.number,
  disabledItemIds: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(Set<ItemId>)]),
  id: PropTypes.string,
  inputState: PropTypes.oneOf(Object.values(InputState)),
  inputDataAttributes: PropTypes.object,
  items: PropTypes.array.isRequired,
  label: PropTypes.string,
  onInputChange: PropTypes.func,
  onSelectionChange: PropTypes.func,
  placeholder: PropTypes.string,
  renderPrefix: PropTypes.func,
  renderMenuOption: PropTypes.func,
  selectedItemId: PropTypes.string,
  tooltipPlacement: unrequired(Tooltip.propTypes?.placement),
  tooltipText: unrequired(Tooltip.propTypes?.tooltipText),
  verticalMenuDataAttributes: PropTypes.object,
};

export const SingleSelectComponent = React.forwardRef(
  <TItem extends ISelectItem<TItem>>(
    props: SingleSelectProps<TItem>,
    forwardedRef: React.Ref<HTMLDivElement>,
  ) => {
    const {
      ariaLabel,
      autoFocus,
      caption,
      children,
      defaultSelectedItemId,
      delayAutoFocus,
      disabledItemIds,
      id,
      inputDataAttributes,
      inputState,
      isVirtualized = false,
      items,
      label,
      optionHeight = simpleMenuItemHeight,
      onInputChange,
      onSelectionChange,
      placeholder,
      renderPrefix,
      renderMenuOption,
      selectedItemId,
      tooltipPlacement,
      tooltipText,
      verticalMenuDataAttributes,
      ...otherProps
    } = props;
    const isReadOnly = inputState === InputState.ReadOnly;
    const isDisabled = inputState === InputState.Disabled;

    const inputRef = useRef<HTMLInputElement>(null);
    const triggerRef = useRef<HTMLElement>(null);
    const menuRef = React.useRef<HTMLDivElement>(null);
    const virtualizedListRef = React.useRef<VirtualizedList<HTMLDivElement>>(null);

    const state = useSingleSelectState({
      ...props,
      inputRef,
    });

    const { collection, isOpen, selectionManager } = state;

    const { inputProps, listBoxProps, triggerProps } = useSingleSelect(
      {
        ...props,
        isVirtualized,
        inputRef,
        inputState,
        triggerRef,
        menuRef,
        virtualizedListRef,
      },
      state,
    );

    const chevronTrigger = <ChevronTrigger {...triggerProps} />;

    const selectedKey = [...selectionManager.selectedKeys][0]?.toString() ?? emptySelectionItem.id;
    const selectedItem = collection.getItem(selectedKey);

    const selectMenuProps = useSelectMenu(
      {
        isReadOnly,
        isVirtualized,
        menuRef,
        optionHeight,
        renderMenuOption,
        selectionMode: 'single',
        verticalMenuDataAttributes,
        virtualizedListRef,
      },
      state,
    );

    return (
      <div ref={forwardedRef} {...getDataUiComponentAttribute(SingleSelect)} {...otherProps}>
        <DropDownMenuControlled
          triggerId={id}
          triggerRef={triggerRef}
          renderTrigger={({ ref, type, onKeyDown, ...restDropDownProps }) => (
            <Input
              inputFieldRef={ref}
              prefix={renderPrefix?.(selectedKey, selectedItem as Node<TItem>)}
              suffixes={[chevronTrigger]}
              {...getDataUiCLElementAttribute(DataUiCLElement.SingleSelectDropdownInput)}
              {...mergeProps(inputProps, restDropDownProps, {
                ariaLabel,
                autoFocus,
                caption,
                delayAutoFocus,
                inputState,
                label,
                placeholder,
                tooltipPlacement,
                tooltipText,
              })}
              {...inputDataAttributes}
            />
          )}
          renderDropDown={(triggerWidth, _, menuProps) => (
            <ListBox {...mergeProps(listBoxProps, menuProps)}>
              <SelectMenu triggerWidth={triggerWidth} {...selectMenuProps} />
            </ListBox>
          )}
          isDropDownVisible={isOpen && !isDisabled}
          onDropDownVisibilityChange={(newIsOpen) => {
            if (!newIsOpen) {
              state.revertState();
            }
          }}
        />
      </div>
    );
  },
);

SingleSelectComponent.displayName = 'SingleSelect';
SingleSelectComponent.propTypes = propTypes;

export const SingleSelect = SingleSelectComponent as (<TItem extends ISelectItem<TItem>>(
  p: SingleSelectProps<TItem> & RefAttributes<HTMLDivElement>,
) => ReturnType<React.FC<SingleSelectProps<TItem>>>) &
  Pick<React.FC, 'displayName'>;
