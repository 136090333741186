import {
  TrackUserEventDirectData,
  UserEvent,
  eventTrackingService,
} from '../services/eventTrackingService.ts';
import { AmplitudeUser } from './amplitudeUtils.ts';
import { trackEventToAppInsights } from './applicationInsights.ts';

let user: AmplitudeUser | EmptyObject = {};

const logEvent = (eventName: UserEvent, metadata: TrackUserEventDirectData): void => {
  trackEventToAppInsights({ name: eventName }, { ...metadata, ...user });
};

const setUserInfo = (newUser: AmplitudeUser): void => {
  user = newUser;
  eventTrackingService.subscribe(logEvent);
};

const resetUserInfo = (): void => {
  user = {};
  eventTrackingService.unsubscribe(logEvent);
};

export const ApplicationInsights = {
  setUserInfo,
  resetUserInfo,
  logEvent,
};
