import PropTypes, { ValidationMap } from 'prop-types';
import { DateTimeCompiledTypeElementServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { TypeElementConfigurability } from '../../../../contentModels/shared/models/elements/types/elementConfigurabilityOptions.ts';
import { ElementType } from '../ContentItemElementType.ts';
import {
  BaseTypeElementValidationMap,
  EmptyBaseTypeElement,
  IBaseTypeElement,
  getBaseTypeElementFromServerModel,
} from './types/TypeElement.ts';

export interface IDateTimeTypeElement extends IBaseTypeElement {
  readonly defaultValue: string;
  readonly type: ElementType.DateTime;
}

export const EmptyDateTimeTypeElement: IDateTimeTypeElement = {
  ...EmptyBaseTypeElement,
  _configurability: TypeElementConfigurability.Recommended,
  defaultValue: '',
  type: ElementType.DateTime,
};

export const DateTimeTypeElementPropTypes = PropTypes.exact<ValidationMap<IDateTimeTypeElement>>({
  ...BaseTypeElementValidationMap([ElementType.DateTime]),
  defaultValue: PropTypes.string.isRequired,
});

export const getDateTimeTypeElementFromServerModel = (
  serverModel: DateTimeCompiledTypeElementServerModel,
): IDateTimeTypeElement => ({
  ...getBaseTypeElementFromServerModel(serverModel),
  _configurability: EmptyDateTimeTypeElement._configurability,
  defaultValue: serverModel.default?.global.value ?? '',
  type: ElementType.DateTime,
});
