import { useAttachRef } from '@kontent-ai/hooks';
import { FocusScope } from '@react-aria/focus';
import { useMenu, useMenuTrigger } from '@react-aria/menu';
import { mergeProps } from '@react-aria/utils';
import React, { ComponentProps, RefAttributes } from 'react';
import { VerticalMenuFrame, VerticalMenuFrameProps } from './VerticalMenuFrame.tsx';
import { VerticalMenuItem, VerticalMenuSection } from './types.ts';

export interface VerticalMenuProps<TItem extends VerticalMenuItem<TItem>>
  extends VerticalMenuFrameProps<TItem>,
    Partial<Omit<ComponentProps<typeof FocusScope>, 'autoFocus'>>,
    Partial<Pick<ReturnType<typeof useMenuTrigger>['menuProps'], 'autoFocus'>> {
  readonly items: ReadonlyArray<VerticalMenuSection<TItem> | TItem> | undefined;
}

const VerticalMenuComponent = React.forwardRef(
  <TItem extends VerticalMenuItem<TItem>>(
    {
      autoFocus,
      children,
      contain = true,
      items,
      restoreFocus = true,
      state,
      ...otherProps
    }: VerticalMenuProps<TItem>,
    forwardedRef: React.Ref<HTMLDivElement>,
  ) => {
    const { refObject, refToForward } = useAttachRef(forwardedRef);

    const { menuProps } = useMenu(
      {
        autoFocus,
        items,
        shouldFocusWrap: false,
        ...otherProps,
      },
      state,
      refObject,
    );

    const verticalMenuProps = mergeProps(
      otherProps,
      {
        ref: refToForward,
        state,
      },
      menuProps,
    );

    return (
      <FocusScope contain={contain} restoreFocus={restoreFocus}>
        <VerticalMenuFrame {...verticalMenuProps} />
      </FocusScope>
    );
  },
);

VerticalMenuComponent.displayName = 'VerticalMenu';

// forwardRef doesn't support generic parameters, so we cast the components to the correct type
// https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref
export const VerticalMenu = VerticalMenuComponent as (<TItem extends VerticalMenuItem<TItem>>(
  props: VerticalMenuProps<TItem> & RefAttributes<HTMLDivElement>,
) => ReturnType<React.FC<VerticalMenuProps<TItem>>>) &
  React.FC;
