import { INote } from '../../../../applications/itemEditor/models/contentItem/Assignment.ts';
import {
  IAssignmentWorkflowStep,
  emptyAssignmentWorkflowStep,
} from '../../../../data/models/workflow/WorkflowStep.ts';
import { DateTime, createDateTime } from '../../../models/DateTime.ts';
import { IUserIdentifier } from '../../../models/UserIdentifier.ts';
import { DefaultDisplayTimeZoneId } from '../../../utils/dateTime/timeZoneUtils.ts';
import { ScheduleMethod } from '../constants/scheduleMethods.ts';
import { ChangeWorkflowStepModalAction } from '../types/workflowStepModalActionTypes.ts';

export interface IChangeWorkflowStepModalData {
  readonly contributors: ReadonlySet<IUserIdentifier>;
  readonly dueDate: DateTime;
  readonly isInBulk: boolean;
  readonly note: INote | null;
  readonly savingError: string | null;
  readonly scheduledToPublishAt: DateTimeStamp | null;
  readonly scheduledPublishDisplayTimeZone: string;
  readonly scheduledToUnpublishAt: DateTimeStamp | null;
  readonly scheduledUnpublishDisplayTimeZone: string;
  readonly scheduleMethod: ScheduleMethod;
  readonly showAllContributors: boolean;
  readonly workflowStepAction: ChangeWorkflowStepModalAction | null;
  readonly workflowStep: IAssignmentWorkflowStep;
}

export const createChangeWorkflowStepModalData = (
  props?: Partial<IChangeWorkflowStepModalData>,
): IChangeWorkflowStepModalData => ({
  contributors: new Set<IUserIdentifier>(),
  dueDate: createDateTime(),
  isInBulk: false,
  note: null,
  savingError: null,
  scheduleMethod: ScheduleMethod.Now,
  showAllContributors: false,
  workflowStepAction: null,
  scheduledToPublishAt: null,
  scheduledPublishDisplayTimeZone: DefaultDisplayTimeZoneId,
  scheduledToUnpublishAt: null,
  scheduledUnpublishDisplayTimeZone: DefaultDisplayTimeZoneId,
  workflowStep: emptyAssignmentWorkflowStep,
  ...props,
});
