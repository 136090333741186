import { PortalContainerContext } from '@kontent-ai/component-library/context';
import { useBoundingResizeObserver } from '@kontent-ai/component-library/hooks';
import { spacingPopupDistance } from '@kontent-ai/component-library/tokens';
import { CLPropTypes } from '@kontent-ai/component-library/validators';
import { useAttachRef } from '@kontent-ai/hooks';
import { RefCallback } from '@kontent-ai/utils';
import { Placement } from '@popperjs/core';
import { useMenuTrigger } from '@react-aria/menu';
import { animated, useTransition } from '@react-spring/web';
import Tippy, { TippyProps } from '@tippyjs/react';
import PropTypes from 'prop-types';
import React, { Ref, RefObject, useContext } from 'react';
import { useUpdateTippyWithScroll } from '../../../app/_shared/hooks/useUpdateTippyWithScroll.ts';

export type DropdownTippyOptions = Pick<
  TippyProps,
  'placement' | 'popperOptions' | 'appendTo' | 'offset' | 'zIndex'
>;

export type CommonDropDownProps = {
  readonly allowAnimation?: boolean;
  readonly tippyOptions?: DropdownTippyOptions | null;
};

export const commonDropDownPropTypes: PropTypeMap<CommonDropDownProps> = {
  allowAnimation: PropTypes.bool,
  tippyOptions: PropTypes.object,
};

export type DropDownProps = {
  readonly id?: string;
  readonly placement?: Placement;
  readonly autoFocus?: boolean;
} & Omit<ReturnType<typeof useMenuTrigger>['menuProps'], 'autoFocus'>;

type TriggerProps = {
  readonly ref: RefCallback<HTMLElement>;
  readonly 'aria-controls'?: string;
} & Omit<Readonly<ReturnType<typeof useMenuTrigger>['menuTriggerProps']>, 'children'>;

export type DropDownMenuPositionerProps = CommonDropDownProps & {
  readonly dropDownRef?: Ref<HTMLElement>;
  readonly isDropDownVisible: boolean;
  readonly renderDropDown: (
    triggerWidth: number | undefined,
    triggerRefObject: RefObject<HTMLElement>,
    dropDownProps: DropDownProps,
  ) => React.ReactNode;
  readonly renderTrigger: (triggerProps: TriggerProps) => React.ReactNode;
  readonly triggerRef?: Ref<HTMLElement>;
};

export const dropdownMenuPositionerPropTypes: PropTypeMap<DropDownMenuPositionerProps> = {
  dropDownRef: CLPropTypes.ref,
  isDropDownVisible: PropTypes.bool.isRequired,
  renderDropDown: PropTypes.func.isRequired,
  renderTrigger: PropTypes.func.isRequired,
  triggerRef: CLPropTypes.ref,
  ...commonDropDownPropTypes,
};

// Default options which are applied always but can be overridden by the custom tippyOptions prop
const requiredTippyOptions: TippyProps = {
  // Accessibility should be handled manually
  aria: {
    content: null,
    expanded: false,
  },
  interactive: true,
  offset: [0, 0],
};

// Default options which are used in case no options are provided within tippyOptions prop
export const defaultDropdownTippyOptions: DropdownTippyOptions = {
  placement: 'bottom-start',
  popperOptions: {
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-start'],
        },
      },
    ],
  },
  offset: [0, spacingPopupDistance],
};

const dropDownTransitions = {
  from: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
  },
  leave: {
    opacity: 0,
  },
};

export const DropDownMenuPositioner: React.FC<DropDownMenuPositionerProps> = ({
  allowAnimation = true,
  dropDownRef,
  isDropDownVisible,
  renderDropDown,
  renderTrigger,
  tippyOptions,
  triggerRef,
}) => {
  const { portalContainerRef } = useContext(PortalContainerContext);
  const useTippyOptions = {
    ...requiredTippyOptions,
    appendTo: portalContainerRef.current ?? document.body,
    ...(tippyOptions || defaultDropdownTippyOptions),
  };
  const { refObject: triggerRefObject, refToForward: triggerRefToForward } =
    useAttachRef<HTMLElement>(triggerRef);

  const { refToForward: dropDownRefToForward } = useAttachRef<HTMLElement>(dropDownRef);

  const { width: triggerWidth } = useBoundingResizeObserver(triggerRefObject);
  const scrollTippyProps = useUpdateTippyWithScroll(isDropDownVisible);

  const transitions = useTransition(isDropDownVisible, {
    immediate: !allowAnimation,
    ...dropDownTransitions,
  });

  return (
    <>
      {renderTrigger({ ref: triggerRefToForward })}
      <Tippy
        {...scrollTippyProps}
        {...useTippyOptions}
        animation
        reference={triggerRefObject}
        render={(attrs) =>
          transitions(
            (style, item) =>
              item && (
                <animated.div style={style} {...attrs} ref={dropDownRefToForward}>
                  {renderDropDown(triggerWidth, triggerRefObject, {
                    placement: attrs['data-placement'],
                  })}
                </animated.div>
              ),
          )
        }
        visible={isDropDownVisible}
      />
    </>
  );
};

DropDownMenuPositioner.displayName = 'DropDownMenuPositioner';
DropDownMenuPositioner.propTypes = dropdownMenuPositionerPropTypes;
