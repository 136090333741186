import { Dispatch, GetState, ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { ICollectionGroupRoles } from '../../../../../data/models/users/ProjectContributor.ts';
import { Invite_Initialize } from '../../constants/usersActionTypes.ts';
import { getInitialInvitationCollectionGroups } from '../../selectors/getInitialInvitationCollectionGroups.ts';

const inviteModalInitialized = (
  initialCollectionGroups: readonly ICollectionGroupRoles[],
  initialEmail: string | undefined,
) =>
  ({
    type: Invite_Initialize,
    payload: {
      initialEmail,
      initialCollectionGroups,
    },
  }) as const;

export type InitializeInviteModalActionsType = ReturnType<typeof inviteModalInitialized>;

export const createInitializeInviteModalAction =
  () =>
  (initialEmail: string | undefined): ThunkFunction =>
  (dispatch: Dispatch, getState: GetState): void => {
    const {
      data: {
        roles: { rolesById },
      },
    } = getState();

    const initialCollectionGroups = getInitialInvitationCollectionGroups(rolesById);

    dispatch(inviteModalInitialized(initialCollectionGroups, initialEmail));
  };
