import PropTypes from 'prop-types';
import React from 'react';
import { IconName } from '../../../constants/iconEnumGenerated.ts';
import { DataUiAction } from '../../../utils/dataAttributes/DataUiAttributes.ts';
import {
  IForwardedRefProps,
  forwardRef,
  forwardedRefProps,
} from '../../../utils/forwardedRefProps.tsx';
import { ITriggerProps } from '../../PopoverDialog/types/PopoverDialogContract.type.ts';
import { BarItemAction } from './BarItemAction.tsx';

export interface IBarItemCodenameAction extends ITriggerProps, IForwardedRefProps<HTMLDivElement> {}

const propTypes: PropTypesShape<IBarItemCodenameAction> = {
  ...forwardedRefProps,
  onToggle: PropTypes.func,
  popover: PropTypes.object,
  isActive: PropTypes.bool,
};

const BarItemCodenameAction: React.FC<IBarItemCodenameAction> = ({
  onToggle,
  forwardedRef,
  isActive,
  ...otherProps
}) => (
  <BarItemAction
    ref={forwardedRef}
    dataUiActionName={DataUiAction.GetCodename}
    iconName={IconName.BracesOctothorpe}
    isActive={isActive}
    onClick={onToggle}
    screenReaderText="Codename"
    tooltipText="Codename"
    {...otherProps}
  />
);

BarItemCodenameAction.displayName = 'BarItemCodenameAction';
BarItemCodenameAction.propTypes = propTypes;

const BarItemCodenameActionFRC = forwardRef(BarItemCodenameAction);
export { BarItemCodenameActionFRC as BarItemCodenameAction };
