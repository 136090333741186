import React from 'react';
import { Route, Switch } from 'react-router';
import {
  ProjectSettingsRoute,
  ProjectsRoute,
  SubscriptionEnvironmentSettingsRoute,
  SubscriptionRoute,
  UserProfileRoute,
} from '../../constants/routePaths.ts';

type Props = {
  readonly inProjectOrEnvironmentContext?: () => React.ReactNode;
  readonly outOfProjectOrEnvironmentContext?: () => React.ReactNode;
};

const projectOrEnvironmentUnrelatedRoutes = [SubscriptionRoute, UserProfileRoute, ProjectsRoute];

const projectOrEnvironmentRelatedRoutes = [
  SubscriptionEnvironmentSettingsRoute,
  ProjectSettingsRoute,
];

export const HasProjectOrEnvironmentContext: React.FC<Props> = ({
  inProjectOrEnvironmentContext,
  outOfProjectOrEnvironmentContext,
}) => {
  return (
    <Switch>
      {projectOrEnvironmentRelatedRoutes.map((route) => (
        <Route key={route} path={route}>
          {inProjectOrEnvironmentContext?.()}
        </Route>
      ))}
      {projectOrEnvironmentUnrelatedRoutes.map((route) => (
        // This is to get around a failing proptype check, if you give 'path' an array of strings
        <Route key={route} path={route}>
          {outOfProjectOrEnvironmentContext?.()}
        </Route>
      ))}
      <Route>{inProjectOrEnvironmentContext?.()}</Route>
    </Switch>
  );
};

HasProjectOrEnvironmentContext.displayName = 'HasProjectOrEnvironmentContext';
