import {
  ScrollAlignment,
  ScrollElementsToViewOptions,
  scrollElementsToView,
} from '@kontent-ai/DOM';
import PropTypes from 'prop-types';
import React from 'react';
import { waitUntilFocusAndScrollAreNotDeferred } from '../../../../../../_shared/utils/autoScrollUtils.ts';
import { debounce } from '../../../../../../_shared/utils/func/debounce.ts';
import { ICommentThread } from '../../../../models/comments/CommentThreads.ts';
import {
  getCommentedFragmentCssSelector,
  isThreadInline,
  isThreadResolved,
} from '../../../../utils/commentUtils.ts';
import { CommentThreadCssClass, ItemEditorScrollOptions } from '../../constants/uiConstants.ts';
import { CommentPositionTransitionDuration } from './InlineCommentPane.tsx';

export interface IFocusedCommentThreadScrollerProps {
  readonly focusedThread: ICommentThread | null;
}

export class FocusedCommentThreadScroller extends React.PureComponent<IFocusedCommentThreadScrollerProps> {
  static displayName = 'FocusedCommentThreadScroller';

  static propTypes: PropTypesShape<IFocusedCommentThreadScrollerProps> = {
    focusedThread: PropTypes.object,
  };

  private readonly scrollToFocusedComment = debounce(
    waitUntilFocusAndScrollAreNotDeferred(() => {
      const focusedFragmentElement = this.getFocusedFragmentElement();
      const focusedThreadElement = this.getFocusedThreadElement();
      if (focusedThreadElement && focusedFragmentElement) {
        const threadScrollOptions: ScrollElementsToViewOptions = {
          ...ItemEditorScrollOptions,
          // When the comment is being replied to, we prefer bottom alignment so that the reply doesn't hide
          // behind the fold upon refocusing in case the comment thread is long
          alignment: this.props.focusedThread?.isReplying
            ? ScrollAlignment.Bottom
            : ItemEditorScrollOptions.alignment,
        };

        if (this.isFocusedThreadInlineAndUnresolved()) {
          scrollElementsToView([focusedFragmentElement, focusedThreadElement], threadScrollOptions);
        } else {
          scrollElementsToView([focusedFragmentElement], ItemEditorScrollOptions);
          scrollElementsToView([focusedThreadElement], threadScrollOptions);
        }
      }
    }),
    CommentPositionTransitionDuration,
  );

  private readonly isFocusedThreadInlineAndUnresolved = () => {
    const { focusedThread } = this.props;
    return focusedThread && isThreadInline(focusedThread) && !isThreadResolved(focusedThread);
  };

  componentDidMount() {
    const { focusedThread } = this.props;
    if (focusedThread) {
      this.scrollToFocusedComment();
    }
  }

  componentDidUpdate(prevProps: Readonly<IFocusedCommentThreadScrollerProps>) {
    const { focusedThread } = this.props;
    const { focusedThread: previouslyFocusedThread } = prevProps;

    if (
      focusedThread &&
      (!previouslyFocusedThread || focusedThread.id !== previouslyFocusedThread.id)
    ) {
      this.scrollToFocusedComment.cancel();
      this.scrollToFocusedComment();
    }
  }

  componentWillUnmount() {
    this.scrollToFocusedComment.cancel();
  }

  private readonly getFocusedThreadElement = (): HTMLElement | null => {
    const { focusedThread } = this.props;
    if (!focusedThread) {
      return null;
    }

    return document.querySelector(`.${CommentThreadCssClass}[id="${focusedThread.id}"]`);
  };

  private readonly getFocusedFragmentElement = (): HTMLElement | null => {
    const cssSelector = getCommentedFragmentCssSelector(this.props.focusedThread);
    if (!cssSelector) {
      return null;
    }

    return document.querySelector(cssSelector);
  };

  render() {
    return null;
  }
}
