import React from 'react';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import { Button, ButtonSize } from '../../../../../_shared/uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import { Icon } from '../../../../../_shared/uiComponents/Icon/Icon.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface IProps {
  readonly onClick: () => void;
  readonly text?: string;
}

export const ClearFilterButton: React.FC<IProps> = ({ onClick, text }) => (
  <Button
    size={ButtonSize.S}
    style={ButtonStyle.Tertiary}
    onClick={onClick}
    {...getDataUiActionAttribute(DataUiAction.ClearFilter)}
  >
    <Icon iconName={IconName.Times} className="btn__icon" />
    {text || 'Clear'}
  </Button>
);

ClearFilterButton.displayName = 'ClearFilterButton';
