import React from 'react';
import { useHistory, useParams } from 'react-router';
import { SubscriptionEnvironmentRoleEditorRouteParams } from '../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { createRoleEditingLink } from '../../../../_shared/utils/routing/projectSubscriptionRoutingUtils.ts';
import { createRole } from '../actions/thunkRolesActions.ts';
import { RoleEditorToolbarActions } from '../components/RoleEditorToolbarActions.tsx';
import { PredefinedRoles } from '../constants/predefinedRoles.ts';
import { getCustomRolesLimitInfo } from '../selectors/customRoleLimit.ts';
import { isRoleUsedByAnyUser } from '../selectors/isRoleUsed.ts';
import { isRoleUsedByWorkflowStep as isRoleUsedByWorkflowStepSelector } from '../selectors/isRoleUsedByWorkflowStep.ts';
import { canCreateNewCustomRole } from '../utils/customRolesUtils.ts';

export const SubscriptionManagerRoleCreatorToolbarActions: React.FC = () => {
  const { projectId, subscriptionId } = useParams<SubscriptionEnvironmentRoleEditorRouteParams>();
  const history = useHistory();
  const dispatch = useDispatch();

  const status = useSelector((s) => s.rolesApp.editorUi.status);
  const isEditedRoleReadOnly = useSelector((s) => {
    const { editedRole } = s.rolesApp.editorUi;
    return editedRole.isReadonly && editedRole.codename !== PredefinedRoles.LocalProjectManager;
  });
  const isRoleUsedByUser = useSelector((s) =>
    isRoleUsedByAnyUser(s.rolesApp.editorUi.editedRole, s.data.users.usersById),
  );
  const isRoleUsedByWorkflowStep = useSelector((s) =>
    isRoleUsedByWorkflowStepSelector(s.rolesApp.editorUi.editedRole, s.data.workflows.byId),
  );
  const isReadonly = useSelector((s) => !canCreateNewCustomRole(getCustomRolesLimitInfo(s)));

  const handleSave = () =>
    dispatch(
      createRole({
        history,
        getRoleEditingRoute: (roleId: Uuid) =>
          createRoleEditingLink({
            projectId,
            subscriptionId,
            roleId,
          }),
      }),
    );

  return (
    <RoleEditorToolbarActions
      status={status}
      isEditedRoleReadOnly={isEditedRoleReadOnly}
      isRoleUsedByUser={isRoleUsedByUser}
      isRoleUsedByWorkflowStep={isRoleUsedByWorkflowStep}
      readOnly={isReadonly}
      onSave={handleSave}
    />
  );
};

SubscriptionManagerRoleCreatorToolbarActions.displayName =
  'SubscriptionManagerRoleCreatorToolbarActions';
