import { Direction } from '@kontent-ai/types';
import { DraftDecorator } from 'draft-js';
import React, { PropsWithChildren, useCallback, useMemo, useRef, useState } from 'react';
import { logError } from '../../../../_shared/utils/logError.ts';
import { useEditorApi } from '../../editorCore/hooks/useEditorApi.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import {
  CanUpdateContent,
  IsEditorLocked,
  RemoveInvalidState,
} from '../../editorCore/types/Editor.base.type.ts';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { Apply, EditorPlugin, Init, Render } from '../../editorCore/types/Editor.plugins.type.ts';
import {
  EditorChangeReason,
  internalChangeReasons,
} from '../../editorCore/types/EditorChangeReason.ts';
import { DecorableFunction, Decorator, decorable } from '../../editorCore/utils/decorable.ts';
import { withDisplayName } from '../../editorCore/utils/withDisplayName.ts';
import {
  getFullBlockTypesAtSelection,
  getMetadataAtSelection,
} from '../../utils/editorSelectionUtils.ts';
import { ModalsPlugin, OnCloseModal } from '../ModalsPlugin.tsx';
import { TextFormattingFeature } from '../apiLimitations/api/editorLimitationUtils.ts';
import { EntityApiPlugin } from '../entityApi/EntityApiPlugin.tsx';
import { EntityDecoratorProps } from '../entityApi/api/editorEntityUtils.ts';
import { isTextFormattingCommandAllowedAtSelection } from '../keyboardShortcuts/api/editorCommandUtils.ts';
import { TextApiPlugin } from '../textApi/TextApiPlugin.tsx';
import { InlineToolbarPlugin } from '../toolbars/InlineToolbarPlugin.tsx';
import { EditorLinkApi } from './api/EditorLinkApi.type.ts';
import { isLink, isLinkPlaceholder, isNewLink } from './api/LinkEntity.ts';
import { LinkType } from './api/LinkType.ts';
import { NewLinkType } from './api/NewLinkType.ts';
import { editorLinkApi } from './api/editorLinkApi.ts';
import {
  findLinks,
  getLinkType,
  isLinkAllowedAtSelection,
  isUnlinkAllowedAtSelection,
} from './api/editorLinkUtils.ts';
import { CreateLink, CreateLinkOption } from './components/CreateLink.tsx';
import { UnlinkButton } from './components/buttons/UnlinkButton.tsx';

type CancelNewLink = (entityKey: string, isPlaceholder: boolean) => void;
type EditLink = (entityKey: string) => Promise<void>;
type LinkEditingCancelled = (entityKey: string) => void;
type LinkEditingFinished = () => void;
type Unlink = (entityKey: string) => void;
type UnlinkAtSelection = () => void;

export type GetLinkEntityComponent<TCustomProps extends ReadonlyRecord<string, any>> = (
  linkType: LinkType | NewLinkType,
) => {
  readonly component: React.FC<EntityDecoratorProps>;
  readonly props: TCustomProps;
} | null;

type GetUnknownLinkEntityComponent = GetLinkEntityComponent<ReadonlyRecord<string, unknown>>;

export type GetLinkOptions = () => ReadonlyArray<CreateLinkOption>;

type LinksPluginState = {
  readonly cancelNewLink: CancelNewLink;
  readonly editedLinkEntityKey: string | null;
  readonly editLink: EditLink;
  readonly getLinkEntityComponent: DecorableFunction<GetUnknownLinkEntityComponent>;
  readonly getLinkOptions: DecorableFunction<GetLinkOptions>;
  readonly linkEditingCancelled: LinkEditingCancelled;
  readonly linkEditingFinished: LinkEditingFinished;
  readonly setEditedLinkEntityKey: (entityKey: string) => void;
  readonly unlink: Unlink;
  readonly unlinkAtSelection: UnlinkAtSelection;
};

export type LinksPlugin = EditorPlugin<
  LinksPluginState,
  None,
  EditorLinkApi,
  [TextApiPlugin, EntityApiPlugin, InlineToolbarPlugin, ModalsPlugin]
>;

type LinkToolbarButtonProps = {
  readonly allowAddLink: boolean;
  readonly disabled: boolean;
  readonly onUnlink?: () => void;
  readonly options: ReadonlyArray<CreateLinkOption>;
};

const LinkToolbarButton: React.FC<LinkToolbarButtonProps> = ({
  allowAddLink,
  disabled,
  onUnlink,
  options,
}) => {
  const isAddLinkAllowed = allowAddLink && !disabled && !!options.length;

  if (onUnlink) {
    return <UnlinkButton disabled={disabled} onClick={onUnlink} />;
  }

  return <CreateLink isAddLinkAllowed={isAddLinkAllowed} options={options} />;
};

LinkToolbarButton.displayName = 'LinkToolbarButton';

const getLinkOptions: GetLinkOptions = () => [];

type LinkCustomProps = {
  readonly getLinkEntityComponentRef: React.RefObject<GetUnknownLinkEntityComponent>;
};

const LinkEntity = (props: PropsWithChildren<EntityDecoratorProps<LinkCustomProps>>) => {
  const { getLinkEntityComponentRef, ...entityProps } = props;

  const contentState = props.contentState;
  const entity = contentState.getEntity(props.entityKey);
  const linkType = getLinkType(entity);

  const componentResult = linkType && getLinkEntityComponentRef.current?.(linkType);
  if (componentResult) {
    return componentResult.component({
      ...entityProps,
      ...componentResult.props,
    });
  }

  if (linkType) {
    logError(
      `Cannot find link entity component for link type '${linkType}'. Make sure that a plugin for this link type is registered and decorates method getLinkEntityComponent.`,
    );
  }
  return props.children;
};

LinkEntity.displayName = 'LinkEntity';

export const useLinks: PluginCreator<LinksPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('LinksPlugin', {
        ComposedEditor: (props) => {
          const { disabled } = props;

          // We need to access methods using state callbacks via handle to editor component
          // as we need to pass them to the decorator, and they are not yet available in the init phase
          const getLinkEntityComponentRef = useRef<GetUnknownLinkEntityComponent | null>(null);

          const [editedLinkEntityKey, setEditedLinkEntityKey] = useState<string | null>(null);
          const linkEditingFinished: LinkEditingFinished = useCallback(
            () => setEditedLinkEntityKey(null),
            [],
          );

          const isEditorLocked: Decorator<IsEditorLocked> = useCallback(
            (baseIsEditorLocked) => () => !!editedLinkEntityKey || baseIsEditorLocked(),
            [editedLinkEntityKey],
          );

          const canUpdateContent: Decorator<CanUpdateContent> = useCallback(
            (baseCanUpdateContent) => (changeReason) =>
              // Even reviewer can add comments
              internalChangeReasons.has(changeReason ?? EditorChangeReason.Regular)
                ? baseCanUpdateContent(changeReason)
                : !editedLinkEntityKey && baseCanUpdateContent(changeReason),
            [editedLinkEntityKey],
          );

          const renderInlineToolbarButtons: Decorator<Render<LinksPlugin>> = useCallback(
            (baseRenderInlineToolbarContent) => (state) => {
              const { editorState } = state;
              const content = editorState.getCurrentContent();
              const selection = editorState.getSelection();

              const canUpdate = state.canUpdateContent();
              const allowUnlink =
                canUpdate && isUnlinkAllowedAtSelection(content, selection) && !disabled;
              const allowAddLink =
                canUpdate && isLinkAllowedAtSelection(content, selection) && !disabled;

              const options = state.getLinkOptions();
              const showLinkButton = canUpdate && (options.length || allowUnlink);
              if (!showLinkButton) {
                return baseRenderInlineToolbarContent(state);
              }

              const fullBlockTypesAtSelection = getFullBlockTypesAtSelection(content, selection);
              const metadataAtSelection = getMetadataAtSelection(content, selection);
              const linkDisabled = !isTextFormattingCommandAllowedAtSelection(
                TextFormattingFeature.Link,
                fullBlockTypesAtSelection,
                metadataAtSelection,
                state.getApi().getLimitations(),
              );

              return (
                <>
                  {baseRenderInlineToolbarContent(state)}
                  <LinkToolbarButton
                    allowAddLink={allowAddLink}
                    disabled={!!disabled || linkDisabled}
                    onUnlink={allowUnlink ? state.unlinkAtSelection : undefined}
                    options={options}
                  />
                </>
              );
            },
            [disabled],
          );

          const init: Init = useCallback((state) => {
            const linkCustomProps: LinkCustomProps = { getLinkEntityComponentRef };
            const linkDecorator: DraftDecorator<LinkCustomProps> = {
              strategy: findLinks,
              component: LinkEntity,
              props: linkCustomProps,
            };

            return {
              decorators: [...state.decorators, linkDecorator],
            };
          }, []);

          const apply: Apply<LinksPlugin> = useCallback(
            (state) => {
              state.renderInlineToolbarButtons.decorate(renderInlineToolbarButtons);
              state.isEditorLocked.decorate(isEditorLocked);
              state.canUpdateContent.decorate(canUpdateContent);

              const editLink: EditLink = async (entityKey) => {
                if (!state.canUpdateContent(EditorChangeReason.Internal)) {
                  return;
                }

                await state.executeChange((editorState) => {
                  const newEditorState = state
                    .getApi()
                    .forceSelectionToEntity(editorState, entityKey);
                  if (newEditorState !== editorState) {
                    setEditedLinkEntityKey(entityKey);
                  }
                  return newEditorState;
                }, EditorChangeReason.Internal);
              };

              const linkEditingCancelled: LinkEditingCancelled = (entityKey) => {
                if (!state.canUpdateContent(EditorChangeReason.Internal)) {
                  return;
                }

                state.executeChange((editorState) => {
                  const newEditorState = state
                    .getApi()
                    .forceSelectionToEntity(editorState, entityKey);
                  linkEditingFinished();
                  return newEditorState;
                }, EditorChangeReason.Internal);
              };

              const cancelNewLink: CancelNewLink = (entityKey, isPlaceholder) => {
                if (!state.canUpdateContent(EditorChangeReason.Internal)) {
                  return;
                }

                state.executeChange((editorState) => {
                  const selection = state.getApi().getSelectionForEntity(editorState, entityKey);
                  if (selection && !selection.isCollapsed()) {
                    const newEditorState = isPlaceholder
                      ? state
                          .getApi()
                          .handleDeleteAtSelection(editorState, selection, Direction.Backward)
                          .editorState
                      : state.getApi().unlink(editorState, selection);

                    linkEditingFinished();
                    return newEditorState;
                  }
                  return editorState;
                }, EditorChangeReason.Internal);
              };

              const onCloseModal: Decorator<OnCloseModal> = (baseOnCloseModal) => () => {
                if (editedLinkEntityKey) {
                  const editorState = state.getEditorState();
                  const content = editorState.getCurrentContent();
                  const entity = content.getEntity(editedLinkEntityKey);
                  if (isLink(entity)) {
                    const isNew = isNewLink(entity);

                    if (isNew) {
                      const isPlaceholder = isLinkPlaceholder(entity);
                      cancelNewLink(editedLinkEntityKey, isPlaceholder);
                    } else {
                      linkEditingCancelled(editedLinkEntityKey);
                    }
                    return true;
                  }
                }
                return baseOnCloseModal();
              };

              state.onCloseModal.decorate(onCloseModal);

              const unlinkAtSelection = (): void => {
                if (state.canUpdateContent()) {
                  state.executeChange((editorState) => {
                    const selection = editorState.getSelection();
                    const content = editorState.getCurrentContent();
                    if (isUnlinkAllowedAtSelection(content, selection)) {
                      return state.getApi().unlink(editorState, selection);
                    }
                    return editorState;
                  });
                }
              };

              const unlink = (entityKey: string): void => {
                if (state.canUpdateContent(EditorChangeReason.Internal)) {
                  state.executeChange((editorState) => {
                    const selection = state.getApi().getSelectionForEntity(editorState, entityKey);
                    if (selection) {
                      const newEditorState = state.getApi().unlink(editorState, selection);
                      linkEditingFinished();

                      return newEditorState;
                    }
                    return editorState;
                  }, EditorChangeReason.Internal);
                }
              };

              const getLinkEntityComponent = decorable<GetUnknownLinkEntityComponent>(() => null);
              getLinkEntityComponentRef.current = getLinkEntityComponent;

              const removeInvalidState: Decorator<RemoveInvalidState> =
                (baseRemoveInvalidState) => (editorState) => {
                  if (editedLinkEntityKey) {
                    const content = editorState.getCurrentContent();
                    const entity = content.getEntity(editedLinkEntityKey);
                    if (!isLink(entity)) {
                      linkEditingFinished();
                      return;
                    }

                    const linkSelection = state
                      .getApi()
                      .getSelectionForEntity(editorState, editedLinkEntityKey);
                    if (!linkSelection) {
                      linkEditingFinished();
                    }
                  }
                  baseRemoveInvalidState(editorState);
                };

              state.removeInvalidState.decorate(removeInvalidState);

              return {
                cancelNewLink,
                editedLinkEntityKey,
                editLink,
                getLinkEntityComponent,
                getLinkOptions: decorable(getLinkOptions),
                linkEditingCancelled,
                linkEditingFinished,
                setEditedLinkEntityKey,
                unlink,
                unlinkAtSelection,
              };
            },
            [
              canUpdateContent,
              editedLinkEntityKey,
              isEditorLocked,
              linkEditingFinished,
              renderInlineToolbarButtons,
            ],
          );

          const { getApiMethods } = useEditorApi<LinksPlugin>(editorLinkApi);

          return useEditorWithPlugin(baseEditor, props, { init, apply, getApiMethods });
        },
      }),
    [baseEditor],
  );
