import { isAbortError } from '@kontent-ai/errors';
import { History } from 'history';
import Immutable from 'immutable';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { EnvironmentRouteParams, RolesRoute } from '../../../../../_shared/constants/routePaths.ts';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IRoleRepository } from '../../../../../repositories/interfaces/IRoleRepository.type.ts';
import { IRoleServerModel } from '../../../../../repositories/serverModels/IRoleServerModel.type.ts';
import {
  Role_EditorInit_Finished,
  Role_EditorInit_Started,
} from '../../constants/rolesActionTypes.ts';
import { RoleEditorStatus } from '../../models/RoleEditorStatus.ts';

const roleEditorInitStarted = (status: RoleEditorStatus) =>
  ({
    type: Role_EditorInit_Started,
    payload: { status },
  }) as const;

const roleEditorInitFinished = (
  role: IRoleServerModel,
  allTypes: Immutable.Map<Uuid, IContentType>,
) =>
  ({
    type: Role_EditorInit_Finished,
    payload: {
      role,
      allTypes,
    },
  }) as const;

export type InitRoleEditorActionsType = ReturnType<
  typeof roleEditorInitStarted | typeof roleEditorInitFinished
>;

interface IDeps {
  readonly roleRepository: Pick<IRoleRepository, 'getRole'>;
  readonly loadRoles: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadUsers: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadWorkflows: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadTypes: (abortSignal?: AbortSignal) => ThunkPromise;
}

interface IActionParams {
  readonly history: History;
  readonly roleId: Uuid;
}

export const makeInitRoleEditorAction =
  (deps: IDeps) =>
  ({ history, roleId }: IActionParams, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      sharedApp: { currentProjectId: projectId },
      rolesApp: {
        editorUi: { status },
      },
    } = getState();

    dispatch(roleEditorInitStarted(status));
    try {
      const [serverRole] = await Promise.all([
        deps.roleRepository.getRole(roleId, abortSignal),
        dispatch(deps.loadUsers(abortSignal)),
        dispatch(deps.loadRoles(abortSignal)),
        dispatch(deps.loadWorkflows(abortSignal)),
        dispatch(deps.loadTypes(abortSignal)),
      ]);

      dispatch(roleEditorInitFinished(serverRole, getState().data.contentTypes.byId));
    } catch (error) {
      if (!isAbortError(error)) {
        history.push(buildPath<EnvironmentRouteParams>(RolesRoute, { projectId }));
      }

      throw error;
    }
  };
