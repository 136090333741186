import { Collection } from '@kontent-ai/utils';
import { EditorState } from 'draft-js';
import React, { useCallback, useMemo, useRef } from 'react';
import { IconName } from '../../../../_shared/constants/iconEnumGenerated.ts';
import { RTECommandSource } from '../../../../_shared/models/events/RTECommandEventData.type.ts';
import { Callback, RegisterCallback } from '../../../../_shared/types/RegisterCallback.type.ts';
import { DataUiRteAction } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { EditorSizeHandler } from '../../components/utility/EditorSizeHandler.tsx';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { OnUpdate } from '../../editorCore/types/Editor.base.type.ts';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { Contract, None } from '../../editorCore/types/Editor.contract.type.ts';
import {
  Apply,
  EditorPlugin,
  PluginState,
  Render,
} from '../../editorCore/types/Editor.plugins.type.ts';
import { DecorableFunction, Decorator, decorable } from '../../editorCore/utils/decorable.ts';
import { withDisplayName } from '../../editorCore/utils/withDisplayName.ts';
import { TextBlockTypes } from '../../utils/blocks/blockType.ts';
import {
  doesSelectionContainText,
  getBaseBlockTypes,
  getFullBlockTypesAtSelection,
  getMetadataAtSelection,
} from '../../utils/editorSelectionUtils.ts';
import { KeyboardShortcutsPlugin } from '../keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { RichTextInputCommand } from '../keyboardShortcuts/api/EditorCommand.ts';
import { StylesPlugin } from '../visuals/StylesPlugin.tsx';
import { AddToolbarWrapper } from './components/block/AddToolbar.tsx';
import {
  BlockToolbar as BlockToolbarComponent,
  Resettable,
} from './components/block/BlockToolbar.tsx';
import { BlockToolbarButton } from './components/block/BlockToolbarButton.tsx';
import { useSelfPositioningComponentCallback } from './hooks/useSelfPositioningComponentCallback.tsx';
import { ButtonInputProps, shouldResetBlockToolbar } from './utils/toolbarUtils.ts';

export type RenderBlockToolbarContent<TPlugin extends Contract = BlockToolbarPlugin> = Render<
  TPlugin,
  [boolean]
>;
export type CanDisplayBlockToolbar = (editorState: EditorState) => boolean;

type BlockToolbarPluginState = {
  readonly canDisplayBlockToolbar: DecorableFunction<CanDisplayBlockToolbar>;
  readonly renderBlockToolbarContent: DecorableFunction<RenderBlockToolbarContent>;
  readonly renderAddToolbarContent: DecorableFunction<RenderBlockToolbarContent>;
};

type BlockToolbarPluginProps = {
  readonly hidesDisallowedFeatures?: boolean;
};

export type BlockToolbarPlugin = EditorPlugin<
  BlockToolbarPluginState,
  BlockToolbarPluginProps,
  None,
  [StylesPlugin]
>;

type BlockToolbarProps = {
  readonly editorRef: React.RefObject<HTMLDivElement>;
  readonly editorState: EditorState;
  readonly registerUpdateToolbarPosition: RegisterCallback<Callback>;
  readonly renderBody: (isToolbarVertical: boolean) => React.ReactElement | null;
};

const BlockToolbar: React.FC<BlockToolbarProps> = ({
  editorRef,
  editorState,
  registerUpdateToolbarPosition,
  renderBody,
}) => {
  const content = editorState.getCurrentContent();
  const selection = editorState.getSelection();

  const metadataAtSelection = getMetadataAtSelection(content, selection);
  const selectionContainsText = doesSelectionContainText(selection, metadataAtSelection);

  const fullBlockTypesAtSelection = getFullBlockTypesAtSelection(content, selection);
  const baseBlockTypesAtSelection = getBaseBlockTypes(fullBlockTypesAtSelection);
  const onlyTextBlocksAtSelection = !Collection.removeMany(
    baseBlockTypesAtSelection,
    TextBlockTypes,
  ).size;
  const showBlockToolbar =
    selection.getHasFocus() && !selectionContainsText && onlyTextBlocksAtSelection;
  if (!showBlockToolbar) {
    return null;
  }

  return (
    <BlockToolbarComponent
      editorRef={editorRef}
      registerUpdateToolbarPosition={registerUpdateToolbarPosition}
      renderContent={renderBody}
    />
  );
};

BlockToolbar.displayName = 'BlockToolbar';

const renderEmptyContent: Render<BlockToolbarPlugin> = () => null;

export const useBlockToolbar: PluginCreator<BlockToolbarPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('BlockToolbarPlugin', {
        ComposedEditor: (props) => {
          const insertButtonRef = useRef<Resettable>(null);

          const reset: Decorator<OnUpdate> = useCallback(
            (baseOnUpdate) => (params) => {
              if (shouldResetBlockToolbar(params.changeReason)) {
                insertButtonRef.current?.reset();
              }
              baseOnUpdate(params);
            },
            [],
          );

          const {
            registerUpdateSelfPositioningComponent,
            updateSelfPositioningComponent,
            onUpdateDecorator,
          } = useSelfPositioningComponentCallback();

          const renderBlockToolbarContent: RenderBlockToolbarContent = useCallback(
            (state, isToolbarVertical) => {
              const addToolbarContent = state.renderAddToolbarContent(state, isToolbarVertical);
              if (addToolbarContent) {
                return (
                  <BlockToolbarButton
                    buttonDescription="Insert..."
                    fallbackToolbarPlacements={isToolbarVertical ? ['right'] : ['bottom-start']}
                    iconName={IconName.PlusCircle}
                    ref={insertButtonRef}
                    toolbarPlacement={isToolbarVertical ? 'right' : 'top-start'}
                    uiAction={DataUiRteAction.AddBlock}
                  >
                    <AddToolbarWrapper>{addToolbarContent}</AddToolbarWrapper>
                  </BlockToolbarButton>
                );
              }
              return null;
            },
            [],
          );

          const renderOverlays: Decorator<Render<BlockToolbarPlugin>> = useCallback(
            (baseRenderOverlays) => (state) => (
              <>
                {state.canDisplayBlockToolbar(state.editorState) && (
                  <BlockToolbar
                    editorRef={state.getRteInputRef()}
                    editorState={state.editorState}
                    registerUpdateToolbarPosition={registerUpdateSelfPositioningComponent}
                    renderBody={(isToolbarVertical) =>
                      state.renderBlockToolbarContent(state, isToolbarVertical)
                    }
                  />
                )}
                {baseRenderOverlays(state)}
              </>
            ),
            [registerUpdateSelfPositioningComponent],
          );

          const render: Decorator<Render<BlockToolbarPlugin>> = useCallback(
            (baseRender) => (state) => (
              <>
                {baseRender(state)}
                <EditorSizeHandler
                  editorRef={state.getWrapperRef()}
                  onSizeChanged={updateSelfPositioningComponent}
                />
              </>
            ),
            [updateSelfPositioningComponent],
          );

          const apply: Apply<BlockToolbarPlugin> = useCallback(
            (state) => {
              state.onUpdate.decorate(onUpdateDecorator);
              state.onUpdate.decorate(reset);
              state.render.decorate(render);
              state.renderOverlays.decorate(renderOverlays);

              const canDisplayBlockToolbar: CanDisplayBlockToolbar = () => state.canUpdateContent();

              return {
                canDisplayBlockToolbar: decorable(canDisplayBlockToolbar),
                renderBlockToolbarContent: decorable(renderBlockToolbarContent),
                renderAddToolbarContent: decorable(renderEmptyContent),
              };
            },
            [onUpdateDecorator, render, renderBlockToolbarContent, renderOverlays, reset],
          );

          return useEditorWithPlugin(baseEditor, props, { apply });
        },
      }),
    [baseEditor],
  );

export function getToolbarButtonInputProps(
  state: PluginState<
    EditorPlugin<None, None, None, [KeyboardShortcutsPlugin<RichTextInputCommand>]>
  >,
  hidesDisallowedFeatures: boolean | undefined,
): ButtonInputProps {
  const { editorState } = state;
  const content = editorState.getCurrentContent();
  const selection = editorState.getSelection();

  const fullBlockTypesAtSelection = getFullBlockTypesAtSelection(content, selection);

  const buttonProps: ButtonInputProps = {
    fullBlockTypesAtSelection,
    currentVisualStyle: null,
    hidesDisallowedFeatures: !!hidesDisallowedFeatures,
    limitations: state.getApi().getLimitations(),
    metadataAtSelection: null,
    onCommand: (command) => state.handleCommand(command, RTECommandSource.BlockToolbar),
    selectionContainsText: null,
    tooltipPlacement: 'top-start',
  };
  return buttonProps;
}
