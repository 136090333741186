import React, { useCallback } from 'react';
import { repositoryCollection } from '../../../../../_shared/repositories/repositories.ts';
import { ListingRequestOptionsModel } from '../../../../../repositories/utils/ensureAllRequestedDataFetched.ts';
import { TaskWidgetDetailTableLiveContainer } from '../../shared/containers/TaskWidgetDetailTableLiveContainer.tsx';
import { MostOverdueTasksWidget } from '../components/MostOverdueTasksWidget.tsx';

const { missionControlRepository } = repositoryCollection;

export const MostOverdueTasksWidgetLiveContainer: React.FC = () => {
  const tasksFetcher = useCallback(
    (requestOptions: ListingRequestOptionsModel, abortSignal: AbortSignal) =>
      missionControlRepository.getOverdueTasks(requestOptions, abortSignal),
    [],
  );

  return (
    <MostOverdueTasksWidget
      tasksFetcher={tasksFetcher}
      WidgetDetail={TaskWidgetDetailTableLiveContainer}
    />
  );
};

MostOverdueTasksWidgetLiveContainer.displayName = 'MostOverdueTasksWidgetLiveResolver';
