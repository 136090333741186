import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { abandonAssignmentSectionChange } from '../../../actions/contentItemEditingActions.ts';
import { updateNote } from '../../../actions/thunkContentItemEditingActions.ts';
import { LeafActionFormProps } from '../../../components/editingActions/EditingLeafActionForm.tsx';
import { NoteForm as NoteFormComponent } from '../../../components/editingActions/leafActionForms/NoteForm.tsx';
import { AssignmentSections } from '../../../constants/AssignmentSections.ts';

export const NoteForm: React.FC<LeafActionFormProps> = ({
  actionOrigin,
  useCustomHandleClickOutside,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const initialNote = useSelector(
    (s) => s.contentApp.editedContentItemVariant?.assignment.note?.noteText ?? null,
  );

  return (
    <NoteFormComponent
      actionOrigin={actionOrigin}
      initialNote={initialNote}
      onCancel={() => dispatch(abandonAssignmentSectionChange(AssignmentSections.Note))}
      onConfirm={(values) => dispatch(updateNote(history, values.note, actionOrigin))}
      useCustomHandleClickOutside={useCustomHandleClickOutside}
    />
  );
};

NoteForm.displayName = 'NoteForm';
