import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import {
  Workflows_ListingInit_Finished,
  Workflows_ListingInit_Started,
} from '../../constants/workflowActionTypes.ts';

interface IInitWorkflowStepsEditorActionDependencies {
  readonly loadWorkflows: () => ThunkPromise;
  readonly loadWorkflowsUsage: () => ThunkPromise;
}

const initWorkflowsListingStarted = () => ({ type: Workflows_ListingInit_Started }) as const;

const initWorkflowsListingFinished = () => ({ type: Workflows_ListingInit_Finished }) as const;

export type InitWorkflowsListingActions = ReturnType<
  typeof initWorkflowsListingStarted | typeof initWorkflowsListingFinished
>;

export const createInitWorkflowsListingAction =
  ({ loadWorkflows, loadWorkflowsUsage }: IInitWorkflowStepsEditorActionDependencies) =>
  (): ThunkPromise =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(initWorkflowsListingStarted());

    dispatch(loadWorkflowsUsage());
    await dispatch(loadWorkflows());

    dispatch(initWorkflowsListingFinished());
  };
