import React from 'react';
import {
  colorAlertIcon,
  colorAlertIconInverse,
  colorAlertText,
  colorAlertTextInverse,
  colorIconHint,
  colorIconHintInverse,
  colorSuccessIcon,
  colorSuccessIconInverse,
  colorSuccessText,
  colorSuccessTextInverse,
  colorTextLowEmphasis,
  colorTextLowEmphasisInverse,
  colorWarningIcon,
  colorWarningIconInverse,
  colorWarningText,
  colorWarningTextInverse,
} from '../../tokens/decision/colors.ts';
import { getDataUiObjectNameAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { Icons } from '../Icons/components/icons.ts';
import {
  BaseSimpleStatusComponent,
  ISimpleStatusProps,
  baseSimpleStatusPropTypes,
} from './components/BaseSimpleStatusComponent.tsx';

type InjectedPropKeys =
  | 'flavor'
  | 'iconColor'
  | 'iconColorInverse'
  | 'labelColor'
  | 'labelColorInverse';

interface IFlavoredSimpleStatusProps extends Omit<ISimpleStatusProps, InjectedPropKeys> {}

interface IFlavoredSimpleStatusPropsRequiringLabel extends IFlavoredSimpleStatusProps {
  readonly label: string;
}

type SimpleStatusFlavorInfo = Pick<ISimpleStatusProps, 'icon' | InjectedPropKeys>;

const createFlavoredSimpleStatus = ({
  flavor,
  icon: flavorIcon,
  iconColor,
  iconColorInverse,
  labelColor,
  labelColorInverse,
  ...rest
}: SimpleStatusFlavorInfo) => {
  const SimpleStatusFlavored = React.forwardRef<
    HTMLDivElement,
    React.PropsWithChildren<IFlavoredSimpleStatusProps>
  >(({ icon, ...otherProps }, forwardedRef) => (
    <BaseSimpleStatusComponent
      flavor={flavor}
      ref={forwardedRef}
      icon={icon ?? flavorIcon}
      iconColor={iconColor}
      iconColorInverse={iconColorInverse}
      labelColor={labelColor}
      labelColorInverse={labelColorInverse}
      {...rest}
      {...otherProps}
    />
  ));

  SimpleStatusFlavored.propTypes = baseSimpleStatusPropTypes;
  SimpleStatusFlavored.displayName = `SimpleStatus${flavor}`;
  return SimpleStatusFlavored;
};

export const SimpleStatusSuccess = createFlavoredSimpleStatus({
  flavor: 'Success',
  iconColor: colorSuccessIcon,
  iconColorInverse: colorSuccessIconInverse,
  labelColor: colorSuccessText,
  labelColorInverse: colorSuccessTextInverse,
  ...getDataUiObjectNameAttribute('success'),
});

export const SimpleStatusWarning = createFlavoredSimpleStatus({
  flavor: 'Warning',
  icon: Icons.ExclamationTriangle,
  iconColor: colorWarningIcon,
  iconColorInverse: colorWarningIconInverse,
  labelColor: colorWarningText,
  labelColorInverse: colorWarningTextInverse,
  ...getDataUiObjectNameAttribute('warning'),
});

export const SimpleStatusError = createFlavoredSimpleStatus({
  flavor: 'Error',
  icon: Icons.ExclamationTriangleInverted,
  iconColor: colorAlertIcon,
  iconColorInverse: colorAlertIconInverse,
  labelColor: colorAlertText,
  labelColorInverse: colorAlertTextInverse,
  ...getDataUiObjectNameAttribute('error'),
}) as React.FC<IFlavoredSimpleStatusPropsRequiringLabel>;

export const SimpleStatusDefault = createFlavoredSimpleStatus({
  flavor: 'Default',
  iconColor: colorIconHint,
  iconColorInverse: colorIconHintInverse,
  labelColor: colorTextLowEmphasis,
  labelColorInverse: colorTextLowEmphasisInverse,
  ...getDataUiObjectNameAttribute('default'),
});
