import { Card } from '@kontent-ai/component-library/Card';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';
import { HtmlSettingsPageTitle } from '../../../../_shared/components/HtmlSettingsPageTitle.tsx';
import { PageTitle } from '../../../../_shared/components/PageTitle.tsx';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { EnvironmentSettingsAppNames } from '../../root/constants/EnvironmentSettingsAppNames.ts';
import { AuditLogFilter } from '../containers/AuditLogFilter.tsx';
import { AuditLogListing } from '../containers/AuditLogListing.tsx';

export interface IAuditLogProps {
  readonly areEventsLoaded: boolean;
  readonly hasAnyResults: boolean;
  readonly isFilterSet: boolean;
}

export const AuditLog: React.FC<IAuditLogProps> = ({
  areEventsLoaded,
  hasAnyResults,
  isFilterSet,
}) => {
  if (!hasAnyResults && !isFilterSet && areEventsLoaded) {
    return (
      <div className="canvas__inner-section">
        <PageTitle css={`margin-bottom: ${Spacing.XL}px`}>
          {EnvironmentSettingsAppNames.AuditLog}
        </PageTitle>
        <EmptyState>
          <EmptyState.Title>Nothing has been logged yet.</EmptyState.Title>
          <EmptyState.Content>Start using the app and logs will appear here.</EmptyState.Content>
        </EmptyState>
      </div>
    );
  }

  return (
    <div className="canvas__inner-section canvas__inner-section--restricted-width">
      <HtmlSettingsPageTitle settingsAppName={EnvironmentSettingsAppNames.AuditLog} />

      <Stack spacing={Spacing.XL}>
        <PageTitle>{EnvironmentSettingsAppNames.AuditLog}</PageTitle>
        <Card
          cardLabel={EnvironmentSettingsAppNames.AuditLog}
          component="section"
          {...getDataUiAppNameAttribute(DataUiAppName.AuditLog)}
        >
          <Card.Body>
            <AuditLogFilter />
            <AuditLogListing />
          </Card.Body>
        </Card>
      </Stack>
    </div>
  );
};

AuditLog.displayName = 'AuditLog';
