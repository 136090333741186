import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import {
  ContentTypeSnippet_Listing_InitFinished,
  ContentTypeSnippet_Listing_InitStarted,
} from '../../constants/snippetActionTypes.ts';
import { contentTypeSnippetListingRouteEntered } from '../snippetsActions.ts';

interface IDeps {
  readonly loadSnippetsData: (abortSignal?: AbortSignal) => ThunkPromise;
}

export const started = () => ({ type: ContentTypeSnippet_Listing_InitStarted }) as const;
export const finished = () => ({ type: ContentTypeSnippet_Listing_InitFinished }) as const;
export type InitContentTypeSnippetListingActionTypes =
  | ReturnType<typeof started>
  | ReturnType<typeof finished>;

export const createInitContentTypeSnippetListingAction =
  (deps: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(started());

    dispatch(contentTypeSnippetListingRouteEntered());
    await dispatch(deps.loadSnippetsData(abortSignal));

    dispatch(finished());
  };
