import { InputState } from '@kontent-ai/component-library/Input';
import { useMemo } from 'react';
import { useController } from 'react-hook-form';
import { ValidatedMultipleOptionSelect } from '../../../../../_shared/components/input/ValidatedMultipleOptionSelect.tsx';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { HookFormProps } from '../../../../../_shared/types/hookFormProps.ts';
import {
  DataUiCollection,
  DataUiInput,
  getDataUiCollectionAttribute,
  getDataUiInputAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { EntityWebhookAction } from '../../../../../data/models/webhooks/EntityWebhookSetting.ts';
import { anyContentTypeContentType } from '../../../constants/anyContentTypeContentType.ts';
import { ContentTypeOption } from '../../../models/ContentTypeOption.ts';
import { IEntityWebhookFormShape } from '../../../models/IEntityWebhookFormShape.type.ts';
import { getSortedContentTypeOptionsListWithAnyContentTypeFirst } from '../../../selectors/getSortedContentTypeOptionsListWithAnyContentTypeFirst.ts';
import { handleAnyContentTypeContentTypeOnContentTypesChangedInFormInput } from '../../../utils/anyContentTypeContentTypeBehaviorUtils.ts';
import { renderFilterOption } from '../../../utils/renderFilterOption.tsx';
import { EntityWebhookValidationError } from '../EntityWebhookValidationError.tsx';

type Props = {
  readonly formProps: HookFormProps<IEntityWebhookFormShape>;
};

export const EntityWebhookContentTypeFilterSelectors = ({ formProps }: Props) => {
  const { control } = formProps;
  const { field } = useController({ control, name: 'triggers.contentTypeTrigger' });

  const contentTypes = useSelector((s) => s.data.contentTypes.byId);
  const contentTypeOptions = useMemo(
    () => getSortedContentTypeOptionsListWithAnyContentTypeFirst(contentTypes),
    [contentTypes],
  );

  const inputState =
    field.value.checked && field.value.actions.some((a) => a !== EntityWebhookAction.Created)
      ? InputState.Default
      : InputState.Disabled;

  const isEmptyContentTypeFilter = field.value.contentTypeIds.length < 1;

  const normalize = (
    formValues: readonly ContentTypeOption[],
    previousValues: readonly ContentTypeOption[],
  ): readonly ContentTypeOption[] => {
    const newValues = handleAnyContentTypeContentTypeOnContentTypesChangedInFormInput(
      formValues,
      previousValues,
    );
    field.onChange({
      ...field.value,
      contentTypeIds: newValues.map((option) => option.id),
    });
    return newValues;
  };

  return (
    <>
      <ValidatedMultipleOptionSelect<IEntityWebhookFormShape, ContentTypeOption>
        formProps={formProps}
        inputState={inputState}
        items={contentTypeOptions}
        label="Content type"
        name="triggers.contentTypeTrigger.contentTypeIds"
        normalize={normalize}
        placeholder="Select content types"
        renderSelectedOption={renderFilterOption(anyContentTypeContentType.id)}
        tooltipText={
          field.value.actions.includes(EntityWebhookAction.Created)
            ? 'The criteria don’t apply to the “Content type created” event.'
            : undefined
        }
        verticalMenuDataAttributes={getDataUiCollectionAttribute(
          DataUiCollection.ContentTypeFilter,
        )}
        {...getDataUiInputAttribute(DataUiInput.EntityWebhookContentTypeFilter)}
      />
      {isEmptyContentTypeFilter && inputState === InputState.Default && (
        <EntityWebhookValidationError message="Please select at least one content type." />
      )}
    </>
  );
};

EntityWebhookContentTypeFilterSelectors.displayName = 'EntityWebhookContentTypeFilterFormFields';
