import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Spacing, px } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { AssetTilePreview } from '../../../../../_shared/components/AssetTile/AssetTilePreview.tsx';
import { AssetTileProgressBar } from '../../../../../_shared/components/AssetTile/AssetTileProgressBar.tsx';
import { AssetTileSummary } from '../../../../../_shared/components/AssetTile/AssetTileSummary.tsx';
import { Option } from '../../../../../_shared/components/Options/Option.tsx';
import { TrackUserEventWithData } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { OptionMode } from '../../../../../_shared/models/optionMode.ts';
import { getAssetDescriptionBySelectedLanguage } from '../../../../../_shared/selectors/AssetTile/getAssetDescription.ts';
import { singleOrDoubleClick } from '../../../../../_shared/utils/clickUtils.ts';
import { getDataUiObjectNameAttribute } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { PropTypeOrNull } from '../../../../../_shared/utils/propTypesValidators.ts';
import { Asset, IAsset } from '../../../../../data/models/assets/Asset.ts';
import { IAssetValidationResult } from '../../../../itemEditor/features/ContentItemEditing/components/elements/asset/AssetTile.tsx';
import { useAssetTileSelection } from '../../context/AssetListing/AssetTileSelectionContext.tsx';
import { AssetTileActions } from './AssetTileActions.tsx';
import { FlexingTile } from './FlexingTile.tsx';

export const AssetThumbnailBottomClassname = 'asset-thumbnail__bottom';

const StyledOption = styled(Option)`
    align-items: baseline;
    
    ::before {
      position: relative;
      top: ${px(Spacing.XS)};
    }
  `;

export interface IAssetTileStateProps {
  readonly collectionName: string | null;
  readonly disabled?: boolean;
  readonly folderName?: string;
  readonly folderPathTooltip?: string;
  readonly isSelectable: boolean;
  readonly isUncategorized: boolean | null;
  readonly isValidType: boolean;
  readonly showHeight: boolean;
  readonly showWidth: boolean;
  readonly selectedLanguageId: Uuid;
  readonly validationResult: IAssetValidationResult;
}

export interface IAssetTileDispatchProps {
  readonly trackUserEvent?: TrackUserEventWithData;
}

export interface IAssetTileOwnProps {
  readonly item: IAsset;
  readonly onClick?: (assetId: Uuid) => void;
  readonly onDoubleClick?: (assetId: Uuid) => void;
  readonly searchPhrase?: string;
}

type Props = IAssetTileStateProps & IAssetTileDispatchProps & IAssetTileOwnProps;

const propTypes: PropTypesShape<Props> = {
  // Own props
  item: Asset.propTypeValidator.isRequired,
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
  searchPhrase: PropTypes.string,

  // State props
  collectionName: PropTypes.string,
  disabled: PropTypes.bool,
  folderName: PropTypes.string,
  folderPathTooltip: PropTypes.string,
  isSelectable: PropTypes.bool.isRequired,
  isUncategorized: PropTypeOrNull(PropTypes.bool),
  isValidType: PropTypes.bool.isRequired,
  selectedLanguageId: PropTypes.string.isRequired,
  showHeight: PropTypes.bool.isRequired,
  showWidth: PropTypes.bool.isRequired,
  validationResult: PropTypes.shape({
    isAssetFileSizeValid: PropTypes.bool,
    isAssetFileTypeValid: PropTypes.bool,
    isAssetHeightValid: PropTypes.bool,
    isAssetWidthValid: PropTypes.bool,
  }).isRequired,

  // Dispatch props
  trackUserEvent: PropTypes.func,
};

export const AssetTile: React.FC<Props> = ({
  collectionName,
  disabled,
  folderName,
  folderPathTooltip,
  isSelectable,
  isUncategorized,
  isValidType,
  item,
  onClick,
  onDoubleClick,
  searchPhrase,
  selectedLanguageId,
  showHeight,
  showWidth,
  trackUserEvent,
  validationResult,
}) => {
  const { selectedAssets, toggleAssetSelection: onSelectAsset } = useAssetTileSelection();
  const isSelected = selectedAssets.has(item.id);

  const onAssetSelected = (_isAssetSelected: boolean, isShiftPressed: boolean): void => {
    onSelectAsset(item.id, isShiftPressed);
  };

  const getSummary = (): JSX.Element => {
    return (
      <AssetTileSummary
        asset={item}
        collectionName={collectionName}
        folderName={folderName}
        folderPathTooltip={folderPathTooltip}
        searchPhrase={searchPhrase || ''}
        isHeightLimitationSet={showHeight}
        isWidthLimitationSet={showWidth}
        validationResult={validationResult}
      />
    );
  };

  const getAssetTileOption = (): JSX.Element => (
    <div className="asset-thumbnail__asset-selection">
      <StyledOption
        showFullSize
        label={getSummary()}
        isSelected={isSelected}
        accessibleLabel="Select asset"
        mode={OptionMode.InvertedMultiple}
        onOptionSelected={onAssetSelected}
      />
    </div>
  );

  const isDisabled = (): boolean => disabled || !!item._uploading;

  const _onClick = (): void => {
    if (onClick && !item._uploading) {
      onClick(item.id);
    }
  };

  const _onDoubleClick = (): void => {
    if (onDoubleClick && !item._uploading) {
      onDoubleClick(item.id);
    }
  };

  const isClickable = isValidType && !item._failed && !item._uploading;

  const handleClick = isClickable ? singleOrDoubleClick(_onClick, _onDoubleClick) : undefined;
  const showCheckbox = isSelectable && isClickable && !!onSelectAsset;

  const altText = getAssetDescriptionBySelectedLanguage(item.descriptions, selectedLanguageId);

  return (
    <FlexingTile>
      <Tooltip
        tooltipText={isValidType ? undefined : 'This asset is not an image.'}
        placement="right"
      >
        <div
          {...getDataUiObjectNameAttribute(item.title || item.filename)}
          className={classNames('asset-thumbnail', 'asset-thumbnail--is-in-asset-library', {
            'asset-thumbnail--is-disabled': isDisabled(),
            'asset-thumbnail--has-focus': isSelected,
            'asset-thumbnail--is-not-clickable': !isClickable,
            'asset-thumbnail--is-not-selectable': !isClickable,
          })}
        >
          {item && !item.archived && trackUserEvent && (
            <AssetTileActions
              asset={item}
              isUncategorized={isUncategorized}
              trackUserEvent={trackUserEvent}
            />
          )}
          <div className="asset-thumbnail__preview-pane" onClick={handleClick}>
            <AssetTileProgressBar asset={item} />
            <AssetTilePreview asset={item} altText={altText} />
          </div>
          <div
            className={classNames(AssetThumbnailBottomClassname, {
              [`${AssetThumbnailBottomClassname}--with-padding`]: !showCheckbox,
            })}
            onClick={showCheckbox ? undefined : handleClick}
          >
            {showCheckbox ? getAssetTileOption() : getSummary()}
          </div>
        </div>
      </Tooltip>
    </FlexingTile>
  );
};

AssetTile.displayName = 'AssetTile';
AssetTile.propTypes = propTypes;
