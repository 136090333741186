import { Card } from '@kontent-ai/component-library/Card';
import { PaperLevel } from '@kontent-ai/component-library/Paper';
import { Collection } from '@kontent-ai/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { DialogState } from '../../../../component-library/components/Dialogs/DialogStateEnum.ts';
import { PopoverFrame } from '../../../../component-library/components/Dialogs/Popover/components/PopoverFrame.tsx';
import { usePopoverDialog } from '../../../../component-library/components/Dialogs/Popover/usePopoverDialog.tsx';
import { isCodenameUnique } from '../../../applications/contentModels/shared/utils/typeCodenameUtils.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { BarItemCodenameAction } from '../BarItems/Actions/BarItemCodenameAction.tsx';
import { Codename } from './Codename.tsx';

type Props = {
  readonly codename: string | null;
  readonly maxLength?: number;
  readonly relatedCodenames?: ReadonlySet<string>;
  readonly onCodenameChange?: (codename: string) => void;
  readonly onCodenameCopy?: () => void;
  readonly onCodenameEdit?: () => void;
};

export const CodenameBarItemButton: React.FC<Props> = (props) => {
  const {
    codename: codenameFromProps,
    maxLength,
    onCodenameChange,
    onCodenameCopy,
    onCodenameEdit,
    relatedCodenames,
  } = props;

  const { isOpen, openDialog, closeDialog, targetProps, popoverDialogProps } = usePopoverDialog({
    dialogState: DialogState.Default,
    headline: 'Foo',
    placement: 'bottom-end',
    disabledFocusLock: true,
  });

  if (!codenameFromProps) {
    return null;
  }

  const isCodenameUniqueCallback = (codename: string) =>
    isCodenameUnique(
      codename,
      relatedCodenames && Collection.remove(relatedCodenames, codenameFromProps),
    );

  return (
    <>
      <BarItemCodenameAction
        onToggle={isOpen ? closeDialog : openDialog}
        isActive={isOpen}
        {...targetProps}
      />
      <PopoverFrame {...popoverDialogProps}>
        <Card
          level={PaperLevel.Popout}
          cardLabel="Codename"
          component="section"
          {...getDataUiElementAttribute(DataUiElement.CodenameForm)}
        >
          <Card.Body>
            <Codename
              codename={codenameFromProps}
              isEditable={!!onCodenameChange}
              isCodenameUnique={isCodenameUniqueCallback}
              maxLength={maxLength}
              onCodenameChange={onCodenameChange}
              onCopyCompleted={closeDialog}
              onEdited={onCodenameEdit}
              onCopied={onCodenameCopy}
            />
          </Card.Body>
        </Card>
      </PopoverFrame>
    </>
  );
};

CodenameBarItemButton.displayName = 'CodenameBarItemButton';
CodenameBarItemButton.propTypes = {
  codename: PropTypes.string,
  maxLength: PropTypes.number,
  onCodenameChange: PropTypes.func,
  onCodenameCopy: PropTypes.func,
  onCodenameEdit: PropTypes.func,
  relatedCodenames: PropTypes.instanceOf<ReadonlySet<string>>(Set),
};
