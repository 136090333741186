import { useCallback, useMemo } from 'react';
import { useEditorWithPlugin } from '../../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginCreator } from '../../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../../editorCore/types/Editor.contract.type.ts';
import { Apply, EditorPlugin } from '../../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../../editorCore/utils/decorable.ts';
import { withDisplayName } from '../../../editorCore/utils/withDisplayName.ts';
import { CanCreateComment, CommentsPlugin } from '../../../plugins/comments/CommentsPlugin.tsx';
import { AutoGeneratedUrlSlugPlugin } from './AutoGeneratedUrlSlugPlugin.tsx';

export type DisableCommentsForAutoGeneratedUrlSlugPlugin = EditorPlugin<
  None,
  None,
  None,
  [CommentsPlugin, AutoGeneratedUrlSlugPlugin]
>;

export const useDisableCommentsForAutoGeneratedUrlSlug: PluginCreator<
  DisableCommentsForAutoGeneratedUrlSlugPlugin
> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('DisableCommentsForAutoGeneratedUrlSlugPlugin', {
        ComposedEditor: (props) => {
          const { customMode } = props;

          const canCreateComment: Decorator<CanCreateComment> = useCallback(
            (baseCanCreateComment) => () => customMode && baseCanCreateComment(),
            [customMode],
          );

          const apply: Apply<DisableCommentsForAutoGeneratedUrlSlugPlugin> = useCallback(
            (state) => {
              state.canCreateComment.decorate(canCreateComment);
              return {};
            },
            [canCreateComment],
          );

          return useEditorWithPlugin(baseEditor, props, { apply });
        },
      }),
    [baseEditor],
  );
