import { useCallback, useMemo } from 'react';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { EditorPlugin } from '../../editorCore/types/Editor.plugins.type.ts';
import { withDisplayName } from '../../editorCore/utils/withDisplayName.ts';
import { EditorStatisticsApi } from './api/EditorStatisticsApi.type.ts';
import { IEditorStatistics } from './api/editorStatistics.ts';
import { createEditorStatisticsApi } from './api/editorStatisticsApi.ts';

export type ApiStatisticsPlugin = EditorPlugin<None, None, EditorStatisticsApi>;

export const useApiStatistics: PluginCreator<ApiStatisticsPlugin, [IEditorStatistics | null]> = (
  baseEditor,
  statistics,
) =>
  useMemo(
    () =>
      withDisplayName('StatisticsPlugin', {
        ComposedEditor: (props) => {
          const api = useMemo(() => createEditorStatisticsApi(statistics), [statistics]);
          const getApiMethods = useCallback(() => api, [api]);

          return useEditorWithPlugin<ApiStatisticsPlugin>(baseEditor, props, { getApiMethods });
        },
      }),
    [baseEditor, statistics],
  );
