import { usePrevious } from '@kontent-ai/hooks';
import { noOperation } from '@kontent-ai/utils';
import classNames from 'classnames';
import { EditorProps, EditorState } from 'draft-js';
import { HTMLProps, useCallback, useEffect, useMemo, useState } from 'react';
import { ConnectDropTarget } from 'react-dnd';
import { useThrottledCallback } from 'use-debounce';
import {
  DataUiElement,
  DataUiInput,
  getDataUiElementAttribute,
  getDataUiInputAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { RichTextHighlighter } from '../../components/utility/RichTextHighlighter.tsx';
import { useEditorStateCallbacks } from '../../editorCore/hooks/useEditorStateCallbacks.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { GetEditorRef, useGetEditorRef } from '../../editorCore/hooks/useGetEditorRef.ts';
import { BaseEditorProps, OnUpdate } from '../../editorCore/types/Editor.base.type.ts';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { DecoratedEditor } from '../../editorCore/types/Editor.decorated.type.ts';
import {
  Apply,
  EditorPlugin,
  Init,
  PluginState,
  Render,
} from '../../editorCore/types/Editor.plugins.type.ts';
import { DecorableFunction, Decorator, decorable } from '../../editorCore/utils/decorable.ts';
import {
  RichTextHighlightUpdateThrottleInterval,
  RteClassName,
  getBlockClass,
  getEditorIdClassName,
} from '../../editorCore/utils/editorComponentUtils.ts';
import { withDisplayName } from '../../editorCore/utils/withDisplayName.ts';
import { decorateBlocksWithAdjacentBlockTypes } from '../../utils/consistency/editorConsistencyUtils.ts';
import { getSelectionEdgeStatus } from '../../utils/editorSelectionUtils.ts';
import { emptyEditorState } from '../../utils/general/editorEmptyValues.ts';
import { FocusPlugin } from '../behavior/FocusPlugin.tsx';
import { WrapperPlugin } from './WrapperPlugin.tsx';
import {
  HighlightState,
  getHighlightedBlockKeys,
  getInitialHighlightState,
  shouldUpdateHighlightedBlockKeys,
} from './utils/editorHighlightUtils.ts';

export type RteProps = Readonly<
  Omit<HTMLProps<HTMLDivElement>, 'ref' | 'id'> & { connectDropTarget?: ConnectDropTarget }
>;
const initialRteProps: RteProps = {};

export type RteInputProps = Readonly<Omit<HTMLProps<HTMLDivElement>, 'ref' | 'onClick'>>;
const initialRteInputProps: RteInputProps = {};

export type OnHighlightedBlocksChanged = (highlightedBlockKeys: ReadonlySet<string>) => void;

type StylesPluginState = {
  readonly getRteInputRef: GetEditorRef<HTMLDivElement>;
  readonly getRteRef: GetEditorRef<HTMLDivElement>;
  readonly onHighlightedBlocksChanged: DecorableFunction<OnHighlightedBlocksChanged>;
  readonly renderOverlays: DecorableFunction<Render<StylesPlugin>>;
  readonly renderStatus: DecorableFunction<Render<StylesPlugin>>;
  readonly rteInputProps: RteInputProps;
  readonly rteProps: RteProps;
};

type StylesPluginProps = {
  readonly className?: string;
  readonly dataUiElement?: DataUiElement;
  readonly dataUiInput?: DataUiInput;
  readonly id?: string;
  readonly isInvalid?: boolean;
};

export type StylesPlugin = EditorPlugin<
  StylesPluginState,
  StylesPluginProps,
  None,
  [FocusPlugin, WrapperPlugin]
>;

const getHighlight = (
  editorState: EditorState,
  currentHighlight: HighlightState,
): HighlightState => {
  const selection = editorState.getSelection();

  if (selection.getHasFocus()) {
    const content = editorState.getCurrentContent();
    const newSelectionEdgeStatus = getSelectionEdgeStatus(content, selection);

    if (
      shouldUpdateHighlightedBlockKeys(
        currentHighlight.selection,
        currentHighlight.selectionEdgeStatus,
        selection,
        newSelectionEdgeStatus,
      )
    ) {
      return {
        highlighted: getHighlightedBlockKeys(content, selection),
        selection,
        selectionEdgeStatus: getSelectionEdgeStatus(content, selection),
      };
    }
  }

  if (currentHighlight.selection.getHasFocus()) {
    // Reset highlight upon losing focus to prevent flicker on regaining focus
    return getInitialHighlightState(editorState);
  }

  return currentHighlight;
};

type EditorWithStylesProps = Pick<BaseEditorProps, 'disabled'> & {
  readonly highlight: HighlightState;
};

const EditorWithStyles: DecoratedEditor<StylesPlugin, EditorWithStylesProps> = ({
  baseRender,
  className,
  dataUiElement,
  dataUiInput,
  disabled,
  highlight,
  id,
  isInvalid,
  state,
}) => {
  const {
    editorProps: { blockStyleFn: parentBlockStyleFn },
    editorState,
    focus,
    getEditorId,
    getRteInputRef,
    getRteRef,
    isEditorLocked,
    renderOverlays,
    renderStatus,
    rteInputProps,
    rteProps: { connectDropTarget, ...rteProps },
  } = state;

  const blockStyleFn = useCallback<Required<EditorProps>['blockStyleFn']>(
    (block) => classNames(getBlockClass(block), parentBlockStyleFn?.(block)),
    [parentBlockStyleFn],
  );

  const stateWithStyles: PluginState<StylesPlugin> = {
    ...state,
    editorProps: {
      ...state.editorProps,
      blockStyleFn,
    },
  };

  const hasFocus = editorState.getSelection().getHasFocus();
  const editorIsLocked = isEditorLocked();

  const editor = (
    <div
      ref={getRteRef()}
      id={id}
      {...rteProps}
      className={classNames(rteProps.className, className, RteClassName, {
        'rte--is-disabled': disabled,
        'rte--is-locked': editorIsLocked,
        'rte--has-focus': hasFocus,
      })}
      {...(dataUiInput && getDataUiInputAttribute(dataUiInput))}
      {...(dataUiElement && getDataUiElementAttribute(dataUiElement))}
    >
      {renderOverlays(state)}
      <div
        ref={getRteInputRef()}
        {...rteInputProps}
        className={classNames(
          rteInputProps.className,
          'rte__content',
          getEditorIdClassName(getEditorId()),
          {
            'rte__content--is-disabled': disabled,
            'rte__content--has-focus': !disabled && hasFocus,
            'rte__content--has-error': isInvalid,
          },
        )}
        onClick={focus}
        {...getDataUiElementAttribute(DataUiElement.RteContent)}
      >
        {baseRender(stateWithStyles)}
      </div>
      {hasFocus && <RichTextHighlighter editorId={getEditorId()} {...highlight.highlighted} />}
      {renderStatus(state)}
    </div>
  );

  return connectDropTarget ? connectDropTarget(editor) : editor;
};

EditorWithStyles.displayName = 'EditorWithStyles';

const init: Init = (state) => ({
  content: decorateBlocksWithAdjacentBlockTypes(state.content),
});

const renderNull: Render<StylesPlugin> = () => null;

export const useStyles: PluginCreator<StylesPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('StylesPlugin', {
        ComposedEditor: (props) => {
          const { className, dataUiElement, dataUiInput, disabled, id, isInvalid } = props;

          const getRteInputRef = useGetEditorRef<HTMLDivElement>();
          const getRteRef = useGetEditorRef<HTMLDivElement>();

          const { decorateWithEditorStateCallbacks, onHighlightedBlocksChanged } =
            useEditorStateCallbacks<StylesPlugin>();

          const [highlight, setHighlight] = useState(() =>
            getInitialHighlightState(emptyEditorState),
          );

          const highlightedBlockKeys = highlight.highlighted.blockKeys;
          const previousHighlightedBlockKeys = usePrevious(highlightedBlockKeys);
          useEffect(() => {
            if (highlightedBlockKeys !== previousHighlightedBlockKeys) {
              onHighlightedBlocksChanged(highlightedBlockKeys);
            }
          }, [previousHighlightedBlockKeys, highlightedBlockKeys, onHighlightedBlocksChanged]);

          const updateHighlightSelection = useCallback((newEditorState: EditorState): void => {
            setHighlight((currentHighlight) => getHighlight(newEditorState, currentHighlight));
          }, []);

          const updateHighlightSelectionThrottled = useThrottledCallback(
            updateHighlightSelection,
            RichTextHighlightUpdateThrottleInterval,
          );

          const onUpdate: Decorator<OnUpdate> = useCallback(
            (baseOnUpdate) => (params) => {
              updateHighlightSelectionThrottled(params.editorState);
              baseOnUpdate(params);
            },
            [updateHighlightSelectionThrottled],
          );

          const render: Decorator<Render<StylesPlugin>> = useCallback(
            (baseRender) => (state) => (
              <EditorWithStyles
                baseRender={baseRender}
                className={className}
                dataUiElement={dataUiElement}
                dataUiInput={dataUiInput}
                disabled={disabled}
                highlight={highlight}
                id={id}
                isInvalid={isInvalid}
                state={state}
              />
            ),
            [className, disabled, dataUiInput, dataUiElement, id, isInvalid, highlight],
          );

          const apply: Apply<StylesPlugin> = useCallback(
            (state) => {
              decorateWithEditorStateCallbacks(state);
              state.onUpdate.decorate(onUpdate);
              state.render.decorate(render);

              return {
                getRteInputRef,
                getRteRef,
                onHighlightedBlocksChanged: decorable(noOperation),
                renderOverlays: decorable(renderNull),
                renderStatus: decorable(renderNull),
                rteInputProps: initialRteInputProps,
                rteProps: initialRteProps,
              };
            },
            [decorateWithEditorStateCallbacks, getRteInputRef, getRteRef, onUpdate, render],
          );

          return useEditorWithPlugin(baseEditor, props, {
            init,
            apply,
          });
        },
      }),
    [baseEditor],
  );
