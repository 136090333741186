import { useOverlay } from '@react-aria/overlays';
import Tippy from '@tippyjs/react';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import {
  ArrowPosition,
  Popover,
  PopoverIsPointing,
  PopoverMessage,
  PopoverWidth,
} from '../../../../../../../_shared/uiComponents/Popover/Popover.tsx';
import {
  Popovers,
  getDataUiObjectNameAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type QuickActionsLeafMenuWrapperProps = {
  readonly children: React.ReactNode;
  readonly onClickOutside: () => void;
  readonly renderButton: (ref: React.RefObject<HTMLElement> | undefined) => React.ReactElement;
  readonly shouldCloseOnClickOutside?: (element: HTMLElement) => boolean;
};

const propTypes: PropTypesShape<QuickActionsLeafMenuWrapperProps> = {
  children: PropTypes.node.isRequired,
  onClickOutside: PropTypes.func.isRequired,
  renderButton: PropTypes.func.isRequired,
  shouldCloseOnClickOutside: PropTypes.func,
};

const popperOptions = {
  modifiers: [
    {
      name: 'flip',
      options: {
        fallbackPlacements: ['bottom-end'],
      },
    },
  ],
};

export const QuickActionsLeafMenuWrapper: React.FC<QuickActionsLeafMenuWrapperProps> = (props) => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  useOverlay(
    {
      isDismissable: true,
      isOpen: true,
      onClose: props.onClickOutside,
      shouldCloseOnInteractOutside: props.shouldCloseOnClickOutside,
    },
    popoverRef,
  );

  return (
    <>
      {props.renderButton(buttonRef)}
      <Tippy
        appendTo="parent"
        interactive
        offset={[0, 0]}
        placement="bottom-start"
        popperOptions={popperOptions}
        reference={buttonRef}
        render={(attrs) => (
          <Popover
            popoverDataAttribute={{
              ...getDataUiObjectNameAttribute(Popovers.QuickActionPopover),
              ...attrs,
            }}
            orientation={PopoverIsPointing.Up}
            arrowPosition={ArrowPosition.Start}
            width={PopoverWidth.IsMediumWide}
            ref={popoverRef}
          >
            <PopoverMessage>{props.children}</PopoverMessage>
          </Popover>
        )}
        visible
      />
    </>
  );
};

QuickActionsLeafMenuWrapper.displayName = 'QuickActionsLeafMenuWrapper';
QuickActionsLeafMenuWrapper.propTypes = propTypes;
