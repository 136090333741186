import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { compose } from '../../../../../_shared/utils/func/compose.ts';
import { IProjectContributor } from '../../../../../data/models/users/ProjectContributor.ts';
import { initializeUserDetail } from '../../actions/thunkUsersActions.ts';
import { closeUserDetail } from '../../actions/usersActions.ts';

interface IUserDetailDataEnsurerOwnProps {
  readonly userId: string;
  readonly userListingLink: string;
  readonly children: (user: IProjectContributor, onNavigateBack: () => void) => React.ReactElement;
}

export const UserDetailDataEnsurer: React.FC<IUserDetailDataEnsurerOwnProps> = ({
  userListingLink,
  userId,
  children,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.usersApp.projectContributors.get(userId));
  const isInitialized = useSelector((state) => state.usersApp.userDetail.isInitialized);
  const onNavigateBack = useCallback(
    () => history.push(userListingLink),
    [history, userListingLink],
  );

  const onUserNotFound = useCallback(() => {
    history.push(userListingLink);
  }, [history, userListingLink]);

  useEffect(() => {
    dispatch(initializeUserDetail(userId, onUserNotFound));

    return compose(dispatch, closeUserDetail) as () => void;
  }, [userId, onUserNotFound]);

  if (!isInitialized || !user) {
    return null;
  }

  return children(user, onNavigateBack);
};
