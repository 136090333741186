import React, { useRef } from 'react';
import { WaitForEditedItemInitialized } from '../../itemEditor/features/ContentItemEditing/containers/WaitForEditedItemInitialized.tsx';
import { ContentItemSidebar } from '../../itemEditor/features/ContentItemEditing/containers/sidebar/ContentItemSidebar.tsx';
import { WebSpotlightTabs } from './WebSpotlightTabs.tsx';
import { WebSpotlightWorkspace } from './WebSpotlightWorkspace.tsx';
import { WebSpotlightPreview } from './preview/WebSpotlightPreview.tsx';

export const WebSpotlightPreviewView: React.FC = () => {
  const workspaceRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <WebSpotlightTabs />
      <WebSpotlightWorkspace workspaceRef={workspaceRef}>
        <WebSpotlightPreview />
      </WebSpotlightWorkspace>
      <WaitForEditedItemInitialized renderLoader={() => null}>
        <ContentItemSidebar outsideClickElementRefs={workspaceRef} />
      </WaitForEditedItemInitialized>
    </>
  );
};

WebSpotlightPreviewView.displayName = 'WebSpotlightPreviewView';
