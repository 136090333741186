import PropTypes from 'prop-types';
import React, { ComponentProps } from 'react';
import { Tag } from '../../../../../client/component-library/components/Tag/Tag.tsx';
import { BaseColor } from '../../../tokens/quarks/colors.ts';
import { CLPropTypes } from '../../../validators/propTypes.ts';

type CountStatusProps = ComponentProps<typeof Tag.CountStatus>;
type GeneralTagSpecificProps = Readonly<{
  countValue: CountStatusProps['value'];
  countPlacement: 'before' | 'after';
  label: string;
  children?: never;
}>;

type Props = Omit<ComponentProps<typeof Tag>, 'background'> & GeneralTagSpecificProps;

const propTypes: PropTypeMap<GeneralTagSpecificProps> = {
  countValue: PropTypes.number.isRequired,
  countPlacement: PropTypes.oneOf(['before', 'after'] as const).isRequired,
  label: PropTypes.string.isRequired,
  children: CLPropTypes.never,
};

export const GeneralTag = React.forwardRef<HTMLButtonElement, Props>(
  ({ countValue, countPlacement, label, ...otherProps }, forwardedRef) => (
    <Tag background={BaseColor.Orange30} label={label} ref={forwardedRef} {...otherProps}>
      {countPlacement === 'before' && <Tag.CountStatus value={countValue} />}
      <Tag.Label>{label}</Tag.Label>
      {countPlacement === 'after' && <Tag.CountStatus value={countValue} />}
    </Tag>
  ),
);

GeneralTag.displayName = 'GeneralTag';
GeneralTag.propTypes = propTypes;
