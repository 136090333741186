import { Box } from '@kontent-ai/component-library/Box';
import { Typography } from '@kontent-ai/component-library/tokens';
import { HookFormProps } from '../../../../../_shared/types/hookFormProps.ts';
import { IEntityWebhookFormShape } from '../../../models/IEntityWebhookFormShape.type.ts';
import { EntityWebhookContentItemFilterSelectors as EntityWebhookContentItemFilterSelectorsComponent } from './EntityWebhookContentItemFilterSelectors.tsx';

type Props = {
  readonly formProps: HookFormProps<IEntityWebhookFormShape>;
};

export const EntityWebhookContentItemFilter = ({ formProps }: Props) => {
  return (
    <>
      <Box typography={Typography.HeadlineMedium}>Filters</Box>
      <Box overflowWrap="anywhere" typography={Typography.LabelLarge}>
        Limit the events to content items that match the filtering criteria.
      </Box>
      <EntityWebhookContentItemFilterSelectorsComponent formProps={formProps} />
    </>
  );
};
