import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { projectLocationsLoadingInit } from '../../../../_shared/actions/sharedActions.ts';
import { clearSelectedSubscription as clearSubscription } from '../../../subscriptionManagement/shared/actions/subscriptionManagementActions.ts';
import { ProjectListing_Init } from '../../constants/projectActionTypes.ts';

interface IInitializeProjectManagementDependencies {
  readonly loadProjects: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly startPollingProjects: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadAvailableProjectLocations: (abortSignal?: AbortSignal) => ThunkPromise;
}

const projectListingInit = () =>
  ({
    type: ProjectListing_Init,
  }) as const;

export type InitializeProjectManagementActionsType = ReturnType<typeof projectListingInit>;

export const createInitializeProjectManagementAction =
  (deps: IInitializeProjectManagementDependencies) =>
  (clearSelectedSubscription?: boolean, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(projectListingInit());
    dispatch(projectLocationsLoadingInit());

    await dispatch(deps.loadProjects(abortSignal));
    await dispatch(deps.startPollingProjects(abortSignal));
    await dispatch(deps.loadAvailableProjectLocations(abortSignal));

    if (clearSelectedSubscription) {
      dispatch(clearSubscription());
    }
  };
