import React, { useEffect, useRef } from 'react';
import {
  feedbackModalSubmitted,
  modalDismissed,
} from '../../../../_shared/actions/sharedActions.ts';
import { useUserFeedback } from '../../../../_shared/hooks/UserFeedback/useUserFeedback.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { ContentModelingFeedbackServerKey } from '../../../../_shared/models/UserPropertiesServerKeys.ts';
import { UserFeedbackSource } from '../../../../_shared/models/events/UserFeedbackEventData.type.ts';
import { contentModelingUserPropertiesSelector } from '../../../../_shared/selectors/umuxSurveySelectors.ts';
import { ContentModelingSurveyQuestionnaire } from './ContentModelingSurveyQuestionnaire.tsx';

// KCL-12891 Survey trigger removed and survey disabled
export const ContentModelingSurveyModal: React.FC = () => {
  const dispatch = useDispatch();

  const isSeenRef = useRef(false);

  const { dismissUserFeedback, submitUserFeedback, showUserFeedback } = useUserFeedback(
    UserFeedbackSource.ContentModeling,
    ContentModelingFeedbackServerKey,
    contentModelingUserPropertiesSelector,
  );

  const dismissFeedback = () => {
    dismissUserFeedback();
    dispatch(modalDismissed());
  };

  const submit = async (ease: string, requirements: string, comment: string): Promise<void> => {
    await submitUserFeedback(ease, requirements, comment);
    dispatch(feedbackModalSubmitted());
  };

  useEffect(() => {
    if (!isSeenRef.current) {
      isSeenRef.current = true;
      showUserFeedback();
    }
  }, [showUserFeedback]);

  return <ContentModelingSurveyQuestionnaire onClose={dismissFeedback} onSubmit={submit} />;
};

ContentModelingSurveyModal.displayName = 'ContentModelingSurveyModal';
