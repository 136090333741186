import { EditorState } from 'draft-js';
import PropTypes from 'prop-types';
import React, { ClipboardEvent, useCallback } from 'react';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import {
  IUrlSlugTypeElement,
  UrlSlugTypeElementPropTypes,
} from '../../../../../contentInventory/content/models/contentTypeElements/UrlSlugTypeElement.ts';
import { setRichTextClipboard } from '../../../../../richText/actions/thunkRichTextActions.ts';
import { SimpleMultilineTextDiff } from '../../../../../richText/editors/simpleText/SimpleMultilineTextDiff.tsx';
import { EmptyMetadata } from '../../../../../richText/plugins/clipboard/thunks/setRichTextClipboard.ts';
import { EmptyContentComponents } from '../../../../models/contentItem/ContentComponent.ts';
import {
  IUrlSlugItemElement,
  UrlSlugItemElementPropTypesShape,
} from '../../../../models/contentItemElements/UrlSlugItemElement.ts';
import { ItemElement } from '../../../ContentItemEditing/containers/elements/ItemElement.tsx';
import { ItemElementRefresher } from '../../../ContentItemEditing/containers/elements/ItemElementRefresher.tsx';
import { ReadonlyEmptyElementPlaceholder } from '../../../ContentItemEditing/models/ReadonlyEmptyElementPlaceholder.ts';
import { useRenderPlaceholderText } from '../../hooks/useRenderPlaceholderText.ts';
import { IRevisionItemElementProps } from '../../utils/itemElementRevisionComponentCreator.ts';

type UrlSlugProps = IRevisionItemElementProps<IUrlSlugItemElement, IUrlSlugTypeElement>;

const propTypes: PropTypeMap<UrlSlugProps> = {
  elementData: UrlSlugItemElementPropTypesShape.isRequired,
  hideValidationStatus: PropTypes.bool,
  originalElementData: UrlSlugItemElementPropTypesShape,
  revisionElementModifiedBy: PropTypes.string,
  typeElement: UrlSlugTypeElementPropTypes.isRequired,
};

export const UrlSlug: React.FC<UrlSlugProps> = ({
  elementData,
  hideValidationStatus,
  originalElementData,
  revisionElementModifiedBy,
  typeElement,
}) => {
  const placeholderText = useRenderPlaceholderText(
    ReadonlyEmptyElementPlaceholder.UrlSlugElement,
    originalElementData,
    typeElement,
  );
  const dispatch = useDispatch();
  const onSetRichTextClipboard = useCallback(
    (e: ClipboardEvent, editorState: EditorState) =>
      dispatch(setRichTextClipboard(e, editorState, EmptyContentComponents, EmptyMetadata)),
    [],
  );

  return (
    <ItemElement
      disabled
      hideValidationStatus={hideValidationStatus}
      isDisplayOnly
      typeElement={typeElement}
      revisionChangedBy={revisionElementModifiedBy}
    >
      <ItemElementRefresher
        elementData={elementData}
        renderInput={({ _editorState }: IUrlSlugItemElement) => (
          <SimpleMultilineTextDiff
            className="rte--in-content-item-element"
            editorState={_editorState}
            originalContent={originalElementData?._editorState.getCurrentContent()}
            placeholder={placeholderText ?? undefined}
            setRichTextClipboard={onSetRichTextClipboard}
            spellCheck={false}
          />
        )}
      />
    </ItemElement>
  );
};

UrlSlug.displayName = 'UrlSlug (Revisions)';
UrlSlug.propTypes = propTypes;
