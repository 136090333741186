import React, { useEffect, useState, useCallback } from 'react';
import { trackUserEventWithData } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import {
  FindRightContentTrackedEvent,
  TrackedEvent,
} from '../../../../../_shared/constants/trackedEvent.ts';
import { YourContentTabName } from '../../../../../_shared/constants/yourContentTabName.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { ContentItemId } from '../../../../../_shared/models/ContentItemId.type.ts';
import { OrderBy, OrderByDirection } from '../../../../../_shared/models/OrderBy.ts';
import { repositoryCollection } from '../../../../../_shared/repositories/repositories.ts';
import { createAssignedContentItem } from '../../../../../data/models/listingContentItems/IAssignedListingContentItem.ts';
import { IListingContentItemServerModel } from '../../../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import {
  ListingRequestOptionsModel,
  ListingServerResponseModel,
} from '../../../../../repositories/utils/ensureAllRequestedDataFetched.ts';
import { getOrderBy } from '../../../../contentInventory/content/models/filter/contentItemFilterUtils.ts';
import { entityListWidgetDisplayCount } from '../../shared/constants/widgetConstants.ts';
import { useWidgetListingDataFetcher } from '../../shared/hooks/useWidgetListingDataFetcher.tsx';
import { ItemsAssignedToYouWidget } from '../components/ItemsAssignedToYouWidget.tsx';
import { AssignedContentItemsOrderByColumnCode } from '../constants/assignedContentItemsOrderByColumnCode.ts';

export const ItemsAssignedToYouWidgetContainer: React.FC = () => {
  const dispatch = useDispatch();
  const [numberOfItemsToFetch, setNumberOfItemsToFetch] = useState(entityListWidgetDisplayCount);

  const [orderBy, setOrderBy] = useState<OrderBy<AssignedContentItemsOrderByColumnCode>>({
    columnCode: AssignedContentItemsOrderByColumnCode.LastModified,
    direction: OrderByDirection.Descending,
  });

  const fetcher = useCallback(
    (requestOptions: ListingRequestOptionsModel, abortSignal?: AbortSignal) =>
      itemsAssignedToYouFetcher(orderBy, requestOptions, abortSignal),
    [orderBy],
  );

  const {
    fetchInit,
    fetchMore,
    state: itemsState,
    data: itemsAssignedToYou,
  } = useWidgetListingDataFetcher(fetcher, createAssignedContentItem);

  useEffect(() => fetchInit(numberOfItemsToFetch).cancel, [fetchInit, numberOfItemsToFetch]);

  const changeOrderBy = (
    newOrderBy: OrderBy<AssignedContentItemsOrderByColumnCode>,
    requiredNumberOfItems: number,
  ) => {
    setOrderBy(newOrderBy);
    setNumberOfItemsToFetch(requiredNumberOfItems);
  };

  // Revisit in task KCL-13158
  const trackOpeningItem = (id: ContentItemId, index: number) =>
    dispatch(
      trackUserEventWithData(TrackedEvent.FindRightContent, {
        index,
        itemId: id.itemId,
        name: FindRightContentTrackedEvent.OpenedItemEditingFromYourContent,
        tab: YourContentTabName.AssignedToYou,
      }),
    );

  return (
    <ItemsAssignedToYouWidget
      fetchMore={fetchMore}
      items={itemsAssignedToYou}
      itemsState={itemsState}
      orderBy={orderBy}
      onChangeOrderBy={changeOrderBy}
      onItemClick={trackOpeningItem}
    />
  );
};

const itemsAssignedToYouFetcher = (
  orderBy: OrderBy<AssignedContentItemsOrderByColumnCode>,
  requestOptionsModel: ListingRequestOptionsModel,
  abortSignal?: AbortSignal,
): Promise<ListingServerResponseModel<IListingContentItemServerModel>> =>
  repositoryCollection.contentItemRepository.getItemsAssignedToCurrentUser(
    getOrderBy(orderBy),
    requestOptionsModel,
    abortSignal,
  );
