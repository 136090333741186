import { EditorApiImplementation } from '../../../editorCore/types/Editor.api.type.ts';
import { None } from '../../../editorCore/types/Editor.contract.type.ts';
import { EditorPlugin } from '../../../editorCore/types/Editor.plugins.type.ts';
import {
  doesSelectionContainText,
  getFullBlockTypesAtSelection,
  getMetadataAtSelection,
} from '../../../utils/editorSelectionUtils.ts';
import { CustomInputHandlingPlugin } from '../../customInputHandling/CustomInputHandlingPlugin.tsx';
import { EditorCommandApi } from './EditorCommandApi.type.ts';
import { getCommandStatus as getCommandStatusForMetadata } from './editorCommandUtils.ts';

type CommandsPlugin = EditorPlugin<None, None, EditorCommandApi, [CustomInputHandlingPlugin]>;

export const editorCommandApi: EditorApiImplementation<CommandsPlugin> = {
  getCommandStatus(api, editorState, command) {
    const content = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    const fullBlockTypesAtSelection = getFullBlockTypesAtSelection(content, selection);
    const metadataAtSelection = getMetadataAtSelection(content, selection);
    const currentVisualStyle = api.getCurrentVisualStyle(editorState);
    const selectionContainsText = doesSelectionContainText(selection, metadataAtSelection);

    const status = getCommandStatusForMetadata(
      command,
      fullBlockTypesAtSelection,
      currentVisualStyle,
      metadataAtSelection,
      selectionContainsText,
      api.getLimitations(),
    );
    return status;
  },
};
