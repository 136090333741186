import Immutable from 'immutable';
import React, { useState } from 'react';
import { getComposedElementCodename } from '../../../applications/contentModels/shared/utils/typeElementCodenameComposer.ts';
import { EditCodename } from './EditCodename.tsx';
import { ReadCodename } from './ReadCodename.tsx';

interface ICodenameProps {
  readonly codename: string;
  readonly disabledEditingMessage?: string | null;
  readonly footnoteMessage?: string | null;
  readonly friendlyWarningMessage?: string;
  readonly hideTitle?: boolean;
  readonly isBeingSaved?: boolean;
  readonly isCodenameUnique: (codename: string) => boolean;
  readonly isEditable?: boolean;
  readonly maxLength?: number;
  readonly onCodenameChange?: (codename: string) => void;
  readonly onCopied?: () => void;
  readonly onCopyCompleted?: () => void;
  readonly onDiscardChanges?: () => void;
  readonly onEdited?: () => void;
  readonly relatedCodenames?: Immutable.Set<string>;
  readonly saveCodename?: boolean;
  readonly snippetCodename?: string | null;
}

export const Codename: React.FC<ICodenameProps> = ({
  codename,
  disabledEditingMessage,
  footnoteMessage,
  friendlyWarningMessage,
  hideTitle,
  isBeingSaved,
  isCodenameUnique,
  isEditable,
  maxLength,
  onCodenameChange,
  onCopied,
  onCopyCompleted,
  onEdited,
  onDiscardChanges,
  saveCodename,
  snippetCodename,
}) => {
  const [isEdited, setIsEdited] = useState<boolean>(false);

  const onEdit = (): void => {
    onEdited?.();
    setIsEdited(true);
  };

  const onFinishEditing = (): void => {
    setIsEdited(false);
  };

  const onDiscardedChanges = (): void => {
    onDiscardChanges?.();
    setIsEdited(false);
  };

  return isEdited ? (
    <EditCodename
      codename={codename}
      friendlyWarningMessage={friendlyWarningMessage}
      hideTitle={hideTitle}
      isBeingSaved={isBeingSaved}
      isCodenameUnique={isCodenameUnique}
      maxLength={maxLength}
      onCodenameChange={onCodenameChange}
      onDiscardChanges={onDiscardedChanges}
      onFinishEditing={onFinishEditing}
      saveCodename={saveCodename}
      snippetCodename={snippetCodename}
    />
  ) : (
    <ReadCodename
      composedCodename={getComposedElementCodename(codename, snippetCodename)}
      disabledEditingMessage={disabledEditingMessage}
      footnoteMessage={footnoteMessage}
      hideTitle={hideTitle}
      isEditable={isEditable}
      onCopied={onCopied}
      onCopyCompleted={onCopyCompleted}
      onEdit={onEdit}
    />
  );
};

Codename.displayName = 'Codename';
