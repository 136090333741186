import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { areTimestampsEqual } from '../../../../../../../_shared/utils/dateTime/timeUtils.ts';
import { createFormValidationResolver } from '../../../../../../../_shared/utils/validation/createFormValidationResolver.ts';
import {
  dueDateFormChanged,
  dueDateFormReset,
} from '../../../actions/contentItemEditingActions.ts';
import { DueDateFormBase } from '../../../components/details/DueDate/DueDateFormBase.tsx';
import { AssignmentSections } from '../../../constants/AssignmentSections.ts';
import { IDueDateFormShape } from '../../../models/IDueDateFormShape.type.ts';
import {
  isOtherAssignmentSectionSubmitting as isOtherAssignmentSectionSubmittingSelector,
  isSectionSubmitting as isSectionSubmittingSelector,
} from '../../../selectors/isSectionSubmitting.ts';
import { dueDateFormValidationConfig } from '../../../validation/dueDateFormValidation.ts';
import { useIsWarningDisplayed } from '../../hooks/useIsWarningDisplayed.ts';

export type DueDateFormBaseContainerProps = {
  readonly datePickerId?: string;
  readonly initialDueDate: DateTimeStamp | null;
  readonly onCancel: () => void;
  readonly onConfirm: (values: IDueDateFormShape) => Promise<void>;
};

const hasFormUnsavedChanges = (a: IDueDateFormShape, b: IDueDateFormShape): boolean =>
  !areTimestampsEqual(a.date.value, b.date.value);

const DueDateFormBaseContainer: React.FC<DueDateFormBaseContainerProps> = (props) => {
  const defaultDueDate = props.initialDueDate || '';
  const defaultValues = useMemo(
    (): IDueDateFormShape => ({
      date: {
        value: defaultDueDate,
        isValid: true,
      },
    }),
    [defaultDueDate],
  );

  const unsavedValues = useSelector(
    (s) => s.contentApp.editorUi.editingActions.forms.dueDate.unsavedValues,
  );
  const isOtherAssignmentSectionSubmitting = useSelector((s) =>
    isOtherAssignmentSectionSubmittingSelector(s, AssignmentSections.DueDate),
  );
  const isSectionSubmitting = useSelector((s) =>
    isSectionSubmittingSelector(s, AssignmentSections.DueDate),
  );

  const formProps = useForm<IDueDateFormShape>({
    defaultValues: unsavedValues || defaultValues,
    resolver: createFormValidationResolver(dueDateFormValidationConfig, {}),
  });

  const { handleSubmit, watch } = formProps;
  const dispatch = useDispatch();

  useEffect(() => {
    const subscription = watch((formValues: IDueDateFormShape) => {
      if (hasFormUnsavedChanges(formValues, defaultValues)) {
        dispatch(dueDateFormChanged(formValues));
      } else {
        dispatch(dueDateFormReset());
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, defaultValues]);

  const formValues = watch();
  const hasUnsavedChanges = hasFormUnsavedChanges(formValues, defaultValues);
  const isWarningDisplayed = useIsWarningDisplayed(hasUnsavedChanges);
  const submitForm = handleSubmit(props.onConfirm);

  const resetForm = (): void => {
    props.onCancel();
    dispatch(dueDateFormReset());
  };

  return (
    <DueDateFormBase
      datePickerId={props.datePickerId}
      formProps={formProps}
      initialDueDate={props.initialDueDate}
      isOtherAssignmentSectionSubmitting={isOtherAssignmentSectionSubmitting}
      isSectionSubmitting={isSectionSubmitting}
      isWarningDisplayed={isWarningDisplayed}
      onReset={resetForm}
      onSubmit={submitForm}
    />
  );
};

DueDateFormBaseContainer.displayName = 'DueDateFormBaseContainer';

export { DueDateFormBaseContainer as DueDateFormBase };
