import { UnreachableCaseException } from '@kontent-ai/errors';
import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { IntercomUtils } from '../../../../../../_shared/utils/intercomUtils.ts';
import { logError } from '../../../../../../_shared/utils/logError.ts';
import {
  ItemEditorOperationId,
  parseElementOperationId,
} from '../../../../../contentInventory/content/utils/itemEditorOperationIdUtils.ts';
import {
  EditedContentItemFailure,
  FailureStatus,
} from '../../../../models/contentItem/edited/EditedContentItemStatus.ts';
import { prioritizeFailures } from '../../../../utils/editingSaveFailureutils.ts';
import { refreshItemEditing } from '../../actions/thunkContentItemEditingActions.ts';
import { NonLocalizableSavingFailedNotificationContent } from '../../components/contentItemEditorNotifications/NonLocalizableSavingFailedNotificationContent.tsx';
import { SavingFailedNotificationBar as SavingFailedNotificationBarComponent } from '../../components/contentItemEditorNotifications/SavingFailedNotificationBar.tsx';
import { getEditedContentItemTypeElementData } from '../selectors/getEditedContentItemTypeElementData.ts';
import { ResaveNotificationBar } from './ResaveNotificationBar.tsx';

const getHighestPriorityFailedOperation = (
  store: IStore,
): readonly [operationId: ItemEditorOperationId, failure: EditedContentItemFailure] | null => {
  const [highestPriorityFailureEntry] = prioritizeFailures(
    store.contentApp.editedContentItemStatus.failures,
  );
  return highestPriorityFailureEntry ?? null;
};

export const SavingFailedNotificationBar: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((store) => {
    const [operationId, failure] = getHighestPriorityFailedOperation(store) ?? [];
    const elementId = operationId ? parseElementOperationId(operationId).elementId : null;
    const elementName = elementId
      ? getEditedContentItemTypeElementData(store, elementId)?.name ?? null
      : null;

    return {
      elementName,
      failure,
      operationId,
    };
  });

  if (!data.failure || !data.operationId) {
    return null;
  }

  switch (data.failure.status) {
    case FailureStatus.CodenameIsNotUnique: {
      return null;
    }
    case FailureStatus.MissingCapability: {
      return (
        <SavingFailedNotificationBarComponent
          message="We couldn’t save the item because you lost the permission to modify this item."
          buttonText="Refresh"
          onButtonClick={() => dispatch(refreshItemEditing(history))}
        />
      );
    }
    case FailureStatus.NonLocalizableContentTooLarge: {
      return (
        <SavingFailedNotificationBarComponent
          message={
            <NonLocalizableSavingFailedNotificationContent
              failedVariantIds={data.failure.variantIds}
              elementName={data.elementName ?? null}
            />
          }
          buttonText="Contact us"
          onButtonClick={() => IntercomUtils.showIntercom()}
        />
      );
    }
    case FailureStatus.ContentTooLarge: {
      return (
        <SavingFailedNotificationBarComponent
          message="We couldn’t save the item because its content is too large. Shorten your content and tell us why you need this."
          buttonText="Contact us"
          onButtonClick={() => IntercomUtils.showIntercom()}
        />
      );
    }
    case FailureStatus.ElementTooLarge: {
      return (
        <SavingFailedNotificationBarComponent
          message={`We couldn’t save the item because content in ${
            data.elementName || 'one of the elements'
          } is too long. Try to shorten it and tell us why you need this.`}
          buttonText="Contact us"
          onButtonClick={() => IntercomUtils.showIntercom()}
        />
      );
    }
    case FailureStatus.ModelValidationFailed: {
      return (
        <SavingFailedNotificationBarComponent
          message={`We couldn’t save the item because content in ${
            data.elementName || 'one of the elements'
          } caused an unknown error. Please contact us, to help you get this straight.`}
          buttonText="Contact us"
          onButtonClick={() => IntercomUtils.showIntercom()}
        />
      );
    }
    case FailureStatus.ContentWasModified: {
      return (
        <SavingFailedNotificationBarComponent
          message="We couldn’t save the item. Please refresh the page."
          buttonText="Refresh"
          onButtonClick={() => dispatch(refreshItemEditing(history))}
        />
      );
    }
    case FailureStatus.ElementHasConcurrentConflict: {
      return (
        <SavingFailedNotificationBarComponent
          message={`We can’t save your changes in ${
            data.elementName || 'one of the elements'
          }. You’ve been offline for some time and someone else has updated the element in the meantime.`}
          buttonText="Refresh"
          onButtonClick={() => dispatch(refreshItemEditing(history))}
        />
      );
    }
    case FailureStatus.UnknownFailure: {
      return <ResaveNotificationBar elementName={data.elementName} />;
    }
    default: {
      logError(
        UnreachableCaseException(
          data.failure,
          `Failure status '${data.failure}' is not handled in ${__filename}.`,
        ),
      );
      return null;
    }
  }
};

SavingFailedNotificationBar.displayName = 'SavingFailedNotificationBar';
