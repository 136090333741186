import React from 'react';
import { IFriendlyWarning } from '../../../../../applications/itemEditor/utils/itemElementFriendlyWarningCheckers/types/FriendlyWarnings.ts';
import { PublishMethodSection } from '../../containers/Elements/PublishMethodSection.tsx';
import { PublishingErrorSection } from '../../containers/Elements/PublishingErrorSection.tsx';
import { PublishingQuickTipSection } from '../../containers/Elements/PublishingQuickTipSection.tsx';
import { PublishingFriendlyWarningSection } from '../Elements/PublishingFriendlyWarningSection/PublishingFriendlyWarningSection.tsx';
import { UnpublishDateSection } from '../Elements/UnpublishDateSection.tsx';

export interface IPublishingSectionProps {
  readonly activeUsersNames: ReadonlyArray<string>;
  readonly friendlyWarningReasons: ReadonlyArray<IFriendlyWarning>;
  readonly scheduledToUnpublishAt: DateTimeStamp | null;
}

export const PublishingSection: React.FC<IPublishingSectionProps> = (props) => (
  <>
    <PublishingFriendlyWarningSection
      activeUsersNames={props.activeUsersNames}
      friendlyWarningReasons={props.friendlyWarningReasons}
    />
    <PublishingErrorSection />
    <PublishMethodSection />
    {props.scheduledToUnpublishAt && (
      <UnpublishDateSection scheduledToUnpublishAt={props.scheduledToUnpublishAt} />
    )}
    <PublishingQuickTipSection />
  </>
);

PublishingSection.displayName = 'PublishingSection';
