import React, { useState } from 'react';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { ListingMessage } from '../../../../../../../_shared/uiComponents/ListingMessage/ListingMessage.tsx';
import { CancelBulkSchedulePublishConfirmationDialog } from '../../../../components/cancelConfirmation/CancelBulkSchedulePublishConfirmationDialog.tsx';
import { makeGetStatusInfo } from '../../../../reducers/listingUi/statusInfo/selectors/statusInfo.ts';
import { StatusMessageAction } from '../../../../stores/IContentAppStoreState.ts';
import {
  undoDeleteContentItems,
  undoPublishContentItems,
  undoSchedulePublishContentItems,
} from '../../actions/thunkContentItemInventoryActions.ts';

const getStatusInfo = makeGetStatusInfo({
  undoDeleteContentItems,
  undoPublishContentItems,
  undoSchedulePublishContentItems,
});

export const ContentItemScrollTableTitle: React.FC = () => {
  const lastActionType = useSelector(
    (state) => state.contentApp.listingUi.statusInfo.lastAction.lastActionType,
  );
  const statusInfoMessage = useSelector(getStatusInfo);
  const { undo } = statusInfoMessage;

  const [isUndoConfirmationDialogOpen, setIsUndoConfirmationDialogOpen] = useState(false);

  const dispatch = useDispatch();
  const undoAction = undo && (() => dispatch(undo.undoFn()));

  const onUndo = undoAction
    ? () => {
        if (lastActionType === StatusMessageAction.ScheduledToPublish) {
          setIsUndoConfirmationDialogOpen(true);
        } else {
          undoAction();
        }
      }
    : null;

  return (
    <>
      <ListingMessage
        onUndo={onUndo}
        statusInfoMessage={statusInfoMessage}
        undoButtonText={undo?.undoText}
      />
      <CancelBulkSchedulePublishConfirmationDialog
        isOpen={
          lastActionType === StatusMessageAction.ScheduledToPublish &&
          isUndoConfirmationDialogOpen &&
          !!undoAction
        }
        onCancel={() => setIsUndoConfirmationDialogOpen(false)}
        onConfirm={() => undoAction?.()}
      />
    </>
  );
};
