import { History } from 'history';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import { projectContainerContextLeft } from '../../../applications/projectSettings/root/actions/projectSettingsActions.ts';
import { clearSelectedSubscription } from '../../../applications/subscriptionManagement/shared/actions/subscriptionManagementActions.ts';
import { loadUserProjectsInfo } from '../../../data/actions/thunkDataActions.ts';
import { IUserProjectInfo } from '../../../data/models/user/UserProjectInfo.ts';
import {
  getCurrentEnvironmentIdForProjectContainer,
  getCurrentProjectContainerId,
} from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { projectContainerIdStorage } from '../../../localStorages/projectContainerStorage.ts';
import { currentProjectContainerUpdated } from '../../actions/sharedActions.ts';
import {
  loadProjectFeatureFlags,
  loadProjectProperties,
  loadWebSpotlightConfiguration,
} from '../../actions/thunkSharedActions.ts';
import { trackUserEvent } from '../../actions/thunks/trackUserEvent.ts';
import { EnsureValidState, IEnsureValidStateOwnProps } from '../../components/EnsureValidState.tsx';
import {
  ProjectRouteParams,
  ProjectSettingsRoute,
  ProjectsRoute,
} from '../../constants/routePaths.ts';
import { TrackedEvent } from '../../constants/trackedEvent.ts';
import { useDispatch } from '../../hooks/useDispatch.ts';
import { useSelector } from '../../hooks/useSelector.ts';
import { buildPath } from '../../utils/routing/routeTransitionUtils.ts';
import { isUuid } from '../../utils/validation/typeValidators.ts';

const areProjectContainersIdsReconciled = (
  currentProjectContainerId?: Uuid | null,
  desiredProjectContainerId?: Uuid | null,
) => {
  const areProjectContainersReconciled =
    isUuid(currentProjectContainerId) && currentProjectContainerId === desiredProjectContainerId;

  return areProjectContainersReconciled;
};

const isProjectContainerDataEnsured = (
  currentProjectContainerId: Uuid | null,
  areProjectsReconciled: boolean,
  areUserProjectsLoaded: boolean,
) => {
  const isStateEnsured: boolean =
    isUuid(currentProjectContainerId) && areUserProjectsLoaded && areProjectsReconciled;

  return isStateEnsured;
};

export const useProjectContainerRouteEntered = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Clear selected subscription always on mount
    dispatch(clearSelectedSubscription());

    return () => {
      dispatch(projectContainerContextLeft());
    };
  }, []);
};

export const useTrackAndSaveCurrentProjectContainer = (
  currentProjectContainerId: Uuid | null,
  areProjectContainersReconciled: boolean,
  setCurrentProjectContainerId: (projectContainerId: Uuid) => void,
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (areProjectContainersReconciled && isUuid(currentProjectContainerId)) {
      dispatch(trackUserEvent(TrackedEvent.CurrentProjectContainerUpdated));
      setCurrentProjectContainerId(currentProjectContainerId);
    }
  }, [areProjectContainersReconciled, currentProjectContainerId, setCurrentProjectContainerId]);
};

export const useLoadProjectInfo = (areProjectsReconciled: boolean) => {
  const [isUserInfoLoaded, setIsUserInfoLoaded] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!areProjectsReconciled) {
      setIsUserInfoLoaded(false);
      dispatch(loadUserProjectsInfo()).then(() => {
        setIsUserInfoLoaded(true);
      });
    }
  }, [areProjectsReconciled]);

  return isUserInfoLoaded;
};

const useLoadIfReconciled = (
  loadFunction: (id: Uuid) => ThunkPromise,
  currentId: Uuid | null,
  areProjectsReconciled: boolean,
) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (areProjectsReconciled && isUuid(currentId)) {
      dispatch(loadFunction(currentId));
    }
  }, [areProjectsReconciled, currentId, loadFunction]);
};

export const useReconcileCurrentProjectContainerIdWithRouteParameter = (
  desiredProjectContainerId: Uuid | null,
  userProjects: Immutable.Map<string, IUserProjectInfo> | null,
  history: History,
) => {
  const projectToBeSet =
    ((desiredProjectContainerId &&
      userProjects?.filter((p) => p?.projectContainerId === desiredProjectContainerId)?.first()) ||
      userProjects?.first()) ??
    null;
  const projectContainerIdToBeSet = projectToBeSet?.projectContainerId;

  const dispatch = useDispatch();

  useEffect(() => {
    if (userProjects && !projectToBeSet) {
      history.push(ProjectsRoute);
    }
  }, [history, projectToBeSet, userProjects]);

  useEffect(() => {
    if (
      isUuid(projectContainerIdToBeSet) &&
      projectContainerIdToBeSet !== desiredProjectContainerId
    ) {
      history.push(
        buildPath<ProjectRouteParams>(ProjectSettingsRoute, {
          projectContainerId: projectContainerIdToBeSet,
        }),
      );
    }
  }, [desiredProjectContainerId, history, projectContainerIdToBeSet]);

  useEffect(() => {
    if (
      isUuid(projectContainerIdToBeSet) &&
      projectContainerIdToBeSet === desiredProjectContainerId
    ) {
      dispatch(currentProjectContainerUpdated(projectContainerIdToBeSet));
    }
  }, [desiredProjectContainerId, projectContainerIdToBeSet]);
};

export const EnsureCurrentProjectContainer: React.FC<IEnsureValidStateOwnProps> = (props) => {
  const history = useHistory();

  const currentProjectContainerId = useSelector(getCurrentProjectContainerId);
  const userProjects = useSelector((state) => state.data.user.projectsInfoById);

  const { projectContainerId: projectContainerIdFromParams } = useParams<ProjectRouteParams>();
  const storedProjectContainerId = projectContainerIdStorage.load();

  const desiredProjectContainerId = projectContainerIdFromParams || storedProjectContainerId;
  const areProjectContainersReconciled = areProjectContainersIdsReconciled(
    currentProjectContainerId,
    desiredProjectContainerId,
  );

  const currentEnvironmentId = useSelector((s) =>
    getCurrentEnvironmentIdForProjectContainer(s.data.user, desiredProjectContainerId),
  );

  const isUserInfoLoaded = useLoadProjectInfo(areProjectContainersReconciled);

  const isStateEnsured = isProjectContainerDataEnsured(
    currentProjectContainerId,
    areProjectContainersReconciled,
    isUserInfoLoaded,
  );

  useProjectContainerRouteEntered();
  useReconcileCurrentProjectContainerIdWithRouteParameter(
    desiredProjectContainerId,
    userProjects,
    history,
  );
  useTrackAndSaveCurrentProjectContainer(
    currentProjectContainerId,
    areProjectContainersReconciled,
    projectContainerIdStorage.save,
  );

  useLoadIfReconciled(
    loadWebSpotlightConfiguration,
    currentEnvironmentId,
    areProjectContainersReconciled,
  );
  useLoadIfReconciled(loadProjectProperties, currentEnvironmentId, areProjectContainersReconciled);
  useLoadIfReconciled(
    loadProjectFeatureFlags,
    currentEnvironmentId,
    areProjectContainersReconciled,
  );

  return <EnsureValidState {...props} isStateEnsured={isStateEnsured} />;
};

EnsureCurrentProjectContainer.displayName = 'EnsureCurrentProjectContainer';
