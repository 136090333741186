import { memoize } from '@kontent-ai/memoization';
import {
  allLanguagesOption,
  anyEnvironmentOption,
  anyProjectOption,
} from '../../../../_shared/constants/userListingFilter.ts';
import {
  IEnvironmentFilterOption,
  ILanguageFilterOption,
  IProjectFilterOption,
} from '../../../../_shared/models/UserListingFilterOption.type.ts';
import { ILanguage } from '../../../../data/models/languages/Language.ts';
import { IProjectDetails } from '../../../../data/models/projects/ProjectDetails.ts';
import { addInactiveLanguageSuffix } from '../../../environmentSettings/localization/utils/languageUtils.ts';

export const getProjectFilterOptions = memoize.maxOne(
  (
    projects: ReadonlyArray<IProjectDetails>,
    withAnyProjectOption?: boolean,
  ): ReadonlyArray<IProjectFilterOption> => {
    const options = projects.map(({ masterEnvironmentId, projectName }: IProjectDetails) => ({
      id: masterEnvironmentId,
      label: projectName,
    }));
    return withAnyProjectOption ? [anyProjectOption, ...options] : options;
  },
);

export const getEnvironmentFilterOptions = memoize.maxOne(
  (
    environments: ReadonlyArray<IProjectDetails>,
    withAnyEnvironmentOption?: boolean,
  ): ReadonlyArray<IEnvironmentFilterOption> => {
    const options = environments.map(({ projectId, environmentName }: IProjectDetails) => ({
      id: projectId,
      label: environmentName,
    }));
    return withAnyEnvironmentOption ? [anyEnvironmentOption, ...options] : options;
  },
);

export const getLanguageFilterOptions = memoize.maxOne(
  (languages: ReadonlyArray<ILanguage>): ReadonlyArray<ILanguageFilterOption> => [
    allLanguagesOption,
    ...languages
      .map((language: ILanguage) => addInactiveLanguageSuffix(language))
      .map((language) => ({
        ...language,
        label: language.name,
      })),
  ],
);
