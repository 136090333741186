import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { StatusBarLogo } from './components/StatusBarLogo.tsx';

export enum ContainerContentType {
  Static = '--with-static-content',
  Shrinkable = '--with-shrinkable-content',
}

interface IStatusBarContainerProps {
  readonly centralContentType?: ContainerContentType;
  readonly renderLeftContainer: () => React.ReactNode;
  readonly renderCentralContainer: (ref: React.RefObject<HTMLDivElement>) => React.ReactNode;
  readonly renderRightContainer: () => React.ReactNode;
  readonly rightContentType?: ContainerContentType;
}

const propTypes: PropTypesShape<IStatusBarContainerProps> = {
  centralContentType: PropTypes.string,
  renderLeftContainer: PropTypes.func.isRequired,
  renderCentralContainer: PropTypes.func.isRequired,
  renderRightContainer: PropTypes.func.isRequired,
  rightContentType: PropTypes.string,
};

export const StatusBarRightPaneId = 'status-bar-right-pane';

export const StatusBarContainer: React.FC<IStatusBarContainerProps> = ({
  centralContentType = ContainerContentType.Shrinkable,
  renderLeftContainer,
  renderRightContainer,
  renderCentralContainer,
  rightContentType = ContainerContentType.Static,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  return (
    <>
      <StatusBarLogo />
      <div className="status-bar__left-pane">{renderLeftContainer()}</div>
      <div
        className={classNames(
          'status-bar__central-pane',
          `status-bar__central-pane${centralContentType}`,
        )}
        ref={ref}
      >
        {renderCentralContainer(ref)}
      </div>
      <div
        className={classNames(
          'status-bar__right-pane',
          `status-bar__right-pane${rightContentType}`,
        )}
        id={StatusBarRightPaneId}
      >
        {renderRightContainer()}
      </div>
    </>
  );
};

StatusBarContainer.displayName = 'StatusBarContainer';
StatusBarContainer.propTypes = propTypes;
