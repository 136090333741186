import { Column, Row } from '@kontent-ai/component-library/Row';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import Immutable from 'immutable';
import ImmutablePropTypes from 'immutable-prop-types';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { HtmlSettingsPageTitle } from '../../../../_shared/components/HtmlSettingsPageTitle.tsx';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { PageTitle } from '../../../../_shared/components/PageTitle.tsx';
import { PlanLimitationWarning } from '../../../../_shared/containers/infos/PlanLimitationWarning.tsx';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { areFallbacksForLinkedContentEnabled } from '../../../../_shared/selectors/fallbacksForLinkedContent.ts';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ILanguage } from '../../../../data/models/languages/Language.ts';
import { EnvironmentSettingsAppNames } from '../../root/constants/EnvironmentSettingsAppNames.ts';
import { FallbacksCard } from './FallbacksCard.tsx';
import { LanguageProductivityTip } from './LanguageProductivityTip.tsx';
import { LanguagesCard } from './LanguagesCard.tsx';

export interface ILanguageListingDataProps {
  readonly isCreatingAllowed: boolean;
  readonly isLimitReached: boolean;
  readonly isEditedLanguageNew: boolean;
  readonly shouldRenderIntro: boolean;
  readonly languages: Immutable.List<ILanguage>;
  readonly isLocalizationEditorInitialized: boolean;
}

export interface ILanguageListingCallbackProps {
  readonly onCreateNewClick: () => void;
  readonly onInit: () => void;
}

type LanguageListingProps = ILanguageListingDataProps & ILanguageListingCallbackProps;

const propTypes: PropTypesShape<LanguageListingProps> = {
  isCreatingAllowed: PropTypes.bool.isRequired,
  isEditedLanguageNew: PropTypes.bool.isRequired,
  isLimitReached: PropTypes.bool.isRequired,
  isLocalizationEditorInitialized: PropTypes.bool.isRequired,
  shouldRenderIntro: PropTypes.bool.isRequired,
  languages: ImmutablePropTypes.list.isRequired,
  onCreateNewClick: PropTypes.func.isRequired,
  onInit: PropTypes.func.isRequired,
};

export const LanguageListing: React.FC<LanguageListingProps> = ({
  onInit,
  isLocalizationEditorInitialized,
  languages,
  isCreatingAllowed,
  onCreateNewClick,
  isLimitReached,
}) => {
  useEffect(() => {
    onInit();
  }, [onInit]);

  const areFallbacksEnabled = useSelector(areFallbacksForLinkedContentEnabled);

  return isLocalizationEditorInitialized ? (
    <Stack spacing={Spacing.XL} {...getDataUiAppNameAttribute(DataUiAppName.Localization)}>
      <HtmlSettingsPageTitle settingsAppName={EnvironmentSettingsAppNames.Localization} />
      <PageTitle>{EnvironmentSettingsAppNames.Localization}</PageTitle>
      <Row spacing={Spacing.XL}>
        <Column flexFactor={5} flexBasis={600} maxWidth={1000 + Spacing.L}>
          <Stack spacing={Spacing.XL}>
            <FallbacksCard areFallbacksForLinkedContentEnabled={areFallbacksEnabled} />
            <LanguagesCard
              languages={languages}
              isCreatingAllowed={isCreatingAllowed}
              onCreateNewClick={onCreateNewClick}
            />
          </Stack>
        </Column>
        <Column flexFactor={1} flexBasis={250}>
          {isLimitReached ? (
            <PlanLimitationWarning
              introMessage="To add more languages"
              title="Language limit reached"
              description="You’ve reached the limit of languages for this project."
            />
          ) : (
            <LanguageProductivityTip />
          )}
        </Column>
      </Row>
    </Stack>
  ) : (
    <Loader />
  );
};

LanguageListing.displayName = 'LanguageListing';
LanguageListing.propTypes = propTypes;
