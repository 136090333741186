import { Collection } from '@kontent-ai/utils';
import React, { useMemo, useState } from 'react';
import { ModalViewer } from '../../../../_shared/components/Modal/ModalViewer.tsx';
import { ModalViewerPosition } from '../../../../_shared/components/Modal/ModalViewerPosition.ts';
import { DefaultVariantId } from '../../../../_shared/constants/variantIdValues.ts';
import { HandleUnsavedFormOnNavigation } from '../../../../_shared/containers/HandleUnsavedFormOnNavigation.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import {
  areCollectionsVisibleForSpaces,
  getCollections,
} from '../../../../_shared/selectors/contentCollections.ts';
import { compose } from '../../../../_shared/utils/func/compose.ts';
import { isAllowedTypeForCapability } from '../../../../_shared/utils/permissions/activeCapabilities.ts';
import { Capability } from '../../../../_shared/utils/permissions/capability.ts';
import { getCurrentUserRoleForCollectionForLanguage } from '../../../../_shared/utils/permissions/getContributorRole.ts';
import { ISpace } from '../../../../data/models/space/space.ts';
import { ModalContentItemSelector } from '../../../features/ModalContentItemSelector/containers/ModalContentItemSelector.tsx';
import { prepareNewContentItemDialogForSpaces } from '../../../itemEditor/features/NewContentItem/actions/thunkNewContentItemActions.ts';
import { NewContentItemDialog } from '../../../itemEditor/features/NewContentItem/containers/NewContentItemDialog.tsx';
import { getAvailableCollectionsForContentItem } from '../../../itemEditor/features/NewContentItem/utils/getAvailableCollectionsForContentItem.ts';
import { isWebSpotlightEnabledAndInitializedForCurrentProject } from '../../../webSpotlight/selectors/webSpotlightSelectors.ts';
import { ExpandedSpaceBar as ExpandedSpaceBarComponent } from '../components/ExpandedSpaceBar.tsx';
import { ExpandedSpaceBarContent } from '../components/ExpandedSpaceBarContent.tsx';
import {
  DisabledActionWhenInProgressMessage,
  MissingSpaceNameMessage,
} from '../constants/spacesUiConstants.ts';
import { ActionInProgress } from '../types/acionInProgress.ts';
import { anyCollectionInMultipleSpaces } from '../utils/spacesUtils.ts';

const dialogTypes = ['linkExisting', 'createNew'] as const;
type DialogType = (typeof dialogTypes)[number];

type Props = {
  readonly actionInProgress: ActionInProgress;
  readonly space: ISpace;
  readonly onCancel: () => void;
  readonly onDelete?: () => void;
  readonly onSave: (
    name: string,
    collectionIds: ReadonlyArray<Uuid>,
    rootItemId: Uuid | null,
    createNewRootItem: boolean,
    onSuccess?: () => void,
    onFailure?: () => void,
  ) => void;
};

export const ExpandedSpaceBar: React.FC<Props> = ({
  actionInProgress,
  space,
  onCancel,
  onDelete,
  onSave,
}) => {
  const isWebSpotlightActive = useSelector((s) =>
    isWebSpotlightEnabledAndInitializedForCurrentProject(s),
  );
  const rootTypeId = useSelector((s) => s.webSpotlightApp.configuration?.rootTypeId ?? '');
  const allowedContentTypeIds = useMemo(() => Immutable.Set.of<Uuid>(rootTypeId), [rootTypeId]);
  const forcedContentTypeIds = useMemo(() => new Set([rootTypeId]), [rootTypeId]);

  const canUserCreateContent = useSelector((s) => {
    const availableCollections = getAvailableCollectionsForContentItem(DefaultVariantId, s);

    return availableCollections.some((collection) => {
      const userRole = getCurrentUserRoleForCollectionForLanguage(
        s,
        collection.id,
        DefaultVariantId,
      );

      return isAllowedTypeForCapability(userRole?.settings, Capability.CreateContent)(rootTypeId);
    });
  });

  const showCollections = useSelector((s) =>
    areCollectionsVisibleForSpaces(s, Collection.getValues(s.data.collections.byId)),
  );
  const allCollections = useSelector((s) => getCollections(s.data.collections.byId));
  const projectId = useSelector((s) => s.sharedApp.currentProjectId);
  const [selectedCollectionIds, setSelectedCollectionIds] = useState(space.collectionIds);
  const anySharedCollection = useSelector((s) => {
    const spaceMapWithUpdatedCollections = new Map(s.data.spaces.byId).set(space.id, {
      ...space,
      collectionIds: selectedCollectionIds,
    });

    return anyCollectionInMultipleSpaces(spaceMapWithUpdatedCollections, selectedCollectionIds);
  });

  const [name, setName] = useState(space.name);
  const [rootItemId, setRootItemId] = useState(space.webSpotlightRootItemId);
  const [isNewRootItemSubmitted, setIsNewRootItemSubmitted] = useState(false);
  const [dialogType, setDialogType] = useState<DialogType | null>(null);

  const submit = (onSuccess?: () => void, onFailure?: () => void): void =>
    onSave(name, selectedCollectionIds, rootItemId, isNewRootItemSubmitted, onSuccess, onFailure);

  const submitNewRootItemCreation = () => setIsNewRootItemSubmitted(true);
  const unlinkRootItem = () => {
    if (rootItemId) {
      setRootItemId(null);
    } else {
      setIsNewRootItemSubmitted(false);
    }
  };

  const dispatch = useDispatch();
  const openCreateNewItemDialog = () => {
    dispatch(prepareNewContentItemDialogForSpaces({ allowedContentTypeIds }));
    setDialogType('createNew');
  };
  const openLinkExistingItemDialog = () => setDialogType('linkExisting');
  const closeDialog = () => setDialogType(null);

  const hasExistingRootItem = useSelector(
    (s) => !!rootItemId && !!s.data.listingContentItems.byId.get(rootItemId),
  );
  const isRootItemLoading = actionInProgress === ActionInProgress.RootItemLoading;
  const shouldShowRootItem = isRootItemLoading || hasExistingRootItem || isNewRootItemSubmitted;
  const hasSpaceLinkedRootItem = !!rootItemId || hasExistingRootItem || isNewRootItemSubmitted;
  const hasUnsavedChanges =
    name !== space.name ||
    rootItemId !== space.webSpotlightRootItemId ||
    isNewRootItemSubmitted ||
    space.collectionIds.length !== selectedCollectionIds.length ||
    Collection.intersect(space.collectionIds, selectedCollectionIds).length !==
      selectedCollectionIds.length;

  const disabledActionTooltipText = getDisabledActionTooltipText(actionInProgress);
  const disabledSaveTooltipText = disabledActionTooltipText || getDisabledSaveTooltipText(name);

  return (
    <>
      <ModalViewer
        dialogClassName="dialog"
        isDialogVisible={dialogType === 'linkExisting'}
        onClose={closeDialog}
        position={ModalViewerPosition.Center}
      >
        <ModalContentItemSelector
          forceContentTypesTooltipText="Web Spotlight root item is restricted to these content types."
          forcedContentTypeIds={forcedContentTypeIds}
          onClose={closeDialog}
          onSelect={compose(closeDialog, setRootItemId)}
          titleBarText="Select your Web Spotlight root item"
        />
      </ModalViewer>
      <NewContentItemDialog
        onClose={closeDialog}
        isOpen={dialogType === 'createNew'}
        onSubmit={compose(closeDialog, submitNewRootItemCreation)}
      />
      <HandleUnsavedFormOnNavigation
        isBeingSaved={actionInProgress === ActionInProgress.Saving}
        onSaveChanges={submit}
        hasUnsavedChanges={hasUnsavedChanges}
        disabledTooltip={disabledSaveTooltipText}
      />
      <ExpandedSpaceBarComponent
        actionInProgress={actionInProgress}
        disabledActionTooltipText={disabledActionTooltipText}
        disabledSaveTooltipText={disabledSaveTooltipText}
        name={name}
        onCancel={onCancel}
        onDelete={onDelete}
        onSubmit={submit}
      >
        <ExpandedSpaceBarContent
          allCollections={allCollections}
          anySharedCollection={anySharedCollection}
          canUserCreateContent={canUserCreateContent}
          hasExistingRootItem={hasExistingRootItem}
          hasSpaceLinkedRootItem={hasSpaceLinkedRootItem}
          isRootItemLoading={isRootItemLoading}
          isWebSpotlightActive={isWebSpotlightActive}
          name={name}
          onNameChange={setName}
          onOpenCreateNewItemDialog={openCreateNewItemDialog}
          onOpenLinkExistingItemDialog={openLinkExistingItemDialog}
          onRootItemUnlink={unlinkRootItem}
          onSelectedCollectionIdsChange={setSelectedCollectionIds}
          projectId={projectId}
          rootItemId={rootItemId}
          selectedCollectionIds={selectedCollectionIds}
          shouldShowRootItem={shouldShowRootItem}
          showCollections={showCollections}
        />
      </ExpandedSpaceBarComponent>
    </>
  );
};

ExpandedSpaceBar.displayName = 'ExpandedSpaceBar';

const getDisabledActionTooltipText = (actionInProgress: ActionInProgress): string | undefined => {
  if (
    [ActionInProgress.Saving, ActionInProgress.Deleting, ActionInProgress.Restoring].includes(
      actionInProgress,
    )
  ) {
    return DisabledActionWhenInProgressMessage;
  }

  return undefined;
};

const getDisabledSaveTooltipText = (spaceName: string): string | undefined => {
  if (!spaceName.trim().length) {
    return MissingSpaceNameMessage;
  }

  return undefined;
};
