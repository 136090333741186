import PropTypes from 'prop-types';
import React, { memo, useContext } from 'react';
import { useGradualSequence } from '../../../../../_shared/hooks/useGradualSequence.ts';
import { DeferAutoScrollCssClass } from '../../../../../_shared/utils/autoScrollUtils.ts';
import { TypeElement } from '../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { isGuidelinesTypeElement } from '../../../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { FloatingEditorContext } from '../../../../webSpotlight/components/preview/FloatingEditor/FloatingEditorContext.tsx';
import { ContentItemElement } from './ContentItemElement.tsx';
import { ContentItemPaneElementsGroup } from './ContentItemPaneElementsGroup.tsx';

interface IContentItemElementGroupProps {
  readonly canEdit: boolean;
  readonly hasTopRoundedCorners?: boolean;
  readonly hasBottomRoundedCorners?: boolean;
  readonly typeElements: ReadonlyArray<TypeElement>;
}

const propTypes: PropTypesShape<IContentItemElementGroupProps> = {
  canEdit: PropTypes.bool,
  hasTopRoundedCorners: PropTypes.bool,
  hasBottomRoundedCorners: PropTypes.bool,
  typeElements: PropTypes.array.isRequired,
};

const ContentItemElementGroup: React.FC<IContentItemElementGroupProps> = ({
  canEdit,
  hasTopRoundedCorners,
  hasBottomRoundedCorners,
  typeElements,
}) => {
  const isFloatingEditor = useContext(FloatingEditorContext);
  const { renderItems, isComplete } = useGradualSequence(
    typeElements,
    (typeElement) => typeElement.elementId,
    isFloatingEditor ? undefined : { chunkSize: Number.MAX_VALUE },
  );

  return (
    <ContentItemPaneElementsGroup
      isDisabled={!canEdit}
      isGuideline={isGuidelinesTypeElement(typeElements[0])}
      className={isComplete ? undefined : DeferAutoScrollCssClass}
      hasTopRoundedCorners={hasTopRoundedCorners}
      hasBottomRoundedCorners={hasBottomRoundedCorners}
    >
      {renderItems.map((typeElement) => (
        <ContentItemElement
          key={typeElement.elementId}
          canEdit={canEdit}
          typeElement={typeElement}
        />
      ))}
    </ContentItemPaneElementsGroup>
  );
};

ContentItemElementGroup.displayName = 'ContentItemElementGroup';
ContentItemElementGroup.propTypes = propTypes;

const ContentItemElementGroupMemo = memo(ContentItemElementGroup);

export { ContentItemElementGroupMemo as ContentItemElementGroup };
