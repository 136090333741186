import Immutable from 'immutable';
import {
  IActiveCapabilitiesForVariantServerModel,
  IContentItemVariantServerModel,
  IContentItemWithVariantServerModel,
  IContentItemWithVariantsServerModel,
} from '../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import {
  IContentItemVariantReference,
  getContentItemVariantReferenceFromServerModel,
} from '../../../models/contentItem/ContentItemVariantReference.ts';
import {
  IEditedContentItem,
  mapEditedContentItemFromServerModel,
} from '../../../models/contentItem/edited/EditedContentItem.ts';
import {
  EditedContentItemVariant,
  getContentItemVariantFromServerModel,
} from '../../../models/contentItem/edited/EditedContentItemVariant.ts';
import { ICompiledContentItemElementData } from '../../../models/contentItemElements/ICompiledContentItemElement.ts';
import { IConvertElementsToDomainModel } from '../../../stores/utils/contentItemHelperMethods.ts';

export interface IParsedItemVariant {
  readonly editedContentItemVariant: EditedContentItemVariant;
  readonly editedContentItemRawVariant: IContentItemVariantServerModel;
  readonly editedContentItemVariantElements: ReadonlyArray<ICompiledContentItemElementData>;
  readonly editedContentItemVariantReference: IContentItemVariantReference;
}

export interface IParsedItem extends IParsedItemVariant {
  readonly editedContentItem: IEditedContentItem;
  readonly contentItemVariants: Immutable.Map<Uuid, IContentItemVariantReference>;
}

interface IDeps {
  readonly convertElementsToDomainModel: IConvertElementsToDomainModel;
  readonly ensureContentItemNameValidity: (name: string) => string;
}

export type IParseContentItemVariant = (
  contentItemWithVariant: IContentItemWithVariantServerModel,
) => IParsedItemVariant;

export type IParseContentItem = (
  contentItem: IContentItemWithVariantServerModel,
  itemWithVariants: IContentItemWithVariantsServerModel,
) => IParsedItem;

interface ICreateItemParserOutput {
  readonly parseContentItem: IParseContentItem;
  readonly parseContentItemVariant: IParseContentItemVariant;
}

const reduceEditedVariantToItemReference = (
  variant: EditedContentItemVariant,
  name: string,
  typeId: Uuid,
  activeCapabilities: IActiveCapabilitiesForVariantServerModel,
): IContentItemVariantReference => ({
  activeCapabilities,
  assignment: variant.assignment,
  id: variant.id,
  isArchived: variant.isArchived,
  name,
  isPublishedVersion: false,
  publishingState: variant.publishingState,
  typeId,
});

export function createItemParser({
  convertElementsToDomainModel,
  ensureContentItemNameValidity,
}: IDeps): ICreateItemParserOutput {
  return {
    parseContentItem(
      contentItem: IContentItemWithVariantServerModel,
      itemWithVariants: IContentItemWithVariantsServerModel,
    ): IParsedItem {
      const parsedEditedContentItem = mapEditedContentItemFromServerModel(contentItem.item);
      const editedContentItem: IEditedContentItem = {
        ...parsedEditedContentItem,
        name: ensureContentItemNameValidity(parsedEditedContentItem.name),
      };

      const { activeCapabilities, item, variant } = contentItem;
      const contentItemVariants = itemWithVariants.variants.reduce(
        (reduced: Immutable.Map<Uuid, IContentItemVariantReference>, variantReference) => {
          return reduced.set(
            variantReference._id.variantId,
            getContentItemVariantReferenceFromServerModel(variantReference),
          );
        },
        Immutable.Map<Uuid, IContentItemVariantReference>(),
      );
      const editedContentItemVariant = getContentItemVariantFromServerModel(contentItem.variant);
      const editedContentItemVariantElements = convertElementsToDomainModel(
        variant?.contentElements,
      );
      const reference = reduceEditedVariantToItemReference(
        editedContentItemVariant,
        item.name,
        item.type._id,
        activeCapabilities,
      );

      return {
        editedContentItem,
        editedContentItemVariant,
        editedContentItemRawVariant: variant,
        contentItemVariants,
        editedContentItemVariantElements,
        editedContentItemVariantReference: reference,
      };
    },

    parseContentItemVariant(
      contentItemWithVariant: IContentItemWithVariantServerModel,
    ): IParsedItemVariant {
      const { variant, item } = contentItemWithVariant;
      const editedContentItemVariant = getContentItemVariantFromServerModel(variant);
      const editedContentItemVariantElements = convertElementsToDomainModel(
        variant?.contentElements,
      );
      const reference = reduceEditedVariantToItemReference(
        editedContentItemVariant,
        item.name,
        item.type._id,
        contentItemWithVariant.activeCapabilities,
      );

      return {
        editedContentItemVariant,
        editedContentItemVariantElements,
        editedContentItemRawVariant: variant,
        editedContentItemVariantReference: reference,
      };
    },
  };
}
