import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import { RelatedItemDoesNotExistReason } from '../../../applications/itemEditor/constants/errorMessages.ts';
import { IgnoreByGrammarly } from '../IgnoreByGrammarly.tsx';

interface INonexistentLinkedItemContentProps {
  readonly className: string;
  readonly message: string;
}

const propTypes: PropTypesShape<INonexistentLinkedItemContentProps> = {
  className: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export const NonexistentLinkedItemContent: React.FC<INonexistentLinkedItemContentProps> = ({
  className,
  message,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [exhaustiveTooltipNeeded, setExhaustiveTooltipNeeded] = useState<boolean>(false);

  useResizeObserver({
    ref: containerRef,
    onResize: () => {
      const containerEl = containerRef.current;
      if (!containerEl) {
        return;
      }

      const isTooltipNeeded = containerEl.offsetWidth < containerEl.scrollWidth;
      if (isTooltipNeeded !== exhaustiveTooltipNeeded) {
        setExhaustiveTooltipNeeded(isTooltipNeeded);
      }
    },
  });

  return (
    <div className={className}>
      <div className="bar-item__title-description" ref={containerRef}>
        <Tooltip
          placement="bottom"
          tooltipText={
            exhaustiveTooltipNeeded
              ? [message, RelatedItemDoesNotExistReason].join('\n')
              : RelatedItemDoesNotExistReason
          }
        >
          <IgnoreByGrammarly>{message}</IgnoreByGrammarly>
        </Tooltip>
      </div>
    </div>
  );
};

NonexistentLinkedItemContent.displayName = 'NonexistentLinkedItemContent';
NonexistentLinkedItemContent.propTypes = propTypes;
