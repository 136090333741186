import React from 'react';
import { ListChildComponentProps } from 'react-window';
import { verticalMenuScrollablePartPadding } from '../decisionTokens.ts';

type VirtualizedListInnerWrapperProps = Pick<ListChildComponentProps, 'style'>;

export const VirtualizedListInnerWrapper = React.forwardRef<
  HTMLDivElement,
  VirtualizedListInnerWrapperProps
>(({ style, ...otherProps }, forwardedRef) => {
  const height: number =
    typeof style.height === 'string' ? Number.parseFloat(style.height) : style.height ?? 0;

  return (
    <div
      ref={forwardedRef}
      style={{
        ...style,
        height: height + 2 * verticalMenuScrollablePartPadding,
      }}
      {...otherProps}
    />
  );
});

VirtualizedListInnerWrapper.displayName = 'VirtualizedListInnerWrapper';
