import { AnchorButton } from '@kontent-ai/component-library/Button';
import { Spacing, ZIndex, gridUnit, px } from '@kontent-ai/component-library/tokens';
import styled from 'styled-components';

export const mainContentId = 'mainContent';

export const SkipToMainContent = styled(AnchorButton).attrs({
  buttonStyle: 'primary',
  size: 'small',
  href: `#${mainContentId}`,
  children: 'Skip to main content',
})`
  position: absolute;
  top: ${px(-gridUnit * 100)};
  left: 0;
  padding: ${px(Spacing.S)} ${px(Spacing.M)};
  z-index: ${ZIndex.OneHundred};
  
  &:focus-visible {
    top: ${px(Spacing.XXXL)};
    left : ${px(Spacing.M)};
  }
`;
