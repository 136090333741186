import { makeCancellablePromise, swallowCancelledPromiseError } from '@kontent-ai/utils';
import { useEffect } from 'react';
import {
  HotkeysHandler,
  HotkeysMode,
} from '../../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { HandleUnsavedFormOnNavigation } from '../../../../_shared/containers/HandleUnsavedFormOnNavigation.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { compose } from '../../../../_shared/utils/func/compose.ts';
import { areSpacesInCollectionsEnabled } from '../../selectors/allowedFeaturesUtils.ts';
import { leaveCollectionsApp } from '../actions/baseActions.ts';
import {
  initCollectionsListing,
  saveEditedCollections,
} from '../actions/collectionsThunkActions.ts';
import { CollectionsApp as CollectionsAppComponent } from '../components/CollectionsApp.tsx';
import { haveCollectionsUnsavedChanges } from '../selectors/haveCollectionsUnsavedChanges.ts';

export const CollectionsApp = () => {
  const dispatch = useDispatch();
  const isInitialized = useSelector((s) => s.collectionsApp.isInitialized);
  const isBeingSaved = useSelector((s) => s.collectionsApp.isBeingSaved);
  const collectionIds = useSelector((s) => s.collectionsApp.collectionIds);
  const editedCollections = useSelector((s) => s.collectionsApp.editedCollections);
  const hasUnsavedChanges = useSelector(haveCollectionsUnsavedChanges);
  const showSpaces = useSelector(areSpacesInCollectionsEnabled);

  useEffect(() => {
    const { cancel } = makeCancellablePromise(() => dispatch(initCollectionsListing())).catch(
      swallowCancelledPromiseError,
    );

    return () => {
      cancel();
      dispatch(leaveCollectionsApp());
    };
  }, []);

  if (!isInitialized) {
    return <Loader />;
  }

  const onControlS = (event: KeyboardEvent): void => {
    event.preventDefault();

    if (!isBeingSaved) {
      dispatch(saveEditedCollections());
    }
  };

  return (
    <HotkeysHandler
      mode={HotkeysMode.Dual}
      handlers={{
        onControlS,
      }}
    >
      <HandleUnsavedFormOnNavigation
        isBeingSaved={isBeingSaved}
        onSaveChanges={compose(dispatch, saveEditedCollections)}
        hasUnsavedChanges={hasUnsavedChanges}
      />
      <CollectionsAppComponent
        collectionIds={collectionIds}
        collectionsMap={editedCollections}
        showSpaces={showSpaces}
      />
    </HotkeysHandler>
  );
};
