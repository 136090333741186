import React, { MouseEvent } from 'react';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import { SquareButton } from '../../../../../_shared/uiComponents/Button/SquareButton.tsx';
import { ButtonStyle } from '../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import { SquareButtonSize } from '../../../../../_shared/uiComponents/Button/squareButtonSize.ts';
import { Icon } from '../../../../../_shared/uiComponents/Icon/Icon.tsx';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ITaxonomyTerm } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyTerm.ts';
import { FlexingTile } from '../AssetTile/FlexingTile.tsx';

type Props = {
  readonly assetFolder: ITaxonomyTerm;
  readonly onRename?: () => void;
  readonly onOpen: () => void;
};

export const AssetFolder: React.FC<Props> = (props) => {
  const { assetFolder, onOpen, onRename } = props;

  const initiateRenaming = (event: MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    onRename?.();
  };

  const renderRenameButton = (): JSX.Element | null => {
    if (!onRename) {
      return null;
    }

    return (
      <SquareButton
        className="asset-folder__action"
        onClick={initiateRenaming}
        screenReaderText="Rename folder"
        style={ButtonStyle.Quinary}
        size={SquareButtonSize.Quinary}
        tooltipPlacement="bottom"
        tooltipText="Rename"
        iconName={IconName.Edit}
        {...getDataUiActionAttribute(DataUiAction.Edit)}
      />
    );
  };

  return (
    <FlexingTile className="asset-folder">
      <div
        className="asset-folder__pane"
        onClick={onOpen}
        {...getDataUiElementAttribute(DataUiElement.AssetFolder)}
        {...getDataUiObjectNameAttribute(assetFolder.name)}
      >
        <div className="asset-folder__icon">
          <Icon iconName={IconName.FolderInverted} />
        </div>
        <span className="asset-folder__name" title={assetFolder.name}>
          {assetFolder.name}
        </span>
        {renderRenameButton()}
      </div>
    </FlexingTile>
  );
};

AssetFolder.displayName = 'AssetFolder';
