import { History } from 'history';
import Immutable from 'immutable';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { createRoleEditingBackLink } from '../../../../../_shared/utils/routing/projectSubscriptionRoutingUtils.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IRoleServerModel } from '../../../../../repositories/serverModels/IRoleServerModel.type.ts';
import {
  Role_CreatorInit_Finished,
  Role_CreatorInit_Started,
} from '../../constants/rolesActionTypes.ts';
import { getCustomRolesLimitInfo } from '../../selectors/customRoleLimit.ts';
import { canCreateNewCustomRole } from '../../utils/customRolesUtils.ts';

const roleCreatorInitStarted = () =>
  ({
    type: Role_CreatorInit_Started,
  }) as const;

export const roleCreatorInitFinished = (
  role: IRoleServerModel,
  allTypes: Immutable.Map<Uuid, IContentType>,
) =>
  ({
    type: Role_CreatorInit_Finished,
    payload: {
      role,
      allTypes,
    },
  }) as const;

export type InitRoleCreatorActionsType = ReturnType<
  typeof roleCreatorInitStarted | typeof roleCreatorInitFinished
>;

interface IDeps {
  readonly loadRoles: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadTypes: (abortSignal?: AbortSignal) => ThunkPromise;
}

export const makeInitRoleCreatorAction =
  (deps: IDeps) =>
  (
    history: History,
    projectId: Uuid,
    subscriptionId?: Uuid,
    abortSignal?: AbortSignal,
  ): ThunkPromise =>
  async (dispatch, getState) => {
    dispatch(roleCreatorInitStarted());

    await Promise.all([
      dispatch(deps.loadRoles(abortSignal)),
      dispatch(deps.loadTypes(abortSignal)),
    ]);

    const limits = getCustomRolesLimitInfo(getState());
    const canCreateNew = canCreateNewCustomRole(limits);

    if (!canCreateNew) {
      const roleListingPath = createRoleEditingBackLink({
        projectId,
        subscriptionId,
      });
      history.push(roleListingPath);
      return;
    }

    dispatch(
      roleCreatorInitFinished(
        {
          id: '',
          name: '',
          capabilities: [],
          codename: null,
          settings: {
            canRules: [],
            cannotRules: [],
          },
        },
        getState().data.contentTypes.byId,
      ),
    );
  };
