import React from 'react';
import { NotificationBar } from '../../../../_shared/containers/NotificationBar.tsx';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { EnvironmentSettingsMenu } from '../containers/EnvironmentSettingsMenu.tsx';

export const EnvironmentSettingsApp: React.FC<React.PropsWithChildren<NoProps>> = ({
  children,
}) => (
  <div className="canvas" {...getDataUiAppNameAttribute(DataUiAppName.EnvironmentSettings)}>
    <div className="canvas__workspace">
      <EnvironmentSettingsMenu />
      <section className="canvas__content">
        <div className="canvas__notifications">
          <NotificationBar />
        </div>
        <div className="canvas__content-pane">{children}</div>
      </section>
    </div>
  </div>
);

EnvironmentSettingsApp.displayName = 'EnvironmentSettingsApp';
