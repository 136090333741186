import { BaseEditor } from '../types/Editor.base.type.ts';
import { EditorComposition } from '../types/Editor.composition.type.ts';
import { Contract, DependenciesOf } from '../types/Editor.contract.type.ts';
import { Combine } from '../types/Editor.plugins.type.ts';

export const withDisplayName = <
  TPlugin extends Contract,
  TEditor extends Combine<BaseEditor, DependenciesOf<TPlugin>>,
>(
  pluginName: string,
  composition: EditorComposition<Combine<TEditor, TPlugin>>,
): EditorComposition<Combine<TEditor, TPlugin>> => {
  composition.ComposedEditor.displayName = `ComposedEditor(${pluginName})`;
  return composition;
};
