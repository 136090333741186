import { EditorState } from 'draft-js';
import PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';
import { IUrlSlugTypeElement } from '../../../../../../contentInventory/content/models/contentTypeElements/UrlSlugTypeElement.ts';
import { IFocusable } from '../../../../../../richText/plugins/behavior/FocusPlugin.tsx';
import { EditorChangeCallback } from '../../../../../../richText/plugins/behavior/OnChangePlugin.tsx';
import { UrlSlugMode } from '../../../../../constants/urlSlugMode.ts';
import {
  IUrlSlugItemElement,
  UrlSlugItemElementPropTypesShape,
} from '../../../../../models/contentItemElements/UrlSlugItemElement.ts';
import { ItemElement } from '../../../containers/elements/ItemElement.tsx';
import { ItemElementRefresher } from '../../../containers/elements/ItemElementRefresher.tsx';
import { UrlSlugInput } from '../../../containers/elements/urlSlug/UrlSlugInput.tsx';
import { IItemElementComponentOwnProps } from '../IItemElementOwnProps.type.ts';

export type UrlSlugExtraProps = {
  readonly dependentTextElementName?: string;
  readonly onChange: (newState: EditorState) => void;
  readonly onAutoGenerated: () => void;
};

export type UrlSlugProps = IItemElementComponentOwnProps<IUrlSlugItemElement, IUrlSlugTypeElement> &
  UrlSlugExtraProps;

const propTypes: PropTypesShape<UrlSlugProps> = {
  // data props
  elementData: UrlSlugItemElementPropTypesShape.isRequired,
  typeElement: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  dependentTextElementName: PropTypes.string,
  autoFocus: PropTypes.bool.isRequired,

  // callback props
  onChange: PropTypes.func.isRequired,
  onAutoGenerated: PropTypes.func.isRequired,
};

export const UrlSlug: React.FC<UrlSlugProps> = ({
  elementData,
  typeElement,
  disabled,
  dependentTextElementName,
  autoFocus,
  onAutoGenerated,
  onChange,
}) => {
  const editorRef = useRef<IFocusable>(null);

  const focusEditorAtTheStart = useCallback(() => editorRef.current?.focusAtTheStart(), []);
  const focusEditorAtTheEnd = useCallback(() => editorRef.current?.focusAtTheEnd(), []);

  const onContentChange: EditorChangeCallback = useCallback(
    (editorState) => {
      onChange(editorState);
      return Promise.resolve();
    },
    [onChange],
  );

  const isInCustomMode = elementData.mode === UrlSlugMode.Custom;

  return (
    <ItemElement
      typeElement={typeElement}
      disabled={disabled}
      onHeaderClick={focusEditorAtTheStart}
      onContentClick={focusEditorAtTheEnd}
    >
      <ItemElementRefresher
        elementData={elementData}
        renderInput={(refresherItemElement: IUrlSlugItemElement) => (
          <UrlSlugInput
            autoFocus={autoFocus}
            className="rte--in-content-item-element"
            customMode={isInCustomMode}
            dependentTextFieldName={dependentTextElementName}
            disabled={disabled}
            editorState={refresherItemElement._editorState}
            onContentChange={onContentChange}
            onRegenerate={onAutoGenerated}
            ref={editorRef}
            typeElement={typeElement}
          />
        )}
      />
    </ItemElement>
  );
};

UrlSlug.displayName = 'UrlSlug';
UrlSlug.propTypes = propTypes;
