import PropTypes from 'prop-types';
import React from 'react';
import { LinkLike } from '../../../../uiComponents/LinkLike/LinkLike.tsx';

interface IContributorsSectionDescriptionDataProps {
  readonly showAllContributors: boolean;
  readonly workflowStepName: string;
}

interface IContributorsSectionDescriptionCallbackProps {
  readonly onShowAllContributors: () => void;
  readonly onShowAvailableContributors: () => void;
}

interface IContributorsSectionDescriptionProps
  extends IContributorsSectionDescriptionDataProps,
    IContributorsSectionDescriptionCallbackProps {}

export const ContributorsSectionDescription: React.FC<IContributorsSectionDescriptionProps> = (
  props: IContributorsSectionDescriptionProps,
): JSX.Element => {
  return (
    <p className="sidebar__description">
      {props.showAllContributors ? (
        <>
          Showing all contributors.&nbsp;
          <LinkLike
            className="content-editing-actions__link"
            onClick={props.onShowAvailableContributors}
          >
            Show suggested contributors
          </LinkLike>
          , who are allowed to work with the <b>{props.workflowStepName}</b> workflow step.
        </>
      ) : (
        <>
          Showing only the contributors allowed to work with the <b>{props.workflowStepName}</b>{' '}
          workflow step.&nbsp;
          <LinkLike className="content-editing-actions__link" onClick={props.onShowAllContributors}>
            Show all contributors
          </LinkLike>
        </>
      )}
    </p>
  );
};

ContributorsSectionDescription.displayName = 'ContributorsSectionDescription';
const propTypes: PropTypesShape<IContributorsSectionDescriptionProps> = {
  showAllContributors: PropTypes.bool.isRequired,
  workflowStepName: PropTypes.string.isRequired,
  onShowAllContributors: PropTypes.func.isRequired,
  onShowAvailableContributors: PropTypes.func.isRequired,
};
ContributorsSectionDescription.propTypes = propTypes;
