import { UnreachableCaseException } from '@kontent-ai/errors';
import PropTypes from 'prop-types';
import React, { ComponentProps, PropsWithChildren } from 'react';
import { Box } from '../../layout/Box/Box.tsx';
import { GuardGradient } from '../../tokens/decision/gradients.ts';
import {
  sizeGuard,
  spacingElementEdgeHorizontal,
  spacingElementEdgeVertical,
} from '../../tokens/decision/spacing.ts';

// We're not using Object.keys() here because type inference doesn't work correctly then (prop table shows only "string" instead of these specific values).
export const guardPlacementValues: ReadonlyArray<keyof typeof GuardGradient> = [
  'top',
  'right',
  'bottom',
  'left',
] as const;
export type GuardPlacement = (typeof guardPlacementValues)[number];

const getGuardStyles = (placement: GuardPlacement): ComponentProps<typeof Box> => {
  switch (placement) {
    case 'top':
      return {
        top: 0,
        left: 0,
        width: '100%',

        alignItems: 'center',
        justifyContent: 'flex-start',
      };
    case 'bottom':
      return {
        bottom: 0,
        left: 0,
        width: '100%',

        alignItems: 'center',
        justifyContent: 'flex-end',
      };
    case 'left':
      return {
        top: 0,
        left: 0,
        height: '100%',

        alignItems: 'flex-start',
        justifyContent: 'center',
      };
    case 'right':
      return {
        top: 0,
        right: 0,
        height: '100%',

        alignItems: 'flex-end',
        justifyContent: 'center',
      };
    default:
      throw UnreachableCaseException(placement);
  }
};

const getGuardGradientStyles = (placement: GuardPlacement): ComponentProps<typeof Box> => {
  switch (placement) {
    case 'bottom':
    case 'top':
      return {
        width: '100%',
        height: sizeGuard,
      };
    case 'right':
    case 'left':
      return {
        width: sizeGuard,
        height: '100%',
      };
    default:
      throw UnreachableCaseException(placement);
  }
};

type Props = Readonly<{
  placement: GuardPlacement;
}>;

const propTypes: PropTypeMap<Props> = {
  placement: PropTypes.oneOf(guardPlacementValues).isRequired,
};

/**
 * Guard is positioned **absolutely** — that is relatively to its closest "**positioned**" ancestor (anything other than `position: static`).
 * You need to take this into account and handle it yourself.
 *
 * It has built-in positioning, content alignment and size based on the provided Guard placement and its closest **positioned** ancestor.
 *
 * You can wrap it in another component to position it yourself or, if you need even more flexibility, use `GuardGradient` directly.
 */
export const Guard = React.forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  ({ children, placement, ...otherProps }, forwardedRef) => (
    <Box
      ref={forwardedRef}
      position="absolute"
      display="flex"
      flexDirection="column"
      paddingX={spacingElementEdgeHorizontal}
      paddingY={spacingElementEdgeVertical}
      {...getGuardStyles(placement)}
      {...otherProps}
    >
      <Box
        inset="inherit"
        position="absolute"
        backgroundImage={GuardGradient[placement]}
        pointerEvents="none"
        {...getGuardGradientStyles(placement)}
      />

      {children}
    </Box>
  ),
);

Guard.displayName = 'Guard';
Guard.propTypes = propTypes;
