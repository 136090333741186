import { InvariantException } from '@kontent-ai/errors';
import { History } from 'history';
import Immutable from 'immutable';
import { Dispatch, GetState, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IRoleRepository } from '../../../../../repositories/interfaces/IRoleRepository.type.ts';
import { IRoleServerModel } from '../../../../../repositories/serverModels/IRoleServerModel.type.ts';
import {
  NoCapabilitySelectedErrorMessage,
  UnableToSaveErrorMessage,
} from '../../constants/UIConstants.ts';
import {
  Role_Create_Failed,
  Role_Create_Finished,
  Role_Create_Started,
} from '../../constants/rolesActionTypes.ts';
import { getUpdateServerModelFromEditedRole } from '../../utils/getUpdateServerModelFromEditedRole.ts';
import { isRoleValid } from '../../utils/validationUtils.ts';
import { roleValidationFailed } from '../rolesActions.ts';

interface IDeps {
  readonly roleRepository: Pick<IRoleRepository, 'createRole'>;
}

interface IParams {
  readonly history: History;
  readonly getRoleEditingRoute: (roleId: Uuid) => string;
  readonly onSuccess?: () => void;
  readonly onFail?: () => void;
}

const started = () =>
  ({
    type: Role_Create_Started,
  }) as const;

const finished = (role: IRoleServerModel, allTypes: Immutable.Map<Uuid, IContentType>) =>
  ({
    type: Role_Create_Finished,
    payload: {
      role,
      allTypes,
    },
  }) as const;

const failed = (errorMessage: string) =>
  ({
    type: Role_Create_Failed,
    payload: { errorMessage },
  }) as const;

export type CreateRoleActionsType = ReturnType<typeof started | typeof finished | typeof failed>;

export const makeCreateRoleAction =
  (deps: IDeps) =>
  (params: IParams): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {
      rolesApp: {
        editorUi: { editedRole },
      },
      data: { contentTypes },
    } = getState();

    if (!editedRole) {
      throw InvariantException('createRole.ts: edited role is null');
    }

    if (!isRoleValid(editedRole)) {
      dispatch(roleValidationFailed());
      if (params.onFail) {
        params.onFail();
      }
      return;
    }

    try {
      dispatch(started());

      const updateServerModel = getUpdateServerModelFromEditedRole(editedRole, contentTypes.byId);
      if (updateServerModel.capabilities.length <= 0) {
        dispatch(failed(NoCapabilitySelectedErrorMessage));
        if (params.onFail) {
          params.onFail();
        }
        return;
      }

      const serverModel = await deps.roleRepository.createRole(updateServerModel);
      dispatch(finished(serverModel, contentTypes.byId));
      dispatch(trackUserEvent(TrackedEvent.CustomRoleCreated));

      if (params.onSuccess) {
        params.onSuccess();
      } else {
        params.history.push(params.getRoleEditingRoute(serverModel.id));
      }
    } catch (e) {
      if (params.onFail) {
        params.onFail();
      }

      dispatch(failed(UnableToSaveErrorMessage));

      throw e;
    }
  };
