import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '../../../../../../../_shared/uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import { DataUiAction } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export interface IContentProductionRulesEmptyCanRulesProps {
  readonly isDisabled: boolean;
  readonly onAddRule: () => void;
}

export const ContentProductionRulesEmptyCanRules: React.ComponentType<
  IContentProductionRulesEmptyCanRulesProps
> = (props) => (
  <div className="capability-rules__pane">
    <p className="capability-rules__title">Users with this role can:</p>
    <Button
      style={ButtonStyle.Tertiary}
      customButtonClassName="capability-rules__button"
      onClick={props.onAddRule}
      disabled={props.isDisabled}
      dataUiAction={DataUiAction.Add}
    >
      Add rule
    </Button>
  </div>
);

const propTypes: PropTypesShape<IContentProductionRulesEmptyCanRulesProps> = {
  isDisabled: PropTypes.bool.isRequired,
  onAddRule: PropTypes.func.isRequired,
};
ContentProductionRulesEmptyCanRules.propTypes = propTypes;
ContentProductionRulesEmptyCanRules.displayName = 'ContentProductionRulesEmptyCanRules';
