import { Box } from '@kontent-ai/component-library/Box';
import { QuinaryButton, QuinaryButtonStyle } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Column, Row } from '@kontent-ai/component-library/Row';
import {
  IconSize,
  Spacing,
  colorIconDefault,
  colorIconDefaultInverse,
} from '@kontent-ai/component-library/tokens';
import { UnreachableCaseException } from '@kontent-ai/errors';
import { useAttachRef } from '@kontent-ai/hooks';
import PropTypes from 'prop-types';
import React, { PropsWithChildren, useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import { NotificationBarType, notificationBarTypeValues } from '../notificationBarType.ts';
import { StyledNotificationBarContent } from './StyledNotificationBarContent.tsx';
import { StyledNotificationBarWrapper } from './StyledNotificationBarWrapper.tsx';

const notificationBarActionsAlignmentValues = ['left', 'right'] as const;
export type NotificationBarActionsAlignment =
  (typeof notificationBarActionsAlignmentValues)[number];

export type NotificationBarActions = {
  readonly alignment: NotificationBarActionsAlignment;
  readonly renderActions: () => React.ReactNode;
};

export type NotificationBarProps = PropsWithChildren<{
  readonly actions?: NotificationBarActions;
  readonly hideIcon?: boolean;
  readonly isFloating?: boolean;
  readonly onDismiss?: () => void;
}>;

export const notificationBarPropTypes: PropTypeMap<NotificationBarProps> = {
  actions: PropTypes.exact({
    alignment: PropTypes.oneOf(notificationBarActionsAlignmentValues).isRequired,
    renderActions: PropTypes.func.isRequired,
  }),
  isFloating: PropTypes.bool,
  hideIcon: PropTypes.bool,
  onDismiss: PropTypes.func,
  children: PropTypes.node,
};

type BaseNotificationBarProps = NotificationBarProps & {
  readonly notificationBarType: NotificationBarType;
};

const propTypes: PropTypeMap<BaseNotificationBarProps> = {
  ...notificationBarPropTypes,
  notificationBarType: PropTypes.oneOf(notificationBarTypeValues).isRequired,
};

const getIconColor = (notificationBarType: NotificationBarType) => {
  switch (notificationBarType) {
    case 'Alert':
    case 'Success':
      return colorIconDefaultInverse;
    case 'Info':
    case 'Warning':
    case 'FriendlyWarning':
      return colorIconDefault;
    case 'InfoEmphasis':
    case 'Blank':
      return undefined;
    default:
      throw UnreachableCaseException(notificationBarType);
  }
};

const getIcon = (notificationBarType: NotificationBarType) => {
  switch (notificationBarType) {
    case 'Success':
      return Icons.CbCheck;
    case 'Warning':
    case 'FriendlyWarning':
      return Icons.ExclamationTriangle;
    case 'Alert':
      return Icons.ExclamationTriangleInverted;
    case 'Info':
      return Icons.ICircle;
    case 'InfoEmphasis':
    case 'Blank':
      return null;
    default:
      throw UnreachableCaseException(notificationBarType);
  }
};

const getQuinaryButtonStyle = (notificationBarType: NotificationBarType): QuinaryButtonStyle => {
  switch (notificationBarType) {
    case 'Alert':
    case 'InfoEmphasis':
    case 'Success':
      return 'inverse';
    case 'Warning':
    case 'Info':
    case 'FriendlyWarning':
    case 'Blank':
      return 'default';
    default:
      throw UnreachableCaseException(notificationBarType);
  }
};

const widthWrapBreakpoint = 600;

export const BaseNotificationBar = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<BaseNotificationBarProps>
>(
  (
    { children, isFloating, hideIcon, onDismiss, actions, notificationBarType, ...otherProps },
    forwardedRef,
  ) => {
    const [shouldWrap, setShouldWrap] = useState<boolean>(false);

    const { refToForward, refObject } = useAttachRef(forwardedRef);

    useResizeObserver({
      ref: refObject,
      onResize: ({ width }) => {
        if (width) {
          setShouldWrap(width <= widthWrapBreakpoint);
        }
      },
    });

    const Icon = getIcon(notificationBarType);

    return (
      <StyledNotificationBarWrapper
        role={notificationBarType === 'Alert' ? 'alert' : 'status'}
        $type={notificationBarType}
        floating={isFloating}
        ref={refToForward}
        {...otherProps}
      >
        <Box paddingY={Spacing.S} paddingRight={Spacing.M} paddingLeft={Spacing.L}>
          <Row alignY="center" noWrap>
            {Icon && !hideIcon && (
              <Column width="content">
                <Box paddingRight={Spacing.S}>
                  <Icon
                    size={IconSize.S}
                    color={getIconColor(notificationBarType)}
                    screenReaderText="icon"
                  />
                </Box>
              </Column>
            )}

            <Column>
              <Box display="flex" alignItems="center" paddingRight={Spacing.M}>
                <StyledNotificationBarContent>{children}</StyledNotificationBarContent>
                {actions && !shouldWrap && actions.alignment === 'left' && (
                  <Box paddingLeft={Spacing.M}>{actions.renderActions()}</Box>
                )}
              </Box>
            </Column>

            {actions && actions.alignment === 'right' && (
              <Column width="content">
                <Box paddingRight={Spacing.M}>{actions.renderActions()}</Box>
              </Column>
            )}

            {onDismiss && (
              <Column
                width="content"
                css={`
                  align-self: ${shouldWrap ? 'flex-start' : 'center'};
                `}
              >
                <QuinaryButton
                  buttonStyle={getQuinaryButtonStyle(notificationBarType)}
                  tooltipPlacement="top"
                  onClick={onDismiss}
                  tooltipText="Dismiss"
                >
                  <QuinaryButton.Icon
                    icon={Icons.ModalClose}
                    screenReaderText="Dismiss notification"
                  />
                </QuinaryButton>
              </Column>
            )}
          </Row>
          {actions && actions.alignment === 'left' && shouldWrap && (
            <Row noWrap alignX="center">
              <Column width="content">
                <Box paddingTop={Spacing.M}>{actions.renderActions()}</Box>
              </Column>
            </Row>
          )}
        </Box>
      </StyledNotificationBarWrapper>
    );
  },
);

BaseNotificationBar.displayName = 'NotificationBar';
BaseNotificationBar.propTypes = propTypes;
