import Immutable from 'immutable';
import React, { useState } from 'react';
import { OptionsList } from '../../../_shared/components/Options/OptionsList.tsx';
import { Warning } from '../../../_shared/components/infos/Warning.tsx';
import { EmailPreferenceType } from '../../../_shared/models/EmailPreferenceTypeEnum.ts';
import { OptionMode } from '../../../_shared/models/optionMode.ts';
import { Button } from '../../../_shared/uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../../_shared/uiComponents/Button/buttonStyle.ts';

const options = [
  {
    id: EmailPreferenceType.Assignment,
    label: 'Workflow changes',
  },
  {
    id: EmailPreferenceType.Comments,
    label: 'Comments',
  },
  {
    id: EmailPreferenceType.Mentions,
    label: 'Mentions',
  },
  {
    id: EmailPreferenceType.Tasks,
    label: 'Tasks',
  },
  {
    id: EmailPreferenceType.ReportsWithZeroPrice,
    label: 'Usage reports with a total price of $0',
  },
  {
    id: EmailPreferenceType.WebhookNotifications,
    label: 'Webhook notifications',
  },
];

type UserEmailPreferencesProps = {
  readonly initialEmailPreferences: Immutable.Map<EmailPreferenceType, boolean>;
  readonly isUpdating: boolean;
  readonly onChange: (changed: boolean) => void;
  readonly onUpdate: (preferences: Immutable.Map<EmailPreferenceType, boolean>) => void;
};

export const UserEmailPreferences: React.FC<UserEmailPreferencesProps> = ({
  initialEmailPreferences,
  isUpdating,
  onChange,
  onUpdate,
}) => {
  const [emailPreferences, setEmailPreferences] = useState(initialEmailPreferences);

  const isChanged = !Immutable.is(emailPreferences, initialEmailPreferences);

  const updatePreferences = (): void => onUpdate(emailPreferences);

  const togglePreference = (type: EmailPreferenceType): void => {
    setEmailPreferences((prevPreferences) => prevPreferences.set(type, !prevPreferences.get(type)));
    onChange(isChanged);
  };

  const renderOptions = (): JSX.Element => {
    const selectedOptions = emailPreferences
      .filter((preference) => !!preference && preference.valueOf())
      .keySeq()
      .toArray();

    return (
      <OptionsList
        mode={OptionMode.InvertedMultiple}
        options={options}
        selectedOptions={selectedOptions}
        onOptionClick={togglePreference}
      />
    );
  };

  return (
    <section className="card__pane">
      <div className="card__content-section">
        <div className="user-profile__section-heading">
          <h2 className="user-profile__section-title user-profile__section-title--with-status-message">
            Email preferences
          </h2>
        </div>
        <div className="user-profile__form-group">Select what you want to be notified about.</div>
        <div className="user-profile__form-group">
          <Warning hideTitle>
            <p>E-mail preferences apply to all projects you are working on.</p>
          </Warning>
        </div>
        <div className="user-profile__form-group">{renderOptions()}</div>
      </div>
      <div className="card__bottom-section">
        <Button
          style={ButtonStyle.Primary}
          onClick={updatePreferences}
          disabled={!isChanged || isUpdating}
          hasLoader={isUpdating}
        >
          {isUpdating ? 'Updating preferences' : 'Update preferences'}
        </Button>
      </div>
    </section>
  );
};

UserEmailPreferences.displayName = 'UserEmailPreferences';
