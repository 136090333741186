import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { CSSProperties } from 'react';
import { ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import { ScrollTableState } from '../../../applications/contentInventory/content/models/ScrollTableState.type.ts';
import {
  DataUiCollection,
  DataUiElement,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { DebouncedFunction, debounce } from '../../utils/func/debounce.ts';
import {
  areScrollTableStatsTheSame,
  convertFromPixelsToItems,
  getMaxAdmissibleValue,
} from '../../utils/scrollTableUtils.ts';
import { ScrollTableDefaultRowSkelet } from './ScrollTableDefaultRowSkelet.tsx';

export interface IScrollTableDataProps<ItemType> {
  readonly itemHeight: number;
  readonly items: ReadonlyArray<ItemType | null>;
  readonly collectionName: DataUiCollection;
  readonly scrollTableState: ScrollTableState;
  readonly currentProjectId?: Uuid | null;
}

export interface IScrollTableCallbacksProps {
  readonly onChange: (state: ScrollTableState) => void;
}

export interface IScrollTableOwnProps<ItemType> {
  readonly items: ReadonlyArray<ItemType | null>;
  readonly onItemClick?: (contentItemId: Uuid) => void;
  readonly onItemDoubleClick?: (contentItemId: Uuid) => void;
  readonly onLoadContentItems: ThunkPromise;
  readonly parentContainerRef: React.RefObject<HTMLDivElement>;
  readonly renderEmptyState?: () => React.ReactNode;
  readonly renderRowItem: (
    params: ITableRowSkeletComponentOwnProps<ItemType>,
  ) => React.ReactElement<ITableRowSkeletComponentOwnProps<ItemType>>;
  readonly renderRowSkelet?: () => React.ReactNode;
  readonly renderTableActions?: () => React.ReactNode;
  readonly renderTableHead: () => React.ReactNode;
  readonly renderTableTitle: () => React.ReactNode;
  readonly withColumnSettings?: boolean;
}

type ScrollTableProps<ItemType> = IScrollTableOwnProps<ItemType> &
  IScrollTableDataProps<ItemType> &
  IScrollTableCallbacksProps;

interface IScrollTableComponentState {
  readonly availableHeightPx: number;
  readonly scrollBarWidthPx: number;
  readonly scrollPositionPx: number;
}

export interface ITableRowSkeletComponentOwnProps<ItemType> {
  readonly item: ItemType;
  readonly index: number;
  readonly onItemDoubleClick?: (contentItemId: Uuid, variantId: Uuid) => void;
  readonly onItemClick?: (contentItemId: Uuid, variantId: Uuid) => void;
}

export class ScrollTable<ItemType> extends React.PureComponent<
  ScrollTableProps<ItemType>,
  IScrollTableComponentState
> {
  static displayName = 'ScrollTable';

  static propTypes: PropTypesShape<ScrollTableProps<unknown>> = {
    collectionName: PropTypes.string.isRequired,
    currentProjectId: PropTypes.string,
    itemHeight: PropTypes.number.isRequired,
    items: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onItemClick: PropTypes.func,
    onItemDoubleClick: PropTypes.func,
    onLoadContentItems: PropTypes.func.isRequired,
    parentContainerRef: PropTypes.object.isRequired,
    renderEmptyState: PropTypes.func,
    renderRowItem: PropTypes.func.isRequired,
    renderRowSkelet: PropTypes.func,
    renderTableActions: PropTypes.func,
    renderTableHead: PropTypes.func.isRequired,
    renderTableTitle: PropTypes.func.isRequired,
    scrollTableState: PropTypes.object.isRequired,
    withColumnSettings: PropTypes.bool,
  };

  private readonly _propagateUpdate: DebouncedFunction = debounce(() => {
    if (!areScrollTableStatsTheSame(this._lastDispatchedScrollTableStats, this.state)) {
      this._lastDispatchedScrollTableStats = this.state;
      this.props.onChange(this.state);
    }
  }, 30);

  private readonly _bodyContainer = React.createRef<HTMLDivElement>();
  private readonly _body = React.createRef<HTMLDivElement>();
  private _lastDispatchedScrollTableStats: IScrollTableComponentState | null = null;
  private _scrollWatcherRegistered: boolean = false;
  private readonly _debouncedRecalculateAvailableSize: DebouncedFunction;

  constructor(props: ScrollTableProps<ItemType>) {
    super(props);

    this.state = {
      availableHeightPx: 0,
      scrollBarWidthPx: 0,
      scrollPositionPx: 0,
    };
    this._debouncedRecalculateAvailableSize = debounce(this._recalculateAvailableSize, 100);
  }

  componentDidMount() {
    this._updateScrollPositionAccordingToProps(this.props.scrollTableState);
    this._recalculateAvailableSize();
    this._registerResizeWatcher();
    this._registerScrollWatcher();
    this._propagateUpdate();
  }

  componentDidUpdate(prevProps: ScrollTableProps<ItemType>): void {
    this._recalculateAvailableSize();
    this._registerScrollWatcher();
    this._propagateUpdate();

    if (prevProps.items.length && !this.props.items.length) {
      this._unregisterScrollWatcher();
    }

    // on project change or filter change, the stats in the store will be changed
    if (
      !areScrollTableStatsTheSame(prevProps.scrollTableState, this.props.scrollTableState) &&
      !areScrollTableStatsTheSame(this.props.scrollTableState, this.state)
    ) {
      this._updateScrollPositionAccordingToProps(this.props.scrollTableState);
    } else {
      const bodyContainer = this._bodyContainer.current;
      if (
        bodyContainer &&
        bodyContainer.scrollTop !== this.props.scrollTableState.scrollPositionPx
      ) {
        bodyContainer.scrollTop = this.state.scrollPositionPx;
      }
    }
  }

  componentWillUnmount() {
    this._propagateUpdate.cancel();
    this._debouncedRecalculateAvailableSize.cancel();
    this._unregisterScrollWatcher();
  }

  private readonly _updateScrollPositionAccordingToProps = (
    storeScrollState: ScrollTableState,
  ): void => {
    this.setState(() => ({
      scrollPositionPx: storeScrollState.scrollPositionPx,
    }));

    const bodyContainer = this._bodyContainer.current;
    if (bodyContainer) {
      bodyContainer.scrollTop = this.state.scrollPositionPx;
    }
  };

  private readonly _recalculateAvailableSize = (): void => {
    const bodyContainer = this._bodyContainer.current;
    const body = this._body.current;
    const parentContainer = this.props.parentContainerRef.current;

    if (bodyContainer && body && parentContainer) {
      const tableTopEdge = bodyContainer.getBoundingClientRect().top;
      const tableBottomEdge = parentContainer.getBoundingClientRect().bottom;
      const availableHeightPx = Math.max(0, Math.round(tableBottomEdge - tableTopEdge));
      const scrollBarWidthPx = Math.max(
        0,
        Math.round(bodyContainer.offsetWidth - body.offsetWidth),
      );
      this.setState(() => ({
        availableHeightPx,
        scrollBarWidthPx,
      }));
    }
  };

  private readonly _updateScrollPosition = (event: WheelEvent): void => {
    const scrollPosition = (event.target as HTMLElement).scrollTop;
    this.setState(() => ({
      scrollPositionPx: scrollPosition,
    }));
  };

  private readonly _registerResizeWatcher = (): void =>
    window.addEventListener('resize', this._debouncedRecalculateAvailableSize);

  private readonly _registerScrollWatcher = (): void => {
    const bodyContainer = this._bodyContainer.current;
    if (!this._scrollWatcherRegistered && bodyContainer) {
      this._scrollWatcherRegistered = true;
      bodyContainer.addEventListener('scroll', this._updateScrollPosition);
    }
  };

  private readonly _unregisterScrollWatcher = (): void => {
    const bodyContainer = this._bodyContainer.current;
    if (this._scrollWatcherRegistered && bodyContainer) {
      bodyContainer.removeEventListener('scroll', this._updateScrollPosition);
    }
    this._scrollWatcherRegistered = false;
    window.removeEventListener('resize', this._debouncedRecalculateAvailableSize);
  };

  private readonly _getRow = (index: number): React.ReactNode => {
    const { items, onItemClick, onItemDoubleClick, renderRowItem, renderRowSkelet } = this.props;

    const item = items[index];
    if (item) {
      return renderRowItem({
        index,
        item,
        onItemDoubleClick,
        onItemClick,
      });
    }

    return renderRowSkelet ? renderRowSkelet() : <ScrollTableDefaultRowSkelet key={index} />;
  };

  render() {
    const {
      collectionName,
      itemHeight,
      items,
      renderEmptyState,
      renderTableActions,
      renderTableHead,
      renderTableTitle,
      withColumnSettings,
    } = this.props;

    const scrollTableStats = convertFromPixelsToItems({
      stats: this.state,
      itemHeight,
      totalNumberOfItems: items.length,
    });
    let itemIndex = Math.max(scrollTableStats.indexOfFirstItemInViewport, 0);

    const tableHeadStyle: CSSProperties = {
      paddingRight: this.state.scrollBarWidthPx,
    };

    const tableContainerStyle = {
      maxHeight: getMaxAdmissibleValue(itemHeight * items.length, 0, this.state.availableHeightPx),
    } satisfies CSSProperties;

    const tableBodyStyle: CSSProperties = {
      paddingTop: scrollTableStats.indexOfFirstItemInViewport * itemHeight,
      paddingBottom:
        (items.length -
          scrollTableStats.numberOfItemsInViewport -
          scrollTableStats.indexOfFirstItemInViewport) *
        itemHeight,
    };

    const isScrollable =
      !!tableContainerStyle.maxHeight &&
      tableContainerStyle.maxHeight >= this.state.availableHeightPx;

    return (
      <div
        className={classNames('scroll-table', {
          'scroll-table--with-column-settings': withColumnSettings,
        })}
        {...getDataUiElementAttribute(DataUiElement.ScrollTable)}
      >
        <div className="scroll-table__status">
          {renderTableTitle()}
          {renderTableActions?.()}
        </div>
        <div style={tableHeadStyle}>{renderTableHead()}</div>
        {!items.length && renderEmptyState ? (
          renderEmptyState()
        ) : (
          <div
            className={classNames('scroll-table__body-container', {
              'scroll-table__body-container--no-scroll': !isScrollable,
            })}
            style={tableContainerStyle}
            ref={this._bodyContainer}
            {...getDataUiCollectionAttribute(collectionName)}
          >
            <div className="scroll-table__body" style={tableBodyStyle} ref={this._body}>
              {new Array(
                getMaxAdmissibleValue(scrollTableStats.numberOfItemsInViewport, 0, items.length) +
                  1,
              )
                .join('.')
                .split('')
                .map(() => this._getRow(itemIndex++))}
            </div>
          </div>
        )}
      </div>
    );
  }
}
