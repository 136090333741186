import { useCallback, useMemo } from 'react';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { EditorPlugin } from '../../editorCore/types/Editor.plugins.type.ts';
import { withDisplayName } from '../../editorCore/utils/withDisplayName.ts';
import { EditorFeatureLimitations } from './api/EditorFeatureLimitations.ts';
import { EditorLimitationsApi } from './api/EditorLimitationsApi.type.ts';
import { createEditorLimitationsApi } from './api/editorLimitationsApi.ts';

export type ApiLimitationsPlugin = EditorPlugin<None, None, EditorLimitationsApi>;

export const useApiLimitations: PluginCreator<ApiLimitationsPlugin, [EditorFeatureLimitations]> = (
  baseEditor,
  limitations,
) =>
  useMemo(
    () =>
      withDisplayName('LimitationsPlugin', {
        ComposedEditor: (props) => {
          const api = useMemo(() => createEditorLimitationsApi(limitations), [limitations]);
          const getApiMethods = useCallback(() => api, [api]);

          return useEditorWithPlugin<ApiLimitationsPlugin>(baseEditor, props, { getApiMethods });
        },
      }),
    [baseEditor, limitations],
  );
