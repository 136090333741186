import { Box } from '@kontent-ai/component-library/Box';
import { Button } from '@kontent-ai/component-library/Button';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Stack } from '@kontent-ai/component-library/Stack';
import { ShortcutsConfig, useHotkeys } from '@kontent-ai/component-library/hooks';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { mergeProps } from '@react-aria/utils';
import React from 'react';
import {
  ControlShortcutTemplate,
  ShortcutSymbols,
} from '../../../../../../_shared/constants/shortcutSymbols.ts';
import {
  DataUiAction,
  ObjectWithDataAttribute,
  getDataUiActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type Props = Readonly<{
  dataUiAttribute?: ObjectWithDataAttribute;
  disableCancelButton?: boolean;
  disableSubmitButton?: boolean;
  onReset: () => void;
  onSubmit: (event: React.FormEvent | KeyboardEvent) => void;
  renderExtraButton?: () => React.ReactNode;
  submitButtonText: string;
  submitButtonTooltipText: string;
  submitUsingControlEnter?: boolean;
}>;

export const FormPane = React.forwardRef<HTMLFormElement, React.PropsWithChildren<Props>>(
  (
    {
      children,
      dataUiAttribute,
      disableCancelButton,
      disableSubmitButton,
      onReset,
      onSubmit,
      renderExtraButton,
      submitButtonText,
      submitButtonTooltipText,
      submitUsingControlEnter,
    },
    ref,
  ) => {
    const submitShortcut = submitUsingControlEnter
      ? ControlShortcutTemplate(ShortcutSymbols.Enter)
      : ShortcutSymbols.Enter;
    const cancelButtonRef = React.useRef<HTMLButtonElement>(null);
    const formProps = useHotkeys(
      {
        [ShortcutsConfig.ControlEnter]: submitUsingControlEnter ? onSubmit : undefined,
        [ShortcutsConfig.Enter]: submitUsingControlEnter ? undefined : onSubmit,
        [ShortcutsConfig.Escape]: onReset,
      },
      {
        excludedElementRefs: [cancelButtonRef],
        ref,
      },
    );

    return (
      <form onReset={onReset} onSubmit={onSubmit} {...mergeProps(formProps, dataUiAttribute ?? {})}>
        <Stack spacing={Spacing.XL}>{children}</Stack>
        <Box paddingTop={Spacing.XL}>
          <Row>
            <Column>{renderExtraButton?.()}</Column>
            <Column width="content">
              <Inline spacingX={Spacing.S}>
                <Button
                  ref={cancelButtonRef}
                  disabled={disableCancelButton}
                  size="small"
                  buttonStyle="secondary"
                  type="reset"
                  tooltipText="Cancel"
                  tooltipPlacement="bottom-end"
                  tooltipShortcuts={ShortcutSymbols.Escape}
                  {...getDataUiActionAttribute(DataUiAction.Cancel)}
                >
                  Cancel
                </Button>
                <Button
                  disabled={disableSubmitButton}
                  size="small"
                  buttonStyle="primary"
                  type="submit"
                  tooltipPlacement="bottom-end"
                  tooltipShortcuts={submitShortcut}
                  tooltipText={submitButtonTooltipText}
                  {...getDataUiActionAttribute(DataUiAction.Submit)}
                >
                  {submitButtonText}
                </Button>
              </Inline>
            </Column>
          </Row>
        </Box>
      </form>
    );
  },
);
