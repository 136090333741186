import { assert } from '@kontent-ai/utils';
import { ThunkFunction, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { modalDismissed } from '../../../../../_shared/actions/sharedActions.ts';
import { ContentItemEditingEventOrigins } from '../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { newContentItemVariantInitPrepared } from '../../ContentItemEditing/actions/contentItemEditingActions.ts';
import { CreateNewItemFromType } from '../../ContentItemEditing/actions/thunks/createNewItemFromType.ts';
import { RouteProps } from '../../ContentItemEditing/utils/redirectToItem.ts';

interface IDeps {
  readonly createNewItemFromType: CreateNewItemFromType;
  readonly redirectToItem: (routeProps: RouteProps, contentItemId: Uuid) => void;
  readonly trackContentItemEditingCreateNewAction: (
    actionOrigin: ContentItemEditingEventOrigins,
  ) => ThunkFunction;
}

export const createDuplicateItemWithoutContentAction =
  (deps: IDeps) =>
  (
    routeProps: RouteProps,
    actionOrigin: ContentItemEditingEventOrigins,
    destinationCollectionId?: Uuid,
  ): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      contentApp: { editedContentItemVariant },
    } = getState();

    assert(
      editedContentItemVariant,
      () => `${__filename}: Edited content item variant was not found.`,
    );

    dispatch(
      newContentItemVariantInitPrepared({
        workflowId: editedContentItemVariant.assignment.workflowStatus.workflowId,
      }),
    );

    const createdItem = await dispatch(deps.createNewItemFromType(destinationCollectionId));

    deps.redirectToItem(routeProps, createdItem.createdItemId);
    dispatch(modalDismissed());

    dispatch(deps.trackContentItemEditingCreateNewAction(actionOrigin));
  };
