import { areShallowEqual } from '@kontent-ai/utils';
import {
  Reducer,
  RefCallback,
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
} from 'react';
import useResizeObserver from 'use-resize-observer';

export enum SliceFrom {
  Beginning = 'Beginning',
  End = 'End',
}

export enum SliceDirection {
  Horizontal = 'Horizontal',
  Vertical = 'Vertical',
}

type VisibleItemId = number | string;
type VisibleItemsById = Map<VisibleItemId, HTMLElement>;

type SplitItems<TItem> = {
  readonly allItems: ReadonlyArray<TItem>;
  readonly visibleItems: ReadonlyArray<TItem>;
  readonly hiddenItems: ReadonlyArray<TItem>;
  readonly thresholdSize: number;
};

type IGetElementSize = (element: Element) => number;

const getElementWidth: IGetElementSize = (element) => element.scrollWidth;
const getElementHeight: IGetElementSize = (element) => element.scrollHeight;

type Action<TItem> =
  | {
      readonly type: 'resized';
      readonly thresholdSize: number;
    }
  | {
      readonly type: 'itemsChanged';
      readonly items: ReadonlyArray<TItem>;
    }
  | {
      readonly type: 'rendered';
      readonly visibleItemsById: VisibleItemsById;
      readonly sliceFrom: SliceFrom;
      readonly containerRef: RefObject<HTMLElement>;
      readonly getElementSize: IGetElementSize;
    };

export const sliceItems = <TItem>(
  allItems: ReadonlyArray<TItem>,
  lastVisibleIndex: number | null,
  sliceFrom: SliceFrom,
): [visibleItems: ReadonlyArray<TItem>, hiddenItems: ReadonlyArray<TItem>] => {
  if (lastVisibleIndex === null) {
    return [[], allItems];
  }

  if (sliceFrom === SliceFrom.Beginning) {
    return [
      allItems.slice(-1 * (lastVisibleIndex + 1)),
      allItems.slice(0, -1 * (lastVisibleIndex + 1)),
    ];
  }

  return [allItems.slice(0, lastVisibleIndex + 1), allItems.slice(lastVisibleIndex + 1)];
};

const reducer = <TItem>(state: SplitItems<TItem>, action: Action<TItem>): SplitItems<TItem> => {
  switch (action.type) {
    case 'itemsChanged': {
      return {
        ...state,
        allItems: action.items,
        visibleItems: action.items,
        hiddenItems: [],
      };
    }

    case 'resized': {
      return {
        ...state,
        thresholdSize: action.thresholdSize,
        visibleItems: state.allItems,
        hiddenItems: [],
      };
    }

    case 'rendered': {
      const itemsSizeAcc = getAccumulatedItemsSize(
        action.visibleItemsById,
        action.sliceFrom,
        action.getElementSize,
      );
      const hideableContentSize = itemsSizeAcc[itemsSizeAcc.length - 1] ?? 0;
      const containerContentSize = Array.from(action.containerRef.current?.children ?? []).reduce(
        (totalSize, child) => {
          return totalSize + action.getElementSize(child);
        },
        0,
      );
      const nonHideableContentSize =
        containerContentSize - hideableContentSize < 0
          ? 0
          : containerContentSize - hideableContentSize;
      const itemsSizeAccWithOffset = itemsSizeAcc.map((size) => size + nonHideableContentSize);
      const shouldSlice =
        state.thresholdSize < (itemsSizeAccWithOffset[itemsSizeAccWithOffset.length - 1] ?? 0);

      if (shouldSlice) {
        const lastVisibleIndex = getLastVisibleIndex(state.thresholdSize, itemsSizeAccWithOffset);
        const [visibleItems, hiddenItems] = sliceItems(
          state.allItems,
          lastVisibleIndex,
          action.sliceFrom,
        );

        if (
          areShallowEqual(visibleItems, state.visibleItems) &&
          areShallowEqual(hiddenItems, state.hiddenItems)
        ) {
          return state;
        }

        return {
          ...state,
          visibleItems,
          hiddenItems,
        };
      }
      return state;
    }

    default:
      return state;
  }
};

export function getLastVisibleIndex(
  thresholdSize: number,
  itemsSizeAcc: ReadonlyArray<number>,
): number | null {
  const indexOfFirstNotVisibleItem = itemsSizeAcc.findIndex((accSize) => accSize > thresholdSize);

  if (indexOfFirstNotVisibleItem === 0) {
    return null;
  }

  return indexOfFirstNotVisibleItem === -1
    ? itemsSizeAcc.length - 1
    : indexOfFirstNotVisibleItem - 1;
}

const sortVisibleItems = (
  visibleItemsById: VisibleItemsById,
  direction: SliceFrom,
): ReadonlyArray<HTMLElement> => {
  const visibleItems = Array.from(visibleItemsById.values());
  return direction === SliceFrom.End ? visibleItems : visibleItems.reverse();
};

const getAccumulatedItemsSize = (
  visibleItemsById: VisibleItemsById,
  sliceFrom: SliceFrom,
  getElementSize: IGetElementSize,
): ReadonlyArray<number> => {
  const visibleItems = sortVisibleItems(visibleItemsById, sliceFrom);
  const itemsSize = visibleItems.filter(Boolean).map((item) => getElementSize(item));

  return itemsSize.reduce((itemSizes, nextItemSize) => {
    const lastSize = itemSizes[itemSizes.length - 1] ?? 0;
    return [...itemSizes, lastSize + nextItemSize];
  }, []);
};

export type AttachVisibleItemRefCallback = (itemId: VisibleItemId) => RefCallback<HTMLElement>;

const getVisibleItemsById = (visibleItemsRef: RefObject<VisibleItemsById>): VisibleItemsById =>
  visibleItemsRef.current ?? new Map();

interface OverflowingItemsInfo<TItem> {
  readonly attachVisibleItemRef: AttachVisibleItemRefCallback;
  readonly visibleItems: ReadonlyArray<TItem>;
  readonly hiddenItems: ReadonlyArray<TItem>;
}

export const useSliceOverflowingItems = <TItem>(
  allItems: ReadonlyArray<TItem>,
  containerRef: RefObject<HTMLElement>,
  sliceFrom: SliceFrom,
  direction: SliceDirection,
): OverflowingItemsInfo<TItem> => {
  const getElementSize: IGetElementSize =
    direction === SliceDirection.Horizontal ? getElementWidth : getElementHeight;

  const [{ visibleItems, hiddenItems }, dispatch] = useReducer<
    Reducer<SplitItems<TItem>, Action<TItem>>
  >(reducer, {
    allItems,
    visibleItems: allItems,
    hiddenItems: [],
    thresholdSize: containerRef.current ? getElementSize(containerRef.current) : Number.MAX_VALUE,
  });

  useResizeObserver({
    onResize: ({ width = Number.MAX_VALUE, height = Number.MAX_VALUE }) => {
      dispatch({
        type: 'resized',
        thresholdSize: direction === SliceDirection.Horizontal ? width : height,
      });
    },
    ref: containerRef,
  });

  const visibleItemsRef = useRef<VisibleItemsById>(new Map());

  useLayoutEffect(() => {
    const visibleItemsById = getVisibleItemsById(visibleItemsRef);
    dispatch({
      type: 'rendered',
      sliceFrom,
      visibleItemsById,
      containerRef,
      getElementSize,
    });
  });

  useEffect(() => {
    dispatch({
      type: 'itemsChanged',
      items: allItems,
    });
  }, [allItems]);

  const attachVisibleItemRef: AttachVisibleItemRefCallback = useCallback(
    (itemId) => (instance) => {
      if (instance instanceof HTMLElement) {
        visibleItemsRef.current.set(itemId, instance);
      } else {
        visibleItemsRef.current.delete(itemId);
      }
    },
    [],
  );

  return {
    attachVisibleItemRef,
    visibleItems,
    hiddenItems,
  };
};
