import { getSelectedLanguageId } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { getCurrentProject } from '../../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { ICompiledContentItemElementData } from '../../../../models/contentItemElements/ICompiledContentItemElement.ts';
import {
  isRichTextElement,
  isTextElement,
} from '../../../../models/contentItemElements/compiledItemElementTypeGuards.ts';
import { getCurrentlyEditedVariantTranslationTaskId } from '../../../../reducers/editorUi/reducers/tasks/getCurrentlyEditedVariantTranslationTaskId.ts';
import { VariantTranslationCondition } from '../../constants/VariantTranslationCondition.ts';
import { getLanguageOptionsMemoized } from '../../containers/selectors/copyContentFromVariantMenuOptions.ts';

export const getFailedTranslationCondition = (state: IStore): VariantTranslationCondition => {
  const translatableElementsPresent = areTranslatableElementsPresent(
    state.contentApp.editedContentItemVariantElements,
  );

  if (!translatableElementsPresent) return VariantTranslationCondition.NoTranslatableElements;

  if (!doesSomeOtherVariantExist(state)) return VariantTranslationCondition.NoOtherVariantExists;

  const taskId = getCurrentlyEditedVariantTranslationTaskId(state);
  if (taskId && state.contentApp.variantTranslationTaskIdsInProgress.has(taskId)) {
    return VariantTranslationCondition.TranslationIsRunning;
  }

  return VariantTranslationCondition.None;
};

const getTranslateContentLanguageOptions = (state: IStore) => {
  const selectedLanguageId = getSelectedLanguageId(state);
  if (!selectedLanguageId) {
    return [];
  }

  const {
    contentApp: { contentItemVariants, editedContentItem },
    data: { languages, user },
  } = state;

  const currentProject = getCurrentProject(state);
  const collectionId = editedContentItem?.collectionId ?? null;

  return getLanguageOptionsMemoized(
    languages,
    selectedLanguageId,
    contentItemVariants,
    user,
    currentProject.projectId,
    collectionId,
  );
};

const doesSomeOtherVariantExist = (state: IStore) =>
  !!(state.data.languages.byId.size > 0 && getTranslateContentLanguageOptions(state).length);

const areTranslatableElementsPresent = (elements: ReadonlyArray<ICompiledContentItemElementData>) =>
  elements.some((e) => isRichTextElement(e) || isTextElement(e));
