import { format } from 'date-fns';
import { Button } from '../../../uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../../uiComponents/Button/buttonStyle.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';

export const DateTimePickerTitle = () => (
  <div className="datetime-picker__title">Select a date</div>
);

type DateTimePickerHeaderProps = {
  readonly changeMonth: (month: number) => void;
  readonly changeYear: (year: number) => void;
  readonly date: Date;
  readonly decreaseMonth: () => void;
  readonly increaseMonth: () => void;
  readonly nextMonthButtonDisabled: boolean;
  readonly prevMonthButtonDisabled: boolean;
};

export const DateTimePickerHeader = (props: DateTimePickerHeaderProps) => {
  const readableDateTime = format(props.date, 'MMMM yyyy');

  return (
    <>
      <DateTimePickerTitle />
      <div className="datetime-picker__header">
        <Button
          style={ButtonStyle.None}
          type="button"
          customButtonClassName="datetime-picker__navigation datetime-picker__navigation--previous"
          onClick={props.prevMonthButtonDisabled ? undefined : props.decreaseMonth}
          disabled={props.prevMonthButtonDisabled}
          dataUiAction={DataUiAction.SelectPreviousMonth}
        />
        <div
          className="datetime-picker__current-month"
          {...getDataUiElementAttribute(DataUiElement.DateTimePickerCurrentMonth)}
        >
          {readableDateTime}
        </div>
        <Button
          style={ButtonStyle.None}
          type="button"
          customButtonClassName="datetime-picker__navigation datetime-picker__navigation--next"
          onClick={props.nextMonthButtonDisabled ? undefined : props.increaseMonth}
          disabled={props.nextMonthButtonDisabled}
          dataUiAction={DataUiAction.SelectNextMonth}
        />
      </div>
    </>
  );
};
