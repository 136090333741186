import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import { Icon } from '../../../../../_shared/uiComponents/Icon/Icon.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ITaxonomyTerm } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyTerm.ts';

type Props = {
  readonly folder: ITaxonomyTerm;
  readonly isInTooltip?: boolean;
  readonly isLast: boolean;
  readonly onClick?: (event: React.MouseEvent) => void;
};

const propTypes: PropTypesShape<Props> = {
  folder: PropTypes.object.isRequired,
  isInTooltip: PropTypes.bool,
  isLast: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

const AssetFolderBreadcrumbItem: React.FC<Props> = ({ folder, isInTooltip, isLast, onClick }) => (
  <Tooltip tooltipText={folder.name} placement={isInTooltip ? 'right' : 'bottom'}>
    <div
      className={classNames('asset-breadcrumbs__item', {
        'asset-breadcrumbs__item--is-last': isLast,
        'asset-breadcrumbs__item--is-clickable': !!onClick,
        'asset-breadcrumbs__item--is-in-tooltip': isInTooltip,
      })}
      onClick={onClick}
      {...getDataUiElementAttribute(DataUiElement.AssetFolderBreadcrumbItem)}
    >
      <Icon iconName={IconName.FolderInverted} className="asset-breadcrumbs__item-icon" />
      <span className="asset-breadcrumbs__item-name">{folder.name}</span>
    </div>
  </Tooltip>
);

AssetFolderBreadcrumbItem.displayName = 'AssetFolderBreadcrumbItem';
AssetFolderBreadcrumbItem.propTypes = propTypes;

export { AssetFolderBreadcrumbItem };
