import Immutable from 'immutable';
import { ContentItemId } from '../../../_shared/models/ContentItemId.type.ts';
import {
  IAssignment,
  emptyAssignment,
  getAssignmentFromServerModel,
} from '../../../applications/itemEditor/models/contentItem/Assignment.ts';
import { IListingVariantDataServerModel } from '../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import { IContentItemIdentifierServerModel } from '../../../repositories/serverModels/INewContentItemServerModel.ts';
import { PublishingState } from '../../constants/PublishingState.ts';
import { IAssignmentWorkflowStep, mapToAssignmentWorkflowStep } from '../workflow/WorkflowStep.ts';

export interface IListingVariantData {
  readonly id: ContentItemId;
  readonly actualWorkflowStatus: IAssignmentWorkflowStep | null;
  readonly lastPublishedAt: DateTimeStamp | null;
  readonly lastModified: DateTimeStamp | null;
  readonly publishingState: PublishingState;
  readonly assignment: IAssignment;
  readonly isComplete: boolean;
  readonly isArchived: boolean;
  readonly urlSlug?: string | null;
  readonly contentLinkIds: Immutable.Set<Uuid>;
  readonly modularItemIds: ReadonlyArray<Uuid>;
}

export const emptyListingVariantData: IListingVariantData = {
  id: { itemId: '', variantId: '' },
  actualWorkflowStatus: null,
  lastPublishedAt: null,
  lastModified: null,
  publishingState: PublishingState.None,
  assignment: emptyAssignment,
  isComplete: false,
  isArchived: false,
  urlSlug: null,
  contentLinkIds: Immutable.Set<Uuid>(),
  modularItemIds: [],
};

export const getListingVariantDataFromJS = (
  serverModel: IListingVariantDataServerModel,
): IListingVariantData => {
  const variant: IListingVariantData = {
    id: serverModel.id,
    actualWorkflowStatus: serverModel.actualWorkflowStatus
      ? mapToAssignmentWorkflowStep(serverModel.actualWorkflowStatus)
      : null,
    lastPublishedAt: serverModel.lastPublishedAt,
    lastModified: serverModel.lastModified,
    publishingState: serverModel.publishingState,
    assignment: serverModel.assignment
      ? getAssignmentFromServerModel(serverModel.assignment)
      : emptyAssignment,
    isComplete: serverModel.isComplete,
    isArchived: serverModel.archived,
    urlSlug: serverModel.urlSlug,
    contentLinkIds: Immutable.Set(
      (serverModel.contentLinkIds || []).map((id: IContentItemIdentifierServerModel) => id.itemId),
    ),
    modularItemIds: (serverModel.modularItemIds || []).map(
      (id: IContentItemIdentifierServerModel) => id.itemId,
    ),
  };
  return variant;
};
