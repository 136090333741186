import React, { memo } from 'react';
import { DataUiInput } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  createEditorWithPlugins,
  getBaseEditor,
  usePluginWithParams,
} from '../../editorCore/Editor.composition.tsx';
import { EditorProps } from '../../editorCore/types/Editor.composition.type.ts';
import { useAiStyles } from '../../plugins/ai/AiStylesPlugin.tsx';
import { useApiLimitations } from '../../plugins/apiLimitations/ApiLimitationsPlugin.tsx';
import { SimpleTextEditorLimitations } from '../../plugins/apiLimitations/api/EditorFeatureLimitations.ts';
import { useCompositionModeAdjustments } from '../../plugins/behavior/CompositionModeAdjustmentsPlugin.tsx';
import { useExternalUpdate } from '../../plugins/behavior/ExternalUpdatePlugin.tsx';
import { useFocus } from '../../plugins/behavior/FocusPlugin.tsx';
import { useReadonly } from '../../plugins/behavior/ReadonlyPlugin.tsx';
import { useSpellCheck } from '../../plugins/behavior/SpellCheckPlugin.tsx';
import { useClipboard } from '../../plugins/clipboard/ClipboardPlugin.tsx';
import { useEntityApi } from '../../plugins/entityApi/EntityApiPlugin.tsx';
import { useDisplayInlineAi } from '../../plugins/inlineAi/DisplayInlineAiPlugin.tsx';
import { useKeyboardShortcuts } from '../../plugins/keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { textKeyCommandMap } from '../../plugins/keyboardShortcuts/api/editorKeyboardUtils.ts';
import { useTextApi } from '../../plugins/textApi/TextApiPlugin.tsx';
import { usePlaceholder } from '../../plugins/visuals/PlaceholderPlugin.tsx';
import { useStyles } from '../../plugins/visuals/StylesPlugin.tsx';
import { useWrapper } from '../../plugins/visuals/WrapperPlugin.tsx';

export const useComposedViewer = () =>
  createEditorWithPlugins(
    getBaseEditor(),
    usePlaceholder,
    useWrapper,
    useCompositionModeAdjustments,
    useFocus,
    usePluginWithParams(useApiLimitations, SimpleTextEditorLimitations),
    usePluginWithParams(useKeyboardShortcuts, textKeyCommandMap),
    useTextApi,
    useClipboard,
    useStyles,
    useAiStyles,
    useEntityApi,
    useDisplayInlineAi,
    useSpellCheck,
    useExternalUpdate,
    useReadonly,
  );

export type SimpleMultilineTextViewerProps = EditorProps<typeof useComposedViewer>;

export const SimpleMultilineTextViewer: React.FC<SimpleMultilineTextViewerProps> = memo((props) => {
  const { ComposedEditor } = useComposedViewer();

  return (
    <ComposedEditor dataUiInput={props.dataUiInput ?? DataUiInput.ItemTextElement} {...props} />
  );
});

SimpleMultilineTextViewer.displayName = 'SimpleMultilineTextViewer';
