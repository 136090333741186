import { Action } from '../../../../../../@types/Action.type.ts';
import { ContentItemSidebarEditableSection } from '../../../../features/ContentItemEditing/constants/ContentItemSidebarEditableSection.ts';
import {
  ContentItemEditing_Action_Activated,
  ContentItemEditing_CreateTask_Finished,
  ContentItemEditing_Sidebar_Section_Focused,
  ContentItemEditing_TaskArchive_Finished,
  ContentItemEditing_TaskEditing_Cancelled,
  ContentItemEditing_TaskEditing_Started,
  ContentItemEditing_UpdateTask_Finished,
} from '../../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { NewTaskId } from '../../../../features/ContentItemEditing/constants/taskConstants.ts';
import { EditingAction } from '../../../../features/ContentItemEditing/models/EditingAction.ts';

export const selectedTaskId = (state: Uuid | null = null, action: Action): Uuid | null => {
  switch (action.type) {
    case ContentItemEditing_Sidebar_Section_Focused: {
      if (action.payload.section === ContentItemSidebarEditableSection.Tasks) {
        return NewTaskId;
      }

      return state;
    }

    case ContentItemEditing_Action_Activated: {
      if (action.payload.activatedAction === EditingAction.addTask) {
        return NewTaskId;
      }

      return state;
    }

    case ContentItemEditing_TaskEditing_Cancelled:
      return null;

    case ContentItemEditing_UpdateTask_Finished:
    case ContentItemEditing_CreateTask_Finished:
    case ContentItemEditing_TaskArchive_Finished:
      return action.payload.shouldSectionClose ? null : state;

    case ContentItemEditing_TaskEditing_Started:
      return action.payload.taskId;

    default: {
      return state;
    }
  }
};
