import PropTypes from 'prop-types';
import React from 'react';
import { RevisionViewer } from '../../itemEditor/features/Revisions/containers/RevisionViewer.tsx';
import { RevisionsContextProvider } from '../../itemEditor/features/Revisions/context/RevisionsContext.tsx';
import { WebSpotlightTabs } from './WebSpotlightTabs.tsx';
import { WebSpotlightWorkspace } from './WebSpotlightWorkspace.tsx';

interface IWebSpotlightRevisionViewProps {
  readonly timelineItemId: Uuid;
}

const propTypes: PropTypesShape<IWebSpotlightRevisionViewProps> = {
  timelineItemId: PropTypes.string.isRequired,
};

export const WebSpotlightRevisionView: React.FC<IWebSpotlightRevisionViewProps> = ({
  timelineItemId,
}) => (
  <>
    <WebSpotlightTabs />
    <WebSpotlightWorkspace>
      <RevisionsContextProvider>
        <RevisionViewer timelineItemId={timelineItemId} />
      </RevisionsContextProvider>
    </WebSpotlightWorkspace>
  </>
);

WebSpotlightRevisionView.displayName = 'WebSpotlightRevisionView';
WebSpotlightRevisionView.propTypes = propTypes;
