import { InvariantException } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { DateTime } from '../../../../../../_shared/models/DateTime.ts';
import { Task, createTaskFromServerModel } from '../../../../../../_shared/models/Task.ts';
import { TrackUserEventWithDataAction } from '../../../../../../_shared/models/TrackUserEvent.type.ts';
import { TasksEventActions } from '../../../../../../_shared/models/events/TaskEventData.type.ts';
import { logError } from '../../../../../../_shared/utils/logError.ts';
import { ITaskRepository } from '../../../../../../repositories/interfaces/ITaskRepository.type.ts';
import {
  ContentItemEditing_UpdateTask_Failed,
  ContentItemEditing_UpdateTask_Finished,
  ContentItemEditing_UpdateTask_Started,
} from '../../constants/contentItemEditingActionTypes.ts';
import { shouldCloseTask } from '../../selectors/shouldCloseTask.ts';
import { tasksFormReset } from '../contentItemEditingActions.ts';

const started = (taskId: Uuid) =>
  ({
    type: ContentItemEditing_UpdateTask_Started,
    payload: {
      taskId,
    },
  }) as const;

const failed = (taskId: Uuid) =>
  ({
    type: ContentItemEditing_UpdateTask_Failed,
    payload: {
      taskId,
    },
  }) as const;

const finished = (task: Task, shouldSectionClose: boolean) =>
  ({
    type: ContentItemEditing_UpdateTask_Finished,
    payload: {
      shouldSectionClose,
      task,
    },
  }) as const;

export type UpdateTaskActionsType = ReturnType<typeof failed | typeof finished | typeof started>;

interface IDeps {
  readonly taskRepository: Pick<ITaskRepository, 'modifyTask'>;
  readonly trackUserEventWithData: TrackUserEventWithDataAction;
}

export const updateTaskCreator =
  (deps: IDeps) =>
  (
    taskId: Uuid,
    description: string,
    contributorIds: ReadonlyArray<string>,
    dueDate: DateTime,
  ): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      contentApp: { editedContentItemVariant },
    } = getState();
    if (!editedContentItemVariant) {
      throw InvariantException('updateTask.ts: editedContentItemVariant is falsy');
    }

    dispatch(started(taskId));

    try {
      const updatedTask = await deps.taskRepository.modifyTask(taskId, {
        assignees: contributorIds,
        description,
        due: dueDate?.isValid ? dueDate.value : null,
      });
      const parsedTask = createTaskFromServerModel(updatedTask);

      const closeTask = shouldCloseTask(getState(), taskId);
      dispatch(finished(parsedTask, closeTask));
      if (closeTask) {
        dispatch(tasksFormReset(taskId));
      }
    } catch (error) {
      logError('Error during updating a task', error);
      dispatch(failed(taskId));
    }

    dispatch(
      deps.trackUserEventWithData(TrackedEvent.Tasks, {
        action: TasksEventActions.TaskUpdated,
        workflowStepId: editedContentItemVariant.assignment.workflowStatus.id,
        hasDueDate: dueDate.isValid,
      }),
    );
  };
