import { Input, InputType } from '@kontent-ai/component-library/Input';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactFocusLock from 'react-focus-lock';
import { HotkeysHandler } from '../../../../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import { PopoverMessage } from '../../../../../../_shared/uiComponents/Popover/Popover.tsx';
import {
  DataUiElement,
  DataUiInput,
  getDataUiElementAttribute,
  getDataUiInputAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { EmailLinkProperties } from '../../../../../itemEditor/models/contentItemElements/richText/EmailLinkProperties.type.ts';
import { LinkDialogActionsBar } from '../../components/dialog/LinkDialogActionsBar.tsx';

interface IEmailLinkDialogProps {
  readonly emailAddress?: string;
  readonly emailSubject?: string;
  readonly linkText: string;
  readonly onCancel: () => void;
  readonly onUnlink?: () => void;
  readonly onUpsert: (values: EmailLinkProperties) => void;
}

const propTypes: PropTypeMap<IEmailLinkDialogProps> = {
  emailAddress: PropTypes.string,
  emailSubject: PropTypes.string,
  linkText: PropTypes.string.isRequired,

  onCancel: PropTypes.func.isRequired,
  onUpsert: PropTypes.func.isRequired,
  onUnlink: PropTypes.func,
};

export const EmailLinkDialog: React.FC<IEmailLinkDialogProps> = (props) => {
  const [linkText, setLinkText] = useState(props.linkText);
  const [emailAddress, setEmailAddress] = useState(props.emailAddress || '');
  const [emailSubject, setEmailSubject] = useState(props.emailSubject || '');

  const isSubmittingDisabled = !linkText || !emailAddress;

  const onSubmit = (e: React.FormEvent<HTMLFormElement> | KeyboardEvent): void => {
    e.preventDefault();
    if (!isSubmittingDisabled) {
      props.onUpsert({
        linkText,
        emailAddress,
        emailSubject,
      });
    }
  };

  const submitButtonText = props.onUnlink ? 'Update' : 'Insert';
  const disabledSubmitButtonTooltipText = `Fill in the link text and email address before ${
    props.onUnlink ? 'updating' : 'insertion'
  }`;

  return (
    <ReactFocusLock>
      <HotkeysHandler
        handlers={{
          onEscape: props.onCancel,
        }}
        {...getDataUiElementAttribute(DataUiElement.RteLinkDialog)}
      >
        <form onSubmit={onSubmit}>
          <PopoverMessage>
            <Stack spacing={Spacing.XL}>
              <Input
                label="Link text"
                autoComplete="off"
                type={InputType.Text}
                value={linkText}
                onChange={(e) => setLinkText(e.target.value)}
                {...getDataUiInputAttribute(DataUiInput.Text)}
              />
              <Input
                autoFocus
                label="Email"
                autoComplete="off"
                type={InputType.Text}
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
                {...getDataUiInputAttribute(DataUiInput.Email)}
              />
              <Input
                label="Subject"
                autoComplete="off"
                type={InputType.Text}
                value={emailSubject}
                onChange={(e) => setEmailSubject(e.target.value)}
                {...getDataUiInputAttribute(DataUiInput.Title)}
              />
            </Stack>
          </PopoverMessage>
          <LinkDialogActionsBar
            disabledSubmitButtonTooltipText={disabledSubmitButtonTooltipText}
            isSubmittingDisabled={isSubmittingDisabled}
            onCancel={props.onCancel}
            onUnlink={props.onUnlink}
            submitButtonText={submitButtonText}
          />
        </form>
      </HotkeysHandler>
    </ReactFocusLock>
  );
};

EmailLinkDialog.displayName = 'EmailLinkDialog';
EmailLinkDialog.propTypes = propTypes;
