import { ContentState, EditorState } from 'draft-js';
import React, { useCallback, useImperativeHandle, useMemo } from 'react';
import { useEditorStateCallbacks } from '../../editorCore/hooks/useEditorStateCallbacks.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { EditorPlugin } from '../../editorCore/types/Editor.plugins.type.ts';
import { withDisplayName } from '../../editorCore/utils/withDisplayName.ts';

type ResetInput = (content: ContentState) => void;

export type Resettable = {
  readonly resetInput: ResetInput;
};

type ManualResetPluginProps = {
  readonly resettableRef?: React.Ref<Resettable>;
};

export type ManualResetPlugin = EditorPlugin<None, ManualResetPluginProps>;

export const useManualReset: PluginCreator<ManualResetPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('ManualResetPlugin', {
        ComposedEditor: (props) => {
          const { resettableRef } = props;

          const { decorateWithEditorStateCallbacks, reinit } = useEditorStateCallbacks();

          const resetInput: ResetInput = useCallback(
            (contentState) => {
              reinit(EditorState.createWithContent(contentState));
            },
            [reinit],
          );

          useImperativeHandle(resettableRef, () => ({ resetInput }));

          return useEditorWithPlugin(baseEditor, props, {
            apply: decorateWithEditorStateCallbacks,
          });
        },
      }),
    [baseEditor],
  );
