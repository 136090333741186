import { notNullNorUndefined } from '@kontent-ai/utils';
import React from 'react';

export const createAiTranslateButtonPlaceholderId = (
  elementId: Uuid,
  contentComponentId?: Uuid | null,
): string => [contentComponentId, elementId].filter(notNullNorUndefined).join(';');

export const AiTranslateButtonPlaceholder: React.FC<{
  readonly contentComponentId?: Uuid | null;
  readonly elementId: Uuid;
}> = ({ contentComponentId, elementId }) => (
  <div id={createAiTranslateButtonPlaceholderId(elementId, contentComponentId)} />
);
