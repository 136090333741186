import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  DataTableCell,
  IDataTableCellProps,
  propTypes as dataTableCellPropTypes,
  wrapPlainContentItems,
} from './DataTableCell.tsx';

interface ILinkDataTableCellProps extends IDataTableCellProps {
  readonly linkPath: string | undefined | null;
  readonly openInNewTab?: boolean;
}

const propTypes: PropTypesShape<ILinkDataTableCellProps> = {
  ...dataTableCellPropTypes,
  linkPath: PropTypes.string,
  openInNewTab: PropTypes.bool,
};

export const LinkDataTableCell: React.FC<React.PropsWithChildren<ILinkDataTableCellProps>> = ({
  disabled,
  isMultiline,
  linkPath,
  openInNewTab,
  children,
  className,
  ...rest
}) => {
  if (linkPath && !disabled) {
    return (
      <DataTableCell {...rest} className={className} isMultiline={isMultiline} hasLink>
        <Link
          className={classNames('data-table__link', {
            'data-table__link--multiline': isMultiline,
            'data-table__link--outward': openInNewTab,
          })}
          to={linkPath}
          target={openInNewTab ? '_blank' : undefined}
        >
          {wrapPlainContentItems(children)}
        </Link>
      </DataTableCell>
    );
  }

  return (
    <DataTableCell {...rest} disabled={disabled} className={className} isMultiline={isMultiline}>
      {children}
    </DataTableCell>
  );
};

LinkDataTableCell.displayName = 'LinkDataTableCell';
LinkDataTableCell.propTypes = propTypes;
