import { Action } from '../../../../../@types/Action.type.ts';
import {
  SubscriptionUserDetail_Closed,
  SubscriptionUserDetail_NotificationBar_Hidden,
  SubscriptionUserDetail_ResendInviteToProjects_Failed,
  SubscriptionUserDetail_ResendInviteToProjects_Finished,
  SubscriptionUserDetail_RevokeInviteToProjects_Failed,
  SubscriptionUserDetail_RevokeInviteToProjects_Finished,
  SubscriptionUserDetail_ToggleActiveStateOnProjects_Failed,
  SubscriptionUserDetail_ToggleActiveStateOnProjects_Finished,
  SubscriptionUser_TriggerActiveStateOnAllSubscriptionProjects_Failed,
  SubscriptionUser_TriggerActiveStateOnAllSubscriptionProjects_Finished,
} from '../../constants/actionTypes.ts';
import {
  activationFailed,
  deactivationFailed,
  inviteFailed,
  revokeFailed,
} from '../../constants/errors.ts';

const emptyState = null;

export const savingError = (state: string | null = emptyState, action: Action): string | null => {
  switch (action.type) {
    case SubscriptionUser_TriggerActiveStateOnAllSubscriptionProjects_Failed: {
      if (!action.payload.triggeredFromUsersOverview) {
        return action.payload.activate ? activationFailed : deactivationFailed;
      }

      return state;
    }

    case SubscriptionUserDetail_ToggleActiveStateOnProjects_Failed:
      return action.payload.activate ? activationFailed : deactivationFailed;

    case SubscriptionUserDetail_RevokeInviteToProjects_Failed:
      return revokeFailed;

    case SubscriptionUserDetail_ResendInviteToProjects_Failed:
      return inviteFailed;

    case SubscriptionUserDetail_Closed:
    case SubscriptionUser_TriggerActiveStateOnAllSubscriptionProjects_Finished:
    case SubscriptionUserDetail_ToggleActiveStateOnProjects_Finished:
    case SubscriptionUserDetail_ResendInviteToProjects_Finished:
    case SubscriptionUserDetail_RevokeInviteToProjects_Finished:
    case SubscriptionUserDetail_NotificationBar_Hidden:
      return emptyState;

    default:
      return state;
  }
};
