import { Action } from '../../../../../../@types/Action.type.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { ContentItemSidebarEditableSection } from '../../../../features/ContentItemEditing/constants/ContentItemSidebarEditableSection.ts';
import {
  ContentItemEditing_AssignmentChange_Abandoned,
  ContentItemEditing_CreateTask_Finished,
  ContentItemEditing_Init_Started,
  ContentItemEditing_Sidebar_Closed,
  ContentItemEditing_Sidebar_Section_Cleaned_Up,
  ContentItemEditing_Sidebar_Section_Focused,
  ContentItemEditing_Sidebar_Section_UpdateFinished,
  ContentItemEditing_TaskEditing_Cancelled,
  ContentItemEditing_TaskEditing_Started,
  ContentItemEditing_UpdateTask_Finished,
} from '../../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

export interface IContentItemSidebarEditedSection {
  readonly actionOrigin: ContentItemEditingEventOrigins;
  readonly section: ContentItemSidebarEditableSection | null;
}

const initialState: IContentItemSidebarEditedSection = {
  actionOrigin: ContentItemEditingEventOrigins.Undefined,
  section: null,
};

export const editedSection = (
  state: IContentItemSidebarEditedSection = initialState,
  action: Action,
): IContentItemSidebarEditedSection => {
  switch (action.type) {
    case ContentItemEditing_Sidebar_Section_Focused: {
      return {
        actionOrigin: action.payload.actionOrigin,
        section: action.payload.section,
      };
    }
    case ContentItemEditing_TaskEditing_Started: {
      return {
        actionOrigin: ContentItemEditingEventOrigins.ItemDetails,
        section: ContentItemSidebarEditableSection.Tasks,
      };
    }
    case ContentItemEditing_AssignmentChange_Abandoned:
    case ContentItemEditing_Init_Started:
    case ContentItemEditing_Sidebar_Closed:
    case ContentItemEditing_Sidebar_Section_Cleaned_Up:
    case ContentItemEditing_TaskEditing_Cancelled: {
      return initialState;
    }
    case ContentItemEditing_CreateTask_Finished:
    case ContentItemEditing_Sidebar_Section_UpdateFinished:
    case ContentItemEditing_UpdateTask_Finished: {
      return action.payload.shouldSectionClose ? initialState : state;
    }
    default:
      return state;
  }
};
