import { memoize } from '@kontent-ai/memoization';
import React from 'react';
import {
  publishSelectedContentItems,
  restoreSelectedVariantsFromArchivedStep,
  schedulePublishContentItems,
  scheduleUnpublishContentItems,
  selectWorkflowStepForListing,
  unpublishSelectedContentItems,
  updateSelectedVariantsStandardWorkflowStep,
} from '../../../../applications/contentInventory/content/features/ContentItemInventory/actions/thunkContentItemInventoryActions.ts';
import { useSelectedContentItemUsedIn } from '../../../../applications/contentInventory/content/hooks/useSelectedContentItemUsedIn.ts';
import { IPublishWarnings } from '../../../../applications/itemEditor/models/PublishWarnings.ts';
import {
  BrokenLinksInListing,
  BrokenModularsInListing,
  IFriendlyWarning,
} from '../../../../applications/itemEditor/utils/itemElementFriendlyWarningCheckers/types/FriendlyWarnings.ts';
import { modalDismissed, modalOpened } from '../../../actions/sharedActions.ts';
import { ModalDialogType } from '../../../constants/modalDialogType.ts';
import { useDispatch } from '../../../hooks/useDispatch.ts';
import { useSelector } from '../../../hooks/useSelector.ts';
import { DateTime } from '../../../models/DateTime.ts';
import {
  dueDateChanged,
  hideNotificationBarInChangeWorkflowStepModal,
  noteChanged,
} from '../actions/changeWorkflowStepModalActions.ts';
import { ChangeWorkflowStepModal as ChangeWorkflowStepModalComponent } from '../components/ChangeWorkflowStepModal.tsx';
import { getChangeWorkflowStepModalSubmitProps } from './selectors/getChangeWorkflowStepModalSubmitProps.ts';
import { getWorkflowStepModalTitle } from './selectors/getWorkflowStepModalTitle.ts';
import { isWorkflowStepModalSubmitDisabled } from './selectors/isWorkflowStepModalSubmitDisabled.ts';

const getFriendlyWarningReasonsForListing = memoize.maxOne(
  (publishWarnings: IPublishWarnings): ReadonlyArray<IFriendlyWarning> => {
    const result: Array<IFriendlyWarning> = [];
    if (!publishWarnings.itemsWithBrokenModulars.isEmpty()) {
      result.push(BrokenModularsInListing);
    }
    if (!publishWarnings.itemsWithBrokenLinks.isEmpty()) {
      result.push(BrokenLinksInListing);
    }

    return result;
  },
);

export const ChangeWorkflowStepListingModal: React.FC = () => {
  const publishWarnings = useSelector((s) => s.contentApp.listingUi.publishWarnings);
  const bulkActionCheckResult = useSelector((s) => s.contentApp.listingUi.bulkActionCheckResult);
  const activeUsers = Array<string>();
  const changeWorkflowStepModalData = useSelector((s) => s.contentApp.changeWorkflowStepModalData);
  const friendlyWarningReasons = getFriendlyWarningReasonsForListing(publishWarnings);
  const isSubmitDisabled = useSelector((s) => isWorkflowStepModalSubmitDisabled(s));
  const modalTitle = getWorkflowStepModalTitle(changeWorkflowStepModalData.workflowStepAction);
  const shouldRenderTasksSection = false;

  const dispatch = useDispatch();
  const onDueDateChange = (dateTime: DateTime) => dispatch(dueDateChanged(dateTime));
  const onNoteChanged = (newNote: string) => dispatch(noteChanged(newNote));
  const onPublish = () => dispatch(publishSelectedContentItems());
  const onScheduledPublish = () => dispatch(schedulePublishContentItems());
  const onUnpublish = () => dispatch(unpublishSelectedContentItems());
  const onScheduledUnpublish = () => dispatch(scheduleUnpublishContentItems());
  const onUpdate = () => dispatch(updateSelectedVariantsStandardWorkflowStep());
  const onRestoreFromArchivedStep = () => dispatch(restoreSelectedVariantsFromArchivedStep());
  const onMoveToArchivedStep = () =>
    dispatch(modalOpened(ModalDialogType.MoveItemsToArchivedStepConfirmationDialog));
  const onCloseModal = () => dispatch(modalDismissed());
  const onHideNotificationBar = () => dispatch(hideNotificationBarInChangeWorkflowStepModal());
  const { workflowId } = changeWorkflowStepModalData.workflowStep;
  const onSelectorValueChanged = (workflowStepId: Uuid) =>
    dispatch(selectWorkflowStepForListing(workflowId, workflowStepId));

  const { isLoading, contentItemUsages, selectedItemIds } = useSelectedContentItemUsedIn();

  const updateSubmitProps = getChangeWorkflowStepModalSubmitProps(
    changeWorkflowStepModalData,
    false,
    isSubmitDisabled,
    false,
    bulkActionCheckResult,
    {
      onMoveToArchivedStep,
      onUpdate,
      onScheduledPublish,
      onPublish,
      onScheduledUnpublish,
      onUnpublish,
      onRestoreFromArchivedStep,
    },
  );

  return (
    <ChangeWorkflowStepModalComponent
      activeUsersNames={activeUsers}
      changeWorkflowStepModalData={changeWorkflowStepModalData}
      friendlyWarningReasons={friendlyWarningReasons}
      modalTitle={modalTitle}
      shouldRenderTasksSection={shouldRenderTasksSection}
      onCloseModal={onCloseModal}
      onDueDateChange={onDueDateChange}
      onHideNotificationBar={onHideNotificationBar}
      onNoteChanged={onNoteChanged}
      onSelectorValueChanged={onSelectorValueChanged}
      updateSubmitProps={updateSubmitProps}
      itemIds={selectedItemIds}
      contentItemUsages={contentItemUsages}
      isLoading={isLoading}
    />
  );
};

ChangeWorkflowStepListingModal.displayName = 'ChangeWorkflowStepListingModal';
