import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import {
  AutoScrollId,
  autoScrollIdPropTypes,
} from '../../../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { useAutoScroll } from '../../../../../../_shared/hooks/useAutoScroll.ts';
import { isSelectionCollapsed } from '../../../../../../_shared/utils/selectionUtils.ts';
import { EditorLinkStatus } from '../../../apiLimitations/api/EditorLinkStatus.ts';
import { InlineContentWithDialog } from '../../components/dialog/InlineContentWithDialog.tsx';
import { ContentLinkDetail } from '../containers/ContentLinkDetail.tsx';

export interface IContentLinkOwnProps {
  readonly disabled?: boolean;
  readonly entityKey: string;
  readonly isViewOnly?: boolean;
  readonly itemId: Uuid;
  readonly onEdit?: () => void;
  readonly onUnlink?: () => void;
  readonly status?: EditorLinkStatus;
}

interface IContentLinkStateProps {
  readonly alternativeScrollIds?: ReadonlyArray<AutoScrollId>;
  readonly currentPath: string;
  readonly isBrokenLink: boolean;
  readonly isInvalidTypeLink: boolean;
  readonly scrollId?: AutoScrollId;
}

interface IContentLinkProps extends IContentLinkOwnProps, IContentLinkStateProps {
  readonly children: React.ReactNode;
}

const propTypes = {
  alternativeScrollIds: PropTypes.arrayOf(PropTypes.shape(autoScrollIdPropTypes).isRequired),
  children: PropTypes.node.isRequired,
  currentPath: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  entityKey: PropTypes.string.isRequired,
  isBrokenLink: PropTypes.bool.isRequired,
  isInvalidTypeLink: PropTypes.bool.isRequired,
  isViewOnly: PropTypes.bool,
  itemId: PropTypes.string.isRequired,
  onEdit: PropTypes.func,
  onUnlink: PropTypes.func,
  scrollId: PropTypes.shape(autoScrollIdPropTypes),
  status: PropTypes.oneOf(Object.values(EditorLinkStatus)),
};

export const ContentLink: React.FC<IContentLinkProps> = (props) => {
  const {
    children,
    alternativeScrollIds,
    currentPath,
    disabled,
    entityKey,
    isBrokenLink,
    isInvalidTypeLink,
    isViewOnly,
    itemId,
    onEdit,
    onUnlink,
    scrollId,
    status,
  } = props;

  const [isActive, setIsActive] = useState(false);
  const contentRef = useRef<HTMLAnchorElement | null>(null);

  useAutoScroll({ alternativeScrollIds, scrollTargetRef: contentRef, scrollId });

  const closeDetail = (): void => setIsActive(false);

  const handleClose = (): void => {
    if (isActive) {
      closeDetail();
    }
  };

  const edit = (): void => {
    if (onEdit) {
      closeDetail();
      onEdit();
    }
  };

  const unlink = (): void => {
    if (onUnlink) {
      closeDetail();
      onUnlink();
    }
  };

  const toggleActive = (e: any): void => {
    const selectionCollapsed = isSelectionCollapsed();
    setIsActive((prevState) => selectionCollapsed && !prevState);
    e.preventDefault();
  };

  return (
    <InlineContentWithDialog
      isInfo
      renderContent={(ref) => {
        return (
          <a
            className={classNames('rte__link', 'rte__link--is-clickable', {
              'rte__link--is-broken': isBrokenLink,
              'rte__link--type-is-not-allowed': isInvalidTypeLink,
            })}
            data-item-id={itemId}
            onClick={toggleActive}
            ref={(c) => {
              contentRef.current = c;
              ref(c);
            }}
          >
            {children}
          </a>
        );
      }}
      renderDialog={() => {
        return (
          isActive && (
            <ContentLinkDetail
              currentPath={currentPath}
              disabled={disabled}
              entityKey={entityKey}
              isViewOnly={isViewOnly}
              itemId={itemId}
              onEdit={onEdit ? edit : undefined}
              onUnlink={onUnlink ? unlink : undefined}
              scrollId={scrollId}
              status={status}
            />
          )
        );
      }}
      onClose={handleClose}
    />
  );
};

ContentLink.displayName = 'ContentLink';
ContentLink.propTypes = propTypes;
