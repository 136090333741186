import Immutable from 'immutable';
import ImmutablePropTypes from 'immutable-prop-types';
import PropTypes, { ValidationMap } from 'prop-types';
import { PropTypeOrNull } from '../../../../../_shared/utils/propTypesValidators.ts';
import { CustomCompiledTypeElementServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { TypeElementConfigurability } from '../../../../contentModels/shared/models/elements/types/elementConfigurabilityOptions.ts';
import { ElementType } from '../ContentItemElementType.ts';
import {
  BaseTypeElementValidationMap,
  EmptyBaseTypeElement,
  IBaseTypeElement,
  getBaseTypeElementFromServerModel,
} from './types/TypeElement.ts';

export interface ICustomTypeElement extends IBaseTypeElement {
  readonly sourceUrl: string;
  readonly config: string | null;
  readonly allowedElements: Immutable.List<Uuid>;
  readonly type: ElementType.Custom;
}

export const EmptyCustomTypeElement: ICustomTypeElement = {
  ...EmptyBaseTypeElement,
  _configurability: TypeElementConfigurability.Required,
  allowedElements: Immutable.List<Uuid>(),
  config: null,
  sourceUrl: '',
  type: ElementType.Custom,
};

export const CustomTypeElementPropTypes = PropTypes.exact<ValidationMap<ICustomTypeElement>>({
  ...BaseTypeElementValidationMap([ElementType.Custom]),
  sourceUrl: PropTypes.string.isRequired,
  config: PropTypeOrNull(PropTypes.string),
  allowedElements: ImmutablePropTypes.list.isRequired,
});

export const createCustomTypeElementDomainModel = (
  serverModel: CustomCompiledTypeElementServerModel,
): ICustomTypeElement => ({
  ...getBaseTypeElementFromServerModel(serverModel),
  _configurability: EmptyCustomTypeElement._configurability,
  allowedElements: Immutable.List<Uuid>(
    serverModel.allowedElements || EmptyCustomTypeElement.allowedElements,
  ),
  config: serverModel.config,
  sourceUrl: serverModel.sourceUrl || EmptyCustomTypeElement.sourceUrl,
  type: ElementType.Custom,
});
