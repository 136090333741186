import React from 'react';
import { Guidelines as GuidelinesElement } from '../../../../../../_shared/containers/Guidelines.tsx';
import {
  GuidelinesTypeElementPropTypesShape,
  IGuidelinesTypeElement,
} from '../../../../../contentInventory/content/models/contentTypeElements/GuidelinesTypeElement.ts';
import { ItemElement } from '../../../ContentItemEditing/containers/elements/ItemElement.tsx';

interface IGuidelinesProps {
  readonly typeElement: IGuidelinesTypeElement;
}

const propTypes: PropTypeMap<IGuidelinesProps> = {
  typeElement: GuidelinesTypeElementPropTypesShape.isRequired,
};

export const Guidelines: React.FC<IGuidelinesProps> = ({ typeElement }) => (
  <ItemElement disabled hideValidationStatus typeElement={typeElement} isDisplayOnly>
    <GuidelinesElement typeElement={typeElement} />
  </ItemElement>
);

Guidelines.displayName = 'Guidelines (Revisions)';
Guidelines.propTypes = propTypes;
