import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { isDisabled } from '../../../../../_shared/utils/contentItemUtils.ts';
import { TemporaryContentItemState } from '../../../../contentInventory/content/models/temporaryContentItemState.ts';
import { newlyCreatedItemEditorMounted } from '../actions/contentItemEditingActions.ts';
import {
  ContentItemEditor as ContentItemEditorComponent,
  IContentItemEditorDispatchProps,
  IContentItemEditorOwnProps,
  IContentItemEditorStateProps,
} from '../components/ContentItemEditor.tsx';

function mapStateToProps(state: IStore): IContentItemEditorStateProps {
  const {
    editedContentItem,
    editedContentItemVariant,
    editedContentItemVariantElements,
    loadedContentItemTypes,
    progressMessage,
    editorUi: { temporaryItem },
  } = state.contentApp;

  const editedContentItemType =
    (editedContentItem && loadedContentItemTypes.get(editedContentItem.editedContentItemTypeId)) ||
    null;
  const disabled = isDisabled(false, editedContentItemVariant);
  const allEditingDataLoaded = !!(
    editedContentItem &&
    editedContentItemVariant &&
    editedContentItemType &&
    editedContentItemVariantElements
  );
  const contentItemName = editedContentItem?.name ?? null;

  return {
    allEditingDataLoaded,
    contentItemName,
    disabled,
    isNewlyCreatedItem:
      !!temporaryItem && temporaryItem.itemState !== TemporaryContentItemState.Edited,
    progressMessage,
  };
}

const mapDispatchToProps = (dispatch: Dispatch): IContentItemEditorDispatchProps => ({
  onInit: (isNewlyCreatedItem) =>
    isNewlyCreatedItem ? dispatch(newlyCreatedItemEditorMounted()) : undefined,
});

export const ContentItemEditor: React.ComponentType<
  React.PropsWithChildren<IContentItemEditorOwnProps>
> = connect(mapStateToProps, mapDispatchToProps)(ContentItemEditorComponent);
