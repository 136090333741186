import React, { useMemo } from 'react';
import { StatusBarActions } from '../../../../../_shared/components/StatusBar/StatusBarActions.tsx';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { isAssetTypeEnabled } from '../../../../../_shared/selectors/enhancedAssetManagement.ts';
import { compose } from '../../../../../_shared/utils/func/compose.ts';
import { getCurrentProjectPlan } from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { saveEditedAssetType } from '../../actions/thunkAssetTypeActions.ts';

export const AssetTypeEditorToolbarActions: React.FC = () => {
  const isBeingSaved = useSelector((s) => s.contentModelsApp.typeEditor.typeIsBeingSaved);
  const isLoading = useSelector(
    (s) => s.contentModelsApp.typeEditor.loadingStatus !== LoadingStatus.Loaded,
  );
  const isVisible = useSelector((state) =>
    isAssetTypeEnabled(getCurrentProjectPlan(state), state.data.assetTypes.defaultAssetType),
  );

  const dispatch = useDispatch();
  const onSave = useMemo(() => compose(dispatch, saveEditedAssetType), []);

  if (!isVisible) {
    return null;
  }

  return <StatusBarActions isBeingSaved={isBeingSaved} isLoading={isLoading} onSave={onSave} />;
};

AssetTypeEditorToolbarActions.displayName = 'AssetTypeEditorToolbarActions';
