import { ScrollElementsToViewOptions } from '@kontent-ai/DOM';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useRef } from 'react';
import { ScrollExecutedCallback } from '../../../../../_shared/hooks/useAutoScroll.ts';
import {
  IRichTextTypeElement,
  RichTextTypeElementPropTypes,
} from '../../../../contentInventory/content/models/contentTypeElements/RichTextTypeElement.ts';
import { IFocusable } from '../../../../richText/plugins/behavior/FocusPlugin.tsx';
import { EditorChangeCallback } from '../../../../richText/plugins/behavior/OnChangePlugin.tsx';
import {
  IRichTextItemElement,
  RichTextItemElementPropTypesShape,
} from '../../../models/contentItemElements/RichTextItemElement.ts';
import { deferredScrollToRteTarget } from '../../../utils/customScrollTargetUtils.ts';
import { ContentItemRichTextInput } from '../../ContentItemEditing/containers/elements/richString/ContentItemRichTextInput.tsx';
import { RichTextItemElementContext } from '../../ContentItemEditing/context/RichTextItemElementContext.tsx';
import { INestableContentComponentItemElementOwnProps } from '../IContentComponentItemElementOwnProps.type.ts';
import { ContentComponentItemElement } from '../containers/ContentComponentItemElement.tsx';
import { useItemVariantId } from '../context/ContentItemContext.tsx';

export interface IContentComponentRichStringElementOwnProps
  extends INestableContentComponentItemElementOwnProps<
    IRichTextItemElement,
    IRichTextTypeElement
  > {}

export interface IContentComponentRichStringElementCallbackProps {
  onRedirectToContentItem?: (contentItemId: Uuid) => void;
}

type ContentComponentRichStringElementInputProps = IContentComponentRichStringElementOwnProps &
  IContentComponentRichStringElementCallbackProps;

const propTypes: PropTypesShape<ContentComponentRichStringElementInputProps> = {
  // data props
  autoFocus: PropTypes.bool.isRequired,
  contentComponentId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  elementData: RichTextItemElementPropTypesShape.isRequired,
  typeElement: RichTextTypeElementPropTypes.isRequired,
  validationResultSelectorId: PropTypes.string.isRequired,

  // callback props
  onRedirectToContentItem: PropTypes.func,
  onUpdate: PropTypes.func.isRequired,
};

export const ContentComponentRichStringElement: React.FC<
  ContentComponentRichStringElementInputProps
> = ({
  autoFocus,
  contentComponentId,
  disabled,
  elementData,
  onUpdate,
  typeElement,
  validationResultSelectorId,
}) => {
  const contentComponentItemElementRef = useRef<HTMLDivElement>(null);
  const contentItemRichTextInputRef = useRef<IFocusable>(null);
  const contentItemIdentifier = useItemVariantId();
  const { rootRichTextElementId } = useContext(RichTextItemElementContext);

  const onContentChange: EditorChangeCallback = (newEditorState) => {
    onUpdate({
      ...elementData,
      _editorState: newEditorState,
    });
    return Promise.resolve();
  };

  const focusContentComponentItemElement = useCallback(() => {
    contentComponentItemElementRef.current?.focus();
  }, []);

  const focusContentItemRichTextInput = useCallback(() => {
    contentItemRichTextInputRef.current?.focus();
  }, []);

  const focusContentItemRichTextInputAtTheStart = useCallback(() => {
    contentItemRichTextInputRef.current?.focusAtTheStart();
  }, []);

  const onCustomScrollToTarget = useCallback(
    (
      targetId: string,
      scrollOptions: ScrollElementsToViewOptions,
      onScrollExecuted: ScrollExecutedCallback,
    ) => {
      return deferredScrollToRteTarget(
        targetId,
        contentItemIdentifier,
        elementData,
        scrollOptions,
        rootRichTextElementId ? rootRichTextElementId : undefined,
        contentComponentId,
        onScrollExecuted,
      );
    },
    [contentComponentId, contentItemIdentifier, elementData, rootRichTextElementId],
  );

  return (
    <ContentComponentItemElement
      contentComponentId={contentComponentId}
      defaultTabIndex
      disabled={disabled}
      escapeHandledByEditor
      focusEditor={focusContentItemRichTextInput}
      onContentClick={focusContentItemRichTextInput}
      onCustomScrollToTarget={onCustomScrollToTarget}
      onHeaderClick={focusContentItemRichTextInputAtTheStart}
      ref={contentComponentItemElementRef}
      typeElement={typeElement}
      validationResultSelectorId={validationResultSelectorId}
    >
      <ContentItemRichTextInput
        autoFocus={autoFocus}
        className="rte--in-content-item-element"
        disabled={disabled}
        editedEntityName={`${typeElement.name} (Rich text element)`}
        editorState={elementData._editorState}
        onContentChange={onContentChange}
        onEscape={focusContentComponentItemElement}
        ref={contentItemRichTextInputRef}
        typeElement={typeElement}
      />
    </ContentComponentItemElement>
  );
};

ContentComponentRichStringElement.displayName = 'ContentComponentRichStringElement';
ContentComponentRichStringElement.propTypes = propTypes;
