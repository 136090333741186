import { Button } from '@kontent-ai/component-library/Button';
import { IconName as ComponentLibraryIconName, Icons } from '@kontent-ai/component-library/Icons';
import { Collection } from '@kontent-ai/utils';
import React, { MouseEvent, RefObject } from 'react';
import { IconName } from '../../constants/iconEnumGenerated.ts';
import { SquareButton } from '../../uiComponents/Button/SquareButton.tsx';
import { ButtonStyle as OldButtonStyle } from '../../uiComponents/Button/buttonStyle.ts';
import { createDropDown } from '../../uiComponents/DropDown/DropDown.tsx';
import { DropDownOption } from '../../uiComponents/DropDown/DropDownOption.tsx';
import { DropDownOptionName } from '../../uiComponents/DropDown/DropDownOptionName.tsx';
import {
  IDropdownTippyOptions,
  defaultDropdownTippyOptions,
} from '../../uiComponents/DropDown/dropDownTippyOptions.ts';
import {
  DataUiAction,
  DataUiCollection,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

export type DataTableAction = {
  readonly dataUiAction: DataUiAction;
  readonly iconName?: ComponentLibraryIconName;
  readonly isDestructive: boolean;
  readonly isDisabled: boolean;
  readonly onClick: (e: MouseEvent<any>) => void;
  readonly text: string;
  readonly tooltipMessage?: string;
};

const DropDown = createDropDown<DataTableAction>();

const tippyOptions: IDropdownTippyOptions = {
  ...defaultDropdownTippyOptions,
  offset: [0, 5],
};

interface IActionsProps {
  readonly actions: ReadonlyArray<DataTableAction>;
}

export const DataTableActions: React.FC<IActionsProps> = ({ actions }) => {
  if (actions.length === 1) {
    const action = Collection.getFirst(actions);

    if (!action) {
      return null;
    }

    return (
      <Button
        tooltipText={action.tooltipMessage}
        tooltipPlacement="left"
        buttonStyle="secondary"
        destructive={action.isDestructive}
        disabled={action.isDisabled}
        onClick={action.onClick}
        {...getDataUiActionAttribute(action.dataUiAction)}
      >
        {action.iconName && !action.isDestructive && <Button.Icon icon={Icons[action.iconName]} />}
        {action.text}
      </Button>
    );
  }

  return (
    <DropDown
      optionListDataUiAttributes={getDataUiCollectionAttribute(
        DataUiCollection.MoreActionsDropdown,
      )}
      options={actions}
      renderSelectedOption={(ref: RefObject<HTMLDivElement>, toggleMoreActions, isOpen) => (
        <SquareButton
          ref={ref}
          style={OldButtonStyle.Primary}
          onClick={toggleMoreActions}
          iconName={IconName.Ellipsis}
          iconClass="u-rotate-90"
          screenReaderText="More actions"
          tooltipText={isOpen ? undefined : 'More actions'}
          tooltipPlacement="left"
          {...getDataUiActionAttribute(DataUiAction.MoreActions)}
        />
      )}
      renderOption={(hideMenu, option: DataTableAction) => (
        <DropDownOption
          key={option.text}
          onClick={(e) => {
            hideMenu();
            option.onClick(e);
          }}
          isDestructive={option.isDestructive}
          isDisabled={option.isDisabled}
          tooltipText={option.tooltipMessage}
          dataUiAttributes={getDataUiActionAttribute(option.dataUiAction)}
        >
          <DropDownOptionName text={option.text} />
        </DropDownOption>
      )}
      tippyOptions={tippyOptions}
    />
  );
};

DataTableActions.displayName = 'DataTableActions';
