import { checkboxGroupSpacingBetweenOptions } from '@kontent-ai/component-library/CheckboxGroup';
import { Stack } from '@kontent-ai/component-library/Stack';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { ReactNode } from 'react';
import { IconName } from '../../constants/iconEnumGenerated.ts';
import { Icon } from '../../uiComponents/Icon/Icon.tsx';
import { TreeList } from './TreeList.tsx';

interface ITreeNodeDataProps {
  readonly collapsed: boolean;
  readonly disabled?: boolean;
  readonly disabledExpand?: boolean;
  readonly nodeItem: ReactNode;
}

interface ITreeNodeCallbackProps {
  readonly onToggle?: () => void;
}

type TreeNodeProps = ITreeNodeDataProps & ITreeNodeCallbackProps;

const propTypes: PropTypesShape<TreeNodeProps> = {
  // Data props
  collapsed: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  disabledExpand: PropTypes.bool,
  nodeItem: PropTypes.node,

  // Callback props
  onToggle: PropTypes.func,
};

const TreeNode: React.FC<React.PropsWithChildren<TreeNodeProps>> = ({
  collapsed,
  disabled,
  onToggle,
  disabledExpand,
  nodeItem,
  children,
}) => (
  <Stack
    component="li"
    className="tree__node"
    data-hj-suppress=""
    spacing={checkboxGroupSpacingBetweenOptions}
  >
    <div
      className={classNames('tree__item', {
        'tree__item--is-collapsed': collapsed,
        'tree__item--is-expanded': !collapsed,
        'tree__item--is-disabled': disabled,
      })}
    >
      {onToggle && (
        <div className="tree__expand-area" onClick={onToggle}>
          <Icon
            iconName={IconName.ChevronDown}
            className={classNames('tree__expand-action', {
              'tree__expand-action--is-collapsed': collapsed,
              'tree__expand-action--is-expanded': !collapsed,
              'tree__expand-action--is-disabled': disabledExpand,
            })}
          />
        </div>
      )}
      {nodeItem}
    </div>

    {React.Children.count(children) > 0 && <TreeList collapsed={collapsed}>{children}</TreeList>}
  </Stack>
);

TreeNode.displayName = 'TreeNode';
TreeNode.propTypes = propTypes;

export { TreeNode };
