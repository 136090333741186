import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Spacing, px } from '@kontent-ai/component-library/tokens';
import React from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

type Props = Readonly<{
  onClick: () => void;
  tooltipText: string;
}>;

export const ItemSettings: React.FC<Props> = ({ onClick, tooltipText }) => (
  <QuinaryButton
    onClick={(event) => {
      event.stopPropagation();
      onClick();
    }}
    tooltipText={tooltipText}
    tooltipPlacement="right"
    buttonStyle="default"
    css={`margin-left: ${px(Spacing.S)}`}
    {...getDataUiActionAttribute(DataUiAction.GoToSettings)}
  >
    <QuinaryButton.Icon icon={Icons.Cogwheel} />
  </QuinaryButton>
);

ItemSettings.displayName = 'ItemSettings';
