import { ScrollElementsToViewOptions } from '@kontent-ai/DOM';
import PropTypes from 'prop-types';
import { AutoScrollId, autoScrollIdPropTypes } from './AutoScrollId.ts';

export type AutoScrollComponentProps = {
  readonly alternativeScrollIds?: ReadonlyArray<AutoScrollId>;
  readonly scrollId?: AutoScrollId;
  readonly scrollOptions?: ScrollElementsToViewOptions;
};

const autoScrollOptionsPropTypes: PropTypesShape<ScrollElementsToViewOptions> = {
  alignment: PropTypes.string,
  behavior: PropTypes.string,
  bottomOffset: PropTypes.number,
  offset: PropTypes.number,
  topOffset: PropTypes.number,
};

export const autoScrollPropTypes: PropTypesShape<AutoScrollComponentProps> = {
  alternativeScrollIds: PropTypes.arrayOf(PropTypes.shape(autoScrollIdPropTypes).isRequired),
  scrollId: PropTypes.shape(autoScrollIdPropTypes),
  scrollOptions: PropTypes.shape(autoScrollOptionsPropTypes),
};
