import React, { ReactNode } from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';
import { UnsavedChangesDialog } from './UnsavedChangesDialog.tsx';

export type HandleUnsavedFormOnNavigationProps = {
  readonly disabledTooltip?: string | undefined;
  readonly hasUnsavedChanges: boolean;
  readonly isBeingSaved: boolean;
  readonly onSaveChanges: (onSuccess: () => void, onFail: () => void) => void;
  readonly onDiscard: () => void;
  readonly renderAdditionalContent?: () => ReactNode;
};

export const HandleUnsavedFormOnNavigation: React.FC<HandleUnsavedFormOnNavigationProps> = ({
  hasUnsavedChanges,
  disabledTooltip,
  isBeingSaved,
  onSaveChanges,
  onDiscard,
  renderAdditionalContent,
}) => {
  return (
    <NavigationPrompt when={hasUnsavedChanges} renderIfNotActive>
      {({ isActive, onCancel, onConfirm }) => (
        <UnsavedChangesDialog
          disabledTooltip={disabledTooltip}
          isVisible={isActive}
          isBeingSaved={isBeingSaved}
          onClose={onCancel}
          onDiscard={() => {
            onDiscard();
            onConfirm();
          }}
          onSaveChanges={() => onSaveChanges(onConfirm, onCancel)}
          renderAdditionalContent={renderAdditionalContent}
        />
      )}
    </NavigationPrompt>
  );
};

HandleUnsavedFormOnNavigation.displayName = 'HandleUnsavedFormOnNavigation';
