import styled, { css } from 'styled-components';
import { Box } from '../../../layout/Box/Box.tsx';
import { borderWidthHighEmphasis } from '../../../tokens/decision/border.ts';
import { colorAccent, colorBackgroundHover } from '../../../tokens/decision/colors.ts';
import { widthFocus } from '../../../tokens/decision/focus.ts';
import { spacingNavigationBarToggle } from '../../../tokens/decision/spacing.ts';
import { BorderRadius } from '../../../tokens/quarks/border.ts';
import { FontWeight } from '../../../tokens/quarks/fontWeight.ts';
import { shadowFocusStyles } from '../../../tokens/quarks/shadow.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { navigationBarWidthPx } from '../tokens.ts';

type Props = {
  readonly $isActive: boolean;
  readonly $isFocusVisible: boolean;
};

export const StyledNavigationItem = styled(Box).attrs({
  display: 'block',
  width: navigationBarWidthPx,
  padding: spacingNavigationBarToggle,
})<Props>`
  user-select: none;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    inset: 0;
    // offset so that focus ring is fully visible
    left: ${px(widthFocus)};
    border-radius: ${px(BorderRadius.S)};
  }

  ${({ $isFocusVisible }) =>
    $isFocusVisible &&
    css`
      &:after {
        ${shadowFocusStyles};
      }
    `};

  &:hover {
    background-color: ${colorBackgroundHover};
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
    font-weight: ${FontWeight.Medium};
    box-shadow: inset -${px(borderWidthHighEmphasis)} 0 0 0 ${colorAccent};
  `}
`;
