import { InvariantException } from '@kontent-ai/errors';
import Immutable from 'immutable';
import { Dispatch, GetState, ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { onboardingNotificationTriggered } from '../../../../../../_shared/actions/thunkSharedActions.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { DefaultVariantId } from '../../../../../../_shared/constants/variantIdValues.ts';
import {
  CannotPublishNonLocalizableElementsAndViewDefaultVariantError,
  PublishErrorMessage,
} from '../../../../../../_shared/features/ChangeWorkflowStepModal/constants/uiConstants.ts';
import { ContentItemId } from '../../../../../../_shared/models/ContentItemId.type.ts';
import { TrackUserEventWithDataAction } from '../../../../../../_shared/models/TrackUserEvent.type.ts';
import {
  ContentItemEditingEventOrigins,
  ContentItemEditingEventTypes,
} from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { logError } from '../../../../../../_shared/utils/logError.ts';
import { hasCapabilityInLanguage } from '../../../../../../_shared/utils/permissions/capabilitiesInLanguageUtils.ts';
import { Capability } from '../../../../../../_shared/utils/permissions/capability.ts';
import { OnboardingNotification } from '../../../../../../data/models/user/OnboardingNotification.ts';
import { IContentItemWithVariantServerModel } from '../../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import {
  isElementsCompletenessError,
  isNonLocalizableElementPublishError,
} from '../../utils/errorUtils.ts';
import { IParsedItemVariant } from '../../utils/parseContentItem.ts';
import { prepareWorkflowStepTrackingData } from '../../utils/prepareWorkflowStepTrackingData.ts';
import {
  contentItemEditingSidebarSectionCleanedUp,
  publishContentItemVariantFailed,
  publishContentItemVariantFinished,
  publishingContentItemVariantStarted,
} from '../contentItemEditingActions.ts';
import { ILoadRelatedContentItemElementsDataAction } from './loadRelatedContentItemElementsData.ts';

interface IPublishContentItemVariantDependencies {
  readonly trackUserEvent: TrackUserEventWithDataAction;
  readonly contentItemRepository: {
    readonly getItemWithVariant: (
      contentItemId: Uuid,
      variantId: Uuid,
    ) => Promise<IContentItemWithVariantServerModel>;
    readonly publishVariant: (
      contentItemId: Uuid,
      variantId: Uuid,
    ) => Promise<IContentItemWithVariantServerModel>;
  };
  readonly parseContentItemVariant: (
    contentItemWithVariant: IContentItemWithVariantServerModel,
  ) => IParsedItemVariant;
  readonly loadRelatedContentItemElementsData: ILoadRelatedContentItemElementsDataAction;
  readonly loadContentItemUsage: (itemId: Uuid, variantId: Uuid) => ThunkPromise;
}

export const publishContentItemVariantActionCreator =
  (deps: IPublishContentItemVariantDependencies) =>
  (
    { itemId, variantId }: ContentItemId,
    actionOrigin: ContentItemEditingEventOrigins,
  ): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {
      contentApp: {
        listingUi: { filter, orderBy },
      },
      data: {
        listingContentItems: { usedSearchMethod },
      },
      sharedApp: { modalDialog },
    } = getState();

    dispatch(publishingContentItemVariantStarted());
    try {
      await deps.contentItemRepository.publishVariant(itemId, variantId);
      const itemWithVariant = await deps.contentItemRepository.getItemWithVariant(
        itemId,
        variantId,
      );
      const variantData = deps.parseContentItemVariant(itemWithVariant);
      const loadContentItemUsagePromise = dispatch(deps.loadContentItemUsage(itemId, variantId));
      await dispatch(
        deps.loadRelatedContentItemElementsData(
          itemId,
          variantId,
          variantData.editedContentItemVariantElements,
          null,
        ),
      );
      const compiledVariant = {
        ...variantData,
        editedContentItemVariantElements: variantData.editedContentItemVariantElements,
      };
      dispatch(
        onboardingNotificationTriggered(OnboardingNotification.ContentPublishedNotification, {
          publishedItemIds: Immutable.List([itemId]),
        }),
      );

      await loadContentItemUsagePromise;
      dispatch(
        publishContentItemVariantFinished({
          itemWithVariant,
          itemVariantData: compiledVariant,
          filter,
          orderBy,
          usedSearchMethod,
        }),
      );

      dispatch(
        deps.trackUserEvent(
          TrackedEvent.ContentItemEditing,
          prepareWorkflowStepTrackingData(compiledVariant, {
            action: ContentItemEditingEventTypes.Publish,
            origin: actionOrigin,
          }),
        ),
      );

      dispatch(contentItemEditingSidebarSectionCleanedUp());
    } catch (error) {
      const isNonLocalizableElementsError = isNonLocalizableElementPublishError(error);

      if (!isElementsCompletenessError(error) && !isNonLocalizableElementsError) {
        logError(`${__filename}: Error during publishing of an item variant`, error);
      }

      if (!modalDialog.properties) {
        throw InvariantException(
          `${__filename}: No modal dialog properties when trying to open dialog after failed publish.`,
        );
      }

      const errorMessage =
        isNonLocalizableElementsError &&
        !hasCapabilityInLanguage(getState(), Capability.ViewContent, DefaultVariantId)
          ? CannotPublishNonLocalizableElementsAndViewDefaultVariantError
          : PublishErrorMessage;

      dispatch(publishContentItemVariantFailed(errorMessage, modalDialog.properties));
    }
  };
