import { ContentState } from 'draft-js';
import React, { useState } from 'react';
import { trackUserEvent } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { getContentStateActionResult } from '../../../../../_shared/features/AI/helpers/transformAiResult.ts';
import { useAiTask } from '../../../../../_shared/features/AI/hooks/aiTasks/useAiTask.ts';
import {
  AiTaskProgress,
  useAiTaskProgress,
} from '../../../../../_shared/features/AI/hooks/aiTasks/useAiTaskProgress.ts';
import { useOnFinishedAiActionTask } from '../../../../../_shared/features/AI/hooks/aiTasks/useOnFinishedAiActionTask.ts';
import { useAiSessionId } from '../../../../../_shared/features/AI/hooks/useAiSessionId.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { ILanguage } from '../../../../../data/models/languages/Language.ts';
import { AiActionName } from '../../../../../repositories/serverModels/ai/AiActionName.type.ts';
import { createAssetOperationTrackingData } from '../../../../../repositories/serverModels/ai/AiServerModels.params.ts';
import { createTranslateContentRequest } from '../../../../../repositories/serverModels/ai/actions/AiServerModels.translateContent.ts';
import { ITextTypeElement } from '../../../content/models/contentTypeElements/TextTypeElement.ts';
import { AssetDescription as AssetDescriptionComponent } from '../../components/AssetEditing/Description/AssetDescription.tsx';
import {
  aiIsWorkingOnDescription,
  getTranslateDescriptionWithAiTooltip,
} from '../../constants/aiConstants.ts';
import { matchDescribeImageAiTask } from '../../utils/matchDescribeImageAiTask.tsx';
import { AssetDescriptionAiLoader } from './Description/DescribeWithAi/AssetDescriptionAiLoader.tsx';
import { TranslateDescriptionFromLanguageButton } from './Description/DescribeWithAi/TranslateDescriptionFromLanguageButton.tsx';
import { TranslateWithAiError } from './Description/DescribeWithAi/TranslateWithAiError.tsx';

interface IProps {
  readonly assetId: Uuid;
  readonly copyFromOtherLanguageButton: JSX.Element;
  readonly defaultDescription: string;
  readonly defaultLanguage: ILanguage;
  readonly descriptionLabel: string;
  readonly isEditable: boolean;
  readonly language: ILanguage;
  readonly languageDescription: string;
  readonly onChangeDescription: (variantId: Uuid, newDescription: string) => void;
  readonly placeholder: string;
  readonly typeElement: ITextTypeElement;
}

export const AssetDescriptionWithAiTranslateAction: React.FC<IProps> = ({
  assetId,
  copyFromOtherLanguageButton,
  defaultDescription,
  defaultLanguage,
  descriptionLabel,
  isEditable,
  language,
  languageDescription,
  onChangeDescription,
  placeholder,
  typeElement,
}) => {
  const dispatch = useDispatch();
  const { aiSessionId, resetAiSessionId } = useAiSessionId();
  const [isDescriptionBeingTranslated, setIsDescriptionBeingTranslated] = useState(false);
  const [isErrorDismissed, setIsErrorDismissed] = useState(false);
  const { run, result, isRunning } = useAiTask(
    AiActionName.TranslateContent,
    getContentStateActionResult,
  );
  const describeImageAiTask = useAiTaskProgress(
    getContentStateActionResult,
    matchDescribeImageAiTask(assetId),
  );
  const assetTrackingData = createAssetOperationTrackingData(assetId, aiSessionId);

  useOnFinishedAiActionTask(result.isFinished, () => {
    const translatedDescription = result.content?.getPlainText();

    if (translatedDescription) {
      onChangeDescription(language.id, translatedDescription);
    }

    setIsDescriptionBeingTranslated(false);
  });

  const onTranslateDescription = () => {
    run(
      createTranslateContentRequest(
        ContentState.createFromText(defaultDescription),
        defaultLanguage.name,
        defaultLanguage.codename,
        language.name,
        language.codename,
        assetTrackingData,
      ),
      { assetId, sourceLanguageId: defaultLanguage.id, targetLanguageId: language.id },
    );
    dispatch(trackUserEvent(TrackedEvent.AiImageDescriptionTranslate));
    setIsDescriptionBeingTranslated(true);
    setIsErrorDismissed(false);
    resetAiSessionId();
  };

  const isAiWorkingOnDescription =
    isDescriptionBeingTranslated || describeImageAiTask.progress === AiTaskProgress.Running;

  const buttons = (
    <>
      {copyFromOtherLanguageButton}
      <TranslateDescriptionFromLanguageButton
        isDisabled={isAiWorkingOnDescription}
        tooltipText={
          isAiWorkingOnDescription
            ? aiIsWorkingOnDescription
            : getTranslateDescriptionWithAiTooltip(defaultLanguage.name, language.name)
        }
        onClick={onTranslateDescription}
      />
    </>
  );

  const changeDescription = (variantId: Uuid, newDescription: string) => {
    onChangeDescription(variantId, newDescription);

    if (!isErrorDismissed) {
      setIsErrorDismissed(true);
    }
  };

  return (
    <AssetDescriptionComponent
      buttons={buttons}
      descriptionLabel={descriptionLabel}
      errorNotificationBar={
        result.error && !isErrorDismissed ? (
          <TranslateWithAiError
            dismissError={() => setIsErrorDismissed(true)}
            errorCode={result.error.code}
            targetLanguageName={language.name}
          />
        ) : undefined
      }
      isEditable={isEditable}
      language={language}
      languageDescription={languageDescription}
      loader={
        isRunning ? (
          <AssetDescriptionAiLoader
            message={`Translating from ${defaultLanguage.name} to ${language.name}...`}
          />
        ) : undefined
      }
      onChangeDescription={changeDescription}
      placeholder={placeholder}
      typeElement={typeElement}
    />
  );
};
