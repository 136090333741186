import { Box } from '@kontent-ai/component-library/Box';
import { InputState } from '@kontent-ai/component-library/Input';
import { ISelectItem } from '@kontent-ai/component-library/Selects';
import { PortalContainerContextProvider } from '@kontent-ai/component-library/context';
import React, { useCallback, useMemo, useRef } from 'react';
import { ScrollContainerContextProvider } from '../../../../../../../../component-library/components/ScrollContainer/ScrollContainerContext.tsx';
import { FormGroup } from '../../../../../../../_shared/components/input/FormGroup.tsx';
import { ValidatedDateTimePicker } from '../../../../../../../_shared/components/input/ValidatedDateTimePicker.tsx';
import { ValidatedSingleSelect } from '../../../../../../../_shared/components/input/ValidatedSingleSelect.tsx';
import { ValidatedTextArea } from '../../../../../../../_shared/components/input/ValidatedTextArea.tsx';
import { HookFormProps } from '../../../../../../../_shared/types/hookFormProps.ts';
import {
  DataUiCollection,
  ItemEditingForms,
  getDataUiCollectionAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  formatCurrentUserName,
  formatUserName,
  makeCurrentUserFirst,
  userLastNameComparer,
} from '../../../../../../../_shared/utils/usersUtils.ts';
import { IProjectContributor } from '../../../../../../../data/models/users/ProjectContributor.ts';
import { DatetimePickerPlaceholder } from '../../../../../../projects/constants/UIConstants.ts';
import {
  TaskPopoverFriendlyWarningMaxWidth,
  UnableToSeeTaskErrorMessage,
} from '../../../constants/uiConstants.ts';
import { ITaskFormShape } from '../../../models/ITaskFormShape.type.ts';
import { FormPane } from '../FormPane.tsx';
import { UnsavedChangesWarning } from '../UnsavedChangesWarning.tsx';

export type TaskFormSharedProps = {
  readonly allActiveContributors: ReadonlyArray<IProjectContributor>;
  readonly isTaskSubmitting: boolean;
  readonly isTaskArchiving?: boolean;
  readonly renderExtraButton?: () => React.ReactNode;
};

export type TaskFormProps = {
  readonly submitButtonName: string;
  readonly submitButtonText: string;
};

type Props = TaskFormSharedProps &
  TaskFormProps & {
    readonly assigneesWithoutAccess: ReadonlyArray<IProjectContributor>;
    readonly currentUserId: Uuid;
    readonly formProps: HookFormProps<ITaskFormShape>;
    readonly isWarningDisplayed: boolean;
    readonly onReset: () => void;
    readonly onSubmit: () => void;
  };

type UserOption = IProjectContributor & ISelectItem<UserOption>;

const getUserSelectorStatus = (
  formDisabledState: boolean,
  assigneesWithoutAccess: ReadonlyArray<IProjectContributor>,
): InputState => {
  if (formDisabledState) {
    return InputState.Disabled;
  }
  if (assigneesWithoutAccess.length > 0) {
    return InputState.Alert;
  }

  return InputState.Default;
};

export const TaskForm: React.FC<Props> = (props) => {
  const { allActiveContributors, currentUserId } = props;
  const portalContainerRef = useRef<HTMLFormElement>(null);

  const mapProjectContributorToUserOption = useCallback(
    (contributor: IProjectContributor): UserOption => {
      return {
        ...contributor,
        label: formatCurrentUserName(currentUserId)(contributor),
        id: contributor.userId,
      };
    },
    [currentUserId],
  );

  const options = useMemo(
    () =>
      makeCurrentUserFirst(
        [...allActiveContributors].sort(userLastNameComparer),
        currentUserId,
      ).map(mapProjectContributorToUserOption),
    [allActiveContributors, currentUserId, mapProjectContributorToUserOption],
  );

  const formStateDisabled = props.isTaskSubmitting || (props.isTaskArchiving ?? false);

  return (
    <PortalContainerContextProvider portalContainerRef={portalContainerRef}>
      <ScrollContainerContextProvider tippyBoundaryRef={null}>
        <FormPane
          dataUiAttribute={getDataUiObjectNameAttribute(ItemEditingForms.Tasks)}
          disableCancelButton={formStateDisabled}
          disableSubmitButton={formStateDisabled}
          onReset={props.onReset}
          onSubmit={props.onSubmit}
          submitButtonText={props.submitButtonText}
          submitButtonTooltipText={props.submitButtonName}
          ref={portalContainerRef}
          renderExtraButton={props.renderExtraButton}
        >
          <FormGroup>
            <ValidatedTextArea<ITaskFormShape>
              autoFocus
              formProps={props.formProps}
              isDisabled={formStateDisabled}
              label="Task description"
              name="taskDescription"
              placeholder="Type what needs to be done..."
              rows={3}
            />
          </FormGroup>
          <FormGroup>
            <Box maxWidth={TaskPopoverFriendlyWarningMaxWidth}>
              <ValidatedSingleSelect<ITaskFormShape, UserOption>
                verticalMenuDataAttributes={getDataUiCollectionAttribute(
                  DataUiCollection.Contributors,
                )}
                formProps={props.formProps}
                inputState={getUserSelectorStatus(formStateDisabled, props.assigneesWithoutAccess)}
                label="Assign to"
                name="assignedContributorId"
                items={options}
                placeholder="Type a name or select..."
                caption={
                  props.assigneesWithoutAccess.length > 0
                    ? UnableToSeeTaskErrorMessage(props.assigneesWithoutAccess.map(formatUserName))
                    : undefined
                }
              />
            </Box>
          </FormGroup>
          <FormGroup>
            <ValidatedDateTimePicker<ITaskFormShape>
              defaultDate=""
              defaultTime="16:00"
              disabled={formStateDisabled}
              formProps={props.formProps}
              isFullWidth
              label="Due date"
              name="taskDueDate"
              placeholder={DatetimePickerPlaceholder}
            />
          </FormGroup>
          {props.isWarningDisplayed && (
            <Box maxWidth={TaskPopoverFriendlyWarningMaxWidth}>
              <UnsavedChangesWarning
                submitButtonName={props.submitButtonName}
                subjectOfChange="task"
              />
            </Box>
          )}
        </FormPane>
      </ScrollContainerContextProvider>
    </PortalContainerContextProvider>
  );
};

TaskForm.displayName = 'TaskForm';
