import { InvariantException } from '@kontent-ai/errors';
import { History } from 'history';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import {
  ApiKeyDetailRouteParams,
  DapiKeyDetailRoute,
  MapiKeyDetailRoute,
  PersonalMapiKeyDetailRoute,
} from '../../../../../_shared/constants/routePaths.ts';
import { ApiKeyType } from '../../../../../_shared/models/ApiKeyType.ts';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IApiKeysRepository } from '../../../../../repositories/interfaces/IApiKeysRepository.type.ts';
import {
  ApiKeyDetail_Creating_Failed,
  ApiKeyDetail_Creating_Finished,
  ApiKeyDetail_Creating_Started,
} from '../../constants/apiKeysActionTypes.ts';
import {
  ApiKey,
  convertToCreateServerModel,
  createApiKeyFromServerModel,
} from '../../models/ApiKey.ts';

const started = () =>
  ({
    type: ApiKeyDetail_Creating_Started,
  }) as const;

const failed = () =>
  ({
    type: ApiKeyDetail_Creating_Failed,
  }) as const;

const success = (apiKey: ApiKey) =>
  ({
    type: ApiKeyDetail_Creating_Finished,
    payload: { apiKey },
  }) as const;

interface ICreateApiKeyDependencies {
  readonly apiKeysRepository: Pick<IApiKeysRepository, 'createApiKey'>;
}

export type CreateApiKeyActionsType = ReturnType<typeof started | typeof failed | typeof success>;

export const createApiKeyCreator =
  ({ apiKeysRepository }: ICreateApiKeyDependencies) =>
  (apiKey: ApiKey, history?: History, projectContainerId?: Uuid): ThunkPromise =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(started());

      const apiKeyServerModel = convertToCreateServerModel(apiKey);

      const serverModel = await apiKeysRepository.createApiKey(apiKeyServerModel);

      dispatch(success(createApiKeyFromServerModel(serverModel)));

      if (history && projectContainerId) {
        const apiKeyDetailRoute = getRedirectRoute(apiKey.type);

        history.push(
          buildPath<ApiKeyDetailRouteParams>(apiKeyDetailRoute, {
            projectContainerId,
            tokenSeedId: serverModel.token_seed_id,
          }),
        );
      }
    } catch (error) {
      dispatch(failed());
      throw InvariantException(
        `Could not update api key because of the following error: ${JSON.stringify(error)}`,
      );
    }
  };

const getRedirectRoute = (apiKeyType: ApiKeyType): string => {
  switch (apiKeyType) {
    case ApiKeyType.MAPIPat:
      return PersonalMapiKeyDetailRoute;
    case ApiKeyType.MAPI:
      return MapiKeyDetailRoute;
    case ApiKeyType.DAPI:
      return DapiKeyDetailRoute;
    default:
      throw InvariantException(`Unsupported API key type ${apiKeyType}.`);
  }
};
