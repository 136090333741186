import React from 'react';
import {
  DataUiCollection,
  DataUiElement,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { preventDefault } from '../../../../../../_shared/utils/func/functionalTools.ts';

export const AddToolbarWrapper: React.FC<React.PropsWithChildren<NoProps>> = ({ children }) => (
  <div
    className="toolbar__add"
    // Prevent editor focus lost when toolbar button is clicked.
    onMouseDown={preventDefault}
    onMouseUp={preventDefault}
    {...getDataUiElementAttribute(DataUiElement.RteAddToolbar)}
  >
    <div
      className="rte__insert-toolbar"
      {...getDataUiCollectionAttribute(DataUiCollection.BlockOptions)}
    >
      {children}
    </div>
  </div>
);

AddToolbarWrapper.displayName = 'AddToolbarWrapper';
