import React from 'react';
import { NotificationBarAlert } from '../../../../../component-library/components/NotificationBar/NotificationBar.tsx';
import { IFriendlyWarning } from '../../../../applications/itemEditor/utils/itemElementFriendlyWarningCheckers/types/FriendlyWarnings.ts';
import { ContentItemUsages } from '../../../../data/reducers/listingContentItems/IListingContentItems.type.ts';
import { Loader, LoaderType } from '../../../components/Loader.tsx';
import { ModalDialog } from '../../../components/ModalDialog/ModalDialog.tsx';
import { ModalDialogTwoColumnBodyLayout } from '../../../components/ModalDialog/ModalDialogTwoColumnBodyLayout.tsx';
import { DateTime } from '../../../models/DateTime.ts';
import { Button } from '../../../uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../../uiComponents/Button/buttonStyle.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { WorkflowStatusSelectorSection } from '../containers/WorkflowStatusSelectorSection.tsx';
import { IChangeWorkflowStepModalData } from '../reducers/IChangeWorkflowStepModalData.ts';
import { ChangeAssignmentSection } from './ChangeAssignmentSection.tsx';
import {
  WorkflowSubmitButton,
  WorkflowSubmitButtonProps,
} from './Elements/WorkflowSubmitButton.tsx';

type ChangeWorkflowStepModalProps = {
  readonly activeUsersNames: ReadonlyArray<string>;
  readonly changeWorkflowStepModalData: IChangeWorkflowStepModalData;
  readonly contentItemUsages: ContentItemUsages;
  readonly friendlyWarningReasons: ReadonlyArray<IFriendlyWarning>;
  readonly hasPendingUpdates?: boolean;
  readonly isLoading?: boolean;
  readonly itemIds: ReadonlySet<Uuid>;
  readonly modalTitle: string;
  readonly onCloseModal: () => void;
  readonly onDueDateChange: (dateTime: DateTime) => void;
  readonly onHideNotificationBar: () => void;
  readonly onNoteChanged: (newNote: string) => void;
  readonly onSelectorValueChanged: (optionId: Uuid) => void;
  readonly shouldRenderTasksSection: boolean;
  readonly updateSubmitProps: WorkflowSubmitButtonProps;
};

// TODO KCL-3573: provide actual implementation
const TasksSection: React.FC = () => <div />;

const LeftColumnElement: React.FC<ChangeWorkflowStepModalProps> = (props) =>
  props.isLoading ? (
    <Loader type={LoaderType.Sidebar} />
  ) : (
    <ChangeAssignmentSection
      activeUsersNames={props.activeUsersNames}
      changeWorkflowStepModalData={props.changeWorkflowStepModalData}
      friendlyWarningReasons={props.friendlyWarningReasons}
      onDueDateChange={props.onDueDateChange}
      onNoteChanged={props.onNoteChanged}
      itemIds={props.itemIds}
      contentItemUsages={props.contentItemUsages}
    />
  );

const ModalBody: React.FC<ChangeWorkflowStepModalProps> = (props) => (
  <ModalDialogTwoColumnBodyLayout
    leftColumnElement={<LeftColumnElement {...props} />}
    rightColumnElement={props.shouldRenderTasksSection && <TasksSection />}
    topElement={
      <WorkflowStatusSelectorSection onSelectorValueChanged={props.onSelectorValueChanged} />
    }
    notificationBar={
      props.changeWorkflowStepModalData.savingError && (
        <NotificationBarAlert onDismiss={props.onHideNotificationBar}>
          {props.changeWorkflowStepModalData.savingError}
        </NotificationBarAlert>
      )
    }
  />
);

const ModalFooter: React.FC<ChangeWorkflowStepModalProps> = (props) => (
  <>
    <Button
      style={ButtonStyle.Secondary}
      onClick={props.onCloseModal}
      {...getDataUiActionAttribute(DataUiAction.CloseDialog)}
    >
      Cancel
    </Button>
    <WorkflowSubmitButton
      {...props.updateSubmitProps}
      isLoading={props.isLoading || props.hasPendingUpdates}
    />
  </>
);

export const ChangeWorkflowStepModal: React.FC<ChangeWorkflowStepModalProps> = (props) => (
  <ModalDialog
    bodyContent={<ModalBody {...props} />}
    disableCloseOnOverlayClick
    footerContentRight={<ModalFooter {...props} />}
    headerContent={props.modalTitle}
    onClose={props.onCloseModal}
    shouldHaveFixedTop
    withFullWidthBodyContent
  />
);

ChangeWorkflowStepModal.displayName = 'ChangeWorkflowStepModal';
