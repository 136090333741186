import { Box } from '@kontent-ai/component-library/Box';
import { Spacing } from '@kontent-ai/component-library/tokens';
import PropTypes from 'prop-types';
import React from 'react';

type Props = {
  readonly children: React.ReactNode;
};

const propTypes: PropTypeMap<Props> = {
  children: PropTypes.node.isRequired,
};

export const EmptyStateFooter: React.FC<Props> = ({ children }) => {
  return (
    <Box display="flex" alignItems="center" gap={Spacing.L} textAlign="center">
      {children}
    </Box>
  );
};

EmptyStateFooter.displayName = 'EmptyStateFooter';
EmptyStateFooter.propTypes = propTypes;
