import React from 'react';
import { ContributorSelector } from '../../../../../applications/itemEditor/features/ContentItemEditing/components/ContributorSelector.tsx';
import { IProjectContributor } from '../../../../../data/models/users/ProjectContributor.ts';
import { IUserInfo } from '../../../../models/UserInfo.ts';
import { ModalSection } from '../ModalSection.tsx';
import { ContributorsSectionDescription } from './ContributorsSectionDescription.tsx';

export interface IContributorsSectionOwnProps {
  readonly autoFocus?: boolean;
}

export interface IContributorsSectionStateProps {
  readonly assignedContributors: ReadonlyArray<UserId>;
  readonly availableContributors: ReadonlyArray<IProjectContributor>;
  readonly currentUserId: UserId;
  readonly errorMessage?: string;
  readonly showAllContributors: boolean;
  readonly workflowStepName: string;
}

export interface IContributorsSectionDispatchProps {
  readonly onContributorListChanged: (contributors: ReadonlyArray<IUserInfo>) => void;
  readonly onShowAllContributors: () => void;
  readonly onShowAvailableContributors: () => void;
}

type ContributorsSectionProps = IContributorsSectionOwnProps &
  IContributorsSectionStateProps &
  IContributorsSectionDispatchProps;

export const ContributorsSection: React.FC<ContributorsSectionProps> = (props) => (
  <ModalSection title="Contributors" key="contributorsSection">
    <ContributorSelector
      assignedContributors={props.assignedContributors}
      autoFocus={props.autoFocus}
      availableContributors={props.availableContributors}
      currentUserId={props.currentUserId}
      onContributorListChanged={props.onContributorListChanged}
      validationMessage={props.errorMessage}
    />
    <ContributorsSectionDescription
      showAllContributors={props.showAllContributors}
      workflowStepName={props.workflowStepName}
      onShowAllContributors={props.onShowAllContributors}
      onShowAvailableContributors={props.onShowAvailableContributors}
    />
  </ModalSection>
);

ContributorsSection.displayName = 'ContributorsSection';
