import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import { MenuItemWithMouseHover } from '@kontent-ai/component-library/MenuItem';
import { Paper } from '@kontent-ai/component-library/Paper';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Spacing, colorTextDefault } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { DropDownMenu } from '../../../../../../component-library/components/DropDownMenu/DropDownMenu.tsx';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { updateAssetListingOrdering } from '../../actions/thunkAssetLibraryActions.ts';
import { AssetsOrderBy } from '../../types/orderBy.type.ts';
import { getColumnName, orderingOptions } from '../../utils/assetListingOrderingUtils.ts';

type AssetListingOrderingProps = {
  readonly onOrderingChange: (orderingData: AssetsOrderBy) => void;
  readonly orderBy: AssetsOrderBy;
};

export const AssetListingOrdering: React.FC<AssetListingOrderingProps> = ({
  onOrderingChange,
  orderBy,
}) => {
  const dispatch = useDispatch();

  const hasPotentiallyUnsortedAssets = useSelector((s) =>
    s.assetLibraryApp.potentiallyUnsortedBy.has(orderBy.columnCode),
  );

  return (
    <Row alignY="center" spacing={Spacing.XS}>
      <Column>
        <Label color={colorTextDefault} size={LabelSize.L}>
          Sort by:
        </Label>
      </Column>

      <Column>
        <DropDownMenu
          renderTrigger={(triggerProps) => (
            <QuinaryButton {...triggerProps} tooltipText="">
              {hasPotentiallyUnsortedAssets ? 'Unsorted' : getColumnName(orderBy)}
            </QuinaryButton>
          )}
          renderDropDown={(hideMenu) => (
            <Paper paddingY={Spacing.S}>
              {orderingOptions.map((option, i) => (
                <MenuItemWithMouseHover
                  key={i}
                  menuItemState="default"
                  text={getColumnName(option)}
                  onPress={() => {
                    dispatch(updateAssetListingOrdering(option));
                    onOrderingChange(option);
                    hideMenu();
                  }}
                />
              ))}
            </Paper>
          )}
        />
      </Column>
    </Row>
  );
};

AssetListingOrdering.displayName = 'AssetListingOrdering';
