import React from 'react';
import { BlockType } from '../../../utils/blocks/blockType.ts';
import { EditorFeatureLimitations } from '../../apiLimitations/api/EditorFeatureLimitations.ts';
import { RichTextInputCommand } from '../../keyboardShortcuts/api/EditorCommand.ts';
import { ButtonInputProps, getToolbarButtonProps } from '../../toolbars/utils/toolbarUtils.ts';
import { Heading1Button } from './buttons/Heading1Button.tsx';
import { Heading2Button } from './buttons/Heading2Button.tsx';
import { Heading3Button } from './buttons/Heading3Button.tsx';
import { Heading4Button } from './buttons/Heading4Button.tsx';
import { Heading5Button } from './buttons/Heading5Button.tsx';
import { Heading6Button } from './buttons/Heading6Button.tsx';
import { OrderedListButton } from './buttons/OrderedListButton.tsx';
import { ParagraphButton } from './buttons/ParagraphButton.tsx';
import { UnorderedListButton } from './buttons/UnorderedListButton.tsx';

export type BlockTypeToolbarButtonsProps = {
  readonly fullBlockTypesAtSelection: ReadonlySet<BlockType>;
  readonly className?: string;
  readonly hidesDisallowedFeatures: boolean;
  readonly limitations: EditorFeatureLimitations;
  readonly onCommand: (command: RichTextInputCommand) => void;
};

export const BlockTypeToolbarButtons: React.FC<BlockTypeToolbarButtonsProps> = ({
  fullBlockTypesAtSelection,
  className,
  hidesDisallowedFeatures,
  limitations,
  onCommand,
}) => {
  const buttonProps: ButtonInputProps = {
    fullBlockTypesAtSelection,
    currentVisualStyle: null,
    hidesDisallowedFeatures,
    limitations,
    metadataAtSelection: null,
    onCommand,
    selectionContainsText: null,
    tooltipPlacement: 'top-start',
  };

  const paragraphProps = getToolbarButtonProps(RichTextInputCommand.Unstyled, buttonProps);
  const headingOneProps = getToolbarButtonProps(RichTextInputCommand.HeadingOne, buttonProps);
  const headingTwoProps = getToolbarButtonProps(RichTextInputCommand.HeadingTwo, buttonProps);
  const headingThreeProps = getToolbarButtonProps(RichTextInputCommand.HeadingThree, buttonProps);
  const headingFourProps = getToolbarButtonProps(RichTextInputCommand.HeadingFour, buttonProps);
  const headingFiveProps = getToolbarButtonProps(RichTextInputCommand.HeadingFive, buttonProps);
  const headingSixProps = getToolbarButtonProps(RichTextInputCommand.HeadingSix, buttonProps);
  const orderedListProps = getToolbarButtonProps(RichTextInputCommand.OrderedList, buttonProps);
  const unorderedListProps = getToolbarButtonProps(RichTextInputCommand.UnorderedList, buttonProps);

  return (
    <div className={className}>
      {paragraphProps && <ParagraphButton {...paragraphProps} />}
      {headingOneProps && <Heading1Button {...headingOneProps} />}
      {headingTwoProps && <Heading2Button {...headingTwoProps} />}
      {headingThreeProps && <Heading3Button {...headingThreeProps} />}
      {headingFourProps && <Heading4Button {...headingFourProps} />}
      {headingFiveProps && <Heading5Button {...headingFiveProps} />}
      {headingSixProps && <Heading6Button {...headingSixProps} />}
      {orderedListProps && <OrderedListButton {...orderedListProps} />}
      {unorderedListProps && <UnorderedListButton {...unorderedListProps} />}
    </div>
  );
};
BlockTypeToolbarButtons.displayName = 'BlockTypeToolbarButtons';
