import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { ContentItemId } from '../../../../../../_shared/models/ContentItemId.type.ts';
import { IRichTextItemElement } from '../../../../models/contentItemElements/RichTextItemElement.ts';
import { isRichTextElement } from '../../../../models/contentItemElements/compiledItemElementTypeGuards.ts';
import { getElementById } from '../../../../stores/utils/contentItemElementsUtils.ts';
import { areIdsEquivalent } from '../../utils/itemEditingUtils.ts';
import { IRichStringElementValueChangedAction } from './richStringElementValueChanged.ts';

export type IChangeRichStringElementValueAction = (
  contentItemId: ContentItemId,
  elementId: Uuid,
  modifier: IRichTextItemElementModifier,
) => ThunkFunction;

export interface IChangeRichStringElementValueActionDependencies {
  readonly richStringElementValueChanged: IRichStringElementValueChangedAction;
}

export type IRichTextItemElementModifier = (
  elementData: IRichTextItemElement,
) => IRichTextItemElement;

export const changeRichStringElementValueActionCreator =
  (deps: IChangeRichStringElementValueActionDependencies): IChangeRichStringElementValueAction =>
  (contentItemId, elementId, modifier) =>
  (dispatch, getState) => {
    const state = getState();
    const {
      contentApp: { editedContentItemVariant, editedContentItemVariantElements },
    } = state;

    if (
      !editedContentItemVariant ||
      !areIdsEquivalent(contentItemId, editedContentItemVariant.id)
    ) {
      return;
    }

    const elementData = getElementById(elementId, editedContentItemVariantElements);
    if (!isRichTextElement(elementData)) {
      return;
    }

    const newElementData = modifier(elementData);
    dispatch(deps.richStringElementValueChanged(contentItemId, elementId, newElementData));
  };
