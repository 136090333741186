import React, { useCallback } from 'react';
import { HandleUnsavedFormOnNavigation } from '../../../../../_shared/containers/HandleUnsavedFormOnNavigation.tsx';

interface IHandleUnsavedApiKeyFormOnNavigationProps {
  readonly isDirty: boolean;
  readonly onSaveChanges: () => Promise<boolean>;
}

export const HandleUnsavedApiKeyFormOnNavigation: React.FC<
  IHandleUnsavedApiKeyFormOnNavigationProps
> = ({ isDirty, onSaveChanges }) => {
  const unsavedNavigationHandler = useCallback(
    async (onSuccess: () => void, onFail: () => void): Promise<void> => {
      try {
        const isKeyFormValid = await onSaveChanges();

        if (isKeyFormValid) {
          onSuccess();
        } else {
          onFail();
        }
      } catch {
        onFail();
      }
    },
    [onSaveChanges],
  );

  return (
    <HandleUnsavedFormOnNavigation
      hasUnsavedChanges={isDirty}
      isBeingSaved={false}
      onSaveChanges={unsavedNavigationHandler}
    />
  );
};
