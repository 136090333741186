import { useFocusRing } from '@react-aria/focus';
import { useHover, usePress } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import { forwardRef, useEffect, useState } from 'react';
import { ShortcutsConfig, useHotkeys } from '../../hooks/useHotkeys.tsx';
import { IconSize } from '../../tokens/quarks/iconSize.ts';
import { getDataUiComponentAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { Icons } from '../Icons/components/icons.ts';
import { Tooltip } from '../Tooltip/Tooltip.tsx';
import { TooltipPropsExtension } from '../_utils/propPrefabs.ts';
import { HintAppearanceType } from './HintStyle.ts';
import { StyledHint } from './StyledHint.ts';
import { StyledHintWrapper } from './StyledHintWrapper.tsx';

interface IHintProps extends Pick<TooltipPropsExtension, 'tooltipPlacement'> {
  readonly tooltipText: string;
  readonly ariaLabel?: string;
  readonly hintStyle?: HintAppearanceType;
}

export const Hint = forwardRef<HTMLButtonElement, IHintProps>(
  ({ tooltipText, tooltipPlacement = 'top', ariaLabel, hintStyle = 'default', ...props }, ref) => {
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const { isFocusVisible, focusProps } = useFocusRing();

    useEffect(() => {
      setIsTooltipVisible(isFocusVisible);
    }, [isFocusVisible]);

    const { hoverProps, isHovered } = useHover({
      isDisabled: isFocusVisible,
      onHoverChange: () => {
        setIsTooltipVisible((prev) => !prev);
      },
    });

    const { pressProps } = usePress({
      isDisabled: isHovered,
      onPress: (event) => {
        if (event.pointerType === 'touch' || event.pointerType === 'keyboard') {
          setIsTooltipVisible((prev) => !prev);
        }
      },
    });

    const shouldShowTooltip = isTooltipVisible;

    const hotkeyProps = useHotkeys(
      {
        [ShortcutsConfig.Escape]: (event) => {
          if (isTooltipVisible) {
            setIsTooltipVisible(false);
            event.preventDefault();
          }
        },
      },
      { isDisabled: !isTooltipVisible, ref },
    );

    return (
      <Tooltip placement={tooltipPlacement} tooltipText={tooltipText} visible={shouldShowTooltip}>
        <StyledHintWrapper>
          <StyledHint
            aria-label={ariaLabel || tooltipText}
            isFocusVisible={isFocusVisible}
            hintStyle={hintStyle}
            {...mergeProps(focusProps, hoverProps, pressProps, props, hotkeyProps)}
            {...getDataUiComponentAttribute(Hint)}
          >
            <Icons.QuestionCircle size={IconSize.S} />
          </StyledHint>
        </StyledHintWrapper>
      </Tooltip>
    );
  },
);

Hint.displayName = 'Hint';
