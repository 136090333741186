import { memoize } from '@kontent-ai/memoization';
import {
  ContentBlock,
  ContentState,
  DraftDecoratorComponentProps,
  DraftEditorLeafs,
  EntityInstance,
  Modifier,
  SelectionState,
} from 'draft-js';
import {
  createSelection,
  getSelectionForEntity,
  isSelectionWithinOneBlock,
  preserveSelectionOverContentChanges,
  setContentSelection,
} from '../../../utils/editorSelectionUtils.ts';
import { EntityMap, getBlocks, getEntityMap } from '../../../utils/general/editorContentGetters.ts';
import {
  IContentChangeInput,
  IContentChangeResult,
} from '../../../utils/general/editorContentUtils.ts';
import { getLinkTypeFromData } from '../../links/api/LinkEntity.ts';
import { LinkType } from '../../links/api/LinkType.ts';
import { NewLinkType } from '../../links/api/NewLinkType.ts';
import { updateText } from '../../textApi/api/editorTextUtils.ts';
import { EntityData, EntityType, getEntityType } from './Entity.ts';

type BaseEntityDecoratorProps = Omit<DraftDecoratorComponentProps, 'children'> & {
  readonly entityKey: string;
  readonly children: DraftEditorLeafs;
};

export type EntityDecoratorProps<
  TCustomProps extends ReadonlyRecord<string, any> = ReadonlyRecord<string, never>,
> = TCustomProps extends ReadonlyRecord<string, never>
  ? BaseEntityDecoratorProps
  : BaseEntityDecoratorProps & TCustomProps;

// Map entity data to element attributes.
const EntityAttributesMap = {
  [EntityType.Link]: {
    url: 'href',
    openInNewWindow: 'data-new-window',
    title: 'title',
    itemId: 'data-item-id',
    assetId: 'data-asset-id',
    emailAddress: 'data-email-address',
    emailSubject: 'data-email-subject',
    phoneNumber: 'data-phone-number',
  },
} as const;

const isEntityAttributesType = (input: EntityType): input is keyof typeof EntityAttributesMap =>
  (Object.keys(EntityAttributesMap) as ReadonlyArray<EntityType>).includes(input);

const TypeConverterMap = {
  [EntityType.Link]: {
    openInNewWindow: convertToBoolean,
  },
} as const;

const isTypeConverterKey = (input: EntityType): input is keyof typeof TypeConverterMap =>
  (Object.keys(TypeConverterMap) as ReadonlyArray<EntityType>).includes(input);

const EntityConsistencyFilters: {
  readonly [key in EntityType]: (data: AnyObject) => AnyObject | null;
} = {
  [EntityType.Link]: (data) => {
    const linkType = getLinkTypeFromData(data);
    if (!linkType) {
      return null;
    }

    switch (linkType) {
      case LinkType.Content:
        return { itemId: data.itemId };
      case LinkType.Asset:
        return { assetId: data.assetId };
      case LinkType.Web: {
        const result: Record<string, any> = {
          url: data.url,
        };
        if (Object.hasOwn(data, 'openInNewWindow')) {
          result.openInNewWindow = data.openInNewWindow;
        }
        if (Object.hasOwn(data, 'title')) {
          result.title = data.title;
        }
        return result;
      }
      case LinkType.Email: {
        const result: Record<string, any> = {
          emailAddress: data.emailAddress,
        };
        if (Object.hasOwn(data, 'emailSubject')) {
          result.emailSubject = data.emailSubject;
        }
        return result;
      }
      case LinkType.Phone:
        return { phoneNumber: data.phoneNumber };
      case NewLinkType.ContentLink:
      case NewLinkType.AssetLink:
      case NewLinkType.WebLink:
      case NewLinkType.EmailLink:
      case NewLinkType.PhoneLink:
        return {};
      default:
        throw new Error(`Entity consistency filter for ${linkType} does not exist.`);
    }
  },
  [EntityType.Mention]: (data: AnyObject) => data,
  [EntityType.AiInstruction]: (data: AnyObject) => data,
};

export type IGetAttributesFromEntityData = (entity: EntityInstance) => AnyObject | null;

export function convertToBoolean(input: string): boolean {
  if (input === 'true' || input === 'false') {
    return input === 'true';
  }

  return false;
}

function convert(dataKey: string, entityType: EntityType, attrValue: string): any {
  if (isTypeConverterKey(entityType) && Object.hasOwn(TypeConverterMap[entityType], dataKey)) {
    return (TypeConverterMap as any)[entityType][dataKey](attrValue);
  }

  return attrValue;
}

export function getAttributesFromEntityData(
  entity: EntityInstance,
): ReadonlyRecord<string, string> | null {
  const entityType = getEntityType(entity);
  const map = isEntityAttributesType(entityType) ? EntityAttributesMap[entityType] : {};
  const data = getConsistentEntityData(entityType, entity.getData());
  if (!data) {
    return null;
  }

  const attrs: Record<string, string> = {};

  let found = false;
  for (const dataKey of Object.keys(map)) {
    const dataValue = data[dataKey];
    if (dataValue) {
      if (Object.hasOwn(map, dataKey)) {
        const attrKey = map[dataKey];
        attrs[attrKey] = `${dataValue}`;
        found = true;
      }
    }
  }

  if (found) {
    return attrs;
  }
  return null;
}

export function getEntityDataFromAttributes(
  node: HTMLElement,
  entityType: EntityType,
): EntityData | null {
  const map = isEntityAttributesType(entityType) ? EntityAttributesMap[entityType] : {};
  const data = {};

  let found = false;
  for (const dataKey of Object.keys(map)) {
    if (Object.hasOwn(map, dataKey)) {
      const attrKey = map[dataKey];
      const attrValue = node.getAttribute(attrKey);
      if (attrValue) {
        (data as any)[dataKey] = convert(dataKey, entityType, attrValue);
        found = true;
      }
    }
  }

  if (found) {
    return getConsistentEntityData(entityType, data);
  }
  return null;
}

export function removeEntities(input: IContentChangeInput): IContentChangeResult {
  const newContent = Modifier.applyEntity(input.content, input.selection, null);

  return {
    content: newContent,
    selection: newContent.getSelectionAfter(),
    wasModified: true,
  };
}

export type EntityPredicate = (entity: EntityInstance, entityKey: string) => boolean;

export function findEntities(
  block: ContentBlock,
  entityMap: EntityMap,
  predicate: EntityPredicate,
  callback: (start: number, end: number, entity: EntityInstance) => void,
) {
  block.findEntityRanges(
    (ch) => !!ch.getEntity(),
    (start, end) => {
      const entityKey = block.getEntityAt(start);
      if (entityKey) {
        const entity = entityMap.__get(entityKey);
        if (predicate(entity, entityKey)) {
          callback(start, end, entity);
        }
      }
    },
  );
}

const blockContainsMatchingEntities = memoize.weak(
  (block: ContentBlock, entityMap: EntityMap, predicate: EntityPredicate) => {
    let found = false;
    findEntities(block, entityMap, predicate, () => {
      found = true;
    });
    return found;
  },
);

export function containsMatchingEntities(
  content: ContentState,
  predicate: EntityPredicate,
): boolean {
  const entityMap = getEntityMap(content);

  return getBlocks(content).some((block) =>
    blockContainsMatchingEntities(block, entityMap, predicate),
  );
}

export type GetReplacementText = (originalText: string, entity: EntityInstance) => string;

export function removeMatchingEntities(
  input: IContentChangeInput,
  predicate: EntityPredicate,
  getReplacementText?: GetReplacementText,
): IContentChangeResult {
  const { content, selection } = input;

  const entityMap = getEntityMap(content);

  let updatedContent = content;
  let updatedSelection = selection;

  const blocks = getBlocks(content);
  blocks.map((block) => {
    findEntities(block, entityMap, predicate, (start, end, entity) => {
      const blockKey = block.getKey();
      const entitySelection = createSelection(blockKey, start, blockKey, end);
      const removeInput = {
        content: updatedContent,
        selection: entitySelection,
      };

      const withRemovedEntity = removeEntities(removeInput);
      updatedContent = withRemovedEntity.content;

      if (!getReplacementText) {
        return;
      }

      const text = block.getText().substring(start, end);
      const newText = getReplacementText(text, entity);
      if (newText !== text) {
        const withUpdatedText = updateText(withRemovedEntity, newText);
        updatedContent = withUpdatedText.content;
        updatedSelection = preserveSelectionOverContentChanges(
          updatedSelection,
          withRemovedEntity.selection,
          withUpdatedText.selection,
        );
      }
    });
  });

  const newContentWithSelection = setContentSelection(
    updatedContent,
    input.selection,
    updatedSelection,
  );

  return {
    content: newContentWithSelection,
    selection: updatedSelection,
    wasModified: updatedContent !== content,
  };
}

function isOffsetInsideEntity(block: ContentBlock, offset: number): boolean {
  const entityAtOffset = block.getEntityAt(offset);
  return !!entityAtOffset && entityAtOffset === block.getEntityAt(offset + 1);
}

// Splits the entity if the selection is inside the entity
// Entity is removed from the selection
// Entity before selection is kept
// Entity after selection is recreated as new entity
export function splitEntity(input: IContentChangeInput): IContentChangeResult {
  const { content, selection } = input;

  if (!isSelectionWithinOneBlock(selection)) {
    return input;
  }

  const block = content.getBlockForKey(selection.getFocusKey());
  if (!block) {
    return input;
  }

  if (
    !isOffsetInsideEntity(block, selection.getStartOffset()) ||
    !isOffsetInsideEntity(block, selection.getEndOffset())
  ) {
    return input;
  }

  const entityKey = block.getEntityAt(selection.getStartOffset());
  if (!entityKey) {
    return input;
  }

  const entity = content.getEntity(entityKey);
  if (!entity) {
    return input;
  }

  // Remove link over the selection
  const withRemovedMiddle = removeEntities(input);

  const entitySelection = getSelectionForEntity(content, entityKey);
  if (entitySelection) {
    const afterEntitySelection = createSelection(
      selection.getEndKey(),
      selection.getEndOffset(),
      entitySelection.getEndKey(),
      entitySelection.getEndOffset(),
    );

    // Replicate link entity and assign to link part after selection
    const contentWithRemovedMiddle = withRemovedMiddle.content;
    const contentWithNewEntity = contentWithRemovedMiddle.createEntity(
      getEntityType(entity),
      entity.getMutability(),
      entity.getData(),
    );
    const newEntityKey = contentWithNewEntity.getLastCreatedEntityKey();
    const newContent = Modifier.applyEntity(
      contentWithNewEntity,
      afterEntitySelection,
      newEntityKey,
    );
    const newContentWithSelection = setContentSelection(newContent, selection, selection);

    return {
      wasModified: true,
      content: newContentWithSelection,
      selection,
    };
  }

  return input;
}

export function isAtEntity(
  content: ContentState,
  blockKey: string,
  offset: number,
  predicate: EntityPredicate,
): boolean {
  if (offset < 0) {
    return false;
  }

  const block = content.getBlockForKey(blockKey);
  if (!block) {
    return false;
  }

  if (offset >= block.getLength()) {
    return false;
  }

  const entityKey = block.getEntityAt(offset);
  if (!entityKey) {
    return false;
  }

  const entity = content.getEntity(entityKey);
  if (!entity) {
    return false;
  }

  return predicate(entity, entityKey);
}

function getConsistentEntityData(entityType: EntityType, data: AnyObject): EntityData | null {
  return (
    EntityConsistencyFilters[entityType] ? EntityConsistencyFilters[entityType](data) : data
  ) as EntityData;
}

export function isSelectionEdgeWithinEntity(
  content: ContentState,
  selection: SelectionState,
  predicate: EntityPredicate,
): boolean {
  if (
    isAtEntity(content, selection.getStartKey(), selection.getStartOffset(), predicate) ||
    isAtEntity(content, selection.getStartKey(), selection.getStartOffset() - 1, predicate)
  ) {
    return true;
  }

  return (
    !selection.isCollapsed() &&
    (isAtEntity(content, selection.getEndKey(), selection.getEndOffset(), predicate) ||
      isAtEntity(content, selection.getEndKey(), selection.getEndOffset() - 1, predicate))
  );
}
