import Immutable from 'immutable';
import { ValidationConstants } from '../../../_shared/constants/validationConstants.ts';
import { isUuid } from '../../../_shared/utils/validation/typeValidators.ts';
import { ICompiledContentType } from '../../contentInventory/content/models/CompiledContentType.ts';
import {
  EditableElementType,
  ElementType,
} from '../../contentInventory/content/models/ContentItemElementType.ts';
import { EditableTypeElement } from '../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { getCustomElementErrors } from './elementErrorCheckers/customElementErrorChecker.ts';
import { getDateTimeElementErrors } from './elementErrorCheckers/dateTimeElementErrorChecker.ts';
import { getLinkedItemsElementErrors } from './elementErrorCheckers/linkedItemsElementErrorChecker.ts';
import { getNumberElementErrors } from './elementErrorCheckers/numberElementErrorChecker.ts';
import { createRichTextElementErrorChecker } from './elementErrorCheckers/richTextElementErrorChecker.ts';
import { createTaxonomyElementErrorChecker } from './elementErrorCheckers/taxonomyElementErrorChecker.ts';
import { createTextElementErrorChecker } from './elementErrorCheckers/textElementErrorChecker.ts';
import {
  ElementErrorResult,
  emptyElementErrorResult,
} from './elementErrorCheckers/types/Errors.ts';

const getTextContentElementErrors = createTextElementErrorChecker(ValidationConstants);
const getRichTextContentElementErrors = createRichTextElementErrorChecker(ValidationConstants);
const getTaxonomyElementErrors = createTaxonomyElementErrorChecker(isUuid);

export interface IGetElementErrorsCheckerParams<
  TElementType extends EditableTypeElement = EditableTypeElement,
> {
  readonly value: unknown;
  readonly typeElement: TElementType;
  readonly loadedContentTypes: Immutable.Map<Uuid, ICompiledContentType>;
}

export type IGetElementErrors<
  TElementType extends EditableTypeElement = EditableTypeElement,
  TParams extends Partial<
    IGetElementErrorsCheckerParams<TElementType>
  > = IGetElementErrorsCheckerParams<TElementType>,
> = (params: TParams) => ElementErrorResult;

type ErrorCheckersMap = {
  [key in EditableElementType]: IGetElementErrors<any, any>;
};

const elementErrorCheckersMap: ErrorCheckersMap = {
  [ElementType.Asset]: () => emptyElementErrorResult,
  [ElementType.Custom]: getCustomElementErrors,
  [ElementType.DateTime]: getDateTimeElementErrors,
  [ElementType.LinkedItems]: getLinkedItemsElementErrors,
  [ElementType.MultipleChoice]: () => emptyElementErrorResult,
  [ElementType.Number]: getNumberElementErrors,
  [ElementType.RichText]: getRichTextContentElementErrors,
  [ElementType.Subpages]: getLinkedItemsElementErrors,
  [ElementType.Taxonomy]: getTaxonomyElementErrors,
  [ElementType.Text]: getTextContentElementErrors,
  [ElementType.UrlSlug]: getTextContentElementErrors,
};

export function getElementErrors(params: IGetElementErrorsCheckerParams): ElementErrorResult {
  const getErrors = elementErrorCheckersMap[params.typeElement.type];
  return getErrors(params);
}
