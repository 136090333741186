import { Hint } from '@kontent-ai/component-library/Hint';
import PropTypes from 'prop-types';
import React from 'react';
import { TextFilter } from '../../../../_shared/components/TextFilter.tsx';
import { ValidationConstants } from '../../../../_shared/constants/validationConstants.ts';
import { FullTextSearchStatus } from '../../shared/reducers/IContentInventoryStoreState.type.ts';
import { DisabledSearchHelpMessage, ExactMatchHelpMessage } from '../constants/fullTextSearch.ts';
import { MaxFullTextSearchPhraseLength } from '../constants/uiConstants.ts';
import { validateFullTextSearchPhrase } from '../utils/validateFullTextSearchPhrase.ts';
import { ContentItemsSearchSort } from './ContentItemsSearchSort.tsx';

type Props = {
  readonly autofocus?: boolean;
  readonly fullTextSearchStatus: FullTextSearchStatus;
  readonly onSortReset: () => void;
  readonly onUpdateSearchPhrase: (text: string) => void;
  readonly orderByColumnIsCustom: boolean;
  readonly searchPhrase: string;
};

const propTypes: PropTypesShape<Props> = {
  autofocus: PropTypes.bool,
  fullTextSearchStatus: PropTypes.oneOf(Object.values(FullTextSearchStatus)).isRequired,
  onSortReset: PropTypes.func.isRequired,
  onUpdateSearchPhrase: PropTypes.func.isRequired,
  orderByColumnIsCustom: PropTypes.bool.isRequired,
  searchPhrase: PropTypes.string.isRequired,
};

export const ContentItemsTextFilter: React.FC<Props> = ({
  autofocus,
  fullTextSearchStatus,
  onSortReset,
  onUpdateSearchPhrase,
  orderByColumnIsCustom,
  searchPhrase,
}) => {
  const isFullTextSearchEnabled = fullTextSearchStatus === FullTextSearchStatus.Enabled;

  const helpMessage = isFullTextSearchEnabled ? ExactMatchHelpMessage : DisabledSearchHelpMessage;

  const errorMessage = validateFullTextSearchPhrase({
    fullTextSearchStatus,
    searchPhrase,
  });

  return (
    <TextFilter
      autofocus={autofocus}
      errorMessage={errorMessage}
      extraIconsAfterReset={[
        <Hint key="full-text-search-help" tooltipPlacement="bottom" tooltipText={helpMessage} />,
      ]}
      extraIconsBeforeReset={[
        <ContentItemsSearchSort
          isVisible={isFullTextSearchEnabled && !!searchPhrase}
          key="full-text-search-sort"
          onSortReset={onSortReset}
          orderByColumnIsCustom={orderByColumnIsCustom}
        />,
      ]}
      maxLength={
        isFullTextSearchEnabled
          ? MaxFullTextSearchPhraseLength
          : ValidationConstants.ContentItemNameMaxLength
      }
      onChange={onUpdateSearchPhrase}
      placeholder={
        isFullTextSearchEnabled
          ? 'Search for items by their name or content'
          : 'Search for items by their name'
      }
      text={searchPhrase}
    />
  );
};

ContentItemsTextFilter.displayName = 'ContentItemsTextFilter';
ContentItemsTextFilter.propTypes = propTypes;
