export enum LandmarkAriaLabels {
  Application = 'application',
  AssetDetails = 'assets details',
  ContentAndAssets = 'content and assets',
  ContentGroups = 'content groups',
  ContentModels = 'content models',
  EnvironmentSettings = 'environment settings',
  Home = 'home',
  ItemDetails = 'item details',
  MyProjects = 'my projects',
  ProjectsAndEnvironments = 'projects and environments',
  ProjectSettings = 'projects settings',
  SubscriptionSettings = 'subscription settings',
  UserContext = 'user context',
}
