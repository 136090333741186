import { EditorProps } from 'draft-js';
import { useCallback, useMemo } from 'react';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { DecoratedEditor } from '../../editorCore/types/Editor.decorated.type.ts';
import {
  Apply,
  EditorPlugin,
  PluginState,
  Render,
} from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import { withDisplayName } from '../../editorCore/utils/withDisplayName.ts';
import { ForceSingleUnstyledBlockPlugin } from '../behavior/ForceSingleUnstyledBlockPlugin.tsx';

export type IgnoreCtrlEnterPlugin = EditorPlugin<
  None,
  None,
  None,
  [ForceSingleUnstyledBlockPlugin]
>;

const EditorWithReturnHandling: DecoratedEditor<IgnoreCtrlEnterPlugin> = ({
  baseRender,
  state,
}) => {
  const {
    editorProps: { handleReturn: parentHandleReturn },
  } = state;

  const handleReturn: Required<EditorProps>['handleReturn'] = useCallback(
    (event, editorState) => {
      if (event.ctrlKey || event.metaKey) {
        //  Handled by parent within Ctrl/Command + Enter
        event.preventDefault();
        return 'handled';
      }

      return parentHandleReturn?.(event, editorState) ?? 'not-handled';
    },
    [parentHandleReturn],
  );

  const stateWithReturnHandling: PluginState<IgnoreCtrlEnterPlugin> = {
    ...state,
    editorProps: {
      ...state.editorProps,
      handleReturn,
    },
  };

  return baseRender(stateWithReturnHandling);
};

EditorWithReturnHandling.displayName = 'EditorWithReturnHandling';

export const useIgnoreCtrlEnter: PluginCreator<IgnoreCtrlEnterPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('IgnoreCtrlEnterPlugin', {
        ComposedEditor: (props) => {
          const render: Decorator<Render<IgnoreCtrlEnterPlugin>> = useCallback(
            (baseRender) => (state) => (
              <EditorWithReturnHandling baseRender={baseRender} state={state} />
            ),
            [],
          );

          const apply: Apply<IgnoreCtrlEnterPlugin> = useCallback(
            (state) => {
              state.render.decorate(render);
              return {};
            },
            [render],
          );

          return useEditorWithPlugin(baseEditor, props, { apply });
        },
      }),
    [baseEditor],
  );
