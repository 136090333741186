import React from 'react';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { ActiveCapabilityType } from '../../../../../../_shared/models/activeCapability.type.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { cancelContentItemVariantsScheduledPublish } from '../../actions/thunkContentItemEditingActions.ts';
import { AssignmentSection as AssignmentSectionComponent } from '../../components/details/AssignmentSection.tsx';

export const AssignmentSection: React.FC = () => {
  const dispatch = useDispatch();
  const editedContentItemVariant = useSelector(
    (state) => state.contentApp.editedContentItemVariant,
  );
  const canDiscard = useSelector((state) =>
    state.contentApp.editorUi.activeCapabilities.variantCapabilities.includes(
      ActiveCapabilityType.DiscardNewVersion,
    ),
  );

  if (!editedContentItemVariant) {
    return null;
  }
  const cancelScheduling = () =>
    dispatch(cancelContentItemVariantsScheduledPublish(ContentItemEditingEventOrigins.ItemDetails));

  return (
    <AssignmentSectionComponent
      editedContentItemVariant={editedContentItemVariant}
      canDiscard={canDiscard}
      cancelScheduling={cancelScheduling}
    />
  );
};
