import React from 'react';
import { Controller } from 'react-hook-form';
import { DatetimePicker } from '../../../../../../../_shared/components/DatetimePicker/DatetimePicker.tsx';
import { HookFormProps } from '../../../../../../../_shared/types/hookFormProps.ts';
import { getTodayReadableDate } from '../../../../../../../_shared/utils/dateTime/timeUtils.ts';
import { showFieldError } from '../../../../../../../_shared/utils/validation/showFieldError.ts';
import { IDueDateFormShape } from '../../../models/IDueDateFormShape.type.ts';
import { FormPane } from '../FormPane.tsx';
import { UnsavedChangesWarning } from '../UnsavedChangesWarning.tsx';

type Props = {
  readonly datePickerId?: string;
  readonly formProps: HookFormProps<IDueDateFormShape>;
  readonly initialDueDate: DateTimeStamp | null;
  readonly isOtherAssignmentSectionSubmitting: boolean;
  readonly isSectionSubmitting: boolean;
  readonly isWarningDisplayed: boolean;
  readonly onReset: () => void;
  readonly onSubmit: () => void;
};

const getSubmitButtonText = (
  initialDueDate: DateTimeStamp | null,
  withIngForm: boolean,
): string => {
  if (!initialDueDate) {
    return withIngForm ? 'Setting...' : 'Set';
  }
  return withIngForm ? 'Changing...' : 'Change';
};

export const DueDateFormBase: React.FC<Props> = (props) => (
  <FormPane
    disableCancelButton={props.isSectionSubmitting}
    disableSubmitButton={props.isSectionSubmitting || props.isOtherAssignmentSectionSubmitting}
    onSubmit={props.onSubmit}
    onReset={props.onReset}
    submitButtonTooltipText={getSubmitButtonText(props.initialDueDate, false)}
    submitButtonText={getSubmitButtonText(props.initialDueDate, props.isSectionSubmitting)}
  >
    <Controller
      control={props.formProps.control}
      name="date"
      render={({ field, fieldState, formState }) => (
        <DatetimePicker
          ref={field.ref}
          autoFocus
          datePickerId={props.datePickerId}
          defaultTime="16:00"
          defaultDate={getTodayReadableDate()}
          disabled={props.isSectionSubmitting}
          hideValidationError={!showFieldError(formState, fieldState.error)}
          onChange={field.onChange}
          popperClassName="sidebar__date-input"
          value={field.value.value}
        />
      )}
    />
    {props.isWarningDisplayed && (
      <UnsavedChangesWarning
        submitButtonName={getSubmitButtonText(props.initialDueDate, false)}
        subjectOfChange="due date"
      />
    )}
  </FormPane>
);

DueDateFormBase.displayName = 'DueDateFormBase';
