import PropTypes, { Validator } from 'prop-types';
import { WorkflowScope } from '../../../data/models/workflow/Workflow.ts';
import { DefaultWorkflowId } from '../../constants/variantIdValues.ts';

export type WorkflowWithApplicableScopes = {
  id: Uuid;
  name: string;
  applicableScopes: ReadonlyArray<WorkflowScope>;
};

export const workflowsWithApplicableScopesPropTypes = PropTypes.arrayOf(PropTypes.object.isRequired)
  .isRequired as Validator<ReadonlyArray<WorkflowWithApplicableScopes>>;

export type WorkflowsWithApplicableScopesByLimitations = {
  limitedWorkflows: ReadonlyArray<WorkflowWithApplicableScopes>;
  unlimitedWorkflows: ReadonlyArray<WorkflowWithApplicableScopes>;
};

export const scopeHasNoLimitation = (scope: WorkflowScope) =>
  scope.contentTypes.size === 0 && scope.collections.size === 0;

export const doesWorkflowHaveLimitedScope = (workflow: WorkflowWithApplicableScopes) =>
  !workflow.applicableScopes.every(scopeHasNoLimitation);

export const doesWorkflowHaveUnlimitedScope = (workflow: WorkflowWithApplicableScopes): boolean =>
  workflow.applicableScopes.some(scopeHasNoLimitation) || workflow.id === DefaultWorkflowId;
