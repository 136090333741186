import { checkboxGroupSpacingBetweenOptions } from '@kontent-ai/component-library/CheckboxGroup';
import { Stack } from '@kontent-ai/component-library/Stack';
import PropTypes from 'prop-types';
import React from 'react';
import { Capability } from '../../../../../../_shared/utils/permissions/capability.ts';
import { CapabilityOption } from '../../../containers/CapabilityOption.tsx';
import { CapabilityConfigurationCard } from './CapabilityConfigurationCard.tsx';

interface IContentModelsCapabilitiesConfigurationCardProps {
  readonly isAssetTypeEnabled: boolean;
  readonly isSitemapEnabled: boolean;
}

export const ContentModelsCapabilitiesConfigurationCard: React.FC<
  IContentModelsCapabilitiesConfigurationCardProps
> = (props) => (
  <CapabilityConfigurationCard title="Content models" dataUiObjectName="content-models-tile">
    <div className="card__content card__content--full-width">
      <Stack spacing={checkboxGroupSpacingBetweenOptions}>
        <CapabilityOption
          optionName={
            props.isAssetTypeEnabled
              ? 'Manage content types, asset type, and snippets'
              : 'Manage content types and content type snippets'
          }
          checkboxOption={Capability.ConfigureContentTypes}
        />
        <CapabilityOption
          optionName="Manage taxonomy"
          checkboxOption={Capability.ConfigureTaxonomy}
        />
        {props.isSitemapEnabled && (
          <CapabilityOption
            optionName="Manage sitemap"
            checkboxOption={Capability.ConfigureSitemap}
          />
        )}
      </Stack>
    </div>
  </CapabilityConfigurationCard>
);

const propTypes: PropTypesShape<IContentModelsCapabilitiesConfigurationCardProps> = {
  isAssetTypeEnabled: PropTypes.bool.isRequired,
  isSitemapEnabled: PropTypes.bool.isRequired,
};

ContentModelsCapabilitiesConfigurationCard.displayName =
  'ContentModelsCapabilitiesConfigurationCard';
ContentModelsCapabilitiesConfigurationCard.propTypes = propTypes;
