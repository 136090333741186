import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { DraggedFilesContext } from '../../../../../_shared/components/DragDrop/DraggedFilesContext.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getStopEventsConsumedByDraftJs } from '../../../editorCore/utils/stopEventsConsumedByDraftJs.ts';

interface ICustomBlockWrapperProps {
  readonly className: string;
  readonly uiElement?: DataUiElement;
}

const propTypes: PropTypesShape<ICustomBlockWrapperProps> = {
  className: PropTypes.string.isRequired,
  uiElement: PropTypes.string,
};

export const CustomBlockWrapper: React.FC<React.PropsWithChildren<ICustomBlockWrapperProps>> = ({
  children,
  className,
  uiElement,
}) => {
  const { isDraggingFiles } = useContext(DraggedFilesContext);

  return (
    <div
      className={className}
      // Custom blocks are complex components with their own live cycle isolated from that of rich text editor.
      // That is why we disable propagation of their events into the editor. Editor handlers could conflict with those of custom block component.
      {...getStopEventsConsumedByDraftJs(isDraggingFiles)}
      {...(uiElement && getDataUiElementAttribute(uiElement))}
    >
      {children}
    </div>
  );
};

CustomBlockWrapper.displayName = 'CustomBlockWrapper';
CustomBlockWrapper.propTypes = propTypes;
