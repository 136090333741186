import { memoize } from '@kontent-ai/memoization';
import React, { useCallback } from 'react';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { TypeElement } from '../../../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { ICompiledContentItemElementData } from '../../../../../models/contentItemElements/ICompiledContentItemElement.ts';
import { isCustomElement } from '../../../../../models/contentItemElements/compiledItemElementTypeGuards.ts';
import { getElementById } from '../../../../../stores/utils/contentItemElementsUtils.ts';
import { onCustomElementValueChange } from '../../../actions/thunkContentItemEditingActions.ts';
import {
  CustomElement as CustomElementComponent,
  CustomElementExtraProps,
  CustomElementProps,
} from '../../../components/elements/CustomElement.tsx';
import { getElementValueForCustomElement } from '../../../utils/getElementValueForCustomElement.ts';
import { getEditedTypeElements } from '../../selectors/getEditedTypeElements.ts';

const createGetElementValue = memoize.weak(
  (
    elements: ReadonlyArray<ICompiledContentItemElementData>,
    typeElements: ReadonlyArray<TypeElement>,
    forCustomElementId: Uuid,
  ) =>
    (elementCodename: string) =>
      getElementValueForCustomElement(elements, forCustomElementId, typeElements, elementCodename),
);

type Props = Omit<CustomElementProps, 'elementData' | keyof CustomElementExtraProps>;

export const CustomElement: React.FC<Props> = (props) => {
  const {
    typeElement: { elementId },
  } = props;

  const dispatch = useDispatch();
  const elementData = useSelector((state) =>
    getElementById(elementId, state.contentApp.editedContentItemVariantElements),
  );
  const elements = useSelector((state) => state.contentApp.editedContentItemVariantElements);

  const validationErrors = useSelector(
    (state) => state.contentApp.itemValidationErrors.get(elementId)?.errorMessages,
  );
  const typeElements = useSelector(getEditedTypeElements);

  const onValueChange = useCallback(
    (value: string | null, searchableValue: string | null) =>
      dispatch(onCustomElementValueChange(elementId, value, searchableValue)),
    [elementId],
  );

  if (!isCustomElement(elementData)) {
    return null;
  }

  return (
    <CustomElementComponent
      {...props}
      validationErrors={validationErrors}
      getElementValue={createGetElementValue(elements, typeElements, elementId)}
      elements={elements}
      elementData={elementData}
      typeElements={typeElements}
      onValueChange={onValueChange}
    />
  );
};

CustomElement.displayName = 'CustomElementContainer';
