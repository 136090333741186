import { PortalContainerContext } from '@kontent-ai/component-library/context';
import { useAttachRef, usePrevious } from '@kontent-ai/hooks';
import { noOperation } from '@kontent-ai/utils';
import { useOverlay } from '@react-aria/overlays';
import Tippy, { TippyProps } from '@tippyjs/react';
import { add, getHours, getMinutes, isValid, setHours, setMinutes } from 'date-fns';
import React, {
  ChangeEventHandler,
  EventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactDatePicker from 'react-datepicker';
import { EventKeys } from '../../../constants/eventKeys.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { isEmptyOrWhitespace } from '../../../utils/stringUtils.ts';
import { IDateTimePickerTippyOptions } from '../DatetimePicker.tsx';
import { DateTimePickerHeader } from './DatetimePickerHeader.tsx';
import { TimeInput } from './TimeInput.tsx';
import {
  DateTimeISOFormat,
  IncrementDownHours,
  IncrementDownMinutes,
  IncrementUpHours,
  IncrementUpMinutes,
  dateFormats,
  formatTimeToReadable,
  parseDate,
  parseTime,
} from './datetimeUtils.ts';
import { defaultLocaleWithCorrectStartOfWeekDay } from './localeUtils.ts';

const stopEventPropagation: EventHandler<any> = (e) => {
  e.stopPropagation();
};

const isTimeInputValueValid = (timeInputValue: string | null | undefined) => {
  if (!timeInputValue || isEmptyOrWhitespace(timeInputValue)) {
    return true;
  }

  const parsedTime = parseTime(timeInputValue);
  return !!parsedTime;
};

const requiredTippyOptions: TippyProps = {
  interactive: true,
  offset: [0, 0],
};

const defaultDatetimePickerTippyOptions: IDateTimePickerTippyOptions = {
  placement: 'bottom-start',
  popperOptions: {
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['bottom-end', 'top-start', 'top-end', 'right', 'left'],
        },
      },
    ],
  },
};

interface IModalPickerProps {
  readonly className?: string;
  readonly datePickerId?: string;
  readonly defaultDate?: string;
  readonly defaultTime?: string;
  readonly inputWrapperRef?: RefObject<HTMLElement>;
  readonly isVisible: boolean;
  readonly maxValue?: Date;
  readonly minValue?: Date;
  readonly onChange: (value: Date) => void;
  readonly onClose?: () => void;
  readonly popperClassName?: string;
  readonly tippyOptions?: IDateTimePickerTippyOptions;
  readonly value: Date | undefined | null;
}

export const ModalPicker = React.forwardRef<HTMLDivElement, IModalPickerProps>(
  (
    {
      className,
      datePickerId,
      defaultDate,
      defaultTime,
      inputWrapperRef,
      isVisible,
      maxValue,
      minValue,
      onChange,
      onClose,
      popperClassName,
      tippyOptions,
      value,
    },
    forwardedRef,
  ) => {
    const { portalContainerRef } = useContext(PortalContainerContext);
    const useTippyOptions = {
      ...requiredTippyOptions,
      appendTo: portalContainerRef.current ?? document.body,
      ...(tippyOptions || defaultDatetimePickerTippyOptions),
    };

    const { refObject: pickerRefObject, refToForward: pickerRefToForward } =
      useAttachRef<HTMLDivElement>(forwardedRef);

    const { overlayProps } = useOverlay(
      {
        isDismissable: !!onClose,
        isOpen: isVisible,
        onClose,
      },
      pickerRefObject,
    );

    // Following refs exist to avoid running effects when unwanted
    const onChangeRef = useRef(onChange);
    const valueRef = useRef(value);
    const defaultDateRef = useRef<Date | null>(null);

    const readableTimeValue = value ? formatTimeToReadable(value) : '';
    const [timeInputValue, setTimeInputValue] = useState<string>(defaultTime ?? readableTimeValue);
    const [timeInputIsBeingEdited, setTimeInputIsBeingEdited] = useState<boolean>(false);

    const timeInputIsValid = isTimeInputValueValid(timeInputValue);

    useEffect(() => {
      onChangeRef.current = onChange;
    }, [onChange]);
    useEffect(() => {
      valueRef.current = value;
    }, [value]);
    useEffect(() => {
      const parsedDefaultDate = defaultDate && parseDate(defaultDate, dateFormats);
      defaultDateRef.current =
        parsedDefaultDate && isValid(parsedDefaultDate) ? parsedDefaultDate : null;
    }, [defaultDate]);

    const previousReadableTimeValue = usePrevious(readableTimeValue);
    useEffect(() => {
      if (timeInputIsBeingEdited || previousReadableTimeValue === readableTimeValue) {
        return;
      }

      if (readableTimeValue) {
        setTimeInputValue(readableTimeValue);
      }
    }, [readableTimeValue, previousReadableTimeValue, timeInputIsBeingEdited]);

    const previousTimeInputValue = usePrevious(timeInputValue);
    useEffect(() => {
      if (!isVisible || previousTimeInputValue === timeInputValue) {
        return;
      }

      const parsedTime = parseTime(timeInputValue);
      if (parsedTime) {
        const newValue = setMinutes(
          setHours(valueRef.current ?? new Date(), getHours(parsedTime)),
          getMinutes(parsedTime),
        );
        onChangeRef.current?.(newValue);
      }
    }, [isVisible, previousTimeInputValue, timeInputValue]);

    const onTimeInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
      ({ target: { value: newValue } }) => {
        setTimeInputIsBeingEdited(true);
        setTimeInputValue(newValue);

        if (isEmptyOrWhitespace(newValue)) {
          if (value) {
            onChange(setMinutes(setHours(value, 0), 0));
          }
        } else {
          const parsedTime = parseTime(newValue);
          if (parsedTime) {
            onChange(
              setMinutes(
                setHours(value ?? new Date(), getHours(parsedTime)),
                getMinutes(parsedTime),
              ),
            );
          }
        }
      },
      [onChange, value],
    );

    const onTimeInputBlur: FocusEventHandler<HTMLInputElement> = useCallback(() => {
      setTimeInputIsBeingEdited(false);
    }, []);

    const onTimeInputKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
      (event): void => {
        const {
          key: eventKey,
          currentTarget: { value: timeValue },
        } = event;

        if (['ArrowDown', 'ArrowUp', 'Enter', 'Tab'].includes(eventKey)) {
          event.stopPropagation();
          event.preventDefault();
          setTimeInputIsBeingEdited(['ArrowDown', 'ArrowUp'].includes(eventKey));
        }
        const parsedTime = parseTime(timeValue);
        if (parsedTime) {
          if (eventKey === EventKeys.ArrowDown) {
            const newTime = add(
              parsedTime,
              event.shiftKey ? IncrementDownMinutes : IncrementDownHours,
            );
            const newValue = setMinutes(
              setHours(value ?? new Date(), getHours(newTime)),
              getMinutes(newTime),
            );
            setTimeInputValue(formatTimeToReadable(newValue));
          }

          if (event.key === EventKeys.ArrowUp) {
            const newTime = add(parsedTime, event.shiftKey ? IncrementUpMinutes : IncrementUpHours);
            const newValue = setMinutes(
              setHours(value ?? new Date(), getHours(newTime)),
              getMinutes(newTime),
            );
            setTimeInputValue(formatTimeToReadable(newValue));
          }
        }

        if (event.key === EventKeys.Tab || event.key === EventKeys.Enter) {
          onClose?.();
        }
      },
      [onClose, value],
    );

    const onCalendarDateSelect = useCallback(
      (date: Date, event: React.SyntheticEvent<any> | undefined): void => {
        if (event && event.type !== 'click') {
          return;
        }

        setTimeInputIsBeingEdited(false);
        const parsedDefaultTime = defaultTime && parseTime(defaultTime);
        const timeSource = value ? value : parsedDefaultTime;
        const newValue = timeSource
          ? setMinutes(setHours(date, getHours(timeSource)), getMinutes(timeSource))
          : date;
        onChange(newValue);
      },
      [defaultTime, onChange, value],
    );

    const onDone = useCallback(() => {
      if (!value && defaultDateRef.current) {
        onCalendarDateSelect(defaultDateRef.current, undefined);
      }
      onClose?.();
    }, [onCalendarDateSelect, onClose, value]);

    return (
      <Tippy
        {...useTippyOptions}
        reference={inputWrapperRef}
        render={() =>
          isVisible && (
            <div {...overlayProps} ref={pickerRefToForward} id={datePickerId}>
              <ReactDatePicker
                className={className}
                dateFormat={DateTimeISOFormat}
                disabledKeyboardNavigation
                fixedHeight
                inline
                maxDate={maxValue}
                minDate={minValue}
                onChange={noOperation}
                onSelect={onCalendarDateSelect}
                popperClassName={popperClassName}
                renderCustomHeader={DateTimePickerHeader}
                selected={value}
                highlightDates={
                  !value && defaultDateRef.current ? [defaultDateRef.current] : undefined
                }
                shouldCloseOnSelect={false}
                useWeekdaysShort
                locale={defaultLocaleWithCorrectStartOfWeekDay}
              >
                <div
                  className="datetime-picker__calendar-dropdown-footer"
                  // Stop focus/blur propagation from calendar popper so that ItemElement caret focus/blur works
                  onFocus={stopEventPropagation}
                  onBlur={stopEventPropagation}
                >
                  <TimeInput
                    defaultTime={timeInputValue}
                    isValid={timeInputIsValid}
                    onBlur={onTimeInputBlur}
                    onChange={onTimeInputChange}
                    onKeyDown={onTimeInputKeyDown}
                    value={timeInputValue || ''}
                  />
                  <button
                    className="datetime-picker__calendar-dropdown-submit-btn btn"
                    onClick={onDone}
                    {...getDataUiActionAttribute(DataUiAction.SaveDateTime)}
                  >
                    Done
                  </button>
                </div>
              </ReactDatePicker>
            </div>
          )
        }
        visible={isVisible}
      />
    );
  },
);
