import PropTypes from 'prop-types';
import React from 'react';
import { IconName } from '../../../../../../../_shared/constants/iconEnumGenerated.ts';
import { SquareButton } from '../../../../../../../_shared/uiComponents/Button/SquareButton.tsx';
import { ButtonStyle } from '../../../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface IRuleDeleteButtonProps {
  readonly onRemove: () => void;
  readonly disabled?: boolean;
}

const propTypes: PropTypesShape<IRuleDeleteButtonProps> = {
  disabled: PropTypes.bool,
  onRemove: PropTypes.func.isRequired,
};

export const RuleDeleteButton: React.FC<IRuleDeleteButtonProps> = ({ disabled, onRemove }) => (
  <SquareButton
    className="capability-rules__delete-button"
    disabled={disabled}
    iconName={IconName.Bin}
    onClick={disabled ? undefined : onRemove}
    screenReaderText="Delete rule"
    style={ButtonStyle.Tertiary}
    {...getDataUiActionAttribute(DataUiAction.Delete)}
  />
);

RuleDeleteButton.displayName = 'RuleDeleteButton';
RuleDeleteButton.propTypes = propTypes;
