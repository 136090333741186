import { UnreachableCaseException } from '@kontent-ai/errors';
import { Direction } from '@kontent-ai/types';
import { Collection } from '@kontent-ai/utils';
import { ContentBlock, ContentState, DraftInlineStyle, SelectionState } from 'draft-js';
import {
  BaseBlockType,
  BlockType,
  TextBlockTypes,
  getBaseType,
  isBlockTypeNestedIn,
  isMutableBlockType,
  isTableContentBlockType,
  isTopLevelBlockType,
  parseBlockType,
} from '../../../utils/blocks/blockType.ts';
import {
  CustomBlockSleevePosition,
  HeadingBlockTypeSequence,
  findSiblingBlock,
  getCustomBlockSleevePosition,
  getNextBlockTypeInSequence,
  isCustomBlockSleeve,
  isInTable,
  isTextBlock,
} from '../../../utils/blocks/blockTypeUtils.ts';
import { getBaseBlockType, getBlockKey } from '../../../utils/blocks/editorBlockGetters.ts';
import { findBlockIndex } from '../../../utils/blocks/editorBlockUtils.ts';
import {
  filterSelection,
  getBaseBlockTypes,
  getFullBlockTypesAtSelection,
  setContentSelection,
} from '../../../utils/editorSelectionUtils.ts';
import { getBlocks } from '../../../utils/general/editorContentGetters.ts';
import {
  IContentChangeInput,
  IContentChangeResult,
} from '../../../utils/general/editorContentUtils.ts';
import { IAggregatedMetadata } from '../../../utils/metadata/editorMetadataUtils.ts';
import { EditorFeatureLimitations } from '../../apiLimitations/api/EditorFeatureLimitations.ts';
import {
  BaseTextFormattingFeature,
  RichTextCommandFeature,
  RichTextFeature,
  TableBlockCategoryFeature,
  TextBlockTypeFeature,
  TextFormattingFeature,
  TextStyleFeature,
  TopLevelBlockCategoryFeature,
  areAllTextBlocksAllowed,
  areAllTextBlocksDisallowed,
  getAllDisallowedFeatures,
  isTextFeatureAllowed,
} from '../../apiLimitations/api/editorLimitationUtils.ts';
import {
  NoStyle,
  applyInlineStyleForSelectedChars,
  removeInlineStyleForSelectedChars,
} from '../../inlineStyles/api/editorStyleUtils.ts';
import { DraftJSInlineStyle } from '../../inlineStyles/api/inlineStyles.ts';
import {
  BlockTypeCommand,
  CaretSelectionCommand,
  RichTextInputCommand,
  TextFormattingCommand,
} from './EditorCommand.ts';

export const TextFormattingCommandFeatureMap: ReadonlyRecord<
  TextFormattingCommand,
  BaseTextFormattingFeature
> = {
  [RichTextInputCommand.Bold]: TextFormattingFeature.Bold,
  [RichTextInputCommand.Code]: TextFormattingFeature.Code,
  [RichTextInputCommand.Italic]: TextFormattingFeature.Italic,
  [RichTextInputCommand.Subscript]: TextFormattingFeature.Subscript,
  [RichTextInputCommand.Superscript]: TextFormattingFeature.Superscript,
  [RichTextInputCommand.InsertLink]: TextFormattingFeature.Link,
};

const isTextFormattingCommand = (command: RichTextInputCommand): command is TextFormattingCommand =>
  (Object.keys(TextFormattingCommandFeatureMap) as ReadonlyArray<RichTextInputCommand>).includes(
    command,
  );

export const TextStyleFeatureStyleMap: ReadonlyRecord<TextStyleFeature, DraftJSInlineStyle> = {
  [TextFormattingFeature.Bold]: DraftJSInlineStyle.Bold,
  [TextFormattingFeature.Italic]: DraftJSInlineStyle.Italic,
  [TextFormattingFeature.Code]: DraftJSInlineStyle.Code,
  [TextFormattingFeature.Subscript]: DraftJSInlineStyle.Subscript,
  [TextFormattingFeature.Superscript]: DraftJSInlineStyle.Superscript,
};

export const isTextStyleFeature = (feature: RichTextCommandFeature): feature is TextStyleFeature =>
  (Object.keys(TextStyleFeatureStyleMap) as ReadonlyArray<RichTextFeature>).includes(feature);

export const BlockTypeCommandFeatureMap: ReadonlyRecord<BlockTypeCommand, TextBlockTypeFeature> = {
  [RichTextInputCommand.Unstyled]: TextBlockTypeFeature.Paragraph,
  [RichTextInputCommand.HeadingOne]: TextBlockTypeFeature.HeadingOne,
  [RichTextInputCommand.HeadingTwo]: TextBlockTypeFeature.HeadingTwo,
  [RichTextInputCommand.HeadingThree]: TextBlockTypeFeature.HeadingThree,
  [RichTextInputCommand.HeadingFour]: TextBlockTypeFeature.HeadingFour,
  [RichTextInputCommand.HeadingFive]: TextBlockTypeFeature.HeadingFive,
  [RichTextInputCommand.HeadingSix]: TextBlockTypeFeature.HeadingSix,
  [RichTextInputCommand.OrderedList]: TextBlockTypeFeature.OrderedList,
  [RichTextInputCommand.UnorderedList]: TextBlockTypeFeature.UnorderedList,
};

const isBlockTypeCommand = (command: RichTextInputCommand): command is BlockTypeCommand =>
  (Object.keys(BlockTypeCommandFeatureMap) as ReadonlyArray<RichTextInputCommand>).includes(
    command,
  );

export const TextBlockFeatureBlockTypeMap: ReadonlyRecord<TextBlockTypeFeature, BaseBlockType> = {
  [TextBlockTypeFeature.Paragraph]: BlockType.Unstyled,
  [TextBlockTypeFeature.HeadingOne]: BlockType.HeadingOne,
  [TextBlockTypeFeature.HeadingTwo]: BlockType.HeadingTwo,
  [TextBlockTypeFeature.HeadingThree]: BlockType.HeadingThree,
  [TextBlockTypeFeature.HeadingFour]: BlockType.HeadingFour,
  [TextBlockTypeFeature.HeadingFive]: BlockType.HeadingFive,
  [TextBlockTypeFeature.HeadingSix]: BlockType.HeadingSix,
  [TextBlockTypeFeature.OrderedList]: BlockType.OrderedListItem,
  [TextBlockTypeFeature.UnorderedList]: BlockType.UnorderedListItem,
};

const isTextBlockTypeFeature = (feature: RichTextCommandFeature): feature is TextBlockTypeFeature =>
  (Object.keys(TextBlockFeatureBlockTypeMap) as ReadonlyArray<RichTextCommandFeature>).includes(
    feature,
  );

export function getFeatureForBlockType(blockType: BaseBlockType): TextBlockTypeFeature | null {
  return (
    Object.keys(TextBlockFeatureBlockTypeMap).find(
      (key) => TextBlockFeatureBlockTypeMap[key] === blockType,
    ) ?? null
  );
}

export function getFeatureForStyle(style: DraftJSInlineStyle): BaseTextFormattingFeature | null {
  return (
    Object.keys(TextStyleFeatureStyleMap).find((key) => TextStyleFeatureStyleMap[key] === style) ??
    null
  );
}

export function getFeature(command: RichTextInputCommand): RichTextCommandFeature | null {
  if (isTextFormattingCommand(command)) {
    return TextFormattingCommandFeatureMap[command];
  }

  if (isBlockTypeCommand(command)) {
    return BlockTypeCommandFeatureMap[command];
  }

  return null;
}

export enum EditorCommandStatus {
  // Default state - Command applies the feature
  InactiveAllowed = 'InactiveAllowed',

  // Not allowed and not used - Command disabled
  InactiveNotAllowed = 'InactiveNotAllowed',

  // Used and allowed - Command removes the feature
  ActiveAllowed = 'ActiveAllowed',

  // Used but not allowed - Command removes the feature
  ActiveNotAllowed = 'ActiveNotAllowed',
}

export const getCommandDirection = (command: CaretSelectionCommand): Direction => {
  switch (command) {
    case RichTextInputCommand.MoveCaretToPreviousBlock:
    case RichTextInputCommand.AdjustSelectionToPreviousBlock:
    case RichTextInputCommand.MoveCaretToStartOfLine:
    case RichTextInputCommand.AdjustSelectionToStartOfLine: {
      return Direction.Backward;
    }
    case RichTextInputCommand.MoveCaretToNextBlock:
    case RichTextInputCommand.AdjustSelectionToNextBlock:
    case RichTextInputCommand.MoveCaretToEndOfLine:
    case RichTextInputCommand.AdjustSelectionToEndOfLine: {
      return Direction.Forward;
    }
    default:
      throw UnreachableCaseException(command, 'Provided command does not have a direction.');
  }
};

export const isSelectionCommand = (command: RichTextInputCommand): boolean => {
  switch (command) {
    case RichTextInputCommand.AdjustSelectionToPreviousBlock:
    case RichTextInputCommand.AdjustSelectionToNextBlock:
    case RichTextInputCommand.AdjustSelectionToEndOfLine:
    case RichTextInputCommand.AdjustSelectionToStartOfLine: {
      return true;
    }
    default:
      return false;
  }
};

export const getSleeveOnOppositeSideOfTheCustomBlock = (
  content: ContentState,
  startBlockKey: string,
  direction: Direction,
): ContentBlock | null => {
  const blocks = getBlocks(content);
  const startIndex = findBlockIndex(blocks, startBlockKey);
  if (startIndex >= 0) {
    const position = getCustomBlockSleevePosition(startIndex, blocks);
    if (
      (position === CustomBlockSleevePosition.AfterOwner && direction === Direction.Forward) ||
      (position === CustomBlockSleevePosition.BeforeOwner && direction === Direction.Backward) ||
      position === CustomBlockSleevePosition.None
    ) {
      return null;
    }
    for (
      let blockSearchResult = findSiblingBlock(startIndex, blocks, direction);
      blockSearchResult.block;
      blockSearchResult = findSiblingBlock(blockSearchResult.index, blocks, direction)
    ) {
      const { block: currentBlock, index } = blockSearchResult;

      if (isCustomBlockSleeve(index, blocks)) {
        return currentBlock;
      }
    }
  }

  return null;
};

export const getAdjacentBlockAcceptingSelection = (
  content: ContentState,
  startBlockKey: string,
  direction: Direction,
): ContentBlock | null => {
  const blocks = getBlocks(content);
  const getNextBlock = (key: string) =>
    direction === Direction.Forward ? content.getBlockAfter(key) : content.getBlockBefore(key);
  for (
    let targetBlock = getNextBlock(startBlockKey);
    targetBlock;
    targetBlock = getNextBlock(getBlockKey(targetBlock))
  ) {
    const blockIndex = findBlockIndex(blocks, getBlockKey(targetBlock));
    if (isTextBlock(targetBlock) || (blockIndex >= 0 && isCustomBlockSleeve(blockIndex, blocks))) {
      return targetBlock;
    }
  }
  return null;
};

export function canCommandExecute(status: EditorCommandStatus): boolean {
  switch (status) {
    case EditorCommandStatus.InactiveAllowed:
    case EditorCommandStatus.ActiveAllowed:
    case EditorCommandStatus.ActiveNotAllowed:
      return true;

    default:
      return false;
  }
}

export function isFeatureActive(status: EditorCommandStatus): boolean {
  switch (status) {
    case EditorCommandStatus.ActiveAllowed:
    case EditorCommandStatus.ActiveNotAllowed:
      return true;

    default:
      return false;
  }
}

export const isTextFormattingFeatureUsedInAllowedLevelsAtSelection = (
  feature: BaseTextFormattingFeature,
  metadataAtSelection: IAggregatedMetadata | null,
  limitations: EditorFeatureLimitations,
) => {
  if (!metadataAtSelection) {
    return false;
  }

  const isTextAllowed = limitations.allowedBlocks.has(TopLevelBlockCategoryFeature.Text);
  const isTableAllowed = limitations.allowedBlocks.has(TopLevelBlockCategoryFeature.Tables);

  if (isTextStyleFeature(feature)) {
    const style = TextStyleFeatureStyleMap[feature];
    return (
      ((metadataAtSelection.styleAtAnyTopLevelChars?.contains(style) ?? false) && isTextAllowed) ||
      ((metadataAtSelection.styleAtAnyTableChars?.contains(style) ?? false) && isTableAllowed)
    );
  }

  switch (feature) {
    case TextFormattingFeature.Link:
      // Simplified, does not check entity type
      return (
        (!(metadataAtSelection.entityKeyAtAnyTopLevelChars?.isEmpty() ?? true) && isTextAllowed) ||
        (!(metadataAtSelection.entityKeyAtAnyTableChars?.isEmpty() ?? true) && isTableAllowed)
      );

    default:
      return false;
  }
};

const isFeatureUsed = (
  feature: RichTextCommandFeature,
  blockTypes: ReadonlySet<BlockType>,
  styleAtAnyChars: DraftInlineStyle | null | undefined,
): boolean => {
  if (isTextBlockTypeFeature(feature)) {
    const blockType = TextBlockFeatureBlockTypeMap[feature];
    return Collection.getValues(blockTypes).some((b) => !!b && getBaseType(b) === blockType);
  }

  if (isTextStyleFeature(feature)) {
    const style = TextStyleFeatureStyleMap[feature];
    return !!styleAtAnyChars?.contains(style);
  }

  return false;
};

export const isTextCommandAllowed = (
  feature: RichTextCommandFeature,
  fullBlockTypesAtSelection: ReadonlySet<BlockType>,
  metadataAtSelection: IAggregatedMetadata | null,
  limitations: EditorFeatureLimitations,
): boolean => {
  if (areAllTextBlocksDisallowed(fullBlockTypesAtSelection, limitations)) {
    return false;
  }

  const { disallowedTopLevelFeatures, disallowedTableFeatures } =
    getAllDisallowedFeatures(limitations);

  const isTextAllowed = limitations.allowedBlocks.has(TopLevelBlockCategoryFeature.Text);
  const isTableAllowed = limitations.allowedBlocks.has(TopLevelBlockCategoryFeature.Tables);

  const isAllowedInTopLevel = !disallowedTopLevelFeatures.has(feature) && isTextAllowed;
  const isAllowedInTable = !disallowedTableFeatures.has(feature) && isTableAllowed;

  // When the feature is block based, we are interested in block types in selection (if any present) that can be altered by it
  // When character based (text formatting), we are interested in selected chars that the formatting can potentially apply to
  const isBlockFeature = isTextBlockTypeFeature(feature);

  const relevantTopLevelBlockTypesAtSelection = new Set<BlockType>(
    isTextAllowed
      ? Collection.getValues(fullBlockTypesAtSelection).filter(
          (b) => !!b && isMutableBlockType(getBaseType(b)) && isTopLevelBlockType(b),
        )
      : [],
  );
  const selectionContainsRelevantTopLevelBlocks = !!relevantTopLevelBlockTypesAtSelection.size;
  const selectionContainsRelevantTopLevelChars =
    isTextAllowed && !!metadataAtSelection?.styleAtAnyTopLevelChars;
  const selectionContainsRelevantTopLevelContent = isBlockFeature
    ? selectionContainsRelevantTopLevelBlocks
    : selectionContainsRelevantTopLevelChars;

  const relevantTableBlockTypesAtSelection = new Set<BlockType>(
    isTableAllowed
      ? Collection.getValues(fullBlockTypesAtSelection).filter(
          (b) => !!b && isMutableBlockType(getBaseType(b)) && isTableContentBlockType(b),
        )
      : [],
  );
  const selectionContainsRelevantTableBlocks = !!relevantTableBlockTypesAtSelection.size;
  const selectionContainsRelevantTableChars =
    isTableAllowed && !!metadataAtSelection?.styleAtAnyTableChars;
  const selectionContainsRelevantTableContent = isBlockFeature
    ? selectionContainsRelevantTableBlocks
    : selectionContainsRelevantTableChars;

  // Mixed top level and table content
  if (selectionContainsRelevantTopLevelContent && selectionContainsRelevantTableContent) {
    // When the feature is the same in both, we can just use any of the result
    if (isAllowedInTopLevel === isAllowedInTable) {
      return isAllowedInTopLevel;
    }

    // One side doesn't allow the feature. When the forbidden side uses it, the command is not allowed
    // The code in getTextFormattingCommandStatus later ensures that the overall command status is active but not allowed
    // making the action to remove the invalid formatting the preferred action for the user interaction (clean first, then allow valid actions)
    const isFeatureUsedAsTopLevel = isFeatureUsed(
      feature,
      relevantTopLevelBlockTypesAtSelection,
      metadataAtSelection?.styleAtAnyTopLevelChars,
    );
    const isFeatureUsedAsTableContent = isFeatureUsed(
      feature,
      relevantTableBlockTypesAtSelection,
      metadataAtSelection?.styleAtAnyTableChars,
    );

    return isAllowedInTopLevel ? !isFeatureUsedAsTableContent : !isFeatureUsedAsTopLevel;
  }

  // Only top level or table content are present, we just check whether the respective side is allowed
  return selectionContainsRelevantTopLevelContent ? isAllowedInTopLevel : isAllowedInTable;
};

const getAllowedVisualStyle = (
  feature: BaseTextFormattingFeature,
  currentVisualStyle: DraftInlineStyle | null,
  metadataAtSelection: IAggregatedMetadata | null,
  limitations: EditorFeatureLimitations,
): DraftInlineStyle | null => {
  const { disallowedTopLevelFeatures, disallowedTableFeatures } =
    getAllDisallowedFeatures(limitations);
  const isAllowedInTopLevel =
    !disallowedTopLevelFeatures.has(feature) &&
    limitations.allowedBlocks.has(TopLevelBlockCategoryFeature.Text);
  const isAllowedInTable =
    !disallowedTableFeatures.has(feature) &&
    limitations.allowedBlocks.has(TopLevelBlockCategoryFeature.Tables);

  if (!metadataAtSelection) {
    return NoStyle;
  }

  if (isAllowedInTopLevel && isAllowedInTable) {
    return currentVisualStyle;
  }

  if (isAllowedInTopLevel) {
    return metadataAtSelection.styleAtAllTopLevelChars;
  }

  if (isAllowedInTable) {
    return metadataAtSelection.styleAtAllTableChars;
  }

  return NoStyle;
};

export function getTextFormattingCommandStatus(
  feature: BaseTextFormattingFeature,
  fullBlockTypesAtSelection: ReadonlySet<BlockType>,
  currentVisualStyle: DraftInlineStyle | null,
  metadataAtSelection: IAggregatedMetadata | null,
  limitations: EditorFeatureLimitations,
): EditorCommandStatus {
  const canExecuteCommand = isTextFormattingCommandAllowedAtSelection(
    feature,
    fullBlockTypesAtSelection,
    metadataAtSelection,
    limitations,
  );
  if (!canExecuteCommand) {
    return EditorCommandStatus.InactiveNotAllowed;
  }

  const isCommandAllowed = isTextCommandAllowed(
    feature,
    fullBlockTypesAtSelection,
    metadataAtSelection,
    limitations,
  );

  const isFeatureUsedAtSelection = isTextFormattingFeatureUsedInAllowedLevelsAtSelection(
    feature,
    metadataAtSelection,
    limitations,
  );
  const currentAllowedVisualStyle = getAllowedVisualStyle(
    feature,
    currentVisualStyle,
    metadataAtSelection,
    limitations,
  );
  const isFeatureUsedAtCurrentStyle =
    isTextStyleFeature(feature) &&
    !!currentAllowedVisualStyle &&
    currentAllowedVisualStyle.contains(TextStyleFeatureStyleMap[feature]);

  if (isCommandAllowed) {
    // When feature is allowed and used at the current (active) style, we toggle based on the current style as the user is not limited
    return isFeatureUsedAtCurrentStyle
      ? EditorCommandStatus.ActiveAllowed
      : EditorCommandStatus.InactiveAllowed;
  }

  // When not allowed, we either allow removing it to make the content valid, or just disable it
  return isFeatureUsedAtSelection
    ? EditorCommandStatus.ActiveNotAllowed
    : EditorCommandStatus.InactiveNotAllowed;
}

const getRelevantBaseBlockTypes = (
  allBaseBlockTypes: ReadonlySet<BaseBlockType>,
  topLevelBlockTypes: ReadonlySet<BlockType>,
  tableBlockTypes: ReadonlySet<BlockType>,
  shouldReturnTopLevelBlockTypes: boolean,
  shouldReturnTableBlockTypes: boolean,
): ReadonlySet<BaseBlockType> => {
  if (allBaseBlockTypes.size === 0) {
    return allBaseBlockTypes;
  }
  if (shouldReturnTopLevelBlockTypes && shouldReturnTableBlockTypes) {
    return allBaseBlockTypes;
  }
  if (shouldReturnTopLevelBlockTypes) {
    return getBaseBlockTypes(topLevelBlockTypes);
  }
  if (shouldReturnTableBlockTypes) {
    return getBaseBlockTypes(tableBlockTypes);
  }
  return new Set();
};

function getBlockTypeCommandStatus(
  feature: TextBlockTypeFeature,
  fullBlockTypesAtSelection: ReadonlySet<BlockType>,
  limitations: EditorFeatureLimitations,
): EditorCommandStatus {
  const isCommandAllowed = isTextCommandAllowed(
    feature,
    fullBlockTypesAtSelection,
    null,
    limitations,
  );
  const { disallowedTopLevelFeatures, disallowedTableFeatures } =
    getAllDisallowedFeatures(limitations);

  const isTextAllowed = limitations.allowedBlocks.has(TopLevelBlockCategoryFeature.Text);
  const isTableAllowed = limitations.allowedBlocks.has(TopLevelBlockCategoryFeature.Tables);

  const isAllowedInTopLevel = !disallowedTopLevelFeatures.has(feature);
  const isAllowedInTable = !disallowedTableFeatures.has(feature);

  const topLevelBlockTypesAtSelection = new Set<BlockType>(
    isTextAllowed
      ? Collection.getValues(fullBlockTypesAtSelection).filter(isTopLevelBlockType)
      : [],
  );
  const tableBlockTypesAtSelection = new Set<BlockType>(
    isTableAllowed
      ? Collection.getValues(fullBlockTypesAtSelection).filter(
          (b) => !!b && isBlockTypeNestedIn(b, BlockType.TableCell),
        )
      : [],
  );

  const blockType = TextBlockFeatureBlockTypeMap[feature];
  const baseBlockTypesAtSelection = getBaseBlockTypes(fullBlockTypesAtSelection);
  const allowedBaseBlockTypesAtSelection = getRelevantBaseBlockTypes(
    baseBlockTypesAtSelection,
    topLevelBlockTypesAtSelection,
    tableBlockTypesAtSelection,
    isTextAllowed,
    isTableAllowed,
  );
  const isBlockTypeUsedAtSelection = allowedBaseBlockTypesAtSelection.has(blockType);

  if (isCommandAllowed) {
    const relevantBaseBlockTypesAtSelection = getRelevantBaseBlockTypes(
      allowedBaseBlockTypesAtSelection,
      topLevelBlockTypesAtSelection,
      tableBlockTypesAtSelection,
      isAllowedInTopLevel,
      isAllowedInTable,
    );
    const relevantBaseTextBlockTypesAtSelection = Collection.intersect(
      TextBlockTypes,
      relevantBaseBlockTypesAtSelection,
    );

    const isOnlyBlockTypeAtSelection =
      relevantBaseTextBlockTypesAtSelection.length === 1 && isBlockTypeUsedAtSelection;
    return isOnlyBlockTypeAtSelection
      ? EditorCommandStatus.ActiveAllowed
      : EditorCommandStatus.InactiveAllowed;
  }
  return isBlockTypeUsedAtSelection
    ? EditorCommandStatus.ActiveNotAllowed
    : EditorCommandStatus.InactiveNotAllowed;
}

function isGeneralCommandAllowed(
  command: RichTextInputCommand,
  fullBlockTypesAtSelection: ReadonlySet<BlockType>,
  selectionContainsText: boolean | null,
  limitations: EditorFeatureLimitations,
): boolean {
  const { allowedBlocks, allowedTableBlocks } = limitations;
  const baseBlockTypesAtSelection = getBaseBlockTypes(fullBlockTypesAtSelection);
  const onlyTextBlocksAtSelection = !Collection.removeMany(
    baseBlockTypesAtSelection,
    TextBlockTypes,
  ).size;
  const isTableContentAtSelection = Collection.getValues(fullBlockTypesAtSelection)
    .flatMap(parseBlockType)
    .includes(BlockType.TableCell);
  const isSelectionValidForNewCustomBlock = !selectionContainsText && onlyTextBlocksAtSelection;

  switch (command) {
    case RichTextInputCommand.CycleHeading:
    case RichTextInputCommand.AddSuggestion:
      return areAllTextBlocksAllowed(fullBlockTypesAtSelection, limitations);

    case RichTextInputCommand.InsertAsset: {
      if (!isSelectionValidForNewCustomBlock) {
        return false;
      }
      if (isTableContentAtSelection) {
        if (!allowedBlocks.has(TopLevelBlockCategoryFeature.Tables)) {
          return false;
        }
        return allowedTableBlocks.has(TableBlockCategoryFeature.Images);
      }
      return allowedBlocks.has(TopLevelBlockCategoryFeature.Images);
    }

    case RichTextInputCommand.InsertItem:
    case RichTextInputCommand.InsertComponent: {
      if (!isSelectionValidForNewCustomBlock) {
        return false;
      }
      return (
        !isTableContentAtSelection &&
        allowedBlocks.has(TopLevelBlockCategoryFeature.ComponentsAndItems)
      );
    }

    case RichTextInputCommand.InsertTable: {
      if (!isSelectionValidForNewCustomBlock) {
        return false;
      }
      return !isTableContentAtSelection && allowedBlocks.has(TopLevelBlockCategoryFeature.Tables);
    }

    default:
      return true;
  }
}

export const getCommandStatus = (
  command: RichTextInputCommand,
  fullBlockTypesAtSelection: ReadonlySet<BlockType>,
  currentVisualStyle: DraftInlineStyle | null,
  metadataAtSelection: IAggregatedMetadata | null,
  selectionContainsText: boolean | null,
  limitations: EditorFeatureLimitations,
): EditorCommandStatus => {
  if (isTextFormattingCommand(command)) {
    return getTextFormattingCommandStatus(
      TextFormattingCommandFeatureMap[command],
      fullBlockTypesAtSelection,
      currentVisualStyle,
      metadataAtSelection,
      limitations,
    );
  }

  if (isBlockTypeCommand(command)) {
    return getBlockTypeCommandStatus(
      BlockTypeCommandFeatureMap[command],
      fullBlockTypesAtSelection,
      limitations,
    );
  }

  const isAllowed = isGeneralCommandAllowed(
    command,
    fullBlockTypesAtSelection,
    selectionContainsText,
    limitations,
  );
  return isAllowed ? EditorCommandStatus.InactiveAllowed : EditorCommandStatus.InactiveNotAllowed;
};

export function isTextFormattingCommandAllowedAtSelection(
  feature: BaseTextFormattingFeature,
  fullBlockTypesAtSelection: ReadonlySet<BlockType>,
  metadataAtSelection: IAggregatedMetadata | null,
  limitations: EditorFeatureLimitations,
): boolean {
  const isCommandAllowed = isTextCommandAllowed(
    feature,
    fullBlockTypesAtSelection,
    metadataAtSelection,
    limitations,
  );

  // Even when formatting command is not allowed, we allow it in case it is used in place where removing the format makes the content valid
  // so that it can be removed. If whole level (text vs. table) is not allowed, there is no point in allowing it.
  return (
    isCommandAllowed ||
    isTextFormattingFeatureUsedInAllowedLevelsAtSelection(feature, metadataAtSelection, limitations)
  );
}

export const isBlockAllowed = (
  block: ContentBlock,
  limitations: EditorFeatureLimitations,
): boolean => {
  const { disallowedTopLevelFeatures, disallowedTableFeatures } =
    getAllDisallowedFeatures(limitations);
  const feature = getFeatureForBlockType(getBaseBlockType(block));
  if (!feature) {
    return false;
  }

  return isInTable(block)
    ? !disallowedTableFeatures.has(feature)
    : !disallowedTopLevelFeatures.has(feature);
};

export function removeViolatedInlineStyleForSelectedChars(
  input: IContentChangeInput,
  inlineStyle: DraftJSInlineStyle,
  limitations: EditorFeatureLimitations,
): IContentChangeResult {
  const feature = getFeatureForStyle(inlineStyle);

  if (!feature) {
    return input;
  }

  const { disallowedTopLevelFeatures, disallowedTableFeatures } =
    getAllDisallowedFeatures(limitations);
  const { allowedBlocks } = limitations;
  const isTextAllowed = allowedBlocks.has(TopLevelBlockCategoryFeature.Text);
  const isTableAllowed = allowedBlocks.has(TopLevelBlockCategoryFeature.Tables);
  const isFeatureDisallowedInText = disallowedTopLevelFeatures.has(feature);
  const isFeatureDisallowedInTable = disallowedTableFeatures.has(feature);
  const selections = filterSelection(input.content, input.selection, (block: ContentBlock) =>
    isInTable(block)
      ? isFeatureDisallowedInTable && isTableAllowed
      : isFeatureDisallowedInText && isTextAllowed,
  );

  const newContent = selections.reduce((previousNewContent, selection) => {
    const contentChangeInput = {
      content: previousNewContent,
      selection,
    };
    return removeInlineStyleForSelectedChars(contentChangeInput, inlineStyle).content;
  }, input.content);

  const newContentWithSelection = setContentSelection(newContent, input.selection, input.selection);

  return {
    content: newContentWithSelection,
    selection: input.selection,
    wasModified: newContent !== input.content,
  };
}

export const applyAllowedInlineStyleForSelectedChars = (
  input: IContentChangeInput,
  inlineStyle: DraftJSInlineStyle,
  limitations: EditorFeatureLimitations,
): IContentChangeResult => {
  const feature = getFeatureForStyle(inlineStyle);
  if (!feature) {
    return applyInlineStyleForSelectedChars(input, inlineStyle);
  }

  const { disallowedTopLevelFeatures, disallowedTableFeatures } =
    getAllDisallowedFeatures(limitations);
  const { allowedBlocks } = limitations;
  const isTextAllowed = allowedBlocks.has(TopLevelBlockCategoryFeature.Text);
  const isTableAllowed = allowedBlocks.has(TopLevelBlockCategoryFeature.Tables);
  const isAllowedInText = !disallowedTopLevelFeatures.has(feature);
  const isAllowedInTable = !disallowedTableFeatures.has(feature);

  const selections = filterSelection(input.content, input.selection, (block: ContentBlock) =>
    isInTable(block) ? isAllowedInTable && isTableAllowed : isAllowedInText && isTextAllowed,
  );

  const newContent = selections.reduce((previousNewContent, selection) => {
    const contentChangeInput = {
      content: previousNewContent,
      selection,
    };
    return applyInlineStyleForSelectedChars(contentChangeInput, inlineStyle).content;
  }, input.content);

  const newContentWithSelection = setContentSelection(newContent, input.selection, input.selection);

  return {
    content: newContentWithSelection,
    selection: input.selection,
    wasModified: newContent !== input.content,
  };
};

function getNextAllowedBlockType(
  sequence: ReadonlyArray<BaseBlockType>,
  fullBlockTypes: ReadonlySet<BlockType>,
  limitations: EditorFeatureLimitations,
  allowCycle: boolean,
  backwards?: boolean,
): BaseBlockType | null {
  const baseBlockTypesAtSelection = getBaseBlockTypes(fullBlockTypes);
  const onlyTextBlocksAtSelection = Collection.intersect(TextBlockTypes, baseBlockTypesAtSelection);
  const currentBlockType =
    onlyTextBlocksAtSelection.length === 1
      ? Collection.getFirst(onlyTextBlocksAtSelection) ?? null
      : // For a mixture of blocks start with the last block type to make the next step Unstyled
        Collection.getLast(sequence) ?? null;
  let nextBlockType: BaseBlockType | null = currentBlockType;

  // Failsafe to prevent infinite loop
  for (let i = 0; i < TextBlockTypes.length; i++) {
    nextBlockType = getNextBlockTypeInSequence(sequence, nextBlockType, allowCycle, backwards);
    if (nextBlockType === null) {
      return null;
    }
    if (nextBlockType === currentBlockType) {
      return null;
    }
    const feature = getFeatureForBlockType(nextBlockType);
    if (feature) {
      const isFeatureAllowed = isTextFeatureAllowed(feature, fullBlockTypes, limitations);

      if (isFeatureAllowed) {
        return nextBlockType;
      }
    }
  }
  return null;
}

export function getNextAllowedBlockTypeForSelection(
  sequence: ReadonlyArray<BaseBlockType>,
  content: ContentState,
  selection: SelectionState,
  limitations: EditorFeatureLimitations,
): BaseBlockType | null {
  const fullBlockTypesAtSelection = getFullBlockTypesAtSelection(content, selection);

  return getNextAllowedBlockType(sequence, fullBlockTypesAtSelection, limitations, true, false);
}

export function getAllowedFallbackForTextBlock(
  blockType: BaseBlockType,
  limitations: EditorFeatureLimitations,
  isWithinTable?: boolean,
): BaseBlockType {
  if (!HeadingBlockTypeSequence.includes(blockType)) {
    return BlockType.Unstyled;
  }

  const isBlockFeatureAllowed = isWithinTable
    ? limitations.allowedBlocks.has(TopLevelBlockCategoryFeature.Tables)
    : limitations.allowedBlocks.has(TopLevelBlockCategoryFeature.Text);
  const allowedTextBlocks = isWithinTable
    ? limitations.allowedTableTextBlocks
    : limitations.allowedTextBlocks;
  if (!isBlockFeatureAllowed || allowedTextBlocks.has(TextBlockTypeFeature.Paragraph)) {
    return BlockType.Unstyled;
  }

  const fullBlockTypes = new Set<BlockType>([blockType]);
  const nextLevelDown = getNextAllowedBlockType(
    HeadingBlockTypeSequence,
    fullBlockTypes,
    limitations,
    false,
    false,
  );
  if (nextLevelDown) {
    return nextLevelDown;
  }
  const nextLevelUp = getNextAllowedBlockType(
    HeadingBlockTypeSequence,
    fullBlockTypes,
    limitations,
    false,
    true,
  );

  return nextLevelUp ?? BlockType.Unstyled;
}
