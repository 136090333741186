import { Icons } from '@kontent-ai/component-library/Icons';
import { InputState } from '@kontent-ai/component-library/Input';
import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { ISelectItem } from '@kontent-ai/component-library/Selects';
import { Stack } from '@kontent-ai/component-library/Stack';
import {
  BaseColor,
  Spacing,
  colorAlertBackgroundInverse,
  colorBackgroundDisabledComplementary,
} from '@kontent-ai/component-library/tokens';
import { alphabetically, createCompare } from '@kontent-ai/utils';
import React, { ComponentProps, ReactElement, useMemo } from 'react';
import { FieldPathByValue, FieldValues, useWatch } from 'react-hook-form';
import { DefaultTag } from '../../../../../../component-library/components/Tag/DefaultTag.tsx';
import { Tag } from '../../../../../../component-library/components/Tag/Tag.tsx';
import { ValidatedMultipleOptionSelect } from '../../../../../_shared/components/input/ValidatedMultipleOptionSelect.tsx';
import { HookFormProps } from '../../../../../_shared/types/hookFormProps.ts';
import {
  DataUiElement,
  DataUiInput,
  getDataUiElementAttribute,
  getDataUiInputAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IProjectContainerActiveUser } from '../../../../../data/models/projectContainerActiveUsers/ProjectContainerActiveUser.ts';

type Props<TFormShape extends FieldValues> = {
  readonly formProps: HookFormProps<TFormShape>;
  readonly name: FieldPathByValue<TFormShape, ReadonlyArray<string>>;
  readonly projectContainerActiveUsers: ReadonlyArray<IProjectContainerActiveUser>;
  readonly selectedUsersSelectorInputState: InputState;
};

interface ISharedWithUserOption extends ISelectItem<ISharedWithUserOption> {
  readonly background?: BaseColor;
  readonly isDeactivated?: boolean;
}

export const getUserName = (user: IProjectContainerActiveUser): string =>
  `${user.firstName.trim()} ${user.lastName.trim()}`;

export const DeactivatedUserLabel = 'Deactivated user';

const renderOption = (
  _id: Uuid,
  selectedItem: ISharedWithUserOption,
  defaultTagProps: ComponentProps<typeof DefaultTag>,
) => {
  if (selectedItem.isDeactivated && selectedItem.background) {
    return (
      <Tag {...defaultTagProps} background={selectedItem.background}>
        <Tag.Icon icon={Icons.ExclamationTriangleInverted} />
        <Tag.Label>{selectedItem.label}</Tag.Label>
      </Tag>
    );
  }

  if (selectedItem.background) {
    return (
      <Tag {...defaultTagProps} background={selectedItem.background} label={selectedItem.label} />
    );
  }

  return <DefaultTag {...defaultTagProps} label={selectedItem.label} />;
};

const SharedWithUsersSelectorComponent: React.FC = <TFormShape extends FieldValues>({
  formProps,
  name,
  projectContainerActiveUsers,
  selectedUsersSelectorInputState,
}: Props<TFormShape>): ReactElement => {
  const selectedUserIds = useWatch({ control: formProps.control, name }) as ReadonlyArray<string>;

  const projectManagerUserOptions: ReadonlyArray<ISharedWithUserOption> =
    projectContainerActiveUsers
      .filter((value) => value.isProjectManager)
      .map(
        (value): ISharedWithUserOption => ({
          background: colorBackgroundDisabledComplementary,
          id: value.userId,
          label: getUserName(value),
        }),
      );

  const allSelectedUserOptions: ReadonlyArray<ISharedWithUserOption> = useMemo(() => {
    const selectedUserOptions = projectContainerActiveUsers
      .filter((value) => !value.isProjectManager)
      .map(
        (value): ISharedWithUserOption => ({
          background: undefined,
          id: value.userId,
          label: getUserName(value),
        }),
      );

    const sortedSelectedUserOptions = selectedUserOptions.toSorted(
      createCompare({
        compare: alphabetically,
        select: (selectedUserOption: ISharedWithUserOption) => selectedUserOption.label,
      }),
    );

    const deactivatedSelectedUserIds = selectedUserIds.filter(
      (selectedUserId) =>
        !selectedUserOptions.some((selectedUserOption) => selectedUserOption.id === selectedUserId),
    );

    const deactivatedSelectedUserOptions = deactivatedSelectedUserIds.map(
      (value): ISharedWithUserOption => ({
        background: colorAlertBackgroundInverse,
        id: value,
        isDeactivated: true,
        label: DeactivatedUserLabel,
      }),
    );

    return [...sortedSelectedUserOptions, ...deactivatedSelectedUserOptions];
  }, [projectContainerActiveUsers, selectedUserIds]);

  return (
    <Stack spacing={Spacing.XL}>
      <MultiSelect<ISharedWithUserOption>
        label="Project managers who can manage this API key"
        inputState={InputState.Disabled}
        items={projectManagerUserOptions}
        permanentOptions={projectManagerUserOptions}
        renderPermanentOption={renderOption}
        tooltipText="Everyone with the Project manager role can view, use, and edit this API key."
        inputDataAttributes={getDataUiInputAttribute(
          DataUiInput.SharedWithUsersProjectManagersSelectorItems,
        )}
        {...getDataUiElementAttribute(DataUiElement.SharedWithUsersProjectManagersSelector)}
      />
      <ValidatedMultipleOptionSelect<TFormShape, ISharedWithUserOption>
        formProps={formProps}
        inputState={selectedUsersSelectorInputState}
        items={allSelectedUserOptions}
        label="Additional users who can view this API key"
        name={name}
        placeholder="Select users"
        renderSelectedOption={renderOption}
        tooltipText={
          selectedUsersSelectorInputState === InputState.Disabled
            ? 'This is a legacy API key. If you want to assign users to the API key, you need to regenerate the API key first.'
            : undefined
        }
        inputDataAttributes={getDataUiInputAttribute(DataUiInput.SharedWithUsersSelectorItems)}
        {...getDataUiElementAttribute(DataUiElement.SharedWithUsersSelector)}
      />
    </Stack>
  );
};

SharedWithUsersSelectorComponent.displayName = 'SharedWithUsersSelector';
type SharedWithUsersSelectorType = <TFormShape extends FieldValues>(
  props: Props<TFormShape>,
) => ReactElement;

export const SharedWithUsersSelector =
  SharedWithUsersSelectorComponent as SharedWithUsersSelectorType;
