import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Placement } from '@kontent-ai/component-library/types';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useId } from 'react';
import { OptionMode } from '../../models/optionMode.ts';
import { ObjectWithDataAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { IForwardedRefProps, forwardRef } from '../../utils/forwardedRefProps.tsx';
import { Toggle } from '../Toggle.tsx';

export const optionModePropType = PropTypes.oneOf(Object.values(OptionMode));

export enum OptionVariant {
  ContentItem = 'content-item',
  ContentType = 'content-type',
}

export enum BlockVariant {
  InlineBlock = 'inline-block',
  Block = 'block',
}

const isOptionTreeNode = (mode: OptionMode): boolean => mode === OptionMode.TreeMultiple;
const isOptionRadioButton = (mode: OptionMode): boolean =>
  mode === OptionMode.Single || mode === OptionMode.InvertedSingle;
const isOptionCheckBox = (mode: OptionMode): boolean =>
  mode === OptionMode.Multiple || mode === OptionMode.InvertedMultiple;
const isOptionInverted = (mode: OptionMode): boolean =>
  mode === OptionMode.InvertedSingle || mode === OptionMode.InvertedMultiple;

interface IOptionStyleOptions {
  readonly extraClasses: string;
  readonly isDisabled: boolean;
  readonly isFullSize: boolean;
  readonly isSelected: boolean;
  readonly mode: OptionMode;
  readonly variant?: OptionVariant;
}

const getTreeOptionStyles = ({ isSelected, isDisabled, extraClasses }: IOptionStyleOptions) =>
  classNames(extraClasses, 'tree__name tree__name--is-selectable', {
    'tree__name--is-selected': isSelected,
    'tree__name--is-disabled': isDisabled,
  });

const getRegularOptionStyles = ({
  extraClasses,
  isDisabled,
  isFullSize,
  isSelected,
  mode,
  variant,
}: IOptionStyleOptions) =>
  classNames(extraClasses, 'option', {
    'option--content-type': variant && variant === OptionVariant.ContentType,
    'option--content-item': variant && variant === OptionVariant.ContentItem,
    'option--with-alt-color-scheme': isOptionInverted(mode),
    'option--is-radio-button': isOptionRadioButton(mode),
    'option--is-checkbox': isOptionCheckBox(mode),
    'option--is-selected': isSelected,
    'option--is-disabled': isDisabled,
    'option--is-fullsize': isFullSize,
  });

const getOptionStyles = (styleOptions: IOptionStyleOptions) =>
  isOptionTreeNode(styleOptions.mode)
    ? getTreeOptionStyles(styleOptions)
    : getRegularOptionStyles(styleOptions);

interface IOptionDataProps<TDataUiActionType> {
  readonly accessibleLabel?: string;
  readonly autoFocus?: boolean;
  readonly className?: string;
  readonly dataId?: string;
  readonly dataUiAttribute?: TDataUiActionType;
  readonly disabled?: boolean;
  readonly isSelected?: boolean;
  readonly label?: React.ReactNode;
  readonly mode: OptionMode;
  readonly showFullSize?: boolean;
  readonly variant?: OptionVariant;
  readonly blockVariant?: BlockVariant;
  readonly tooltipText?: string;
  readonly tooltipPlacement?: Placement;
}

interface IOptionCallbacksProps {
  readonly onFocus?: React.FocusEventHandler<HTMLInputElement>;
  readonly onBlur?: React.FocusEventHandler<HTMLInputElement>;
  readonly onOptionSelected?: (isOptionSelected: boolean, isShiftPressed: boolean) => void;
}

export type OptionProps<TDataUiActionType = undefined> = IOptionDataProps<TDataUiActionType> &
  IOptionCallbacksProps;

export const createOptionComponent = <TDataUiActionType extends string = never>(
  getDataUiAttribute?: (attribute: TDataUiActionType) => ObjectWithDataAttribute,
) => {
  type CompleteOptionProps = OptionProps<TDataUiActionType> & IForwardedRefProps<HTMLDivElement>;

  const Option: React.FC<CompleteOptionProps> = ({
    accessibleLabel,
    autoFocus,
    className,
    dataId,
    dataUiAttribute,
    disabled,
    isSelected,
    label,
    mode,
    onBlur,
    onFocus,
    onOptionSelected,
    showFullSize,
    variant,
    forwardedRef,
    blockVariant,
    tooltipText,
    tooltipPlacement = 'top',
  }) => {
    const toggleId = useId();
    const isRadioButton = isOptionRadioButton(mode);
    const isTreeNode = isOptionTreeNode(mode);

    const selectOption = useCallback(
      (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLInputElement>): void => {
        onOptionSelected?.(!isSelected, event.shiftKey);
      },
      [isSelected, onOptionSelected],
    );

    const renderToggle = () => {
      const styleOptions: IOptionStyleOptions = {
        extraClasses: className || '',
        isDisabled: !!disabled,
        isFullSize: !!showFullSize,
        isSelected: !!isSelected,
        mode,
        variant,
      };

      return (
        <Toggle
          id={toggleId}
          accessibleLabel={accessibleLabel}
          autoFocus={autoFocus}
          checked={!!isSelected}
          dataId={dataId}
          disabled={disabled}
          label={label}
          labelClassName={getOptionStyles(styleOptions)}
          name={isRadioButton ? toggleId : undefined}
          spanClassName={isTreeNode ? undefined : 'option__label'}
          type={isRadioButton ? 'radio' : 'checkbox'}
          onBlur={onBlur}
          onToggle={selectOption}
          onFocus={onFocus}
          {...(getDataUiAttribute && dataUiAttribute && getDataUiAttribute(dataUiAttribute))}
        />
      );
    };

    /* It is important not to pass name property to checkbox
     * because it breaks tab navigation, radio-button requires
     * this property to be navigable via tab instead of arrows
     */
    return (
      <Tooltip tooltipText={tooltipText} placement={tooltipPlacement}>
        <div
          ref={forwardedRef}
          className={classNames('option__pane', {
            'option__pane--inlined': blockVariant === BlockVariant.InlineBlock,
          })}
        >
          {renderToggle()}
        </div>
      </Tooltip>
    );
  };

  Option.displayName = 'Option';

  Option.propTypes = {
    accessibleLabel: PropTypes.string,
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    dataId: PropTypes.string,
    disabled: PropTypes.bool,
    isSelected: PropTypes.bool,
    label: PropTypes.node,
    mode: optionModePropType.isRequired,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    showFullSize: PropTypes.bool,
    onOptionSelected: PropTypes.func,
    variant: PropTypes.oneOf(Object.values(OptionVariant)),
  };

  return forwardRef<HTMLDivElement, CompleteOptionProps>(Option);
};
