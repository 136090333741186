import { IBaseSelectItem } from '@kontent-ai/component-library/Selects';
import React, { useEffect, useState } from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getRelevantCollections } from '../../../../../_shared/selectors/getRelevantCollections.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { isAssetCollectionMandatoryForCurrentSubscriptionOrProject } from '../../../../environmentSettings/selectors/allowedFeaturesUtils.ts';
import { AssetsSelectCollectionDialog as AssetsSelectCollectionDialogComponent } from '../../components/AssetListing/AssetsSelectCollectionDialog.tsx';
import { notInCollectionSelectItem } from '../../constants/assetNullCollectionConstants.ts';
import { cannotCreateAssetsInCollection } from '../../constants/uiConstants.ts';
import { getCollectionItems } from '../../utils/getCollectionItems.ts';

type Props = {
  readonly handleClose: () => void;
  readonly handleSelect: (collectionId: Uuid | null) => void;
};

export const AssetsUploadToCollectionDialog: React.FC<Props> = (props) => {
  const isAssetCollectionMandatory = useSelector(
    isAssetCollectionMandatoryForCurrentSubscriptionOrProject,
  );

  const availableSelectItems = useSelector((s) =>
    getCollectionItems(
      getRelevantCollections(s, Capability.CreateAssets),
      !isAssetCollectionMandatory,
    ),
  );
  const disabledSelectItems = useSelector((s) =>
    getCollectionItems(getRelevantCollections(s, Capability.ViewAssets), false)
      .filter((item) => !availableSelectItems.some(({ id }) => item.id === id))
      .map((item) => ({ ...item, disabledTooltipText: cannotCreateAssetsInCollection })),
  );

  const collectionContext = useSelector((s) => s.contentApp.editedContentItem?.collectionId);

  const [selectedOption, setSelectedOption] = useState<IBaseSelectItem | null>(
    availableSelectItems.find((collection) => collection.id === collectionContext) ?? null,
  );

  const isOnlyOneOptionAvailable =
    isAssetCollectionMandatory &&
    availableSelectItems.length === 1 &&
    disabledSelectItems.length === 0;

  useEffect(() => {
    if (isOnlyOneOptionAvailable) {
      props.handleSelect(availableSelectItems[0]?.id ?? null);
      props.handleClose();
    }
  }, [availableSelectItems, isOnlyOneOptionAvailable, props]);

  return (
    !isOnlyOneOptionAvailable && (
      <AssetsSelectCollectionDialogComponent
        collectionSelectItems={[...availableSelectItems, ...disabledSelectItems]}
        disabledCollectionSelectItemIds={disabledSelectItems.map((item) => item.id)}
        disabledTooltipText={selectedOption ? undefined : 'Select a collection first.'}
        handleClose={props.handleClose}
        handleSelect={(option) =>
          props.handleSelect(option.id === notInCollectionSelectItem.id ? null : option.id)
        }
        handleSelectedOptionChange={setSelectedOption}
        headlineText="Upload assets"
        labelText="Select a collection for the new assets"
        primaryActionText="Upload"
        selectedOption={selectedOption}
      />
    )
  );
};

AssetsUploadToCollectionDialog.displayName = 'AssetsUploadToCollectionDialog';
