import { SimpleStatusWarning } from '@kontent-ai/component-library/SimpleStatus';
import { usePrevious } from '@kontent-ai/hooks';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { IconName } from '../../constants/iconEnumGenerated.ts';
import { SquareButton } from '../Button/SquareButton.tsx';
import { ButtonStyle } from '../Button/buttonStyle.ts';
import { SquareButtonSize } from '../Button/squareButtonSize.ts';

interface IPlayableImageDataProps {
  readonly imageClassName?: string;
  readonly playUrl?: string;
  readonly previewUrl: string;
}

interface IPlayableImageDispatchProps {
  readonly onPlayingStateChanged?: (isPlaying: boolean) => void;
}

type IPlayableImageProps = IPlayableImageDataProps & IPlayableImageDispatchProps;

const propTypes: PropTypeMap<IPlayableImageProps> = {
  imageClassName: PropTypes.string,
  onPlayingStateChanged: PropTypes.func,
  playUrl: PropTypes.string,
  previewUrl: PropTypes.string.isRequired,
};

export const PlayableImage: React.FC<IPlayableImageProps> = ({
  imageClassName,
  playUrl,
  previewUrl,
  onPlayingStateChanged,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const canPlay = !!playUrl && playUrl !== previewUrl;
  const isPaused = canPlay && !isPlaying;

  const imgSrc = canPlay && isPlaying ? playUrl : previewUrl;

  const previousIsPlaying = usePrevious(isPlaying);
  useEffect(() => {
    if (previousIsPlaying !== isPlaying) {
      onPlayingStateChanged?.(isPlaying);
    }
  }, [previousIsPlaying, isPlaying, onPlayingStateChanged]);

  const onClick = () => setIsPlaying((p) => !p);

  const imageRef = useRef<HTMLImageElement>(null);
  const [isMissingSize, setIsMissingSize] = useState(false);
  const ensureSize = () => {
    setIsMissingSize(imageRef.current?.clientHeight === 0);
  };

  return (
    <div
      className={classNames('playable-image', {
        'playable-image--can-play': canPlay,
      })}
      onClick={onClick}
    >
      {imgSrc && (
        <div>
          <img
            alt="Preview"
            className={classNames('playable-image__preview-image', imageClassName, {
              'playable-image__preview-image--is-missing-size': isMissingSize,
            })}
            src={imgSrc}
            onLoad={ensureSize}
            ref={imageRef}
          />
        </div>
      )}
      {!imgSrc && !canPlay && <SimpleStatusWarning label="No preview" />}
      {isPaused && (
        <div className="playable-image__preview-overlay">
          <div className="playable-image__preview-overlay-control">
            <SquareButton
              screenReaderText="Play"
              size={SquareButtonSize.L}
              style={ButtonStyle.Primary}
              iconName={IconName.TriangleRight}
            />
          </div>
        </div>
      )}
    </div>
  );
};

PlayableImage.displayName = 'PlayableImage';
PlayableImage.propTypes = propTypes;
