import { memoize } from '@kontent-ai/memoization';
import React, { useCallback, useEffect } from 'react';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { DefaultWorkflowId } from '../../../../_shared/constants/variantIdValues.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import {
  createNewWorkflowLink,
  createWorkflowEditingLink,
} from '../../../../_shared/utils/routing/projectSubscriptionRoutingUtils.ts';
import { Workflow } from '../../../../data/models/workflow/Workflow.ts';
import { getCurrentProject } from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { getWorkflowsSortedByName } from '../../../itemEditor/selectors/workflows/getWorkflowsSortedByName.ts';
import { areMultipleWorkflowsEnabledForCurrentProject } from '../../selectors/allowedFeaturesUtils.ts';
import { initWorkflowsListing, restoreWorkflow } from '../actions/thunkWorkflowActions.ts';
import { leaveWorkflowsListing } from '../actions/workflowActions.ts';
import {
  WorkflowItem,
  WorkflowsListing as WorkflowsListingComponent,
} from '../components/WorkflowsListing.tsx';

const getWorkflowsMemoized = memoize.maxOne(
  (
    workflows: ReadonlyMap<Uuid, Workflow>,
    usage: ReadonlyMap<Uuid, number>,
    projectId: Uuid,
    subscriptionId: Uuid | undefined,
  ): readonly WorkflowItem[] =>
    getWorkflowsSortedByName(workflows).map(
      (workflow: Workflow): WorkflowItem => ({
        id: workflow.id,
        name: workflow.name,
        isDefault: workflow.id === DefaultWorkflowId,
        itemsUsage: usage.get(workflow.id),
        workflowEditorPath: createWorkflowEditingLink({
          projectId,
          workflowId: workflow.id,
          subscriptionId,
        }),
      }),
    ),
);

const getWorkflows = (state: IStore, subscriptionId: Uuid | undefined): readonly WorkflowItem[] => {
  const { projectId } = getCurrentProject(state);

  return getWorkflowsMemoized(
    state.data.workflows.byId,
    state.data.workflows.usage,
    projectId,
    subscriptionId,
  );
};

type WorkflowsListingContainerProps = {
  readonly subscriptionId?: Uuid;
};

export const WorkflowsListingContainer: React.FC<WorkflowsListingContainerProps> = ({
  subscriptionId,
}) => {
  const dispatch = useDispatch();
  const loadingStatus = useSelector((s) => s.workflowsApp.listingUi.loadingStatus);
  const { projectId } = useSelector(getCurrentProject);
  const operationStatus = useSelector((s) => s.workflowsApp.listingUi.operationStatus);
  const workflows = useSelector((state) => getWorkflows(state, subscriptionId));
  const onRestoreWorkflow = useCallback(() => dispatch(restoreWorkflow()), []);
  const areMultipleWorkflowsEnabled = useSelector(areMultipleWorkflowsEnabledForCurrentProject);
  const createNewWorkflowPath = areMultipleWorkflowsEnabled
    ? createNewWorkflowLink({ projectId, subscriptionId })
    : undefined;

  useEffect(() => {
    dispatch(initWorkflowsListing());

    return () => {
      dispatch(leaveWorkflowsListing());
    };
  }, []);

  if (loadingStatus !== LoadingStatus.Loaded) {
    return <Loader />;
  }

  return (
    <WorkflowsListingComponent
      createNewWorkflowPath={createNewWorkflowPath}
      onRestoreWorkflow={onRestoreWorkflow}
      operationStatus={operationStatus}
      workflows={workflows}
    />
  );
};

WorkflowsListingContainer.displayName = 'WorkflowsListingContainer';
export { WorkflowsListingContainer as WorkflowsListing };
