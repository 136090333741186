import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Box } from '@kontent-ai/component-library/Box';
import { IconButton } from '@kontent-ai/component-library/Button';
import { Clipboard } from '@kontent-ai/component-library/Clipboard';
import { InputState, InputType } from '@kontent-ai/component-library/Input';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, Typography } from '@kontent-ai/component-library/tokens';
import { noOperation } from '@kontent-ai/utils';
import { EnterHotkeyDisabler } from '../../../../_shared/components/Hotkeys/EnterHotkeyDisabler.tsx';
import { documentationLinks } from '../../../../_shared/constants/documentationLinks.ts';
import { HookFormProps } from '../../../../_shared/types/hookFormProps.ts';
import {
  DataUiAction,
  DataUiInput,
  getDataUiActionAttribute,
  getDataUiInputAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IEntityWebhookFormShape } from '../../models/IEntityWebhookFormShape.type.ts';

type EntityWebhookSecretProps = {
  readonly enabled: boolean;
  readonly errorMessage: string;
  readonly formProps: HookFormProps<IEntityWebhookFormShape>;
  readonly informationMessage: string;
  readonly onGenerateNewSecret: () => void;
  readonly secretValue: string | undefined;
};

export const EntityWebhookSecret = ({
  enabled,
  errorMessage,
  informationMessage,
  onGenerateNewSecret,
  secretValue,
}: EntityWebhookSecretProps) => {
  const inputState = errorMessage ? InputState.Alert : InputState.ReadOnly;
  const buttonState = enabled ? 'default' : 'disabled';
  const regenerationButton = (
    <IconButton
      buttonState={buttonState}
      buttonStyle="tertiary"
      iconName="RotateDoubleRight"
      onClick={onGenerateNewSecret}
      size="medium"
      tooltipPlacement="top-start"
      tooltipText="Generate new secret"
      {...getDataUiActionAttribute(DataUiAction.Regenerate)}
    />
  );

  return (
    <Stack spacing={Spacing.L}>
      <Box typography={Typography.HeadlineMedium}>Secret</Box>
      <Box typography={Typography.SubheadlineLarge}>
        Use the secret to{' '}
        <OutwardLink href={documentationLinks.validateReceivedNotifications}>
          verify the authenticity of notifications
        </OutwardLink>
        .
      </Box>
      <Box width="100%">
        <Row alignX="start" spacingX={Spacing.L}>
          <Column width="1/2">
            <EnterHotkeyDisabler>
              <Clipboard
                auxiliaryElement={regenerationButton}
                caption={errorMessage}
                inputState={inputState}
                onInputChange={noOperation}
                overlayMessage={informationMessage}
                type={InputType.Password}
                value={secretValue || ''}
                {...getDataUiInputAttribute(DataUiInput.Secret)}
              />
            </EnterHotkeyDisabler>
          </Column>
        </Row>
      </Box>
    </Stack>
  );
};

EntityWebhookSecret.displayName = 'EntityWebhookSecret';
