import PropTypes, { ValidationMap } from 'prop-types';
import { Validator } from 'react';
import { NumberValue, createNumberValue } from '../../../../_shared/models/NumberValue.ts';
import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  CompiledContentItemElementDataValidationMap,
  ICompiledContentItemElementData,
} from './ICompiledContentItemElement.ts';

interface INumberItemElementValue {
  readonly _number: NumberValue;
  readonly value: string;
}

export interface INumberItemElement
  extends INumberItemElementValue,
    ICompiledContentItemElementData {}

export const EmptyNumberItemElementValue: INumberItemElementValue = {
  _number: createNumberValue(),
  value: '',
};

export const EmptyNumberItemElement: INumberItemElement = {
  elementId: '',
  lastModifiedAt: null,
  lastModifiedBy: null,
  type: ElementType.Number,
  ...EmptyNumberItemElementValue,
};

export const NumberItemElementPropTypes = PropTypes.exact<ValidationMap<INumberItemElement>>({
  ...CompiledContentItemElementDataValidationMap,
  _number: PropTypes.object.isRequired as Validator<NumberValue>,
  value: PropTypes.string.isRequired,
});

export const newNumberItemElement = (params: Partial<INumberItemElement>): INumberItemElement => ({
  ...EmptyNumberItemElement,
  ...params,
});
