import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { ObjectWithDataAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { DropDownOptionList } from './DropDownOptionList.tsx';
import {
  CommonDropDownPositionerProps,
  DropDownPositioner,
  ExtraDropDownPositionerProps,
  commonDropDownPositionerPropTypes,
  extraDropDownPositionerPropTypes,
} from './DropDownPositioner.tsx';

export type CommonDropDownProps = CommonDropDownPositionerProps & {
  readonly hasTrailingElements?: boolean;
  readonly longer?: boolean;
  readonly optionListClassName?: string;
  readonly optionListDataUiAttributes: ObjectWithDataAttribute;
};

export const commonDropDownPropTypes: PropTypesShape<CommonDropDownProps> = {
  ...commonDropDownPositionerPropTypes,
  hasTrailingElements: PropTypes.bool,
  longer: PropTypes.bool,
  optionListClassName: PropTypes.string,
  optionListDataUiAttributes: PropTypes.object.isRequired,
};

type DropDownFrameProps = CommonDropDownProps & ExtraDropDownPositionerProps;

const dropdownFramePropTypes: PropTypesShape<DropDownFrameProps> = {
  ...commonDropDownPropTypes,
  ...extraDropDownPositionerPropTypes,
};

export const DropDownFrame = forwardRef<HTMLElement, DropDownFrameProps>(
  (
    {
      hasTrailingElements,
      longer,
      optionListClassName,
      optionListDataUiAttributes,
      renderContent,
      ...restProps
    },
    ref,
  ) => (
    <DropDownPositioner
      {...restProps}
      renderContent={() => (
        <DropDownOptionList
          ref={ref}
          className={optionListClassName}
          hasTrailingElements={hasTrailingElements}
          longer={longer}
          optionListDataUiAttributes={optionListDataUiAttributes}
        >
          {renderContent()}
        </DropDownOptionList>
      )}
    />
  ),
);

DropDownFrame.displayName = 'DropDownFrame';
DropDownFrame.propTypes = dropdownFramePropTypes;
