import PropTypes, { Validator } from 'prop-types';
import React from 'react';
import { urlStartsWithHttps } from '../../../../_shared/utils/urlUtils.ts';
import { AccessDeniedToContentItemAction } from '../../../errorHandling/constants/AccessDeniedToContentItemAction.ts';
import { AccessDeniedToContentItem } from '../../../errorHandling/containers/AccessDeniedToContentItem.tsx';
import { PreviewError, PreviewUrlInfo } from '../../../itemEditor/utils/previewUtils.ts';
import { PreviewContentItemInfo } from '../../../itemEditor/utils/selectDeepestItemWithPreview.ts';
import { WebSpotlightPreviewEmptyState } from './WebSpotlightPreviewEmptyState.tsx';
import { WebSpotlightPreviewError } from './WebSpotlightPreviewError.tsx';
import { WebSpotlightPreviewSandbox } from './WebSpotlightPreviewSandbox.tsx';

const sharedPreviewUrlExcludedErrors: ReadonlyArray<PreviewError> = [
  PreviewError.MissingUrlSlugValue,
  PreviewError.MissingUrlSlugElement,
];

interface IWebSpotlightPreviewContentProps {
  readonly previewContentItemInfo: PreviewContentItemInfo | null;
  readonly previewUrlInfo: PreviewUrlInfo;
  readonly sharedPreviewUrl: string | null;
}

const propTypes: PropTypeMap<IWebSpotlightPreviewContentProps> = {
  previewContentItemInfo: PropTypes.object as Validator<PreviewContentItemInfo>,
  previewUrlInfo: PropTypes.object.isRequired as Validator<PreviewUrlInfo>,
  sharedPreviewUrl: PropTypes.string,
};

export const WebSpotlightPreviewContent: React.FC<IWebSpotlightPreviewContentProps> = ({
  previewContentItemInfo,
  previewUrlInfo,
  sharedPreviewUrl,
}) => {
  if (previewUrlInfo.error) {
    if (previewUrlInfo.error === PreviewError.AccessDenied) {
      return <AccessDeniedToContentItem requestedAction={AccessDeniedToContentItemAction.View} />;
    }

    if (!sharedPreviewUrl || !sharedPreviewUrlExcludedErrors.includes(previewUrlInfo.error)) {
      return (
        <WebSpotlightPreviewEmptyState>
          {previewUrlInfo.error !== PreviewError.NoPreview && previewContentItemInfo && (
            <WebSpotlightPreviewError
              error={previewUrlInfo.error}
              previewContentItemInfo={previewContentItemInfo}
            />
          )}
        </WebSpotlightPreviewEmptyState>
      );
    }
  }

  if ((!sharedPreviewUrl && !previewUrlInfo.url) || !previewContentItemInfo) {
    return <WebSpotlightPreviewEmptyState />;
  }

  const selectedPreviewUrlStartsWithHttps = sharedPreviewUrl
    ? urlStartsWithHttps(sharedPreviewUrl)
    : previewUrlInfo.url && urlStartsWithHttps(previewUrlInfo.url);

  if (urlStartsWithHttps(document.location.href) && !selectedPreviewUrlStartsWithHttps) {
    return (
      <WebSpotlightPreviewEmptyState hideDocumentationLink={!!sharedPreviewUrl}>
        <WebSpotlightPreviewError
          error={PreviewError.NotHttps}
          previewContentItemInfo={previewContentItemInfo}
        />
      </WebSpotlightPreviewEmptyState>
    );
  }

  return <WebSpotlightPreviewSandbox />;
};

WebSpotlightPreviewContent.displayName = 'WebSpotlightPreviewContent';
WebSpotlightPreviewContent.propTypes = propTypes;
