import Immutable from 'immutable';
import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { IAssetRendition } from '../../../../../../data/models/assetRenditions/AssetRendition.ts';
import { IAsset } from '../../../../../../data/models/assets/Asset.ts';
import {
  DefaultValueDoesNotMeetLimitations,
  DeletedAssetsInDefaultValue,
  ProvideAPositiveNumberForLimit,
  ProvideValidSizeLimit,
} from '../../../constants/errorMessageTemplates.ts';
import { IAssetTypeElementData } from '../../../models/elements/AssetTypeElementData.ts';
import { AssetTypeElementValidationResult } from '../../../utils/typeElementValidators/types/AssetTypeElementValidationResult.type.ts';
import { ITypeElementValidatorDependencies } from '../../../utils/typeElementValidators/types/ITypeElementValidatorDependencies.type.ts';
import { areLimitationsInAssetDefaultValueMet } from '../../../utils/typeElementValidators/utils/areLimitationsInAssetDefaultValueMet.ts';
import { createErrorMessagesFromConditionValidationResult } from '../../../utils/typeElementValidators/utils/conditions/createErrorMessagesFromConditionValidationResult.ts';
import {
  ConditionValidationData,
  getConditionValidationResult,
} from '../../../utils/typeElementValidators/utils/conditions/getConditionValidationResult.ts';

function getAssetTypeElementValidationResult(
  { typeElementValidationUtils }: ITypeElementValidatorDependencies,
  typeElement: IAssetTypeElementData,
  assetsById: Immutable.Map<Uuid, IAsset>,
  assetRenditionsById: ReadonlyMap<Uuid, IAssetRendition>,
  conditionValidationData: ConditionValidationData,
): AssetTypeElementValidationResult {
  const {
    getNameValidationResult,
    getGuidelinesValidationResult,
    isLimitValueValid,
    isFileSizeValid,
  } = typeElementValidationUtils;

  const nameValidationResult = getNameValidationResult(typeElement);
  const guidelinesValidationResult = getGuidelinesValidationResult(typeElement);

  const minItems = typeElement.minItems;
  const maxItems = typeElement.maxItems;
  const isNumberOfItemsValid = isLimitValueValid(minItems) && isLimitValueValid(maxItems);

  const isWidthValid =
    isLimitValueValid(typeElement.minWidth) && isLimitValueValid(typeElement.maxWidth);
  const isHeightValid =
    isLimitValueValid(typeElement.minHeight) && isLimitValueValid(typeElement.maxHeight);

  const fileSize = typeElement.fileSize;
  const isFileSizeValueValid = isFileSizeValid(fileSize);

  const defaultValueMeetsLimitations = areLimitationsInAssetDefaultValueMet(
    typeElement,
    assetsById,
    assetRenditionsById,
  );

  const warnings: Array<string> = [];
  const errors: Array<string> = [];

  if (!isFileSizeValueValid) {
    errors.push(ProvideValidSizeLimit);
  }

  if (!isNumberOfItemsValid) {
    errors.push(ProvideAPositiveNumberForLimit(' for the number of assets'));
  }

  if (!nameValidationResult.isValid && nameValidationResult.errorMessage) {
    errors.push(nameValidationResult.errorMessage);
  }

  if (!guidelinesValidationResult.isValid && guidelinesValidationResult.errorMessage) {
    errors.push(guidelinesValidationResult.errorMessage);
  }
  if (!isWidthValid) {
    errors.push(ProvideAPositiveNumberForLimit(' for the image width'));
  }
  if (!isHeightValid) {
    errors.push(ProvideAPositiveNumberForLimit(' for the image height'));
  }

  const defaultValueContainsDeletedAsset = typeElement.defaultValue.some(
    (defaultAsset) => assetsById.get(defaultAsset.id)?.archived,
  );
  if (defaultValueContainsDeletedAsset) {
    errors.push(DeletedAssetsInDefaultValue);
  }

  if (!defaultValueMeetsLimitations) {
    warnings.push(DefaultValueDoesNotMeetLimitations);
  }

  const conditionValidationResult = getConditionValidationResult(
    typeElement,
    conditionValidationData,
  );

  return {
    conditionValidationResult,
    isNameValid: nameValidationResult.isValid,
    isGuidelinesValid: guidelinesValidationResult.isValid,
    isFileSizeValid: isFileSizeValueValid,
    isNumberOfItemsValid,
    errorMessages: [
      ...createErrorMessagesFromConditionValidationResult(conditionValidationResult),
      ...errors,
    ],
    warningMessages: warnings,
    isHeightValid,
    isWidthValid,
  };
}

export const createValidateAssetTypeElementAction =
  (deps: ITypeElementValidatorDependencies) =>
  (typeElement: IAssetTypeElementData): ThunkFunction =>
  (dispatch, getState) => {
    const {
      data: { assetRenditions, assets },
    } = getState();
    const validationResult = getAssetTypeElementValidationResult(
      deps,
      typeElement,
      assets.byId,
      assetRenditions.byId,
      deps.getConditionValidationData(getState),
    );

    dispatch(deps.typeElementValidated(typeElement.elementId, validationResult));
  };
