import {
  BorderRadius,
  BoxShadow,
  Spacing,
  colorAlertBackgroundInverse,
  colorBackground,
  colorInfoBackground,
  colorInfoBackgroundInverse,
  colorSuccessBackgroundInverse,
  colorTextDefault,
  colorTextDefaultInverse,
  colorWarningBackground,
  colorWarningBackgroundInverse,
  px,
} from '@kontent-ai/component-library/tokens';
import { UnreachableCaseException } from '@kontent-ai/errors';
import styled, { css } from 'styled-components';
import { NotificationBarType } from '../notificationBarType.ts';

interface IStyledNotificationBarWrapperProps {
  readonly $type: NotificationBarType;
  readonly floating?: boolean;
}

const getStylesByType = ({ $type }: IStyledNotificationBarWrapperProps) => {
  switch ($type) {
    case 'Alert':
      return css`
        background-color: ${colorAlertBackgroundInverse}; 
        color: ${colorTextDefaultInverse};
      `;
    case 'Info':
      return css`
        background-color: ${colorInfoBackground};
        color: ${colorTextDefault};
      `;
    case 'InfoEmphasis':
      return css`
        background-color: ${colorInfoBackgroundInverse}; 
        color: ${colorTextDefaultInverse};
      `;
    case 'Success':
      return css`
        background-color: ${colorSuccessBackgroundInverse}; 
        color: ${colorTextDefaultInverse};
      `;
    case 'Warning':
      return css`
        background-color: ${colorWarningBackgroundInverse}; 
        color: ${colorTextDefault};
      `;
    case 'FriendlyWarning':
      return css`
        background-color: ${colorWarningBackground};
        color: ${colorTextDefault};
      `;
    case 'Blank':
      return css`
        background-color: ${colorBackground};
        color: ${colorTextDefault};
      `;
    default:
      throw UnreachableCaseException($type);
  }
};

export const StyledNotificationBarWrapper = styled.div<IStyledNotificationBarWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: ${px(Spacing.XXXXL)};
  min-width: min-content;

  border-radius: ${px(BorderRadius.M)};
  box-shadow: ${({ floating }) => (floating ? BoxShadow.M : BoxShadow.S)};
  ${getStylesByType};
`;
