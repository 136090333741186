import { Box } from '@kontent-ai/component-library/Box';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Spacing } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import PropTypes, { Validator } from 'prop-types';
import React, { ReactNode } from 'react';
import { FallbacksStatus } from '../../../../../_shared/components/FallbacksForLinkedContent/FallbacksStatus.tsx';
import { FallbacksStatusCompact } from '../../../../../_shared/components/FallbacksForLinkedContent/FallbacksStatusCompact.tsx';
import { NotTranslatedBadge } from '../../../../../_shared/components/Workflow/NotTranslatedBadge.tsx';
import { NotTranslatedTagForVariant } from '../../../../../_shared/components/Workflow/NotTranslatedTagForVariant.tsx';
import { DeletedLinkedItemContent } from '../../../../../_shared/components/linkedItems/DeletedLinkedItemContent.tsx';
import { NonexistentLinkedItemContent } from '../../../../../_shared/components/linkedItems/NonexistentLinkedItemContent.tsx';
import { NotTranslatedOrFallbacksStatus } from '../../../../../_shared/containers/FallbacksForLinkedContent/NotTranslatedOrFallbacksStatus.tsx';
import { WorkflowStatusTagForVariant } from '../../../../../_shared/containers/Workflow/WorkflowStatusTagForVariant.tsx';
import { LoadedLinkedItemContent } from '../../../../../_shared/containers/linkedItems/LoadedLinkedItemContent.tsx';
import {
  AnimatedChevron,
  RestDirection,
} from '../../../../../_shared/uiComponents/AnimatedChevron/AnimatedChevron.tsx';
import { SquareButtonShell } from '../../../../../_shared/uiComponents/Button/SquareButtonShell.tsx';
import { ButtonStyle } from '../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import { SquareButtonSize } from '../../../../../_shared/uiComponents/Button/squareButtonSize.ts';
import { WorkflowBadges } from '../../../../../_shared/uiComponents/WorkflowBadges/WorkflowBadges.tsx';
import {
  VariantCompletionStatus,
  getContentVariantState,
} from '../../../../../_shared/utils/contentItemVariantUtils.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IListingContentItem } from '../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { ContentItemEditorReachedThresholds } from '../../../types/ContentItemEditorReachedThresholds.type.ts';

const getToggleExpandTooltipText = (
  allowExpand: boolean | null,
  isExpanded: boolean | undefined,
): string | undefined => {
  if (!allowExpand) {
    return undefined;
  }

  return isExpanded ? 'Collapse' : 'Expand';
};

type LinkedItemContentProps = {
  readonly cannotViewDisabledMessage: string | undefined;
  readonly contentItem?: IListingContentItem;
  readonly contentType?: IContentType;
  readonly doNotRenderToggle?: boolean;
  readonly isExpanded?: boolean;
  readonly isInvalid?: boolean;
  readonly isPage?: boolean;
  readonly onToggleExpand?: () => void;
  readonly reachedThresholds: ContentItemEditorReachedThresholds;
};

const linkedItemContentPropTypes: PropTypeMap<LinkedItemContentProps> = {
  cannotViewDisabledMessage: PropTypes.string,
  contentItem: PropTypes.object as Validator<IListingContentItem>,
  contentType: PropTypes.object as Validator<IContentType>,
  doNotRenderToggle: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isPage: PropTypes.bool,
  onToggleExpand: PropTypes.func,
  reachedThresholds: PropTypes.object.isRequired as Validator<ContentItemEditorReachedThresholds>,
};

export const LinkedItemContent: React.FC<LinkedItemContentProps> = ({
  cannotViewDisabledMessage,
  contentItem,
  contentType,
  doNotRenderToggle,
  isExpanded,
  isInvalid,
  isPage,
  onToggleExpand,
  reachedThresholds,
}) => {
  if (!contentItem) {
    return (
      <NonexistentLinkedItemContent
        className="bar-item__title-pane bar-item__title-pane--missing"
        message={`This ${isPage ? 'page' : 'linked item'} doesn’t exist`}
      />
    );
  }
  if (!contentType) {
    return (
      <NonexistentLinkedItemContent
        className="bar-item__title-pane bar-item__title-pane--missing"
        message={`Content type of this ${isPage ? 'page' : 'linked item'} doesn’t exist`}
      />
    );
  }
  if (contentItem.item.archived) {
    return (
      <DeletedLinkedItemContent
        cannotViewDisabledMessage={cannotViewDisabledMessage}
        name={contentItem.item.name}
        isCompact={reachedThresholds.sizeXS}
        showExpandActionPlaceholder={!doNotRenderToggle}
      />
    );
  }

  const variantData = contentItem.variant;
  const itemData = contentItem.item;
  const workflowStatus =
    variantData && !variantData.isArchived && variantData.assignment.workflowStatus;
  const publishingState = variantData && !variantData.isArchived && variantData.publishingState;
  const scheduledToPublishAt = variantData?.assignment.scheduledToPublishAt || null;
  const scheduledToUnpublishAt = variantData?.assignment.scheduledToUnpublishAt || null;

  const displayDescription = reachedThresholds.sizeL || reachedThresholds.sizeM;
  const allowExpand =
    !doNotRenderToggle && !!onToggleExpand && variantData && !variantData.isArchived;

  const toggleExpandTooltipText = getToggleExpandTooltipText(allowExpand, isExpanded);
  const isUnfinished = getContentVariantState(variantData) === VariantCompletionStatus.unfinished;

  const showNotTranslatedAsWFStatusTag = !workflowStatus && !publishingState;

  const renderWorkflowStep = (): ReactNode =>
    reachedThresholds.sizeL ? (
      <div className="bar-item__tags-pane bar-item__tags-pane--in-modular-item">
        {showNotTranslatedAsWFStatusTag ? (
          <div className="bar-item__tag">
            <NotTranslatedOrFallbacksStatus
              itemId={itemData.id}
              renderFallbacksStatus={(itemId) => <FallbacksStatus itemId={itemId} />}
              renderNotTranslatedStatus={() => <NotTranslatedTagForVariant />}
            />
          </div>
        ) : (
          workflowStatus &&
          publishingState && (
            <div className="bar-item__tag">
              <WorkflowStatusTagForVariant
                workflowStatus={workflowStatus}
                publishingState={publishingState}
                scheduledToPublishAt={scheduledToPublishAt}
                scheduledToUnpublishAt={scheduledToUnpublishAt}
              />
            </div>
          )
        )}
      </div>
    ) : (
      <div
        className={classNames('bar-item__badges-pane', {
          'bar-item__badges-pane--size-s': reachedThresholds.sizeS,
          'bar-item__badges-pane--size-xs': reachedThresholds.sizeXS,
        })}
      >
        <Box paddingLeft={reachedThresholds.sizeXS ? Spacing.XS : undefined}>
          <WorkflowBadges
            item={contentItem}
            renderNotTranslatedBadge={() => (
              <NotTranslatedOrFallbacksStatus
                itemId={itemData.id}
                renderFallbacksStatus={(itemId) => <FallbacksStatusCompact itemId={itemId} />}
                renderNotTranslatedStatus={() => <NotTranslatedBadge />}
              />
            )}
          />
        </Box>
      </div>
    );

  const renderExpandToggle = (): ReactNode => (
    <>
      {!doNotRenderToggle && (
        <Tooltip tooltipText={toggleExpandTooltipText} placement="bottom-start">
          <div
            className={classNames('bar-item__action', 'bar-item__action--no-shrink', {
              'bar-item__action--is-hidden': !allowExpand,
              'bar-item__action--size-xs': reachedThresholds.sizeXS,
            })}
            {...getDataUiActionAttribute(isExpanded ? DataUiAction.Collapse : DataUiAction.Expand)}
          >
            {allowExpand && (
              <SquareButtonShell
                screenReaderText={isExpanded ? 'Collapse' : 'Expand'}
                style={ButtonStyle.Quinary}
                size={SquareButtonSize.Quinary}
              >
                <AnimatedChevron
                  isTurned={isExpanded || false}
                  restDirection={RestDirection.Down}
                />
              </SquareButtonShell>
            )}
          </div>
        </Tooltip>
      )}
    </>
  );

  return (
    <LoadedLinkedItemContent
      cannotViewDisabledMessage={cannotViewDisabledMessage}
      collectionId={contentItem.item.collectionId}
      contentItemName={contentItem.item.name}
      contentTypeName={contentType.name}
      hideDescription={!displayDescription}
      isCompact={reachedThresholds.sizeXS}
      isInvalid={isInvalid}
      isUnfinished={isUnfinished}
      onClick={allowExpand ? onToggleExpand : undefined}
      renderExpandToggle={renderExpandToggle}
      renderWorkflowStep={renderWorkflowStep}
    />
  );
};

LinkedItemContent.displayName = 'LinkedItemContent';
LinkedItemContent.propTypes = linkedItemContentPropTypes;
