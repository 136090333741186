import { setUser as setSentryUser } from '@sentry/react';
import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import {
  loadGeneralProperties,
  loadUserProperties,
} from '../../_shared/actions/thunkSharedActions.ts';
import { InitializerLoader } from '../../_shared/components/InitializerLoader.tsx';
import { useAuthToken } from '../../_shared/contexts/AuthTokenProvider.tsx';
import { useDispatch } from '../../_shared/hooks/useDispatch.ts';
import { useHandleEmailVerification } from '../../_shared/hooks/useHandleEmailVerification.ts';
import { useOneTimeAction } from '../../_shared/hooks/useOneTimeAction.ts';
import {
  loadAdministratedSubscriptions,
  loadUserInfo,
  loadUserProjectsInfo,
} from '../../data/actions/thunkDataActions.ts';
import { bootstrap } from '../actions/thunkInitializationActions.ts';
import { enableAccountTracking } from '../actions/thunks/enableAccountTracking.ts';
import { logIntroductionUserEvents } from '../actions/thunks/logIntroductionUserEvents.ts';

type Props = {
  readonly children: ReactNode;
};

export const Initializer = (props: Props) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { getAuthToken } = useAuthToken();

  const [isBootstrapDone] = useOneTimeAction(() => dispatch(bootstrap(getAuthToken)));
  const isVerified = useHandleEmailVerification(getAuthToken, isBootstrapDone);

  const initializationActions = async () => {
    dispatch(logIntroductionUserEvents(location));
    const userInfo = await dispatch(loadUserInfo());

    setSentryUser({
      id: userInfo.userId,
      email: userInfo.email,
    });

    await Promise.all([
      dispatch(loadUserProperties(userInfo.userId)),
      dispatch(loadUserProjectsInfo()),
      dispatch(loadAdministratedSubscriptions()),
      dispatch(loadGeneralProperties()),
    ]);
  };
  const [areInitializationActionsDone] = useOneTimeAction(initializationActions, isVerified);

  useOneTimeAction(() => dispatch(enableAccountTracking()), areInitializationActionsDone);

  return areInitializationActionsDone ? props.children : <InitializerLoader />;
};
