import { InputState } from '@kontent-ai/component-library/Input';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';
import { DependantCodenameEditor } from '../../../../../_shared/components/input/DependantCodenameEditor.tsx';
import { ValidatedInput } from '../../../../../_shared/components/input/ValidatedInput.tsx';
import { ValidationConstants } from '../../../../../_shared/constants/validationConstants.ts';
import { HookFormProps, hookFormProps } from '../../../../../_shared/types/hookFormProps.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { LanguageFallbackOption } from '../../models/LanguageFallbackOption.type.ts';
import { LanguageFormShape } from '../../models/languageFormShape.type.ts';
import { FallbackLanguageDropdown } from './FallbackLanguageDropdown.tsx';

type ILanguageFormFieldsProps = {
  readonly disabled?: boolean;
  readonly fallbackLanguageOptions: ReadonlyArray<LanguageFallbackOption>;
  readonly formProps: HookFormProps<LanguageFormShape>;
  readonly initialCodename: string;
};

const propTypes: PropTypesShape<ILanguageFormFieldsProps> = {
  disabled: PropTypes.bool,
  fallbackLanguageOptions: PropTypes.array.isRequired,
  formProps: hookFormProps.isRequired,
  initialCodename: PropTypes.string.isRequired,
};

export const LanguageFormFields: React.FC<ILanguageFormFieldsProps> = ({
  disabled,
  fallbackLanguageOptions,
  formProps,
  initialCodename,
}) => (
  <div
    className="bar-item__expansion"
    {...getDataUiElementAttribute(DataUiElement.BarItemLanguageFields)}
  >
    <ValidatedInput
      autoFocus
      inputState={disabled ? InputState.Disabled : InputState.Default}
      label="Language name"
      name="name"
      maxLength={ValidationConstants.LanguageNameMaxLength}
      formProps={formProps}
    />
    <div className="language-settings-form-fields">
      <div className="language-settings-form-group">
        <div className="form__group form__group--medium-width">
          <Controller
            control={formProps.control}
            name="fallbackLanguageId"
            render={({ field }) => (
              <FallbackLanguageDropdown
                ref={field.ref}
                disabled={!!disabled}
                fallbackLanguageOptions={fallbackLanguageOptions}
                onChange={field.onChange}
                value={field.value}
              />
            )}
          />
        </div>
      </div>
      <div className="language-settings-form-group">
        <DependantCodenameEditor<LanguageFormShape>
          dependantFieldName="name"
          formProps={formProps}
          initialValue={initialCodename}
          name="codename"
          readOnly={disabled}
        />
      </div>
    </div>
  </div>
);

LanguageFormFields.displayName = 'LanguageFormFields';
LanguageFormFields.propTypes = propTypes;
