import { getAbsoluteTopOffset, getScrollParent } from '@kontent-ai/DOM';
import PropTypes from 'prop-types';
import React from 'react';
import { waitUntilFocusAndScrollAreNotDeferred } from '../../../../../_shared/utils/autoScrollUtils.ts';

interface IDeferredAutoFocusProps {
  readonly autoFocus?: boolean;
  readonly onAutoFocus: () => void;
  readonly scrollToComponentAfterAutoFocus?: boolean;
}

export class DeferredAutoFocus extends React.PureComponent<IDeferredAutoFocusProps> {
  static displayName = 'DeferredAutoFocus';

  static propTypes: PropTypesShape<IDeferredAutoFocusProps> = {
    autoFocus: PropTypes.bool,
    onAutoFocus: PropTypes.func.isRequired,
    scrollToComponentAfterAutoFocus: PropTypes.bool,
  };

  private autoFocusScrollElement: HTMLDivElement | null = null;

  private readonly autoFocusInput = waitUntilFocusAndScrollAreNotDeferred(() => {
    const { onAutoFocus, scrollToComponentAfterAutoFocus } = this.props;

    onAutoFocus();

    if (scrollToComponentAfterAutoFocus) {
      this.scrollToElement();
    }
  });

  private readonly scrollToElement = () => {
    if (this.autoFocusScrollElement) {
      const scrollParent = getScrollParent(this.autoFocusScrollElement);

      const scrollParentHeight = scrollParent.getBoundingClientRect().height;
      const scrollElementTopOffset = getAbsoluteTopOffset(this.autoFocusScrollElement);

      if (scrollElementTopOffset) {
        const scrollOptions: ScrollToOptions = {
          left: 0,
          top: scrollElementTopOffset - scrollParentHeight / 2,
          behavior: 'smooth',
        };

        scrollParent.scrollTo(scrollOptions);
      }
    }
  };

  componentDidMount() {
    if (this.props.autoFocus) {
      this.autoFocusInput();
    }
  }

  componentWillUnmount() {
    this.autoFocusInput.cancel();
  }

  render(): JSX.Element | null {
    return this.props.scrollToComponentAfterAutoFocus ? (
      <div
        ref={(div) => {
          this.autoFocusScrollElement = div;
        }}
      />
    ) : null;
  }
}
