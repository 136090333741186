import { Card } from '@kontent-ai/component-library/Card';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { HookFormProps } from '../../../../_shared/types/hookFormProps.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IEntityWebhookFormShape } from '../../models/IEntityWebhookFormShape.type.ts';
import { EntityWebhookEventTrigger } from './EntityWebhookEventTrigger.tsx';
import { EntityWebhookSlotTrigger } from './EntityWebhookSlotTrigger.tsx';

type EntityWebhookTriggersProps = {
  readonly enabled: boolean;
  readonly formProps: HookFormProps<IEntityWebhookFormShape>;
};

export const EntityWebhookTriggers = ({ enabled, formProps }: EntityWebhookTriggersProps) => {
  return (
    <Card cardLabel="Triggers" component="section">
      <Card.Headline {...getDataUiElementAttribute(DataUiElement.FormSection)}>
        Triggers
      </Card.Headline>
      <Card.Body>
        <Stack spacing={Spacing.XL}>
          <EntityWebhookSlotTrigger enabled={enabled} formProps={formProps} />
          <EntityWebhookEventTrigger enabled={enabled} formProps={formProps} />
        </Stack>
      </Card.Body>
    </Card>
  );
};

EntityWebhookTriggers.displayName = 'EntityWebhookTriggers';
