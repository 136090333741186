import Immutable from 'immutable';
import React from 'react';
import { useParams } from 'react-router';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { IItemElement } from '../actions/thunks/editItemVariant.ts';
import { editItemVariantCodenameByPath } from '../actions/thunks/editItemVariantCodenameByPath.ts';
import { SmartLink } from '../components/SmartLink.tsx';
import { EditItemVariantCodenameRouteParams } from '../constants/routePaths.ts';

type Props = {
  readonly path: Immutable.List<IItemElement>;
};

export const EditItemVariantSmartLink: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const { projectId, variantCodename } = useParams<EditItemVariantCodenameRouteParams>();

  const onMount = () =>
    dispatch(editItemVariantCodenameByPath(projectId, variantCodename, props.path));

  return <SmartLink onMount={onMount} />;
};

EditItemVariantSmartLink.displayName = 'EditItemVariantSmartLink';
