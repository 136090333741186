import { createGuid } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { DefaultWorkflowId } from '../../../../../_shared/constants/variantIdValues.ts';
import { logError } from '../../../../../_shared/utils/logError.ts';
import { initializeRequestTokenFactory } from '../../../../../_shared/utils/requestTokenUtils.ts';
import { Workflow } from '../../../../../data/models/workflow/Workflow.ts';
import {
  Workflow_InitializeEditing_Finished,
  Workflow_InitializeEditing_Started,
} from '../../constants/workflowActionTypes.ts';

interface IInitWorkflowEditingActionDependencies {
  readonly loadContentTypes: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadRoles: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadWorkflows: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadWorkflowStepsUsage: (workflowId: Uuid, abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadWorkflowsUsage: (
    workflowIds?: ReadonlyArray<Uuid>,
    abortSignal?: AbortSignal,
  ) => ThunkPromise;
}

const initWorkflowEditingAppStarted = (cancellationToken: Uuid) =>
  ({
    type: Workflow_InitializeEditing_Started,
    payload: {
      cancellationToken,
    },
  }) as const;

const initWorkflowEditingAppFinished = (workflow: Workflow) =>
  ({
    type: Workflow_InitializeEditing_Finished,
    payload: {
      workflow,
    },
  }) as const;

export type InitWorkflowEditingActions = ReturnType<
  typeof initWorkflowEditingAppStarted | typeof initWorkflowEditingAppFinished
>;

const workflowEditingTokenFactory = initializeRequestTokenFactory(
  (state) => state.workflowsApp.editorUi.cancellationToken,
  (cancellationToken) => () => initWorkflowEditingAppStarted(cancellationToken),
  createGuid,
);

const getWorkflowWithScopes = (workflow: Workflow): Workflow => {
  if (workflow.scopes.length > 0 || workflow.id === DefaultWorkflowId) {
    return workflow;
  }

  return {
    ...workflow,
    scopes: [
      {
        id: createGuid(),
        collections: new Set<Uuid>(),
        contentTypes: new Set<Uuid>(),
      },
    ],
  };
};

export const createInitWorkflowEditingAction =
  ({
    loadContentTypes,
    loadRoles,
    loadWorkflows,
    loadWorkflowStepsUsage,
    loadWorkflowsUsage,
  }: IInitWorkflowEditingActionDependencies) =>
  (workflowId: Uuid, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const { isCurrentTokenValid, tokenizedActionCreator: onStart } =
      workflowEditingTokenFactory(getState);

    dispatch(onStart());

    await Promise.all([
      dispatch(loadRoles(abortSignal)),
      dispatch(loadWorkflows(abortSignal)),
      dispatch(loadWorkflowStepsUsage(workflowId, abortSignal)),
      dispatch(loadWorkflowsUsage([workflowId], abortSignal)),
      dispatch(loadContentTypes(abortSignal)),
    ]);

    const workflow = getState().data.workflows.byId.get(workflowId);
    if (!workflow) {
      logError('initWorkflowEditing.ts: workflow not found.');
      return;
    }

    if (!isCurrentTokenValid()) {
      return;
    }

    const workflowWithScopes = getWorkflowWithScopes(workflow);
    dispatch(initWorkflowEditingAppFinished(workflowWithScopes));
  };
