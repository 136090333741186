import PropTypes, { ValidationMap } from 'prop-types';
import {
  ConditionPropTypeShape,
  TypeElementCondition,
  convertConditionFromServerModel,
} from '../../../../../../_shared/models/utils/TypeElementCondition.ts';
import { PropTypeOrNull } from '../../../../../../_shared/utils/propTypesValidators.ts';
import { CompiledContentTypeElementServerModel } from '../../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import {
  ITypeElementDataWithValidationRegex,
  ValidationRegex,
} from '../../../../../contentModels/shared/models/elements/types/TypeElementData.ts';
import { TypeElementConfigurability } from '../../../../../contentModels/shared/models/elements/types/elementConfigurabilityOptions.ts';
import {
  TableBlockCategoryFeature,
  TextBlockTypeFeature,
  TextFormattingFeature,
  TopLevelBlockCategoryFeature,
} from '../../../../../richText/plugins/apiLimitations/api/editorLimitationUtils.ts';
import { AssetFileTypeOption } from '../../assetFileTypeOptions.ts';
import { FileSizeUnit } from '../../fileSizeOptions.ts';
import { QuantityOption } from '../../quantityOptions.ts';
import { UnitOption } from '../../textLengthOptions.ts';

export interface IBaseTypeElement {
  readonly _configurability: TypeElementConfigurability;
  readonly codename: string | null;
  readonly condition: TypeElementCondition | null;
  readonly contentGroupId: Uuid | null;
  readonly elementId: Uuid;
  readonly guidelines: string;
  readonly isNonLocalizable: boolean;
  readonly isRequired: boolean;
  readonly name: string;
}

export const EmptyBaseTypeElement: Omit<IBaseTypeElement, '_configurability'> = {
  codename: null,
  condition: null,
  contentGroupId: null,
  elementId: '',
  guidelines: '',
  isNonLocalizable: false,
  isRequired: false,
  name: '',
};

export const BaseTypeElementValidationMap = <TType>(
  types: TType[],
): ValidationMap<IBaseTypeElement & { readonly type: TType }> => ({
  codename: PropTypeOrNull(PropTypes.string),
  condition: PropTypeOrNull(ConditionPropTypeShape),
  contentGroupId: PropTypeOrNull(PropTypes.string),
  elementId: PropTypes.string.isRequired,
  guidelines: PropTypes.string.isRequired,
  isNonLocalizable: PropTypes.bool.isRequired,
  isRequired: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(types).isRequired,
  _configurability: PropTypes.oneOf(Object.values(TypeElementConfigurability)).isRequired,
});

export interface ITypeElementWithNumberOfItemsLimit {
  readonly _quantityUnitOption: QuantityOption;
  readonly maxItems: number | null;
  readonly minItems: number | null;
}

export const NumberOfItemsLimitParametersValidationMap: ValidationMap<ITypeElementWithNumberOfItemsLimit> =
  {
    _quantityUnitOption: PropTypes.oneOf(Object.values(QuantityOption)).isRequired,
    maxItems: PropTypeOrNull(PropTypes.number),
    minItems: PropTypeOrNull(PropTypes.number),
  };

export interface ITypeElementWithAllowedItemLinkTypesLimit {
  readonly allowedItemLinkTypes: ReadonlyArray<Uuid>;
}

export const AllowedItemLinkTypesLimitParametersValidationMap: ValidationMap<ITypeElementWithAllowedItemLinkTypesLimit> =
  {
    allowedItemLinkTypes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  };

export interface ITypeElementWithAllowedContentTypesLimit {
  readonly allowedTypes: ReadonlyArray<Uuid>;
}

export const AllowedContentTypesLimitParametersValidationMap: ValidationMap<ITypeElementWithAllowedContentTypesLimit> =
  {
    allowedTypes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  };

export interface ITypeElementWithFileSizeLimit {
  readonly _fileSizeUnitOption: FileSizeUnit;
  readonly fileSize: number | null;
}

export interface ITypeElementWithImageDimensions {
  readonly maxHeight: number | null;
  readonly maxWidth: number | null;
  readonly minHeight: number | null;
  readonly minWidth: number | null;
}

export interface ITypeElementWithTextLimit {
  readonly _lengthUnitOption: UnitOption;
  readonly maxChars: number | null;
  readonly maxWords: number | null;
}

export const TypeElementWithTextLimitValidationMap: ValidationMap<ITypeElementWithTextLimit> = {
  _lengthUnitOption: PropTypes.oneOf(Object.values(UnitOption)).isRequired,
  maxChars: PropTypeOrNull(PropTypes.number),
  maxWords: PropTypeOrNull(PropTypes.number),
};

export interface ITypeElementWithValidationRegex {
  readonly validationRegex: ValidationRegex | null;
}

export const TypeElementDataWithValidationRegexValidationMap: ValidationMap<ITypeElementDataWithValidationRegex> =
  {
    validationRegex: PropTypeOrNull(
      PropTypes.shape({
        regex: PropTypes.string.isRequired,
        flags: PropTypeOrNull(PropTypes.string),
        validationMessage: PropTypeOrNull(PropTypes.string),
        isActive: PropTypes.bool.isRequired,
      }),
    ),
  };

export interface ITypeElementWithAssetTypeLimitation {
  readonly fileType: AssetFileTypeOption;
}

export interface ITypeElementWithAssetLimit
  extends ITypeElementWithImageDimensions,
    ITypeElementWithFileSizeLimit,
    ITypeElementWithAssetTypeLimitation {}

export const TypeElementWithAssetLimitValidationMap: ValidationMap<ITypeElementWithAssetLimit> = {
  _fileSizeUnitOption: PropTypes.oneOf(Object.values(FileSizeUnit)).isRequired,
  fileSize: PropTypeOrNull(PropTypes.number),
  fileType: PropTypes.oneOf(Object.values(AssetFileTypeOption)).isRequired,
  maxHeight: PropTypeOrNull(PropTypes.number),
  maxWidth: PropTypeOrNull(PropTypes.number),
  minHeight: PropTypeOrNull(PropTypes.number),
  minWidth: PropTypeOrNull(PropTypes.number),
};

export interface ITypeElementWithLimitationCategories {
  readonly allowedBlocks: ReadonlySet<TopLevelBlockCategoryFeature>;
  readonly allowedTextBlocks: ReadonlySet<TextBlockTypeFeature>;
  readonly allowedTextFormatting: ReadonlySet<TextFormattingFeature>;
  readonly allowedTableBlocks: ReadonlySet<TableBlockCategoryFeature>;
  readonly allowedTableTextBlocks: ReadonlySet<TextBlockTypeFeature>;
  readonly allowedTableTextFormatting: ReadonlySet<TextFormattingFeature>;
}

export const TypeElementWithLimitationCategoriesValidationMap: ValidationMap<ITypeElementWithLimitationCategories> =
  {
    allowedBlocks: PropTypes.instanceOf(Set<TopLevelBlockCategoryFeature>).isRequired,
    allowedTextBlocks: PropTypes.instanceOf(Set<TextBlockTypeFeature>).isRequired,
    allowedTextFormatting: PropTypes.instanceOf(Set<TextFormattingFeature>).isRequired,
    allowedTableBlocks: PropTypes.instanceOf(Set<TableBlockCategoryFeature>).isRequired,
    allowedTableTextBlocks: PropTypes.instanceOf(Set<TextBlockTypeFeature>).isRequired,
    allowedTableTextFormatting: PropTypes.instanceOf(Set<TextFormattingFeature>).isRequired,
  };

export function getBaseTypeElementFromServerModel(
  serverModel: CompiledContentTypeElementServerModel,
): Omit<IBaseTypeElement, '_configurability'> {
  return {
    codename: serverModel.codeName,
    condition: convertConditionFromServerModel(serverModel.conditions),
    contentGroupId: serverModel.contentGroupId,
    elementId: serverModel.elementId,
    guidelines: serverModel.guidelines || EmptyBaseTypeElement.guidelines,
    isNonLocalizable: !!serverModel.isNonLocalizable,
    isRequired: !!serverModel.isRequired,
    name: serverModel.name,
  };
}
