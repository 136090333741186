import { memoize } from '@kontent-ai/memoization';
import { Direction } from '@kontent-ai/types';
import { Collection, notNull } from '@kontent-ai/utils';

const makeIndicesCyclic = memoize.maxOne(
  (indices: ReadonlyArray<number>): ReadonlyArray<number> =>
    [Collection.getLast(indices), ...indices, Collection.getFirst(indices)].filter(notNull),
);

const getSelectableIndicesWithCurrentOption = memoize.maxOne(
  <T>(
    currentOption: T,
    optionList: ReadonlyArray<T>,
    isOptionSelectable?: (option: T) => boolean,
  ): ReadonlyArray<number> => {
    if (isOptionSelectable) {
      return optionList
        .map((option, i) => (isOptionSelectable(option) || currentOption === option ? i : -1))
        .filter((i) => i !== -1);
    }

    return optionList.map((_option, i) => i);
  },
);

export const getNewSelectedOption = <T>(
  direction: Direction,
  optionList: ReadonlyArray<T>,
  currentOption: T,
  isOptionSelectable?: (option: T) => boolean,
): T | null => {
  const selectableIndices = getSelectableIndicesWithCurrentOption(
    currentOption,
    optionList,
    isOptionSelectable,
  );
  const currentIndex = selectableIndices.indexOf(optionList.indexOf(currentOption)) + 1;
  const selectableIndicesWithCycling = makeIndicesCyclic(selectableIndices);

  const newIndex =
    selectableIndicesWithCycling[
      direction === Direction.Forward ? currentIndex + 1 : currentIndex - 1
    ] ?? null;

  return newIndex === null ? null : optionList[newIndex] ?? null;
};
