import React from 'react';
import { AddTaskFormBase } from '../../../containers/details/Tasks/AddTaskFormBase.tsx';
import { LeafActionFormProps } from '../EditingLeafActionForm.tsx';

type AddTaskFormProps = LeafActionFormProps;

export const AddTaskForm: React.FC<AddTaskFormProps> = () => {
  return (
    <div className="editing-actions-assignment-form">
      <AddTaskFormBase />
    </div>
  );
};

AddTaskForm.displayName = 'AddTaskForm';
