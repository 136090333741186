import { memoize } from '@kontent-ai/memoization';
import { assert, Collection } from '@kontent-ai/utils';
import { compile } from 'path-to-regexp';
import { RouteProps, matchPath as routerMatchPath } from 'react-router';
import { IContentItemVariantReference } from '../../../applications/itemEditor/models/contentItem/ContentItemVariantReference.ts';
import {
  ContentAppEntryRoute,
  ContentItemEditorRouteParams,
  ContentItemEditorRoutes,
  ContentItemOpenCommentRouteParams,
  ContentItemPreviewWithEditorRoute,
  ContentItemPreviewWithEditorRouteParams,
  ContentItemRevisionCompareRoute,
  ContentItemRevisionCompareRouteParams,
  ContentItemRevisionViewerRoute,
  ContentItemRevisionViewerRouteParams,
  ContentItemRoute,
  ContentItemRouteParams,
  ContentItemTimelineLatestPublishedRoute,
  ContentItemsAppEntryRoute,
  ContentItemsAppEntryRouteParams,
  ContentItemsAppRouteSegment,
  ContentItemsRoute,
  ContentItemsRouteParams,
  EnvironmentRoute,
  EnvironmentRouteParams,
  HomeEntryRoute,
  HomeEntryRouteParams,
  MissionControlLanguageDependentRoute,
  MissionControlLanguageDependentRouteParams,
  RelationsEntryRoute,
  RelationsEntryRouteParams,
  WebSpotlightEntryRoute,
  WebSpotlightEntryRouteParams,
} from '../../constants/routePaths.ts';
import { DefaultVariantId } from '../../constants/variantIdValues.ts';
import { ContentItemId } from '../../models/ContentItemId.type.ts';

export const parseContentItemIds = memoize.maxN((contentItemIds: string): UuidArray => {
  return contentItemIds.split('/');
}, 1_000);

export type BuildPathParams = ReadonlyRecord<string, any> & { readonly contentItemIds?: UuidArray };

export type BuildPath = <TRouteParams extends BuildPathParams>(
  route: string,
  params: TRouteParams,
) => string;

export const buildPath: BuildPath = (route, params): string => compile(route)(params);

const isRouteProps = (param: RouteProps | string): param is RouteProps => typeof param !== 'string';

export type MatchPathParams = ReadonlyRecord<string, any> & { readonly contentItemIds?: string };

export function matchPath<T extends MatchPathParams>(
  pathname: string,
  param: RouteProps | string,
): T | null {
  const matchParams = isRouteProps(param) ? param : { path: param };
  const match = routerMatchPath<T>(pathname, matchParams);
  return match?.params ?? null;
}

export function matchExactPath<T extends MatchPathParams>(
  pathname: string,
  param: RouteProps | string,
): T | null {
  const matchParams = isRouteProps(param) ? param : { path: param };
  const match = routerMatchPath<T>(pathname, matchParams);
  return match?.isExact ? match.params : null;
}

export const convertMatchPathParamsToBuildPathParams = (
  match: MatchPathParams | null,
): BuildPathParams => {
  if (!match) {
    return {};
  }

  if (match.contentItemIds) {
    return {
      ...match,
      contentItemIds: parseContentItemIds(match.contentItemIds),
    };
  }

  const { contentItemIds, ...matchWithoutContentItemIds } = match;
  return matchWithoutContentItemIds;
};

export function getPathWithReplacedVariantIdForContentInventoryTypeRoute(
  pathname: string,
  wantedVariantId: Uuid,
): string {
  return getPathWithReplacedParameters<ContentItemsAppEntryRouteParams>(
    ContentItemsAppEntryRoute,
    pathname,
    { variantId: wantedVariantId },
  );
}

export function getPathWithReplacedVariantIdForHomeRoute(
  pathname: string,
  wantedVariantId: Uuid,
): string {
  return getPathWithReplacedParameters<HomeEntryRouteParams>(HomeEntryRoute, pathname, {
    variantId: wantedVariantId,
  });
}

export const getPathWithReplacedVariantIdForMissionControlLanguageDependentRoutes = (
  pathname: string,
  wantedVariantId: Uuid,
): string =>
  getPathWithReplacedParameters<MissionControlLanguageDependentRouteParams>(
    MissionControlLanguageDependentRoute,
    pathname,
    {
      variantId: wantedVariantId,
    },
  );

export function getPathWithReplacedVariantIdForRelationsRoute(
  pathname: string,
  wantedVariantId: Uuid,
): string {
  return getPathWithReplacedParameters<RelationsEntryRouteParams>(RelationsEntryRoute, pathname, {
    variantId: wantedVariantId,
  });
}

export function getPathWithReplacedVariantIdForWebSpotlightRoute(
  pathname: string,
  wantedVariantId: Uuid,
): string {
  return getPathWithReplacedParameters<WebSpotlightEntryRouteParams>(
    WebSpotlightEntryRoute,
    pathname,
    { variantId: wantedVariantId },
  );
}

export function getPathWithReplacedTimelineItemId(
  pathname: string,
  wantedTimelineItemId: Uuid,
): string {
  return getPathWithReplacedParameters<ContentItemRevisionViewerRouteParams<UuidArray>>(
    ContentItemRevisionViewerRoute,
    pathname,
    { timelineItemId: wantedTimelineItemId },
  );
}

export function getPathWithReplacedComparedTimelineItemId(
  pathname: string,
  wantedComparedTimelineItemId: Uuid,
): string {
  return getPathWithReplacedParameters<ContentItemRevisionCompareRouteParams<UuidArray>>(
    ContentItemRevisionCompareRoute,
    pathname,
    { compareItemId: wantedComparedTimelineItemId },
  );
}

export function isTimelineCompareRoute(pathname: string): boolean {
  return !!matchPath<ContentItemRevisionCompareRouteParams<string>>(
    pathname,
    ContentItemRevisionCompareRoute,
  );
}

export function getPathWithReplacedParameters<RouteParams extends EnvironmentRouteParams>(
  routePattern: string,
  pathname: string,
  parametersToBeReplaced: Partial<RouteParams>,
): string {
  const match = matchPath<RouteParams>(pathname, routePattern);
  assert(match, () => `Current route does not belong to ${routePattern}`);

  const partToReplace = buildPath<RouteParams>(routePattern, match);

  const newParams: RouteParams = {
    ...match,
    ...parametersToBeReplaced,
  };
  const replaceWithPart = buildPath<RouteParams>(routePattern, newParams);

  return pathname.replace(partToReplace, replaceWithPart);
}

export function getPathForContentItemDetailLanguageSwitcher(
  pathname: string,
  wantedVariantId: Uuid,
  keepSubPath: boolean,
): string {
  const match = matchPath<ContentItemRouteParams<string>>(pathname, ContentItemRoute);
  assert(match, () => 'Current route does not fit to ContentItemRouteParams');

  const contentItemIds = parseContentItemIds(match.contentItemIds);
  const lastContentItemId = Collection.getLast(contentItemIds);
  const newContentItemRootPath = buildPath<ContentItemRouteParams<UuidArray>>(ContentItemRoute, {
    app: match.app,
    projectId: match.projectId,
    variantId: wantedVariantId,
    spaceId: match.spaceId,
    contentItemIds:
      match.app === ContentItemsAppRouteSegment.WebSpotlight
        ? // Web spotlight keeps the full route while switching languages to keep hierarchical location
          contentItemIds
        : // Content item editing from inventory starts over from the last item
          lastContentItemId
          ? [lastContentItemId]
          : [],
  });

  if (!keepSubPath) {
    return newContentItemRootPath;
  }

  const oldContentItemRootPath = buildPath<ContentItemRouteParams<UuidArray>>(ContentItemRoute, {
    app: match.app,
    projectId: match.projectId,
    variantId: match.variantId,
    spaceId: match.spaceId,
    contentItemIds,
  });

  const updatedPath = pathname.replace(oldContentItemRootPath, newContentItemRootPath);
  return updatedPath;
}

export function getInventoryPath(currentPath: string): string {
  const matchedParams = matchPath<EnvironmentRouteParams>(currentPath, EnvironmentRoute);
  assert(matchedParams, () => 'Current route does not fit to ProjectRoute');

  return buildPath<EnvironmentRouteParams>(ContentAppEntryRoute, {
    projectId: matchedParams.projectId,
  });
}

export function getContentItemPath(
  currentPath: string,
  itemId: Uuid,
  variantId?: Uuid | null,
  route?: string,
  commentThreadId?: Uuid,
): string {
  const matchedItemsParams = matchPath<ContentItemsRouteParams>(currentPath, ContentItemsRoute);
  if (!matchedItemsParams) {
    const matchedProjectParams = matchPath<EnvironmentRouteParams>(currentPath, EnvironmentRoute);
    assert(matchedProjectParams, () => 'Current route does not fit to ProjectRoute');

    const itemPath = buildPath<
      ContentItemRouteParams<UuidArray> | ContentItemOpenCommentRouteParams<UuidArray>
    >(route ?? ContentItemRoute, {
      app: ContentItemsAppRouteSegment.Content,
      projectId: matchedProjectParams.projectId,
      variantId: variantId ?? DefaultVariantId,
      spaceId: undefined,
      contentItemIds: [itemId],
      commentThreadId,
    });
    return itemPath;
  }

  const localizedItemPath = buildPath<
    ContentItemRouteParams<UuidArray> | ContentItemOpenCommentRouteParams<UuidArray>
  >(route ?? ContentItemRoute, {
    app: matchedItemsParams.app,
    projectId: matchedItemsParams.projectId,
    variantId: variantId ?? matchedItemsParams.variantId,
    spaceId: matchedItemsParams.spaceId,
    contentItemIds: [itemId],
    commentThreadId,
  });
  return localizedItemPath;
}

export function getLinkedContentItemPath(
  currentPath: string,
  linkedContentItemId: Uuid,
  route?: string,
): string {
  const matchedFloatingEditorParams = matchPath<ContentItemPreviewWithEditorRouteParams<string>>(
    currentPath,
    ContentItemPreviewWithEditorRoute,
  );

  if (matchedFloatingEditorParams?.editedItemId) {
    return buildPath<ContentItemPreviewWithEditorRouteParams<UuidArray>>(
      ContentItemPreviewWithEditorRoute,
      {
        app: matchedFloatingEditorParams.app,
        projectId: matchedFloatingEditorParams.projectId,
        variantId: matchedFloatingEditorParams.variantId,
        spaceId: matchedFloatingEditorParams.spaceId,
        contentItemIds: parseContentItemIds(matchedFloatingEditorParams.contentItemIds),
        editedItemId: linkedContentItemId,
      },
    );
  }

  const matchedItemParams = matchPath<ContentItemRouteParams<string>>(
    currentPath,
    ContentItemRoute,
  );
  if (!matchedItemParams) {
    return getContentItemPath(currentPath, linkedContentItemId, undefined, route);
  }

  const nestedItemPath = buildPath<ContentItemRouteParams<UuidArray>>(route ?? ContentItemRoute, {
    app: matchedItemParams.app,
    projectId: matchedItemParams.projectId,
    variantId: matchedItemParams.variantId,
    spaceId: matchedItemParams.spaceId,
    contentItemIds: [...parseContentItemIds(matchedItemParams.contentItemIds), linkedContentItemId],
  });
  return nestedItemPath;
}

export function getEditedContentItemId(match: ContentItemEditorRouteParams<string>): Uuid {
  if (match.editedItemId) {
    return match.editedItemId;
  }

  const contentItemIds = parseContentItemIds(match.contentItemIds);
  const lastContentItemId = Collection.getLast(contentItemIds);
  assert(
    lastContentItemId,
    () => 'Content item editor route match should contain at least one contentItemId.',
  );

  return lastContentItemId;
}

export function getEditedContentItemIdFromPath(pathname: string): ContentItemId | null {
  const match = matchPath<ContentItemEditorRouteParams<string>>(pathname, {
    path: ContentItemEditorRoutes,
  });
  if (!match) {
    return null;
  }

  const itemId = getEditedContentItemId(match);
  const variantId = match.variantId;
  if (itemId && variantId) {
    return {
      itemId,
      variantId,
    };
  }
  return null;
}

export const createBuildLinkForVariantReference = (
  projectId: Uuid,
  currentPath: string | null = null,
) =>
  memoize.maxOne((variantReference: IContentItemVariantReference): string => {
    const matchParams = currentPath
      ? matchPath<ContentItemRouteParams<string>>(currentPath, ContentItemRoute)
      : null;

    const precedingItemIds = matchParams ? parseContentItemIds(matchParams.contentItemIds) : [];
    const app = matchParams ? matchParams.app : ContentItemsAppRouteSegment.Content;

    const route = variantReference.isPublishedVersion
      ? ContentItemTimelineLatestPublishedRoute
      : ContentItemRoute;

    return buildPath<ContentItemRouteParams<UuidArray>>(route, {
      app,
      projectId,
      variantId: variantReference.id.variantId,
      spaceId: matchParams?.spaceId,
      contentItemIds: [...precedingItemIds, variantReference.id.itemId],
    });
  });
