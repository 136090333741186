import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import { IconName } from '../../../../../../../_shared/constants/iconEnumGenerated.ts';
import { Icon } from '../../../../../../../_shared/uiComponents/Icon/Icon.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface IUrlSlugAutogenerateButtonProps {
  readonly onClick: () => void;
}

const propTypes: PropTypesShape<IUrlSlugAutogenerateButtonProps> = {
  onClick: PropTypes.func.isRequired,
};

export const UrlSlugAutogenerateButton: React.FC<IUrlSlugAutogenerateButtonProps> = ({
  onClick,
}) => (
  <Tooltip tooltipText="Autogenerate" placement="top-start">
    <div
      onClick={onClick}
      className="text-field__button"
      {...getDataUiActionAttribute(DataUiAction.Regenerate)}
    >
      <div className="text-field__button-icon">
        <Icon iconName={IconName.RotateDoubleRight} />
      </div>
    </div>
  </Tooltip>
);
UrlSlugAutogenerateButton.displayName = 'UrlSlugAutogenerateButton';
UrlSlugAutogenerateButton.propTypes = propTypes;
