import { Collection } from '@kontent-ai/utils';
import { History } from 'history';
import { Dispatch, GetState, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import {
  EnvironmentRouteParams,
  TaxonomyGroupsRoute,
} from '../../../../../_shared/constants/routePaths.ts';
import { logError } from '../../../../../_shared/utils/logError.ts';
import { redirectToDefaultRoute } from '../../../../../_shared/utils/routing/redirectToDefaultRoute.ts';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import {
  ITaxonomyGroup,
  createTaxonomyGroupDomainModel,
} from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { VariantUsageQueryServerModel } from '../../../../../repositories/serverModels/ContentItemFilterWithContinuationServerModel.ts';
import { ITaxonomyGroupServerModel } from '../../../../../repositories/serverModels/contentModels/TaxonomyGroupServerModel.type.ts';
import { getTaxonomyGroupItemFilterServerModel } from '../../../../contentInventory/content/models/filter/contentItemFilterUtils.ts';
import {
  Taxonomy_GroupEdit_Cleared,
  Taxonomy_GroupEdit_Initialized,
  Taxonomy_Group_UsageOfEditedInPublishedItems,
} from '../../constants/taxonomyActionTypes.ts';

interface ICreateInitTaxonomyGroupEditActionDependencies {
  readonly taxonomyRepository: {
    getTaxonomyGroup: (taxonomyGroupId: Uuid) => Promise<ITaxonomyGroupServerModel>;
  };
  readonly contentItemRepository: {
    readonly projectContainsPublishedItems: (
      filter: VariantUsageQueryServerModel,
    ) => Promise<boolean>;
  };
}

interface IActionParams {
  readonly taxonomyGroupId: Uuid;
  readonly history: History;
}

const taxonomyEditingCleared = () =>
  ({
    type: Taxonomy_GroupEdit_Cleared,
  }) as const;

const editedGroupInitialized = (editedTaxonomyGroup: ITaxonomyGroup) =>
  ({
    type: Taxonomy_GroupEdit_Initialized,
    payload: { editedTaxonomyGroup },
  }) as const;

const taxonomyGroupUsageInPublishedItems = (isUsed: boolean) =>
  ({
    type: Taxonomy_Group_UsageOfEditedInPublishedItems,
    payload: { isUsed },
  }) as const;

export type InitTaxonomyGroupEditActionsType = ReturnType<
  | typeof taxonomyEditingCleared
  | typeof editedGroupInitialized
  | typeof taxonomyGroupUsageInPublishedItems
>;

export const createInitTaxonomyGroupEditAction =
  (deps: ICreateInitTaxonomyGroupEditActionDependencies) =>
  ({ taxonomyGroupId, history }: IActionParams): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    dispatch(taxonomyEditingCleared());
    const projectId = getState().sharedApp.currentProjectId;

    try {
      const serverGroup = await deps.taxonomyRepository
        .getTaxonomyGroup(taxonomyGroupId)
        .catch((error) => {
          redirectToDefaultRoute({
            currentProjectId: projectId,
            history,
            error,
          });
          return;
        });

      const editedGroup = createTaxonomyGroupDomainModel(serverGroup || null);

      if (editedGroup.isArchived) {
        history.push(buildPath<EnvironmentRouteParams>(TaxonomyGroupsRoute, { projectId }));
        return;
      }

      const termIds = Collection.getKeys(editedGroup.terms);
      if (termIds.length) {
        const isUsed = await deps.contentItemRepository.projectContainsPublishedItems({
          taxonomiesByGroupId: {
            [editedGroup.id]: getTaxonomyGroupItemFilterServerModel(termIds),
          },
        });
        dispatch(taxonomyGroupUsageInPublishedItems(isUsed));
      } else {
        dispatch(taxonomyGroupUsageInPublishedItems(false));
      }

      dispatch(editedGroupInitialized(editedGroup));
    } catch (error) {
      logError('Error while initializing taxonomy group edit', error);
    }
  };
