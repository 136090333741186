import { useRef } from 'react';
import styled from 'styled-components';
import {
  SliceDirection,
  SliceFrom,
  useSliceOverflowingItems,
} from '../../../hooks/useSliceOverflowingItems.ts';
import { Stack } from '../../../layout/Stack/Stack.tsx';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { AppMenuItem } from '../types/AppMenuItem.type.ts';
import { MoreApps } from './MoreApps.tsx';
import { NavigationBarLink } from './NavigationBarItems.tsx';

const NavigationPane = styled.nav`
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex: 1 1 0;
`;

type Props = Readonly<{ items: ReadonlyArray<AppMenuItem>; ariaLabel?: string }>;

export const AppMenu = ({ items, ariaLabel }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { attachVisibleItemRef, visibleItems, hiddenItems } = useSliceOverflowingItems(
    items,
    containerRef,
    SliceFrom.End,
    SliceDirection.Vertical,
  );

  return (
    <NavigationPane ref={containerRef} aria-label={ariaLabel}>
      <Stack spacing={Spacing.S} align="center">
        {visibleItems.map((item) => (
          <NavigationBarLink
            key={item.id}
            ref={attachVisibleItemRef(item.label)}
            {...item}
            tooltipText={item.label}
          />
        ))}
        {hiddenItems.length > 0 && <MoreApps items={hiddenItems} />}
      </Stack>
    </NavigationPane>
  );
};
