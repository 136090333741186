import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { noteFormChanged, noteFormReset } from '../../../actions/contentItemEditingActions.ts';
import { NoteFormBase } from '../../../components/details/Note/NoteFormBase.tsx';
import { AssignmentSections } from '../../../constants/AssignmentSections.ts';
import { INoteFormShape } from '../../../models/INoteFormShape.type.ts';
import {
  isOtherAssignmentSectionSubmitting as isOtherAssignmentSectionSubmittingSelector,
  isSectionSubmitting as isSectionSubmittingSelector,
} from '../../../selectors/isSectionSubmitting.ts';
import { useIsWarningDisplayed } from '../../hooks/useIsWarningDisplayed.ts';

const areSameOrFalsy = (
  stringA: string | null | undefined,
  stringB: string | null | undefined,
): boolean => stringA === stringB || (!stringA && !stringB);

const hasFormUnsavedChanges = (a: INoteFormShape, b: INoteFormShape): boolean =>
  !areSameOrFalsy(a.note, b.note);

export type NoteFormBaseContainerProps = {
  readonly initialNote: string | null | undefined;
  readonly onCancel: () => void;
  readonly onConfirm: (values: INoteFormShape) => Promise<void>;
};

const NoteFormBaseContainer: React.FC<NoteFormBaseContainerProps> = (props) => {
  const defaultNote = props.initialNote || '';
  const defaultValues = useMemo(
    (): INoteFormShape => ({
      note: defaultNote,
    }),
    [defaultNote],
  );

  const unsavedValues = useSelector(
    (s) => s.contentApp.editorUi.editingActions.forms.note.unsavedValues,
  );
  const isOtherAssignmentSectionSubmitting = useSelector((s) =>
    isOtherAssignmentSectionSubmittingSelector(s, AssignmentSections.Note),
  );
  const isSectionSubmitting = useSelector((s) =>
    isSectionSubmittingSelector(s, AssignmentSections.Note),
  );

  const formProps = useForm<INoteFormShape>({
    defaultValues: unsavedValues || defaultValues,
  });

  const { handleSubmit, watch } = formProps;
  const dispatch = useDispatch();

  useEffect(() => {
    const subscription = watch((formValues: INoteFormShape) => {
      if (hasFormUnsavedChanges(formValues, defaultValues)) {
        dispatch(noteFormChanged(formValues));
      } else {
        dispatch(noteFormReset());
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, defaultValues]);

  const formValues = watch();
  const hasUnsavedChanges = hasFormUnsavedChanges(formValues, defaultValues);
  const isWarningDisplayed = useIsWarningDisplayed(hasUnsavedChanges);
  const submitForm = handleSubmit(props.onConfirm);

  const resetForm = (): void => {
    props.onCancel();
    dispatch(noteFormReset());
  };

  return (
    <NoteFormBase
      formProps={formProps}
      initialNote={props.initialNote}
      isOtherAssignmentSectionSubmitting={isOtherAssignmentSectionSubmitting}
      isSectionSubmitting={isSectionSubmitting}
      isWarningDisplayed={isWarningDisplayed}
      onReset={resetForm}
      onSubmit={submitForm}
    />
  );
};

NoteFormBaseContainer.displayName = 'NoteFormBaseContainer';

export { NoteFormBaseContainer as NoteFormBase };
