import { isAbortedXMLHttpRequest } from './errorUtils.ts';

export class AbortError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'AbortError';
  }
}

export function swallowAbortError(e: unknown): void | never {
  if (!isAbortError(e)) {
    throw e;
  }
}

export function isAbortError(e: unknown): e is AbortError | XMLHttpRequest {
  return e instanceof AbortError || isAbortedXMLHttpRequest(e);
}
