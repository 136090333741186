import { Box } from '@kontent-ai/component-library/Box';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Spacing, Typography } from '@kontent-ai/component-library/tokens';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { IgnoreByGrammarly } from '../IgnoreByGrammarly.tsx';

interface IAssetCollectionProps {
  readonly collectionName?: string | null;
}

const propTypes: PropTypeMap<IAssetCollectionProps> = {
  collectionName: PropTypes.string,
};

const StyledLabel = styled.span`
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const AssetCollection: React.FC<IAssetCollectionProps> = ({ collectionName }) => {
  return (
    collectionName && (
      <Box typography={Typography.LabelLarge} paddingTop={Spacing.S} position="relative">
        <Tooltip placement="top-end" tooltipText={`Collection: ${collectionName}`}>
          <IgnoreByGrammarly text={collectionName}>
            <StyledLabel>{collectionName}</StyledLabel>
          </IgnoreByGrammarly>
        </Tooltip>
      </Box>
    )
  );
};

AssetCollection.displayName = 'AssetCollection';
AssetCollection.propTypes = propTypes;
