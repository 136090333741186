import PropTypes from 'prop-types';
import React from 'react';
import { CLPropTypes } from '../../validators/propTypes.ts';
import { StyledSkeleton } from './components/StyledSkeleton.tsx';
import { SkeletonTypeToSkeletonBorderRadiusMap } from './types.ts';

type CircleSkeletonProps = Readonly<{
  diameter: number;
  children?: never;
}>;

const circleSkeletonPropTypes: PropTypeMap<CircleSkeletonProps> = {
  children: CLPropTypes.never,
  diameter: PropTypes.number.isRequired,
};

export const CircleSkeleton = React.forwardRef<HTMLElement, CircleSkeletonProps>((props, ref) => (
  <StyledSkeleton
    $width={props.diameter}
    $height={props.diameter}
    borderRadius={SkeletonTypeToSkeletonBorderRadiusMap.circle}
    ref={ref}
  />
));

CircleSkeleton.displayName = 'CircleSkeleton';
CircleSkeleton.propTypes = circleSkeletonPropTypes;
