import { pick } from '@kontent-ai/component-library/component-utils';
import { memoize } from '@kontent-ai/memoization';
import { alphabetically, chronologically, createCompare } from '@kontent-ai/utils';
import {
  ByStatus,
  allCollectionsOption,
  allLanguagesOption,
} from '../../../../_shared/constants/userListingFilter.ts';
import { OrderBy } from '../../../../_shared/models/OrderBy.ts';
import { getTimestamp } from '../../../../_shared/utils/dateTime/timeUtils.ts';
import { doesEntitySatisfyFilterPhrase } from '../../../../_shared/utils/filter/nameFilterUtils.ts';
import { isEmptyOrWhitespace } from '../../../../_shared/utils/stringUtils.ts';
import { formatUserName } from '../../../../_shared/utils/usersUtils.ts';
import { ICollection } from '../../../../data/models/collections/Collection.ts';
import { ILanguage } from '../../../../data/models/languages/Language.ts';
import { IProjectDetails } from '../../../../data/models/projects/ProjectDetails.ts';
import { ISubscriptionUserViewModel } from '../components/SubscriptionUsersScrollTableRow.tsx';
import { ISubscriptionUsersListingFilter } from '../models/SubscriptionUserListingFilter.ts';
import { ISubscriptionUserWithSettings } from '../models/SubscriptionUserWithSettings.ts';
import { ICollectionGroup } from '../models/UserProjectSettings.ts';
import { SubscriptionUserListingColumnCode } from '../reducers/subscriptionUsersListingUi/sortBy.ts';
import { dateWeStartedTrackingLoginsTimestamp } from './subscriptionUsersScrollTableRowUtils.ts';

export type DecoratedUser = {
  readonly email: string;
  readonly fullName: string;
  readonly isActivating: boolean;
  readonly isDeactivating: boolean;
  readonly isActiveInAllProjects: boolean;
  readonly isInvitationPending: boolean;
  readonly isEmployee: boolean;
  readonly isSubscriptionAdmin: boolean;
  readonly latestLastLoginDateTimeString: string | null;
  readonly userEnvironmentNames: ReadonlyArray<string>;
  readonly userId: UserId;
  readonly userProjectNames: ReadonlyArray<string>;
  readonly userRoleNames: ReadonlyArray<string>;
};

export const getFilteredUsers = memoize.weak(
  (
    allEnvironmentsByMasterEnvironmentId: ReadonlyMap<Uuid, ReadonlyArray<IProjectDetails>>,
    subscriptionUsers: ReadonlyArray<ISubscriptionUserWithSettings>,
    selectedProjectCollections: ReadonlyArray<ICollection>,
    selectedProjectLanguages: ReadonlyArray<ILanguage>,
    filters: ISubscriptionUsersListingFilter,
  ): ReadonlyArray<ISubscriptionUserWithSettings> =>
    subscriptionUsers.filter((user) => {
      const userCollectionGroups = findUserCollectionGroupsInEnvironment(
        user,
        filters.byEnvironment,
      );
      return (
        doesUserSatisfyFilterByNameOrEmail(user, filters.byName) &&
        doesUserSatisfyFilterByStatus(user, filters.byStatus) &&
        doesUserSatisfyFilterByMasterEnvironment(
          user,
          filters.byProject,
          allEnvironmentsByMasterEnvironmentId,
        ) &&
        doesUserSatisfyFilterByEnvironment(user, filters.byEnvironment) &&
        doesUserSatisfyFilterByRole(userCollectionGroups, filters.byRole) &&
        doesUserSatisfyFilterByCollection(
          userCollectionGroups,
          filters.byCollection,
          selectedProjectCollections,
        ) &&
        doesUserSatisfyFilterByLanguage(
          userCollectionGroups,
          filters.byLanguage,
          selectedProjectLanguages,
        )
      );
    }),
);

const doesUserSatisfyFilterByNameOrEmail = (
  user: ISubscriptionUserWithSettings,
  filterPhrase: string,
): boolean =>
  !filterPhrase ||
  doesEntitySatisfyFilterPhrase(filterPhrase, user, [
    (item) => formatUserName(item),
    (item) => item.email,
  ]);

const doesUserSatisfyFilterByMasterEnvironment = memoize.weak(
  (
    user: ISubscriptionUserWithSettings,
    byMasterEnvironmentId: Uuid,
    allEnvironmentsByMasterEnvironmentId: ReadonlyMap<Uuid, ReadonlyArray<IProjectDetails>>,
  ) =>
    isEmptyOrWhitespace(byMasterEnvironmentId) ||
    allEnvironmentsByMasterEnvironmentId
      .get(byMasterEnvironmentId)
      ?.some((projectDetail) =>
        user.userProjectsSettings.some(
          (userSetting) => projectDetail.projectId === userSetting.projectId,
        ),
      ),
);

const doesUserSatisfyFilterByEnvironment = memoize.weak(
  (user: ISubscriptionUserWithSettings, byEnvironmentId: Uuid): boolean =>
    isEmptyOrWhitespace(byEnvironmentId) ||
    user.userProjectsSettings.some(
      (userProjectSetting) => userProjectSetting.projectId === byEnvironmentId,
    ),
);

const doesUserSatisfyFilterByStatus = memoize.weak(
  (user: ISubscriptionUserWithSettings, byStatus: ByStatus): boolean => {
    const isActive = user.userProjectsSettings.some(
      (userProjectSettings) => !userProjectSettings.inactive,
    );

    switch (byStatus) {
      case ByStatus.Active:
        return isActive;
      case ByStatus.Deactivated:
        return !isActive;
      default:
        return true;
    }
  },
);

const doesUserSatisfyFilterByRole = memoize.weak(
  (userCollectionGroups: ReadonlyArray<ICollectionGroup>, byRole: ReadonlySet<Uuid>): boolean => {
    if (!byRole.size) {
      return true;
    }

    const allUserRoleIds = userCollectionGroups.flatMap((colGroup) =>
      colGroup.roles.flatMap((role) => role.id),
    );

    return allUserRoleIds.some((roleId) => byRole.has(roleId));
  },
);

const doesUserSatisfyFilterByCollection = memoize.weak(
  (
    userCollectionGroups: ReadonlyArray<ICollectionGroup>,
    byCollection: ReadonlySet<Uuid>,
    selectedProjectCollections: ReadonlyArray<ICollection>,
  ): boolean => {
    if (!byCollection.size || selectedProjectCollections.length < 2) {
      return true;
    }

    const allUserCollections = userCollectionGroups.flatMap((colGroup) =>
      colGroup.collectionIds.flat(),
    );

    if (!allUserCollections.length) {
      return true;
    }

    const filterByAllCollections = byCollection.has(allCollectionsOption.id);
    if (filterByAllCollections) {
      return selectedProjectCollections.every((collection) =>
        allUserCollections.includes(collection.id),
      );
    }

    return allUserCollections.some((collectionId) => byCollection.has(collectionId));
  },
);

const doesUserSatisfyFilterByLanguage = memoize.weak(
  (
    userCollectionGroups: ReadonlyArray<ICollectionGroup>,
    byLanguage: ReadonlySet<Uuid>,
    selectedProjectLanguages: ReadonlyArray<ILanguage>,
  ): boolean => {
    if (!byLanguage.size || selectedProjectLanguages.length < 2) {
      return true;
    }

    const allUserLanguageIds = userCollectionGroups.flatMap((colGroup) =>
      colGroup.roles.flatMap((role) => role.languages.flatMap((language) => language.id)),
    );

    const filterByAllLanguages = byLanguage.has(allLanguagesOption.id);
    if (filterByAllLanguages) {
      return selectedProjectLanguages.every((language) => allUserLanguageIds.includes(language.id));
    }

    return allUserLanguageIds.some((languageId) => byLanguage.has(languageId));
  },
);

const missingLastLoginTimestamp = dateWeStartedTrackingLoginsTimestamp;
const userIsNotJoinedYetTimestamp = '2020-08-04T00:00:00.000Z';
const getDecoratedUserCompareMethod = (
  userListingOrderBy: OrderBy<SubscriptionUserListingColumnCode>,
) => {
  switch (userListingOrderBy.columnCode) {
    case SubscriptionUserListingColumnCode.LastLogin:
      return createCompare({
        compare: chronologically,
        direction: userListingOrderBy.direction,
        select: (user: DecoratedUser) =>
          user.isInvitationPending
            ? userIsNotJoinedYetTimestamp
            : user.latestLastLoginDateTimeString ?? missingLastLoginTimestamp,
      });

    case SubscriptionUserListingColumnCode.Status:
      return createCompare({
        compare: alphabetically,
        direction: userListingOrderBy.direction,
        select: (user: DecoratedUser) =>
          (user.isInvitationPending ? 'b' : user.isActiveInAllProjects ? 'a' : 'c') +
          (user.isSubscriptionAdmin ? 'b' : 'a') +
          user.fullName,
      });

    case SubscriptionUserListingColumnCode.Name:
    case SubscriptionUserListingColumnCode.None:
      return createCompare({
        compare: alphabetically,
        direction: userListingOrderBy.direction,
        select: (user: DecoratedUser) => user.fullName,
      });
  }
};

export const decorateFilteredUsers = memoize.weak(
  (
    filteredUsers: ReadonlyArray<ISubscriptionUserWithSettings>,
    projects: ReadonlyArray<IProjectDetails>,
    environments: ReadonlyArray<IProjectDetails>,
    usersListingOrderBy: OrderBy<SubscriptionUserListingColumnCode>,
  ): ISubscriptionUserViewModel[] =>
    filteredUsers
      .map((user) => decorateUser(user, projects, environments))
      .sort(getDecoratedUserCompareMethod(usersListingOrderBy)),
);

const decorateUser = memoize.weak(
  (
    user: ISubscriptionUserWithSettings,
    projects: ReadonlyArray<IProjectDetails>,
    environments: ReadonlyArray<IProjectDetails>,
  ): DecoratedUser => {
    const userName = formatUserName(user);

    const userProjectNames = projects
      .filter((project) =>
        user.userProjectsSettings.some(
          (userProjectSetting) => userProjectSetting.masterEnvironmentId === project.projectId,
        ),
      )
      .map((project) => project.projectName);

    const userEnvironmentNames = environments
      .filter((environment) =>
        user.userProjectsSettings.some(
          (userProjectSetting) => userProjectSetting.projectId === environment.projectId,
        ),
      )
      .map((environment) => environment.environmentName);

    const userRoleNames: string[] = user.userProjectsSettings.reduce(
      (reducedRoleNames, userProjectSetting) => {
        return [
          ...reducedRoleNames,
          ...userProjectSetting.collectionGroups.reduce(
            (reducedCollectionRoleNames, collectionGroup) => {
              const collectionGroupRoleNames = collectionGroup.roles.map((role) => role.name);
              return [...reducedCollectionRoleNames, ...collectionGroupRoleNames];
            },
            [],
          ),
        ];
      },
      [],
    );

    const latestLastLoginDateTimeString = user.userProjectsSettings.reduce<string | null>(
      (reducedLastLogin, userProjectSetting) => {
        const latestLastLoginDateTimestamp = getTimestamp(reducedLastLogin);
        const currentProjectLastLoginDateTimestamp = getTimestamp(userProjectSetting.lastLoginAt);
        return currentProjectLastLoginDateTimestamp > latestLastLoginDateTimestamp
          ? userProjectSetting.lastLoginAt
          : reducedLastLogin;
      },
      null,
    );

    const isActiveInAllProjects = user.userProjectsSettings.some(
      (userProjectSettings) => !userProjectSettings.inactive,
    );

    return {
      ...pick(user, [
        'email',
        'userId',
        'isActivating',
        'isDeactivating',
        'isInvitationPending',
        'isEmployee',
        'isSubscriptionAdmin',
      ]),
      fullName: userName,
      userRoleNames,
      userProjectNames,
      userEnvironmentNames,
      isActiveInAllProjects,
      latestLastLoginDateTimeString,
    };
  },
);

const findUserCollectionGroupsInEnvironment = memoize.weak(
  (user: ISubscriptionUserWithSettings, environmentId: Uuid): ReadonlyArray<ICollectionGroup> => {
    const collectionGroups = user.userProjectsSettings.find(
      (setting) => setting.projectId === environmentId,
    )?.collectionGroups;
    return collectionGroups ?? [];
  },
);
