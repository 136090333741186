import { forwardRef } from 'react';
import { ModalDialog } from '../../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { NewContentItemForm } from '../containers/NewContentItemForm.tsx';

type Props = {
  readonly disabledSubmitTooltip: string | undefined;
  readonly headline: string;
  readonly isOpen: boolean;
  readonly isSubmitButtonShown: boolean;
  readonly onClose: () => void;
  readonly onReturnFocus?: () => void;
  readonly onSubmit: () => void;
};

export const NewContentItemDialog = forwardRef<HTMLDivElement, Props>(
  (
    {
      disabledSubmitTooltip,
      headline,
      isOpen,
      isSubmitButtonShown,
      onClose,
      onReturnFocus,
      onSubmit,
    },
    ref,
  ) => (
    <ModalDialog
      headline={headline}
      isDismissable
      isOpen={isOpen}
      primaryAction={
        isSubmitButtonShown
          ? {
              disabled: !!disabledSubmitTooltip,
              onClick: onSubmit,
              text: 'Create',
              tooltipText: disabledSubmitTooltip,
              ...getDataUiActionAttribute(DataUiAction.Confirm),
            }
          : undefined
      }
      onClose={onClose}
      onReturnFocus={onReturnFocus}
      ref={ref}
      {...getDataUiElementAttribute(DataUiElement.Dialog)}
    >
      <NewContentItemForm onClose={onClose} />
    </ModalDialog>
  ),
);

NewContentItemDialog.displayName = 'NewContentItemDialog';
