import PropTypes from 'prop-types';
import React, { RefObject, forwardRef, useState } from 'react';
import { CommonDropDownProps, DropDownFrame, commonDropDownPropTypes } from './DropDownFrame.tsx';

type StatefulDropDownProps = CommonDropDownProps & {
  readonly isOpen?: boolean;
  readonly onClose?: () => void;
  readonly onOpen?: () => void;
  readonly renderContent: (onHideMenu: () => void) => React.ReactNode;
  readonly renderSelectedOption: (
    ref: RefObject<HTMLElement>,
    onClick: () => void,
    isOptionListVisible: boolean,
  ) => React.ReactNode;
};

const statefulDropDownPropTypes: PropTypesShape<StatefulDropDownProps> = {
  ...commonDropDownPropTypes,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  renderContent: PropTypes.func.isRequired,
  renderSelectedOption: PropTypes.func.isRequired,
};

const useIsDropDownVisible = (
  overrideValue?: boolean,
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
  const [isVisible, setIsVisible] = useState(false);
  const isDropDownVisible = overrideValue !== undefined ? overrideValue : isVisible;

  return [isDropDownVisible, setIsVisible];
};

export const StatefulDropDown = forwardRef<HTMLElement, StatefulDropDownProps>(
  ({ isOpen, onClose, onOpen, renderSelectedOption, renderContent, ...rest }, ref) => {
    const [isDropDownVisible, setIsDropdownVisible] = useIsDropDownVisible(isOpen);

    const showDropDown = () => {
      if (!isDropDownVisible) {
        setIsDropdownVisible(true);
        onOpen?.();
      }
    };

    const hideDropDown = () => {
      if (isDropDownVisible) {
        setIsDropdownVisible(false);
        onClose?.();
      }
    };

    return (
      <DropDownFrame
        ref={ref}
        isOptionListVisible={isDropDownVisible}
        onClickOutside={hideDropDown}
        renderContent={() => renderContent(hideDropDown)}
        renderSelector={(selectorRef) =>
          renderSelectedOption(
            selectorRef,
            isDropDownVisible ? hideDropDown : showDropDown,
            isDropDownVisible,
          )
        }
        {...rest}
      />
    );
  },
);

StatefulDropDown.displayName = 'StatefulDropDown';
StatefulDropDown.propTypes = statefulDropDownPropTypes;
