import {
  FeatureToggleConsent,
  FeatureToggleValue,
} from '../../applications/environmentSettings/innovationLab/types/InnovationLabProjectProperty.type.ts';
import { AiFeatureSet } from '../constants/AiFeatureSet.ts';
import {
  AiFeatureSet as AiFeatureSetKey,
  AssetCollectionMandatory,
  AssetReplacement,
  MissionControlConsent,
  MissionControlFeature,
  MultiselectOptimization,
  SecureAssets,
  SignalRAlternativeTransport,
  SpacesInCollections,
  WebSpotlightPreviewAutoRefresh,
} from './ProjectPropertiesServerKeys.ts';

export type ProjectPropertiesModel = {
  AiFeatureSet: ReadonlyArray<AiFeatureSet>;
  AssetCollectionMandatory: FeatureToggleValue;
  AssetReplacement: FeatureToggleValue;
  MissionControl: FeatureToggleValue;
  MissionControlConsentInfo: FeatureToggleConsent;
  MultiselectOptimization: FeatureToggleValue;
  SecureAssets: FeatureToggleValue;
  SignalRAlternativeTransport: FeatureToggleValue;
  SpacesInCollections: FeatureToggleValue;
  WebSpotlightPreviewAutoRefresh: FeatureToggleValue;
};

/**
 * Ensure that the default value of added property  won't affect UI tests or Screen Comparison.
 * You can set the right value in KenticoCloud.UI.Tests repository at TestDataFactory\Provider\ProjectProvider.cs.
 * If you are removing the property from here, make sure code managing the property in UI tests is also removed.
 */
export const ProjectPropertiesStateKeysByServerKeys: Dictionary<keyof ProjectPropertiesModel> = {
  [AiFeatureSetKey]: 'AiFeatureSet',
  [AssetCollectionMandatory]: 'AssetCollectionMandatory',
  [AssetReplacement]: 'AssetReplacement',
  [MissionControlConsent]: 'MissionControlConsentInfo',
  [MissionControlFeature]: 'MissionControl',
  [MultiselectOptimization]: 'MultiselectOptimization',
  [SecureAssets]: 'SecureAssets',
  [SignalRAlternativeTransport]: 'SignalRAlternativeTransport',
  [SpacesInCollections]: 'SpacesInCollections',
  [WebSpotlightPreviewAutoRefresh]: 'WebSpotlightPreviewAutoRefresh',
};
