import { animated, useChain, useSpring, useSpringRef, useTransition } from '@react-spring/web';
import React, { memo, useRef, useState } from 'react';

type Props = {
  readonly estimatedMaxHeightWhenExpanded: number;
  readonly heightWhenCollapsed: number;
  readonly renderCollapsed: () => React.ReactElement | null;
  readonly renderExpanded: () => React.ReactElement | null;
  readonly shouldBeExpanded: boolean;
};

const springExpandDurationMs = 150;
const springCollapseDurationMs = 250;
const transitionDurationMs = 200;
const chainDelaySeconds = springExpandDurationMs / 1000;

const ExpandCollapseAnimation: React.FC<Props> = ({
  estimatedMaxHeightWhenExpanded, // If the exact value is not available, prefer setting this to a slightly higher value than the expected content height, rather than smaller. The animation will look better that way (but it should look good-enough even if the guessed value is slightly smaller, and gets exceeded).
  heightWhenCollapsed,
  renderCollapsed,
  renderExpanded,
  shouldBeExpanded,
}) => {
  const springRef = useSpringRef();
  const transitionRef = useSpringRef();

  const [isSpringInProgress, setIsSpringInProgress] = useState(false);
  const [isTransitionInProgress, setIsTransitionInProgress] = useState(false);

  const expandedRef = useRef<HTMLDivElement>(null);
  const [expandedHeight, setExpandedHeight] = useState(estimatedMaxHeightWhenExpanded);

  const spring = useSpring({
    height: shouldBeExpanded ? expandedHeight : heightWhenCollapsed,
    config: { duration: shouldBeExpanded ? springExpandDurationMs : springCollapseDurationMs },
    ref: springRef,
    onStart: () => setIsSpringInProgress(true),
    onRest: () => setIsSpringInProgress(false),
  });

  const transitions = useTransition(shouldBeExpanded, {
    initial: { opacity: 1 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: transitionDurationMs },
    ref: transitionRef,
    onStart: () => setIsTransitionInProgress(true),
    onRest: () => {
      setIsTransitionInProgress(false);

      if (expandedRef?.current?.scrollHeight) {
        setExpandedHeight(expandedRef.current.scrollHeight);
      }
    },
  });

  useChain(shouldBeExpanded ? [springRef, transitionRef] : [transitionRef, springRef], [
    0,
    chainDelaySeconds,
  ]);

  const heightProperty = shouldBeExpanded
    ? 'maxHeight' // when expanding, the height might be a guess, so we don't want to expand the item beyond its actual height
    : 'height'; // when collapsing, only the collapsed version is rendered, so we use height for a smooth animation (to prevent jumping)

  // Ideally, we'd render (and thus cross-fade) the expanded and collapsed variants within transitions below,
  // as react-spring docs suggest. However, using divs with position: absolute is needed for that,
  // and unfortunately, those would break styling in all the places the animation is used.
  const shouldRenderCollapsed = !shouldBeExpanded && !isTransitionInProgress;

  return (
    <animated.div
      style={isSpringInProgress ? { [heightProperty]: spring.height } : {}}
      css={
        // hide empty div, but preserve in the DOM while spring is in progress to prevent jumping of elements
        isSpringInProgress
          ? undefined
          : `
        &:empty {
          display: none;
        }
      `
      }
    >
      {shouldRenderCollapsed && renderCollapsed()}
      {transitions(
        (styles, shouldRenderExpanded) =>
          shouldRenderExpanded && (
            <animated.div ref={expandedRef} style={styles}>
              {renderExpanded()}
            </animated.div>
          ),
      )}
    </animated.div>
  );
};

ExpandCollapseAnimation.displayName = 'BarItemAnimation';

const ExpandCollapseAnimationMemoized = memo(ExpandCollapseAnimation);
export { ExpandCollapseAnimationMemoized as ExpandCollapseAnimation };
