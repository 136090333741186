import Immutable from 'immutable';
import ImmutablePropTypes from 'immutable-prop-types';
import PropTypes, { Validator } from 'prop-types';
import React from 'react';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IContentType } from '../../../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IRule, IRuleWithScope } from '../../../../../../../data/models/roles/IRoleSettings.ts';
import { RoleEditorStatus } from '../../../../models/RoleEditorStatus.ts';
import { ContentProductionCanRules } from './ContentProductionCanRules.tsx';
import { ContentProductionCannotRules } from './ContentProductionCannotRules.tsx';

export interface IContentProductionRulesProps {
  readonly allTypes: Immutable.Map<Uuid, IContentType>;
  readonly isDisabled: boolean;
  readonly contentCanRules: ReadonlyArray<IRuleWithScope>;
  readonly contentCannotRules: ReadonlyArray<IRule>;
  readonly onCanRulesChanged: (newRules: ReadonlyArray<IRuleWithScope>) => void;
  readonly onCannotRulesChanged: (newRules: ReadonlyArray<IRule>) => void;
  readonly status: RoleEditorStatus;
}

export const ContentProductionRules: React.ComponentType<IContentProductionRulesProps> = ({
  allTypes,
  isDisabled,
  contentCanRules,
  contentCannotRules,
  onCanRulesChanged,
  onCannotRulesChanged,
  status,
}) => (
  <div className="capability-rules__pane capability-rules__pane--expanded">
    <div
      {...getDataUiCollectionAttribute(DataUiCollection.CapabilityRules)}
      className="capability-rules__section"
    >
      <ContentProductionCanRules
        isDisabled={isDisabled}
        contentCanRules={contentCanRules}
        allTypes={allTypes}
        onChange={onCanRulesChanged}
        status={status}
      />
    </div>
    <div
      {...getDataUiCollectionAttribute(DataUiCollection.CapabilityRuleExceptions)}
      className="capability-rules__section"
    >
      <ContentProductionCannotRules
        isDisabled={isDisabled}
        contentCannotRules={contentCannotRules}
        allTypes={allTypes}
        onChange={onCannotRulesChanged}
        status={status}
      />
    </div>
  </div>
);

const propTypes: PropTypeMap<IContentProductionRulesProps> = {
  isDisabled: PropTypes.bool.isRequired,
  allTypes: ImmutablePropTypes.map.isRequired,
  contentCanRules: PropTypes.arrayOf(PropTypes.object.isRequired as Validator<IRuleWithScope>)
    .isRequired,
  contentCannotRules: PropTypes.arrayOf(PropTypes.object.isRequired as Validator<IRule>).isRequired,
  onCanRulesChanged: PropTypes.func.isRequired,
  onCannotRulesChanged: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired as Validator<RoleEditorStatus>,
};

ContentProductionRules.propTypes = propTypes;
ContentProductionRules.displayName = 'ContentProductionRules';
