import { assert } from '@kontent-ai/utils';
import { EditorState } from 'draft-js';
import { ClipboardEvent, forwardRef, useCallback } from 'react';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { ActiveCapabilityType } from '../../../../../../../_shared/models/activeCapability.type.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { IUrlSlugTypeElement } from '../../../../../../contentInventory/content/models/contentTypeElements/UrlSlugTypeElement.ts';
import { setRichTextClipboard } from '../../../../../../richText/actions/thunkRichTextActions.ts';
import {
  UrlSlugInput as UrlSlugInputComponent,
  UrlSlugInputProps,
} from '../../../../../../richText/editors/urlSlug/UrlSlugInput.tsx';
import { IFocusable } from '../../../../../../richText/plugins/behavior/FocusPlugin.tsx';
import { EmptyMetadata } from '../../../../../../richText/plugins/clipboard/thunks/setRichTextClipboard.ts';
import { OnAddComment } from '../../../../../../richText/plugins/comments/CommentsPlugin.tsx';
import { IApprovedSuggestion } from '../../../../../../richText/plugins/comments/api/editorSuggestionUtils.ts';
import { EmptyContentComponents } from '../../../../../models/contentItem/ContentComponent.ts';
import {
  getCommentThreadIdByExternalSegmentIdMappingForElement,
  getFocusedCommentThreadIdForElement,
  getTextElementCommentThreadsByExternalSegmentId,
} from '../../../../../selectors/elementCommentThreads.ts';
import { IUrlSlugWarningResult } from '../../../../../utils/itemElementWarningCheckers/types/IUrlSlugWarningResult.type.ts';
import { getValidationSelectorId } from '../../../../../utils/itemElementWarningCheckers/utils/getValidationSelectorId.ts';
import { focusedCommentThreadChanged } from '../../../actions/contentItemEditingActions.ts';
import {
  addCommentToRichTextElement,
  blurCommentThread,
  markSuggestionAsApproved,
} from '../../../actions/thunkContentItemEditingActions.ts';
import { ReadonlyEmptyElementPlaceholder } from '../../../models/ReadonlyEmptyElementPlaceholder.ts';
import { getErrorMessages } from '../../../utils/itemValidationUtils.ts';
import { useItemElementReference } from '../../hooks/useItemElementReference.ts';

type UrlSlugInputOwnProps = Omit<
  UrlSlugInputProps,
  | 'allowCreateCommentThread'
  | 'approvedSuggestions'
  | 'commentThreadIdMapping'
  | 'commentThreads'
  | 'errorMessages'
  | 'focusedCommentThreadId'
  | 'limitations'
  | 'onAddComment'
  | 'onBlurCommentThread'
  | 'onFocusCommentThread'
  | 'onSuggestionApplied'
  | 'placeholder'
  | 'focusableRef'
  | 'setRichTextClipboard'
  | 'validationResult'
>;

interface IUrlSlugInputContainerProps extends UrlSlugInputOwnProps {
  readonly typeElement: IUrlSlugTypeElement;
}

export const UrlSlugInput = forwardRef<IFocusable, IUrlSlugInputContainerProps>((props, ref) => {
  const { disabled, typeElement } = props;
  const { elementId } = typeElement;
  const element = useItemElementReference(props.typeElement);
  const contentComponentId = element?.contentComponentId;
  const validationResultSelectorId = getValidationSelectorId(
    elementId,
    contentComponentId ?? undefined,
  );

  const allowCreateCommentThread = useSelector((s) =>
    hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.ViewContent, s),
  );
  const commentThreads = useSelector((s) =>
    getTextElementCommentThreadsByExternalSegmentId(
      s.contentApp.editedContentItemVariantComments.commentThreads,
      elementId,
      contentComponentId ?? null,
    ),
  );
  const commentThreadIdMapping = useSelector((s) =>
    getCommentThreadIdByExternalSegmentIdMappingForElement(
      s.contentApp.editedContentItemVariantComments.commentThreads,
      elementId,
      contentComponentId ?? null,
    ),
  );
  const focusedCommentThreadId = useSelector((s) =>
    getFocusedCommentThreadIdForElement(
      s.contentApp.editedContentItemVariantComments.commentThreads,
      elementId,
      contentComponentId ?? null,
      s.contentApp.editedContentItemVariantComments.focusedCommentThreadId,
    ),
  );
  const approvedSuggestions = useSelector((s) => s.contentApp.editorUi.approvedSuggestions);
  const validationResult = useSelector(
    (s) =>
      s.contentApp.itemValidationWarnings.get(validationResultSelectorId) as IUrlSlugWarningResult,
  );
  const errorMessages = useSelector((s) =>
    getErrorMessages(
      s.contentApp.itemValidationErrors,
      s.contentApp.itemValidationWarnings,
      validationResultSelectorId,
      s.contentApp.showIncompleteItemWarningsBeforePublish,
    ),
  );

  const dispatch = useDispatch();
  const onSetRichTextClipboard = useCallback(
    (e: ClipboardEvent, editorState: EditorState) =>
      dispatch(setRichTextClipboard(e, editorState, EmptyContentComponents, EmptyMetadata)),
    [],
  );
  const onAddComment: OnAddComment = useCallback(
    (editorState, type, api) => {
      assert(element, () => 'Missing edited element reference');
      return dispatch(addCommentToRichTextElement(element, editorState, type, api));
    },
    [element],
  );
  const onBlurCommentThread = useCallback(() => dispatch(blurCommentThread()), []);
  const onFocusCommentThread = useCallback(
    (threadId: Uuid) => dispatch(focusedCommentThreadChanged(threadId)),
    [],
  );
  const onSuggestionApplied = useCallback(
    (suggestion: IApprovedSuggestion) =>
      dispatch(markSuggestionAsApproved(suggestion.commentThreadId, suggestion.suggestionId)),
    [],
  );

  return (
    <UrlSlugInputComponent
      {...props}
      allowCreateCommentThread={allowCreateCommentThread}
      approvedSuggestions={approvedSuggestions}
      commentThreadIdMapping={commentThreadIdMapping}
      commentThreads={commentThreads}
      errorMessages={errorMessages}
      focusedCommentThreadId={focusedCommentThreadId}
      limitations={typeElement}
      onAddComment={onAddComment}
      onBlurCommentThread={onBlurCommentThread}
      onFocusCommentThread={onFocusCommentThread}
      onSuggestionApplied={onSuggestionApplied}
      placeholder={
        disabled ? ReadonlyEmptyElementPlaceholder.UrlSlugElement : 'Provide a URL slug…'
      }
      focusableRef={ref}
      setRichTextClipboard={onSetRichTextClipboard}
      validationResult={validationResult}
    />
  );
});

UrlSlugInput.displayName = 'UrlSlugInputContainer';
