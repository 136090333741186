import { useController, useWatch } from 'react-hook-form';
import { HookFormProps } from '../../../../_shared/types/hookFormProps.ts';
import { EntityWebhookEntityType } from '../../../../data/models/webhooks/EntityWebhookEntityType.ts';
import {
  EntityWebhookAction,
  EntityWebhookDeliverySlot,
} from '../../../../data/models/webhooks/EntityWebhookSetting.ts';
import { EntityWebhookEventTriggerBar } from '../../containers/settings/EntityWebhookEventTriggerBar.tsx';
import { IEntityWebhookFormShape } from '../../models/IEntityWebhookFormShape.type.ts';
import { hasNoCheckedEntityTrigger } from '../../utils/entityTriggersChecks.ts';
import { EntityWebhookValidationError } from './EntityWebhookValidationError.tsx';

const assetTriggers: ReadonlyArray<IEventTrigger> = [
  {
    title: 'Asset created',
    action: EntityWebhookAction.Created,
  },
  {
    title: 'Asset updated',
    action: EntityWebhookAction.Changed,
  },
  {
    title: 'Asset deleted',
    action: EntityWebhookAction.Deleted,
  },
  {
    title: 'Asset metadata changed',
    action: EntityWebhookAction.MetadataChanged,
  },
];
const contentTypeTriggers: ReadonlyArray<IEventTrigger> = [
  {
    title: 'Content type created',
    action: EntityWebhookAction.Created,
  },
  {
    title: 'Content type updated',
    action: EntityWebhookAction.Changed,
  },
  {
    title: 'Content type deleted',
    action: EntityWebhookAction.Deleted,
  },
];
const contentItemLiveTriggers: ReadonlyArray<IEventTrigger> = [
  {
    title: 'Content item metadata changed',
    action: EntityWebhookAction.MetadataChanged,
  },
  {
    title: 'Content item variant published',
    action: EntityWebhookAction.Published,
  },
  {
    title: 'Content item variant unpublished',
    action: EntityWebhookAction.Unpublished,
  },
];
const contentItemPreviewTriggers: ReadonlyArray<IEventTrigger> = [
  {
    title: 'Content item metadata changed',
    action: EntityWebhookAction.MetadataChanged,
  },
  {
    title: 'Content item variant created',
    action: EntityWebhookAction.Created,
  },
  {
    title: 'Content item variant updated',
    action: EntityWebhookAction.Changed,
  },
  {
    title: 'Content item variant deleted',
    action: EntityWebhookAction.Deleted,
  },
  {
    title: 'Workflow step changed to',
    action: EntityWebhookAction.WorkflowStepChanged,
  },
];
const taxonomyTriggers: ReadonlyArray<IEventTrigger> = [
  {
    title: 'Taxonomy group created',
    action: EntityWebhookAction.Created,
  },
  {
    title: 'Taxonomy group metadata changed',
    action: EntityWebhookAction.MetadataChanged,
  },
  {
    title: 'Taxonomy group deleted',
    action: EntityWebhookAction.Deleted,
  },
  {
    title: 'Taxonomy term created',
    action: EntityWebhookAction.TermCreated,
  },
  {
    title: 'Taxonomy term changed',
    action: EntityWebhookAction.TermChanged,
  },
  {
    title: 'Taxonomy term deleted',
    action: EntityWebhookAction.TermDeleted,
  },
  {
    title: 'Taxonomy terms moved',
    action: EntityWebhookAction.TermsMoved,
  },
];
const languageTriggers: ReadonlyArray<IEventTrigger> = [
  {
    title: 'Language created',
    action: EntityWebhookAction.Created,
  },
  {
    title: 'Language updated',
    action: EntityWebhookAction.Changed,
  },
  {
    title: 'Language deleted',
    action: EntityWebhookAction.Deleted,
  },
];

export type IEventTrigger = {
  readonly action: EntityWebhookAction;
  readonly title: string;
};

type EventTriggerBarListingProps = {
  readonly enabled: boolean;
  readonly formProps: HookFormProps<IEntityWebhookFormShape>;
};

export const EntityWebhookEventTriggerBarListing = ({
  enabled,
  formProps,
}: EventTriggerBarListingProps) => {
  const { control } = formProps;
  const { field } = useController({ control, name: 'triggers' });
  useWatch({ control, name: 'triggers.slot' });

  return (
    <>
      {hasNoCheckedEntityTrigger(field.value) && (
        <EntityWebhookValidationError message="Please select at least one trigger event." />
      )}
      <ul className="bar-item__list">
        <EntityWebhookEventTriggerBar
          enabled={enabled}
          eventTriggerName="Content item"
          eventTriggerType={EntityWebhookEntityType.ContentItem}
          eventTriggers={
            field.value.slot === EntityWebhookDeliverySlot.Published
              ? contentItemLiveTriggers
              : contentItemPreviewTriggers
          }
          fieldNameTriggers="triggers.contentItemTrigger"
          formProps={formProps}
          hasEmptyFilters={
            field.value.contentItemTrigger.contentTypeIds.length < 1 ||
            field.value.contentItemTrigger.collectionIds.length < 1 ||
            field.value.contentItemTrigger.languageIds.length < 1 ||
            (field.value.slot === EntityWebhookDeliverySlot.Preview &&
              field.value.contentItemTrigger.actions.includes(
                EntityWebhookAction.WorkflowStepChanged,
              ) &&
              field.value.contentItemTrigger.workflowSteps.length < 1)
          }
        />
        <EntityWebhookEventTriggerBar
          enabled={enabled}
          eventTriggerName="Content type"
          eventTriggerType={EntityWebhookEntityType.ContentType}
          eventTriggers={contentTypeTriggers}
          fieldNameTriggers="triggers.contentTypeTrigger"
          formProps={formProps}
          hasEmptyFilters={
            field.value.contentTypeTrigger.contentTypeIds.length < 1 &&
            field.value.contentItemTrigger.actions.some((a) => a !== EntityWebhookAction.Created)
          }
        />
        <EntityWebhookEventTriggerBar
          enabled={enabled}
          eventTriggerName="Asset"
          eventTriggerType={EntityWebhookEntityType.Asset}
          eventTriggers={assetTriggers}
          fieldNameTriggers="triggers.assetTrigger"
          formProps={formProps}
          hasEmptyFilters={false}
        />
        <EntityWebhookEventTriggerBar
          enabled={enabled}
          eventTriggerName="Taxonomy"
          eventTriggerType={EntityWebhookEntityType.Taxonomy}
          eventTriggers={taxonomyTriggers}
          fieldNameTriggers="triggers.taxonomyTrigger"
          formProps={formProps}
          hasEmptyFilters={
            field.value.taxonomyTrigger.taxonomyIds.length < 1 &&
            field.value.taxonomyTrigger.actions.some((a) => a !== EntityWebhookAction.Created)
          }
        />
        <EntityWebhookEventTriggerBar
          enabled={enabled}
          eventTriggerName="Language"
          eventTriggerType={EntityWebhookEntityType.Language}
          eventTriggers={languageTriggers}
          fieldNameTriggers="triggers.languageTrigger"
          formProps={formProps}
          hasEmptyFilters={
            field.value.languageTrigger.languageIds.length < 1 &&
            field.value.languageTrigger.actions.some((a) => a !== EntityWebhookAction.Created)
          }
        />
      </ul>
    </>
  );
};

EntityWebhookEventTriggerBarListing.displayName = 'EntityWebhookEventTriggerBarListing';
