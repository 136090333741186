import { ValidationConstants } from '../../../../_shared/constants/validationConstants.ts';
import { isEmptyOrWhitespace } from '../../../../_shared/utils/stringUtils.ts';
import { ContentItemElementPatchPath } from '../../../../repositories/serverModels/INewContentItemServerModel.ts';
import { IContentItemElementServerModel } from '../../../../repositories/serverModels/elementServerModels.type.ts';
import { untitledContentItem } from '../../../contentInventory/content/constants/uiConstants.ts';
import { ICompiledContentItemElementData } from '../../models/contentItemElements/ICompiledContentItemElement.ts';
import {
  getItemElementDomainModelConverter,
  getItemElementServerModelConverter,
} from '../../utils/getItemElementDataConverters.ts';
import { IItemElementServerModelConverterDataDependencies } from '../../utils/itemElementDataConverters/types/IItemElementDataConverters.type.ts';

export type IConvertElementsToDomainModel = (
  elements: ReadonlyArray<IContentItemElementServerModel> | null,
) => ReadonlyArray<ICompiledContentItemElementData>;

export const convertElementsToDomainModel: IConvertElementsToDomainModel = (
  elements: ReadonlyArray<IContentItemElementServerModel> | null,
): ReadonlyArray<ICompiledContentItemElementData> => {
  const rawElements: ReadonlyArray<IContentItemElementServerModel> = elements || [];
  const preparedElements = rawElements.map((element) => {
    const converter = getItemElementDomainModelConverter(element.type);
    const convertedElement = converter(element);
    return convertedElement;
  });
  return preparedElements;
};

function ensureContentEntryNameLength(name: string): string {
  return name ? name.substring(0, ValidationConstants.ContentItemNameMaxLength) : name;
}

export function ensureContentItemNameValidity(contentItemName: string): string {
  // Discard name with only whitespace characters
  return isEmptyOrWhitespace(contentItemName) ? '' : ensureContentEntryNameLength(contentItemName);
}

export function getNotEmptyItemName(name: string): string {
  return isEmptyOrWhitespace(name) ? untitledContentItem : name;
}

export function convertElementToServerModel(
  element: ICompiledContentItemElementData,
  dataDependencies: IItemElementServerModelConverterDataDependencies,
): IContentItemElementServerModel {
  return getItemElementServerModelConverter(element.type)(element, dataDependencies);
}

export const createItemElementPatchPath = (elementId: Uuid): ContentItemElementPatchPath =>
  `/contentElements/${elementId}`;
