import { Collection } from '@kontent-ai/utils';
import { IRole } from '../../../../data/models/roles/IRole.ts';
import { IWorkflowStep, emptyWorkflowStep } from '../../../../data/models/workflow/WorkflowStep.ts';
import { getDeletedRole } from '../constants/getDeletedRole.ts';

const getPreviousStep = (allSteps: ReadonlyArray<IWorkflowStep>, stepId: Uuid): IWorkflowStep => {
  const indexOfThisStep = allSteps.findIndex((s) => s.id === stepId);

  return allSteps[indexOfThisStep - 1] || emptyWorkflowStep;
};

export const getTransitionFromSteps = (
  allSteps: ReadonlyArray<IWorkflowStep>,
  stepId: Uuid,
  isNew: boolean,
): ReadonlyArray<IWorkflowStep> =>
  isNew
    ? [getPreviousStep(allSteps, stepId)]
    : allSteps.filter(
        (currentStep: IWorkflowStep) =>
          currentStep.id !== stepId && currentStep.transitionsTo.has(stepId),
      );

export const getTransitionToSteps = (
  allSteps: ReadonlyArray<IWorkflowStep>,
  step: IWorkflowStep,
): ReadonlyArray<IWorkflowStep> =>
  allSteps.filter((currentStep: IWorkflowStep) => step.transitionsTo.has(currentStep.id));

export const getDeletedRoles = (
  allRoles: ReadonlyArray<IRole>,
  roleIds: ReadonlySet<Uuid>,
): ReadonlyArray<IRole> =>
  Collection.getValues(roleIds)
    .filter((id) => !allRoles.some((role) => !!role && role.id === id))
    .map(getDeletedRole);
