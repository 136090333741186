import { Button, RouterLinkButton } from '@kontent-ai/component-library/Button';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import {
  CreateNewContentTypeRoute,
  EnvironmentRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { CantCreateOrUseContentTypesTooltipMessage } from '../../../../contentInventory/content/features/ContentItemInventory/constants/uiConstants.ts';

interface IEmptyContentTypePlaceholderProps {
  readonly canCreateContentTypes: boolean;
  readonly onCreateNewTypeClick: () => void;
  readonly projectId: Uuid;
}

export const EmptyContentTypePlaceholder: React.FC<IEmptyContentTypePlaceholderProps> = ({
  canCreateContentTypes,
  onCreateNewTypeClick,
  projectId,
}) => (
  <div css="max-width: 350px">
    <Stack spacing={Spacing.M}>
      <p>
        Each content item is based on a specific content type. You need to create at least one
        content type in order to create a content item.
      </p>
      {canCreateContentTypes ? (
        <RouterLinkButton
          buttonStyle="primary"
          buttonDisplay="block"
          onPress={onCreateNewTypeClick}
          size="medium"
          to={buildPath<EnvironmentRouteParams>(CreateNewContentTypeRoute, { projectId })}
          {...getDataUiActionAttribute(DataUiAction.CreateNew)}
        >
          Create new Content type
        </RouterLinkButton>
      ) : (
        <Button
          buttonDisplay="block"
          buttonStyle="primary"
          disabled
          onClick={onCreateNewTypeClick}
          size="medium"
          tooltipPlacement="right"
          tooltipText={CantCreateOrUseContentTypesTooltipMessage}
          {...getDataUiActionAttribute(DataUiAction.CreateNew)}
        >
          Create new Content type
        </Button>
      )}
    </Stack>
  </div>
);

EmptyContentTypePlaceholder.displayName = 'EmptyContentTypePlaceholder';
