import PropTypes from 'prop-types';
import React, { ReactNode } from 'react';

interface INoUsageProps {
  readonly children: ReactNode;
}

export const NoUsage: React.FC<INoUsageProps> = ({ children }) => (
  <div className="sidebar__section-content">{children}</div>
);

const propTypes: PropTypesShape<INoUsageProps> = {
  children: PropTypes.node.isRequired,
};

NoUsage.displayName = 'NoUsage';
NoUsage.propTypes = propTypes;
