import { Collection } from '@kontent-ai/utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { BarItemExpandCollapseAction } from '../../../../../_shared/components/BarItems/Actions/BarItemExpandCollapseAction.tsx';
import { BarItemActions } from '../../../../../_shared/components/BarItems/BarItemActions.tsx';
import { SearchPhraseHighlighter } from '../../../../../_shared/components/Highlighting/SearchPhraseHighlighter.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { stopPropagation } from '../../../../../_shared/utils/func/functionalTools.ts';
import {
  isEmptyOrWhitespace,
  pluralizeWithCount,
} from '../../../../../_shared/utils/stringUtils.ts';
import { IProjectDetails } from '../../../../../data/models/projects/ProjectDetails.ts';
import {
  IUserProjectSettingActiveStatus,
  UserProjectSettingsStatus,
} from '../../containers/UserDetail/UserProjectSettingsStatus.tsx';
import { IUserProjectSettingsViewModel } from '../../models/UserProjectSettingsViewModel.ts';
import {
  ProjectSettingsFullTextFields,
  ProjectSettingsSearchResult,
} from '../../utils/userProjectSettingsFilterUtils.ts';
import { UserProjectSettingsDetail } from './UserProjectSettingsDetail.tsx';

type UserProjectSettingsProps = {
  readonly activeStatus: IUserProjectSettingActiveStatus;
  readonly isExpanded: boolean;
  readonly masterProject: IProjectDetails;
  readonly projectSettings?: IUserProjectSettingsViewModel;
  readonly searchResult: ProjectSettingsSearchResult;
  readonly userId: Uuid;
  readonly isSingleProject: boolean;

  readonly onNavigateBack: () => void;
  readonly onToggleExpand: () => void;
};

const propTypes: PropTypesShape<UserProjectSettingsProps> = {
  activeStatus: PropTypes.object.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  masterProject: PropTypes.object.isRequired,
  projectSettings: PropTypes.object,
  searchResult: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  isSingleProject: PropTypes.bool.isRequired,

  onNavigateBack: PropTypes.func.isRequired,
  onToggleExpand: PropTypes.func.isRequired,
};

const getFullTextMatches = (
  searchPhrase: string,
  fullTextFields?: ProjectSettingsFullTextFields,
): ReadonlyArray<string> => {
  if (!fullTextFields) {
    return [];
  }

  const environments = fullTextFields.environmentNames;
  const roles = Collection.getValues(fullTextFields.roleNames);
  const languages = Collection.getValues(fullTextFields.languageNames);

  if (isEmptyOrWhitespace(searchPhrase)) {
    return [
      `${pluralizeWithCount('role', roles.length)} in ${pluralizeWithCount(
        'environment',
        environments.length,
      )}`,
    ];
  }

  return environments
    .map((match) => `${match} env.`)
    .concat(roles.map((match) => `${match} role`))
    .concat(languages.map((match) => `${match} lang.`));
};

export const UserProjectSettings: React.FC<UserProjectSettingsProps> = ({
  activeStatus,
  isExpanded,
  masterProject,
  projectSettings,
  searchResult,
  userId,
  isSingleProject,
  onNavigateBack,
  onToggleExpand,
}) => {
  return (
    <div
      className="bar-item__node"
      {...getDataUiElementAttribute(DataUiElement.UserProjectSettings)}
    >
      <div className="bar-item__wrapper">
        <div
          className={classNames('bar-item__pane', 'user-project-settings', {
            'bar-item__pane--is-expanded': isExpanded,
          })}
        >
          <div
            className={classNames('bar-item__bar', 'bar-item__bar--is-clickable', {
              'bar-item__bar--is-expanded': isExpanded,
            })}
          >
            <BarItemActions>
              <BarItemExpandCollapseAction
                isCollapsed={!isExpanded}
                onCollapse={onToggleExpand}
                onExpand={onToggleExpand}
              />
            </BarItemActions>
            <div className="bar-item__title-pane" onClick={onToggleExpand}>
              <div className="bar-item__title user-project-settings__project-name">
                {masterProject.projectName}
              </div>
              <div className="bar-item__title-description user-project-settings__search-result">
                <SearchPhraseHighlighter
                  searchPhrase={searchResult.searchPhrase}
                  text={
                    searchResult.fullTextMatches
                      ? getFullTextMatches(
                          searchResult.searchPhrase,
                          searchResult.fullTextMatches,
                        ).join(', ')
                      : ''
                  }
                />
              </div>
              <div
                className={classNames(
                  'bar-item__actions-pane user-project-settings__status-actions',
                  {
                    'bar-item__actions-pane user-project-settings__status-actions--invitation-pending':
                      activeStatus.isInvitationPending,
                  },
                )}
                onClick={stopPropagation}
              >
                <UserProjectSettingsStatus
                  activeStatus={activeStatus}
                  userId={userId}
                  isSingleProject={isSingleProject}
                  onNavigateBack={onNavigateBack}
                />
              </div>
            </div>
          </div>
          {isExpanded && (
            <UserProjectSettingsDetail
              onClose={onToggleExpand}
              environmentSettings={projectSettings?.environmentSettings}
              userId={userId}
            />
          )}
        </div>
      </div>
    </div>
  );
};

UserProjectSettings.displayName = 'UserProjectSettings';
UserProjectSettings.propTypes = propTypes;
