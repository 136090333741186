import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Placement } from '@kontent-ai/component-library/types';
import classNames from 'classnames';
import React, { MouseEvent, ReactNode, forwardRef } from 'react';
import {
  DataUiAction,
  getDataAttributeProps,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { ButtonStyle } from './buttonStyle.ts';
import { SquareButtonSize } from './squareButtonSize.ts';

export interface ISquareButtonShellProps {
  readonly appendTooltipToParent?: boolean;
  readonly className?: string;
  readonly children?: ReactNode;
  readonly customButtonClassName?: string;
  readonly dataUiAction?: DataUiAction;
  readonly disabled?: boolean;
  readonly isActive?: boolean;
  readonly screenReaderText: string;
  readonly size?: SquareButtonSize;
  readonly style: ButtonStyle;
  readonly tooltipPlacement?: Placement;
  readonly tooltipShortcuts?: string;
  readonly tooltipText?: string;
  readonly onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  readonly onFocus?: (e: React.FocusEvent) => void;
}

export const SquareButtonShell = forwardRef<HTMLDivElement, ISquareButtonShellProps>(
  ({ appendTooltipToParent, size = SquareButtonSize.M, ...props }, forwardedRef) => {
    return (
      <Tooltip
        placement={props.tooltipPlacement ?? 'bottom'}
        shortcuts={props.tooltipShortcuts}
        tippyOptions={appendTooltipToParent ? { appendTo: 'parent' } : undefined}
        tooltipText={props.isActive ? '' : props.tooltipText}
      >
        <div ref={forwardedRef} className={classNames('btn-wrapper', props.className)}>
          <button
            className={classNames(
              'btn btn--square',
              props.style,
              size,
              props.customButtonClassName,
              { active: props.isActive },
            )}
            onClick={props.onClick}
            onFocus={props.onFocus}
            disabled={props.disabled}
            {...getDataAttributeProps(props)}
          >
            {props.children}
            <span className="sr-only">{props.screenReaderText}</span>
          </button>
        </div>
      </Tooltip>
    );
  },
);

SquareButtonShell.displayName = 'SquareButtonShell';
