export enum MapiKeyCapability {
  UNKNOWN = 'UNKNOWN',
  ReadContent = 'read-content',
  ManageContent = 'manage-content',
  ReadAssets = 'read-assets',
  ManageAssets = 'manage-assets',
  ManageContentModel = 'manage-content-model',
  ManageEnvironmentSettings = 'manage-environment-settings',
  ManageEnvironments = 'manage-environments',
}
