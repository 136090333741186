import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {
  renderDatetimeString,
  toFuzzyDateDifference,
  toLocalTime,
} from '../../../../../../../../_shared/utils/dateTime/timeUtils.ts';
import { PublishingState } from '../../../../../../../../data/constants/PublishingState.ts';

interface IContentItemPublishedDate {
  readonly lastPublishedAt: DateTimeStamp | null;
  readonly publishingState: PublishingState;
}

const propTypes: PropTypesShape<IContentItemPublishedDate> = {
  lastPublishedAt: PropTypes.string,
  publishingState: PropTypes.string.isRequired,
};

interface IFormattedPublishedDate {
  readonly publishDateReadable?: string;
  readonly publishDateFuzzy?: string | null;
}

const getFormattedPublishedDate = (
  lastPublishedAt: DateTimeStamp | null,
  publishingState: PublishingState,
): IFormattedPublishedDate => {
  switch (publishingState) {
    case PublishingState.Published: {
      const publishDateLocal = toLocalTime(lastPublishedAt);
      return {
        publishDateReadable: renderDatetimeString(lastPublishedAt) || undefined,
        publishDateFuzzy:
          publishDateLocal && toFuzzyDateDifference(new Date(), publishDateLocal, false),
      };
    }
    case PublishingState.Unpublished:
      return {
        publishDateFuzzy: 'Unpublished',
      };

    default:
      return {
        publishDateFuzzy: '—',
      };
  }
};

export const ContentItemPublishedDate: React.FC<IContentItemPublishedDate> = ({
  lastPublishedAt,
  publishingState,
}) => {
  const { publishDateFuzzy, publishDateReadable } = getFormattedPublishedDate(
    lastPublishedAt,
    publishingState,
  );

  return (
    <div
      title={publishDateReadable}
      className={classNames('scroll-table__publish-date', {
        'scroll-table__publish-date--is-unpublished':
          publishingState === PublishingState.Unpublished,
        'scroll-table__publish-date--is-none': publishingState === PublishingState.None,
      })}
    >
      {publishDateFuzzy}
    </div>
  );
};

ContentItemPublishedDate.displayName = 'ContentItemPublishedDate';
ContentItemPublishedDate.propTypes = propTypes;
