import { ButtonStyle } from '@kontent-ai/component-library/Button';
import { propagateInstanceToParent } from '@kontent-ai/hooks';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { ContentItemEditingEventOrigins } from '../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { ActionType } from '../../../constants/editingActions/staticDetails.ts';
import { EditingAction as EditingActionEnum } from '../../../models/EditingAction.ts';
import { EditingAction } from '../actions/EditingAction.tsx';
import { QuickActionsButton } from './QuickActionsButton.tsx';

type QuickActionsButtonToActionMapperProps = {
  readonly action: EditingActionEnum;
  readonly actionOrigin: ContentItemEditingEventOrigins;
  readonly isActive?: boolean;
};

const propTypes: PropTypeMap<QuickActionsButtonToActionMapperProps> = {
  action: PropTypes.oneOf(Object.values(EditingActionEnum)).isRequired,
  actionOrigin: PropTypes.oneOf(Object.values(ContentItemEditingEventOrigins)).isRequired,
  isActive: PropTypes.bool,
};

function getButtonStyle(actionType: ActionType): ButtonStyle {
  switch (actionType) {
    case ActionType.Important:
      return 'primary';
    default:
      return 'tertiary';
  }
}

export const QuickActionsButtonToActionMapper = forwardRef<
  HTMLButtonElement,
  QuickActionsButtonToActionMapperProps
>((props, forwardedRef) => (
  <EditingAction action={props.action}>
    {(details) => (
      <QuickActionsButton
        buttonStyle={getButtonStyle(details.actionType)}
        dataAttributes={details.dataUiAttribute}
        disabled={details.isDisabled}
        ref={(e) => propagateInstanceToParent(forwardedRef, e)}
        iconName={details.iconName}
        isActive={props.isActive}
        onClick={() => details.performAction(props.actionOrigin)}
        outwardLinkUrl={details.outwardUrl}
        text={details.caption}
        tooltip={details.tooltip}
        withSubmenu={details.isSubmenu}
      />
    )}
  </EditingAction>
));

QuickActionsButtonToActionMapper.displayName = 'QuickActionsButtonToActionMapper';
QuickActionsButtonToActionMapper.propTypes = propTypes;
