import React, { useEffect } from 'react';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { CreateContentGroupTabsId } from '../../../../../../itemEditor/features/ContentItemEditing/utils/contentGroupTabsId.ts';
import { ensureLoadedContentTypes } from '../../../../../../itemEditor/features/LoadedItems/actions/thunkLoadedItemsActions.ts';
import { IContentComponent } from '../../../../../../itemEditor/models/contentItem/ContentComponent.ts';
import { getSelectedContentGroupIdFromStateOrFirst } from '../../../../../../itemEditor/stores/utils/contentItemElementsUtils.ts';
import { ExpandedContentComponentElements as ExpandedContentComponentElementsComponent } from '../../../components/expanded/elements/ExpandedContentComponentElements.tsx';

interface Props {
  readonly contentComponent: IContentComponent;
  readonly isHighlighted: boolean;
  readonly originalContentComponent?: IContentComponent;
}

export const ExpandedContentComponentElements: React.FC<Props> = (props) => {
  const { contentComponent } = props;

  const dispatch = useDispatch();
  const contentType =
    useSelector((state) =>
      state.contentApp.loadedContentItemTypes.get(contentComponent.contentTypeId),
    ) ?? null;
  const missingContentTypeId = contentType ? null : contentComponent.contentTypeId;

  useEffect(() => {
    if (missingContentTypeId) {
      dispatch(ensureLoadedContentTypes(new Set([missingContentTypeId])));
    }
  }, [missingContentTypeId]);

  const contentGroupTabsId = CreateContentGroupTabsId.forContentComponent(contentComponent.id);
  const selectedContentGroupId = useSelector(
    (state) =>
      contentType &&
      getSelectedContentGroupIdFromStateOrFirst(
        contentGroupTabsId,
        contentType.contentGroups,
        state,
      ),
  );

  return (
    <ExpandedContentComponentElementsComponent
      {...props}
      contentType={contentType}
      selectedContentGroupId={selectedContentGroupId}
    />
  );
};
