import { Collection } from '@kontent-ai/utils';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { Loader } from '../../../../../_shared/components/Loader.tsx';
import { AuthorizedSection } from '../../../../../_shared/components/routing/AuthorizedSection.tsx';
import { ApiKeyDetailAccessDeniedRouteSegment } from '../../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ApiKeyType } from '../../../../../_shared/models/ApiKeyType.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { getCurrentProjectContainer } from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { isProjectManagerInAnyEnvironment } from '../../../root/selectors/isProjectManagerInAnyEnvironment.ts';
import { apiKeyDetailsAlertDismissed } from '../../actions/apiKeysActions.ts';
import { createApiKey, initNewApiKey } from '../../actions/thunkApiKeysActions.ts';
import { ApiKey, emptyApiKey } from '../../models/ApiKey.ts';
import {
  AllEnvironmentsTagId,
  getAllEnvironmentOptions,
} from '../../selectors/getSelectedEnvironmentOptions.ts';
import { isSecureAccessAvailable } from '../../selectors/isSecuredDeliveryApiAvailable.ts';
import { DapiKeyFormShape } from '../../validation/dapiKeyValidationConfig.ts';
import { DapiKeyDetailForm } from './DapiKeyDetailForm.tsx';

export const NewDapiKeyDetail: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isProjectManager = useSelector(isProjectManagerInAnyEnvironment);
  const currentProject = useSelector(getCurrentProjectContainer);
  const allEnvironmentOptions = useSelector((state) =>
    getAllEnvironmentOptions(state, ApiKeyType.DAPI),
  );
  const isDapiSecureAccessAvailable = useSelector(isSecureAccessAvailable);
  const loadingStatus = useSelector((state) => state.apiKeysApp.detailLoadingStatus);
  const actionStatus = useSelector((state) => state.apiKeysApp.detailActionStatus);
  const projectContainerActiveUsersById = useSelector(
    (state) => state.data.projectContainerActiveUsers.byId,
  );

  const projectContainerActiveUsers = useMemo(
    () => Collection.getValues(projectContainerActiveUsersById),
    [projectContainerActiveUsersById],
  );

  useEffect(() => {
    dispatch(initNewApiKey(ApiKeyType.DAPI));
  }, []);

  const onCreateApiKey = useCallback(
    (dapiKeyFormShape: DapiKeyFormShape, isFromUnsavedChangesDialog: boolean) => {
      const hasAccessToAllEnvironments =
        dapiKeyFormShape.environments.includes(AllEnvironmentsTagId);
      const apiKey: ApiKey = {
        ...emptyApiKey,
        environments: hasAccessToAllEnvironments ? [] : dapiKeyFormShape.environments,
        expiresAt: dapiKeyFormShape.expiresAt.value,
        hasAccessToAllEnvironments,
        hasSecureDeliveryAccess: dapiKeyFormShape.access.hasSecureDeliveryAccess,
        hasPreviewDeliveryAccess: dapiKeyFormShape.access.hasPreviewDeliveryAccess,
        name: dapiKeyFormShape.name,
        sharedWithUsers: dapiKeyFormShape.sharedWithUsers,
        type: ApiKeyType.DAPI,
      };

      return dispatch(
        createApiKey(
          apiKey,
          isFromUnsavedChangesDialog ? undefined : history,
          currentProject.projectContainerId,
        ),
      );
    },
    [history, currentProject.projectContainerId],
  );

  if (loadingStatus !== LoadingStatus.Loaded) {
    return <Loader />;
  }

  return (
    <AuthorizedSection
      appName={ApiKeyDetailAccessDeniedRouteSegment}
      isAuthorized={isProjectManager}
      projectId={currentProject.masterEnvironmentId}
      projectContainerId={currentProject.projectContainerId}
    >
      <DapiKeyDetailForm
        allEnvironmentOptions={allEnvironmentOptions}
        apiKey={getNewDapiKey(isDapiSecureAccessAvailable)}
        apiKeyActionStatus={actionStatus}
        hiddenEnvironmentOption={null}
        isProjectManager={isProjectManager}
        isSecureAccessAvailable={isDapiSecureAccessAvailable}
        projectContainerActiveUsers={projectContainerActiveUsers}
        onAlertDismiss={() => dispatch(apiKeyDetailsAlertDismissed())}
        onSave={onCreateApiKey}
      />
    </AuthorizedSection>
  );
};

NewDapiKeyDetail.displayName = 'NewDapiKeyDetail';

const getNewDapiKey = (isDapiSecureAccessAvailable: boolean): ApiKey => ({
  ...emptyApiKey,
  hasPreviewDeliveryAccess: !isDapiSecureAccessAvailable,
  type: ApiKeyType.DAPI,
});
