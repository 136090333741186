import React from 'react';
import { EnsureContentProductionCapabilities } from '../../../_shared/components/EnsureContentProductionCapabilities.tsx';
import { AppNames } from '../../../_shared/constants/applicationNames.ts';
import { AuthorizedSection } from '../../../_shared/containers/routing/AuthorizedSection.tsx';
import { YourContentContextualHelp } from '../../contentInventory/shared/containers/YourContentContextualHelp.tsx';
import { contentRequiredCapabilities } from '../../contentInventory/shared/utils/contentInventoryRequiredCapabilities.ts';
import { YourContent } from '../../yourContent/containers/YourContent.tsx';

export const YourContentSection: React.FC = () => (
  <AuthorizedSection
    appName={AppNames.YourContent}
    requiresOneOfCapabilities={contentRequiredCapabilities}
  >
    <EnsureContentProductionCapabilities appName={AppNames.YourContent}>
      <YourContent />
      <YourContentContextualHelp />
    </EnsureContentProductionCapabilities>
  </AuthorizedSection>
);

YourContentSection.displayName = 'YourContentSection';
