import { Box } from '@kontent-ai/component-library/Box';
import { Spacing, Typography } from '@kontent-ai/component-library/tokens';
import Immutable from 'immutable';
import ImmutablePropTypes from 'immutable-prop-types';
import PropTypes, { Validator } from 'prop-types';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { QuickTip } from '../../../../../../../_shared/components/infos/QuickTip.tsx';
import {
  WorkflowsRoute,
  WorkflowsRouteParams,
} from '../../../../../../../_shared/constants/routePaths.ts';
import { buildPath } from '../../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IContentType } from '../../../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IRoleSettings } from '../../../../../../../data/models/roles/IRoleSettings.ts';
import { emptyContentCanRule } from '../../../../constants/emptyRules.ts';
import { RoleEditorStatus } from '../../../../models/RoleEditorStatus.ts';
import { isAssetScopeRule, isContentScopeRule } from '../../../../utils/ruleScopeUtils.ts';
import { ContentProductionRules } from './ContentProductionRules.tsx';
import { ContentProductionRulesEmptyCanRules } from './ContentProductionRulesEmptyCanRules.tsx';

type Props = {
  readonly allTypes: Immutable.Map<Uuid, IContentType>;
  readonly isDisabled: boolean;
  readonly onSettingsChanged: (settings: IRoleSettings) => void;
  readonly projectId: Uuid;
  readonly roleSettings: IRoleSettings;
  readonly status: RoleEditorStatus;
};

export const ContentItemsCapabilitiesSection: React.FC<Props> = ({
  allTypes,
  isDisabled,
  onSettingsChanged,
  projectId,
  roleSettings,
  status,
}) => {
  const contentCanRules = useMemo(
    () => roleSettings.canRules.filter(isContentScopeRule),
    [roleSettings],
  );
  const assetCanRules = useMemo(
    () => roleSettings.canRules.filter(isAssetScopeRule),
    [roleSettings],
  );
  return (
    <>
      <Box typography={Typography.HeadlineMedium} marginTop={Spacing.XXL} marginBottom={Spacing.L}>
        Content items
      </Box>
      {contentCanRules.length > 0 ? (
        <ContentProductionRules
          allTypes={allTypes}
          contentCanRules={contentCanRules}
          contentCannotRules={roleSettings.cannotRules}
          isDisabled={isDisabled}
          onCanRulesChanged={(newContentCanRules) =>
            onSettingsChanged({
              ...roleSettings,
              canRules: [...newContentCanRules, ...assetCanRules],
            })
          }
          onCannotRulesChanged={(contentCannotRules) =>
            onSettingsChanged({
              ...roleSettings,
              cannotRules: contentCannotRules,
            })
          }
          status={status}
        />
      ) : (
        <ContentProductionRulesEmptyCanRules
          isDisabled={isDisabled}
          onAddRule={() =>
            onSettingsChanged({
              ...roleSettings,
              canRules: [...roleSettings.canRules, emptyContentCanRule],
            })
          }
        />
      )}
      <QuickTip>
        &ldquo;View&rdquo; allows to view content items, assign contributors, add comments, and
        change items’ workflow steps.
        <br />
        &ldquo;Edit&rdquo; allows to edit content items and create and restore versions of items.
        <br />
        &ldquo;Create&rdquo; allows to create new items and variants or convert components to items.
        <br />
        &ldquo;Delete&rdquo; allows to delete content items and variants.
        <br />
        <br />
        {!isDisabled && (
          <>
            Use the section below to <b>set permissions for working with assets</b>.<br /> <br />{' '}
          </>
        )}
        Do you need to <b>customize publishing for this role</b>? Limit which roles can work with
        specific workflow steps in{' '}
        <Link to={buildPath<WorkflowsRouteParams>(WorkflowsRoute, { projectId })}>Workflows</Link>.
        <br />
      </QuickTip>
    </>
  );
};

const propTypes: PropTypeMap<Props> = {
  allTypes: ImmutablePropTypes.map.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onSettingsChanged: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  roleSettings: PropTypes.object.isRequired as Validator<IRoleSettings>,
  status: PropTypes.string.isRequired as Validator<RoleEditorStatus>,
};

ContentItemsCapabilitiesSection.propTypes = propTypes;
ContentItemsCapabilitiesSection.displayName = 'ContentItemsCapabilitiesSection';
