import { Box } from '@kontent-ai/component-library/Box';
import { Card } from '@kontent-ai/component-library/Card';
import React from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { EnvironmentNameLabel } from '../constants/UIConstants.ts';
import { EnvironmentNameEditorElement } from '../containers/EnvironmentNameEditorElement.tsx';

export const EnvironmentNameCard: React.FC = () => {
  return (
    <Card
      cardLabel={EnvironmentNameLabel}
      component="section"
      {...getDataUiElementAttribute(DataUiElement.EnvironmentNameCard)}
    >
      <Card.Headline {...getDataUiElementAttribute(DataUiElement.EnvironmentNameCardTitle)}>
        {EnvironmentNameLabel}
      </Card.Headline>
      <Card.Body>
        <Box {...getDataUiElementAttribute(DataUiElement.EnvironmentNameInput)}>
          <EnvironmentNameEditorElement />
        </Box>
      </Card.Body>
    </Card>
  );
};

EnvironmentNameCard.displayName = 'EnvironmentNameCard';
