import { areShallowEqual } from '@kontent-ai/utils';
import React, { Component, ComponentType, memo } from 'react';
import { Dispatch, ThunkFunction, ThunkPromise } from '../../@types/Dispatcher.type.ts';
import { useDispatch } from '../hooks/useDispatch.ts';
import { useSelector } from '../hooks/useSelector.ts';
import { IStore } from '../stores/IStore.type.ts';
import { createAutoDispatcherRunner } from '../utils/AutoDispatcherRunner.ts';
import { debounce } from '../utils/func/debounce.ts';

// AutoDispatcher is limited to thunks since there's no need to auto-dispatch synchronous actions in this way.
// Callback that returns a synchronous Action could work, but TypeScript can't handle the polymorphism on the return type of the callback.
type Callback<IObservedProps = void> = (props: IObservedProps) => ThunkPromise | ThunkFunction;
type MapState<TWrappedComponentProps, ObservedProps> = (
  state: IStore,
  ownProps: TWrappedComponentProps,
) => ObservedProps;
type ShouldDispatch<TObservedProps> = (
  currentState: IStore,
  nextState: IStore,
  currentObserved: TObservedProps,
  nextObserved: TObservedProps,
) => boolean;
type Decorator<TProps> = (
  WrappedComponent: ComponentType<React.PropsWithChildren<TProps>>,
) => React.FC<React.PropsWithChildren<TProps>>;
type DispatchProps = {
  readonly dispatch: Dispatch;
};

interface IAutoDispatcherProps<TObservedProps extends AnyObject> {
  readonly state: IStore;
  readonly observed: TObservedProps;
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
const runInNonBlockingFashion = <T,>(func: (...args: unknown[]) => T) => debounce<T>(func, 0);

function createAutoDispatchComponent<TWrappedComponentProps, TObservedProps extends AnyObject>(
  WrappedComponent: ComponentType<React.PropsWithChildren<TWrappedComponentProps>>,
  callback: Callback<TObservedProps>,
  debounceTime: number,
  shouldDispatch: ShouldDispatch<TObservedProps>,
  onDispatchPending?: Callback,
  onDispatchCancelled?: Callback,
): ComponentType<
  React.PropsWithChildren<
    TWrappedComponentProps & IAutoDispatcherProps<TObservedProps> & DispatchProps
  >
> {
  type AutoDispatcherProps = TWrappedComponentProps &
    IAutoDispatcherProps<TObservedProps> &
    DispatchProps;

  class AutoDispatcher extends Component<AutoDispatcherProps> {
    static displayName = `AutoDispatcher(${WrappedComponent.displayName})`;

    private _originalObservedState!: TObservedProps; // We need to compare against the state before the last debounce. Not only last two changes.
    private _originalState!: IStore;

    private readonly _storeOriginalState = (): void => {
      this._originalObservedState = this.props.observed;
      this._originalState = this.props.state;
    };

    private readonly _dispatchRunner = createAutoDispatcherRunner<void>(() => {
      this._storeOriginalState();

      return Promise.resolve(this.props.dispatch(callback(this.props.observed) as any));
    }, debounceTime);

    private readonly _shouldDispatch = (nextState: TObservedProps): boolean => {
      if (areShallowEqual(nextState, this._originalObservedState)) {
        return false;
      }

      return shouldDispatch(
        this._originalState,
        this.props.state,
        this._originalObservedState,
        nextState,
      );
    };

    private readonly _cancelDispatch = (): void => {
      this._dispatchRunner.cancel();

      if (onDispatchPending && onDispatchCancelled) {
        this.props.dispatch(onDispatchCancelled() as any);
      }
    };

    // Evaluation of shouldDispatch may be costly with larger states, give React some breathing room
    private readonly _checkDispatch = runInNonBlockingFashion(
      (nextObserved: TObservedProps): { flush: () => void } | undefined => {
        // Now let's evaluate if there was a real change
        if (this._shouldDispatch(nextObserved)) {
          this._dispatchRunner.run();
          return { flush: this._dispatchRunner.flush };
        }

        // If state reverted back to previous which wasn't yet dispatched, cancel the dispatch as it is not needed anymore
        this._cancelDispatch();
        return;
      },
    );

    componentDidMount(): void {
      this._storeOriginalState();
    }

    shouldComponentUpdate(nextProps: AutoDispatcherProps): boolean {
      // Only re-evaluate after a change between immediately following observed props
      // Otherwise you risk frequent UI updates to starve your auto-dispatch
      if (!areShallowEqual(this.props.observed, nextProps.observed)) {
        if (onDispatchPending) {
          this.props.dispatch(onDispatchPending() as any);
        }
        this._checkDispatch(nextProps.observed);
      }

      // Re-render only if properties of the wrapped component changed
      return !areShallowEqual(this.props, nextProps, ['state', 'observed']);
    }

    componentWillUnmount(): void {
      // If there is a pending check dispatch, we need to finish it to make sure pending changes are processed and saved
      this._checkDispatch.now()?.flush();
    }

    render() {
      const { state, observed, ...ownProps } = this.props;

      return <WrappedComponent {...(ownProps as any)} />;
    }
  }

  return AutoDispatcher;
}

export function withAutoDispatcher<TWrappedComponentProps, TObservedProps extends AnyObject>(
  mapObservedState: MapState<TWrappedComponentProps, TObservedProps>,
  callback: Callback<TObservedProps>,
  debounceTime: number,
  shouldDispatch: ShouldDispatch<TObservedProps>,
  onDispatchPending?: Callback,
  onDispatchCancelled?: Callback,
): Decorator<TWrappedComponentProps> {
  return (
    WrappedComponent: ComponentType<React.PropsWithChildren<TWrappedComponentProps>>,
  ): React.FC<React.PropsWithChildren<TWrappedComponentProps>> => {
    const AutoDispatcher = createAutoDispatchComponent<TWrappedComponentProps, TObservedProps>(
      WrappedComponent,
      callback,
      debounceTime,
      shouldDispatch,
      onDispatchPending,
      onDispatchCancelled,
    );

    const ConnectedAutoDispatcher: React.FC<React.PropsWithChildren<TWrappedComponentProps>> = (
      wrappedComponentProps,
    ) => {
      const dispatch = useDispatch();

      const autoDispatcherProps: IAutoDispatcherProps<TObservedProps> = useSelector(
        (state) => ({
          state,
          observed: mapObservedState(state, wrappedComponentProps),
        }),
        areShallowEqual,
      );

      return (
        <AutoDispatcher {...autoDispatcherProps} {...wrappedComponentProps} dispatch={dispatch} />
      );
    };

    ConnectedAutoDispatcher.displayName = `ConnectedAutoDispatcher(${WrappedComponent.displayName})`;

    return memo(ConnectedAutoDispatcher);
  };
}
