import { IBaseSelectItem } from '@kontent-ai/component-library/Selects';
import React from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { shallowEqual } from '../../../../../_shared/utils/shallowEqual.ts';
import {
  getHintForWorkflowsCurrentUserCanCreateVariantIn,
  getWorkflowsCurrentUserCanCreateVariantIn,
} from '../../../selectors/workflows/getWorkflowsCurrentUserCanCreateVariantIn.ts';
import { getTooltipForWorkflowsCurrentUserCanCreateVariantIn } from '../../../utils/workflowTooltipUtils.ts';
import { newContentItemSelectedWorkflowChanged } from '../actions/newContentItemActions.ts';
import { validateSelectedValues } from '../actions/thunkNewContentItemActions.ts';
import { WorkflowSelector } from '../components/WorkflowSelector.tsx';
import { getNewContentItemForm } from '../selectors/newContentItemDialog.ts';

const getWorkflows = (state: IStore): ReadonlyArray<IBaseSelectItem> => {
  const { languageId, collectionId, contentTypeId } = getNewContentItemForm(state);

  const workflows = getWorkflowsCurrentUserCanCreateVariantIn(
    state,
    languageId,
    collectionId,
    contentTypeId,
  );

  return workflows.map(
    ({ id, name }): IBaseSelectItem => ({
      id,
      label: name,
    }),
  );
};

const getWorkflowTooltip = (state: IStore): string | undefined => {
  const { languageId, collectionId, contentTypeId } = getNewContentItemForm(state);

  const hint = getHintForWorkflowsCurrentUserCanCreateVariantIn(
    state,
    languageId,
    collectionId,
    contentTypeId,
  );

  return getTooltipForWorkflowsCurrentUserCanCreateVariantIn(hint);
};

type Props = {
  readonly disabledTooltip: string | undefined;
  readonly index: number;
};

const WorkflowSelectorContainer: React.FC<Props> = ({ disabledTooltip, index }) => {
  const workflows = useSelector(getWorkflows, shallowEqual);
  const disabledTooltipText = useSelector((s) => disabledTooltip || getWorkflowTooltip(s));
  const isSelectedValueShown = !disabledTooltipText || workflows.length === 1;
  const selectedWorkflowId = useSelector((s) =>
    isSelectedValueShown ? getNewContentItemForm(s).workflowId : null,
  );

  const dispatch = useDispatch();
  const selectWorkflow = (workflowId: Uuid): void => {
    dispatch(newContentItemSelectedWorkflowChanged(workflowId));
    dispatch(validateSelectedValues());
  };

  return (
    <WorkflowSelector
      disabledTooltip={disabledTooltipText}
      index={index}
      onSelect={selectWorkflow}
      selectedWorkflowId={selectedWorkflowId}
      workflows={workflows}
    />
  );
};

WorkflowSelectorContainer.displayName = 'WorkflowSelectorContainer';

export { WorkflowSelectorContainer as WorkflowSelector };
