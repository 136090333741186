import React from 'react';
import { IconName } from '../../../../../../_shared/constants/iconEnumGenerated.ts';
import { ControlShortcutTemplate } from '../../../../../../_shared/constants/shortcutSymbols.ts';
import {
  DataUiRteAction,
  getDataUiActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  IForwardedRefProps,
  forwardRef,
} from '../../../../../../_shared/utils/forwardedRefProps.tsx';
import {
  IToggleButtonProps,
  RTEToolbarIconButton,
  toggleButtonPropTypes,
} from '../../../toolbars/components/RTEToolbarButton.tsx';

const BoldButtonComponent: React.FC<
  React.PropsWithChildren<IToggleButtonProps & IForwardedRefProps<HTMLButtonElement>>
> = (props) => (
  <RTEToolbarIconButton
    {...props}
    iconName={IconName.B}
    ref={props.forwardedRef}
    shortcut={ControlShortcutTemplate('B')}
    tooltipPlacement={props.tooltipPlacement ?? 'bottom-start'}
    tooltipText={props.tooltipText ?? 'Bold'}
    {...getDataUiActionAttribute(DataUiRteAction.Bold)}
  />
);

BoldButtonComponent.displayName = 'BoldButton';
BoldButtonComponent.propTypes = toggleButtonPropTypes;

export const BoldButton = forwardRef(BoldButtonComponent);
