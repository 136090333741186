import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { StatusBarActions } from '../../../../_shared/components/StatusBar/StatusBarActions.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { OperationStatus } from '../../../../_shared/models/OperationStatus.ts';
import { createWorkflowEditingLink } from '../../../../_shared/utils/routing/projectSubscriptionRoutingUtils.ts';
import { getCurrentProjectId } from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { saveWorkflowToServer } from '../actions/thunkWorkflowActions.ts';

export const WorkflowCreatorToolbarActions: React.FC = () => {
  const isBeingSaved = useSelector(
    (s) => s.projectsApp.savingStatus.workflow.status === OperationStatus.Pending,
  );
  const isLoading = useSelector(
    (s) => s.workflowsApp.editorUi.workflowLoadingStatus !== LoadingStatus.Loaded,
  );
  const subscriptionId = useSelector((s) => s.subscriptionApp.selectedSubscriptionId);
  const projectId = useSelector(getCurrentProjectId);

  const dispatch = useDispatch();
  const history = useHistory();
  const onSave = useCallback(
    () =>
      dispatch(
        saveWorkflowToServer({
          redirectToWorkflowEditingRoute: (workflowId: Uuid) =>
            history.push(
              createWorkflowEditingLink({
                projectId,
                subscriptionId,
                workflowId,
              }),
            ),
        }),
      ),
    [history, projectId, subscriptionId],
  );

  return <StatusBarActions isBeingSaved={isBeingSaved} isLoading={isLoading} onSave={onSave} />;
};

WorkflowCreatorToolbarActions.displayName = 'WorkflowCreatorToolbarActions';
