import { areShallowEqual } from '@kontent-ai/utils';
import { Action } from '../../../../@types/Action.type.ts';
import { isEditableElement } from '../../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import {
  Content_Editing_AssignmentSubmittingFinished,
  Content_Editing_CancelScheduledPublishingFinished,
  Content_Editing_CancelScheduledUnpublishingFinished,
  Content_Editing_DiscardNewVersionFinished,
  Content_Editing_PublishContentItemVariantFinished,
  Content_Editing_ScheduledPublishingFinished,
  Content_Editing_ScheduledUnpublishingFinished,
  Content_Editing_UnpublishContentItemVariantFinished,
} from '../../constants/editorActionTypes.ts';
import {
  ContentEditing_CascadeAction_UndoFinished,
  ContentEditing_CascadeModal_PublishingFinished,
  ContentEditing_CascadeModal_SchedulingFinished,
} from '../../features/CascadePublish/constants/cascadeModalActionTypes.ts';
import {
  ContentItemEditing_CreateNewVersion_Finished,
  ContentItemEditing_ElementRefresh_Finished,
  ContentItemEditing_ElementValue_Changed,
  ContentItemEditing_HiddenElements_ClearValues,
  ContentItemEditing_Init_Ready,
  ContentItemEditing_UrlSlugContentElements_Update,
  ContentItemEditing_VisibleElements_AddDefaultValues,
} from '../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { ICompiledContentItemElementData } from '../../models/contentItemElements/ICompiledContentItemElement.ts';
import { getElementById, modifyElement } from '../../stores/utils/contentItemElementsUtils.ts';
import { getItemElementEqualityChecker } from '../../utils/getItemElementsEqualityChecker.ts';
import {
  clearValuesOfElementsThatBecameHidden,
  createItemElementWithDefaultValue,
} from '../../utils/itemElementCreator.ts';
import { shouldResetEditedItemState } from '../utils/shouldResetEditedItemState.ts';

function updateChangedElements(
  existingElements: ReadonlyArray<ICompiledContentItemElementData>,
  newElements: ReadonlyArray<ICompiledContentItemElementData>,
): ReadonlyArray<ICompiledContentItemElementData> {
  const updatedElements = newElements.map((newElement) => {
    const existingElement = getElementById(newElement.elementId, existingElements);
    return !existingElement ||
      existingElement.type !== newElement.type ||
      !getItemElementEqualityChecker(newElement.type)(existingElement, newElement)
      ? newElement
      : existingElement;
  });

  return areShallowEqual(updatedElements, existingElements) ? existingElements : updatedElements;
}

const initialState: ReadonlyArray<ICompiledContentItemElementData> = [];

export function editedContentItemVariantElements(
  state = initialState,
  action: Action,
): ReadonlyArray<ICompiledContentItemElementData> {
  if (shouldResetEditedItemState(action)) {
    return initialState;
  }

  switch (action.type) {
    case ContentItemEditing_Init_Ready: {
      return action.payload.editedContentItemVariantElements;
    }

    case ContentEditing_CascadeModal_PublishingFinished:
    case ContentEditing_CascadeModal_SchedulingFinished:
    case ContentEditing_CascadeAction_UndoFinished:
    case Content_Editing_AssignmentSubmittingFinished:
    case Content_Editing_DiscardNewVersionFinished:
    case Content_Editing_PublishContentItemVariantFinished:
    case Content_Editing_UnpublishContentItemVariantFinished:
    case Content_Editing_ScheduledPublishingFinished:
    case Content_Editing_ScheduledUnpublishingFinished:
    case Content_Editing_CancelScheduledPublishingFinished:
    case Content_Editing_CancelScheduledUnpublishingFinished:
    case ContentItemEditing_CreateNewVersion_Finished: {
      return updateChangedElements(
        state,
        action.payload.itemVariantData.editedContentItemVariantElements,
      );
    }

    case ContentItemEditing_ElementValue_Changed:
    case ContentItemEditing_ElementRefresh_Finished: {
      const { elementData } = action.payload;
      return modifyElement(state, elementData.elementId, () => elementData);
    }

    case ContentItemEditing_UrlSlugContentElements_Update: {
      const { urlSlugElements } = action.payload;
      const updatedElements = state.map(
        (element) => urlSlugElements.find((el) => el.elementId === element.elementId) || element,
      );
      return updatedElements;
    }

    case ContentItemEditing_HiddenElements_ClearValues: {
      const { idsOfElementsThatBecameHidden } = action.payload;
      return clearValuesOfElementsThatBecameHidden(state, idsOfElementsThatBecameHidden);
    }

    case ContentItemEditing_VisibleElements_AddDefaultValues: {
      const { visibleElements, typeElements } = action.payload;
      return state.map((element) => {
        const typeElement = typeElements
          .filter(isEditableElement)
          .find(({ elementId }) => elementId === element.elementId);
        return typeElement && visibleElements.find((el) => el.elementId === element.elementId)
          ? createItemElementWithDefaultValue(typeElement)
          : element;
      });
    }

    default: {
      return state;
    }
  }
}
