import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import React from 'react';
import { userStatusOptions } from '../../../../_shared/constants/userListingFilter.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { IStatusFilterOption } from '../../../../_shared/models/UserListingFilterOption.type.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { statusFilterUpdated } from '../actions/subscriptionUserListingActions.ts';

export const SubscriptionUsersStatusFilter: React.FC = () => {
  const dispatch = useDispatch();
  const selectedStatusOptionId = useSelector(
    (s) => s.subscriptionApp.users.listingUi.filter.byStatus,
  );

  const onUpdateFilter = (updatedStatusOptionId: IStatusFilterOption['id']) => {
    if (updatedStatusOptionId !== selectedStatusOptionId) {
      dispatch(statusFilterUpdated(updatedStatusOptionId));
    }
  };

  return (
    <SingleSelect<IStatusFilterOption>
      label="Subscription status"
      onSelectionChange={onUpdateFilter}
      items={userStatusOptions}
      selectedItemId={selectedStatusOptionId}
      verticalMenuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.UserStatuses)}
      {...getDataUiCollectionAttribute(DataUiCollection.UserStatuses)}
    />
  );
};

SubscriptionUsersStatusFilter.displayName = 'SubscriptionUsersStatusFilter';
