import PropTypes, { ValidationMap } from 'prop-types';
import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  CompiledContentItemElementDataValidationMap,
  ICompiledContentItemElementData,
} from './ICompiledContentItemElement.ts';

interface IMultipleChoiceItemElementValue {
  readonly options: readonly string[];
}

export interface IMultipleChoiceItemElement
  extends IMultipleChoiceItemElementValue,
    ICompiledContentItemElementData {}

export const EmptyMultipleChoiceItemElementValue: IMultipleChoiceItemElementValue = {
  options: [],
};

export const EmptyMultipleChoiceItemElement: IMultipleChoiceItemElement = {
  elementId: '',
  lastModifiedAt: null,
  lastModifiedBy: null,
  type: ElementType.MultipleChoice,
  ...EmptyMultipleChoiceItemElementValue,
};

export const MultipleChoiceItemElementPropTypes = PropTypes.exact<
  ValidationMap<IMultipleChoiceItemElement>
>({
  ...CompiledContentItemElementDataValidationMap,
  options: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
});

export const newMultipleChoiceItemElement = (
  params: Partial<IMultipleChoiceItemElement>,
): IMultipleChoiceItemElement => ({
  ...EmptyMultipleChoiceItemElement,
  ...params,
});
