import { Box } from '@kontent-ai/component-library/Box';
import { TabItem, TabView } from '@kontent-ai/component-library/TabView';
import {
  Spacing,
  spacingMainLayoutLeft,
  spacingMainLayoutRight,
  spacingMainLayoutTop,
} from '@kontent-ai/component-library/tokens';
import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router';
import { trackUserEvent } from '../../../_shared/actions/thunks/trackUserEvent.ts';
import { EnsureContentProductionCapabilities } from '../../../_shared/components/EnsureContentProductionCapabilities.tsx';
import { EnsureSelectedVariantIdInRoute } from '../../../_shared/components/EnsureSelectedVariantIdInRoute.tsx';
import { PageTitle } from '../../../_shared/components/PageTitle.tsx';
import { useRedirectPropsWithSameSearch } from '../../../_shared/components/routing/useRedirectPropsWithSameSearch.tsx';
import { AppNames } from '../../../_shared/constants/applicationNames.ts';
import {
  ContentPaceRoute,
  ContentStatusRoute,
  MissionControlCalendarRoute,
  MissionControlDashboardRoute,
  MissionControlLanguageAgnosticRouteParams,
  MissionControlLanguageDependentRoute,
  MissionControlLanguageDependentRouteParams,
  MissionControlProjectOverviewRoute,
  MissionControlQuickstartRoute,
  MissionControlYourWorkRoute,
} from '../../../_shared/constants/routePaths.ts';
import { TrackedEvent } from '../../../_shared/constants/trackedEvent.ts';
import { AuthorizedSection } from '../../../_shared/containers/routing/AuthorizedSection.tsx';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useGetLastRouteSegment } from '../../../_shared/hooks/useGetLastRouteSegment.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import {
  getSelectedLanguageId,
  getSelectedLanguageIdOrRouteMacro,
} from '../../../_shared/selectors/getSelectedLanguageId.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
  getDataUiObjectNameAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { allCapabilities } from '../../../_shared/utils/permissions/capability.ts';
import { buildPath } from '../../../_shared/utils/routing/routeTransitionUtils.ts';
import { projectHasQuickstart } from '../../../_shared/utils/trialUtils.ts';
import {
  getCurrentProject,
  getCurrentProjectId,
} from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { contentRequiredCapabilities } from '../../contentInventory/shared/utils/contentInventoryRequiredCapabilities.ts';
import { localizedRouteLeft } from '../../home/actions/homeActions.ts';
import { EnsureSelectedArrangementForCalendar } from '../../home/containers/EnsureSelectedArrangementForCalendar.tsx';
import { Quickstart } from '../../quickstart/containers/Quickstart.tsx';
import {
  ensureSelectedVariantForMissionControlLanguageDependentRoutes,
  initMissionControl,
} from '../actions/thunkMissionControlActions.ts';
import { Calendar } from '../calendar/components/Calendar.tsx';
import { ContentPace } from '../contentPace/containers/ContentPace.tsx';
import { ContentStatusTabContent } from '../contentStatus/components/ContentStatusTabContent.tsx';
import { MissionControlDemoContextProvider } from '../contexts/MissionControlDemoContext.tsx';
import { DashboardTabContent } from '../dashboard/container/DashboardTabContent.tsx';
import { ProjectOverview } from '../projectOverview/components/ProjectOverview.tsx';
import { YourWork } from '../yourWork/components/YourWork.tsx';
import { MissionControlDemoModeToggle } from './MissionControlDemoModeToggle.tsx';
import { MissionControlTabPanel } from './MissionControlTabPanel.tsx';

export const MissionControlPage: React.FC = () => {
  const selectedSectionPath = useGetLastRouteSegment();
  const dispatch = useDispatch();

  const tabs = useTabs();

  const selectedTab = tabs.find((tab) => tab.to.endsWith(selectedSectionPath));

  useEffect(() => {
    dispatch(trackUserEvent(TrackedEvent.MissionControlOpened));
    dispatch(initMissionControl());
  }, []);

  return (
    <MissionControlDemoContextProvider>
      <Box display="flex" flexDirection="column" height="100%" paddingTop={spacingMainLayoutTop}>
        <Box
          paddingLeft={spacingMainLayoutLeft}
          paddingRight={spacingMainLayoutRight}
          paddingBottom={Spacing.XXL}
        >
          <PageTitle>{AppNames.MissionControl}</PageTitle>
        </Box>

        <Box flexGrow={1} minHeight={0}>
          <TabView selectedKey={selectedTab?.id || ''} items={tabs}>
            <Box paddingLeft={spacingMainLayoutLeft} paddingRight={spacingMainLayoutRight}>
              <TabView.TabGroup
                {...getDataUiCollectionAttribute(DataUiCollection.MissionControlNavigationMenu)}
                renderAuxElement={() => <MissionControlDemoModeToggle />}
              />
            </Box>
            <MissionControlTabPanel>{Routes}</MissionControlTabPanel>
          </TabView>
        </Box>
      </Box>
    </MissionControlDemoContextProvider>
  );
};

type MissionControlTabItem = TabItem & {
  readonly id: string;
  readonly label: AppNames;
  readonly to: string;
};

const useTabs = (): ReadonlyArray<MissionControlTabItem> => {
  const shouldShowQuickstart = useSelector((s) => projectHasQuickstart(s, getCurrentProject(s)));
  const projectId = useSelector(getCurrentProjectId);
  const selectedLanguageIdWithMacroFallback = useSelector(getSelectedLanguageIdOrRouteMacro);

  return [
    ...(shouldShowQuickstart
      ? [
          {
            id: 'quickstart',
            label: AppNames.Quickstart,
            leadingElement: null,
            to: buildPath<MissionControlLanguageAgnosticRouteParams>(
              MissionControlQuickstartRoute,
              {
                projectId,
              },
            ),
            trailingElement: null,
            ...getDataUiObjectNameAttribute('quickstart'),
          },
        ]
      : []),
    {
      id: 'your-work',
      label: AppNames.YourWork,
      leadingElement: null,
      to: buildPath<MissionControlLanguageAgnosticRouteParams>(MissionControlYourWorkRoute, {
        projectId,
      }),
      trailingElement: null,
      ...getDataUiObjectNameAttribute('your-work'),
    },
    {
      id: 'dashboard',
      label: AppNames.Dashboard,
      leadingElement: null,
      to: buildPath<MissionControlLanguageAgnosticRouteParams>(MissionControlDashboardRoute, {
        projectId,
      }),
      trailingElement: null,
      ...getDataUiObjectNameAttribute('dashboard'),
    },
    {
      id: 'content-status',
      label: AppNames.ContentStatus,
      leadingElement: null,
      to: buildPath<MissionControlLanguageAgnosticRouteParams>(ContentStatusRoute, {
        projectId,
      }),
      trailingElement: null,
      ...getDataUiObjectNameAttribute('content-status'),
    },
    {
      id: 'content-pace',
      label: AppNames.ContentPace,
      leadingElement: null,
      to: buildPath<MissionControlLanguageAgnosticRouteParams>(ContentPaceRoute, {
        projectId,
      }),
      trailingElement: null,
      ...getDataUiObjectNameAttribute('content-pace'),
    },
    {
      id: 'project-overview',
      label: AppNames.ProjectOverview,
      leadingElement: null,
      to: buildPath<MissionControlLanguageDependentRouteParams>(
        MissionControlProjectOverviewRoute,
        {
          projectId,
          variantId: selectedLanguageIdWithMacroFallback,
        },
      ),
      trailingElement: null,
      ...getDataUiObjectNameAttribute('project-overview'),
    },
    {
      id: 'calendar',
      label: AppNames.Calendar,
      leadingElement: null,
      to: buildPath<MissionControlLanguageDependentRouteParams>(MissionControlCalendarRoute, {
        projectId,
        variantId: selectedLanguageIdWithMacroFallback,
      }),
      trailingElement: null,
      ...getDataUiObjectNameAttribute('calendar'),
    },
  ] as const satisfies readonly MissionControlTabItem[];
};

const Routes: React.FC = () => {
  const getRedirectPropsWithSameSearch = useRedirectPropsWithSameSearch();
  const { projectId } = useParams<MissionControlLanguageAgnosticRouteParams>();
  const shouldShowQuickstart = useSelector((s) => projectHasQuickstart(s, getCurrentProject(s)));
  const defaultRoute = shouldShowQuickstart
    ? buildPath<MissionControlLanguageAgnosticRouteParams>(MissionControlQuickstartRoute, {
        projectId,
      })
    : buildPath<MissionControlLanguageAgnosticRouteParams>(MissionControlYourWorkRoute, {
        projectId,
      });

  return (
    <Switch>
      <Route path={MissionControlQuickstartRoute}>
        <AuthorizedSection
          appName={AppNames.Quickstart}
          requiresOneOfCapabilities={allCapabilities}
        >
          <Quickstart />
        </AuthorizedSection>
      </Route>
      <Route exact path={MissionControlYourWorkRoute}>
        <AuthorizedSection
          appName={AppNames.YourWork}
          requiresOneOfCapabilities={contentRequiredCapabilities}
        >
          <EnsureContentProductionCapabilities appName={AppNames.YourWork}>
            <YourWork />
          </EnsureContentProductionCapabilities>
        </AuthorizedSection>
      </Route>
      <Route exact path={ContentStatusRoute}>
        <ContentStatusTabContent />
      </Route>
      <Route exact path={MissionControlDashboardRoute}>
        <DashboardTabContent />
      </Route>
      <Route exact path={ContentPaceRoute}>
        <ContentPace />
      </Route>
      <Route path={MissionControlLanguageDependentRoute}>
        <LanguageDependentRoutes />
      </Route>
      <Route>
        <Redirect {...getRedirectPropsWithSameSearch({ to: defaultRoute })} />
      </Route>
    </Switch>
  );
};

const LanguageDependentRoutes: React.FC = () => {
  const { projectId, variantId } = useParams<MissionControlLanguageDependentRouteParams>();

  return (
    <EnsureSelectedVariantIdInRoute
      key={`${projectId}${variantId}`}
      currentRouteVariantId={variantId}
      ensureSelectedVariant={ensureSelectedVariantForMissionControlLanguageDependentRoutes}
      getSelectedLanguageIdFromStore={getSelectedLanguageId}
      localizedRouteLeft={localizedRouteLeft}
    >
      <Switch>
        <Route exact path={MissionControlProjectOverviewRoute}>
          <AuthorizedSection
            appName={AppNames.ProjectOverview}
            requiresOneOfCapabilities={contentRequiredCapabilities}
          >
            <ProjectOverview />
          </AuthorizedSection>
        </Route>
        <Route exact path={MissionControlCalendarRoute}>
          <AuthorizedSection
            appName={AppNames.Calendar}
            requiresOneOfCapabilities={contentRequiredCapabilities}
          >
            <EnsureSelectedArrangementForCalendar>
              <Calendar />
            </EnsureSelectedArrangementForCalendar>
          </AuthorizedSection>
        </Route>
      </Switch>
    </EnsureSelectedVariantIdInRoute>
  );
};
