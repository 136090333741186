import {
  SmartLink_HandleSuccessfulRedirect,
  SmartLink_HandleUnsuccessfulRedirect,
  SmartLink_RouteEntered,
  SmartLink_RouteLeft,
} from '../constants/smartLinkActionTypes.ts';

export const handleUnsuccessfulRedirect = (
  linkText: string | null,
  linkMessage: string | null,
  linkLocation: string | null,
) =>
  ({
    type: SmartLink_HandleUnsuccessfulRedirect,
    payload: {
      linkText,
      linkLocation,
      linkMessage,
    },
  }) as const;

export const handleSuccessfulRedirect = (targetLocation: string | null) =>
  ({
    type: SmartLink_HandleSuccessfulRedirect,
    payload: {
      targetLocation,
    },
  }) as const;

export const smartLinkRouteEntered = () =>
  ({
    type: SmartLink_RouteEntered,
  }) as const;

export const smartLinkRouteLeft = () =>
  ({
    type: SmartLink_RouteLeft,
  }) as const;
