import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { ICompiledContentItemElementData } from '../../../../models/contentItemElements/ICompiledContentItemElement.ts';
import { finishContentItemElementRefresh } from '../../actions/thunkContentItemEditingActions.ts';
import {
  IItemElementRefresherDispatchProps,
  IItemElementRefresherOwnProps,
  IItemElementRefresherStateProps,
  ItemElementRefresher as ItemElementRefresherComponent,
} from '../../components/elements/ItemElementRefresher.tsx';

function mapStateToProps(
  state: IStore,
  ownProps: IItemElementRefresherOwnProps,
): IItemElementRefresherStateProps {
  const {
    contentApp: {
      editorUi: { pendingRefreshElements },
    },
  } = state;

  const refreshedElementData = pendingRefreshElements.get(ownProps.elementData.elementId) ?? null;

  return {
    refreshedElementData,
  };
}

function mapDispatchToProps(dispatch: Dispatch): IItemElementRefresherDispatchProps {
  return {
    onRefreshFinished: (refreshedElementData: ICompiledContentItemElementData) =>
      dispatch(finishContentItemElementRefresh(refreshedElementData)),
  };
}

export const ItemElementRefresher: React.ComponentType<IItemElementRefresherOwnProps> = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ItemElementRefresherComponent);
