import { ThunkFunction } from '../../../@types/Dispatcher.type.ts';
import { gtmTrackUser, trackCampaign } from '../../../_shared/utils/bootstrap.ts';
import { UserState } from '../../../data/models/user/CurrentUserInfo.ts';

export const enableAccountTracking = (): ThunkFunction => (_dispatch, getState) => {
  const {
    data: { user },
  } = getState();

  if (user.info.state === UserState.Complete) {
    trackCampaign(user.info);
  }
  gtmTrackUser(user.info);
};
