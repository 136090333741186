import { InvariantException } from '@kontent-ai/errors';
import { Dispatch, GetState, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { logError } from '../../../../../_shared/utils/logError.ts';
import {
  Workflow_Restore_Failed,
  Workflow_Restore_Finished,
  Workflow_Restore_Started,
} from '../../constants/workflowActionTypes.ts';
import { WorkflowListingOperationType } from '../../model/WorkflowListingOperation.ts';
import { fireRestoreWorkflowEvents } from './fireWorkflowIntercomEvents.ts';

interface IDeps {
  readonly workflowRepository: {
    readonly restore: (workflowId: Uuid) => Promise<void>;
  };
  readonly loadWorkflows: () => ThunkPromise;
}

const started = () =>
  ({
    type: Workflow_Restore_Started,
  }) as const;

const finished = () =>
  ({
    type: Workflow_Restore_Finished,
  }) as const;

const failed = (errorMessage: string) =>
  ({
    type: Workflow_Restore_Failed,
    payload: { errorMessage },
  }) as const;

export type RestoreWorkflowActionsType = ReturnType<
  typeof started | typeof finished | typeof failed
>;

export const createRestoreWorkflowAction =
  ({ workflowRepository, loadWorkflows }: IDeps) =>
  (): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState) => {
    try {
      const {
        workflowsApp: {
          listingUi: {
            operationStatus: { operationType, affectedWorkflowId },
          },
        },
      } = getState();

      if (operationType !== WorkflowListingOperationType.Deleted || !affectedWorkflowId) {
        throw InvariantException(
          `${__filename}: affectedWorkflowId is falsy or operation type does not match`,
        );
      }

      dispatch(started());

      await workflowRepository.restore(affectedWorkflowId);
      await dispatch(loadWorkflows());

      dispatch(finished());
      dispatch(fireRestoreWorkflowEvents(affectedWorkflowId));
    } catch (e) {
      logError(`${__filename}: Failed to restore workflow.`, e);
      dispatch(failed('Failed to restore workflow.'));
      throw e;
    }
  };
