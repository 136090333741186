import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import { useEditorWithPlugin } from '../../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginCreator } from '../../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../../editorCore/types/Editor.contract.type.ts';
import { Apply, EditorPlugin, Render } from '../../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../../editorCore/utils/decorable.ts';
import { withDisplayName } from '../../../editorCore/utils/withDisplayName.ts';
import { StylesPlugin } from '../../../plugins/visuals/StylesPlugin.tsx';

export type CommentInputStylesPlugin = EditorPlugin<None, None, None, [StylesPlugin]>;

export const useCommentInputStyles: PluginCreator<CommentInputStylesPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('CommentInputStylesPlugin', {
        ComposedEditor: (props) => {
          const { disabled } = props;

          const render: Decorator<Render<CommentInputStylesPlugin>> = useCallback(
            (baseRender) => (state) =>
              baseRender({
                ...state,
                rteProps: {
                  ...state.rteProps,
                  className: classNames(state.rteInputProps.className, 'rte--in-comment'),
                },
                rteInputProps: {
                  ...state.rteInputProps,
                  className: classNames(state.rteInputProps.className, {
                    'rte__content--is-disabled-in-comment': disabled,
                  }),
                },
              }),
            [disabled],
          );

          const apply: Apply<CommentInputStylesPlugin> = useCallback(
            (state) => {
              state.render.decorate(render);
              return {};
            },
            [render],
          );

          return useEditorWithPlugin(baseEditor, props, { apply });
        },
      }),
    [baseEditor],
  );
