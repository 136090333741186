import { isAbortedXMLHttpRequest } from '@kontent-ai/errors';
import { Dispatch, GetState, ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import { browserIsOffline, onAccessUnauthorized } from '../../../_shared/actions/sharedActions.ts';
import { IStore } from '../../../_shared/stores/IStore.type.ts';
import { ICurrentUserInfo } from '../../../data/models/user/CurrentUserInfo.ts';

interface IDeps {
  readonly loadUserInfo: () => ThunkPromise<ICurrentUserInfo>;
}

const handle401 = (dispatch: Dispatch): void => {
  // Unauthorized access
  dispatch(onAccessUnauthorized());
};

const handle403 =
  ({ loadUserInfo }: IDeps) =>
  async (dispatch: Dispatch): Promise<ICurrentUserInfo> =>
    // Forbidden access (logged in but does not have the necessary permission)
    await dispatch(loadUserInfo());

const handleOffline = (dispatch: Dispatch, getState: GetState<Partial<IStore>>): void => {
  const state = getState();
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
  const isNotOffline = !state || !state.sharedApp || state.sharedApp.isOnline !== false;

  if (isNotOffline) {
    dispatch(browserIsOffline());
  }
};

export const createHandleServerErrorResponseAction =
  (deps: IDeps) =>
  (httpRequest: XMLHttpRequest): ThunkPromise =>
  async (dispatch, getState) => {
    switch (httpRequest.status) {
      case 401: {
        handle401(dispatch);
        return;
      }
      case 403: {
        await handle403(deps)(dispatch);
        return;
      }
      case 0: {
        if (!isAbortedXMLHttpRequest(httpRequest)) {
          handleOffline(dispatch, getState);
        }
        return;
      }
      default: {
        return;
      }
    }
  };
