import { mergeProps } from '@react-aria/utils';
import PropTypes from 'prop-types';
import React, { HTMLAttributes, RefObject, useState } from 'react';
import { LinkProps } from 'react-router-dom';
import { useOurFocusRing } from '../../hooks/useOurFocusRing.ts';
import { Column } from '../../layout/Row/Column.tsx';
import { Row } from '../../layout/Row/Row.tsx';
import { colorAlertIcon, colorIconDefaultInverse } from '../../tokens/decision/colors.ts';
import { Spacing } from '../../tokens/quarks/spacing.ts';
import { placements } from '../../types/placement.ts';
import { getDataUiComponentAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { Anchor, RouterLink } from '../Anchor/Anchor.tsx';
import { Icons } from '../Icons/components/icons.ts';
import { Tooltip } from '../Tooltip/Tooltip.tsx';
import { pick } from '../_utils/pick.ts';
import { TooltipPropsExtension, tooltipExtensionPropTypes } from '../_utils/propPrefabs.ts';
import { MenuItemBody, MenuItemBodyProps } from './MenuItemBody.tsx';
import { StyledLeadingElement } from './components/StyledLeadingElement.tsx';
import { IStyledMenuItemProps, StyledMenuItem } from './components/StyledMenuItem.tsx';
import { MenuItemState, menuItemStateOptions } from './menuItemState.ts';

const disablingAttributes = {
  'aria-disabled': true,
  disabled: true,
};

type MenuItemWithoutHTMLAttrsProps = Pick<
  MenuItemBodyProps,
  'renderLabel' | 'renderComplementaryText'
> &
  Pick<TooltipPropsExtension, 'tooltipText' | 'tooltipPlacement'> & {
    readonly className?: string;
    readonly complementaryText?: string;
    readonly disabledFocus?: boolean;
    readonly href?: string;
    readonly isExpanded?: boolean;
    readonly isHovered?: boolean;
    readonly leadingElement?: React.ReactNode;
    readonly linkPath?: LinkProps['to'];
    readonly menuItemState: MenuItemState;
    readonly nestedLevel?: number;
    readonly onPress?: () => void;
    readonly text: string;
    readonly trailingElements?: React.ReactNode;
  };

export type MenuItemProps = MenuItemWithoutHTMLAttrsProps &
  Omit<HTMLAttributes<HTMLDivElement | HTMLAnchorElement>, 'className' | 'onClick'>;

export const menuItemPropTypes: PropTypeMap<MenuItemWithoutHTMLAttrsProps> = {
  ...pick(tooltipExtensionPropTypes, ['tooltipText', 'tooltipPlacement']),
  className: PropTypes.string,
  complementaryText: PropTypes.string,
  disabledFocus: PropTypes.bool,
  href: PropTypes.string,
  isExpanded: PropTypes.bool,
  isHovered: PropTypes.bool,
  leadingElement: PropTypes.element,
  linkPath: PropTypes.string,
  menuItemState: PropTypes.oneOf(menuItemStateOptions).isRequired,
  nestedLevel: PropTypes.number,
  onPress: PropTypes.func,
  renderComplementaryText: PropTypes.func,
  renderLabel: PropTypes.func,
  text: PropTypes.string.isRequired,
  tooltipPlacement: PropTypes.oneOf(placements),
  tooltipText: PropTypes.string,
  trailingElements: PropTypes.node,
};

export const MenuItem = React.forwardRef<HTMLDivElement | HTMLAnchorElement, MenuItemProps>(
  (
    {
      className,
      complementaryText,
      disabledFocus,
      href,
      isExpanded,
      isHovered,
      leadingElement,
      linkPath,
      menuItemState = 'default',
      nestedLevel,
      onPress,
      renderComplementaryText,
      renderLabel,
      text,
      tooltipPlacement = 'bottom',
      tooltipText,
      trailingElements,
      ...restProps
    },
    forwardedRef,
  ) => {
    const { isFocusVisible, focusProps } = useOurFocusRing();

    const isInteractionDisabled = menuItemState === 'disabled' || menuItemState === 'readonly';
    const isInErrorState = menuItemState === 'error' || menuItemState === 'error-selected';
    const [menuItemIsTruncated, setMenuItemIsTruncated] = useState(false);

    const displayTooltipText =
      tooltipText ||
      (menuItemIsTruncated ? `${text} ${complementaryText ? complementaryText : ''}` : undefined);

    const item = (
      <Row alignY="center" spacing={Spacing.S} noWrap>
        {isInErrorState && (
          <Column width="content">
            <StyledLeadingElement>
              <Icons.ExclamationTriangleInverted
                color={
                  menuItemState === 'error' && !(isHovered || isExpanded)
                    ? colorAlertIcon
                    : colorIconDefaultInverse
                }
              />
            </StyledLeadingElement>
          </Column>
        )}
        {leadingElement && (
          <Column width="content">
            <StyledLeadingElement>{leadingElement}</StyledLeadingElement>
          </Column>
        )}
        <Tooltip placement={tooltipPlacement} tooltipText={displayTooltipText}>
          <MenuItemBody
            complementaryText={complementaryText}
            isExpanded={isExpanded}
            isHovered={isHovered}
            renderComplementaryText={renderComplementaryText}
            renderLabel={renderLabel}
            state={menuItemState}
            text={text}
            onTruncated={setMenuItemIsTruncated}
          />
        </Tooltip>

        {trailingElements && (
          <Column width="content">
            <Row alignY="center" spacing={Spacing.S} noWrap>
              {React.Children.map(
                trailingElements,
                (child) => child && <Column width="content">{child}</Column>,
              )}
            </Row>
          </Column>
        )}
      </Row>
    );

    const commonProps: IStyledMenuItemProps = {
      $isFocusVisible: isFocusVisible,
      $nestedLevel: nestedLevel,
      $state: menuItemState,
      $isHovered: isHovered,
      $isExpanded: isExpanded,
      ...mergeProps(
        {
          className,
          onClick: isInteractionDisabled ? undefined : onPress,
        },
        disabledFocus ? {} : focusProps,
        restProps,
      ),
      ...(isInteractionDisabled ? disablingAttributes : {}),
      ...getDataUiComponentAttribute(MenuItem),
    };

    if (href && !isInteractionDisabled) {
      return (
        <StyledMenuItem
          as={Anchor}
          target="_blank"
          rel="noopener noreπferrer"
          href={href}
          ref={forwardedRef as RefObject<HTMLAnchorElement>}
          {...commonProps}
        >
          {item}
        </StyledMenuItem>
      );
    }

    if (linkPath && !isInteractionDisabled) {
      return (
        <StyledMenuItem
          as={RouterLink}
          to={linkPath}
          innerRef={forwardedRef as RefObject<HTMLAnchorElement>}
          tabIndex={disabledFocus ? -1 : undefined}
          {...commonProps}
        >
          {item}
        </StyledMenuItem>
      );
    }

    const customMenuItemProps = {
      tabIndex: disabledFocus ? undefined : 0,
      onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
          onPress?.();
        }
      },
    };
    const mergedMenuItemProps = mergeProps(
      {
        ...(isInteractionDisabled ? {} : customMenuItemProps),
      },
      commonProps,
    );

    return (
      <StyledMenuItem
        ref={forwardedRef as RefObject<HTMLDivElement>}
        role="button"
        {...mergedMenuItemProps}
      >
        {item}
      </StyledMenuItem>
    );
  },
);
MenuItem.displayName = 'MenuItem';
MenuItem.propTypes = menuItemPropTypes;
