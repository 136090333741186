import { CheckboxGroup } from '@kontent-ai/component-library/CheckboxGroup';
import { alphabetically, areArraysShallowEqual } from '@kontent-ai/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { MultipleChoiceMode } from '../../../../../../../_shared/models/MultipleChoiceModeEnum.ts';
import { getOrderedOptionsList } from '../../../../../../../_shared/selectors/multipleChoiceOptions.ts';
import { getDataUiObjectNameAttribute } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  IMultipleChoiceTypeElement,
  MultipleChoiceTypeElementPropTypes,
} from '../../../../../../contentInventory/content/models/contentTypeElements/MultipleChoiceTypeElement.ts';
import {
  IMultipleChoiceItemElement,
  MultipleChoiceItemElementPropTypes,
} from '../../../../../models/contentItemElements/MultipleChoiceItemElement.ts';
import { MinimumOptionCountForCompactSelector } from '../../../constants/uiConstants.ts';
import { hasDefaultValue } from '../../../utils/defaultValueUtils.ts';
import { DefaultValueStatus } from '../subComponents/limitInfoMessages/DefaultValueStatus.tsx';
import { CompactMultipleChoiceInput } from './CompactMultipleChoiceInput.tsx';

export interface IMultipleChoiceInputStateProps {
  readonly autoFocus: boolean;
  readonly disabled: boolean;
  readonly defaultValue: readonly string[];
  readonly elementData: IMultipleChoiceItemElement;
  readonly typeElement: IMultipleChoiceTypeElement;
}

export interface IMultipleChoiceInputDispatchProps {
  readonly onSelectedOptionsUpdate: (options: readonly string[]) => void;
}

type MultipleChoiceInputProps = IMultipleChoiceInputDispatchProps & IMultipleChoiceInputStateProps;

export class MultipleChoiceInput extends React.PureComponent<MultipleChoiceInputProps> {
  static displayName = 'MultipleChoiceInput';

  static propTypes: PropTypeMap<MultipleChoiceInputProps> = {
    autoFocus: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    defaultValue: PropTypes.array.isRequired,
    elementData: MultipleChoiceItemElementPropTypes.isRequired,
    onSelectedOptionsUpdate: PropTypes.func.isRequired,
    typeElement: MultipleChoiceTypeElementPropTypes.isRequired,
  };

  render() {
    const { autoFocus, disabled, defaultValue, elementData, typeElement, onSelectedOptionsUpdate } =
      this.props;

    const orderedOptions = getOrderedOptionsList(typeElement.options, typeElement.optionsOrder);
    const optionCount = orderedOptions.length;

    if (optionCount === 0) {
      return <div className="content-item-element__placeholder">There are no options here.</div>;
    }

    const shouldShowCompact = optionCount >= MinimumOptionCountForCompactSelector;
    const isDefaultValueSet = hasDefaultValue(defaultValue);
    const sortedDefaultValue = [...defaultValue].sort(alphabetically);
    const sortedCurrentValue = [...elementData.options].sort(alphabetically);
    const isCurrentValueDefault = isDefaultValueSet
      ? areArraysShallowEqual(sortedCurrentValue, sortedDefaultValue)
      : false;

    if (!shouldShowCompact && typeElement.mode === MultipleChoiceMode.Multiple) {
      return (
        <>
          <CheckboxGroup
            ariaLabel={typeElement.name}
            onChange={onSelectedOptionsUpdate}
            selectedValues={elementData.options}
          >
            {orderedOptions.map((option, index) => {
              return (
                <CheckboxGroup.Checkbox
                  ariaLabel={option.label}
                  autoFocus={autoFocus && index === 0}
                  checkboxState={disabled ? 'disabled' : 'default'}
                  key={option.id}
                  name={option.label}
                  value={option.id}
                  {...getDataUiObjectNameAttribute(option.label)}
                >
                  {option.label}
                </CheckboxGroup.Checkbox>
              );
            })}
          </CheckboxGroup>
          <DefaultValueStatus
            isStatusRendered={isDefaultValueSet && !disabled}
            isValueDefault={isCurrentValueDefault}
          />
        </>
      );
    }

    return (
      <>
        <CompactMultipleChoiceInput
          disabled={disabled}
          elementData={elementData}
          onUpdateSelectedOptions={onSelectedOptionsUpdate}
          typeElement={typeElement}
        />
        <DefaultValueStatus
          isStatusRendered={isDefaultValueSet && !disabled}
          isValueDefault={isCurrentValueDefault}
        />
      </>
    );
  }
}
