import PropTypes from 'prop-types';
import React from 'react';

interface IReceivingItemsProps {
  readonly message: string;
}

export const RetrievingItemsMessage: React.FC<IReceivingItemsProps> = ({
  message,
}: IReceivingItemsProps) => (
  <div className="utility-message utility-message--centered utility-message--in-progress">
    <h1 className="utility-message__title">{message}</h1>
  </div>
);

RetrievingItemsMessage.displayName = 'RetrievingItemsMessage';

const propTypes: PropTypesShape<IReceivingItemsProps> = {
  message: PropTypes.string.isRequired,
};
RetrievingItemsMessage.propTypes = propTypes;
