import PropTypes from 'prop-types';
import React, { HTMLAttributes, KeyboardEventHandler, MouseEventHandler } from 'react';
import styled from 'styled-components';
import { BorderRadius } from '../../tokens/quarks/border.ts';
import { BaseColor } from '../../tokens/quarks/colors.ts';
import { GradientType } from '../../tokens/quarks/gradients.ts';
import { Spacing } from '../../tokens/quarks/spacing.ts';
import { px } from '../../tokens/utils/utils.ts';
import { CLPropTypes } from '../../validators/propTypes.ts';

interface IColorCircleWithoutHTMLAttrsProps {
  readonly className?: string;
  readonly color: BaseColor | GradientType;
  readonly onClick?: MouseEventHandler<HTMLDivElement>;
  readonly onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
  readonly size: number;
  readonly tabIndex?: number;
}

export interface IColorCircleProps
  extends IColorCircleWithoutHTMLAttrsProps,
    Omit<
      HTMLAttributes<HTMLElement>,
      'color' | 'className' | 'onClick' | 'onKeyDown' | 'tabIndex'
    > {}

const propTypes: PropTypeMap<IColorCircleWithoutHTMLAttrsProps> = {
  className: PropTypes.string,
  color: PropTypes.oneOfType([CLPropTypes.colors.baseColor, CLPropTypes.colors.gradient])
    .isRequired,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  size: PropTypes.oneOf(Object.values(Spacing) as ReadonlyArray<Spacing>).isRequired,
  tabIndex: PropTypes.number,
};

interface IStyledCircleProps {
  readonly $color: BaseColor | GradientType;
  readonly $size: number;
}

const StyledCircle = styled.div<IStyledCircleProps>`
  align-items: center;
  background: ${(props) => props.$color.toString()};
  border-radius: ${px(BorderRadius.Pill)};
  display: flex;
  justify-content: center;
  height: ${(props) => px(props.$size)};
  width: ${(props) => px(props.$size)};
  color: ${BaseColor.White};
`;

export const ColorCircle = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<IColorCircleProps>
>(
  (
    { className, children, color, onClick, onKeyDown, size, tabIndex, onFocus, onBlur },
    forwardedRef,
  ) => (
    <StyledCircle
      className={className}
      $color={color}
      onClick={onClick}
      onKeyDown={onKeyDown}
      ref={forwardedRef}
      $size={size}
      tabIndex={tabIndex}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      {children}
    </StyledCircle>
  ),
);
ColorCircle.displayName = 'ColorCircle';
ColorCircle.propTypes = propTypes;
