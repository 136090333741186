import PropTypes from 'prop-types';
import React from 'react';
import { Button, ButtonSize } from '../../../../../../../_shared/uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import {
  DataUiCommentsAction,
  getDataUiCommentActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface INewCommentThreadItemReplyOptionsProps {
  readonly canSuggest: boolean;
  readonly onNewSuggestion: () => void;
  readonly onNewComment: () => void;
}

const propTypes: PropTypesShape<INewCommentThreadItemReplyOptionsProps> = {
  canSuggest: PropTypes.bool.isRequired,
  onNewSuggestion: PropTypes.func.isRequired,
  onNewComment: PropTypes.func.isRequired,
};

export const NewCommentThreadItemReplyOptions: React.FC<INewCommentThreadItemReplyOptionsProps> = (
  props,
) => {
  return (
    <div className="comment-editor__options">
      <div className="comment-editor__actions">
        {props.canSuggest && (
          <Button
            style={ButtonStyle.Secondary}
            size={ButtonSize.S}
            customButtonClassName="comment-editor__action"
            onClick={props.onNewSuggestion}
            {...getDataUiCommentActionAttribute(DataUiCommentsAction.Suggest)}
          >
            Suggest changes
          </Button>
        )}
        <Button
          style={ButtonStyle.Primary}
          size={ButtonSize.S}
          customButtonClassName="comment-editor__action"
          onClick={props.onNewComment}
          {...getDataUiCommentActionAttribute(DataUiCommentsAction.Reply)}
        >
          Reply
        </Button>
      </div>
    </div>
  );
};

NewCommentThreadItemReplyOptions.displayName = 'NewCommentThreadItemReplyOptions';
NewCommentThreadItemReplyOptions.propTypes = propTypes;
