import React, { ComponentProps } from 'react';
import { useLocation } from 'react-router';
import { LinkDataTableCell } from '../../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { LastUpdatedAt } from '../../../../../_shared/components/LastUpdatedAt.tsx';
import { WorkflowStatusTagForVariant } from '../../../../../_shared/containers/Workflow/WorkflowStatusTagForVariant.tsx';
import { stringifyContentItemId } from '../../../../../_shared/models/utils/contentItemIdUtils.ts';
import { DataUiCollection } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getContentItemPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IAssignedListingContentItem } from '../../../../../data/models/listingContentItems/IAssignedListingContentItem.ts';
import { TableCellDueDate } from '../../../../contentInventory/content/features/ContentItemInventory/components/TableCellDueDate.tsx';
import {
  StyledDataTableCell,
  StyledDataTableRow,
} from '../../shared/components/StyledDataTable.tsx';

type ItemsAssignedToYouWidgetRowProps = {
  readonly onItemClick: () => void;
  readonly item: IAssignedListingContentItem;
};

export const ItemsAssignedToYouWidgetRow: React.FC<ItemsAssignedToYouWidgetRowProps> = ({
  onItemClick,
  item,
}) => {
  const { pathname } = useLocation();

  const linkProps = {
    linkPath: getContentItemPath(pathname, item.id.itemId, item.id.variantId),
    onClick: () => onItemClick(),
  } satisfies ComponentProps<typeof LinkDataTableCell>;

  return (
    <StyledDataTableRow
      dataUiObjectName={DataUiCollection.ContentItems}
      id={stringifyContentItemId(item.id)}
    >
      <StyledDataTableCell {...linkProps}>{item.name}</StyledDataTableCell>
      <StyledDataTableCell {...linkProps}>
        <WorkflowStatusTagForVariant
          publishingState={item.publishingState}
          workflowStatus={item.workflowStatus}
          scheduledToPublishAt={item.scheduledToPublishAt}
          scheduledToUnpublishAt={item.scheduledToUnpublishAt}
        />
      </StyledDataTableCell>
      <StyledDataTableCell {...linkProps}>
        <TableCellDueDate dueDate={item.due} />
      </StyledDataTableCell>
      <StyledDataTableCell {...linkProps}>
        <LastUpdatedAt time={item.lastUpdatedAt} />
      </StyledDataTableCell>
    </StyledDataTableRow>
  );
};
