import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { DemoSampleAppsSection } from '../../containers/SampleProjectQuickstart/DemoSampleAppsSection.tsx';
import { QuickstartQuickTip } from '../GettingStartedQuickstart/QuickstartQuickTip.tsx';
import { QuickstartTitle } from '../shared/QuickstartTitle.tsx';
import { SampleProjectSdkSection } from './SampleProjectSdkSection.tsx';

export const SampleProjectQuickstart: React.FC = () => (
  <div className="canvas__workspace">
    <div className="canvas__content">
      <div className="canvas__content-pane">
        <div className="canvas__inner-section canvas__inner-section--restricted-width canvas__inner-section--centered">
          <div className="quickstart">
            <QuickstartTitle>
              Welcome. This showcase project will show you what you can do with Kontent.ai.
            </QuickstartTitle>
            <Stack spacing={Spacing.XL}>
              <SampleProjectSdkSection />
              <DemoSampleAppsSection />
              <QuickstartQuickTip />
            </Stack>
          </div>
        </div>
      </div>
    </div>
  </div>
);

SampleProjectQuickstart.displayName = 'SampleProjectQuickstart';
