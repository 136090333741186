import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { ModalDialog } from '../../../../../_shared/components/ModalDialog/ModalDialog.tsx';
import {
  ItemColumnCode,
  translateColumnCodeToTitle,
} from '../../../../../_shared/constants/itemColumnCode.ts';
import { ContentItemId } from '../../../../../_shared/models/ContentItemId.type.ts';
import { stringifyContentItemId } from '../../../../../_shared/models/utils/contentItemIdUtils.ts';
import { Button } from '../../../../../_shared/uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import { ScrollTableHeadColumn } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableHeadColumn.tsx';
import { ScrollTableHeadRow } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableHeadRow.tsx';
import { SimpleScrollTable } from '../../../../../_shared/uiComponents/ScrollTable/SimpleScrollTable.tsx';
import {
  DataUiAction,
  DataUiCollection,
  DataUiElement,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { FailedItemRow } from './FailedItemRow.tsx';

interface Props {
  readonly modalTitle: string;
  readonly dataUiElement: DataUiElement;
  readonly failedItemIds: ReadonlyArray<ContentItemId>;
  readonly renderScrollTableTitle: () => JSX.Element;
  readonly renderWarning?: () => JSX.Element;
  readonly onClose: () => void;
  readonly showCollectionColumn: boolean;
}

const ModalBody: React.FC<Props> = (props) => (
  <Stack spacing={Spacing.L}>
    {props.renderWarning?.()}

    <SimpleScrollTable
      collectionName={DataUiCollection.ContentItems}
      fillAvailableSpace
      noShadow
      renderTitle={props.renderScrollTableTitle}
      renderHead={(showScrollBar: boolean) => (
        <ScrollTableHeadRow collectionName={DataUiCollection.ColumnHeaderCollection}>
          <ScrollTableHeadColumn
            name={translateColumnCodeToTitle(ItemColumnCode.Name)}
            size={10}
            isGrowing
          />
          <ScrollTableHeadColumn name="" size={1} />
          <ScrollTableHeadColumn
            name={translateColumnCodeToTitle(ItemColumnCode.WorkflowStep)}
            size={4}
            isGrowing
          />
          <ScrollTableHeadColumn
            name={translateColumnCodeToTitle(ItemColumnCode.ContentType)}
            size={4}
            isGrowing
          />
          {props.showCollectionColumn && (
            <ScrollTableHeadColumn
              name={translateColumnCodeToTitle(ItemColumnCode.Collection)}
              size={4}
              isGrowing
            />
          )}
          {showScrollBar && <div className="scroll-table__head-scroll" />}
        </ScrollTableHeadRow>
      )}
    >
      {props.failedItemIds.map((contentItemId) => (
        <FailedItemRow
          key={stringifyContentItemId(contentItemId)}
          contentItemId={contentItemId}
          showCollectionColumn={props.showCollectionColumn}
        />
      ))}
    </SimpleScrollTable>
  </Stack>
);

export const FailedItemsModal: React.FC<Props> = (props) => (
  <ModalDialog
    withDividers
    headerContent={props.modalTitle}
    dataUiElement={props.dataUiElement}
    onClose={props.onClose}
    bodyContent={<ModalBody {...props} />}
    footerContentRight={
      <Button
        style={ButtonStyle.Primary}
        dataUiAction={DataUiAction.CloseDialog}
        onClick={props.onClose}
      >
        Dismiss
      </Button>
    }
  />
);

FailedItemsModal.displayName = 'FailedItemsModal';
