import Immutable from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../@types/Dispatcher.type.ts';
import { withAutoDispatcher } from '../../../../_shared/components/AutoDispatcher.tsx';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { isLanguageActive } from '../../../../_shared/models/utils/isLanguageActive.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { ILanguage, Languages } from '../../../../data/models/languages/Language.ts';
import { IPlan } from '../../../../data/models/plans/Plan.ts';
import { getCurrentProject } from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { ISubscriptionUsage } from '../../../subscriptionManagement/shared/models/SubscriptionUsage.ts';
import { showLanguageCreateForm } from '../actions/localizationActions.ts';
import { initLanguagesEditor, saveLanguagesToServer } from '../actions/thunkLocalizationActions.ts';
import {
  ILanguageListingCallbackProps,
  ILanguageListingDataProps,
  LanguageListing as LanguageListingComponent,
} from '../components/LanguageListing.tsx';

const isSubscriptionLimitReached = (
  subscriptionUsage: ISubscriptionUsage | undefined,
  languages: Immutable.Map<Uuid, ILanguage>,
  plans: Immutable.Map<Uuid, IPlan>,
): boolean => {
  if (!subscriptionUsage) {
    return true;
  }

  const plan = plans.get(subscriptionUsage.planId);
  const maxLanguages = plan ? plan.features.maxActiveLanguages : null;
  if (!maxLanguages) {
    return false;
  }

  return languages.count(isLanguageActive) + 1 >= maxLanguages;
};

const mapStateToLanguagesListingProps = (state: IStore): ILanguageListingDataProps => {
  const {
    data: {
      plans,
      subscriptions: { subscriptionUsages },
    },
    localizationApp: {
      isEditedLanguageNew,
      languages,
      languageItemsOrder,
      editedLanguageId,
      isDefaultLanguageEdited,
      loadingStatus,
    },
  } = state;

  const languagesOrdered = languageItemsOrder
    .map((item) => (item ? languages.get(item) : null))
    .filter((item) => !!item) as Immutable.List<ILanguage>;

  const subscriptionUsage = subscriptionUsages.get(getCurrentProject(state).subscriptionId);
  const languageLimitReached = isSubscriptionLimitReached(subscriptionUsage, languages, plans.byId);

  return {
    isCreatingAllowed: !editedLanguageId && !isDefaultLanguageEdited && !languageLimitReached,
    isLimitReached: languageLimitReached,
    isEditedLanguageNew,
    shouldRenderIntro: languages.isEmpty(),
    languages: languagesOrdered,
    isLocalizationEditorInitialized: loadingStatus === LoadingStatus.Loaded,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ILanguageListingCallbackProps => ({
  onCreateNewClick: () => dispatch(showLanguageCreateForm()),
  onInit: () => dispatch(initLanguagesEditor()),
});

type ObservedState = {
  readonly defaultLanguage: ILanguage;
  readonly languages: Languages;
  readonly languageItemsOrder: Immutable.List<Uuid>;
};

const mapObservedState = (state: IStore): ObservedState => ({
  defaultLanguage: state.localizationApp.defaultLanguage,
  languages: state.localizationApp.languages,
  languageItemsOrder: state.localizationApp.languageItemsOrder,
});

const shouldDispatch = (_oldState: IStore, newState: IStore): boolean => {
  const serverErrorNextState = newState.projectsApp.serverError;
  const languagesHavePendingChanges = newState.localizationApp.languagesHavePendingChanges;

  return !serverErrorNextState && languagesHavePendingChanges;
};

const ConnectedLanguagesListing: React.ComponentType = connect(
  mapStateToLanguagesListingProps,
  mapDispatchToProps,
)(LanguageListingComponent);

export const LanguagesListing = withAutoDispatcher<NoProps, ObservedState>(
  mapObservedState,
  saveLanguagesToServer,
  500,
  shouldDispatch,
)(ConnectedLanguagesListing);
