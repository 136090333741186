import React, { memo } from 'react';
import { createEditorWithPlugins } from '../../../../editorCore/Editor.composition.tsx';
import { EditorProps } from '../../../../editorCore/types/Editor.composition.type.ts';
import { useComposedViewer } from '../../../../editors/simpleText/SimpleMultilineTextViewer.tsx';
import { useBorderless } from '../../../visuals/BorderlessPlugin.tsx';

export const useComposedAiViewer = () =>
  createEditorWithPlugins(useComposedViewer(), useBorderless);

export type StringAiSuggestionViewerProps = EditorProps<typeof useComposedAiViewer>;

export const StringAiSuggestionViewer: React.FC<StringAiSuggestionViewerProps> = memo((props) => {
  const { ComposedEditor } = useComposedAiViewer();

  return <ComposedEditor {...props} />;
});

StringAiSuggestionViewer.displayName = 'StringAiSuggestionViewer';
