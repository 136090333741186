import { InvariantException } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../../../_shared/models/TrackUserEvent.type.ts';
import { TasksEventActions } from '../../../../../../_shared/models/events/TaskEventData.type.ts';
import { logError } from '../../../../../../_shared/utils/logError.ts';
import { ITaskRepository } from '../../../../../../repositories/interfaces/ITaskRepository.type.ts';
import {
  ContentItemEditing_TaskComplete_Failed,
  ContentItemEditing_TaskComplete_Finished,
  ContentItemEditing_TaskComplete_Started,
} from '../../constants/contentItemEditingActionTypes.ts';

const started = (taskId: Uuid) =>
  ({
    type: ContentItemEditing_TaskComplete_Started,
    payload: { taskId },
  }) as const;

const finished = (taskId: Uuid) =>
  ({
    type: ContentItemEditing_TaskComplete_Finished,
    payload: { taskId },
  }) as const;

const failed = (taskId: Uuid) =>
  ({
    type: ContentItemEditing_TaskComplete_Failed,
    payload: { taskId },
  }) as const;

export type CompleteTaskActionsType = ReturnType<typeof finished | typeof started | typeof failed>;

interface IDeps {
  readonly taskRepository: Pick<ITaskRepository, 'completeTask'>;
  readonly trackUserEventWithData: TrackUserEventWithDataAction;
}

export const completeTaskCreator =
  (deps: IDeps) =>
  (taskId: Uuid): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      contentApp: { editedContentItemVariant },
      data: {
        tasks: { byId: tasksById },
      },
    } = getState();
    if (!editedContentItemVariant) {
      throw InvariantException('completeTask.ts: editedContentItemVariant is falsy');
    }
    dispatch(started(taskId));
    try {
      await deps.taskRepository.completeTask(taskId);
      dispatch(finished(taskId));
    } catch {
      logError(`Completing task ${taskId} failed.`);
      dispatch(failed(taskId));
    }

    dispatch(
      deps.trackUserEventWithData(TrackedEvent.Tasks, {
        action: TasksEventActions.TaskCompleted,
        workflowStepId: editedContentItemVariant.assignment.workflowStatus.id,
        hasDueDate: !!tasksById.get(taskId)?.dueDate,
      }),
    );
  };
