import React, { useCallback, useMemo } from 'react';
import {
  DataUiRteAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { EmailLinkProperties } from '../../../../itemEditor/models/contentItemElements/richText/EmailLinkProperties.type.ts';
import { useEditorWithPlugin } from '../../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginCreator } from '../../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../../editorCore/types/Editor.contract.type.ts';
import {
  Apply,
  EditorPlugin,
  PluginProps,
  PluginState,
} from '../../../editorCore/types/Editor.plugins.type.ts';
import { EditorChangeReason } from '../../../editorCore/types/EditorChangeReason.ts';
import { Decorator } from '../../../editorCore/utils/decorable.ts';
import { withDisplayName } from '../../../editorCore/utils/withDisplayName.ts';
import { getLinkStatus } from '../../apiLimitations/api/editorLimitationUtils.ts';
import { EntityDecoratorProps } from '../../entityApi/api/editorEntityUtils.ts';
import { GetLinkEntityComponent, GetLinkOptions, LinksPlugin } from '../LinksPlugin.tsx';
import { isEmailLink, isNewLinkOfType } from '../api/LinkEntity.ts';
import { LinkType } from '../api/LinkType.ts';
import { NewLinkType } from '../api/NewLinkType.ts';
import { isLinkAllowedAtSelection } from '../api/editorLinkUtils.ts';
import { EmailLink } from './components/EmailLink.tsx';
import { NewEmailLink } from './components/NewEmailLink.tsx';

export type EmailLinksPlugin = EditorPlugin<None, None, None, [LinksPlugin]>;

type UpdateEmailLink = (
  entityKey: string,
  values: EmailLinkProperties,
  allowUndo?: boolean,
) => void;

type CustomLinkProps = Pick<
  PluginState<EmailLinksPlugin>,
  'editLink' | 'cancelNewLink' | 'linkEditingCancelled' | 'unlink' | 'getApi'
> &
  Pick<PluginProps<EmailLinksPlugin>, 'disabled'> & {
    readonly updateEmailLink: UpdateEmailLink;
  };

type NewEmailLinkEntityProps = EntityDecoratorProps<CustomLinkProps> & {
  readonly children: ReadonlyArray<React.ReactNode>;
};

const NewEmailLinkEntity: React.FC<NewEmailLinkEntityProps> = ({
  cancelNewLink,
  children,
  contentState,
  decoratedText,
  entityKey,
  updateEmailLink,
}) => {
  const entity = contentState.getEntity(entityKey);
  if (!isNewLinkOfType(entity, NewLinkType.EmailLink)) {
    return children;
  }

  const isPlaceholder = !!entity.getData().isPlaceholder;

  return (
    <NewEmailLink
      linkText={decoratedText}
      isPlaceholder={isPlaceholder}
      key={entityKey}
      onCancel={() => cancelNewLink(entityKey, isPlaceholder)}
      onInsert={(values) => updateEmailLink(entityKey, values, false)}
    >
      {children}
    </NewEmailLink>
  );
};

NewEmailLinkEntity.displayName = 'NewEmailLinkEntity';

type EmailLinkEntityProps = EntityDecoratorProps<CustomLinkProps> & {
  readonly children: ReadonlyArray<React.ReactNode>;
};

const EmailLinkEntity: React.FC<EmailLinkEntityProps> = ({
  blockKey,
  children,
  contentState,
  decoratedText,
  disabled,
  editLink,
  entityKey,
  getApi,
  linkEditingCancelled,
  unlink,
  updateEmailLink,
}) => {
  const entity = contentState.getEntity(entityKey);
  if (!isEmailLink(entity)) {
    return children;
  }

  const { emailAddress, emailSubject } = entity.getData();

  const status = getLinkStatus({ blockKey, contentState }, getApi().getLimitations());

  return (
    <EmailLink
      disabled={disabled}
      emailAddress={emailAddress}
      emailSubject={emailSubject}
      key={entityKey}
      onCancel={() => linkEditingCancelled(entityKey)}
      onEdit={() => editLink(entityKey)}
      onUnlink={() => unlink(entityKey)}
      onUpdate={(values) => updateEmailLink(entityKey, values)}
      status={status}
      text={decoratedText}
    >
      {children}
    </EmailLink>
  );
};

EmailLinkEntity.displayName = 'EmailLinkEntity';

export const useEmailLinks: PluginCreator<EmailLinksPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('EmailLinksPlugin', {
        ComposedEditor: (props) => {
          const { disabled } = props;

          const apply: Apply<EmailLinksPlugin> = useCallback(
            (state) => {
              const updateEmailLink: UpdateEmailLink = (entityKey, values, allowUndo = true) => {
                if (!state.canUpdateContent(EditorChangeReason.Internal)) {
                  return;
                }

                state.executeChange((editorState) => {
                  const selection = state.getApi().getSelectionForEntity(editorState, entityKey);
                  if (selection) {
                    const newEditorState = state
                      .getApi()
                      .createEmailLink(editorState, selection, values, allowUndo);
                    state.linkEditingFinished();
                    return newEditorState;
                  }
                  return editorState;
                }, EditorChangeReason.Internal);
              };

              const getLinkEntityComponent: Decorator<GetLinkEntityComponent<CustomLinkProps>> =
                (baseGetLinkEntityComponent) => (linkType) => {
                  const customLinkProps: CustomLinkProps = {
                    disabled,
                    cancelNewLink: state.cancelNewLink,
                    unlink: state.unlink,
                    editLink: state.editLink,
                    getApi: state.getApi,
                    linkEditingCancelled: state.linkEditingCancelled,
                    updateEmailLink,
                  };

                  switch (linkType) {
                    case LinkType.Email:
                      return {
                        component: EmailLinkEntity,
                        props: customLinkProps,
                      };

                    case NewLinkType.EmailLink:
                      return {
                        component: NewEmailLinkEntity,
                        props: customLinkProps,
                      };

                    default:
                      return baseGetLinkEntityComponent(linkType);
                  }
                };

              state.getLinkEntityComponent.decorate(getLinkEntityComponent);

              const newEmailLink = (): void => {
                if (!state.canUpdateContent()) {
                  return;
                }

                state.executeChange((editorState) => {
                  const selection = editorState.getSelection();
                  const content = editorState.getCurrentContent();
                  if (isLinkAllowedAtSelection(content, selection)) {
                    const newEditorState = selection.isCollapsed()
                      ? state
                          .getApi()
                          .createLinkPlaceholder(editorState, selection, NewLinkType.EmailLink)
                      : state.getApi().createNewEmailLink(editorState, selection);
                    if (editorState !== newEditorState) {
                      const entityKey = newEditorState
                        .getCurrentContent()
                        .getLastCreatedEntityKey();
                      state.setEditedLinkEntityKey(entityKey);
                      return newEditorState;
                    }
                  }
                  return editorState;
                }, EditorChangeReason.Internal);
              };

              const getLinkOptions: Decorator<GetLinkOptions> = (baseGetLinkOptions) => () => [
                ...baseGetLinkOptions(),
                {
                  name: 'Email',
                  onMouseDown: newEmailLink,
                  dataAttributes: getDataUiActionAttribute(DataUiRteAction.AddEmailLink),
                },
              ];

              state.getLinkOptions.decorate(getLinkOptions);

              return {};
            },
            [disabled],
          );

          return useEditorWithPlugin(baseEditor, props, { apply });
        },
      }),
    [baseEditor],
  );
