import { useEnsuredContext } from '@kontent-ai/hooks';
import { useButton } from '@react-aria/button';
import { HTMLAttributes, ReactNode, RefObject } from 'react';
import { MenuContext } from './MenuContext.tsx';

type TriggerProps<TElement extends Element = Element> = HTMLAttributes<TElement>;

type Props = Readonly<{
  children: (ref: RefObject<Element>, triggerProps: TriggerProps, isOpen: boolean) => ReactNode;
}>;

export const MenuTrigger = (props: Props) => {
  const {
    menuTriggerProps,
    menuTriggerRef,
    menuTriggerState: { isOpen },
  } = useEnsuredContext(MenuContext);

  const { buttonProps } = useButton(menuTriggerProps, menuTriggerRef);

  return props.children(menuTriggerRef, buttonProps, isOpen);
};
