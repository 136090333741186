import { InvariantException } from '@kontent-ai/errors';
import React from 'react';
import { modalDismissed } from '../../../../../_shared/actions/sharedActions.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getSelectedLanguageId } from '../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { LastCascadeAction } from '../../../../contentInventory/content/stores/IContentAppStoreState.ts';
import { CascadeFailedItemsModal } from '../components/CascadeFailedItemsModal.tsx';
import { getStatusInfoMessageForFailedDialog } from '../utils/getStatusInfoMessage.ts';
import { sortChildContentItemItemIds } from '../utils/sortChildContentItemItemIds.ts';

const getLastAction = (action: LastCascadeAction): string => {
  switch (action) {
    case LastCascadeAction.Publish:
      return 'publish';
    case LastCascadeAction.Schedule:
      return 'schedule';
    case LastCascadeAction.UndoPublish:
      return 'unpublish';
    case LastCascadeAction.UndoSchedule:
      return 'cancel scheduled publish';
    default:
      throw InvariantException(
        `CascadePublishFailedItemsModal.tsx: ${action} does not correlate to any cascade action.`,
      );
  }
};

export const CascadePublishFailedItemsModal: React.FC = () => {
  const dispatch = useDispatch();
  const lastAction = useSelector((s) =>
    getLastAction(s.contentApp.editorUi.cascadePublish.cascadeResult.lastAction),
  );
  const failedItemIds = useSelector((s) => {
    const selectedLanguageId = getSelectedLanguageId(s);

    return selectedLanguageId
      ? sortChildContentItemItemIds(
          s.contentApp.editorUi.cascadePublish.cascadeResult.failedItemIds,
          s.data.listingContentItems.byId,
          s.data.listingContentItems.defaultById,
          selectedLanguageId,
        )
      : null;
  });
  const statusInfoMessage = useSelector((s) =>
    getStatusInfoMessageForFailedDialog(s.contentApp.editorUi.cascadePublish.cascadeResult),
  );

  if (!failedItemIds) {
    return null;
  }

  return (
    <CascadeFailedItemsModal
      modalTitle={`Some items failed to ${lastAction}`}
      failedItemIds={failedItemIds}
      statusInfoMessage={statusInfoMessage}
      onClose={() => dispatch(modalDismissed())}
    />
  );
};

CascadePublishFailedItemsModal.displayName = 'CascadePublishFailedItemsModal';
