import Immutable from 'immutable';
import React, { useCallback } from 'react';
import { initializePeopleListingForProject } from '../../../applications/environmentSettings/users/actions/thunkUsersActions.ts';
import { IProjectListingUser } from '../../../applications/projects/models/ProjectListingUser.ts';
import { getCurrentProject } from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { AvailableSubscriptionAdmins as AvailableSubscriptionAdminsComponent } from '../../components/infos/AvailableSubscriptionAdmins.tsx';
import { useDispatch } from '../../hooks/useDispatch.ts';
import { useSelector } from '../../hooks/useSelector.ts';

const MAX_ADMINS_IN_DIALOG = 10;

interface IAvailableSubscriptionAdminsContainerProps {
  readonly onClose: () => void;
}

export const AvailableSubscriptionAdmins: React.FC<IAvailableSubscriptionAdminsContainerProps> = ({
  onClose,
}) => {
  const projectId = useSelector((state) => getCurrentProject(state).projectId);
  const admins = useSelector<Immutable.List<IProjectListingUser> | null>((state) => {
    const projectDetail = state.data.projects.byId.get(projectId);
    return projectDetail
      ? projectDetail.projectManagers
          .filter((manager: IProjectListingUser) =>
            projectDetail.subscriptionAdmins.contains(manager.userId),
          )
          .slice(0, MAX_ADMINS_IN_DIALOG)
          .toList()
      : null;
  });

  const dispatch = useDispatch();
  const loadAdmins = useCallback(
    () => dispatch(initializePeopleListingForProject(projectId)),
    [projectId],
  );

  return (
    <AvailableSubscriptionAdminsComponent
      admins={admins}
      onClose={onClose}
      loadAdmins={loadAdmins}
    />
  );
};

AvailableSubscriptionAdmins.displayName = 'AvailableSubscriptionAdminsContainer';
