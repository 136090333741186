import Immutable from 'immutable';
import ImmutablePropTypes from 'immutable-prop-types';
import PropTypes from 'prop-types';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  CompiledContentItemElementDataValidationMap,
  ICompiledContentItemElementData,
} from '../ICompiledContentItemElement.ts';

interface ILinkedItemsElementValue {
  readonly nestedLevel: number;
  readonly value: Immutable.List<Uuid>;
}

export interface ILinkedItemsElement
  extends ILinkedItemsElementValue,
    ICompiledContentItemElementData {}

export const EmptyLinkedItemsElementValue: ILinkedItemsElementValue = {
  nestedLevel: 1,
  value: Immutable.List<Uuid>(),
};

export const EmptyLinkedItemElement: ILinkedItemsElement = {
  elementId: '',
  type: ElementType.LinkedItems,
  lastModifiedBy: null,
  lastModifiedAt: null,
  ...EmptyLinkedItemsElementValue,
};

export const LinkedItemsElementPropTypes = PropTypes.exact({
  ...CompiledContentItemElementDataValidationMap,
  nestedLevel: PropTypes.number.isRequired,
  value: ImmutablePropTypes.list.isRequired,
});

export const newLinkedItemsElement = (
  params: Partial<ILinkedItemsElement>,
): ILinkedItemsElement => ({
  ...EmptyLinkedItemElement,
  ...params,
});
