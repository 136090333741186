import { InvariantException } from '@kontent-ai/errors';
import { memoize } from '@kontent-ai/memoization';
import { assert } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { getViewableContentGroups } from '../../../../_shared/utils/contentItemUtils.ts';
import { canViewContentGroup } from '../../../../_shared/utils/permissions/activeCapabilities.ts';
import { ICompiledContentType } from '../../../contentInventory/content/models/CompiledContentType.ts';
import {
  EditableTypeElement,
  TypeElement,
} from '../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { IContentGroup } from '../../../contentInventory/content/models/contentTypeElements/types/ContentGroup.ts';
import { ContentGroupTabsId } from '../../features/ContentItemEditing/utils/contentGroupTabsId.ts';
import { ICompiledContentItemElementData } from '../../models/contentItemElements/ICompiledContentItemElement.ts';
import { createItemElementWithInitialValue } from '../../utils/itemElementCreator.ts';

export const getElementById = <
  TItemElement extends ICompiledContentItemElementData = ICompiledContentItemElementData,
>(
  elementId: Uuid,
  elements: ReadonlyArray<ICompiledContentItemElementData>,
): TItemElement | null => elements.find((el) => el.elementId === elementId) as TItemElement | null;

export const getItemElementOrDefault = (
  typeElement: EditableTypeElement,
  elements: ReadonlyArray<ICompiledContentItemElementData>,
): ICompiledContentItemElementData =>
  getElementById(typeElement.elementId, elements) ?? createItemElementWithInitialValue(typeElement);

export const getElementByIdOrThrow = <
  TItemElement extends ICompiledContentItemElementData = ICompiledContentItemElementData,
>(
  elementId: Uuid,
  elements: ReadonlyArray<ICompiledContentItemElementData>,
): TItemElement => {
  const element = getElementById<TItemElement>(elementId, elements);
  assert(element, () => `Element '${elementId}' not found.`);
  return element;
};

export type IElementModifier = (
  componentElementData: ICompiledContentItemElementData,
) => ICompiledContentItemElementData;

export const modifyElement = (
  elements: ReadonlyArray<ICompiledContentItemElementData>,
  elementId: Uuid,
  modifier: IElementModifier,
  createMissingElement?: () => ICompiledContentItemElementData,
): ReadonlyArray<ICompiledContentItemElementData> => {
  let found = false;
  const newElements = elements.map((elementData) => {
    if (elementData.elementId === elementId) {
      found = true;
      return modifier(elementData);
    }
    return elementData;
  });

  if (!found && createMissingElement) {
    const newElement = modifier(createMissingElement());
    return [...elements, newElement];
  }
  return newElements;
};

export const getElementFromContentTypes = memoize.allForever(
  (
    elementId: Uuid | null,
    contentTypes: Immutable.Map<Uuid, ICompiledContentType>,
  ): TypeElement | null => {
    const element = contentTypes
      .reduce(
        (reduction: ReadonlyArray<TypeElement>, contentType: ICompiledContentType) =>
          reduction.concat(contentType.contentElements),
        [],
      )
      .find((e) => e.elementId === elementId);

    return element || null;
  },
);

export const getTypeElementsInContentGroup = memoize.weak(
  (
    contentElements: ReadonlyArray<TypeElement>,
    contentGroupId: Uuid | null,
  ): ReadonlyArray<TypeElement> =>
    contentElements.filter((element) => element.contentGroupId === contentGroupId),
);

export const getItemElementsInContentGroup = memoize.weak(
  (
    elements: ReadonlyArray<ICompiledContentItemElementData>,
    contentType: ICompiledContentType,
    contentGroupId: Uuid | null,
  ): ReadonlyArray<ICompiledContentItemElementData> => {
    const typeElementsInContentGroup = getTypeElementsInContentGroup(
      contentType.contentElements,
      contentGroupId,
    );

    const elementsInContentGroup = elements.filter(
      (element) =>
        !!typeElementsInContentGroup.find(
          (typeElement) => typeElement.elementId === element.elementId,
        ),
    );

    return elementsInContentGroup;
  },
);

export const getContentGroupForElement = memoize.weak(
  (contentElements: ReadonlyArray<TypeElement>, elementId: Uuid): Uuid | null => {
    const element = contentElements.find((e) => e.elementId === elementId);
    if (!element) {
      throw InvariantException(
        `contentItemElementsUtils.ts: can’t find element with id='${elementId}'.`,
      );
    }
    return element.contentGroupId;
  },
);

export const getSelectedContentGroupIdFromStateOrFirst = (
  contentGroupTabsId: ContentGroupTabsId,
  contentGroups: Immutable.List<IContentGroup>,
  state: IStore,
): Uuid | null => {
  const stateSelectedContentGroupId =
    state.contentApp.editorUi.selectedContentGroups.get(contentGroupTabsId);
  if (
    stateSelectedContentGroupId &&
    contentGroups.some((group: IContentGroup) => group.id === stateSelectedContentGroupId)
  ) {
    return stateSelectedContentGroupId;
  }

  return contentGroups.first()?.id ?? null;
};

export function getEditedItemViewableContentGroups(state: IStore): Immutable.List<IContentGroup> {
  const {
    contentApp: { loadedContentItemTypes, editedContentItem, editorUi },
  } = state;

  const contentType = loadedContentItemTypes.get(editedContentItem?.editedContentItemTypeId || '');
  if (!contentType) {
    return Immutable.List();
  }

  const allowedContentGroups = getViewableContentGroups(contentType, editorUi);

  return allowedContentGroups ?? Immutable.List();
}

export const getElementsChangedBy = memoize.weak(
  (
    elements: ReadonlyArray<ICompiledContentItemElementData>,
    previousRevisionTime: DateTimeStamp,
  ): ReadonlyMap<Uuid, UserId> => {
    return elements.reduce((result, element) => {
      const isChanged =
        element.lastModifiedAt &&
        Date.parse(element.lastModifiedAt) > Date.parse(previousRevisionTime);

      if (!isChanged || !element.lastModifiedBy) {
        return result;
      }

      return result.set(element.elementId, element.lastModifiedBy);
    }, new Map<Uuid, UserId>());
  },
);

export function canViewContentElement(elementId: Uuid, state: IStore): boolean {
  const { loadedContentItemTypes, editedContentItem } = state.contentApp;
  const itemType = loadedContentItemTypes.get(editedContentItem?.editedContentItemTypeId || '');

  if (!itemType || !itemType.contentElements.some((e) => e.elementId === elementId)) {
    // type might not be loaded yet (when transitioning from one item to another)
    return false;
  }
  const groupId = getContentGroupForElement(itemType.contentElements, elementId);
  return canViewContentGroup(groupId, state.contentApp.editorUi);
}
