import { Dispatch, GetState, ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../../../_shared/models/TrackUserEvent.type.ts';
import { logError } from '../../../../../../_shared/utils/logError.ts';
import { IContentItemWithVariantServerModel } from '../../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import { TemporaryContentItemState } from '../../../../../contentInventory/content/models/temporaryContentItemState.ts';
import {
  ContentItemEditing_ArchiveTemporaryContentItem_Finished,
  ContentItemEditing_ArchiveTemporaryContentItem_Started,
} from '../../constants/contentItemEditingActionTypes.ts';

interface IDeps {
  readonly contentItemRepository: {
    readonly archiveVariant: (
      contentItemId: Uuid,
      variantId: Uuid,
    ) => Promise<IContentItemWithVariantServerModel>;
  };
  readonly loadContentTypes: () => ThunkPromise;
  readonly trackUserEventWithData: TrackUserEventWithDataAction;
}

const temporaryItemArchiveStarted = (itemId: Uuid) =>
  ({
    type: ContentItemEditing_ArchiveTemporaryContentItem_Started,
    payload: { itemId },
  }) as const;

const temporaryItemArchiveFinished = (itemId: Uuid) =>
  ({
    type: ContentItemEditing_ArchiveTemporaryContentItem_Finished,
    payload: { itemId },
  }) as const;

export type ArchiveTemporaryContentItemActionsType = ReturnType<
  typeof temporaryItemArchiveStarted | typeof temporaryItemArchiveFinished
>;

export const createArchiveTemporaryContentItemAction =
  (deps: IDeps) =>
  (): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {
      editorUi: { temporaryItem },
    } = getState().contentApp;

    if (!temporaryItem || temporaryItem.itemState !== TemporaryContentItemState.Loaded) {
      return;
    }

    dispatch(temporaryItemArchiveStarted(temporaryItem.itemId));

    try {
      await deps.contentItemRepository.archiveVariant(
        temporaryItem.itemId,
        temporaryItem.variantId,
      );

      dispatch(
        deps.trackUserEventWithData(TrackedEvent.ContentItemDeleted, { reason: 'no-changes' }),
      );
      dispatch(temporaryItemArchiveFinished(temporaryItem.itemId));
      await dispatch(deps.loadContentTypes());
    } catch (e) {
      logError(
        `Archiving variant with itemId: '${temporaryItem.itemId}', variantId: '${temporaryItem.variantId}' failed.`,
        e,
      );
    }
  };
