import React, { useEffect } from 'react';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { OperationStatus } from '../../../../_shared/models/OperationStatus.ts';
import { isMultipleWorkflowsConfigurationVisible } from '../../../../_shared/selectors/Workflows/isMultipleWorkflowsConfigurationVisible.ts';
import { leaveWorkflowEditor } from '../actions/workflowActions.ts';
import { WorkflowEditor as WorkflowEditorComponent } from '../components/WorkflowEditor.tsx';
import { hasWorkflowEditorUnsavedChanges } from '../utils/hasWorkflowEditorUnsavedChanges.ts';

type WorkflowEditorBaseProps = {
  readonly onInit: () => void;
  readonly onSaveWorkflow: (onSuccess?: () => void, onFail?: () => void) => void;
};

export const WorkflowEditorBase: React.FC<WorkflowEditorBaseProps> = ({
  onInit,
  onSaveWorkflow,
}) => {
  const dispatch = useDispatch();

  const workflowName = useSelector((s) => s.workflowsApp.editorUi.editedWorkflowName);
  const hasUnsavedChanges = useSelector(hasWorkflowEditorUnsavedChanges);
  const isBeingSaved = useSelector(
    (s) => s.projectsApp.savingStatus.workflow.status === OperationStatus.Pending,
  );
  const loadingStatus = useSelector((s) => s.workflowsApp.editorUi.workflowLoadingStatus);
  const isAdvancedEditorEnabled = useSelector(isMultipleWorkflowsConfigurationVisible);

  useEffect(() => {
    onInit();

    return () => {
      dispatch(leaveWorkflowEditor());
    };
  }, [onInit]);

  if (loadingStatus !== LoadingStatus.Loaded) {
    return <Loader />;
  }

  return (
    <WorkflowEditorComponent
      hasUnsavedChanges={hasUnsavedChanges}
      isAdvancedEditorEnabled={isAdvancedEditorEnabled}
      isBeingSaved={isBeingSaved}
      isCentered={isAdvancedEditorEnabled}
      pageTitle={isAdvancedEditorEnabled ? workflowName : undefined}
      onSaveWorkflow={onSaveWorkflow}
    />
  );
};

WorkflowEditorBase.displayName = 'WorkflowEditorBase';
