import { useAttachRef } from '@kontent-ai/hooks';
import { assert } from '@kontent-ai/utils';
import { useCheckboxGroupItem } from '@react-aria/checkbox';
import { useHover } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import PropTypes from 'prop-types';
import React, { FocusEventHandler, ReactNode, useContext, useId } from 'react';
import { useOurFocusRing } from '../../hooks/useOurFocusRing.ts';
import { Box } from '../../layout/Box/Box.tsx';
import { Inline } from '../../layout/Inline/Inline.tsx';
import { Column } from '../../layout/Row/Column.tsx';
import { Row } from '../../layout/Row/Row.tsx';
import { Stack } from '../../layout/Stack/Stack.tsx';
import { getDataUiComponentAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { CheckboxGroupContext } from '../CheckboxGroup/CheckboxGroupContext.tsx';
import { Tooltip } from '../Tooltip/Tooltip.tsx';
import { pick } from '../_utils/pick.ts';
import { TooltipPropsExtension, tooltipExtensionPropTypes } from '../_utils/propPrefabs.ts';
import { CheckboxCaption } from './components/CheckboxCaption.tsx';
import { CheckboxSymbol } from './components/CheckboxSymbol.tsx';
import { StyledCheckboxInput } from './components/StyledCheckboxInput.tsx';
import { StyledCheckboxLabel, labelOffset } from './components/StyledCheckboxLabel.tsx';
import { StyledCheckboxWrapper } from './components/StyledCheckboxWrapper.tsx';
import { CheckboxState, checkboxStateOptions } from './types.ts';
import { getCheckboxStatus } from './utils/getCheckboxStatus.ts';
import { CheckboxStylingProps, checkboxPadding } from './utils/stylingUtils.ts';

type CheckboxItemProps = Pick<TooltipPropsExtension, 'tooltipPlacement' | 'tooltipText'> & {
  readonly ariaLabel?: string;
  readonly autoFocus?: boolean;
  readonly caption?: ReactNode | null;
  readonly checkboxState: CheckboxState;
  readonly children?: ReactNode;
  readonly className?: string;
  readonly indeterminate?: boolean;
  readonly name?: string;
  readonly onBlur?: FocusEventHandler<HTMLInputElement>;
  readonly onFocus?: FocusEventHandler<HTMLInputElement>;
  readonly tabIndex?: number;
  readonly value: string;
};

const propTypes: PropTypeMap<CheckboxItemProps> = {
  ...pick(tooltipExtensionPropTypes, ['tooltipText', 'tooltipPlacement']),
  ariaLabel: PropTypes.string,
  autoFocus: PropTypes.bool,
  caption: PropTypes.node,
  checkboxState: PropTypes.oneOf(checkboxStateOptions).isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  indeterminate: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  tabIndex: PropTypes.number,
  value: PropTypes.string.isRequired,
};

export const CheckboxItem = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<CheckboxItemProps>
>(
  (
    {
      ariaLabel,
      autoFocus,
      caption,
      checkboxState,
      children,
      indeterminate = false,
      onBlur,
      onFocus,
      tooltipText = '',
      tooltipPlacement = 'top',
      className,
      tabIndex,
      name,
      value,
      ...restProps
    },
    forwardedRef,
  ) => {
    const checkboxGroupContext = useContext(CheckboxGroupContext);
    assert(
      checkboxGroupContext,
      () => `${__filename} component was rendered outside CheckboxGroupContext`,
    );

    const isChecked = checkboxGroupContext.state.isSelected(value);
    const isDisabled = checkboxState === 'disabled' || checkboxGroupContext.state.isDisabled;
    const checkboxStatus = getCheckboxStatus(isChecked, indeterminate);

    const { isFocusVisible, focusProps } = useOurFocusRing();
    const { isHovered, hoverProps } = useHover({});
    const { refToForward, refObject } = useAttachRef(forwardedRef);

    const captionId = useId();

    const { inputProps } = useCheckboxGroupItem(
      {
        'aria-describedby': caption ? captionId : undefined,
        'aria-label': ariaLabel,
        autoFocus,
        isIndeterminate: indeterminate,
        isDisabled,
        onFocus,
        onBlur,
        children,
        value,
      },
      checkboxGroupContext.state,
      refObject,
    );

    const checkboxStylingProps: CheckboxStylingProps = {
      checkboxState: isDisabled ? 'disabled' : checkboxState,
      checkboxStatus,
    };

    return (
      <Stack align="start" {...getDataUiComponentAttribute(CheckboxItem)} {...restProps}>
        <Inline>
          <Tooltip
            placement={tooltipPlacement}
            tooltipText={tooltipText}
            visible={isFocusVisible || isHovered}
          >
            <StyledCheckboxWrapper
              isFocusVisible={isFocusVisible}
              className={className}
              {...checkboxStylingProps}
              {...hoverProps}
            >
              <StyledCheckboxInput
                tabIndex={tabIndex}
                ref={refToForward}
                name={name}
                {...checkboxStylingProps}
                {...mergeProps(inputProps, focusProps)}
              />
              <Row component="span" alignY="start" noWrap>
                <Column component="span" width="content">
                  <CheckboxSymbol {...checkboxStylingProps} />
                </Column>
                {!!children && (
                  <Column width="fit-content">
                    <Box
                      paddingRight={checkboxPadding}
                      css={`
                        padding-top: ${labelOffset};
                        padding-bottom: ${labelOffset};
                      `}
                    >
                      <StyledCheckboxLabel {...checkboxStylingProps}>
                        {children}
                      </StyledCheckboxLabel>
                    </Box>
                  </Column>
                )}
              </Row>
            </StyledCheckboxWrapper>
          </Tooltip>
        </Inline>
        {!!caption && <CheckboxCaption captionId={captionId} caption={caption} />}
      </Stack>
    );
  },
);

CheckboxItem.displayName = 'CheckboxItem';
CheckboxItem.propTypes = propTypes;
