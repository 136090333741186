import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import {
  IEntityWebhookSetting,
  createEntityWebhookSettingDomainModel,
} from '../../../../data/models/webhooks/EntityWebhookSetting.ts';
import { IEntityWebhookRepository } from '../../../../repositories/interfaces/IEntityWebhookRepository.type.ts';
import {
  EntityWebhooks_InitEdit_Completed,
  EntityWebhooks_InitEdit_Started,
} from '../../constants/entityWebhooksActionTypes.ts';

interface IDeps {
  readonly entityWebhookRepository: IEntityWebhookRepository;
  readonly loadContentTypes: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadLanguages: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadCollections: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadTaxonomyGroups: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadWorkflows: (abortSignal?: AbortSignal) => ThunkPromise;
}

const started = () =>
  ({
    type: EntityWebhooks_InitEdit_Started,
  }) as const;

const completed = (webhook: IEntityWebhookSetting) =>
  ({
    type: EntityWebhooks_InitEdit_Completed,
    payload: {
      webhook,
    },
  }) as const;

export type InitEntityWebhookEditorActionsType = ReturnType<typeof started | typeof completed>;

export const createInitEntityWebhookEditorAction =
  (deps: IDeps) =>
  (webhookId: Uuid, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(started());

    const [entityWebhook] = await Promise.all([
      deps.entityWebhookRepository.get(webhookId, abortSignal),
      dispatch(deps.loadContentTypes(abortSignal)),
      dispatch(deps.loadLanguages(abortSignal)),
      dispatch(deps.loadCollections(abortSignal)),
      dispatch(deps.loadTaxonomyGroups(abortSignal)),
      dispatch(deps.loadWorkflows(abortSignal)),
    ]);

    dispatch(completed(createEntityWebhookSettingDomainModel(entityWebhook)));
  };
