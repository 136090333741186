import React from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import {
  getCurrentProjectContainer,
  getProjectPlan,
} from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import {
  showCreateEnvironmentDialog,
  showDeleteEnvironmentDialog,
  showSwapEnvironmentDialog,
} from '../actions/environmentsActions.ts';
import { initEnvironmentListing } from '../actions/thunkEnvironmentsActions.ts';
import { EnvironmentListing as EnvironmentListingComponent } from '../components/EnvironmentListing.tsx';
import { getEnvironmentsForCloning } from '../selectors/getEnvironmentsForCloning.ts';
import { getSplitEnvironmentsForListing } from '../selectors/getEnvironmentsForListing.ts';
import {
  getDefaultEnvironmentToCloneFromId,
  getEnvironmentsCount,
  isEnvironmentsLimitReached,
} from '../utils/environmentsUtils.ts';

interface IOwnProps {
  readonly withSubscriptionIdInRoute?: Uuid;
}

export const EnvironmentListing: React.FC<IOwnProps> = (props) => {
  const loadingStatus = useSelector((s) => s.environmentsApp.loadingStatus);
  const currentProject = useSelector((s) => getCurrentProjectContainer(s));
  const environments = useSelector((s) =>
    getSplitEnvironmentsForListing(s, currentProject.masterEnvironmentId),
  );
  const environmentsCount = useSelector((s) => getEnvironmentsCount(s, currentProject.projectId));
  const environmentsForCloning = useSelector((s) =>
    getEnvironmentsForCloning(s, currentProject.masterEnvironmentId),
  );
  const defaultCloneFromId = getDefaultEnvironmentToCloneFromId(
    environmentsForCloning,
    currentProject.masterEnvironmentId,
  );
  const hasAnyEnvironmentToCloneFrom = environmentsForCloning.count() > 0;
  const maxProjectEnvironments = useSelector(
    (s) => getProjectPlan(s, currentProject.projectId).features.maxProjectEnvironments,
  );

  const dispatch = useDispatch();
  const initialize = () => dispatch(initEnvironmentListing());
  const onCreateEnvironment = (cloneFromId: Uuid, isNewEnvironment: boolean) =>
    dispatch(showCreateEnvironmentDialog(cloneFromId, isNewEnvironment));
  const onDeleteEnvironment = (environmentId: Uuid) =>
    dispatch(showDeleteEnvironmentDialog(environmentId));
  const onSwapEnvironment = (environmentId: Uuid) =>
    dispatch(showSwapEnvironmentDialog(environmentId));

  return (
    <EnvironmentListingComponent
      defaultCloneFromId={defaultCloneFromId}
      environments={environments}
      hasAnyEnvironmentToCloneFrom={hasAnyEnvironmentToCloneFrom}
      initialize={initialize}
      isEnvironmentsFeatureInPlan={maxProjectEnvironments !== 1}
      loadingStatus={loadingStatus}
      masterEnvironmentId={currentProject.masterEnvironmentId}
      onCreateEnvironment={onCreateEnvironment}
      onDeleteEnvironment={onDeleteEnvironment}
      onSwapEnvironment={onSwapEnvironment}
      planLimitReached={isEnvironmentsLimitReached(maxProjectEnvironments, environmentsCount)}
      subscriptionId={props.withSubscriptionIdInRoute}
    />
  );
};

EnvironmentListing.displayName = 'EnvironmentListing';
