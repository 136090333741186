import Immutable from 'immutable';
import { Action } from '../../../@types/Action.type.ts';
import { AssetLibrary_Asset_TitleChanged } from '../../../applications/contentInventory/assets/constants/assetLibraryActionTypes.ts';
import { UntitledContentTypeName } from '../../../applications/contentModels/contentTypes/constants/contentTypeConstants.ts';
import {
  ContentType_Editor_ChangeContentTypeName,
  ContentType_Editor_SavingFinished,
} from '../../../applications/contentModels/contentTypes/constants/contentTypesActionTypes.ts';
import { UntitledContentTypeSnippetName } from '../../../applications/contentModels/snippets/constants/UntitledContentTypeSnippetName.ts';
import {
  ContentTypeSnippet_Editor_ChangeName,
  ContentTypeSnippet_Editor_SavingFinished,
} from '../../../applications/contentModels/snippets/constants/snippetActionTypes.ts';
import { Taxonomy_Group_NameChanged } from '../../../applications/contentModels/taxonomy/constants/taxonomyActionTypes.ts';
import { UntitledEntityWebhookName } from '../../../applications/entityWebhooks/constants/entityWebhookConstants.ts';
import { EntityWebhooks_Editing_NameChanged } from '../../../applications/entityWebhooks/constants/entityWebhooksActionTypes.ts';
import {
  UntitledRoleSurrogateName,
  UntitledWorkflowSurrogateName,
} from '../../../applications/environmentSettings/roles/constants/UIConstants.ts';
import { Role_Editing_NameChanged } from '../../../applications/environmentSettings/roles/constants/rolesActionTypes.ts';
import { Workflow_Editing_NameChanged } from '../../../applications/environmentSettings/workflow/constants/workflowActionTypes.ts';
import { ContentItemEditing_ItemName_Changed } from '../../../applications/itemEditor/features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { getNotEmptyItemName } from '../../../applications/itemEditor/stores/utils/contentItemHelperMethods.ts';
import {
  ApiKeyDetail_Updating_Finished,
  MapiKeyDetail_Editor_NameLoaded,
} from '../../../applications/projectSettings/apiKeys/constants/apiKeysActionTypes.ts';
import { Subscription_SubscriptionRename_Finished } from '../../../applications/subscriptionManagement/General/constants/actionTypes.ts';
import { UntitledSubscription } from '../../../applications/subscriptionManagement/shared/constants/UIConstants.ts';
import { UntitledTaxonomyGroup } from '../../constants/defaultNames.ts';
import { Shared_Breadcrumbs_Changed } from '../../constants/sharedActionTypes.ts';
import { Breadcrumb } from '../../models/Breadcrumb.type.ts';
import { isEmptyOrWhitespace } from '../../utils/stringUtils.ts';

const initialState: Immutable.List<Breadcrumb> = Immutable.List();

const updateInnerBreadcrumbTitle = (
  breadcrumbIndex: number,
  breadcrumbList: Immutable.List<Breadcrumb>,
  title: string,
): Immutable.List<Breadcrumb> =>
  breadcrumbList.isEmpty()
    ? breadcrumbList
    : breadcrumbList.update(breadcrumbIndex, (lastBreadcrumb) => {
        return {
          ...lastBreadcrumb,
          title,
        };
      });

const updateLastBreadcrumbTitle = (
  breadcrumbList: Immutable.List<Breadcrumb>,
  title: string,
): Immutable.List<Breadcrumb> => updateInnerBreadcrumbTitle(-1, breadcrumbList, title);

export const breadcrumbs = (state = initialState, action: Action): Immutable.List<Breadcrumb> => {
  switch (action.type) {
    case Shared_Breadcrumbs_Changed: {
      return action.payload.breadcrumbs;
    }

    case Taxonomy_Group_NameChanged: {
      return updateLastBreadcrumbTitle(
        state,
        isEmptyOrWhitespace(action.payload.name) ? UntitledTaxonomyGroup : action.payload.name,
      );
    }

    case ContentTypeSnippet_Editor_ChangeName: {
      return updateLastBreadcrumbTitle(
        state,
        isEmptyOrWhitespace(action.payload.name)
          ? UntitledContentTypeSnippetName
          : action.payload.name,
      );
    }

    case ContentType_Editor_ChangeContentTypeName: {
      return updateLastBreadcrumbTitle(
        state,
        isEmptyOrWhitespace(action.payload.name) ? UntitledContentTypeName : action.payload.name,
      );
    }

    case Role_Editing_NameChanged: {
      return updateLastBreadcrumbTitle(
        state,
        isEmptyOrWhitespace(action.payload.name) ? UntitledRoleSurrogateName : action.payload.name,
      );
    }

    case Workflow_Editing_NameChanged: {
      return updateLastBreadcrumbTitle(
        state,
        isEmptyOrWhitespace(action.payload.name)
          ? UntitledWorkflowSurrogateName
          : action.payload.name,
      );
    }

    case EntityWebhooks_Editing_NameChanged: {
      return updateLastBreadcrumbTitle(
        state,
        isEmptyOrWhitespace(action.payload.name) ? UntitledEntityWebhookName : action.payload.name,
      );
    }

    case AssetLibrary_Asset_TitleChanged: {
      return updateLastBreadcrumbTitle(
        state,
        isEmptyOrWhitespace(action.payload.title)
          ? action.payload.placeholder
          : action.payload.title,
      );
    }

    case Subscription_SubscriptionRename_Finished: {
      const breadcrumbTileIndex = -2;
      const title = isEmptyOrWhitespace(action.payload.name)
        ? UntitledSubscription
        : action.payload.name;
      return updateInnerBreadcrumbTitle(breadcrumbTileIndex, state, title);
    }

    case ContentItemEditing_ItemName_Changed: {
      return updateLastBreadcrumbTitle(state, getNotEmptyItemName(action.payload.newName));
    }

    case ContentTypeSnippet_Editor_SavingFinished: {
      const name = action.payload.contentTypeSnippet.name;
      return updateLastBreadcrumbTitle(
        state,
        isEmptyOrWhitespace(name) ? UntitledContentTypeSnippetName : name,
      );
    }

    case ContentType_Editor_SavingFinished: {
      const name = action.payload.contentType.name;
      return updateLastBreadcrumbTitle(
        state,
        isEmptyOrWhitespace(name) ? UntitledContentTypeName : name,
      );
    }

    case ApiKeyDetail_Updating_Finished: {
      return updateLastBreadcrumbTitle(state, action.payload.apiKey.name);
    }

    case MapiKeyDetail_Editor_NameLoaded: {
      return updateLastBreadcrumbTitle(state, action.payload.name);
    }

    default:
      return state;
  }
};
