import { InvariantException } from '@kontent-ai/errors';
import { memoize } from '@kontent-ai/memoization';
import React from 'react';
import { connect } from 'react-redux';
import { ActiveCapabilityType } from '../../../../../_shared/models/activeCapability.type.ts';
import { areCollectionsVisibleForCurrentUser } from '../../../../../_shared/selectors/contentCollections.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { isDisabled } from '../../../../../_shared/utils/contentItemUtils.ts';
import {
  canUpdateContentGroup,
  hasActiveVariantCapabilityForEditedItem,
} from '../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { ICompiledContentType } from '../../../../contentInventory/content/models/CompiledContentType.ts';
import { isEditableElement } from '../../../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { getIsoLanguageCodename } from '../../../../contentInventory/content/utils/getIsoLanguageCodename.ts';
import { areSpacesInCollectionsEnabled } from '../../../../environmentSettings/selectors/allowedFeaturesUtils.ts';
import { ICompiledContentItemElementData } from '../../../models/contentItemElements/ICompiledContentItemElement.ts';
import { getAreAnyContentGroupsVisible } from '../../../selectors/getAreAnyContentGroupsVisible.ts';
import {
  getEditedItemViewableContentGroups,
  getElementById,
  getSelectedContentGroupIdFromStateOrFirst,
  getTypeElementsInContentGroup,
} from '../../../stores/utils/contentItemElementsUtils.ts';
import {
  prioritizeFailures,
  shouldDisableFormBasedOnFailureType,
} from '../../../utils/editingSaveFailureutils.ts';
import { isElementVisible } from '../../../utils/itemElementConditionUtils.ts';
import {
  ContentItemElements as ContentItemElementsComponent,
  IContentItemElementsProps,
} from '../components/ContentItemElements.tsx';
import { CreateContentGroupTabsId } from '../utils/contentGroupTabsId.ts';

function mapStateToProps(state: IStore): IContentItemElementsProps {
  const {
    contentApp: {
      editedContentItem,
      editedContentItemVariant,
      editedContentItemVariantElements,
      editorUi: { activeCapabilities },
      loadedContentItemTypes,
      editedContentItemStatus: { failures },
    },
    data: { languages },
  } = state;

  const [highestPriorityFailureEntry] = prioritizeFailures(failures);
  const [, highestPriorityFailure] = highestPriorityFailureEntry ?? [];
  const isDisabledBecauseSaveFailure =
    !!highestPriorityFailure && shouldDisableFormBasedOnFailureType(highestPriorityFailure.status);

  const isCollectionInMainPaneEnabled =
    areSpacesInCollectionsEnabled(state) && areCollectionsVisibleForCurrentUser(state);
  const selectedLanguageId = getSelectedLanguageIdOrThrow(state);
  if (!editedContentItem || !editedContentItemVariant) {
    throw InvariantException(
      'ContentItemElements.tsx container: "editedContentItem" or "editedContentItemVariant" is not loaded',
    );
  }

  const editedContentItemType = loadedContentItemTypes.get(
    editedContentItem.editedContentItemTypeId,
  );

  if (!editedContentItemType) {
    throw InvariantException(
      'ContentItemElements.tsx container: "editedContentItemType" is not loaded',
    );
  }

  const contentGroupTabsId = CreateContentGroupTabsId.forContentItem(editedContentItem.id);
  const selectedContentGroupId = getSelectedContentGroupIdFromStateOrFirst(
    contentGroupTabsId,
    getEditedItemViewableContentGroups(state),
    state,
  );
  const typeElementsToRender = getTypeElementsToRender(
    editedContentItemType,
    selectedContentGroupId,
    editedContentItemVariantElements,
  );

  const disabled = isDisabled(false, editedContentItemVariant) || isDisabledBecauseSaveFailure;
  const currentLanguage = languages.byId.get(selectedLanguageId) || languages.defaultLanguage;
  const canUpdateGroup = canUpdateContentGroup(selectedContentGroupId, state.contentApp.editorUi);

  return {
    activeCapabilities: activeCapabilities.variantCapabilities,
    areAnyContentGroupsVisible: getAreAnyContentGroupsVisible(state),
    canUpdateContent:
      hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.UpdateContent, state) &&
      canUpdateGroup,
    contentType: editedContentItemType,
    disabled,
    isCollectionInMainPaneEnabled,
    selectedLanguageIsoCodename: getIsoLanguageCodename(
      currentLanguage.name,
      currentLanguage.codename,
    ),
    typeElementsToRender,
    workflowStepId: editedContentItemVariant.assignment.workflowStatus.id,
  };
}

export const ContentItemElements: React.ComponentType = connect(mapStateToProps)(
  ContentItemElementsComponent,
);

const getTypeElementsToRender = memoize.weak(
  (
    editedContentItemType: ICompiledContentType,
    selectedContentGroupId: Uuid | null,
    editedContentItemVariantElements: ReadonlyArray<ICompiledContentItemElementData>,
  ) => {
    const allTypeElementsInContentGroup = getTypeElementsInContentGroup(
      editedContentItemType.contentElements,
      selectedContentGroupId,
    );

    return allTypeElementsInContentGroup
      .filter(
        (typeElement) =>
          !isEditableElement(typeElement) ||
          getElementById(typeElement.elementId, editedContentItemVariantElements),
      )
      .filter((typeElement) => isElementVisible(typeElement, editedContentItemVariantElements));
  },
);
