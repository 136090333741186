import { Card } from '@kontent-ai/component-library/Card';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { ReactNode } from 'react';
import { Divider } from '../../../../../component-library/components/Dividers/Divider.tsx';
import { DividerDirection } from '../../../../../component-library/components/Dividers/components/StyledDivider.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface IApiActivationStatusCardProps {
  readonly renderApiKeysCallout: () => ReactNode;
  readonly renderManagementApiStatusSwitch: () => ReactNode;
  readonly renderSecuredDeliveryApiStatusSwitch: () => ReactNode;
}

export const ApiActivationStatusCard: React.FC<IApiActivationStatusCardProps> = ({
  renderApiKeysCallout,
  renderManagementApiStatusSwitch,
  renderSecuredDeliveryApiStatusSwitch,
}) => (
  <Card
    cardLabel="Enabled APIs"
    component="section"
    {...getDataUiElementAttribute(DataUiElement.EnabledApisCard)}
  >
    <Card.Headline {...getDataUiElementAttribute(DataUiElement.EnabledApisCardTitle)}>
      Enabled APIs
    </Card.Headline>
    <Card.Body>
      <Row>
        <Column flexFactor={1} {...getDataUiElementAttribute(DataUiElement.ManagementApiActivator)}>
          {renderManagementApiStatusSwitch()}
        </Column>
        <Column width="content">
          <Divider
            direction={DividerDirection.Vertical}
            offsetBefore={Spacing.XL}
            offsetAfter={Spacing.XL}
          />
        </Column>
        <Column
          flexFactor={1}
          {...getDataUiElementAttribute(DataUiElement.SecureDeliveryApiActivator)}
        >
          {renderSecuredDeliveryApiStatusSwitch()}
        </Column>
      </Row>
    </Card.Body>
    <Card.Footer>{renderApiKeysCallout()}</Card.Footer>
  </Card>
);

ApiActivationStatusCard.displayName = 'ApiActivationStatusCard';
