// This is a modified Popper arrow modifier which adjusts the arrow to align it to an edge part of inline content
// typically the sub-rectangle placed most at the side where the popper element is
// Source: https://github.com/popperjs/popper-core/blob/master/src/modifiers/arrow.js
// Note: The code is slightly modified to match our standards but mostly kept the same (including the debugging parts) for easier updates
import { ComponentLibraryGlobals } from '@kontent-ai/component-library/globals';
import {
  Modifier,
  ModifierArguments,
  Placement,
  Rect,
  SideObject,
  basePlacements,
  bottom,
  left,
  right,
  top,
} from '@popperjs/core';
import contains from '@popperjs/core/lib/dom-utils/contains';
import getLayoutRect from '@popperjs/core/lib/dom-utils/getLayoutRect';
import getOffsetParent from '@popperjs/core/lib/dom-utils/getOffsetParent';
import { isHTMLElement } from '@popperjs/core/lib/dom-utils/instanceOf';
import expandToHashMap from '@popperjs/core/lib/utils/expandToHashMap';
import getBasePlacement from '@popperjs/core/lib/utils/getBasePlacement';
import getMainAxisFromPlacement from '@popperjs/core/lib/utils/getMainAxisFromPlacement';
import mergePaddingObject from '@popperjs/core/lib/utils/mergePaddingObject';
import { within } from '@popperjs/core/lib/utils/within';
import { isNumeric } from '../../../../../app/_shared/utils/validation/isNumeric.ts';
import { getInlineBoundingClientRect } from './inlinePlacementUtils.ts';

type Padding = Partial<SideObject> | number;
type PaddingFunc = (params: {
  popper: Rect;
  reference: Rect;
  placement: Placement;
}) => Partial<SideObject>;

export type Options = {
  element: HTMLElement | string | null;
  padding: Padding | PaddingFunc;
};

const toPaddingObject = (
  padding: Padding | PaddingFunc | undefined,
  state: ModifierArguments<Options>['state'],
): Partial<SideObject> => {
  const paddingObject =
    typeof padding === 'function'
      ? padding({
          ...state.rects,
          placement: state.placement,
        })
      : padding;

  return mergePaddingObject(
    isNumeric(paddingObject) ? expandToHashMap(paddingObject, basePlacements) : paddingObject ?? {},
  );
};

function arrow({ state, name, options }: ModifierArguments<Options>) {
  const arrowElement = state.elements.arrow;
  const popperOffsets = state.modifiersData.popperOffsets;
  const basePlacement = getBasePlacement(state.placement);
  const axis = getMainAxisFromPlacement(basePlacement);
  const isVertical = [left, right].includes(basePlacement as any);
  const len = isVertical ? 'height' : 'width';

  if (!arrowElement || !popperOffsets) {
    return;
  }

  const paddingObject = toPaddingObject(options.padding, state);
  const arrowRect = getLayoutRect(arrowElement);
  const minProp = axis === 'y' ? top : left;
  const maxProp = axis === 'y' ? bottom : right;

  // Kontent - We use the rectangle from TippyJS inlinePositioning plugin instead of the original bounding rectangle
  // as the inline content may not be exactly rectangular, in fact often isn't
  // const referenceRect = state.rects.reference;
  const referenceRect = getInlineBoundingClientRect(basePlacement, state.elements.reference);

  const endDiff =
    referenceRect[len] + referenceRect[axis] - popperOffsets[axis] - state.rects.popper[len];
  const startDiff = popperOffsets[axis] - referenceRect[axis];

  const arrowOffsetParent = getOffsetParent(arrowElement);
  const clientSize = arrowOffsetParent
    ? axis === 'y'
      ? arrowOffsetParent.clientHeight || 0
      : arrowOffsetParent.clientWidth || 0
    : 0;

  const centerToReference = endDiff / 2 - startDiff / 2;

  // Make sure the arrow doesn't overflow the popper if the center point is
  // outside of the popper bounds
  const min = paddingObject[minProp] ?? 0;
  const max = clientSize - arrowRect[len] - (paddingObject[maxProp] ?? 0);
  const center = clientSize / 2 - arrowRect[len] / 2 + centerToReference;
  const offset = within(min, center, max);

  // Prevents breaking syntax highlighting...
  const axisProp: string = axis;
  state.modifiersData[name] = {
    [axisProp]: offset,
    centerOffset: offset - center,
  };
}

function effect({ state, options }: ModifierArguments<Options>) {
  let arrowElement: string | Element | null = options.element ?? '[data-popper-arrow]';

  if (arrowElement === null) {
    return;
  }

  // CSS selector
  if (typeof arrowElement === 'string') {
    arrowElement = state.elements.popper.querySelector(arrowElement);

    if (!arrowElement) {
      return;
    }
  }

  if (!isHTMLElement(arrowElement)) {
    ComponentLibraryGlobals.logError(
      [
        'Popper: "arrow" element must be an HTMLElement (not an SVGElement).',
        'To use an SVG arrow, wrap it in an HTMLElement that will be used as',
        'the arrow.',
      ].join(' '),
    );
  }

  if (!contains(state.elements.popper, arrowElement)) {
    ComponentLibraryGlobals.logError(
      ['Popper: "arrow" modifier’s `element` must be a child of the popper', 'element.'].join(' '),
    );

    return;
  }

  if (arrowElement instanceof HTMLElement) {
    state.elements.arrow = arrowElement;
  }
}

export type ArrowModifier = Modifier<'arrow', Options>;

const modifier: ArrowModifier = {
  name: 'arrow',
  enabled: true,
  phase: 'main',
  fn: arrow,
  effect,
  requires: ['popperOffsets'],
  requiresIfExists: ['preventOverflow'],
};

export { modifier as InlineArrowModifier };
