import PropTypes from 'prop-types';
import React from 'react';
import { IconName } from '../../../../_shared/constants/iconEnumGenerated.ts';
import { Button, ButtonSize } from '../../../../_shared/uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../../../_shared/uiComponents/Button/buttonStyle.ts';
import { Icon } from '../../../../_shared/uiComponents/Icon/Icon.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface ClearFilterButtonProps {
  readonly onClick: () => void;
}

const propTypes: PropTypesShape<ClearFilterButtonProps> = {
  onClick: PropTypes.func.isRequired,
};

export const AuditLogClearFilterButton: React.FC<ClearFilterButtonProps> = ({ onClick }) => (
  <Button
    size={ButtonSize.M}
    style={ButtonStyle.Tertiary}
    onClick={onClick}
    {...getDataUiActionAttribute(DataUiAction.ClearFilter)}
  >
    <Icon iconName={IconName.Times} className="btn__icon" />
    Clear
  </Button>
);

AuditLogClearFilterButton.displayName = 'AuditLogClearFilterButton';
AuditLogClearFilterButton.propTypes = propTypes;
