import { Collection, areArraysMembersShallowEqual, areShallowEqual } from '@kontent-ai/utils';
import React, { useEffect } from 'react';
import {
  closeNotificationBar,
  showErrorNotification,
} from '../../../_shared/actions/sharedActions.ts';
import { StatusMessageTypes } from '../../../_shared/constants/statusMessageType.ts';
import { HandleUnsavedFormOnNavigation } from '../../../_shared/containers/HandleUnsavedFormOnNavigation.tsx';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { createSpaceListingLink } from '../../../_shared/utils/routing/projectSubscriptionRoutingUtils.ts';
import { getCurrentProjectId } from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { getSortedContentTypesByName } from '../../contentModels/shared/utils/typeUtils.ts';
import { areSpacesEnabledForCurrentProject } from '../../environmentSettings/selectors/allowedFeaturesUtils.ts';
import { previewConfigurationFormChanged } from '../actions/previewConfigurationActions.ts';
import {
  updatePreviewConfiguration,
  validatePreviewConfiguration,
} from '../actions/thunkPreviewConfigurationActions.ts';
import { PreviewConfiguration as PreviewConfigurationComponent } from '../components/PreviewConfiguration.tsx';
import { PreviewConfigurationNotValidErrorMessage } from '../constants/previewConfigurationUiConstants.ts';
import { IPreviewUrlPattern } from '../models/IPreviewUrlPattern.type.ts';
import { IPreviewConfiguration, ISpaceDomain } from '../models/PreviewConfiguration.type.ts';
import { ContentTypeId } from '../stores/IPreviewConfigurationAppStoreState.type.ts';

export const PreviewConfiguration: React.FC = () => {
  const dispatch = useDispatch();
  const spaces = useSelector((state) => state.data.spaces.byId);
  const previewConfiguration = useSelector(
    (state) => state.previewConfigurationApp.previewConfiguration,
  );
  const editedPreviewConfiguration = useSelector(
    (state) => state.previewConfigurationApp.editedPreviewConfiguration,
  );
  const previewUrlsSectionErrors = useSelector(
    (state) => state.previewConfigurationApp.previewUrlsSectionErrors,
  );
  const contentTypes = useSelector((state) =>
    getSortedContentTypesByName(state.data.contentTypes.byId),
  );
  const areSpacesEnabled = useSelector(areSpacesEnabledForCurrentProject);
  const isFormBeingSaved = useSelector(
    (state) => state.previewConfigurationApp.status.messageType === StatusMessageTypes.Saving,
  );
  const spacesAppRoute = useSelector((s) =>
    createSpaceListingLink({
      projectId: getCurrentProjectId(s),
      subscriptionId: s.subscriptionApp.selectedSubscriptionId,
    }),
  );

  useEffect(() => {
    return () => {
      dispatch(closeNotificationBar());
    };
  }, []);

  const hasUnsavedChanges =
    !areArraysMembersShallowEqual(
      editedPreviewConfiguration?.spaceDomains ?? [],
      previewConfiguration?.spaceDomains ?? [],
    ) ||
    !arePreviewUrlPatternsEqual(
      editedPreviewConfiguration?.previewUrlPatterns ?? new Map(),
      previewConfiguration?.previewUrlPatterns ?? new Map(),
    );

  const changePreviewUrlPatterns = (
    previewUrlPatterns: ReadonlyMap<Uuid, ReadonlyArray<IPreviewUrlPattern>>,
  ): void => {
    if (!editedPreviewConfiguration) {
      return;
    }

    // Do not forget to add not used disabled legacy patterns to don't lose them
    const disabledLegacyPatterns = Array.from(
      editedPreviewConfiguration.previewUrlPatterns.entries(),
    ).filter(
      ([key, patterns]) => !previewUrlPatterns.has(key) && patterns.every((p) => !p.enabled),
    );

    const updatedPreviewConfiguration: IPreviewConfiguration = {
      ...editedPreviewConfiguration,
      previewUrlPatterns: new Map([...previewUrlPatterns, ...disabledLegacyPatterns]),
    };

    dispatch(previewConfigurationFormChanged(updatedPreviewConfiguration));
    dispatch(validatePreviewConfiguration());
  };

  const changeSpaceDomains = (spaceDomains: ReadonlyArray<ISpaceDomain>): void => {
    if (!editedPreviewConfiguration) {
      return;
    }

    const updatedPreviewConfiguration: IPreviewConfiguration = {
      ...editedPreviewConfiguration,
      spaceDomains,
    };

    dispatch(previewConfigurationFormChanged(updatedPreviewConfiguration));
    dispatch(validatePreviewConfiguration());
  };

  const submitForm = (onSuccess: () => void, onFail: () => void): void => {
    if (previewUrlsSectionErrors.size) {
      onFail();
      dispatch(showErrorNotification(PreviewConfigurationNotValidErrorMessage));
    } else {
      dispatch(updatePreviewConfiguration(onSuccess, onFail));
    }
  };

  return (
    <>
      <HandleUnsavedFormOnNavigation
        hasUnsavedChanges={hasUnsavedChanges}
        isBeingSaved={isFormBeingSaved}
        onSaveChanges={submitForm}
      />
      <PreviewConfigurationComponent
        areSpacesEnabled={areSpacesEnabled}
        contentTypes={contentTypes}
        onChangePreviewUrlPatterns={changePreviewUrlPatterns}
        onChangeSpaceDomains={changeSpaceDomains}
        previewConfiguration={editedPreviewConfiguration}
        previewUrlsSectionErrors={previewUrlsSectionErrors}
        spaces={spaces}
        spacesAppRoute={spacesAppRoute}
      />
    </>
  );
};

PreviewConfiguration.displayName = 'PreviewConfiguration';

const arePreviewUrlPatternsEqual = (
  patterns: ReadonlyMap<ContentTypeId, ReadonlyArray<IPreviewUrlPattern>>,
  otherPatterns: ReadonlyMap<ContentTypeId, ReadonlyArray<IPreviewUrlPattern>>,
): boolean => {
  if (patterns.size !== otherPatterns.size) {
    return false;
  }

  return Collection.getEntries(patterns).every(([contentTypeId, previewUrlPatterns]) => {
    const otherPreviewUrlPatterns = otherPatterns.get(contentTypeId) ?? [];

    if (previewUrlPatterns.length !== otherPreviewUrlPatterns.length) {
      return false;
    }

    return previewUrlPatterns.every((previewUrlPattern, index) => {
      const otherPreviewUrlPattern = otherPreviewUrlPatterns[index];

      return (
        !!otherPreviewUrlPattern &&
        previewUrlPattern.urlPattern === otherPreviewUrlPattern.urlPattern &&
        areShallowEqual(previewUrlPattern.spaces, otherPreviewUrlPattern.spaces)
      );
    });
  });
};
