import { usePrevious } from '@kontent-ai/hooks';
import { createGuid } from '@kontent-ai/utils';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import NavigationPrompt from 'react-router-navigation-prompt';
import { EnsureValidState } from '../../../../../_shared/components/EnsureValidState.tsx';
import { ContentItemOpenCommentRouteParams } from '../../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { PendingChangesDialog } from '../../PendingChangesDialog/containers/PendingChangesDialog.tsx';
import { areAnyOperationsPending } from '../../safeRedirect/selectors/areAnyOperationsPending.ts';
import { initContentItemEditing } from '../actions/thunkContentItemEditingActions.ts';
import { getIsEditedItemInitialized } from '../selectors/isEditedItemInitialized.ts';

const ActivePendingNavigation: React.FC<{
  readonly initOperationId: Uuid;
  readonly onConfirm: () => void;
  readonly onCancel: () => void;
  readonly onAbortInit: () => void;
}> = ({ initOperationId, onAbortInit, onCancel, onConfirm }) => {
  const isInitPending = useSelector((s) =>
    s.contentApp.editorUi.pendingOperationIds.has(initOperationId),
  );
  const previousIsInitPending = usePrevious(isInitPending);
  const triggeredWithInitPending = useRef(isInitPending).current;

  useEffect(() => {
    if (triggeredWithInitPending && previousIsInitPending && !isInitPending) {
      // When triggered while item is initializing, automatically confirm navigation after the init finishes
      onConfirm();
    }
  }, [isInitPending, previousIsInitPending, triggeredWithInitPending, onConfirm]);

  useEffect(() => {
    if (triggeredWithInitPending) {
      onAbortInit();
    }
  }, [onAbortInit, triggeredWithInitPending]);

  return triggeredWithInitPending ? null : (
    <PendingChangesDialog onCancel={onCancel} onConfirm={onConfirm} />
  );
};

ActivePendingNavigation.displayName = 'ActivePendingNavigation';

const HandlePendingNavigation: React.FC<{
  readonly initOperationId: Uuid;
  readonly onAbortInit: () => void;
}> = ({ initOperationId, onAbortInit }) => {
  const preventInvoluntaryNavigation = useSelector((state) => {
    const {
      contentApp: {
        editorUi: { editedContentItemVariantUploadingAssets },
        editedContentItemStatus: { failures: saveFailures },
        itemValidationErrors,
      },
    } = state;

    const hasUnsavedAssets = editedContentItemVariantUploadingAssets.length > 0;
    const hasFailedOperations = saveFailures.size > 0;
    const hasPendingOperations = areAnyOperationsPending(state);
    const hasValidationErrors = itemValidationErrors.size > 0;

    return hasUnsavedAssets || hasFailedOperations || hasPendingOperations || hasValidationErrors;
  });

  return (
    <NavigationPrompt when={preventInvoluntaryNavigation}>
      {({ isActive, onConfirm, onCancel }) =>
        isActive && (
          <ActivePendingNavigation
            initOperationId={initOperationId}
            onAbortInit={onAbortInit}
            onCancel={onCancel}
            onConfirm={onConfirm}
          />
        )
      }
    </NavigationPrompt>
  );
};

HandlePendingNavigation.displayName = 'HandlePendingNavigation';

type EnsureEditedItemInitializedProps = {
  readonly onRouteLeft?: () => void;
};

export const EnsureEditedItemInitialized: React.FC<
  React.PropsWithChildren<EnsureEditedItemInitializedProps>
> = ({ children, onRouteLeft }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { commentThreadId } = useParams<ContentItemOpenCommentRouteParams<string>>();

  const operationId = useMemo(createGuid, []);

  const abortController = useMemo(() => new AbortController(), []);
  const abort = useCallback(() => abortController.abort(), [abortController]);
  useEffect(() => abort, [abort]);

  const onRouteEntered = () =>
    dispatch(initContentItemEditing(history, commentThreadId, operationId, abortController.signal));

  const isEditedItemInitialized = useSelector((s) =>
    getIsEditedItemInitialized(s, location.pathname),
  );

  return (
    <>
      <HandlePendingNavigation onAbortInit={abort} initOperationId={operationId} />
      <EnsureValidState
        isStateEnsured={isEditedItemInitialized}
        onRouteEntered={onRouteEntered}
        onRouteLeft={onRouteLeft}
        renderLoader={() => null}
      >
        {children}
      </EnsureValidState>
    </>
  );
};

EnsureEditedItemInitialized.displayName = 'EnsureEditedItemInitialized';
