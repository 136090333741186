import React from 'react';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import { ControlAltShortcutTemplate } from '../../../../../_shared/constants/shortcutSymbols.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import {
  DataUiRteAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  IForwardedRefProps,
  forwardRef,
  forwardedRefProps,
} from '../../../../../_shared/utils/forwardedRefProps.tsx';
import {
  ISpecificButtonProps,
  RTEToolbarIconButton,
  specificButtonPropTypes,
} from '../../toolbars/components/RTEToolbarButton.tsx';
import { getContentComponentTooltip } from '../utils/getContentComponentTooltip.ts';

type ComponentButtonProps = ISpecificButtonProps & IForwardedRefProps<HTMLButtonElement>;

const ComponentButtonComponent: React.FC<ComponentButtonProps> = ({ ...otherProps }) => {
  const componentTooltip = useSelector(getContentComponentTooltip);

  return (
    <RTEToolbarIconButton
      {...otherProps}
      iconName={IconName.CollapseScheme}
      ref={otherProps.forwardedRef}
      shortcut={ControlAltShortcutTemplate('C')}
      tooltipPlacement={otherProps.tooltipPlacement ?? 'bottom-start'}
      tooltipText={
        otherProps.tooltipText ??
        (otherProps.disabled ? 'Components cannot be added here' : componentTooltip)
      }
      {...getDataUiActionAttribute(DataUiRteAction.InsertNewContentComponent)}
    />
  );
};

ComponentButtonComponent.displayName = 'ComponentButton';

const componentButtonPropTypes: PropTypesShape<ComponentButtonProps> = {
  ...specificButtonPropTypes,
  ...forwardedRefProps,
};
ComponentButtonComponent.propTypes = componentButtonPropTypes;

export const ComponentButton = forwardRef(ComponentButtonComponent);
