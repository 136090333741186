import { useCallback, useMemo } from 'react';
import { createGlobalStyle } from 'styled-components';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { Apply, EditorPlugin, Render } from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import { getEditorIdClassName } from '../../editorCore/utils/editorComponentUtils.ts';
import { withDisplayName } from '../../editorCore/utils/withDisplayName.ts';
import { StylesPlugin } from './StylesPlugin.tsx';

export type BorderlessPlugin = EditorPlugin<None, None, None, [StylesPlugin]>;

const BorderlessEditorStyle = createGlobalStyle<{ readonly editorId: string }>`
  .${(props) => getEditorIdClassName(props.editorId)} {
    background: none;
    border: none;
  }

  .${(props) => getEditorIdClassName(props.editorId)} .public-DraftEditor-content {
    padding: 0;
  }
`;

const render: Decorator<Render<BorderlessPlugin>> = (baseRender) => (state) => (
  <>
    {baseRender(state)}
    <BorderlessEditorStyle editorId={state.getEditorId()} />
  </>
);

export const useBorderless: PluginCreator<BorderlessPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('BorderlessPlugin', {
        ComposedEditor: (props) => {
          const apply: Apply<BorderlessPlugin> = useCallback((state) => {
            state.render.decorate(render);
            return {};
          }, []);

          return useEditorWithPlugin(baseEditor, props, { apply });
        },
      }),
    [baseEditor],
  );
