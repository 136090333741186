import { Collection } from '@kontent-ai/utils';
import { useSyncExternalStore } from 'react';
import { useAiTaskManager } from '../../../../contexts/AiTaskManagerProvider.tsx';
import { IsFinished, ResultSelector } from '../../types/ResultSelector.type.ts';
import { MatchAiTask } from './matchAiTask.type.ts';

export enum AiTaskProgress {
  Completed = 'Completed',
  NotStarted = 'NotStarted',
  Running = 'Running',
}

/**
 * Hook that observes progress of an AI task
 * @param selectResult Transforms AI action messages and context into a result.
 * @param matchAiTask A function that receives information about all running AI tasks and returns an id of the task the hook should take over.
 */
export const useAiTaskProgress = <TResult extends IsFinished>(
  selectResult: ResultSelector<TResult>,
  matchAiTask?: MatchAiTask,
): {
  readonly progress: AiTaskProgress;
  readonly result: ReturnType<typeof selectResult>;
} => {
  const aiTaskManager = useAiTaskManager();
  const allTasksSnapshot = useSyncExternalStore(
    aiTaskManager.subscribe,
    aiTaskManager.getAllTasksSnapshot,
  );
  const allTasks = Collection.getValues(allTasksSnapshot);

  const aiTaskId = matchAiTask?.(allTasks)?.id ?? null;

  if (!aiTaskId) {
    return {
      progress: AiTaskProgress.NotStarted,
      result: selectResult([], { hasTimedOut: false }),
    };
  }

  const messages = aiTaskManager.getTaskActionMessages(aiTaskId) ?? [];
  const result = selectResult(messages, { hasTimedOut: false });
  return {
    progress: result.isFinished ? AiTaskProgress.Completed : AiTaskProgress.Running,
    result,
  };
};
