import PropTypes, { ValidationMap } from 'prop-types';
import { TextCompiledTypeElementServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { TypeElementConfigurability } from '../../../../contentModels/shared/models/elements/types/elementConfigurabilityOptions.ts';
import { ElementType } from '../ContentItemElementType.ts';
import { UnitOption } from '../textLengthOptions.ts';
import {
  BaseTypeElementValidationMap,
  EmptyBaseTypeElement,
  IBaseTypeElement,
  ITypeElementWithTextLimit,
  ITypeElementWithValidationRegex,
  TypeElementDataWithValidationRegexValidationMap,
  TypeElementWithTextLimitValidationMap,
  getBaseTypeElementFromServerModel,
} from './types/TypeElement.ts';

export interface ITextTypeElement
  extends IBaseTypeElement,
    ITypeElementWithTextLimit,
    ITypeElementWithValidationRegex {
  readonly defaultValue: string;
  readonly type: ElementType.Text;
}

export const EmptyTextTypeElement: ITextTypeElement = {
  ...EmptyBaseTypeElement,
  _configurability: TypeElementConfigurability.Recommended,
  _lengthUnitOption: UnitOption.Words,
  defaultValue: '',
  maxChars: null,
  maxWords: null,
  type: ElementType.Text,
  validationRegex: null,
};

export const getTextTypeElementFromServerModel = (
  serverModel: TextCompiledTypeElementServerModel,
): ITextTypeElement => ({
  ...getBaseTypeElementFromServerModel(serverModel),
  _configurability: EmptyTextTypeElement._configurability,
  _lengthUnitOption: serverModel.maxChars ? UnitOption.Characters : UnitOption.Words,
  defaultValue: serverModel.default?.global.value ?? '',
  maxChars: serverModel.maxChars ? serverModel.maxChars : null,
  maxWords: serverModel.maxWords ? serverModel.maxWords : null,
  type: ElementType.Text,
  validationRegex: serverModel.validationRegex,
});

export const TextTypeElementPropTypes = PropTypes.exact<ValidationMap<ITextTypeElement>>({
  ...BaseTypeElementValidationMap([ElementType.Text]),
  ...TypeElementWithTextLimitValidationMap,
  ...TypeElementDataWithValidationRegexValidationMap,
  defaultValue: PropTypes.string.isRequired,
});

export const newTextTypeElement = (params: Partial<ITextTypeElement>): ITextTypeElement => ({
  ...EmptyTextTypeElement,
  ...params,
});
