import React from 'react';
import { ModalDialogType } from '../../../../../_shared/constants/modalDialogType.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { cascadePublishDialogSubmitted } from '../actions/thunkCascadeModalActions.ts';
import { getEditedContentItemName } from '../selectors/getEditedContentItemName.ts';
import { getSelectedItems } from '../selectors/getSelectedItems.ts';
import { getCannotPublishReasonForContext } from '../utils/getCannotPublishReason.ts';
import { getStatusInfoMessage } from '../utils/getStatusInfoMessage.ts';
import { CascadeModal } from './CascadeModal.tsx';
import { CascadePublishModalFooter } from './CascadePublishModalFooter.tsx';

const getSelectedItemsCount = (s: IStore) =>
  getSelectedItems(s, getCannotPublishReasonForContext(ModalDialogType.CascadePublishDialog))
    .length;

export const CascadePublishModal: React.FC = () => {
  const dispatch = useDispatch();

  const modalTitle = useSelector(
    (s) => `Select what should be published with ${getEditedContentItemName(s)}`,
  );
  const onPublish = () => dispatch(cascadePublishDialogSubmitted());
  const statusInfoMessage = useSelector((s) =>
    getStatusInfoMessage(getEditedContentItemName(s), getSelectedItemsCount(s)),
  );

  return (
    <CascadeModal
      modalTitle={modalTitle}
      renderFooter={({ isPublishDisabled, onClose }) => (
        <CascadePublishModalFooter
          isPublishDisabled={isPublishDisabled}
          onClose={onClose}
          onPublish={onPublish}
        />
      )}
      statusInfoMessage={statusInfoMessage}
    />
  );
};

CascadePublishModal.displayName = 'CascadePublishModal';
