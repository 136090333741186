import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Box } from '@kontent-ai/component-library/Box';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, Typography, colorTextDefault } from '@kontent-ai/component-library/tokens';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { IOtherResource, IResourceItem } from '../../data/resources.ts';
import { ResourceType } from '../../models/ResourceType.ts';
import { CodePane } from './ResourcesSections/CodePane.tsx';

type Props = {
  readonly item: IResourceItem;
  readonly onLinkClick: (linkId: string, technology: ResourceType, target: string) => void;
};

const ListItem = styled(Box).attrs({
  component: 'li',
  typography: Typography.BodyMediumEmphasis,
  color: colorTextDefault,
})``;

const renderSampleAppResources = (
  sampleAppResources: ReadonlyArray<IOtherResource> | undefined,
  onLinkClick: (linkId: string, technology: ResourceType, target: string) => void,
  resourceType: ResourceType,
): ReactElement | null => {
  const firstSampleApp = sampleAppResources?.[0];
  if (!firstSampleApp || !sampleAppResources) {
    return null;
  }

  if (sampleAppResources.length === 1) {
    return (
      <OutwardLink
        href={firstSampleApp.linkUrl}
        key={firstSampleApp.linkId}
        onClick={() => {
          onLinkClick(firstSampleApp.linkId, resourceType, firstSampleApp.linkUrl);
        }}
      >
        Run {firstSampleApp.linkName} sample app
      </OutwardLink>
    );
  }

  return (
    <>
      Run sample apps:{' '}
      {sampleAppResources.map((resource: IOtherResource) => (
        <span key={resource.linkId}>
          <OutwardLink
            href={resource.linkUrl}
            onClick={() => {
              onLinkClick(resource.linkId, resourceType, resource.linkUrl);
            }}
          >
            {resource.linkName}
          </OutwardLink>
          {sampleAppResources[sampleAppResources.length - 1] !== resource && ', '}
        </span>
      ))}
    </>
  );
};

export const SampleAppContent: React.FC<Props> = (props) => {
  const sdkResourcesComponents = props.item.sdkResources.length && (
    <>
      Explore{' '}
      {props.item.sdkResources.map((resource: IOtherResource) => (
        <OutwardLink
          href={resource.linkUrl}
          key={resource.linkId}
          onClick={() => {
            props.onLinkClick(resource.linkId, props.item.resourceType, resource.linkUrl);
          }}
        >
          {resource.linkName}
        </OutwardLink>
      ))}
    </>
  );

  const stepByStepComponents = props.item.stepByStepResources?.length && (
    <>
      {props.item.stepByStepResources?.map((resource: IOtherResource) => (
        <OutwardLink
          href={resource.linkUrl}
          key={resource.linkId}
          onClick={() => {
            props.onLinkClick(resource.linkId, props.item.resourceType, resource.linkUrl);
          }}
        >
          {resource.linkName}
        </OutwardLink>
      ))}
    </>
  );

  const sampleAppComponents = renderSampleAppResources(
    props.item.sampleAppResources,
    props.onLinkClick,
    props.item.resourceType,
  );

  return (
    <Row spacing={Spacing.L}>
      <Column>
        <Stack spacing={Spacing.L}>
          <Box typography={Typography.HeadlineMedium} color={colorTextDefault}>
            Resources
          </Box>

          <Stack spacing={Spacing.S} component="ul" align="normal">
            {sdkResourcesComponents && <ListItem>{sdkResourcesComponents}</ListItem>}

            {stepByStepComponents && <ListItem>{stepByStepComponents}</ListItem>}

            {sampleAppComponents && <ListItem>{sampleAppComponents}</ListItem>}
          </Stack>
        </Stack>
      </Column>
      <Column>
        <Stack spacing={Spacing.L}>
          <Box typography={Typography.HeadlineMedium} color={colorTextDefault}>
            {`Get the ${props.item.sdkName} SDK`}
          </Box>
          <CodePane code={props.item.installScript} />
        </Stack>
      </Column>
    </Row>
  );
};
