import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import { DefaultVariantId } from '../../../../_shared/constants/variantIdValues.ts';
import { isLanguageActive } from '../../../../_shared/models/utils/isLanguageActive.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { Capability } from '../../../../_shared/utils/permissions/capability.ts';
import {
  getLanguageIdsWithAllowedCapabilityForCollection,
  getLanguageIdsWithAllowedCapabilityInAnyCollection,
} from '../../../../_shared/utils/permissions/getLanguageIdsWithAllowedCapability.ts';
import { addDefaultLanguageSuffix } from '../../../../applications/environmentSettings/localization/utils/languageUtils.ts';
import { ILanguage, Languages } from '../../../models/languages/Language.ts';
import { IUser } from '../../user/IUser.type.ts';
import { getNormalizedRolesWithSettingsForUser } from '../../user/selectors/userProjectsInfoSelectors.ts';
import { ILanguagesData } from '../ILanguagesData.type.ts';

/* eslint-disable @typescript-eslint/prefer-enum-initializers */
enum Suffix {
  Default = 0,
  None = 1,
}
/* eslint-enable @typescript-eslint/prefer-enum-initializers */

export const getLanguage = (languages: ILanguagesData, languageId: Uuid): ILanguage | null => {
  if (languages.defaultLanguage.id === languageId) {
    return languages.defaultLanguage;
  }

  return languages.byId.get(languageId) ?? null;
};

export const getDefaultLanguage = (state: IStore): ILanguage =>
  addDefaultLanguageSuffix(state.data.languages.defaultLanguage);

const getAllLanguages = (languageData: ILanguagesData, suffixStrategy: Suffix): Languages =>
  Immutable.OrderedMap<Uuid, ILanguage>()
    .set(
      languageData.defaultLanguage.id,
      suffixStrategy === Suffix.Default
        ? addDefaultLanguageSuffix(languageData.defaultLanguage)
        : languageData.defaultLanguage,
    )
    .merge(languageData.byId);

const getLanguageIds = (languages: Languages): Immutable.OrderedSet<Uuid> =>
  languages.map((language: ILanguage) => language.id).toOrderedSet();

export const getAllLanguagesWithDefaultSuffix = memoize.maxOne((languageData: ILanguagesData) =>
  getAllLanguages(languageData, Suffix.Default),
);

const getActiveLanguages = (languageData: ILanguagesData, suffixStrategy: Suffix): Languages =>
  getAllLanguages(languageData, suffixStrategy).filter(isLanguageActive).toOrderedMap();

export const getActiveLanguagesWithDefaultSuffix = memoize.maxOne(
  (languageData: ILanguagesData): Languages => getActiveLanguages(languageData, Suffix.Default),
);

export const getActiveLanguagesWithFallbackChain = memoize.maxOne(
  (languageData: ILanguagesData): ReadonlyArray<ILanguage> =>
    getAllLanguages(languageData, Suffix.None)
      .map((language) =>
        language.id !== DefaultVariantId && language.fallbackLanguageId === null
          ? { ...language, fallbackLanguageId: DefaultVariantId }
          : language,
      )
      .toArray(),
);

export const getAllLanguageIds = memoize.maxOne(
  (languages: ILanguagesData): Immutable.OrderedSet<Uuid> =>
    getLanguageIds(getAllLanguages(languages, Suffix.None)),
);

export const getActiveLanguageIds = memoize.maxOne(
  (languages: ILanguagesData): Immutable.OrderedSet<Uuid> =>
    getLanguageIds(getActiveLanguages(languages, Suffix.None)),
);

export const getAllActiveLanguagesForCurrentUserInAnyCollection = memoize.maxOne(
  (user: IUser, projectId: Uuid, languages: ILanguagesData, capability: Capability): Languages => {
    const allActiveLanguages = getActiveLanguages(languages, Suffix.Default);
    const allowedIds = getLanguageIdsWithAllowedCapabilityInAnyCollection(
      getNormalizedRolesWithSettingsForUser(user, projectId),
      getLanguageIds(allActiveLanguages),
      capability,
      null,
    );
    return allActiveLanguages
      .filter((language: ILanguage) => allowedIds.has(language.id))
      .toOrderedMap();
  },
);

export const getAllActiveLanguagesForCurrentUserForCollection = memoize.maxOne(
  (
    user: IUser,
    projectId: Uuid,
    collectionId: Uuid | null,
    languages: ILanguagesData,
    capability: Capability,
  ): Languages => {
    const allActiveLanguages = getActiveLanguages(languages, Suffix.Default);
    const allowedIds = getLanguageIdsWithAllowedCapabilityForCollection(
      getNormalizedRolesWithSettingsForUser(user, projectId),
      collectionId,
      getLanguageIds(allActiveLanguages),
      capability,
    );
    return allActiveLanguages
      .filter((language: ILanguage) => allowedIds.has(language.id))
      .toOrderedMap();
  },
);
