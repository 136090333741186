import PropTypes from 'prop-types';
import React from 'react';
import {
  ArchivedAssetLink,
  NonExistingAssetLink,
  ValidAssetLink,
} from './AssetLinkDetailVariants.tsx';

interface ISpecificAssetLinkProps {
  readonly archived: boolean;
  readonly assetName: string;
  readonly assetPath?: string;
  readonly disabled?: boolean;
  readonly exists: boolean;
  readonly onAssetClick: () => void;
}

const propTypes: PropTypesShape<ISpecificAssetLinkProps> = {
  archived: PropTypes.bool.isRequired,
  assetName: PropTypes.string.isRequired,
  assetPath: PropTypes.string,
  exists: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onAssetClick: PropTypes.func.isRequired,
};

export const SpecificAssetLink: React.FC<ISpecificAssetLinkProps> = (props) => {
  const { archived, assetName, assetPath, disabled, exists, onAssetClick } = props;

  // This can happen only when non-existing asset is referenced.
  if (!exists) {
    return <NonExistingAssetLink />;
  }

  if (archived) {
    return <ArchivedAssetLink assetName={assetName} />;
  }

  return (
    <ValidAssetLink
      assetName={assetName}
      assetPath={assetPath}
      disabled={disabled}
      onAssetClick={onAssetClick}
    />
  );
};
SpecificAssetLink.displayName = 'SpecificAssetLink';
SpecificAssetLink.propTypes = propTypes;
