import { DraftDecorator } from 'draft-js';
import React, { useCallback, useMemo } from 'react';
import { useEditorStateCallbacks } from '../../../editorCore/hooks/useEditorStateCallbacks.ts';
import { useEditorWithPlugin } from '../../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginCreator } from '../../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../../editorCore/types/Editor.contract.type.ts';
import { EditorPlugin, Init } from '../../../editorCore/types/Editor.plugins.type.ts';
import { withDisplayName } from '../../../editorCore/utils/withDisplayName.ts';
import { EntityApiPlugin } from '../../entityApi/EntityApiPlugin.tsx';
import { EntityDecoratorProps } from '../../entityApi/api/editorEntityUtils.ts';
import { isWebLink } from '../api/LinkEntity.ts';
import { convertOpenInNewWindow, findLinks } from '../api/editorLinkUtils.ts';
import { AutomaticWebLinkConversionPlugin } from './AutomaticWebLinkConversionPlugin.tsx';
import { WebLink } from './components/WebLink.tsx';

export type SimpleWebLinksPlugin = EditorPlugin<
  None,
  None,
  None,
  [EntityApiPlugin, AutomaticWebLinkConversionPlugin]
>;

type CustomWebLinkEntityProps = {
  readonly unlink: (entityKey: string) => void;
};

type WebLinkEntityProps = EntityDecoratorProps<CustomWebLinkEntityProps> & {
  readonly children: ReadonlyArray<React.ReactNode>;
};

const WebLinkEntity: React.FC<WebLinkEntityProps> = ({
  children,
  contentState,
  decoratedText,
  entityKey,
  unlink,
}) => {
  const entity = contentState.getEntity(entityKey);
  if (!isWebLink(entity)) {
    return children;
  }

  const { url, openInNewWindow, title } = entity.getData();

  return (
    <WebLink
      key={entityKey}
      onUnlink={() => unlink(entityKey)}
      openInNewWindow={convertOpenInNewWindow(openInNewWindow)}
      text={decoratedText}
      title={title}
      url={url}
    >
      {children}
    </WebLink>
  );
};

WebLinkEntity.displayName = 'WebLinkEntity';

export const useSimpleWebLinks: PluginCreator<SimpleWebLinksPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('SimpleWebLinksPlugin', {
        ComposedEditor: (props) => {
          const { decorateWithEditorStateCallbacks, canUpdateContent, executeChange, getApi } =
            useEditorStateCallbacks<SimpleWebLinksPlugin>();

          const unlink = useCallback(
            (entityKey: string): void => {
              if (!canUpdateContent()) {
                return;
              }

              executeChange((editorState) => {
                const selection = getApi().getSelectionForEntity(editorState, entityKey);
                if (!selection) {
                  return editorState;
                }

                return getApi().unlink(editorState, selection);
              });
            },
            [executeChange, canUpdateContent, getApi],
          );

          const init: Init = useCallback(
            (state) => {
              const linkCustomProps: CustomWebLinkEntityProps = { unlink };
              const linkDecorator: DraftDecorator<CustomWebLinkEntityProps> = {
                strategy: findLinks,
                component: WebLinkEntity,
                props: linkCustomProps,
              };

              return {
                decorators: [...state.decorators, linkDecorator],
              };
            },
            [unlink],
          );

          return useEditorWithPlugin(baseEditor, props, {
            init,
            apply: decorateWithEditorStateCallbacks,
          });
        },
      }),
    [baseEditor],
  );
