import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { ObjectWithDataAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import {
  IForwardedRefProps,
  forwardRef,
  forwardedRefProps,
} from '../../utils/forwardedRefProps.tsx';
import { TabBadgeType } from './TabBadgeType.tsx';

interface ITabWrapperDataProps extends IForwardedRefProps<HTMLAnchorElement> {
  readonly badgeType?: TabBadgeType;
  readonly children: React.ReactNode;
  readonly className?: string;
  readonly dataUiAttributes?: ObjectWithDataAttribute;
  readonly onClick: () => void;
  readonly selected?: boolean;
  readonly stretched?: boolean;
}

interface ITabWrapperCallbackProps {
  readonly onClick: () => void;
}

type TabWrapperProps = ITabWrapperCallbackProps & ITabWrapperDataProps;

const TabWrapper: React.FC<TabWrapperProps> = ({
  badgeType,
  children,
  className,
  dataUiAttributes,
  forwardedRef,
  onClick,
  selected,
  stretched,
}) => (
  <a
    ref={forwardedRef}
    className={classNames('tabbed-navigation__tab', className, {
      'tabbed-navigation__tab--is-active': selected,
      'tabbed-navigation__tab--is-stretched': stretched,
      'tabbed-navigation__tab--with-warning': badgeType === TabBadgeType.Warning,
      'tabbed-navigation__tab--with-error': badgeType === TabBadgeType.Error,
    })}
    onClick={onClick}
    {...dataUiAttributes}
  >
    <div
      className={classNames('tabbed-navigation__tab-content', {
        'tabbed-navigation__tab-content--is-stretched': stretched,
      })}
    >
      {children}
    </div>
  </a>
);

const propTypes: PropTypesShape<TabWrapperProps> = {
  ...forwardedRefProps,
  badgeType: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dataUiAttributes: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  stretched: PropTypes.bool,
};

TabWrapper.displayName = 'TabWrapper';
TabWrapper.propTypes = propTypes;

const TabWrapperFRC = forwardRef(TabWrapper);
export { TabWrapperFRC as TabWrapper };
