import { InputState } from '@kontent-ai/component-library/Input';
import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import React from 'react';
import { DefaultTag } from '../../../../../component-library/components/Tag/DefaultTag.tsx';
import { Tag } from '../../../../../component-library/components/Tag/Tag.tsx';
import {
  allLanguagesOption,
  anyLanguageOption,
} from '../../../../_shared/constants/userListingFilter.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { ILanguageFilterOption } from '../../../../_shared/models/UserListingFilterOption.type.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getFilterTagColor } from '../../../../_shared/utils/userListingUtils.ts';
import { languageFilterUpdated } from '../actions/subscriptionUserListingActions.ts';
import { getLanguageFilterOptions } from '../utils/getFilterOptions.ts';

export const SubscriptionUsersLanguageFilter: React.FC = () => {
  const loadedLanguages = useSelector((s) => s.subscriptionApp.users.listingUi.languages);
  const languageOptions = getLanguageFilterOptions(loadedLanguages);
  const isEnvironmentSelected = useSelector(
    (s) => !!s.subscriptionApp.users.listingUi.filter.byEnvironment,
  );
  const selectedLanguageOptionIds = useSelector(
    (s) => s.subscriptionApp.users.listingUi.filter.byLanguage,
  );

  const dispatch = useDispatch();
  const onUpdateFilter = (updatedLanguageIds: ReadonlySet<ILanguageFilterOption['id']>) => {
    const allLanguagesOptionWasAdded =
      updatedLanguageIds.has(allLanguagesOption.id) &&
      !selectedLanguageOptionIds.has(allLanguagesOption.id);
    const resolvedLanguageIds = new Set(
      allLanguagesOptionWasAdded
        ? [allLanguagesOption.id]
        : [...updatedLanguageIds].filter((languageId) => languageId !== allLanguagesOption.id),
    );

    dispatch(languageFilterUpdated(resolvedLanguageIds));
  };

  const placeholderProps = isEnvironmentSelected
    ? {
        placeholder: anyLanguageOption.label,
        placeholderType: 'tag' as const,
      }
    : {
        placeholder: 'Select environment first',
      };

  return (
    <MultiSelect<ILanguageFilterOption>
      inputState={
        !isEnvironmentSelected || !loadedLanguages.length ? InputState.Disabled : InputState.Default
      }
      label="Language"
      onSelectionChange={onUpdateFilter}
      items={languageOptions}
      renderSelectedOption={(_id, selectedCollection, defaultTagProps) => {
        const background = getFilterTagColor(selectedCollection);
        if (background) {
          return <Tag {...defaultTagProps} background={background} />;
        }
        return <DefaultTag {...defaultTagProps} />;
      }}
      selectedItemIds={selectedLanguageOptionIds}
      verticalMenuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.LanguageList)}
      {...getDataUiCollectionAttribute(DataUiCollection.LanguageList)}
      {...placeholderProps}
    />
  );
};

SubscriptionUsersLanguageFilter.displayName = 'SubscriptionUsersLanguageFilter';
