import React, { useEffect } from 'react';
import { trackUserEventWithData } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import {
  FindRightContentTrackedEvent,
  TrackedEvent,
} from '../../../../../_shared/constants/trackedEvent.ts';
import { YourContentTabName } from '../../../../../_shared/constants/yourContentTabName.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { ContentItemId } from '../../../../../_shared/models/ContentItemId.type.ts';
import { repositoryCollection } from '../../../../../_shared/repositories/repositories.ts';
import { createRecentlyEditedContentItem } from '../../../../../data/models/listingContentItems/IRecentlyEditedListingContentItem.ts';
import { IListingContentItemWithMetadataServerModel } from '../../../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import {
  ListingRequestOptionsModel,
  ListingServerResponseModel,
} from '../../../../../repositories/utils/ensureAllRequestedDataFetched.ts';
import { entityListWidgetDisplayCount } from '../../shared/constants/widgetConstants.ts';
import { useWidgetListingDataFetcher } from '../../shared/hooks/useWidgetListingDataFetcher.tsx';
import { RecentlyEditedByYouWidget } from '../components/RecentlyEditedByYouWidget.tsx';

export const RecentlyEditedByYouWidgetContainer: React.FC = () => {
  const dispatch = useDispatch();

  const {
    fetchInit,
    fetchMore,
    state: itemsState,
    data: recentlyEditedItems,
  } = useWidgetListingDataFetcher(itemsFetcher, createRecentlyEditedContentItem);

  useEffect(() => fetchInit(entityListWidgetDisplayCount).cancel, [fetchInit]);

  // Revisit in task KCL-13158
  const trackOpeningItem = (id: ContentItemId, index: number) =>
    dispatch(
      trackUserEventWithData(TrackedEvent.FindRightContent, {
        index,
        itemId: id.itemId,
        name: FindRightContentTrackedEvent.OpenedItemEditingFromYourContent,
        tab: YourContentTabName.RecentlyEditedByYou,
      }),
    );

  return (
    <RecentlyEditedByYouWidget
      onItemClick={trackOpeningItem}
      fetchMore={fetchMore}
      items={recentlyEditedItems}
      itemsState={itemsState}
    />
  );
};

const itemsFetcher = async (
  _: ListingRequestOptionsModel,
  abortSignal: AbortSignal,
): Promise<ListingServerResponseModel<IListingContentItemWithMetadataServerModel>> => {
  const result =
    await repositoryCollection.contentItemRepository.getItemsRecentlyEditedByCurrentUser(
      abortSignal,
    );

  return {
    data: result,
    continuationToken: null,
  };
};
