import { IconButton } from '@kontent-ai/component-library/Button';
import Clipboard from 'clipboard';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

interface ICopyToClipboardIconProps {
  readonly text: string;
  readonly containerId?: string;
  readonly className?: string;
  readonly disabled?: boolean;
  readonly onSuccess?: () => void;
  readonly onError?: () => void;
}

const propTypes: PropTypeMap<ICopyToClipboardIconProps> = {
  text: PropTypes.string.isRequired,
  containerId: PropTypes.string,
  className: PropTypes.string,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  disabled: PropTypes.bool,
};

export const CopyToClipboardIcon: React.FC<ICopyToClipboardIconProps> = ({
  containerId,
  disabled,
  onError,
  onSuccess,
  text,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const onErrorRef = useRef(onError);
  onErrorRef.current = onError;

  const onSuccessRef = useRef(onSuccess);
  onSuccessRef.current = onSuccess;

  useEffect(() => {
    if (!buttonRef.current) {
      return;
    }

    const container = containerId ? document.getElementById(containerId) : null;

    const clipboard = new Clipboard(buttonRef.current, {
      text: () => text,
      container: container || undefined,
    });

    clipboard.on('success', (e) => {
      e.clearSelection();
      onSuccessRef.current?.();
    });

    clipboard.on('error', () => onErrorRef.current?.());

    return () => {
      clipboard.destroy();
    };
  }, [containerId, text]);

  return (
    <IconButton
      ref={buttonRef}
      key="clipboard-button"
      buttonStyle="tertiary"
      iconName="DocCopy"
      size="medium"
      tooltipPlacement="top-end"
      tooltipText={disabled ? '' : 'Copy to clipboard'}
      type="button"
    />
  );
};

CopyToClipboardIcon.displayName = 'CopyToClipboardIcon';
CopyToClipboardIcon.propTypes = propTypes;
