import { Box } from '@kontent-ai/component-library/Box';
import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { GeneralTag, ISelectItem } from '@kontent-ai/component-library/Selects';
import React, { ComponentProps, useContext, useMemo } from 'react';
import { DefaultTag } from '../../../../../../component-library/components/Tag/DefaultTag.tsx';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { ILanguage } from '../../../../../data/models/languages/Language.ts';
import { getAllActiveLanguagesForCurrentUserInAnyCollection } from '../../../../../data/reducers/languages/selectors/getLanguages.ts';
import { getCurrentProject } from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { LanguageFilterContext } from '../../contexts/LanguageFilter.tsx';

interface ILanguageOption extends ISelectItem<ILanguageOption> {}

const toSelectItem = ({ id, name }: ILanguage): ILanguageOption => ({
  id,
  label: name,
});

const languageMultiSelectMaxWidth = 320;

export const LanguagesFilterSelector = () => {
  const { selectedLanguageIds, setSelectedLanguageIds } = useContext(LanguageFilterContext);
  const languagesWithViewCapability = useSelector((state) => {
    const { projectId } = getCurrentProject(state);
    return getAllActiveLanguagesForCurrentUserInAnyCollection(
      state.data.user,
      projectId,
      state.data.languages,
      Capability.ViewContent,
    ).toArray();
  });
  const options = useMemo(
    () => languagesWithViewCapability.map(toSelectItem),
    [languagesWithViewCapability],
  );

  const renderGeneralTag = (
    count: number,
    defaultTagProps: Omit<ComponentProps<typeof DefaultTag>, 'children'>,
  ): React.ReactNode => (
    <GeneralTag countPlacement="before" countValue={count} label="Languages" {...defaultTagProps} />
  );

  return languagesWithViewCapability.length > 1 ? (
    <Box maxWidth={languageMultiSelectMaxWidth}>
      <MultiSelect
        items={options}
        onSelectionChange={setSelectedLanguageIds}
        placeholder="All languages"
        placeholderType="tag"
        selectedItemIds={selectedLanguageIds}
        generalTagThreshold={3}
        renderGeneralTag={renderGeneralTag}
        {...getDataUiCollectionAttribute(DataUiCollection.LanguageList)}
      />
    </Box>
  ) : null;
};
