/* eslint-disable @typescript-eslint/no-base-to-string */
import { Stack } from '@kontent-ai/component-library/Stack';
import { Typography } from '@kontent-ai/component-library/tokens';
import PropTypes from 'prop-types';
import React from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly children: React.ReactNode;
};

const propTypes: PropTypeMap<Props> = {
  children: PropTypes.node.isRequired,
};

export const EmptyStateContentGroup: React.FC<Props> = ({ children }) => {
  return (
    <Stack
      align="center"
      css={`
        ${Typography.UIParagraph}
        text-align: center;
      `}
      {...getDataUiElementAttribute(DataUiElement.EmptyState)}
    >
      {children}
    </Stack>
  );
};

EmptyStateContentGroup.displayName = 'EmptyStateContentGroup';
EmptyStateContentGroup.propTypes = propTypes;
