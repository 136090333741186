import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IApiKeyFilter, emptyApiKeyFilter } from '../../models/ApiKeyFilter.ts';

interface IInitApiKeyListingDependencies {
  readonly loadDapiKeyListing: (filter: IApiKeyFilter, abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadMapiKeyListing: (filter: IApiKeyFilter, abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadProjects: (abortSignal?: AbortSignal) => ThunkPromise;
}

export const initApiKeyListingCreator =
  (deps: IInitApiKeyListingDependencies) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    await Promise.all([
      dispatch(deps.loadDapiKeyListing(emptyApiKeyFilter, abortSignal)),
      dispatch(deps.loadMapiKeyListing(emptyApiKeyFilter, abortSignal)),
      dispatch(deps.loadProjects(abortSignal)),
    ]);
  };
