import React from 'react';
import {
  AdditionalOnboardingNotificationData,
  ContentItemPublishedOnboardingNotificationData,
  ContentTypeEditedOnboardingNotificationData,
  OnboardingNotification,
} from '../../../data/models/user/OnboardingNotification.ts';
import { ContentPublishingOnboardingBusinessUserNotification } from '../../containers/OnboardingNotifications/ContentPublishingOnboardingBusinessUserNotification.tsx';
import { ContentPublishingOnboardingTechnicalUserNotification } from '../../containers/OnboardingNotifications/ContentPublishingOnboardingTechnicalUserNotification.tsx';
import { ContentTypeEditingOnboardingNotification } from '../../containers/OnboardingNotifications/ContentTypeEditingOnboardingNotification.tsx';

export interface IOnboardingNotificationSelectorStateProps {
  readonly onboardingNotification: OnboardingNotification | null;
  readonly additionalData: AdditionalOnboardingNotificationData | null;
  readonly isTechnicalRole: boolean;
}

interface IOnboardingNotificationSelectorProps extends IOnboardingNotificationSelectorStateProps {}

export const OnboardingNotificationsSelector: React.FC<IOnboardingNotificationSelectorProps> = ({
  isTechnicalRole,
  additionalData,
  onboardingNotification,
}) => {
  if (!additionalData || !onboardingNotification) {
    return null;
  }

  const { editedTypeId, languageIdToCreateItem, collectionIdToCreateItem } =
    additionalData as ContentTypeEditedOnboardingNotificationData;
  if (
    onboardingNotification === OnboardingNotification.ContentTypeEditedNotification &&
    editedTypeId &&
    languageIdToCreateItem &&
    collectionIdToCreateItem
  ) {
    return (
      <ContentTypeEditingOnboardingNotification
        editedContentTypeId={editedTypeId}
        languageIdToCreateItem={languageIdToCreateItem}
        collectionIdToCreateItem={collectionIdToCreateItem}
      />
    );
  }

  const { publishedItemIds } = additionalData as ContentItemPublishedOnboardingNotificationData;
  if (
    onboardingNotification === OnboardingNotification.ContentPublishedNotification &&
    publishedItemIds
  ) {
    return isTechnicalRole ? (
      <ContentPublishingOnboardingTechnicalUserNotification
        publishedContentItemIds={publishedItemIds}
      />
    ) : (
      <ContentPublishingOnboardingBusinessUserNotification />
    );
  }

  return null;
};

OnboardingNotificationsSelector.displayName = 'OnboardingNotificationsSelector';
