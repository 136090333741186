import { createGuid } from '@kontent-ai/utils';
import { ContentState, EditorState } from 'draft-js';

const neverSplitDelimiter = createGuid();

export const createSimpleTextValueContent = (text: string): ContentState =>
  ContentState.createFromText(text, neverSplitDelimiter);

export const createSimpleTextValueEditorState = (text: string): EditorState =>
  EditorState.createWithContent(createSimpleTextValueContent(text));

export const getSimpleTextValue = (editorState: EditorState): string =>
  editorState.getCurrentContent().getFirstBlock().getText();
