import { Callout } from '@kontent-ai/component-library/Callout';
import PropTypes from 'prop-types';
import React from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { pluralizeWithoutCount } from '../../utils/stringUtils.ts';

export type SubscriptionPlanIncludedUserLimitReachedWarningStateProps = {
  readonly extraUserSlotPrice: number;
  readonly includedUserSlotsCount: number;
  readonly isInActivationMode?: boolean;
  readonly isTrial: boolean;
  readonly isVisible: boolean;
  readonly maxActiveUsers: number | null;
  readonly newUsersAboveIncludedUnitsCount: number;
};

export type SubscriptionPlanIncludedUserLimitReachedWarningOwnProps = {
  readonly isHeadlineHidden?: boolean;
  readonly isInActivationMode?: boolean;
  readonly newEmails: readonly string[];
};

type Props = SubscriptionPlanIncludedUserLimitReachedWarningStateProps &
  SubscriptionPlanIncludedUserLimitReachedWarningOwnProps;

const propTypes: PropTypesShape<Props> = {
  extraUserSlotPrice: PropTypes.number.isRequired,
  includedUserSlotsCount: PropTypes.number.isRequired,
  isHeadlineHidden: PropTypes.bool,
  isInActivationMode: PropTypes.bool,
  isTrial: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  maxActiveUsers: PropTypes.number,
  newEmails: PropTypes.arrayOf(PropTypes.string).isRequired,
  newUsersAboveIncludedUnitsCount: PropTypes.number.isRequired,
};

const createInvitationHardLimitSentence = (hardLimit: number | null) =>
  hardLimit === null
    ? 'You can invite as many users as you like.'
    : `You can invite up to ${hardLimit} users.`;

const createActivationHardLimitSentence = (hardLimit: number | null) =>
  hardLimit === null
    ? 'You can activate as many users as you like.'
    : `You can activate up to ${hardLimit} users.`;

const createThisUsersCountMessage = (numberOfUsers: number) =>
  numberOfUsers === 1 ? 'This user counts' : 'These users count';

const createActivationMessage = (props: Props) =>
  `You’re adding 1 user above the limit of ${props.includedUserSlotsCount} active users included in your subscription plan.
   ${createThisUsersCountMessage(1)} as extra usage for $${
     props.extraUserSlotPrice
   }. ${createActivationHardLimitSentence(props.maxActiveUsers)}`;

const createInvitationMessage = (props: Props) =>
  `You’re adding ${props.newUsersAboveIncludedUnitsCount} ${pluralizeWithoutCount(
    'user',
    props.newUsersAboveIncludedUnitsCount,
  )} above the ${props.includedUserSlotsCount} users limit of your subscription.
  ${createThisUsersCountMessage(props.newUsersAboveIncludedUnitsCount)} as extra usage for $${
    props.extraUserSlotPrice
  }. ${createInvitationHardLimitSentence(props.maxActiveUsers)}`;

export const SubscriptionPlanIncludedUserLimitReachedWarning: React.FC<Props> = (props) => {
  if (!props.isVisible) {
    return null;
  }

  const headline = `${props.isInActivationMode ? 'Activating' : 'Inviting'} extra users`;

  return (
    <Callout
      calloutType="friendlyWarning"
      headline={props.isHeadlineHidden ? undefined : headline}
      maxWidth={960}
    >
      <div
        {...getDataUiElementAttribute(
          DataUiElement.SubscriptionPlanIncludedUsersLimitReachedWarning,
        )}
      >
        {props.isTrial ? (
          <div>
            <p>
              You can {props.isInActivationMode ? 'activate' : 'invite'} up to{' '}
              {props.maxActiveUsers ?? 0} users for free during your trial.
            </p>
            <p>
              After the trial ends, you can select whether to pay for the extra users or disable
              them.
            </p>
          </div>
        ) : (
          <div>
            <p>
              {props.isInActivationMode
                ? createActivationMessage(props)
                : createInvitationMessage(props)}
            </p>
          </div>
        )}
      </div>
    </Callout>
  );
};

SubscriptionPlanIncludedUserLimitReachedWarning.displayName =
  'SubscriptionPlanIncludedUserLimitReachedWarning';
SubscriptionPlanIncludedUserLimitReachedWarning.propTypes = propTypes;
