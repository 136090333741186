import PropTypes from 'prop-types';
import React from 'react';
import { SearchPhraseHighlighter } from '../../../../../../../../_shared/components/Highlighting/SearchPhraseHighlighter.tsx';
import { useSelector } from '../../../../../../../../_shared/hooks/useSelector.ts';
import { findMatchingIndexesInWordPrefixes } from '../../../../../../../../_shared/utils/filter/nameFilterUtils.ts';
import { FullTextSearchStatus } from '../../../../../../shared/reducers/IContentInventoryStoreState.type.ts';

export interface IContentItemSearchPhraseHighlighterProps {
  readonly highlightedName: string | null;
  readonly itemName: string;
  readonly searchPhrase: string;
}

const propTypes: PropTypesShape<IContentItemSearchPhraseHighlighterProps> = {
  highlightedName: PropTypes.string,
  itemName: PropTypes.string.isRequired,
  searchPhrase: PropTypes.string.isRequired,
};

export const ContentItemSearchPhraseHighlighter: React.FC<
  IContentItemSearchPhraseHighlighterProps
> = (props) => {
  const { highlightedName, itemName, searchPhrase } = props;

  const isFullTextSearchEnabled = useSelector(
    (state) => state.contentInventory.fullTextSearchStatus === FullTextSearchStatus.Enabled,
  );

  if (isFullTextSearchEnabled && searchPhrase) {
    return highlightedName ? (
      <span
        className="scroll-table__value scroll-table__item-name-text"
        dangerouslySetInnerHTML={{ __html: highlightedName }}
      />
    ) : (
      <span className="scroll-table__item-name-text">{itemName}</span>
    );
  }

  return (
    <SearchPhraseHighlighter
      className="scroll-table__value scroll-table__item-name-text"
      findMatchingIndexes={findMatchingIndexesInWordPrefixes}
      searchPhrase={searchPhrase}
      text={itemName}
    />
  );
};

ContentItemSearchPhraseHighlighter.displayName = 'ContentItemSearchPhraseHighlighter';
ContentItemSearchPhraseHighlighter.propTypes = propTypes;
