import { IconSize, px } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import styled from 'styled-components';
import { IconName } from '../../../../../../../_shared/constants/iconEnumGenerated.ts';
import { SquareButton } from '../../../../../../../_shared/uiComponents/Button/SquareButton.tsx';
import { ButtonStyle } from '../../../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import { SquareButtonSize } from '../../../../../../../_shared/uiComponents/Button/squareButtonSize.ts';
import { Icon } from '../../../../../../../_shared/uiComponents/Icon/Icon.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly isActive: boolean;
  readonly isAlwaysVisible: boolean;
  readonly isSaving: boolean;
  readonly onClick: () => void;
};

const IconWrapper = styled.div`
  width: ${px(IconSize.L)};
  height: ${px(IconSize.L)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EditSavedFilter = ({ isActive, isAlwaysVisible, isSaving, onClick }: Props) => {
  if (isSaving) {
    return (
      <IconWrapper>
        <Icon iconName={IconName.Spinner} className="spin-500 btn__icon" />
      </IconWrapper>
    );
  }

  return (
    <SquareButton
      isActive={isActive}
      iconName={IconName.Edit}
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
      size={SquareButtonSize.Quinary}
      screenReaderText="Edit filter"
      style={ButtonStyle.Quinary}
      tooltipPlacement="right"
      tooltipText="Edit filter"
      className={classNames('saved-filters__edit-item', {
        'saved-filters__edit-item--always-visible': isAlwaysVisible,
      })}
      {...getDataUiActionAttribute(DataUiAction.Edit)}
    />
  );
};
