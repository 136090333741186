import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import { IAssetItemElement } from './AssetItemElement.ts';
import { ICustomItemElement } from './CustomItemElement.ts';
import { DateTimeItemElement } from './DateTimeItemElement.ts';
import { ICompiledContentItemElementData } from './ICompiledContentItemElement.ts';
import { IMultipleChoiceItemElement } from './MultipleChoiceItemElement.ts';
import { INumberItemElement } from './NumberItemElement.ts';
import { IRichTextItemElement } from './RichTextItemElement.ts';
import { ITaxonomyItemElement } from './TaxonomyItemElement.ts';
import { ITextItemElement } from './TextItemElement.ts';
import { IUrlSlugItemElement } from './UrlSlugItemElement.ts';
import { ILinkedItemsElement } from './modularItems/ILinkedItemsElement.ts';

export const isAssetElement = (
  arg: ICompiledContentItemElementData | undefined | null,
): arg is IAssetItemElement => arg?.type === ElementType.Asset;

export const isCustomElement = (
  arg: ICompiledContentItemElementData | undefined | null,
): arg is ICustomItemElement => arg?.type === ElementType.Custom;

export const isDateTimeElement = (
  arg: ICompiledContentItemElementData | undefined | null,
): arg is DateTimeItemElement => arg?.type === ElementType.DateTime;

export const isModularContentElement = (
  arg: ICompiledContentItemElementData | undefined | null,
): arg is ILinkedItemsElement => arg?.type === ElementType.LinkedItems;

export const isRichTextElement = (
  arg: ICompiledContentItemElementData | undefined | null,
): arg is IRichTextItemElement => arg?.type === ElementType.RichText;

export const isTextElement = (
  arg: ICompiledContentItemElementData | undefined | null,
): arg is ITextItemElement => arg?.type === ElementType.Text;

export const isUrlSlugElement = (
  arg: ICompiledContentItemElementData | undefined | null,
): arg is IUrlSlugItemElement => arg?.type === ElementType.UrlSlug;

export const isTaxonomyElement = (
  arg: ICompiledContentItemElementData | undefined | null,
): arg is ITaxonomyItemElement => arg?.type === ElementType.Taxonomy;

export const isMultipleChoiceElement = (
  arg: ICompiledContentItemElementData | undefined | null,
): arg is IMultipleChoiceItemElement => arg?.type === ElementType.MultipleChoice;

export const isNumberElement = (
  arg: ICompiledContentItemElementData | undefined | null,
): arg is INumberItemElement => arg?.type === ElementType.Number;
