import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import { Content_LoadedItems_ListingItemsLoaded } from '../../../../applications/contentInventory/content/constants/contentActionTypes.ts';
import { ContentListing_DeleteItems_Finished } from '../../../../applications/contentInventory/content/features/ContentItemInventory/constants/contentItemInventoryActionTypes.ts';
import { LocalizedRouteLeft } from '../../../../applications/contentInventory/shared/constants/contentInventoryActionTypes.ts';
import { Calendar_LoadItemsWithVariantActiveInPeriod_Finished } from '../../../../applications/contentPlanning/calendar/constants/calendarActionTypes.ts';
import { ProjectOverview_LoadItemsMatchingFilter_Succeeded } from '../../../../applications/contentPlanning/projectOverview/constants/projectOverviewActionTypes.ts';
import {
  Content_Editing_AssignmentSubmittingFinished,
  Content_Editing_CancelScheduledPublishingFinished,
  Content_Editing_CancelScheduledUnpublishingFinished,
  Content_Editing_DiscardNewVersionFinished,
  Content_Editing_PublishContentItemVariantFinished,
  Content_Editing_ScheduledPublishingFinished,
  Content_Editing_ScheduledUnpublishingFinished,
  Content_Editing_UnpublishContentItemVariantFinished,
} from '../../../../applications/itemEditor/constants/editorActionTypes.ts';
import { ContentEditing_CascadeModal_ExpandNodeFinished } from '../../../../applications/itemEditor/features/CascadePublish/constants/cascadeModalActionTypes.ts';
import { ItemWithVariantAndFilterPayload } from '../../../../applications/itemEditor/features/ContentItemEditing/actions/contentItemEditingActions.ts';
import {
  ContentItemEditing_ArchiveTemporaryContentItem_Finished,
  ContentItemEditing_ArchiveTemporaryContentItem_Started,
  ContentItemEditing_Archiving_Finished,
  ContentItemEditing_CopyContentFromVariant_Finished,
  ContentItemEditing_CreateNewVersion_Finished,
  ContentItemEditing_Duplicating_Finished,
  ContentItemEditing_Init_Ready,
  ContentItemEditing_ItemElementsSaving_Finished,
  ContentItemEditing_ItemName_Changed,
  ContentItemEditing_ItemSaving_Finished,
  ContentItemEditing_LinkedEntityChanged_ItemRefreshed,
} from '../../../../applications/itemEditor/features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { mapEditedContentItemFromServerModel } from '../../../../applications/itemEditor/models/contentItem/edited/EditedContentItem.ts';
import { getNotEmptyItemName } from '../../../../applications/itemEditor/stores/utils/contentItemHelperMethods.ts';
import {
  Data_ListingContentItems_FullTextSearchBecameUnavailable,
  Data_ListingContentItems_Success,
} from '../../../constants/dataActionTypes.ts';
import { createActiveCapabilitiesFromServerModel } from '../../../models/activeCapabilities.ts';
import {
  IListingContentItem,
  getListingContentItemFromJS,
} from '../../../models/listingContentItems/IListingContentItem.ts';
import { getItemDataFromJS } from '../../../models/listingContentItems/IListingItemData.ts';
import { getListingVariantDataFromJS } from '../../../models/listingContentItems/IListingVariantData.ts';

const initialState: Immutable.Map<Uuid, IListingContentItem> = Immutable.Map();

const addAllToState = (
  state: Immutable.Map<Uuid, IListingContentItem>,
  newItems: ReadonlyArray<IListingContentItem>,
): Immutable.Map<Uuid, IListingContentItem> =>
  state.withMutations((newState) =>
    newItems.forEach((contentItem) => newState.set(contentItem.item.id, contentItem)),
  );

const getOriginalHighlightedName = (
  state: Immutable.Map<Uuid, IListingContentItem>,
  itemId: Uuid,
): string | null => state.get(itemId)?.highlightedName ?? null;

export function byId(
  state: Immutable.Map<Uuid, IListingContentItem> = initialState,
  action: Action,
): Immutable.Map<Uuid, IListingContentItem> {
  switch (action.type) {
    case Content_LoadedItems_ListingItemsLoaded:
    case Data_ListingContentItems_Success:
    case Calendar_LoadItemsWithVariantActiveInPeriod_Finished:
    case ProjectOverview_LoadItemsMatchingFilter_Succeeded: {
      const { contentItems } = action.payload;
      if (contentItems === null) {
        return state;
      }

      return addAllToState(state, contentItems);
    }

    case ContentEditing_CascadeModal_ExpandNodeFinished: {
      const { allChildNodes, editedVariantId } = action.payload;

      const [, editedVariantChildItems] = Array.from(allChildNodes.entries()).find(
        ([variantId]) => variantId === editedVariantId,
      ) ?? ['', []];

      return editedVariantChildItems.length ? addAllToState(state, editedVariantChildItems) : state;
    }

    case LocalizedRouteLeft:
    case Shared_CurrentProjectId_Updated:
      return initialState;

    case Data_ListingContentItems_FullTextSearchBecameUnavailable:
      return action.payload.shouldResetLoadedItems ? initialState : state;

    // KCL-1355 - Inventory is not reloaded after going back from editing
    // in order to provide at least some feedback on changes we shall update our in-memory data
    case ContentItemEditing_ItemSaving_Finished: {
      const { itemServerModel } = action.payload;
      const savedItem = mapEditedContentItemFromServerModel(itemServerModel);
      const oldItem = state.get(savedItem.id);
      if (!oldItem) {
        // KCL-6969 - Do nothing in case the saved item is not present (we probably wait for fresh data)
        return state;
      }
      const newItem = {
        ...oldItem.item,
        name: getNotEmptyItemName(savedItem.name),
      };

      return state.set(savedItem.id, {
        ...oldItem,
        item: newItem,
      });
    }

    case ContentItemEditing_ArchiveTemporaryContentItem_Started:
    case ContentItemEditing_ArchiveTemporaryContentItem_Finished: {
      return state.delete(action.payload.itemId);
    }

    case ContentItemEditing_Archiving_Finished: {
      const { itemId, itemWithVariant } = action.payload;
      const newItem: IListingContentItem = {
        activeCapabilities: createActiveCapabilitiesFromServerModel(
          itemWithVariant.activeCapabilities,
        ),
        highlightedName: getOriginalHighlightedName(state, itemWithVariant.item.id),
        isPublishedVersion: false,
        item: getItemDataFromJS(itemWithVariant.item),
        variant: null,
        translatedLanguageIds: [],
      };

      return state.set(itemId, newItem);
    }

    case ContentItemEditing_Init_Ready: {
      const { actualWorkflowStatus, itemWithVariant } = action.payload;

      if (actualWorkflowStatus) {
        return state;
      }

      const newItem = getListingContentItemFromJS({
        ...itemWithVariant,
        isPublishedVersion: false,
        highlightedName: getOriginalHighlightedName(state, itemWithVariant.item.id),
        translatedLanguages: [],
      });

      return state.set(itemWithVariant.item.id, newItem);
    }

    case Content_Editing_AssignmentSubmittingFinished:
    case Content_Editing_CancelScheduledPublishingFinished:
    case Content_Editing_CancelScheduledUnpublishingFinished:
    case Content_Editing_DiscardNewVersionFinished:
    case Content_Editing_PublishContentItemVariantFinished:
    case Content_Editing_ScheduledPublishingFinished:
    case Content_Editing_ScheduledUnpublishingFinished:
    case Content_Editing_UnpublishContentItemVariantFinished:
    case ContentItemEditing_CopyContentFromVariant_Finished:
    case ContentItemEditing_CreateNewVersion_Finished:
    case ContentItemEditing_LinkedEntityChanged_ItemRefreshed:
    case ContentItemEditing_ItemElementsSaving_Finished: {
      const { itemWithVariant } = action.payload as ItemWithVariantAndFilterPayload;

      const newItem = getListingContentItemFromJS({
        ...itemWithVariant,
        isPublishedVersion: false,
        highlightedName: getOriginalHighlightedName(state, itemWithVariant.item.id),
        translatedLanguages: [],
      });

      return state.set(itemWithVariant.item.id, newItem);
    }

    case ContentItemEditing_ItemName_Changed: {
      return state.update(action.payload.itemId, (item) => ({
        ...item,
        highlightedName: null,
      }));
    }

    case ContentItemEditing_Duplicating_Finished: {
      const { itemWithVariant } = action.payload;

      const newItem: IListingContentItem = {
        activeCapabilities: createActiveCapabilitiesFromServerModel(
          itemWithVariant.activeCapabilities,
        ),
        highlightedName: null,
        isPublishedVersion: false,
        item: getItemDataFromJS(itemWithVariant.item),
        variant: getListingVariantDataFromJS(itemWithVariant.variant),
        translatedLanguageIds: [],
      };

      return state.set(newItem.item.id, newItem);
    }

    case ContentListing_DeleteItems_Finished: {
      return action.payload.successfulIds
        .toArray()
        .reduce<Immutable.Map<Uuid, IListingContentItem>>(
          (reducedItems, id) => reducedItems.delete(id),
          state,
        );
    }

    default:
      return state;
  }
}
