import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { modalDismissed } from '../../../../../_shared/actions/sharedActions.ts';
import { IContentItemWithVariantsServerModel } from '../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import { newlyCreatedItemEditorMounted } from '../../ContentItemEditing/actions/contentItemEditingActions.ts';
import { RouteProps } from '../../ContentItemEditing/utils/redirectToItem.ts';

interface IDeps {
  readonly duplicateEditedContentItem: (
    destinationCollectionId?: Uuid,
  ) => ThunkPromise<IContentItemWithVariantsServerModel | null>;
  readonly redirectToItem: (routeProps: RouteProps, contentItemId: Uuid) => void;
}

export const createDuplicateItemWithContentAction =
  (deps: IDeps) =>
  (routeProps: RouteProps, destinationCollectionId?: Uuid): ThunkPromise =>
  async (dispatch) => {
    const duplicatedItem = await dispatch(deps.duplicateEditedContentItem(destinationCollectionId));

    if (duplicatedItem) {
      deps.redirectToItem(routeProps, duplicatedItem.item.id);
      dispatch(modalDismissed());

      dispatch(newlyCreatedItemEditorMounted());
    }
  };
