import { ScheduleMethod } from '../../../../../../_shared/features/ChangeWorkflowStepModal/constants/scheduleMethods.ts';
import { IChangeWorkflowStepModalData } from '../../../../../../_shared/features/ChangeWorkflowStepModal/reducers/IChangeWorkflowStepModalData.ts';
import { isTimeInPast } from '../../../../../../_shared/utils/dateTime/timeUtils.ts';

export enum UnpublishingValidationResult {
  Ok = 'Ok',
  UnpublishDateIsEmptyOrInvalid = 'UnpublishDateIsEmptyOrInvalid',
  UnpublishDateIsInPast = 'UnpublishDateIsInPast',
}

export const validateUnpublishingConfiguration = (
  changeWorkflowStepModalData: IChangeWorkflowStepModalData,
): UnpublishingValidationResult => {
  if (changeWorkflowStepModalData.scheduleMethod === ScheduleMethod.Now) {
    return UnpublishingValidationResult.Ok;
  }

  if (!changeWorkflowStepModalData.scheduledToUnpublishAt) {
    return UnpublishingValidationResult.UnpublishDateIsEmptyOrInvalid;
  }

  if (isTimeInPast(new Date(), changeWorkflowStepModalData.scheduledToUnpublishAt)) {
    return UnpublishingValidationResult.UnpublishDateIsInPast;
  }

  return UnpublishingValidationResult.Ok;
};
