import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';

interface IDeps {
  readonly loadSubscriptionUsersUsage: () => ThunkPromise;
  readonly loadProjects: () => ThunkPromise;
}

export const createInitSubscriptionUsers =
  ({ loadSubscriptionUsersUsage, loadProjects }: IDeps) =>
  () =>
  (dispatch: Dispatch) => {
    dispatch(loadSubscriptionUsersUsage());
    dispatch(loadProjects());
  };
