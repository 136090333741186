import { CancellableExecutor } from '@kontent-ai/utils';
import PropTypes from 'prop-types';
import React, { ChangeEvent } from 'react';
import TextareaAutoSize from 'react-textarea-autosize';
import { HotkeysHandler } from '../../../../../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import { ValidationConstants } from '../../../../../../../_shared/constants/validationConstants.ts';
import { waitUntilFullyVisible } from '../../../../../../../_shared/utils/autoScrollUtils.ts';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export interface ISuggestionInputDataProps {
  readonly autoFocus?: boolean;
  readonly inputId?: Uuid;
  readonly inputValue: string;
  readonly onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  readonly onEscape: () => void;
}

export class SuggestionInput extends React.PureComponent<ISuggestionInputDataProps> {
  static displayName = 'SuggestionInput';

  static propTypes: PropTypesShape<ISuggestionInputDataProps> = {
    autoFocus: PropTypes.bool,
    inputId: PropTypes.string,
    inputValue: PropTypes.string,
    onChange: PropTypes.func,
    onEscape: PropTypes.func.isRequired,
  };

  private readonly inputRef = React.createRef<HTMLTextAreaElement>();
  private readonly focusWhenInView: CancellableExecutor<[]>;

  constructor(props: ISuggestionInputDataProps) {
    super(props);

    this.focusWhenInView = waitUntilFullyVisible(this.inputRef, this.focus);
  }

  public componentDidMount(): void {
    if (this.props.autoFocus) {
      // Comment may not be in view at the moment of auto focus, as positioning and animations are handled by InlineCommentPane
      // We defer the focus to the moment input is in view in order to prevent unwanted scrolling forced by the focus
      this.focusWhenInView();
    }
  }

  public componentWillUnmount(): void {
    this.focusWhenInView.cancel();
  }

  public focus = (): void => {
    this.focusWhenInView.cancel();
    const input = this.inputRef.current;
    if (input) {
      input.focus();
    }
  };

  public blur = (): void => {
    this.focusWhenInView.cancel();
    const input = this.inputRef.current;
    if (input) {
      input.blur();
    }
  };

  render(): JSX.Element {
    return (
      <HotkeysHandler
        handlers={{
          onEscape: this.props.onEscape,
        }}
      >
        <TextareaAutoSize
          id={this.props.inputId}
          className="form__text-field form__text-field--300"
          value={this.props.inputValue}
          maxLength={ValidationConstants.CommentTextMaxLength}
          onChange={this.props.onChange}
          ref={this.inputRef}
          placeholder="Suggest replacement text"
          {...getDataUiInputAttribute(DataUiInput.Suggestion)}
        />
      </HotkeysHandler>
    );
  }
}
