import { InputState } from '@kontent-ai/component-library/Input';
import { useMemo } from 'react';
import { useController } from 'react-hook-form';
import { ValidatedMultipleOptionSelect } from '../../../../../_shared/components/input/ValidatedMultipleOptionSelect.tsx';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { HookFormProps } from '../../../../../_shared/types/hookFormProps.ts';
import {
  DataUiCollection,
  DataUiInput,
  getDataUiCollectionAttribute,
  getDataUiInputAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { EntityWebhookAction } from '../../../../../data/models/webhooks/EntityWebhookSetting.ts';
import { getActiveLanguagesWithDefaultSuffix } from '../../../../../data/reducers/languages/selectors/getLanguages.ts';
import { anyLanguageLanguage } from '../../../constants/anyLanguageLanguage.ts';
import { IEntityWebhookFormShape } from '../../../models/IEntityWebhookFormShape.type.ts';
import { LanguageOption } from '../../../models/LanguageOption.ts';
import { getSortedLanguageOptionsListWithAnyLanguageFirst } from '../../../selectors/getSortedLanguageOptionsListWithAnyLanguageFirst.ts';
import { handleAnyLanguageLanguageOnLanguagesChangedInFormInput } from '../../../utils/anyLanguageLanguageBehaviorUtils.ts';
import { renderFilterOption } from '../../../utils/renderFilterOption.tsx';
import { EntityWebhookValidationError } from '../EntityWebhookValidationError.tsx';

type Props = {
  readonly formProps: HookFormProps<IEntityWebhookFormShape>;
};

export const EntityWebhookLanguageFilterSelectors = ({ formProps }: Props) => {
  const { control } = formProps;
  const { field } = useController({ control, name: 'triggers.languageTrigger' });

  const activeLanguages = useSelector((s) => getActiveLanguagesWithDefaultSuffix(s.data.languages));
  const languageOptions = useMemo(
    () => getSortedLanguageOptionsListWithAnyLanguageFirst(activeLanguages),
    [activeLanguages],
  );

  const inputState =
    field.value.checked && field.value.actions.some((a) => a !== EntityWebhookAction.Created)
      ? InputState.Default
      : InputState.Disabled;

  const isEmptyLanguageFilter = field.value.languageIds.length < 1;

  const normalize = (
    formValues: readonly LanguageOption[],
    previousValues: readonly LanguageOption[],
  ): readonly LanguageOption[] => {
    const newValues = handleAnyLanguageLanguageOnLanguagesChangedInFormInput(
      formValues,
      previousValues,
    );
    field.onChange({
      ...field.value,
      languageIds: newValues.map((option) => option.id),
    });
    return newValues;
  };

  return (
    <>
      <ValidatedMultipleOptionSelect<IEntityWebhookFormShape, LanguageOption>
        formProps={formProps}
        inputState={inputState}
        items={languageOptions}
        label="Language"
        name="triggers.languageTrigger.languageIds"
        normalize={normalize}
        placeholder="Select languages"
        renderSelectedOption={renderFilterOption(anyLanguageLanguage.id)}
        tooltipText={
          field.value.actions.includes(EntityWebhookAction.Created)
            ? 'The criteria don’t apply to the “Language created” event.'
            : undefined
        }
        verticalMenuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.LanguageFilter)}
        {...getDataUiInputAttribute(DataUiInput.EntityWebhookLanguageFilter)}
      />
      {isEmptyLanguageFilter && inputState === InputState.Default && (
        <EntityWebhookValidationError message="Please select at least one language." />
      )}
    </>
  );
};

EntityWebhookLanguageFilterSelectors.displayName = 'EntityWebhookLanguageFilterFormFields';
