import React from 'react';
import { formatDatetimeToReadable } from '../../../../../../component-library/components/DatetimePicker/datetimeUtils.ts';
import { DataTableRow } from '../../../../../_shared/components/DataTable/DataTableRow.tsx';
import { LinkDataTableCell } from '../../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { IUserInfo } from '../../../../../_shared/models/UserInfo.ts';
import { pluralizeWithCount } from '../../../../../_shared/utils/stringUtils.ts';
import { IListingVariantData } from '../../../../../data/models/listingContentItems/IListingVariantData.ts';
import { ScrollTableItemContributors } from '../../../contentStatus/components/ScrollTableItemContributors.tsx';
import { daysSinceDateTimeStamp } from '../../../utils/daysSinceDate.ts';

type UnchangedPublishedItemsWidgetDetailRowProps = Readonly<{
  collectionName: string;
  contributors: ReadonlyArray<IUserInfo>;
  itemName: string;
  linkPath: string | null;
  variant: IListingVariantData;
  variantName: string;
}>;

export const UnchangedPublishedItemsWidgetDetailRow: React.FC<
  UnchangedPublishedItemsWidgetDetailRowProps
> = ({ collectionName, contributors, itemName, linkPath, variant, variantName }) => {
  const itemId = `${variant.id.itemId}:${variant.id.variantId}`;
  const lastPublishedAt =
    variant.lastPublishedAt && formatDatetimeToReadable(new Date(variant.lastPublishedAt), false);

  return (
    <DataTableRow dataUiObjectName={itemId} id={itemId}>
      <LinkDataTableCell linkPath={linkPath}>{itemName}</LinkDataTableCell>
      <LinkDataTableCell linkPath={linkPath}>{variantName}</LinkDataTableCell>
      <LinkDataTableCell linkPath={linkPath}>{collectionName}</LinkDataTableCell>
      <LinkDataTableCell linkPath={linkPath}>
        <ScrollTableItemContributors contributors={contributors} />
      </LinkDataTableCell>
      <LinkDataTableCell linkPath={linkPath}>{lastPublishedAt}</LinkDataTableCell>
      <LinkDataTableCell linkPath={linkPath}>
        {pluralizeWithCount('day', daysSinceDateTimeStamp(variant.lastPublishedAt))}
      </LinkDataTableCell>
    </DataTableRow>
  );
};

UnchangedPublishedItemsWidgetDetailRow.displayName = 'UnchangedPublishedItemsWidgetDetailRow';
