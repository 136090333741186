import Immutable from 'immutable';
import { ContextualHelpInfo } from '../../data/models/contextualHelp/ContextualHelpInfo.type.ts';
import { ContextualHelpType } from '../../data/models/contextualHelp/ContextualHelpTypeEnum.ts';
import { IOnboardingNotificationsServerModel } from '../../data/models/onboardingNotifications/OnboardingNotificationsServerModel.type.ts';
import {
  OnboardingNotification,
  OnboardingNotificationsInfo,
} from '../../data/models/user/OnboardingNotification.ts';
import { IContextualHelpServerModel } from '../../repositories/serverModels/ContextualHelpServerModel.type.ts';
import { AiFeatureSet } from '../constants/AiFeatureSet.ts';
import {
  UserPropertiesModel,
  UserPropertiesStateKeysByServerKeys,
} from '../models/UserPropertiesModel.ts';
import {
  AiFeatureSetServerKey,
  AiFeedbackTimestamps,
  ContentModelingFeedbackServerKey,
  ContextualHelpSettingsServerKey,
  DismissedNewWebhooksOnboardingServerKey,
  HasCreatedSeventhOrMoreWorkflowStep,
  IsSentryBugReportWidgetEnabledServerKey,
  LongProcessingChangesWarningDismissedServerKey,
  OnboardingNotificationsSettingsServerKey,
  PossiblyIncorrectPlacementWarningDismissedServerKey,
  PrefersClosedEditingSidebar,
  UpsellMultipleWorkflowsAfterCreatingStepDismissedServerKey,
  UpsellMultipleWorkflowsInitialDismissedServerKey,
  UserFeedbackServerKey,
  WebSpotlightTreeCollapsedServerKey,
} from '../models/UserPropertiesServerKeys.ts';
import { matchingStrategies, parseArrayOfSpecificStrings } from './parseJson.ts';
import { parseBooleanFromValue } from './parseUtils.ts';

const resolveContextualHelpSettings = (
  propertyValue: string,
): Immutable.Map<ContextualHelpType, boolean> | null => {
  const mapFromServerModel = (serverModel: IContextualHelpServerModel): ContextualHelpInfo => ({
    ContentItem: serverModel.showForContentItem || false,
    ContentItems: serverModel.showForContentItems || false,
    ContentType: serverModel.showForContentType || false,
    ContentTypes: serverModel.showForContentTypes || false,
    YourContent: serverModel.showForMyContent || false,
  });

  const serverData = resolveAsJson(propertyValue) as IContextualHelpServerModel | null;
  return serverData
    ? Immutable.Map<ContextualHelpType, boolean>(mapFromServerModel(serverData))
    : null;
};

const resolveOnboardingNotificationSettings = (
  propertyValue: string,
): Immutable.Map<OnboardingNotification, boolean> | null => {
  const mapFromServerModel = (
    serverModel: IOnboardingNotificationsServerModel,
  ): OnboardingNotificationsInfo => ({
    ContentPublishedNotification: serverModel.showForItemPublishing || false,
    ContentTypeCreatedNotification: serverModel.showForTypeEditing || false,
  });

  const serverData = resolveAsJson(propertyValue) as IOnboardingNotificationsServerModel | null;
  return serverData
    ? Immutable.Map<OnboardingNotification, boolean>(mapFromServerModel(serverData))
    : null;
};

const resolveAsJson = (propertyValue: string): unknown => {
  try {
    return JSON.parse(propertyValue);
  } catch {
    return null;
  }
};

export const resolvePropertyValue = <PropName extends keyof UserPropertiesModel>(
  propName: PropName,
  propertyValue: string,
): UserPropertiesModel[PropName] | null => {
  switch (propName) {
    case UserPropertiesStateKeysByServerKeys[ContextualHelpSettingsServerKey]: {
      return resolveContextualHelpSettings(propertyValue) as UserPropertiesModel[PropName] | null;
    }

    case UserPropertiesStateKeysByServerKeys[OnboardingNotificationsSettingsServerKey]: {
      return resolveOnboardingNotificationSettings(propertyValue) as
        | UserPropertiesModel[PropName]
        | null;
    }

    case UserPropertiesStateKeysByServerKeys[AiFeedbackTimestamps]:
    case UserPropertiesStateKeysByServerKeys[ContentModelingFeedbackServerKey]:
    case UserPropertiesStateKeysByServerKeys[UserFeedbackServerKey]: {
      return resolveAsJson(propertyValue) as UserPropertiesModel[PropName] | null;
    }

    case UserPropertiesStateKeysByServerKeys[DismissedNewWebhooksOnboardingServerKey]:
    case UserPropertiesStateKeysByServerKeys[IsSentryBugReportWidgetEnabledServerKey]:
    case UserPropertiesStateKeysByServerKeys[HasCreatedSeventhOrMoreWorkflowStep]:
    case UserPropertiesStateKeysByServerKeys[LongProcessingChangesWarningDismissedServerKey]:
    case UserPropertiesStateKeysByServerKeys[PossiblyIncorrectPlacementWarningDismissedServerKey]:
    case UserPropertiesStateKeysByServerKeys[PrefersClosedEditingSidebar]:
    case UserPropertiesStateKeysByServerKeys[
      UpsellMultipleWorkflowsAfterCreatingStepDismissedServerKey
    ]:
    case UserPropertiesStateKeysByServerKeys[UpsellMultipleWorkflowsInitialDismissedServerKey]:
    case UserPropertiesStateKeysByServerKeys[WebSpotlightTreeCollapsedServerKey]: {
      return parseBooleanFromValue(propertyValue) as UserPropertiesModel[PropName];
    }

    case UserPropertiesStateKeysByServerKeys[AiFeatureSetServerKey]: {
      return parseArrayOfSpecificStrings(
        propertyValue,
        Object.values(AiFeatureSet),
        matchingStrategies.caseInsensitive,
      ) as UserPropertiesModel[PropName];
    }

    default:
      return propertyValue as UserPropertiesModel[PropName];
  }
};
