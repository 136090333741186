import React from 'react';
import {
  ScheduledActionInProgress,
  ScheduledActionType,
} from '../../../../../../_shared/components/Workflow/ScheduledActionInProgress.tsx';
import { RemoveUnpublishingDate } from '../../../../../../_shared/constants/itemActions.ts';
import { Button, ButtonSize } from '../../../../../../_shared/uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import {
  DataUiElement,
  DataUiWorkflowAction,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { renderDatetimeString } from '../../../../../../_shared/utils/dateTime/timeUtils.ts';
import { isVariantScheduledActionInProgress } from '../../../../../../_shared/utils/workflow/isVariantScheduledActionInProgress.ts';
import { SectionTitle } from './SectionTitle.tsx';

export interface IUnpublishDateSectionStateProps {
  readonly canCancelUnpublish: boolean;
  readonly unpublishDate: DateTimeStamp | null;
}

export interface IUnpublishDateSectionDispatchProps {
  readonly cancelScheduledUnpublish: () => void;
}

type UnpublishDateSectionProps = IUnpublishDateSectionDispatchProps &
  IUnpublishDateSectionStateProps;

export const UnpublishDateSection: React.FC<UnpublishDateSectionProps> = ({
  cancelScheduledUnpublish,
  canCancelUnpublish,
  unpublishDate,
}) =>
  unpublishDate ? (
    <div
      className="sidebar__section"
      data-hj-suppress=""
      {...getDataUiElementAttribute(DataUiElement.UnpublishDateSection)}
    >
      {isVariantScheduledActionInProgress(unpublishDate) ? (
        <>
          <SectionTitle>
            <ScheduledActionInProgress actionType={ScheduledActionType.Unpublish} showTooltip>
              Unpublishing and archiving now
            </ScheduledActionInProgress>
          </SectionTitle>
          <div className="sidebar__section-item">
            Scheduled for {renderDatetimeString(unpublishDate)}
          </div>
        </>
      ) : (
        <>
          <SectionTitle>To be unpublished on</SectionTitle>
          <div className="sidebar__section-item">{renderDatetimeString(unpublishDate)}</div>
        </>
      )}
      {canCancelUnpublish && (
        <div className="sidebar__section-button-wrapper">
          <Button
            dataUiAction={DataUiWorkflowAction.CancelScheduledUnpublish}
            onClick={cancelScheduledUnpublish}
            size={ButtonSize.Block}
            style={ButtonStyle.DestructiveSecondary}
          >
            {RemoveUnpublishingDate}
          </Button>
        </div>
      )}
    </div>
  ) : null;

UnpublishDateSection.displayName = 'UnpublishDateSection';
