import React, { RefAttributes, RefObject, useCallback } from 'react';
import { VariableSizeList as VirtualizedList } from 'react-window';
import {
  DataUiCLElement,
  getDataUiCLElementAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { IItemProps } from '../../VerticalMenu/types.ts';
import { MultiSelectState } from '../MultiSelect/useMultiSelectState.ts';
import { SingleSelectState } from '../SingleSelect/useSingleSelectState.ts';
import { Option } from '../components/Option.tsx';
import { RenderSelectMenuOptionProps, SelectMenuProps } from '../components/SelectMenu.tsx';
import { ISelectItem } from '../types.ts';

type Refs = {
  readonly menuRef: RefObject<HTMLDivElement>;
  readonly virtualizedListRef?: RefObject<VirtualizedList<HTMLDivElement>>;
};

export type UseSelectMenuOptions<TItem extends ISelectItem<TItem>> = Readonly<{
  isReadOnly: boolean;
  isVirtualized: boolean;
  onInputChange?: (value: string) => void;
  renderMenuOption?: (optionProps: RenderSelectMenuOptionProps<TItem>) => React.ReactNode;
  selectionMode: 'single' | 'multi';
}> &
  Refs &
  Pick<SelectMenuProps<TItem>, 'optionHeight' | 'verticalMenuDataAttributes'>;

export type UseSelectMenuResult<TItem extends ISelectItem<TItem>> = Omit<
  SelectMenuProps<TItem>,
  'triggerWidth'
> &
  RefAttributes<HTMLDivElement>;

/**
 * Returns props for the SelectMenu component, except the `triggerWidth` which is provided by the DropDownMenu component.
 */
export const useSelectMenu = <TItem extends ISelectItem<TItem>>(
  options: UseSelectMenuOptions<TItem>,
  state: SingleSelectState<TItem> | MultiSelectState<TItem>,
): UseSelectMenuResult<TItem> => {
  const {
    isReadOnly,
    isVirtualized,
    menuRef,
    renderMenuOption,
    selectionMode,
    virtualizedListRef,
    ...otherSelectMenuProps
  } = options;

  const isSingleSelect = selectionMode === 'single';

  const renderSelectMenuOption = useCallback(
    (itemProps: IItemProps<TItem>) => {
      const props: RenderSelectMenuOptionProps<TItem> = {
        highlightPattern: state.filterValue,
        isReadOnly,
        isVirtualized,
        onPress: isSingleSelect ? state.closeMenu : undefined,
        ...getDataUiCLElementAttribute(
          isSingleSelect
            ? DataUiCLElement.SingleSelectDropdownOption
            : DataUiCLElement.MultiSelectDropdownOption,
        ),
        ...itemProps,
      };

      return renderMenuOption?.(props) ?? <Option {...props} />;
    },
    [
      state.filterValue,
      state.closeMenu,
      isReadOnly,
      isVirtualized,
      isSingleSelect,
      renderMenuOption,
    ],
  );

  return {
    isVirtualized,
    ref: menuRef,
    renderMenuOption: renderSelectMenuOption,
    state,
    virtualizedListRef,
    ...getDataUiCLElementAttribute(
      isSingleSelect ? DataUiCLElement.SingleSelectDropdown : DataUiCLElement.MultiSelectDropdown,
    ),
    ...otherSelectMenuProps,
  };
};
