import { usePrevious } from '@kontent-ai/hooks';
import { assert, noOperation, notUndefined } from '@kontent-ai/utils';
import {
  CompositeDecorator,
  DraftBlockRenderConfig,
  DraftHandleValue,
  Editor as DraftJSEditor,
  EditorProps as DraftJSEditorProps,
  EditorState,
  genKey,
} from 'draft-js';
import Immutable from 'immutable';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { logError } from '../../../_shared/utils/logError.ts';
import { shallowEqual } from '../../../_shared/utils/shallowEqual.ts';
import {
  ApplyEditorStateChanges,
  BaseEditor,
  BaseEditorProps,
  BaseState,
  CanUpdateContent,
  Change,
  ExecuteChange,
  ExecuteExternalAction,
  GetBaseBlockRenderMap,
  InternalEditorProps,
  IsEditorLocked,
  OnUpdate,
  Reinit,
  RemoveInvalidState,
} from '../editorCore/types/Editor.base.type.ts';
import { applyUndoRedoStacks, disableUndo } from '../plugins/undoRedo/api/editorUndoUtils.ts';
import { BaseBlockType } from '../utils/blocks/blockType.ts';
import { baseEditorApi } from './api/baseEditorApi.ts';
import { bindApiMethods } from './hooks/bindApiMethods.ts';
import { useGetEditorRef } from './hooks/useGetEditorRef.ts';
import { GetApi } from './types/Editor.api.type.ts';
import { EditorPluginProps } from './types/Editor.composition.type.ts';
import { Api } from './types/Editor.contract.type.ts';
import {
  Callbacks,
  EditorPlugin,
  Init,
  InitState,
  Render,
  UndoState,
} from './types/Editor.plugins.type.ts';
import { EditorChangeReason, internalChangeReasons } from './types/EditorChangeReason.ts';
import { decorable, seal } from './utils/decorable.ts';
import { BaseBlockRenderMap } from './utils/editorComponentUtils.ts';

const disableDefaultDndBehaviour = (): DraftHandleValue => 'handled';

const initialBlockRenderMap: BaseBlockRenderMap = Immutable.Map<
  BaseBlockType,
  DraftBlockRenderConfig
>({
  [BaseBlockType.Unstyled]: {
    element: 'div',
    aliasedElements: ['p'],
  },
});

const getInitialBaseBlockRenderMap: GetBaseBlockRenderMap = () => initialBlockRenderMap;

type EditorProps = BaseEditorProps & EditorPluginProps;

export const Editor: React.FC<EditorProps> = (props) => {
  const { disabled, plugins } = props;

  const lastPluginInits = useRef<ReadonlyArray<Init> | null>(null);
  const pluginInits = useMemo(() => {
    const newInits = plugins?.map((plugin) => plugin.init).filter(notUndefined) ?? [];
    const lastInits = lastPluginInits.current;
    if (lastInits && !shallowEqual(lastInits, newInits)) {
      const firstChanged = newInits.find((newInit, index) => lastInits[index] !== newInit);
      logError(`Init method of a plugin must be a stable reference since the editor can’t re-init on-the-fly.
An init method which changed but can’t be applied is the following:

${firstChanged}
`);
    }

    lastPluginInits.current = newInits;
    return lastPluginInits.current;
  }, [plugins]);

  // biome-ignore lint/correctness/useExhaustiveDependencies(pluginInits?.reduce): We need to spread params in dependencies because their number is not universally defined.
  const init = useCallback(
    (editorState: EditorState): EditorState => {
      const initialInitState: InitState = {
        initialEditorState: editorState,
        content: editorState.getCurrentContent(),
        decorators: [],
        // For content components it is important to apply undo stack within initial component mount because it may be caused by undo of component deletion
        // for regular inputs we don't typically expect undo stack to be in source data, but for consistency we use it as well (just in case)
        undo: UndoState.EnabledKeepHistory,
      };
      const completeInitState =
        pluginInits?.reduce(
          (initState, pluginInit) => ({
            ...initState,
            ...pluginInit?.(initState),
          }),
          initialInitState,
        ) ?? initialInitState;

      const completeEditorState = EditorState.createWithContent(
        completeInitState.content,
        completeInitState.decorators.length
          ? new CompositeDecorator([...completeInitState.decorators])
          : undefined,
      );

      const withUndoStack =
        completeInitState.undo !== UndoState.DisabledDropHistory
          ? applyUndoRedoStacks(editorState, completeEditorState)
          : editorState;

      const withUndoFlag =
        completeInitState.undo !== UndoState.EnabledKeepHistory
          ? disableUndo(withUndoStack)
          : withUndoStack;

      return withUndoFlag;
    },
    [...pluginInits],
  );

  const [editorState, setEditorState] = useState<EditorState>(() => init(props.editorState));

  // We need to keep reference to the last editor state because we don't want to rebuild methods that are just reading it upon every small change
  // We also need to read it in the executeChange pipeline because we can't trigger complex actions with side effects from setEditorState callback
  // which may violate rendering pre-conditions (render other components while Editor is in the middle of rendering phase)
  const editorStateRef = useRef(editorState);
  const updateEditorState = useCallback((change: Change) => {
    const newEditorState = change(editorStateRef.current);
    editorStateRef.current = newEditorState;
    setEditorState(newEditorState);
  }, []);

  const getEditorState = useCallback(() => editorStateRef.current, []);

  const reinit = useCallback<Reinit>(
    (currentEditorState) => {
      updateEditorState(() => init(currentEditorState));
    },
    [init, updateEditorState],
  );

  const [isExternalActionInProgress, setIsExternalActionInProgress] = useState(false);

  const [editorId, setEditorId] = useState(genKey);
  const getEditorId = useCallback(() => editorId, [editorId]);

  // We have to force DraftJS Editor re-render when disabled prop is changed to reflect the disabled state in the respective components
  // e.g. show/hide Remove button in linked item/component or edit button in links
  const previousDisabled = usePrevious(disabled);
  useEffect(() => {
    if (disabled !== previousDisabled) {
      setEditorId(genKey());
    }
  }, [previousDisabled, disabled]);

  const getEditorRef = useGetEditorRef<DraftJSEditor>();

  const completeApi: Api<BaseEditor> = useMemo(() => {
    const api =
      plugins?.reduce((aggregatedApi, plugin) => {
        const pluginApi = plugin.getApiMethods?.(aggregatedApi);
        return pluginApi ? { ...aggregatedApi, ...pluginApi } : aggregatedApi;
      }, baseEditorApi) ?? baseEditorApi;
    return bindApiMethods(api);
  }, [plugins]);

  const getApi: GetApi<EditorPlugin> = useCallback(() => completeApi, [completeApi]);

  const callbacks = useMemo(() => {
    const onUpdate = decorable<OnUpdate>(noOperation);

    const canUpdateContent = decorable<CanUpdateContent>((changeReason) =>
      internalChangeReasons.has(changeReason ?? EditorChangeReason.Regular)
        ? !disabled
        : !disabled && !isExternalActionInProgress,
    );

    const applyEditorStateChanges = decorable<ApplyEditorStateChanges>(
      ({ newState, oldState, allowEditContent, changeReason }) =>
        getApi().applyEditorStateChanges(newState, oldState, allowEditContent, changeReason),
    );

    const executeChange: ExecuteChange = async (
      change,
      changeReason = EditorChangeReason.Regular,
    ) =>
      await new Promise<EditorState>((resolve, reject) => {
        updateEditorState((currentEditorState) => {
          try {
            const newEditorState = change(currentEditorState);
            const allowEditContent = canUpdateContent(changeReason);
            const allowedNewState = applyEditorStateChanges({
              newState: newEditorState,
              oldState: currentEditorState,
              allowEditContent,
              changeReason,
            });

            resolve(allowedNewState);

            if (allowedNewState !== currentEditorState) {
              onUpdate({
                editorState: allowedNewState,
                changeReason,
              });
            }

            return allowedNewState;
          } catch (e) {
            reject(e);
            throw e;
          }
        });
      });

    const executeExternalAction: ExecuteExternalAction = async (
      action,
      changeReason = EditorChangeReason.Internal,
    ) => {
      // External action is an async action that modifies editorState. For the time of the action execution the editor is disabled
      setIsExternalActionInProgress(true);

      try {
        const newEditorState = await action(getEditorState());
        return await executeChange(() => newEditorState, changeReason);
      } finally {
        setIsExternalActionInProgress(false);
      }
    };

    const isEditorLocked = decorable<IsEditorLocked>(() => isExternalActionInProgress);

    const render = decorable<Render<BaseEditor>>((state) => (
      <DraftJSEditor key={editorId} ref={state.getEditorRef()} {...state.editorProps} />
    ));

    const getBaseBlockRenderMap = decorable(getInitialBaseBlockRenderMap);

    const removeInvalidState = decorable<RemoveInvalidState>(noOperation);

    const initialCallbacks: Callbacks<BaseState> = {
      applyEditorStateChanges,
      canUpdateContent,
      executeChange,
      executeExternalAction,
      getApi,
      getBaseBlockRenderMap,
      getEditorId,
      getEditorState,
      getEditorRef,
      isEditorLocked,
      onUpdate,
      reinit,
      removeInvalidState,
      render,
    };

    const completeCallbacks: Callbacks<BaseState> =
      plugins?.reduce((state, plugin) => {
        const pluginState = plugin.apply?.(state);
        for (const propName in pluginState) {
          if (Object.hasOwn(pluginState, propName)) {
            assert(
              !Object.hasOwn(state, propName),
              () =>
                `Plugin is not allowed to change existing state property ${propName}, use ${propName}.decorate(...)`,
            );
          }
        }
        return pluginState ? { ...pluginState, ...state } : state;
      }, initialCallbacks) ?? initialCallbacks;

    plugins?.forEach((plugin) => plugin.finalize?.(completeCallbacks));

    const sealedState = seal(completeCallbacks);
    return sealedState;
  }, [
    getApi,
    disabled,
    editorId,
    getEditorState,
    getEditorId,
    getEditorRef,
    isExternalActionInProgress,
    plugins,
    reinit,
    updateEditorState,
  ]);

  const { canUpdateContent, executeChange, getBaseBlockRenderMap } = callbacks;

  const onNativeChange: DraftJSEditorProps['onChange'] = useCallback(
    (newEditorState) => {
      executeChange(() => newEditorState, EditorChangeReason.Native);
    },
    [executeChange],
  );

  const handleBeforeInput = useCallback<Required<DraftJSEditorProps>['handleBeforeInput']>(
    (_, currentEditorState) => {
      const selection = currentEditorState.getSelection();
      if (!selection.getHasFocus() || !canUpdateContent()) {
        return 'handled';
      }

      return 'not-handled';
    },
    [canUpdateContent],
  );

  const initialEditorProps: InternalEditorProps = {
    editorState,
    handleBeforeInput,
    handleDrop: disableDefaultDndBehaviour,
    handleDroppedFiles: disableDefaultDndBehaviour,
    onChange: onNativeChange,
    spellCheck: true,
    stripPastedStyles: true,
    blockRenderMap: getBaseBlockRenderMap(),
  };

  const state = {
    ...callbacks,
    editorState,
    editorProps: initialEditorProps,
  };

  return callbacks.render(state);
};

Editor.displayName = 'Editor';
