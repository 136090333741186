import { memoize } from '@kontent-ai/memoization';
import { Direction } from '@kontent-ai/types';
import { notNullNorUndefined } from '@kontent-ai/utils';
import { ContentBlock, ContentState } from 'draft-js';
import Immutable from 'immutable';
import { CONTENT_COMPONENT_ID_KEY } from '../../../../itemEditor/features/ContentComponent/constants/contentComponentConstants.ts';
import { IContentComponent } from '../../../../itemEditor/models/contentItem/ContentComponent.ts';
import { ICompiledContentItemElementData } from '../../../../itemEditor/models/contentItemElements/ICompiledContentItemElement.ts';
import { isRichTextElement } from '../../../../itemEditor/models/contentItemElements/compiledItemElementTypeGuards.ts';
import { BlockType } from '../../../utils/blocks/blockType.ts';
import { isContentComponent } from '../../../utils/blocks/blockTypeUtils.ts';
import {
  createRawBlock,
  getBlockDataValue,
  setBlockDataValue,
} from '../../../utils/blocks/editorBlockUtils.ts';
import { getBlocks } from '../../../utils/general/editorContentGetters.ts';
import {
  IContentChangeInput,
  IContentChangeResult,
  deleteObjectBlock,
  modifyBlocks,
  replaceBlock,
} from '../../../utils/general/editorContentUtils.ts';
import { insertMultipleModularContentItems } from '../../linkedItems/api/editorModularUtils.ts';

export interface IComponentPathItem {
  readonly contentComponentId: Uuid;
  readonly contentGroupId: Uuid | null;
}

function getContentComponentRawBlock(contentComponentId: Uuid) {
  const blockData = {
    [CONTENT_COMPONENT_ID_KEY]: contentComponentId,
  };

  const contentComponentBlock = createRawBlock({
    type: BlockType.ContentComponent,
    data: blockData,
  });
  return contentComponentBlock;
}

export function insertContentComponent(
  input: IContentChangeInput,
  placeholderBlockKey: string,
  contentComponentId: Uuid,
): IContentChangeResult {
  const rawBlock = getContentComponentRawBlock(contentComponentId);

  const withComponent = replaceBlock(input, placeholderBlockKey, [rawBlock]);
  return withComponent;
}

export function convertContentComponentToModularItem(
  input: IContentChangeInput,
  blockKey: string,
  itemIds: UuidArray,
): IContentChangeResult {
  const deletedBlockElement = deleteObjectBlock(input, blockKey, Direction.Backward);
  const insertedModularItems = insertMultipleModularContentItems(deletedBlockElement, itemIds);

  return {
    wasModified: deletedBlockElement.wasModified || insertedModularItems.wasModified,
    content: insertedModularItems.content,
    selection: insertedModularItems.selection,
  };
}

function transformContentElements(
  contentElements: ReadonlyArray<ICompiledContentItemElementData>,
): Immutable.Map<Uuid, ICompiledContentItemElementData> {
  const transformedElements = Immutable.Map<Uuid, ICompiledContentItemElementData>(
    contentElements.map((element) => [element.elementId, element]),
  );

  return transformedElements;
}

export const getContentElements = memoize.weak(
  (
    contentComponentItem: IContentComponent,
  ): Immutable.Map<Uuid, ICompiledContentItemElementData> => {
    const contentElements = transformContentElements(contentComponentItem.elements);

    return contentElements;
  },
);

export const getContentComponentId = (block: ContentBlock): Uuid | null =>
  getBlockDataValue<Uuid>(block, CONTENT_COMPONENT_ID_KEY) ?? null;

export const setContentComponentId = (
  block: ContentBlock,
  contentComponentId: Uuid,
): ContentBlock => setBlockDataValue(block, CONTENT_COMPONENT_ID_KEY, contentComponentId);

export const getUsedContentComponents = (
  content: ContentState,
  contentComponents: ReadonlyMap<Uuid, IContentComponent>,
): ReadonlyArray<IContentComponent> =>
  getContentComponentIds(content)
    .map((contentComponentId) => contentComponents.get(contentComponentId))
    .filter(notNullNorUndefined);

export const getAllUsedContentComponents = (
  content: ContentState,
  contentComponents: ReadonlyMap<Uuid, IContentComponent>,
): ReadonlyArray<IContentComponent> =>
  getUsedContentComponents(content, contentComponents).flatMap((contentComponent) => [
    contentComponent,
    ...contentComponent.elements
      .filter(isRichTextElement)
      .flatMap((element) =>
        getAllUsedContentComponents(element._editorState.getCurrentContent(), contentComponents),
      ),
  ]);

export const getContentComponentIds = (content: ContentState): ReadonlyArray<Uuid> =>
  getBlocks(content)
    .filter(isContentComponent)
    .map(getContentComponentId)
    .filter(notNullNorUndefined);

export const getMaxComponentDepth = (
  content: ContentState,
  contentComponents: ReadonlyMap<Uuid, IContentComponent>,
): number =>
  Math.max(
    0,
    ...getUsedContentComponents(content, contentComponents).map((contentComponent) =>
      Math.max(
        1,
        ...contentComponent.elements
          .filter(isRichTextElement)
          .map(
            (element) =>
              getMaxComponentDepth(element._editorState.getCurrentContent(), contentComponents) + 1,
          ),
      ),
    ),
  );

export const updateContentComponentIds = (
  content: ContentState,
  newContentComponentIds: ReadonlyMap<Uuid, Uuid>,
): ContentState => {
  if (newContentComponentIds.size === 0) {
    return content;
  }

  return modifyBlocks(content, (block) => {
    const contentComponentId = isContentComponent(block) ? getContentComponentId(block) : null;
    if (contentComponentId) {
      const newContentComponentId = newContentComponentIds.get(contentComponentId);
      if (newContentComponentId && newContentComponentId !== contentComponentId) {
        return setContentComponentId(block, newContentComponentId);
      }
    }
    return block;
  });
};
