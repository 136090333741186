import { ApiKeyType } from '../../../../_shared/models/ApiKeyType.ts';
import { DateTime } from '../../../../_shared/models/DateTime.ts';
import { isEmptyOrWhitespace } from '../../../../_shared/utils/stringUtils.ts';
import { FormValidatorConfig } from '../../../../_shared/utils/validation/createFormValidationResolver.ts';
import { IProjectContainerActiveUser } from '../../../../data/models/projectContainerActiveUsers/ProjectContainerActiveUser.ts';
import { expirationDateIsValidBuilder } from './expirationDateValidation.ts';

export type MapiKeyFormShape = {
  readonly environments: ReadonlyArray<Uuid>;
  readonly expiresAt: DateTime;
  readonly name: string;
  readonly permissions: Permissions;
  readonly sharedWithUsers: ReadonlyArray<string>;
};

export type Permissions = {
  readonly canManageAssets: boolean;
  readonly canManageContent: boolean;
  readonly canManageContentModel: boolean;
  readonly canManageEnvironmentSettings: boolean;
  readonly canManageEnvironments: boolean;
  readonly canReadAssets: boolean;
  readonly canReadContent: boolean;
};

type Props = {
  readonly projectContainerActiveUsers: ReadonlyArray<IProjectContainerActiveUser>;
};

const atLeastOneSelected =
  (errorMessage: string) =>
  (value: ReadonlyArray<Uuid>): string | undefined =>
    value.length === 0 ? errorMessage : undefined;

const atLeastOneChecked =
  (errorMessage: string) =>
  (value: Permissions): string | undefined =>
    value.canManageAssets ||
    value.canManageContent ||
    value.canManageContentModel ||
    value.canManageEnvironmentSettings ||
    value.canManageEnvironments ||
    value.canReadAssets ||
    value.canReadContent
      ? undefined
      : errorMessage;

const allUserIdsExistInActiveUsersCollection =
  (errorMessage: string) =>
  (value: ReadonlyArray<string>, _form: MapiKeyFormShape, props: Props): string | undefined => {
    const projectContainerActiveUserIds = props.projectContainerActiveUsers.map(
      (activeUser) => activeUser.userId,
    );

    const containsDeactivatedUserId = value.some(
      (selectedUserId) => !projectContainerActiveUserIds.includes(selectedUserId),
    );

    return containsDeactivatedUserId ? errorMessage : undefined;
  };

const requiredField =
  (errorMessage: string): ((value: string) => string | undefined) =>
  (value: string) =>
    isEmptyOrWhitespace(value) ? errorMessage : undefined;

const environmentRequired = atLeastOneSelected('Select at least one environment.');
const validExpirationDateRequired = expirationDateIsValidBuilder(ApiKeyType.MAPI);
const nameRequired = requiredField('Provide a name for the API key.');
const permissionRequired = atLeastOneChecked('Select at least one permission.');
const allUsersAreActive = allUserIdsExistInActiveUsersCollection(
  'This field can’t contain deactivated users.',
);

export const mapiKeyValidationConfig: FormValidatorConfig<MapiKeyFormShape, Props> = {
  environments: [environmentRequired],
  expiresAt: [validExpirationDateRequired],
  name: [nameRequired],
  permissions: [permissionRequired],
  sharedWithUsers: [allUsersAreActive],
};
