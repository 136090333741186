import { BaseColor, colorOverlayBackground } from '@kontent-ai/component-library/tokens';
import { rgba } from '@kontent-ai/component-library/utils';
import { UseTransitionProps, animated, useTransition } from '@react-spring/web';
import React from 'react';
import { DataUiElement } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { Modal } from './Modal.tsx';
import { ModalViewerPosition } from './ModalViewerPosition.ts';

interface IModalViewerOwnProps {
  readonly dialogClassName: string;
  readonly isDialogVisible: boolean;
  readonly onClose?: () => void;
  readonly onReturnFocus?: () => void;
  readonly position: ModalViewerPosition;
}

const transparentOverlayColor = rgba(BaseColor.OceanBlue70, 0);

const underlayTransitionsDefinition = {
  from: {
    background: transparentOverlayColor,
  },
  enter: {
    background: colorOverlayBackground,
  },
  leave: {
    background: transparentOverlayColor,
  },
};

const sidebarTransitionsDefinition: UseTransitionProps = {
  from: {
    transform: 'translateX(100%)',
  },
  enter: {
    transform: 'translateX(0%)',
  },
  leave: {
    transform: 'translateX(100%)',
  },
};

const modalTransitionsDefinition: UseTransitionProps = {
  from: {
    transform: 'translateY(100%)',
  },
  enter: {
    transform: 'translateY(0%)',
  },
  leave: {
    transform: 'translateY(100%)',
  },
};

export const ModalViewer: React.FC<React.PropsWithChildren<IModalViewerOwnProps>> = (props) => {
  const { children, dialogClassName, isDialogVisible, onClose, onReturnFocus, position } = props;

  const Dialog = !!children && React.Children.only(children);

  const underlayTransitions = useTransition(isDialogVisible, underlayTransitionsDefinition);

  const dialogTransitions = useTransition(
    isDialogVisible,
    position === ModalViewerPosition.Right
      ? sidebarTransitionsDefinition
      : modalTransitionsDefinition,
  );

  const animatedDialog = dialogTransitions(
    (style, item) =>
      item && (
        <animated.div className={dialogClassName} style={style}>
          {Dialog}
        </animated.div>
      ),
  );

  return (
    <Modal
      underlayClass="mask-modal"
      underlayDataAttribute={DataUiElement.MaskModal}
      underlayTransitions={underlayTransitions}
      onClose={() => onClose?.()}
      onReturnFocus={onReturnFocus}
    >
      {animatedDialog}
    </Modal>
  );
};

ModalViewer.displayName = 'ModalViewer';
