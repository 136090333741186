import { checkboxGroupSpacingBetweenOptions } from '@kontent-ai/component-library/CheckboxGroup';
import { Stack } from '@kontent-ai/component-library/Stack';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

interface ITreeListDataProps {
  readonly collapsed?: boolean;
  readonly disabled?: boolean;
}

const propTypes: PropTypesShape<ITreeListDataProps> = {
  collapsed: PropTypes.bool,
  disabled: PropTypes.bool,
};

const TreeList: React.FC<React.PropsWithChildren<ITreeListDataProps>> = ({
  collapsed,
  disabled,
  children,
}) => (
  <Stack
    component="ul"
    spacing={checkboxGroupSpacingBetweenOptions}
    className={classNames('tree__list', {
      'tree__list--is-collapsed': collapsed,
      'tree__list--is-expanded': !collapsed,
      'tree__list--is-disabled': disabled,
    })}
  >
    {children}
  </Stack>
);

TreeList.displayName = 'TreeList';
TreeList.propTypes = propTypes;

export { TreeList };
