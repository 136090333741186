import { ICancellablePromise, delay, swallowCancelledPromiseError } from '@kontent-ai/utils';
import classNames from 'classnames';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { IconName } from '../../constants/iconEnumGenerated.ts';
import { SquareButton } from '../../uiComponents/Button/SquareButton.tsx';
import { ButtonStyle } from '../../uiComponents/Button/buttonStyle.ts';
import { SquareButtonSize } from '../../uiComponents/Button/squareButtonSize.ts';

type Props = {
  readonly bulletPoints: ReadonlyArray<() => ReactNode>;
  readonly callToAction?: ReactNode;
  readonly onDismiss: () => void;
  readonly shouldShow: boolean;
  readonly title: string;
};

export const ContextualHelp = ({
  bulletPoints,
  title,
  callToAction,
  onDismiss,
  shouldShow,
}: Props) => {
  const [isHidden, setIsHidden] = useState(true);
  const timeToDisplay = useRef<ICancellablePromise | null>(null);

  useEffect(() => {
    if (shouldShow && !timeToDisplay.current) {
      timeToDisplay.current = delay(1200)
        .then(() => setIsHidden(false))
        .catch(swallowCancelledPromiseError);
    }
    return () => timeToDisplay.current?.cancel();
  }, [shouldShow]);

  return shouldShow ? (
    <div className="contextual-help">
      <div
        className={classNames('contextual-help__pane', {
          'contextual-help__pane--hidden': isHidden,
        })}
      >
        <div className="contextual-help__header">
          <h4 className="contextual-help__title">{title}</h4>
          <SquareButton
            customButtonClassName="contextual-help__dismiss-button"
            iconName={IconName.Times}
            onClick={onDismiss}
            screenReaderText="Close"
            size={SquareButtonSize.Quinary}
            style={ButtonStyle.Quinary}
          />
        </div>
        <div className="contextual-help__content">
          <ul className="contextual-help__bulletpoints">
            {bulletPoints.map((renderBulletPoint, index) => (
              <li key={index}>{renderBulletPoint()}</li>
            ))}
          </ul>
          <div>{callToAction}</div>
        </div>
      </div>
    </div>
  ) : null;
};
