import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import { ElementReference } from '../../../applications/itemEditor/features/ContentItemEditing/containers/hooks/useItemElementReference.ts';
import {
  AssetReference,
  IAssetItemElement,
} from '../../../applications/itemEditor/models/contentItemElements/AssetItemElement.ts';
import { IAsset } from '../../../data/models/assets/Asset.ts';
import { CollectionsMap } from '../../../data/models/collections/Collection.ts';
import { Languages } from '../../../data/models/languages/Language.ts';
import { TrackedEvent } from '../../constants/trackedEvent.ts';
import { DefaultLanguageId, DefaultVariantId } from '../../constants/variantIdValues.ts';
import { TrackUserEventWithData } from '../../models/TrackUserEvent.type.ts';
import { AssetDescriptionSource } from '../../models/TrackUserEventData.ts';
import { doesEntitySatisfyFilterPhrase } from '../filter/nameFilterUtils.ts';
import { UsageItemReference, compareUsages } from '../usage/usageUtils.ts';
import {
  AssetUploadFinishedEventDetail,
  AssetUploadFinishedEventForGlobalState,
  AssetUploadFinishedEventForLocalState,
} from './AssetUploadFinishedEvent.ts';

export const sortAndOrderAssetUsages = memoize.maxOne(
  (
    assetUsage: Immutable.Set<UsageItemReference>,
    languages: Languages,
  ): Immutable.OrderedMap</* variantId */ Uuid, Immutable.List<UsageItemReference>> => {
    const languageOrderWithDefault = languages.keySeq().toList().insert(0, DefaultVariantId);

    return assetUsage
      .sort(
        (a: UsageItemReference, b: UsageItemReference) =>
          languageOrderWithDefault.indexOf(a.primary.id.variantId) -
          languageOrderWithDefault.indexOf(b.primary.id.variantId),
      )
      .groupBy((x: UsageItemReference) => x.primary.id.variantId)
      .map((value: Immutable.Map<UsageItemReference, UsageItemReference>) =>
        value.sort(compareUsages).toList(),
      )
      .toOrderedMap();
  },
);

export function trackAssetUpdate(
  trackUserEvent: TrackUserEventWithData,
  updatedAsset: IAsset,
  aiGeneratedDescription: string | null,
) {
  const defaultDescriptionSource = getDefaultLanguageDescriptionSource(
    updatedAsset,
    aiGeneratedDescription,
  );

  trackUserEvent(TrackedEvent.AssetUpdated, {
    type: updatedAsset.type,
    'asset-id': updatedAsset.id,
    'default-language-description-source': defaultDescriptionSource,
  });
}

export const getDefaultLanguageDescriptionSource = (
  asset: IAsset,
  aiGeneratedDescription: string | null,
): AssetDescriptionSource | undefined => {
  if (!aiGeneratedDescription) {
    return undefined;
  }

  const defaultLanguageDescription = asset.descriptions.get(DefaultLanguageId);

  if (aiGeneratedDescription === defaultLanguageDescription) {
    return AssetDescriptionSource.GeneratedByAi;
  }

  return AssetDescriptionSource.GeneratedByAiAndModified;
};

export const shouldAssetFileNameBeRendered = (
  searchPhrase: string | undefined,
  asset: IAsset,
  isAssetFileTypeValid: boolean,
): boolean => {
  if (!asset.title || !isAssetFileTypeValid) {
    return true;
  }
  return !!searchPhrase && doesEntitySatisfyFilterPhrase(searchPhrase, asset, [(a) => a.filename]);
};

export const getCollectionName = (
  collectionId: Uuid | null,
  collectionsById: CollectionsMap,
): string | null => {
  if (collectionId === null) {
    return '∅ Not in collection';
  }

  return collectionsById.get(collectionId)?.name ?? null;
};

export const getFileName = (fileName: string): string => {
  if (!fileName) {
    return fileName;
  }
  return fileName.substr(0, fileName.lastIndexOf('.'));
};

export const getFileExtension = (fileName: string): string => {
  if (!fileName) {
    return fileName;
  }
  const parts: string[] = fileName.split('.');
  const extension = parts.pop();

  return extension || '';
};

export const replaceAssetIdentifier = (
  orderedAssetIds: Immutable.OrderedMap<Uuid, AssetReference>,
  oldAssetId: Uuid,
  newAssetId: Uuid,
): Immutable.OrderedMap<Uuid, AssetReference> =>
  orderedAssetIds.has(oldAssetId)
    ? Immutable.OrderedMap<Uuid, AssetReference>(
        orderedAssetIds.valueSeq().map((assetReference: AssetReference) =>
          assetReference.id === oldAssetId
            ? [
                newAssetId,
                {
                  ...assetReference,
                  id: newAssetId,
                },
              ]
            : [assetReference.id, assetReference],
        ),
      )
    : orderedAssetIds;

export function removeNonPersistentAssets(
  element: IAssetItemElement,
  assets: Immutable.Map<Uuid, IAsset>,
): IAssetItemElement {
  const filteredAssets = element.value
    .filter((_, id: Uuid) => {
      const asset = assets.get(id);
      return !!asset && !asset._failed && !asset._uploading;
    })
    .toOrderedMap();

  const newElementData = {
    ...element,
    value: filteredAssets,
  };

  return newElementData;
}

export const triggerAssetUploadFinishedEvents = (
  oldAssetId: Uuid,
  newAssetId: Uuid | null,
  element: ElementReference | null,
): void => {
  const detail: AssetUploadFinishedEventDetail = {
    oldAssetId,
    newAssetId,
    element,
  };

  const eventForLocalState = new AssetUploadFinishedEventForLocalState(detail);

  // The first event is meant for mounted components that update their state directly
  self.dispatchEvent(eventForLocalState);

  // If no component handled the event, we fire a fallback event so that assetUploadMiddleware can handle it
  if (!eventForLocalState.isPropagationStopped()) {
    const eventForGlobalState = new AssetUploadFinishedEventForGlobalState(detail);

    self.dispatchEvent(eventForGlobalState);
  }
};
