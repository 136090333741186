import { Collection } from '@kontent-ai/utils';
import { DefaultVariantId } from '../../../_shared/constants/variantIdValues.ts';
import { isCreatingRenditionsEnabled } from '../../../_shared/selectors/enhancedAssetManagement.ts';
import { getSelectedLanguageIdOrThrow } from '../../../_shared/selectors/getSelectedLanguageId.ts';
import { IStore } from '../../../_shared/stores/IStore.type.ts';
import {
  IValidationResult,
  isResultEmpty,
} from '../../../_shared/utils/validation/ValidationResult.ts';
import { getDefaultLanguage } from '../../../data/reducers/languages/selectors/getLanguages.ts';
import { getCurrentProjectPlan } from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { ElementType } from '../../contentInventory/content/models/ContentItemElementType.ts';
import {
  EditableTypeElement,
  TypeElement,
} from '../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import {
  isEditableElement,
  isNonLocalizableElement,
} from '../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { validElementWarningResult } from '../constants/validElementWarningResults.ts';
import { NonLocalizableElementPublishingWarning } from '../constants/warningMessageTemplates.ts';
import { isDefaultVariantPublishedOrForbidden } from '../features/ContentItemEditing/utils/itemValidationUtils.ts';
import { ICompiledContentItemElementData } from '../models/contentItemElements/ICompiledContentItemElement.ts';
import { associateResultWithTopLevelRichText } from '../reducers/utils/validationResultUtils.ts';
import {
  ItemElementErrorResult,
  emptyItemElementErrorResult,
} from './elementErrorCheckers/types/Errors.ts';
import {
  areElementContentLimitationsMet,
  createValidationResult,
  getItemElementValidationResult,
  isElementRequiredLimitMet,
} from './getItemElementValidationResult.ts';
import { isElementVisible } from './itemElementConditionUtils.ts';
import {
  ItemElementFriendlyWarningResult,
  emptyItemElementFriendlyWarningResult,
} from './itemElementFriendlyWarningCheckers/types/FriendlyWarnings.ts';
import { ItemElementWarningResult } from './itemElementWarningCheckers/types/Warnings.ts';

const getResultsWithAssociateTopLevelRichTextId = (
  results: IValidationResult,
  elementData: ICompiledContentItemElementData,
): IValidationResult => {
  if (elementData.type !== ElementType.RichText) {
    return results;
  }

  const { elementId } = elementData;

  const warnings = new Map<UuidPath, ItemElementWarningResult>(
    Collection.getEntries(results.warnings).map(([validationSelectorId, warning]) => [
      validationSelectorId,
      associateResultWithTopLevelRichText(warning, elementId),
    ]),
  );
  const friendlyWarnings = new Map<UuidPath, ItemElementFriendlyWarningResult>(
    Collection.getEntries(results.friendlyWarnings).map(
      ([validationSelectorId, friendlyWarning]) => [
        validationSelectorId,
        associateResultWithTopLevelRichText(friendlyWarning, elementId),
      ],
    ),
  );
  const errors = new Map<UuidPath, ItemElementErrorResult>(
    Collection.getEntries(results.errors).map(([validationSelectorId, error]) => [
      validationSelectorId,
      associateResultWithTopLevelRichText(error, elementId),
    ]),
  );

  return {
    warnings,
    friendlyWarnings,
    errors,
  };
};

export const getNonLocalizableElementValidationResult = (
  typeElement: EditableTypeElement,
  validationResult: IValidationResult,
  editedVariantId: Uuid,
  defaultLanguageName: string,
): IValidationResult => {
  const isEditingNonDefaultVariant = editedVariantId !== DefaultVariantId;

  if (typeElement.isNonLocalizable && isEditingNonDefaultVariant) {
    const elementErrors =
      validationResult.errors.get(typeElement.elementId) || emptyItemElementErrorResult;
    const elementWarnings =
      validationResult.warnings.get(typeElement.elementId) || validElementWarningResult;

    const isRequiredLimitMet = isElementRequiredLimitMet(elementWarnings);
    const areContentLimitationsMet = areElementContentLimitationsMet(elementWarnings);
    const hasValidationErrors = elementErrors.errorMessages.length > 0;

    if (!isRequiredLimitMet || !areContentLimitationsMet || hasValidationErrors) {
      const message = NonLocalizableElementPublishingWarning(defaultLanguageName);
      const warning = {
        limitationMessages: areContentLimitationsMet ? [] : [message],
        requiredMessage: isRequiredLimitMet ? null : message,
      };

      return createValidationResult(
        emptyItemElementErrorResult,
        warning,
        emptyItemElementFriendlyWarningResult,
        typeElement.elementId,
      );
    }
  }

  return validationResult;
};

const emptyItemValidationResults: {
  readonly warnings: ReadonlyArray<[UuidPath, ItemElementWarningResult]>;
  readonly friendlyWarnings: ReadonlyArray<[UuidPath, ItemElementFriendlyWarningResult]>;
  readonly errors: ReadonlyArray<[UuidPath, ItemElementErrorResult]>;
} = {
  warnings: [],
  friendlyWarnings: [],
  errors: [],
};

export const getItemValidationResult = (
  contentItemId: Uuid,
  variantElements: ReadonlyArray<ICompiledContentItemElementData>,
  typeElements: ReadonlyArray<TypeElement>,
  state: IStore,
): IValidationResult => {
  const {
    data: { taxonomyGroups, languages, listingContentItems, assets, assetRenditions },
    contentApp: { loadedContentItemTypes },
  } = state;

  const selectedLanguageId = getSelectedLanguageIdOrThrow(state);
  const areAssetRenditionsEnabled = isCreatingRenditionsEnabled(getCurrentProjectPlan(state));

  const editingDefaultVariant = selectedLanguageId === DefaultVariantId;
  const defaultLanguageName = getDefaultLanguage(state).name;
  const skipNonLocalizableElementValidation =
    !editingDefaultVariant && isDefaultVariantPublishedOrForbidden(state, contentItemId);

  const validationResults = variantElements.reduce((results, elementData) => {
    const elementId = elementData.elementId;
    const typeElement = typeElements.find((typeEl) => typeEl.elementId === elementId);

    const skipValidation =
      !isEditableElement(typeElement) ||
      (isNonLocalizableElement(typeElement) && skipNonLocalizableElementValidation) ||
      !isElementVisible(typeElement, variantElements);

    if (skipValidation) {
      return results;
    }

    const validationResult = getItemElementValidationResult(
      typeElement,
      elementData,
      loadedContentItemTypes,
      assets.byId,
      assetRenditions.byId,
      taxonomyGroups.byId,
      listingContentItems.byId,
      selectedLanguageId,
      languages.byId,
      areAssetRenditionsEnabled,
    );

    const withNonLocalizableValidationResults = getNonLocalizableElementValidationResult(
      typeElement,
      validationResult,
      selectedLanguageId,
      defaultLanguageName,
    );

    // We expect most of the elements OK most of the time, so there is no need for mutation in case the given element yields no results
    if (isResultEmpty(withNonLocalizableValidationResults)) {
      return results;
    }

    const withAssociations = getResultsWithAssociateTopLevelRichTextId(
      withNonLocalizableValidationResults,
      elementData,
    );

    return {
      warnings: [...results.warnings, ...withAssociations.warnings],
      friendlyWarnings: [...results.friendlyWarnings, ...withAssociations.friendlyWarnings],
      errors: [...results.errors, ...withAssociations.errors],
    };
  }, emptyItemValidationResults);

  return {
    warnings: new Map<UuidPath, ItemElementWarningResult>(validationResults.warnings),
    friendlyWarnings: new Map<UuidPath, ItemElementFriendlyWarningResult>(
      validationResults.friendlyWarnings,
    ),
    errors: new Map<UuidPath, ItemElementErrorResult>(validationResults.errors),
  };
};
