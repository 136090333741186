import Immutable from 'immutable';
import React from 'react';
import { useLocation, useParams } from 'react-router';
import { trackUserEventWithData } from '../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { ILinkVisitedIntercomData } from '../../../_shared/models/events/ContentItemEventData.type.ts';
import { parseQueryParams } from '../../../_shared/utils/routing/queryParamsUtils.ts';
import { editItemVariant } from '../actions/thunkSmartLinkActions.ts';
import { IEditItemVariantParameters } from '../actions/thunks/editItemVariant.ts';
import { SmartLink } from '../components/SmartLink.tsx';
import { EditItemRouteParams } from '../constants/routePaths.ts';

export const EditItemSmartLink: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { itemGuid, variantGuid, projectId } = useParams<EditItemRouteParams>();

  const onMount = () => {
    const linkId = parseQueryParams<{ linkId: string }>(location.search).linkId;
    const eventData: ILinkVisitedIntercomData = {
      'journey-stage': 'opened',
      itemId: itemGuid,
      linkId: linkId || '',
      projectId,
      variantId: variantGuid,
    };

    const editVariantData: IEditItemVariantParameters = {
      projectId,
      variantId: variantGuid,
      path: Immutable.List([{ itemId: itemGuid }]),
    };
    dispatch(trackUserEventWithData(TrackedEvent.LinkVisited, eventData));
    dispatch(editItemVariant(editVariantData));
  };

  return <SmartLink onMount={onMount} />;
};

EditItemSmartLink.displayName = 'EditItemSmartLink';
