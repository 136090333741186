import { BaseColor } from '@kontent-ai/component-library/tokens';
import { rgba } from '@kontent-ai/component-library/utils';
import { delay } from '@kontent-ai/utils';
import classNames from 'classnames';
import { DraftStyleMap, EditorState, SelectionState } from 'draft-js';
import { useCallback, useMemo, useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import {
  HighlightStyle,
  RichTextHighlighter,
} from '../../components/utility/RichTextHighlighter.tsx';
import { baseEditorApi } from '../../editorCore/api/baseEditorApi.ts';
import { bindApiMethods } from '../../editorCore/hooks/bindApiMethods.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginApi } from '../../editorCore/types/Editor.api.type.ts';
import {
  ApplyEditorStateChanges,
  CanUpdateContent,
} from '../../editorCore/types/Editor.base.type.ts';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { DecoratedEditor } from '../../editorCore/types/Editor.decorated.type.ts';
import {
  Apply,
  EditorPlugin,
  PluginState,
  Render,
} from '../../editorCore/types/Editor.plugins.type.ts';
import { EditorChangeReason } from '../../editorCore/types/EditorChangeReason.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import { withDisplayName } from '../../editorCore/utils/withDisplayName.ts';
import { UnlimitedEditorLimitations } from '../apiLimitations/api/EditorFeatureLimitations.ts';
import { createEditorLimitationsApi } from '../apiLimitations/api/editorLimitationsApi.ts';
import { InlineStylesPlugin } from '../inlineStyles/InlineStylesPlugin.tsx';
import { editorInlineStyleApi } from '../inlineStyles/api/editorInlineStyleApi.ts';
import { DraftJSInlineStyle } from '../inlineStyles/api/inlineStyles.ts';
import { editorCommandApi } from '../keyboardShortcuts/api/editorCommandApi.ts';
import { editorTextApi } from '../textApi/api/editorTextApi.ts';
import { CanDisplayInlineToolbar, InlineToolbarPlugin } from '../toolbars/InlineToolbarPlugin.tsx';
import { editorUndoRedoApi } from '../undoRedo/api/editorUndoRedoApi.ts';
import { StylesPlugin } from '../visuals/StylesPlugin.tsx';
import {
  HighlightedBlockKeys,
  getHighlightedBlockKeys,
} from '../visuals/utils/editorHighlightUtils.ts';
import { FocusPlugin } from './FocusPlugin.tsx';

const selectionHighlightClass = 'rte__highlight-selection';

const HighlightStyles = {
  [DraftJSInlineStyle.SelectionHighlight]: {
    className: selectionHighlightClass,
  },
};

type LockedState = {
  readonly editorState: EditorState;
  readonly highlightedBlockKeys: HighlightedBlockKeys;
};

type EditorWithHighlightProps = {
  readonly lockedState: LockedState | null;
};

const selectionHighlightStyle: HighlightStyle = {
  colorSuffix: '-highlight',
  cssProperty: 'background-image',
  cssSelectionSelector: `.${selectionHighlightClass}`,
};

const selectionHighlightColor = rgba(BaseColor.PersianGreen60, 0.2);

const GlobalSelectionHighlightStyles = createGlobalStyle`
  :root {
    --element-selection-bg-color-highlight: ${selectionHighlightColor};
    --block-selection-bg-color-highlight: ${selectionHighlightColor};
    --text-selection-bg-color-highlight: ${selectionHighlightColor};
    --none-selection-bg-color-highlight: transparent;
  }

  .rte__highlight-selection {
    // The next prop is not background-color in order to mix with comment backgrounds.
    // see CommentThreadHighlighter and FocusedCommentThreadHighlighter
    background-image: linear-gradient(var(--text-selection-bg-color-highlight), var(--text-selection-bg-color-highlight));
  }
`;

const EditorWithLockedState: DecoratedEditor<LockEditorPlugin, EditorWithHighlightProps> = ({
  baseRender,
  lockedState,
  state,
}) => {
  const {
    editorProps: { customStyleMap: parentCustomStyleMap },
  } = state;

  const customStyleMap = useMemo(
    () =>
      ({
        ...parentCustomStyleMap,
        ...HighlightStyles,
      }) as DraftStyleMap,
    [parentCustomStyleMap],
  );

  const stateWithLockedState: PluginState<LockEditorPlugin> = lockedState
    ? {
        ...state,
        editorProps: {
          ...state.editorProps,
          // Render the locked state with extra styles for highlight
          customStyleMap,
          editorState: lockedState.editorState,
          // Disable the spell check, as Grammarly may overlap toolbars
          spellCheck: false,
        },
        rteInputProps: {
          ...state.rteInputProps,
          // Use disabled styles to make sure the caret doesn't show in the locked editor
          className: classNames(state.rteInputProps.className, 'rte__content--is-disabled'),
        },
      }
    : state;

  return (
    <>
      {lockedState && (
        <>
          <GlobalSelectionHighlightStyles />
          <RichTextHighlighter
            editorId={state.getEditorId()}
            style={selectionHighlightStyle}
            {...lockedState.highlightedBlockKeys}
          />
        </>
      )}
      {baseRender(stateWithLockedState)}
    </>
  );
};

EditorWithLockedState.displayName = 'EditorWithLockedState';

/**
 * It prohibits editor state changes, highlights the current selection and closes the inline toolbar.
 */
export type LockEditor = (editorState: EditorState) => Promise<void>;

/**
 * It allows editor state changes, resets the selection highlight and reopens the inline toolbar.
 */
export type UnlockEditor = () => void;

type LockEditorPluginState = {
  readonly lockEditor: LockEditor;
  readonly unlockEditor: UnlockEditor;
};

export type LockEditorPlugin = EditorPlugin<
  LockEditorPluginState,
  None,
  None,
  [FocusPlugin, StylesPlugin, InlineToolbarPlugin]
>;

// We use a local instance of the editor API, as locked state is a non-editing branch of the editor state that gets dropped eventually
const api: PluginApi<InlineStylesPlugin> = bindApiMethods({
  ...baseEditorApi,
  ...editorUndoRedoApi,
  ...createEditorLimitationsApi(UnlimitedEditorLimitations),
  ...editorCommandApi,
  ...editorTextApi,
  ...editorInlineStyleApi,
});

export const useLockEditor: PluginCreator<LockEditorPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('LockEditorPlugin', {
        ComposedEditor: (props) => {
          const [lockedState, setLockedState] = useState<LockedState | null>(null);

          const render: Decorator<Render<LockEditorPlugin>> = useCallback(
            (baseRender) => (state) => (
              <EditorWithLockedState
                baseRender={baseRender}
                state={state}
                lockedState={lockedState}
              />
            ),
            [lockedState],
          );

          const lockEditor = useCallback(async (editorState: EditorState) => {
            const selection = editorState.getSelection();
            const content = editorState.getCurrentContent();

            const lockedEditorState = api.applyInlineStyle(
              editorState,
              DraftJSInlineStyle.SelectionHighlight,
            );
            const lockedHighlightedBlockKeys = getHighlightedBlockKeys(content, selection);
            setLockedState({
              editorState: lockedEditorState,
              highlightedBlockKeys: lockedHighlightedBlockKeys,
            });
            // We let the editor re-render before we consider it fully locked, as the lock may cause it to remount some of its nodes
            // due to applying highlight, and we need the DOM selection after lock to update to the newly rendered DOM tree
            await delay(0);
          }, []);

          const unlockEditor = useCallback(() => setLockedState(null), []);

          const updateLockedEditor = useCallback(
            (newSelection: SelectionState, forceSelection: boolean) =>
              setLockedState(
                (prev) =>
                  prev && {
                    ...prev,
                    editorState: forceSelection
                      ? EditorState.forceSelection(prev.editorState, newSelection)
                      : EditorState.acceptSelection(prev.editorState, newSelection),
                  },
              ),
            [],
          );

          const canDisplayInlineToolbar: Decorator<CanDisplayInlineToolbar> = useCallback(
            (baseCanDisplayInlineToolbar) => (editorState) =>
              !lockedState && baseCanDisplayInlineToolbar(editorState),
            [lockedState],
          );

          const allowChanges = useCallback(
            (changeReason?: EditorChangeReason) =>
              changeReason === EditorChangeReason.Internal || !lockedState,
            [lockedState],
          );

          const canUpdateContent: Decorator<CanUpdateContent> = useCallback(
            (baseCanUpdateContent) => (changeReason) =>
              allowChanges(changeReason) && baseCanUpdateContent(changeReason),
            [allowChanges],
          );

          const applyEditorStateChanges: Decorator<ApplyEditorStateChanges> = useCallback(
            (baseApplyEditorStateChanges) => (params) => {
              // When the editor is locked, we only allow selection changes on it and ignore any changes to the content.
              // Having said that, we still need to let through internal changes from the finishing actions before the editor is unlocked.
              if (!allowChanges(params.changeReason) && lockedState) {
                const newSelection = params.newState.getSelection();
                // We only force the selection to the locked state in case the new state is forcing it (needs re-render with DOM selection update)
                // Otherwise when regaining focus while doing the selection with mouse, the editor might programmatically
                // update the DOM selection via re-rendering, unexpectedly extending the mouse selection towards the earlier selection
                updateLockedEditor(newSelection, params.newState.mustForceSelection());

                // We also apply the new selection to the original editor state, because some actions such as copy to clipboard may need it
                // Also, when the editor gets unlocked, it will continue from the last selection
                return EditorState.forceSelection(params.oldState, newSelection);
              }

              return baseApplyEditorStateChanges(params);
            },
            [allowChanges, updateLockedEditor, lockedState],
          );

          const apply: Apply<LockEditorPlugin> = useCallback(
            (state) => {
              state.render.decorate(render);
              state.canDisplayInlineToolbar.decorate(canDisplayInlineToolbar);
              state.canUpdateContent.decorate(canUpdateContent);
              state.applyEditorStateChanges.decorate(applyEditorStateChanges);

              return {
                lockEditor,
                unlockEditor,
              };
            },
            [
              applyEditorStateChanges,
              canDisplayInlineToolbar,
              canUpdateContent,
              lockEditor,
              render,
              unlockEditor,
            ],
          );

          return useEditorWithPlugin(baseEditor, props, { apply });
        },
      }),
    [baseEditor],
  );
