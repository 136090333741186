import React, { useEffect, useRef } from 'react';
import { Route, Switch } from 'react-router';
import { HtmlPageTitle } from '../../../../../_shared/components/HtmlPageTitle.tsx';
import { Loader } from '../../../../../_shared/components/Loader.tsx';
import { AppNames } from '../../../../../_shared/constants/applicationNames.ts';
import {
  ContentItemRevisionCompareRoute,
  ContentItemRevisionCompareRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import {
  IRouteContext,
  RouteContext,
} from '../../../../../_shared/containers/routing/RouteContext.tsx';
import { EditorPaperContextProvider } from '../../../../../_shared/contexts/EditorPaperContext.tsx';
import { IntersectionObserverRootContextProvider } from '../../../../../_shared/hooks/IntersectionObserverRootContext.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IEditedContentItem } from '../../../models/contentItem/edited/EditedContentItem.ts';
import { EditedContentItemVariant } from '../../../models/contentItem/edited/EditedContentItemVariant.ts';
import { EditedContentItemContextProvider } from '../../ContentComponent/context/ContentItemContext.tsx';
import { ContentItemNotificationsWrapper } from '../../ContentItemEditing/components/ContentItemNotifications.tsx';
import { EnsureComparedRevision } from '../../ContentItemEditing/containers/EnsureComparedRevision.tsx';
import { EnsureSelectedRevision } from '../../ContentItemEditing/containers/EnsureSelectedRevision.tsx';
import { ItemLiveUsersNotificationBar } from '../../ContentItemEditing/containers/contentItemEditorNotifications/ItemLiveUsersNotificationBar.tsx';
import { RevisionCompareTimeline } from '../containers/RevisionCompareTimeline.tsx';
import { RevisionTimeline } from '../containers/RevisionTimeline.tsx';
import { RevisionContent } from './RevisionContent.tsx';

export interface IRevisionViewerStateProps {
  readonly editedContentItem: IEditedContentItem | null;
  readonly editedContentItemVariant: EditedContentItemVariant | null;
  readonly showComparisonKey: boolean;
}

export interface IRevisionViewerDispatchProps {
  readonly onLeave: (editedItemId?: Uuid) => void;
}

export interface IRevisionViewerOwnProps {
  readonly timelineItemId: Uuid;
}

type RevisionViewerProps = IRevisionViewerStateProps &
  IRevisionViewerDispatchProps &
  IRevisionViewerOwnProps;

export const RevisionViewer: React.FC<RevisionViewerProps> = ({
  editedContentItem,
  editedContentItemVariant,
  showComparisonKey,
  timelineItemId,
  onLeave,
}) => {
  const contentItemCanvas = useRef<HTMLDivElement>(null);
  const editedContentItemId = editedContentItem?.id;

  useEffect(() => () => onLeave(editedContentItemId), [onLeave, editedContentItemId]);

  if (!editedContentItem || !editedContentItemVariant) {
    return <Loader />;
  }

  return (
    <div className="canvas">
      <HtmlPageTitle
        appName={AppNames.ContentItemRevisionViewer}
        customName={editedContentItem.name}
      />
      <EditedContentItemContextProvider>
        <IntersectionObserverRootContextProvider rootRef={contentItemCanvas}>
          <div className="canvas__workspace" ref={contentItemCanvas}>
            <EditorPaperContextProvider>
              <section
                className="canvas__content content-item-editor"
                {...getDataUiElementAttribute(DataUiElement.ContentItemEditor)}
              >
                <EnsureSelectedRevision
                  key={timelineItemId}
                  contentItemId={editedContentItem.id}
                  variantId={editedContentItemVariant.id.variantId}
                  timelineItemId={timelineItemId}
                >
                  <ContentItemNotificationsWrapper>
                    <div className="content-item-editor__notifications">
                      <ItemLiveUsersNotificationBar showUserNamesInMessage />
                    </div>
                  </ContentItemNotificationsWrapper>
                  <div className="content-item-pane__canvas">
                    <Switch>
                      <Route exact path={ContentItemRevisionCompareRoute}>
                        <RouteContext>
                          {({
                            match,
                          }: IRouteContext<ContentItemRevisionCompareRouteParams<string>>) => (
                            <EnsureComparedRevision
                              key={match.params.compareItemId}
                              contentItemId={editedContentItem.id}
                              variantId={editedContentItemVariant.id.variantId}
                              comparedTimelineId={match.params.compareItemId}
                            >
                              <RevisionContent showComparisonKey={showComparisonKey} />
                            </EnsureComparedRevision>
                          )}
                        </RouteContext>
                      </Route>
                      <Route>
                        <RevisionContent showComparisonKey={showComparisonKey} />
                      </Route>
                    </Switch>
                  </div>
                </EnsureSelectedRevision>
              </section>
            </EditorPaperContextProvider>
            <section className="canvas__sidebar">
              <Switch>
                <Route path={ContentItemRevisionCompareRoute}>
                  <RevisionCompareTimeline />
                </Route>
                <Route>
                  <RevisionTimeline />
                </Route>
              </Switch>
            </section>
          </div>
        </IntersectionObserverRootContextProvider>
      </EditedContentItemContextProvider>
    </div>
  );
};

RevisionViewer.displayName = 'RevisionViewer';
