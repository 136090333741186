import classNames from 'classnames';
import React from 'react';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import { Icon } from '../../../../../_shared/uiComponents/Icon/Icon.tsx';

type Props = {
  readonly isInTooltip?: boolean;
  readonly onClick: () => void;
};

export const AssetFolderHomeBreadcrumbItem: React.FC<Props> = ({ isInTooltip, onClick }) => (
  <div
    className={classNames(
      'asset-breadcrumbs__item',
      'asset-breadcrumbs__item--is-clickable',
      'asset-breadcrumbs__item--is-first',
      {
        'asset-breadcrumbs__item--is-in-tooltip': isInTooltip,
      },
    )}
    onClick={onClick}
  >
    <Icon iconName={IconName.Home} className="asset-breadcrumbs__item-icon" />
    <span className="asset-breadcrumbs__item-name">Assets</span>
  </div>
);

AssetFolderHomeBreadcrumbItem.displayName = 'AssetFolderHomeBreadcrumbItem';
