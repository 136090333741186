import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { IconButton } from '@kontent-ai/component-library/Button';
import { InputState, InputType } from '@kontent-ai/component-library/Input';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { useState } from 'react';
import { UseFieldArrayRemove, useController, useWatch } from 'react-hook-form';
import { ValidatedInput } from '../../../../_shared/components/input/ValidatedInput.tsx';
import { documentationLinks } from '../../../../_shared/constants/documentationLinks.ts';
import { HookFormProps } from '../../../../_shared/types/hookFormProps.ts';
import {
  DataUiAction,
  DataUiInput,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IEntityWebhookFormShape } from '../../models/IEntityWebhookFormShape.type.ts';
import { EntityWebhookValidationErrorWithLink } from './EntityWebhookValidationErrorWithLink.tsx';

type EntityWebhookCustomHeaderProps = {
  readonly enabled: boolean;
  readonly formProps: HookFormProps<IEntityWebhookFormShape>;
  readonly index: number;
  readonly remove: UseFieldArrayRemove;
};

const keyPattern = /^[a-zA-Z0-9_-]+$/;
const valuePattern = /^[a-zA-Z0-9_\- :;.,\\/""'?!(){}[\]@<>=+*#$&`|~^%]+$/;

const reservedHeaderKeys: string[] = [
  'host',
  'content-length',
  'x-kontent-ai-signature',
  'request-context',
  'request-id',
  'traceparent',
  'content-type',
];

const reservedKeyPrefix = 'x-kontent-ai';

export const EntityWebhookCustomHeader = ({
  enabled,
  formProps,
  index,
  remove,
}: EntityWebhookCustomHeaderProps) => {
  const [isDisplayed, setIsDisplayed] = useState(false);
  const { control } = formProps;
  const { formState } = useController({
    control,
    name: `headers.${index}`,
  });
  const watch = useWatch({
    control,
    name: `headers.${index}`,
  });

  const keyTouched = formState.touchedFields.headers?.[index]?.key ?? false;
  const valueTouched = formState.touchedFields.headers?.[index]?.value ?? false;

  const isKeyEmpty: boolean = keyTouched && watch.key.length === 0;
  const isKeyInvalid: boolean = keyTouched && !isKeyEmpty && !keyPattern.test(watch.key);
  const isKeyReserved: boolean =
    keyTouched &&
    !isKeyEmpty &&
    (reservedHeaderKeys.includes(watch.key) || watch.key.startsWith(reservedKeyPrefix));

  const isValueEmpty: boolean = valueTouched && watch.value.length === 0;
  const isValueInvalid: boolean = valueTouched && !isValueEmpty && !valuePattern.test(watch.value);

  const keyInputState = enabled
    ? isKeyEmpty || isKeyInvalid || isKeyReserved
      ? InputState.Alert
      : InputState.Default
    : InputState.Disabled;

  const valueInputState = enabled
    ? isValueEmpty || isValueInvalid
      ? InputState.Alert
      : InputState.Default
    : InputState.Disabled;

  return (
    <Stack spacing={Spacing.XL}>
      <Row spacingX={Spacing.M} noWrap>
        <Column flexBasis={0} flexFactor={1}>
          <ValidatedInput<IEntityWebhookFormShape>
            name={`headers.${index}.key`}
            dataUiInputName={DataUiInput.HeaderKey}
            inputState={keyInputState}
            formProps={formProps}
            maxLength={200}
            placeholder=""
          />
          {isKeyEmpty && (
            <EntityWebhookValidationErrorWithLink>
              Provide a header key.
            </EntityWebhookValidationErrorWithLink>
          )}
          {isKeyInvalid && (
            <EntityWebhookValidationErrorWithLink>
              The provided header key contains a forbidden character. Check the{' '}
              <OutwardLink href={documentationLinks.customHeaders}>
                rules for custom headers
              </OutwardLink>
              .
            </EntityWebhookValidationErrorWithLink>
          )}
          {isKeyReserved && (
            <EntityWebhookValidationErrorWithLink>
              This header key is reserved. Provide another key. Check the{' '}
              <OutwardLink href={documentationLinks.customHeaders}>
                rules for custom headers
              </OutwardLink>
              .
            </EntityWebhookValidationErrorWithLink>
          )}
        </Column>
        <Column flexBasis={0} flexFactor={1}>
          <ValidatedInput<IEntityWebhookFormShape>
            name={`headers.${index}.value`}
            dataUiInputName={DataUiInput.HeaderValue}
            inputState={valueInputState}
            formProps={formProps}
            maxLength={2000}
            placeholder=""
            type={isDisplayed ? InputType.Text : InputType.Password}
          />
          {isValueEmpty && (
            <EntityWebhookValidationErrorWithLink>
              Provide a header value.
            </EntityWebhookValidationErrorWithLink>
          )}
          {isValueInvalid && (
            <EntityWebhookValidationErrorWithLink>
              The provided header value contains a forbidden character. Check the &nbsp;
              <OutwardLink href={documentationLinks.customHeaders}>
                rules for custom headers
              </OutwardLink>
              .
            </EntityWebhookValidationErrorWithLink>
          )}
        </Column>
        <Column width="fit-content">
          <IconButton
            tooltipText={isDisplayed ? 'Show me this value' : 'Hide this value'}
            tooltipPlacement="top"
            iconName={isDisplayed ? 'EyeSlash' : 'Eye'}
            size="medium"
            buttonStyle="tertiary"
            onClick={() => setIsDisplayed(!isDisplayed)}
            {...getDataUiActionAttribute(DataUiAction.RemoveRoleBuilderRow)}
          />
        </Column>
        <Column width="fit-content">
          <IconButton
            buttonState={enabled ? 'default' : 'disabled'}
            tooltipText="Delete header"
            tooltipPlacement="top"
            iconName="Bin"
            size="medium"
            buttonStyle="tertiary"
            onClick={() => remove(index)}
            {...getDataUiActionAttribute(DataUiAction.RemoveRoleBuilderRow)}
          />
        </Column>
      </Row>
    </Stack>
  );
};
