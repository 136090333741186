import React, { useContext } from 'react';
import { Route } from 'react-router';
import { useThrottledCallback } from 'use-debounce';
import useResizeObserver from 'use-resize-observer';
import { ContentItemPreviewWithEditorRoute } from '../../../../_shared/constants/routePaths.ts';
import { WebSpotlightContext } from '../../context/WebSpotlightContext.tsx';
import { FloatingEditorWrapper } from './FloatingEditor/FloatingEditorWrapper.tsx';
import { WebSpotlightSmartPreview } from './WebSpotlightSmartPreview.tsx';

const rescaleThrottleIntervalMs = 200;

export const WebSpotlightPreview: React.FC = () => {
  const { previewPaneRef, rescalePreviewToFit } = useContext(WebSpotlightContext);
  const onResize = useThrottledCallback(rescalePreviewToFit, rescaleThrottleIntervalMs);

  useResizeObserver({
    ref: previewPaneRef,
    onResize,
  });

  return (
    <div className="web-spotlight-preview">
      <div
        ref={previewPaneRef}
        className="web-spotlight-preview__content web-spotlight__layout-guard"
      >
        <WebSpotlightSmartPreview />
      </div>
      <Route path={ContentItemPreviewWithEditorRoute}>
        <FloatingEditorWrapper />
      </Route>
    </div>
  );
};

WebSpotlightPreview.displayName = 'WebSpotlightPreview';
