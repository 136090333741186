import React from 'react';
import { ModalDialog } from '../../../../../_shared/components/ModalDialog/ModalDialog.tsx';
import { Warning } from '../../../../../_shared/components/infos/Warning.tsx';
import { Button } from '../../../../../_shared/uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import {
  DataUiAction,
  DataUiCollection,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ILanguage } from '../../../../../data/models/languages/Language.ts';

type LanguageDeactivationModalProps = {
  readonly areLanguageRolesEnabled: boolean;
  readonly language: ILanguage;
  readonly onCancel: () => void;
  readonly onConfirm: () => void;
};

export const LanguageDeactivationModal: React.FC<LanguageDeactivationModalProps> = ({
  areLanguageRolesEnabled,
  language,
  onCancel,
  onConfirm,
}) => (
  <ModalDialog
    headerContent={`${language.name} deactivation`}
    bodyContent={
      <div>
        <Warning subtitle={`${language.name} language may have published content`}>
          <p>
            If there’s published content in {language.name}, deactivating the language will
            unpublish this content.
          </p>
          <p>
            The unpublished content will be removed from the Delivery API. This action may break
            your app.
          </p>
        </Warning>
        {areLanguageRolesEnabled && (
          <Warning subtitle="Users may lose access to the project">
            <p>
              Users whose roles are limited to {language.name} language won’t be able to access the
              project.
            </p>
          </Warning>
        )}
      </div>
    }
    onClose={onCancel}
    footerContentRight={
      <div {...getDataUiCollectionAttribute(DataUiCollection.WarningMessageActions)}>
        <Button
          style={ButtonStyle.Secondary}
          onClick={onCancel}
          {...getDataUiActionAttribute(DataUiAction.Cancel)}
        >
          Cancel
        </Button>
        <Button
          style={ButtonStyle.Primary}
          onClick={onConfirm}
          {...getDataUiActionAttribute(DataUiAction.Deactivate)}
        >
          Deactivate
        </Button>
      </div>
    }
  />
);

LanguageDeactivationModal.displayName = 'LanguageDeactivationModal';
