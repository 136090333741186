import React from 'react';
import { useHistory } from 'react-router';
import { modalDismissed } from '../../../../../../_shared/actions/sharedActions.ts';
import { trackUserEventWithData } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { ConfirmationDialog } from '../../../../../../_shared/components/ModalDialog/ConfirmationDialog.tsx';
import { AppNames } from '../../../../../../_shared/constants/applicationNames.ts';
import { Archive } from '../../../../../../_shared/constants/itemActions.ts';
import { ModalDialogType } from '../../../../../../_shared/constants/modalDialogType.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { ConfirmationDialogEventTypes } from '../../../../../../_shared/models/TrackUserEventData.ts';
import { getModalDialogProperties } from '../../../../../../_shared/selectors/getModalDialogProperties.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { getCurrentUserRoleForCollectionForLanguage } from '../../../../../../_shared/utils/permissions/getContributorRole.ts';
import { canRoleDoSomethingInStep } from '../../../../../../_shared/utils/permissions/roleInWorkflowStepUtils.ts';
import { getWorkflow } from '../../../../../../data/reducers/workflow/selectors/workflowSelectors.ts';
import { moveItemToArchivedStep } from '../../actions/thunkContentItemEditingActions.ts';

const getModalProps = getModalDialogProperties(
  ModalDialogType.MoveItemToArchivedStepConfirmationDialog,
);

const isRestoreFromArchivedStepPossible = (state: IStore): boolean => {
  const collectionId = state.contentApp.editedContentItem?.collectionId;
  const languageId = getSelectedLanguageIdOrThrow(state);
  const userRole =
    collectionId && getCurrentUserRoleForCollectionForLanguage(state, collectionId, languageId);
  const workflowId = getModalProps(state)?.workflowId;
  const currentWorkflow = workflowId && getWorkflow(state, workflowId);

  return (
    !!userRole &&
    !!currentWorkflow &&
    canRoleDoSomethingInStep(userRole.id, currentWorkflow.archivedStep.id, currentWorkflow)
  );
};

export const MoveItemToArchivedStepConfirmationDialog: React.FC = () => {
  const canRestoreItemFromArchivedStep = useSelector(isRestoreFromArchivedStepPossible);

  const history = useHistory();
  const dispatch = useDispatch();

  const onCancel = () =>
    dispatch(
      trackUserEventWithData(TrackedEvent.MoveItemToArchivedStepConfirmationDialog, {
        action: ConfirmationDialogEventTypes.Cancel,
      }),
    );

  const onClose = () => dispatch(modalDismissed());
  const onConfirm = () => dispatch(moveItemToArchivedStep(history));

  return (
    <ConfirmationDialog
      confirmButtonIcon="Box"
      confirmButtonText={Archive}
      headerContent="Archive this item"
      onCancel={onCancel}
      onClose={onClose}
      onConfirm={onConfirm}
    >
      <p className="confirmation-dialog__section">
        Archiving makes the item read-only. Its workflow step changes to Archived.
      </p>
      <p className="confirmation-dialog__section">
        You can still find the item in {AppNames.Content}
        {canRestoreItemFromArchivedStep && ' and restore it if needed'}.
      </p>
    </ConfirmationDialog>
  );
};

MoveItemToArchivedStepConfirmationDialog.displayName = 'MoveItemToArchivedStepConfirmationDialog';
