import React from 'react';
import {
  EnsureValidState,
  IEnsureValidStateOwnProps,
} from '../../../../_shared/components/EnsureValidState.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { apiKeysSettingsLeft } from '../actions/apiKeysActions.ts';
import { initApiKeysSettings } from '../actions/thunkApiKeysActions.ts';
import { isApiKeysSettingsInitialized } from '../selectors/apiKeysLoadedSelectors.ts';

export const EnsureApiKeysSettingsInitialized: React.FC<
  React.PropsWithChildren<IEnsureValidStateOwnProps>
> = ({ children }) => {
  const isStateInitialized = useSelector(isApiKeysSettingsInitialized);
  const dispatch = useDispatch();

  return (
    <EnsureValidState
      isStateEnsured={isStateInitialized}
      onRouteEntered={() => dispatch(initApiKeysSettings())}
      onRouteLeft={() => dispatch(apiKeysSettingsLeft())}
    >
      {children}
    </EnsureValidState>
  );
};

EnsureApiKeysSettingsInitialized.displayName = 'EnsureApiKeysSettingsInitialized';
