import Immutable from 'immutable';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { LinkIsInvalidMessage } from '../../constants/uiConstants.ts';
import { variantService } from '../../services/variantService.ts';
import { handleUnsuccessfulRedirect } from '../smartLinkActions.ts';
import { editItemVariant } from '../thunkSmartLinkActions.ts';
import { IItemElement } from './editItemVariant.ts';

export const editItemVariantCodenameByPath =
  (
    projectId: string,
    variantCodename: string,
    path: Immutable.List<IItemElement>,
    abortSignal?: AbortSignal,
  ): ThunkPromise =>
  async (dispatch) => {
    const variantId = await variantService.getVariantIdFromCodename(
      variantCodename,
      projectId,
      abortSignal,
    );

    if (variantId) {
      await dispatch(
        editItemVariant(
          {
            projectId,
            variantId,
            path,
          },
          abortSignal,
        ),
      );
    } else {
      dispatch(handleUnsuccessfulRedirect(null, LinkIsInvalidMessage, null));
    }
  };
