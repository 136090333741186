import React, { useRef, useState } from 'react';
import { usePopover } from '../../../../../../component-library/components/Dialogs/Popover/usePopover.tsx';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import { SquareButton } from '../../../../../_shared/uiComponents/Button/SquareButton.tsx';
import { ButtonStyle } from '../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import { SquareButtonSize } from '../../../../../_shared/uiComponents/Button/squareButtonSize.ts';
import { ITaxonomyTerm } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyTerm.ts';
import { AssetFoldersBreadcrumbsTooltip } from './AssetFoldersBreadcrumbsTooltip.tsx';

type Props = {
  readonly onFolderOpen: (folderId: Uuid) => void;
  readonly onFolderReset: () => void;
  readonly openedFolders: Immutable.List<ITaxonomyTerm>;
};

export const AssetFolderEllipsisBreadcrumbItem: React.FC<Props> = ({
  onFolderOpen,
  onFolderReset,
  openedFolders,
}) => {
  const [isDialogShown, setIsDialogShown] = useState(false);

  const openDialog = (): void => setIsDialogShown(true);
  const closeDialog = (): void => setIsDialogShown(false);

  const openFolderFromDialog = (id: Uuid): void => {
    onFolderOpen(id);
    closeDialog();
  };

  const targetRef = useRef<HTMLElement>(null);
  const tooltipText = 'Expand entire hierarchy';

  const { Popover, popoverProps, targetProps } = usePopover({
    isOpen: isDialogShown,
    onClose: closeDialog,
    shouldCloseOnInteractOutside: (element) => !targetRef.current?.contains(element),
    targetRef,
  });

  return (
    <>
      <SquareButton
        {...targetProps}
        className="asset-breadcrumbs__ellipsis-item"
        iconClass="asset-breadcrumbs__item-icon asset-breadcrumbs__ellipsis-icon"
        iconName={IconName.Ellipsis}
        isActive={isDialogShown}
        onClick={isDialogShown ? closeDialog : openDialog}
        screenReaderText={tooltipText}
        size={SquareButtonSize.Quinary}
        style={ButtonStyle.Quinary}
        tooltipPlacement="bottom"
        tooltipText={tooltipText}
      />
      <Popover {...popoverProps}>
        <AssetFoldersBreadcrumbsTooltip
          onFolderOpen={openFolderFromDialog}
          onFolderReset={onFolderReset}
          openedFolders={openedFolders}
        />
      </Popover>
    </>
  );
};

AssetFolderEllipsisBreadcrumbItem.displayName = 'AssetFolderEllipsisBreadcrumbItem';
