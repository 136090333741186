import { ContentState, DraftDecorator, EditorState } from 'draft-js';
import React from 'react';
import { DeepSealed } from '../utils/decorable.ts';
import { StateWithApi } from './Editor.api.type.ts';
import { BaseEditor } from './Editor.base.type.ts';
import {
  Api,
  Contract,
  None,
  Props,
  RequiredApi,
  RequiredProps,
  RequiredState,
  State,
} from './Editor.contract.type.ts';

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void
  ? I
  : never;

export type Combine<C1 extends BaseEditor, C2 extends Contract> = [
  state: State<C1> & State<C2>,
  props: Props<C1> & Props<C2>,
  api: Api<C1> & Api<C2>,
  requiredState: RequiredState<C1> & RequiredState<C2>,
  requiredProps: RequiredProps<C1> & RequiredProps<C2>,
  requiredApi: RequiredApi<C1> & RequiredApi<C2>,
];

type RequiredStateFromPlugins<Contracts extends ReadonlyArray<Contract>> = [
  State<Contracts[number]>,
  RequiredState<Contracts[number]>,
] extends [infer States, infer RequiredStates]
  ? UnionToIntersection<States> & UnionToIntersection<RequiredStates>
  : never;

type RequiredPropsFromPlugins<Contracts extends ReadonlyArray<Contract>> = [
  Props<Contracts[number]>,
  RequiredProps<Contracts[number]>,
] extends [infer Propses, infer RequiredPropses]
  ? UnionToIntersection<Propses> & UnionToIntersection<RequiredPropses>
  : never;

type RequiredApiFromPlugins<Contracts extends ReadonlyArray<Contract>> = [
  Api<Contracts[number]>,
  RequiredApi<Contracts[number]>,
] extends [infer Apis, infer RequiredApis]
  ? UnionToIntersection<Apis> & UnionToIntersection<RequiredApis>
  : never;

export type EditorPlugin<
  TState extends None = None,
  TProps extends None = None,
  TApi extends None = None,
  TRequiredPlugins extends Contract[] = Contract[],
> = [
  state: TState,
  props: TProps,
  api: TApi,
  requiredState: RequiredStateFromPlugins<TRequiredPlugins>,
  requiredProps: RequiredPropsFromPlugins<TRequiredPlugins>,
  requiredApi: RequiredApiFromPlugins<TRequiredPlugins>,
];

type DecorablePluginState<
  TPlugin extends Contract = Contract,
  TEditor extends BaseEditor = BaseEditor,
> = StateWithApi<State<TEditor> & State<TPlugin> & RequiredState<TPlugin>, TPlugin, TEditor>;

export type PluginState<
  TPlugin extends Contract = Contract,
  TEditor extends BaseEditor = BaseEditor,
> = DeepSealed<DecorablePluginState<TPlugin, TEditor>>;

export type PluginProps<
  TPlugin extends Contract = Contract,
  TEditor extends BaseEditor = BaseEditor,
> = Props<TEditor> & Props<TPlugin> & RequiredProps<TPlugin>;

export type Render<
  TPlugin extends Contract,
  TArgs extends any[] = [],
  TEditor extends BaseEditor = BaseEditor,
> = (state: PluginState<TPlugin, TEditor>, ...args: TArgs) => React.ReactElement | null;

type DeepUpdateRender<TPossibleRender, TNewState extends None> = TPossibleRender extends Render<
  Contract,
  infer TArgs
>
  ? (state: TNewState, ...args: TArgs) => React.ReactElement | null
  : TPossibleRender extends ReadonlyRecord<string, unknown>
    ? { readonly [P in keyof TPossibleRender]: DeepUpdateRender<TPossibleRender[P], TNewState> }
    : TPossibleRender;

export enum UndoState {
  // We keep the enum number-based be able to compare and take the best/worst
  DisabledDropHistory = 0,
  DisabledKeepHistory = 1,
  EnabledKeepHistory = 2,
}

export type InitState = {
  readonly initialEditorState: EditorState;
  readonly content: ContentState;
  readonly decorators: ReadonlyArray<DraftDecorator>;
  readonly undo: UndoState;
};

export type Init = (state: InitState) => Partial<Omit<InitState, 'initialEditorState'>>;

export type SubsetOf<TState extends ReadonlyRecord<string, unknown>, TValue> = Pick<
  TState,
  {
    [Key in keyof TState]: TState[Key] extends TValue ? Key : never;
  }[keyof TState]
>;

export type Callbacks<TState extends None> = SubsetOf<TState, AnyFunction | undefined>;

export type Apply<
  TPlugin extends Contract,
  TEditor extends BaseEditor = BaseEditor,
  TInputState extends None = Callbacks<
    StateWithApi<State<TEditor> & RequiredState<TPlugin>, TPlugin, TEditor>
  >,
> = (state: TInputState) => DeepUpdateRender<State<TPlugin>, PluginState<TPlugin>>;

export type DecorateWithCallbacks<
  TPlugin extends Contract,
  TEditor extends BaseEditor = BaseEditor,
> = (state: Parameters<Apply<TPlugin, TEditor>>[0]) => void;

export type Finalize<TPlugin extends Contract> = (
  state: Callbacks<DecorablePluginState<TPlugin>>,
) => void;
