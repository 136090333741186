// Subscription users listing
export const SubscriptionUsers_SortBy_Changed = 'SubscriptionUsers_SortBy_Changed';
export const SubscriptionUsers_SortBy_Initialized = 'SubscriptionUsers_SortBy_Initialized';
export const SubscriptionUsers_AllFilters_Cleared = 'SubscriptionUsers_AllFilters_Cleared';
export const SubscriptionUsers_CollectionFilter_LoadCollections_Failed =
  'SubscriptionUsers_CollectionFilter_LoadCollections_Failed';
export const SubscriptionUsers_CollectionFilter_LoadCollections_Started =
  'SubscriptionUsers_CollectionFilter_LoadCollections_Started';
export const SubscriptionUsers_CollectionFilter_LoadCollections_Succeeded =
  'SubscriptionUsers_CollectionFilter_LoadCollections_Succeeded';
export const SubscriptionUsers_CollectionFilter_Removed =
  'SubscriptionUsers_CollectionFilter_Removed';
export const SubscriptionUsers_CollectionFilter_Updated =
  'SubscriptionUsers_CollectionFilter_Updated';
export const SubscriptionUsers_EnvironmentFilter_Updated =
  'SubscriptionUsers_EnvironmentFilter_Updated';
export const SubscriptionUsers_Filter_Initialized = 'SubscriptionUsers_Filter_Initialized';
export const SubscriptionUsers_LanguageFilter_LoadLanguages_Failed =
  'SubscriptionUsers_LanguageFilter_LoadLanguages_Failed';
export const SubscriptionUsers_LanguageFilter_LoadLanguages_Started =
  'SubscriptionUsers_LanguageFilter_LoadLanguages_Started';
export const SubscriptionUsers_LanguageFilter_LoadLanguages_Succeeded =
  'SubscriptionUsers_LanguageFilter_LoadLanguages_Succeeded';
export const SubscriptionUsers_LanguageFilter_Removed = 'SubscriptionUsers_LanguageFilter_Removed';
export const SubscriptionUsers_LanguageFilter_Updated = 'SubscriptionUsers_LanguageFilter_Updated';
export const SubscriptionUsers_Left = 'SubscriptionUsers_Left';
export const SubscriptionUsers_NameFilter_Updated = 'SubscriptionUsers_NameFilter_Updated';
export const SubscriptionUsers_ProjectFilter_Updated = 'SubscriptionUsers_ProjectFilter_Updated';
export const SubscriptionUsers_RoleFilter_LoadRoles_Failed =
  'SubscriptionUsers_RoleFilter_LoadRoles_Failed';
export const SubscriptionUsers_RoleFilter_LoadRoles_Started =
  'SubscriptionUsers_RoleFilter_LoadRoles_Started';
export const SubscriptionUsers_RoleFilter_LoadRoles_Succeeded =
  'SubscriptionUsers_RoleFilter_LoadRoles_Succeeded';
export const SubscriptionUsers_RoleFilter_Removed = 'SubscriptionUsers_RoleFilter_Removed';
export const SubscriptionUsers_RoleFilter_Updated = 'SubscriptionUsers_RoleFilter_Updated';
export const SubscriptionUsers_StatusFilter_Updated = 'SubscriptionUsers_StatusFilter_Updated';
// Subscription user detail
export const SubscriptionUserDetail_ProjectFilter_Init =
  'SubscriptionUserDetail_ProjectFilter_Init';
export const SubscriptionUserDetail_ProjectFilter_Updated =
  'SubscriptionUserDetail_ProjectFilter_Updated';
export const SubscriptionUserDetail_SearchPhraseFilter_Updated =
  'SubscriptionUserDetail_SearchPhraseFilter_Updated';
export const SubscriptionUserDetail_Filters_Cleared = 'SubscriptionUserDetail_Filters_Cleared';
export const SubscriptionUserDetail_UserNotFound = 'SubscriptionUserDetail_UserNotFound';
export const SubscriptionUserDetail_Initialized = 'SubscriptionUserDetail_Initialized';
export const SubscriptionUserDetail_Closed = 'SubscriptionUserDetail_Closed';
export const SubscriptionUserDetail_NotificationBar_Hidden =
  'SubscriptionUserDetail_NotificationBar_Hidden';
export const SubscriptionUserDetail_ResendInviteToProjects_Started =
  'SubscriptionUserDetail_ResendInviteToProjects_Started';
export const SubscriptionUserDetail_ResendInviteToProjects_Finished =
  'SubscriptionUserDetail_ResendInviteToProjects_Finished';
export const SubscriptionUserDetail_ResendInviteToProjects_Failed =
  'SubscriptionUserDetail_ResendInviteToProjects_Failed';
export const SubscriptionUserDetail_RevokeInviteToProjects_Started =
  'SubscriptionUserDetail_RevokeInviteToProjects_Started';
export const SubscriptionUserDetail_RevokeInviteToProjects_Finished =
  'SubscriptionUserDetail_RevokeInviteToProjects_Finished';
export const SubscriptionUserDetail_RevokeInviteToProjects_Failed =
  'SubscriptionUserDetail_RevokeInviteToProjects_Failed';
export const SubscriptionUserDetail_ToggleActiveStateOnProjects_Started =
  'SubscriptionUserDetail_ToggleActiveStateOnProjects_Started';
export const SubscriptionUserDetail_ToggleActiveStateOnProjects_Finished =
  'SubscriptionUserDetail_ToggleActiveStateOnProjects_Finished';
export const SubscriptionUserDetail_ToggleActiveStateOnProjects_Failed =
  'SubscriptionUserDetail_ToggleActiveStateOnProjects_Failed';
// Subscription users usage
export const SubscriptionUsers_UsersUsage_Loaded = 'SubscriptionUsers_UsersUsage_Loaded';
// Subscription user activation/deactivation on all subscription projects
export const SubscriptionUser_TriggerActiveStateOnAllSubscriptionProjects_Started =
  'SubscriptionUser_TriggerActiveStateOnAllSubscriptionProjects_Started';
export const SubscriptionUser_TriggerActiveStateOnAllSubscriptionProjects_Finished =
  'SubscriptionUser_TriggerActiveStateOnAllSubscriptionProjects_Finished';
export const SubscriptionUser_TriggerActiveStateOnAllSubscriptionProjects_Failed =
  'SubscriptionUser_TriggerActiveStateOnAllSubscriptionProjects_Failed';
