import { Box } from '@kontent-ai/component-library/Box';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { noOperation, noOperationAsync } from '@kontent-ai/utils';
import React, { useRef } from 'react';
import { Loader } from '../../../../../_shared/components/Loader.tsx';
import { ItemColumnCode } from '../../../../../_shared/constants/itemColumnCode.ts';
import { ScrollTable } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTable.tsx';
import { DataUiCollection } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getContentItemScrollTableRowHeightPx } from '../../../../contentInventory/content/constants/uiConstants.ts';
import { notSpecifiedContentListingOrderBy } from '../../../../contentInventory/content/reducers/listingUi/reducers/orderBy.ts';
import { RecommendedLinkedItemsScrollTableRow } from '../containers/RecommendedLinkedItemsScrollTableRow.tsx';
import { ListingContentItemWithScore } from '../types/ListingContentItemWithScore.type.ts';
import { RecommendedLinkedItemsLoadingStatus as LoadingStatus } from '../types/recommendedLinkedItemsLoadingStatus.type.ts';
import { NoSuggestionsEmptyState } from './NoSuggestionsEmptyState.tsx';
import { OriginIndexNotFoundEmptyState } from './OriginIndexNotFoundEmptyState.tsx';
import { RecommendedLinkedItemsModalStatus } from './RecommendedLinkedItemsModalStatus.tsx';
import { RecommendedLinkedItemsScrollTableHead } from './RecommendedLinkedItemsScrollTableHead.tsx';

type Props = {
  readonly hiddenColumns: ReadonlySet<ItemColumnCode>;
  readonly items: ReadonlyArray<ListingContentItemWithScore>;
  readonly loadingStatus: LoadingStatus;
  readonly onItemClick: (itemId: Uuid) => void;
  readonly showScoreColumn: boolean;
};

export const RecommendedLinkedItemsModalBody: React.FC<Props> = ({
  hiddenColumns,
  items,
  loadingStatus,
  onItemClick,
  showScoreColumn,
}) => {
  const contentPaneRef = useRef<HTMLDivElement>(null);

  return (
    <Box height="100%" ref={contentPaneRef}>
      {loadingStatus === LoadingStatus.Loading && <Loader />}
      {loadingStatus === LoadingStatus.OriginIndexNotFound && <OriginIndexNotFoundEmptyState />}
      {loadingStatus === LoadingStatus.Loaded && (
        <Stack spacing={Spacing.L}>
          <RecommendedLinkedItemsModalStatus itemCount={items.length} />
          {items.length > 0 ? (
            <ScrollTable<ListingContentItemWithScore>
              collectionName={DataUiCollection.ContentItems}
              itemHeight={itemHeight}
              items={items}
              onChange={noOperation}
              onItemClick={onItemClick}
              onLoadContentItems={noOperationAsync}
              parentContainerRef={contentPaneRef}
              renderRowItem={(params) => (
                <RecommendedLinkedItemsScrollTableRow
                  key={params.item.item.id + params.index}
                  item={params.item}
                  index={params.index}
                  onItemClick={params.onItemClick}
                  showScoreColumn={showScoreColumn}
                />
              )}
              renderTableHead={() => (
                <RecommendedLinkedItemsScrollTableHead
                  contentListingOrderBy={notSpecifiedContentListingOrderBy}
                  hiddenColumns={hiddenColumns}
                  renderOpenItemInNewTab
                  showScoreColumn={showScoreColumn}
                />
              )}
              renderTableTitle={noOperation}
              scrollTableState={{
                availableHeightPx: 0,
                scrollPositionPx: 0,
              }}
            />
          ) : (
            <NoSuggestionsEmptyState />
          )}
        </Stack>
      )}
    </Box>
  );
};

const itemHeight = getContentItemScrollTableRowHeightPx();
