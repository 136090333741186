import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import React, { useState } from 'react';
import { ModalDialog } from '../../../../../_shared/components/ModalDialog/ModalDialog.tsx';
import { Warning } from '../../../../../_shared/components/infos/Warning.tsx';
import { ModalSection } from '../../../../../_shared/features/ChangeWorkflowStepModal/components/ModalSection.tsx';
import { Button } from '../../../../../_shared/uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ICollection } from '../../../../../data/models/collections/Collection.ts';
import { CollectionSingleSelect } from '../../../../contentInventory/shared/components/CollectionSingleSelect.tsx';
import {
  duplicateToCollectionHeader,
  duplicateToCollectionWarning,
} from '../constants/duplicateToCollectionUiConstants.ts';

interface IDuplicateToCollectionDialogProps {
  readonly collectionName: string;
  readonly collectionOptions: ReadonlyArray<ICollection>;
  readonly itemName: string;
  readonly onClose: () => void;
  readonly onDuplicate: (collectionId: Uuid) => void;
}

export const DuplicateToCollectionDialog: React.FC<IDuplicateToCollectionDialogProps> = ({
  collectionName,
  collectionOptions,
  itemName,
  onClose,
  onDuplicate,
}) => {
  const [collectionId, setCollectionId] = useState<string | null>(null);

  return (
    <ModalDialog
      customClassName="duplicate-to-collection-modal"
      headerContent={duplicateToCollectionHeader(itemName)}
      bodyContent={
        <>
          <Warning hideTitle>{duplicateToCollectionWarning(collectionName)}</Warning>
          <div className="duplicate-to-collection-modal__section">
            <ModalSection title="Collection">
              <CollectionSingleSelect
                currentCollectionId={collectionId}
                customClassName="duplicate-to-collection-modal__selector"
                onChange={setCollectionId}
                collectionOptions={collectionOptions}
                shouldHighlightAsError={false}
              />
            </ModalSection>
          </div>
        </>
      }
      footerContentRight={
        <>
          <Button
            style={ButtonStyle.Secondary}
            onClick={onClose}
            {...getDataUiActionAttribute(DataUiAction.CloseDialog)}
          >
            Cancel
          </Button>
          <Tooltip
            placement="top-end"
            tooltipText={collectionId ? undefined : 'Select a collection'}
          >
            <Button
              disabled={!collectionId}
              style={ButtonStyle.Primary}
              onClick={collectionId ? () => onDuplicate(collectionId) : undefined}
              {...getDataUiActionAttribute(DataUiAction.Duplicate)}
            >
              Duplicate
            </Button>
          </Tooltip>
        </>
      }
      onClose={onClose}
    />
  );
};

DuplicateToCollectionDialog.displayName = 'DuplicateToCollectionDialog';
