import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { useState } from 'react';
import { VariantAssignment } from '../../../../../../_shared/components/VariantAssignments/VariantAssignment.tsx';
import {
  ItemDetailSection,
  getDataUiObjectNameAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { CancelSchedulePublishConfirmationDialog } from '../../../../../contentInventory/content/components/cancelConfirmation/CancelSchedulePublishConfirmationDialog.tsx';
import { EditedContentItemVariant } from '../../../../models/contentItem/edited/EditedContentItemVariant.ts';
import { AssignmentSectionActions } from '../../containers/details/AssignmentSectionActions.tsx';

type AssignmentSectionProps = {
  readonly canDiscard: boolean;
  readonly cancelScheduling: () => void;
  readonly editedContentItemVariant: EditedContentItemVariant;
};

export const AssignmentSection: React.FC<AssignmentSectionProps> = ({
  editedContentItemVariant,
  canDiscard,
  cancelScheduling,
}) => {
  const [isCancelSchedulingConfirmationDialogOpen, setIsCancelSchedulingConfirmationDialogOpen] =
    useState(false);

  return (
    <>
      <CancelSchedulePublishConfirmationDialog
        isOpen={isCancelSchedulingConfirmationDialogOpen}
        onCancel={() => setIsCancelSchedulingConfirmationDialogOpen(false)}
        onConfirm={cancelScheduling}
      />
      <div
        className="sidebar__section sidebar__section--is-first-in-details-tab"
        data-hj-suppress=""
        {...getDataUiObjectNameAttribute(ItemDetailSection.AssignmentSection)}
      >
        <h2 className="sidebar__section-title">Workflow step</h2>
        <Stack spacing={Spacing.L}>
          <VariantAssignment canDiscard={canDiscard} variant={editedContentItemVariant} />
          <AssignmentSectionActions
            onCancelSchedulingConfirmationDialog={() =>
              setIsCancelSchedulingConfirmationDialogOpen(true)
            }
          />
        </Stack>
      </div>
    </>
  );
};

AssignmentSection.displayName = 'AssignmentSection';
