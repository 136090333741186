import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { RouterLinkButton } from '@kontent-ai/component-library/Button';
import PropTypes from 'prop-types';
import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';
import { documentationLinks } from '../../../../_shared/constants/documentationLinks.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type EntityWebhookEmptyStateProps = {
  readonly createNewEntityWebhookPath: string;
};

const propTypes: PropTypeMap<EntityWebhookEmptyStateProps> = {
  createNewEntityWebhookPath: PropTypes.string.isRequired,
};

export const EntityWebhookEmptyState = ({
  createNewEntityWebhookPath,
}: EntityWebhookEmptyStateProps) => (
  <EmptyState>
    <EmptyState.Title>Know about changes to your content</EmptyState.Title>
    <EmptyState.Content>
      <EmptyState.ContentGroup>
        <p>
          With webhooks, set up advanced filters to get immediate notifications about specific
          changes in your project.
        </p>
        <p>
          If you’d like to check for changes instead of being notified, use{' '}
          <OutwardLink href={documentationLinks.apiSync}>Sync API</OutwardLink>.
        </p>
      </EmptyState.ContentGroup>
    </EmptyState.Content>
    <EmptyState.Footer>
      <RouterLinkButton
        buttonStyle="primary"
        to={createNewEntityWebhookPath}
        {...getDataUiActionAttribute(DataUiAction.CreateNew)}
      >
        Create new webhook
      </RouterLinkButton>
    </EmptyState.Footer>
  </EmptyState>
);

EntityWebhookEmptyState.displayName = 'EntityWebhookEmptyState';
EntityWebhookEmptyState.propTypes = propTypes;
