import { isString } from '../../../../../../_shared/utils/stringUtils.ts';
import { encodeAttr, encodeText } from './encoding.ts';

export type TextData = string;

export type TagData = {
  readonly attributes?: ReadonlyRecord<string, string | undefined>;
  readonly children?: HtmlData;
  readonly selfClosing?: boolean;
  readonly tagName: string;
};

export type HtmlData = ReadonlyArray<TagData | TextData>;

const selfClosingCharsByTagName: ReadonlyRecord<string, string> = {
  br: '/',
} as const;

export const getStartTagHtml = (data: TagData): string => {
  const attributeEntries = data.attributes && Object.entries(data.attributes);
  const attributes = attributeEntries?.length
    ? attributeEntries
        .map(([name, value]) => (value !== undefined ? ` ${name}="${encodeAttr(value)}"` : ''))
        .join('')
    : '';

  return `<${data.tagName}${attributes}${
    data.selfClosing ? selfClosingCharsByTagName[data.tagName] ?? '' : ''
  }>`;
};

export const getStartHtml = (data: HtmlData): string =>
  data
    .map((tagData) =>
      isString(tagData)
        ? encodeText(tagData)
        : `${getStartTagHtml(tagData)}${tagData.children ? getHtml(tagData.children) : ''}`,
    )
    .join('');

export const getEndTagHtml = (data: TagData): string =>
  data.selfClosing ? '' : `</${data.tagName}>`;

export const getEndHtml = (data: HtmlData): string =>
  [...data]
    .reverse()
    .map((tagData) => (isString(tagData) ? '' : getEndTagHtml(tagData)))
    .join('');

export const getHtml = (data: HtmlData): string =>
  data
    .map((item) =>
      isString(item) ? encodeText(item) : `${getStartHtml([item])}${getEndHtml([item])}`,
    )
    .join('');
