import PropTypes from 'prop-types';
import React, { ReactElement } from 'react';
import { EmptyObject, FieldPathByValue, FieldValues, useController } from 'react-hook-form';
import { HookFormProps, hookFormProps } from '../../../../_shared/types/hookFormProps.ts';
import { LabelFor } from '../../../../_shared/uiComponents/LabelFor/LabelFor.tsx';
import { ProjectLocationSelector } from '../../containers/projects/ProjectLocationSelector.tsx';

type Props<TFormShape extends FieldValues> = {
  readonly destinationSubscriptionId: Uuid;
  readonly disabled?: boolean;
  readonly formProps: HookFormProps<TFormShape>;
  readonly name: FieldPathByValue<TFormShape, string>;
};

const propTypes: PropTypesShape<Props<any>> = {
  disabled: PropTypes.bool,
  destinationSubscriptionId: PropTypes.string.isRequired,
  formProps: hookFormProps.isRequired,
  name: PropTypes.string.isRequired,
};

export const ProjectLocationSelectorWrapper = <TFormShape extends FieldValues = EmptyObject>({
  destinationSubscriptionId,
  disabled,
  formProps,
  name,
}: Props<TFormShape>): ReactElement => {
  const { field } = useController<TFormShape>({
    control: formProps.control,
    name,
  });

  return (
    <LabelFor
      target={(id: Uuid) => (
        <ProjectLocationSelector
          ref={field.ref}
          destinationSubscriptionId={destinationSubscriptionId}
          disabled={disabled}
          dropdownId={id}
          onSelectionChanged={field.onChange}
          selectedProjectLocationId={field.value as string}
        />
      )}
    >
      Project data center
    </LabelFor>
  );
};

(ProjectLocationSelectorWrapper as React.FC).displayName = 'ProjectLocationSelectorWrapper';
(ProjectLocationSelectorWrapper as React.FC<Props<any>>).propTypes = propTypes;
