import { usePrevious } from '@kontent-ai/hooks';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useOneTimeAction } from '../../../_shared/hooks/useOneTimeAction.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { parseQueryParams } from '../../../_shared/utils/routing/queryParamsUtils.ts';

type Props = {
  readonly onMount: (linkId: string) => void;
};

export const SmartLink: React.FC<Props> = ({ onMount }) => {
  const location = useLocation();
  const history = useHistory();
  const { targetLocation, linkText, linkLocation, linkMessage, progress } = useSelector(
    (s) => s.smartLinkApp,
  );

  const previousTargetLocation = usePrevious(targetLocation);

  useOneTimeAction(() => {
    if (onMount) {
      const linkId = parseQueryParams<{ linkId: string }>(location.search).linkId;
      onMount(linkId || '');
    }
  });

  useEffect(() => {
    if (targetLocation && targetLocation !== previousTargetLocation) {
      history.push(targetLocation);
    }
  }, [history, targetLocation, previousTargetLocation]);

  if (!targetLocation) {
    return (
      <div
        className={classNames('utility-message utility-message--centered', {
          'utility-message--in-progress': progress,
        })}
      >
        <h1 className="utility-message__title">{linkMessage}</h1>
        {linkText && linkLocation && (
          <p className="utility-message__content">
            <a href={linkLocation}>{linkText}</a>
          </p>
        )}
      </div>
    );
  }

  return null;
};

SmartLink.displayName = 'SmartLink';
