import React from 'react';
import { ItemDetailSection } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ClickableContributorsSection } from '../../../containers/details/Contributors/SectionTypes/ClickableContributorsSection.tsx';
import { NonClickableContributorsSection } from '../../../containers/details/Contributors/SectionTypes/NonClickableContributorsSection.tsx';
import { Section } from '../../sidebar/Section.tsx';
import { SectionType } from '../../sidebar/sectionTypes/SectionType.tsx';

export interface ContributorsSectionProps {
  readonly sectionType: SectionType;
}

export const ContributorsSection: React.FC<ContributorsSectionProps> = ({ sectionType }) => (
  <Section
    ClickableSectionComponent={ClickableContributorsSection}
    NonClickableSectionComponent={NonClickableContributorsSection}
    ReadonlySectionComponent={() => null}
    title="Contributors"
    type={sectionType}
    uiObjectNameAttribute={ItemDetailSection.ContributorsSection}
  />
);

ContributorsSection.displayName = 'ContributorsSection';
