import React, { ComponentProps, PropsWithChildren } from 'react';
import { RouterLink } from '../Anchor/Anchor.tsx';
import { MenuItem } from './MenuItem.tsx';

type MenuRouterLinkProps = ComponentProps<typeof MenuItem> & ComponentProps<typeof RouterLink>;

export const MenuRouterLink = React.forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<MenuRouterLinkProps>
>((props, forwardedRef) => {
  const { to, ...otherProps } = props;
  return <MenuItem ref={forwardedRef} linkPath={to} {...otherProps} />;
});
