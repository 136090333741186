import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { createWorkflowEditingLink } from '../../../../_shared/utils/routing/projectSubscriptionRoutingUtils.ts';
import { getSelectedSubscription } from '../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { getCurrentProject } from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { initWorkflowCreating, saveWorkflowToServer } from '../actions/thunkWorkflowActions.ts';
import { WorkflowEditorBase } from './WorkflowEditorBase.tsx';

export const WorkflowCreatorContainer: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onInit = useCallback(() => dispatch(initWorkflowCreating()), []);

  const subscription = useSelector(getSelectedSubscription);
  const project = useSelector(getCurrentProject);
  const onSaveWorkflow = (onSuccess?: () => void, onFail?: () => void) =>
    dispatch(
      saveWorkflowToServer({
        onSuccess,
        onFail,
        redirectToWorkflowEditingRoute: (workflowId: Uuid) =>
          history.push(
            createWorkflowEditingLink({
              projectId: project.projectId,
              subscriptionId: subscription?.subscriptionId,
              workflowId,
            }),
          ),
      }),
    );

  return <WorkflowEditorBase onInit={onInit} onSaveWorkflow={onSaveWorkflow} />;
};

WorkflowCreatorContainer.displayName = 'WorkflowCreatorContainer';
export { WorkflowCreatorContainer as WorkflowCreator };
