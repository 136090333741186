import { InvariantException } from '@kontent-ai/errors';
import { createGuid } from '@kontent-ai/utils';
import { History } from 'history';
import { Dispatch, ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { trackUserEventWithData } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import {
  ContentItemEditingEventOrigins,
  ContentItemEditingEventTypes,
} from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { IContentItemWithVariantServerModel } from '../../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import {
  copyContentFromVariantFailed,
  copyContentFromVariantFinished,
  copyContentFromVariantStarted,
} from '../contentItemEditingActions.ts';
import { InitContentItemEditing } from './initContentItemEditing.ts';

interface IDeps {
  readonly contentItemRepository: {
    readonly copyFromVariant: (
      contentItemId: Uuid,
      variantId: Uuid,
      fromVariantId: Uuid,
    ) => Promise<IContentItemWithVariantServerModel>;
  };
  readonly initContentItemEditing: InitContentItemEditing;
}

export const createCopyContentValuesFromDifferentExistingVariantAction =
  (deps: IDeps) =>
  (
    history: History,
    fromVariantId: Uuid,
    actionOrigin: ContentItemEditingEventOrigins,
  ): ThunkPromise =>
  async (dispatch: Dispatch, getState: () => IStore): Promise<void> => {
    const {
      contentApp: {
        editedContentItem,
        editedContentItemVariant,
        listingUi: { filter },
      },
      data: {
        listingContentItems: { usedSearchMethod },
      },
    } = getState();

    if (!editedContentItem || !editedContentItemVariant) {
      throw InvariantException(
        'copyContentFromDifferentVariant: "editedContentItem" or "editedContentItemVariant" is null',
      );
    }

    const editedContentItemId = editedContentItem.id;
    const editedVariantId = editedContentItemVariant.id.variantId;

    dispatch(copyContentFromVariantStarted());
    try {
      const itemWithVariant = await deps.contentItemRepository.copyFromVariant(
        editedContentItemId,
        editedVariantId,
        fromVariantId,
      );

      await dispatch(deps.initContentItemEditing(history, null, createGuid()));

      dispatch(
        trackUserEventWithData(TrackedEvent.ContentItemEditing, {
          action: ContentItemEditingEventTypes.CopyFromLanguage,
          origin: actionOrigin,
        }),
      );

      dispatch(
        copyContentFromVariantFinished({
          itemWithVariant,
          filter,
          usedSearchMethod,
        }),
      );
    } catch {
      dispatch(copyContentFromVariantFailed());
    }
  };
