import { EditorState } from 'draft-js';
import { useCallback, useMemo, useState } from 'react';
import { getContentStateActionResult } from '../../../../../../../_shared/features/AI/helpers/transformAiResult.ts';
import { useAiTask } from '../../../../../../../_shared/features/AI/hooks/aiTasks/useAiTask.ts';
import { useOnFinishedAiActionTask } from '../../../../../../../_shared/features/AI/hooks/aiTasks/useOnFinishedAiActionTask.ts';
import { useAiActionTrackingWithSession } from '../../../../../../../_shared/features/AI/hooks/useAiActionTrackingWithSession.ts';
import { AiActionTrackingProps } from '../../../../../../../_shared/features/AI/types/AiActionTrackingProps.type.ts';
import {
  AiActionSource,
  AiFollowingAction,
  TrackingAiActionName,
} from '../../../../../../../_shared/models/events/AiActionEventData.type.ts';
import { getDataUiObjectNameAttribute } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { AiActionName } from '../../../../../../../repositories/serverModels/ai/AiActionName.type.ts';
import { createMatchWritingStyleOfItemParams } from '../../../../../../../repositories/serverModels/ai/actions/AiServerModels.matchWritingStyleOfItem.ts';
import { useEditorStateCallbacks } from '../../../../../editorCore/hooks/useEditorStateCallbacks.ts';
import { useEditorWithPlugin } from '../../../../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginCreator } from '../../../../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../../../../editorCore/types/Editor.contract.type.ts';
import {
  Apply,
  EditorPlugin,
  Render,
} from '../../../../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../../../../editorCore/utils/decorable.ts';
import { withDisplayName } from '../../../../../editorCore/utils/withDisplayName.ts';
import { extractSelectedContent } from '../../../../../utils/general/editorContentUtils.ts';
import { AiMenuActionItem } from '../../../components/menu/AiMenuActionItem.tsx';
import { AiSubMenuItem } from '../../../components/menu/AiMenuItemWithSubMenu.tsx';
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard.ts';
import { useReplaceSelection } from '../../../hooks/useReplaceSelection.ts';
import { ResultPositioner, useResultPositioner } from '../../../hooks/useResultPositioner.tsx';
import { useResultWithPreservedBlockKeys } from '../../../hooks/useResultWithPreservedBlockKeys.ts';
import { ImproveContentPlugin } from '../../ImproveContent/ImproveContentPlugin.tsx';
import { ChangeTonePlugin, GetExtraChangeToneMenuItems } from '../ChangeTonePlugin.tsx';
import { MatchWritingStyleOfItemAction } from './MatchWritingStyleOfItemAction.tsx';

export type MatchWritingStyleOfItemPlugin = EditorPlugin<
  None,
  AiActionTrackingProps,
  None,
  [ChangeTonePlugin]
>;

export const useMatchWritingStyleOfItem: PluginCreator<MatchWritingStyleOfItemPlugin> = (
  baseEditor,
) =>
  useMemo(
    () =>
      withDisplayName('MatchWritingStyleOfItemPlugin', {
        ComposedEditor: (props) => {
          const [actionEditorState, setActionEditorState] = useState<EditorState | null>(null);
          const [referenceItemId, setReferenceItemId] = useState<Uuid | null>(null);

          const {
            aiSessionId,
            elementOperationTrackingData,
            resetAiSessionId,
            trackFinishedAction,
            trackFollowingAction,
            trackStartingAction,
          } = useAiActionTrackingWithSession(props.element);

          const { decorateWithEditorStateCallbacks, getEditorState, lockEditor, unlockEditor } =
            useEditorStateCallbacks<MatchWritingStyleOfItemPlugin>();

          const { cancel, run, result } = useAiTask(
            AiActionName.MatchWritingStyleOfItem,
            getContentStateActionResult,
          );

          useOnFinishedAiActionTask(
            result.isFinished,
            () => result.trackingParams && trackFinishedAction(result.trackingParams),
          );

          const startMatchWritingStyleOfItemAction = useCallback(
            (selectedReferenceItemId: Uuid, actionSource: AiActionSource) => {
              const variantId = props.element.itemId?.variantId;
              if (!actionEditorState || !variantId) {
                return;
              }

              const contentState = extractSelectedContent(
                actionEditorState.getCurrentContent(),
                actionEditorState.getSelection(),
              );

              setReferenceItemId(selectedReferenceItemId);

              run(
                createMatchWritingStyleOfItemParams(
                  contentState,
                  props.element.elementId,
                  {
                    itemId: selectedReferenceItemId,
                    variantId,
                  },
                  elementOperationTrackingData,
                ),
              );

              trackStartingAction({
                action: AiActionName.MatchWritingStyleOfItem,
                referenceItemId: selectedReferenceItemId,
                source: actionSource,
              });
            },
            [
              trackStartingAction,
              actionEditorState,
              run,
              elementOperationTrackingData,
              props.element,
            ],
          );

          const start = useCallback(async () => {
            const editorState = getEditorState();
            await lockEditor(editorState);
            setActionEditorState(editorState);
            trackStartingAction({
              action: TrackingAiActionName.MatchWritingStyle,
              source: AiActionSource.InlineToolbar,
            });
          }, [trackStartingAction, lockEditor, getEditorState]);

          const reset = useCallback(() => {
            if (!result.isFinished) {
              cancel();
            }
            resetAiSessionId();
            setActionEditorState(null);
            setReferenceItemId(null);
            unlockEditor();
          }, [unlockEditor, cancel, result.isFinished, resetAiSessionId]);

          const tryAgain = useMemo(
            () =>
              result.isFinished
                ? () => {
                    if (actionEditorState && referenceItemId) {
                      trackFollowingAction({ action: AiFollowingAction.TryAgain });
                      startMatchWritingStyleOfItemAction(
                        referenceItemId,
                        AiActionSource.ActionMenu,
                      );
                    }
                  }
                : undefined,
            [
              startMatchWritingStyleOfItemAction,
              actionEditorState,
              referenceItemId,
              result.isFinished,
              trackFollowingAction,
            ],
          );

          const { decorateWithReplaceCallbacks, replaceSelection } =
            useReplaceSelection(actionEditorState);

          const onReplaceSelection = useMemo(() => {
            const content = result.content;

            return result.isFinished && content
              ? () => {
                  trackFollowingAction({ action: AiFollowingAction.ReplaceSelection });
                  reset();
                  replaceSelection(content);
                }
              : undefined;
          }, [replaceSelection, reset, result.content, result.isFinished, trackFollowingAction]);

          const { copyToClipboard } = useCopyToClipboard();

          const onCopyToClipboard = useMemo(() => {
            const content = result.content;

            return result.isFinished && content
              ? () => {
                  trackFollowingAction({ action: AiFollowingAction.CopyToClipboard });
                  reset();
                  copyToClipboard(content, aiSessionId);
                }
              : undefined;
          }, [
            copyToClipboard,
            reset,
            result.content,
            result.isFinished,
            trackFollowingAction,
            aiSessionId,
          ]);

          const { decorateWithPositionerCallbacks, resultPositionerProps } = useResultPositioner(
            !!actionEditorState,
          );
          const resultWithPreservedBlockKeys = useResultWithPreservedBlockKeys(result);

          const renderOverlays: Decorator<Render<ImproveContentPlugin>> = useCallback(
            (baseRenderOverlays) => (state) => (
              <>
                {baseRenderOverlays(state)}
                {actionEditorState && (
                  <ResultPositioner
                    {...resultPositionerProps}
                    renderResult={(isPositionedAboveContent, resultWidth, resultRef) => (
                      <MatchWritingStyleOfItemAction
                        onClosePropsDialog={() => {
                          trackFollowingAction({ action: AiFollowingAction.Cancel });
                          reset();
                        }}
                        onDiscard={() => {
                          trackFollowingAction({ action: AiFollowingAction.Discard });
                          reset();
                        }}
                        onCopyToClipboard={onCopyToClipboard}
                        onInputsEdited={() =>
                          trackFollowingAction({ action: AiFollowingAction.EditInputs })
                        }
                        onReplaceSelection={onReplaceSelection}
                        onSubmit={(contentItemId) =>
                          startMatchWritingStyleOfItemAction(
                            contentItemId,
                            AiActionSource.ActionDialog,
                          )
                        }
                        onTryAgain={tryAgain}
                        preferMenuOnTop={isPositionedAboveContent}
                        ref={resultRef}
                        result={resultWithPreservedBlockKeys}
                        resultWidth={resultWidth}
                      />
                    )}
                  />
                )}
              </>
            ),
            [
              actionEditorState,
              reset,
              onCopyToClipboard,
              onReplaceSelection,
              startMatchWritingStyleOfItemAction,
              resultWithPreservedBlockKeys,
              resultPositionerProps,
              tryAgain,
              trackFollowingAction,
            ],
          );

          const getExtraChangeToneMenuItems: Decorator<GetExtraChangeToneMenuItems> = useCallback(
            (base) => (editorState, onActionStarted) => {
              return [
                ...base(editorState, onActionStarted),
                ...(isActionAvailable(editorState)
                  ? [
                      {
                        id: 'reference-item',
                        label: 'Match voice and tone to content…',
                        renderIntoMenu: (aiMenuItem) => (
                          <AiMenuActionItem
                            label={aiMenuItem.label}
                            onPress={() => {
                              onActionStarted();
                              start();
                            }}
                            {...getDataUiObjectNameAttribute(aiMenuItem.label)}
                          />
                        ),
                      } satisfies AiSubMenuItem,
                    ]
                  : []),
              ];
            },
            [start],
          );

          const apply: Apply<MatchWritingStyleOfItemPlugin> = useCallback(
            (state) => {
              decorateWithEditorStateCallbacks(state);
              decorateWithReplaceCallbacks(state);
              decorateWithPositionerCallbacks(state);
              state.getExtraChangeToneMenuItems.decorate(getExtraChangeToneMenuItems);
              state.renderOverlays.decorate(renderOverlays);
              return {};
            },
            [
              decorateWithEditorStateCallbacks,
              decorateWithReplaceCallbacks,
              decorateWithPositionerCallbacks,
              getExtraChangeToneMenuItems,
              renderOverlays,
            ],
          );

          return useEditorWithPlugin(baseEditor, props, { apply });
        },
      }),
    [baseEditor],
  );

const isActionAvailable = (_: EditorState) => true;
