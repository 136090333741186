import { Box } from '@kontent-ai/component-library/Box';
import { VerticalMenuItem, useVerticalMenu } from '@kontent-ai/component-library/VerticalMenu';
import {
  Typography,
  colorTextHint,
  colorTextHintInverse,
} from '@kontent-ai/component-library/tokens';
import { useHistory } from 'react-router';
import { SelectGroupRenderMode } from '../../../../../component-library/components/StatusBar/Selects/types/SelectGroupRenderMode.ts';
import { ProductionEnvironmentDefaultName } from '../../../../applications/projectSettings/environments/constants/uiConstants.ts';
import { AppNames } from '../../../constants/applicationNames.ts';
import { StatefulDropDown } from '../../../uiComponents/DropDown/StatefulDropDown.tsx';
import {
  DataUiAction,
  DataUiCollection,
  ObjectWithDataAttribute,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { StatusBarDropDownSelected } from '../../StatusBar/StatusBarDropDownSelected.tsx';
import { ItemSettings } from '../ItemSettings.tsx';
import { SelectMenuItem } from '../SelectMenuItem.tsx';
import { optionMaxWidth, projectMenuTippyOptions } from '../constants.ts';
import { MenuItem } from '../types.type.ts';

type ProductionEnvironmentLabelProps = Readonly<{
  type: 'default' | 'inverse';
}>;
const ProductionEnvironmentLabel = ({ type }: ProductionEnvironmentLabelProps) => (
  <Box
    typography={Typography.LabelLarge}
    color={type === 'inverse' ? colorTextHintInverse : colorTextHint}
    overflow="hidden"
    textOverflow="ellipsis"
  >
    ({ProductionEnvironmentDefaultName})
  </Box>
);

export type EnvironmentMenuItem = MenuItem &
  VerticalMenuItem<EnvironmentMenuItem> & {
    readonly dataAttributes: ObjectWithDataAttribute;
    readonly isSelected: boolean;
    readonly settingsEnabled: boolean;
    readonly settingsLink: string;
  };

type EnvironmentSelectProps = {
  readonly environmentId: Uuid;
  readonly environmentItems: ReadonlyArray<EnvironmentMenuItem>;
  readonly productionId: Uuid;
  readonly renderMode: SelectGroupRenderMode;
  readonly selectedEnvironmentName: string;
};

export const EnvironmentSelect: React.FC<EnvironmentSelectProps> = ({
  environmentId,
  environmentItems,
  productionId,
  selectedEnvironmentName,
  renderMode,
}) => {
  const history = useHistory();

  const {
    verticalMenuState: environmentVerticalMenuState,
    VerticalMenu,
    verticalMenuProps,
  } = useVerticalMenu<EnvironmentMenuItem>([
    {
      id: 'environments',
      items: environmentItems,
      label: 'Environments',
      type: 'section',
    },
  ]);

  return (
    <StatefulDropDown
      optionListDataUiAttributes={getDataUiCollectionAttribute(DataUiCollection.Environments)}
      renderContent={(closeEnvironmentList) => (
        <VerticalMenu
          {...verticalMenuProps}
          state={environmentVerticalMenuState}
          pinnedItemId="environments-pinned"
          maxWidth={optionMaxWidth}
          renderItem={({ item }) =>
            item.value && (
              <SelectMenuItem
                closeMenu={closeEnvironmentList}
                item={item.value}
                statusTag={
                  item.value.id === productionId && <ProductionEnvironmentLabel type="default" />
                }
                settings={
                  item.value.settingsEnabled && (
                    <ItemSettings
                      onClick={() => {
                        closeEnvironmentList();
                        if (item.value) {
                          history.push(item.value.settingsLink);
                        }
                      }}
                      tooltipText={AppNames.EnvironmentSettings}
                    />
                  )
                }
              />
            )
          }
        />
      )}
      renderSelectedOption={(ref, toggleEnvironmentList, isOpen) => (
        <StatusBarDropDownSelected
          dataUiAttributes={getDataUiActionAttribute(DataUiAction.OpenEnvironmentMenu)}
          isExpanded={isOpen}
          onClick={toggleEnvironmentList}
          ref={ref}
          renderMode={renderMode}
          tag={environmentId === productionId && <ProductionEnvironmentLabel type="inverse" />}
        >
          {selectedEnvironmentName}
        </StatusBarDropDownSelected>
      )}
      tippyOptions={projectMenuTippyOptions}
    />
  );
};

EnvironmentSelect.displayName = 'EnvironmentSelect';
