import { MenuItemWithMouseHover } from '@kontent-ai/component-library/MenuItem';
import { Row } from '@kontent-ai/component-library/Row';
import React, { ReactNode } from 'react';
import { useHistory } from 'react-router';
import { MenuItem } from './types.type.ts';

type Props = Readonly<{
  closeMenu: () => void;
  item: MenuItem;
  statusTag?: ReactNode;
  settings?: ReactNode;
}>;

export const SelectMenuItem: React.FC<Props> = ({ closeMenu, item, statusTag, settings }) => {
  const history = useHistory();

  return (
    <MenuItemWithMouseHover
      menuItemState={item.isSelected ? 'selected' : 'default'}
      text={item.label}
      trailingElements={
        <Row alignY="center">
          {statusTag}
          {settings}
        </Row>
      }
      onPress={() => {
        closeMenu();
        item.onClick?.();
        history.push(item.link);
      }}
      {...item.dataAttributes}
    />
  );
};

SelectMenuItem.displayName = 'SelectMenuItem';
