import { Box } from '@kontent-ai/component-library/Box';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { Warning } from '../../../../../../_shared/components/infos/Warning.tsx';

type FormPaneWarningProps = {
  readonly subtitle: string;
};

export const FormPaneWarning: React.FC<React.PropsWithChildren<FormPaneWarningProps>> = (props) => (
  <Box paddingTop={Spacing.S}>
    <Warning subtitle={props.subtitle}>
      <p>{props.children}</p>
    </Warning>
  </Box>
);

FormPaneWarning.displayName = 'FormPaneWarning';
