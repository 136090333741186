import {
  sizeSidebarPlaceholderWidth,
  sizeSidebarWidth,
} from '@kontent-ai/component-library/tokens';
import { animated, useTransition } from '@react-spring/web';
import PropTypes from 'prop-types';
import React, { RefObject, useCallback, useEffect, useRef } from 'react';
import { LandmarkAriaLabels } from '../../../../../../_shared/constants/landmarkAriaLabels.ts';
import { useOnClickOutside } from '../../../../../../_shared/hooks/useOnClickOutside.ts';
import { waitUntilFocusAndScrollAreNotDeferred } from '../../../../../../_shared/utils/autoScrollUtils.ts';
import {
  DataUiAction,
  DataUiCollection,
  DataUiElement,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { forwardedRefProps } from '../../../../../../_shared/utils/forwardedRefProps.tsx';
import { ContentItemDetails } from '../../containers/ContentItemDetails.tsx';
import { ContentItemSidebarButton } from './ContentItemSidebarButton.tsx';

export type ContentItemSidebarOwnProps<TRef> = {
  readonly hasPlaceholder?: boolean;
  readonly outsideClickElementRefs: OneOrMore<RefObject<TRef>>;
  readonly shouldOpenByDefault?: boolean;
};

type Props<TRef> = ContentItemSidebarOwnProps<TRef> & {
  readonly isInitialized: boolean;
  readonly isOpen: boolean;
  readonly onInit: () => void;
  readonly onClose: () => void;
  readonly onOpen: () => void;
};

export const ContentItemSidebar = <TRef extends HTMLElement>(
  props: React.PropsWithoutRef<Props<TRef>>,
): ReturnType<React.FC> => {
  const {
    hasPlaceholder,
    isInitialized,
    isOpen,
    onInit,
    onClose,
    onOpen,
    outsideClickElementRefs,
    shouldOpenByDefault,
  } = props;

  useEffect(() => {
    // Do not initiate sidebar showing until all the content is rendered to give the sidebar maximum resources for its animation to be smooth
    const deferredOnInit = waitUntilFocusAndScrollAreNotDeferred(onInit);
    deferredOnInit();
  }, [onInit]);

  useEffect(() => {
    if (isInitialized && shouldOpenByDefault) {
      onOpen();
    }
  }, [isInitialized, onOpen, shouldOpenByDefault]);

  const innerRef = useRef<HTMLDivElement>(null);
  const hiddenTransform = hasPlaceholder
    ? `translateX(${sizeSidebarWidth - sizeSidebarPlaceholderWidth}px)`
    : 'translateX(100%)';
  const visibleTransform = hasPlaceholder ? 'translateX(0px)' : 'translateX(0%)';

  const transitions = useTransition(isOpen, {
    initial: {
      transform: isOpen ? visibleTransform : hiddenTransform,
    },
    from: {
      transform: hiddenTransform,
    },
    enter: {
      transform: visibleTransform,
    },
    leave: {
      transform: hiddenTransform,
    },
  });

  const closeOnClickOutside = useCallback(
    (event: MouseEvent) => {
      const outsideClickElementRefsArray = Array.isArray(outsideClickElementRefs)
        ? outsideClickElementRefs
        : [outsideClickElementRefs];

      const wasEventTriggeredFromValidOutsideElement = outsideClickElementRefsArray.some(
        (ref) => !!ref.current?.contains(event.target as HTMLElement),
      );

      if (isOpen && wasEventTriggeredFromValidOutsideElement) {
        onClose();
      }
    },
    [isOpen, onClose, outsideClickElementRefs],
  );

  useOnClickOutside(innerRef, closeOnClickOutside);

  if (!isInitialized) {
    return null;
  }

  return transitions(
    (style, isOpenState) =>
      isOpenState && (
        <animated.section
          ref={innerRef}
          style={style}
          className="content-item-pane__sidebar"
          aria-label={LandmarkAriaLabels.ItemDetails}
          {...getDataUiElementAttribute(DataUiElement.ContentItemDetailsSidebar)}
        >
          <div
            className="content-item-pane__sidebar-pane"
            {...getDataUiCollectionAttribute(DataUiCollection.ItemDetailSections)}
          >
            <ContentItemDetails />
          </div>
          <ContentItemSidebarButton
            className="content-item-pane__sidebar-btn-toggle"
            dataUiAction={DataUiAction.CloseSidebar}
            iconName="ChevronDoubleRight"
            onClick={onClose}
            tooltipText="Close sidebar"
          />
        </animated.section>
      ),
  );
};

ContentItemSidebar.displayName = 'ContentItemSidebar';
ContentItemSidebar.propTypes = {
  isInitialized: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onInit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  outsideClickElementRefs: PropTypes.oneOfType([
    forwardedRefProps.forwardedRef,
    PropTypes.arrayOf(forwardedRefProps.forwardedRef),
  ]).isRequired,
};
