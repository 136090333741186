import { notNull } from '@kontent-ai/utils';
import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { isCreatingRenditionsEnabled } from '../../../../../../_shared/selectors/enhancedAssetManagement.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { IValidationResult } from '../../../../../../_shared/utils/validation/ValidationResult.ts';
import { getCurrentProjectPlan } from '../../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { ICompiledContentItemElementData } from '../../../../models/contentItemElements/ICompiledContentItemElement.ts';
import {
  isAssetElement,
  isRichTextElement,
} from '../../../../models/contentItemElements/compiledItemElementTypeGuards.ts';
import { getItemElementValidationResult } from '../../../../utils/getItemElementValidationResult.ts';
import { isElementVisible } from '../../../../utils/itemElementConditionUtils.ts';
import { isElementEditableInVariant } from '../../../../utils/itemElementUtils.ts';
import { ContentItemEditing_AssetAndRichTextElementsRevalidated } from '../../constants/contentItemEditingActionTypes.ts';

const assetAndRichTextElementsRevalidated = (
  validationResults: ReadonlyMap<ICompiledContentItemElementData, IValidationResult>,
) =>
  ({
    type: ContentItemEditing_AssetAndRichTextElementsRevalidated,
    payload: {
      validationResults,
    },
  }) as const;

export type RevalidateAssetAndRichTextElementsType = ReturnType<
  typeof assetAndRichTextElementsRevalidated
>;

export const createRevalidateAssetAndRichTextElementsAction =
  () => (): ThunkFunction => (dispatch, getState) => {
    const state = getState();
    const {
      data: { taxonomyGroups, languages, listingContentItems, assets, assetRenditions },
      contentApp: { editedContentItemVariantElements, loadedContentItemTypes, editedContentItem },
    } = state;

    const selectedLanguageId = getSelectedLanguageIdOrThrow(state);
    if (!editedContentItem) {
      return;
    }

    const editedContentItemType = loadedContentItemTypes.get(
      editedContentItem.editedContentItemTypeId,
    );
    if (!editedContentItemType) {
      return;
    }
    const areAssetRenditionsEnabled = isCreatingRenditionsEnabled(getCurrentProjectPlan(state));

    const validationResults = editedContentItemVariantElements
      .filter((elementData) => isAssetElement(elementData) || isRichTextElement(elementData))
      .map((elementData: ICompiledContentItemElementData) => {
        const elementId = elementData.elementId;
        const typeElement = editedContentItemType.contentElements.find(
          (typeEl) => typeEl.elementId === elementId,
        );

        const skipValidation =
          !isElementEditableInVariant(typeElement, selectedLanguageId) ||
          !isElementVisible(typeElement, editedContentItemVariantElements);

        if (skipValidation) {
          return null;
        }

        const validationResult = getItemElementValidationResult(
          typeElement,
          elementData,
          loadedContentItemTypes,
          assets.byId,
          assetRenditions.byId,
          taxonomyGroups.byId,
          listingContentItems.byId,
          selectedLanguageId,
          languages.byId,
          areAssetRenditionsEnabled,
        );

        return [elementData, validationResult] as const;
      })
      .filter(notNull);

    dispatch(assetAndRichTextElementsRevalidated(new Map(validationResults)));
  };
