import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { getCurrentProjectContainer } from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import {
  Environment_InitListing_Finished,
  Environment_InitListing_Started,
} from '../../constants/environmentActionTypes.ts';

interface IInitEnvironmentListingDependencies {
  readonly initializeProjectManagement: (
    clearSelectedSubscription?: boolean,
    abortSignal?: AbortSignal,
  ) => ThunkPromise;
  readonly loadSubscriptionUsage: (subscriptionId: Uuid, abortSignal?: AbortSignal) => ThunkPromise;
}

const start = () =>
  ({
    type: Environment_InitListing_Started,
  }) as const;

const finish = () =>
  ({
    type: Environment_InitListing_Finished,
  }) as const;

export type InitEnvironmentListingActionsType = ReturnType<typeof start | typeof finish>;

export const initEnvironmentListingCreator =
  (deps: IInitEnvironmentListingDependencies) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    dispatch(start());

    const subscriptionId = getCurrentProjectContainer(getState()).subscriptionId;

    await Promise.all([
      dispatch(deps.initializeProjectManagement(undefined, abortSignal)),
      dispatch(deps.loadSubscriptionUsage(subscriptionId, abortSignal)),
    ]);

    dispatch(finish());
  };
