import { Box } from '@kontent-ai/component-library/Box';
import { Clipboard } from '@kontent-ai/component-library/Clipboard';
import { InputState } from '@kontent-ai/component-library/Input';
import { Spacing } from '@kontent-ai/component-library/tokens';
import PropTypes from 'prop-types';
import React from 'react';
import { QuickTip } from '../../../../_shared/components/infos/QuickTip.tsx';
import { FormGroup } from '../../../../_shared/components/input/FormGroup.tsx';
import { LabelFor } from '../../../../_shared/uiComponents/LabelFor/LabelFor.tsx';
import { ISubscriptionBillingInformation } from '../models/SubscriptionBillingInformation.ts';

interface IBillingInformationDetailsOwnProps {
  readonly billingInformation: ISubscriptionBillingInformation | null;
  readonly subscriptionId: Uuid;
}

interface IBillingInformationDetailsProps extends IBillingInformationDetailsOwnProps {}

const renderField = (name: string, value: string): JSX.Element => (
  <FormGroup>
    <LabelFor
      target={(id: Uuid) => <input id={id} className="form__text-field" value={value} readOnly />}
    >
      {name}
    </LabelFor>
  </FormGroup>
);

const renderBillingDetails = (
  billingInformation: ISubscriptionBillingInformation | null,
): JSX.Element | null => {
  return (
    billingInformation && (
      <>
        {renderField('First name', billingInformation.firstName)}
        {renderField('Last name', billingInformation.lastName)}
        {renderField('Company', billingInformation.company)}
        {renderField('Email', billingInformation.email)}
      </>
    )
  );
};

const BillingInformationDetails: React.FC<IBillingInformationDetailsProps> = (props) => {
  const { subscriptionId, billingInformation } = props;

  return (
    <div className="card__pane">
      <div className="card__content-section">
        <div className="card__title-section">
          <h3 className="card__title">Billing account details</h3>
        </div>
        {billingInformation && (
          <div className="billing-information-details__quick-tip">
            <QuickTip hideTitle subtitle="Read only">
              If you need to make changes to billing account details, update your payment method.
              For help, please contact our support.
            </QuickTip>
          </div>
        )}
        <div className="card__content">
          {renderBillingDetails(billingInformation)}
          <Box marginTop={billingInformation ? Spacing.XL : undefined}>
            <Clipboard
              inputState={InputState.ReadOnly}
              value={subscriptionId}
              label="Subscription ID"
            />
          </Box>
        </div>
      </div>
    </div>
  );
};

BillingInformationDetails.displayName = 'BillingInformationDetails';
BillingInformationDetails.propTypes = {
  billingInformation: PropTypes.object as any,
  subscriptionId: PropTypes.string.isRequired,
};

export { BillingInformationDetails };
