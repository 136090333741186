import PropTypes from 'prop-types';
import React, { ReactElement } from 'react';
import { EmptyObject, FieldPathByValue, FieldValues, useController } from 'react-hook-form';
import { OptionWithInputAttribute } from '../../../../_shared/components/Options/Option.tsx';
import { OptionMode } from '../../../../_shared/models/optionMode.ts';
import { HookFormProps, hookFormProps } from '../../../../_shared/types/hookFormProps.ts';
import { DataUiInput } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type Props<TFormShape extends FieldValues> = {
  readonly formProps: HookFormProps<TFormShape>;
  readonly name: FieldPathByValue<TFormShape, boolean>;
};

const propTypes: PropTypesShape<Props<any>> = {
  formProps: hookFormProps.isRequired,
  name: PropTypes.string.isRequired,
};

export const IncludeContentSelector = <TFormShape extends FieldValues = EmptyObject>({
  formProps,
  name,
}: Props<TFormShape>): ReactElement => {
  const { field } = useController({
    control: formProps.control,
    name,
  });

  return (
    <div>
      <OptionWithInputAttribute
        ref={field.ref}
        dataUiAttribute={DataUiInput.IncludeContentAndAssets}
        isSelected={field.value as boolean}
        label="Include content items and assets"
        mode={OptionMode.InvertedMultiple}
        onOptionSelected={field.onChange}
      />
    </div>
  );
};

(IncludeContentSelector as React.FC).displayName = 'IncludeContentSelector';
(IncludeContentSelector as React.FC<Props<any>>).propTypes = propTypes;
