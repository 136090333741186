import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { upsertUserProperty } from '../../../../../_shared/actions/thunkSharedActions.ts';
import { trackUserEventWithData } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import {
  CodenameEventType,
  CodenameTargetType,
} from '../../../../../_shared/models/TrackUserEventData.ts';
import { LongProcessingChangesWarningDismissedServerKey } from '../../../../../_shared/models/UserPropertiesServerKeys.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { compose } from '../../../../../_shared/utils/func/compose.ts';
import { ContentTypeOrSnippetEditorToolbarActions } from '../../../shared/components/statusBar/ContentTypeOrSnippetEditorToolbarActions.tsx';
import { getElementsWithAffectedLocalization } from '../../../shared/utils/getElementsWithAffectedLocalization.ts';
import { getTypeCodenamesWithoutEditedContentTypeCodename } from '../../../shared/utils/typeCodenameUtils.ts';
import { changeContentTypeCodename } from '../../actions/contentTypesActions.ts';
import {
  duplicateContentType,
  saveEditedContentType,
} from '../../actions/thunkContentTypesActions.ts';
import { shouldLongProcessingChangesWarningBeHidden } from '../../selectors/shouldLongProcessingChangesWarningBeHidden.ts';

const getRelatedCodenames = (s: IStore): ReadonlySet<string> => {
  const {
    contentTypes: { byId: contentTypesById },
    snippets: { byId: snippetsById },
  } = s.data;
  const editedTypeId = s.contentModelsApp.typeEditor.editedType.id;

  return getTypeCodenamesWithoutEditedContentTypeCodename(
    contentTypesById,
    snippetsById,
    editedTypeId,
  );
};

export const ContentTypeEditorToolbarActions: React.FC = () => {
  const codename = useSelector((s) => s.contentModelsApp.typeEditor.editedType.codename);
  const relatedCodenames = useSelector((s) => getRelatedCodenames(s));
  const isBeingSaved = useSelector((s) => s.contentModelsApp.typeEditor.typeIsBeingSaved);
  const isModified = useSelector((s) => s.contentModelsApp.typeEditor.editedTypeIsModified);
  const isLoading = useSelector(
    (s) => s.contentModelsApp.typeEditor.loadingStatus !== LoadingStatus.Loaded,
  );
  const hideLongProcessingChangesWarning = useSelector(shouldLongProcessingChangesWarningBeHidden);
  const editedType = useSelector((s) => s.contentModelsApp.typeEditor.editedType);
  const originalTypeElements = useSelector(
    (s) => s.data.contentTypes.byId.get(editedType.id)?.typeElements,
  );

  const elementsWithAffectedLocalization = useMemo(
    () => getElementsWithAffectedLocalization(editedType, originalTypeElements),
    [editedType, originalTypeElements],
  );

  const dispatch = useDispatch();
  const onSave = useMemo(() => compose(dispatch, saveEditedContentType), []);
  const onCodenameChange = useMemo(() => compose(dispatch, changeContentTypeCodename), []);
  const onCodenameCopy = useCallback(
    () =>
      dispatch(
        trackUserEventWithData(TrackedEvent.Codename, {
          type: CodenameEventType.Copy,
          target: CodenameTargetType.ContentType,
        }),
      ),
    [],
  );
  const onCodenameEdit = useCallback(
    () =>
      dispatch(
        trackUserEventWithData(TrackedEvent.Codename, {
          type: CodenameEventType.Edit,
          target: CodenameTargetType.ContentType,
        }),
      ),
    [],
  );
  const history = useHistory();
  const onDuplicate = useCallback(() => dispatch(duplicateContentType(history)), [history]);
  const onDismissWarning = useCallback(
    () => dispatch(upsertUserProperty(LongProcessingChangesWarningDismissedServerKey, 'true')),
    [],
  );

  return (
    <ContentTypeOrSnippetEditorToolbarActions
      codename={codename}
      elementsWithAffectedLocalization={elementsWithAffectedLocalization}
      isBeingSaved={isBeingSaved}
      isCodenameEditable
      relatedCodenames={relatedCodenames}
      isDuplicateDisabled={isModified}
      isLoading={isLoading}
      onSave={onSave}
      onCodenameChange={onCodenameChange}
      onCodenameCopy={onCodenameCopy}
      onCodenameEdit={onCodenameEdit}
      onDuplicate={onDuplicate}
      onDismissWarning={onDismissWarning}
      hideLongProcessingChangesWarning={hideLongProcessingChangesWarning}
    />
  );
};

ContentTypeEditorToolbarActions.displayName = 'ContentTypeEditorToolbarActions';
