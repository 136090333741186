import { isElement } from '@kontent-ai/DOM';
import { Button } from '@kontent-ai/component-library/Button';
import { useObserveElementPresence } from '@kontent-ai/hooks';
import React, { useCallback, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useForm } from 'react-hook-form';
import { getDefaultExpiration } from '../../../../../_shared/constants/apiKeyExpirations.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { ApiKeyType } from '../../../../../_shared/models/ApiKeyType.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { createFormValidationResolver } from '../../../../../_shared/utils/validation/createFormValidationResolver.ts';
import { IProjectContainerActiveUser } from '../../../../../data/models/projectContainerActiveUsers/ProjectContainerActiveUser.ts';
import { UntitledDeliveryKeyName } from '../../../../environmentSettings/roles/constants/UIConstants.ts';
import { apiKeyFormSavingInterrupted } from '../../actions/apiKeysActions.ts';
import { ApiKeyDetailLayout } from '../../components/ApiKeyDetail/ApiKeyDetailLayout.tsx';
import { ApiKeyDetailToolbarSaveButtonElementId } from '../../components/ApiKeyDetail/ApiKeyDetailToolbarActions.tsx';
import { DapiKeyFormContent } from '../../components/ApiKeyDetail/DapiKeyFormContent.tsx';
import { HandleUnsavedApiKeyFormOnNavigation } from '../../components/ApiKeyDetail/HandleUnsavedApiKeyFormOnNavigation.tsx';
import { ApiKey } from '../../models/ApiKey.ts';
import { ApiKeyActionStatus } from '../../reducers/IApiKeysAppStoreState.type.ts';
import { hiddenEnvironmentsOptionId } from '../../selectors/getHiddenEnvironmentOption.ts';
import {
  AllEnvironmentsTagId,
  EnvironmentOption,
} from '../../selectors/getSelectedEnvironmentOptions.ts';
import { isContainerScopedDapiKey } from '../../utils/isContainerScopedDapiKey.ts';
import {
  DapiKeyFormShape,
  dapiKeyValidationConfig,
} from '../../validation/dapiKeyValidationConfig.ts';

export type DapiKeyDetailFormProps = {
  readonly allEnvironmentOptions: ReadonlyArray<EnvironmentOption>;
  readonly apiKey: ApiKey;
  readonly apiKeyActionStatus: ApiKeyActionStatus;
  readonly hiddenEnvironmentOption: EnvironmentOption | null;
  readonly isProjectManager: boolean;
  readonly isSecureAccessAvailable: boolean;
  readonly projectContainerActiveUsers: ReadonlyArray<IProjectContainerActiveUser>;
  readonly onAlertDismiss: () => void;
  readonly onRegenerate?: () => void;
  readonly onSave: (
    updatedKey: DapiKeyFormShape,
    isFromUnsavedChangesDialog: boolean,
  ) => Promise<void>;
};

export const DapiKeyDetailForm: React.FC<DapiKeyDetailFormProps> = ({
  allEnvironmentOptions,
  apiKey,
  apiKeyActionStatus,
  hiddenEnvironmentOption,
  isProjectManager,
  isSecureAccessAvailable,
  projectContainerActiveUsers,
  onAlertDismiss,
  onRegenerate,
  onSave,
}) => {
  const dispatch = useDispatch();

  const getDefaultExpirationDate = useMemo(() => () => getDefaultExpiration(ApiKeyType.DAPI), []);
  const formProps = useForm<DapiKeyFormShape>({
    defaultValues: {
      access: {
        hasPreviewDeliveryAccess: false,
        hasSecureDeliveryAccess: false,
      },
      name: '',
      environments: [],
      expiresAt: {
        value: getDefaultExpirationDate(),
        isValid: true,
      },
      sharedWithUsers: [],
    },
    resolver: createFormValidationResolver(dapiKeyValidationConfig, {
      projectContainerActiveUsers,
    }),
  });

  const {
    handleSubmit,
    watch,
    reset,
    formState: { isDirty },
  } = formProps;
  const keyName = watch('name');

  const onSaveChanges = handleSubmit(
    async (values): Promise<void> => {
      reset(values);
      await onSave(values, false);
    },
    (): void => {
      dispatch(apiKeyFormSavingInterrupted());
    },
  );

  const onSaveFromUnsavedChangesDialog = async (): Promise<boolean> => {
    let isValid: boolean = true;

    await handleSubmit(
      async (values): Promise<void> => {
        reset(values);
        await onSave(values, true);
      },
      (): void => {
        isValid = false;
        dispatch(apiKeyFormSavingInterrupted());
      },
    )();

    return isValid;
  };

  const propagateApiKeyChangesToForm = useCallback(
    () =>
      reset({
        access: {
          hasPreviewDeliveryAccess: apiKey.hasPreviewDeliveryAccess,
          hasSecureDeliveryAccess: apiKey.hasSecureDeliveryAccess,
        },
        name: apiKey.name,
        environments: apiKey.hasAccessToAllEnvironments
          ? [AllEnvironmentsTagId]
          : isProjectManager
            ? apiKey.environments
            : [...apiKey.environments, hiddenEnvironmentsOptionId],
        expiresAt: {
          value: apiKey.expiresAt || getDefaultExpirationDate(),
          isValid: true,
        },
        sharedWithUsers: apiKey.sharedWithUsers,
      }),
    [
      reset,
      apiKey.hasPreviewDeliveryAccess,
      apiKey.hasSecureDeliveryAccess,
      apiKey.name,
      apiKey.hasAccessToAllEnvironments,
      apiKey.environments,
      apiKey.expiresAt,
      apiKey.sharedWithUsers,
      isProjectManager,
      getDefaultExpirationDate,
    ],
  );

  useEffect(() => {
    propagateApiKeyChangesToForm();
  }, [propagateApiKeyChangesToForm]);

  const canUpdateKey = isContainerScopedDapiKey(apiKey) && isProjectManager;
  const { current: apiKeyDetailToolbarSaveButtonContainerElement } = useObserveElementPresence(
    ApiKeyDetailToolbarSaveButtonElementId,
  );
  const allOptions =
    isProjectManager || !hiddenEnvironmentOption
      ? allEnvironmentOptions
      : [...allEnvironmentOptions, hiddenEnvironmentOption];

  return (
    <ApiKeyDetailLayout
      onAlertDismiss={onAlertDismiss}
      apiKeyName={keyName || UntitledDeliveryKeyName}
      apiKeyActionStatus={apiKeyActionStatus}
    >
      <DapiKeyFormContent
        allEnvironmentOptions={allOptions}
        apiKey={apiKey}
        canUpdateKey={canUpdateKey}
        formProps={formProps}
        isProjectManager={isProjectManager}
        isSecureAccessAvailable={isSecureAccessAvailable}
        projectContainerActiveUsers={projectContainerActiveUsers}
        onRegenerate={onRegenerate}
      />
      <HandleUnsavedApiKeyFormOnNavigation
        isDirty={isDirty}
        onSaveChanges={onSaveFromUnsavedChangesDialog}
      />
      {isElement(apiKeyDetailToolbarSaveButtonContainerElement) &&
        canUpdateKey &&
        createPortal(
          <Button
            buttonStyle="primary"
            onClick={onSaveChanges}
            {...getDataUiActionAttribute(DataUiAction.Save)}
          >
            Save changes
          </Button>,
          apiKeyDetailToolbarSaveButtonContainerElement,
        )}
    </ApiKeyDetailLayout>
  );
};

DapiKeyDetailForm.displayName = 'DapiKeyDetailForm';
