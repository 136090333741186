import React from 'react';
import { useVariantTranslation } from '../../../../../../../_shared/features/AI/hooks/useVariantTranslation.ts';
import { ContentItemId } from '../../../../../../../_shared/models/ContentItemId.type.ts';
import { DropDownOption } from '../../../../../../../_shared/uiComponents/DropDown/DropDownOption.tsx';
import { DropDownOptionName } from '../../../../../../../_shared/uiComponents/DropDown/DropDownOptionName.tsx';

type MenuOption = {
  readonly variantId: Uuid;
  readonly name: string;
  readonly isDisabled: boolean;
};

type TranslateFromLanguageOptionsProps = {
  readonly contentItemId: ContentItemId;
  readonly menuOptions: ReadonlyArray<MenuOption>;
  readonly onTranslationStarted: () => void;
  readonly projectEnvironmentId: Uuid;
  readonly userId: string;
};

export const TranslateFromLanguageOptions: React.FC<TranslateFromLanguageOptionsProps> = ({
  contentItemId,
  menuOptions,
  onTranslationStarted,
  projectEnvironmentId,
  userId,
}) => {
  const { startTranslation } = useVariantTranslation(
    projectEnvironmentId,
    contentItemId.itemId,
    contentItemId.variantId,
  );

  return (
    <>
      {menuOptions.map((option: MenuOption, index: number) => (
        <DropDownOption
          isDisabled={option.isDisabled}
          key={index}
          onClick={async () => {
            await startTranslation(option.variantId, userId).then(onTranslationStarted);
          }}
          slim
          tooltipText={
            option.isDisabled
              ? 'You can only translate from language variants assigned to you.'
              : undefined
          }
        >
          <DropDownOptionName text={option.name} />
        </DropDownOption>
      ))}
    </>
  );
};

TranslateFromLanguageOptions.displayName = 'TranslateFromLanguageOptions';
