import { InvariantException } from '@kontent-ai/errors';
import { assert } from '@kontent-ai/utils';
import { IStore } from 'client/app/_shared/stores/IStore.type';
import { History } from 'history';
import { List } from 'immutable';
import { Dispatch, GetState } from '../../../../../@types/Dispatcher.type.ts';
import {
  ContentItemRevisionViewerRoute,
  ContentItemRevisionViewerRouteParams,
  ContentItemsAppRouteSegment,
} from '../../../../../_shared/constants/routePaths.ts';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { loadWorkflows } from '../../../../../data/actions/thunkDataActions.ts';
import { getWorkflow } from '../../../../../data/reducers/workflow/selectors/workflowSelectors.ts';
import {
  ServerApiErrorCode,
  tryParseApiError,
} from '../../../../../repositories/serverModels/ServerApiError.ts';
import { AccessDeniedToContentItemAction } from '../../../../errorHandling/constants/AccessDeniedToContentItemAction.ts';
import { canFetchAnotherTimelineChunk } from '../../../../itemEditor/features/Revisions/utils/fetchTimelineItemsUtils.ts';
import {
  loadItemVariantTimeline,
  loadMoreVariantTimelineItems,
} from '../../../../itemEditor/features/Timeline/actions/thunkTimelineActions.ts';
import { TimelineItem } from '../../../../itemEditor/models/revisions/TimeLineItem.ts';
import { getAccessDeniedToContentItemRoute } from '../../utils/accessDeniedRouteUtils.ts';

const getLatestPublishedRevision = (
  timeline: List<TimelineItem>,
  state: IStore,
): TimelineItem | undefined => {
  return timeline.find((item: TimelineItem) => {
    if (!item.assignment?.workflowId || !item.assignment.workflowStepId) {
      return false;
    }

    const { workflowId } = item.assignment;
    const workflow = getWorkflow(state, workflowId);

    assert(
      workflow,
      () => `redirectToLatestPublishedRevision.ts: Did not find workflow with id: ${workflowId}`,
    );

    const publishedStepId = workflow.publishedStep.id;
    return item.assignment.workflowStepId === publishedStepId;
  });
};

export const redirectToLatestPublishedRevision =
  (
    history: History,
    projectId: Uuid,
    app: ContentItemsAppRouteSegment,
    variantId: Uuid,
    spaceId: Uuid | undefined,
    contentItemId: Uuid,
  ) =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      await dispatch(loadItemVariantTimeline(contentItemId, variantId));
    } catch (error) {
      const apiError = tryParseApiError(error);
      if (apiError && apiError.code === ServerApiErrorCode.MissingActivePermission) {
        history.push(
          getAccessDeniedToContentItemRoute(
            projectId,
            variantId,
            contentItemId,
            AccessDeniedToContentItemAction.View,
            history.location.pathname,
          ),
        );
        return;
      }
    }

    await dispatch(loadWorkflows());

    const state = getState();
    const { entryTimeline } = state.contentApp;

    if (!entryTimeline) {
      throw InvariantException('redirectToLatestPublishedRevision.ts: Timeline is not loaded.');
    }

    let latestPublishedRevision = getLatestPublishedRevision(entryTimeline, state);

    while (canFetchAnotherTimelineChunk(getState) && !latestPublishedRevision) {
      const nextTimelineChunk = await dispatch(
        loadMoreVariantTimelineItems(contentItemId, variantId),
      );
      latestPublishedRevision = getLatestPublishedRevision(nextTimelineChunk, state);
    }

    if (latestPublishedRevision) {
      const timelineItemPath = buildPath<ContentItemRevisionViewerRouteParams<UuidArray>>(
        ContentItemRevisionViewerRoute,
        {
          app,
          projectId,
          variantId,
          spaceId,
          contentItemIds: [contentItemId],
          timelineItemId: latestPublishedRevision.itemId,
        },
      );
      history.push(timelineItemPath);
    }
  };
