import { VerticalMenuItem, useVerticalMenu } from '@kontent-ai/component-library/VerticalMenu';
import React from 'react';
import { SelectGroupRenderMode } from '../../../../../component-library/components/StatusBar/Selects/types/SelectGroupRenderMode.ts';
import { StatefulDropDown } from '../../../uiComponents/DropDown/StatefulDropDown.tsx';
import {
  DataUiAction,
  DataUiCollection,
  ObjectWithDataAttribute,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { StatusBarDropDownSelected } from '../../StatusBar/StatusBarDropDownSelected.tsx';
import { SelectMenuItem } from '../SelectMenuItem.tsx';
import { optionMaxWidth, projectMenuTippyOptions } from '../constants.ts';
import { MenuItem } from '../types.type.ts';

export type SpaceMenuItem = MenuItem &
  VerticalMenuItem<SpaceMenuItem> & {
    readonly dataAttributes: ObjectWithDataAttribute;
    readonly isSelected: boolean;
    readonly rootItemId?: Uuid;
  };

type SpaceSelectProps = {
  readonly renderMode: SelectGroupRenderMode;
  readonly selectedSpaceName: string | null;
  readonly spaceItems: ReadonlyArray<SpaceMenuItem> | null;
};

export const SpaceSelect: React.FC<SpaceSelectProps> = ({
  spaceItems,
  selectedSpaceName,
  renderMode,
}) => {
  const {
    verticalMenuState: spaceVerticalMenuState,
    VerticalMenu,
    verticalMenuProps,
  } = useVerticalMenu<SpaceMenuItem>([
    {
      id: 'spaces',
      items: spaceItems ?? [],
      label: 'Spaces',
      type: 'section',
    },
  ]);

  return (
    <StatefulDropDown
      optionListDataUiAttributes={getDataUiCollectionAttribute(DataUiCollection.Spaces)}
      renderContent={(closeProjectList) => (
        <VerticalMenu
          {...verticalMenuProps}
          state={spaceVerticalMenuState}
          pinnedItemId="spaces-pinned"
          maxWidth={optionMaxWidth}
          renderItem={({ item }) =>
            item.value && <SelectMenuItem closeMenu={closeProjectList} item={item.value} />
          }
        />
      )}
      renderSelectedOption={(ref, toggleSpaceList, isOpen) => (
        <StatusBarDropDownSelected
          dataUiAttributes={getDataUiActionAttribute(DataUiAction.OpenSpacesMenu)}
          isExpanded={isOpen}
          onClick={toggleSpaceList}
          ref={ref}
          renderMode={renderMode}
        >
          {selectedSpaceName ?? ''}
        </StatusBarDropDownSelected>
      )}
      tippyOptions={projectMenuTippyOptions}
    />
  );
};

SpaceSelect.displayName = 'SpaceSelect';
