import { AriaCheckboxGroupProps, useCheckboxGroup } from '@react-aria/checkbox';
import { mergeProps } from '@react-aria/utils';
import { useCheckboxGroupState } from '@react-stately/checkbox';
import PropTypes from 'prop-types';
import React, { ReactNode } from 'react';
import { Box, BoxBorder } from '../../layout/Box/Box.tsx';
import { Column } from '../../layout/Row/Column.tsx';
import { Row } from '../../layout/Row/Row.tsx';
import { Stack } from '../../layout/Stack/Stack.tsx';
import {
  colorAlertIcon,
  colorAlertText,
  colorTextDefault,
  colorTextLowEmphasis,
} from '../../tokens/decision/colors.ts';
import { IconSize } from '../../tokens/quarks/iconSize.ts';
import { Spacing } from '../../tokens/quarks/spacing.ts';
import { Typography } from '../../tokens/quarks/typography.ts';
import { getDataUiComponentAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { CheckboxItem } from '../Checkbox/CheckboxItem.tsx';
import { CheckboxState, checkboxStateOptions } from '../Checkbox/types.ts';
import { Icons } from '../Icons/components/icons.ts';
import { CheckboxGroupContextProvider } from './CheckboxGroupContext.tsx';
import { checkboxGroupSpacingBetweenOptions } from './tokens.ts';

interface IGroupLabel {
  readonly groupLabel: string;
}

interface IAriaLabel {
  readonly ariaLabel: string;
}

type HasGroupLabelOrAriaLabel =
  | (IGroupLabel & Partial<IAriaLabel>)
  | (Partial<IGroupLabel> & IAriaLabel);

type CheckboxGroupBaseProps = {
  readonly alertMessage?: string;
  readonly checkboxGroupState?: CheckboxState;
  readonly groupSubLabel?: ReactNode;
  readonly onChange?: (value: readonly string[]) => void;
  readonly selectedValues: readonly string[];
};

export type CheckboxGroupProps = CheckboxGroupBaseProps & HasGroupLabelOrAriaLabel;

const propTypes: PropTypeMap<CheckboxGroupProps> = {
  alertMessage: PropTypes.string,
  ariaLabel: (props: CheckboxGroupProps, propName: string, componentName: string) => {
    if (!props.ariaLabel && !props.groupLabel) {
      return new Error(
        `[${propName}]: Please specify at least one prop from [ariaLabel, groupLabel] in component ${componentName}`,
      );
    }
    return null;
  },
  checkboxGroupState: PropTypes.oneOf(checkboxStateOptions),
  groupLabel: (props: CheckboxGroupProps, propName: string, componentName: string) => {
    if (!props.ariaLabel && !props.groupLabel) {
      return new Error(
        `[${propName}]: Please specify at least one prop from [ariaLabel, groupLabel] in component ${componentName}`,
      );
    }
    return null;
  },
  groupSubLabel: PropTypes.node,
  onChange: PropTypes.func,
  selectedValues: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export const CheckboxGroupComponent = React.forwardRef<
  HTMLFieldSetElement,
  React.PropsWithChildren<CheckboxGroupProps>
>((props, forwardedRef) => {
  const {
    alertMessage,
    ariaLabel,
    checkboxGroupState = 'default',
    children,
    groupLabel,
    groupSubLabel,
    selectedValues,
    ...otherProps
  } = props;

  const commonProps: AriaCheckboxGroupProps = {
    ...props,
    value: selectedValues as string[],
    description: groupSubLabel,
    errorMessage: alertMessage,
    isDisabled: checkboxGroupState === 'disabled',
    label: ariaLabel || groupLabel,
  };

  const state = useCheckboxGroupState(commonProps);

  const { groupProps, labelProps, descriptionProps, errorMessageProps } = useCheckboxGroup(
    commonProps,
    state,
  );

  return (
    <Box
      component="fieldset"
      ref={forwardedRef}
      {...getDataUiComponentAttribute(CheckboxGroupComponent)}
      {...mergeProps(groupProps, otherProps)}
      padding={0}
      border={BoxBorder.None}
      margin={0}
    >
      <CheckboxGroupContextProvider state={state}>
        <Stack spacing={Spacing.S} {...groupProps}>
          {(groupLabel || groupSubLabel) && (
            <Stack spacing={Spacing.L}>
              {groupLabel && (
                <Box
                  component="legend"
                  color={colorTextDefault}
                  overflowWrap="anywhere"
                  typography={Typography.LabelLarge}
                  {...labelProps}
                >
                  {groupLabel}
                </Box>
              )}
              {groupSubLabel && (
                <Box
                  color={colorTextLowEmphasis}
                  overflowWrap="anywhere"
                  typography={Typography.LabelLarge}
                  {...descriptionProps}
                >
                  {groupSubLabel}
                </Box>
              )}
            </Stack>
          )}

          <Stack spacing={checkboxGroupSpacingBetweenOptions}>{children}</Stack>

          {!!alertMessage && checkboxGroupState === 'alert' && (
            <Row spacing={Spacing.XS} noWrap>
              <Column width="content">
                <Icons.ExclamationTriangleInverted color={colorAlertIcon} size={IconSize.S} />
              </Column>
              <Column>
                <Box
                  color={checkboxGroupState === 'alert' ? colorAlertText : colorTextDefault}
                  overflowWrap="anywhere"
                  typography={Typography.TitleMedium}
                  {...errorMessageProps}
                >
                  {alertMessage}
                </Box>
              </Column>
            </Row>
          )}
        </Stack>
      </CheckboxGroupContextProvider>
    </Box>
  );
});

CheckboxGroupComponent.displayName = 'CheckboxGroup';
CheckboxGroupComponent.propTypes = propTypes;

export const CheckboxGroup = Object.assign(CheckboxGroupComponent, {
  Checkbox: CheckboxItem,
});
