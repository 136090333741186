import React from 'react';
import { DatetimePicker } from '../../../../components/DatetimePicker/DatetimePicker.tsx';
import { DateTime } from '../../../../models/DateTime.ts';
import { ModalSection } from '../ModalSection.tsx';

interface IDueDateSectionProps {
  readonly dueDate: DateTimeStamp | null;
  readonly onDueDateChanged: (dateTime: DateTime) => void;
  readonly autoFocus?: boolean;
}

export const DueDateSection: React.FC<IDueDateSectionProps> = (props) => (
  <ModalSection title="Due date" key="dueSection">
    <DatetimePicker defaultTime="16:00" onChange={props.onDueDateChanged} value={props.dueDate} />
  </ModalSection>
);

DueDateSection.displayName = 'DueDateSection';
