import PropTypes from 'prop-types';
import React from 'react';
import { ContentItemEditingEventOrigins } from '../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { EditingAction } from '../../../models/EditingAction.ts';
import { LeafActionFormProps } from '../EditingLeafActionForm.tsx';
import { ActionsMenuItemToActionMapper } from '../actionsMenu/ActionsMenuItemToActionMapper.tsx';

export type DuplicationOptionsOwnProps = Pick<LeafActionFormProps, 'actionOrigin'>;

type DuplicationMenuProps = DuplicationOptionsOwnProps & {
  readonly availableActions: readonly EditingAction[];
};

const propTypes: PropTypesShape<DuplicationMenuProps> = {
  availableActions: PropTypes.arrayOf(PropTypes.oneOf(Object.values(EditingAction))).isRequired,
  actionOrigin: PropTypes.oneOf(Object.values(ContentItemEditingEventOrigins)).isRequired,
};

export const DuplicationOptions: React.FC<DuplicationMenuProps> = (props) => {
  const actionsToRender = [
    EditingAction.duplicateItemWithContent,
    EditingAction.duplicateItemWithoutContent,
  ].filter((action) => props.availableActions.includes(action));

  return (
    <>
      {actionsToRender.map((action) => (
        <ActionsMenuItemToActionMapper
          action={action}
          actionOrigin={props.actionOrigin}
          key={action}
        />
      ))}
    </>
  );
};

DuplicationOptions.displayName = 'DuplicationOptions';
DuplicationOptions.propTypes = propTypes;
