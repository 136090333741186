import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import React, { RefObject, useRef } from 'react';
import { ContentItemsSearchArea } from '../../../applications/contentInventory/content/components/ContentItemsSearchArea.tsx';
import { EnsureInventoryRelatedData } from '../../../applications/contentInventory/content/features/ListingFilter/containers/EnsureInventoryRelatedData.tsx';
import { EnsureFullTextSearchStatus } from '../../../applications/contentInventory/shared/containers/EnsureFullTextSearchStatus.tsx';
import { ModalDialog } from '../../../applications/itemEditor/features/ContentItemEditing/components/modalDialog/ModalDialog.tsx';
import { Loader } from '../../components/Loader.tsx';
import { ShortcutSymbols } from '../../constants/shortcutSymbols.ts';
import { NotificationBar } from '../../containers/NotificationBar.tsx';
import { Button } from '../../uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../uiComponents/Button/buttonStyle.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

interface IProps {
  readonly getContentItemPath?: (contentItemId: Uuid) => string | null;
  readonly isFullTextSearchForceDisabled?: boolean;
  readonly isInitRetrieving: boolean;
  readonly onClose: () => void;
  readonly onInit: () => void;
  readonly renderItemFilter: () => JSX.Element;
  readonly renderScrollTable: (ref: RefObject<HTMLDivElement>) => JSX.Element;
  readonly titleBarText: string;
}

export const ModalContentItemSelectorLayout: React.FC<IProps> = (props) => {
  const contentPaneRef = useRef<HTMLDivElement>(null);

  return (
    <ModalDialog
      buttons={
        <Tooltip tooltipText="Cancel" placement="top" shortcuts={ShortcutSymbols.Escape}>
          <Button
            style={ButtonStyle.Secondary}
            onClick={props.onClose}
            {...getDataUiActionAttribute(DataUiAction.Cancel)}
          >
            Cancel
          </Button>
        </Tooltip>
      }
      onCancel={props.onClose}
      titleBarText={props.titleBarText}
      onInit={props.onInit}
    >
      <div className="canvas__workspace canvas__workspace--in-modal">
        <EnsureInventoryRelatedData>
          {props.renderItemFilter()}
          <section className="canvas__content">
            <div className="canvas__notifications">
              <NotificationBar />
            </div>
            <div
              ref={contentPaneRef}
              className="canvas__content-pane
                         canvas__content-pane--no-bottom-offset
                         canvas__content-pane--without-vertical-scrollbar
                         canvas__content-pane--in-modal"
            >
              <div className="row">
                <div className="col-sm-24">
                  <EnsureFullTextSearchStatus>
                    <ContentItemsSearchArea
                      isInDialog
                      isFullTextSearchForceDisabled={props.isFullTextSearchForceDisabled}
                    />
                    {props.isInitRetrieving ? <Loader /> : props.renderScrollTable(contentPaneRef)}
                  </EnsureFullTextSearchStatus>
                </div>
              </div>
            </div>
          </section>
        </EnsureInventoryRelatedData>
      </div>
    </ModalDialog>
  );
};

ModalContentItemSelectorLayout.displayName = 'ModalContentItemSelectorLayout';
