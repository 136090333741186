import { Box } from '@kontent-ai/component-library/Box';
import { Spacing } from '@kontent-ai/component-library/tokens';
import styled from 'styled-components';

export const BreadcrumbsWrapper = styled(Box)`
  &:not(:empty) {
    margin-top: ${Spacing.L}px;
  }
`;

BreadcrumbsWrapper.displayName = 'BreadcrumbsWrapper';
