import { Box } from '@kontent-ai/component-library/Box';
import { Button } from '@kontent-ai/component-library/Button';
import { Paper } from '@kontent-ai/component-library/Paper';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Stack } from '@kontent-ai/component-library/Stack';
import {
  BorderRadius,
  Color,
  Spacing,
  colorPrimary,
  px,
  spacingCard,
} from '@kontent-ai/component-library/tokens';
import PropTypes from 'prop-types';
import React, { ComponentProps } from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  IForwardedRefProps,
  forwardRef,
  forwardedRefProps,
} from '../../../../../_shared/utils/forwardedRefProps.tsx';
import {
  StyledDataPointContent,
  StyledDataPointLabel,
  StyledHighlightedDataPointContent,
  StyledHighlightedDataPointLabel,
} from './StyledOverviewBar.tsx';

export interface IDataPoint {
  readonly buttonProps?: ICustomButtonProps;
  readonly content: string | number;
  readonly contentUiElement?: DataUiElement;
  readonly label: string | number;
}

export interface IHighlightedDataPoint extends Omit<IDataPoint, 'buttonProps'> {
  readonly backgroundColor?: Color;
}

export interface ICustomButtonProps
  extends Omit<ComponentProps<typeof Button>, 'buttonStyle' | 'size'> {
  readonly text: string;
}

interface IOverviewBarProps extends IForwardedRefProps<HTMLDivElement> {
  readonly dataPoints: ReadonlyArray<IDataPoint>;
  readonly highlightedDataPoint: IHighlightedDataPoint;
}

const propTypes: PropTypesShape<IOverviewBarProps> = {
  ...forwardedRefProps,
  dataPoints: PropTypes.arrayOf(PropTypes.object).isRequired,
  highlightedDataPoint: PropTypes.object.isRequired,
};

const renderDataPoints = (dataPoints: ReadonlyArray<IDataPoint>) => (
  <>
    {dataPoints.map((dataPoint) => (
      <Column key={dataPoint.label}>
        <Stack spacing={Spacing.L}>
          <StyledDataPointLabel>{dataPoint.label}</StyledDataPointLabel>
          <StyledDataPointContent>{dataPoint.content}</StyledDataPointContent>
          {dataPoint.buttonProps && (
            <Button buttonStyle="secondary" size="medium" {...dataPoint.buttonProps}>
              {dataPoint.buttonProps?.text}
            </Button>
          )}
        </Stack>
      </Column>
    ))}
  </>
);

const OverviewBarWithRef: React.FC<IOverviewBarProps> = ({
  forwardedRef,
  dataPoints,
  highlightedDataPoint,
}) => (
  <Paper
    borderRadius={BorderRadius.L}
    ref={forwardedRef}
    css={`
        overflow: hidden;
        margin-bottom: ${px(Spacing.XL)};
      `}
  >
    <Row noWrap>
      <Column>
        <Box padding={spacingCard}>
          <Row spacing={spacingCard}>{renderDataPoints(dataPoints)}</Row>
        </Box>
      </Column>
      <Column width="content">
        <Box
          padding={Spacing.XL}
          backgroundColor={highlightedDataPoint.backgroundColor || colorPrimary}
          css="min-height: 100%"
        >
          <Stack align="center" spacing={Spacing.L}>
            <StyledHighlightedDataPointLabel>
              {highlightedDataPoint.label}
            </StyledHighlightedDataPointLabel>
            <StyledHighlightedDataPointContent
              {...(highlightedDataPoint.contentUiElement &&
                getDataUiElementAttribute(highlightedDataPoint.contentUiElement))}
            >
              {highlightedDataPoint.content}
            </StyledHighlightedDataPointContent>
          </Stack>
        </Box>
      </Column>
    </Row>
  </Paper>
);

OverviewBarWithRef.displayName = 'OverviewBar';
OverviewBarWithRef.propTypes = propTypes;

export const OverviewBar = forwardRef(OverviewBarWithRef);
