import React from 'react';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { ContentItemPaneElementsGroup } from '../../../../../itemEditor/features/ContentItemEditing/components/ContentItemPaneElementsGroup.tsx';
import { Guidelines } from '../../../../../itemEditor/features/ContentItemEditing/components/elements/Guidelines.tsx';
import { ICompiledContentItemElementData } from '../../../../../itemEditor/models/contentItemElements/ICompiledContentItemElement.ts';
import { getItemElementOrDefault } from '../../../../../itemEditor/stores/utils/contentItemElementsUtils.ts';
import { isGuidelinesTypeElement } from '../../../../content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { AssetElement } from './AssetElement.tsx';
import { AssetTaxonomyOnboarding } from './AssetTaxonomyOnboarding.tsx';

interface IAssetElementsProps {
  readonly disabled: boolean;
  readonly elements: ReadonlyArray<ICompiledContentItemElementData>;
  readonly onChange: (updatedElement: ICompiledContentItemElementData) => void;
}

export const AssetElements: React.FC<IAssetElementsProps> = ({ disabled, elements, onChange }) => {
  const typeElements = useSelector(
    (state) => state.data.assetTypes.defaultAssetType?.contentElements ?? [],
  );

  if (typeElements.length === 0) {
    return <AssetTaxonomyOnboarding />;
  }

  return (
    <ContentItemPaneElementsGroup
      isDisabled={disabled}
      hasTopRoundedCorners
      hasBottomRoundedCorners
    >
      {typeElements.map((typeElement) => {
        if (isGuidelinesTypeElement(typeElement)) {
          return (
            <Guidelines key={typeElement.elementId} disabled={disabled} typeElement={typeElement} />
          );
        }

        return (
          <AssetElement
            disabled={disabled}
            elementData={getItemElementOrDefault(typeElement, elements)}
            key={typeElement.elementId}
            onChange={onChange}
            typeElement={typeElement}
          />
        );
      })}
    </ContentItemPaneElementsGroup>
  );
};

AssetElements.displayName = 'AssetElements';
