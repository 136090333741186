import { InvariantException } from '@kontent-ai/errors';
import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../@types/Dispatcher.type.ts';
import {
  NotificationBar,
  NotificationBarProps,
  NotificationBarType,
} from '../../../../../_shared/components/NotificationBar.tsx';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { DataUiElement } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { closeConvertContentComponentToItemNotificationBar } from '../actions/contentItemEditingActions.ts';

interface IStateProps {
  readonly message: ReactNode;
  readonly type: NotificationBarType;
  readonly uiElement: DataUiElement;
  readonly itemId: Uuid;
}

type DispatchProp = { dispatch: Dispatch };

function mapStateToProps(state: IStore): IStateProps {
  const editedItem = state.contentApp.editedContentItem;
  if (!editedItem) {
    throw InvariantException('Edited content item is not defined.');
  }

  const itemId = editedItem.id;
  const showMessage = state.contentApp.editorUi.convertedItemIds.contains(itemId);
  return {
    type: NotificationBarType.InfoLight,
    message: showMessage ? 'Now you can reuse your content. Don’t forget to name the item!' : null,
    uiElement: DataUiElement.ContentComponentConvertedToItemInfo,
    itemId,
  };
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProp => ({
  dispatch,
});

function mergeProps(stateProps: IStateProps, { dispatch }: DispatchProp): NotificationBarProps {
  return {
    type: stateProps.type,
    message: stateProps.message,
    uiElement: stateProps.uiElement,
    onClosePanel: () =>
      dispatch(closeConvertContentComponentToItemNotificationBar(stateProps.itemId)),
  };
}

export const ContentComponentConvertedToItemNotificationBar: React.ComponentType = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(NotificationBar);
