const stop = (e: any) => {
  e.stopPropagation();
};

export const getStopEventsConsumedByDraftJs = (allowDrop: boolean) => ({
  onBeforeInput: stop,
  onBlur: stop,
  onClick: stop,
  onCompositionEnd: stop,
  onCompositionStart: stop,
  onCopy: stop,
  onCut: stop,
  ...(allowDrop ? undefined : { onDrop: stop }),
  onInput: stop,
  onKeyDown: stop,
  onKeyPress: stop,
  onKeyUp: stop,
  onMouseDown: stop,
  onMouseUp: stop,
  onPaste: stop,
  onSelect: stop,

  // Element must have enabled focus, otherwise it would not catch and stop propagation of focus event.
  tabIndex: 0,
  onFocus: stop,
});
