import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../@types/Dispatcher.type.ts';
import {
  EnsureValidState,
  IEnsureValidStateDispatchProps,
  IEnsureValidStateStateProps,
} from '../../../../_shared/components/EnsureValidState.tsx';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { loadFullTextSearchStatus } from '../actions/thunkContentInventoryActions.ts';
import { FullTextSearchStatus } from '../reducers/IContentInventoryStoreState.type.ts';

const mapStateToProps = (state: IStore): IEnsureValidStateStateProps => {
  const {
    contentInventory: { fullTextSearchStatus },
  } = state;

  return {
    isStateEnsured: fullTextSearchStatus !== FullTextSearchStatus.Initial,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IEnsureValidStateDispatchProps => ({
  onRouteEntered: () => dispatch(loadFullTextSearchStatus()),
});

export const EnsureFullTextSearchStatus: React.ComponentType<React.PropsWithChildren<NoProps>> =
  connect(mapStateToProps, mapDispatchToProps)(EnsureValidState);
