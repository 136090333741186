import { useMemo } from 'react';
import { useEditorApi } from '../../editorCore/hooks/useEditorApi.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { None, Optional } from '../../editorCore/types/Editor.contract.type.ts';
import { EditorPlugin } from '../../editorCore/types/Editor.plugins.type.ts';
import { withDisplayName } from '../../editorCore/utils/withDisplayName.ts';
import { ApiLimitationsPlugin } from '../apiLimitations/ApiLimitationsPlugin.tsx';
import { ApiStatisticsPlugin } from '../apiStatistics/ApiStatisticsPlugin.tsx';
import { TextBlockTypesPlugin } from '../textBlockTypes/TextBlockTypesPlugin.tsx';
import { EditorTextApi } from './api/EditorTextApi.type.ts';
import { editorTextApi } from './api/editorTextApi.ts';

export type TextApiPlugin = EditorPlugin<
  None,
  None,
  EditorTextApi,
  [Optional<TextBlockTypesPlugin>, ApiLimitationsPlugin, Optional<ApiStatisticsPlugin>]
>;

export const useTextApi: PluginCreator<TextApiPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('TextApiPlugin', {
        ComposedEditor: (props) => {
          const { getApiMethods } = useEditorApi<TextApiPlugin>(editorTextApi);

          return useEditorWithPlugin<TextApiPlugin>(baseEditor, props, { getApiMethods });
        },
      }),
    [baseEditor],
  );
