import { BaseColor, colorAlertBackgroundInverse } from '@kontent-ai/component-library/tokens';
import { allCollectionsOption, allLanguagesOption } from '../constants/userListingFilter.ts';
import {
  ICollectionFilterOption,
  ILanguageFilterOption,
} from '../models/UserListingFilterOption.type.ts';

export const getFilterTagColor = (
  option: ICollectionFilterOption | ILanguageFilterOption,
): BaseColor | undefined =>
  option === allCollectionsOption || option === allLanguagesOption ? BaseColor.Gray30 : undefined;

export const getFilterLanguageTagColor = (
  language: ILanguageFilterOption,
): BaseColor | undefined =>
  language.inactive ? colorAlertBackgroundInverse : getFilterTagColor(language);
