import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { IgnoreByGrammarly } from '../../../../../../../_shared/components/IgnoreByGrammarly.tsx';
import { getFileSizeObject } from '../../../../../../../_shared/utils/fileSizeUtils.ts';
import { getFileSizeOptionName } from '../../../../../../contentInventory/content/models/fileSizeOptions.ts';

interface IFormattedFileSize {
  fileSizeInBytes?: number | null;
  className?: string;
  wrapperClassName?: string;
  invalidClassName?: string;
  messageBefore?: string;
}

export const FormattedFileSize: React.FC<IFormattedFileSize> = ({
  messageBefore,
  fileSizeInBytes,
  className,
  invalidClassName,
  wrapperClassName,
}: IFormattedFileSize): JSX.Element | null => {
  if (!fileSizeInBytes) {
    return null;
  }

  const fileSizeObject = getFileSizeObject(fileSizeInBytes);

  if (!fileSizeObject) {
    return null;
  }

  return (
    <span className={wrapperClassName}>
      {messageBefore}
      <span className={classNames(className, invalidClassName)}>
        <IgnoreByGrammarly>
          {fileSizeObject?.fileSize}&nbsp;
          {fileSizeObject && getFileSizeOptionName(fileSizeObject.unit)}
        </IgnoreByGrammarly>
      </span>
    </span>
  );
};

FormattedFileSize.displayName = 'FormattedFileSize';
const propTypes: PropTypesShape<IFormattedFileSize> = {
  className: PropTypes.string,
  invalidClassName: PropTypes.string,
  fileSizeInBytes: PropTypes.number,
  messageBefore: PropTypes.string,
  wrapperClassName: PropTypes.string,
};
FormattedFileSize.propTypes = propTypes;
