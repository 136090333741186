import React from 'react';
import { TaskForm, TaskFormContainerProps } from '../../../containers/details/Tasks/TaskForm.tsx';
import { TaskFormSharedProps } from './TaskForm.tsx';

export const EditTaskFormBase: React.FC<TaskFormContainerProps & TaskFormSharedProps> = (props) => (
  <TaskForm
    {...props}
    submitButtonName="Save"
    submitButtonText={props.isTaskSubmitting ? 'Saving…' : 'Save'}
  />
);

EditTaskFormBase.displayName = 'EditTaskFormBase';
