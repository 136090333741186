import { IconName, Icons } from '@kontent-ai/component-library/Icons';
import PropTypes from 'prop-types';
import React from 'react';
import { DropDownOption } from '../../../../../../../_shared/uiComponents/DropDown/DropDownOption.tsx';
import {
  Color,
  DropDownOptionBadge,
} from '../../../../../../../_shared/uiComponents/DropDown/DropDownOptionBadge.tsx';
import { DropDownOptionName } from '../../../../../../../_shared/uiComponents/DropDown/DropDownOptionName.tsx';
import { DropDownOptionSubmenuMark } from '../../../../../../../_shared/uiComponents/DropDown/DropDownOptionSubmenuMark.tsx';
import { ObjectWithDataAttribute } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { EditingActionsLinkWrapper } from '../actions/EditingActionsLinkWrapper.tsx';

export type ActionsMenuItemProps = {
  readonly badgeColor?: Color;
  readonly badgeValue?: number;
  readonly dataAttributes?: ObjectWithDataAttribute;
  readonly disabled?: boolean;
  readonly iconName: IconName;
  readonly isDestructive?: boolean;
  readonly isSlim?: boolean;
  readonly onClick: () => void;
  readonly outwardLinkUrl?: string;
  readonly text: string;
  readonly tooltip?: string;
  readonly withSubmenu?: boolean;
};

const propTypes: PropTypeMap<ActionsMenuItemProps> = {
  badgeColor: PropTypes.oneOf(Object.values(Color)),
  badgeValue: PropTypes.number,
  dataAttributes: PropTypes.object,
  disabled: PropTypes.bool,
  iconName: PropTypes.oneOf(Object.keys(Icons)).isRequired,
  isDestructive: PropTypes.bool,
  isSlim: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  outwardLinkUrl: PropTypes.string,
  text: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  withSubmenu: PropTypes.bool,
};

export const ActionsMenuItem: React.FC<ActionsMenuItemProps> = ({
  badgeColor,
  badgeValue,
  dataAttributes,
  disabled,
  iconName,
  isDestructive,
  isSlim = true,
  onClick,
  outwardLinkUrl,
  text,
  tooltip,
  withSubmenu,
}) => {
  const Icon = Icons[iconName];

  return (
    <EditingActionsLinkWrapper
      disabled={disabled}
      onClick={onClick}
      outwardLinkUrl={outwardLinkUrl}
    >
      <DropDownOption
        dataUiAttributes={dataAttributes}
        isDisabled={disabled}
        isDestructive={isDestructive}
        onClick={onClick}
        slim={isSlim}
        tooltipText={tooltip}
      >
        <div className="actions-menu-item">
          <Icon className="actions-menu-item__icon" />
          <DropDownOptionName text={text} />
          {!!badgeValue && !!badgeColor && (
            <DropDownOptionBadge badgeColor={badgeColor} badgeValue={badgeValue} />
          )}
        </div>
        {withSubmenu && <DropDownOptionSubmenuMark />}
      </DropDownOption>
    </EditingActionsLinkWrapper>
  );
};

ActionsMenuItem.displayName = 'ActionsMenuItem';
ActionsMenuItem.propTypes = propTypes;
