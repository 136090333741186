import PropTypes from 'prop-types';
import React from 'react';
import { ActionsMenuGroup } from '../../../../../../itemEditor/features/ContentItemEditing/components/editingActions/actionsMenu/ActionsMenuGroup.tsx';
import { Menu } from '../../../../../../itemEditor/features/ContentItemEditing/constants/Menu.ts';
import { EditingAction } from '../../../../../../itemEditor/features/ContentItemEditing/models/EditingAction.ts';
import { floatingEditorMenuStructure } from './floatingEditorMenuStructure.ts';

interface IMoreActionsMainMenuProps {
  readonly actions: readonly EditingAction[];
}

const propTypes: PropTypesShape<IMoreActionsMainMenuProps> = {
  actions: PropTypes.arrayOf(PropTypes.oneOf(Object.values(EditingAction))).isRequired,
};

export const MoreActionsMainMenu: React.FC<IMoreActionsMainMenuProps> = ({ actions }) => (
  <div className="content-editing-actions__main-menu" key={Menu.MainMenu}>
    {floatingEditorMenuStructure.map((group) => (
      <ActionsMenuGroup
        key={group.key}
        allGroupActions={group.actions}
        availableActions={actions}
        title={group.title}
        isSlim={false}
      />
    ))}
  </div>
);

MoreActionsMainMenu.displayName = 'MoreActionsMainMenu';
MoreActionsMainMenu.propTypes = propTypes;
