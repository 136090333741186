import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { IProjectSpecificStorage } from '../../../../localStorages/projectSpecificStorage.ts';
import { IWebSpotlightPreferences } from '../../../../localStorages/webSpotlightPreferencesStorage.ts';
import { WebSpotlight_Space_Selected } from '../../constants/webSpotlightActionTypes.ts';

type Deps = {
  readonly loadWebSpotlightTree: (
    rootItemId: Uuid,
    spaceId: Uuid | undefined,
    path: string,
    abortSignal?: AbortSignal,
  ) => ThunkPromise;
  readonly webSpotlightPreferencesStorage: IProjectSpecificStorage<IWebSpotlightPreferences>;
};

const spaceSelected = (spaceId: Uuid | null, rootItemId: Uuid | null) =>
  ({
    type: WebSpotlight_Space_Selected,
    payload: {
      spaceId,
      rootItemId,
    },
  }) as const;

export type SelectSpaceActionsType = ReturnType<typeof spaceSelected>;

export const createSelectSpaceAction =
  (deps: Deps) =>
  (
    spaceId: Uuid | null,
    rootItemId: Uuid | null,
    path: string,
    abortSignal?: AbortSignal,
  ): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      sharedApp: { currentProjectId },
    } = getState();

    dispatch(spaceSelected(spaceId, rootItemId));
    deps.webSpotlightPreferencesStorage.update({ spaceId }, currentProjectId);

    if (rootItemId) {
      await dispatch(
        deps.loadWebSpotlightTree(rootItemId, spaceId || undefined, path, abortSignal),
      );
    }
  };
