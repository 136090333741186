import PropTypes from 'prop-types';
import React from 'react';
import { CLPropTypes } from '../../validators/propTypes.ts';
import { StyledTextSkeleton } from './components/StyledTextSkeleton.tsx';
import { SkeletonTypeToSkeletonBorderRadiusMap } from './types.ts';

type TextSkeletonProps = Readonly<{
  width: number | string;
  children?: never;
}>;

const textSkeletonPropTypes: PropTypeMap<TextSkeletonProps> = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  children: CLPropTypes.never,
};

export const TextSkeleton = React.forwardRef<HTMLElement, TextSkeletonProps>((props, ref) => (
  <StyledTextSkeleton
    $width={props.width}
    borderRadius={SkeletonTypeToSkeletonBorderRadiusMap.text}
    ref={ref}
  />
));

TextSkeleton.displayName = 'TextSkeleton';
TextSkeleton.propTypes = textSkeletonPropTypes;
