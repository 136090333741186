import { connect } from 'react-redux';
import { Dispatch } from '../../../../../../@types/Dispatcher.type.ts';
import { SidebarNames } from '../../../../../../_shared/models/SidebarNames.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { getDiscussionsThreads } from '../../../../utils/commentUtils.ts';
import { discussionsSidebarClosed } from '../../actions/contentItemEditingActions.ts';
import {
  DiscussionsSidebar as DiscussionsSidebarComponent,
  IDiscussionsSidebarDispatchProps,
  IDiscussionsSidebarStateProps,
} from '../../components/comments/DiscussionsSidebar.tsx';
import { getCommentsOnRemovedContent } from '../../selectors/inlineCommentSelectors.ts';

const mapStateToProps = (state: IStore): IDiscussionsSidebarStateProps => {
  const {
    sharedApp: { sidebarStatus },
    contentApp: { editedContentItemVariantComments },
  } = state;

  const isDiscussionsSidebarOpen =
    sidebarStatus.isVisible && sidebarStatus.sidebarName === SidebarNames.DiscussionsSidebar;

  const commentThreads = getDiscussionsThreads(editedContentItemVariantComments.commentThreads);
  const commentsOnRemovedContent = getCommentsOnRemovedContent(state);

  return {
    isOpened: isDiscussionsSidebarOpen,
    commentThreads,
    commentsOnRemovedContent,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IDiscussionsSidebarDispatchProps => ({
  onClose: () => dispatch(discussionsSidebarClosed()),
});

export const DiscussionsSidebar: React.ComponentType = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DiscussionsSidebarComponent);
