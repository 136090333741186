import React, { useEffect } from 'react';
import { trackUserEventWithData } from '../../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { ContentItemEditingEventTypes } from '../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { ContentItemCodenameSavingStatus } from '../../../../../models/contentItem/ContentItemCodenameSavingStatus.ts';
import {
  contentItemCodenameEditorClosed,
  contentItemCodenameEditorErrorStatusCleared,
  contentItemCodenameEditorOpened,
  deactivateContentItemEditingAction,
} from '../../../actions/contentItemEditingActions.ts';
import { saveContentItemCodenameToServer } from '../../../actions/thunkContentItemEditingActions.ts';
import { LeafActionFormProps } from '../../../components/editingActions/EditingLeafActionForm.tsx';
import { CodenameForm as CodenameFormComponent } from '../../../components/editingActions/leafActionForms/CodenameForm.tsx';
import {
  CodenameChangeMayBreakYourAppMessage,
  CodenameEditingPossibleOnlyInDefaultLanguage,
  CodenameIsAutoGeneratedMessage,
} from '../../../constants/uiConstants.ts';
import { isContentItemNameAndCodenameEditingAllowed } from '../../../utils/itemEditingUtils.ts';

export const CodenameForm: React.FC<LeafActionFormProps> = (props) => {
  const dispatch = useDispatch();
  const codename = useSelector((s) => s.contentApp.editedContentItem?.codename ?? '');
  const isCodenameAutogenerated = useSelector(
    (s) => s.contentApp.editorUi.codenameEditor.isCodenameAutogenerated,
  );
  const codenameSavingStatus = useSelector(
    (s) => s.contentApp.editorUi.codenameEditor.codenameSavingStatus,
  );
  const isCodenameEditingAllowed = useSelector((s) =>
    isContentItemNameAndCodenameEditingAllowed(
      s.contentApp.contentItemVariants,
      s.data.languages.defaultLanguage.id,
      getSelectedLanguageIdOrThrow(s),
    ),
  );
  const disabledEditingMessage = isCodenameEditingAllowed
    ? null
    : CodenameEditingPossibleOnlyInDefaultLanguage;
  const footnoteMessage = isCodenameAutogenerated ? CodenameIsAutoGeneratedMessage : null;
  const isBeingSaved = codenameSavingStatus === ContentItemCodenameSavingStatus.IsBeingSaved;
  const isCodenameUnique =
    codenameSavingStatus !== ContentItemCodenameSavingStatus.CodenameNotUniqueError;
  const friendlyWarningMessage = isCodenameAutogenerated
    ? undefined
    : CodenameChangeMayBreakYourAppMessage;

  const onCodenameChange = (newCodename: string) =>
    dispatch(saveContentItemCodenameToServer(newCodename));
  const onCopied = () => {
    dispatch(
      trackUserEventWithData(TrackedEvent.ContentItemEditing, {
        action: ContentItemEditingEventTypes.Codename,
        origin: props.actionOrigin,
      }),
    );
  };
  const onCopyCompleted = () => dispatch(deactivateContentItemEditingAction());
  const onDiscardChanges = () => dispatch(contentItemCodenameEditorErrorStatusCleared());

  useEffect(() => {
    dispatch(contentItemCodenameEditorOpened());
    return () => {
      dispatch(contentItemCodenameEditorClosed());
    };
  }, []);

  return (
    <CodenameFormComponent
      codename={codename}
      isCodenameUnique={isCodenameUnique}
      disabledEditingMessage={disabledEditingMessage}
      footnoteMessage={footnoteMessage}
      friendlyWarningMessage={friendlyWarningMessage}
      isBeingSaved={isBeingSaved}
      onCodenameChange={onCodenameChange}
      onDiscardChanges={onDiscardChanges}
      onCopyCompleted={onCopyCompleted}
      onCopied={onCopied}
    />
  );
};

CodenameForm.displayName = 'CodenameForm';
