import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { ITaxonomyRepository } from '../../../../../repositories/interfaces/ITaxonomyRepository.type.ts';
import { ITaxonomyGroupServerModel } from '../../../../../repositories/serverModels/contentModels/TaxonomyGroupServerModel.type.ts';
import {
  Taxonomy_App_Initialized,
  Taxonomy_Groups_LoadingStarted,
} from '../../constants/taxonomyActionTypes.ts';

interface ICreateLoadTaxonomyGroupsActionDependencies {
  readonly taxonomyRepository: ITaxonomyRepository;
  readonly loadUsers: (abortSignal?: AbortSignal) => ThunkPromise;
}

const taxonomyGroupsLoadingStarted = () =>
  ({
    type: Taxonomy_Groups_LoadingStarted,
  }) as const;

const taxonomyAppInitialized = (taxonomyGroups: readonly ITaxonomyGroupServerModel[]) =>
  ({
    type: Taxonomy_App_Initialized,
    payload: {
      taxonomyGroups,
    },
  }) as const;

export type LoadTaxonomyGroupsActionsType = ReturnType<
  typeof taxonomyGroupsLoadingStarted | typeof taxonomyAppInitialized
>;

export const createLoadTaxonomyGroupsAction =
  (deps: ICreateLoadTaxonomyGroupsActionDependencies) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(taxonomyGroupsLoadingStarted());

    const [serverGroups] = await Promise.all([
      deps.taxonomyRepository.getTaxonomyGroups(abortSignal),
      dispatch(deps.loadUsers(abortSignal)),
    ]);

    dispatch(taxonomyAppInitialized(serverGroups));
  };
