import { ITooltipProps, tooltipPropTypes } from '../Tooltip/Tooltip.tsx';
import { unrequired } from './unrequired.ts';

export type TooltipPropsExtension = Readonly<{
  tooltipMaxGridUnitsWidth?: ITooltipProps['maxGridUnitsWidth'];
  tooltipPlacement?: ITooltipProps['placement'];
  tooltipShortcuts?: ITooltipProps['shortcuts'];
  tooltipText?: ITooltipProps['tooltipText'];
}>;

export const tooltipExtensionPropTypes: PropTypeMap<TooltipPropsExtension> = {
  tooltipMaxGridUnitsWidth: tooltipPropTypes.maxGridUnitsWidth,
  tooltipPlacement: unrequired(tooltipPropTypes.placement),
  tooltipShortcuts: tooltipPropTypes.shortcuts,
  tooltipText: tooltipPropTypes.tooltipText,
};
