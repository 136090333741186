import { DefaultVariantId } from '../../../../../_shared/constants/variantIdValues.ts';
import {
  ContentItemId,
  MemoizedContentItemId,
} from '../../../../../_shared/models/ContentItemId.type.ts';

export const groupItemIdsByVariantId = (
  contentItemIds: ReadonlyArray<ContentItemId>,
): ReadonlyMap<Uuid, ReadonlyArray<Uuid>> =>
  contentItemIds.reduce((result, id) => {
    const { itemId, variantId } = id;
    const current = result.get(variantId) ?? [];
    return result.set(variantId, [...current, itemId]);
  }, new Map<Uuid, ReadonlyArray<Uuid>>());

export const groupContentItemIdsByItemIdWithRelevantVariantFirst = (
  contentItemIds: ReadonlyArray<MemoizedContentItemId>,
): ReadonlyMap<Uuid, ReadonlyArray<MemoizedContentItemId>> =>
  contentItemIds.reduce((result, id) => {
    const { itemId, variantId } = id;
    const current = result.get(itemId) ?? [];
    return result.set(itemId, variantId === DefaultVariantId ? [...current, id] : [id, ...current]);
  }, new Map<Uuid, ReadonlyArray<MemoizedContentItemId>>());
