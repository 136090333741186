import { useAttachRef, useEnsuredContext } from '@kontent-ai/hooks';
import { AriaMenuOptions, useMenu } from '@react-aria/menu';
import { AriaPopoverProps, DismissButton, Overlay, usePopover } from '@react-aria/overlays';
import { mergeProps } from '@react-aria/utils';
import { animated, useTransition } from '@react-spring/web';
import { OverlayTriggerState } from '@react-stately/overlays';
import React, { useContext, PropsWithChildren } from 'react';
import { Paper, PaperProps } from '../../containers/Paper/Paper.tsx';
import { Box } from '../../layout/Box/Box.tsx';
import { spacingPopupDistance } from '../../tokens/decision/spacing.ts';
import { defaultTippyZIndex } from '../../tokens/decision/zIndex.ts';
import { Spacing } from '../../tokens/quarks/spacing.ts';
import { VerticalMenuProps } from '../VerticalMenu/VerticalMenu.tsx';
import {
  verticalMenuMaxHeight,
  verticalMenuMaxWidth,
  verticalMenuMinWidth,
} from '../VerticalMenu/decisionTokens.ts';
import {
  DescendantContext,
  DescendantContextProvider,
  useDescendantContextInit,
} from './DescendantContext.tsx';
import { MenuContext } from './MenuContext.tsx';
import { SubmenuContext } from './SubmenuContext.tsx';

const dropDownTransitions = {
  from: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
  },
  leave: {
    opacity: 0,
  },
};

type PopoverProps = Omit<AriaPopoverProps, 'popoverRef' | 'maxHeight'> &
  Pick<PaperProps, 'minWidth' | 'maxWidth' | 'maxHeight' | 'width'> &
  Readonly<{
    state: OverlayTriggerState;
  }>;

const MenuPopover = React.forwardRef<HTMLDivElement, PropsWithChildren<PopoverProps>>(
  ({ children, state, maxHeight, ...props }, forwardedRef) => {
    const { refObject, refToForward } = useAttachRef(forwardedRef);
    const { popoverProps, underlayProps } = usePopover(
      {
        ...props,
        offset: spacingPopupDistance,
        popoverRef: refObject,
      },
      state,
    );

    const transitions = useTransition(state.isOpen, {
      ...dropDownTransitions,
      config: {
        duration: 150,
      },
    });

    return transitions(
      (style, item) =>
        item && (
          <Overlay>
            <div
              {...underlayProps}
              style={{
                position: 'fixed',
                inset: 0,
              }}
            />
            <div
              {...popoverProps}
              ref={refToForward}
              style={{
                ...popoverProps.style,
                zIndex: defaultTippyZIndex,
              }}
            >
              <animated.div style={style}>
                <Paper overflow="hidden">
                  <Box
                    paddingY={Spacing.S}
                    overflowX="hidden"
                    overflowY="auto"
                    tabIndex={-1}
                    {...props}
                  >
                    <DismissButton onDismiss={() => state.close()} />
                    {children}
                    <DismissButton onDismiss={() => state.close()} />
                  </Box>
                </Paper>
              </animated.div>
            </div>
          </Overlay>
        ),
    );
  },
);

const InitializedMenu = React.forwardRef<HTMLDivElement, PropsWithChildren<MenuListInnerProps>>(
  (props, forwardedRef) => {
    const { refObject, refToForward } = useAttachRef(forwardedRef);

    const { state } = useEnsuredContext(DescendantContext);
    const { menuProps } = useMenu(
      {
        ...props,
        autoFocus: 'first',
        // TODO: allow selection
        selectionMode: 'none',
      },
      state,
      refObject,
    );

    return (
      <div ref={refToForward} {...menuProps}>
        {props.children}
      </div>
    );
  },
);

type MenuListInnerProps = Omit<AriaMenuOptions<any>, 'items'>;

const MenuListInner = React.forwardRef<HTMLDivElement, PropsWithChildren<MenuListInnerProps>>(
  (props, forwardedRef) => {
    const descendantContextProps = useDescendantContextInit();
    const { descendants, state } = descendantContextProps;
    const isMenuInitialized = descendants.length && state.collection.size;

    return (
      <DescendantContextProvider {...descendantContextProps}>
        {isMenuInitialized ? <InitializedMenu ref={forwardedRef} {...props} /> : props.children}
      </DescendantContextProvider>
    );
  },
);

type MenuListProps = Omit<VerticalMenuProps<any>, 'items' | 'renderItem' | 'state'> &
  Pick<AriaPopoverProps, 'placement'>;

export const MenuList = React.forwardRef<HTMLDivElement, MenuListProps>(
  (
    {
      children,
      contain = true,
      placement,
      restoreFocus = true,
      maxHeight = verticalMenuMaxHeight,
      maxWidth = verticalMenuMaxWidth,
      minWidth = verticalMenuMinWidth,
      width,
      ...otherProps
    },
    forwardedRef,
  ) => {
    const menuContext = useEnsuredContext(MenuContext);

    const submenuContext = useContext(SubmenuContext);
    const isSubmenu = !!submenuContext;

    const sizeProps = {
      maxHeight,
      maxWidth,
      minWidth,
      width,
    };

    if (isSubmenu && submenuContext.submenuTriggerState) {
      return (
        <MenuPopover
          placement={placement ?? 'right'}
          state={submenuContext.submenuTriggerState}
          triggerRef={submenuContext.submenuTriggerRef}
          ref={forwardedRef}
          {...mergeProps(submenuContext.popoverProps, sizeProps)}
        >
          <MenuListInner
            ref={submenuContext.submenuListRef}
            {...mergeProps(submenuContext.submenuListProps, otherProps)}
          >
            {children}
          </MenuListInner>
        </MenuPopover>
      );
    }
    if (menuContext.menuTriggerState) {
      return (
        <MenuPopover
          placement={placement ?? 'bottom'}
          state={menuContext.menuTriggerState}
          triggerRef={menuContext.menuTriggerRef}
          ref={forwardedRef}
          {...sizeProps}
        >
          <MenuListInner
            ref={menuContext.menuListRef}
            {...mergeProps(menuContext.menuListProps, otherProps)}
          >
            {children}
          </MenuListInner>
        </MenuPopover>
      );
    }

    return null;
  },
);
