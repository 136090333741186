import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../@types/Action.type.ts';
import {
  AssetLibrary_AssetEditorDialog_InitStarted,
  AssetLibrary_AssetEditor_InitStarted,
  AssetLibrary_AssetEditor_ModalClosed,
  AssetLibrary_AssetEditor_SaveFailed,
} from '../../../applications/contentInventory/assets/constants/assetLibraryActionTypes.ts';
import { assetAlreadyModifiedErrorMessage } from '../../../applications/contentInventory/assets/constants/uiConstants.ts';
import {
  ContentListing_CancelScheduledPublish_Started,
  ContentListing_Init_Started,
  ContentListing_MoveToArchivedStep_Started,
  ContentListing_PublishItems_Started,
  ContentListing_RestoreFromArchivedStep_Started,
  ContentListing_ScheduledPublishItems_Started,
  ContentListing_ScheduledUnpublishItems_Started,
  ContentListing_UnpublishItems_Started,
  ContentListing_UpdateStandardStep_Started,
} from '../../../applications/contentInventory/content/features/ContentItemInventory/constants/contentItemInventoryActionTypes.ts';
import {
  ListingFilter_CreateLinkedFilter_Failed,
  ListingFilter_DeleteSavedFilter_Failed,
  ListingFilter_DeleteSavedFilter_Finished,
  ListingFilter_InitSavedFilters_Started,
  ListingFilter_SaveFilter_Failed,
  ListingFilter_SaveFilter_Finished,
  ListingFilter_UpdateSavedFilter_Failed,
  ListingFilter_UpdateSavedFilter_Finished,
} from '../../../applications/contentInventory/content/features/ListingFilter/constants/listingFilterActionTypes.ts';
import { itemEditorOperationIds } from '../../../applications/contentInventory/content/utils/itemEditorOperationIdUtils.ts';
import {
  Relations_ExpandNode_Failed,
  Relations_InitFailed,
  Relations_SetDefaultRoot_Failed,
  Relations_SetRoot_Failed,
} from '../../../applications/contentInventory/relations/constants/relationsActionTypes.ts';
import {
  AssetType_Editor_AnyInvalidElement_ShowWarning,
  AssetType_Editor_SavingFailed,
} from '../../../applications/contentModels/assetTypes/constants/assetTypeActionTypes.ts';
import { ContentType_Editor_AnyInvalidElement_ShowWarning } from '../../../applications/contentModels/contentTypes/constants/contentTypesActionTypes.ts';
import {
  NotAllRequiredFieldsAreFilledIn,
  NotAllRequiredFieldsInAssetTypeAreFilledIn,
  NotAllRequiredFieldsInSnippetAreFilledIn,
} from '../../../applications/contentModels/shared/constants/errorMessageTemplates.ts';
import {
  ContentModels_TypeEditor_ErrorMessageCleared,
  ContentModels_TypeEditor_InitStarted,
  ContentModels_TypeEditor_Left,
} from '../../../applications/contentModels/shared/constants/sharedContentModelsActionTypes.ts';
import {
  Sitemap_Deleted,
  Sitemap_InitSitemap,
  Sitemap_NotificationBar_Hide,
  Sitemap_SavingFailed,
} from '../../../applications/contentModels/sitemap/constants/sitemapActionTypes.ts';
import { ContentTypeSnippet_Editor_AnyInvalidElement_ShowWarning } from '../../../applications/contentModels/snippets/constants/snippetActionTypes.ts';
import {
  Taxonomy_App_Initialized,
  Taxonomy_GroupCreate_Initialized,
  Taxonomy_GroupEdit_Initialized,
  Taxonomy_Group_CreationFailed,
  Taxonomy_Group_CreationFinished,
  Taxonomy_Group_SavingFailed,
  Taxonomy_Group_SavingFinished,
  Taxonomy_Terms_HideCreateNewForm,
  Taxonomy_Terms_TermCreated,
  Taxonomy_Terms_TermEditingAbandoned,
  Taxonomy_Terms_TermRemoved,
} from '../../../applications/contentModels/taxonomy/constants/taxonomyActionTypes.ts';
import {
  Collections_Listing_Left,
  Collections_Save_Failed,
  Collections_Save_Finished,
} from '../../../applications/environmentSettings/collections/constants/actionTypes.ts';
import { ValidationErrorPanelMessage } from '../../../applications/environmentSettings/roles/constants/UIConstants.ts';
import {
  Role_Create_Failed,
  Role_Create_Finished,
  Role_CreatorInit_Started,
  Role_Delete_Failed,
  Role_Editing_CapabilitiesChecked,
  Role_Editing_RoleSettingsChanged,
  Role_EditorInit_Started,
  Role_Update_Failed,
  Role_Update_Finished,
  Role_Validation_Failed,
} from '../../../applications/environmentSettings/roles/constants/rolesActionTypes.ts';
import {
  Spaces_Create_Failed,
  Spaces_Create_Succeeded,
  Spaces_Delete_Failed,
  Spaces_Delete_Succeeded,
  Spaces_Listing_Left,
  Spaces_Restore_Failed,
  Spaces_Restore_Succeeded,
  Spaces_Update_Failed,
  Spaces_Update_Succeeded,
} from '../../../applications/environmentSettings/spaces/constants/spacesActionTypes.ts';
import {
  activationFailed,
  deactivationFailed,
  someInvitesFailed,
  userNotFound,
} from '../../../applications/environmentSettings/users/constants/errorMessages.ts';
import {
  Invite_SubmitInvitations_Finished,
  UserDetail_Initialized,
  UserDetail_UserNotFound,
  UserListing_Init_Started,
  User_ToggleActiveState_Failed,
  User_ToggleActiveState_Finished,
} from '../../../applications/environmentSettings/users/constants/usersActionTypes.ts';
import {
  Workflow_Delete_Failed,
  Workflow_Editing_EmptyTransitionsSaveFailed,
  Workflow_Editing_Left,
  Workflow_Editing_WorkflowSaveFailed,
  Workflow_Editing_WorkflowSaveFinished,
  Workflow_InitializeEditing_Started,
  Workflow_ServerValidation_ReceivedGlobalErrors,
  Workflow_ServerValidation_ReceivedStepErrors,
  Workflow_StepEditor_Delete,
  Workflow_StepEditor_Save,
} from '../../../applications/environmentSettings/workflow/constants/workflowActionTypes.ts';
import {
  emptyTransitionsPanelText,
  genericWorkflowError,
  noPathToPublishedPanelText,
} from '../../../applications/environmentSettings/workflow/constants/workflowErrorMessages.ts';
import { WorkflowStepValidationError } from '../../../applications/environmentSettings/workflow/model/WorkflowValidationErrors.ts';
import {
  ModalContentItemSelector_Closed,
  ModalMultipleContentItemsSelector_Closed,
} from '../../../applications/features/ModalContentItemSelector/constants/modalContentItemSelectorActionTypes.ts';
import {
  Content_Editing_CancelScheduledPublishingFailed,
  Content_Editing_CancelScheduledUnpublishingFailed,
  Content_Editing_DiscardNewVersionFailed,
  Content_HideValidationResults,
} from '../../../applications/itemEditor/constants/editorActionTypes.ts';
import {
  ContentEditing_CascadeAction_UndoFailed,
  ContentEditing_CascadeModal_PublishingFailed,
  ContentEditing_CascadeModal_SchedulingFailed,
} from '../../../applications/itemEditor/features/CascadePublish/constants/cascadeModalActionTypes.ts';
import {
  ContentItemEditing_CreateNewVersion_Failed,
  ContentItemEditing_CreateNewVersion_Finished,
  ContentItemEditing_Init_Started,
  ContentItemEditing_ItemSaving_Finished,
} from '../../../applications/itemEditor/features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { CreateNewVersionErrorMessage } from '../../../applications/itemEditor/features/ContentItemEditing/constants/uiConstants.ts';
import {
  NewContentItem_ContentItemCreatedInLinkedItems,
  NewContentItem_ContentItemCreatedInListing,
  NewContentItem_ContentItemCreatedInRichText,
} from '../../../applications/itemEditor/features/NewContentItem/constants/newContentItemActionTypes.ts';
import { RichText_Paste_Failed } from '../../../applications/richText/constants/richTextActionTypes.ts';
import {
  SubscriptionUserDetail_Initialized,
  SubscriptionUserDetail_UserNotFound,
  SubscriptionUser_TriggerActiveStateOnAllSubscriptionProjects_Failed,
  SubscriptionUser_TriggerActiveStateOnAllSubscriptionProjects_Finished,
  SubscriptionUsers_Left,
} from '../../../applications/subscriptionManagement/SubscriptionUsers/constants/actionTypes.ts';
import { subscriptionUsersUserDetailFailed } from '../../../applications/subscriptionManagement/SubscriptionUsers/constants/errors.ts';
import { UserProfile_RouteEntered } from '../../../applications/userProfile/constants/userProfileActionTypes.ts';
import { ServerApiErrorCode } from '../../../repositories/serverModels/ServerApiError.ts';
import {
  Shared_NotificationBar_Close,
  Shared_NotificationBar_ShowError,
  Shared_NotificationBar_ShowSuccess,
} from '../../constants/sharedActionTypes.ts';

export const text = (state: string | null = null, action: Action): string | null => {
  switch (action.type) {
    case Shared_NotificationBar_ShowSuccess:
    case Sitemap_Deleted:
      return action.payload.message;

    case AssetType_Editor_SavingFailed:
    case Content_Editing_CancelScheduledPublishingFailed:
    case Content_Editing_CancelScheduledUnpublishingFailed:
    case Content_Editing_DiscardNewVersionFailed:
    case ContentEditing_CascadeModal_PublishingFailed:
    case ContentEditing_CascadeModal_SchedulingFailed:
    case ContentEditing_CascadeAction_UndoFailed:
    case ListingFilter_CreateLinkedFilter_Failed:
    case ListingFilter_DeleteSavedFilter_Failed:
    case ListingFilter_SaveFilter_Failed:
    case ListingFilter_UpdateSavedFilter_Failed:
    case Relations_ExpandNode_Failed:
    case Relations_InitFailed:
    case Relations_SetDefaultRoot_Failed:
    case Relations_SetRoot_Failed:
    case RichText_Paste_Failed:
    case Role_Create_Failed:
    case Role_Delete_Failed:
    case Role_Update_Failed:
    case Workflow_Delete_Failed:
    case Shared_NotificationBar_ShowError:
    case Sitemap_SavingFailed:
    case Taxonomy_Group_CreationFailed:
    case Taxonomy_Group_SavingFailed:
    case Workflow_Editing_WorkflowSaveFailed:
    case Collections_Save_Failed:
    case Spaces_Create_Failed:
    case Spaces_Delete_Failed:
    case Spaces_Restore_Failed:
    case Spaces_Update_Failed:
      return action.payload.errorMessage;

    case AssetType_Editor_AnyInvalidElement_ShowWarning:
      return NotAllRequiredFieldsInAssetTypeAreFilledIn;

    case ContentType_Editor_AnyInvalidElement_ShowWarning:
      return NotAllRequiredFieldsAreFilledIn;

    case ContentTypeSnippet_Editor_AnyInvalidElement_ShowWarning:
      return NotAllRequiredFieldsInSnippetAreFilledIn;

    case Workflow_Editing_EmptyTransitionsSaveFailed:
      return emptyTransitionsPanelText;

    case Workflow_ServerValidation_ReceivedStepErrors: {
      const errorValues = Collection.getValues(action.payload.errors);
      if (
        errorValues.some(
          (list) => !!list && list.has(WorkflowStepValidationError.NoPathToPublished),
        )
      ) {
        return noPathToPublishedPanelText;
      }
      if (
        errorValues.some((list) => !!list && list.has(WorkflowStepValidationError.NoTransitions))
      ) {
        return emptyTransitionsPanelText;
      }
      return state;
    }

    case Workflow_ServerValidation_ReceivedGlobalErrors: {
      const { errors } = action.payload;
      if (errors && errors.size > 0) {
        return `${genericWorkflowError} ${Array.from(errors).join(' ')}`;
      }
      return state;
    }

    case Role_Validation_Failed:
      return ValidationErrorPanelMessage;

    case ContentItemEditing_ItemSaving_Finished: {
      if (action.payload.operationId === itemEditorOperationIds.codename) {
        return null;
      }
      return state;
    }

    case UserDetail_UserNotFound:
      return userNotFound;

    case Invite_SubmitInvitations_Finished: {
      const { failedInvitations } = action.payload;

      if (failedInvitations.length > 0) {
        return someInvitesFailed(failedInvitations.map((invitation) => invitation.email));
      }
      return state;
    }

    case ContentItemEditing_CreateNewVersion_Failed: {
      if (action.payload.apiError === ServerApiErrorCode.ContentWasModified) {
        return CreateNewVersionErrorMessage;
      }
      return state;
    }

    case AssetLibrary_AssetEditor_SaveFailed: {
      if (action.payload.apiError === ServerApiErrorCode.ContentWasModified) {
        return assetAlreadyModifiedErrorMessage;
      }
      if (action.payload.apiError === ServerApiErrorCode.CodenameIsNotUnique) {
        return action.payload.message;
      }
      return state;
    }

    case User_ToggleActiveState_Failed:
    case SubscriptionUser_TriggerActiveStateOnAllSubscriptionProjects_Failed: {
      if (action.payload.triggeredFromUsersOverview) {
        return action.payload.activate ? activationFailed : deactivationFailed;
      }

      return state;
    }

    case SubscriptionUserDetail_UserNotFound:
      return subscriptionUsersUserDetailFailed;

    case AssetLibrary_AssetEditor_InitStarted:
    case AssetLibrary_AssetEditor_ModalClosed:
    case AssetLibrary_AssetEditorDialog_InitStarted:
    case Role_CreatorInit_Started:
    case Role_EditorInit_Started:
    case Role_Editing_CapabilitiesChecked:
    case Role_Editing_RoleSettingsChanged:
    case Role_Create_Finished:
    case Role_Update_Finished:
    case ContentModels_TypeEditor_ErrorMessageCleared:
    case Content_HideValidationResults:
    case ContentItemEditing_Init_Started:
    case ContentItemEditing_CreateNewVersion_Finished:
    case ContentListing_CancelScheduledPublish_Started:
    case ContentListing_Init_Started:
    case ContentListing_PublishItems_Started:
    case ContentListing_ScheduledPublishItems_Started:
    case ContentListing_ScheduledUnpublishItems_Started:
    case ContentListing_UnpublishItems_Started:
    case ContentListing_UpdateStandardStep_Started:
    case ContentListing_RestoreFromArchivedStep_Started:
    case ContentListing_MoveToArchivedStep_Started:
    case ContentModels_TypeEditor_Left:
    case ContentModels_TypeEditor_InitStarted:
    case ListingFilter_InitSavedFilters_Started:
    case ListingFilter_DeleteSavedFilter_Finished:
    case ListingFilter_SaveFilter_Finished:
    case ListingFilter_UpdateSavedFilter_Finished:
    case ModalContentItemSelector_Closed:
    case ModalMultipleContentItemsSelector_Closed:
    case NewContentItem_ContentItemCreatedInRichText:
    case NewContentItem_ContentItemCreatedInLinkedItems:
    case NewContentItem_ContentItemCreatedInListing:
    case Shared_NotificationBar_Close:
    case Sitemap_InitSitemap:
    case Sitemap_NotificationBar_Hide:
    case Spaces_Create_Succeeded:
    case Spaces_Delete_Succeeded:
    case Spaces_Listing_Left:
    case Spaces_Restore_Succeeded:
    case Spaces_Update_Succeeded:
    case Taxonomy_App_Initialized:
    case Taxonomy_Group_SavingFinished:
    case Taxonomy_Group_CreationFinished:
    case Taxonomy_GroupCreate_Initialized:
    case Taxonomy_GroupEdit_Initialized:
    case Taxonomy_Terms_HideCreateNewForm:
    case Taxonomy_Terms_TermCreated:
    case Taxonomy_Terms_TermRemoved:
    case Taxonomy_Terms_TermEditingAbandoned:
    case UserProfile_RouteEntered:
    case UserListing_Init_Started:
    case Workflow_InitializeEditing_Started:
    case Workflow_StepEditor_Delete:
    case Workflow_StepEditor_Save:
    case Workflow_Editing_WorkflowSaveFinished:
    case Workflow_Editing_Left:
    case Collections_Listing_Left:
    case Collections_Save_Finished:
    case User_ToggleActiveState_Finished:
    case SubscriptionUser_TriggerActiveStateOnAllSubscriptionProjects_Finished:
    case UserDetail_Initialized:
    case SubscriptionUserDetail_Initialized:
    case SubscriptionUsers_Left:
      return null;

    default:
      return state;
  }
};
