import { Collection, mapObjectValues } from '@kontent-ai/utils';
import { VariantCompletionStatus } from '../../../_shared/utils/contentItemVariantUtils.ts';
import { uncategorizedItemFilterTaxonomyTermId } from '../../../applications/contentInventory/content/features/ListingFilter/constants/listingFilterConstants.ts';
import { UntitledFilter } from '../../../applications/contentInventory/content/features/ListingFilter/constants/uiConstants.ts';
import {
  convertContentItemStatusToQueryParams,
  getContentItemStatusFromIds,
} from '../../../applications/contentInventory/content/models/filter/IContentItemStatus.ts';
import { IListingFilter } from '../../../applications/contentInventory/content/models/filter/IListingFilter.ts';
import {
  convertPublishingStateToQueryParams,
  getPublishingStateFromIds,
} from '../../../applications/contentInventory/content/models/filter/IPublishingState.ts';
import { getTaxonomyGroupItemFilterServerModel } from '../../../applications/contentInventory/content/models/filter/contentItemFilterUtils.ts';
import {
  IFilterServerModel,
  IFilterUpdateServerModel,
} from '../../../repositories/serverModels/FilterServerModels.type.ts';
import { PublishingState } from '../../constants/PublishingState.ts';

export interface ISavedFilter {
  readonly collectionsNodes: UuidArray;
  readonly contentTypesNodes: UuidArray;
  readonly contributorsNodes: ReadonlyArray<string>;
  readonly id: Uuid;
  readonly name: string;
  readonly publishingStateNodes: ReadonlyArray<PublishingState>;
  readonly sitemapNodes: UuidArray;
  readonly spacesNodes: UuidArray;
  readonly statusNodes: ReadonlyArray<VariantCompletionStatus>;
  readonly taxonomyNodes: ReadonlyRecord<Uuid, UuidArray>;
  readonly workflowsNodes: ReadonlyRecord<Uuid, UuidArray>;
}

const emptySavedFilter: ISavedFilter = {
  collectionsNodes: [],
  contentTypesNodes: [],
  contributorsNodes: [],
  id: '',
  name: UntitledFilter,
  publishingStateNodes: [],
  sitemapNodes: [],
  spacesNodes: [],
  statusNodes: [],
  taxonomyNodes: {},
  workflowsNodes: {},
};

export const getSavedFilterFromServerModel = (serverModel: IFilterServerModel): ISavedFilter => ({
  collectionsNodes: serverModel.collectionsNodes || emptySavedFilter.collectionsNodes,
  contentTypesNodes: serverModel.contentTypesNodes || emptySavedFilter.contentTypesNodes,
  contributorsNodes: serverModel.contributorsNodes || emptySavedFilter.contributorsNodes,
  id: serverModel.id || emptySavedFilter.id,
  name: serverModel.name || emptySavedFilter.name,
  publishingStateNodes: serverModel.publishingStateNodes || emptySavedFilter.publishingStateNodes,
  sitemapNodes: serverModel.sitemapNodes || emptySavedFilter.sitemapNodes,
  spacesNodes: serverModel.spacesNodes || emptySavedFilter.spacesNodes,
  statusNodes: serverModel.statusNodes || emptySavedFilter.statusNodes,
  taxonomyNodes: mapObjectValues(
    serverModel.taxonomiesByGroupId ?? emptySavedFilter.taxonomyNodes,
    (group) =>
      group.includeUncategorized
        ? [uncategorizedItemFilterTaxonomyTermId, ...group.termIds]
        : group.termIds,
  ),
  workflowsNodes: serverModel.workflowsNodes || emptySavedFilter.workflowsNodes,
});

export const getSavedFilterUpdateServerModel = (
  filter: ISavedFilter,
): IFilterUpdateServerModel => ({
  ...filter,
  taxonomiesByGroupId: mapObjectValues(filter.taxonomyNodes, (termIds) =>
    getTaxonomyGroupItemFilterServerModel(termIds),
  ),
});

export const getListingFilterFromSavedFilter = (
  filter: ISavedFilter,
  forcedContentTypeIds?: ReadonlySet<Uuid> | undefined,
): Partial<IListingFilter> => ({
  selectedCollectionsNodes: new Set(filter.collectionsNodes),
  selectedContentItemStatus: getContentItemStatusFromIds(new Set(filter.statusNodes)),
  selectedContentTypesNodes: forcedContentTypeIds
    ? forcedContentTypeIds
    : new Set(filter.contentTypesNodes),
  selectedContributorsNodes: new Set(filter.contributorsNodes),
  selectedPublishingStateNodes: getPublishingStateFromIds(new Set(filter.publishingStateNodes)),
  selectedSitemapNodes: new Set(filter.sitemapNodes),
  selectedSpacesNodes: new Set(filter.spacesNodes),
  selectedTaxonomyNodes: new Map(
    Object.entries(mapObjectValues(filter.taxonomyNodes, (termIds) => new Set(termIds))),
  ),
  selectedWorkflowNodes: new Map(
    Object.entries(filter.workflowsNodes).map(([workflowId, stepIds]) => [
      workflowId,
      new Set(stepIds),
    ]),
  ),
});

export const getSavedFilterFromListingFilter = (filter: IListingFilter): ISavedFilter => ({
  ...emptySavedFilter,
  collectionsNodes: Array.from(filter.selectedCollectionsNodes),
  contentTypesNodes: Array.from(filter.selectedContentTypesNodes),
  contributorsNodes: Array.from(filter.selectedContributorsNodes),
  publishingStateNodes: convertPublishingStateToQueryParams(filter.selectedPublishingStateNodes),
  sitemapNodes: Array.from(filter.selectedSitemapNodes),
  spacesNodes: Array.from(filter.selectedSpacesNodes),
  statusNodes: convertContentItemStatusToQueryParams(filter.selectedContentItemStatus),
  taxonomyNodes: Object.fromEntries(
    Collection.getEntries(filter.selectedTaxonomyNodes).map(([groupId, termIds]) => [
      groupId,
      Array.from(termIds),
    ]),
  ),
  workflowsNodes: Object.fromEntries(
    Collection.getEntries(filter.selectedWorkflowNodes).map(([workflowId, stepIds]) => [
      workflowId,
      Array.from(stepIds),
    ]),
  ),
});

export const getSavedFilterUpdateServerModelFromListingFilter = (
  filter: IListingFilter,
  name: string = '',
): IFilterUpdateServerModel => ({
  name,
  workflowsNodes: Object.fromEntries(
    Collection.getEntries(filter.selectedWorkflowNodes).map(([workflowId, stepIds]) => [
      workflowId,
      Array.from(stepIds),
    ]),
  ),
  contributorsNodes: Array.from(filter.selectedContributorsNodes),
  collectionsNodes: Array.from(filter.selectedCollectionsNodes),
  contentTypesNodes: Array.from(filter.selectedContentTypesNodes),
  sitemapNodes: Array.from(filter.selectedSitemapNodes),
  spacesNodes: Array.from(filter.selectedSpacesNodes),
  taxonomiesByGroupId: Object.fromEntries(
    Collection.getEntries(filter.selectedTaxonomyNodes).map(([groupId, termIds]) => [
      groupId,
      getTaxonomyGroupItemFilterServerModel(Array.from(termIds)),
    ]),
  ),
  publishingStateNodes: convertPublishingStateToQueryParams(filter.selectedPublishingStateNodes),
  statusNodes: convertContentItemStatusToQueryParams(filter.selectedContentItemStatus),
});
