import { useSelector } from 'react-redux';
import { IStore } from '../../../../../../../../_shared/stores/IStore.type.ts';
import { ItemEditingForms } from '../../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getHintForWorkflowsCurrentUserCanMoveEditedVariantTo } from '../../../../../../selectors/workflows/getWorkflowsCurrentUserCanMoveEditedVariantTo.ts';
import { getTooltipForWorkflowsSidebarSection } from '../../../../../../utils/workflowTooltipUtils.ts';
import { ReadonlySection } from '../../../../components/sidebar/sectionTypes/ReadonlySection.tsx';
import { getEditedVariantWorkflowName } from '../../../selectors/getEditedVariantWorkflowName.ts';

const getTooltipText = (state: IStore): string | undefined => {
  const hint = getHintForWorkflowsCurrentUserCanMoveEditedVariantTo(state);
  return getTooltipForWorkflowsSidebarSection(hint);
};

export const ReadonlyWorkflowSection = () => {
  const workflowName = useSelector(getEditedVariantWorkflowName);
  const tooltipText = useSelector(getTooltipText);

  return (
    <ReadonlySection dataUiNameAttribute={ItemEditingForms.Workflow} tooltipText={tooltipText}>
      {workflowName}
    </ReadonlySection>
  );
};
