import { Button } from '@kontent-ai/component-library/Button';
import { Card } from '@kontent-ai/component-library/Card';
import { Stack } from '@kontent-ai/component-library/Stack';
import {
  BaseColor,
  Spacing,
  Typography,
  borderWidthDefault,
  colorBorderDefault,
  gridUnit,
  px,
  typographyCardHeadline,
} from '@kontent-ai/component-library/tokens';
import React from 'react';
import styled from 'styled-components';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getCurrentProjectContainer } from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import {
  EnvironmentsLimitReachedTooltipMessage,
  NoEnvironmentWithCapabilityToCloneFromTooltipMessage,
} from '../constants/uiConstants.ts';
import { getEnvironmentsForCloning } from '../selectors/getEnvironmentsForCloning.ts';
import { EnvironmentCard } from './EnvironmentTile.tsx';

const PlaceholderCard = styled(EnvironmentCard)`
  text-align: center;
  background-color: ${BaseColor.Transparent};
  border-style: solid;
  border-width: ${px(borderWidthDefault)};
  border-color: ${colorBorderDefault};
`;

const PlaceholderImage = styled.img`
  width: ${px(6.5 * gridUnit)};
`;

const PlaceholderHeadline = styled.p`
  ${typographyCardHeadline};
  word-break: break-word;
`;

const PlaceholderText = styled.p`
  ${Typography.LabelLarge};
`;

type Props = {
  readonly onCreateEnvironment: () => void;
  readonly isDisabled: boolean;
};

export const EnvironmentPlaceholder: React.FC<Props> = ({ isDisabled, onCreateEnvironment }) => {
  const currentProject = useSelector((state) => getCurrentProjectContainer(state));
  const environmentsForCloning = useSelector((state) =>
    getEnvironmentsForCloning(state, currentProject.masterEnvironmentId),
  );
  const hasAnyEnvironmentToCloneFrom = environmentsForCloning.count() > 0;

  const tooltipText = hasAnyEnvironmentToCloneFrom
    ? isDisabled
      ? EnvironmentsLimitReachedTooltipMessage
      : null
    : NoEnvironmentWithCapabilityToCloneFromTooltipMessage;

  return (
    <PlaceholderCard
      component="section"
      cardLabel="Create new environment"
      {...getDataUiElementAttribute(DataUiElement.EnvironmentPlaceholder)}
    >
      <Card.Body>
        <Stack spacing={Spacing.S} align="center">
          <PlaceholderImage src="/images/rocket.svg" alt="Create new environment" />
          <PlaceholderHeadline>Create new environment.</PlaceholderHeadline>
          <PlaceholderText>
            Prepare new functionality without affecting what your customers see on production.
          </PlaceholderText>
        </Stack>
      </Card.Body>
      <Card.Footer>
        <Button
          buttonStyle="secondary"
          disabled={isDisabled}
          tooltipText={tooltipText}
          onClick={() => onCreateEnvironment()}
          {...getDataUiActionAttribute(DataUiAction.CreateNew)}
        >
          Create new environment
        </Button>
      </Card.Footer>
    </PlaceholderCard>
  );
};

EnvironmentPlaceholder.displayName = 'EnvironmentPlaceholder';
