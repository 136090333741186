export interface IValidationConstants {
  readonly ApiKeyNameMaxLength: number;
  readonly AssetTitleMaxLength: number;
  readonly CodenameMaxLength: number;
  readonly CollectionNameMaxLength: number;
  readonly CommentTextMaxLength: number;
  readonly CompanyNameMaxLength: number;
  readonly ContentGroupCodeNameMaxLength: number;
  readonly ContentGroupNameMaxLength: number;
  readonly ContentItemNameMaxLength: number;
  readonly CustomElementValueMaxLength: number;
  readonly CustomTypeElementConfigMaxLength: number;
  readonly ElementGuidelinesMaxLength: number;
  readonly ElementNameMaxLength: number;
  readonly FilterNameMaxLength: number;
  readonly GuidelinesElementTextMaxLength: number;
  readonly LanguageCodenameMaxLength: number;
  readonly LanguageNameMaxLength: number;
  readonly MaxNumberOfMultipleChoiceOptions: number;
  readonly MultipleChoiceOptionCodenameMaxLength: number;
  readonly MultipleChoiceOptionNameMaxLength: number;
  readonly PhoneNumberMaxLength: number;
  readonly ProjectNameMaxLength: number;
  readonly RegexPatternMaxLength: number;
  readonly RegexCustomValidationMessageMaxLength: number;
  readonly RichTextElementValueMaxLength: number;
  readonly RoleNameMaxLength: number;
  readonly SimpleTextElementValueMaxLength: number;
  readonly SitemapNameMaxLength: number;
  readonly SnippetNameMaxLength: number;
  readonly SpaceCodenameMaxLength: number;
  readonly SpaceNameMaxLength: number;
  readonly SubscriptionNameMaxLength: number;
  readonly TaxonomyTermCodenameMaxLength: number;
  readonly TaxonomyTermNameMaxLength: number;
  readonly TypeNameMaxLength: number;
  readonly UrlMaxLength: number;
  readonly UserEmailMaxLength: number;
  readonly UserFirstNameMaxLength: number;
  readonly UserLastNameMaxLength: number;
  readonly WebhookUrlMaxLength: number;
  readonly WorkflowNameMaxLength: number;
  readonly WorkflowStatusCodenameMaxLength: number;
  readonly WorkflowStatusNameMaxLength: number;
}

export const ValidationConstants: IValidationConstants = {
  ApiKeyNameMaxLength: 200,
  AssetTitleMaxLength: 200,
  CodenameMaxLength: 60,
  CollectionNameMaxLength: 200,
  CommentTextMaxLength: 1000,
  CompanyNameMaxLength: 200,
  ContentGroupCodeNameMaxLength: 60,
  ContentGroupNameMaxLength: 50,
  ContentItemNameMaxLength: 200,
  CustomElementValueMaxLength: 200000,
  CustomTypeElementConfigMaxLength: 100000,
  ElementGuidelinesMaxLength: 10000,
  ElementNameMaxLength: 50,
  FilterNameMaxLength: 200,
  GuidelinesElementTextMaxLength: 100000,
  LanguageCodenameMaxLength: 25,
  LanguageNameMaxLength: 25,
  MaxNumberOfMultipleChoiceOptions: 250,
  MultipleChoiceOptionCodenameMaxLength: 160,
  MultipleChoiceOptionNameMaxLength: 150,
  PhoneNumberMaxLength: 50,
  ProjectNameMaxLength: 200,
  RegexPatternMaxLength: 500,
  RegexCustomValidationMessageMaxLength: 1000,
  RichTextElementValueMaxLength: 100000,
  RoleNameMaxLength: 200,
  SimpleTextElementValueMaxLength: 100000,
  SitemapNameMaxLength: 200,
  SnippetNameMaxLength: 50,
  SpaceCodenameMaxLength: 210,
  SpaceNameMaxLength: 200,
  SubscriptionNameMaxLength: 250,
  TaxonomyTermCodenameMaxLength: 210,
  TaxonomyTermNameMaxLength: 200,
  TypeNameMaxLength: 50,
  UrlMaxLength: 250,
  UserEmailMaxLength: 200,
  UserFirstNameMaxLength: 200,
  UserLastNameMaxLength: 200,
  WebhookUrlMaxLength: 2000,
  WorkflowNameMaxLength: 200,
  WorkflowStatusCodenameMaxLength: 40,
  WorkflowStatusNameMaxLength: 30,
};
