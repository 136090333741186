import { InvariantException } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { canCreateItemVariant } from '../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { IContentItemRepository } from '../../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import {
  IContentItemWithVariantServerModel,
  createEmptyContentItemVariant,
} from '../../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import { ICompiledContentType } from '../../../../../contentInventory/content/models/CompiledContentType.ts';
import { EditableTypeElement } from '../../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { ICompiledContentItemElementData } from '../../../../models/contentItemElements/ICompiledContentItemElement.ts';
import { getItemElementServerModelConverter } from '../../../../utils/getItemElementDataConverters.ts';
import { isElementVisible } from '../../../../utils/itemElementConditionUtils.ts';
import {
  clearItemElementValue,
  createItemElementWithDefaultValue,
} from '../../../../utils/itemElementCreator.ts';
import {
  GetAssets,
  IItemElementServerModelConverterDataDependencies,
} from '../../../../utils/itemElementDataConverters/types/IItemElementDataConverters.type.ts';
import { getRelevantEditableElements } from '../../../../utils/itemElementUtils.ts';
import { createEmptyAssignment } from '../../utils/assignmentUtils.ts';

export type ICreateContentItemVariant = (
  item: { readonly id: Uuid; readonly collectionId: Uuid },
  variantId: Uuid,
  type: ICompiledContentType,
  workflowId: Uuid,
  abortSignal?: AbortSignal,
) => ThunkPromise<IContentItemWithVariantServerModel>;

interface ICreateContentItemVariantDependency {
  readonly contentItemRepository: Pick<IContentItemRepository, 'createVariant'>;
  readonly createGuid: () => Uuid;
}

export const createCreateContentItemVariantAction =
  (deps: ICreateContentItemVariantDependency): ICreateContentItemVariant =>
  (
    item: { readonly id: Uuid; readonly collectionId: Uuid },
    variantId,
    type,
    workflowId,
    abortSignal,
  ) =>
  async (_, getState) => {
    const state = getState();
    const { user } = state.data;
    if (!canCreateItemVariant(type.id, item.collectionId, variantId, state)) {
      throw InvariantException(
        'Content item variant creating action called without having the capability to create content.',
      );
    }

    const assignment = createEmptyAssignment(deps.createGuid(), user.info, workflowId);
    const getAssets: GetAssets = () => getState().data.assets.byId;
    const elementDependencies: IItemElementServerModelConverterDataDependencies = { getAssets };

    const contentElements = createContentElementsForServer(
      getRelevantEditableElements(type.contentElements, variantId),
    ).map((element) =>
      getItemElementServerModelConverter(element.type)(element, elementDependencies),
    );

    const variant = createEmptyContentItemVariant({
      itemId: item.id,
      variantId,
      contentElements,
      contentLastUpdatedBy: user.info.userId,
      assignment,
    });

    return await deps.contentItemRepository.createVariant(variant, abortSignal);
  };

const createContentElementsForServer = (
  typeElements: ReadonlyArray<EditableTypeElement>,
): ReadonlyArray<ICompiledContentItemElementData> => {
  const itemElements = typeElements.map(createItemElementWithDefaultValue);

  return typeElements.map((typeElement) => {
    const element = createItemElementWithDefaultValue(typeElement);

    return isElementVisible(typeElement, itemElements) ? element : clearItemElementValue(element);
  });
};
