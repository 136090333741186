import { Collection } from '@kontent-ai/utils';
import { ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { isCreatingRenditionsEnabled } from '../../../../../_shared/selectors/enhancedAssetManagement.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { getCurrentProjectPlan } from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { ICompiledContentItemElementData } from '../../../../itemEditor/models/contentItemElements/ICompiledContentItemElement.ts';
import { associateResultWithTopLevelRichText } from '../../../../itemEditor/reducers/utils/validationResultUtils.ts';
import { getItemElementValidationResult } from '../../../../itemEditor/utils/getItemElementValidationResult.ts';
import { isElementVisible } from '../../../../itemEditor/utils/itemElementConditionUtils.ts';
import {
  ItemElementFriendlyWarningResult,
  ItemElementFriendlyWarnings,
} from '../../../../itemEditor/utils/itemElementFriendlyWarningCheckers/types/FriendlyWarnings.ts';
import { isElementEditableInVariant } from '../../../../itemEditor/utils/itemElementUtils.ts';
import { ElementType } from '../../../content/models/ContentItemElementType.ts';
import { AssetLibrary_Asset_AssetsInEditedItemValidationFinished } from '../../constants/assetLibraryActionTypes.ts';

const validateAssetsFinished = (friendlyWarnings: ItemElementFriendlyWarnings) =>
  ({
    type: AssetLibrary_Asset_AssetsInEditedItemValidationFinished,
    payload: {
      friendlyWarnings,
    },
  }) as const;

export type ValidateAssetsInEditedItemActionsType = ReturnType<typeof validateAssetsFinished>;

export const createValidateAssetsInEditedItemAction =
  () => (): ThunkFunction => (dispatch, getState) => {
    const state = getState();
    const {
      contentApp: { editedContentItemVariantElements, loadedContentItemTypes, editedContentItem },
      data: { listingContentItems, assets, assetRenditions, languages, taxonomyGroups },
    } = state;

    const selectedLanguageId = getSelectedLanguageIdOrThrow(state);
    if (!editedContentItem) {
      return;
    }

    const editedContentItemType = loadedContentItemTypes.get(
      editedContentItem.editedContentItemTypeId,
    );
    if (!editedContentItemType) {
      return;
    }
    const areAssetRenditionsEnabled = isCreatingRenditionsEnabled(getCurrentProjectPlan(state));

    const friendlyWarnings = editedContentItemVariantElements.flatMap(
      (elementData: ICompiledContentItemElementData) => {
        const elementId = elementData.elementId;
        const typeElement = editedContentItemType.contentElements.find(
          (typeEl) => typeEl.elementId === elementId,
        );

        const skipValidation =
          !isElementEditableInVariant(typeElement, selectedLanguageId) ||
          !isElementVisible(typeElement, editedContentItemVariantElements);

        if (skipValidation) {
          return [];
        }

        const validationResult = getItemElementValidationResult(
          typeElement,
          elementData,
          loadedContentItemTypes,
          assets.byId,
          assetRenditions.byId,
          taxonomyGroups.byId,
          listingContentItems.byId,
          selectedLanguageId,
          languages.byId,
          areAssetRenditionsEnabled,
        );

        const entries = Collection.getEntries(validationResult.friendlyWarnings);

        return elementData.type === ElementType.RichText
          ? entries.map(
              ([selectorId, friendlyWarning]): [UuidPath, ItemElementFriendlyWarningResult] => [
                selectorId,
                associateResultWithTopLevelRichText(friendlyWarning, elementId),
              ],
            )
          : entries;
      },
    );

    dispatch(
      validateAssetsFinished(new Map<UuidPath, ItemElementFriendlyWarningResult>(friendlyWarnings)),
    );
  };
