import React from 'react';
import { Loader, LoaderType } from '../../../../../../_shared/components/Loader.tsx';
import { ICommentThread } from '../../../../models/comments/CommentThreads.ts';
import { CommentSectionThread } from '../../containers/comments/CommentThread.tsx';

interface ICommentsStateProps {
  readonly threads: ReadonlyArray<ICommentThread>;
}

interface ICommentsOwnProps {
  readonly isInlineThreadWithRemovedContent?: boolean;
  readonly loaderText?: string;
}

type ICommentsProps = ICommentsStateProps & ICommentsOwnProps;

export const Comments: React.FC<Readonly<ICommentsProps>> = ({
  threads,
  isInlineThreadWithRemovedContent,
  loaderText,
}) => (
  <div className="comment-thread-list__threads-list" data-hj-suppress="">
    {threads ? (
      threads.map((thread: ICommentThread) => (
        <CommentSectionThread
          showReferenceForInlineThreads
          className="comment-thread-list__thread"
          commentThread={thread}
          key={thread.id}
          isInlineThreadWithRemovedContent={isInlineThreadWithRemovedContent}
        />
      ))
    ) : (
      <Loader text={loaderText} type={LoaderType.Sidebar} />
    )}
  </div>
);

Comments.displayName = 'Comments';
