import { Box } from '@kontent-ai/component-library/Box';
import { Callout } from '@kontent-ai/component-library/Callout';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { IBaseTypeElementData } from '../../../applications/contentModels/shared/models/elements/types/TypeElementData.ts';
import { LinkLike } from '../../uiComponents/LinkLike/LinkLike.tsx';
import { joinWithOxfordComma } from '../../utils/stringUtils.ts';

interface ISaveElementsWithAffectedLocalizationWarningProps {
  readonly elementsWithAffectedLocalization: ReadonlyArray<IBaseTypeElementData>;
}

const maxVisibleElementNamesCount = 3;

export const SaveElementsWithAffectedLocalizationWarning: React.FC<
  ISaveElementsWithAffectedLocalizationWarningProps
> = ({ elementsWithAffectedLocalization }) => {
  if (elementsWithAffectedLocalization.length === 0) {
    return null;
  }
  const hiddenElements = elementsWithAffectedLocalization.slice(
    maxVisibleElementNamesCount,
    elementsWithAffectedLocalization.length,
  );

  return (
    <Callout calloutType="friendlyWarning">
      <p>
        You’ve marked some non-localizable elements as localizable. After you save the changes, we
        will copy these elements’ content from the default language to all languages.
      </p>
      <Box marginY={Spacing.XL}>
        <strong>This change is permanent</strong> for existing elements.
      </Box>
      <p>Affected elements:</p>
      <ul>
        {elementsWithAffectedLocalization.slice(0, maxVisibleElementNamesCount).map((e) => (
          <li key={e.elementId}>{e.name}</li>
        ))}
        {hiddenElements.length > 0 && (
          <li>
            <Tooltip
              tooltipText={joinWithOxfordComma(hiddenElements.map((e) => e.name))}
              placement="top"
            >
              <Box display="inline-block">
                &hellip;&nbsp;
                <LinkLike>and {hiddenElements.length} more</LinkLike>
              </Box>
            </Tooltip>
          </li>
        )}
      </ul>
    </Callout>
  );
};

SaveElementsWithAffectedLocalizationWarning.displayName =
  'SaveElementsWithAffectedLocalizationWarning';
