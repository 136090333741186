import PropTypes from 'prop-types';
import React from 'react';
import {
  ILinkedItemsTypeElement,
  LinkedItemsTypeElementPropTypes,
} from '../../../../../contentInventory/content/models/contentTypeElements/LinkedItemsTypeElement.ts';
import {
  ILinkedItemsElement,
  LinkedItemsElementPropTypes,
} from '../../../../models/contentItemElements/modularItems/ILinkedItemsElement.ts';
import { ItemElement } from '../../../ContentItemEditing/containers/elements/ItemElement.tsx';
import { ItemElementRefresher } from '../../../ContentItemEditing/containers/elements/ItemElementRefresher.tsx';
import { IRevisionItemElementProps } from '../../utils/itemElementRevisionComponentCreator.ts';
import { LinkedItemsDiff } from './subelements/LinkedItemsDiff.tsx';

type LinkedItemsProps = IRevisionItemElementProps<ILinkedItemsElement, ILinkedItemsTypeElement>;

const propTypes: PropTypeMap<LinkedItemsProps> = {
  elementData: LinkedItemsElementPropTypes.isRequired,
  hideValidationStatus: PropTypes.bool,
  originalElementData: LinkedItemsElementPropTypes,
  revisionElementModifiedBy: PropTypes.string,
  typeElement: LinkedItemsTypeElementPropTypes.isRequired,
};

export const LinkedItems: React.FC<LinkedItemsProps> = ({
  elementData,
  hideValidationStatus,
  originalElementData,
  typeElement,
  revisionElementModifiedBy,
}) => (
  <ItemElement
    disabled
    hideValidationStatus={hideValidationStatus}
    isDisplayOnly
    typeElement={typeElement}
    revisionChangedBy={revisionElementModifiedBy}
  >
    <ItemElementRefresher
      elementData={elementData}
      renderInput={(refresherElementData: ILinkedItemsElement) => (
        <LinkedItemsDiff
          elementData={refresherElementData}
          originalElementData={originalElementData}
          typeElement={typeElement}
        />
      )}
    />
  </ItemElement>
);

LinkedItems.displayName = 'LinkedItems (Revisions)';
LinkedItems.propTypes = propTypes;
