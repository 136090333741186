import { EditorState } from 'draft-js';
import { useCallback, useMemo, useState } from 'react';
import { IconName } from '../../../../../../_shared/constants/iconEnumGenerated.ts';
import { getContentStateActionResult } from '../../../../../../_shared/features/AI/helpers/transformAiResult.ts';
import { useAiTask } from '../../../../../../_shared/features/AI/hooks/aiTasks/useAiTask.ts';
import { useOnFinishedAiActionTask } from '../../../../../../_shared/features/AI/hooks/aiTasks/useOnFinishedAiActionTask.ts';
import { useAiActionTrackingWithSession } from '../../../../../../_shared/features/AI/hooks/useAiActionTrackingWithSession.ts';
import { AiActionTrackingProps } from '../../../../../../_shared/features/AI/types/AiActionTrackingProps.type.ts';
import {
  AiActionSource,
  AiFollowingAction,
} from '../../../../../../_shared/models/events/AiActionEventData.type.ts';
import {
  DataUiRteAction,
  getDataUiActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { AiActionName } from '../../../../../../repositories/serverModels/ai/AiActionName.type.ts';
import { createMakeShorterParams } from '../../../../../../repositories/serverModels/ai/actions/AiServerModels.makeShorter.ts';
import { useEditorStateCallbacks } from '../../../../editorCore/hooks/useEditorStateCallbacks.ts';
import { useEditorWithPlugin } from '../../../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginCreator } from '../../../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../../../editorCore/types/Editor.contract.type.ts';
import { Apply, EditorPlugin, Render } from '../../../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../../../editorCore/utils/decorable.ts';
import { withDisplayName } from '../../../../editorCore/utils/withDisplayName.ts';
import { extractSelectedContent } from '../../../../utils/general/editorContentUtils.ts';
import { LockEditorPlugin } from '../../../behavior/LockEditorPlugin.tsx';
import { ClipboardPlugin } from '../../../clipboard/ClipboardPlugin.tsx';
import { AiMenuItem, AiMenuPlugin, AiMenuSection, GetAiMenuItems } from '../../AiMenuPlugin.tsx';
import { AiMenuActionItem } from '../../components/menu/AiMenuActionItem.tsx';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard.ts';
import { useReplaceSelection } from '../../hooks/useReplaceSelection.ts';
import { ResultPositioner, useResultPositioner } from '../../hooks/useResultPositioner.tsx';
import { useResultWithPreservedBlockKeys } from '../../hooks/useResultWithPreservedBlockKeys.ts';
import { MakeShorterAction } from './MakeShorterAction.tsx';

export type MakeShorterPlugin = EditorPlugin<
  None,
  AiActionTrackingProps,
  None,
  [ClipboardPlugin, AiMenuPlugin, LockEditorPlugin]
>;

export const useMakeShorter: PluginCreator<MakeShorterPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('MakeShorterPlugin', {
        ComposedEditor: (props) => {
          const [actionEditorState, setActionEditorState] = useState<EditorState | null>(null);

          const {
            aiSessionId,
            elementOperationTrackingData,
            resetAiSessionId,
            trackFinishedAction,
            trackFollowingAction,
            trackStartingAction,
          } = useAiActionTrackingWithSession(props.element);

          const { decorateWithEditorStateCallbacks, getEditorState, lockEditor, unlockEditor } =
            useEditorStateCallbacks<MakeShorterPlugin>();

          const { cancel, run, result } = useAiTask(
            AiActionName.MakeShorter,
            getContentStateActionResult,
          );

          useOnFinishedAiActionTask(
            result.isFinished,
            () => result.trackingParams && trackFinishedAction(result.trackingParams),
          );

          const startMakeShorterAction = useCallback(
            (editorState: EditorState, actionSource: AiActionSource) => {
              const contentState = extractSelectedContent(
                editorState.getCurrentContent(),
                editorState.getSelection(),
              );
              run(createMakeShorterParams(contentState, elementOperationTrackingData));

              trackStartingAction({ action: AiActionName.MakeShorter, source: actionSource });
            },
            [run, trackStartingAction, elementOperationTrackingData],
          );

          const start = useCallback(async () => {
            const editorState = getEditorState();
            await lockEditor(editorState);
            setActionEditorState(editorState);
            startMakeShorterAction(editorState, AiActionSource.InlineToolbar);
          }, [lockEditor, getEditorState, startMakeShorterAction]);

          const reset = useCallback(() => {
            if (!result.isFinished) {
              cancel();
            }
            resetAiSessionId();
            setActionEditorState(null);
            unlockEditor();
          }, [unlockEditor, cancel, result.isFinished, resetAiSessionId]);

          const tryAgain = useMemo(
            () =>
              result.isFinished
                ? () => {
                    if (actionEditorState) {
                      trackFollowingAction({ action: AiFollowingAction.TryAgain });
                      startMakeShorterAction(actionEditorState, AiActionSource.ActionMenu);
                    }
                  }
                : undefined,
            [startMakeShorterAction, actionEditorState, result.isFinished, trackFollowingAction],
          );

          const { decorateWithReplaceCallbacks, replaceSelection } =
            useReplaceSelection(actionEditorState);

          const onReplaceSelection = useMemo(() => {
            const content = result.content;

            return result.isFinished && content
              ? () => {
                  trackFollowingAction({ action: AiFollowingAction.ReplaceSelection });
                  reset();
                  replaceSelection(content);
                }
              : undefined;
          }, [replaceSelection, reset, result.content, result.isFinished, trackFollowingAction]);

          const { decorateWithPositionerCallbacks, resultPositionerProps } = useResultPositioner(
            !!actionEditorState,
          );

          const { copyToClipboard } = useCopyToClipboard();

          const onCopyToClipboard = useMemo(() => {
            const content = result.content;

            return result.isFinished && content
              ? () => {
                  trackFollowingAction({ action: AiFollowingAction.CopyToClipboard });
                  reset();
                  copyToClipboard(content, aiSessionId);
                }
              : undefined;
          }, [
            copyToClipboard,
            reset,
            result.content,
            result.isFinished,
            trackFollowingAction,
            aiSessionId,
          ]);

          const resultWithPreservedBlockKeys = useResultWithPreservedBlockKeys(result);
          const renderOverlays: Decorator<Render<MakeShorterPlugin>> = useCallback(
            (baseRenderOverlays) => (state) => (
              <>
                {baseRenderOverlays(state)}
                {actionEditorState && (
                  <ResultPositioner
                    {...resultPositionerProps}
                    renderResult={(isPositionedAboveContent, resultWidth, resultRef) => (
                      <MakeShorterAction
                        onDiscard={() => {
                          trackFollowingAction({ action: AiFollowingAction.Discard });
                          reset();
                        }}
                        onCopyToClipboard={onCopyToClipboard}
                        onReplaceSelection={onReplaceSelection}
                        onTryAgain={tryAgain}
                        preferMenuOnTop={isPositionedAboveContent}
                        ref={resultRef}
                        result={resultWithPreservedBlockKeys}
                        resultWidth={resultWidth}
                      />
                    )}
                  />
                )}
              </>
            ),
            [
              actionEditorState,
              reset,
              onReplaceSelection,
              resultWithPreservedBlockKeys,
              resultPositionerProps,
              tryAgain,
              onCopyToClipboard,
              trackFollowingAction,
            ],
          );

          const getAiMenuItems: Decorator<GetAiMenuItems> = useCallback(
            (baseGetAiMenuItems) => (editorState) => {
              return [
                ...baseGetAiMenuItems(editorState),
                ...(isActionAvailable(editorState)
                  ? [
                      {
                        id: 'make-shorter',
                        label: 'Make shorter',
                        renderIntoMenu: (item, onActionStarted) => (
                          <AiMenuActionItem
                            label={item.label}
                            iconName={IconName.ParagraphShort}
                            onPress={() => {
                              onActionStarted();
                              start();
                            }}
                            {...getDataUiActionAttribute(DataUiRteAction.MakeShorter)}
                          />
                        ),
                        section: AiMenuSection.EditOrReviewSelection,
                        type: 'item',
                      } satisfies AiMenuItem,
                    ]
                  : []),
              ];
            },
            [start],
          );

          const apply: Apply<MakeShorterPlugin> = useCallback(
            (state) => {
              decorateWithEditorStateCallbacks(state);
              decorateWithReplaceCallbacks(state);
              decorateWithPositionerCallbacks(state);
              state.getInlineToolbarAiMenuItems.decorate(getAiMenuItems);
              state.renderOverlays.decorate(renderOverlays);

              return {};
            },
            [
              decorateWithEditorStateCallbacks,
              decorateWithReplaceCallbacks,
              decorateWithPositionerCallbacks,
              getAiMenuItems,
              renderOverlays,
            ],
          );

          return useEditorWithPlugin(baseEditor, props, { apply });
        },
      }),
    [baseEditor],
  );

const isActionAvailable = (_: EditorState) => true;
