import { EditorState } from 'draft-js';
import { useCallback, useMemo } from 'react';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { ApplyEditorStateChanges } from '../../editorCore/types/Editor.base.type.ts';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { Apply, EditorPlugin } from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import { withDisplayName } from '../../editorCore/utils/withDisplayName.ts';

export type ForceSelectionOnBlurPlugin = EditorPlugin;

const applyEditorStateChanges: Decorator<ApplyEditorStateChanges> =
  (baseApplyEditorStateChanges) => (params) => {
    const allowedNewState = baseApplyEditorStateChanges(params);
    const isBeingBlurred =
      !params.newState.getSelection().getHasFocus() && params.oldState.getSelection().getHasFocus();
    return isBeingBlurred ? EditorState.moveSelectionToEnd(allowedNewState) : allowedNewState;
  };

// This is here because the draft.js editor ignores any selection change in the onBlur event
// We don't know the exact scenarios which didn't work though (lost in history), we are just afraid to remove it at the moment
export const useForceSelectionOnBlur: PluginCreator<ForceSelectionOnBlurPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('ForceSelectionOnBlurPlugin', {
        ComposedEditor: (props) => {
          const apply: Apply<ForceSelectionOnBlurPlugin> = useCallback((state) => {
            state.applyEditorStateChanges.decorate(applyEditorStateChanges);

            return {};
          }, []);

          return useEditorWithPlugin(baseEditor, props, { apply });
        },
      }),
    [baseEditor],
  );
