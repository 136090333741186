import { assert } from '@kontent-ai/utils';
import React from 'react';
import { connect } from 'react-redux';
import { modalDismissed } from '../../../../../../_shared/actions/sharedActions.ts';
import {
  ConfirmationDialog,
  IConfirmationDialogDispatchProps,
  IConfirmationDialogStateProps,
} from '../../../../../../_shared/components/ModalDialog/ConfirmationDialog.tsx';
import { DiscardCurrentVersion } from '../../../../../../_shared/constants/itemActions.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { discardNewVersion } from '../../actions/thunkContentItemEditingActions.ts';

const mapStateToProps = (state: IStore): IConfirmationDialogStateProps => {
  const editedContentItemVariant = state.contentApp.editedContentItemVariant;
  assert(
    editedContentItemVariant,
    () => 'DiscardNewVersionConfirmationDialog.tsx: Edited content item variant is not set.',
  );

  const versionStepName = editedContentItemVariant.assignment.workflowStatus.name;

  return {
    headerContent: `Discard the ${versionStepName}`,
    confirmButtonText: DiscardCurrentVersion,
    children: (
      <>
        <p className="confirmation-dialog__section">
          This will return the content to the last <b>Published</b> version.
        </p>
        <p className="confirmation-dialog__section">
          Do you want to discard the selected {versionStepName}?
        </p>
      </>
    ),
  };
};

const mapDispatchToProps: IConfirmationDialogDispatchProps = {
  onClose: modalDismissed,
  onConfirm: discardNewVersion,
};

export const DiscardNewVersionConfirmationDialog: React.ComponentType = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfirmationDialog);
