import { InvariantException } from '@kontent-ai/errors';
import { History } from 'history';
import { Dispatch, GetState, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IRoleWithSettings } from '../../../../../data/models/roles/IRoleWithSettings.ts';
import { IRoleRepository } from '../../../../../repositories/interfaces/IRoleRepository.type.ts';
import { UnableToDeleteErrorMessage } from '../../constants/UIConstants.ts';
import {
  Role_Delete_Failed,
  Role_Delete_Finished,
  Role_Delete_Started,
} from '../../constants/rolesActionTypes.ts';

interface IDeps {
  readonly roleRepository: Pick<IRoleRepository, 'deleteRole'>;
}

interface IParams {
  readonly history: History;
  readonly redirectToLink: string;
}

const started = () =>
  ({
    type: Role_Delete_Started,
  }) as const;

const finished = (role: IRoleWithSettings) =>
  ({
    type: Role_Delete_Finished,
    payload: { role },
  }) as const;

const failed = (errorMessage: string) =>
  ({
    type: Role_Delete_Failed,
    payload: { errorMessage },
  }) as const;

export type DeleteEditedRoleActionsType = ReturnType<
  typeof started | typeof finished | typeof failed
>;

export const makeDeleteEditedRoleAction =
  (deps: IDeps) =>
  (params: IParams): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {
      rolesApp: {
        editorUi: { editedRole, originalEditedRole },
      },
    } = getState();

    if (!editedRole) {
      throw InvariantException('deleteRole.ts: edited role is null');
    }

    if (!originalEditedRole) {
      throw InvariantException('deleteRole.ts: original edited role is null');
    }

    dispatch(started());
    await deps.roleRepository.deleteRole(editedRole.id).catch((e) => {
      dispatch(failed(UnableToDeleteErrorMessage));

      throw e;
    });
    dispatch(finished(originalEditedRole));

    params.history.push(params.redirectToLink);
  };
