import PropTypes from 'prop-types';
import React from 'react';
import {
  IMultipleChoiceTypeElement,
  MultipleChoiceTypeElementPropTypes,
} from '../../../../contentInventory/content/models/contentTypeElements/MultipleChoiceTypeElement.ts';
import {
  IMultipleChoiceItemElement,
  MultipleChoiceItemElementPropTypes,
} from '../../../models/contentItemElements/MultipleChoiceItemElement.ts';
import { MultipleChoiceDiff } from '../../Revisions/components/elements/subelements/MultipleChoiceDiff.tsx';
import { ExpandedItemElement } from '../containers/ExpandedItemElement.tsx';
import { ExpandedItemElementDataProps } from './types/expandedItemElement.type.ts';

type ExpandedMultipleChoiceElementProps = ExpandedItemElementDataProps<
  IMultipleChoiceTypeElement,
  IMultipleChoiceItemElement
>;

const propTypes: PropTypeMap<ExpandedMultipleChoiceElementProps> = {
  className: PropTypes.string,
  elementData: MultipleChoiceItemElementPropTypes.isRequired,
  originalElementData: MultipleChoiceItemElementPropTypes,
  typeElement: MultipleChoiceTypeElementPropTypes.isRequired,
};

export const ExpandedMultipleChoiceElement: React.FC<ExpandedMultipleChoiceElementProps> = ({
  className,
  elementData,
  originalElementData,
  typeElement,
}) => {
  return (
    <ExpandedItemElement
      className={className}
      disabled
      hideValidationStatus
      isCompact
      typeElement={typeElement}
    >
      <MultipleChoiceDiff
        elementData={elementData}
        originalElementData={originalElementData}
        typeElement={typeElement}
      />
    </ExpandedItemElement>
  );
};

ExpandedMultipleChoiceElement.displayName = 'ExpandedMultipleChoiceElement';
ExpandedMultipleChoiceElement.propTypes = propTypes;
