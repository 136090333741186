import React, { useContext } from 'react';
import { Feature } from '../../../../@types/FeatureEnum.ts';
import { isFeatureEnabled } from '../../../_shared/utils/featureToggles.ts';

const IsUnifiedMissionControlEnabledContext = React.createContext<boolean>(false);

export const IsUnifiedMissionControlEnabledContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const isEnabled = isFeatureEnabled(Feature.UnifiedMissionControl);

  return (
    <IsUnifiedMissionControlEnabledContext.Provider value={isEnabled}>
      {children}
    </IsUnifiedMissionControlEnabledContext.Provider>
  );
};

export const useIsUnifiedMissionControlEnabled = (): boolean =>
  useContext(IsUnifiedMissionControlEnabledContext);
