import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

export interface IRouteChangeHandlerCallbackProps {
  readonly onDidMount?: () => void;
  readonly onWillUnmount?: () => void;
}

const propTypes: PropTypesShape<IRouteChangeHandlerCallbackProps> = {
  onDidMount: PropTypes.func,
  onWillUnmount: PropTypes.func,
};

const callIfCallable = (callback?: () => void): void => {
  if (callback) {
    callback();
  }
};

export const RouteChangeHandler: React.FC<IRouteChangeHandlerCallbackProps> = ({
  onDidMount,
  onWillUnmount,
}) => {
  useEffect(() => {
    callIfCallable(onDidMount);
    return () => callIfCallable(onWillUnmount);
  }, [onDidMount, onWillUnmount]);

  return null;
};

RouteChangeHandler.displayName = 'RouteChangeHandler';
RouteChangeHandler.propTypes = propTypes;
