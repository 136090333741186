import React from 'react';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import { Button, ButtonSize } from '../../../../../_shared/uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import { Icon } from '../../../../../_shared/uiComponents/Icon/Icon.tsx';

interface IFailedActionButtonProps {
  readonly onDelete: () => void;
  readonly isAdminOnProject: boolean;
  readonly isBeingDeleted: boolean;
}

export const FailedActionButton: React.FC<IFailedActionButtonProps> = ({
  isAdminOnProject,
  isBeingDeleted,
  onDelete,
}) => (
  <div className="my-project__button-wrapper">
    <div className="my-project__message">
      <Icon iconName={IconName.ExclamationTriangle} className="my-project__failed-icon" />
      <span className="my-project__message my-project__message--failed">
        The cloning failed. Please try again.
      </span>
    </div>
    {isAdminOnProject && (
      <Button
        style={ButtonStyle.DestructiveSecondary}
        size={ButtonSize.M}
        onClick={onDelete}
        disabled={isBeingDeleted}
        hasLoader={isBeingDeleted}
      >
        {isBeingDeleted ? 'Deleting project' : 'Remove'}
      </Button>
    )}
  </div>
);

FailedActionButton.displayName = 'FailedActionButton';
