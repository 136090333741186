import { EditorApiImplementation } from '../../../editorCore/types/Editor.api.type.ts';
import { SelectionAfter } from '../../../utils/editorStateUtils.ts';
import { CommentsPlugin } from '../CommentsPlugin.tsx';
import {
  addCommentToText,
  setBlockCommentSegmentId,
  syncCommentStyles,
} from './editorCommentUtils.ts';
import { applyApprovedSuggestions } from './editorSuggestionUtils.ts';

export const editorCommentApi: EditorApiImplementation<CommentsPlugin> = {
  applyApprovedSuggestions(api, editorState, suggestions, onSuggestionApplied, threadIdMapping) {
    const selection = editorState.getSelection();

    return api.executeContentChange(
      editorState,
      selection,
      (input) => applyApprovedSuggestions(input, suggestions, onSuggestionApplied, threadIdMapping),
      'insert-fragment',
      true,
      SelectionAfter.NewWithOriginalFocus,
    );
  },

  addCommentToText(api, editorState, selection, segmentId) {
    return api.executeContentChange(
      editorState,
      selection,
      (input) => addCommentToText(input, segmentId),
      'change-inline-style',
      false,
    );
  },

  setBlockCommentSegmentId(api, editorState, blockKey, segmentId) {
    return api.executeContentChange(
      editorState,
      editorState.getSelection(),
      (input) => setBlockCommentSegmentId(input, blockKey, segmentId),
      'change-block-data',
      false,
      SelectionAfter.Original,
    );
  },

  syncComments(api, editorState, commentsChanges) {
    return api.executeContentChange(
      editorState,
      editorState.getSelection(),
      (input) => syncCommentStyles(input, commentsChanges),
      'change-inline-style',
      false,
      SelectionAfter.Original,
    );
  },
};
