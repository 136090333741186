import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '../../../../_shared/uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../../../_shared/uiComponents/Button/buttonStyle.ts';

interface ICardProps {
  readonly imageClassName: string;
  readonly title: string;
  readonly text: string;
  readonly buttonText: string;
  readonly onClick: () => void;
  readonly isButtonPrimary?: boolean;
}

const propTypes: PropTypeMap<ICardProps> = {
  imageClassName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isButtonPrimary: PropTypes.bool,
};

export const OnboardingCard: React.FC<ICardProps> = (props) => (
  <div className="onboarding-card">
    <div className={classNames('onboarding-card__image', props.imageClassName)} />
    <div className="onboarding-card__body">
      <div className="onboarding-card__content">
        <span className="onboarding-card__title">{props.title}</span>
        <div className="onboarding-card__text">{props.text}</div>
      </div>
      <div className="onboarding-card__button">
        <Button
          style={props.isButtonPrimary ? ButtonStyle.Primary : ButtonStyle.Secondary}
          onClick={props.onClick}
        >
          {props.buttonText}
        </Button>
      </div>
    </div>
  </div>
);

OnboardingCard.displayName = 'OnboardingCard';
OnboardingCard.propTypes = propTypes;
