import PropTypes from 'prop-types';
import React from 'react';
import { ITextareaProps, Textarea } from '../Input/Textarea.tsx';
import { InputState } from '../Input/inputStateEnum.ts';
import {
  BaseClipboardComponent,
  CommonClipboardProps,
  commonClipboardPropTypes,
} from './components/BaseClipboardComponent.tsx';
import {
  ClipboardButton,
  ClipboardButtonInjectedProps,
  renderDefaultClipboardButton,
} from './components/ClipboardButton.tsx';

type MultilineClipboardOwnProps = {
  readonly renderClipboardButton?: (props: ClipboardButtonInjectedProps) => JSX.Element;
};

export type MultilineClipboardProps = CommonClipboardProps &
  MultilineClipboardOwnProps &
  Pick<
    ITextareaProps,
    | 'hintText'
    | 'inputState'
    | 'maxLength'
    | 'maxRows'
    | 'minRows'
    | 'name'
    | 'placeholder'
    | 'rows'
  >;

const multilineClipboardPropTypes: PropTypeMap<MultilineClipboardProps> = {
  ...commonClipboardPropTypes,
  hintText: PropTypes.string,
  inputState: PropTypes.oneOf(Object.values(InputState)),
  maxLength: PropTypes.number,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  rows: PropTypes.number,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  renderClipboardButton: PropTypes.func,
};

export const MultilineClipboardComponent = React.forwardRef<
  HTMLTextAreaElement,
  MultilineClipboardProps
>(
  (
    {
      inputState = InputState.ReadOnly,
      maxLength,
      minRows = 3,
      maxRows = minRows,
      rows,
      renderClipboardButton,
      ...otherProps
    },
    forwardedRef,
  ) => (
    <BaseClipboardComponent
      dataUiComponent={MultilineClipboard.displayName}
      inputState={inputState}
      renderClipboardButton={renderClipboardButton ?? renderDefaultClipboardButton}
      renderControlComponent={(injectedProps) => (
        <Textarea
          ref={forwardedRef}
          {...{
            maxLength,
            minRows,
            maxRows,
            rows,
          }}
          {...injectedProps}
        />
      )}
      {...otherProps}
    />
  ),
);

MultilineClipboardComponent.displayName = 'MultilineClipboard';
MultilineClipboardComponent.propTypes = multilineClipboardPropTypes;

const clipboardComposition = {
  Button: ClipboardButton,
} as const;

export const MultilineClipboard = Object.assign(MultilineClipboardComponent, clipboardComposition);
