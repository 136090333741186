import React, { RefObject, useContext, useMemo } from 'react';

type ScrollContainerContext = {
  readonly scrollContainerRef: RefObject<HTMLElement>;
  readonly tippyBoundaryRef: RefObject<HTMLElement>;
};

const nullRef: RefObject<HTMLElement> = { current: null };

const defaultScrollContainerContext: ScrollContainerContext = {
  scrollContainerRef: nullRef,
  tippyBoundaryRef: nullRef,
};

export const ScrollContainerContext = React.createContext<ScrollContainerContext>(
  defaultScrollContainerContext,
);

type ScrollContainerContextProviderProps = {
  readonly scrollContainerRef?: RefObject<HTMLElement> | null;
  readonly tippyBoundaryRef?: RefObject<HTMLElement> | null;
};

/**
 * Provides the context of the desired tippy boundary and scroll container for child components that
 * 1) Need to attach scroll events to the scroll container
 *    (e.g. to update Tippy instance upon scrolling, see useUpdateTippyWithScroll)
 * 2) Need to prevent Tippy components to overflow the fold of the scrolling container
 *    (e.g. toolbars/menus/popovers attached to components in a scrollable area, see usePreventOverflowFromScrollContainer)
 *
 * @param scrollContainerRef - The closest parent that provides scrollbar and can listen to scroll events
 * @param tippyBoundaryRef - The parent that defines tippy boundary in the given context
 * @param children
 */
export const ScrollContainerContextProvider: React.FC<
  React.PropsWithChildren<ScrollContainerContextProviderProps>
> = ({ scrollContainerRef, tippyBoundaryRef, children }) => {
  const { scrollContainerRef: parentScrollContainerRef, tippyBoundaryRef: parentTippyBoundaryRef } =
    useContext(ScrollContainerContext);

  const value = useMemo(
    () => ({
      scrollContainerRef:
        scrollContainerRef === undefined ? parentScrollContainerRef : scrollContainerRef ?? nullRef,
      tippyBoundaryRef:
        tippyBoundaryRef === undefined ? parentTippyBoundaryRef : tippyBoundaryRef ?? nullRef,
    }),
    [scrollContainerRef, tippyBoundaryRef, parentScrollContainerRef, parentTippyBoundaryRef],
  );

  return (
    <ScrollContainerContext.Provider value={value}>{children}</ScrollContainerContext.Provider>
  );
};
