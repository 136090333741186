import React, { useCallback } from 'react';
import { EnsureValidState } from '../../../../../_shared/components/EnsureValidState.tsx';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { loadTimeline } from '../../Timeline/actions/thunkTimelineActions.ts';

export const EnsureEntryTimeline: React.FC<React.PropsWithChildren<NoProps>> = ({ children }) => {
  const dispatch = useDispatch();
  const isStateEnsured = useSelector<boolean>((state) => state.contentApp.entryTimeline !== null);

  const onRouteEntered = useCallback(() => dispatch(loadTimeline()), []);

  return (
    <EnsureValidState
      isStateEnsured={isStateEnsured}
      onRouteEntered={isStateEnsured ? undefined : onRouteEntered}
    >
      {children}
    </EnsureValidState>
  );
};
