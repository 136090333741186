import { Box } from '@kontent-ai/component-library/Box';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, Typography, colorSuccessIcon } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { Widget } from './Widget.tsx';

type WidgetEmptyStateProps = {
  readonly title: string;
  readonly message: string;
};

export const WidgetEmptyState: React.FC<WidgetEmptyStateProps> = ({ title, message }) => {
  return (
    <Widget.Body>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        minHeight={200}
      >
        <Stack align="center" spacing={Spacing.L}>
          <Icons.CheckCircle height={48} width={48} color={colorSuccessIcon} />
          <Stack align="center" spacing={Spacing.XXS}>
            <Box typography={Typography.HeadlineLarge}>{title}</Box>
            <Box typography={Typography.BodyMedium}>{message}</Box>
          </Stack>
        </Stack>
      </Box>
    </Widget.Body>
  );
};

WidgetEmptyState.displayName = 'WidgetEmptyState';
