import TextareaAutosize from 'react-textarea-autosize';
import styled, { css } from 'styled-components';
import {
  colorTextDefault,
  colorTextDisabled,
  colorTextHint,
} from '../../../tokens/decision/colors.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { InputStyleProps } from './inputStyleProps.type.ts';
import { getInputCursor } from './stylingUtils.ts';
import { inputMinHeight, inputTypography } from './tokens.ts';

const baseControlStyles = css<InputStyleProps>`
  ${inputTypography};
  
  position: relative;
  
  display: block;
  width: 100%;
  min-height: ${px(inputMinHeight)};
  padding: calc((${px(inputMinHeight)} - ${inputTypography.lineHeight}) / 2) 0; 
  border: none;
  background: none;
  color: ${colorTextDefault};
  
  // override normalize.less styles
  && {
    cursor: ${getInputCursor};
  }
  
  &::placeholder {
    color: ${colorTextHint};
    // Override Firefox’s unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }

  &[disabled] {
    color: ${colorTextDisabled};
    // Safari needs to explicitly override disabled color
    -webkit-text-fill-color: ${colorTextDisabled};
    opacity: 1;
  }

  &[readonly] {
    text-overflow: ellipsis;
  }
  
  &:focus {
    outline: none;
  }
`;

export const InputControl = styled.input`
  ${baseControlStyles};
  
  
  ${({ type }) =>
    type === 'password' &&
    css`
    // In Mozilla the dots are by default bigger than in other browsers
    @-moz-document url-prefix() {
      & {
        font-family: 'pass';
      }
    }

    &[readonly] {
      text-overflow: unset;
    }
  `}
`;
InputControl.displayName = 'InputControl';

export const TextareaControl = styled(TextareaAutosize)`
  ${baseControlStyles};
  resize: none;
`;
TextareaControl.displayName = 'TextareaControl';
