import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { trackUserEventWithData } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import {
  ContentItemEditingEventOrigins,
  ContentItemEditingEventTypes,
} from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { ContentItemSidebarEditableSection } from '../../constants/ContentItemSidebarEditableSection.ts';
import {
  contentItemEditingSidebarSectionFocused,
  deactivateContentItemEditingAction,
} from '../contentItemEditingActions.ts';

type ActionType =
  | ContentItemEditingEventTypes.ChangeDueDateSelected
  | ContentItemEditingEventTypes.AssignContributorsSelected
  | ContentItemEditingEventTypes.NoteSelected
  | ContentItemEditingEventTypes.TasksSelected;

const sectionToAction: { readonly [K in ContentItemSidebarEditableSection]: ActionType } = {
  [ContentItemSidebarEditableSection.Contributors]:
    ContentItemEditingEventTypes.AssignContributorsSelected,
  [ContentItemSidebarEditableSection.DueDate]: ContentItemEditingEventTypes.ChangeDueDateSelected,
  [ContentItemSidebarEditableSection.Note]: ContentItemEditingEventTypes.NoteSelected,
  [ContentItemSidebarEditableSection.Tasks]: ContentItemEditingEventTypes.TasksSelected,
};

type IParams = {
  readonly defaultFocus: ContentItemSidebarEditableSection;
};

export const createOpenItemDetailsWithFocusedSection =
  ({ defaultFocus }: IParams) =>
  (origin: ContentItemEditingEventOrigins): ThunkFunction =>
  (dispatch): void => {
    dispatch(deactivateContentItemEditingAction());
    dispatch(contentItemEditingSidebarSectionFocused(origin, defaultFocus));
    dispatch(
      trackUserEventWithData(TrackedEvent.ContentItemEditing, {
        action: sectionToAction[defaultFocus],
        origin,
      }),
    );
  };
