import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { noOperation } from '@kontent-ai/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { ScrollTableCell } from './ScrollTableCell.tsx';

export interface IScrollTableExpandToggleProps {
  readonly disabledMessage?: string;
  readonly isExpanded: boolean;
  readonly isHidden?: boolean;
  readonly onToggle: () => void;
  readonly tabIndex?: number;
}

const propTypes: PropTypesShape<IScrollTableExpandToggleProps> = {
  disabledMessage: PropTypes.string,
  isExpanded: PropTypes.bool.isRequired,
  isHidden: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  tabIndex: PropTypes.number,
};

export const ScrollTableExpandToggleCell: React.FC<IScrollTableExpandToggleProps> = ({
  disabledMessage,
  isExpanded,
  isHidden,
  onToggle,
}) => {
  const isDisabled = !!disabledMessage;
  const enabledMessage = isExpanded ? 'Collapse' : 'Expand';
  const tooltipText = isDisabled ? '' : enabledMessage;

  return isHidden ? (
    <ScrollTableCell disabledTooltip={disabledMessage} size={1} />
  ) : (
    <ScrollTableCell size={1} disabledTooltip={disabledMessage}>
      <QuinaryButton
        disabled={isDisabled}
        tooltipPlacement="right"
        onClick={isDisabled ? noOperation : onToggle}
        tooltipText={tooltipText}
        {...getDataUiActionAttribute(isExpanded ? DataUiAction.Collapse : DataUiAction.Expand)}
      >
        <QuinaryButton.Chevron
          direction={isExpanded ? 'down' : 'right'}
          screenReaderText={enabledMessage}
        />
      </QuinaryButton>
    </ScrollTableCell>
  );
};

ScrollTableExpandToggleCell.displayName = 'ScrollTableExpandToggleCell';
ScrollTableExpandToggleCell.propTypes = propTypes;
