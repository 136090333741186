import { memoize } from '@kontent-ai/memoization';
import { IRole } from '../../../../data/models/roles/IRole.ts';
import { RoleOption, mapRoleToOption } from '../../../../data/models/roles/RoleOption.ts';
import { getSortedRolesMemoized } from '../../roles/selectors/getSortedRolesList.ts';
import { allRolesRole } from '../constants/allRolesRole.ts';

export const getSortedRoleOptionsListWithAllRolesFirst = memoize.maxOne(
  (rolesById: ReadonlyMap<Uuid, IRole>): ReadonlyArray<RoleOption> =>
    [allRolesRole].concat(getSortedRolesMemoized(rolesById)).map(mapRoleToOption),
);
