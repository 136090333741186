import { ContentState, EditorState } from 'draft-js';
import PropTypes from 'prop-types';
import React, { FormEvent } from 'react';
import { HotkeysHandler } from '../../../../../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import { LabelFor } from '../../../../../../../_shared/uiComponents/LabelFor/LabelFor.tsx';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { isEmptyOrWhitespace } from '../../../../../../../_shared/utils/stringUtils.ts';
import { IFocusable } from '../../../../../../richText/plugins/behavior/FocusPlugin.tsx';
import { Resettable } from '../../../../../../richText/plugins/behavior/ManualResetPlugin.tsx';
import {
  DebouncedChanges,
  EditorChangeCallback,
} from '../../../../../../richText/plugins/behavior/OnChangePlugin.tsx';
import { emptyEditorState } from '../../../../../../richText/utils/general/editorEmptyValues.ts';
import { CommentInput } from '../../../containers/comments/input/CommentInput.tsx';
import { NewCommentThreadActionsBar } from './NewCommentThreadActionsBar.tsx';

export interface INewCommentThreadCallbackProps {
  readonly onBlur?: (isCommentPending: boolean) => void;
  readonly onCancel: () => void;
  readonly onFocus?: () => void;
  readonly onSubmit: (commentContent: ContentState) => Promise<void>;
}

export interface INewCommentThreadDataProps {
  readonly areButtonsDisplayed: boolean;
  readonly isEditing: boolean;
  readonly isSubmitting: boolean;
}

interface INewCommentThreadState {
  readonly commentContent: EditorState;
  readonly lastKnownHasFocus: boolean;
}

type NewCommentThreadProps = INewCommentThreadCallbackProps & INewCommentThreadDataProps;

export class NewCommentThread extends React.PureComponent<
  NewCommentThreadProps,
  INewCommentThreadState
> {
  static displayName = 'NewCommentThread';

  static propTypes: PropTypesShape<NewCommentThreadProps> = {
    areButtonsDisplayed: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,

    onBlur: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
  };

  readonly state: INewCommentThreadState = {
    commentContent: emptyEditorState,
    lastKnownHasFocus: false,
  };

  private isComponentMounted = false;

  private readonly focusableRef = React.createRef<IFocusable>();
  private readonly resettableRef = React.createRef<Resettable>();
  private readonly debouncedChangesRef = React.createRef<DebouncedChanges>();

  componentDidUpdate(prevProps: NewCommentThreadProps) {
    if (!prevProps.isEditing && this.props.isEditing) {
      this.focus();
    }
  }

  componentDidMount() {
    this.isComponentMounted = true;
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  public focus = (): void => {
    this.focusableRef.current?.focus();
    this.props.onFocus?.();
  };

  private readonly isTextValid = (): boolean => {
    return !isEmptyOrWhitespace(this.state.commentContent.getCurrentContent().getPlainText());
  };

  private readonly isSubmitButtonDisabled = (): boolean => {
    return !this.isTextValid() || this.props.isSubmitting;
  };

  private readonly onSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.stopPropagation();
    event.preventDefault();

    this.handleSubmit();
  };

  private readonly onCommentChange: EditorChangeCallback = (editorState) => {
    this.setState(() => ({ commentContent: editorState }));
    return Promise.resolve();
  };

  private readonly blur = (): void => {
    this.focusableRef.current?.blur();
    this.props.onBlur?.(!this.state.commentContent.getCurrentContent().getPlainText());
  };

  private readonly onCancelCommentShortcut = (): void => {
    this.cancelEditing();
    this.blur();
  };

  private readonly handleSubmit = (): void => {
    if (this.isTextValid()) {
      this.submit();
    }
  };

  private readonly submit = async (): Promise<void> => {
    await this.debouncedChangesRef.current?.propagatePendingContentChanges();
    await this.props.onSubmit(this.state.commentContent.getCurrentContent());
    if (this.isComponentMounted) {
      this.resetCommentInput();
      this.focus();
    }
  };

  private readonly resetCommentInput = (): void => {
    this.resettableRef.current?.resetInput(emptyEditorState.getCurrentContent());
    this.setState(() => ({ commentContent: emptyEditorState }));
  };

  private readonly cancelEditing = (): void => {
    this.resetCommentInput();
    this.props.onCancel();
  };

  private readonly onCommentSelectionChange: EditorChangeCallback = (editorState) => {
    const hasFocus = editorState.getSelection().getHasFocus();
    if (this.state.lastKnownHasFocus !== hasFocus) {
      this.focus();
      this.setState(
        () => ({ lastKnownHasFocus: hasFocus }),
        () => {
          if (!hasFocus) {
            this.blur();
          }
        },
      );
    }
    return Promise.resolve();
  };

  render(): JSX.Element {
    const { isSubmitting, areButtonsDisplayed } = this.props;

    return (
      <form
        className="form comment-editor__form comment-editor__form--is-on-discussion-tab"
        onSubmit={this.onSubmit}
      >
        <LabelFor
          isHidden
          target={() => (
            <HotkeysHandler
              handlers={{
                onControlEnter: this.handleSubmit,
              }}
            >
              <CommentInput
                debouncedChangesRef={this.debouncedChangesRef}
                disabled={isSubmitting}
                editorState={emptyEditorState}
                focusableRef={this.focusableRef}
                onContentChange={this.onCommentChange}
                onEscape={this.onCancelCommentShortcut}
                onSelectionChange={this.onCommentSelectionChange}
                resettableRef={this.resettableRef}
                {...getDataUiInputAttribute(DataUiInput.Comment)}
              />
            </HotkeysHandler>
          )}
        >
          Comment
        </LabelFor>
        {areButtonsDisplayed && (
          <NewCommentThreadActionsBar
            isSubmitting={isSubmitting}
            onCancelEditing={this.cancelEditing}
            isSubmitButtonDisabled={this.isSubmitButtonDisabled()}
          />
        )}
      </form>
    );
  }
}
