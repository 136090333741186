import { Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';
import ImmutablePropTypes from 'immutable-prop-types';
import PropTypes, { Validator } from 'prop-types';
import React from 'react';
import { Button } from '../../../../../../../_shared/uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import { DataUiAction } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IContentType } from '../../../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IRuleWithScope } from '../../../../../../../data/models/roles/IRoleSettings.ts';
import { emptyContentCanRule } from '../../../../constants/emptyRules.ts';
import { RoleEditorStatus } from '../../../../models/RoleEditorStatus.ts';
import { ContentCapabilityRulePicker } from './ContentCapabilityRulePicker.tsx';

type Props = {
  readonly allTypes: Immutable.Map<Uuid, IContentType>;
  readonly contentCanRules: ReadonlyArray<IRuleWithScope>;
  readonly isDisabled: boolean;
  readonly onChange: (newRules: ReadonlyArray<IRuleWithScope>) => void;
  readonly status: RoleEditorStatus;
};

export const ContentProductionCanRules: React.FC<Props> = ({
  contentCanRules,
  allTypes,
  onChange,
  isDisabled,
  status,
}) => (
  <>
    <p className="capability-rules__title">Users with this role can:</p>
    {contentCanRules.map((rule: IRuleWithScope, index: number) => (
      <ContentCapabilityRulePicker
        key={index}
        index={index}
        isDisabled={isDisabled}
        onRemove={() => onChange(contentCanRules.filter((_, i) => i !== index))}
        allTypes={allTypes}
        capabilityRule={rule}
        onChange={(newRule) => onChange(Collection.replace(contentCanRules, index, newRule))}
        status={status}
      />
    ))}
    {!isDisabled && (
      <Button
        disabled={isDisabled}
        style={ButtonStyle.Tertiary}
        customButtonClassName="capability-rules__button"
        onClick={() => onChange([...contentCanRules, emptyContentCanRule])}
        dataUiAction={DataUiAction.Add}
      >
        Add rule
      </Button>
    )}
  </>
);

const propTypes: PropTypeMap<Props> = {
  allTypes: ImmutablePropTypes.map.isRequired,
  contentCanRules: PropTypes.arrayOf(PropTypes.object.isRequired as Validator<IRuleWithScope>)
    .isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired as Validator<RoleEditorStatus>,
};

ContentProductionCanRules.propTypes = propTypes;
ContentProductionCanRules.displayName = 'ContentProductionCanRules';
