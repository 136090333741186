import { useAttachRef } from '@kontent-ai/hooks';
import { delay } from '@kontent-ai/utils';
import classNames from 'classnames';
import { Identifier } from 'dnd-core';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { ConnectDragSource, DragSourceHookSpec, useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { getNativeDomSelection } from '../../utils/selectionUtils.ts';
import { DragPreview } from './DragPreview.tsx';
import { CollectedProps, DragObject } from './dragDrop.type.ts';
import { DragSourceElementClassname } from './dragDropConstants.ts';

type DragSourceProps = DragObject & {
  readonly className?: string;
  readonly onDragEnd?: () => void;
  readonly onDragStart?: () => void;
  readonly renderDraggable: (
    connectDragSource: ConnectDragSource,
    isDragging: boolean,
  ) => React.ReactElement;
  readonly renderPreview?: () => React.ReactNode;
  readonly type: Identifier;
};

const propTypes: PropTypeMap<DragSourceProps> = {
  className: PropTypes.string,
  onDragEnd: PropTypes.func,
  onDragStart: PropTypes.func,
  parentId: PropTypes.string.isRequired,
  renderDraggable: PropTypes.func.isRequired,
  renderPreview: PropTypes.func,
  sourceId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export function DragSource({
  className,
  onDragEnd,
  onDragStart,
  parentId,
  renderDraggable,
  renderPreview,
  sourceId,
  type,
}: DragSourceProps) {
  const dragStartPromise = useRef<Promise<void> | null>(null);

  // Drag start event may trigger an immediate DOM update which causes the Chrome ending the drag prematurely
  // in case the scrollbar hits the bottom (the scrollable area shrinks)
  // By debouncing the drag start, we make sure the DOM is not modified until the drag actually starts
  // https://stackoverflow.com/questions/19639969/html5-dragend-event-firing-immediately
  const dragStart = useCallback((): void => {
    // Safari includes the text preview to the drag selection, clear the existing DOM selection to prevent it
    getNativeDomSelection()?.removeAllRanges();
    const deferredDragStart =
      onDragStart &&
      (async () => {
        await delay(0);
        onDragStart();
      });
    dragStartPromise.current = deferredDragStart?.() ?? null;
  }, [onDragStart]);

  // UI tests trigger both dragStart and dragEnd events synchronously
  // but as we defer dragStart above, we need to make sure that dragEnd waits for it to finish first
  // this may be removed after DEVOPS-189 makes D&D in UI tests more user-like
  const dragEnd = useCallback((): void => {
    if (onDragEnd) {
      if (dragStartPromise.current) {
        dragStartPromise.current.then(onDragEnd);
      } else {
        onDragEnd();
      }
    }
  }, [onDragEnd]);

  const createItem = useCallback((): DragObject => {
    dragStart();
    return {
      parentId,
      sourceId,
    };
  }, [dragStart, parentId, sourceId]);

  const dragSpecs = useMemo(
    (): DragSourceHookSpec<DragObject, never, CollectedProps> => ({
      type,
      item: createItem,
      end: dragEnd,
      isDragging: (monitor) => monitor.getItem().sourceId === sourceId,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [createItem, dragEnd, type, sourceId],
  );

  const [{ isDragging }, connectDragSource, connectDragPreview] = useDrag<
    DragObject,
    never,
    CollectedProps
  >(dragSpecs);

  useEffect(() => {
    if (renderPreview) {
      connectDragPreview(getEmptyImage());
    }
  }, [renderPreview, connectDragPreview]);

  const { refObject: sourceRefObject, refToForward: sourceRefToForward } =
    useAttachRef<HTMLDivElement>(renderPreview ? undefined : connectDragPreview);

  return (
    <div className={classNames(DragSourceElementClassname, className)} ref={sourceRefToForward}>
      {renderPreview && (
        <DragPreview
          renderPreview={renderPreview}
          sourceId={sourceId}
          sourceRef={sourceRefObject}
        />
      )}
      {renderDraggable(connectDragSource, isDragging)}
    </div>
  );
}

DragSource.displayName = 'DragSource';
DragSource.propTypes = propTypes;
