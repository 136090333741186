import { Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';
import ImmutablePropTypes from 'immutable-prop-types';
import PropTypes, { Validator } from 'prop-types';
import React from 'react';
import { Button } from '../../../../../../../_shared/uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import { DataUiAction } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IContentType } from '../../../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IRule } from '../../../../../../../data/models/roles/IRoleSettings.ts';
import { emptyCannotRule } from '../../../../constants/emptyRules.ts';
import { RoleEditorStatus } from '../../../../models/RoleEditorStatus.ts';
import { CapabilityRuleExceptionPicker } from './CapabilityRuleExceptionPicker.tsx';

type Props = {
  readonly allTypes: Immutable.Map<Uuid, IContentType>;
  readonly isDisabled: boolean;
  readonly onChange: (newRules: ReadonlyArray<IRule>) => void;
  readonly contentCannotRules: ReadonlyArray<IRule>;
  readonly status: RoleEditorStatus;
};

export const ContentProductionCannotRules: React.FC<Props> = ({
  contentCannotRules,
  allTypes,
  onChange,
  isDisabled,
  status,
}) => (
  <>
    {(contentCannotRules.length > 0 || !isDisabled) && (
      <p className="capability-rules__title">
        Users with this role <b>cannot</b>:
      </p>
    )}
    {contentCannotRules.length === 0 && !isDisabled && (
      <div className="capability-rules__empty-message">Add exceptions to the rules above.</div>
    )}
    {contentCannotRules.map((rule: IRule, index: number) => (
      <CapabilityRuleExceptionPicker
        key={index}
        index={index}
        isDisabled={isDisabled}
        onRemove={() => onChange(contentCannotRules.filter((_, i) => i !== index))}
        allTypes={allTypes}
        capabilityRule={rule}
        onChange={(newRule) => onChange(Collection.replace(contentCannotRules, index, newRule))}
        status={status}
      />
    ))}
    {!isDisabled && (
      <Button
        disabled={isDisabled}
        style={ButtonStyle.Tertiary}
        customButtonClassName="capability-rules__button"
        onClick={() => onChange([...contentCannotRules, emptyCannotRule])}
        dataUiAction={DataUiAction.AddException}
      >
        Add exception
      </Button>
    )}
  </>
);

const propTypes: PropTypeMap<Props> = {
  allTypes: ImmutablePropTypes.map.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  contentCannotRules: PropTypes.arrayOf(PropTypes.object.isRequired as Validator<IRule>).isRequired,
  status: PropTypes.string.isRequired as Validator<RoleEditorStatus>,
};

ContentProductionCannotRules.propTypes = propTypes;
ContentProductionCannotRules.displayName = 'ContentProductionCannotRules';
