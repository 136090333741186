import { makeCancellablePromise, swallowCancelledPromiseError } from '@kontent-ai/utils';
import React, { useEffect } from 'react';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { emptySubscription } from '../../../../data/models/subscriptions/Subscription.ts';
import { getSelectedSubscription } from '../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import {
  loadSubscriptionBalance,
  loadSubscriptionTransactions,
} from '../actions/thunkTransactionsActions.ts';
import { Transactions as TransactionsComponent } from '../components/Transactions.tsx';

export const Transactions: React.FC = () => {
  const selectedSubscription = useSelector((s) => getSelectedSubscription(s) ?? emptySubscription);

  const currentPlan = selectedSubscription.currentPlan;
  const isPrepaid = useSelector(
    (s) => s.data.plans.byId.get(currentPlan.planId)?.isPrepaid ?? false,
  );

  const transactions = useSelector((s) => s.data.subscriptions.subscriptionTransactions);
  const balance = useSelector((s) => s.data.subscriptions.subscriptionBalance);

  const dispatch = useDispatch();

  useEffect(() => {
    const initPromise = makeCancellablePromise(() =>
      Promise.all([
        dispatch(loadSubscriptionTransactions(selectedSubscription.subscriptionId)),
        dispatch(loadSubscriptionBalance(selectedSubscription.subscriptionId)),
      ]),
    ).catch(swallowCancelledPromiseError);

    return () => initPromise.cancel();
  }, [selectedSubscription.subscriptionId]);

  if (!transactions) {
    return <Loader />;
  }

  return (
    <TransactionsComponent
      balance={balance}
      endAt={currentPlan.endAt}
      isPrepaid={isPrepaid}
      transactions={transactions}
    />
  );
};

Transactions.displayName = 'Transactions';
