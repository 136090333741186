import { memoize } from '@kontent-ai/memoization';
import { Collection } from '@kontent-ai/utils';
import { MemoizedContentItemId } from '../../../../../_shared/models/ContentItemId.type.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { CannotPublishReason } from '../constants/cannotPublishReason.ts';
import { GetCannotPublishReason } from '../utils/getCannotPublishReason.ts';

export const getLoadedTransitiveDependencies = memoize.maxOne(
  (state: IStore): ReadonlySet<MemoizedContentItemId> => {
    const { expandedNodesData } = state.contentApp.editorUi.cascadePublish;

    return new Set(
      Array.from(expandedNodesData.values()).flatMap(({ childContentItemIds, contentItemId }) => [
        contentItemId,
        ...childContentItemIds,
      ]),
    );
  },
);

export const getItemsAvailableForSelection = memoize.maxOne(
  (
    state: IStore,
    getCannotPublishReason: GetCannotPublishReason,
  ): ReadonlyArray<MemoizedContentItemId> =>
    Array.from(getLoadedTransitiveDependencies(state)).filter(
      (contentItemId) => getCannotPublishReason(state, contentItemId) === CannotPublishReason.None,
    ),
);

/**
 * Always use this selector when accessing selecting items.
 * After reloading the transitive dependencies, we can lose some of the already selected items
 * from the dependencies, or their validity can change, therefore, we need to filter them out.
 *
 * ========= Note on MEMOIZATION ========
 * This memoization is perfectly fine. We are doing this purely for computation complexity reasons.
 * With one redux dispatch (1 reference to IStore and the same reference to getter function)
 * this function get called hundreds of times for each instance of rendered
 * ChildRow container.
 *
 * Messing this up can have huge performance consequences. Think twice before you change this.
 */
export const getSelectedItems = memoize.maxOne(
  (
    state: IStore,
    getCannotPublishReason: GetCannotPublishReason,
  ): ReadonlyArray<MemoizedContentItemId> =>
    Collection.intersect(
      Array.from(state.contentApp.editorUi.cascadePublish.selectedItems),
      // Even though the selectedItems above is already a Set, it will always be smaller than all items that can be selected
      // Moreover, mostly only few of them will be selected
      // That is why we prefer to create set from all available items to get the best impact for O(log(n)) of the intersection
      new Set(getItemsAvailableForSelection(state, getCannotPublishReason)),
    ),
);
