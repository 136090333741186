import {
  Dispatch,
  GetState,
  ThunkFunction,
  ThunkPromise,
} from '../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import {
  TrackUserEventWithData,
  TrackUserEventWithDataAction,
} from '../../../../../_shared/models/TrackUserEvent.type.ts';
import {
  IContentTypeSnippet,
  convertContentTypeSnippetToServerModel,
  getContentTypeSnippetFromServerModel,
} from '../../../../../data/models/contentModelsApp/snippets/ContentTypeSnippet.ts';
import { ContentTypeSnippetServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeSnippetServerModels.type.ts';
import { typeEditorSaveAttempted } from '../../../shared/actions/sharedContentModelsActions.ts';
import { IBaseTypeElementData } from '../../../shared/models/elements/types/TypeElementData.ts';
import { createActiveConditionIds } from '../../../shared/utils/conditionUtils.ts';
import { ITrackAssetLimitConfigurationChanged } from '../../../shared/utils/intercomHelpers/trackAssetLimitsUtils.ts';
import { ITrackCustomTypeElementUpserted } from '../../../shared/utils/intercomHelpers/trackCustomTypeElementConfig.ts';
import {
  ContentTypeSnippet_Editor_SavingFinished,
  ContentTypeSnippet_Editor_SavingStarted,
} from '../../constants/snippetActionTypes.ts';
import { isContentTypeSnippetValid } from '../../utils/isContentTypeSnippetValid.ts';
import { contentTypeSnippetInvalidElementShowWarning } from '../snippetsActions.ts';

interface IDeps {
  readonly contentTypeSnippetRepository: {
    readonly updateContentTypeSnippet: (
      contentType: ContentTypeSnippetServerModel,
    ) => Promise<ContentTypeSnippetServerModel>;
  };
  readonly loadSnippetsData: () => ThunkPromise;
  readonly saveEditedMultipleChoiceOption: () => ThunkFunction;
  readonly trackAssetLimitConfigurationChanged: ITrackAssetLimitConfigurationChanged;
  readonly trackCustomTypeElementUpserted: ITrackCustomTypeElementUpserted;
  readonly trackUserEvent: (eventName: TrackedEvent) => ThunkFunction;
  readonly trackUserEventWithData: TrackUserEventWithDataAction;
  readonly validateTypeElement: (updatedTypeElement: IBaseTypeElementData) => ThunkFunction;
}

const saveEditedContentTypeSnippetStarted = () =>
  ({
    type: ContentTypeSnippet_Editor_SavingStarted,
  }) as const;

const saveEditedContentTypeSnippetFinished = (
  contentTypeSnippet: IContentTypeSnippet,
  contentTypeSnippetElements: ReadonlyArray<IBaseTypeElementData>,
) =>
  ({
    type: ContentTypeSnippet_Editor_SavingFinished,
    payload: {
      contentTypeSnippet,
      contentTypeSnippetElements,
    },
  }) as const;

export type SaveEditedContentTypeSnippetActionsType = ReturnType<
  typeof saveEditedContentTypeSnippetStarted | typeof saveEditedContentTypeSnippetFinished
>;

export const createSaveEditedContentTypeSnippetAction =
  (deps: IDeps) =>
  (onSuccess?: () => void, onFail?: () => void): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    dispatch(deps.saveEditedMultipleChoiceOption());

    const {
      contentModelsApp: {
        snippets: {
          editor: { editedContentTypeSnippet },
        },
      },
    } = getState();

    dispatch(typeEditorSaveAttempted(createActiveConditionIds(editedContentTypeSnippet)));

    editedContentTypeSnippet.typeElements.forEach((element) =>
      dispatch(deps.validateTypeElement(element)),
    );
    if (!isContentTypeSnippetValid(getState().contentModelsApp.snippets.editor.validationResults)) {
      dispatch(contentTypeSnippetInvalidElementShowWarning());
      if (onFail) {
        onFail();
      }
      return;
    }

    dispatch(saveEditedContentTypeSnippetStarted());
    const contentTypeForServer = convertContentTypeSnippetToServerModel(editedContentTypeSnippet);
    const updatedContentTypeSnippetServerModel =
      await deps.contentTypeSnippetRepository.updateContentTypeSnippet(contentTypeForServer);
    const updatedContentTypeSnippet = getContentTypeSnippetFromServerModel(
      updatedContentTypeSnippetServerModel,
    );
    const updatedContentTypeSnippetElements = updatedContentTypeSnippet.typeElements;
    const originalSnippetElements = getState().data.snippets.byId.get(
      updatedContentTypeSnippet.id,
    )?.typeElements;
    const trackEvent: TrackUserEventWithData = (...args) =>
      dispatch(deps.trackUserEventWithData(...args));
    deps.trackAssetLimitConfigurationChanged(
      trackEvent,
      updatedContentTypeSnippetElements,
      originalSnippetElements,
    );
    deps.trackCustomTypeElementUpserted(
      trackEvent,
      updatedContentTypeSnippetElements,
      originalSnippetElements,
    );
    dispatch(
      saveEditedContentTypeSnippetFinished(
        updatedContentTypeSnippet,
        updatedContentTypeSnippetElements,
      ),
    );
    dispatch(deps.loadSnippetsData());
    dispatch(deps.trackUserEvent(TrackedEvent.ContentTypeSnippetUpdated));

    if (onSuccess) {
      onSuccess();
    }
  };
