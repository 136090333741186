import { Context, useContext } from 'react';

export const useEnsuredContext = <TContext>(context: Context<TContext>): NonNullable<TContext> => {
  const contextValue = useContext(context);
  if (!contextValue) {
    throw new Error(`${context.displayName} context must be used within its provider.`);
  }

  return contextValue;
};
