import Immutable from 'immutable';
import { IAsset } from '../../../data/models/assets/Asset.ts';
import { ITaxonomyGroup } from '../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { Languages } from '../../../data/models/languages/Language.ts';
import { IListingContentItem } from '../../../data/models/listingContentItems/IListingContentItem.ts';
import { ICompiledContentType } from '../../contentInventory/content/models/CompiledContentType.ts';
import {
  CompiledTypeElementType,
  ElementType,
} from '../../contentInventory/content/models/ContentItemElementType.ts';
import { EditableTypeElement } from '../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { ICompiledContentItemElementData } from '../models/contentItemElements/ICompiledContentItemElement.ts';
import { getAssetItemElementValidationFriendlyWarning } from './itemElementFriendlyWarningCheckers/assetElementFriendlyWarningChecker.ts';
import { getLinkedItemElementValidationFriendlyWarning } from './itemElementFriendlyWarningCheckers/modularItemElementFriendlyWarningChecker.ts';
import { getRichTextItemElementValidationFriendlyWarning } from './itemElementFriendlyWarningCheckers/richTextItemElementFriendlyWarningChecker.ts';
import { getTaxonomyElementValidationFriendlyWarning } from './itemElementFriendlyWarningCheckers/taxonomyElementFriendlyWarningChecker.ts';
import { ItemElementFriendlyWarningResult } from './itemElementFriendlyWarningCheckers/types/FriendlyWarnings.ts';

const emptyResult: ItemElementFriendlyWarningResult = {
  warningMessages: [],
};

export interface IGetItemElementFriendlyWarningCheckerParams<
  TItemElement extends ICompiledContentItemElementData = ICompiledContentItemElementData,
> {
  readonly currentLanguageId: Uuid;
  readonly itemElement: TItemElement;
  readonly languages: Languages;
  readonly loadedAssets: Immutable.Map<Uuid, IAsset>;
  readonly loadedContentTypes: Immutable.Map<Uuid, ICompiledContentType>;
  readonly loadedEntries: Immutable.Map<Uuid, IListingContentItem>;
  readonly taxonomyGroups: Immutable.Map<Uuid, ITaxonomyGroup>;
  readonly typeElement: EditableTypeElement;
}

export type IGetItemElementFriendlyWarnings<
  TItemElement extends ICompiledContentItemElementData = ICompiledContentItemElementData,
  TParams extends Partial<
    IGetItemElementFriendlyWarningCheckerParams<TItemElement>
  > = IGetItemElementFriendlyWarningCheckerParams<TItemElement>,
> = (params: TParams) => ItemElementFriendlyWarningResult;

const itemElementFriendlyWarningCheckersMap = (
  elementType: CompiledTypeElementType,
): IGetItemElementFriendlyWarnings<any, any> => {
  switch (elementType) {
    case ElementType.RichText:
      return getRichTextItemElementValidationFriendlyWarning;

    case ElementType.Asset:
      return getAssetItemElementValidationFriendlyWarning;

    case ElementType.LinkedItems:
      return getLinkedItemElementValidationFriendlyWarning;

    case ElementType.Taxonomy:
      return getTaxonomyElementValidationFriendlyWarning;

    default:
      return () => emptyResult;
  }
};

export const getItemElementFriendlyWarnings: IGetItemElementFriendlyWarnings = (
  params,
): ItemElementFriendlyWarningResult => {
  const friendlyWarningsChecker = itemElementFriendlyWarningCheckersMap(params.itemElement.type);
  const result = friendlyWarningsChecker(params);

  return result;
};
