import { makeCancellablePromise, swallowCancelledPromiseError } from '@kontent-ai/utils';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { emptyEntityWebhook } from '../../../data/models/webhooks/EntityWebhookSetting.ts';
import { emptyObject } from '../../../data/models/webhooks/WebhookSetting.ts';
import { loadWebhookMessageListing } from '../actions/thunkWebhooksActions.ts';
import { webhookMessageListingFiltered } from '../actions/webhooksActions.ts';
import { WebhookMessageListing as WebhookMessageListingComponent } from '../components/WebhookMessageListing.tsx';
import { WebhookMessageFilterOptionId } from '../constants/webhookMessageFilterOptions.ts';

type WebhooksMessageListingProps = {
  readonly webhookId: Uuid;
};

export const WebhookMessageListing: React.FC<WebhooksMessageListingProps> = ({ webhookId }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const webhookMessages = useSelector((s) => s.webhooksApp.webhookMessages.get(webhookId) ?? []);
  const webhookMessagesListingFilter = useSelector(
    (s) => s.webhooksApp.webhookMessagesListingFilter,
  );
  const webhookMessagesLoadingStatus = useSelector(
    (s) => s.webhooksApp.webhookMessagesLoadingStatus,
  );
  const webhookSetting = useSelector((s) => s.data.webhooks.byId.get(webhookId) || emptyObject);
  const entityWebhookSetting = useSelector(
    (s) => s.data.entityWebhooks.byId.get(webhookId) || emptyEntityWebhook,
  );

  useEffect(() => {
    const initPromise = makeCancellablePromise(() =>
      dispatch(loadWebhookMessageListing({ webhookId, history })),
    ).catch(swallowCancelledPromiseError);

    return () => initPromise.cancel();
  }, [history, webhookId]);

  return (
    <WebhookMessageListingComponent
      entityWebhookSetting={entityWebhookSetting}
      onFilter={(filter) =>
        dispatch(
          webhookMessageListingFiltered(
            WebhookMessageFilterOptionId[filter] || WebhookMessageFilterOptionId.ShowAllFailed,
          ),
        )
      }
      onRefresh={() => dispatch(loadWebhookMessageListing({ webhookId, history }))}
      webhookMessages={webhookMessages}
      webhookMessagesListingFilter={webhookMessagesListingFilter}
      webhookMessagesLoadingStatus={webhookMessagesLoadingStatus}
      webhookSetting={webhookSetting}
    />
  );
};

WebhookMessageListing.displayName = 'WebhookMessageListing';
