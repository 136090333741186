import { Collection } from '@kontent-ai/utils';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { ModalDialogType } from '../../../../../_shared/constants/modalDialogType.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { areCollectionsVisibleForAssets } from '../../../../../_shared/selectors/contentCollections.ts';
import { ImageLimitsConfig } from '../../../../../_shared/utils/assets/assetValidationUtils.ts';
import { isImageMimeType } from '../../../../../_shared/utils/fileTypeDetection.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { currentUserHasCapabilities } from '../../../../../_shared/utils/permissions/capabilityUtils.ts';
import { AssetLibraryQueryStorage } from '../../../../../localStorages/assetLibraryQueryStorage.ts';
import {
  ListingContext,
  listingOrderByStorage,
  parseLocalStorageDataToOrderBy,
} from '../../../../../localStorages/listingOrderByStorage.ts';
import {
  createAssetFolder,
  ensureLoadedAssetFolder,
} from '../../actions/thunkAssetLibraryActions.ts';
import { AssetLibrary as AssetLibraryComponent } from '../../components/AssetListing/AssetLibrary.tsx';
import { RootFolderId } from '../../constants/assetFolderConstants.ts';
import { useUploadAndPreselectAssets } from '../../hooks/useUploadAndPreselectAssets.ts';
import { IAssetLibraryQuery } from '../../models/IAssetLibraryQuery.ts';
import { isAssetLibraryLoading } from '../../selectors/isAssetLibraryLoading.ts';
import { AssetsOrderBy } from '../../types/orderBy.type.ts';
import { AssetOrderingCode, defaultOrdering } from '../../utils/assetListingOrderingUtils.ts';
import { redirectToAssetFolder } from '../../utils/assetsPathUtils.ts';

type Props = {
  readonly allowSelect: boolean;
  readonly clearAssetSelection: () => void;
  readonly deselectAssets: (assetIds: ReadonlySet<Uuid>) => void;
  readonly imageLimits?: ImageLimitsConfig;
  readonly isWithAnchor?: boolean;
  readonly onAssetClick?: (assetId: Uuid) => void;
  readonly onAssetDoubleClick?: (assetId: Uuid) => void;
  readonly onInit?: (orderBy: AssetsOrderBy) => void;
  readonly selectAssets: (assets: ReadonlyArray<Uuid>) => void;
  readonly selectedAssets: ReadonlySet<Uuid>;
  readonly shouldAutoSelectUploadedAssets?: boolean;
  readonly shouldPersistOptions?: boolean;
  readonly showImagesOnly?: boolean;
  readonly toggleAssetSelection: (assetId: Uuid, shiftPressed: boolean) => void;
};

export const AssetLibrary: React.FC<Props> = ({
  allowSelect,
  clearAssetSelection,
  deselectAssets,
  imageLimits,
  isWithAnchor,
  onAssetClick,
  onAssetDoubleClick,
  onInit,
  selectAssets,
  selectedAssets,
  shouldAutoSelectUploadedAssets,
  shouldPersistOptions,
  showImagesOnly,
  toggleAssetSelection,
}) => {
  const { orderBy, onOrderingChange } = useAssetsOrderState(!!shouldPersistOptions);

  useEnsureAssetFolder(orderBy);

  const orderByRef = useRef(orderBy);
  useEffect(() => {
    onInit?.(orderByRef.current);
  }, [onInit]);

  const onQueryChange = usePersistQueryOnChange(!!shouldPersistOptions);

  const openedFolderId = useSelector((s) => s.assetLibraryApp.openedFolderId);
  const uploadAndPreselectAssets = useUploadAndPreselectAssets({
    assetTypeSelectionFilter: showImagesOnly ? isImageMimeType : undefined,
    selectAssets: shouldAutoSelectUploadedAssets ? selectAssets : undefined,
    targetFolderId: openedFolderId,
  });

  const areCollectionsVisible = useSelector((state) =>
    areCollectionsVisibleForAssets(state, Collection.getValues(state.data.collections.byId)),
  );
  const canCreateAssets = useSelector((state) =>
    currentUserHasCapabilities(state, Capability.CreateAssets),
  );

  const dispatch = useDispatch();
  const createFolder = useSelector((state) =>
    currentUserHasCapabilities(state, Capability.UpdateAssets)
      ? (name: string) => dispatch(createAssetFolder(name))
      : null,
  );

  const showUnsupportedAssetFileTypeModal = useSelector(
    (state) => state.sharedApp.modalDialog?.type === ModalDialogType.UnsupportedAssetFileType,
  );

  const isLoading = useSelector(isAssetLibraryLoading);

  return (
    <AssetLibraryComponent
      allowSelect={allowSelect}
      areCollectionsVisible={areCollectionsVisible}
      canCreateAssets={canCreateAssets}
      clearAssetSelection={clearAssetSelection}
      createFolder={createFolder}
      deselectAssets={deselectAssets}
      imageLimits={imageLimits}
      isAssetLibraryLoading={isLoading}
      isWithAnchor={isWithAnchor}
      onAssetClick={onAssetClick}
      onAssetDoubleClick={onAssetDoubleClick}
      onOrderingChange={onOrderingChange}
      onQueryChange={onQueryChange}
      onUpload={uploadAndPreselectAssets}
      orderBy={orderBy}
      selectedAssets={selectedAssets}
      showImagesOnly={showImagesOnly}
      showUnsupportedAssetFileTypeModal={showUnsupportedAssetFileTypeModal}
      toggleAssetSelection={toggleAssetSelection}
    />
  );
};

AssetLibrary.displayName = 'AssetLibrary';

const useAssetsOrderState = (usePersistentStorage: boolean) => {
  const currentProjectId = useSelector((s) => s.sharedApp.currentProjectId);

  const [orderBy, setOrderBy] = useState<AssetsOrderBy>(() =>
    getInitialValue(currentProjectId, usePersistentStorage),
  );

  useEffect(() => {
    setOrderBy(getInitialValue(currentProjectId, usePersistentStorage));
  }, [currentProjectId, usePersistentStorage]);

  const onOrderingChange = useCallback(
    (ordering: AssetsOrderBy) => {
      setOrderBy(ordering);

      if (usePersistentStorage) {
        listingOrderByStorage.save<AssetsOrderBy>(
          ordering,
          ListingContext.AssetListing,
          currentProjectId,
        );
      }
    },
    [currentProjectId, usePersistentStorage],
  );

  return {
    orderBy,
    onOrderingChange,
  };
};

const getInitialValue = (currentProjectId: Uuid, usePersistentStorage: boolean) => {
  if (!usePersistentStorage) {
    return defaultOrdering;
  }

  const orderByFromStorage = listingOrderByStorage.load<AssetsOrderBy>(
    ListingContext.AssetListing,
    currentProjectId,
    parseLocalStorageDataToOrderBy(AssetOrderingCode),
  );

  return orderByFromStorage ?? defaultOrdering;
};

const useEnsureAssetFolder = (orderBy: AssetsOrderBy) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch<{ assetFolderId?: Uuid; projectId?: Uuid }>();

  const { assetFolderId, projectId } = match.params;

  useEffect(() => {
    const ensure = async () => {
      if (assetFolderId && projectId) {
        const { folderExists } = await dispatch(
          ensureLoadedAssetFolder({
            assetFolderId,
            orderBy,
          }),
        );

        if (!folderExists) {
          redirectToAssetFolder({
            assetFolderId: RootFolderId,
            history,
            projectId,
          });
        }
      }
    };
    ensure();
  }, [assetFolderId, history, orderBy, projectId]);
};

const usePersistQueryOnChange = (shouldPersist: boolean) => {
  const currentProjectId = useSelector((s) => s.sharedApp.currentProjectId);

  return useCallback(
    (query: IAssetLibraryQuery) => {
      if (shouldPersist) {
        AssetLibraryQueryStorage.save(query, currentProjectId);
      }
    },
    [currentProjectId, shouldPersist],
  );
};
