import { createGuid } from '@kontent-ai/utils';
import { History } from 'history';
import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { modalDismissed } from '../../../../../../_shared/actions/sharedActions.ts';
import { trackUserEventWithData } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import {
  ContentItemEditingEventOrigins,
  ContentItemEditingEventTypes,
} from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { newContentItemVariantInitPrepared } from '../../../ContentItemEditing/actions/contentItemEditingActions.ts';
import { initContentItemEditing } from '../../../ContentItemEditing/actions/thunkContentItemEditingActions.ts';

export const createSubmitNewVariantWorkflowSelectionAction =
  () =>
  (history: History): ThunkFunction =>
  (dispatch, getState): void => {
    const { selectedWorkflowId } = getState().contentApp.newVariant;

    dispatch(
      newContentItemVariantInitPrepared({
        workflowId: selectedWorkflowId,
      }),
    );
    dispatch(initContentItemEditing(history, null, createGuid()));
    dispatch(modalDismissed());
    dispatch(
      trackUserEventWithData(TrackedEvent.ContentItemEditing, {
        action: ContentItemEditingEventTypes.NewVariantWorkflowSelected,
        origin: ContentItemEditingEventOrigins.NewVariantModal,
      }),
    );
  };
