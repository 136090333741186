import {
  WebSpotlight_ItemPreviewInfoReload_Requested,
  WebSpotlight_LocalizedRoute_Entered,
  WebSpotlight_LocalizedRoute_Left,
  WebSpotlight_Node_Blurred,
  WebSpotlight_Node_Collapsed,
  WebSpotlight_Node_Focused,
  WebSpotlight_PreviewAutoRefresh_Changed,
  WebSpotlight_Preview_Refreshed,
  WebSpotlight_SetSharedPreviewData,
} from '../constants/webSpotlightActionTypes.ts';
import { NodeId } from '../types/nodeId.type.ts';

export const webSpotlightNodeCollapsed = (nodeId: NodeId) =>
  ({
    type: WebSpotlight_Node_Collapsed,
    payload: { nodeId },
  }) as const;

export const webSpotlightNodeFocused = (nodeId: NodeId) =>
  ({
    type: WebSpotlight_Node_Focused,
    payload: { nodeId },
  }) as const;

export const webSpotlightNodeBlurred = (nodeId: NodeId) =>
  ({
    type: WebSpotlight_Node_Blurred,
    payload: { nodeId },
  }) as const;

export const webSpotlightPreviewRefreshed = (manualRefresh: boolean) =>
  ({
    type: WebSpotlight_Preview_Refreshed,
    payload: {
      manualRefresh,
    },
  }) as const;

export const localizedRouteEntered = (selectedLanguageId: Uuid) =>
  ({
    type: WebSpotlight_LocalizedRoute_Entered,
    payload: {
      selectedLanguageId,
    },
  }) as const;

export const localizedRouteLeft = () =>
  ({
    type: WebSpotlight_LocalizedRoute_Left,
  }) as const;

export const itemPreviewInfoReloadRequested = () =>
  ({
    type: WebSpotlight_ItemPreviewInfoReload_Requested,
  }) as const;

export const webSpotlightPreviewAutoRefreshChanged = (isPreviewAutoRefreshEnabled: boolean) =>
  ({
    type: WebSpotlight_PreviewAutoRefresh_Changed,
    payload: {
      isPreviewAutoRefreshEnabled,
    },
  }) as const;

export const setWebSpotlightSharedPreviewData = (
  url: string,
  width: number | null,
  height: number | null,
) =>
  ({
    type: WebSpotlight_SetSharedPreviewData,
    payload: {
      url,
      width,
      height,
    },
  }) as const;
