export const innovationLabFeatureCodenames = [
  'ai-asset-tagging',
  'ai-element-translation',
  'ai-image-description',
  'ai-item-categorization',
  'ai-linked-items',
  'ai-richtext-beta',
  'asset-collection-mandatory',
  'asset-replace',
  'content-status',
  'secure-assets',
] as const;

export type InnovationLabFeatureCodename = (typeof innovationLabFeatureCodenames)[number];

export type InnovationLabFeatureCodenameWithSemanticSearch =
  | InnovationLabFeatureCodename
  | 'ai-semantic-search';
