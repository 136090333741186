import classNames from 'classnames';
import React, { useEffect } from 'react';
import { HtmlPageTitle } from '../../../../../_shared/components/HtmlPageTitle.tsx';
import { AppNames } from '../../../../../_shared/constants/applicationNames.ts';
import { NotificationBar } from '../../../../../_shared/containers/NotificationBar.tsx';
import { DateRange } from '../../../../../_shared/models/DateRange.type.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { ContentItemFilterOrigin } from '../../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ContentItemFilter } from '../../../../contentInventory/content/features/ListingFilter/containers/ContentItemFilter.tsx';
import { EnsureInventoryRelatedData } from '../../../../contentInventory/content/features/ListingFilter/containers/EnsureInventoryRelatedData.tsx';
import { CalendarWrapper } from '../../containers/Calendar/CalendarWrapper.tsx';
import { CalendarArrangement } from '../../models/CalendarArrangement.ts';
import { ICalendarEvent } from '../../models/CalendarModels.type.ts';
import { CalendarView } from '../../models/CalendarView.ts';
import { ItemsCountLimitWarning } from './ItemsCountLimitWarning.tsx';

export interface ICalendarStateProps {
  readonly calendarView: CalendarView;
  readonly events: readonly ICalendarEvent[];
  readonly isCurrentMonthLoaded: boolean;
  readonly isFilterInitialized: boolean;
  readonly listingItemsLoadingStatus: LoadingStatus;
  readonly range: DateRange;
  readonly selectedArrangement: CalendarArrangement;
  readonly wasItemsLimitExceeded: boolean;
}

export interface ICalendarDispatchProps {
  readonly onClearFilter: () => void;
  readonly onDrillDownDateSelected: (date: DateTimeStamp) => void;
  readonly onFilterChange: () => void;
  readonly onItemDetailSelected: (id: Uuid, date: DateTimeStamp) => void;
  readonly onLeave: () => void;
  readonly onPeriodChanged: (period: DateRange) => void;
}

type ICalendarProps = ICalendarStateProps & ICalendarDispatchProps;

export const Calendar: React.FC<ICalendarProps> = (props) => {
  const { onLeave } = props;

  useEffect(() => {
    return () => {
      onLeave();
    };
  }, [onLeave]);

  return (
    <div className="canvas" {...getDataUiAppNameAttribute(DataUiAppName.Calendar)}>
      <div className="canvas__workspace">
        <EnsureInventoryRelatedData>
          <HtmlPageTitle appName={AppNames.Calendar} />
          <ContentItemFilter
            clearFilter={props.onClearFilter}
            listingItemsLoadingStatus={props.listingItemsLoadingStatus}
            onFilterChange={props.onFilterChange}
            origin={ContentItemFilterOrigin.Calendar}
          />
          <div className="canvas__content">
            <div className="canvas__notifications">
              <NotificationBar />
            </div>
            <div
              className={classNames('canvas__content-pane', 'calendar', {
                'calendar--month-view': props.selectedArrangement === CalendarArrangement.Month,
                'calendar--agenda-view': props.selectedArrangement === CalendarArrangement.Agenda,
                [`calendar-view--${props.calendarView}`]: props.isCurrentMonthLoaded,
              })}
            >
              {props.wasItemsLimitExceeded && <ItemsCountLimitWarning />}
              {props.isFilterInitialized && (
                <CalendarWrapper
                  events={props.events}
                  range={props.range}
                  onDrillDownDateSelected={props.onDrillDownDateSelected}
                  onPeriodChanged={props.onPeriodChanged}
                  onItemDetailSelected={props.onItemDetailSelected}
                  selectedArrangement={props.selectedArrangement}
                />
              )}
            </div>
          </div>
        </EnsureInventoryRelatedData>
      </div>
    </div>
  );
};

Calendar.displayName = 'Calendar';
