import React, { useCallback } from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { initWorkflowEditing, saveWorkflowToServer } from '../actions/thunkWorkflowActions.ts';
import { WorkflowEditorBase } from './WorkflowEditorBase.tsx';

type WorkflowEditorContainerProps = {
  readonly workflowId: Uuid;
};

export const WorkflowEditorContainer: React.FC<WorkflowEditorContainerProps> = ({ workflowId }) => {
  const dispatch = useDispatch();

  const onInit = useCallback(() => dispatch(initWorkflowEditing(workflowId)), [workflowId]);
  const onSaveWorkflow = (onSuccess?: () => void, onFail?: () => void) =>
    dispatch(saveWorkflowToServer({ onSuccess, onFail }));

  return <WorkflowEditorBase onInit={onInit} onSaveWorkflow={onSaveWorkflow} />;
};

WorkflowEditorContainer.displayName = 'WorkflowEditorContainer';
export { WorkflowEditorContainer as WorkflowEditor };
