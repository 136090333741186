import React, { useCallback, useMemo } from 'react';
import { useEditorWithPlugin } from '../../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginCreator } from '../../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../../editorCore/types/Editor.contract.type.ts';
import { Apply, EditorPlugin } from '../../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../../editorCore/utils/decorable.ts';
import { withDisplayName } from '../../../editorCore/utils/withDisplayName.ts';
import { EntityDecoratorProps } from '../../entityApi/api/editorEntityUtils.ts';
import { DisplayLinksPlugin, GetLinkEntityComponent } from '../DisplayLinksPlugin.tsx';
import { isWebLink } from '../api/LinkEntity.ts';
import { LinkType } from '../api/LinkType.ts';
import { convertOpenInNewWindow } from '../api/editorLinkUtils.ts';
import { WebLink } from './components/WebLink.tsx';

export type DisplayWebLinksPlugin = EditorPlugin<None, None, None, [DisplayLinksPlugin]>;

type WebLinkEntityProps = EntityDecoratorProps & {
  readonly children: ReadonlyArray<React.ReactNode>;
};

const WebLinkEntity: React.FC<WebLinkEntityProps> = ({
  contentState,
  decoratedText,
  entityKey,
  children,
}) => {
  const entity = contentState.getEntity(entityKey);
  if (!isWebLink(entity)) {
    return children;
  }

  const { url, openInNewWindow, title } = entity.getData();

  return (
    <WebLink
      disabled
      key={entityKey}
      openInNewWindow={convertOpenInNewWindow(openInNewWindow)}
      text={decoratedText}
      title={title}
      url={url}
    >
      {children}
    </WebLink>
  );
};

WebLinkEntity.displayName = 'WebLinkEntity';

export const useDisplayWebLinks: PluginCreator<DisplayWebLinksPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('DisplayWebLinksPlugin', {
        ComposedEditor: (props) => {
          const apply: Apply<DisplayWebLinksPlugin> = useCallback((state) => {
            const getLinkEntityComponent: Decorator<GetLinkEntityComponent<None>> =
              (baseGetLinkEntityComponent) => (linkType) => {
                switch (linkType) {
                  case LinkType.Web:
                    return {
                      component: WebLinkEntity,
                      props: {},
                    };

                  default:
                    return baseGetLinkEntityComponent(linkType);
                }
              };

            state.getLinkEntityComponent.decorate(getLinkEntityComponent);

            return {};
          }, []);

          return useEditorWithPlugin(baseEditor, props, { apply });
        },
      }),
    [baseEditor],
  );
