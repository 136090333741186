import { Box } from '@kontent-ai/component-library/Box';
import { VerticalTabs } from '@kontent-ai/component-library/VerticalTabs';
import React from 'react';
import { LandmarkAriaLabels } from '../../../../_shared/constants/landmarkAriaLabels.ts';
import {
  DefaultWorkflowRoute,
  SubscriptionEnvironmentDefaultWorkflowRoute,
  SubscriptionEnvironmentUsersRoute,
  UsersRoute,
  WebhooksRoute,
} from '../../../../_shared/constants/routePaths.ts';
import { matchPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { convertAppNameToId } from '../../utils/convertAppNameToId.ts';
import { EnvironmentSettingsAppNames } from '../constants/EnvironmentSettingsAppNames.ts';

export interface IEnvironmentSettingsTabItemInfo {
  readonly id: string;
  readonly isDisabled?: boolean;
  readonly label: string;
  readonly renderAuxElements?: () => React.ReactNode;
  readonly url: string;
}

export interface IFirstLevelEnvironmentSettingsTabItemInfo extends IEnvironmentSettingsTabItemInfo {
  readonly items?: ReadonlyArray<IEnvironmentSettingsTabItemInfo>;
  readonly renderCustomItem?: (defaultItem: React.ReactNode) => React.ReactNode;
}

export interface IEnvironmentSettingsMenuStateProps {
  readonly pathname: string;
  readonly items: ReadonlyArray<IFirstLevelEnvironmentSettingsTabItemInfo>;
}

const getActiveItemId = (pathname: string) => {
  if (matchPath(pathname, WebhooksRoute)) {
    return convertAppNameToId(EnvironmentSettingsAppNames.Webhooks);
  }

  if (
    matchPath(pathname, DefaultWorkflowRoute) ||
    matchPath(pathname, SubscriptionEnvironmentDefaultWorkflowRoute)
  ) {
    return convertAppNameToId(EnvironmentSettingsAppNames.Workflow);
  }

  if (matchPath(pathname, UsersRoute) || matchPath(pathname, SubscriptionEnvironmentUsersRoute)) {
    return convertAppNameToId(EnvironmentSettingsAppNames.Users);
  }

  return pathname.split('/').pop();
};

export const EnvironmentSettingsMenu: React.FC<IEnvironmentSettingsMenuStateProps> = ({
  pathname,
  items,
}) => {
  const activeItemId = getActiveItemId(pathname) || (items[0]?.id ?? 'no-id');

  return (
    <Box
      overflowX="hidden"
      overflowY="auto"
      flexGrow={0}
      flexShrink={0}
      flexBasis="auto"
      display="flex"
    >
      <VerticalTabs ariaLabel={LandmarkAriaLabels.EnvironmentSettings}>
        {items.map(({ renderCustomItem, items: nestedItems, ...item }) => {
          const itemElement = (
            <VerticalTabs.Item
              key={item.id}
              isActive={activeItemId === item.id}
              {...item}
              url={nestedItems?.[0]?.url ?? item.url}
            >
              {nestedItems?.map((nestedItem) => (
                <VerticalTabs.Item
                  key={nestedItem.id}
                  isActive={activeItemId === item.id}
                  {...nestedItem}
                />
              ))}
            </VerticalTabs.Item>
          );

          return renderCustomItem?.(itemElement) ?? itemElement;
        })}
      </VerticalTabs>
    </Box>
  );
};

EnvironmentSettingsMenu.displayName = 'EnvironmentSettingsMenu';
