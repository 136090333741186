import React from 'react';
import { ConfirmationDialog } from '../../../../../_shared/components/ModalDialog/ConfirmationDialog.tsx';

type Props = {
  readonly isOpen: boolean;
  readonly onCancel: () => void;
  readonly onConfirm: () => void;
};

export const CancelBulkSchedulePublishConfirmationDialog: React.FC<Props> = ({
  isOpen,
  onCancel,
  onConfirm,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <ConfirmationDialog
      confirmButtonText="Remove schedule"
      headerContent="Remove the publishing schedule"
      onConfirm={onConfirm}
      onClose={onCancel}
    >
      <p className="confirmation-dialog__section">
        The scheduled date will be removed and{' '}
        <strong>the selected items will not get published.</strong>
      </p>
      <p className="confirmation-dialog__section">
        If the items have published versions, those will remain intact.
      </p>
      <p className="confirmation-dialog__section">
        Are you sure you want to remove the publishing schedule for the selected items?
      </p>
    </ConfirmationDialog>
  );
};

CancelBulkSchedulePublishConfirmationDialog.displayName =
  'CancelBulkSchedulePublishConfirmationDialog';
