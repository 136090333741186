import React from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { preventDefault } from '../../../../../_shared/utils/func/functionalTools.ts';
import { BlockType } from '../../../utils/blocks/blockType.ts';
import { EditorFeatureLimitations } from '../../apiLimitations/api/EditorFeatureLimitations.ts';
import { RichTextInputCommand } from '../../keyboardShortcuts/api/EditorCommand.ts';
import { BlockTypeToolbarButtons } from './BlockTypeToolbarButtons.tsx';

interface IParagraphToolbarProps {
  readonly fullBlockTypesAtSelection: ReadonlySet<BlockType>;
  readonly hidesDisallowedFeatures: boolean;
  readonly limitations: EditorFeatureLimitations;
  readonly onCommand: (command: RichTextInputCommand) => void;
}

export const ParagraphToolbar: React.FC<IParagraphToolbarProps> = ({
  fullBlockTypesAtSelection,
  hidesDisallowedFeatures,
  limitations,
  onCommand,
}) => {
  return (
    <div
      className="toolbar__paragraph"
      // Prevent editor focus lost when toolbar button is clicked.
      onMouseDown={preventDefault}
      onMouseUp={preventDefault}
      {...getDataUiElementAttribute(DataUiElement.RteParagraphToolbar)}
    >
      <BlockTypeToolbarButtons
        className="rte__paragraph-toolbar"
        fullBlockTypesAtSelection={fullBlockTypesAtSelection}
        hidesDisallowedFeatures={hidesDisallowedFeatures}
        limitations={limitations}
        onCommand={onCommand}
      />
    </div>
  );
};

ParagraphToolbar.displayName = 'ParagraphToolbar';
