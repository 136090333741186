import { makeCancellablePromise, swallowCancelledPromiseError } from '@kontent-ai/utils';
import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { logError } from '../../../../_shared/utils/logError.ts';
import { loadAssets } from '../../../../data/actions/thunkDataActions.ts';
import { IAssetRendition } from '../../../../data/models/assetRenditions/AssetRendition.ts';
import { IImage, isImage } from '../../../../data/models/assets/Image.ts';

type Props = {
  readonly assetId: Uuid | null;
  readonly isOpen: boolean;
  readonly renderDialog: (
    isDataLoading: boolean,
    asset: IImage,
    existingRendition: IAssetRendition | null,
  ) => ReactElement;
  readonly renditionId: Uuid | null;
};

export const AssetRenditionDialogDataEnsurer = ({
  assetId,
  isOpen,
  renderDialog,
  renditionId,
}: Props) => {
  const dispatch = useDispatch();

  const asset = useSelector((s) => (assetId ? s.data.assets.byId.get(assetId) : null));
  const existingRendition =
    useSelector((s) => s.data.assetRenditions.byId.get(renditionId ?? '')) ?? null;

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!assetId || !isOpen) {
      return;
    }

    setIsLoading(true);

    const { cancel } = makeCancellablePromise(() =>
      dispatch(
        loadAssets([
          {
            id: assetId,
            renditions: renditionId ? [{ id: renditionId }] : [],
          },
        ]),
      ),
    )
      .then(() => setIsLoading(false))
      .catch(swallowCancelledPromiseError);

    return cancel;
  }, [assetId, renditionId, isOpen]);

  if (!assetId || !asset) {
    return null;
  }

  if (!isImage(asset)) {
    logError(
      'AssetRenditionDialog: asset does not have image data (width, height, preview and download link).',
    );
    return null;
  }

  return renderDialog(isLoading, asset, existingRendition);
};
