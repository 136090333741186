import PropTypes from 'prop-types';
import React from 'react';
import { AccessDeniedToContentItemAction } from '../../../../errorHandling/constants/AccessDeniedToContentItemAction.ts';
import { AccessDeniedToContentItem } from '../../../../errorHandling/containers/AccessDeniedToContentItem.tsx';
import { CloseAction } from './Header/CloseAction.tsx';
import { FloatingEditorHeaderActions } from './Header/FloatingEditorHeaderActions.tsx';

interface IFloatingEditorAccessDeniedProps {
  readonly requestedAction: AccessDeniedToContentItemAction;
}

const propTypes: PropTypesShape<IFloatingEditorAccessDeniedProps> = {
  requestedAction: PropTypes.oneOf(Object.values(AccessDeniedToContentItemAction)).isRequired,
};

export const FloatingEditorAccessDenied: React.FC<IFloatingEditorAccessDeniedProps> = ({
  requestedAction,
}) => (
  <div className="floating-editor__pane">
    <div className="floating-editor__header">
      <FloatingEditorHeaderActions>
        <CloseAction />
      </FloatingEditorHeaderActions>
    </div>
    <div className="floating-editor__content">
      <AccessDeniedToContentItem requestedAction={requestedAction} />
    </div>
  </div>
);

FloatingEditorAccessDenied.displayName = 'FloatingEditorAccessDenied';
FloatingEditorAccessDenied.propTypes = propTypes;
