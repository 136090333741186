import { Button } from '@kontent-ai/component-library/Button';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Stack } from '@kontent-ai/component-library/Stack';
import { TabItem, TabView } from '@kontent-ai/component-library/TabView';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { useEffect, useId } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import { HtmlPageTitle } from '../../../_shared/components/HtmlPageTitle.tsx';
import { PageTitle } from '../../../_shared/components/PageTitle.tsx';
import { useRedirectPropsWithSameSearch } from '../../../_shared/components/routing/useRedirectPropsWithSameSearch.tsx';
import { AppNames } from '../../../_shared/constants/applicationNames.ts';
import {
  ContentAssignedToYouRoute,
  ContentRecentlyEditedByYouRoute,
  YourContentRoute,
  YourTasksRoute,
} from '../../../_shared/constants/routePaths.ts';
import {
  DataUiAction,
  DataUiAppName,
  DataUiCollection,
  getDataUiActionAttribute,
  getDataUiAppNameAttribute,
  getDataUiCollectionAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ContentAssignedToYou } from '../containers/AssignedToYou/ContentAssignedToYou.tsx';
import { ContentRecentlyEditedByYou } from '../containers/RecentlyEditedBy/ContentRecentlyEditedByYou.tsx';
import { YourContentNonEmptyRedirect } from '../containers/YourContentNonEmptyRedirect.tsx';
import { YourTasks } from '../containers/YourTasks/YourTasks.tsx';

export type YourContentTabItem = TabItem &
  Readonly<{
    id: string;
  }>;

type YourContentProps = {
  readonly canCreate: boolean;
  readonly onCreateNew: () => void;
  readonly onInit: () => void;
  readonly onLeave: () => void;
  readonly tabs: ReadonlyArray<YourContentTabItem>;
};

const RedirectToDefaultRoute = () => {
  const getRedirectPropsWithSameSearch = useRedirectPropsWithSameSearch();

  return (
    <Redirect
      {...getRedirectPropsWithSameSearch({
        to: ContentRecentlyEditedByYouRoute,
      })}
    />
  );
};

export const YourContent: React.FC<YourContentProps> = ({
  canCreate,
  onCreateNew,
  onInit,
  onLeave,
  tabs,
}) => {
  const { pathname } = useLocation();
  const titleId = useId();
  useEffect(() => {
    onInit();

    return () => onLeave();
  }, [onInit, onLeave]);

  return (
    <div className="canvas__workspace">
      <div className="canvas__content">
        <div
          className="canvas__content-pane"
          {...getDataUiAppNameAttribute(DataUiAppName.YourContent)}
        >
          <HtmlPageTitle appName={AppNames.YourContent} />
          <div className="your-content" aria-labelledby={titleId}>
            <Stack spacing={Spacing.XL}>
              <Row spacing={Spacing.L}>
                <Column>
                  <PageTitle id={titleId}>{AppNames.YourContent}</PageTitle>
                </Column>
                <Route path={[ContentRecentlyEditedByYouRoute, ContentAssignedToYouRoute]}>
                  {canCreate && (
                    <Column width="content">
                      <Button
                        buttonStyle="primary"
                        onClick={onCreateNew}
                        size="medium"
                        {...getDataUiActionAttribute(DataUiAction.CreateNew)}
                      >
                        Create new
                      </Button>
                    </Column>
                  )}
                </Route>
              </Row>
              <TabView selectedKey={pathname} items={tabs}>
                <TabView.TabGroup
                  {...{ className: 'my-content__tab-menu' }}
                  {...getDataUiCollectionAttribute(DataUiCollection.TabItems)}
                />
                <TabView.TabPanel>
                  {() => (
                    <Switch>
                      <Route exact path={ContentRecentlyEditedByYouRoute}>
                        <ContentRecentlyEditedByYou />
                      </Route>
                      <Route exact path={ContentAssignedToYouRoute}>
                        <ContentAssignedToYou />
                      </Route>
                      <Route exact path={YourTasksRoute}>
                        <YourTasks />
                      </Route>
                      <Route exact path={YourContentRoute}>
                        <YourContentNonEmptyRedirect />
                      </Route>
                      <RedirectToDefaultRoute />
                    </Switch>
                  )}
                </TabView.TabPanel>
              </TabView>
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );
};
YourContent.displayName = 'YourContent';
