import { useAttachRef } from '@kontent-ai/hooks';
import classNames from 'classnames';
import React from 'react';
import { HandleClickOutside } from '../../../../../../_shared/components/HandleClickOutside.tsx';
import { IconName } from '../../../../../../_shared/constants/iconEnumGenerated.ts';
import { Button, ButtonSize } from '../../../../../../_shared/uiComponents/Button/Button.tsx';
import { SquareButton } from '../../../../../../_shared/uiComponents/Button/SquareButton.tsx';
import { ButtonStyle } from '../../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import { SquareButtonSize } from '../../../../../../_shared/uiComponents/Button/squareButtonSize.ts';
import {
  DataUiCommentsAction,
  DataUiElement,
  getDataUiCommentActionAttribute,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  IForwardedRefProps,
  forwardRef,
} from '../../../../../../_shared/utils/forwardedRefProps.tsx';
import { CommentThreadCssClass } from '../../constants/uiConstants.ts';

type ResolveUndoDialogProps = IForwardedRefProps<HTMLDivElement> & {
  readonly isSuggestionThread: boolean;
  readonly onResolveUndo: () => void;
  readonly onResolveUndoCancel: () => void;
};

const _ResolveUndoDialog: React.FC<ResolveUndoDialogProps> = (props) => {
  const { refObject, refToForward } = useAttachRef(props.forwardedRef);

  return (
    <HandleClickOutside observedRefs={refObject} onClickOutside={props.onResolveUndoCancel}>
      <div
        className={classNames(CommentThreadCssClass, 'comment-thread__undo-dialog')}
        ref={refToForward}
        {...getDataUiElementAttribute(DataUiElement.CommentThread)}
      >
        <span className="comment-thread__undo-dialog-message">
          {props.isSuggestionThread ? 'Suggestion' : 'Comment'} resolved
          <Button
            style={ButtonStyle.Tertiary}
            onClick={(event) => {
              event.stopPropagation();
              props.onResolveUndo();
            }}
            size={ButtonSize.S}
            customButtonClassName="comment-thread__undo-dialog-button"
            {...getDataUiCommentActionAttribute(DataUiCommentsAction.ResolveUndo)}
          >
            Undo
          </Button>
        </span>
        <span className="comment-thread__undo-dialog-close">
          <SquareButton
            iconName={IconName.Times}
            screenReaderText="Close"
            size={SquareButtonSize.Quinary}
            style={ButtonStyle.Quinary}
            onClick={props.onResolveUndoCancel}
            tooltipText="Close"
            tooltipPlacement="top-start"
            {...getDataUiCommentActionAttribute(DataUiCommentsAction.ResolveUndoCancel)}
          />
        </span>
      </div>
    </HandleClickOutside>
  );
};

_ResolveUndoDialog.displayName = 'ResolveUndoDialog';

export const ResolveUndoDialog = forwardRef(_ResolveUndoDialog);
