import PropTypes from 'prop-types';
import React, { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import { Box } from '../../../layout/Box/Box.tsx';
import { Column } from '../../../layout/Row/Column.tsx';
import { Row } from '../../../layout/Row/Row.tsx';
import { spacingCard } from '../../../tokens/decision/spacing.ts';
import { typographyCardHeadline } from '../../../tokens/decision/typography.ts';
import { ConditionalWrapper } from '../../_utils/ConditionalWrapper.tsx';

type CardHeadlineProps = {
  readonly id?: string;
  /**
   * Other elements that will be rendered right after the headline text, e.g. SimpleStatus or Tag.
   * They are vertically centered relative to the headline's first line.
   * */
  readonly renderAuxiliaryElements?: () => ReactNode;
};

const propTypes: PropTypeMap<CardHeadlineProps> = {
  id: PropTypes.string,
  renderAuxiliaryElements: PropTypes.func,
};

const CardTitle = styled.h1`
  min-width: 0;
  word-break: break-word;
  ${typographyCardHeadline};
  /* override margin from normalize.less applied to all h1 */
  margin: 0;
`;

export const CardHeadline = React.forwardRef<HTMLDivElement, PropsWithChildren<CardHeadlineProps>>(
  ({ renderAuxiliaryElements, ...otherProps }, forwardedRef) => (
    <Box
      component="header"
      ref={forwardedRef}
      minWidth={0}
      paddingBottom={spacingCard}
      css="grid-area: headline"
    >
      {/* Make CardTitle full-width if renderAuxiliaryElements is not provided to allow text centering. */}
      <ConditionalWrapper
        condition={!!renderAuxiliaryElements}
        wrapper={(children) => (
          <Row spacingX={spacingCard} noWrap>
            <Column width="fit-content">{children}</Column>
            <Column width="content">
              <Box
                display="inline-flex"
                height={typographyCardHeadline.lineHeight}
                alignItems="center"
                flexWrap="nowrap"
              >
                {renderAuxiliaryElements?.()}
              </Box>
            </Column>
          </Row>
        )}
      >
        <CardTitle {...otherProps} />
      </ConditionalWrapper>
    </Box>
  ),
);

CardHeadline.displayName = 'CardHeadline';
CardHeadline.propTypes = propTypes;
