import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Card } from '@kontent-ai/component-library/Card';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { isAiBetaEnabled } from '../../../../../_shared/selectors/aiSelectors.ts';
import { useFeatureActivationDialog } from '../../hooks/useFeatureActivationDialog.ts';
import { useGetFeatureActivationState } from '../../hooks/useGetFeatureActivationState.ts';
import { activateInnovationLabFeature } from '../../thunks/activateInnovationLabFeature.ts';
import { deactivateInnovationLabFeature } from '../../thunks/deactivateInnovationLabFeature.ts';
import { InnovationLabCardComponent } from '../../types/InnovationLabCardComponent.type.ts';
import { FeatureActivationDialog } from '../FeatureActivationDialog.tsx';
import { FeatureDeactivationDialog } from '../FeatureDeactivationDialog.tsx';
import { InnovationLabFeatureButton } from '../InnovationLabFeatureButton.tsx';
import { InnovationLabFeatureCard } from '../InnovationLabFeatureCard.tsx';
import { InnovationLabFeatureGenericallyUnavailable } from '../InnovationLabFeatureGenericallyUnavailable.tsx';
import { InnovationLabStatus } from '../InnovationLabFeatureStatus.tsx';
import { InnovationLabGenericActivationCallout } from '../InnovationLabGenericActivationCallout.tsx';
import { InnovationLabGenericConsent } from '../InnovationLabGenericConsent.tsx';
import { InnovationLabGenericDeactivationWarning } from '../InnovationLabGenericDeactivationWarning.tsx';

export const AuthorAssistCard: InnovationLabCardComponent = ({ featureInfo }) => {
  const activationState = useGetFeatureActivationState(AuthorAssistCard.isFeatureEnabledSelector);

  const {
    dialogDisplayed,
    isAsyncOperationInProgress,
    onCardActionButtonClick,
    onDialogActionClick,
    onDialogClose,
  } = useFeatureActivationDialog(
    featureInfo,
    activationState,
    activateInnovationLabFeature('ai-richtext-beta'),
    deactivateInnovationLabFeature('ai-richtext-beta'),
  );

  const featureName = AuthorAssistCard.featureName;
  const featureState = featureInfo.status;

  return (
    <>
      <InnovationLabFeatureCard
        cardLabel={featureName}
        component="section"
        featureState={featureState}
      >
        <Card.Headline
          renderAuxiliaryElements={() => <InnovationLabStatus activationState={activationState} />}
        >
          {featureName}
        </Card.Headline>

        <Card.Body>
          <Stack spacing={Spacing.M}>
            <p>
              Tap into the{' '}
              <OutwardLink href="https://kontent.ai/learn/docs/content-items/author-assist">
                capabilities of AI in Kontent.ai
              </OutwardLink>{' '}
              to take the tedium out of content creation and craft exceptional content effortlessly.
            </p>
            <p>
              Utilize <b>AI skills</b> to generate new content based on your instructions, condense
              text, craft captivating headlines, or adjust the voice and tone. All that without
              leaving Kontent.ai for 3rd-party services.
            </p>
            <InnovationLabFeatureGenericallyUnavailable
              activationState={activationState}
              featureState={featureState}
            />
          </Stack>
        </Card.Body>

        <Card.Footer>
          <InnovationLabFeatureButton
            activationState={activationState}
            featureName="Author content with AI"
            featureState={featureState}
            isAsyncOperationInProgress={isAsyncOperationInProgress}
            onClick={onCardActionButtonClick}
          />
        </Card.Footer>
      </InnovationLabFeatureCard>
      <FeatureActivationDialog
        headline={`Activate ${featureName}`}
        isOpen={dialogDisplayed === 'activation'}
        isActivating={isAsyncOperationInProgress}
        onClose={onDialogClose}
        onActivate={onDialogActionClick}
      >
        <Stack spacing={Spacing.M}>
          <InnovationLabGenericActivationCallout featureName="Author content with AI" />
          <InnovationLabGenericConsent />
        </Stack>
      </FeatureActivationDialog>
      <FeatureDeactivationDialog
        headline={`Deactivate ${featureName}`}
        isOpen={dialogDisplayed === 'deactivation'}
        isDeactivating={isAsyncOperationInProgress}
        onClose={onDialogClose}
        onDeactivate={onDialogActionClick}
      >
        <InnovationLabGenericDeactivationWarning featureName={featureName} />
      </FeatureDeactivationDialog>
    </>
  );
};

AuthorAssistCard.displayName = 'AuthorAssistCard';
AuthorAssistCard.isFeatureEnabledSelector = isAiBetaEnabled;
AuthorAssistCard.featureName = 'Author content with AI';
AuthorAssistCard.releaseDate = '2023-10-30T00:00:00.000Z';
