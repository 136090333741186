import React from 'react';
import { useLocation } from 'react-router';
import {
  EnsureValidState,
  IEnsureValidStateOwnProps,
} from '../../../../../_shared/components/EnsureValidState.tsx';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getIsEditedItemInitialized } from '../selectors/isEditedItemInitialized.ts';

export const WaitForEditedItemInitialized: React.FC<
  React.PropsWithChildren<IEnsureValidStateOwnProps>
> = ({ children, renderLoader }) => {
  const location = useLocation();

  const isEditedItemInitialized = useSelector((s) =>
    getIsEditedItemInitialized(s, location.pathname),
  );

  return (
    <EnsureValidState isStateEnsured={isEditedItemInitialized} renderLoader={renderLoader}>
      {children}
    </EnsureValidState>
  );
};

WaitForEditedItemInitialized.displayName = 'WaitForEditedItemInitialized';
