import React, { useEffect } from 'react';
import { ContentItemVariantsCallout } from '../../../../../_shared/components/ContentItemVariantsCallout/ContentItemVariantsCallout.tsx';
import { ContentItemVariantWithLanguageName } from '../../../../../_shared/utils/getContentItemVariantsWithLanguageNames.tsx';

interface IDependentScheduledVariantsFriendlyWarningProps {
  readonly defaultLanguageName: string;
  readonly dependentVariants: ReadonlyArray<ContentItemVariantWithLanguageName>;
  readonly onInit?: () => void;
}

export const DependentScheduledVariantsFriendlyWarning: React.FC<
  IDependentScheduledVariantsFriendlyWarningProps
> = ({ defaultLanguageName, dependentVariants, onInit }) => {
  useEffect(() => {
    onInit?.();
  }, [onInit]);

  return (
    <ContentItemVariantsCallout calloutType="friendlyWarning" variants={dependentVariants}>
      <p>
        Removing the publishing schedule of this item in {defaultLanguageName} may cause its
        scheduled language variants to publish with empty elements:
      </p>
    </ContentItemVariantsCallout>
  );
};

DependentScheduledVariantsFriendlyWarning.displayName = 'DependentScheduledVariantsFriendlyWarning';
