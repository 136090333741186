import { InvariantException } from '@kontent-ai/errors';
import { ModalDialogType } from '../../../../../_shared/constants/modalDialogType.ts';
import {
  DefaultVariantId,
  DefaultWorkflowId,
} from '../../../../../_shared/constants/variantIdValues.ts';
import { ContentItemId } from '../../../../../_shared/models/ContentItemId.type.ts';
import { ActiveCapabilityType } from '../../../../../_shared/models/activeCapability.type.ts';
import { areFallbacksForLinkedContentEnabled } from '../../../../../_shared/selectors/fallbacksForLinkedContent.ts';
import { getListingContentItem } from '../../../../../_shared/selectors/getListingContentItem.ts';
import { getSelectedLanguageId } from '../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import {
  isArchivedWorkflowStep,
  isPublishedWorkflowStep,
  isScheduledToPublishWorkflowStep,
} from '../../../../../_shared/utils/contentItemUtils.ts';
import { findNearestTranslatedFallbackLanguageId } from '../../../../../_shared/utils/languageUtils.ts';
import { hasActiveVariantCapability } from '../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { getCurrentUserRoleForCollectionForLanguage } from '../../../../../_shared/utils/permissions/getContributorRole.ts';
import { canRoleDoSomethingInStep } from '../../../../../_shared/utils/permissions/roleInWorkflowStepUtils.ts';
import { IListingContentItem } from '../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { getActiveLanguagesWithFallbackChain } from '../../../../../data/reducers/languages/selectors/getLanguages.ts';
import { getWorkflow } from '../../../../../data/reducers/workflow/selectors/workflowSelectors.ts';
import { isNonLocalizableElement } from '../../../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { isElementValidIfEmpty } from '../../../utils/getItemElementValidationResult.ts';
import { isDefaultVariantPublishedOrForbidden } from '../../ContentItemEditing/utils/itemValidationUtils.ts';
import { CannotPublishReason } from '../constants/cannotPublishReason.ts';

export type GetCannotPublishReason = (
  state: IStore,
  contentItemId: ContentItemId,
) => CannotPublishReason;

const doesItemWfStepLeadToPublish = (item: IListingContentItem, state: IStore): boolean => {
  const currentWorkflowId = item.variant?.assignment.workflowStatus.workflowId ?? DefaultWorkflowId;
  const currentWorkflow = getWorkflow(state, currentWorkflowId);
  const publishedStep = currentWorkflow?.publishedStep;
  const currentStep = item.variant?.assignment.workflowStatus;
  const transitions = currentStep?.transitionsTo;

  return (
    !!currentStep &&
    !!transitions &&
    (transitions.has(publishedStep?.id || '') || isScheduledToPublishWorkflowStep(currentStep))
  );
};

const hasPermissionToPublish = (item: IListingContentItem, state: IStore): boolean => {
  if (!item.variant) {
    return false;
  }

  const userRole = getCurrentUserRoleForCollectionForLanguage(
    state,
    item.item.collectionId,
    item.variant.id.variantId,
  );
  const currentWorkflow = getWorkflow(state, item.variant.assignment.workflowStatus.workflowId);

  return (
    !!currentWorkflow &&
    hasActiveVariantCapability(ActiveCapabilityType.ViewContent, item) &&
    canRoleDoSomethingInStep(
      userRole?.id,
      item.variant.assignment.workflowStatus.id,
      currentWorkflow,
    )
  );
};

const isItemComplete = (item: IListingContentItem): boolean =>
  !!item.variant && item.variant.isComplete;

const isItemPublished = (item: IListingContentItem): boolean =>
  !!item.variant && isPublishedWorkflowStep(item.variant.assignment.workflowStatus);

const isItemScheduled = (item: IListingContentItem): boolean =>
  !!item.variant && isScheduledToPublishWorkflowStep(item.variant.assignment.workflowStatus);

const isItemInArchivedStep = (item: IListingContentItem): boolean =>
  !!item.variant && isArchivedWorkflowStep(item.variant.assignment.workflowStatus);

const isScheduledToPublishEarlier = (
  item: IListingContentItem,
  newScheduleToPublish: DateTimeStamp,
): boolean =>
  !!item.variant &&
  isScheduledToPublishWorkflowStep(item.variant.assignment.workflowStatus) &&
  !!item.variant.assignment.scheduledToPublishAt &&
  item.variant.assignment.scheduledToPublishAt < newScheduleToPublish;

const isDefaultVariant = (item: IListingContentItem): boolean =>
  !!item.variant && item.variant.id.variantId === DefaultVariantId;

const canBePublishedWithNonLocalizableElements = (
  item: IListingContentItem,
  state: IStore,
): boolean => {
  if (isDefaultVariant(item)) {
    return true;
  }

  const contentType = state.contentApp.loadedContentItemTypes.get(item.item.typeId);
  if (!contentType) {
    return false;
  }

  const nonLocalizableElements = contentType.contentElements.filter(isNonLocalizableElement);
  if (!nonLocalizableElements.length) {
    return true;
  }

  if (isDefaultVariantPublishedOrForbidden(state, item.item.id)) {
    return true;
  }

  if (nonLocalizableElements.every((element) => isElementValidIfEmpty(element))) {
    return true;
  }

  const cannotPublishDefaultVariantReason = getCannotPublishReason(state, {
    itemId: item.item.id,
    variantId: DefaultVariantId,
  });

  return cannotPublishDefaultVariantReason === CannotPublishReason.None;
};

const fallbacksToAnotherLanguage = (item: IListingContentItem, state: IStore): boolean => {
  if (!areFallbacksForLinkedContentEnabled(state)) {
    return false;
  }
  const translatedLanguageIds = item.translatedLanguageIds;
  const selectedLanguageId = getSelectedLanguageId(state);
  const activeLanguages = getActiveLanguagesWithFallbackChain(state.data.languages);
  const fallbackId =
    selectedLanguageId &&
    findNearestTranslatedFallbackLanguageId(
      translatedLanguageIds,
      activeLanguages,
      selectedLanguageId,
    );

  return !!fallbackId;
};

const getCannotPublishReason: GetCannotPublishReason = (
  state: IStore,
  contentItemId: ContentItemId,
): CannotPublishReason => {
  const item = getListingContentItem(state, contentItemId);

  if (!item || item.item.archived) {
    return CannotPublishReason.ItemNotFound;
  }

  if (!item.variant || item.variant.isArchived) {
    if (fallbacksToAnotherLanguage(item, state)) {
      return CannotPublishReason.ItemIsNotTranslatedButHasFallback;
    }

    return CannotPublishReason.ItemIsNotTranslated;
  }

  if (isItemInArchivedStep(item)) {
    return CannotPublishReason.WorkflowStep;
  }

  if (isItemPublished(item)) {
    return CannotPublishReason.ItemIsPublished;
  }

  if (isItemScheduled(item)) {
    return CannotPublishReason.ItemIsScheduled;
  }

  if (!hasPermissionToPublish(item, state)) {
    return CannotPublishReason.MissingPermission;
  }

  if (!doesItemWfStepLeadToPublish(item, state)) {
    return CannotPublishReason.WorkflowStep;
  }

  if (!isItemComplete(item)) {
    return CannotPublishReason.ItemIncomplete;
  }

  if (!canBePublishedWithNonLocalizableElements(item, state)) {
    return CannotPublishReason.MustPublishNonLocalizableElements;
  }

  return CannotPublishReason.None;
};

const getCannotScheduleToPublishReason: GetCannotPublishReason = (
  state: IStore,
  contentItemId: ContentItemId,
): CannotPublishReason => {
  const item = getListingContentItem(state, contentItemId);

  const scheduledToPublishAt = state.contentApp.changeWorkflowStepModalData.scheduledToPublishAt;
  if (!scheduledToPublishAt) {
    throw InvariantException(
      'getCannotPublishReason.ts: scheduleToPublishAt is not defined in state',
    );
  }

  if (!item || item.item.archived) {
    return CannotPublishReason.ItemNotFound;
  }

  if (!item.variant || item.variant.isArchived) {
    if (fallbacksToAnotherLanguage(item, state)) {
      return CannotPublishReason.ItemIsNotTranslatedButHasFallback;
    }

    return CannotPublishReason.ItemIsNotTranslated;
  }

  if (isItemInArchivedStep(item)) {
    return CannotPublishReason.WorkflowStep;
  }

  if (isItemPublished(item)) {
    return CannotPublishReason.ItemIsPublished;
  }

  if (!hasPermissionToPublish(item, state)) {
    return CannotPublishReason.MissingPermission;
  }

  if (isScheduledToPublishEarlier(item, scheduledToPublishAt)) {
    return CannotPublishReason.ItemIsScheduledToPublishForEarlier;
  }

  if (!doesItemWfStepLeadToPublish(item, state)) {
    return CannotPublishReason.WorkflowStep;
  }

  if (!isItemComplete(item)) {
    return CannotPublishReason.ItemIncomplete;
  }

  if (!canBePublishedWithNonLocalizableElements(item, state)) {
    return CannotPublishReason.MustPublishNonLocalizableElements;
  }

  return CannotPublishReason.None;
};

export const getCannotPublishReasonForContext = (
  context: ModalDialogType,
): GetCannotPublishReason => {
  switch (context) {
    case ModalDialogType.CascadePublishDialog:
      return getCannotPublishReason;
    case ModalDialogType.CascadeScheduleDialog:
      return getCannotScheduleToPublishReason;
    default:
      throw InvariantException(`getCannotPublishReason.ts: unsupported dialog context ${context}`);
  }
};
