import { Requireable, Validator } from 'prop-types';

export const PropTypeOrNull =
  <T>(validator: Requireable<T>): Validator<NonNullable<T> | null> =>
  (props, propName, ...rest) => {
    if (props[propName] !== null) {
      return validator.isRequired(props, propName, ...rest);
    }
    return null;
  };

function isPresent<T>(value: T | null | undefined): boolean {
  return value !== null && value !== undefined;
}

export function requiredWithPeerProps<T>(
  validator: Validator<T>,
  ...peerPropNames: readonly string[]
): Validator<T> {
  return (props, propName, componentName, ...otherArgs) => {
    const firstPresentPeer = peerPropNames.find((name) => isPresent(props[name]));
    if (firstPresentPeer && !isPresent(props[propName])) {
      return new Error(
        `Property ${propName} is required in ${componentName} when ${firstPresentPeer} property is set.`,
      );
    }

    return validator(props, propName, componentName, ...otherArgs);
  };
}

export function requiredWithPeerPropsWhenTruthy<T>(
  validator: Validator<T>,
  ...propNamesRequiredWhenTruthy: readonly string[]
): Validator<T> {
  return (props, propName, componentName, ...otherArgs) => {
    const missingProperties = propNamesRequiredWhenTruthy.filter((name) => !props[name]);
    if (props[propName] && missingProperties.length) {
      return new Error(
        `Property ${missingProperties[0]} is required in ${componentName} when ${propName} property is truthy.`,
      );
    }

    return validator(props, propName, componentName, ...otherArgs);
  };
}
