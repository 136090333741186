import Immutable from 'immutable';
import { isEmptyOrWhitespace } from '../../../_shared/utils/stringUtils.ts';
import { ICompiledContentItemElementData } from '../../../applications/itemEditor/models/contentItemElements/ICompiledContentItemElement.ts';
import { convertElementsToDomainModel } from '../../../applications/itemEditor/stores/utils/contentItemHelperMethods.ts';
import { getItemElementServerModelConverter } from '../../../applications/itemEditor/utils/getItemElementDataConverters.ts';
import { IItemElementServerModelConverterDataDependencies } from '../../../applications/itemEditor/utils/itemElementDataConverters/types/IItemElementDataConverters.type.ts';
import {
  IAssetRequestServerModel,
  IAssetResponseServerModel,
} from '../../../repositories/serverModels/AssetServerModels.type.ts';
import { EmptyAsset, IAsset } from './Asset.ts';

export function createAssetFromServerModel(assetServerModel: IAssetResponseServerModel): IAsset {
  const {
    _id,
    archived,
    capabilities,
    codename,
    collectionId,
    created,
    createdBy,
    deliveryLink,
    descriptions,
    downloadLink,
    eTag,
    filename,
    folderId,
    image_height,
    image_width,
    isAnimatedGif,
    lastModified,
    lastModifiedBy,
    link,
    nonLocalizableElements,
    size,
    thumbnailLink,
    tags,
    title,
    type,
  } = assetServerModel;

  const domainNonLocalizableElements = convertElementsToDomainModel(nonLocalizableElements);

  return {
    ...EmptyAsset,
    _deliveryLink: deliveryLink ?? EmptyAsset._deliveryLink,
    _downloadLink: downloadLink ?? EmptyAsset._downloadLink,
    _link: link ?? EmptyAsset._link,
    _thumbnailLink: thumbnailLink ?? EmptyAsset._thumbnailLink,
    archived,
    capabilities: capabilities ?? EmptyAsset.capabilities,
    codename: codename ?? EmptyAsset.codename,
    collectionId,
    created,
    createdBy,
    descriptions: descriptions
      ? Immutable.Map<Uuid, string>(descriptions)
      : EmptyAsset.descriptions,
    eTag: eTag ?? null,
    filename: filename ?? EmptyAsset.filename,
    fileSize: size,
    folderId,
    height: image_height,
    id: _id,
    isAnimatedGif,
    lastModified,
    lastModifiedBy,
    nonLocalizableElements: domainNonLocalizableElements,
    tags: tags ?? [],
    title,
    type: type ? type : EmptyAsset.type,
    width: image_width,
  };
}

export function createAssetRequestServerModel(
  asset: IAsset,
  elementDependencies: IItemElementServerModelConverterDataDependencies,
): IAssetRequestServerModel {
  const nonLocalizableElements = asset.nonLocalizableElements.map(
    (element: ICompiledContentItemElementData) =>
      getItemElementServerModelConverter(element.type)(element, elementDependencies),
  );

  return {
    _id: asset.id,
    codename: asset.codename,
    filename: asset.filename,
    title: isEmptyOrWhitespace(asset.title) ? null : asset.title,
    type: asset.type || null,
    size: asset.fileSize,
    image_width: asset.width,
    image_height: asset.height,
    isAnimatedGif: asset.isAnimatedGif,
    descriptions: asset.descriptions.toJS(),
    tags: asset.tags,
    nonLocalizableElements,
    collection: {
      id: asset.collectionId,
    },
    created: asset.created,
    createdBy: asset.createdBy,
    lastModified: asset.lastModified,
    lastModifiedBy: asset.lastModifiedBy,
    archived: asset.archived,
    folderId: asset.folderId,
    eTag: asset.eTag,
  };
}
