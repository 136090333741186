import React from 'react';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { isMultipleWorkflowsConfigurationVisible } from '../../../../_shared/selectors/Workflows/isMultipleWorkflowsConfigurationVisible.ts';
import { EnvironmentSettingsApp } from '../../root/components/EnvironmentSettingsApp.tsx';
import { WorkflowSettingsPage } from '../components/WorkflowSettingsPage.tsx';
import { WorkflowEditor } from './WorkflowEditor.tsx';

type WorkflowEditorPageProps = {
  readonly workflowId: Uuid;
};

export const WorkflowEditorPage: React.FC<WorkflowEditorPageProps> = ({ workflowId }) => {
  const isAdvancedEditorEnabled = useSelector(isMultipleWorkflowsConfigurationVisible);

  return isAdvancedEditorEnabled ? (
    <WorkflowSettingsPage>
      <WorkflowEditor workflowId={workflowId} />
    </WorkflowSettingsPage>
  ) : (
    <EnvironmentSettingsApp>
      <WorkflowEditor workflowId={workflowId} />
    </EnvironmentSettingsApp>
  );
};

WorkflowEditorPage.displayName = 'WorkflowEditorPage';
