import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { AnimatedProgressIcon, Icons } from '@kontent-ai/component-library/Icons';
import {
  SimpleStatusAlign,
  SimpleStatusDefault,
  SimpleStatusError,
  SimpleStatusSuccess,
} from '@kontent-ai/component-library/SimpleStatus';
import { usePrevious } from '@kontent-ai/hooks';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { WebSpotlightContext } from '../context/WebSpotlightContext.tsx';
import { PreviewApiDataStatus } from '../models/PreviewApiDataStatus.ts';
import {
  getMsTimeSinceLastItemModificationInPreview,
  getPreviewApiDataStatus,
  isPreviewAutoRefreshFeatureAvailable,
} from '../selectors/webSpotlightSelectors.ts';

const HideStatusMessageAfterSuccessfulRefreshMs = 30_000;
const WaitingForChangesIsTakingTooLongMs = 60_000;

interface IWebSpotlightPreviewApiLastChangesStatusProps {
  readonly onRefreshPreview: () => void;
}

const propTypes: PropTypesShape<IWebSpotlightPreviewApiLastChangesStatusProps> = {
  onRefreshPreview: PropTypes.func.isRequired,
};

const WebSpotlightPreviewApiLastChangesStatusMessage: React.FC<
  IWebSpotlightPreviewApiLastChangesStatusProps
> = ({ onRefreshPreview }) => {
  const previewApiDataStatus = useSelector(getPreviewApiDataStatus);
  const timeSinceLastModification = useSelector(getMsTimeSinceLastItemModificationInPreview);
  const [visible, setVisible] = useState<boolean>(false);

  const previousPreviewApiDataStatus = usePrevious(previewApiDataStatus);

  useEffect(() => {
    if (previousPreviewApiDataStatus !== previewApiDataStatus) {
      setVisible(previewApiDataStatus !== PreviewApiDataStatus.Initial);
    }
  }, [previousPreviewApiDataStatus, previewApiDataStatus]);

  useEffect(() => {
    let timeoutId: number = 0;

    if (previewApiDataStatus === PreviewApiDataStatus.UpToDate) {
      timeoutId = window.setTimeout(() => {
        setVisible(false);
      }, HideStatusMessageAfterSuccessfulRefreshMs);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [previewApiDataStatus]);

  if (!visible) {
    return null;
  }

  if (previewApiDataStatus === PreviewApiDataStatus.FetchingFailed) {
    return (
      <>
        <SimpleStatusError label="Something went wrong. Try manual refresh." />
        <QuinaryButton tooltipText="Refresh preview iframe" onClick={onRefreshPreview}>
          Refresh
          <QuinaryButton.Icon icon={Icons.RotateDoubleRight} />
        </QuinaryButton>
      </>
    );
  }

  if (previewApiDataStatus === PreviewApiDataStatus.Outdated) {
    if (timeSinceLastModification > WaitingForChangesIsTakingTooLongMs) {
      return (
        <>
          <SimpleStatusDefault label="This is taking too long…" icon={AnimatedProgressIcon} />
          <QuinaryButton tooltipText="Refresh preview iframe" onClick={onRefreshPreview}>
            Refresh
            <QuinaryButton.Icon icon={Icons.RotateDoubleRight} />
          </QuinaryButton>
        </>
      );
    }
    return (
      <SimpleStatusDefault label="Waiting for changes to be applied…" icon={AnimatedProgressIcon} />
    );
  }

  if (previewApiDataStatus === PreviewApiDataStatus.UpToDate) {
    return (
      <SimpleStatusSuccess
        label="Changes are ready"
        icon={Icons.CbCheckPreview}
        iconAlign={SimpleStatusAlign.Left}
      />
    );
  }

  return null;
};

export const WebSpotlightPreviewApiLastChangesStatus: React.FC<
  IWebSpotlightPreviewApiLastChangesStatusProps
> = ({ onRefreshPreview }) => {
  const isPreviewAutoRefreshAvailable = useSelector(isPreviewAutoRefreshFeatureAvailable);
  const isPreviewAutoRefreshSupported =
    useContext(WebSpotlightContext).supportedSmartLinkFeatures?.refreshHandler;

  if (!isPreviewAutoRefreshAvailable || !isPreviewAutoRefreshSupported) {
    return null;
  }

  return (
    <div
      className="web-spotlight__preview-api-last-changes-status"
      {...getDataUiElementAttribute(DataUiElement.WebSpotlightPreviewAutoRefreshStatus)}
    >
      <WebSpotlightPreviewApiLastChangesStatusMessage onRefreshPreview={onRefreshPreview} />
    </div>
  );
};

WebSpotlightPreviewApiLastChangesStatus.displayName = 'WebSpotlightPreviewApiLastChangesStatus';
WebSpotlightPreviewApiLastChangesStatus.propTypes = propTypes;
