import React, { memo } from 'react';
import {
  createEditorWithPlugins,
  getBaseEditor,
  usePluginWithParams,
} from '../../editorCore/Editor.composition.tsx';
import { EditorProps } from '../../editorCore/types/Editor.composition.type.ts';
import { useApiLimitations } from '../../plugins/apiLimitations/ApiLimitationsPlugin.tsx';
import { SimpleTextEditorLimitations } from '../../plugins/apiLimitations/api/EditorFeatureLimitations.ts';
import { useCompositionModeAdjustments } from '../../plugins/behavior/CompositionModeAdjustmentsPlugin.tsx';
import { useExternalUpdate } from '../../plugins/behavior/ExternalUpdatePlugin.tsx';
import { useFocus } from '../../plugins/behavior/FocusPlugin.tsx';
import { useReadonly } from '../../plugins/behavior/ReadonlyPlugin.tsx';
import { useSpellCheck } from '../../plugins/behavior/SpellCheckPlugin.tsx';
import { useClipboard } from '../../plugins/clipboard/ClipboardPlugin.tsx';
import { useKeyboardShortcuts } from '../../plugins/keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { textKeyCommandMap } from '../../plugins/keyboardShortcuts/api/editorKeyboardUtils.ts';
import { useDisplaySimpleWebLinks } from '../../plugins/links/web/DisplaySimpleWebLinksPlugin.tsx';
import { useDisplayMentions } from '../../plugins/mentions/MentionsPlugin.tsx';
import { useTextApi } from '../../plugins/textApi/TextApiPlugin.tsx';
import { usePlaceholder } from '../../plugins/visuals/PlaceholderPlugin.tsx';
import { useStyles } from '../../plugins/visuals/StylesPlugin.tsx';
import { useWrapper } from '../../plugins/visuals/WrapperPlugin.tsx';

const useComposedViewer = () =>
  createEditorWithPlugins(
    getBaseEditor(),
    usePluginWithParams(usePlaceholder, 'No comment'),
    useWrapper,
    useCompositionModeAdjustments,
    useFocus,
    usePluginWithParams(useApiLimitations, SimpleTextEditorLimitations),
    usePluginWithParams(useKeyboardShortcuts, textKeyCommandMap),
    useTextApi,
    useClipboard,
    useDisplayMentions,
    useStyles,
    useSpellCheck,
    useDisplaySimpleWebLinks,
    useExternalUpdate,
    useReadonly,
  );

export type CommentViewerProps = EditorProps<typeof useComposedViewer>;

export const CommentViewer: React.FC<CommentViewerProps> = memo(
  ({ spellCheck = false, ...otherProps }) => {
    const { ComposedEditor } = useComposedViewer();

    return <ComposedEditor {...otherProps} spellCheck={spellCheck} />;
  },
);

CommentViewer.displayName = 'CommentViewer';
