import { RouterLink } from '@kontent-ai/component-library/Anchor';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import { OptionalTooltip } from '@kontent-ai/component-library/Tooltip';
import { useOurFocusRing } from '@kontent-ai/component-library/hooks';
import {
  BorderRadius,
  IconSize,
  Spacing,
  colorBackgroundHoverInverse,
  colorIconDefaultInverse,
  colorTextDefaultInverse,
  gridUnit,
  px,
  shadowFocusStyles,
  transitionBgHover,
} from '@kontent-ai/component-library/tokens';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { getDataUiObjectNameAttribute } from '../../../../app/_shared/utils/dataAttributes/DataUiAttributes.ts';

export interface IBreadcrumbsItem {
  readonly label: string;
  readonly to: string;
}

export const breadcrumbsItemPropTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

interface IBreadcrumbsItemProps extends IBreadcrumbsItem {
  readonly allowEllipsis?: boolean;
  readonly lastItem?: boolean;
}

const propTypes: PropTypeMap<IBreadcrumbsItemProps> = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  lastItem: PropTypes.bool,
  allowEllipsis: PropTypes.bool,
};

export type StyledListItemProps = Pick<IBreadcrumbsItemProps, 'allowEllipsis'>;

interface IFocusRingProps {
  readonly $isFocusVisible: boolean;
}

export const breadcrumbsItemMinWidth = 5 * gridUnit;

export const StyledListItem = styled.li<StyledListItemProps>`
  display: flex;
  align-items: center;
  height: 100%;

  ${({ allowEllipsis }) =>
    allowEllipsis
      ? css`
    min-width: ${px(breadcrumbsItemMinWidth)};

    &:last-child {
      /* '8' was picked by trial-and-error as we want prioritize shrinking of the last breadcrumb item (as opposed to the first) */
      flex-shrink: 8;
    }
  `
      : css`
    flex-shrink: 0;
  `};
`;

export const StyledLink = styled(RouterLink)<IFocusRingProps>`
  position: relative;
  height: 100%;
  display: flex;
  min-width: ${px(breadcrumbsItemMinWidth)};
  padding: 0 ${px(Spacing.S)};
  align-items: center;
  transition: background-color ${transitionBgHover};
  white-space: nowrap;
  color: ${colorTextDefaultInverse};
  border-radius: ${px(BorderRadius.S)};
  
  &:focus,
  &:hover {
    background-color: ${colorBackgroundHoverInverse};
    color: ${colorTextDefaultInverse};
  }

  ${({ $isFocusVisible }) => $isFocusVisible && shadowFocusStyles};
`;

export const BreadcrumbsItem = React.forwardRef<HTMLLIElement, IBreadcrumbsItemProps>(
  ({ label, to, lastItem, allowEllipsis = false }, forwardedRef) => {
    const { isFocusVisible, focusProps } = useOurFocusRing();

    return (
      <StyledListItem ref={forwardedRef} allowEllipsis={allowEllipsis}>
        <OptionalTooltip
          placement="bottom-start"
          text={label}
          customRenderText={(ref) => (
            <StyledLink
              to={to}
              aria-current={lastItem ? 'page' : undefined}
              $isFocusVisible={isFocusVisible}
              {...getDataUiObjectNameAttribute(label)}
              {...focusProps}
            >
              <Label ref={ref} size={LabelSize.L}>
                {label}
              </Label>
            </StyledLink>
          )}
        />
        {!lastItem && <Icons.ChevronRight size={IconSize.XS} color={colorIconDefaultInverse} />}
      </StyledListItem>
    );
  },
);

BreadcrumbsItem.displayName = 'BreadcrumbsItem';
BreadcrumbsItem.propTypes = propTypes;
