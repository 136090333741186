import { Location } from 'history';
import Immutable from 'immutable';
import { Dispatch, ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import { Breadcrumb } from '../../models/Breadcrumb.type.ts';
import {
  IBreadcrumbResolverDependencies,
  IBreadcrumbResolverInfo,
  createBreadcrumbResolvers,
} from '../../utils/breadcrumbs/breadcrumbResolvers.ts';
import { breadcrumbsChanged } from '../sharedActions.ts';

interface ILoadBreadcrumbsActionDeps extends IBreadcrumbResolverDependencies {
  readonly location: Location;
}

export const createLoadBreadcrumbsAction =
  () =>
  (deps: ILoadBreadcrumbsActionDeps): ThunkPromise => {
    const { location, ...restOfDeps } = deps;

    return async (dispatch: Dispatch): Promise<void> => {
      let breadcrumbResolvers: IBreadcrumbResolverInfo[] | undefined =
        createBreadcrumbResolvers(restOfDeps);
      const promises = location.pathname
        ? location.pathname
            .split('/')
            .filter((x) => !!x)
            .map((segment) => {
              const foundResolverInfo = breadcrumbResolvers?.find((resolverInfo) =>
                resolverInfo.pattern.exec(segment),
              );
              breadcrumbResolvers = foundResolverInfo?.childResolvers;
              return foundResolverInfo;
            })
            .map((resolverInfo) => resolverInfo?.resolver?.(location))
            .filter((x) => !!x)
        : [];

      const segments = await Promise.all(promises);
      const breadcrumbs =
        segments.length > 0
          ? segments.reduce(
              (result: Immutable.List<Breadcrumb>, segment: Immutable.List<Breadcrumb>) =>
                result.concat(segment).toList(),
            )
          : Immutable.List<Breadcrumb>();

      dispatch(breadcrumbsChanged(breadcrumbs));
    };
  };
