import { useCallback } from 'react';
import { UserFeedbackTimestamps } from '../../../data/models/user/UserFeedbackTimestamps.type.ts';
import { IntercomMessageServerModel } from '../../../repositories/serverModels/IntercomMessageServerModel.type.ts';
import { upsertUserProperty } from '../../actions/thunkSharedActions.ts';
import { trackUserEventWithData } from '../../actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../constants/trackedEvent.ts';
import { UserPropertyServerKey } from '../../models/UserPropertiesModel.ts';
import { UmuxSurveyEventTypes } from '../../models/events/UmuxSurveyEventData.type.ts';
import {
  IUmuxFeedbackIntercomMessage,
  UserFeedbackSource,
} from '../../models/events/UserFeedbackEventData.type.ts';
import { repositoryCollection } from '../../repositories/repositories.ts';
import { TimestampsSelector } from '../../selectors/umuxSurveySelectors.ts';
import { logError } from '../../utils/logError.ts';
import { useDispatch } from '../useDispatch.ts';
import { useSelector } from '../useSelector.ts';

export const useUserFeedback = (
  source: UserFeedbackSource,
  propertyServerKey: UserPropertyServerKey,
  timestampsSelector: TimestampsSelector,
) => {
  const dispatch = useDispatch();
  const feedbackTimestamps = useSelector((s) => timestampsSelector(s.sharedApp.userProperties));

  const dismissUserFeedback = useCallback(async () => {
    const payload: UserFeedbackTimestamps = {
      ...feedbackTimestamps,
      dismissedAt: new Date().toISOString(),
    };
    const userFeedbackString = JSON.stringify(payload);

    dispatch(
      trackUserEventWithData(TrackedEvent.UmuxSurvey, {
        action: UmuxSurveyEventTypes.Close,
        source,
      }),
    );

    try {
      await dispatch(upsertUserProperty(propertyServerKey, userFeedbackString));
    } catch (e) {
      logError(`${__filename}: Failed to show user feedback dialog.`, e);
    }
  }, [feedbackTimestamps, propertyServerKey, source]);

  const showUserFeedback = useCallback(async () => {
    const payload: UserFeedbackTimestamps = {
      ...feedbackTimestamps,
      displayedAt: new Date().toISOString(),
    };

    const userFeedbackString = JSON.stringify(payload);

    dispatch(
      trackUserEventWithData(TrackedEvent.UmuxSurvey, {
        action: UmuxSurveyEventTypes.AutomaticallyShown,
        source,
      }),
    );

    try {
      await dispatch(upsertUserProperty(propertyServerKey, userFeedbackString));
    } catch (e) {
      logError(`${__filename}: Failed to show user feedback dialog.`, e);
    }
  }, [feedbackTimestamps, propertyServerKey, source]);

  const submitUserFeedback = useCallback(
    async (ease: string, requirements: string, comment: string) => {
      try {
        const feedbackMessage: IUmuxFeedbackIntercomMessage = {
          ease,
          comment,
          source,
          requirements,
        };
        await repositoryCollection.userRepository.sendIntercomMessage(
          createFeedbackMessage(feedbackMessage),
        );
        dispatch(trackUserEventWithData(TrackedEvent.UserFeedback, feedbackMessage));

        const payload: UserFeedbackTimestamps = {
          ...feedbackTimestamps,
          answeredAt: new Date().toISOString(),
          displayedAt: feedbackTimestamps.displayedAt ?? new Date().toISOString(),
        };
        const userFeedbackString = JSON.stringify(payload);
        await dispatch(upsertUserProperty(propertyServerKey, userFeedbackString));
        dispatch(
          trackUserEventWithData(TrackedEvent.UmuxSurvey, {
            action: UmuxSurveyEventTypes.Sent,
            source,
          }),
        );
      } catch (e) {
        logError(`${__filename}: Failed to send user feedback.`, e);
      }
    },
    [feedbackTimestamps, propertyServerKey, source],
  );

  return {
    dismissUserFeedback,
    showUserFeedback,
    submitUserFeedback,
  };
};

const createFeedbackMessage = (
  feedback: IUmuxFeedbackIntercomMessage,
): IntercomMessageServerModel => {
  return {
    text: `This message was automatically generated by Kontent.ai. \nFeedback on: ${
      feedback.source
    } \n${createCustomizedFeedbackText(feedback)}`,
  };
};

const createCustomizedFeedbackText = (feedback: IUmuxFeedbackIntercomMessage): string => {
  const withComment = feedback.comment ? `Comment: ${feedback.comment}` : '';

  return `Requirements: ${feedback.requirements} \nEase: ${feedback.ease} \n${withComment}`;
};
