import { isElement, isElementVisible, scrollToView } from '@kontent-ai/DOM';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

const FINISH_DELAY = 100;
const POSITION_INTERVAL = 100;

interface IFlyProps {
  readonly to: string;
  readonly duration: number;
  readonly onFinished?: () => void;
  readonly children: React.ReactElement;
}

const propTypes: PropTypeMap<IFlyProps> = {
  to: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  onFinished: PropTypes.func,
  children: PropTypes.element.isRequired,
};

export const Fly: React.FC<IFlyProps> = ({ children, duration, onFinished, to }) => {
  const [targetX, setTargetX] = useState<number | null>(null);
  const [targetY, setTargetY] = useState<number | null>(null);

  const anchorRef = useRef<HTMLDivElement>(null);
  const onFinishedRef = useRef(onFinished);
  onFinishedRef.current = onFinished;

  const getTargetElementRef = useRef<() => HTMLElement | null>(() => document.querySelector(to));
  getTargetElementRef.current = () => document.querySelector(to);

  useEffect(() => {
    const scrollToTarget = (): void => {
      const targetElement = getTargetElementRef.current();

      if (isElement(targetElement) && !isElementVisible(targetElement)) {
        scrollToView(targetElement, 'center', 'auto');
      }
    };

    const setTargetOffset = (): void => {
      const targetElement = getTargetElementRef.current();
      const currentElement = anchorRef.current;

      if (!currentElement || !targetElement || !(currentElement instanceof HTMLElement)) {
        return;
      }

      const targetRect = targetElement.getBoundingClientRect();
      const currentRect = currentElement.getBoundingClientRect();

      const deltaX = targetRect.left - currentRect.left;
      const deltaY = targetRect.top - currentRect.top;

      setTargetX(deltaX);
      setTargetY(deltaY);
    };

    const offsetIntervalId = window.setInterval(() => {
      scrollToTarget();
      setTargetOffset();
    }, POSITION_INTERVAL);

    const finishTimeoutId = window.setTimeout(() => {
      clearInterval(offsetIntervalId);
      onFinishedRef.current?.();
    }, duration + FINISH_DELAY);

    return () => {
      clearTimeout(finishTimeoutId);
      clearInterval(offsetIntervalId);
    };
  }, [duration]);

  const styles =
    targetX && targetY
      ? {
          transform: `translate(${targetX}px, ${targetY}px)`,
          transition: `transform ${duration}ms`,
        }
      : undefined;

  return (
    <div ref={anchorRef}>
      <div className="fly__content" style={styles}>
        {children}
      </div>
    </div>
  );
};

Fly.displayName = 'Fly';
Fly.propTypes = propTypes;
