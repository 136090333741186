import { Box } from '@kontent-ai/component-library/Box';
import { Column, Row } from '@kontent-ai/component-library/Row';
import {
  Typography,
  borderWidthDefault,
  colorBorderLowEmphasis,
  colorTextDefault,
  px,
  sizeFilterSidebarWidth,
  spacingElementEdgeHorizontal,
  spacingMainLayoutBottom,
  spacingMainLayoutTop,
} from '@kontent-ai/component-library/tokens';
import React from 'react';
import styled from 'styled-components';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ClearFilterButton } from '../../../shared/components/filter/ClearFilterButton.tsx';
import { AssetsCollectionFilterSelector } from '../../containers/AssetListing/FilterSelectors/AssetCollectionFilterSelector.tsx';
import { AssetsTaxonomyFilterSelector } from '../../containers/AssetListing/FilterSelectors/AssetTaxonomyFilterSelector.tsx';

const assetListingFilterHeadlineIdentifier = 'assetListingFilterHeadline';

const FilterHeadline = styled.div`
  ${Typography.SubheadlineLarge}
  color: ${colorTextDefault};
`;

type AssetFilterProps = {
  readonly onFilterCleared: (() => void) | undefined;
};

export const AssetFilter: React.FC<AssetFilterProps> = ({ onFilterCleared }) => (
  <Box
    component="section"
    aria-labelledby={assetListingFilterHeadlineIdentifier}
    paddingTop={spacingMainLayoutTop}
    paddingX={spacingElementEdgeHorizontal}
    paddingBottom={spacingMainLayoutBottom}
    css={`
      width: ${px(sizeFilterSidebarWidth)};
      min-height: 100%;
      max-height: 100%;
      border-right: ${px(borderWidthDefault)} solid ${colorBorderLowEmphasis};
      overflow-y: auto;
    `}
    {...getDataUiElementAttribute(DataUiElement.Filter)}
  >
    <Row alignY="center">
      <Column>
        <FilterHeadline id={assetListingFilterHeadlineIdentifier}>Refine results</FilterHeadline>
      </Column>

      <Column
        width="content"
        css={onFilterCleared ? undefined : 'visibility: hidden;'}
        aria-hidden={!onFilterCleared}
      >
        <ClearFilterButton onClick={() => onFilterCleared?.()} />
      </Column>
    </Row>

    <AssetsCollectionFilterSelector />
    <AssetsTaxonomyFilterSelector />
  </Box>
);

AssetFilter.displayName = 'AssetFilter';
