import classnames from 'classnames';
import PropTypes, { Validator } from 'prop-types';
import React from 'react';
import { getDropDown } from '../../../../../../../_shared/components/DropDown.tsx';
import {
  DataUiCollection,
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { Capability } from '../../../../../../../_shared/utils/permissions/capability.ts';
import { IRuleWithScope } from '../../../../../../../data/models/roles/IRoleSettings.ts';
import { assetCanRuleCapabilityOptions } from '../../../../constants/capabilityActionOptions.ts';
import { getOptionName } from '../../../../utils/capabilityRulePickerUtils.ts';
import { RuleDeleteButton } from './RuleDeleteButton.tsx';

const ActionDropDown = getDropDown<ReadonlyArray<Capability>>();

interface ICapabilityRulePickerProps {
  readonly capabilityRule: IRuleWithScope;
  readonly isDisabled: boolean;
  readonly onChange: (newRule: IRuleWithScope) => void;
  readonly onRemove: () => void;
}

export const AssetCapabilityRulePicker: React.FC<ICapabilityRulePickerProps> = ({
  capabilityRule,
  isDisabled,
  onChange,
  onRemove,
}) => {
  return (
    <div
      className="capability-rules__rule capability-rules__rule--expanded"
      {...getDataUiElementAttribute(DataUiElement.CapabilityRule)}
    >
      <div
        className={classnames('capability-rules__rule-part', {
          'capability-rules__rule-part--is-disabled': isDisabled,
        })}
      >
        <ActionDropDown
          dataUiCollectionName={DataUiCollection.RuleCapabilities}
          getOptionId={(o) => o.join(',')}
          onSelect={(action) =>
            onChange({
              ...capabilityRule,
              capabilities: action,
            })
          }
          options={assetCanRuleCapabilityOptions}
          renderOptionName={(option) => `${getOptionName(option)} assets`}
          selectedOption={capabilityRule.capabilities}
          className="combo-box"
          disabled={isDisabled}
        />
      </div>
      {!isDisabled && <RuleDeleteButton disabled={isDisabled} onRemove={onRemove} />}
    </div>
  );
};

const propTypes: PropTypeMap<ICapabilityRulePickerProps> = {
  capabilityRule: PropTypes.object.isRequired as Validator<IRuleWithScope>,
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

AssetCapabilityRulePicker.propTypes = propTypes;
AssetCapabilityRulePicker.displayName = 'AssetCapabilityRulePicker';
