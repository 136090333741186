import { usePrevious } from '@kontent-ai/hooks';
import { animated, useSpring } from '@react-spring/web';
import PropTypes from 'prop-types';
import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { gridUnit } from '../../../../../../_shared/constants/styleConstants.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { isSpringAnimationFinished } from '../../../../../../_shared/utils/reactSpringUtils.ts';
import { EditingAction } from '../../models/EditingAction.ts';
import { QuickAction } from './quickActions/QuickAction.tsx';

type EditingQuickActionsProps = {
  readonly actions: readonly EditingAction[];
  readonly activatedAction: EditingAction;
  readonly onDeactivateAction: () => void;
};

const propTypes: PropTypesShape<EditingQuickActionsProps> = {
  actions: PropTypes.arrayOf(PropTypes.oneOf(Object.values(EditingAction))).isRequired,
  activatedAction: PropTypes.oneOf(Object.values(EditingAction)).isRequired,
  onDeactivateAction: PropTypes.func.isRequired,
};

const visibleStyle: CSSProperties = {
  opacity: 1,
  transform: 'translate(0px)',
};

const hiddenStyle: CSSProperties = {
  opacity: 0,
  transform: `translate(${gridUnit * 5}px)`,
};

export const EditingQuickActions: React.FC<EditingQuickActionsProps> = ({
  actions,
  activatedAction,
  onDeactivateAction,
}) => {
  const visible = actions.length > 0;

  // We need to remember the last visible actions to properly fade away upon transition to no actions
  const [renderActions, setRenderActions] = useState(actions);
  useEffect(() => {
    if (actions.length > 0) {
      setRenderActions(actions);
    }
  }, [actions]);

  const [allowActiveActions, setAllowActiveActions] = useState(visible);

  const [springProps, springRef] = useSpring(() => ({
    initial: visible ? visibleStyle : hiddenStyle,
  }));

  const animateVisibilityChange = useCallback(async () => {
    if (!visible) {
      setAllowActiveActions(false);
    }
    const animationResult = springRef.start(visible ? visibleStyle : hiddenStyle);
    if (await isSpringAnimationFinished(animationResult)) {
      setAllowActiveActions(visible);
      if (!visible) {
        setRenderActions([]);
      }
    }
  }, [visible, springRef]);

  const previousVisible = usePrevious(visible);
  useEffect(() => {
    if (previousVisible !== visible) {
      animateVisibilityChange();
    }
  }, [previousVisible, visible, animateVisibilityChange]);

  if (renderActions.length === 0) {
    return null;
  }

  return (
    <animated.div style={springProps}>
      <div
        className="quick-actions"
        {...getDataUiCollectionAttribute(DataUiCollection.QuickActions)}
      >
        {renderActions.map((action) => (
          <QuickAction
            action={action}
            activatedAction={allowActiveActions ? activatedAction : EditingAction.none}
            key={action}
            onClickOutside={onDeactivateAction}
          />
        ))}
      </div>
    </animated.div>
  );
};

EditingQuickActions.displayName = 'EditingQuickActions';
EditingQuickActions.propTypes = propTypes;
