import React from 'react';
import { HotkeysHandler } from '../../../../../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import { IconName } from '../../../../../../../_shared/constants/iconEnumGenerated.ts';
import { Icon } from '../../../../../../../_shared/uiComponents/Icon/Icon.tsx';
import {
  DataUiAction,
  DataUiWorkflowAction,
  ItemEditingForms,
  getDataUiActionAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface ISectionProps {
  readonly dataUiActionAttribute: DataUiWorkflowAction | DataUiAction;
  readonly dataUiNameAttribute: ItemEditingForms;
  readonly onEditClick: () => void;
}

export const ClickableSection: React.FC<React.PropsWithChildren<ISectionProps>> = ({
  children,
  dataUiActionAttribute,
  dataUiNameAttribute,
  onEditClick,
}) => (
  <HotkeysHandler
    handlers={{
      onEnter: onEditClick,
    }}
  >
    <div
      className="sidebar__section-item sidebar__section-item--is-clickable"
      onClick={onEditClick}
      tabIndex={0}
      {...getDataUiActionAttribute(dataUiActionAttribute)}
    >
      <div {...getDataUiObjectNameAttribute(dataUiNameAttribute || '')}>{children}</div>
      <Icon iconName={IconName.Edit} className="content-item-sidebar__icon-edit" />
    </div>
  </HotkeysHandler>
);

ClickableSection.displayName = 'ClickableSection';
