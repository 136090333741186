import PropTypes from 'prop-types';
import React from 'react';
import { IInputProps, Input } from '../Input/Input.tsx';
import { InputState } from '../Input/inputStateEnum.ts';
import {
  BaseClipboardComponent,
  CommonClipboardProps,
  commonClipboardPropTypes,
} from './components/BaseClipboardComponent.tsx';
import {
  ClipboardButton,
  ClipboardButtonInjectedProps,
  renderDefaultClipboardButton,
} from './components/ClipboardButton.tsx';

type ClipboardOwnProps = {
  readonly renderClipboardButton?: (props: ClipboardButtonInjectedProps) => JSX.Element;
};

export type ClipboardProps = CommonClipboardProps &
  ClipboardOwnProps &
  Pick<IInputProps, 'hintText' | 'inputState' | 'maxLength' | 'name' | 'placeholder'>;

const clipboardPropTypes: PropTypeMap<ClipboardProps> = {
  ...commonClipboardPropTypes,
  hintText: PropTypes.string,
  inputState: PropTypes.oneOf(Object.values(InputState)),
  maxLength: PropTypes.number,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  renderClipboardButton: PropTypes.func,
};

const ClipboardComponent = React.forwardRef<HTMLInputElement, ClipboardProps>(
  ({ inputState = InputState.ReadOnly, renderClipboardButton, ...otherProps }, forwardedRef) => (
    <BaseClipboardComponent
      dataUiComponent={Clipboard.displayName}
      inputState={inputState}
      renderClipboardButton={renderClipboardButton ?? renderDefaultClipboardButton}
      renderControlComponent={(injectedProps) => <Input ref={forwardedRef} {...injectedProps} />}
      {...otherProps}
    />
  ),
);

ClipboardComponent.displayName = 'Clipboard';
ClipboardComponent.propTypes = clipboardPropTypes;

const clipboardComposition = {
  Button: ClipboardButton,
} as const;

export const Clipboard = Object.assign(ClipboardComponent, clipboardComposition);
