import { Box } from '@kontent-ai/component-library/Box';
import {
  QuinaryButton,
  quinaryButtonHorizontalPadding,
} from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { RectangleSkeleton } from '@kontent-ai/component-library/Skeleton';
import { SrOnly } from '@kontent-ai/component-library/styles';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { useCallback, useId } from 'react';
import { trackUserEventWithData } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { ItemColumnCode } from '../../../../../_shared/constants/itemColumnCode.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { ContentStatusContentItemClickOrigin } from '../../../../../_shared/models/TrackUserEventData.ts';
import { IUserInfo } from '../../../../../_shared/models/UserInfo.ts';
import { ScrollTableCell } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableCell.tsx';
import { ScrollTableCellWrappedByLink } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableCellWrappedByLink.tsx';
import { ScrollTableRow } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableRow.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { pluralizeWithCount } from '../../../../../_shared/utils/stringUtils.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IListingContentItem } from '../../../../../data/models/listingContentItems/IListingContentItem.ts';
import {
  EmptyCellContent,
  ItemCollectionCellContent,
  ItemNameCellContent,
  ItemSpaceCellContent,
  ItemTypeCellContent,
  getTextForTableCell,
} from '../../../../contentInventory/content/features/ContentItemInventory/components/ItemInventoryScrollTable/Cells/ContentItemScrollTableCellsContents.tsx';
import { TableCellDueDate } from '../../../../contentInventory/content/features/ContentItemInventory/components/TableCellDueDate.tsx';
import {
  SpaceNamesMetadata,
  getCollapsedSpaceNames,
} from '../../../../environmentSettings/spaces/utils/spacesUtils.ts';
import { ContentStatusCommentCount } from '../../../../unifiedMissionControl/contentStatus/reducers/contentStatusComments/byId.ts';
import { ContentStatusTaskCount } from '../../../../unifiedMissionControl/contentStatus/reducers/contentStatusTasks/byId.ts';
import { ScrollTableItemContributors } from '../ScrollTableItemContributors.tsx';

const TextCellContent: React.FC<React.PropsWithChildren<NoProps>> = (props) => (
  <div className="scroll-table__value" {...props} />
);

const EmptyQuinaryButtonCellContent: React.FC = () => (
  <Box paddingX={quinaryButtonHorizontalPadding}>—</Box>
);

type TasksContentProps = Readonly<{
  count: ContentStatusCommentCount | ContentStatusTaskCount | undefined;
}>;

const CommentsOrTasksCellContent: React.FC<React.PropsWithChildren<TasksContentProps>> = ({
  children,
  count,
}) => {
  if (count === undefined || count.loadingStatus === LoadingStatus.Loading) {
    return <RectangleSkeleton width={Spacing.XXXXL} height={Spacing.XL} />;
  }
  if (count.value <= 0) {
    return <EmptyQuinaryButtonCellContent />;
  }

  return children;
};

type Props = {
  readonly allowNavigationWithOnClick?: boolean;
  readonly assignees: ReadonlyArray<IUserInfo>;
  readonly collectionName: string;
  readonly commentCount: ContentStatusCommentCount | undefined;
  readonly disabledClickMessage?: string;
  readonly hiddenColumns: ReadonlySet<ItemColumnCode>;
  readonly isDisabled?: boolean;
  readonly isHighlighted?: boolean;
  readonly item: IListingContentItem;
  readonly itemLinkPath: string;
  readonly itemType: IContentType;
  readonly languageName: string;
  readonly onCommentButtonClick: () => void;
  readonly onItemClick?: (shiftPressed: boolean) => void;
  readonly onItemDoubleClick?: () => void;
  readonly onTaskButtonClick: () => void;
  readonly renderWorkflowStatus: () => React.ReactElement;
  readonly spaceData: SpaceNamesMetadata;
  readonly taskCount: ContentStatusTaskCount | undefined;
};

export const ContentStatusScrollTableRow: React.FC<Props> = (props) => {
  const clickableCellProps = {
    allowNavigationWithOnClick: props.allowNavigationWithOnClick,
    linkPath: props.itemLinkPath,
    onItemClick: props.onItemClick,
    onItemDoubleClick: props.onItemDoubleClick,
    tooltipText: props.disabledClickMessage,
  };
  const descriptionId = useId();
  const dispatch = useDispatch();

  const onCommentButtonClick = useCallback(() => {
    dispatch(
      trackUserEventWithData(TrackedEvent.ContentStatusContentItemClicked, {
        itemId: props.item.item.id,
        origin: ContentStatusContentItemClickOrigin.CommentButton,
        variantId: props.item.variant?.id.variantId,
      }),
    );
    props.onCommentButtonClick();
  }, [props.onCommentButtonClick, props.item.item.id, props.item.variant?.id.variantId]);

  const onTaskButtonClick = useCallback(() => {
    dispatch(
      trackUserEventWithData(TrackedEvent.ContentStatusContentItemClicked, {
        itemId: props.item.item.id,
        origin: ContentStatusContentItemClickOrigin.TaskButton,
        variantId: props.item.variant?.id.variantId,
      }),
    );
    props.onTaskButtonClick();
  }, [props.onTaskButtonClick, props.item.item.id, props.item.variant?.id.variantId]);

  const spaceNames = getCollapsedSpaceNames(props.spaceData);

  return (
    <ScrollTableRow
      objectName={props.item.item.name}
      isDisabled={props.isDisabled}
      isHighlighted={props.isHighlighted}
    >
      <ScrollTableCellWrappedByLink
        size={10}
        isGrowing
        title={props.item.item.name}
        dataUiAttribute={getDataUiElementAttribute(DataUiElement.ItemsTableColumnName)}
        aria-label={props.item.item.name}
        aria-describedby={descriptionId}
        {...clickableCellProps}
      >
        <ItemNameCellContent hideLanguageName item={props.item} />
      </ScrollTableCellWrappedByLink>
      <SrOnly id={descriptionId}>
        Content type: {props.itemType.name}, Collection: {props.collectionName}, Language:{' '}
        {props.languageName}
      </SrOnly>

      {!props.hiddenColumns.has(ItemColumnCode.ContentType) && (
        <ScrollTableCellWrappedByLink
          size={7}
          isGrowing
          title={props.itemType.name}
          dataUiAttribute={getDataUiElementAttribute(DataUiElement.ItemsTableColumnType)}
          {...clickableCellProps}
        >
          <ItemTypeCellContent itemType={props.itemType} />
        </ScrollTableCellWrappedByLink>
      )}

      {!props.hiddenColumns.has(ItemColumnCode.Collection) && (
        <ScrollTableCellWrappedByLink
          size={7}
          isGrowing
          title={getTextForTableCell(props.collectionName)}
          dataUiAttribute={getDataUiElementAttribute(DataUiElement.ItemsTableColumnCollection)}
          {...clickableCellProps}
        >
          <ItemCollectionCellContent collectionName={props.collectionName} />
        </ScrollTableCellWrappedByLink>
      )}

      {!props.hiddenColumns.has(ItemColumnCode.Space) && (
        <ScrollTableCellWrappedByLink
          size={7}
          isGrowing
          title={props.spaceData.usedSpaces.map(({ name }) => name).join(', ')}
          dataUiAttribute={getDataUiElementAttribute(DataUiElement.ItemsTableColumnSpace)}
          {...clickableCellProps}
        >
          <ItemSpaceCellContent spaceNames={spaceNames} />
        </ScrollTableCellWrappedByLink>
      )}

      {!props.hiddenColumns.has(ItemColumnCode.Language) && (
        <ScrollTableCellWrappedByLink
          size={7}
          isGrowing
          title={props.languageName}
          dataUiAttribute={getDataUiElementAttribute(DataUiElement.ItemsTableColumnLanguage)}
          {...clickableCellProps}
        >
          <TextCellContent>{props.languageName}</TextCellContent>
        </ScrollTableCellWrappedByLink>
      )}

      {!props.hiddenColumns.has(ItemColumnCode.Contributors) && (
        <ScrollTableCellWrappedByLink
          size={4}
          dataUiAttribute={getDataUiElementAttribute(DataUiElement.ItemsTableColumnContributors)}
          {...clickableCellProps}
        >
          {props.assignees && props.assignees.length > 0 && (
            <ScrollTableItemContributors contributors={props.assignees} />
          )}
        </ScrollTableCellWrappedByLink>
      )}

      {!props.hiddenColumns.has(ItemColumnCode.DueDate) && (
        <ScrollTableCellWrappedByLink
          size={10}
          isGrowing
          dataUiAttribute={getDataUiElementAttribute(DataUiElement.ItemsTableColumnDueDate)}
          {...clickableCellProps}
        >
          {props.item.variant ? (
            <TableCellDueDate dueDate={props.item.variant.assignment.due} />
          ) : (
            <EmptyCellContent />
          )}
        </ScrollTableCellWrappedByLink>
      )}

      {!props.hiddenColumns.has(ItemColumnCode.WorkflowStep) && (
        <ScrollTableCellWrappedByLink
          size={4.5}
          dataUiAttribute={getDataUiElementAttribute(DataUiElement.ItemsTableColumnWorkflowStep)}
          {...clickableCellProps}
        >
          {props.renderWorkflowStatus()}
        </ScrollTableCellWrappedByLink>
      )}

      {!props.hiddenColumns.has(ItemColumnCode.Comments) && (
        <ScrollTableCell size={3}>
          <CommentsOrTasksCellContent count={props.commentCount}>
            <QuinaryButton
              tooltipText={pluralizeWithCount('unresolved comment', props.commentCount?.value)}
              onClick={onCommentButtonClick}
            >
              <QuinaryButton.Icon icon={Icons.BubbleWithLines} />
              {props.commentCount?.value}
            </QuinaryButton>
          </CommentsOrTasksCellContent>
        </ScrollTableCell>
      )}
      {!props.hiddenColumns.has(ItemColumnCode.Tasks) && (
        <ScrollTableCell size={2}>
          <CommentsOrTasksCellContent count={props.taskCount}>
            <QuinaryButton
              tooltipText={pluralizeWithCount('unfinished task', props.taskCount?.value)}
              onClick={onTaskButtonClick}
            >
              <QuinaryButton.Icon icon={Icons.Checklist} />
              {props.taskCount?.value}
            </QuinaryButton>
          </CommentsOrTasksCellContent>
        </ScrollTableCell>
      )}
    </ScrollTableRow>
  );
};

ContentStatusScrollTableRow.displayName = 'ContentItemScrollTableRow';
