import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Asset, IAsset } from '../../../data/models/assets/Asset.ts';
import { shouldAssetFileNameBeRendered } from '../../utils/assets/assetUtils.ts';
import { SearchPhraseHighlighter } from '../Highlighting/SearchPhraseHighlighter.tsx';
import { IgnoreByGrammarly } from '../IgnoreByGrammarly.tsx';
import { AssetFileName } from './AssetFileName.tsx';

interface IAssetTitleDataProps {
  readonly asset: IAsset;
  readonly isAssetFileTypeValid: boolean;
  readonly searchPhrase?: string;
}

export const nameWithHighlight = (searchPhrase: string | undefined, name: string) =>
  searchPhrase ? <SearchPhraseHighlighter searchPhrase={searchPhrase} text={name} /> : name;

const propTypes: PropTypesShape<IAssetTitleDataProps> = {
  asset: Asset.propTypeValidator.isRequired,
  isAssetFileTypeValid: PropTypes.bool.isRequired,
  searchPhrase: PropTypes.string,
};

export const AssetTitle: React.FC<IAssetTitleDataProps> = ({
  asset,
  searchPhrase,
  isAssetFileTypeValid,
}: IAssetTitleDataProps): JSX.Element | null => {
  return (
    <div className="asset-thumbnail__title">
      {asset.title && (
        <div className={classNames('asset-thumbnail__name')}>
          <IgnoreByGrammarly text={asset.title}>
            {nameWithHighlight(searchPhrase, asset.title)}
          </IgnoreByGrammarly>
        </div>
      )}
      {shouldAssetFileNameBeRendered(searchPhrase, asset, isAssetFileTypeValid) && (
        <div
          className={classNames('asset-thumbnail__name', {
            'asset-thumbnail__name--is-invalid': !isAssetFileTypeValid,
            'asset-thumbnail__name--is-not-first': !!asset.title,
          })}
        >
          <AssetFileName
            searchPhrase={searchPhrase}
            name={asset.filename}
            invalidClassName={
              isAssetFileTypeValid ? '' : 'asset-thumbnail__file-extension--is-invalid'
            }
          />
        </div>
      )}
      {asset.archived && (
        <span className="asset-thumbnail__deleted">
          <IgnoreByGrammarly text="(Deleted)" />
        </span>
      )}
    </div>
  );
};

AssetTitle.displayName = 'AssetTitle';
AssetTitle.propTypes = propTypes;
