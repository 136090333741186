import { memoize } from '@kontent-ai/memoization';
import { Collection, alphabetically } from '@kontent-ai/utils';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import {
  createNewRoleLink,
  createRoleEditingLink,
} from '../../../../_shared/utils/routing/projectSubscriptionRoutingUtils.ts';
import { IRole } from '../../../../data/models/roles/IRole.ts';
import { IProjectContributor } from '../../../../data/models/users/ProjectContributor.ts';
import { onRoleListingLeft } from '../actions/rolesActions.ts';
import { initRoleListing, restoreDeletedRole } from '../actions/thunkRolesActions.ts';
import {
  IRoleListingCallbackProps,
  IRoleListingDataProps,
  RoleItem,
  RoleListing as RoleListingComponent,
} from '../components/RoleListing.tsx';
import { getCustomRolesLimitInfo } from '../selectors/customRoleLimit.ts';
import { hasRole } from '../selectors/isRoleUsed.ts';
import { canCreateNewCustomRole } from '../utils/customRolesUtils.ts';

interface IOwnProps {
  readonly projectId: Uuid;
  readonly withSubscriptionIdInRoute?: Uuid;
}

const getRoleItems = memoize.maxOne(
  (
    allRoles: ReadonlyMap<Uuid, IRole>,
    allUsers: ReadonlyMap<Uuid, IProjectContributor>,
    projectId: Uuid,
    subscriptionId?: Uuid,
  ): ReadonlyArray<RoleItem> => {
    return [...allRoles.values()]
      .map((role: IRole) => ({
        id: role.id,
        name: role.name,
        isDefaultRole: role.isReadonly,
        userCount: Collection.filter(allUsers, hasRole(role.id)).size,
        roleEditorPath: createRoleEditingLink({
          projectId,
          subscriptionId,
          roleId: role.id,
        }),
      }))
      .sort((a, b) => alphabetically(a.name, b.name));
  },
);

function mapStateToProps(state: IStore, ownProps: IOwnProps): IRoleListingDataProps {
  const {
    data: {
      roles: { rolesById },
      users: { usersById },
    },
    rolesApp: {
      listingUi: { loadingStatus, operationStatus },
    },
  } = state;
  const { projectId, withSubscriptionIdInRoute } = ownProps;

  const roleItems = getRoleItems(rolesById, usersById, projectId, withSubscriptionIdInRoute);

  const limitInfo = getCustomRolesLimitInfo(state);
  const canCreateNewRole = canCreateNewCustomRole(limitInfo);

  return {
    createNewRolePath: createNewRoleLink({
      projectId,
      subscriptionId: withSubscriptionIdInRoute,
    }),
    roleItems,
    canCreateNewRole,
    loadingStatus,
    operationStatus,
  };
}

function mapDispatchToProps(dispatch: Dispatch): IRoleListingCallbackProps {
  return {
    onInit: () => dispatch(initRoleListing()),
    onLeave: () => dispatch(onRoleListingLeft()),
    onRenewDeletedRole: () => dispatch(restoreDeletedRole()),
  };
}

export const RoleListing: React.ComponentType<IOwnProps> = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RoleListingComponent);
