import { Spacing } from '@kontent-ai/component-library/tokens';
import { delay } from '@kontent-ai/utils';
import Tippy from '@tippyjs/react';
import React, { useEffect, useRef, useState } from 'react';
import { ModalDialog } from '../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import { NotificationBarAlert } from '../../../component-library/components/NotificationBar/NotificationBar.tsx';

const queue: Parameters<typeof self.console.error>[] = [];
const originalConsoleError = self.console.error;
const addConsoleErrorToQueue: typeof self.console.error = (...args) => {
  queue.push(args);
};

// Though the component does not get rendered in production this condition needs to be here
// to avoid suppressing error logging.
if (process.env.NODE_ENV !== 'production') {
  self.console.error = addConsoleErrorToQueue;
}

export const PropTypeErrorNotifier: React.FC = () => {
  const bodyRef = useRef(document.body);
  const [showPropTypeErrorNotification, setShowPropTypeErrorNotification] = useState(false);
  const [placement, setPlacement] = useState<'top' | 'bottom'>('top');
  const [showBee, setShowBee] = useState<boolean>(false);

  useEffect(() => {
    const replacementConsoleError: typeof self.console.error = (...args) => {
      originalConsoleError(...args);
      const includesPropTypeError = args.some(
        (e: string | null | undefined) =>
          typeof e === 'string' && e.toLocaleLowerCase().includes('invalid prop'),
      );

      if (includesPropTypeError) {
        delay(0).then(() => setShowPropTypeErrorNotification(true));
      }
    };

    self.console.error = replacementConsoleError;
    queue.forEach((args) => replacementConsoleError(...args));

    return () => {
      self.console.error = originalConsoleError;
    };
  }, []);

  return (
    <Tippy
      appendTo={bodyRef.current}
      placement={placement}
      interactive
      visible={showPropTypeErrorNotification}
      popperOptions={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: ({ popper }: any) => {
                return [0, (popper.height + Spacing.L) * -1];
              },
            },
          },
        ],
      }}
      reference={bodyRef}
      render={(attrs) => {
        return (
          <>
            <NotificationBarAlert
              onDismiss={() => {
                setShowBee(true);
                setPlacement((s) => (s === 'top' ? 'bottom' : 'top'));
              }}
              isFloating
              {...attrs}
              {...{
                onMouseEnter: () => {
                  setPlacement((s) => (s === 'top' ? 'bottom' : 'top'));
                },
              }}
            >
              Prop type validation error. Check the browser console.
            </NotificationBarAlert>
            <ModalDialog isDismissable={false} isOpen={showBee} headline="Fix it.">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube-nocookie.com/embed/fqLzZ-WUrSc?controls=0&autoplay=1&playlist=fqLzZ-WUrSc&loop=1"
                title="Breezy the Bee says Nooo"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media;"
              />
            </ModalDialog>
          </>
        );
      }}
    />
  );
};

PropTypeErrorNotifier.displayName = 'PropTypeErrorNotifier';
