import React, { useCallback, useMemo } from 'react';
import { useEditorWithPlugin } from '../../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginCreator } from '../../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../../editorCore/types/Editor.contract.type.ts';
import { Apply, EditorPlugin } from '../../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../../editorCore/utils/decorable.ts';
import { withDisplayName } from '../../../editorCore/utils/withDisplayName.ts';
import { EntityDecoratorProps } from '../../entityApi/api/editorEntityUtils.ts';
import { DisplayLinksPlugin, GetLinkEntityComponent } from '../DisplayLinksPlugin.tsx';
import { isEmailLink } from '../api/LinkEntity.ts';
import { LinkType } from '../api/LinkType.ts';
import { EmailLink } from './components/EmailLink.tsx';

export type DisplayEmailLinksPlugin = EditorPlugin<None, None, None, [DisplayLinksPlugin]>;

type EmailLinkEntityProps = EntityDecoratorProps & {
  readonly children: ReadonlyArray<React.ReactNode>;
};

const EmailLinkEntity: React.FC<EmailLinkEntityProps> = ({
  contentState,
  entityKey,
  decoratedText,
  children,
}) => {
  const entity = contentState.getEntity(entityKey);
  if (!isEmailLink(entity)) {
    return children;
  }

  const { emailAddress, emailSubject } = entity.getData();

  return (
    <EmailLink
      disabled
      emailAddress={emailAddress}
      emailSubject={emailSubject}
      key={entityKey}
      text={decoratedText}
    >
      {children}
    </EmailLink>
  );
};

EmailLinkEntity.displayName = 'EmailLinkEntity';

export const useDisplayEmailLinks: PluginCreator<DisplayEmailLinksPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('DisplayEmailLinksPlugin', {
        ComposedEditor: (props) => {
          const apply: Apply<DisplayEmailLinksPlugin> = useCallback((state) => {
            const getLinkEntityComponent: Decorator<GetLinkEntityComponent<None>> =
              (baseGetLinkEntityComponent) => (linkType) => {
                switch (linkType) {
                  case LinkType.Email:
                    return {
                      component: EmailLinkEntity,
                      props: {},
                    };

                  default:
                    return baseGetLinkEntityComponent(linkType);
                }
              };

            state.getLinkEntityComponent.decorate(getLinkEntityComponent);

            return {};
          }, []);

          return useEditorWithPlugin(baseEditor, props, { apply });
        },
      }),
    [baseEditor],
  );
