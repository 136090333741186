import { useEffect, useState } from 'react';
import { useThrottledCallback } from 'use-debounce';
import { TextFilter } from '../../../../_shared/components/TextFilter.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { isEmptyOrWhitespace } from '../../../../_shared/utils/stringUtils.ts';
import { nameListingFilterUpdated } from '../actions/subscriptionUserListingActions.ts';

export const SubscriptionUsersNameFilter = () => {
  const dispatch = useDispatch();
  const storedFilterValue = useSelector((s) => s.subscriptionApp.users.listingUi.filter.byName);
  const [filterValue, setFilterValue] = useState<string>(storedFilterValue);

  const storeFilter = useThrottledCallback(
    (searchPhrase: string) => dispatch(nameListingFilterUpdated(searchPhrase)),
    1000,
  );

  useEffect(() => {
    if (isEmptyOrWhitespace(storedFilterValue)) {
      setFilterValue('');
    }
  }, [storedFilterValue]);

  const onUpdateFilter = (searchPhrase: string) => {
    setFilterValue(searchPhrase);
    storeFilter(searchPhrase);
  };

  return (
    <TextFilter
      autofocus
      onChange={onUpdateFilter}
      placeholder="Filter by name or email"
      text={filterValue}
    />
  );
};
