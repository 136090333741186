import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import { CannotViewAssetsMessage } from '../../../../../contentInventory/content/constants/cannotViewMessages.ts';
import { RelatedItemDoesNotExistReason } from '../../../../../itemEditor/constants/errorMessages.ts';

export const NoPermissionsAssetLink: React.FC = () => (
  <div className="bar-item__title-pane utility-message">
    <div className="bar-item__title">{CannotViewAssetsMessage}</div>
  </div>
);
NoPermissionsAssetLink.displayName = 'NoPermissionsAssetLink';

export const NonExistingAssetLink: React.FC = () => (
  <div className="bar-item__title-pane utility-message">
    <div className="bar-item__title">
      <Tooltip placement="bottom" tooltipText={RelatedItemDoesNotExistReason}>
        <span>Linked asset doesn’t exist</span>
      </Tooltip>
    </div>
  </div>
);
NonExistingAssetLink.displayName = 'NonExistingAssetLink';

interface IArchivedAssetLinkProps {
  readonly assetName: string;
}

const archivedPropTypes: PropTypesShape<IArchivedAssetLinkProps> = {
  assetName: PropTypes.string.isRequired,
};

export const ArchivedAssetLink: React.FC<IArchivedAssetLinkProps> = ({ assetName }) => (
  <div className="bar-item__title-pane">
    <span className="bar-item__title-text bar-item__title-text--deleted">{assetName}</span>
    <span className="bar-item__title-label">(Asset deleted)</span>
  </div>
);
ArchivedAssetLink.displayName = 'ArchivedAssetLink';
ArchivedAssetLink.propTypes = archivedPropTypes;

interface IValidAssetLinkProps {
  readonly assetName: string;
  readonly assetPath?: string;
  readonly disabled?: boolean;
  readonly onAssetClick: () => void;
}

const validPropTypes: PropTypesShape<IValidAssetLinkProps> = {
  assetName: PropTypes.string.isRequired,
  assetPath: PropTypes.string,
  disabled: PropTypes.bool,
  onAssetClick: PropTypes.func.isRequired,
};

export const ValidAssetLink: React.FC<IValidAssetLinkProps> = ({
  assetName,
  assetPath,
  disabled,
  onAssetClick,
}) => {
  const onAssetTextClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onAssetClick();
  };

  return (
    <a
      className="link-dialog__detail-link-pane"
      href={assetPath}
      target="_blank"
      rel="noopener noreferrer"
      title={assetName}
      onClick={disabled ? undefined : onAssetTextClick}
    >
      <div className="link-dialog__detail-link">{assetName}</div>
    </a>
  );
};
ValidAssetLink.displayName = 'ValidAssetLink';
ValidAssetLink.propTypes = validPropTypes;
