import { ContentState } from 'draft-js';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import {
  commentThreadFormCanceled,
  commentThreadFormOpened,
  commentThreadFormTextFocusLost,
} from '../../../actions/contentItemEditingActions.ts';
import { saveNewCommentThreadToServer } from '../../../actions/thunkContentItemEditingActions.ts';
import {
  INewCommentThreadCallbackProps,
  INewCommentThreadDataProps,
  NewCommentThread as NewCommentThreadComponent,
} from '../../../components/comments/threadItem/NewCommentThread.tsx';

function mapStateToProps(state: IStore): INewCommentThreadDataProps {
  return {
    isEditing: !!state.contentApp.editedContentItemVariantComments.newCommentThreadForm.isEditing,
    areButtonsDisplayed:
      !!state.contentApp.editedContentItemVariantComments.newCommentThreadForm.isVisible,
    isSubmitting:
      !!state.contentApp.editedContentItemVariantComments.newCommentThreadForm.isSubmitting,
  };
}

function mapDispatchToProps(dispatch: Dispatch): INewCommentThreadCallbackProps {
  return {
    onFocus: () => dispatch(commentThreadFormOpened()),
    onCancel: () => dispatch(commentThreadFormCanceled()),
    onBlur: (isCommentPending: boolean) =>
      dispatch(commentThreadFormTextFocusLost(isCommentPending)),
    onSubmit: (commentContent: ContentState) =>
      dispatch(saveNewCommentThreadToServer(commentContent)),
  };
}

export const NewCommentThread = connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(NewCommentThreadComponent);
