import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import React from 'react';
import {
  ActionsPlacement,
  PendingInvitationActions,
} from '../../../../../_shared/components/PendingInvitationActions.tsx';
import { Button } from '../../../../../_shared/uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import { DataUiAction } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IProjectContributor } from '../../../../../data/models/users/ProjectContributor.ts';
import { UserDetailActivationButton } from './UserDetailActivationButton.tsx';

type FooterRightProps = {
  readonly hasUnsavedChanges: boolean;
  readonly isSaving: boolean;
  readonly onClose: () => void;
  readonly onUpdateUserRoles: () => void;
  readonly saveDisabledTooltip: string | undefined;
};

export const UserDetailFooterRight: React.FC<FooterRightProps> = ({
  hasUnsavedChanges,
  isSaving,
  onClose,
  onUpdateUserRoles,
  saveDisabledTooltip,
}) => (
  <>
    <Button style={ButtonStyle.Secondary} onClick={onClose} dataUiAction={DataUiAction.CloseDialog}>
      Cancel
    </Button>
    <Tooltip tooltipText={saveDisabledTooltip} placement="top-end">
      <Button
        dataUiAction={DataUiAction.Save}
        disabled={!!saveDisabledTooltip}
        hasLoader={isSaving}
        onClick={hasUnsavedChanges ? onUpdateUserRoles : onClose}
        style={ButtonStyle.Primary}
      >
        Save
      </Button>
    </Tooltip>
  </>
);
UserDetailFooterRight.displayName = 'UserDetailFooterRight';

type FooterLeftProps = {
  readonly user: IProjectContributor;
  readonly onResendInvite: () => void;
  readonly onRevokeInvite: () => void;
};

export const UserDetailFooterLeft: React.FC<FooterLeftProps> = ({
  user,
  onResendInvite,
  onRevokeInvite,
}) =>
  user.isInvitationPending ? (
    <PendingInvitationActions
      isAdminOnProject={user.isAdminOnProject}
      isInviteRevoking={user.isInviteRevoking}
      isInviteSending={user.isInviteSending}
      isInviteSent={user.isInviteSent}
      onResendInvite={onResendInvite}
      onRevokeInvite={onRevokeInvite}
      placement={ActionsPlacement.UserDetail}
    />
  ) : (
    <UserDetailActivationButton user={user} />
  );

UserDetailFooterLeft.displayName = 'UserDetailFooterLeft';
