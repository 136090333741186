import PropTypes from 'prop-types';
import React from 'react';
import { HorizontalTabs } from '../../../../../component-library/components/StatusBar/HorizontalTabs/HorizontalTabs.tsx';
import { LandmarkAriaLabels } from '../../../../_shared/constants/landmarkAriaLabels.ts';
import {
  DataUiCollection,
  DataUiElement,
  ObjectWithDataAttribute,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type ContentInventoryTab = {
  readonly id: string;
  readonly label: string;
  readonly path: string;
  readonly dataUiAttributes: ObjectWithDataAttribute;
};

type ContentInventoryTabsProps = {
  readonly containerRef: React.RefObject<HTMLDivElement>;
  readonly tabsStructure: readonly ContentInventoryTab[];
};

const propTypes: PropTypesShape<ContentInventoryTabsProps> = {
  containerRef: PropTypes.shape({ current: PropTypes.object }).isRequired,
  tabsStructure: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      dataUiAttributes: PropTypes.object.isRequired,
    }),
  ).isRequired,
};

export const ContentInventoryTabs: React.FC<ContentInventoryTabsProps> = ({
  containerRef,
  tabsStructure,
}) => (
  <HorizontalTabs
    ariaLabel={LandmarkAriaLabels.ContentAndAssets}
    items={tabsStructure}
    containerRef={containerRef}
    getItemProps={(tab) => ({
      label: tab.label,
      pathname: tab.path,
      ...getDataUiElementAttribute(DataUiElement.NavigationTab),
      ...tab.dataUiAttributes,
    })}
    {...getDataUiCollectionAttribute(DataUiCollection.TabItems)}
  />
);

ContentInventoryTabs.displayName = 'ContentInventoryTabs';
ContentInventoryTabs.propTypes = propTypes;
