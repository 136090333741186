import { memoize } from '@kontent-ai/memoization';
import React, { RefObject } from 'react';
import { AppNames } from '../../../_shared/constants/applicationNames.ts';
import {
  CalendarRoute,
  HomeRouteParams,
  ProjectOverviewRoute,
  QuickstartRoute,
  YourContentRoute,
} from '../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { getSelectedLanguageIdOrRouteMacro } from '../../../_shared/selectors/getSelectedLanguageId.ts';
import {
  DataUiAppName,
  ObjectWithDataAttribute,
  getDataUiObjectNameAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { Capability, getUserCapability } from '../../../_shared/utils/permissions/capability.ts';
import { buildPath } from '../../../_shared/utils/routing/routeTransitionUtils.ts';
import { projectHasQuickstart } from '../../../_shared/utils/trialUtils.ts';
import { IUserProjectInfo } from '../../../data/models/user/UserProjectInfo.ts';
import { getCurrentProject } from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import {
  contentInventoryRequiredCapabilities,
  contentRequiredCapabilities,
} from '../../contentInventory/shared/utils/contentInventoryRequiredCapabilities.ts';
import { HomeTabs as HomeTabsComponent, HomeTabsStructure } from '../components/HomeTabs.tsx';

const defaultHomeTabsStructure: readonly HomeTabsStructureConfig[] = [
  {
    text: AppNames.Quickstart,
    route: QuickstartRoute,
    requiredCapabilities: contentInventoryRequiredCapabilities,
    dataUiAttributes: getDataUiObjectNameAttribute(DataUiAppName.Quickstart),
  },
  {
    text: AppNames.YourContent,
    route: YourContentRoute,
    requiredCapabilities: contentRequiredCapabilities,
    dataUiAttributes: getDataUiObjectNameAttribute(DataUiAppName.YourContent),
  },
  {
    text: AppNames.ProjectOverview,
    route: ProjectOverviewRoute,
    requiredCapabilities: contentRequiredCapabilities,
    dataUiAttributes: getDataUiObjectNameAttribute(DataUiAppName.ProjectOverview),
  },
  {
    text: AppNames.Calendar,
    route: CalendarRoute,
    requiredCapabilities: contentRequiredCapabilities,
    dataUiAttributes: getDataUiObjectNameAttribute(DataUiAppName.Calendar),
  },
];

type HomeTabsStructureConfig = {
  readonly text: string;
  readonly route: string;
  readonly dataUiAttributes: ObjectWithDataAttribute;
  readonly requiredCapabilities: ReadonlyArray<Capability>;
};

const getTabsStructure = memoize.maxOne(
  (
    currentProject: IUserProjectInfo,
    selectedLanguageIdOrMacro: Uuid,
    shouldShowQuickStart: boolean,
  ): readonly HomeTabsStructure[] => {
    const { projectId } = currentProject;
    const capabilities = getUserCapability(currentProject);
    const tabsStructure = defaultHomeTabsStructure.filter((tab) => {
      if (!shouldShowQuickStart && tab.text === AppNames.Quickstart) {
        return false;
      }

      return true;
    });

    return tabsStructure
      .filter((tab) => capabilities.canOneOf(tab.requiredCapabilities))
      .map(({ requiredCapabilities, route, text, ...other }) => {
        const path = buildPath<HomeRouteParams>(route, {
          projectId,
          variantId: selectedLanguageIdOrMacro,
        });
        return {
          ...other,
          id: path,
          label: text,
          path,
        };
      });
  },
);

type HomeTabsProps = {
  readonly containerRef: RefObject<HTMLElement>;
};

export const HomeTabs: React.FC<HomeTabsProps> = ({ containerRef }) => {
  const tabsStructure = useSelector((s) => {
    const currentProject = getCurrentProject(s);
    const shouldShowQuickStart = projectHasQuickstart(s, currentProject);
    const selectedLanguageIdWithMacroFallback = getSelectedLanguageIdOrRouteMacro(s);

    return getTabsStructure(
      currentProject,
      selectedLanguageIdWithMacroFallback,
      shouldShowQuickStart,
    );
  });

  return <HomeTabsComponent containerRef={containerRef} tabsStructure={tabsStructure} />;
};

HomeTabs.displayName = 'HomeTabs';
