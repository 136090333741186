import { Box } from '@kontent-ai/component-library/Box';
import { Spacing, Typography, colorTextDefault } from '@kontent-ai/component-library/tokens';
import PropTypes from 'prop-types';
import React, { ReactNode } from 'react';

interface ISectionTitleProps {
  readonly children: ReactNode;
  readonly renderTrailingElement?: () => React.ReactNode;
}

const propTypes: PropTypeMap<ISectionTitleProps> = {
  children: PropTypes.node.isRequired,
  renderTrailingElement: PropTypes.func,
};

export const SectionTitle: React.FC<ISectionTitleProps> = (props) => (
  <Box display="flex" alignItems="center" marginBottom={Spacing.S}>
    <Box component="h2" marginY={0} typography={Typography.HeadlineMedium} color={colorTextDefault}>
      {props.children}
    </Box>
    {props.renderTrailingElement?.()}
  </Box>
);

SectionTitle.displayName = 'SectionTitle';
SectionTitle.propTypes = propTypes;
