import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { isInlineAiEnabled } from '../../../../_shared/selectors/aiSelectors.ts';
import { InlineInstructionActionName } from '../../../../repositories/serverModels/ai/actions/AiServerModels.inlineInstruction.ts';
import { usePluginWithParams } from '../../editorCore/Editor.composition.tsx';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { useAiStyles } from '../ai/AiStylesPlugin.tsx';
import { useInlineAi } from './InlineAiPlugin.tsx';

export const useInlineAiPlugins = (
  actionName: InlineInstructionActionName,
): ReadonlyArray<PluginCreator> => {
  const activePlugin = usePluginWithParams(useInlineAi, actionName);

  return useSelector(isInlineAiEnabled) ? [useAiStyles, activePlugin] : [];
};
