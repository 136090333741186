import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from '../../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../../_shared/hooks/useSelector.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import {
  DataUiWorkflowAction,
  ItemEditingForms,
} from '../../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { abandonAssignmentSectionChange } from '../../../../actions/contentItemEditingActions.ts';
import {
  changeDueDate,
  updateDueDateInSidebar,
} from '../../../../actions/thunkContentItemEditingActions.ts';
import { SectionFormWrapper } from '../../../../components/details/SectionFormWrapper.tsx';
import { SectionLink } from '../../../../components/details/SectionLink.tsx';
import { NonClickableSection } from '../../../../components/sidebar/sectionTypes/NonClickableSection.tsx';
import { AssignmentSections } from '../../../../constants/AssignmentSections.ts';
import { IDueDateFormShape } from '../../../../models/IDueDateFormShape.type.ts';
import { getDueDateState } from '../../../selectors/getDueDateState.ts';
import { DueDateFormBase } from '../DueDateFormBase.tsx';

export const NonClickableDueDateSection: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const onCancel = () => dispatch(abandonAssignmentSectionChange(AssignmentSections.DueDate));
  const onClick = () => dispatch(changeDueDate(ContentItemEditingEventOrigins.ItemDetails));
  const onConfirm = (values: IDueDateFormShape) =>
    dispatch(updateDueDateInSidebar(history, values.date.value));

  const { initialDueDate, isEditing } = useSelector(getDueDateState);

  return (
    <NonClickableSection dataUiNameAttribute={ItemEditingForms.DueDate}>
      {isEditing ? (
        <SectionFormWrapper>
          <DueDateFormBase
            initialDueDate={initialDueDate}
            onCancel={onCancel}
            onConfirm={onConfirm}
          />
        </SectionFormWrapper>
      ) : (
        <SectionLink
          dataUiAttribute={DataUiWorkflowAction.EditDueDate}
          onEditClick={onClick}
          text="Set due date"
        />
      )}
    </NonClickableSection>
  );
};

NonClickableDueDateSection.displayName = 'NonClickableDueDateSection';
