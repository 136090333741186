import { Column, Row } from '@kontent-ai/component-library/Row';
import {
  BorderRadius,
  colorPrimary,
  px,
  sizeBarItemBar,
} from '@kontent-ai/component-library/tokens';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { forwardedRefProps } from '../../../../../_shared/utils/forwardedRefProps.tsx';
import { EditorLinkStatus } from '../../../../richText/plugins/apiLimitations/api/EditorLinkStatus.ts';
import { LinkDetailActions } from './LinkDetailActions.tsx';

const UploadProgressWrapper = styled.div`
  position: absolute;
  top: 0;
  z-index: @z-index-one;
  width: 100%;
  height: ${px(BorderRadius.M)};
  border-radius: ${px(BorderRadius.M)} ${px(BorderRadius.M)} 0 0;
  overflow: hidden;
  background: transparent;
`;

UploadProgressWrapper.displayName = 'UploadProgress';

interface IUploadProgressLineProps {
  readonly $width?: number;
}

const UploadProgressLine = styled.div.attrs<IUploadProgressLineProps>(({ $width }) => ({
  style: {
    width: `${$width ?? 100}%`,
  },
}))<IUploadProgressLineProps>`
  height: 2px;
  background: ${colorPrimary};
`;

UploadProgressLine.displayName = 'UploadProgressLine';

interface ILinkDetailDataProps {
  readonly disabled?: boolean;
  readonly forwardedRef?: React.Ref<HTMLDivElement>;
  readonly isUploading?: boolean;
  readonly status?: EditorLinkStatus;
  readonly uploadedSoFar?: number;
}

interface ILinkDetailCallbackProps {
  readonly onEdit?: () => void;
  readonly onUnlink?: () => void;
}

type LinkDetailProps = ILinkDetailCallbackProps & ILinkDetailDataProps;

const propTypes: PropTypesShape<LinkDetailProps> = {
  ...forwardedRefProps,
  disabled: PropTypes.bool,
  isUploading: PropTypes.bool,
  onEdit: PropTypes.func,
  onUnlink: PropTypes.func,
  status: PropTypes.string,
  uploadedSoFar: PropTypes.number,
};

export const LinkDetail: React.FC<React.PropsWithChildren<LinkDetailProps>> = ({
  children,
  disabled,
  forwardedRef,
  isUploading,
  uploadedSoFar,
  status,
  onEdit,
  onUnlink,
}) => {
  const isDisabled = disabled || status === EditorLinkStatus.InNotAllowedText;
  return (
    <div
      ref={forwardedRef}
      className="rte__link-dialog link-dialog"
      contentEditable={false}
      {...getDataUiElementAttribute(DataUiElement.RteLinkDetail)}
    >
      <div className="link-dialog__detail">
        {isUploading && (
          <UploadProgressWrapper>
            <UploadProgressLine $width={uploadedSoFar} />
          </UploadProgressWrapper>
        )}
        <Row alignY="center" noWrap css={`min-height: ${px(sizeBarItemBar)}`}>
          <Column>{children}</Column>
          {!isDisabled && (
            <Column width="content">
              <LinkDetailActions
                isEditable={status === EditorLinkStatus.Allowed}
                onEdit={onEdit}
                onUnlink={onUnlink}
              />
            </Column>
          )}
        </Row>
      </div>
    </div>
  );
};

LinkDetail.displayName = 'LinkDetail';
LinkDetail.propTypes = propTypes;
