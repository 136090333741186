import { Button } from '@kontent-ai/component-library/Button';
import React, { ReactNode, useState } from 'react';
import { NotificationBarWarning } from '../../../../component-library/components/NotificationBar/NotificationBar.tsx';
import { AvailableSubscriptionAdmins } from '../../containers/infos/AvailableSubscriptionAdmins.tsx';

type Props = {
  readonly action?: ReactNode;
  readonly description: string;
  readonly isSubscriptionAdmin: boolean;
  readonly onClose: () => void;
};

export const SubscriptionWarning: React.FC<Props> = ({
  action,
  description,
  isSubscriptionAdmin,
  onClose,
}) => {
  const [showAdminsDialog, setShowAdminsDialog] = useState(false);

  if (!description) {
    return null;
  }

  const notificationAction = () =>
    action && !isSubscriptionAdmin ? (
      <Button buttonStyle="primary-inverse" size="small" onClick={() => setShowAdminsDialog(true)}>
        View admin list
      </Button>
    ) : (
      action
    );

  return (
    <>
      <NotificationBarWarning
        actions={{
          renderActions: notificationAction,
          alignment: 'left',
        }}
        onDismiss={onClose}
        // This is temporary solution because the notification bar is placed above status bar.
        css="border-radius: 0"
      >
        {description}
      </NotificationBarWarning>
      {showAdminsDialog && (
        <AvailableSubscriptionAdmins onClose={() => setShowAdminsDialog(false)} />
      )}
    </>
  );
};

SubscriptionWarning.displayName = 'SubscriptionWarning';
