import { memoize } from '@kontent-ai/memoization';
import { ICollection } from '../../data/models/collections/Collection.ts';
import { allCollectionsOption } from '../constants/userListingFilter.ts';
import {
  ICollectionFilterOption,
  ILanguageFilterOption,
  IRoleFilterOption,
} from '../models/UserListingFilterOption.type.ts';

export const getSelectedLanguagesFilterOptions = memoize.maxOne(
  (
    languages: ReadonlyArray<ILanguageFilterOption>,
    selectedIds: ReadonlySet<Uuid>,
  ): ReadonlyArray<ILanguageFilterOption> =>
    languages.filter((language: ILanguageFilterOption) => selectedIds.has(language.id)),
);

export const getSelectedRolesFilterOptions = memoize.maxOne(
  (
    roles: ReadonlyArray<IRoleFilterOption>,
    selectedIds: ReadonlySet<Uuid>,
  ): ReadonlyArray<IRoleFilterOption> => roles.filter((role) => selectedIds.has(role.id)),
);

export const getSelectedCollectionsFilterOptions = memoize.maxOne(
  (
    collections: ReadonlyArray<ICollectionFilterOption>,
    selectedIds: ReadonlySet<Uuid>,
  ): ReadonlyArray<ICollectionFilterOption> =>
    collections.filter((collection: ICollectionFilterOption) => selectedIds.has(collection.id)),
);

export const getCollectionsFilterOptions = memoize.maxOne(
  (collections: ReadonlyArray<ICollection>): ReadonlyArray<ICollectionFilterOption> => [
    allCollectionsOption,
    ...collections.map(({ id, name }) => ({
      id,
      label: name,
    })),
  ],
);
