import React from 'react';
import { Button, ButtonSize } from '../../../../../_shared/uiComponents/Button/Button.tsx';
import { ButtonStyle } from '../../../../../_shared/uiComponents/Button/buttonStyle.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface ICopyingDraftDataActionButtonProps {
  readonly text: string;
}

export const CopyingDraftDataActionButton: React.FC<ICopyingDraftDataActionButtonProps> = ({
  text,
}) => (
  <div className="my-project__button-wrapper">
    <Button
      style={ButtonStyle.Secondary}
      size={ButtonSize.Block}
      disabled
      hasLoader
      {...getDataUiElementAttribute(DataUiElement.ProjectCopyStatus)}
    >
      {text}
    </Button>
  </div>
);

CopyingDraftDataActionButton.displayName = 'CopyingDraftDataActionButton';
