import PropTypes from 'prop-types';
import React from 'react';
import {
  renderDatetimeString,
  toFuzzyDateDifference,
  toLocalTime,
} from '../utils/dateTime/timeUtils.ts';

interface ILastUpdatedOwnProps {
  readonly time: DateTimeStamp | null;
}

export const LastUpdatedAt: React.FC<ILastUpdatedOwnProps> = ({ time }) => {
  const local = toLocalTime(time);
  const title = renderDatetimeString(time) || '';

  if (!local) {
    return null;
  }

  return <span title={title}>{toFuzzyDateDifference(new Date(), local)}</span>;
};

const propTypes: PropTypesShape<ILastUpdatedOwnProps> = {
  time: PropTypes.string.isRequired,
};

LastUpdatedAt.displayName = 'LastUpdatedAt';
LastUpdatedAt.propTypes = propTypes;
