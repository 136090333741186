import { Icons } from '@kontent-ai/component-library/Icons';
import {
  SimpleStatusDefault,
  SimpleStatusError,
  SimpleStatusSuccess,
} from '@kontent-ai/component-library/SimpleStatus';
import PropTypes from 'prop-types';
import React from 'react';
import {
  WebhookDisabledDisplayName,
  WebhookHealthStatus,
  WebhookHealthStatusDisplayNames,
} from '../../constants/webhookHealthStatuses.ts';

export interface IWebhookStatusProps {
  readonly isTriggerConfigurationValid: boolean;
  readonly isEnabled: boolean;
  readonly hasLabel?: boolean;
  readonly healthStatus: WebhookHealthStatus;
}

export const WebhookStatus: React.FC<IWebhookStatusProps> = ({
  hasLabel,
  healthStatus,
  isTriggerConfigurationValid,
  isEnabled,
}) => {
  const displayName = isEnabled
    ? WebhookHealthStatusDisplayNames[healthStatus]
    : WebhookDisabledDisplayName;
  const tooltipText = hasLabel ? '' : displayName;
  const labelText = hasLabel ? displayName : '';

  if (!isTriggerConfigurationValid) {
    return <SimpleStatusError label={labelText} tooltipText={tooltipText} />;
  }

  if (!isEnabled) {
    return <SimpleStatusDefault label={labelText} icon={Icons.Drawers} tooltipText={tooltipText} />;
  }

  switch (healthStatus) {
    case WebhookHealthStatus.Dead: {
      return (
        <SimpleStatusDefault label={labelText} icon={Icons.TimesCircle} tooltipText={tooltipText} />
      );
    }
    case WebhookHealthStatus.Failing:
      return <SimpleStatusError label={labelText} tooltipText={tooltipText} />;

    case WebhookHealthStatus.Working:
      return (
        <SimpleStatusSuccess label={labelText} icon={Icons.CbCheck} tooltipText={tooltipText} />
      );

    default:
      return (
        <SimpleStatusDefault
          label={labelText}
          icon={Icons.QuestionCircle}
          tooltipText={tooltipText}
        />
      );
  }
};
WebhookStatus.displayName = 'WebhookStatus';

const propTypes: PropTypesShape<IWebhookStatusProps> = {
  isTriggerConfigurationValid: PropTypes.bool.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  hasLabel: PropTypes.bool,
  healthStatus: PropTypes.string.isRequired,
};
WebhookStatus.propTypes = propTypes;
