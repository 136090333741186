import { ScrollElementsToViewOptions } from '@kontent-ai/DOM';
import PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';
import { ScrollExecutedCallback } from '../../../../../../_shared/hooks/useAutoScroll.ts';
import { IRichTextTypeElement } from '../../../../../contentInventory/content/models/contentTypeElements/RichTextTypeElement.ts';
import { IFocusable } from '../../../../../richText/plugins/behavior/FocusPlugin.tsx';
import { EditorChangeCallback } from '../../../../../richText/plugins/behavior/OnChangePlugin.tsx';
import {
  IRichTextItemElement,
  RichTextItemElementPropTypesShape,
} from '../../../../models/contentItemElements/RichTextItemElement.ts';
import { deferredScrollToRteTarget } from '../../../../utils/customScrollTargetUtils.ts';
import { useItemVariantId } from '../../../ContentComponent/context/ContentItemContext.tsx';
import { ItemElement } from '../../containers/elements/ItemElement.tsx';
import { ItemElementRefresher } from '../../containers/elements/ItemElementRefresher.tsx';
import { ContentItemRichTextInput } from '../../containers/elements/richString/ContentItemRichTextInput.tsx';
import { RichTextItemElementContextProvider } from '../../context/RichTextItemElementContext.tsx';
import { IItemElementComponentOwnProps } from './IItemElementOwnProps.type.ts';

export type RichStringProps = IItemElementComponentOwnProps<
  IRichTextItemElement,
  IRichTextTypeElement
> & {
  readonly onChange: EditorChangeCallback;
};

const propTypes: PropTypesShape<RichStringProps> = {
  autoFocus: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  elementData: RichTextItemElementPropTypesShape.isRequired,
  onChange: PropTypes.func.isRequired,
  typeElement: PropTypes.object.isRequired,
};

export const RichString: React.FC<RichStringProps> = ({
  autoFocus,
  disabled,
  elementData,
  onChange,
  typeElement,
}) => {
  const itemElementRef = useRef<HTMLDivElement>(null);
  const contentItemRichTextInputRef = useRef<IFocusable>(null);
  const contentItemIdentifier = useItemVariantId();

  const focusItemElement = useCallback((): void => {
    itemElementRef.current?.focus();
  }, []);

  const focusRichTextInput = useCallback((): void => {
    contentItemRichTextInputRef.current?.focus();
  }, []);

  const focusRichTextInputAtTheStart = useCallback(() => {
    contentItemRichTextInputRef.current?.focusAtTheStart();
  }, []);

  const onCustomScrollToTarget = useCallback(
    (
      targetId: string,
      scrollOptions: ScrollElementsToViewOptions,
      onScrollExecuted: ScrollExecutedCallback,
    ) => {
      return deferredScrollToRteTarget(
        targetId,
        contentItemIdentifier,
        elementData,
        scrollOptions,
        undefined,
        undefined,
        onScrollExecuted,
      );
    },
    [contentItemIdentifier, elementData],
  );

  return (
    <RichTextItemElementContextProvider rootRichTextElementId={typeElement.elementId}>
      <ItemElement
        defaultTabIndex
        disabled={disabled}
        escapeHandledByEditor
        focusEditor={focusRichTextInput}
        onContentClick={focusRichTextInput}
        onCustomScrollToTarget={onCustomScrollToTarget}
        onHeaderClick={focusRichTextInputAtTheStart}
        ref={itemElementRef}
        typeElement={typeElement}
      >
        <ItemElementRefresher
          elementData={elementData}
          renderInput={(refresherItemElement: IRichTextItemElement) => (
            <ContentItemRichTextInput
              autoFocus={autoFocus}
              className="rte--in-content-item-element"
              disabled={disabled}
              editedEntityName={`${typeElement.name} (Rich text element)`}
              editorState={refresherItemElement._editorState}
              onContentChange={onChange}
              onEscape={focusItemElement}
              ref={contentItemRichTextInputRef}
              typeElement={typeElement}
            />
          )}
        />
      </ItemElement>
    </RichTextItemElementContextProvider>
  );
};

RichString.displayName = 'RichString';
RichString.propTypes = propTypes;
