import classNames from 'classnames';
import React from 'react';
import { ObjectWithDataAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';

interface ITabMenuProps {
  readonly children?: React.ReactNode;
  readonly className?: string;
  readonly dataUiAttributes?: ObjectWithDataAttribute;
  readonly ariaLabel?: string;
}

export const TabMenu: React.FC<ITabMenuProps> = ({
  children,
  dataUiAttributes,
  className,
  ariaLabel,
}) => (
  <div className={classNames('tabbed-navigation', className)} {...dataUiAttributes}>
    <nav aria-label={ariaLabel} className="tabbed-navigation__tabs">
      {children}
    </nav>
  </div>
);

TabMenu.displayName = 'TabMenu';
