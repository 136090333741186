import { InvariantException } from '@kontent-ai/errors';
import { Key } from '@react-types/shared';
import { Property } from 'csstype';
import React, { RefAttributes } from 'react';
import { Divider } from '../../../../client/component-library/components/Dividers/Divider.tsx';
import { DividerDirection } from '../../../../client/component-library/components/Dividers/components/StyledDivider.tsx';
import { Paper, PaperLevel, PaperProps } from '../../containers/Paper/Paper.tsx';
import { Box } from '../../layout/Box/Box.tsx';
import { Stack } from '../../layout/Stack/Stack.tsx';
import { colorTextDefault } from '../../tokens/decision/colors.ts';
import { BaseColor } from '../../tokens/quarks/colors.ts';
import { Spacing } from '../../tokens/quarks/spacing.ts';
import { getDataUiComponentAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { Item } from './components/Item.tsx';
import { ScrollableContainer } from './components/ScrollableContainer.tsx';
import { Section } from './components/Section.tsx';
import {
  verticalMenuMaxHeight,
  verticalMenuMaxWidth,
  verticalMenuMinWidth,
} from './decisionTokens.ts';
import { IItemProps, VerticalMenuItem } from './types.ts';
import { VerticalMenuState } from './useNonAccessibleVerticalMenu.ts';
import { getDataUiAttributeWithSectionName, isItemNode, isSectionNode } from './utils/utils.tsx';

export interface VerticalMenuFrameProps<TItem extends VerticalMenuItem<TItem>>
  extends Pick<PaperProps, 'minWidth' | 'maxWidth' | 'width'> {
  readonly maxHeight?: Property.MaxHeight<number | string>;
  readonly pinnedItemId?: Key;
  readonly renderItem: (itemProps: IItemProps<TItem>) => React.ReactNode;
  readonly state: VerticalMenuState<TItem>;
}

const VerticalMenuFrameComponent = React.forwardRef(
  <TItem extends VerticalMenuItem<TItem>>(
    {
      maxHeight = verticalMenuMaxHeight,
      maxWidth = verticalMenuMaxWidth,
      minWidth = verticalMenuMinWidth,
      width,
      pinnedItemId,
      renderItem,
      state,
      ...otherProps
    }: VerticalMenuFrameProps<TItem>,
    forwardedRef: React.Ref<HTMLDivElement>,
  ) => {
    const pinnedItem = pinnedItemId ? state.collection.getItem(pinnedItemId) : undefined;

    const commonItemProps = {
      expandedKeys: state.expandedKeys,
      pinnedItemId,
      renderItem,
      state,
    };

    return (
      <Paper
        ref={forwardedRef}
        display="flex"
        flexDirection="column"
        color={colorTextDefault}
        level={PaperLevel.Elevated}
        overflow="hidden"
        maxHeight={maxHeight}
        minWidth={minWidth}
        maxWidth={maxWidth}
        width={width}
        {...otherProps}
        {...getDataUiComponentAttribute(VerticalMenuFrameComponent)}
      >
        <ScrollableContainer>
          <Stack>
            {[...state.collection].map((sectionItem, i) => {
              if (isSectionNode(sectionItem)) {
                const items = sectionItem.hasChildNodes ? Array.from(sectionItem.childNodes) : [];

                return [
                  <Section
                    key={sectionItem.key}
                    isFirst={i === 0}
                    item={sectionItem}
                    {...commonItemProps}
                  />,
                  ...items.map(
                    (item) =>
                      isItemNode(item) && (
                        <Item
                          key={item.key}
                          {...getDataUiAttributeWithSectionName(item, state)}
                          {...commonItemProps}
                          item={item}
                          state={state}
                        />
                      ),
                  ),
                ];
              }
              if (isItemNode(sectionItem)) {
                return <Item key={sectionItem.key} item={sectionItem} {...commonItemProps} />;
              }

              throw InvariantException(`Unknown Node type '${sectionItem.type}'.`);
            })}
          </Stack>
        </ScrollableContainer>
        {pinnedItem && isItemNode(pinnedItem) && (
          <Box paddingBottom={Spacing.S}>
            <Divider
              offsetAfter={Spacing.S}
              offsetBefore={Spacing.None}
              color={BaseColor.Gray20}
              direction={DividerDirection.Horizontal}
            />
            {renderItem({
              item: pinnedItem,
              level: 0,
              state,
            })}
          </Box>
        )}
      </Paper>
    );
  },
);

VerticalMenuFrameComponent.displayName = 'VerticalMenuFrameComponent';

// forwardRef doesn't support generic parameters, so we cast the components to the correct type
// https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref
export const VerticalMenuFrame = VerticalMenuFrameComponent as (<
  TItem extends VerticalMenuItem<TItem>,
>(
  props: VerticalMenuFrameProps<TItem> & RefAttributes<HTMLDivElement>,
) => ReturnType<React.FC<VerticalMenuFrameProps<TItem>>>) &
  React.FC;
