import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { roleSettingsChanged } from '../actions/rolesActions.ts';
import { ContentProductionCapabilitiesConfigurationCard as ContentProductionCapabilitiesConfigurationCardComponent } from '../components/configurator/cards/ContentProductionCard/ContentProductionCapabilitiesConfigurationCard.tsx';
import { isRoleEditingDisabled } from '../utils/roleEditorStatusUtils.ts';

type Props = {
  readonly projectId: Uuid;
};

export const ContentProductionCapabilitiesConfigurationCard: React.FC<Props> = ({ projectId }) => {
  const dispatch = useDispatch();

  const isDisabled = useSelector(isRoleEditingDisabled);

  const allTypes = useSelector((state) => state.data.contentTypes.byId);
  const roleSettings = useSelector((state) => state.rolesApp.editorUi.editedRole.settings);
  const status = useSelector((state) => state.rolesApp.editorUi.status);

  return (
    <ContentProductionCapabilitiesConfigurationCardComponent
      allTypes={allTypes}
      isDisabled={isDisabled}
      onSettingsChanged={(newSettings) => dispatch(roleSettingsChanged(newSettings))}
      projectId={projectId}
      roleSettings={roleSettings}
      status={status}
    />
  );
};

const propTypes: PropTypeMap<Props> = {
  projectId: PropTypes.string.isRequired,
};

ContentProductionCapabilitiesConfigurationCard.propTypes = propTypes;
ContentProductionCapabilitiesConfigurationCard.displayName =
  'ContentProductionCapabilitiesConfigurationCard';
