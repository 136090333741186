import PropTypes, { ValidationMap } from 'prop-types';
import { PropTypeOrNull } from '../../../../_shared/utils/propTypesValidators.ts';
import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  CompiledContentItemElementDataValidationMap,
  ICompiledContentItemElementData,
} from './ICompiledContentItemElement.ts';

interface ICustomItemElementValue {
  readonly value: string | null;
  readonly searchableValue: string | null;
}

export interface ICustomItemElement
  extends ICustomItemElementValue,
    ICompiledContentItemElementData {}

export const EmptyCustomItemElementValue: ICustomItemElementValue = {
  searchableValue: null,
  value: null,
};

export const EmptyCustomItemElement: ICustomItemElement = {
  elementId: '',
  lastModifiedAt: null,
  lastModifiedBy: null,
  type: ElementType.Custom,
  ...EmptyCustomItemElementValue,
};

export const newCustomItemElement = (params: Partial<ICustomItemElement>): ICustomItemElement => ({
  ...EmptyCustomItemElement,
  ...params,
});

export const CustomItemElementPropTypes = PropTypes.exact<ValidationMap<ICustomItemElement>>({
  ...CompiledContentItemElementDataValidationMap,
  value: PropTypeOrNull(PropTypes.string),
  searchableValue: PropTypeOrNull(PropTypes.string),
});
