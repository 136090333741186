import { Inline } from '@kontent-ai/component-library/Inline';
import classNames from 'classnames';
import React from 'react';
import { IStatusInfoMessage } from '../../../applications/contentInventory/assets/models/IStatusInfoMessage.type.ts';
import { StatusMessageStyle } from '../../../applications/contentInventory/content/reducers/listingUi/statusInfo/selectors/statusMessageStyle.ts';
import { PageTitle } from '../../components/PageTitle.tsx';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { Button, ButtonSize } from '../Button/Button.tsx';
import { ButtonStyle } from '../Button/buttonStyle.ts';

export interface IListingMessageStateProps {
  readonly statusInfoMessage: IStatusInfoMessage;
  readonly undoButtonText?: string;
}

export interface IListingMessageDispatchProps {
  readonly onUndo?: null | (() => void);
}

export interface IListingMessageOwnProps {
  readonly children?: React.ReactNode;
}

export type IListingMessageProps = IListingMessageStateProps &
  IListingMessageDispatchProps &
  IListingMessageOwnProps;

export const ListingMessage: React.FC<React.PropsWithChildren<IListingMessageProps>> = ({
  children,
  statusInfoMessage,
  onUndo,
  undoButtonText,
}) => {
  const trailingElements = (
    <>
      {onUndo && (
        <div className="listing-message__undo">
          <Button
            style={ButtonStyle.Tertiary}
            size={ButtonSize.S}
            onClick={onUndo}
            {...getDataUiActionAttribute(DataUiAction.Undo)}
          >
            {undoButtonText || 'Undo'}
          </Button>
        </div>
      )}
      {children}
    </>
  );

  return statusInfoMessage.style === StatusMessageStyle.Headline ? (
    <Inline align="center">
      <PageTitle {...getDataUiElementAttribute(DataUiElement.ListingMessageTitle)}>
        {statusInfoMessage.text}
      </PageTitle>
      {trailingElements}
    </Inline>
  ) : (
    <div className="listing-message" {...getDataUiElementAttribute(DataUiElement.ListingMessage)}>
      <div
        className={classNames(
          'listing-message__title',
          `listing-message__title--is-${statusInfoMessage.style}`,
        )}
        {...getDataUiElementAttribute(DataUiElement.ListingMessageTitle)}
      >
        {statusInfoMessage.text}
      </div>
      {trailingElements}
    </div>
  );
};

ListingMessage.displayName = 'ListingMessage';
