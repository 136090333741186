import Immutable from 'immutable';
import ImmutablePropTypes from 'immutable-prop-types';
import PropTypes, { ValidationMap } from 'prop-types';
import { ActiveCapabilityType } from '../../../_shared/models/activeCapability.type.ts';
import { PropTypeOrNull } from '../../../_shared/utils/propTypesValidators.ts';
import { AssetReference } from '../../../applications/itemEditor/models/contentItemElements/AssetItemElement.ts';
import { ICompiledContentItemElementData } from '../../../applications/itemEditor/models/contentItemElements/ICompiledContentItemElement.ts';
import { IAssetReferenceServerModel } from '../../../repositories/serverModels/elementServerModels.type.ts';

const PropTypeStringOrNull = PropTypeOrNull(PropTypes.string);
const PropTypeNumberOrNull = PropTypeOrNull(PropTypes.number);
const PropTypeBoolOrNull = PropTypeOrNull(PropTypes.bool);

export interface IFileProperties {
  readonly fileSize: number;
  readonly type: string;
  readonly width: number | null;
  readonly height: number | null;
}

export interface IAsset extends IFileProperties {
  readonly id: Uuid;
  readonly codename: string;
  readonly filename: string;
  readonly title: string | null;
  readonly descriptions: Immutable.Map<Uuid, string>;
  readonly tags: ReadonlyArray<string>;
  readonly nonLocalizableElements: ReadonlyArray<ICompiledContentItemElementData>;
  readonly created: DateTimeStamp | null;
  readonly createdBy: UserId | null;
  readonly lastModified: DateTimeStamp | null;
  readonly lastModifiedBy: UserId | null;
  readonly archived: boolean;
  readonly isAnimatedGif: boolean;
  readonly folderId: Uuid;
  readonly collectionId: Uuid | null;
  readonly capabilities: {
    readonly activeCapabilities: ReadonlyArray<ActiveCapabilityType>;
  };
  readonly eTag: string | null;
  readonly _thumbnailLink: string | null;
  readonly _link: string | null;
  readonly _downloadLink: string | null;
  readonly _uploading: boolean | null;
  readonly _uploaded: number | null; // Percentages
  readonly _sizeUploaded: number | null; // File size
  readonly _failed: boolean | null;
  readonly _isBeingDeleted: boolean;
  readonly _deliveryLink: string | null;
}

export const EmptyAsset: IAsset = {
  id: '',
  codename: '',
  filename: '',
  title: null,
  type: '',
  fileSize: 0,
  descriptions: Immutable.Map<Uuid, string>(),
  tags: [],
  nonLocalizableElements: [],
  created: '',
  createdBy: '',
  lastModified: '',
  lastModifiedBy: '',
  archived: false,
  height: null,
  width: null,
  isAnimatedGif: false,
  folderId: '',
  collectionId: null,
  capabilities: {
    activeCapabilities: [],
  },
  eTag: null,
  _thumbnailLink: null,
  _link: null,
  _downloadLink: null,
  _uploading: null,
  _uploaded: null, // Percentages
  _sizeUploaded: null, // File size
  _failed: null,
  _isBeingDeleted: false,
  _deliveryLink: null,
};

export const Asset = {
  toAssetReferenceServerModel(assetReference: AssetReference): IAssetReferenceServerModel {
    return {
      _id: assetReference.id,
      renditions: assetReference.renditions,
    };
  },

  exists(asset: IAsset): boolean {
    // This can happen only when asset is referenced, but it was not created yet (possible only from CM API)
    return !(
      asset.id &&
      !asset.archived &&
      !asset.created &&
      !asset.createdBy &&
      !asset.fileSize &&
      !asset.filename &&
      !asset.lastModified &&
      !asset.lastModifiedBy &&
      !asset.title &&
      !asset.type
    );
  },

  hasCapability(asset: IAsset, capability: ActiveCapabilityType): boolean {
    return asset.capabilities?.activeCapabilities?.some((c) => c === capability) ?? false;
  },

  propTypeValidator: PropTypes.shape<ValidationMap<IAsset>>({
    _deliveryLink: PropTypeStringOrNull,
    _downloadLink: PropTypeStringOrNull,
    _failed: PropTypeBoolOrNull,
    _isBeingDeleted: PropTypes.bool.isRequired,
    _link: PropTypeStringOrNull,
    _thumbnailLink: PropTypeStringOrNull,
    _sizeUploaded: PropTypeNumberOrNull,
    _uploaded: PropTypeNumberOrNull,
    _uploading: PropTypeBoolOrNull,
    archived: PropTypes.bool.isRequired,
    codename: PropTypes.string.isRequired,
    created: PropTypeStringOrNull,
    createdBy: PropTypeStringOrNull,
    descriptions: ImmutablePropTypes.map.isRequired,
    tags: PropTypes.array.isRequired,
    eTag: PropTypeStringOrNull,
    filename: PropTypes.string.isRequired,
    fileSize: PropTypes.number.isRequired,
    folderId: PropTypes.string.isRequired,
    height: PropTypeNumberOrNull,
    id: PropTypes.string.isRequired,
    isAnimatedGif: PropTypes.bool.isRequired,
    lastModified: PropTypeStringOrNull,
    lastModifiedBy: PropTypeStringOrNull,
    title: PropTypeStringOrNull,
    type: PropTypes.string.isRequired,
    width: PropTypeNumberOrNull,
  }),
};
