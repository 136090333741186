import { combineReducers } from '@reduxjs/toolkit';
import { linkLocation } from './internalReducers/linkLocation.ts';
import { linkMessage } from './internalReducers/linkMessage.ts';
import { linkText } from './internalReducers/linkText.ts';
import { progress } from './internalReducers/progress.ts';
import { targetLocation } from './internalReducers/targetLocation.ts';

export const smartLinkAppReducer = combineReducers({
  linkLocation,
  linkMessage,
  linkText,
  progress,
  targetLocation,
});
