export const ContentItemEditing_Action_Activated = 'ContentItemEditing_Action_Activated';
export const ContentItemEditing_Action_Deactivated = 'ContentItemEditing_Action_Deactivated';
export const ContentItemEditing_Archiving_Failed = 'ContentItemEditing_Archiving_Failed';
export const ContentItemEditing_Archiving_Finished = 'ContentItemEditing_Archiving_Finished';
export const ContentItemEditing_Archiving_Started = 'ContentItemEditing_Archiving_Started';
export const ContentItemEditing_AssetAndRichTextElementsRevalidated =
  'ContentItemEditing_AssetElementsRevalidated';
export const ContentItemEditing_AssignmentChange_Abandoned =
  'ContentItemEditing_AssignmentChange_Abandoned';
export const ContentItemEditing_BulkContentItemVariantElementsRevalidationFinished =
  'ContentItemEditing_BulkContentItemVariantElementsRevalidationFinished';
export const ContentItemEditing_Changes_Discarded = 'ContentItemEditing_Changes_Discarded';
export const ContentItemEditing_CloseIncompleteDefaultVariantNotificationBar =
  'ContentItemEditing_CloseIncompleteDefaultVariantNotificationBar';
export const ContentItemEditing_CloseIncompleteItemNotificationBar =
  'ContentItemEditing_CloseIncompleteItemNotificationBar';
export const ContentItemEditing_CodenameEditor_Closed = 'ContentItemEditing_CodenameEditor_Closed';
export const ContentItemEditing_CodenameEditor_ErrorStatusCleared =
  'ContentItemEditing_CodenameEditor_ErrorStatusCleared';
export const ContentItemEditing_CodenameEditor_Opened = 'ContentItemEditing_CodenameEditor_Opened';
export const ContentItemEditing_CodenameSaving_NotUniqueError =
  'ContentItemEditing_CodenameSaving_NotUniqueError';
export const ContentItemEditing_Comments_Loaded = 'ContentItemEditing_Comments_Loaded';
export const ContentItemEditing_Comments_RegisterPositionCallback =
  'ContentItemEditing_Comments_RegisterPositionCallback';
export const ContentItemEditing_CommentThread_Blurred = 'ContentItemEditing_CommentThread_Blurred';
export const ContentItemEditing_CommentThread_Focused = 'ContentItemEditing_CommentThread_Focused';
export const ContentItemEditing_CommentThread_FormCanceled =
  'ContentItemEditing_CommentThread_FormCanceled';
export const ContentItemEditing_CommentThread_FormOpened =
  'ContentItemEditing_CommentThread_FormOpened';
export const ContentItemEditing_CommentThread_FormTextFocusLost =
  'ContentItemEditing_CommentThread_FormTextFocusLost';
export const ContentItemEditing_CommentThread_HideAllComments =
  'ContentItemEditing_CommentThread_HideAllComment';
export const ContentItemEditing_CommentThread_InlineSubmittingFinished =
  'ContentItemEditing_CommentThread_InlineSubmittingFinished';
export const ContentItemEditing_CommentThread_InlineSubmittingStarted =
  'ContentItemEditing_CommentThread_InlineSubmittingStarted';
export const ContentItemEditing_CommentThread_InlineThreadAdded =
  'ContentItemEditing_CommentThread_InlineThreadAdded';
export const ContentItemEditing_CommentThread_ItemEditingCanceled =
  'ContentItemEditing_CommentThread_ItemEditingCanceled';
export const ContentItemEditing_CommentThread_ItemEditingStarted =
  'ContentItemEditing_CommentThread_ItemEditingStarted';
export const ContentItemEditing_CommentThread_NewItemCreationFinished =
  'ContentItemEditing_CommentThread_NewItemCreationFinished';
export const ContentItemEditing_CommentThread_NewItemCreationStarted =
  'ContentItemEditing_CommentThread_NewItemCreationStarted';
export const ContentItemEditing_CommentThread_ReplyCanceled =
  'ContentItemEditing_CommentThread_ReplyCanceled';
export const ContentItemEditing_CommentThread_ReplyFocusLost =
  'ContentItemEditing_CommentThread_ReplyFocusLost';
export const ContentItemEditing_CommentThread_ReplyStarted =
  'ContentItemEditing_CommentThread_ReplyStarted';
export const ContentItemEditing_CommentThread_Resolved =
  'ContentItemEditing_CommentThread_Resolved';
export const ContentItemEditing_CommentThread_ResolveUndoCancel =
  'ContentItemEditing_CommentThread_ResolveUndoCancel';
export const ContentItemEditing_CommentThread_ResolvingStarted =
  'ContentItemEditing_CommentThread_ResolvingStarted';
export const ContentItemEditing_CommentThread_ShowAllComments =
  'ContentItemEditing_CommentThread_ShowAllComments';
export const ContentItemEditing_CommentThread_SubmittingFinished =
  'ContentItemEditing_CommentThread_SubmittingFinished';
export const ContentItemEditing_CommentThread_SubmittingStarted =
  'ContentItemEditing_CommentThread_SubmittingStarted';
export const ContentItemEditing_CommentThread_Unresolved =
  'ContentItemEditing_CommentThread_Unresolved';
export const ContentItemEditing_CommentThread_UnresolvingStarted =
  'ContentItemEditing_CommentThread_UnresolvingStarted';
export const ContentItemEditing_CommentThread_UpdateItemFinished =
  'ContentItemEditing_CommentThread_UpdateItemFinished';
export const ContentItemEditing_CommentThread_UpdateItemStarted =
  'ContentItemEditing_CommentThread_UpdateItemStarted';
export const ContentItemEditing_ContentComponent_Collapsed =
  'ContentItemEditing_ContentComponent_Collapsed';
export const ContentItemEditing_ContentComponent_Expanded =
  'ContentItemEditing_ContentComponent_Expanded';
export const ContentItemEditing_ContentComponentConvertedToItemNotification_Close =
  'ContentItemEditing_ContentComponentConvertedToItemNotification_Close';
export const ContentItemEditing_ContentComponents_Collapsed =
  'ContentItemEditing_ContentComponents_Collapsed';
export const ContentItemEditing_ContentComponents_Expanded =
  'ContentItemEditing_ContentComponents_Expanded';
export const ContentItemEditing_ContentDetailsButton_Toggled =
  'ContentItemEditing_ContentDetailsButton_Toggled';
export const ContentItemEditing_ContentGroup_Selected = 'ContentItemEditing_ContentGroup_Selected';
export const ContentItemEditing_ConvertingContentComponent_Failed =
  'ContentItemEditing_ConvertingContentComponent_Failed';
export const ContentItemEditing_ConvertingContentComponent_Finished =
  'ContentItemEditing_ConvertingContentComponent_Finished';
export const ContentItemEditing_ConvertingContentComponent_Started =
  'ContentItemEditing_ConvertingContentComponent_Started';
export const ContentItemEditing_CopyContentFromVariant_Failed =
  'ContentItemEditing_CopyContentFromVariant_Failed';
export const ContentItemEditing_CopyContentFromVariant_Finished =
  'ContentItemEditing_CopyContentFromVariant_Finished';
export const ContentItemEditing_CopyContentFromVariant_Started =
  'ContentItemEditing_CopyContentFromVariant_Started';
export const ContentItemEditing_VariantQuickActionsNotification_Close =
  'ContentItemEditing_VariantQuickActionsNotification_Close';
export const ContentItemEditing_CreateNewVersion_Failed =
  'ContentItemEditing_CreateNewVersion_Failed';
export const ContentItemEditing_CreateNewVersion_Finished =
  'ContentItemEditing_CreateNewVersion_Finished';
export const ContentItemEditing_CreateNewVersion_Started =
  'ContentItemEditing_CreateNewVersion_Started';
export const ContentItemEditing_CreateTask_Failed = 'ContentItemEditing_CreateTask_Failed';
export const ContentItemEditing_CreateTask_Finished = 'ContentItemEditing_CreateTask_Finished';
export const ContentItemEditing_CreateTask_Started = 'ContentItemEditing_CreateTask_Started';
export const ContentItemEditing_CreatingLinkedItem = 'ContentItemEditing_CreatingLinkedItem';
export const ContentItemEditing_DiscussionsSidebarClosed =
  'ContentItemEditing_DiscussionsSidebarClosed';
export const ContentItemEditing_DiscussionsSidebarOpened =
  'ContentItemEditing_DiscussionSidebarOpened';
export const ContentItemEditing_Duplicating_Failed = 'ContentItemEditing_Duplicating_Failed';
export const ContentItemEditing_Duplicating_Finished = 'ContentItemEditing_Duplicating_Finished';
export const ContentItemEditing_Duplicating_Started = 'ContentItemEditing_Duplicating_Started';
export const ContentItemEditing_ElementRefresh_Failed = 'ContentItemEditing_ElementRefresh_Failed';
export const ContentItemEditing_ElementRefresh_Fetched =
  'ContentItemEditing_ElementRefresh_Fetched';
export const ContentItemEditing_ElementRefresh_Finished =
  'ContentItemEditing_ElementRefresh_Finished';
export const ContentItemEditing_ElementRefresh_Started =
  'ContentItemEditing_ElementRefresh_Started';
export const ContentItemEditing_Elements_FocusFirstIncomplete =
  'ContentItemEditing_Elements_FocusFirstIncomplete';
export const ContentItemEditing_Elements_HighlightIncomplete =
  'ContentItemEditing_Elements_HighlightIncomplete';
export const ContentItemEditing_Elements_ResetIsHighlighing =
  'ContentItemEditing_Elements_ResetIsHighlighing';
export const ContentItemEditing_ElementValue_Changed = 'ContentItemEditing_ElementValue_Changed';
export const ContentItemEditing_ExpandModularContentItem_Finished =
  'ContentItemEditing_ExpandModularContentItem_Finished';
export const ContentItemEditing_FocusValidationWarningsBeforePublish =
  'ContentItemEditing_FocusValidationWarningsBeforePublish';
export const ContentItemEditing_GuidelinesElement_Collapsed =
  'ContentItemEditing_GuidelinesElement_Collapsed';
export const ContentItemEditing_GuidelinesElement_Expanded =
  'ContentItemEditing_GuidelinesElement_Expanded';
export const ContentItemEditing_HideElementsAreInaccessibleNotification =
  'ContentItemEditing_HideElementsAreInaccessibleNotification';
export const ContentItemEditing_HighlightedTask_Highlighted =
  'ContentItemEditing_HighlightedTask_Highlighted';
export const ContentItemEditing_HighlightOpenTasks_Finished =
  'ContentItemEditing_HighlightOpenTasks_Finished';
export const ContentItemEditing_Init_Failed = 'ContentItemEditing_Init_Failed';
export const ContentItemEditing_Init_Aborted = 'ContentItemEditing_Init_Aborted';
export const ContentItemEditing_Init_Finished = 'ContentItemEditing_Init_Finished';
export const ContentItemEditing_Init_FinishedNoVariant =
  'ContentItemEditing_Init_FinishedNoVariant';
export const ContentItemEditing_Init_Ready = 'ContentItemEditing_Init_Ready';
export const ContentItemEditing_Init_Started = 'ContentItemEditing_Init_Started';
export const ContentItemEditing_Init_VariantPrepared = 'ContentItemEditing_Init_VariantPrepared';
export const ContentItemEditing_ItemElementsSaving_Failed =
  'ContentItemEditing_ItemElementsSaving_Failed';
export const ContentItemEditing_ItemElementsSaving_Finished =
  'ContentItemEditing_ItemElementsSaving_Finished';
export const ContentItemEditing_ItemElementsSaving_Started =
  'ContentItemEditing_ItemElementsSaving_Started';
export const ContentItemEditing_ItemLiveUserPresenceMonitor_Unmounted =
  'ContentItemEditing_ItemLiveUserPresenceMonitor_Unmounted';
export const ContentItemEditing_ItemLiveUsers_Changed = 'ContentItemEditing_ItemLiveUsers_Changed';
export const ContentItemEditing_ItemName_Changed = 'ContentItemEditing_ItemName_Changed';
export const ContentItemEditing_ItemSaving_Finished = 'ContentItemEditing_ItemSaving_Finished';
export const ContentItemEditing_LanguageVariantSwitched =
  'ContentItemEditing_LanguageVariantSwitched';
export const ContentItemEditing_LinkedEntityChanged_ItemRefreshed =
  'ContentItemEditing_LinkedEntityChanged_ItemRefreshed';
export const ContentItemEditing_LinkedItemVariantElementsRevalidationFinished =
  'ContentItemEditing_LinkedItemVariantElementsRevalidationFinished';
export const ContentItemEditing_LoadTasksForItemVariant_Finished =
  'ContentItemEditing_LoadTasksForItemVariant_Finished';
export const ContentItemEditing_LoadTasksForItemVariant_Started =
  'ContentItemEditing_LoadTasksForItemVariant_Started';
export const ContentItemEditing_LoadPreviewConfiguration_Finished =
  'ContentItemEditing_LoadPreviewConfiguration_Finished';
export const ContentItemEditing_LoadPreviewConfiguration_Started =
  'ContentItemEditing_LoadPreviewConfiguration_Started';
export const ContentItemEditing_LockedElements_Changed =
  'ContentItemEditing_LockedElements_Changed';
export const ContentItemEditing_NewlyCreatedItem_EditorMounted =
  'ContentItemEditing_NewlyCreatedItem_EditorMounted';
export const ContentItemEditing_NotificationBar_Close = 'ContentItemEditing_NotificationBar_Close';
export const ContentItemEditing_PendingAiOperations_Discarded =
  'ContentItemEditing_PendingAiOperations_Discarded';
export const ContentItemEditing_RegenerateUrlSlug_Finished =
  'ContentItemEditing_RegenerateUrlSlug_Finished';
export const ContentItemEditing_RegenerateUrlSlug_Started =
  'ContentItemEditing_RegenerateUrlSlug_Started';
export const ContentItemEditing_ResolvedCommentsSidebarClosed =
  'ContentItemEditing_ResolvedCommentsSidebarClosed';
export const ContentItemEditing_ResolvedCommentsSidebarOpened =
  'ContentItemEditing_ResolvedCommentsSidebarOpened';
export const ContentItemEditing_Saving_Failed = 'ContentItemEditing_Saving_Failed';
export const ContentItemEditing_Saving_Started = 'ContentItemEditing_Saving_Started';
export const ContentItemEditing_ShowElementsAreInaccessibleNotification =
  'ContentItemEditing_ShowElementsAreInaccessibleNotification';
export const ContentItemEditing_ShowIncompleteDefaultVariantNotificationBar =
  'ContentItemEditing_ShowIncompleteDefaultVariantNotificationBar';
export const ContentItemEditing_Sidebar_Closed = 'ContentItemEditing_Sidebar_Closed';
export const ContentItemEditing_Sidebar_Initialized = 'ContentItemEditing_Sidebar_Initialized';
export const ContentItemEditing_Sidebar_Opened = 'ContentItemEditing_Sidebar_Opened';
export const ContentItemEditing_Sidebar_Section_Cleaned_Up =
  'ContentItemEditing_Sidebar_Section_Cleaned_Up';
export const ContentItemEditing_Sidebar_Section_Focused =
  'ContentItemEditing_Sidebar_SectionFocused';
export const ContentItemEditing_Sidebar_Section_UpdateFinished =
  'ContentItemEditing_Sidebar_SectionUpdated';
export const ContentItemEditing_Sidebar_Section_UpdateStarted =
  'ContentItemEditing_Sidebar_Section_UpdateStarted';
export const ContentItemEditing_SitemapLocation_Toggled =
  'ContentItemEditing_SitemapLocation_Toggled';
export const ContentItemEditing_SitemapLocationCollapsedState_Toggled =
  'ContentItemEditing_SitemapLocationCollapsedState_Toggled';
export const ContentItemEditing_SuggestionApprovalInitiated =
  'ContentItemEditing_SuggestionApprovalRequested';
export const ContentItemEditing_SuggestionApproved = 'ContentItemEditing_SuggestionApproved';
export const ContentItemEditing_TaskComplete_Failed = 'ContentItemEditing_TaskComplete_Failed';
export const ContentItemEditing_TaskComplete_Finished = 'ContentItemEditing_TaskComplete_Finished';
export const ContentItemEditing_TaskComplete_Started = 'ContentItemEditing_TaskComplete_Started';
export const ContentItemEditing_TaskArchive_Failed = 'ContentItemEditing_TaskArchive_Failed';
export const ContentItemEditing_TaskArchive_Finished = 'ContentItemEditing_TaskArchive_Finished';
export const ContentItemEditing_TaskArchive_Started = 'ContentItemEditing_TaskArchive_Started';
export const ContentItemEditing_TaskEditing_Cancelled = 'ContentItemEditing_TaskEditing_Cancelled';
export const ContentItemEditing_TaskEditing_Started = 'ContentItemEditing_TaskEditing_Started';
export const ContentItemEditing_TaskReopen_Failed = 'ContentItemEditing_TaskReopen_Failed';
export const ContentItemEditing_TaskReopen_Finished = 'ContentItemEditing_TaskReopen_Finished';
export const ContentItemEditing_TaskReopen_Started = 'ContentItemEditing_TaskReopen_Started';
export const ContentItemEditing_UpdateTask_Failed = 'ContentItemEditing_UpdateTask_Failed';
export const ContentItemEditing_UpdateTask_Finished = 'ContentItemEditing_UpdateTask_Finished';
export const ContentItemEditing_UpdateTask_Started = 'ContentItemEditing_UpdateTask_Started';
export const ContentItemEditing_UrlSlugContentElements_Update =
  'ContentItemEditing_UrlSlugContentElements_Update';
export const ContentItemEditing_VariantTranslation_Started =
  'ContentItemEditing_VariantTranslation_Started';
export const ContentItemEditing_VariantTranslation_Finished =
  'ContentItemEditing_VariantTranslation_Finished';

// Conditional elements
export const ContentItemEditing_HiddenElements_ClearValues =
  'ContentItemEditing_HiddenElements_ClearValues';
export const ContentItemEditing_VisibleElements_AddDefaultValues =
  'ContentItemEditing_VisibleElements_AddDefaultValues';

// Temporarily created new item
export const ContentItemEditing_ArchiveTemporaryContentItem_Finished =
  'ContentItemEditing_ArchiveTemporaryContentItem_Finished';
export const ContentItemEditing_ArchiveTemporaryContentItem_Started =
  'ContentItemEditing_ArchiveTemporaryContentItem_Started';
export const ContentItemEditing_MarkAsTemporary = 'ContentItemEditing_MarkAsTemporary';

// Editing actions forms
export const ContentItemEditing_NoteForm_Changed = 'ContentItemEditing_NoteForm_Changed';
export const ContentItemEditing_NoteForm_Reset = 'ContentItemEditing_NoteForm_Reset';
export const ContentItemEditing_ContributorsForm_Changed =
  'ContentItemEditing_ContributorsForm_Changed';
export const ContentItemEditing_ContributorsForm_Reset =
  'ContentItemEditing_ContributorsForm_Reset';
export const ContentItemEditing_DueDateForm_Changed = 'ContentItemEditing_DueDateForm_Changed';
export const ContentItemEditing_DueDateForm_Reset = 'ContentItemEditing_DueDateForm_Reset';
export const ContentItemEditing_TasksForm_Changed = 'ContentItemEditing_TasksForm_Changed';
export const ContentItemEditing_TasksForm_Reset = 'ContentItemEditing_TasksForm_Reset';

// ContentItemElementsAutoDispatcher + ItemNameElement
export const ContentItemEditing_AutoDispatchSave_Cancelled =
  'ContentItemEditing_AutoDispatchSave_Cancelled';
export const ContentItemEditing_AutoDispatchSave_Pending =
  'ContentItemEditing_AutoDispatchSave_Pending';

// ContentItemElementsAutoDispatcher
export const ContentItemEditing_ShouldAutoDispatchCheck_Pending =
  'ContentItemEditing_ShouldAutoDispatchCheck_Pending';
export const ContentItemEditing_ShouldAutoDispatchCheck_Finished =
  'ContentItemEditing_ShouldAutoDispatchCheck_Finished';
