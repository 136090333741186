import { Collection, notNullNorUndefined } from '@kontent-ai/utils';
import React, { RefObject, useEffect, useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import { aiResultHorizontalMargin } from '../../../../../../../_shared/constants/aiResultHorizontalMargin.ts';
import { ActionMenuPositioner } from '../../../../../../../_shared/features/AI/components/ActionMenuPositioner.tsx';
import {
  AiActionMenu,
  createDiscardAiActionFromResult,
  createRejectSuggestionAiSection,
  createTryAgainAiAction,
  createUseSuggestionAiSection,
} from '../../../../../../../_shared/features/AI/components/AiActionMenu.tsx';
import { getIdsActionResult } from '../../../../../../../_shared/features/AI/helpers/transformAiResult.ts';
import { useAiTask } from '../../../../../../../_shared/features/AI/hooks/aiTasks/useAiTask.ts';
import { useOnFinishedAiActionTask } from '../../../../../../../_shared/features/AI/hooks/aiTasks/useOnFinishedAiActionTask.ts';
import { useAiActionTrackingWithSession } from '../../../../../../../_shared/features/AI/hooks/useAiActionTrackingWithSession.ts';
import { ContentItemId } from '../../../../../../../_shared/models/ContentItemId.type.ts';
import { AiFollowingAction } from '../../../../../../../_shared/models/events/AiActionEventData.type.ts';
import { ITaxonomyGroup } from '../../../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { AiActionName } from '../../../../../../../repositories/serverModels/ai/AiActionName.type.ts';
import { createCategorizeItemParams } from '../../../../../../../repositories/serverModels/ai/actions/AiServerModels.categorizeItem.ts';
import { ElementReference } from '../../../containers/hooks/useItemElementReference.ts';
import { TaxonomyTermTag } from '../../../models/TaxonomyTermTag.type.ts';
import {
  createAddSelectedTermsAiAction,
  createReplaceSelectedTermsAiAction,
} from '../../../utils/taxonomyAiMenuActions.ts';
import { TaxonomyAiAssistantButton } from './TaxonomyAiAssistantButton.tsx';
import { TaxonomyAiAssistantPositioner } from './TaxonomyAiAssistantPositioner.tsx';
import { TaxonomyAiAssistantResult } from './TaxonomyAiAssistantResult.tsx';

export type TaxonomyAiAssistantProps = {
  readonly currentlySelectedTerms: ReadonlySet<Uuid>;
  readonly disabledTooltipText: string | null;
  readonly editedVariantId: ContentItemId;
  readonly isActive: boolean;
  readonly onTermSelectionChanged: (termIds: ReadonlySet<Uuid>) => void;
  readonly setIsActive: (newValue: boolean) => void;
  readonly taxonomyElementInfo: ElementReference;
  readonly taxonomyElementRef: RefObject<HTMLDivElement>;
  readonly taxonomyGroup: ITaxonomyGroup;
  readonly taxonomyTerms: ReadonlyArray<TaxonomyTermTag>;
};

export const TaxonomyAiAssistant: React.FC<TaxonomyAiAssistantProps> = ({
  currentlySelectedTerms,
  disabledTooltipText,
  editedVariantId,
  isActive,
  onTermSelectionChanged,
  setIsActive,
  taxonomyElementInfo,
  taxonomyElementRef,
  taxonomyGroup,
  taxonomyTerms,
}) => {
  const {
    elementOperationTrackingData,
    trackFinishedAction,
    trackStartingAction,
    trackFollowingAction,
    resetAiSessionId,
  } = useAiActionTrackingWithSession(taxonomyElementInfo);

  const { run, cancel, result } = useAiTask(AiActionName.CategorizeItem, getIdsActionResult);

  useOnFinishedAiActionTask(
    result.isFinished,
    () => result.trackingParams && trackFinishedAction(result.trackingParams),
  );

  const [selectedTermIds, setSelectedTermIds] = useState<ReadonlyArray<Uuid>>([]);

  useEffect(() => {
    setSelectedTermIds(result.content ?? []);
  }, [result.content]);

  const startActionWithTracking = () => {
    run(
      createCategorizeItemParams(editedVariantId, taxonomyGroup.id, elementOperationTrackingData),
    );
    trackStartingAction({ action: AiActionName.CategorizeItem });
  };

  const startAction = () => {
    startActionWithTracking();
    setIsActive(true);
  };

  const closeActionAndResetSessionId = () => {
    setIsActive(false);
    resetAiSessionId();
  };

  const closeSuggestion = () => {
    cancel();
    trackFollowingAction({ action: AiFollowingAction.Discard });
    closeActionAndResetSessionId();
  };

  const tryAgain = result.isFinished
    ? () => {
        trackFollowingAction({ action: AiFollowingAction.TryAgain });
        startActionWithTracking();
      }
    : undefined;

  const selectedTerms = selectedTermIds
    .map((termId) => taxonomyTerms.find((term) => term.id === termId))
    .filter(notNullNorUndefined);

  const replaceTerms =
    currentlySelectedTerms.size &&
    result.isFinished &&
    result.content &&
    !result.error &&
    selectedTerms.length > 0
      ? () => {
          onTermSelectionChanged(new Set(selectedTermIds));
          trackFollowingAction({ action: AiFollowingAction.ReplaceTaxonomyTerms });
          closeActionAndResetSessionId();
        }
      : undefined;

  const addTerms =
    result.isFinished && result.content && !result.error && selectedTerms.length > 0
      ? () => {
          const newTermSelection = Collection.addMany(currentlySelectedTerms, selectedTermIds);
          onTermSelectionChanged(newTermSelection);
          trackFollowingAction({ action: AiFollowingAction.AddTaxonomyTerms });
          closeActionAndResetSessionId();
        }
      : undefined;

  const removeTerm = (removedTermId: string) => {
    setSelectedTermIds((previousTerms) => previousTerms.filter((id) => id !== removedTermId));
    trackFollowingAction({ action: AiFollowingAction.RemoveSuggestedTerm });
  };

  const menuOptions = [
    createUseSuggestionAiSection(
      [createAddSelectedTermsAiAction(addTerms), createReplaceSelectedTermsAiAction(replaceTerms)],
      result,
    ),
    createRejectSuggestionAiSection([
      createTryAgainAiAction(result, tryAgain),
      createDiscardAiActionFromResult(closeSuggestion, result),
    ]),
  ];

  const resultWidth = useResultWidth(taxonomyElementRef);

  return (
    <TaxonomyAiAssistantPositioner
      isResultVisible={isActive}
      renderButton={(ref) => (
        <TaxonomyAiAssistantButton
          disabledTooltipText={disabledTooltipText}
          isActive={isActive}
          isDisabled={!!disabledTooltipText}
          onClick={startAction}
          ref={ref}
        />
      )}
      renderResult={(preferMenuOnTop) => (
        <ActionMenuPositioner
          preferMenuOnTop={preferMenuOnTop}
          renderMenu={() => <AiActionMenu options={menuOptions} />}
          renderResult={(ref) => (
            <TaxonomyAiAssistantResult
              ref={ref}
              removeTerm={removeTerm}
              result={result}
              selectedTerms={selectedTerms}
              taxonomyElementName={taxonomyElementInfo.elementName}
              taxonomyGroup={taxonomyGroup}
              width={resultWidth}
            />
          )}
        />
      )}
    />
  );
};

TaxonomyAiAssistant.displayName = 'TaxonomyAiAssistant';

const useResultWidth = (elementRef: RefObject<HTMLDivElement>) => {
  const [resultWidth, setResultWidth] = useState(0);

  useResizeObserver({
    ref: elementRef,
    onResize: ({ width }) => width && setResultWidth(width - 2 * aiResultHorizontalMargin),
  });

  return resultWidth;
};
