import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { ValidationConstants } from '../../../../../../_shared/constants/validationConstants.ts';
import {
  ElementType,
  TypeElementType,
} from '../../../../../contentInventory/content/models/ContentItemElementType.ts';
import { IBaseTypeElementData } from '../../../models/elements/types/TypeElementData.ts';
import { ITypeElementValidationUtilsDependencies } from '../../../utils/typeElementValidators/types/ITypeElementValidationUtilsDependencies.type.ts';
import { ITypeElementValidatorDependencies } from '../../../utils/typeElementValidators/types/ITypeElementValidatorDependencies.type.ts';
import { getConditionValidationDataForTypeElement } from '../../../utils/typeElementValidators/utils/conditions/getConditionValidationData.ts';
import { createTypeElementValidationUtils } from '../../../utils/typeElementValidators/utils/typeElementValidationUtils.ts';
import { typeElementValidated } from '../../sharedContentModelsActions.ts';
import { createValidateAssetTypeElementAction } from '../elementValidation/validateAssetTypeElement.ts';
import { createValidateCustomTypeElementAction } from '../elementValidation/validateCustomTypeElement.ts';
import { createValidateDateTimeTypeElementAction } from '../elementValidation/validateDateTimeTypeElement.ts';
import { createValidateGuidelinesTypeElementAction } from '../elementValidation/validateGuidelinesTypeElement.ts';
import { createValidateModularContentTypeElementAction } from '../elementValidation/validateModularContentTypeElement.ts';
import { createValidateMultipleChoiceTypeElementAction } from '../elementValidation/validateMultipleChoiceTypeElement.ts';
import { createValidateNumberTypeElementAction } from '../elementValidation/validateNumberTypeElement.ts';
import { createValidateRichTextTypeElementAction } from '../elementValidation/validateRichTextTypeElement.ts';
import { createValidateTaxonomyTypeElementAction } from '../elementValidation/validateTaxonomyTypeElement.ts';
import { createValidateTextTypeElementAction } from '../elementValidation/validateTextTypeElement.ts';
import { createValidateTypeSnippetTypeElementAction } from '../elementValidation/validateTypeSnippetTypeElement.ts';
import { createValidateUrlSlugTypeElementAction } from '../elementValidation/validateUrlSlugTypeElement.ts';

const typeElementValidationUtilsDependencies: ITypeElementValidationUtilsDependencies = {
  ValidationConstants,
};

const typeElementValidationUtils = createTypeElementValidationUtils(
  typeElementValidationUtilsDependencies,
);

const dependencies: ITypeElementValidatorDependencies = {
  ValidationConstants,
  getConditionValidationData: getConditionValidationDataForTypeElement,
  typeElementValidationUtils,
  typeElementValidated,
};

const assetTypeElementValidator = createValidateAssetTypeElementAction(dependencies);
const subpagesElementValidator = createValidateModularContentTypeElementAction(dependencies);
const contentTypeSnippetTypeElementValidator =
  createValidateTypeSnippetTypeElementAction(dependencies);
const customTypeElementValidator = createValidateCustomTypeElementAction(dependencies);
const dateTimeTypeElementValidator = createValidateDateTimeTypeElementAction(dependencies);
const guidelinesTypeElementValidator = createValidateGuidelinesTypeElementAction(dependencies);
const modularContentTypeElementValidator =
  createValidateModularContentTypeElementAction(dependencies);
const multipleChoiceTypeElementValidator =
  createValidateMultipleChoiceTypeElementAction(dependencies);
const numberTypeElementValidator = createValidateNumberTypeElementAction(dependencies);
const richTextTypeElementValidator = createValidateRichTextTypeElementAction(dependencies);
const taxonomyTypeElementValidator = createValidateTaxonomyTypeElementAction(dependencies);
const textTypeElementValidator = createValidateTextTypeElementAction(dependencies);
const urlSlugTypeElementValidator = createValidateUrlSlugTypeElementAction(dependencies);

const validatorsMap: {
  readonly [key in TypeElementType]: (typeElement: IBaseTypeElementData) => ThunkFunction;
} = {
  [ElementType.Asset]: assetTypeElementValidator,
  [ElementType.Subpages]: subpagesElementValidator,
  [ElementType.ContentTypeSnippet]: contentTypeSnippetTypeElementValidator,
  [ElementType.Custom]: customTypeElementValidator,
  [ElementType.DateTime]: dateTimeTypeElementValidator,
  [ElementType.Guidelines]: guidelinesTypeElementValidator,
  [ElementType.LinkedItems]: modularContentTypeElementValidator,
  [ElementType.MultipleChoice]: multipleChoiceTypeElementValidator,
  [ElementType.Number]: numberTypeElementValidator,
  [ElementType.RichText]: richTextTypeElementValidator,
  [ElementType.Taxonomy]: taxonomyTypeElementValidator,
  [ElementType.Text]: textTypeElementValidator,
  [ElementType.UrlSlug]: urlSlugTypeElementValidator,
};

export const createValidateTypeElementAction =
  () =>
  (typeElement: IBaseTypeElementData): ThunkFunction =>
  (dispatch) => {
    const validateElement: (typeElement: IBaseTypeElementData) => ThunkFunction =
      validatorsMap[typeElement.type];
    dispatch(validateElement(typeElement));
  };
