import { useHover } from '@react-aria/interactions';
import PropTypes from 'prop-types';
import React, { ComponentProps, MouseEvent } from 'react';
import styled from 'styled-components';
import { useOurFocusRing } from '../../../hooks/useOurFocusRing.ts';
import { offsetFocus } from '../../../tokens/decision/focus.ts';
import { BorderRadius } from '../../../tokens/quarks/border.ts';
import { shadowFocusStyles } from '../../../tokens/quarks/shadow.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { Placement, placements } from '../../../types/placement.ts';
import { getDataUiComponentAttribute } from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { Anchor, RouterLink, RouterNavLink } from '../../Anchor/Anchor.tsx';
import { Icons } from '../../Icons/components/icons.ts';
import { IconName } from '../../Icons/types.ts';
import { Tooltip } from '../../Tooltip/Tooltip.tsx';
import { TooltipPropsExtension, tooltipExtensionPropTypes } from '../../_utils/propPrefabs.ts';
import { ButtonBadge } from '../ButtonBadge.tsx';
import { ButtonSize, buttonSizeValues } from '../buttonSize.ts';
import { ButtonStyle, buttonStyleValues } from '../buttonStyle.ts';
import { StyledIconButton } from '../components/StyledIconButton.tsx';
import { shouldWrapperHandleFocus } from '../utils/stylingUtils.ts';
import { StyledIconButtonIcon } from './IconButtonIcon.tsx';
import { IconButtonState, iconButtonStateValues } from './IconButtonState.ts';

export interface IIconButtonProps extends TooltipPropsExtension {
  readonly activated?: boolean;
  readonly badgeValue?: number;
  readonly buttonState?: IconButtonState;
  readonly buttonStyle: ButtonStyle;
  readonly children?: never;
  readonly className?: string;
  readonly component?: 'button' | typeof Anchor | typeof RouterLink | typeof RouterNavLink;
  readonly destructive?: boolean;
  readonly iconName: IconName;
  readonly id?: string;
  readonly onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  readonly screenReaderText?: string;
  readonly size: ButtonSize;
  readonly tooltipPlacement: Placement;
  readonly tooltipText: string;
  readonly type?: ComponentProps<typeof StyledIconButton>['type'];
}

const propTypes: PropTypeMap<IIconButtonProps> = {
  ...tooltipExtensionPropTypes,
  activated: PropTypes.bool,
  badgeValue: PropTypes.number,
  buttonState: PropTypes.oneOf(iconButtonStateValues),
  buttonStyle: PropTypes.oneOf(buttonStyleValues).isRequired,
  children: PropTypes.any,
  className: PropTypes.string,
  component: PropTypes.any,
  destructive: PropTypes.bool,
  iconName: PropTypes.oneOf(Object.keys(Icons)).isRequired,
  id: PropTypes.string,
  onClick: PropTypes.func,
  screenReaderText: PropTypes.string,
  size: PropTypes.oneOf(buttonSizeValues).isRequired,
  tooltipPlacement: PropTypes.oneOf(placements).isRequired,
  tooltipText: PropTypes.string.isRequired,
  type: PropTypes.string,
};

const isIconButtonDisabled = (buttonState: IconButtonState) => {
  return buttonState === 'disabled' || buttonState === 'in-progress';
};

const StyledFocusWrapper = styled.div<{ readonly isFocusVisible: boolean }>`
  position: relative;
  display: inline-block;
  border-radius: ${px(BorderRadius.Pill)};
  
  &:before {
    content: '';
    position: absolute;
    inset: ${px(-1 * offsetFocus)};
    border-radius: inherit;

    pointer-events: none;
    ${({ isFocusVisible }) => isFocusVisible && shadowFocusStyles};
  }
`;

export const IconButton = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<IIconButtonProps>
>(
  (
    {
      activated,
      badgeValue,
      buttonState = 'default',
      buttonStyle,
      children,
      className,
      component = 'button',
      destructive,
      iconName,
      id,
      onClick,
      screenReaderText,
      size,
      tooltipMaxGridUnitsWidth,
      tooltipPlacement,
      tooltipShortcuts,
      tooltipText,
      ...props
    },
    forwardedRef,
  ) => {
    const isDisabled = isIconButtonDisabled(buttonState);
    const { isFocusVisible, focusProps } = useOurFocusRing(isDisabled);
    const { isHovered, hoverProps } = useHover({});

    return (
      <Tooltip
        maxGridUnitsWidth={tooltipMaxGridUnitsWidth}
        placement={tooltipPlacement}
        shortcuts={tooltipShortcuts}
        tooltipText={activated ? '' : tooltipText}
        visible={isFocusVisible || isHovered}
      >
        <StyledFocusWrapper
          isFocusVisible={shouldWrapperHandleFocus(buttonStyle) && isFocusVisible}
        >
          <StyledIconButton
            $activated={activated}
            $size={size}
            as={component}
            $buttonStyle={buttonStyle}
            className={className}
            $destructive={destructive}
            disabled={isDisabled}
            id={id}
            $isFocusVisible={!shouldWrapperHandleFocus(buttonStyle) && isFocusVisible}
            onClick={onClick}
            ref={forwardedRef}
            {...focusProps}
            {...hoverProps}
            {...props}
            {...getDataUiComponentAttribute(IconButton)}
          >
            <StyledIconButtonIcon
              iconName={iconName}
              buttonStyle={buttonStyle}
              buttonState={buttonState}
              size={size}
              disabled={isDisabled}
              destructive={destructive}
              $activated={activated}
              screenReaderText={screenReaderText}
            />
            {!!badgeValue && <ButtonBadge value={badgeValue} />}
          </StyledIconButton>
        </StyledFocusWrapper>
      </Tooltip>
    );
  },
);

IconButton.displayName = 'IconButton';
IconButton.propTypes = propTypes;
