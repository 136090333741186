import { parse } from 'cookie';

function expireCookie(name: string, now: string) {
  document.cookie = `${name}=;expires=${now}`;
}

export function getExistingCookies(documentCookie: string) {
  return parse(documentCookie);
}

export function expireCookies(cookieNames: ReadonlyArray<string>) {
  const now = new Date().toUTCString();
  if (cookieNames) {
    cookieNames.forEach((name) => expireCookie(name, now));
  }
}
