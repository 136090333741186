import { Box } from '@kontent-ai/component-library/Box';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Spacing } from '@kontent-ai/component-library/tokens';
import PropTypes from 'prop-types';
import React from 'react';
import { ImageLimitsConfig } from '../../../../../_shared/utils/assets/assetValidationUtils.ts';
import { AssetList } from '../../containers/AssetList.tsx';
import { AssetTileSelectionContextProvider } from '../../context/AssetListing/AssetTileSelectionContext.tsx';
import { FileWithThumbnail } from '../../models/FileWithThumbnail.type.ts';
import { AssetsOrderBy } from '../../types/orderBy.type.ts';
import { UploadTile } from '../AssetTile/UploadTile.tsx';
import { AssetListingOrdering } from './AssetListingOrdering.tsx';

export interface IAssetLibraryTilesDataProps {
  readonly showImagesOnly?: boolean;
  readonly isWithAnchor?: boolean;
  readonly imageLimits?: ImageLimitsConfig;
  readonly allowSelect: boolean;
  readonly orderBy: AssetsOrderBy;
  readonly selectedAssets: ReadonlySet<Uuid>;
}

export interface IAssetLibraryTilesDispatchProps {
  readonly onAssetClick?: (assetId: Uuid) => void;
  readonly onAssetDoubleClick?: (assetId: Uuid) => void;
  readonly onOrderingChange: (orderingData: AssetsOrderBy) => void;
  readonly onUpload: ((files: ReadonlyArray<FileWithThumbnail>) => void) | null;
  readonly toggleAssetSelection: (assetId: Uuid, shiftPressed: boolean) => void;
}

type IAssetLibraryTilesProps = IAssetLibraryTilesDataProps & IAssetLibraryTilesDispatchProps;

export const AssetLibraryTiles: React.FC<IAssetLibraryTilesProps> = ({
  allowSelect,
  imageLimits,
  isWithAnchor,
  onOrderingChange,
  showImagesOnly,
  onAssetClick,
  onAssetDoubleClick,
  onUpload,
  orderBy,
  selectedAssets,
  toggleAssetSelection,
}) => (
  <section className="asset-library__section">
    <Box paddingBottom={Spacing.L}>
      <Row alignY="center">
        <Column>
          <h3 className="asset-library__section-title">Assets</h3>
        </Column>

        <Column width="content">
          <AssetListingOrdering onOrderingChange={onOrderingChange} orderBy={orderBy} />
        </Column>
      </Row>
    </Box>
    <div className="asset-library__section-tiles row">
      {onUpload && (
        <UploadTile onUpload={onUpload} accept={showImagesOnly ? 'image/*' : undefined} />
      )}
      <AssetTileSelectionContextProvider
        selectedAssets={selectedAssets}
        toggleAssetSelection={toggleAssetSelection}
      >
        <AssetList
          onAssetDoubleClick={onAssetDoubleClick}
          onAssetClick={onAssetClick}
          isWithAnchor={isWithAnchor}
          imageLimits={imageLimits}
          allowSelect={allowSelect}
          showImagesOnly={showImagesOnly}
        />
      </AssetTileSelectionContextProvider>
    </div>
  </section>
);

const propTypes: PropTypesShape<IAssetLibraryTilesProps> = {
  selectedAssets: PropTypes.object.isRequired,
  showImagesOnly: PropTypes.bool,
  isWithAnchor: PropTypes.bool,

  imageLimits: PropTypes.object,
  allowSelect: PropTypes.bool.isRequired,
  onOrderingChange: PropTypes.func.isRequired,
  orderBy: PropTypes.object.isRequired,
  onUpload: PropTypes.func,

  // callback props
  onAssetClick: PropTypes.func,
  onAssetDoubleClick: PropTypes.func,
  toggleAssetSelection: PropTypes.func.isRequired,
};
AssetLibraryTiles.propTypes = propTypes;

AssetLibraryTiles.displayName = 'AssetLibraryTiles';
