import { Collection } from '@kontent-ai/utils';
import classNames from 'classnames';
import { DraftDecorator, DraftEditorLeafs, DraftInlineStyle, EditorState } from 'draft-js';
import Immutable from 'immutable';
import React, { useCallback, useContext, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { toLocalTime } from '../../../../_shared/utils/dateTime/timeUtils.ts';
import {
  ItemElementsContext,
  OriginalItemElementsContext,
} from '../../../itemEditor/features/ContentComponent/context/ItemElementsContext.tsx';
import { useEditorStateCallbacks } from '../../editorCore/hooks/useEditorStateCallbacks.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { GetEditorId, OnUpdate } from '../../editorCore/types/Editor.base.type.ts';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { Apply, EditorPlugin, Init } from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import { RichTextContentChangeCallbackDebounce } from '../../editorCore/utils/editorComponentUtils.ts';
import { withDisplayName } from '../../editorCore/utils/withDisplayName.ts';
import { FinishedInstruction } from '../../plugins/inlineAi/instructions/FinishedInstruction.tsx';
import {
  NewInstructionContent,
  NewInstructionStartContent,
} from '../../plugins/inlineAi/instructions/NewInstruction.tsx';
import { isFinishedAiInstruction } from '../../plugins/inlineAi/utils/InstructionEntity.ts';
import {
  findFinishedInstructions,
  findInstructionContents,
  findInstructionStarts,
} from '../../plugins/inlineAi/utils/editorInlineAiUtils.ts';
import { useActiveFinishedInstruction } from '../../plugins/inlineAi/utils/useActiveFinishedInstruction.tsx';
import { createSelection } from '../../utils/editorSelectionUtils.ts';
import { AiStylesPlugin } from '../ai/AiStylesPlugin.tsx';
import { DiffStyles, InlineStyleDiff, isDiffStyle } from '../diff/api/editorDiffUtils.ts';
import { EntityApiPlugin } from '../entityApi/EntityApiPlugin.tsx';
import { EntityDecoratorProps } from '../entityApi/api/editorEntityUtils.ts';
import { AtomicEntity, updateLeafNodes } from '../entityApi/components/AtomicEntity.tsx';

const noStyles: DraftInlineStyle = Immutable.OrderedSet();

const useMoveStylesToWrapper = (
  children: DraftEditorLeafs,
  className?: string,
): {
  readonly childrenWithoutStyles: DraftEditorLeafs;
  readonly wrapperClassName?: string;
  readonly isRemoved: boolean;
} => {
  // We do not want the instruction content to use any style to keep its visual clean
  const childrenWithoutStyles = useMemo(() => {
    return updateLeafNodes(children, () => ({ styleSet: noStyles }));
  }, [children]);

  const firstChildProps = Collection.getFirst(children)?.props;
  const diffStyles = firstChildProps?.styleSet.toArray().filter(isDiffStyle);
  const wrapperClassName = classNames(
    className,
    // We need to propagate the diff styles to the top, so that adjacent changes correctly merge
    diffStyles?.map((style) => DiffStyles[style].className),
  );

  return {
    childrenWithoutStyles,
    wrapperClassName,
    isRemoved: !!diffStyles?.includes(InlineStyleDiff.Removed),
  };
};

const NewInstructionStartEntity: React.FC<EntityDecoratorProps> = ({ entityKey, children }) => {
  const { childrenWithoutStyles, wrapperClassName } = useMoveStylesToWrapper(
    children,
    'rte__ai-instruction',
  );

  return (
    <NewInstructionStartContent key={entityKey} className={wrapperClassName}>
      {childrenWithoutStyles}
    </NewInstructionStartContent>
  );
};

NewInstructionStartEntity.displayName = 'NewInstructionStartEntity';

const NewInstructionContentEntity: React.FC<EntityDecoratorProps> = ({ entityKey, children }) => {
  const { childrenWithoutStyles, wrapperClassName } = useMoveStylesToWrapper(children, 'rte__ai');

  return (
    <NewInstructionContent key={entityKey} className={wrapperClassName} entityKey={entityKey}>
      {childrenWithoutStyles}
    </NewInstructionContent>
  );
};

NewInstructionContentEntity.displayName = 'NewInstructionContentEntity';

const useInstructionSnapshotTime = (isRemoved: boolean): Date | null => {
  const { snapshotTime } = useContext(ItemElementsContext);
  const { snapshotTime: originalSnapshotTime } = useContext(OriginalItemElementsContext);

  const nowFromContext = isRemoved ? originalSnapshotTime : snapshotTime;

  return toLocalTime(nowFromContext) ?? null;
};

type InstructionEntityCustomProps = {
  readonly focusInstruction: (entityKey: string) => void;
  readonly getEditorId: GetEditorId;
};

const FinishedInstructionEntity: React.FC<EntityDecoratorProps<InstructionEntityCustomProps>> = (
  props,
) => {
  const { contentState, entityKey, focusInstruction, getEditorId, children, offsetKey } = props;

  const { childrenWithoutStyles, wrapperClassName, isRemoved } = useMoveStylesToWrapper(children);

  const snapshotTime = useInstructionSnapshotTime(isRemoved);

  const entity = contentState.getEntity(entityKey);
  if (!isFinishedAiInstruction(entity)) {
    return null;
  }

  return (
    <AtomicEntity
      {...props}
      renderContent={(content) => (
        <FinishedInstruction
          className={wrapperClassName}
          data={entity.getData()}
          disabled
          editorId={getEditorId()}
          key={entityKey}
          offsetKey={offsetKey}
          onClick={() => focusInstruction(entityKey)}
          snapshotTime={snapshotTime}
        >
          {content}
        </FinishedInstruction>
      )}
    >
      {childrenWithoutStyles}
    </AtomicEntity>
  );
};

FinishedInstructionEntity.displayName = 'FinishedInstructionEntity';

type DisplayInlineAiPlugin = EditorPlugin<None, None, None, [EntityApiPlugin, AiStylesPlugin]>;

export const useDisplayInlineAi: PluginCreator<DisplayInlineAiPlugin> = (baseEditor) =>
  useMemo(
    () =>
      withDisplayName('DisplayInlineAiPlugin', {
        ComposedEditor: (props) => {
          const { decorateWithEditorStateCallbacks, executeChange, getApi, getEditorId } =
            useEditorStateCallbacks<DisplayInlineAiPlugin>();

          const { updateActiveFinishedInstruction } = useActiveFinishedInstruction(getEditorId);
          const debouncedUpdateActiveFinishedInstruction = useDebouncedCallback(
            updateActiveFinishedInstruction,
            RichTextContentChangeCallbackDebounce,
          );

          const onUpdate: Decorator<OnUpdate> = useCallback(
            (baseOnUpdate) => (params) => {
              debouncedUpdateActiveFinishedInstruction(params.editorState);
              baseOnUpdate(params);
            },
            [debouncedUpdateActiveFinishedInstruction],
          );

          const focusInstruction = useCallback(
            (entityKey: string) =>
              executeChange((editorState) => {
                const selection = getApi().getSelectionForEntity(editorState, entityKey);
                if (!selection) {
                  return editorState;
                }

                return EditorState.forceSelection(
                  editorState,
                  createSelection(selection.getEndKey(), selection.getEndOffset()),
                );
              }),
            [getApi, executeChange],
          );

          const apply: Apply<DisplayInlineAiPlugin> = useCallback(
            (state) => {
              decorateWithEditorStateCallbacks(state);
              state.onUpdate.decorate(onUpdate);
              return {};
            },
            [decorateWithEditorStateCallbacks, onUpdate],
          );

          const init: Init = useCallback(
            (state) => {
              const instructionDecorators: ReadonlyArray<DraftDecorator> = [
                {
                  strategy: findInstructionStarts,
                  component: NewInstructionStartEntity,
                },
                {
                  strategy: findInstructionContents,
                  component: NewInstructionContentEntity,
                },
                {
                  strategy: findFinishedInstructions,
                  component: FinishedInstructionEntity,
                  props: {
                    focusInstruction,
                    getEditorId,
                  } satisfies InstructionEntityCustomProps,
                },
              ];

              return {
                decorators: [...state.decorators, ...instructionDecorators],
              };
            },
            [focusInstruction, getEditorId],
          );

          return useEditorWithPlugin(baseEditor, props, { apply, init });
        },
      }),
    [baseEditor],
  );
