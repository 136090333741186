import React, { useCallback } from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import {
  getSelectedSubscription,
  getSubscriptionPlan,
} from '../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import {
  allFiltersCleared,
  subscriptionUsersLeft,
} from '../actions/subscriptionUserListingActions.ts';
import { initSubscriptionUsers } from '../actions/thunkSubscriptionUsersActions.ts';
import { SubscriptionUsers as Component } from '../components/SubscriptionUsers.tsx';
import { SubscriptionCapabilitiesContextProvider } from '../context/SubscriptionCapabilitiesContext.tsx';
import { getSubscriptionProjectsAndEnvironments } from '../selectors/getSubscriptionProjectsAndEnvironments.ts';
import { SubscriptionUserListingConfig } from './SubscriptionUserListingConfig.tsx';

export const SubscriptionUsers: React.FC = () => {
  const selectedSubscriptionId = useSelector(
    (state) => getSelectedSubscription(state)?.subscriptionId ?? '',
  );
  const subscriptionUsersUsage = useSelector((s) => s.data.subscriptions.subscriptionUsersUsage);
  const subscriptionProjectsAndEnvironments = useSelector(getSubscriptionProjectsAndEnvironments);
  const filter = useSelector((s) => s.subscriptionApp.users.listingUi.filter);
  const subscriptionPlan = useSelector((s) => getSubscriptionPlan(s, selectedSubscriptionId));

  const dispatch = useDispatch();

  const onInit = useCallback(() => {
    dispatch(initSubscriptionUsers());
  }, []);

  const onLeave = useCallback(() => {
    dispatch(subscriptionUsersLeft());
  }, []);

  const onClearFilter = () => {
    dispatch(allFiltersCleared());
  };

  return (
    <SubscriptionCapabilitiesContextProvider subscriptionPlan={subscriptionPlan}>
      <SubscriptionUserListingConfig />
      <Component
        filter={filter}
        onClearFilter={onClearFilter}
        onInit={onInit}
        onLeave={onLeave}
        subscriptionId={selectedSubscriptionId}
        subscriptionPlan={subscriptionPlan}
        subscriptionProjectsAndEnvironments={subscriptionProjectsAndEnvironments}
        subscriptionUsersUsage={subscriptionUsersUsage}
      />
    </SubscriptionCapabilitiesContextProvider>
  );
};

SubscriptionUsers.displayName = 'SubscriptionUsers';
