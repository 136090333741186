import { getAbsoluteTopOffset } from '@kontent-ai/DOM';
import { memoize } from '@kontent-ai/memoization';
import {
  assert,
  ICancellablePromise,
  delay,
  swallowCancelledPromiseError,
} from '@kontent-ai/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { waitUntilFocusAndScrollAreNotDeferred } from '../../../../../../_shared/utils/autoScrollUtils.ts';
import { DebouncedFunction, debounce } from '../../../../../../_shared/utils/func/debounce.ts';
import {
  getDeepestCollapsedComponentPosition,
  getDeepestUnselectedContentGroupPositionInComponent,
} from '../../../../../richText/plugins/contentComponents/utils/contentComponentRenderingUtils.ts';
import { ICommentThread } from '../../../../models/comments/CommentThreads.ts';
import { ICommentThreadWithLocation, isThreadResolved } from '../../../../utils/commentUtils.ts';
import { getThreadHeight } from './CommentThread.tsx';
import { CommentThreadPositionerClassName } from './CommentThreadPositioner.tsx';
import { InlineCommentPaneView } from './InlineCommentPaneView.tsx';

const WindowResizeAdjustInterval = 300;

export const CommentPositionTransitionDuration = 500;
const PositionMaintenanceInterval = 500;

const DefaultCommentSpace = 24;

export type ThreadOffsets = Immutable.Map<Uuid, IThreadOffset>;
type ThreadRefs = Map<Uuid, React.RefObject<HTMLDivElement>>;

// Ideally we should compare thread ids, but as we know there are currently no operations which would both add some comments and remove other in one go
// we can compare just sizes
// The only exception to that is turning a new comment into a saved comment, which changes its id but the comment stays in place thanks to this "size" comparison, which is the intended behavior.
const threadsAddedOrRemoved = (
  oldThreads: ReadonlyArray<ICommentThreadWithLocation>,
  threads: ReadonlyArray<ICommentThreadWithLocation>,
): boolean => oldThreads.length !== threads.length;

const getUnresolvedCommentThreads = memoize.weak(
  (threads: ReadonlyArray<ICommentThreadWithLocation>): ReadonlyArray<ICommentThreadWithLocation> =>
    threads.filter((thread) => !isThreadResolved(thread.commentThread)),
);

function keepSpaceAfterRemovedComments(
  threadOffsets: ThreadOffsets,
  oldThreads: ReadonlyArray<ICommentThreadWithLocation>,
  threads: ReadonlyArray<ICommentThreadWithLocation>,
  threadRefs: ThreadRefs,
): ThreadOffsets {
  // Detect removed comments and move their added size to the next comment offset
  const unresolvedOldThreads = getUnresolvedCommentThreads(oldThreads);
  const unresolvedThreads = getUnresolvedCommentThreads(threads);

  if (!threadsAddedOrRemoved(unresolvedThreads, unresolvedOldThreads)) {
    return threadOffsets;
  }

  const compensateForRemovedThread = (
    updatedThreadOffsets: Immutable.Map<Uuid, IThreadOffset>,
    oldThread: ICommentThreadWithLocation,
    oldIndex: number,
  ): ThreadOffsets => {
    const oldThreadId = oldThread.commentThread.id;
    const isRemoved =
      unresolvedThreads.findIndex(
        (thread: ICommentThreadWithLocation) => thread.commentThread.id === oldThreadId,
      ) < 0;
    if (!isRemoved) {
      return updatedThreadOffsets;
    }

    // Remove known offset to make sure the comment doesn't have a known position when re-created to ensure proper animation (e.g. after resolve + unresolve)
    const withRemovedThreadOffset = updatedThreadOffsets.remove(oldThreadId);

    // When removed, adjust offset of the next thread, if available
    const nextThread = unresolvedOldThreads[oldIndex + 1];
    if (!nextThread) {
      return withRemovedThreadOffset;
    }

    const nextThreadId = nextThread.commentThread.id;
    const nextThreadOffset = withRemovedThreadOffset.get(nextThreadId);
    if (nextThreadOffset === undefined) {
      return withRemovedThreadOffset;
    }

    const threadHeight = getThreadHeight(threadRefs.get(oldThreadId));
    if (!threadHeight) {
      return withRemovedThreadOffset;
    }

    const threadOffset = updatedThreadOffsets.get(oldThreadId)?.relativeOffset || 0;
    const spaceTaken = threadOffset + threadHeight + DefaultCommentSpace;

    return withRemovedThreadOffset.set(nextThreadId, {
      threadId: nextThreadId,
      relativeOffset: nextThreadOffset.relativeOffset + spaceTaken,
    });
  };

  const newThreadOffsets = unresolvedOldThreads.reduce(compensateForRemovedThread, threadOffsets);

  return newThreadOffsets;
}

function getUpdatedOffsets(
  threadOffsets: ThreadOffsets,
  newThreadOffsets: ReadonlyArray<IThreadOffset | null>,
): ThreadOffsets {
  const updatedOffsets = newThreadOffsets.reduce(
    (aggregated: ThreadOffsets, threadOffset: IThreadOffset | null) => {
      if (!threadOffset) {
        return aggregated;
      }

      const existing = aggregated.get(threadOffset.threadId);

      return !existing || threadOffset.relativeOffset !== existing.relativeOffset
        ? aggregated.set(threadOffset.threadId, {
            threadId: threadOffset.threadId,
            relativeOffset: threadOffset.relativeOffset,
          })
        : aggregated;
    },
    threadOffsets,
  );

  return updatedOffsets;
}

function getNewFocusedCommentThread(
  lastFocusedThreadId: Uuid | null,
  focusedThreadId: Uuid | null,
  threads: ReadonlyArray<ICommentThreadWithLocation>,
): Uuid | null {
  // Keep last focused thread to hold the scrolling position when everything gets unfocused
  if (!focusedThreadId || lastFocusedThreadId === focusedThreadId) {
    return lastFocusedThreadId;
  }

  const isFocusedCommentThreadUnresolved = threads.some(
    (thread: ICommentThreadWithLocation) =>
      !isThreadResolved(thread.commentThread) && thread.commentThread.id === focusedThreadId,
  );
  if (isFocusedCommentThreadUnresolved) {
    // Adopt a new focused thread if it is being displayed to update the scrolling position to the new one
    return focusedThreadId;
  }

  // Keep the last focused comment thread if some totally unrelated thread or resolved thread got the focus to keep the scrolling position
  return lastFocusedThreadId;
}

function getCollapsedSegmentTopOffset(thread: ICommentThreadWithLocation): number | null {
  if (!thread.componentPath) {
    return null;
  }

  // If comment is inside a collapsed component, its position defaults to that component (the closest content point to which the comment belongs)
  const collapsedComponentPosition = getDeepestCollapsedComponentPosition(thread.componentPath);
  if (collapsedComponentPosition !== null) {
    return collapsedComponentPosition;
  }

  // Alternatively, if comment is inside an expanded component but in a different content group than is currently selected, it also positions by default to that component
  const unselectedContentGroupInComponentPosition =
    getDeepestUnselectedContentGroupPositionInComponent(thread.componentPath);

  return unselectedContentGroupInComponentPosition;
}

export interface IThreadOffset {
  readonly threadId: Uuid;
  readonly relativeOffset: number;
}

interface IOffsetCalculationResult {
  focusedThreadId: Uuid | null;
  relativeOffsets: ReadonlyArray<IThreadOffset>;
  totalSpaceTaken: number;
}

export interface IInlineCommentPaneOwnProps {
  readonly scrollContainerRef?: React.RefObject<HTMLDivElement>;
  readonly threadListRef?: React.RefObject<HTMLDivElement>;
}

export interface IInlineCommentPaneStateProps {
  readonly contentGroupInlineCommentThreads: ReadonlyArray<ICommentThreadWithLocation>;
  readonly focusedCommentThreadId: Uuid | null;
  readonly getCommentThreadPosition: (commentThread: ICommentThread) => number | null;
  readonly selectedContentGroupId: Uuid | null;
}

export interface IInlineCommentPaneState {
  readonly allowAnimation: boolean;
  readonly allowDeferredPositionUpdate: boolean;
  readonly lastFocusedThreadId: Uuid | null;
  readonly processedCommentThreads: ReadonlyArray<ICommentThreadWithLocation>;
  readonly relativeThreadOffsets: ThreadOffsets;
  readonly selectedContentGroupId: Uuid | null;
  readonly threadRefs: ThreadRefs;
  readonly totalSpaceTaken: number | null;
}

export interface IInlineCommentPaneProps
  extends IInlineCommentPaneOwnProps,
    IInlineCommentPaneStateProps {}

export class InlineCommentPane extends React.PureComponent<
  IInlineCommentPaneProps,
  IInlineCommentPaneState
> {
  static displayName = 'InlineCommentPane';

  static propTypes: PropTypesShape<IInlineCommentPaneProps> = {
    // data props
    focusedCommentThreadId: PropTypes.string,
    getCommentThreadPosition: PropTypes.func.isRequired,
    contentGroupInlineCommentThreads: PropTypes.array.isRequired,
    scrollContainerRef: PropTypes.object,
    selectedContentGroupId: PropTypes.string,
    threadListRef: PropTypes.object,
  };

  private readonly debouncedPositionOnWindowResize: DebouncedFunction;
  private readonly threadRefs = new Map<Uuid, React.RefObject<HTMLDivElement>>();
  private positionHandlerDelay: ICancellablePromise | null = null;
  private readonly threadListRef = React.createRef<HTMLDivElement>();

  private readonly deferredUpdatePosition = waitUntilFocusAndScrollAreNotDeferred((): void => {
    if (this.state.allowDeferredPositionUpdate) {
      this.schedulePositionHandler(this.deferredUpdateThreadOffsets, 0);
    }
  }, [CommentThreadPositionerClassName]);

  private readonly deferredUpdateThreadOffsets = waitUntilFocusAndScrollAreNotDeferred(
    (): void => this.updateThreadOffsets(),
    [CommentThreadPositionerClassName],
  );

  static getDerivedStateFromProps(
    props: IInlineCommentPaneProps,
    state: IInlineCommentPaneState,
  ): Partial<IInlineCommentPaneState> {
    if (props.selectedContentGroupId !== state.selectedContentGroupId) {
      // When selected group changes reset state so that the new group comments appear at proper initial position without animation
      return {
        lastFocusedThreadId: props.focusedCommentThreadId,
        processedCommentThreads: [],
        relativeThreadOffsets: Immutable.Map<Uuid, IThreadOffset>(),
        selectedContentGroupId: props.selectedContentGroupId,
        totalSpaceTaken: null,
      };
    }

    const lastFocusedThreadId = getNewFocusedCommentThread(
      state.lastFocusedThreadId,
      props.focusedCommentThreadId,
      props.contentGroupInlineCommentThreads,
    );

    if (props.contentGroupInlineCommentThreads === state.processedCommentThreads) {
      return { lastFocusedThreadId };
    }

    const newThreadOffsets = keepSpaceAfterRemovedComments(
      state.relativeThreadOffsets,
      state.processedCommentThreads,
      props.contentGroupInlineCommentThreads,
      state.threadRefs,
    );

    return {
      allowAnimation: newThreadOffsets === state.relativeThreadOffsets,
      lastFocusedThreadId,
      processedCommentThreads: props.contentGroupInlineCommentThreads,
      relativeThreadOffsets: newThreadOffsets,
    };
  }

  constructor(props: IInlineCommentPaneProps) {
    super(props);

    this.debouncedPositionOnWindowResize = debounce(
      this.deferredUpdatePosition,
      WindowResizeAdjustInterval,
    );

    this.state = {
      allowAnimation: true,
      allowDeferredPositionUpdate: true,
      lastFocusedThreadId: props.focusedCommentThreadId,
      processedCommentThreads: [],
      relativeThreadOffsets: Immutable.Map<Uuid, IThreadOffset>(),
      selectedContentGroupId: props.selectedContentGroupId,
      threadRefs: this.threadRefs,
      totalSpaceTaken: null,
    };
  }

  componentDidMount(): void {
    this.deferredUpdatePosition();
    window.addEventListener('resize', this.debouncedPositionOnWindowResize);
  }

  componentDidUpdate(oldProps: IInlineCommentPaneProps) {
    if (this.handleNewComments(oldProps)) {
      return;
    }

    this.deferredUpdatePosition();
  }

  componentWillUnmount() {
    this.deferredUpdatePosition.cancel();
    this.deferredUpdateThreadOffsets.cancel();
    this.clearPositionHandler();

    window.removeEventListener('resize', this.debouncedPositionOnWindowResize);
    this.debouncedPositionOnWindowResize.cancel();
  }

  private readonly clearPositionHandler = () => {
    if (this.positionHandlerDelay) {
      this.positionHandlerDelay.cancel();
      this.positionHandlerDelay = null;
    }
  };

  private readonly schedulePositionHandler = (action: () => void, ms: number) => {
    this.clearPositionHandler();
    this.positionHandlerDelay = delay(ms)
      .then(() => {
        this.positionHandlerDelay = null;
        action();
        // When other position triggers are inactive, sync the position regularly to adapt to external changes such as (un)collapsing component etc.
        this.schedulePositionHandler(this.deferredUpdateThreadOffsets, PositionMaintenanceInterval);
      })
      .catch(swallowCancelledPromiseError);
  };

  private readonly handleNewComments = (oldProps: IInlineCommentPaneProps): boolean => {
    if (oldProps.selectedContentGroupId !== this.props.selectedContentGroupId) {
      return false;
    }

    const oldThreads = oldProps.contentGroupInlineCommentThreads;
    const unresolvedOldThreads = getUnresolvedCommentThreads(oldThreads);
    const unresolvedThreads = getUnresolvedCommentThreads(
      this.props.contentGroupInlineCommentThreads,
    );

    if (!threadsAddedOrRemoved(unresolvedThreads, unresolvedOldThreads)) {
      return false;
    }

    let offsetsWithNewComments: ReadonlyArray<IThreadOffset> = [];

    // If either old or new comments are empty, do not allow animation
    // this reduces the time to display / hide in case only active comments are displayed
    const allowAnimation = !!unresolvedOldThreads.length && !!unresolvedThreads.length;

    this.setState(
      // Render with space for new comments and let it animate
      (prevState) => {
        this.clearPositionHandler();

        const newOffsetCalculation = this.calculateThreadOffsets(
          prevState.lastFocusedThreadId,
          prevState.relativeThreadOffsets,
        );
        if (!newOffsetCalculation) {
          return null;
        }

        offsetsWithNewComments = newOffsetCalculation.relativeOffsets;

        let addExtraSpace = 0;
        const offsetsWithoutNewComments = offsetsWithNewComments
          .map((item) => {
            const isNewThread = !unresolvedOldThreads.find(
              (thread: ICommentThreadWithLocation) => thread.commentThread.id === item.threadId,
            );
            if (isNewThread) {
              // Add the necessary space to next item(s) offset to allocate the space before the new comment is displayed
              const threadHeight = getThreadHeight(this.threadRefs.get(item.threadId));
              if (threadHeight !== null) {
                const takenSpace = item.relativeOffset + threadHeight + DefaultCommentSpace;
                addExtraSpace += takenSpace;
              }

              return null;
            }

            if (addExtraSpace) {
              const withExtraSpace = {
                threadId: item.threadId,
                relativeOffset: item.relativeOffset + addExtraSpace,
              };
              addExtraSpace = 0;

              return withExtraSpace;
            }

            return item;
          })
          .filter((offset) => !!offset);

        return {
          allowAnimation,
          allowDeferredPositionUpdate: false,
          lastFocusedThreadId: newOffsetCalculation.focusedThreadId,
          relativeThreadOffsets: getUpdatedOffsets(
            prevState.relativeThreadOffsets,
            offsetsWithoutNewComments,
          ),
          totalSpaceTaken: newOffsetCalculation.totalSpaceTaken,
        };
      },
      // After the space is made and everything repositioned, re-render without animation and all comments displayed at their positions
      () => {
        this.schedulePositionHandler(
          () => {
            this.setState((prevState) => ({
              allowAnimation: false,
              allowDeferredPositionUpdate: true,
              relativeThreadOffsets: getUpdatedOffsets(
                prevState.relativeThreadOffsets,
                offsetsWithNewComments,
              ),
            }));
          },
          allowAnimation ? CommentPositionTransitionDuration : 0,
        );
      },
    );
    return true;
  };

  private readonly getThreadListTopOffset = (): number | null => {
    const list = (this.props.threadListRef ?? this.threadListRef).current;
    if (!list) {
      return null;
    }

    const scrollTop = this.props.scrollContainerRef?.current?.scrollTop ?? 0;
    const listTopOffset = getAbsoluteTopOffset(list.parentNode as HTMLElement);

    return listTopOffset ? Math.round(listTopOffset) + scrollTop : null;
  };

  private readonly calculateThreadOffsets = (
    lastFocusedThreadId: Uuid | null,
    knownThreadOffsets: ThreadOffsets,
  ): IOffsetCalculationResult | null => {
    let focusedThreadId = lastFocusedThreadId;

    const { contentGroupInlineCommentThreads, getCommentThreadPosition } = this.props;

    const listTopOffset = this.getThreadListTopOffset();
    if (!listTopOffset) {
      return null;
    }

    const relativeThreadOffsets: Array<IThreadOffset> = [];
    let totalSpaceTaken = 0;
    let someThreadRendered = false;

    const itemIsFalsyMessage = (index: number) => () =>
      `${__filename}: Item at index ${index} is falsy.`;

    contentGroupInlineCommentThreads.forEach((thread: ICommentThreadWithLocation) => {
      const commentThread = thread.commentThread;
      const threadId = commentThread.id;

      const commentedSegmentTopOffset = getCommentThreadPosition(thread.commentThread);

      // When focused comment position is no longer found, we let go the remembered focused comment
      // to prevent positioning back and forth in case of delete/undo or collapse/expand content component
      if (commentedSegmentTopOffset === null && threadId === focusedThreadId) {
        focusedThreadId = null;
      }

      const isFocused = threadId === focusedThreadId;
      const isResolved = isThreadResolved(commentThread);

      // Resolved thread is not rendered, but may still hold the position for other comments just after resolving until other thread is focused
      if (isResolved && !isFocused) {
        return;
      }

      const commentThreadTopOffset = Math.round(
        commentedSegmentTopOffset ||
          getCollapsedSegmentTopOffset(thread) ||
          // When some text with unresolved comment is deleted, the desired thread position may be unknown
          // In such case we fall back to its last known position to prevent it moving to 0 offset before it disappears
          this.getCommentThreadLastKnownTopOffset(
            listTopOffset,
            totalSpaceTaken,
            knownThreadOffsets,
            threadId,
          ),
      );

      const commentThreadRelativeOffset = commentThreadTopOffset - listTopOffset;
      const offsetTopDesiredPosition = commentThreadRelativeOffset - totalSpaceTaken;

      if (
        offsetTopDesiredPosition < 0 &&
        // If focused thread needs to be higher, try to push threads before it up
        (isFocused ||
          // If first found thread needs to be higher, try to push threads before it (threads without corresponding targets) up
          !someThreadRendered)
      ) {
        let remainingOffset = -offsetTopDesiredPosition;
        totalSpaceTaken -= remainingOffset;
        for (let i = relativeThreadOffsets.length - 1; i >= 0; i--) {
          const threadOffset = relativeThreadOffsets[i];
          assert(!!threadOffset, itemIsFalsyMessage(i));
          const previousOffset = threadOffset.relativeOffset;
          const newOffset =
            i === 0
              ? // First item can proceed with negative offset which determines the initial negative position of the whole comment threadListRef
                previousOffset - remainingOffset
              : // Other items cannot shrink their offset (extra space) to less than zero
                Math.max(previousOffset - remainingOffset, 0);
          relativeThreadOffsets[i] = {
            threadId: threadOffset.threadId,
            relativeOffset: newOffset,
          };
          remainingOffset -= previousOffset - newOffset;
          if (remainingOffset <= 0) {
            break;
          }
        }
      }

      // Resolved threads are not rendered, therefore do not occupy space
      if (isResolved) {
        return;
      }

      const threadHeight = getThreadHeight(this.threadRefs.get(threadId));
      if (!threadHeight) {
        return;
      }

      someThreadRendered = true;

      // Push thread to its correct next available position
      const relativeOffset = Math.max(offsetTopDesiredPosition, 0);

      totalSpaceTaken += relativeOffset + threadHeight + DefaultCommentSpace;

      relativeThreadOffsets.push({
        threadId,
        relativeOffset,
      });
    });

    return {
      focusedThreadId,
      relativeOffsets: relativeThreadOffsets,
      totalSpaceTaken,
    };
  };

  private getCommentThreadLastKnownTopOffset(
    listTopOffset: number,
    totalSpaceTaken: number,
    knownThreadOffsets: Immutable.Map<Uuid, IThreadOffset>,
    threadId: string,
  ): number {
    const lastKnownTopOffset =
      listTopOffset +
      totalSpaceTaken +
      (knownThreadOffsets.get(threadId) || { relativeOffset: 0 }).relativeOffset;
    return lastKnownTopOffset;
  }

  private readonly updateThreadOffsets = (): void => {
    this.setState((prevState) => {
      const newOffsetCalculation = this.calculateThreadOffsets(
        prevState.lastFocusedThreadId,
        prevState.relativeThreadOffsets,
      );
      if (!newOffsetCalculation) {
        return null;
      }

      const updatedOffsets = getUpdatedOffsets(
        prevState.relativeThreadOffsets,
        newOffsetCalculation.relativeOffsets,
      );

      return {
        allowAnimation: true,
        lastFocusedThreadId: newOffsetCalculation.focusedThreadId,
        relativeThreadOffsets: updatedOffsets,
        totalSpaceTaken: newOffsetCalculation.totalSpaceTaken,
      };
    });
  };

  private readonly onThreadFocused = () => {
    this.deferredUpdatePosition();
  };

  private readonly onThreadResized = (
    resizedThreadId: Uuid,
    oldHeight: number,
    newHeight: number,
  ): void => {
    const { contentGroupInlineCommentThreads, getCommentThreadPosition } = this.props;

    this.setState((prevState) => {
      // We need to compensate (without animation) for expanding / shrinking threads (mostly due to focus changes)
      // in order to prevent unwanted extra animated movement after some thread within the positioning chain is resized
      const sizeChange = newHeight - oldHeight;
      const unresolvedThreads = getUnresolvedCommentThreads(contentGroupInlineCommentThreads);

      let totalSpaceTaken = 0;
      let adjustNext = 0;

      const newThreadOffsets = unresolvedThreads.map((thread: ICommentThreadWithLocation) => {
        const threadId = thread.commentThread.id;

        const current = prevState.relativeThreadOffsets.get(threadId);
        if (!current) {
          return null;
        }

        const threadHeight = getThreadHeight(this.threadRefs.get(threadId));
        if (!threadHeight) {
          return null;
        }

        if (resizedThreadId === threadId) {
          adjustNext = -sizeChange;
          totalSpaceTaken += current.relativeOffset + threadHeight + DefaultCommentSpace;
          return current;
        }

        if (adjustNext < 0) {
          // Compensating for extra space taken by expanding thread
          const newOffset = Math.max(current.relativeOffset + adjustNext, 0);
          adjustNext += current.relativeOffset - newOffset;
          totalSpaceTaken += newOffset + threadHeight + DefaultCommentSpace;
          return {
            threadId,
            relativeOffset: newOffset,
          };
        }

        if (adjustNext > 0) {
          // Compensating for extra space made by shrinking thread
          const currentPosition = totalSpaceTaken + current.relativeOffset;
          const desiredPosition = getCommentThreadPosition(thread.commentThread);
          if (currentPosition && desiredPosition) {
            const targetPosition = Math.round(desiredPosition);

            const offsetToDesiredPosition = currentPosition - targetPosition;
            if (offsetToDesiredPosition < 0) {
              // If higher than it should be, adjust up to the maximum adjustment, but not too much to not get below the desired position
              const adjust = Math.min(adjustNext, -offsetToDesiredPosition);
              const newOffset = current.relativeOffset + adjust;
              totalSpaceTaken += newOffset + threadHeight + DefaultCommentSpace;
              adjustNext += current.relativeOffset - newOffset;
              return {
                threadId,
                relativeOffset: newOffset,
              };
            }
          }
        }

        totalSpaceTaken += current.relativeOffset + threadHeight + DefaultCommentSpace;
        return current;
      });

      return {
        allowAnimation: false,
        relativeThreadOffsets: getUpdatedOffsets(prevState.relativeThreadOffsets, newThreadOffsets),
      };
    });
  };

  private readonly getRefForThread = (threadId: Uuid): React.RefObject<any> => {
    const existingRef = this.threadRefs.get(threadId);
    if (existingRef) {
      return existingRef;
    }

    const newRef = React.createRef<HTMLDivElement>();
    this.threadRefs.set(threadId, newRef);
    return newRef;
  };

  render() {
    const { contentGroupInlineCommentThreads, threadListRef } = this.props;
    const { allowAnimation, relativeThreadOffsets, totalSpaceTaken } = this.state;

    return (
      <InlineCommentPaneView
        allowAnimation={allowAnimation}
        contentGroupInlineCommentThreads={contentGroupInlineCommentThreads}
        getRefForThread={this.getRefForThread}
        getUnresolvedCommentThreads={getUnresolvedCommentThreads}
        onThreadFocused={this.onThreadFocused}
        onThreadResized={this.onThreadResized}
        ref={threadListRef ?? this.threadListRef}
        relativeThreadOffsets={relativeThreadOffsets}
        minHeight={totalSpaceTaken ?? undefined}
      />
    );
  }
}
