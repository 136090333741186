import { VerticalTabs } from '@kontent-ai/component-library/VerticalTabs';
import React from 'react';
import { useLocation } from 'react-router';
import { LandmarkAriaLabels } from '../../../../_shared/constants/landmarkAriaLabels.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import {
  DataUiAppName,
  getDataUiNavAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { areAnyKeyTabsVisible } from '../../apiKeys/constants/ApiKeyTab.ts';
import { getVisibleApiKeyTabs } from '../../apiKeys/selectors/getVisibleApiKeyTabs.ts';
import { ProjectSettingsAppNames } from '../constants/ProjectSettingsAppNames.ts';
import {
  ApiKeyListingRouteSegment,
  DeliveryKeysRouteSegment,
  EnvironmentsRouteSegment,
  GeneralRouteSegment,
  ManagementKeysRouteSegment,
} from '../constants/routeSegments.ts';
import { isProjectGeneralSettingsTabVisible } from '../selectors/projectSettingsMenuCustomVisibilities.ts';

type ProjectSettingsMenu = {
  readonly url: string;
};

const getActiveItemId = (pathname: string) => {
  const lastRouteSegment = pathname.split('/').pop() ?? '';
  if (
    lastRouteSegment === ManagementKeysRouteSegment ||
    lastRouteSegment === DeliveryKeysRouteSegment
  ) {
    return ApiKeyListingRouteSegment;
  }

  return lastRouteSegment;
};

export const ProjectSettingsMenu: React.FC<ProjectSettingsMenu> = ({ url }) => {
  const { pathname } = useLocation();
  const activeItemId = getActiveItemId(pathname);

  const areGeneralSettingsVisible = useSelector(isProjectGeneralSettingsTabVisible);
  const isApiKeyListingVisible = useSelector((s) => areAnyKeyTabsVisible(getVisibleApiKeyTabs(s)));

  return (
    <VerticalTabs ariaLabel={LandmarkAriaLabels.ProjectSettings}>
      {areGeneralSettingsVisible && (
        <VerticalTabs.Item
          isActive={activeItemId === GeneralRouteSegment}
          label={ProjectSettingsAppNames.General}
          url={`${url}/${GeneralRouteSegment}`}
          {...getDataUiNavAttribute(DataUiAppName.General)}
        />
      )}
      <VerticalTabs.Item
        isActive={activeItemId === EnvironmentsRouteSegment}
        label={ProjectSettingsAppNames.Environments}
        url={`${url}/${EnvironmentsRouteSegment}`}
        {...getDataUiNavAttribute(DataUiAppName.Environments)}
      />
      {isApiKeyListingVisible && (
        <VerticalTabs.Item
          isActive={activeItemId === ApiKeyListingRouteSegment}
          label={ProjectSettingsAppNames.ApiKeyListing}
          url={`${url}/${ApiKeyListingRouteSegment}`}
          {...getDataUiNavAttribute(DataUiAppName.ApiKeys)}
        />
      )}
    </VerticalTabs>
  );
};

ProjectSettingsMenu.displayName = 'ProjectSettingsMenu';
