import React from 'react';
import { HorizontalTabs } from '../../../../component-library/components/StatusBar/HorizontalTabs/HorizontalTabs.tsx';
import { LandmarkAriaLabels } from '../../../_shared/constants/landmarkAriaLabels.ts';
import {
  ActiveProjectsRoute,
  ArchivedProjectsRoute,
} from '../../../_shared/constants/routePaths.ts';
import {
  DataUiAppName,
  DataUiCollection,
  DataUiElement,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
  getDataUiObjectNameAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

const myProjectsTabs = [
  {
    id: ActiveProjectsRoute,
    label: 'Active',
    objectName: DataUiAppName.ActiveProjects,
    to: ActiveProjectsRoute,
  },
  {
    id: ArchivedProjectsRoute,
    label: 'Archived',
    objectName: DataUiAppName.ArchivedProjects,
    to: ArchivedProjectsRoute,
  },
];

type Props = {
  readonly containerRef: React.RefObject<HTMLDivElement>;
};

export const MyProjectsTabs: React.FC<Props> = ({ containerRef }) => (
  <HorizontalTabs
    ariaLabel={LandmarkAriaLabels.MyProjects}
    containerRef={containerRef}
    getItemProps={(tab) => ({
      label: tab.label,
      pathname: tab.to,
      ...getDataUiElementAttribute(DataUiElement.NavigationTab),
      ...getDataUiObjectNameAttribute(tab.objectName),
    })}
    items={myProjectsTabs}
    {...getDataUiCollectionAttribute(DataUiCollection.TabItems)}
  />
);

MyProjectsTabs.displayName = 'MyProjectsTabs';
