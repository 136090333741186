import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getDroppedFiles, isDraggedFiles } from '../../../../_shared/utils/fileDragDropUtils.ts';
import { assetDropzoneNoPermissionText, assetDropzoneText } from '../constants/uiConstants.ts';
import { FileWithThumbnail } from '../models/FileWithThumbnail.type.ts';

type AllowedTagNames = 'div' | 'section';

interface IDropzoneStateProps {
  readonly onDrop: (files: ReadonlyArray<FileWithThumbnail>) => void;
  readonly disabled?: boolean;
  readonly className?: string;
  readonly tagName?: AllowedTagNames;
}

const propTypes: PropTypeMap<IDropzoneStateProps> = {
  onDrop: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  tagName: PropTypes.oneOf(['div', 'section']),
};

export const Dropzone: React.FC<React.PropsWithChildren<IDropzoneStateProps>> = (props) => {
  const [{ isActive }, connectDropTarget] = useDrop({
    accept: NativeTypes.FILE,
    collect: (monitor) => ({
      isActive: monitor.isOver() && monitor.canDrop(),
    }),
    drop: (dragObject) => {
      if (!props.disabled && isDraggedFiles(dragObject) && dragObject.files.length > 0) {
        const filesWithThumbnail = getDroppedFiles(dragObject.files);
        props.onDrop(filesWithThumbnail);
      }
    },
    options: useMemo(
      () => ({
        dropEffect: props.disabled ? 'none' : 'copy',
      }),
      [props.disabled],
    ),
  });

  const dropzoneText = props.disabled ? assetDropzoneNoPermissionText : assetDropzoneText;

  const TagName = props.tagName || 'div';
  return (
    <TagName
      ref={connectDropTarget}
      className={props.className || 'dropzone'}
      {...getDataUiElementAttribute(DataUiElement.UploadDropzone)}
    >
      {isActive && <div className="dropzone__overlay">{dropzoneText}</div>}
      {props.children}
    </TagName>
  );
};

Dropzone.displayName = 'Dropzone';
Dropzone.propTypes = propTypes;
