import { useCallback } from 'react';
import { BaseEditor } from '../editorCore/types/Editor.base.type.ts';
import { Editor } from './Editor.tsx';
import { EditorComposition, PluginCreator } from './types/Editor.composition.type.ts';
import { Contract, None, Props, State } from './types/Editor.contract.type.ts';

type PluginError = {
  error: string;
  requiredState: unknown;
  requiredProps: unknown;
};

type ChainWithError = ReadonlyArray<Contract | PluginError>;

type ValidatedPluginChain<
  Chain extends ReadonlyArray<PluginCreator<Contract, any[]>>,
  Base extends BaseEditor,
> = PluginChain<
  PluginsFromCreators<Chain>,
  State<Base>,
  Props<Base>
> extends infer ChainHasError extends { chainWithError: ChainWithError }
  ? // Some plugin has chaining error (missing dependencies)
    ParamsFromErrorChain<ChainHasError['chainWithError']>
  : Chain extends ReadonlyArray<PluginCreator>
    ? // Correctly composed editor
      Chain
    : ValidatedParameterlessPluginChain<Chain>;

type ValidatedParameterlessPluginChain<
  Chain extends ReadonlyArray<PluginCreator<Contract, any[]>>,
> = {
  [Index in keyof Chain]: Chain[Index] extends infer Creator extends PluginCreator
    ? PluginCreator<PluginFromCreator<Creator>>
    : // Some plugin with mandatory params is not parameterized
      PluginCreator & {
        error: 'To use plugin with mandatory parameters, wrap it with usePluginWithParams';
      };
};

type ParamsFromErrorChain<Plugins extends ChainWithError> = {
  [Index in keyof Plugins]: Plugins[Index] extends infer ParamHasError extends PluginError
    ? PluginCreator & ParamHasError
    : Plugins[Index] extends Contract
      ? PluginCreator<Plugins[Index]>
      : PluginCreator;
};

type PluginFromCreator<Creator extends PluginCreator<Contract, any[]>> =
  Creator extends PluginCreator<infer Plugin, any[]> ? Plugin : never;

type PluginsFromCreators<Creators extends ReadonlyArray<PluginCreator<Contract, any[]>>> = {
  [Index in keyof Creators]: PluginFromCreator<Creators[Index]>;
};

type PluginChain<
  TPlugins extends ReadonlyArray<Contract>,
  TAllState = None,
  TAllProps = None,
  TAllApi = None,
  TValidPluginChain extends any[] = [],
> = TPlugins extends readonly [
  [
    infer TState,
    infer TProps,
    infer TApi,
    infer TRequiredState,
    infer TRequiredProps,
    infer TRequiredApi,
  ],
  ...infer TRemainingPlugins extends ReadonlyArray<Contract>,
]
  ? [TAllState, TAllProps, TAllApi] extends [TRequiredState, TRequiredProps, TRequiredApi]
    ? PluginChain<
        TRemainingPlugins,
        TAllState & TState,
        TAllProps & TProps,
        TAllApi & TApi,
        [
          ...TValidPluginChain,
          [
            state: TState,
            props: TProps,
            api: TApi,
            requiredState: TRequiredState,
            requiredProps: TRequiredProps,
            requiredApi: TRequiredApi,
          ],
        ]
      >
    : {
        chainWithError: [
          ...TValidPluginChain,
          {
            error: 'This plugin has some missing dependencies';
            // We use keyof as a helper to get subtracted aliased types in the error output
            // with Omit, it outputs all involved types in a long chain in an expanded Omit expression which is not very readable
            requiredState: keyof Omit<TRequiredState, keyof TAllState>;
            requiredProps: keyof Omit<TRequiredProps, keyof TAllProps>;
            requiredApi: keyof Omit<TRequiredApi, keyof TAllApi>;
          },
          ...TRemainingPlugins,
        ];
      }
  : TValidPluginChain;

type CombineMany<
  Plugins extends ReadonlyArray<Contract>,
  Base extends Contract = BaseEditor,
> = Plugins extends readonly [
  [infer MoreProvidedState, infer MoreProvidedProps, infer MoreProvidedApi, any, any, any],
  ...infer Rest extends ReadonlyArray<Contract>,
]
  ? CombineMany<
      Rest,
      [
        Base[0] & MoreProvidedState,
        Base[1] & MoreProvidedProps,
        Base[2] & MoreProvidedApi,
        None,
        None,
        None,
      ]
    >
  : Base;

export const createEditorWithPlugins = <
  Base extends BaseEditor,
  PluginCreators extends ReadonlyArray<PluginCreator<Contract, any[]>>,
  FinalContract extends BaseEditor = CombineMany<PluginsFromCreators<PluginCreators>, Base>,
>(
  editor: EditorComposition<Base>,
  ...plugins: ValidatedPluginChain<PluginCreators, Base>
): EditorComposition<FinalContract> =>
  (plugins as ReadonlyArray<PluginCreator>).reduce(
    (composed, plugin) => plugin(composed) as EditorComposition<Base>,
    editor,
  ) as EditorComposition<FinalContract>;

type PluginCreatorParams<Plugin extends PluginCreator> = Plugin extends PluginCreator<
  Contract,
  infer TParams
>
  ? TParams
  : never;

export const usePluginWithParams = <Creator extends PluginCreator<Contract, any[]>>(
  plugin: Creator,
  ...params: PluginCreatorParams<Creator>
): PluginCreator<PluginFromCreator<Creator>> =>
  // biome-ignore lint/correctness/useExhaustiveDependencies(params): We need to spread params in dependencies because their number is not universally defined.
  useCallback((baseEditor) => plugin(baseEditor, ...params), [plugin, ...params]);

const baseComposition: EditorComposition<BaseEditor> = {
  ComposedEditor: Editor,
};

export const getBaseEditor = (): EditorComposition<BaseEditor> => baseComposition;
