import { usePrevious } from '@kontent-ai/hooks';
import { areArraysShallowEqual, createGuid } from '@kontent-ai/utils';
import classNames from 'classnames';
import Immutable from 'immutable';
import React, { useEffect, useMemo, useState } from 'react';
import { DragMoveHandler } from '../../../../../../../_shared/components/DragDrop/dragDrop.type.ts';
import { ModalViewer } from '../../../../../../../_shared/components/Modal/ModalViewer.tsx';
import { ModalViewerPosition } from '../../../../../../../_shared/components/Modal/ModalViewerPosition.ts';
import { DefaultCollectionId } from '../../../../../../../_shared/constants/variantIdValues.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useEventListener } from '../../../../../../../_shared/hooks/useEventListener.ts';
import { AssetUploadFinishedEventForLocalState } from '../../../../../../../_shared/utils/assets/AssetUploadFinishedEvent.ts';
import { triggerAssetUploadFinishedEvents } from '../../../../../../../_shared/utils/assets/assetUtils.ts';
import { isAssetLimited } from '../../../../../../../_shared/utils/assets/assetValidationUtils.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { CustomEventName } from '../../../../../../../_shared/utils/events/KontentEventMap.ts';
import { IAsset } from '../../../../../../../data/models/assets/Asset.ts';
import {
  IAssetUploadResult,
  IOnAssetFinished,
} from '../../../../../../contentInventory/assets/actions/thunks/createAssets.ts';
import { Dropzone } from '../../../../../../contentInventory/assets/components/UploadDropzone.tsx';
import { AssetsUploadToCollectionDialog } from '../../../../../../contentInventory/assets/containers/AssetListing/AssetsUploadToCollectionDialog.tsx';
import { ModalMultipleAssetsSelector } from '../../../../../../contentInventory/assets/features/ModalAssetSelector/containers/ModalMultipleAssetsSelector.tsx';
import { FileWithThumbnail } from '../../../../../../contentInventory/assets/models/FileWithThumbnail.type.ts';
import { CannotViewAssetsMessage } from '../../../../../../contentInventory/content/constants/cannotViewMessages.ts';
import { IAssetTypeElement } from '../../../../../../contentInventory/content/models/contentTypeElements/AssetTypeElement.ts';
import {
  AssetReference,
  IAssetItemElement,
} from '../../../../../models/contentItemElements/AssetItemElement.ts';
import { IAssetItemElementWarningResult } from '../../../../../utils/itemElementWarningCheckers/types/IAssetItemElementWarningResult.type.ts';
import { ModalAssetEditor } from '../../../../ModalAssetEdit/containers/ModalAssetEditor.tsx';
import { AssetRenditionDialog } from '../../../../ModalAssetRendition/AssetRenditionDialog.tsx';
import { AssetRenditionDialogDataEnsurer } from '../../../../ModalAssetRendition/AssetRenditionDialogDataEnsurer.tsx';
import { refreshItemAfterLinkedEntityChange } from '../../../actions/thunkContentItemEditingActions.ts';
import { getAssetValidationSettings } from '../../../containers/elements/asset/AssetTile.tsx';
import {
  areReferencesPointingToSameElement,
  useItemElementReference,
} from '../../../containers/hooks/useItemElementReference.ts';
import { ReadonlyEmptyElementPlaceholder } from '../../../models/ReadonlyEmptyElementPlaceholder.ts';
import {
  getAssetsForElement,
  insertAssetsIntoElementData,
  moveAssetInElementData,
  removeAssetFromElementData,
  replaceAssetInElementData,
} from '../../../utils/assetUtils.ts';
import { hasDefaultValue } from '../../../utils/defaultValueUtils.ts';
import { ContentItemElementStatus } from '../ContentItemElementStatus.tsx';
import { AssetLimitStatusMessage } from '../subComponents/limitInfoMessages/AssetLimitStatusMessage.tsx';
import { DefaultValueStatus } from '../subComponents/limitInfoMessages/DefaultValueStatus.tsx';
import {
  ItemElementLimitType,
  ItemLimitStatusMessage,
} from '../subComponents/limitInfoMessages/ItemLimitStatusMessage.tsx';
import { AssetPicker } from './AssetPicker.tsx';
import { DraggableAsset } from './DraggableAsset.tsx';

export interface IAssetComponentOwnProps {
  readonly elementData: IAssetItemElement;
  readonly typeElement: IAssetTypeElement;
  readonly disabled: boolean;
  readonly contentComponentId?: Uuid;
  readonly onChange: (elementData: IAssetItemElement) => void;
  readonly renderWrapper: (innerComponent: JSX.Element) => JSX.Element;
}

export interface IAssetComponentStateProps {
  readonly areCollectionsVisible: boolean;
  readonly assetsById: Immutable.Map<Uuid, IAsset>;
  readonly canViewAssets: boolean;
  readonly canCreateAssets: boolean;
  readonly currentlyUploadedAssets: Immutable.Map<Uuid, Uuid | null>;
  readonly validationResult?: IAssetItemElementWarningResult;
}

export interface IAssetComponentDispatchProps {
  readonly onAssetSelected: (assetIds: ReadonlyArray<Uuid>) => void;
  readonly onFilesUpload: (
    files: Map<Uuid, FileWithThumbnail>,
    collectionId: Uuid | null,
    onAssetFinished: IOnAssetFinished,
  ) => Promise<ReadonlyArray<IAssetUploadResult>>;
  readonly onPostprocessingAssetsFinished: (oldAssetIds: UuidArray) => void;
}

export type AssetComponentProps = IAssetComponentOwnProps &
  IAssetComponentStateProps &
  IAssetComponentDispatchProps;

const getAssetReferenceId = (assetReference: AssetReference): string => assetReference.id;

export const AssetComponent: React.FC<AssetComponentProps> = ({
  areCollectionsVisible,
  assetsById,
  canViewAssets,
  canCreateAssets,
  contentComponentId,
  currentlyUploadedAssets,
  disabled,
  elementData,
  onAssetSelected,
  onChange,
  onFilesUpload,
  onPostprocessingAssetsFinished,
  renderWrapper,
  typeElement,
  validationResult,
}) => {
  const dispatch = useDispatch();
  const isDefaultValueSet = hasDefaultValue(typeElement.defaultValue);
  const isCurrentValueDefault = areArraysShallowEqual(
    elementData.value.valueSeq().toArray().map(getAssetReferenceId),
    typeElement.defaultValue.map(getAssetReferenceId),
  );

  const element = useItemElementReference(typeElement);
  const [stateElementData, setStateElementData] = useState<IAssetItemElement>(elementData);
  const [draggedAssetId, setDraggedAssetId] = useState<Uuid | null>(null);
  const [editedAssetId, setEditedAssetId] = useState<Uuid | null>(null);
  const [editedRenditionAssetId, setEditedRenditionAssetId] = useState<Uuid | null>(null);

  const [openDialog, setOpenDialog] = useState<
    'select-asset' | 'edit-asset' | 'asset-rendition' | 'upload-asset' | null
  >(null);
  const previousStateElementData = usePrevious(stateElementData);

  const [assetsToUpload, setAssetsToUpload] = useState<ReadonlyArray<FileWithThumbnail>>([]);

  useEffect(() => {
    if (!Immutable.is(stateElementData.value, previousStateElementData.value)) {
      onChange(stateElementData);
    }
  }, [stateElementData, previousStateElementData, onChange]);

  const onDragStart = (draggedItemId: Uuid): void => setDraggedAssetId(draggedItemId);

  const onDragEnd = (): void => setDraggedAssetId(null);

  const closeModalDialog = () => setOpenDialog(null);

  const closeAssetUploadModalDialog = () => {
    setAssetsToUpload([]);
    closeModalDialog();
  };

  const showModalAssetEditor = (assetId: Uuid): void => {
    setEditedAssetId(assetId);
    setOpenDialog('edit-asset');
  };

  const openAssetSelectionDialog = (): void => setOpenDialog('select-asset');

  const openAssetRenditionDialog = (assetId: Uuid): void => {
    setEditedRenditionAssetId(assetId);
    setOpenDialog('asset-rendition');
  };

  const onAssetUploadFinished: IOnAssetFinished = (oldAssetId, newAsset) => {
    triggerAssetUploadFinishedEvents(oldAssetId, newAsset?.id ?? null, element);
    onPostprocessingAssetsFinished([oldAssetId]);
  };

  const handleAssetUploadFinishedEvent = (event: AssetUploadFinishedEventForLocalState): void => {
    const { oldAssetId, newAssetId, element: eventElement } = event.detail;

    if (element && eventElement && areReferencesPointingToSameElement(element, eventElement)) {
      event.stopPropagation();

      if (newAssetId) {
        setStateElementData((state) => replaceAssetInElementData(state, oldAssetId, newAssetId));
      }

      setEditedAssetId((currentEditedAssetId) =>
        currentEditedAssetId === oldAssetId ? newAssetId : currentEditedAssetId,
      );
    }
  };

  useEventListener(
    CustomEventName.assetUploadFinishedForLocalState,
    handleAssetUploadFinishedEvent,
    self,
  );

  const uploadFiles = async (
    files: ReadonlyArray<FileWithThumbnail>,
    collectionId: Uuid | null,
  ): Promise<void> => {
    if (!disabled) {
      const fileMap = new Map(files.map((file) => [createGuid(), file]));
      const newAssetsIds = Immutable.List<Uuid>(fileMap.keys());

      // Add the assets to the element before upload so the progress can be displayed.
      setStateElementData((state) => insertAssetsIntoElementData(newAssetsIds.toArray(), state));

      await onFilesUpload(fileMap, collectionId, onAssetUploadFinished);
    }
  };

  const beforeAssetUpload = (files: ReadonlyArray<FileWithThumbnail>) => {
    if (areCollectionsVisible) {
      setAssetsToUpload(files);
      setOpenDialog('upload-asset');
      return;
    }

    uploadFiles(files, DefaultCollectionId);
  };

  const moveAsset: DragMoveHandler = ({ sourceId, targetId }): void => {
    if (!disabled) {
      setStateElementData((state) => moveAssetInElementData(state, sourceId, targetId));
    }
  };

  const removeAsset = (assetId: Uuid): void => {
    if (!disabled) {
      setStateElementData((state) => removeAssetFromElementData(assetId, state));
    }
  };

  const addExistingAssets = (assetIds: Immutable.List<Uuid>): void => {
    if (!disabled) {
      closeModalDialog();
      setStateElementData((state) => insertAssetsIntoElementData(assetIds.toArray(), state));
      onAssetSelected(assetIds.toArray());
    }
  };

  const onRenditionCreated = (renditionId: Uuid): void => {
    if (!disabled && !!editedRenditionAssetId) {
      const updatedElementValue = stateElementData.value.update(
        editedRenditionAssetId,
        (oldAssetReference) => ({
          ...oldAssetReference,
          renditions: [{ id: renditionId }],
        }),
      );

      closeModalDialog();
      setStateElementData((oldElement) => ({ ...oldElement, value: updatedElementValue }));
    }
  };

  const removeRendition = (): void => {
    if (!disabled && !!editedRenditionAssetId) {
      const updatedElementValue = stateElementData.value.update(
        editedRenditionAssetId,
        (oldAssetReference) => ({
          ...oldAssetReference,
          renditions: [],
        }),
      );

      closeModalDialog();
      setStateElementData((oldElement) => ({ ...oldElement, value: updatedElementValue }));
    }
  };

  const validationSettings = useMemo(() => getAssetValidationSettings(typeElement), [typeElement]);

  const renderAssetTiles = (): JSX.Element[] => {
    const { elementId, value } = stateElementData;

    return getAssetsForElement(value, assetsById, currentlyUploadedAssets)
      .map((asset: IAsset) => {
        const assetId = asset.id;

        return (
          <DraggableAsset
            key={assetId}
            assetId={assetId}
            renditionId={value.get(assetId)?.renditions[0]?.id}
            contentComponentId={contentComponentId ?? null}
            onDragStart={() => onDragStart(assetId)}
            onDragEnd={onDragEnd}
            onMove={moveAsset}
            onOpenRenditionDialog={() => openAssetRenditionDialog(assetId)}
            onRemove={() => removeAsset(assetId)}
            onEdit={() => showModalAssetEditor(assetId)}
            disabled={disabled}
            elementId={elementId}
            validationSettings={validationSettings}
          />
        );
      })
      .toArray();
  };

  const renderLimitStatusMessage = (): JSX.Element | null => {
    const maxItems = typeElement.maxItems;
    const minItems = typeElement.minItems;
    const isNumberOfItemsLimited = !!minItems || !!maxItems;
    const assetLimited = isAssetLimited(typeElement);
    const isLimited = isNumberOfItemsLimited || assetLimited;

    if (!isLimited) {
      return null;
    }

    return (
      <ContentItemElementStatus>
        <div className="content-item-element__status-pane">
          <span>Element parameters: </span>
          <AssetLimitStatusMessage
            limitations={typeElement}
            validationResult={validationResult}
            prefix="Images"
          />
          {isNumberOfItemsLimited && (
            <span className="content-item-element__status-segment">
              <ItemLimitStatusMessage
                min={minItems}
                max={maxItems}
                type={ItemElementLimitType.AssetCount}
                isLimitValueMet={!!validationResult && validationResult.isNumberOfItemsLimitMet}
              />
            </span>
          )}
        </div>
      </ContentItemElementStatus>
    );
  };

  const renderAssetComponent = (): JSX.Element => {
    const isEmpty = elementData.value.isEmpty();
    const editedRenditionId: Uuid | null =
      elementData.value.find((a: AssetReference) => a.id === editedRenditionAssetId)?.renditions[0]
        ?.id ?? null;

    const assetSelector = (
      <ModalMultipleAssetsSelector
        showImagesOnly={false}
        titleBarText={`Insert assets to ${typeElement.name} (Asset element)`}
        limitations={typeElement}
        onClose={closeModalDialog}
        onSelect={addExistingAssets}
        primaryButtonText="Insert"
      />
    );

    const assetEditor = editedAssetId && (
      <ModalAssetEditor
        key={editedAssetId}
        assetId={editedAssetId}
        onAssetEditingFinished={closeModalDialog}
      />
    );

    return (
      <div className="flex flex--column">
        {!disabled && (
          <AssetPicker
            onUpload={canCreateAssets ? beforeAssetUpload : null}
            onPick={openAssetSelectionDialog}
            pickerDisabledTooltipMessage={canViewAssets ? undefined : CannotViewAssetsMessage}
          >
            Pick from Assets
          </AssetPicker>
        )}
        {disabled && isEmpty && (
          <div className="content-item-element__placeholder">
            {ReadonlyEmptyElementPlaceholder.AssetElement}
          </div>
        )}
        <div
          className={classNames({
            'u-spacing-top-l': !isEmpty,
          })}
        >
          <div
            className={classNames('row', {
              'asset__list--is-dragging': !!draggedAssetId,
            })}
            {...getDataUiCollectionAttribute(DataUiCollection.Assets)}
          >
            {renderAssetTiles()}
          </div>
        </div>
        <DefaultValueStatus
          isStatusRendered={isDefaultValueSet && !disabled}
          isValueDefault={isCurrentValueDefault}
        />
        {renderLimitStatusMessage()}
        <ModalViewer
          dialogClassName="dialog"
          isDialogVisible={openDialog === 'select-asset'}
          onClose={closeModalDialog}
          position={ModalViewerPosition.Center}
        >
          {assetSelector}
        </ModalViewer>
        <ModalViewer
          dialogClassName="dialog"
          isDialogVisible={openDialog === 'edit-asset'}
          onClose={closeModalDialog}
          position={ModalViewerPosition.Center}
        >
          {assetEditor}
        </ModalViewer>
        {openDialog === 'upload-asset' && assetsToUpload.length > 0 && (
          <AssetsUploadToCollectionDialog
            handleClose={closeAssetUploadModalDialog}
            handleSelect={(collectionId) => uploadFiles(assetsToUpload, collectionId)}
          />
        )}
        <AssetRenditionDialogDataEnsurer
          assetId={editedRenditionAssetId}
          isOpen={openDialog === 'asset-rendition'}
          key={editedAssetId}
          renditionId={editedRenditionId}
          renderDialog={(isDataLoading, asset, existingRendition) => (
            <AssetRenditionDialog
              asset={asset}
              existingRendition={existingRendition}
              isDataLoading={isDataLoading}
              isOpen={openDialog === 'asset-rendition'}
              limitations={typeElement}
              onClose={closeModalDialog}
              onCreated={onRenditionCreated}
              onRemove={removeRendition}
              onUpdated={() => {
                closeModalDialog();
                dispatch(refreshItemAfterLinkedEntityChange());
              }}
            />
          )}
        />
      </div>
    );
  };

  return (
    <Dropzone disabled={disabled || !canCreateAssets} onDrop={beforeAssetUpload}>
      {renderWrapper(renderAssetComponent())}
    </Dropzone>
  );
};

AssetComponent.displayName = 'AssetComponent';
