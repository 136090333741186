import { ActiveCapabilityType } from '../../_shared/models/activeCapability.type.ts';
import { untitledContentItem } from '../../applications/contentInventory/content/constants/uiConstants.ts';
import { PublishingState } from '../../data/constants/PublishingState.ts';
import { JsonPatchOperation } from '../utils/jsonPatchConstants.ts';
import { IContentItemVariantReferenceServerModel } from './ContentItemUsageModel.type.ts';
import {
  IAssignmentCreationServerModel,
  IAssignmentFullServerModel,
} from './IAssignmentServerModel.type.ts';
import { IContentItemElementServerModel } from './elementServerModels.type.ts';

export interface IContentItemWithVariantsServerModel {
  readonly item: IContentItemServerModel;
  readonly variants: Array<IContentItemVariantReferenceServerModel>;
}

export interface IContentItemWithVariantServerModel {
  readonly item: IContentItemServerModel;
  readonly variant: IContentItemVariantServerModel;
  readonly activeCapabilities: IActiveCapabilitiesServerModel;
}

export interface IActiveCapabilitiesForVariantServerModel {
  readonly variantCapabilities: ReadonlyArray<ActiveCapabilityType>;
}

export interface IActiveCapabilitiesServerModel extends IActiveCapabilitiesForVariantServerModel {
  readonly contentGroupsForViewCapability: UuidArray;
  readonly contentGroupsForUpdateCapability: UuidArray;
}

export interface INewContentItemServerModel {
  readonly name: string;
  readonly collectionId: Uuid;
  readonly codeName: string;
  readonly type: IContentTypeReferenceServerModel;
  readonly sitemapLocation: Array<Uuid>;
}

export interface IDuplicateContentItemServerModel {
  readonly destinationCollectionId: Uuid;
}

export interface IContentItemServerModel extends INewContentItemServerModel {
  readonly id: Uuid;
  readonly lastModified: DateTimeStamp | null;
  readonly lastModifiedBy: string;
  readonly contentLastUpdated: DateTimeStamp | null;
  readonly contentLastUpdatedBy: string;
  readonly archived: boolean;
  readonly spaceIds: UuidArray;
}

export interface IContentTypeReferenceServerModel {
  readonly _id: Uuid;
}

export interface ICollectionReferenceServerModel {
  readonly id: Uuid;
}

export interface IContentItemIdentifierServerModel {
  readonly itemId: Uuid;
  readonly variantId: Uuid;
}

export type ContentItemElementPatchPath = `/contentElements/${string}`;
export type ContentItemPatchPath =
  | '/codename'
  | '/collection'
  | ContentItemElementPatchPath
  | '/name'
  | '/sitemapLocation'
  | '/assignment';

export interface IContentItemPatchServerModel {
  readonly op: JsonPatchOperation;
  readonly path: ContentItemPatchPath;
  readonly value?: any;
}

interface IContentItemVariantServerModelBase {
  readonly id: IContentItemIdentifierServerModel;
  readonly contentElements: ReadonlyArray<IContentItemElementServerModel>;
  readonly lastModified: DateTimeStamp | null;
  readonly lastModifiedBy: string;
  readonly contentLastUpdated: DateTimeStamp | null;
  readonly contentLastUpdatedBy: string;
  readonly lastPublishedAt: DateTimeStamp | null;
  readonly lastPublishedBy: string;
  readonly publishingState: PublishingState;
  readonly archived: boolean;
  readonly isComplete: boolean;
}

export interface IContentItemVariantServerModel extends IContentItemVariantServerModelBase {
  readonly assignment: IAssignmentFullServerModel;
  readonly contentLinkIds: ReadonlyArray<IContentItemIdentifierServerModel>;
  readonly modularItemIds: ReadonlyArray<IContentItemIdentifierServerModel>;
}

export interface IContentItemVariantCreationServerModel extends IContentItemVariantServerModelBase {
  readonly assignment: IAssignmentCreationServerModel;
}

export type RequiredItemCreationParams = {
  readonly collectionId: Uuid;
  readonly typeId: Uuid;
  readonly name?: string;
};

export const createEmptyContentItem = ({
  typeId,
  collectionId,
  name = untitledContentItem,
}: RequiredItemCreationParams): INewContentItemServerModel => ({
  name,
  collectionId,
  codeName: '',
  type: {
    _id: typeId,
  },
  sitemapLocation: [],
});

type CreateVariantParams = {
  readonly itemId: Uuid;
  readonly variantId: Uuid;
  readonly contentElements: IContentItemElementServerModel[];
  readonly contentLastUpdatedBy: string;
  readonly assignment: IAssignmentCreationServerModel;
};

export const createEmptyContentItemVariant = ({
  itemId,
  variantId,
  contentElements,
  contentLastUpdatedBy,
  assignment,
}: CreateVariantParams): IContentItemVariantCreationServerModel => ({
  id: {
    variantId,
    itemId,
  },
  contentElements,
  lastModified: null,
  lastModifiedBy: contentLastUpdatedBy,
  contentLastUpdated: null,
  contentLastUpdatedBy,
  lastPublishedAt: null,
  lastPublishedBy: '',
  publishingState: PublishingState.None,
  assignment,
  archived: false,
  isComplete: false,
});
