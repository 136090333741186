import React, { useEffect } from 'react';
import { modalOpened } from '../../actions/sharedActions.ts';
import { ModalDialogType } from '../../constants/modalDialogType.ts';
import { useUserFeedback } from '../../hooks/UserFeedback/useUserFeedback.ts';
import { useDispatch } from '../../hooks/useDispatch.ts';
import { useSelector } from '../../hooks/useSelector.ts';
import { UserFeedbackServerKey } from '../../models/UserPropertiesServerKeys.ts';
import { UserFeedbackSource } from '../../models/events/UserFeedbackEventData.type.ts';
import {
  helpUsImproveUserPropertiesSelector,
  shouldShowHelpUsImproveSurveyAutomatically,
} from '../../selectors/umuxSurveySelectors.ts';

export const ShowHelpUsImproveSurveyDialogAutomatically: React.FC = () => {
  const shouldShowDialog = useSelector(shouldShowHelpUsImproveSurveyAutomatically);
  const dispatch = useDispatch();
  const { showUserFeedback } = useUserFeedback(
    UserFeedbackSource.HelpUsImprove,
    UserFeedbackServerKey,
    helpUsImproveUserPropertiesSelector,
  );

  useEffect(() => {
    if (shouldShowDialog) {
      showUserFeedback();
      dispatch(modalOpened(ModalDialogType.HelpUsImproveSurveyDialog));
    }
  }, [shouldShowDialog, showUserFeedback]);

  return null;
};

ShowHelpUsImproveSurveyDialogAutomatically.displayName =
  'ShowHelpUsImproveSurveyDialogAutomatically';
