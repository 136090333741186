import React, { useEffect } from 'react';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { leaveAuditLog } from '../actions/auditLogBaseActions.ts';
import { searchAuditEvents } from '../actions/thunkAuditLogActions.ts';
import { AuditLog as AuditLogComponent } from '../components/AuditLog.tsx';
import { isFilterSet as isFilterSetFunc } from '../models/AuditEventFilter.ts';

export const AuditLog: React.FC = () => {
  const dispatch = useDispatch();
  const hasAnyResults = useSelector((state: IStore) => state.data.auditLog.hasAnyResults);
  const areEventsLoaded = useSelector(
    (state: IStore) => state.data.auditLog.loadingStatus === LoadingStatus.Loaded,
  );
  const isFilterSet = useSelector((state: IStore) =>
    isFilterSetFunc(state.auditLogApp.auditLogFilter),
  );

  useEffect(() => {
    dispatch(searchAuditEvents());

    return () => {
      dispatch(leaveAuditLog());
    };
  }, []);

  if (!hasAnyResults && !areEventsLoaded) {
    return <Loader />;
  }

  return (
    <AuditLogComponent
      areEventsLoaded={areEventsLoaded}
      hasAnyResults={hasAnyResults}
      isFilterSet={isFilterSet}
    />
  );
};

AuditLog.displayName = 'AuditLog';
