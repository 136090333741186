import { Hint } from '@kontent-ai/component-library/Hint';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { TextFilter } from '../../../../_shared/components/TextFilter.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { updateSearchPhrase } from '../actions/auditLogFilterActions.ts';
import {
  AvailableSearchPhrasesHelpMessage,
  MaxSearchPhraseLength,
} from '../constants/auditLogProperties.ts';

export interface IAuditLogPhraseSearchProps {
  readonly autofocus?: boolean;
}

const propTypes: PropTypesShape<IAuditLogPhraseSearchProps> = {
  autofocus: PropTypes.bool,
};

export const AuditLogPhraseSearch: React.FC<IAuditLogPhraseSearchProps> = ({ autofocus }) => {
  const auditLogFilter = useSelector((state: IStore) => state.auditLogApp.auditLogFilter);
  const dispatch = useDispatch();

  const searchPhrase = auditLogFilter.searchPhrase;
  const onSearchPhraseChanged = useCallback((newSearchPhrase: string) => {
    dispatch(updateSearchPhrase(newSearchPhrase));
  }, []);

  return (
    <TextFilter
      text={searchPhrase || ''}
      onChange={onSearchPhraseChanged}
      className="audit-log__filter__search-phrase"
      autofocus={autofocus}
      placeholder="Try searching by user, e-mail, or a type of action, such as “delete”"
      maxLength={MaxSearchPhraseLength}
      hideClearButton
      extraIconsAfterReset={[
        <Hint
          key="search-phrase-help"
          tooltipPlacement="bottom-end"
          tooltipText={AvailableSearchPhrasesHelpMessage}
        />,
      ]}
    />
  );
};

AuditLogPhraseSearch.displayName = 'AuditLogPhraseSearch';
AuditLogPhraseSearch.propTypes = propTypes;
