import { Collection } from '@kontent-ai/utils';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { isCollectionsConfigurationVisible } from '../../../../_shared/selectors/contentCollections.ts';
import { isAssetTypeEnabled as isAssetTypeEnabledSelector } from '../../../../_shared/selectors/enhancedAssetManagement.ts';
import { createNewRoleLink } from '../../../../_shared/utils/routing/projectSubscriptionRoutingUtils.ts';
import {
  getCurrentProjectPlan,
  getCurrentProjectSubscription,
} from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { isSitemapEnabled as isSitemapEnabledUtil } from '../../../contentModels/sitemap/utils/sitemapUtils.ts';
import {
  areSpacesEnabledForCurrentProject,
  isAuditLogEnabledForCurrentProjectPlan,
} from '../../selectors/allowedFeaturesUtils.ts';
import { initRoleEditor, updateRole } from '../actions/thunkRolesActions.ts';
import { RoleEditor as RoleEditingComponent } from '../components/RoleEditor.tsx';
import { RoleEditorStatus } from '../models/RoleEditorStatus.ts';
import { getCustomRolesLimitInfo } from '../selectors/customRoleLimit.ts';
import { areCustomRolesOverLimit } from '../utils/customRolesUtils.ts';
import { isLoaded } from '../utils/roleEditorStatusUtils.ts';

interface IContentRoleEditorProps {
  readonly projectId: Uuid;
  readonly roleId: Uuid;
  readonly withSubscriptionIdInRoute?: Uuid;
}

export const RoleEditor: React.FC<IContentRoleEditorProps> = ({
  projectId,
  roleId,
  withSubscriptionIdInRoute,
}) => {
  const status = useSelector((s) => s.rolesApp.editorUi.status);
  const editedRoleName = useSelector((s) => s.rolesApp.editorUi.editedRole.name);
  const isReadonly = useSelector((s) => {
    const areRolesOverLimit = areCustomRolesOverLimit(getCustomRolesLimitInfo(s));
    return areRolesOverLimit || s.rolesApp.editorUi.editedRole.isReadonly;
  });
  const isSitemapEnabled = useSelector((s) =>
    isSitemapEnabledUtil(getCurrentProjectSubscription(s)),
  );
  const isAuditLogEnabled = useSelector(isAuditLogEnabledForCurrentProjectPlan);
  const areCollectionsEnabled = useSelector((s) =>
    isCollectionsConfigurationVisible(s, Collection.getValues(s.data.collections.byId)),
  );
  const isAssetTypeEnabled = useSelector((s) =>
    isAssetTypeEnabledSelector(getCurrentProjectPlan(s), s.data.assetTypes.defaultAssetType),
  );
  const areSpacesEnabled = useSelector(areSpacesEnabledForCurrentProject);
  const isLoading = !isLoaded(status);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initRoleEditor({ roleId, history }));
  }, [history, roleId]);

  const save = (onSuccess?: () => void, onFail?: () => void) =>
    dispatch(updateRole({ onSuccess, onFail }));

  const onControlSHandler = (e: KeyboardEvent): void => {
    e.preventDefault();

    if (!isReadonly && status !== RoleEditorStatus.IsBeingSaved) {
      save();
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <RoleEditingComponent
      areCollectionsEnabled={areCollectionsEnabled}
      areSpacesEnabled={areSpacesEnabled}
      editedRoleName={editedRoleName}
      isAssetTypeEnabled={isAssetTypeEnabled}
      isAuditLogEnabled={isAuditLogEnabled}
      isRoleBeingCreated={false}
      isSitemapEnabled={isSitemapEnabled}
      newRoleLink={createNewRoleLink({
        projectId,
        subscriptionId: withSubscriptionIdInRoute,
      })}
      onControlSHandler={onControlSHandler}
      onSave={save}
      projectId={projectId}
      readonly={isReadonly}
      status={status}
    />
  );
};

RoleEditor.displayName = 'RoleEditor';
