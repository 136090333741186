import { History } from 'history';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { ContentItemRevisionViewerRouteParams } from '../../../../../../_shared/constants/routePaths.ts';
import {
  getPathWithReplacedTimelineItemId,
  isTimelineCompareRoute,
} from '../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { TimelineItem } from '../../../../models/revisions/TimeLineItem.ts';
import { InitContentItemEditing } from '../../../ContentItemEditing/actions/thunks/initContentItemEditing.ts';
import { getTimelineItemPathsMap } from '../../selectors/getTimelineItemPathsMap.ts';
import { fetchTimelineUntilRevisionFound } from '../../utils/fetchTimelineItemsUtils.ts';
import { getSelectedItemOrFallbackWithRevisionId } from '../../utils/revisionUtils.ts';

interface IDeps {
  readonly initContentItemEditing: InitContentItemEditing;
  readonly loadRevision: (
    contentItemId: Uuid,
    timelineItem: TimelineItem,
    abortSignal?: AbortSignal,
  ) => ThunkPromise;
}

export interface ICreateInitViewRevisionParams {
  readonly history: History;
  readonly routeParams: ContentItemRevisionViewerRouteParams<string>;
  readonly contentItemId: Uuid;
  readonly timelineItemId: Uuid;
}

export const createInitViewRevisionAction =
  (deps: IDeps) =>
  (
    { contentItemId, timelineItemId, history, routeParams }: ICreateInitViewRevisionParams,
    abortSignal?: AbortSignal,
  ): ThunkPromise =>
  async (dispatch, getState) => {
    const timeline = await fetchTimelineUntilRevisionFound(
      dispatch,
      getState,
      contentItemId,
      timelineItemId,
      abortSignal,
    );
    const timelineItem = getSelectedItemOrFallbackWithRevisionId(timeline, timelineItemId);

    if (timelineItem.itemId === timelineItemId) {
      await dispatch(deps.loadRevision(contentItemId, timelineItem, abortSignal));
      return;
    }

    const redirectRoute = isTimelineCompareRoute(history.location.pathname)
      ? getPathWithReplacedTimelineItemId(history.location.pathname, timelineItem.itemId)
      : getTimelineItemPathsMap(timeline, routeParams)?.get(timelineItem.itemId);

    if (redirectRoute) {
      history.push(redirectRoute);
    }
  };
