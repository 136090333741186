import { memoize } from '@kontent-ai/memoization';
import { alphabetically } from '@kontent-ai/utils';
import Immutable from 'immutable';
import React from 'react';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { UsageItemReference } from '../../../../../../_shared/utils/usage/usageUtils.ts';
import { IActiveCapabilitiesForVariant } from '../../../../../../data/models/activeCapabilities.ts';
import { ContentItemUsages } from '../../../../../../data/reducers/listingContentItems/IListingContentItems.type.ts';
import { IContentItemVariantReference } from '../../../../models/contentItem/ContentItemVariantReference.ts';
import { EditedContentItemVariant } from '../../../../models/contentItem/edited/EditedContentItemVariant.ts';
import { ICompiledContentItemElementData } from '../../../../models/contentItemElements/ICompiledContentItemElement.ts';
import { getNotEmptyItemName } from '../../../../stores/utils/contentItemHelperMethods.ts';
import {
  ReferenceType,
  getReferencedContentItemIds,
} from '../../../../utils/itemReferences/itemElementReferencesUtils.ts';
import { UsagesSection as UsagesSectionComponent } from '../../components/details/UsagesSection.tsx';

// Check if current item is used as modular link in rich text or modular element
const isEditedItemReferencingItself = (
  elements: ReadonlyArray<ICompiledContentItemElementData>,
  itemId: Uuid,
): boolean => {
  return getReferencedContentItemIds(elements, ReferenceType.ModularItemsAndTextLinks).contains(
    itemId,
  );
};

const reduceEditedVariantToItemReference = memoize.maxOne(
  (
    variant: EditedContentItemVariant,
    name: string,
    typeId: Uuid,
    activeCapabilities: IActiveCapabilitiesForVariant,
  ): IContentItemVariantReference => ({
    activeCapabilities,
    assignment: variant.assignment,
    id: variant.id,
    isArchived: variant.isArchived,
    isPublishedVersion: false,
    name: getNotEmptyItemName(name),
    publishingState: variant.publishingState,
    typeId,
  }),
);

const createSelfReferenceIfNecessary = (
  editedItemName: string,
  editedItemTypeId: Uuid,
  editedContentItemVariant: EditedContentItemVariant,
  elements: ReadonlyArray<ICompiledContentItemElementData>,
  activeCapabilities: IActiveCapabilitiesForVariant,
): IContentItemVariantReference | null => {
  const isEditedItemSelfLinked = isEditedItemReferencingItself(
    elements,
    editedContentItemVariant.id.itemId,
  );
  return isEditedItemSelfLinked
    ? reduceEditedVariantToItemReference(
        editedContentItemVariant,
        editedItemName,
        editedItemTypeId,
        activeCapabilities,
      )
    : null;
};

const getEditedContentItemUsages = memoize.maxOne(
  (
    editedItemId: Uuid,
    contentItemUsages: ContentItemUsages,
    potentialSelfReference: IContentItemVariantReference | null,
  ): Immutable.Map<Uuid, UsageItemReference> => {
    const usages: Immutable.Map<Uuid, UsageItemReference> =
      contentItemUsages.get(editedItemId) || Immutable.Map<Uuid, UsageItemReference>();

    const serverSelfLinkUsage = usages.get(editedItemId);
    const serverSelfLinkUsageInPublishedVersion =
      (serverSelfLinkUsage &&
        (serverSelfLinkUsage.secondary ||
          (serverSelfLinkUsage.primary.isPublishedVersion && serverSelfLinkUsage.primary))) ||
      null;

    // Reinsert currently edited item in used in section (name and workflow status could change)
    if (potentialSelfReference) {
      return usages.update(
        editedItemId,
        (): UsageItemReference => ({
          primary: potentialSelfReference,
          secondary: serverSelfLinkUsageInPublishedVersion,
        }),
      );
    }

    if (serverSelfLinkUsage) {
      if (!serverSelfLinkUsageInPublishedVersion) {
        return usages.remove(editedItemId);
      }

      return usages.update(
        editedItemId,
        (): UsageItemReference => ({
          primary: serverSelfLinkUsageInPublishedVersion,
          secondary: null,
        }),
      );
    }

    return usages;
  },
);

const sortUsages = memoize.maxOne(
  (
    usages: Immutable.OrderedMap<Uuid, UsageItemReference>,
  ): Immutable.OrderedMap<Uuid, UsageItemReference> => {
    return usages
      .sort((a: UsageItemReference, b: UsageItemReference) =>
        alphabetically(a.primary.name, b.primary.name),
      )
      .toOrderedMap();
  },
);

export const UsagesSection: React.FC = () => {
  const contentItemId = useSelector((state) => {
    const {
      contentApp: { editedContentItem },
    } = state;
    return editedContentItem?.id ?? null;
  });

  const usages = useSelector((state) => {
    const {
      contentApp: {
        editedContentItem,
        editedContentItemVariant,
        editedContentItemVariantElements,
        editorUi: { activeCapabilities },
      },
      data: {
        listingContentItems: { contentItemUsages },
      },
    } = state;

    if (!editedContentItem || !editedContentItemVariant) {
      return null;
    }

    const potentialSelfReference = createSelfReferenceIfNecessary(
      editedContentItem.name,
      editedContentItem.editedContentItemTypeId,
      editedContentItemVariant,
      editedContentItemVariantElements,
      activeCapabilities,
    );
    return sortUsages(
      getEditedContentItemUsages(editedContentItem.id, contentItemUsages, potentialSelfReference),
    );
  });

  if (!usages || !contentItemId) {
    return null;
  }

  return <UsagesSectionComponent contentItemId={contentItemId} usages={usages} />;
};

UsagesSection.displayName = 'UsagesSectionContainer';
