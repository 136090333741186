import { PortalContainerContext } from '@kontent-ai/component-library/context';
import { spacingPopupDistance } from '@kontent-ai/component-library/tokens';
import { useOverlay } from '@react-aria/overlays';
import { mergeProps } from '@react-aria/utils';
import { animated, useTransition } from '@react-spring/web';
import Tippy, { TippyProps } from '@tippyjs/react';
import PropTypes from 'prop-types';
import React, { useContext, useRef } from 'react';
import { useUpdateTippyWithScroll } from '../../hooks/useUpdateTippyWithScroll.ts';
import { IDropdownTippyOptions, defaultDropdownTippyOptions } from './dropDownTippyOptions.ts';

export type CommonDropDownPositionerProps = {
  readonly allowAnimation?: boolean;
  readonly tippyOptions?: IDropdownTippyOptions;
};

export const commonDropDownPositionerPropTypes: PropTypesShape<CommonDropDownPositionerProps> = {
  allowAnimation: PropTypes.bool,
  tippyOptions: PropTypes.object,
};

export type ExtraDropDownPositionerProps = {
  readonly isOptionListVisible: boolean;
  readonly onClickOutside?: () => void;
  readonly renderContent: () => React.ReactNode;
  readonly renderSelector: (ref: React.RefObject<HTMLElement>) => React.ReactNode;
  readonly shouldCloseOnClickOutside?: (element: Element) => boolean;
};

export const extraDropDownPositionerPropTypes: PropTypesShape<ExtraDropDownPositionerProps> = {
  isOptionListVisible: PropTypes.bool.isRequired,
  onClickOutside: PropTypes.func,
  renderContent: PropTypes.func.isRequired,
  renderSelector: PropTypes.func.isRequired,
  shouldCloseOnClickOutside: PropTypes.func,
};

type DropDownPositionerProps = CommonDropDownPositionerProps & ExtraDropDownPositionerProps;

export const dropDownPositionerPropTypes: PropTypesShape<DropDownPositionerProps> = {
  ...commonDropDownPositionerPropTypes,
  ...extraDropDownPositionerPropTypes,
};

// Default options which are applied always but can be overridden by the custom tippyOptions prop
const requiredTippyOptions: TippyProps = {
  interactive: true,
  offset: [0, spacingPopupDistance],
};

const dropDownTransitions = {
  from: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
  },
  leave: {
    opacity: 0,
  },
};

export const DropDownPositioner: React.FC<DropDownPositionerProps> = ({
  allowAnimation = false,
  isOptionListVisible,
  onClickOutside,
  renderContent,
  renderSelector,
  shouldCloseOnClickOutside,
  tippyOptions,
}) => {
  const { portalContainerRef } = useContext(PortalContainerContext);
  const useTippyOptions = {
    ...requiredTippyOptions,
    appendTo: portalContainerRef.current ?? document.body,
    ...(tippyOptions || defaultDropdownTippyOptions),
  };
  const selectorRef = useRef<HTMLElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOverlay(
    {
      isDismissable: !!onClickOutside,
      onClose: onClickOutside,
      isOpen: isOptionListVisible,
      shouldCloseOnInteractOutside: (element) =>
        !selectorRef.current?.contains(element) &&
        (!shouldCloseOnClickOutside || shouldCloseOnClickOutside(element)),
    },
    dropdownRef,
  );

  const scrollTippyProps = useUpdateTippyWithScroll(isOptionListVisible) ?? {};

  const transitions = useTransition(isOptionListVisible, {
    immediate: !allowAnimation,
    ...dropDownTransitions,
  });

  return (
    <>
      {renderSelector(selectorRef)}
      {(allowAnimation || isOptionListVisible) && (
        <Tippy
          {...mergeProps(scrollTippyProps, useTippyOptions)}
          animation
          reference={selectorRef}
          render={(attrs) => (
            <>
              {transitions(
                (style, item) =>
                  item && (
                    <animated.div style={style} {...attrs} ref={dropdownRef}>
                      {renderContent()}
                    </animated.div>
                  ),
              )}
            </>
          )}
          visible={isOptionListVisible}
        />
      )}
    </>
  );
};

DropDownPositioner.displayName = 'DropDownPositioner';
DropDownPositioner.propTypes = dropDownPositionerPropTypes;
