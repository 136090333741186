import React from 'react';
import { FormPaneWarning } from './FormPaneWarning.tsx';

type UnsavedChangesWarningProps = {
  readonly submitButtonName: string;
  readonly subjectOfChange: string;
};

export const UnsavedChangesWarning: React.FC<UnsavedChangesWarningProps> = ({
  submitButtonName,
  subjectOfChange,
}) => (
  <FormPaneWarning subtitle="Unsaved changes">
    You have unsaved changes. Click <b>{submitButtonName}</b> to {submitButtonName.toLowerCase()}{' '}
    {subjectOfChange}.
  </FormPaneWarning>
);

UnsavedChangesWarning.displayName = 'UnsavedChangesWarning';
