import { SpacesInDeliver } from './ProjectFeatureFlagsServerKeys.ts';

export type ProjectFeatureFlagValue = boolean;

export type ProjectFeatureFlagsModel = {
  SpacesInDeliver: ProjectFeatureFlagValue;
};

export const ProjectFeatureFlagsStateKeysByServerKeys: Dictionary<keyof ProjectFeatureFlagsModel> =
  {
    [SpacesInDeliver]: 'SpacesInDeliver',
  };
