import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from '../../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../../_shared/hooks/useSelector.ts';
import { ItemEditingForms } from '../../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { abandonAssignmentSectionChange } from '../../../../actions/contentItemEditingActions.ts';
import { updateContributorsInSidebar } from '../../../../actions/thunkContentItemEditingActions.ts';
import { SectionFormWrapper } from '../../../../components/details/SectionFormWrapper.tsx';
import { NonClickableSection } from '../../../../components/sidebar/sectionTypes/NonClickableSection.tsx';
import { AssignmentSections } from '../../../../constants/AssignmentSections.ts';
import { IContributorsFormShape } from '../../../../models/IContributorsFormShape.type.ts';
import { getContributorState } from '../../../selectors/getContributorState.ts';
import { ContributorsFormBase } from '../ContributorsForm/ContributorsFormBase.tsx';

export const NonClickableContributorsSection: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const onCancel = () => dispatch(abandonAssignmentSectionChange(AssignmentSections.Contributors));
  const onConfirm = (values: IContributorsFormShape) =>
    dispatch(updateContributorsInSidebar(history, values.contributors));

  const { initialContributors } = useSelector(getContributorState);

  return (
    <NonClickableSection dataUiNameAttribute={ItemEditingForms.Contributors}>
      <SectionFormWrapper>
        <ContributorsFormBase
          initialContributors={initialContributors}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      </SectionFormWrapper>
    </NonClickableSection>
  );
};

NonClickableContributorsSection.displayName = 'NonClickableContributorsSection';
