import { Box } from '@kontent-ai/component-library/Box';
import { Button } from '@kontent-ai/component-library/Button';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Spacing } from '@kontent-ai/component-library/tokens';
import PropTypes from 'prop-types';
import React from 'react';
import { ShortcutSymbols } from '../../../../../../_shared/constants/shortcutSymbols.ts';
import {
  DataUiAction,
  DataUiRteAction,
  getDataUiActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface ILinkDialogActionsBar {
  readonly disabledSubmitButtonTooltipText: string;
  readonly isSubmittingDisabled: boolean;
  readonly onCancel: () => void;
  readonly onUnlink?: () => void;
  readonly submitButtonText: string;
}

const propTypes: PropTypeMap<ILinkDialogActionsBar> = {
  disabledSubmitButtonTooltipText: PropTypes.string.isRequired,
  isSubmittingDisabled: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUnlink: PropTypes.func,
  submitButtonText: PropTypes.string.isRequired,
};

export const LinkDialogActionsBar: React.FC<ILinkDialogActionsBar> = ({
  disabledSubmitButtonTooltipText,
  isSubmittingDisabled,
  onCancel,
  onUnlink,
  submitButtonText,
}) => (
  <Box marginTop={Spacing.XL}>
    <Row>
      <Column>
        {onUnlink && (
          <Button
            type="button"
            size="small"
            buttonStyle="secondary"
            onClick={onUnlink}
            {...getDataUiActionAttribute(DataUiRteAction.Unlink)}
          >
            Unlink
          </Button>
        )}
      </Column>
      <Column width="content">
        <Inline spacing={Spacing.S}>
          <Button
            tooltipText="Cancel"
            tooltipShortcuts={ShortcutSymbols.Escape}
            tooltipPlacement="bottom-end"
            type="button"
            size="small"
            buttonStyle="tertiary"
            onClick={onCancel}
            {...getDataUiActionAttribute(DataUiAction.Cancel)}
          >
            Cancel
          </Button>
          <Button
            tooltipText={isSubmittingDisabled ? disabledSubmitButtonTooltipText : submitButtonText}
            tooltipShortcuts={isSubmittingDisabled ? undefined : ShortcutSymbols.Enter}
            tooltipPlacement="bottom"
            type="submit"
            size="small"
            disabled={isSubmittingDisabled}
            buttonStyle="primary"
            {...getDataUiActionAttribute(DataUiAction.Insert)}
          >
            {submitButtonText}
          </Button>
        </Inline>
      </Column>
    </Row>
  </Box>
);

LinkDialogActionsBar.displayName = 'LinkDialogActionsBar';
LinkDialogActionsBar.propTypes = propTypes;
