import React, { useEffect } from 'react';
import {
  EnsuredSubscriptionRoute,
  EnsuredSubscriptionRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { formatUserName, getCustomizedPeopleList } from '../../../../_shared/utils/usersUtils.ts';
import {
  loadAdministratedSubscriptions,
  loadProjects,
} from '../../../../data/actions/thunkDataActions.ts';
import { IProjectDetails } from '../../../../data/models/projects/ProjectDetails.ts';
import {
  areAllSubscriptionsCanceled,
  getAdministratedSubscriptions,
  getSubscriptionAdministrators,
  getSubscriptionPlan,
  getSubscriptionsSortedByName,
  isSubscriptionCanceled,
  isSubscriptionExpired,
  isSubscriptionSuspended,
} from '../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { ISubscriptionAdmin } from '../../shared/models/SubscriptionAdmin.ts';
import { getSubscriptionMasterEnvironments } from '../../shared/utils/subscriptionProjectUtils.ts';
import {
  leaveSubscriptionListing,
  showCreateSubscriptionModal,
} from '../actions/subscriptionListingActions.ts';
import {
  SubscriptionListing as SubscriptionListingComponent,
  SubscriptionListingItem,
} from '../components/SubscriptionListing.tsx';

export const SubscriptionListing: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadProjects());
    dispatch(loadAdministratedSubscriptions());

    return () => {
      dispatch(leaveSubscriptionListing());
    };
  }, []);

  return (
    <SubscriptionListingComponent
      areAllProjectSubscriptionsCanceled={useSelector(areAllProjectSubscriptionsCanceled)}
      listingSubscriptions={useSelector(getListingSubscriptions)}
      onShowCreateSubscriptionModal={() => dispatch(showCreateSubscriptionModal())}
      showCreateNewSubscriptionSuccessMessage={useSelector(
        getShowCreateNewSubscriptionSuccessMessage,
      )}
    />
  );
};

SubscriptionListing.displayName = 'SubscriptionListing';

function getListingSubscriptions(state: IStore): ReadonlyArray<SubscriptionListingItem> {
  const {
    data: { user, subscriptions, projects },
  } = state;

  const administratedSubscriptions = getAdministratedSubscriptions(
    subscriptions.administratedIds,
    subscriptions.byId,
  );
  const sortedSubscriptions = getSubscriptionsSortedByName(administratedSubscriptions);
  const listingSubscriptions = sortedSubscriptions.map((subscription) => ({
    adminNames: getCustomizedPeopleList<ISubscriptionAdmin, string>(
      getSubscriptionAdministrators(
        subscriptions.subscriptionAdministrators,
        subscription.subscriptionId,
      ),
      user.info.userId,
      () => 'You',
      formatUserName,
    ),
    id: subscription.subscriptionId,
    isCanceled: isSubscriptionCanceled(subscription),
    isExpired: isSubscriptionExpired(subscription),
    isSuspended: isSubscriptionSuspended(subscription),
    name: subscription.subscriptionName,
    numberOfProjects: getSubscriptionMasterEnvironments(
      projects.byId.toArray(),
      subscription.subscriptionId,
    ).length,
    path: buildPath<EnsuredSubscriptionRouteParams>(EnsuredSubscriptionRoute, {
      subscriptionId: subscription.subscriptionId,
    }),
    planDisplayName: getSubscriptionPlan(state, subscription.subscriptionId).displayName,
  }));
  return listingSubscriptions;
}

function areAllProjectSubscriptionsCanceled(state: IStore): boolean {
  const {
    data: {
      projects: { byId: projects },
      subscriptions: { byId: subscriptions },
    },
  } = state;

  const administratedIds = projects
    .map((project: IProjectDetails) => project.subscriptionId)
    .toSet();
  const administratedSubscriptions = getAdministratedSubscriptions(administratedIds, subscriptions);

  return areAllSubscriptionsCanceled(administratedSubscriptions);
}

function getShowCreateNewSubscriptionSuccessMessage(state: IStore): boolean {
  return state.subscriptionApp.listingUi.showCreateNewSubscriptionSuccessMessage;
}
