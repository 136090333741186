import { IconName } from '../../../../_shared/constants/iconEnumGenerated.ts';

const HeadingBlockType = {
  HeadingOne: 'header-one',
  HeadingTwo: 'header-two',
  HeadingThree: 'header-three',
  HeadingFour: 'header-four',
  HeadingFive: 'header-five',
  HeadingSix: 'header-six',
} as const;
export type HeadingBlockType = (typeof HeadingBlockType)[keyof typeof HeadingBlockType];
export const HeadingBlockTypes = Object.values(HeadingBlockType);

const ListBlockType = {
  OrderedListItem: 'ordered-list-item',
  UnorderedListItem: 'unordered-list-item',
} as const;
export type ListBlockType = (typeof ListBlockType)[keyof typeof ListBlockType];
export const ListBlockTypes = Object.values(ListBlockType);

export const ObjectBlockType = {
  Image: 'image',
  ContentModule: 'content-module',
  ContentComponent: 'content-component',
} as const;
export type ObjectBlockType = (typeof ObjectBlockType)[keyof typeof ObjectBlockType];
export const ObjectBlockTypes = Object.values(ObjectBlockType);

// Base block types - At the root level of the editor
export const BaseBlockType = {
  Unstyled: 'unstyled',
  ...HeadingBlockType,
  ...ListBlockType,
  ...ObjectBlockType,
  TableCell: 'table-cell',
  NewBlockPlaceholder: 'new-block',
} as const;
export type BaseBlockType = (typeof BaseBlockType)[keyof typeof BaseBlockType];

// Nested block types
const NestedBlockTypeSeparator = '/';

export function getNestedBlockType(
  parentBlockTypes: ReadonlyArray<BaseBlockType>,
  blockType: BlockType,
): BlockType {
  if (parentBlockTypes.length === 0) {
    return blockType;
  }
  return [...parentBlockTypes, blockType].join(NestedBlockTypeSeparator) as BlockType;
}

export function parseBlockType(blockType: BlockType): ReadonlyArray<BaseBlockType> {
  return blockType.split(NestedBlockTypeSeparator) as ReadonlyArray<BaseBlockType>;
}

// All block types - Including any nested blocks
export const BlockType = BaseBlockType;

export const TextBlockTypes = [
  BlockType.Unstyled,
  ...HeadingBlockTypes,
  ...ListBlockTypes,
] as const;
export type TextBlockType = (typeof TextBlockTypes)[number];

export const BlockTypesAllowedInTableCell = [
  ...TextBlockTypes,
  BlockType.Image,
  BlockType.NewBlockPlaceholder,
] as const;
type BlockTypeAllowedInTableCell = (typeof BlockTypesAllowedInTableCell)[number];

export type BlockType =
  | BaseBlockType
  | `table-cell/${(typeof BlockTypesAllowedInTableCell)[number]}`;

const BlockTypesWithSleeves = [
  BlockType.TableCell,
  BlockType.NewBlockPlaceholder,
  ...ObjectBlockTypes,
] as const;
export type BlockTypeWithSleeves = (typeof BlockTypesWithSleeves)[number];

// Block type for previous / next block
export const AdjacentBlockType = {
  None: 'none',
  ...BaseBlockType,
} as const;
export type AdjacentBlockType = (typeof AdjacentBlockType)[keyof typeof AdjacentBlockType];
export const AdjacentBlockTypes: ReadonlyArray<AdjacentBlockType> =
  Object.values(AdjacentBlockType);

export const getBaseType = (blockType: BlockType): BaseBlockType => {
  const parsedBlockType = parseBlockType(blockType);
  return parsedBlockType[parsedBlockType.length - 1] || BlockType.Unstyled;
};

export const MaxNestingLevel = 1;

export const isTopLevelBlockType = (blockType: BlockType): boolean =>
  !blockType.includes(NestedBlockTypeSeparator);

export const isNestedBlockType = (blockType: BlockType): boolean =>
  blockType.includes(NestedBlockTypeSeparator);

export function isBlockTypeNestedIn(blockType: BlockType, parentBlockType: BlockType): boolean {
  const parsed = parseBlockType(blockType);
  return parsed.slice(0, parsed.length - 1).join(NestedBlockTypeSeparator) === parentBlockType;
}

export const isTableContentBlockType = (blockType: BlockType): boolean =>
  isBlockTypeNestedIn(blockType, BlockType.TableCell);

export function getParentBlockTypes(blockType: BlockType): ReadonlyArray<BaseBlockType> {
  const parentBlockTypes = parseBlockType(blockType);
  return parentBlockTypes.slice(0, parentBlockTypes.length - 1);
}

export function changeBaseBlockType(
  currentBlockType: BlockType,
  blockType: BaseBlockType,
): BlockType {
  const parentBlockTypes = getParentBlockTypes(currentBlockType);
  const newBlockType = getNestedBlockType(parentBlockTypes, blockType);
  return newBlockType;
}

export function removeParentBlockType(blockType: BlockType): BlockType {
  const types = parseBlockType(blockType);
  if (types.length <= 1) {
    return blockType;
  }
  return types.slice(1).join(NestedBlockTypeSeparator) as BlockType;
}

export const isHeadingBlockType = (blockType: BaseBlockType): blockType is HeadingBlockType =>
  (HeadingBlockTypes as ReadonlyArray<BaseBlockType>).includes(blockType);

export const isTextBlockType = (blockType: BaseBlockType): blockType is TextBlockType =>
  (TextBlockTypes as ReadonlyArray<BaseBlockType>).includes(blockType);

export const isListBlockType = (blockType: BaseBlockType): blockType is ListBlockType =>
  (ListBlockTypes as ReadonlyArray<BaseBlockType>).includes(blockType);

export const isObjectBlockType = (blockType: BaseBlockType): blockType is ObjectBlockType =>
  (ObjectBlockTypes as ReadonlyArray<BaseBlockType>).includes(blockType);

export const isBlockTypeAllowedInTableCell = (
  blockType: BaseBlockType,
): blockType is BlockTypeAllowedInTableCell =>
  (BlockTypesAllowedInTableCell as ReadonlyArray<BaseBlockType>).includes(blockType);

export const isBlockTypeWithSleeves = (
  blockType: BaseBlockType | null,
): blockType is BlockTypeWithSleeves =>
  !!blockType && (BlockTypesWithSleeves as ReadonlyArray<BaseBlockType>).includes(blockType);

export const isMutableBlockType = (blockType: BaseBlockType): blockType is TextBlockType =>
  (TextBlockTypes as ReadonlyArray<BaseBlockType>).includes(blockType);

export const getBlockTypeIconName = (blockType: BlockType): IconName => {
  switch (blockType) {
    case BlockType.HeadingOne:
      return IconName.H1;
    case BlockType.HeadingTwo:
      return IconName.H2;
    case BlockType.HeadingThree:
      return IconName.H3;
    case BlockType.HeadingFour:
      return IconName.H4;
    case BlockType.HeadingFive:
      return IconName.H5;
    case BlockType.HeadingSix:
      return IconName.H6;
    case BlockType.UnorderedListItem:
      return IconName.ListBullets;
    case BlockType.OrderedListItem:
      return IconName.ListNumbers;
    default:
      return IconName.Pilcrow;
  }
};
