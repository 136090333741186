import { Action } from '../../../../../@types/Action.type.ts';
import {
  Role_Create_Failed,
  Role_Create_Finished,
  Role_Create_Started,
  Role_CreatorInit_Finished,
  Role_CreatorInit_Started,
  Role_Delete_Failed,
  Role_Delete_Finished,
  Role_Delete_Started,
  Role_Editing_CanRuleGroupAdded,
  Role_Editing_CanRuleGroupRemoved,
  Role_Editing_CanRuleTypeAdded,
  Role_Editing_CanRuleTypeRemoved,
  Role_Editing_CannotRuleGroupAdded,
  Role_Editing_CannotRuleGroupRemoved,
  Role_Editing_CannotRuleTypeAdded,
  Role_Editing_CannotRuleTypeRemoved,
  Role_Editing_CapabilitiesChecked,
  Role_Editing_CapabilitiesUnchecked,
  Role_Editing_NameChanged,
  Role_Editing_RoleSettingsChanged,
  Role_EditorInit_Finished,
  Role_EditorInit_Started,
  Role_Update_Failed,
  Role_Update_Finished,
  Role_Update_Started,
  Role_Validation_Failed,
  Roles_ListingInit_Started,
} from '../../constants/rolesActionTypes.ts';
import { RoleEditorStatus } from '../../models/RoleEditorStatus.ts';

const initialState = RoleEditorStatus.InitialEmpty;

export const status = (
  state: RoleEditorStatus = initialState,
  action: Action,
): RoleEditorStatus => {
  switch (action.type) {
    case Roles_ListingInit_Started:
    case Role_Delete_Finished:
      return initialState;

    case Role_CreatorInit_Started:
      return RoleEditorStatus.Loading;

    case Role_EditorInit_Started:
      return state === RoleEditorStatus.FreshlySaved
        ? state // coming from create
        : RoleEditorStatus.Loading;

    case Role_CreatorInit_Finished:
      return RoleEditorStatus.Unmodified;

    case Role_EditorInit_Finished:
      return state === RoleEditorStatus.FreshlySaved
        ? state // coming from create
        : RoleEditorStatus.Unmodified;

    case Role_Create_Finished:
    case Role_Update_Finished:
      return RoleEditorStatus.FreshlySaved;

    case Role_Create_Started:
    case Role_Update_Started:
    case Role_Delete_Started:
      return RoleEditorStatus.IsBeingSaved;

    case Role_Update_Failed:
    case Role_Create_Failed:
    case Role_Delete_Failed:
    case Role_Editing_NameChanged:
    case Role_Editing_CapabilitiesChecked:
    case Role_Editing_CapabilitiesUnchecked:
    case Role_Editing_RoleSettingsChanged:
    case Role_Editing_CanRuleTypeAdded:
    case Role_Editing_CanRuleTypeRemoved:
    case Role_Editing_CanRuleGroupAdded:
    case Role_Editing_CanRuleGroupRemoved:
    case Role_Editing_CannotRuleTypeAdded:
    case Role_Editing_CannotRuleTypeRemoved:
    case Role_Editing_CannotRuleGroupAdded:
    case Role_Editing_CannotRuleGroupRemoved:
      return RoleEditorStatus.Modified;

    case Role_Validation_Failed:
      return RoleEditorStatus.ValidationError;

    default:
      return state;
  }
};
