import { Action } from '../../../../../../@types/Action.type.ts';
import { Shared_Editor_Left } from '../../../../../../_shared/constants/sharedActionTypes.ts';
import { itemEditorOperationIds } from '../../../../../contentInventory/content/utils/itemEditorOperationIdUtils.ts';
import { ContentModels_TypeEditor_Left } from '../../../../../contentModels/shared/constants/sharedContentModelsActionTypes.ts';
import { Content_Editing_PublishContentItemVariantFinished } from '../../../../constants/editorActionTypes.ts';
import {
  ContentItemEditing_ItemSaving_Finished,
  ContentItemEditing_NewlyCreatedItem_EditorMounted,
} from '../../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

const initialState: boolean = false;

export function isCodenameAutogenerated(state = initialState, action: Action): boolean {
  switch (action.type) {
    case ContentItemEditing_NewlyCreatedItem_EditorMounted:
      return true;

    case Content_Editing_PublishContentItemVariantFinished:
    case ContentModels_TypeEditor_Left:
    case Shared_Editor_Left:
      return initialState;

    case ContentItemEditing_ItemSaving_Finished: {
      if (action.payload.operationId === itemEditorOperationIds.codename) {
        return initialState;
      }
      return state;
    }

    default:
      return state;
  }
}
