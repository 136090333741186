import {
  makeCancellablePromise,
  noOperation,
  swallowCancelledPromiseError,
} from '@kontent-ai/utils';
import React, { useEffect } from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { CommonPlanName } from '../../../../data/constants/CommonPlanName.ts';
import {
  getSelectedSubscription,
  getSubscriptionPlan,
} from '../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { getPlanName } from '../../shared/utils/planUtils.ts';
import { loadSubscriptionCurrentSpending } from '../actions/thunkCurrentUsageActions.ts';
import { CurrentUsage as CurrentUsageComponent } from '../components/CurrentUsage.tsx';

const plansAllowingChange: string[] = [
  CommonPlanName.Trial,
  CommonPlanName.Starter,
  CommonPlanName.Developer,
  CommonPlanName.Professional,
  CommonPlanName.Business,
  CommonPlanName.Premium,
];

export const CurrentUsage: React.FC = () => {
  const subscriptionCurrentSpending = useSelector(
    (state) => state.data.subscriptions.subscriptionCurrentSpending,
  );

  const selectedSubscription = useSelector(getSelectedSubscription);
  const plan = useSelector((state) =>
    getSubscriptionPlan(state, selectedSubscription?.subscriptionId ?? ''),
  );
  const planName = getPlanName(plan.name);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedSubscription?.subscriptionId) {
      return noOperation;
    }

    const initPromise = makeCancellablePromise(() =>
      dispatch(loadSubscriptionCurrentSpending(selectedSubscription.subscriptionId)),
    ).catch(swallowCancelledPromiseError);

    return () => initPromise.cancel();
  }, [selectedSubscription?.subscriptionId]);

  if (!selectedSubscription) {
    return null;
  }

  return (
    <CurrentUsageComponent
      canChangePlan={plansAllowingChange.includes(planName)}
      currentPlan={plan}
      report={subscriptionCurrentSpending}
      subscription={selectedSubscription}
    />
  );
};

CurrentUsage.displayName = 'CurrentUsage';
