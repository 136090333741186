import { HotkeysConfig, ShortcutsConfig, useHotkeys } from '@kontent-ai/component-library/hooks';
import {
  DraftEditorCommand,
  DraftHandleValue,
  EditorProps,
  EditorState,
  getDefaultKeyBinding,
} from 'draft-js';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import {
  RTECommandInfo,
  RTECommandSource,
} from '../../../../_shared/models/events/RTECommandEventData.type.ts';
import { useEditorApi } from '../../editorCore/hooks/useEditorApi.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginCreator } from '../../editorCore/types/Editor.composition.type.ts';
import { DecoratedEditorProps } from '../../editorCore/types/Editor.decorated.type.ts';
import {
  Apply,
  EditorPlugin,
  PluginState,
  Render,
} from '../../editorCore/types/Editor.plugins.type.ts';
import { DecorableFunction, Decorator, decorable } from '../../editorCore/utils/decorable.ts';
import { withDisplayName } from '../../editorCore/utils/withDisplayName.ts';
import { ApiLimitationsPlugin } from '../apiLimitations/ApiLimitationsPlugin.tsx';
import { RichTextInputCommand, TextInputCommand } from './api/EditorCommand.ts';
import { EditorCommandApi } from './api/EditorCommandApi.type.ts';
import { editorCommandApi } from './api/editorCommandApi.ts';
import { canCommandExecute } from './api/editorCommandUtils.ts';
import { KeyCommandMap, getKeyCommand } from './api/editorKeyboardUtils.ts';

export type ExecuteCommand<TCommand> = (
  command: TCommand | DraftEditorCommand,
  isShiftPressed?: boolean,
) => boolean;
export type HandleCommand<TCommand> = (
  command: TCommand | DraftEditorCommand,
  source: RTECommandSource,
  isShiftPressed?: boolean,
) => boolean;

export type OnEscape = () => void;

export type GetHotKeysHandlers = () => HotkeysConfig;

type KeyboardShortcutsPluginState<TCommand> = {
  readonly executeCommand: DecorableFunction<ExecuteCommand<TCommand>>;
  readonly handleCommand: HandleCommand<TCommand>;
  readonly getHotKeysHandlers: DecorableFunction<GetHotKeysHandlers>;
  readonly getIsShiftPressed: () => boolean;
  readonly onEscape: DecorableFunction<OnEscape>;
};

export type TrackRTECommandUsed = (commandInfo: RTECommandInfo) => void;

type KeyboardShortcutsPluginProps = {
  readonly onEscape?: () => void;
  readonly trackRTECommandUsed?: TrackRTECommandUsed;
};

export type KeyboardShortcutsPlugin<TCommand> = EditorPlugin<
  KeyboardShortcutsPluginState<TCommand>,
  KeyboardShortcutsPluginProps,
  EditorCommandApi,
  [ApiLimitationsPlugin]
>;

type EditorWithKeyShortcutsProps<TCommand> = DecoratedEditorProps<
  KeyboardShortcutsPlugin<TCommand>,
  {
    readonly keyCommandMap: KeyCommandMap<TCommand>;
    readonly isShiftPressedRef: React.MutableRefObject<boolean>;
  }
>;

const EditorWithKeyShortcuts = <
  TCommand extends DraftEditorCommand | TextInputCommand | RichTextInputCommand,
>({
  baseRender,
  isShiftPressedRef,
  keyCommandMap,
  state,
}: EditorWithKeyShortcutsProps<TCommand>) => {
  const {
    editorState: baseEditorState,
    editorProps: {
      handleBeforeInput: baseHandleBeforeInput,
      handleKeyCommand: baseHandleKeyCommand,
    },
    handleCommand,
  } = state;

  const allowInput = useRef(true);

  const debouncedResetAllowInput = useDebouncedCallback(() => {
    allowInput.current = true;
  });
  useEffect(() => debouncedResetAllowInput.cancel, [debouncedResetAllowInput]);

  const keyBindingFn = useCallback(
    (event: React.KeyboardEvent): TCommand | DraftEditorCommand | null => {
      isShiftPressedRef.current = event.shiftKey;

      const commandInfo = getKeyCommand(event, keyCommandMap);
      if (!commandInfo || !commandInfo.isCustomCommandRelevant(baseEditorState)) {
        return getDefaultKeyBinding(event) as TCommand;
      }

      // Some keys only temporarily change keyboard map for the next key https://en.wikipedia.org/wiki/Dead_key
      // which may result in input of special or accented chars despite the fact that we handle the command
      // To avoid the unwanted input we need to temporarily disable processing input until the current event and React loop is processed
      if (commandInfo.command) {
        allowInput.current = false;
        debouncedResetAllowInput();
      }

      return commandInfo.command;
    },
    [keyCommandMap, baseEditorState, isShiftPressedRef, debouncedResetAllowInput],
  );

  const handleKeyCommand = useCallback(
    (
      command: TCommand | DraftEditorCommand,
      editorState: EditorState,
      eventTimeStamp: number,
    ): DraftHandleValue => {
      if (handleCommand(command, RTECommandSource.KeyCommand)) {
        return 'handled';
      }

      return baseHandleKeyCommand?.(command, editorState, eventTimeStamp) ?? 'not-handled';
    },
    [baseHandleKeyCommand, handleCommand],
  );

  const handleBeforeInput = useCallback<Required<EditorProps>['handleBeforeInput']>(
    (chars, editorState, eventTimeStamp) => {
      if (!allowInput.current) {
        return 'handled';
      }

      return baseHandleBeforeInput?.(chars, editorState, eventTimeStamp) ?? 'not-handled';
    },
    [baseHandleBeforeInput],
  );

  const stateWithKeyShortcuts: PluginState<KeyboardShortcutsPlugin<TCommand>> = {
    ...state,
    editorProps: {
      ...state.editorProps,
      handleBeforeInput,
      handleKeyCommand,
      keyBindingFn,
    },
  };

  const hotkeysProps = useHotkeys(state.getHotKeysHandlers());

  return <div {...hotkeysProps}>{baseRender(stateWithKeyShortcuts)}</div>;
};

EditorWithKeyShortcuts.displayName = 'EditorWithKeyShortcuts';

type Command = DraftEditorCommand | TextInputCommand | RichTextInputCommand;

export const useKeyboardShortcuts: PluginCreator<
  KeyboardShortcutsPlugin<Command>,
  [KeyCommandMap<Command>]
> = (baseEditor, keyCommandMap) =>
  useMemo(
    () =>
      withDisplayName('KeyboardShortcutsPlugin', {
        ComposedEditor: (props) => {
          const { onEscape, trackRTECommandUsed } = props;
          const isShiftPressed = useRef(false);

          const render: Decorator<Render<KeyboardShortcutsPlugin<Command>>> = useCallback(
            (baseRender) => (state) => (
              <EditorWithKeyShortcuts
                baseRender={baseRender}
                isShiftPressedRef={isShiftPressed}
                keyCommandMap={keyCommandMap}
                state={state}
                trackRTECommandUsed={trackRTECommandUsed}
              />
            ),
            [trackRTECommandUsed, keyCommandMap],
          );

          const getIsShiftPressed = useCallback(() => isShiftPressed.current, []);

          const apply: Apply<KeyboardShortcutsPlugin<Command>> = useCallback(
            (state) => {
              state.render.decorate(render);

              const internalOnEscape = decorable<OnEscape>(() => onEscape?.());

              const getHotKeysHandlers: GetHotKeysHandlers = () => ({
                [ShortcutsConfig.Escape]: internalOnEscape,
              });

              const executeCommand = decorable<ExecuteCommand<Command>>(() => false);

              const handleCommand: HandleCommand<Command> = (command, source) => {
                const editorState = state.getEditorState();
                const commandStatus = state.getApi().getCommandStatus(editorState, command);
                const canExecuteCommand = canCommandExecute(commandStatus);

                if (!canExecuteCommand) {
                  return true;
                }

                const selection = editorState.getSelection();
                if (!selection.getHasFocus()) {
                  return true;
                }

                if (executeCommand(command, isShiftPressed.current)) {
                  trackRTECommandUsed?.({
                    canExecuteCommand,
                    canUpdateContent: state.canUpdateContent(),
                    command,
                    commandStatus,
                    source,
                  });

                  return true;
                }

                return false;
              };

              return {
                executeCommand,
                getHotKeysHandlers: decorable(getHotKeysHandlers),
                getIsShiftPressed,
                handleCommand,
                onEscape: internalOnEscape,
              };
            },
            [render, getIsShiftPressed, onEscape, trackRTECommandUsed],
          );

          const { getApiMethods } =
            useEditorApi<KeyboardShortcutsPlugin<Command>>(editorCommandApi);

          return useEditorWithPlugin(baseEditor, props, { apply, getApiMethods });
        },
      }),
    [baseEditor, keyCommandMap],
  );
